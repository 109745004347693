/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { TextBox } from '@syncfusion/ej2-inputs';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
import RegioniProvince1 from '../assets/RegioniProvince1.json';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
var fields = [];

export default class PaginaLiquidatori extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete','Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog'};
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                         { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" }}];
        this.DdlObj = null;
        this.txtObj1 = null;
        this.elemSelezProvincia = null;
        this.pageSettings = {pageSize : 15, pageCount : 15};
        this.filterSettings = { type: 'CheckBox'};
        this.searchSettings = {operator: 'contains' , ignoreCase: true};
        this.editTemplate = { create: () => { let elemTXT = document.createElement('input'); return elemTXT },
                               read: () => {return this.txtObj1.value},
                               destroy: () => {this.txtObj1.destroy()},
                               write: (args) => { this.txtObj1 = new TextBox({ value: args.rowData.note, floatLabelType: 'Auto', placeholder: 'Note', 
                                                  multiline: true, htmlAttributes: {rows: 3} }); this.txtObj1.appendTo(args.element) } };  
        this.editTemplate2 = { create: () => { let elemDDL = document.createElement('input'); return elemDDL},
                                read: () => {return this.DdlObj.text},
                                destroy: () => {this.DdlObj.destroy()},
                                write: (args) => {this.elemSelezProvincia = args.rowData.provincia; this.DdlObj = new DropDownList({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Provincia', popupHeight:'200px', 
                                                  dataSource:JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaProvince1'))), value: args.rowData.provincia, floatLabelType: 'Auto', 
                                                  allowFiltering: true, filterType: 'Contains', 
                                                  change: (args2) => {
                                                        args.rowData.provincia = args2.value;
                                                        this.elemSelezProvincia = args2.value;
                                                  }}); this.DdlObj.appendTo(args.element)}};                        
    }
    state = {
        blocking: false,
        dsLiquidatori: [],
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    actionBegin(args){
        if (args.requestType === "beginEdit" || args.requestType === "add") { 
            for (var i = 0; i < this.columns.length; i++) { 
                if (!this.columns[i].visible) { 
                    fields.push(this.columns[i].field); 
                    this.columns[i].visible = true; 
                } 
            } 
        } 
        if (args.requestType === "save" || args.requestType === "cancel") { 
            for (var i = 0; i < this.columns.length; i++) { 
                for (var j = 0; j < fields.length; j++) { 
                    if (this.columns[i].field === fields[j]) { 
                        this.columns[i].visible = false; 
                    } 
                } 
            } 
        }
        if (args.action === "edit" && args.requestType === "save") {
            args.data.provincia = this.elemSelezProvincia;
        }
    }
    actionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) { 
            args.dialog.width = '60%';
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuovo Liquidatore' : `Dettaglio di ${args.rowData.ragione_sociale}`;
        }
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '00009';
            const dataObj = {
                IdLiquidatore: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_liquidatore : args.data[0].id_liquidatore,
                RagioneSociale: CustomJS.isNullEmpty(args.data[0]) ? args.data.ragione_sociale : args.data[0].ragione_sociale,
                Indirizzo: CustomJS.isNullEmpty(args.data[0]) ? args.data.indirizzo : args.data[0].indirizzo,
                Citta: CustomJS.isNullEmpty(args.data[0]) ? args.data.citta : args.data[0].citta,
                Provincia: args.rowData?.provincia,
                CAP: CustomJS.isNullEmpty(args.data[0]) ? args.data.cap : args.data[0].cap,
                Telefono1: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono1 : args.data[0].telefono1,
                Telefono2: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono2 : args.data[0].telefono2,
                PIVA: CustomJS.isNullEmpty(args.data[0]) ? args.data.p_iva : args.data[0].p_iva,
                Email1: CustomJS.isNullEmpty(args.data[0]) ? args.data.email1 : args.data[0].email1,
                Email2: CustomJS.isNullEmpty(args.data[0]) ? args.data.email2 : args.data[0].email2,
                Note: CustomJS.isNullEmpty(args.data[0]) ? args.data.note : args.data[0].note
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });                       
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
            if(args.action === "add")
            setTimeout(() => {
                this.caricaDati();
            }, 200); 
        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }

    render() {
        return(<React.Fragment>  
            <BlockUi id='blockUIPaginaLiquidatori' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                <div className="e-card-content"> 
                                    {/* <div className='row'></div>  
                                    <div className='row'></div>
                                    <div className='row'></div> */}
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <GridComponent id="GridLiquidatori" dataSource={this.state.dsLiquidatori} ref={ grid => this.gridInstance = grid } allowSelection={true} enableHover={true} allowTextWrap={true}
                                                allowPaging={true} allowSorting={false} allowFiltering={true} enableAltRow={true} enablePersistence={true} actionBegin={this.actionBegin} 
                                                actionComplete={this.actionComplete.bind(this)} toolbarClick={this.toolbarClick.bind(this)} pageSettings={this.pageSettings} searchSettings={this.searchSettings} filterSettings={this.filterSettings}
                                                toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px'}} >
                                                <ColumnsDirective>
                                                    <ColumnDirective field='id_liquidatore' headerText='ID Liquidatore' isPrimaryKey='true' textAlign='Center' visible={false}></ColumnDirective>
                                                    <ColumnDirective field='ragione_sociale' headerText="Ragione Sociale" width='20%' validationRules={{ required: true }} headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='indirizzo' headerText='Indirizzo'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='citta' headerText='Città' visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='descr_provincia' headerText='Provincia' editType='DropDownEdit' edit={this.editTemplate2} visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='cap' headerText='CAP'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='p_iva' headerText='P.IVA'  visible={false} headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='telefono1' headerText="Telefono1" width='10%' headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='telefono2' headerText="Telefono2" width='10%' headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='email1' headerText="Email1" width='15%' headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='email2' headerText="Email2" width='15%' headerTemplate={headerTemplate}></ColumnDirective>
                                                    <ColumnDirective field='note' headerText="Note"  width='30%' headerTemplate={headerTemplate} edit={this.editTemplate}></ColumnDirective>
                                                    <ColumnDirective field='Comandi' headerText="" width='10%' commands={this.commands} textAlign='Right' allowFiltering={false}></ColumnDirective>
                                                </ColumnsDirective>
                                                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, Page]} />
                                            </GridComponent>
                                        </div>
                                    </div>                        
                                </div>                              
                            </div>
                        </div> 
                    </div>
                </div>
               </div>
               </BlockUi>
        </React.Fragment>
        );
    }
    componentDidMount(){
        // if (CustomJS.isNullEmpty(this.props.ArrivaDaDettSinistro))
        //     document.getElementById('titoloPagina').innerText = 'Liquidatori';
        this.caricaDati();
    }
    caricaDati(){
        this.toggleBlocking();
        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '00009', retData => {
            retData.forEach(elm => {
                let indOK = 0;
                let objReg = _.find(RegioniProvince1.regioni, elm2 => { return !CustomJS.isNullEmpty(_.find(elm2.province, (elm3,idx) => { indOK = elm3 === elm.provincia ? idx : 0;  return elm3 === elm.provincia; })) })
                elm.descr_provincia = objReg?.capoluoghi[indOK];
            });
            this.gridInstance.dataSource = retData;
            if (!CustomJS.isNullEmpty(this.props.DescrLiquidatore))
                this.gridInstance.search(this.props.DescrLiquidatore);            
            else
                this.gridInstance.search();
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            ); 
        });
    } 
}
