/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable default-case */
/* eslint-disable no-extend-native */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, DetailRow, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import mapboxgl from 'mapbox-gl'; 
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
import RegioniProvince1 from '../assets/RegioniProvince1.json';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
String.prototype.format2 = function() {
    let a = this;
    for (let k in arguments) {
      a = a.replace("{" + k + "}", arguments[k])
    }
    return a
}

//mapboxgl.accessToken = CustomJS.tokenAPIMapboxPublic; //pubblico
mapboxgl.accessToken = CustomJS.tokenAPIMapboxPrivate;  //privato

const getRealCoordinate = (indirizzo, cbFunc) => {        
    let longitude, latitude, re = new RegExp('/',"gi");
    let count = indirizzo.match(re)?.length; 
    indirizzo = indirizzo.replace(/\?/g, '');
    if (count && count > 0) {
        for (let ind=1;ind<=count;ind++)
             indirizzo = CustomJS.removeStringFromString(indirizzo, indirizzo.indexOf('/'), indirizzo.indexOf(' ', indirizzo.indexOf('/') - 1));
    }
    console.log(`Indirizzo sanificato(se necessario) : ${indirizzo}`);    
    CustomJS.getRemoteData2(CustomJS.APIUrlMapboxMaps.format2(indirizzo, CustomJS.tokenAPIMapboxPrivate), '',  
      'GET', "", retData => {
        if (!CustomJS.isNullEmpty(retData)) {
            let geoObj = _.sortBy(JSON.parse(retData).features, 'relevance').reverse();
            longitude = geoObj[0]?.center[0]; //geoObj[0]?.geometry?.coordinates[0];     //Mapbox Maps
            //latitude = geoObj.resourceSets[0]?.resources[0]?.point?.coordinates[0];   //Bing Maps
            latitude = geoObj[0]?.center[1]; //geoObj[0]?.geometry?.coordinates[1];    //Mapbox Maps
            //longitude = geoObj.resourceSets[0]?.resources[0]?.point?.coordinates[1];  //Bing Maps             
            cbFunc(longitude, latitude);       
        }
    }, true, true);  
}  

export default class PaginaGeoreferenziazione extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Search'];
        this.editSettings = { allowAdding: false, allowEditing: false, allowDeleting: false, showDeleteConfirmDialog: true, mode: 'Dialog'};
        this.pageSettings = {pageSize : 15, pageCount : 15};
        this.filterSettings = { type: 'CheckBox'};
        this.salvaSel = {};
        this.commands = [{ type: "Georeferenzia", buttonOption: { iconCss: "fas fa-globe fa-10x", cssClass: "e-details" }},
                        { type: "Search", buttonOption: { iconCss: "fas fa-search fa-10x", cssClass: "e-details" } }];
        this.dialogButtons1 = [{
            click: () => {
                this.dialogInstance1.hide();
                this.aggiornaGPS();
            },
            buttonModel: {
                content: 'CONFERMA',
                cssClass: 'e-primary',
                isPrimary: true
            },
        },
        {
            click: () => {
                this.dialogInstance1.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
            }];
            this.dialogButtons2 = [{
                click: () => {
                    this.dialogInstance1.hide();
                    this.confermaGeorefTutto();
                },
                buttonModel: {
                    content: 'CONFERMA',
                    cssClass: 'e-primary',
                    isPrimary: true
                },
            },
            {
                click: () => {
                    this.dialogInstance1.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
                }];
    }
    state = {
        blocking: false,
        dsGeoreferenziazione: [],
        showDialog1: false,
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    onRowDatabound(args) {       
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }
    commandClick(args) {
        if (args.commandColumn?.buttonOption.iconCss.indexOf('fa-search') > -1) {
            let indirizzo = `${args.rowData.indirizzo}+${args.rowData.cap}+${args.rowData.citta}+${args.rowData.provincia}`;
            this.dialogInstance2.buttons = [];
            this.dialogInstance2.height = '90%';
            this.dialogInstance2.minHeight='80%';
            this.dialogInstance2.width = '80%';  
            this.dialogInstance2.beforeOpen = (args) => {
                document.getElementById('mappaGoogle').src = '';
            };
            this.dialogInstance2.open = (args) => {
                document.getElementById('mappaGoogle').src=`https://www.google.com/maps/embed/v1/place?key=AIzaSyDIusSsqd2WctS-RfkcEJjFLuP7q3d0pcI&q=${indirizzo}`;  
            };
            this.dialogInstance2.show();   
        } 
        if (args.commandColumn?.buttonOption.iconCss.indexOf('fa-globe') > -1) {
            this.dialogInstance1.buttons = this.dialogButtons1;
            this.dialogInstance1.content = `Confermi l'aggiornamento delle coordinate dell'incarico con ID <span style='font-size:17px;font-weight:bolder'>${args.rowData.id_interno_sinistro}</span>?`;
            this.dialogInstance1.height = '20%';
            this.dialogInstance1.minHeight = '20%';
            this.dialogInstance1.width = '20%';           
            this.dialogInstance1.show(); 
            this.salvaSel = args.rowData;  
        }         
    }
    aggiornaGPS(){
        let indirizzo = `${this.salvaSel.indirizzo} ${this.salvaSel.citta} ${this.salvaSel.cap} ${this.salvaSel.provincia}`;
        getRealCoordinate(indirizzo, (longitude, latitude) => { 
            console.log(longitude);
            console.log(latitude);
            if (!CustomJS.isNullEmpty(longitude) && !CustomJS.isNullEmpty(latitude)) {                          
                const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
                const dataObj = {
                    Latitudine :  latitude,
                    Longitudine:  longitude,
                    IdSinistro:  this.salvaSel.id_sinistro,
                };
                const QueryExec = encodeURIComponent("UPDATE [01001] SET [01001].lat = @latitudine, lng = @longitudine WHERE [01001].id_sinistro = @id_sinistro;");
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                    toast.success('Coordinata aggiornata con successo', {
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );
                    this.caricaDati();
                 }, messaggio => {
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                });
            }
            else{
                toast.error(`Coordinate non trovate per l'indirizzo${indirizzo}`, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }
        });    
    }
    btnGeorefTutto(){
        let messaggioConf = '', widthDlg = '', heightDlg = '';
        let arrTmp = this.gridInstance.dataSource.filter(elm => { return CustomJS.isNullEmpty(elm.lng) || CustomJS.isNullEmpty(elm.lat)});
        if (arrTmp.length > 500) {
            messaggioConf = `Gli incarichi da georeferenziare sono <span style='font-size:15px;font-weight:bolder'>${arrTmp.length.toLocaleString()}</span>. Essendo il numero troppo elevato, verrà limitato a <span style='font-size:15px;font-weight:bolder'>500</span> per non avere un tempo di risposta troppo alto. In questo caso l'operazione dovrà essere ripetuta più volte fino al completamento degli incarichi privi di georeferenzazione. Confermi l'aggiornamento delle coordinate GPS di <span style='font-size:15px;font-weight:bolder'>500</span> incarichi che ne sono privi?`
            widthDlg = '35%';
            heightDlg = '20%';
        }
        else {
            messaggioConf = `Gli incarichi da georeferenziare sono ${arrTmp.length.toLocaleString()}. Confermi l'aggiornamento delle coordinate GPS di tutti gli incarichi che ne sono privi?`
            widthDlg = '20%';
            heightDlg = '15%';
        }
        this.dialogInstance1.buttons = this.dialogButtons2;
        this.dialogInstance1.content = messaggioConf; 
        this.dialogInstance1.height = heightDlg;
        this.dialogInstance1.minHeight = heightDlg;
        this.dialogInstance1.width = widthDlg;           
        this.dialogInstance1.show(); 
    }
    confermaGeorefTutto(){
        this.toggleBlocking();
        let arrIndirizzi = [];
        let arrTmpLimit = [];
        let arrTmp = this.gridInstance.dataSource.filter(elm => { return CustomJS.isNullEmpty(elm.lng) || CustomJS.isNullEmpty(elm.lat)});
        if (arrTmp.length > 500) {
            for (let ind = 0; ind <= 499; ind++) {
                arrTmpLimit.push(arrTmp[random(arrTmp.length - 1)]);         
            }
        }
        else
           arrTmpLimit = arrTmp;
        arrTmpLimit.forEach(elm => {
            let indirizzo = `${elm.indirizzo} ${elm.citta} ${elm.cap} ${elm.provincia}`;    
            arrIndirizzi.push({ IdSinistro: elm.id_sinistro, Indirizzo: indirizzo });        
        });
        toast.warn('Procedura di reperimento delle coordinate (max 500 incarichi) in esecuzione. Questa operazione può durare fino a qualche minuto..attendere prego.', {
            onClose: () => toast.dismiss(),
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,           
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            newestOnTop: true,
            rtl: false,
            pauseOnFocusLoss: true}
        );
        document.getElementById('sidebar-menu').style.visibility = 'hidden';
        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;   
        const dataObj = {
            Indirizzi: arrIndirizzi
        };
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/georeferenziaTutto`, JSON.stringify(dataObj), 'POST',
        'application/json', token, retData => { 
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking();
                return;
            }            
            toast.success('Operazione di georeferenziazione conclusa con successo!!', {
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking();
            this.caricaDati();
            document.getElementById('sidebar-menu').style.visibility = 'visible';
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            this.toggleBlocking();
        }, true, false);        
    }

    render() {
        return(<React.Fragment>  
            <BlockUi id='blockUIPaginaGeoreferenziazione' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="defaultDialog" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                    ref={dialog => this.dialogInstance1 = dialog} header='' isModal={true}
                    content=''>
                </DialogComponent> 
                <DialogComponent id="defaultDialog2" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                    ref={dialog => this.dialogInstance2 = dialog} header='<span style="color:#133B95;font-weight:bolder;font-size:1.3em;">Visualizzazione Georeferenza</span>' isModal={true} height='90%' minHeight='90%'
                    content=''>
                        <iframe id='mappaGoogle' src="" frameBorder="1" width='100%' height='99%' allowFullScreen></iframe>
                </DialogComponent> 
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                        {/* <div className='row'></div> 
                                        <div className='row'></div>
                                        <div className='row'></div>   */}
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-end" style={{ paddingBottom : '10px'}}>
                                            <ButtonComponent id='BtnGeorefTutto' alt='Georeferenzia Tutto' onClick={this.btnGeorefTutto.bind(this)} cssClass='e-info'>Georeferenzia Tutto</ButtonComponent>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <GridComponent id="GridGeoreferenziazione" dataSource={this.state.dsGeoreferenziazione} ref={ grid => this.gridInstance = grid } allowSelection={true} enableHover={true} allowTextWrap={true}
                                                    allowPaging={true} allowSorting={false} allowFiltering={true} enableAltRow={true} enablePersistence={true} commandClick={this.commandClick.bind(this)}
                                                    toolbarClick={this.toolbarClick.bind(this)} rowDataBound={this.onRowDatabound} pageSettings={this.pageSettings} filterSettings={this.filterSettings}
                                                    toolbar={this.toolbarOptions} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px'}} >
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='id_interno_sinistro' headerText='ID Sinistro' isPrimaryKey='true'  visible={true} width='25%' textAlign='Center'headerTemplate={headerTemplate}  clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='indirizzo' headerText="Indirizzo"  width='20%' textAlign='Center' visible={true} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='cap' headerText="CAP"  width='20%' textAlign='Center'headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='citta' headerText="Città"  width='20%'  textAlign='Center'headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='descr_provincia' headerText="Provincia"  width='30%' visible={true} textAlign='Center'headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='lat' headerText="Latitudine"  width='20%' textAlign='Center' visible={true} type='number' format='n7' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='lng' headerText="Longitudine"  width='20%' textAlign='Center' visible={true} type='number' format='n7' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='15%' commands={this.commands} textAlign='Right' allowFiltering={false}></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, DetailRow, Page]} />
                                                </GridComponent>
                                            </div> 
                                        </div>                        
                                    </div>                              
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount(){
        // document.getElementById('titoloPagina').innerText = 'Georeferenziazione';
        setTimeout(() => {
            this.caricaDati();
        }, 400); 
    }
    caricaDati() {
        this.toggleBlocking();
        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '01001', retData => {
            let arrTemp = _.uniq(retData, elm => elm.id_interno_sinistro);
            let GridData1 = _.filter(arrTemp, (elm) => {return !CustomJS.isNullEmpty(elm.id_interno_sinistro)});
            GridData1.forEach(elm => {
                let indOK = 0;
                let objReg = _.find(RegioniProvince1.regioni, elm2 => { return !CustomJS.isNullEmpty(_.find(elm2.province, (elm3,idx) => { indOK = elm3 === elm.provincia ? idx : 0;  return elm3 === elm.provincia; })) })
                elm.descr_provincia = objReg?.capoluoghi[indOK];
                indOK = 0;
                objReg = _.find(RegioniProvince1.regioni, elm2 => { return !CustomJS.isNullEmpty(_.find(elm2.province, (elm3,idx) => { indOK = elm3 === elm.provincia_nascita ? idx : 0;  return elm3 === elm.provincia_nascita; })) })
                elm.descr_provincia_nascita = objReg?.capoluoghi[indOK];
            })
            this.gridInstance.dataSource= GridData1;
            this.toggleBlocking();
            }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            ); 
        });
    }
}