import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, DetailRow, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { TextBox } from '@syncfusion/ej2-inputs';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
export default class PaginaModelliEmail extends SampleBase {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog' };
        this.editSettings2 = { allowAdding: true, allowEditing: false, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Normal' };
        this.pageSettings = { pageSize: 10, pageCount: 10 };
        this.filterSettings = { type: 'CheckBox' };
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
        { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" } },
        { type: "Duplica Modello", buttonOption: { iconCss: "fas fa-clone fa-10x", cssClass: "e-details" } }];
        this.commands2 = [{ type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" } }];
        this.editTemplate3 = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => { return this.DdlObj2.text },
            destroy: () => { this.DdlObj2.destroy() },
            write: (args) => {
                this.DdlObj2 = new DropDownList({
                    fields: { text: 'TEXT', value: 'VALUE' }, placeholder: 'Stato', popupHeight: '300px', floatLabelType: 'Auto',
                    dataSource: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02004'))).map(elm => { return { VALUE: elm.id_tipo_stato.toString(), TEXT: elm.descrizione } }),
                    value: args.rowData.id_stato?.toString(),
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => { args.rowData.id_stato = parseInt(args2.value) }
                }); this.DdlObj2.appendTo(args.element)
            }
        };
        this.childGrid = {
            queryString: 'id_email',
            editSettings: this.editSettings2,
            allowPaging: false,
            allowSelection: true,
            enableHover: true,
            showDeleteConfirmDialog: true,
            allowTextWrap: true,
            allowSorting: false,
            allowFiltering: false,
            enableAltRow: true,
            enablePersistence: false,
            actionComplete: this.actionComplete2.bind(this),
            pageSettings: { pageSize: 15, pageCount: 15 },
            toolbar: ['Add', 'Delete', 'Update', 'Cancel', 'Search'],
            columns: [
                { field: 'id_email_stato', isPrimaryKey: true, visible: false },
                { field: 'descr_stato', headerText: 'Stato ', textAlign: 'Center', headerTemplate: headerTemplate, validationRules: { required: true, checkDuplicate: [this.checkDuplicateStato, 'Stato già presente'] }, editType: 'DropDownEdit', edit: this.editTemplate3 },
                { field: 'Comandi', headerText: "", commands: this.commands2, textAlign: 'Right', headerTemplate: headerTemplate },
            ],
            actionBegin: this.actionBegin2
        };
        this.DdlObj = null;
        this.elemSelezTipoMess = null;
        this.DdlObj2 = null;
        this.elemSelezTipoDest = null;
        this.txtObj1 = null;
        this.editTemplate1 = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => { return this.DdlObj.text },
            destroy: () => { this.DdlObj.destroy() },
            write: (args) => {
                this.elemSelezTipoMess = args.rowData.id_tipo_messaggio; this.DdlObj = new DropDownList({
                    fields: { text: 'TEXT', value: 'VALUE' }, placeholder: 'Tipo Messaggio', popupHeight: '200px', floatLabelType: 'Auto',
                    dataSource: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02009'))).map(elm => { return { VALUE: elm.id_tipo_mess.toString(), TEXT: elm.descrizione } }),
                    value: args.rowData.id_tipo_messaggio?.toString(),
                    change: (args2) => {
                        args.rowData.id_tipo_messaggio = parseInt(args2.value);
                        this.elemSelezTipoMess = parseInt(args2.value);
                    }
                }); this.DdlObj.appendTo(args.element)
            }
        };
        this.editTemplate2 = {
            create: () => { let elemDDL = document.createElement('input'); return elemDDL },
            read: () => { return this.DdlObj2.text },
            destroy: () => { this.DdlObj2.destroy() },
            write: (args) => {
                this.elemSelezTipoDest = args.rowData.id_tipo_dest; this.DdlObj2 = new DropDownList({
                    fields: { text: 'TEXT', value: 'VALUE' }, placeholder: 'Destinatario', popupHeight: '300px', floatLabelType: 'Auto',
                    dataSource: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02007'))).map(elm => { return { VALUE: elm.id_tipo_dest.toString(), TEXT: elm.descrizione } }),
                    value: args.rowData.id_tipo_dest?.toString(),
                    change: (args2) => {
                        args.rowData.id_tipo_dest = parseInt(args2.value);
                        this.elemSelezTipoDest = parseInt(args2.value);
                    }
                }); this.DdlObj2.appendTo(args.element)
            }
        };
        this.editTemplate4 = {
            create: () => { let elemTXT = document.createElement('input'); return elemTXT },
            read: () => { return this.txtObj1.value },
            destroy: () => { this.txtObj1.destroy() },
            write: (args) => {
                this.txtObj1 = new TextBox({
                    value: args.rowData.testo, floatLabelType: 'Auto', placeholder: 'Testo', multiline: true,
                    htmlAttributes: { rows: 20 }
                }); this.txtObj1.appendTo(args.element)
            }
        };
    }
    state = {
        blocking: false,
        dsCat: [],
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    checkDuplicateStato(args) {
        if (!CustomJS.isNullEmpty(_.find(document.getElementById(args.element.id.substring(0, args.element.id.indexOf(args.element.name))).ej2_instances[0].dataSource,
            elm2 => { return elm2.id_stato.toString() === args.value })))
            return false;
        else
            return true;
    }
    onRowDatabound(args) {
    }
    onActionBegin(args) {
        if (args.action === "edit" && args.requestType === "save") {
            args.data.id_tipo_messaggio = this.elemSelezTipoMess;
            args.data.id_tipo_dest = this.elemSelezTipoDest;
        }
    }
    onActionBegin2(args) {
        if (args.requestType === 'add') {
            args.data.id_email = this.parentDetails.parentRowData.id_email;
        }
    }
    onActionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
            args.dialog.width = '60%';
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuova Email Manuale' : `Dettaglio di ${args.rowData.titolo_email}`;
        }
        if (((args.action === "edit" || args.action === "add") && args.requestType === "save") || args.requestType === "delete") {
            const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
            const azione = args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '01006';
            const dataObj = {
                IdEmail: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_email : args.data[0].id_email,
                IdTipoMess: args.rowData?.id_tipo_messaggio,
                IdTipoDest: args.rowData?.id_tipo_dest,
                Titolo: CustomJS.isNullEmpty(args.data[0]) ? args.data.titolo_email : args.data[0].titolo_email,
                Oggetto: CustomJS.isNullEmpty(args.data[0]) ? args.data.oggetto : args.data[0].oggetto,
                Testo: CustomJS.isNullEmpty(args.data[0]) ? args.data.testo : args.data[0].testo,
                TuttiStati: CustomJS.isNullEmpty(args.data[0]) ? args.data.tutti_stati : args.data[0].tutti_stati,
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        return;
                    }
                    if (retData.response === 'OK') {
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabellaAzioneCosa', CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            }
                            );
                        }, "SELECT [01006].id_email, [01006].titolo_email, [01006].id_tipo_dest, [020071].id_stato, [01006].id_tipo_messaggio FROM [020071] INNER JOIN [01006] ON [020071].id_email = [01006].id_email WHERE [01006].tutti_stati=0  UNION ALL  SELECT [01006].id_email, [01006].titolo_email, [01006].id_tipo_dest, 0, [01006].id_tipo_messaggio  FROM [01006] WHERE [01006].tutti_stati=1  order by titolo_email;");
                        if (args.action === "add")
                            setTimeout(() => {
                                this.caricaDati();
                            }, 200);
                    }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    })
                }, true, false);
        }
    }
    actionComplete2(args) {
        if (((args.action === "edit" || args.action === "add") && args.requestType === "save") || args.requestType === "delete") {
            const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
            const azione = args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '020071';
            const dataObj = {
                IdEmailStato: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_email_stato : args.data[0].id_email_stato,
                IdEmail: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_email : args.data[0].id_email,
                IdStato: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_stato : args.data[0].id_stato
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        return;
                    }
                    if (retData.response === 'OK') {
                        if (args.action === "add")
                            setTimeout(() => {
                                this.caricaDati();
                            }, 200);
                    }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    })
                }, true, false);
        }
    }
    toolbarClick(args) {
        // eslint-disable-next-line default-case
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }
    onCommandClick(args) {
        if (args.commandColumn.buttonOption.iconCss?.indexOf('fa-clone') > -1) {
            document.getElementById('GridModelliEmail_add').click();
            setTimeout(()=>{
               document.getElementById('GridModelliEmaildescr_tipo_mess').ej2_instances[0].value = args.rowData.id_tipo_messaggio.toString(); 
               document.getElementById('GridModelliEmaildescr_tipo_dest').ej2_instances[0].value = args.rowData.id_tipo_dest.toString(); 
               document.getElementById('GridModelliEmailtitolo_email').ej2_instances[0].value = args.rowData.titolo_email; 
               document.getElementById('GridModelliEmailoggetto').ej2_instances[0].value = args.rowData.oggetto; 
               document.getElementById('GridModelliEmailtesto').ej2_instances[0].value = args.rowData.testo; 
               document.getElementById('GridModelliEmailtutti_stati').ej2_instances[0].checked = args.rowData.tutti_stati; 
            }, 200)
        }
    }
    render() {
        return (<React.Fragment>
            <BlockUi id='blockUIPaginaCat' tag='div' blocking={this.state.blocking} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <div tabIndex="0" className="e-card" id="main_card">
                                    <div className="e-card-content">
                                        {/* <div className='row'></div> 
                                        <div className='row'></div>
                                        <div className='row'></div>   */}
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <GridComponent id="GridModelliEmail" dataSource={this.state.dsCat} ref={grid => this.gridInstance = grid} allowSelection={true} enableHover={true} allowTextWrap={true}
                                                    allowPaging={true} allowSorting={false} allowFiltering={true} enableAltRow={true} enablePersistence={true} childGrid={this.childGrid} hierarchyPrintMode='All' actionBegin={this.onActionBegin.bind(this)}
                                                    actionComplete={this.onActionComplete.bind(this)} toolbarClick={this.toolbarClick.bind(this)} rowDataBound={this.onRowDatabound} pageSettings={this.pageSettings} filterSettings={this.filterSettings}
                                                    toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px' }} commandClick={this.onCommandClick.bind(this)}>
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='id_email' headerText='' isPrimaryKey={true} visible={false}></ColumnDirective>
                                                        <ColumnDirective field='descr_tipo_mess' headerText="Tipo Mess." width='6%' validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate} editType='DropDownEdit' edit={this.editTemplate1}></ColumnDirective>
                                                        <ColumnDirective field='descr_tipo_dest' headerText="Destinatario" width='8%' validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate} editType='DropDownEdit' edit={this.editTemplate2}></ColumnDirective>
                                                        <ColumnDirective field='titolo_email' headerText="Titolo" width='15%' validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='oggetto' headerText="Oggetto" width='20%' validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='testo' headerText="Testo" width='45%' validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate} clipMode="EllipsisWithTooltip" edit={this.editTemplate4}></ColumnDirective>
                                                        <ColumnDirective field='tutti_stati' headerText="Tutti Stati" width='7%' displayAsCheckBox={true} editType='booleanedit' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='5%' commands={this.commands} textAlign='Right' allowFiltering={false}></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, DetailRow, Page]} />
                                                </GridComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        // document.getElementById('titoloPagina').innerText = 'Modelli Email Manuali';
        this.caricaDati();
    }
    caricaDati() {
        this.toggleBlocking();
        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '01006', retData => {
            let GridData1 = _.uniq(retData, elm => elm.id_email);
            let arrTemp = _.filter(retData, (elm) => { return !CustomJS.isNullEmpty(elm.id_stato) });
            let GridData2 = _.map(arrTemp, (elm) => { return { id_email: elm.id_email, id_email_stato: elm.id_email_stato, id_stato: elm.id_stato, descr_stato: elm.descr_stato } });
            this.gridInstance.dataSource = GridData1;
            this.gridInstance.childGrid.dataSource = GridData2;
            this.gridInstance.refresh();
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
            this.toggleBlocking();
        });
    }
}