/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, DetailRow, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { TextBox } from '@syncfusion/ej2-inputs';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
import RegioniProvince1 from '../assets/RegioniProvince1.json';


function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
var fields = [];
let elemDdl = null;
export default class PaginaBroker extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.loaderType = ['Ball-Triangle', 'Bars', 'Circles', 'Grid', 'Puff', 'Rings', 'TailSpin', 'ThreeDots', 'Oval', 'MutatingDots', 'RevolvingDot'];
        this.toolbarOptions = ['Add', 'Edit', 'Delete','Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog'};
        this.pageSettings = {pageSize : 15, pageCount : 15};
        this.filterSettings = { type: 'CheckBox'};
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                         { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" }}];
        this.DdlObj = null;
        this.elemSelezProvincia = null;
        this.txtObj1 = null;
        this.editTemplate = { create: () => { elemDdl = document.createElement('input'); return elemDdl},
                              read: () => {return this.DdlObj.text},
                              destroy: () => {this.DdlObj.destroy()},
                              write: (args) => {this.elemSelezProvincia = args.rowData.provincia; this.DdlObj = new DropDownList({fields:{text: 'TEXT' , value: 'VALUE'}, placeholder:'Provincia', popupHeight:'200px', 
                                                dataSource: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaProvince1'))), value: args.rowData.provincia, allowFiltering: true, filterType: 'Contains', floatLabelType: 'Auto', 
                              change: (args2) => {args.rowData.provincia = args2.value; this.elemSelezProvincia = args2.value;} }); this.DdlObj.appendTo(elemDdl)}};
        this.editTemplate2 = { create: () => { let elemTXT = document.createElement('input'); return elemTXT },
                              read: () => {return this.txtObj1.value},
                              destroy: () => {this.txtObj1.destroy()},
                              write: (args) => { this.txtObj1 = new TextBox({ value: args.rowData.note_gen_broker_coll, floatLabelType: 'Auto', placeholder: 'Note', multiline: true,
                                                  htmlAttributes: {rows: 5} }); this.txtObj1.appendTo(args.element) } };  
        this.childGrid = {
            queryString: 'id_broker',
            editSettings: this.editSettings,
            allowPaging: true,
            allowSelection: true,
            enableHover: true,
            showDeleteConfirmDialog: true,
            allowTextWrap:true,
            allowSorting:false, 
            allowFiltering:false,
            enableAltRow:true, 
            enablePersistence:false,
            actionComplete: this.actionComplete2.bind(this),
            pageSettings: { pageSize: 15, pageCount: 15 },
            toolbar: [ 'Add', 'Edit', 'Delete','Update', 'Cancel', 'Search', 'ExcelExport' ],
            columns: [
                { field: 'id_broker_coll', headerText: 'ID Broker Collaboratore ', isPrimaryKey: true, textAlign: 'Center', visible: false },
                { field: 'id_broker', headerText: 'ID Broker ', textAlign: 'Center', visible: false, allowEditing: false},
                { field: 'nominativo_broker_coll', headerText: 'Nominativo ', textAlign: 'Center', headerTemplate: headerTemplate , validationRules: {required: true}},
                { field: 'telefono1_broker_coll', headerText: 'Telefono', textAlign: 'Center', headerTemplate: headerTemplate },
                { field: 'telefono2_broker_coll', headerText: 'Telefono2', textAlign: 'Center', visible: false, headerTemplate: headerTemplate },
                { field: 'email1_broker_coll', headerText: 'Email', textAlign: 'Center',  headerTemplate: headerTemplate },
                { field: 'email2_broker_coll', headerText: 'Email2', textAlign: 'Center', visible: false, headerTemplate: headerTemplate },
                { field: 'note_gen_broker_coll', headerText: 'Note', textAlign: 'Center', headerTemplate: headerTemplate, edit: this.editTemplate2 },
                { field: 'Comandi', headerText:"", commands: this.commands, textAlign:'Right', headerTemplate: headerTemplate },
            ], 
            actionBegin: this.actionBegin2
        };
    }
    state = {
        blocking: false,
        dsBroker: [],
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    onRowDatabound(args) {       
    }
    actionBegin2(args){
        if(args.requestType === 'add'){
            args.data.id_broker = this.parentDetails.parentRowData.id_broker;
        }
        if (args.requestType === "beginEdit" || args.requestType === "add") { 
            for (var i = 0; i < this.columns.length; i++) { 
                if (!this.columns[i].visible) { 
                    fields.push(this.columns[i].field); 
                    this.columns[i].visible = true; 
                } 
            } 
        } 
        if (args.requestType === "save" || args.requestType === "cancel") { 
            for (var i = 0; i < this.columns.length; i++) { 
                for (var j = 0; j < fields.length; j++) { 
                    if (this.columns[i].field === fields[j]) { 
                        this.columns[i].visible = false; 
                    } 
                } 
            } 
        }
    }
    actionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) { 
            args.dialog.width = '60%';
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuovo Broker' : `Dettaglio di ${args.rowData.ragione_sociale}`;
        }
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '00008';
            const dataObj = {
                IdBroker: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_broker : args.data[0].id_broker,
                RagioneSociale: CustomJS.isNullEmpty(args.data[0]) ? args.data.ragione_sociale : args.data[0].ragione_sociale,
                Indirizzo: CustomJS.isNullEmpty(args.data[0]) ? args.data.indirizzo : args.data[0].indirizzo,
                Citta: CustomJS.isNullEmpty(args.data[0]) ? args.data.citta : args.data[0].citta,
                Provincia: args.rowData?.provincia,
                CAP: CustomJS.isNullEmpty(args.data[0]) ? args.data.cap : args.data[0].cap,
                PIVA: CustomJS.isNullEmpty(args.data[0]) ? args.data.p_iva : args.data[0].p_iva,
                Telefono1: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono1 : args.data[0].telefono1,
                Telefono2: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono2 : args.data[0].telefono2,
                Email1: CustomJS.isNullEmpty(args.data[0]) ? args.data.email1 : args.data[0].email1,
                Email2: CustomJS.isNullEmpty(args.data[0]) ? args.data.email2 : args.data[0].email2,
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });                       
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
            if(args.action === "add")
            setTimeout(() => {
                this.caricaDati();
            }, 200);  
        }
    }
    actionComplete2(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) { 
            args.dialog.width = '60%';
            args.dialog.header = args.requestType === 'add' ? 'Inserimento nuovo Broker' : `Dettaglio di ${args.rowData.nominativo_broker_coll}`;
        }
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            debugger;
            const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '000081';
            const dataObj = {
                IdBroker: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_broker : args.data[0].id_broker,
                IdBrokerColl: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_broker_coll : args.data[0].id_broker_coll,
                NominativoBrokerColl: CustomJS.isNullEmpty(args.data[0]) ? args.data.nominativo_broker_coll : args.data[0].nominativo_broker_coll,
                Telefono1BrokerColl: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono1_broker_coll : args.data[0].telefono1_broker_coll,
                Telefono2BrokerColl: CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono2_broker_coll : args.data[0].telefono2_broker_coll,
                Email1BrokerColl: CustomJS.isNullEmpty(args.data[0]) ? args.data.email1_broker_coll : args.data[0].email1_broker_coll,
                Email2BrokerColl: CustomJS.isNullEmpty(args.data[0]) ? args.data.email2_broker_coll : args.data[0].email2_broker_coll,
                NoteGenBrokerColl: CustomJS.isNullEmpty(args.data[0]) ? args.data.note_gen_broker_coll : args.data[0].note_gen_broker_coll,
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });  
                        if(args.action === "add")
                        setTimeout(() => {
                            this.caricaDati();
                        }, 200);   
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }

    render() {
        return(<React.Fragment>  
            <BlockUi id='blockUIPaginaBroker' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                        {/* <div className='row'></div> 
                                        <div className='row'></div>
                                        <div className='row'></div>   */}
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12"> 
                                                <GridComponent id="GridBroker" dataSource={this.state.dsBroker} ref={ grid => this.gridInstance = grid } allowSelection={true} enableHover={true} allowTextWrap={true}
                                                    allowPaging={true} allowSorting={false} allowFiltering={true} enableAltRow={true} enablePersistence={true} childGrid={this.childGrid} filterSettings={this.filterSettings}
                                                    actionComplete={this.actionComplete.bind(this)} toolbarClick={this.toolbarClick.bind(this)} rowDataBound={this.onRowDatabound} pageSettings={this.pageSettings} 
                                                    toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px'}} >
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='id_broker' headerText='ID Broker' isPrimaryKey='true'  visible={false} width='25%' textAlign='Center'headerTemplate={headerTemplate} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='ragione_sociale' headerText="Ragione sociale"  width='30%' validationRules={{ required: true }} headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='indirizzo' headerText="Indirizzo"  width='25%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='citta' headerText="Città"  width='18%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='descr_provincia' headerText="Provincia"  width='20%'  textAlign='Center'headerTemplate={headerTemplate} edit={this.editTemplate}></ColumnDirective>
                                                        <ColumnDirective field='cap' headerText="CAP"  width='15%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='telefono1' headerText="Telefono1"  width='20%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='telefono2' headerText="Telefono2"  width='20%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='p_iva' headerText="P.IVA"  width='22%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='email1' headerText="Email1"  width='25%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='email2' headerText="Email2"  width='25%' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='20%' commands={this.commands} textAlign='Right'></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, DetailRow, Page]} />
                                                </GridComponent>
                                            </div>
                                        </div>                        
                                    </div>                              
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount(){
        // if (CustomJS.isNullEmpty(this.props.ArrivaDaDettSinistro))
        //     document.getElementById('titoloPagina').innerText = 'Broker';
        this.caricaDati();
    }
    caricaDati() {
        this.toggleBlocking();
        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '00008', retData => {
            let GridData1 = _.uniq(retData, elm => elm.id_broker);
            let arrTemp = _.filter(retData, (elm) => {return !CustomJS.isNullEmpty(elm.id_broker)});
            let GridData2 = _.map(arrTemp, (elm) => { return {id_broker_coll: elm.id_broker_coll, id_broker: elm.id_broker, nominativo_broker_coll: elm.nominativo_broker_coll, telefono1_broker_coll: elm.telefono1_broker_coll, email1_broker_coll: elm.email1_broker_coll, note_gen_broker_coll: elm.note_gen_broker_coll}});
            GridData1.forEach(elm => {
                let indOK = 0;
                let objReg = _.find(RegioniProvince1.regioni, elm2 => { return !CustomJS.isNullEmpty(_.find(elm2.province, (elm3,idx) => { indOK = elm3 === elm.provincia ? idx : 0;  return elm3 === elm.provincia; })) })
                elm.descr_provincia = objReg?.capoluoghi[indOK];
            })
            this.gridInstance.dataSource= GridData1;
            this.gridInstance.childGrid.dataSource = GridData2;
            this.gridInstance.refresh();   
            this.toggleBlocking();
            if (!CustomJS.isNullEmpty(this.props.DescrBroker))
                this.gridInstance.search(this.props.DescrBroker);            
            else
                this.gridInstance.search();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            ); 
        });
    }
}