import React, { useEffect, useState } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer'
import * as CustomJS from '../Custom';


export default function IdleTimeout() {
    const promptBeforeIdle = 1000 * 60; //tempo popup fine sessione
    // const timeout = 1000 * 30 - promptBeforeIdle; //30 sec fase di test
    const timeout = CustomJS.timeout - promptBeforeIdle; // tempo di sessione

    const [open, setOpen] = useState(false)

    const [remaining, setRemaining] = useState(0)

    const onPrompt = () => {
        setRemaining(promptBeforeIdle)
        setTimeout(() => {
            setOpen(true);
        }, 500)
    }

    const onIdle = () => {
        setOpen(false)
        setRemaining(0)
        sessionStorage.setItem("JellyfishTechnologySessionTimeout", 'true');
        window.location.reload();
    }
    const onActive = () => {
        setOpen(false)
        setRemaining(0)
    }

    const { getRemainingTime, isPrompted, activate } = useIdleTimer({
        timeout,
        promptBeforeIdle,
        onPrompt,
        onIdle,
        onActive,
        timers: workerTimers,
        stopOnIdle: true,
        syncTimers: 200,
        crossTab: true
    })

    const handleStillHere = () => {
        setOpen(false)
        activate()
    }

    useEffect(() => {
        const interval = setInterval(() => {
            // console.log(getRemainingTime()) //fase di test
            document.getElementById('tempoRimanenteNav').innerHTML = tempoRimanenteScadenzaSessione(getRemainingTime());
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000))
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [getRemainingTime, isPrompted])

    return (
        <div tabIndex={-1} className="modal bs-example-modal-sm" role="dialog" aria-hidden="true" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', display: open ? 'block' : 'none' }}>
            <div style={{ marginTop: '20vh', }} className="modal-dialog modal-sm">
                <div id='modalSessione' className="modal-content">
                    <div className="modal-header"><h4>Sessione scaduta <i className="fa fa-lock"></i></h4></div>
                    <div className="modal-body"><i className="fa fa-question-circle"></i> Verrai scollegato tra {remaining} secondi</div>
                    <button id='sessionButton' onClick={handleStillHere}>Continua</button>
                </div>
            </div>
        </div>
    )
}

function tempoRimanenteScadenzaSessione(millisecondi) {
    var oraStr = "ore"
    let seconds = Math.floor(millisecondi / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;
    if (hours === 1)
        oraStr = "ora"
    // return "Tempo rimanente scadenza sessione : " + hours + " " + oraStr + " " + minutes + " minuti e " + seconds + " secondi" //fase di test
    return "Tempo rimanente scadenza sessione : " + hours + " " + oraStr + " " + minutes + " minuti"
}