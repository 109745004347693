/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { MenuComponent, SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { SampleBase } from './sampleBase';
import LogoJellyfish from '../assets/JFTecNavbar.png';
import GestioneSinistri from './PaginaGestioneSinistri';
import PaginaHome from './PaginaHome';
import PaginaLogin from './PaginaLogin';
import PaginaCompagnie from './PaginaCompagnie';
import PaginaDivisioni from './PaginaDivisioni';
import PaginaLiquidatori from './PaginaLiquidatori';
import PaginaAmministratori from './PaginaAmministratori';
import PaginaNuovaAssistenza from './PaginaNuovaAssistenza';
import PaginaSituazioneAssistenza from './PaginaSituazioneAssistenza';
import PaginaAree from './PaginaAree';
import PaginaTipiDanni from './PaginaTipiDanni';
import PaginaAgenzie from './PaginaAgenzie';
import PaginaCAT from './PaginaCAT';
import PaginaBroker from './PaginaBroker';
import PaginaTipoIndennizzo from './PaginaTipoIndennizzo';
import PaginaDannoAccertato from './PaginaDannoAccertato';
import PaginaBeni from './PaginaBeni';
import PaginaTipiPolizza from './PaginaTipiPolizze';
import PaginaResetSinistri from './PaginaResetSinistri';
import PaginaUtenti from './PaginaUtenti';
import PaginaGruppiUtenti from './PaginaGruppiUtenti';
import PaginaGeoreferenziazione from './PaginaGeoreferenziazione';
import PaginaModelliEmail from './PaginaModelliEmail';
import PaginaModelliEmailStati from './PaginaModelliEmailStati';
import PaginaCompagnieDivisioniAgenzie from './PaginaCompagnieDivisioniAgenzie';
import PaginaStatiSinistro from './PaginaStatiSinistro';
import PaginaEmissioniCompetenze from './PaginaEmissioniCompetenze';
import PaginaAssegnazioneMultiplaPeriti from './PaginaAssegnazioneMultiplaPeriti';
import LogoDigitalPraesidium from '../assets/LogoDigitalPraesidium.png';
import * as CustomJS from '../Custom';
import Cookies from 'universal-cookie';
import $ from 'jquery';
import _ from 'underscore/underscore-min';
import { random, isUndefined, times } from 'underscore';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import TurndownService from 'turndown';

const cookies = new Cookies();
export class NavMenu extends SampleBase {
  constructor(props) {
    super(...arguments);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.fields = { tooltip: 'text' };
    this.menuItemsAdm = [
      { id: "id1", text: 'Pagina Principale', iconCss: "fas fa-home", pagina: "Home" },
      { id: "id2", text: 'Gestione Sinistri', iconCss: "fas fa-file-contract", pagina: "GestioneSinistri" },
      { id: "id3", text: 'Statistiche', iconCss: "fas fa-chart-pie", pagina: "Statistiche" },
      { id: "id6", text: 'Monitor ACT', iconCss: "fas fa-tv", pagina: "MonitorACT" },
      {
        id: "id4", text: 'Operazioni Generali', iconCss: "fas fa-tools", items: [{
          id: 'id4_1', text: 'Amministrazione', items: [
            { id: 'id4_1_1', text: 'Fatturazione', pagina: 'Fatturazione' },
            { id: 'id4_1_2', text: 'Reset Sinistri', pagina: 'ResetSinistri' }, 
            { id: 'id4_1_3', text: 'Assegnazione multipla sinistri a periti', pagina: 'AssegnazioneMultiplaPeriti' }
          ]
        },
        {
          id: 'id4_2', text: 'Anagrafiche', items: [{ id: 'id4_2_1', text: 'Compagnie-Divisioni-Agenzie', pagina: 'CompagnieDivisioniAgenzie' },
          { id: 'id4_2_2', text: 'Agenzie', pagina: 'Agenzie' },
          { id: 'id4_2_3', text: 'Compagnie', pagina: 'Compagnie' },
          { id: 'id4_2_4', text: 'Divisioni', pagina: 'Divisioni' },
          { id: 'id4_2_5', text: 'CAT', pagina: 'CAT' },
          { id: 'id4_2_6', text: 'Liquidatori', pagina: 'Liquidatori' },
          { id: 'id4_2_7', text: 'Aree', pagina: 'Aree' },
          { id: 'id4_2_8', text: 'Tipi Polizza', pagina: 'TipiPolizza' },
          { id: 'id4_2_9', text: 'Broker', pagina: 'Broker' },
          { id: 'id4_2_10', text: 'Tipi Danno', pagina: 'TipiDanni' },
          { id: 'id4_2_11', text: 'Beni/Altri Beni', pagina: 'Beni' },
          { id: 'id4_2_12', text: 'Danno Accertato', pagina: 'DannoAccertato' },
          { id: 'id4_2_13', text: 'Tipi Indennizzo', pagina: 'TipoIndennizzo' }]
        },
        {
          id: 'id4_3', text: 'Impostazioni', items: [{ id: 'id4_3_1', text: 'Stati Sinistro', pagina: 'StatiSinistro' },
          { id: 'id4_3_2', text: 'Modelli Email/SMS Manuali', pagina: 'ModelliEmailManuali' },
          { id: 'id4_3_3', text: 'Modelli Email/SMS Automatiche', pagina: 'ModelliEmailAutomatiche' },
          { id: 'id4_3_4', text: 'Gruppi Utenti', pagina: 'GruppiUtenti' },
          { id: 'id4_3_5', text: 'Utenti', pagina: 'Utenti' },
          { id: 'id4_3_6', text: 'Georeferenziazione', pagina: 'Georeferenziazione' }]
        },
        { id: 'id4_4', text: 'Emissioni Competenze', pagina: 'EmissioniCompetenze' }]
      },
      {
        id: "id8", text: 'Link Utili', iconCss: "fas fa-globe", items:
            [
                { id: 'id8_1', text: 'CESI', pagina: "CESI" },
                { id: 'id8_2', text: 'METEOCAST', pagina: "METEOCAST" },
                { id: 'id8_3', text: 'GFK', pagina: "GFK" },
                { id: 'id8_4', text: 'DAFTLOGIC', pagina: "DAFTLOGIC" },
                { id: 'id8_5', text: 'SIGFOT', pagina: "SIGFOT" },
                { id: 'id8_6', text: 'ChatGPT', pagina: "CHATGPT" }
            ]
      },
      { id: "id7", text: 'Richiesta Assistenza', iconCss: "fas fa-headset", items: [
        { id: 'id7_1', text: 'Situazione Richieste Assistenza', pagina: 'SituazioneRichiesteAssistenza' }, 
        { id: 'id7_2', text: 'Nuova Richiesta Assistenza', pagina: 'NuovaRichiestaAssistenza' }] },
      { id: "id5", text: 'Uscita', iconCss: "fas fa-sign-out-alt", pagina: 'Login' },
    ];
    this.menuItemsSegreteria = [
      { id: "id1", text: 'Pagina Principale', iconCss: "fas fa-home", pagina: "Home" },
      { id: "id2", text: 'Gestione Sinistri', iconCss: "fas fa-file-contract", pagina: "GestioneSinistri" },
      { id: "id3", text: 'Statistiche', iconCss: "fas fa-chart-pie", pagina: "Statistiche" },
      { id: "id4_1", text: 'Operazioni generali', iconCss: "fas fa-tools", items: [{ id: 'id4_1_3', text: 'Assegnazione multipla sinistri a periti', pagina: 'AssegnazioneMultiplaPeriti' }] },
      { id: "id6", text: 'Monitor ACT', iconCss: "fas fa-tv", pagina: "MonitorACT" },
      {
        id: "id8", text: 'Link Utili', iconCss: "fas fa-globe", items:
            [
                { id: 'id8_1', text: 'CESI', pagina: "CESI" },
                { id: 'id8_2', text: 'METEOCAST', pagina: "METEOCAST" },
                { id: 'id8_3', text: 'GFK', pagina: "GFK" },
                { id: 'id8_4', text: 'DAFTLOGIC', pagina: "DAFTLOGIC" },
                { id: 'id8_5', text: 'SIGFOT', pagina: "SIGFOT" },
                { id: 'id8_6', text: 'ChatGPT', pagina: "CHATGPT" }
            ]
      },
      { id: "id7", text: 'Richiesta Assistenza', iconCss: "fas fa-headset", items: [
        { id: 'id7_1', text: 'Situazione Richieste Assistenza', pagina: 'SituazioneRichiesteAssistenza' }, 
        { id: 'id7_2', text: 'Nuova Richiesta Assistenza', pagina: 'NuovaRichiestaAssistenza' }] },
      { id: "id5", text: 'Uscita', iconCss: "fas fa-sign-out-alt", pagina: 'Login' },
    ];
    this.menuItemsPeritiAvanzati = [
      { id: "id1", text: 'Pagina Principale', iconCss: "fas fa-home", pagina: "Home" },
      { id: "id2", text: 'Gestione Sinistri', iconCss: "fas fa-file-contract", pagina: "GestioneSinistri" },
      { id: "id3", text: 'Statistiche', iconCss: "fas fa-chart-pie", pagina: "Statistiche" },
      { id: "id6", text: 'Monitor ACT', iconCss: "fas fa-tv", pagina: "MonitorACT" },
      { id: "id4", text: 'Operazioni generali', iconCss: "fas fa-tools", items: [{ id: 'id4_4', text: 'Emissioni Competenze', pagina: 'EmissioniCompetenze' }] },
      {
        id: "id8", text: 'Link Utili', iconCss: "fas fa-globe", items:
            [
                { id: 'id8_1', text: 'CESI', pagina: "CESI" },
                { id: 'id8_2', text: 'METEOCAST', pagina: "METEOCAST" },
                { id: 'id8_3', text: 'GFK', pagina: "GFK" },
                { id: 'id8_4', text: 'DAFTLOGIC', pagina: "DAFTLOGIC" },
                { id: 'id8_5', text: 'SIGFOT', pagina: "SIGFOT" },
                { id: 'id8_6', text: 'ChatGPT', pagina: "CHATGPT" }
            ]
      },
      { id: "id7", text: 'Richiesta Assistenza', iconCss: "fas fa-headset", items: [
        { id: 'id7_1', text: 'Situazione Richieste Assistenza', pagina: 'SituazioneRichiesteAssistenza' }, 
        { id: 'id7_2', text: 'Nuova Richiesta Assistenza', pagina: 'NuovaRichiestaAssistenza' }] },
      { id: "id5", text: 'Uscita', iconCss: "fas fa-sign-out-alt", pagina: 'Login' }
    ];
    this.menuItemsPeritiSemplici = [
      { id: "id1", text: 'Pagina Principale', iconCss: "fas fa-home", pagina: "Home" },
      { id: "id2", text: 'Gestione Sinistri', iconCss: "fas fa-file-contract", pagina: "GestioneSinistri" },
      { id: "id3", text: 'Statistiche', iconCss: "fas fa-chart-pie", pagina: "Statistiche" },
      { id: "id6", text: 'Monitor ACT', iconCss: "fas fa-tv", pagina: "MonitorACT" },
      { id: "id4", text: 'Operazioni generali', iconCss: "fas fa-tools", items: [{ id: 'id4_4', text: 'Emissioni Competenze', pagina: 'EmissioniCompetenze' }] },
      {
        id: "id8", text: 'Link Utili', iconCss: "fas fa-globe", items:
            [
                { id: 'id8_1', text: 'CESI', pagina: "CESI" },
                { id: 'id8_2', text: 'METEOCAST', pagina: "METEOCAST" },
                { id: 'id8_3', text: 'GFK', pagina: "GFK" },
                { id: 'id8_4', text: 'DAFTLOGIC', pagina: "DAFTLOGIC" },
                { id: 'id8_5', text: 'SIGFOT', pagina: "SIGFOT" },
                { id: 'id8_6', text: 'ChatGPT', pagina: "CHATGPT" }
            ]
      },
      { id: "id7", text: 'Richiesta Assistenza', iconCss: "fas fa-headset", items: [
        { id: 'id7_1', text: 'Situazione Richieste Assistenza', pagina: 'SituazioneRichiesteAssistenza' }, 
        { id: 'id7_2', text: 'Nuova Richiesta Assistenza', pagina: 'NuovaRichiestaAssistenza' }] },
      { id: "id5", text: 'Uscita', iconCss: "fas fa-sign-out-alt", pagina: 'Login' }
    ];
    this.menuItemsReferentiACE = [
      { id: "id1", text: 'Pagina Principale', iconCss: "fas fa-home", pagina: "Home" },
      { id: "id2", text: 'Gestione Sinistri', iconCss: "fas fa-file-contract", pagina: "GestioneSinistri" },
      { id: "id3", text: 'Statistiche', iconCss: "fas fa-chart-pie", pagina: "Statistiche" },
      { id: "id6", text: 'Monitor ACT', iconCss: "fas fa-tv", pagina: "MonitorACT" },
      {
        id: "id8", text: 'Link Utili', iconCss: "fas fa-globe", items:
            [
                { id: 'id8_1', text: 'CESI', pagina: "CESI" },
                { id: 'id8_2', text: 'METEOCAST', pagina: "METEOCAST" },
                { id: 'id8_3', text: 'GFK', pagina: "GFK" },
                { id: 'id8_4', text: 'DAFTLOGIC', pagina: "DAFTLOGIC" },
                { id: 'id8_5', text: 'SIGFOT', pagina: "SIGFOT" },
                { id: 'id8_6', text: 'ChatGPT', pagina: "CHATGPT" }
            ]
      },
      { id: "id7", text: 'Richiesta Assistenza', iconCss: "fas fa-headset", pagina: 'NuovaRichiestaAssistenza' },
      { id: "id5", text: 'Uscita', iconCss: "fas fa-sign-out-alt", pagina: 'Login' }
    ];
    this.menuItemsAgenzia = [
      { id: "id5", text: 'Uscita', iconCss: "fas fa-sign-out-alt", pagina: 'Login' }
    ];
   this.itemsNavUtente = [
      { id: "idLog1", text: 'Profilo', iconCss: "fa fa-user", pagina: 'Profilo' },
      { id: "idLog2", text: 'Richiesta Assistenza', iconCss: "fas fa-headset", pagina: 'NuovaRichiestaAssistenza' },
      { id: "idLog3", text: 'Uscita', iconCss: "fas fa-sign-out-alt", pagina: 'Login' }
    ];     
    this.menuItemsUtente = [];
    this.childKey = 0;
    this.rootContainer = null;
    this.container = null;
    this.turndownService = new TurndownService();
  }
  state = {
    collapsed: true,
    showDialog1: false
  }
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  toggleBlocking2(show) {
    let container, root;
    if (show) {
        document.getElementById('blockUIGenerico').style.removeProperty('display');
        document.getElementById('spinnerUIGenerico').style.removeProperty('display');
        container = document.getElementById('spinnerUIGenerico');
        root = createRoot(container);
        root.render(CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]);
    }
    else {
        container = document.getElementById('spinnerUIGenerico');
        root = createRoot(container);
        root.unmount();
        document.getElementById('blockUIGenerico').style.display = 'none';
        document.getElementById('spinnerUIGenerico').style.display = 'none';
    }
  }
  cambiaLinguaApplicativo(lingua) {
  }
  openClick() {
    document.getElementById('sidebar-menu').ej2_instances[0].toggle();
  }
  onSelectMenu(args) {
    if (CustomJS.isNullEmpty(args.item.pagina)) return;
    document.getElementById('sidebar-menu').ej2_instances[0].hide();
    let componente, divContainer = '';
    ++this.childKey;
    switch (args.item.pagina) {
      case 'Home':
        componente = <PaginaHome key={this.childKey}></PaginaHome>;
        divContainer = 'contenutoDinamico2';
        break;
      case 'GestioneSinistri':
        componente = <GestioneSinistri key={this.childKey}></GestioneSinistri>;
        divContainer = 'contenutoDinamico2';
        break;
      case 'Compagnie':
        componente = <PaginaCompagnie key={this.childKey}></PaginaCompagnie>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Compagnie');
        return;
        break;
      case 'Divisioni':
        componente = <PaginaDivisioni key={this.childKey}></PaginaDivisioni>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Divisioni');
        return;
        break;
      case 'Liquidatori':
        componente = <PaginaLiquidatori key={this.childKey}></PaginaLiquidatori>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Liquidatori');
        return;
        break;
      case 'Aree':
        componente = <PaginaAree key={this.childKey}></PaginaAree>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Aree');
        return;
        break;
      case 'TipiDanni':
        componente = <PaginaTipiDanni key={this.childKey}></PaginaTipiDanni>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Tipi Danno');
        return;
        break;
      case 'Agenzie':
        componente = <PaginaAgenzie key={this.childKey}></PaginaAgenzie>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Agenzie');
        return;
        break;
      case 'Broker':
        componente = <PaginaBroker key={this.childKey}></PaginaBroker>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Broker');
        return;
        break;
      case 'CAT':
        componente = <PaginaCAT key={this.childKey}></PaginaCAT>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'CAT');
        return;
        break;
      case 'TipoIndennizzo':
        componente = <PaginaTipoIndennizzo key={this.childKey}></PaginaTipoIndennizzo>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Tipi Indennizzo');
        return;
        break;
      case 'DannoAccertato':
        componente = <PaginaDannoAccertato key={this.childKey}></PaginaDannoAccertato>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Danni Accertati');
        return;
        break;
      case 'Beni':
        componente = <PaginaBeni key={this.childKey}></PaginaBeni>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Beni/Altri Beni');
        return;
        break;
      case 'TipiPolizza':
        componente = <PaginaTipiPolizza key={this.childKey}></PaginaTipiPolizza>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Tipi Polizza');
        return;
        break;
      case 'ResetSinistri':
        componente = <PaginaResetSinistri key={this.childKey}></PaginaResetSinistri>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Reset Sinistri');
        return;
        break;
      case 'AssegnazioneMultiplaPeriti':
          componente = <PaginaAssegnazioneMultiplaPeriti key={this.childKey}></PaginaAssegnazioneMultiplaPeriti>;
          divContainer = 'contenutoDinamico2';
          this.apriDialogFunzionalita(componente, 'Assegnazione multipla sinistri a periti');
          return;
          break;
      case 'Utenti':
        componente = <PaginaUtenti key={this.childKey}></PaginaUtenti>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Utenti');
        return;
        break;
      case 'GruppiUtenti':
        componente = <PaginaGruppiUtenti key={this.childKey}></PaginaGruppiUtenti>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Gruppi Utenti');
        return;
        break;
      case 'Georeferenziazione':
        componente = <PaginaGeoreferenziazione key={this.childKey}></PaginaGeoreferenziazione>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Georeferenziazione');
        return;
        break;
      case 'ModelliEmailManuali':
        componente = <PaginaModelliEmail key={this.childKey}></PaginaModelliEmail>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Modelli Email/SMS Manuali');
        return;
        break;
      case 'ModelliEmailAutomatiche':
        componente = <PaginaModelliEmailStati key={this.childKey}></PaginaModelliEmailStati>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Modelli Email/SMS Automatici');
        return;
        break;
      case 'CompagnieDivisioniAgenzie':
        componente = <PaginaCompagnieDivisioniAgenzie key={this.childKey}></PaginaCompagnieDivisioniAgenzie>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Compagnie-Divisioni-Agenzie');
        return;
        break;
      case 'StatiSinistro':
        componente = <PaginaStatiSinistro key={this.childKey}></PaginaStatiSinistro>;
        divContainer = 'contenutoDinamico2';
        this.apriDialogFunzionalita(componente, 'Stati Sinistro');
        return;
        break;
      case 'EmissioniCompetenze':
        componente = <PaginaEmissioniCompetenze key={this.childKey}></PaginaEmissioniCompetenze>;
        divContainer = 'contenutoDinamico2';
        break;
      case 'MonitorACT':
        cookies.set('act_monitor', 'true', { domain: '.jfish.it' });
        window.open(CustomJS.URLMonitorACTProd, '_blank');
        return;
      case 'NuovaRichiestaAssistenza':
          componente = <PaginaNuovaAssistenza key={this.childKey}></PaginaNuovaAssistenza>;
          divContainer = 'contenutoDinamico2';
          this.apriDialogFunzionalita(componente, 'Nuova Richiesta Assistenza');
          return;
          break;  
      case 'SituazioneRichiesteAssistenza':
            componente = <PaginaSituazioneAssistenza key={this.childKey} arrivaDa='NavMenu'></PaginaSituazioneAssistenza>;
            divContainer = 'contenutoDinamico2';
            this.apriDialogFunzionalita(componente, 'Situazione Richieste Assistenza');
            return;
            break;   
      case 'CESI':
            window.open(
                'https://services.meteorage.com/cats/index.php/catsweb/it/auth/meteorage',
                '_blank');
            return;
            break;
      case 'METEOCAST':
            window.open(
                'https://meteocast.radarmeteo.com/#/map/Archive',
                '_blank');
            return;
            break;
      case 'GFK':
            window.open(
                'https://claimmanager.gfk.com/Default.aspx',
                '_blank');
            return;
            break;
      case 'DAFTLOGIC':
            window.open(
                'https://www.daftlogic.com/projects-google-maps-area-calculator-tool.htm',
                '_blank');
            return;
            break;    
      case 'SIGFOT':
            window.open(
                'https://re.jrc.ec.europa.eu/pvg_tools/it/tools.html',
                '_blank');
            return;
            break;     
      case 'CHATGPT':
            window.open(
                'https://chatgpt.com',
                '_blank');
            return;
            break;  
      case 'Login':
        // sessionStorage.setItem("JellyfishTechnologySessionTimeout", 'false');
        sessionStorage.removeItem("JellyfishTechnologySessionTimeout");
        sessionStorage.removeItem('JellyfishTechnologyAuthenticationPayload');
        sessionStorage.removeItem('JellyfishTechnologyFiltroSituazioneSinistri');
        componente = <PaginaLogin key={this.childKey}></PaginaLogin>;
        divContainer = 'contenutoDinamico1';
        break; 
      default:
        alert('Funzionalità non ancora implementata!');
        return;
    }
    if (document.getElementById('divBtnFinePag'))
        document.getElementById('divBtnFinePag').style.display = 'none';
    if (document.getElementById('divBtnVediNotif'))
        document.getElementById('divBtnVediNotif').style.display = 'none';
    if (document.getElementById('defaultDialog'))
        document.getElementById('defaultDialog').ej2_instances[0]?.destroy();
    if (document.getElementById('defaultDialog1'))
        document.getElementById('defaultDialog1').ej2_instances[0]?.destroy();
    if (document.getElementById('defaultDialog2'))
        document.getElementById('defaultDialog2').ej2_instances[0]?.destroy();
    if (document.getElementById('defaultDialog3'))
        document.getElementById('defaultDialog3').ej2_instances[0]?.destroy();
    if (document.getElementById('defaultDialog4'))
        document.getElementById('defaultDialog4').ej2_instances[0]?.destroy();
    if (document.getElementById('defaultDialog5'))
        document.getElementById('defaultDialog5').ej2_instances[0]?.destroy();
    if (document.getElementById('defaultDialog6'))
        document.getElementById('defaultDialog6').ej2_instances[0]?.destroy();
    if (document.getElementById('defaultDialog7'))
        document.getElementById('defaultDialog7').ej2_instances[0]?.destroy();
    if (document.getElementById('defaultDialog8'))
        document.getElementById('defaultDialog8').ej2_instances[0]?.destroy();
    if (document.getElementById('defaultDialog9'))
        document.getElementById('defaultDialog9').ej2_instances[0]?.destroy();
    if (document.getElementById('defaultDialog10'))
        document.getElementById('defaultDialog10').ej2_instances[0]?.destroy();
    if (document.getElementById('defaultDialog11'))
        document.getElementById('defaultDialog11').ej2_instances[0]?.destroy();
    if (document.getElementById('defaultDialog12'))
        document.getElementById('defaultDialog12').ej2_instances[0]?.destroy();
    if (document.getElementById('defaultDialog13'))
        document.getElementById('defaultDialog13').ej2_instances[0]?.destroy();
    if (document.getElementById('defaultDialog14'))
        document.getElementById('defaultDialog14').ej2_instances[0]?.destroy();

    if (document.getElementById('defaultDialogFunzionalita').ej2_instances[0].visible === true)
        document.getElementById('defaultDialogFunzionalita').ej2_instances[0].hide();

    if (this.container?.isEqualNode(document.getElementById(divContainer)) || CustomJS.isNullEmpty(this.container)) {
        this.container = document.getElementById(divContainer);
        this.rootContainer = createRoot(this.container);  
    }
    this.rootContainer.render(<CallbackWrapper callback={() => {
      if (divContainer === 'contenutoDinamico1') {
          document.getElementById('contenutoDinamico1').style.removeProperty('display');
          document.getElementById('contenutoDinamico3').style.display = 'none';
          window.location.reload();
      }
    }}>{componente}</CallbackWrapper>);     
  }
  onSelectNavUtente(args) {
    if (CustomJS.isNullEmpty(args.item.pagina)) return;
    document.getElementById('sidebar-menu').ej2_instances[0].hide();
    let componente, divContainer = '';
    ++this.childKey;
    switch (args.item.pagina) {
        case 'NuovaRichiestaAssistenza':
            componente = <PaginaNuovaAssistenza key={this.childKey}></PaginaNuovaAssistenza>;
            divContainer = 'contenutoDinamico2';
            this.apriDialogFunzionalita(componente, 'Nuova Richiesta Assistenza');
            return;
            break;  
        case 'Login':
            // sessionStorage.setItem("JellyfishTechnologySessionTimeout", 'false');
            sessionStorage.removeItem("JellyfishTechnologySessionTimeout");
            sessionStorage.removeItem('JellyfishTechnologyAuthenticationPayload');
            sessionStorage.removeItem('JellyfishTechnologyFiltroSituazioneSinistri');
            componente = <PaginaLogin key={this.childKey}></PaginaLogin>;
            divContainer = 'contenutoDinamico1';
            break;
        default:
            alert('Funzionalità non ancora implementata!');
            return;
    }

    if (document.getElementById('defaultDialogFunzionalita').ej2_instances[0].visible === true)
      document.getElementById('defaultDialogFunzionalita').ej2_instances[0].hide();

    if (this.container?.isEqualNode(document.getElementById(divContainer)) || CustomJS.isNullEmpty(this.container)) {
      this.container = document.getElementById(divContainer);
      this.rootContainer = createRoot(this.container);  
    }

    this.rootContainer.render(<CallbackWrapper callback={() => {
      if (divContainer === 'contenutoDinamico1') {
          document.getElementById('contenutoDinamico1').style.removeProperty('display');
          document.getElementById('contenutoDinamico3').style.display = 'none';
          window.location.reload();
      }
    }}>{componente}</CallbackWrapper>); 
  }
  onCreatedSideBar(args) {
    this.element.style.visibility = "visible";
  }
  apriDialogFunzionalita(componente, titolo) {
    if (this.dialogInstance1.visible === true) {
      let container = document.getElementById('defaultDialogFunzionalitaContent');
      let root = createRoot(container); 
      root.unmount();
      if (document.getElementById('defaultDialog13'))
          document.getElementById('defaultDialog13').ej2_instances[0]?.destroy();
      if (document.getElementById('defaultDialog14'))
          document.getElementById('defaultDialog14').ej2_instances[0]?.destroy();
      setTimeout(() => {
        container = document.getElementById('defaultDialogFunzionalitaContent');
        root = createRoot(container);                         
        root.render(<CallbackWrapper callback={() => {
          this.dialogInstance1.header = `<div class='titoloPagina1'><span class='titoloPagina2'>${titolo}</span></div>`;    
          this.dialogInstance1.width = titolo === 'Nuova Richiesta Assistenza' ? '65%' : '95%';
          this.dialogInstance1.height = titolo === 'Nuova Richiesta Assistenza' ? '93%' : '93%';
          this.dialogInstance1.minHeight = titolo === 'Nuova Richiesta Assistenza' ? '93%' : '93%';
        }}>{componente}</CallbackWrapper>);
      }, 100);
    }
    else {
      if (document.getElementById('defaultDialog13'))
          document.getElementById('defaultDialog13').ej2_instances[0]?.destroy();
      if (document.getElementById('defaultDialog14'))
          document.getElementById('defaultDialog14').ej2_instances[0]?.destroy();
      this.dialogInstance1.header = `<div class='titoloPagina1'><span class='titoloPagina2'>${titolo}</span></div>`;    
      this.dialogInstance1.width = titolo === 'Nuova Richiesta Assistenza' ? '65%' : '95%';
      this.dialogInstance1.height = titolo === 'Nuova Richiesta Assistenza' ? '95%' : '93%';
      this.dialogInstance1.minHeight = titolo === 'Nuova Richiesta Assistenza' ? '95%' : '93%';
      this.dialogInstance1.buttons = titolo !== 'Nuova Richiesta Assistenza' ? [] :  
            [
              { click: () => { 
                  if (CustomJS.isNullEmpty(document.getElementById('mittenteRichiesta').ej2_instances[0].value?.trim())) {
                    document.getElementById('mittenteRichiesta').focus();
                    return;
                  }
                  if (CustomJS.isNullEmpty(document.getElementById('oggettoRichiesta').ej2_instances[0].value?.trim())) {
                      document.getElementById('oggettoRichiesta').focus();
                      return;
                  }
                  let priorita = $("input[type='radio'][name='priorita']:checked").val() === 'Altissima' ? 1 : 
                                  $("input[type='radio'][name='priorita']:checked").val() === 'Alta' ? 2 :
                                  $("input[type='radio'][name='priorita']:checked").val() === 'Normale' ? 3 :
                                  $("input[type='radio'][name='priorita']:checked").val() === 'Bassa' ? 4 : '';
                  let oggettoRichiesta = $("input[type='radio'][name='tipoRichiesta']:checked").val() === 'Errore' ? '[BUG] Anomalia su Jellyfish Technology : ' :
                                          $("input[type='radio'][name='tipoRichiesta']:checked").val() === 'ModificaNoEvo' ? '[MODIFICA] Modifica a Jellyfish Technology : ' :
                                          $("input[type='radio'][name='tipoRichiesta']:checked").val() === 'ModificaEvo' ? '[EVOLUTIVA] Modifica a Jellyfish Technology : ' : '';
                  oggettoRichiesta += document.getElementById('oggettoRichiesta').ej2_instances[0].value?.trim();
                  let descrRichiesta = document.getElementById('descrizioneRichiesta').ej2_instances[0].value?.trim(); //.replace(/<.*?>/g, '');
                  descrRichiesta += `<br/><br/><br/> Cordiali Saluti <br/><br/>&nbsp;&nbsp;&nbsp;<b>${document.getElementById('mittenteRichiesta').ej2_instances[0].value?.trim()}</b>`;
                  let oggettoBody = {
                    name: oggettoRichiesta,
                    description: descrRichiesta ?? '',
                    markdown_description: this.turndownService.turndown(descrRichiesta ?? ''),
                    assignees: [ 54104721 ],  //Stefano Giannotta
                    tags: [],
                    status: "IN ANALISI",
                    priority: priorita,
                    due_date: null,
                    due_datetime: false,
                    time_estimate: null,
                    start_date: null,
                    start_date_time: false,
                    notify_all: false,
                    parent: null,
                    links_to: null,
                    custom_fields: []
                  };
                  this.toggleBlocking2(true);
                  CustomJS.callAPI(`${CustomJS.APIClickUpURLBase1.replace('{0}', CustomJS.clickUPListID).replace('{1}', CustomJS.clickUPTeamID)}`, JSON.stringify(oggettoBody), 'POST',
                    'application/json', CustomJS.tokenAPIClickUP, retData => { 
                      document.getElementById('descrizioneRichiesta').querySelectorAll('.e-rte-image.e-imginline').forEach((elm,idx) => {
                        let blob = (/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/).test(elm.getAttribute('src')?.substring(elm.getAttribute('src')?.indexOf(',')+1)) ? CustomJS.b64toBlob(elm.getAttribute('src')?.substring(elm.getAttribute('src')?.indexOf(',')+1)) : (/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/).test(elm.getAttribute('src2')?.substring(elm.getAttribute('src2')?.indexOf(',')+1)) ? CustomJS.b64toBlob(elm.getAttribute('src2')?.substring(elm.getAttribute('src2')?.indexOf(',')+1)) : null;
                        let formData = new FormData();
                        let estensione = elm.getAttribute('src2')?.indexOf('application/pdf') > -1 ? '.pdf' : elm.getAttribute('src')?.indexOf('image/png') > -1 ? '.png' : elm.getAttribute('src')?.indexOf('image/jpeg') > -1 ? '.jpg'
                                          : elm.getAttribute('src')?.indexOf('image/webp') > -1 ? '.jpg' : elm.getAttribute('src')?.indexOf('image/jfif') > -1 ? '.jpg' 
                                          : elm.getAttribute('src2')?.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') > -1 ? '.docx'
                                          : elm.getAttribute('src2')?.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') > -1 ? '.xlsx' 
                                          : elm.getAttribute('src2')?.indexOf('application/msword') > -1 ? '.doc' : elm.getAttribute('src2')?.indexOf('application/msexcel') > -1 ? '.xls' 
                                          : elm.getAttribute('src2')?.indexOf('text/plain') > -1 ? '.txt' : elm.getAttribute('src2')?.indexOf('text/xml') > -1 ? '.xml' : elm.getAttribute('src2')?.indexOf('application/json') > -1 ? '.json'
                                          : elm.getAttribute('src2')?.indexOf('application/x-zip-compressed') > -1 ? '.zip' :  elm.getAttribute('src2')?.indexOf('application/x-compressed') > -1 ? '.rar' : '';
                        formData.append("attachment", blob, `Allegato${(idx+1).toString()}${estensione}`);
                        const query = new URLSearchParams({
                          custom_task_ids: 'true',
                          // team_id: CustomJS.clickUPTeamID
                        }).toString();

                        CustomJS.callAPI(`${CustomJS.APIClickUpURLBase2.replace('{0}', retData.id).replace('{1}', query)}`, formData, 'POST',
                          'multipart/form-data', CustomJS.tokenAPIClickUP, retData2 => {  
                            console.log(`Allegato caricato correttamente su ClickUp con la seguente risposta : ${retData2}`);
                            console.log(retData2);
                        }, error => {
                          let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                          toast.warn(messaggio, {
                              containerId: 'toastContainer1',
                              onClose: () => toast.dismiss(),                    
                              position: "bottom-right",
                              autoClose: 15000,
                              hideProgressBar: false,            
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: false,
                              progress: undefined,
                              newestOnTop: true,
                              rtl: false,
                              pauseOnFocusLoss: true});
                        }, true, false);          
                      });     
                      this.toggleBlocking2(false);
                      this.dialogInstance1.hide();
                      toast.success('Richiesta di assistenza inviata con successo!!! Il riscontro verrà fornito nel più breve tempo possibile. Grazie', {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),                    
                        position: "bottom-right",
                        autoClose: 7000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true});
                      const paramEmailSistema = 'mail.gruppoallconsulting.com;465;jellyfish@gruppoallconsulting.com;r%9W#4wpFdRt;True;Jellyfish Technology;info@actsrl.it';
                      let valoriSplit = paramEmailSistema.split(';');
                      let dataObj = {
                        server:valoriSplit[0],
                        porta:valoriSplit[1],
                        utente:valoriSplit[2],
                        password:valoriSplit[3],
                        SSL:valoriSplit[4],
                        mittente: valoriSplit[5],
                        destinatario: 'stefano.giannotta@digitalpraesidium.it',
                        emailMittente:valoriSplit[6],
                        emailCC: '',
                        emailBCC: '',
                        soggetto: 'ATTENZIONE : Nuova Richiesta Assistenza su Jellyfish Technology (Click Up)',
                        messaggioHtml: `Oggetto Richiesta: ${oggettoRichiesta}<br/><br/><br/>Descrizione Richiesta: ${document.getElementById('descrizioneRichiesta').ej2_instances[0].value?.trim()}`
                      };
                      CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/sendEmail`, JSON.stringify(dataObj), 'POST',
                        'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => {
                            if (retData.response === 'KO')
                                toast.error(retData.msg, {
                                  containerId: 'toastContainer1',
                                  onClose: () => toast.dismiss(),                    
                                  position: "bottom-right",
                                  autoClose: 15000,
                                  hideProgressBar: false,            
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: false,
                                  progress: undefined,
                                  newestOnTop: true,
                                  rtl: false,
                                  pauseOnFocusLoss: true});
                            else                            
                                toast.success('Email di notifica al supporto Jellyfish Technology inviata con successo!!!', {
                                  containerId: 'toastContainer1',
                                  onClose: () => toast.dismiss(),                    
                                  position: "bottom-right",
                                  autoClose: 7000,
                                  hideProgressBar: false,            
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: false,
                                  progress: undefined,
                                  newestOnTop: true,
                                  rtl: false,
                                  pauseOnFocusLoss: true});
                      }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                          containerId: 'toastContainer1',
                          onClose: () => toast.dismiss(),                    
                          position: "bottom-right",
                          autoClose: 15000,
                          hideProgressBar: false,            
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: false,
                          progress: undefined,
                          newestOnTop: true,
                          rtl: false,
                          pauseOnFocusLoss: true});
                      }, true, false);
                  }, error => {
                      let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                      toast.warn(messaggio, {
                          containerId: 'toastContainer1',
                          onClose: () => toast.dismiss(),                    
                          position: "bottom-right",
                          autoClose: 15000,
                          hideProgressBar: false,            
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: false,
                          progress: undefined,
                          newestOnTop: true,
                          rtl: false,
                          pauseOnFocusLoss: true});
                  }, true, false);  
                }, 
                buttonModel: { 
                    content: 'INVIA RICHIESTA', 
                    cssClass: 'e-primary',
                    isPrimary: true 
                }
              },
              {
                  click: () => {
                      this.dialogInstance1.hide();
                  },
                  buttonModel: {
                      content: 'ANNULLA',
                  }
              }
          ];  
      this.dialogInstance1.beforeOpen = (args) => {
          const container = document.getElementById('defaultDialogFunzionalitaContent');
          const root = createRoot(container); 
          root.unmount();
      };
      this.dialogInstance1.open = (args) => {                
          const container = document.getElementById('defaultDialogFunzionalitaContent');
          const root = createRoot(container);                         
          root.render(<CallbackWrapper callback={() => {
          }}>{componente}</CallbackWrapper>);
      };  
      this.dialogInstance1.close = (args) => {
        $(`[id=descrizioneRichiesta_toolbar_FontColor_Target]`).hide();
        $(`[id=descrizioneRichiesta_toolbar_BackgroundColor_Target]`).hide();      
      };        
      this.dialogInstance1.show();  
    }
  }

  render() {
    if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione?.indexOf('Amministratori') > -1
      || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione?.indexOf('SysAdmin') > -1) {
      this.menuItemsUtente = this.menuItemsAdm;
    }
    else if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione?.indexOf('Segreteria') > -1) {
             this.menuItemsUtente = this.menuItemsSegreteria;
    }
    else if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione?.indexOf('Periti Semplici') > -1) {
             this.menuItemsUtente = this.menuItemsPeritiSemplici;
    }
    else if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione?.indexOf('Team Leader') > -1) {
             this.menuItemsUtente = this.menuItemsPeritiAvanzati;
    }
    else if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione?.indexOf('Referenti ACE') > -1)
             this.menuItemsUtente = this.menuItemsReferentiACE;
    else if (!CustomJS.isNullEmpty(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].id_agenzia))
             this.menuItemsUtente = this.menuItemsAgenzia;
    return (
      <React.Fragment>
        <DialogComponent id="defaultDialogFunzionalita" showCloseIcon={true} target='body' closeOnEscape={true} animationSettings={this.animationSettings} visible={this.state.showDialog1}
            ref={dialogObj => this.dialogInstance1 = dialogObj} isModal={true} style={{ marginTop: '40px', marginLeft: '50px' }}>
            <div id='defaultDialogFunzionalitaContent'></div>
        </DialogComponent>
        <div className="header-section" id='header'>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 text-start">
                <span id="hamburger" className="fas fa-bars fa-2x icon list" style={{ width: '10px' }} onClick={this.openClick.bind(this)} ></span>
                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                <img id='logoJFTechNav' src={LogoJellyfish} height="auto" width="180vw" style={{ paddingBottom: '20px' }} />
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                <img id='logoDPNav' src={LogoDigitalPraesidium} height="auto" width="143vw" />
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-center">
                <span id='titoloPagina' style={{ fontSize: '1.8vw', fontWeight: 'bolder', color: '#133B95', fontFamily: 'Muli, sans-serif'}}></span>
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-center" id='boxUserLogout'>
                  <DropDownButtonComponent id="UserLogout" items={this.itemsNavUtente} select={this.onSelectNavUtente.bind(this)}>
                    <span id='nomeUtenteNav'>{CustomJS.isNullEmpty(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].id_agenzia) ? `${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].nome} ${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].cognome} (${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione})` : `${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].ragione_sociale}`}</span>
                    <span id="tempoRimanenteNav"></span>
                  </DropDownButtonComponent>
              </div>
            </div>
            <SidebarComponent dockSize="50px" enableDock="true" width="220px" id="sidebar-menu" type="Over" created={this.onCreatedSideBar} showBackdrop={true} closeOnDocumentClick={false}>
              <div className="title-header">
                <div className="main-menu">
                  <div>
                    <div id="listcontainer">
                      <MenuComponent id='main-menubar' orientation='Vertical' items={this.menuItemsUtente} cssClass='dock-menu' select={this.onSelectMenu.bind(this)}></MenuComponent>
                    </div>
                  </div>
                </div>
              </div>
            </SidebarComponent>
          </div>
        </div>
      </React.Fragment>
    );
  }
  componentDidMount() {
    if (!CustomJS.isNullEmpty(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].id_agenzia)) {
        document.getElementById('UserLogout').ej2_instances[0].items.splice(0, 2);          
        document.getElementById('UserLogout').ej2_instances[0].refresh();  
    }
  }
}