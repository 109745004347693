/* eslint-disable default-case */
/* eslint-disable no-extend-native */
/* eslint-disable jsx-a11y/iframe-has-title */
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { TabComponent, TabItemDirective, TabItemsDirective, TabAnimationSettings } from '@syncfusion/ej2-react-navigations';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page, ColumnChooser, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent, DateRangePickerComponent, DateTimePickerComponent, Inject as DTPInject, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { ButtonComponent, CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { SplitButtonComponent, ProgressButtonComponent, DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { DropDownButton } from '@syncfusion/ej2-splitbuttons';
import { TextBoxComponent, NumericTextBoxComponent, MaskedTextBox, UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { RichTextEditorComponent, RichTextEditor, Toolbar as RTEtoolbar, Inject as RTEInject, Image, Link, HtmlEditor, Count, QuickToolbar, Table } from '@syncfusion/ej2-react-richtexteditor';
import { KanbanComponent, ColumnsDirective as ColumnsDirectiveKanban, ColumnDirective as ColumnDirectiveKanban } from "@syncfusion/ej2-react-kanban";
import { SampleBase } from './sampleBase';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { FileManagerComponent, Inject as FMInject, NavigationPane, DetailsView, Toolbar as FMToolbar, ContextMenu } from '@syncfusion/ej2-react-filemanager';
import { PdfViewerComponent, Toolbar as PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner, Inject as PDFVInject } from '@syncfusion/ej2-react-pdfviewer';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { Browser, Internationalization } from '@syncfusion/ej2-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import $ from 'jquery';
import { random, isUndefined, times } from 'underscore';
import { DashboardModal } from '@uppy/react';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import ImageEditor from '@uppy/image-editor';
import Italiano from '@uppy/locales/lib/it_IT';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import ImmaginePDF from '../assets/Immagine2PDF.png';
import ImmagineDOC from '../assets/ImmagineDOC.png';
import ImmagineImgVarie from '../assets/ImmagineImgVarie.png';
import ImmagineEXCEL from '../assets/ImmagineEXCEL.png';
import ImmagineZIP from '../assets/ImmagineZIP.png';
import moment from 'moment';
import PaginaAgenzie from './PaginaAgenzie';
import PaginaLiquidatori from './PaginaLiquidatori';
import PaginaBroker from './PaginaBroker';
import PaginaTipiPolizze from './PaginaTipiPolizze';
import PaginaAmministratori from './PaginaAmministratori';
import PaginaPerizia from './PaginaPerizia';
import PaginaPeriziaOnline from './PaginaPeriziaOnline';
import PaginaPeriziaOnlineCalcoli from './PaginaPeriziaOnlineCalcoli';
import { MDBFooter } from 'mdb-react-ui-kit';
import { DatePicker } from '@syncfusion/ej2-calendars';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import ReactPlayer from 'react-player';
import ImageKO from '../assets/Delete.png';
import ImageOK from '../assets/GreenOK.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ElencoComuni from '../assets/ListaComuni.json';

RichTextEditor.Inject(RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table);

const MESI = { A: '01', B: '02', C: '03', D: '04', E: '05', H: '06', L: '07', M: '08', P: '09', R: '10', S: '11', T: '12' };

Array.prototype.getIndexBy = function (name, value) {
    for (var i = 0; i < this.length; i++) {
        if (this[i][name] === value) {
            return i;
        }
    }
    return -1;
}

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.74vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {
    let colore = props.column.field === 'id_interno_sinistro' ? '#133B95' : 'purple';  
    let renderDiv = <div><span style={{ fontSize: '0.85vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>;
    return (
        renderDiv
    );
}

function fieldTemplate2(props) {
    if (props.column.field === 'note_cat') {  
        let renderDiv = CustomJS.isNullEmpty(props.note_cat) ? <></> : props.note_cat?.split("<br").length > 10 || props.note_cat?.split("<p").length > 10 || (props.note_cat)?.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/ig, ' ').length > 250 ? props.note_cat?.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/ig, ' ').substring(0, 249) + "..." : props.note_cat?.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/ig, ' ');
        return (
            renderDiv
        );
    }   
}

function fieldTemplate3(props) {
    if (props.column.field === 'note_rfd') {  
        let renderDiv = CustomJS.isNullEmpty(props.note_rfd) ? <></> : props.note_rfd?.split("<br").length > 10 || props.note_rfd?.split("<p").length > 10 || (props.note_rfd)?.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/ig, ' ').length > 250 ? props.note_rfd?.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/ig, ' ').substring(0, 249) + "..." : props.note_rfd?.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/ig, ' ');
        return (
            renderDiv
        );
    }   
}

function fieldTemplate4(props) {
    let colore = props.column.field === 'Esito' && props[props.column.field].indexOf('scartato') > -1 ? 'red' : 'green';  
    let renderDiv = <div><span style={{ fontSize: '0.85vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>;
    return (
        renderDiv
    );
}

export default class GestioneSinistri extends SampleBase {
    constructor() {
        super(...arguments);               
        this.toggleBlocking = this.toggleBlocking.bind(this);  
        this.toggleVisibility = this.toggleVisibility.bind(this);
        this.tabHeaderText = [{ text: "SITUAZIONE SINISTRI" }];
        this.tabHeaderText2 = [{ text: "Dettaglio Sinistro" }];
        this.editSettings = { allowAdding: true, mode: 'Normal'};
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.itemsDropDownBtn = [{ text: 'Cartelle' }, { text: 'Files' }];
        this.dsFiltroSinistri = [{ TEXT: 'Sinistri Recenti', VALUE: 'Recenti'}, { TEXT: 'Tutti i Sinistri', VALUE: 'Tutti'}];
        this.toolbarOptions = ['Add', { text: 'Aggiorna Tabella', prefixIcon: "e-aggiornatabella", id: "aggiornatabella" }, 'ExcelExport', 
                               { template: this.ddlFiltroSinistriToolbar.bind(this) }, { template: this.btnOrdinaPerRitardo.bind(this) }, 
                               { template: this.ddlFiltroStatiToolbar.bind(this) }, { template: this.ddlFiltroPeritiToolbar.bind(this), id: 'filtroperito' }, 'Search']; 
        this.toolbarOptions2 = ['Search', 'ExcelExport'];
        this.commands = [{ type: "Vedi Note Sinistro", buttonOption: { iconCss: "fas fa-eye fa-10x", cssClass: "e-details" }},
                         { id: 'SollGiust', title: "Sollecito Documentazione", buttonOption: { content: 'SD', cssClass: "e-details iconaSollGiust" }},
                         { id: 'SollAtto', title: "Sollecito Atto", buttonOption: { content: 'SA', cssClass: "e-details iconaSollGiust" }},                         
                         { type: "Duplica Sinistro", buttonOption: { iconCss: "fas fa-clone fa-10x", cssClass: "e-details" }},
                         { type: "Vedi Notifiche Sinistro", buttonOption: { iconCss: "fas fa-comment-dots fa-10x", cssClass: "e-details" }},
                         { type: "Invia Accettazione Sinistro", buttonOption: { iconCss: "fas fa-paper-plane fa-10x", cssClass: "e-details" }},
                         { type: "Vedi Altri Sinistri Uguali", buttonOption: { iconCss: "fas fa-exclamation-triangle fa-10x", cssClass: "e-details" }}]; 
        this.commands2 = [{ type: "Importa Sinistro", buttonOption: { iconCss: "fas fa-download fa-10x", cssClass: "e-details" } }]; 
        this.pageSettings = { pageSize : 10, pageCount : 15, pageSizes: ["10", "50", "100", "200", "500", "1000"] };  
        this.pageSettings2 = { pageSize: 10, pageCount: 5, pageSizes: ["10", "50", "100", "200", "Tutti"] };
        this.filterSettings = { type: 'Excel'};  
        this.groupSettings = { showGroupedColumn: true };
        this.searchSettings = { fields: ['id_interno_sinistro', 'numero_sinistro_agenzia', 'danneggiato', 'contr_nominativo', 'ass_nominativo', 'numero_polizza'] };
        this.DtPick1 = null;
        this.DtPick2 = null;
        this.DtPick3 = null;
        this.DtPick4 = null;
        this.rteObj1 = null;
        this.rteObj2 = null;
        this.editTemplate1 = { create: () => { let elemDpick1 = document.createElement('input'); return elemDpick1 },
                               read: () => { return CustomJS.isNullEmpty(this.DtPick1.value) ? null : moment(this.DtPick1.value).format('DD/MM/YYYY') },
                               destroy: () => { this.DtPick1.destroy() },
                               write: (args) => { this.DtPick1 = new DatePicker({floatLabelType: 'Always', placeholder:'Scegli una Data di Assegnazione', allowEdit: false, 
                                                  value: CustomJS.isNullEmpty(args.rowData[args.column.field]) ? null : args.rowData[args.column.field]}); this.DtPick1.appendTo(args.element)}}
        this.editTemplate2 = { create: () => { let elemDpick2 = document.createElement('input'); return elemDpick2},
                               read: () => { return CustomJS.isNullEmpty(this.DtPick2.value) ? null : moment(this.DtPick2.value).format('DD/MM/YYYY') },
                               destroy: () => { this.DtPick2.destroy() },
                               write: (args) => { this.DtPick2 = new DatePicker({floatLabelType: 'Always', placeholder:'Scegli una Data di Riconsegna', allowEdit: false, 
                                                  value: CustomJS.isNullEmpty(args.rowData[args.column.field]) ? null : args.rowData[args.column.field]}); this.DtPick2.appendTo(args.element)}}
        this.editTemplate3 = { create: () => { let elemRTE = document.createElement('textarea'); return elemRTE },
                               read: () => { return this.rteObj1.value },
                               destroy: () => { this.rteObj1.destroy() },
                               write: (args) => { this.rteObj1 = new RichTextEditor({ value: args.rowData.note_cat, floatLabelType: 'Auto', placeholder: 'Note', 
                                                  enableHtmlEncode: false, maxLength: 5000, toolbarSettings: this.RTEtoolbarSettings, height: '250px' }); this.rteObj1.appendTo(args.element);} };  
        this.editTemplate4 = { create: () => { let elemRTE = document.createElement('textarea'); return elemRTE },
                               read: () => { return this.rteObj2.value },
                               destroy: () => { this.rteObj2.destroy() },
                               write: (args) => { this.rteObj2 = new RichTextEditor({ value: args.rowData.note_rfd, floatLabelType: 'Auto', placeholder: 'Note', 
                                                  enableHtmlEncode: false, maxLength: 5000, toolbarSettings: this.RTEtoolbarSettings, height: '250px' }); this.rteObj2.appendTo(args.element);} };      
        this.editTemplate5 = { create: () => { let elemDpick3 = document.createElement('input'); return elemDpick3},
                               read: () => { return CustomJS.isNullEmpty(this.DtPick3.value) ? null : moment(this.DtPick3.value).format('DD/MM/YYYY') },
                               destroy: () => { this.DtPick3.destroy() },
                               write: (args) => { this.DtPick3 = new DatePicker({floatLabelType: 'Always', placeholder:'Scegli una Data di Assegnazione', allowEdit: false, 
                                                  value: CustomJS.isNullEmpty(args.rowData[args.column.field]) ? null : args.rowData[args.column.field]}); this.DtPick3.appendTo(args.element)}}
        this.editTemplate6 = { create: () => { let elemDpick4 = document.createElement('input'); return elemDpick4},
                            read: () => { return CustomJS.isNullEmpty(this.DtPick4.value) ? null : moment(this.DtPick4.value).format('DD/MM/YYYY') },
                            destroy: () => { this.DtPick4.destroy() },
                            write: (args) => { this.DtPick4 = new DatePicker({floatLabelType: 'Always', placeholder:'Scegli una Data di Riconsegna', allowEdit: false, 
                                               value: CustomJS.isNullEmpty(args.rowData[args.column.field]) ? null : args.rowData[args.column.field]}); this.DtPick4.appendTo(args.element)}}
       
        this.salvaRigaSel = {};
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.accordHeader = ['Filtri Aggiuntivi', '<span style="font-size:18px;font-weight:bolder;">Statistica Prestazioni Perito {0} - Anno {1}</span>']; 
        this.childKey = 0;
        this.indiceAll = 0;
        this.suffissoEmail = '';
        this.childGrid = {
            dataSource: this.state.GridData2,
            queryString: 'id_sinistro',  
            enablePersistence: false,          
            columns: [
                { field: 'CAT', headerText: 'CAT', textAlign: 'Center', headerTemplate: headerTemplate },
                { field: 'DataChiusuraCAT', format: 'dd/MM/yyyy', headerText: 'Data Chiusura CAT', headerTemplate: headerTemplate, textAlign: 'Center' },
                { field: 'RitardoCAT', format: 'n0', headerText: 'Ritardo CAT', headerTemplate: headerTemplate, textAlign: 'Center' },
                { field: 'Perito', headerText: 'Perito', headerTemplate: headerTemplate, textAlign: 'Center' },
                { field: 'DataChiusuraPerito', format: 'dd/MM/yyyy', headerText: 'Data Chiusura Perito', headerTemplate: headerTemplate, textAlign: 'Center' },
                { field: 'RitardoPerito', format: 'n0', headerText: 'Ritardo Perito', headerTemplate: headerTemplate, textAlign: 'Center' },
                { field: 'CQ', headerText: 'Controllo Qualità', headerTemplate: headerTemplate, textAlign: 'Center' },
                { field: 'DataCQ', headerText: 'Data Controllo Qualità', headerTemplate: headerTemplate, textAlign: 'Center' },
                { field: 'RitardoCQ', format: 'n0', headerText: 'Ritardo CQ', headerTemplate: headerTemplate, textAlign: 'Center' },
            ],
            dataBound: this.onDataBound2, 
            queryCellInfo: this.onQueryCellInfo2
        };
        this.dialogButtons1 = [
            {
                click: () => {
                    this.dialogInstance4.hide();  
                    this.duplicaSinistro();                           
                },
                buttonModel: {
                    content: 'CONFERMA',
                    cssClass: 'e-primary',
                    isPrimary: true
                }
            },
            {
                click: () => {
                    this.dialogInstance4.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.asyncSettings =  {saveUrl: `${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumenti/JFTECH/NoUtente/NoInc/ALLEGATI`,
                               removeUrl: `${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumenti/JFTECH/NoUtente/NoInc/ALLEGATI`};
        this.dsStatoFiltro = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaStatoFiltro')))?.map(elm => { return { VALUE: elm.id_tipo_stato.toString(), TEXT: elm.descrizione} }), 'TEXT');
        this.dsGruppoFiltro = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00002')))?.map(elm => { return { VALUE: elm.id_compagnia.toString(), TEXT: elm.descrizione} }), 'TEXT');
        this.dsCompDivFiltro = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00003')))?.map(elm => { return { VALUE: elm.id_divisione.toString(), TEXT: elm.descrizione, ID_COMPAGNIA: elm.id_compagnia.toString() } }), 'TEXT');
        this.dsAgenziaFiltro = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaAgenziaFiltro')))?.map(elm => { return { VALUE: elm.id_agenzia.toString(), TEXT: elm.descrizione, id_divisione: elm.id_divisione } }), 'TEXT');
        this.dsPolizzaFiltro = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaPolizzaFiltro')))?.map(elm => { return { VALUE: elm.id_tipo_polizza.toString(), TEXT: elm.descrizione, nome: elm.nome } }), 'TEXT');
        this.dsPolizzaFiltro2 = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaPolizzaFiltro2')))?.map(elm => { return { VALUE: elm.id_tipo_polizza.toString(), TEXT: elm.descrizione, nome: elm.nome } }), 'TEXT');
        this.dsCATFiltro = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaCATFiltro')))?.map(elm => { return { VALUE: elm.id_collaboratore.toString(), TEXT: elm.nominativo, telefono1: elm.telefono1, telefono2: elm.telefono2, email1: elm.email1, email2: elm.email2, utente_JFCAT: elm.utente_JFCAT, password_JFCAT: elm.password_JFCAT } }), 'TEXT');
        this.dsCATCompleto = _.sortBy(_.uniq(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00006'))), elm => elm.id_collaboratore).map(elm => { return { VALUE: elm.id_collaboratore.toString(), TEXT: elm.ragione_sociale  } }), 'TEXT');
        this.dsRFDCompleto = _.sortBy(_.uniq(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00006'))), elm => elm.id_collaboratore).map(elm => { return { VALUE: elm.id_collaboratore.toString(), TEXT: elm.ragione_sociale  } }), 'TEXT');
        this.dsPeritoFiltro = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaPeritoFiltro')))?.map(elm => { return { VALUE: elm.id_utente.toString(), TEXT: elm.perito, email: elm.email1 } }), 'TEXT');
        this.dsCQFiltro = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaCQFiltro')))?.map(elm => { return { VALUE: elm.id_utente.toString(), TEXT: elm.CQ } }), 'TEXT');
        this.dsCRFiltro = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaCRFiltro')))?.map(elm => { return { VALUE: elm.id_utente.toString(), TEXT: elm.CR } }), 'TEXT');
        this.dsAreaFiltro = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00007')))?.map(elm => { return { VALUE: elm.id_area.toString(), TEXT: elm.descrizione } }), 'TEXT');
        this.dsTipiDannoFiltro = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02002')))?.map(elm => { return { VALUE: elm.id_tipo_fenomeno.toString(), TEXT: elm.descrizione } }), 'TEXT');
        this.dsTipiRamoFiltro = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02035')))?.map(elm => { return { VALUE: elm.id_tipo_ramo.toString(), TEXT: elm.descrizione } }), 'TEXT');
        this.dsLiquidatoreDettaglio = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00009')))?.map(elm => { return { VALUE: elm.id_liquidatore.toString(), TEXT: elm.ragione_sociale, telefono1:elm.telefono1, email1: elm.email1 } }), 'TEXT');
        this.dsAmministratoreFiltro = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00012')))?.map(elm => { return { VALUE: elm.id_amministratore.toString(), TEXT: elm.ragione_sociale, telefono1:elm.telefono1, email1: elm.email1 } }), 'TEXT');
        this.dsBrokerDettaglio = _.sortBy(_.uniq(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00008'))), elm => elm.id_broker).map(elm => { return { VALUE: elm.id_broker.toString(), TEXT: elm.ragione_sociale, telefono1: elm.telefono1, email1: elm.email1 } }), 'TEXT');
        this.dsGiustificativi = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02017')))?.map(elm => { return { VALUE: elm.id_presenze_doc.toString(), TEXT: elm.descrizione } }), 'TEXT');
        this.dsAChiDettaglio = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02007')))?.map(elm => { return { VALUE: elm.id_tipo_dest.toString(), TEXT: elm.descrizione } }), 'TEXT');
        this.dsAzioneDettaglio = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02009')))?.map(elm => { return { VALUE: elm.id_tipo_mess?.toString(), TEXT: elm.descrizione } }), 'TEXT');
        this.dsSollecitoDettaglio = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02019')))?.map(elm => { return { VALUE: elm.id_tipo_sollecito.toString(), TEXT: elm.descrizione } }), 'TEXT');
        this.dsAssegnaRFD = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaAssegnaRFD')))?.map(elm => { return { VALUE: elm.id_collaboratore.toString(), TEXT: elm.nominativo } }), 'TEXT');
        this.dsRipristFormaDir = [{ VALUE: 'Si', TEXT: 'SI'}, { VALUE: 'No', TEXT: 'NO'}];
        this.dsGradoSinistroDettaglio = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02034'))).map(elm => { return { VALUE: elm.id_grado_sinistro?.toString(), TEXT: elm.descrizione } }), 'VALUE');
        this.dsMotivazNO = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaMotivazNO')))?.map(elm => { return { VALUE: elm.id?.toString(), TEXT: elm.descrizione } }), 'TEXT');
        this.dsStatoDettaglio = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaStatoDettaglio'))).filter(elm => { return !CustomJS.isNullEmpty(elm.id_tipo_stato)}).map(elm => { return { VALUE: elm.id_tipo_stato?.toString(), TEXT: elm.descrizione, timer: elm.t_timer } }), 'TEXT');
        this.dsAgenziaDettaglio = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaAgenziaDettaglio'))).map(elm => { return { VALUE: elm.id_agenzia?.toString(), TEXT: elm.ragione_sociale, id_divisione: elm.id_divisione, telefono1: elm.telefono1, email1: elm.email1, area: elm.descrizione} }), 'TEXT');
        this.dsAzioneCosaDettaglio = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaAzioneCosa'))).map(elm => { return { VALUE: elm.id_email?.toString(), TEXT: elm.titolo_email, id_tipo_dest: elm.id_tipo_dest.toString(), id_stato: elm.id_stato.toString(), id_tipo_messaggio: elm.id_tipo_messaggio.toString()} }), 'TEXT');
        this.dsMittentiEmail = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaEmailDefault'))).map(elm => { return { VALUE: elm.IdEmail, TEXT: `${elm.ParametriEmail.split(';')[5]} <${elm.ParametriEmail.split(';')[6]}>`, ParametriEmail: elm.ParametriEmail } });
        this.dsDiciture = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella09008')))?.map(elm => { return { VALUE: elm.id_dicitura.toString(), TEXT: elm.descr_dicitura } }), 'TEXT');

        this.altriSinAssic = [];
        this.noteFin = [];
        this.RTEtoolbarSettings = {
            items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                    'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                    'LowerCase', 'UpperCase', '|',
                    'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                    'Outdent', 'Indent', '|',
                    'CreateTable', 'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
                    'SourceCode', '|', 'Undo', 'Redo', {
                        tooltipText: 'Emoticons',
                        template: '<button type="button" class="e-tbar-btn e-btn" tabindex="-1" id="emot_tbar2" style="width:100%"><div class="e-tbar-btn-text" style="font-weight: 500;">&#128578;</div></button>'
                    }]
        };
        this.RTEtoolbarSettings2 = { items: [] };
        this.toolbarOptionsCat = [ 'Edit','Update', 'Cancel', 'ColumnChooser' ];
        this.editSettingsCat = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog'};
        this.pageSettingsCat = {pageSize : 2};
        this.pageSettingsControlloCat = {pageSize : 3};
        this.filterSettingsCat = { type: 'CheckBox'};
        this.commandsCat = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" }},
                            { type: "Revoca Incarico", buttonOption: { iconCss: "fas fa-times fa-10x", cssClass: "e-details" }},
                            { type: "Chiudi Incarico", buttonOption: { iconCss: "fas fa-check fa-10x", cssClass: "e-details" }},
                            { type: "Invia Email Scheda Incarico", buttonOption: { iconCss: "fas fa-envelope fa-10x", cssClass: "e-details" }},
                            { type: "Invia Incarico a Jellyfish CAT Mobile", buttonOption: { iconCss: "fas fa-paper-plane fa-10x", cssClass: "e-details" }},
                            { type: "Revoca Incarico in Jellyfish CAT Mobile", buttonOption: { iconCss: "fas fa-paper-plane fa-10x", cssClass: "e-details" }}];
        this.commandsRfd = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" }},
                            { type: "Revoca Incarico", buttonOption: { iconCss: "fas fa-times fa-10x", cssClass: "e-details" }},
                            { type: "Chiudi Incarico", buttonOption: { iconCss: "fas fa-check fa-10x", cssClass: "e-details" }},
                            { type: "Invia Email Scheda Incarico", buttonOption: { iconCss: "fas fa-envelope fa-10x", cssClass: "e-details" }}];
        this.toolbarOptionsPeriti = [ 'Edit','Update', 'Cancel'];
        this.editSettingsPeriti = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog'};
        this.pageSettingsPeriti = {pageSize : 5};
        this.filterSettingsPeriti = { type: 'CheckBox'};
        this.commandsPeriti = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                               { type: "Compila Tabellino", buttonOption: { iconCss: "fas fa-file-signature fa-10x", cssClass: "e-details sfondoGiallo" } },
                               { type: "Esporta Scheda Peritale", buttonOption: { iconCss: "fas fa-file-alt fa-10x", cssClass: "e-details sfondoVerde" } },
                               { type: "Revoca Incarico", buttonOption: { iconCss: "fas fa-times fa-10x", cssClass: "e-details" }},
                               { type: "Chiudi Incarico", buttonOption: { iconCss: "fas fa-check fa-10x", cssClass: "e-details" } },
                            // { type: "Esporta Scheda Peritale Generali", buttonOption: { iconCss: "fab fa-google fa-10x", cssClass: "e-details" }}
                              ];
        this.toolbarOptionsControlloCat = [ 'ExcelExport', 'Search'];
        this.toolbarOptionsSinistriImport = [ 'ExcelExport', 'Search'];
        this.btnItems = [
            {
                text: 'Visualizza Documentazione',
                id: 'VediDocs'
            },
            {
                text: 'Carica Documentazione',
                id: 'CaricaDocs'
            },
            {
                text: 'Importa Documentazione GENERALI',
                id: 'ImportaDocsGENERALI'
            }
        ]; 
        this.btnItems2 = [
            // {
            //     text: 'UNIPOLSAI (Email)',
            //     id: 'ImportaUnipolSAIEmail'
            // },
            {
                text: 'UNIPOLSAI',
                id: 'ImportaUnipolSAIAPI'
            },
            {
                text: 'GENERALI',
                id: 'ImportaGeneraliAPI'
            },
            {
                text: 'ZURICH',
                id: 'ImportaZurich'
            },
            {
                text: 'ASSIMOCO',
                id: 'ImportaAssimoco'
            },
            // {
            //     text: 'GENERALI/CATTOLICA',
            //     id: 'ImportaGeneraliCattolica'
            // }
        ];  
        this.btnItems3 = [
            {
                text: 'NESSUN ORDINAMENTO',
                id: 'NessunOrdinamento'
            },
            {
                text: 'RITARDO CRESCENTE PERITO',
                id: 'OrdinaRitardoCrescentePerito'
            },
            {
                text: 'RITARDO DECRESCENTE PERITO',
                id: 'OrdinaRitardoDecrescentePerito'
            },
            {
                text: 'RITARDO CRESCENTE CQ',
                id: 'OrdinaRitardoCrescenteCQ'
            },
            {
                text: 'RITARDO DECRESCENTE CQ',
                id: 'OrdinaRitardoDecrescenteCQ'
            },            
            {
                text: 'DATA SOPRALLUOGO CRESCENTE',
                id: 'OrdinaDataSopralluogoCrescente'
            },
            {
                text: 'DATA SOPRALLUOGO DECRESCENTE',
                id: 'OrdinaDataSopralluogoDecrescente'
            }

        ];  
        this.handleClose = this.handleClose.bind(this); 
        this.matriceEmail = []; 
        this.noSblocco = false;  
        this.arrivoDaFiltriAgg = false;
        this.arrFilePath = [];
        this.lightboxImages = [];
        this.lightboxImage = '';
        this.uppy = new Uppy({ locale: Italiano, 
            restrictions: {
                 maxTotalFileSize: 1073741824,
                 maxFileSize: 524288000 
            }
        })    
        .use(XHRUpload, { method: 'POST', formData: true,  fieldName: 'documenti', bundle: true, timeout: 0,
               headers: { "Authorization": JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token }, 
               endpoint: '' 
        })    
        .use(ImageEditor, {               
            quality: 0.8,
            cropperOptions: {
                viewMode: 1,
                background: false,
                autoCropArea: 1,
                responsive: true
            },
            actions: {
            revert: true,
            rotate: true,
            flip: true,
            zoomIn: true,
            zoomOut: true,
            cropSquare: true,
            cropWidescreen: true,
            cropWidescreenVertical: true
            }
        })
        .on('upload-success', (file, response) => {  
                            
        })
        .on('complete', async (result) => {
            const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;

            const fileToBase64 = (file) => {
                return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => resolve(reader.result);
                  reader.onerror = error => reject(error);
                });
            }

            if (this.fotoGEOUnipolSAI) 
                toast.success(CustomJS.messaggioFotoGEOUnipolSAI, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }); 

            if (this.fotoRischioGENERALI) {
                result.successful.forEach(async (elm,idx) => {     
                    const base64String = await fileToBase64(elm.data);    
                    document.getElementById(`fotoRischioBase64${idSin}`).value = base64String;
                    document.getElementById(`btnVisualizzaFotoRischioGENERALI${idSin}`).style.removeProperty('display');
                    document.getElementById(`btnCancellaFotoRischioGENERALI${idSin}`).style.removeProperty('display');
                    toast.success(CustomJS.messaggioFotoRischioGENERALI, {
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                });     
            }
        });
        this.dataFotoKanban = []
        this.idCardKanban = 0;
        this.argsRowKanban = [];
        this.refreshStateInterval = null;
        this.fotoGEOUnipolSAI = false;
        this.fotoRischioGENERALI = false;
        this.takeNomiDanneggiati = [];
        this.liquidazioniPresenti = false;
    }
    state = {
        blocking: false,
        GridData: [],
        GridData2: [],
        GridData3: [],
        statistCat: [],
        statistRfd: [],
        showUppy: false,
        showDialog1: false, 
        showDialog2: false,
        showDialog3: false,
        showLightbox: false,
        showLightbox2: false,
        playVideo: false,
        nomeAllegato: '', 
        NumSinistro: '',
        NumSinistroAgenzia: '',
        importoLiquidazioneRiepilogo: 0,
        isVisible: true
    } 
    handleClose() {
        this.setState({
            showUppy: false
        })
    }
    toggleVisibility() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        //this.state.isVisible = !this.state.isVisible;
        this.setState({
            isVisible: !this.state.isVisible
        });
        if (this.state.isVisible) {
            document.getElementById(`contenutoEsitoReportFulmini${idSin}`).style.display = 'none';
            document.getElementById(`btnVisualNascEsitoReportFulmini${idSin}`).title = 'Mostra Esito Report Fulmini';
            //document.getElementById(`iconaBtnVisualNascEsitoReportFulmini${idSin}`).setAttribute('icon', faEye);
            this.iconRef1.setAttribute('icon', faEye);
        }
        else {
            document.getElementById(`contenutoEsitoReportFulmini${idSin}`).style.removeProperty('display');
            document.getElementById(`btnVisualNascEsitoReportFulmini${idSin}`).title = 'Nascondi Esito Report Fulmini';
            this.iconRef1.setAttribute('icon', faEyeSlash);
            //document.getElementById(`iconaBtnVisualNascEsitoReportFulmini${idSin}`).setAttribute('icon', faEyeSlash);
        }
    }
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }   
    toggleBlocking2(show) {
        let container, root;
        if (show) {
            document.getElementById('blockUIGenerico').style.removeProperty('display');
            document.getElementById('spinnerUIGenerico').style.removeProperty('display');
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.render(CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]);
        }
        else {
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.unmount();
            document.getElementById('blockUIGenerico').style.display = 'none';
            document.getElementById('spinnerUIGenerico').style.display = 'none';
        }
    }
    sortComparer1(reference, comparer) {
        if (moment(reference, 'DD/MM/YYYY').isBefore(moment(comparer, 'DD/MM/YYYY'))) {
            return -1;
        }
        if (moment(reference, 'DD/MM/YYYY').isAfter(moment(comparer, 'DD/MM/YYYY'))) {
            return 1;
        }
        return 0;
    }
    onMinimizeDialogOpen(titolo, minWidth, minPosY, initMinHeight, dialogInstance) {   
        dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-restore")?.classList.remove('fa-window-restore');
        dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-minimize").setAttribute('title', 'Minimizza Finestra');
        dialogInstance.element.querySelector('.e-dlg-content').classList.remove('hide-content');
        dialogInstance.position = { X: 'center', Y: '70' };
        dialogInstance.allowDragging = true;
        document.getElementById(dialogInstance.id).style.height = initMinHeight;
        document.getElementById(dialogInstance.id).style.minHeight = initMinHeight;
        this.minBtn = document.getElementById(`min-btn-${dialogInstance.id}`);
        this.minBtn.onclick = (e) => {
            let minimizeIcon = dialogInstance.element.querySelector(".e-dlg-header-content .fa-window-minimize");
            if (!dialogInstance.element.classList.contains('e-dlg-fullscreen')) {
                if (!dialogInstance.element.classList.contains('dialog-minimized')) {
                    this.dialogOldPositions = { X: dialogInstance.position.X, Y: dialogInstance.position.Y };
                    this.dialogOldWidth = dialogInstance.width;
                    dialogInstance.element.classList.add('dialog-minimized');
                    dialogInstance.element.querySelector('.e-dlg-content').classList.add('hide-content');
                    dialogInstance.element.querySelector('.e-footer-content')?.classList.add('hide-content');
                    dialogInstance.position = { X: 'right', Y: minPosY };
                    dialogInstance.width = minWidth;
                    dialogInstance.height = '100px';
                    document.getElementById(dialogInstance.id).style.minHeight = '3%';
                    //dialogInstance.minHeight = '100px';
                    dialogInstance.dataBind();
                    minimizeIcon.classList.add('fa-window-restore');
                    minimizeIcon.setAttribute('title', 'Ripristina Finestra');
                }
                else {
                    dialogInstance.element.classList.remove('dialog-minimized');
                    dialogInstance.element.querySelector('.e-dlg-content').classList.remove('hide-content');
                    dialogInstance.element.querySelector('.e-footer-content')?.classList.remove('hide-content');
                    minimizeIcon.classList.add('fa-window-minimize');
                    minimizeIcon.setAttribute('title', 'Minimizza Finestra');
                    minimizeIcon.classList.remove('fa-window-restore');
                    dialogInstance.position = this.dialogOldPositions;
                    dialogInstance.width = this.dialogOldWidth;
                    document.getElementById(dialogInstance.id).style.minHeight = initMinHeight;
                    document.getElementById(`dialog-title-${dialogInstance.id}`).innerHTML = titolo;
                    dialogInstance.dataBind();
                }
            }
            else {
                dialogInstance.show(false);
                dialogInstance.element.classList.remove('dialog-maximized');
                dialogInstance.element.classList.add('dialog-minimized');
                dialogInstance.element.querySelector('.e-dlg-content').classList.add('hide-content');
                minimizeIcon.classList.remove('fa-window-minimize');
                minimizeIcon.removeAttribute('title');
                dialogInstance.position = { X: 'center', Y: 'bottom' };
                dialogInstance.dataBind();
                this.isFullScreen = true;
            }
        };
    }
    onBeforeExcelExportStatistiche(args){
        args.gridObject.dataSource.forEach(elm => {
            elm.estratto_denuncia = elm.estratto_denuncia?.replace(/&lt;.*?&gt;/g, '');
            elm.t_chiusura = CustomJS.isNullEmpty(elm.t_chiusura) ? '01/01/2010' : elm.t_chiusura;
        });
    }
    onQueryCellInfo(args) {       
        if (args.column.field === 'conta_Apertura' || args.column.field === 'id_interno_sinistro') {            
            if (args.data.conta_Apertura >= 30 && args.data.conta_Apertura < 60) {
                args.cell.bgColor = "Fuchsia";
                args.cell.style.fontWeight = 'bolder';                
            }
            else if (args.data.conta_Apertura >= 60) {
                args.cell.bgColor = "Red";
                args.cell.style.fontWeight = 'bolder';
            }
        }
        if (args.column.field === 'danneggiato') {
            args.cell.bgColor = "PaleTurquoise";
            args.cell.style.fontWeight = 'bolder';
        }
    } 
    onQueryCellInfo2(args) {       
        if (args.column.field === 'RitardoCAT' || args.column.field === 'DataChiusuraCAT' || args.column.field === 'CAT') {            
            if (args.data.RitardoCAT >= 10 ) {
                args.cell.bgColor = "Yellow";
                args.cell.style.fontWeight = 'bolder';                
            }
        }
        if (args.column.field === 'RitardoPerito' || args.column.field === 'DataChiusuraPerito' || args.column.field === 'Perito') {            
            if (args.data.RitardoPerito >= 10 ) {
                args.cell.bgColor = "Yellow";
                args.cell.style.fontWeight = 'bolder';                
            }
        }
        if (args.column.field === 'RitardoCQ' || args.column.field === 'DataCQ' || args.column.field === 'CQ') {            
            if (args.data.RitardoCQ >= 10 ) {
                args.cell.bgColor = "Yellow";
                args.cell.style.fontWeight = 'bolder';                
            }
        }
        if (args.column.field === 'CAT' || args.column.field === 'DataChiusuraCAT' || args.column.field === 'RitardoCAT')  
            args.rowSpan = _.filter(this.currentViewData, elm => { return elm.CAT === args.data.CAT }).length;
        if (args.column.field === 'Perito' || args.column.field === 'DataChiusuraPerito' || args.column.field === 'RitardoPerito') 
            args.rowSpan = _.filter(this.currentViewData, elm => { return elm.Perito === args.data.Perito }).length;
        if (args.column.field === 'CQ' || args.column.field === 'DataCQ' || args.column.field === 'RitardoCQ') 
            args.rowSpan = _.filter(this.currentViewData, elm => { return elm.CQ === args.data.CQ }).length;
    } 
    onQueryCellInfo3(args) {
        if (args.column.field === 'PercChiusiPD') {
            if (args.data.PercChiusiPD.replace('%','').replace(',','.') >= 80 )
                args.cell.bgColor = "LightGreen";
            else 
                args.cell.bgColor = "LightCoral";
        }
        if (args.column.field === 'LiquidatoMedioChiusiPD') {
            if ((args.data.IdCompagnia === 5 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') < 700)
                 || (args.data.IdCompagnia === 3 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') < 600)
                 || (args.data.IdCompagnia === 6 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') < 900))
                args.cell.bgColor = "LightGreen";
            else if ((args.data.IdCompagnia === 5 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') >= 700 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') <= 799)
                      || (args.data.IdCompagnia === 3 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') >= 600 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') <= 699)
                      || (args.data.IdCompagnia === 6 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') >= 900 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') <= 999)) 
                args.cell.bgColor = "Gold";
            else if (args.data.IdCompagnia === 5 || args.data.IdCompagnia === 3 || args.data.IdCompagnia === 6)
                args.cell.bgColor = "LightCoral";

            if (args.data.IdCompagnia !== 5 && args.data.IdCompagnia !== 3 && args.data.IdCompagnia !== 6) {
                if (args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') < 700)
                    args.cell.bgColor = "LightGreen";
                else if (args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') >= 700 && args.data.LiquidatoMedioChiusiPD.replace('€','').replace('.','').replace(',','.') <= 799)
                    args.cell.bgColor = "Gold";
                else 
                    args.cell.bgColor = "LightCoral";
            }
        }
        if (args.column.field === 'PercChiusiNegativiPD') {
            if (args.data.PercChiusiNegativiPD.replace('%','').replace(',','.') >= 10 )
                args.cell.bgColor = "LightGreen";
            else 
                args.cell.bgColor = "LightCoral";
        }
    }
    onRowDataboundCat(args) {
        $($(args.row).find('button')[4]).hide();
        $($(args.row).find('button')[5]).hide();
        if (args.data.revocata === true) {
            $($(args.row).find('button')[1]).hide(); 
            $($(args.row).find('button')[2]).hide(); 
        }     
        else if (args.data.chiusa === true) {    
            $($(args.row).find('button')[1]).hide();
            $($(args.row).find('button')[2]).hide();
        }       
        else{
            $($(args.row).find('button')[1]).show(); 
            $($(args.row).find('button')[2]).show(); 
        }
        //TODO Togliere commento se l'incarico al CAT NON può essee chiuso manualmante ma solo tramite scambio dati JFCAT-JFTECH.
        //TODO Lasciare commentato se l'incarico al CAT può essere chiuso anche manualmente. In questo caso se lo scambio dati JFCAT-JFTECH avviene dopo la chiusura manuale,
        //TODO i dati di chusura vengono sovrascritti
        // if (!CustomJS.isNullEmpty(args.data.utente_JFCAT) && args.data.inviato_JFCAT === true)  
        //     $($(args.row).find('button')[2]).hide();
        if (!CustomJS.isNullEmpty(args.data.utente_JFCAT) && (CustomJS.isNullEmpty(args.data.inviato_JFCAT) || args.data.inviato_JFCAT === false) 
            && args.data.revocata === false && args.data.chiusa === false) 
            $($(args.row).find('button')[4]).show();
        if (!CustomJS.isNullEmpty(args.data.utente_JFCAT) && (CustomJS.isNullEmpty(args.data.revocato_JFCAT) || args.data.revocato_JFCAT === false) 
            && args.data.revocata === true && args.data.chiusa === false) 
            $($(args.row).find('button')[5]).show();
    }
    onRowDataboundRfd(args) {
        if (args.data.revocata === true) {
            $($(args.row).find('button')[1]).hide(); 
            $($(args.row).find('button')[2]).hide(); 
        }     
        else if (args.data.chiusa === true) {    
            $($(args.row).find('button')[1]).hide();
            $($(args.row).find('button')[2]).hide();
        }       
        else{
            $($(args.row).find('button')[1]).show(); 
            $($(args.row).find('button')[2]).show(); 
        }
    }
    onRowDataboundPeriti(args) {
        if (args.data.revocata === true) {
            $($(args.row).find('button')[1]).hide(); 
            $($(args.row).find('button')[3]).hide(); 
            $($(args.row).find('button')[4]).hide(); 
        }     
        else if (args.data.chiusa === true) { 
            $($(args.row).find('button')[3]).hide();
            $($(args.row).find('button')[4]).hide();
        }       
        else{
            $($(args.row).find('button')[3]).show(); 
            $($(args.row).find('button')[4]).show(); 
        }
        if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti Semplici') > -1)
            $($(args.row).find('button')[3]).hide();
    }
    onDataBound(args) {
        for (let ind = 0; ind <= 18; ind++)
             document.getElementById('GridSituazioneSinistri').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        setTimeout(() => {
            if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti') > -1
                || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Team Leader') > -1
                || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Referenti ACE') > -1)
                this.gridInstance.toolbarModule.enableItems(['GridSituazioneSinistri_add'], false);
        },300);
    }
    onDataBound2(args) {
        for (let ind=0;ind<=2;ind++)
             this.element.querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'LightSkyBlue';
        for (let ind=3;ind<=5;ind++)
             this.element.querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'LightCoral';
        for (let ind=6;ind<=8;ind++)
             this.element.querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'LightGreen';
    }
    onRowDatabound(args) {  
        if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti') > -1
            || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Team Leader') > -1
            || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].id_utente === 3) {    
            $($(args.row).find('button')[3]).hide(); 
            if (args.data.sollecito_giustificativo === true)
                $($(args.row).find('button')[1]).css('background', 'cyan');
            if (args.data.sollecito_atto === true)
                $($(args.row).find('button')[2]).css('background', 'cyan');
        }
        else {
            $($(args.row).find('button')[1]).hide(); 
            $($(args.row).find('button')[2]).hide(); 
        }
        if (args.data.id_grado_sinistro === 4) {
            args.row.className = 'e-row';
            args.row.bgColor = '#FFD966';  
        }   
        if (CustomJS.isNullEmpty(args.data.IdIncaricoUNI) && CustomJS.isNullEmpty(args.data.IdIncaricoGEN)) {
            $($(args.row).find('button')[4]).hide(); 
        }  
        else {
            if ((args.data.Esito_Ultimo_Evento_UNI?.trim() === 'OK' && !CustomJS.isNullEmpty(args.data.IdIncaricoUNI))
                || (args.data.Esito_Ultimo_Evento_GEN?.trim() === 'OK' && !CustomJS.isNullEmpty(args.data.IdIncaricoGEN))) {
                $($(args.row).find('button')[4]).css('background', 'lawngreen');
            }
            else if ((args.data.Esito_Ultimo_Evento_UNI?.trim() === 'KO' && !CustomJS.isNullEmpty(args.data.IdIncaricoUNI))
                     || (args.data.Esito_Ultimo_Evento_GEN?.trim() === 'KO' && !CustomJS.isNullEmpty(args.data.IdIncaricoGEN))) {
                $($(args.row).find('button')[4]).css('background', 'orangered');
            }
        }
        $($(args.row).find('button')[5]).hide();
        if (CustomJS.isNullEmpty(args.data.EventoAccettazioneUNI) && args.data.id_compagnia?.toString() === CustomJS.codiceCompagniaUnipolSAI 
            && args.data.id_stato === 29 && !CustomJS.isNullEmpty(args.data.IdIncaricoUNI) && JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti') === -1 
            && JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Team Leader') === -1
            && JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Referenti ACE') === -1) {  
            //&& new Date(args.data.data_ora_importazione) < new Date().setMinutes(new Date().getMinutes() - 60) { // SOLO SE ACCETTAZIONE INVIATA DA IMPORTAZIONE
            $($(args.row).find('button')[5]).show();
            $($(args.row).find('button')[5]).css('background', 'gold');
        }
        $($(args.row).find('button')[6]).hide();
        if (!CustomJS.isNullEmpty(args.data.Presenza_Altri_Sinistri_Uguali)) {
            $($(args.row).find('button')[6]).show();
            $($(args.row).find('button')[6]).css('background', 'gold');
        }
        const filter = args.data.id_sinistro;
        const childrecord = new DataManager(this.childGrid.dataSource)
        .executeLocal(new Query().where("id_sinistro", "equal", filter, true));
        if(childrecord.length === 0) {
            args.row.querySelector('td').innerHTML=" ";
            args.row.querySelector('td').className = "e-customizedExpandcell";
        }
    }
    onRowDataBound2(args) {
        if (args.data.status === 'Da importare')
            $($(args.row).find('button')).show();
        else {
            $($(args.row).find('button')).hide();
            args.isSelectable = false;
        }
    }
    toolbarClick(args) {
        const excelExportProps = {
            includeHiddenColumn: true,
            hierarchyPrintMode: 'All'
        };
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.toggleBlocking();
                this.gridInstance.excelExport(excelExportProps);
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
        if (args.item.id === 'GridSituazioneSinistri_add') {
            args.cancel = true;  
            if (!CustomJS.isNullEmpty(_.find(this.tabInstance.items, elm => { return elm.nuovo === true }))) {
                this.tabInstance.select(this.tabInstance.items.getIndexBy('id', _.find(this.tabInstance.items, elm => { return elm.nuovo === true }).id));
                return;
            }
            this.toggleBlocking();
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/inserisciNuovoSinistro`, '', 'GET',
            '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                if (retData.response === 'KO') {
                    let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),                    
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking();
                    return;
                }    
                const dataObj = {
                    IdSinistro : retData.id_sinistro,
                    Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                    DescrNota: 'Nuovo sinistro inserito.',
                };
                const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                  ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, 39)";        
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData2 => {
                    this.creaTab({ id_sinistro: retData.id_sinistro, id_interno_sinistro: retData.id_interno_sinistro, id_stato: 39 }, true); 
                    this.toggleBlocking();
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );
                }, true, true);
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking();
            }, true, false);            
        }        
        if (args.item.id === 'aggiornatabella') {
            const oggettoFiltri = JSON.parse(sessionStorage.getItem('JellyfishTechnologyFiltriAggiuntiviSinistri'));
            this.caricaDati(oggettoFiltri, null, $('#GridSituazioneSinistri_searchbar').val()?.trim());
        }
    }
    onExcelExportComplete(args) {
        this.toggleBlocking();
    }
    toolbarClickContrCAT(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance2.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance2.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance2.csvExport();
                break;
        }
    }
    toolbarClickContrRFD(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance3.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance3.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance3.csvExport();
                break;
        }
    }
    toolbarClickSinistriImport(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance4.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance4.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance4.csvExport();
                break;
        } 
    }
    toolbarClickImportUnipolSAI(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance8.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance8.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance8.csvExport();
                break;
        }
    }
    toolbarClickImportGenerali(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance9.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance9.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance9.csvExport();
                break;
        }
    }
    onQueryCellInfoSinistriImport(args) {
        if (args.column.field === "NumeroSinistro")
            args.cell.innerHTML = `<div><span style="font-size: 0.85vw; color: purple; font-weight: bold;">${args.cell.innerHTML}</span></div>`;  
    }
    onCommandClick(args) {
        if (args.commandColumn.buttonOption.iconCss?.indexOf('fa-edit') > -1) {
            this.toggleBlocking();
            setTimeout(() => { 
                this.creaTab(args.rowData, false);
                this.toggleBlocking();  
            }, 1000);    
        }  
        if (args.commandColumn.buttonOption.iconCss?.indexOf('fa-clone') > -1) { 
            this.salvaRigaSel = { 
                IdInternoSinistro: args.rowData.id_interno_sinistro
            }  
            this.dialogInstance4.header = 'Attenzione!!'            
            this.dialogInstance4.content = `Si vuole duplicare il sinistro con ID <span style='font-size:17px;font-weight:bolder'>${args.rowData.id_interno_sinistro}</span>. Confermare la duplicazione?`;
            this.dialogInstance4.width = '25%'; 
            this.dialogInstance4.height = '22%';
            this.dialogInstance4.minHeight = '22%';
            this.dialogInstance4.show();              
        }      
        if (args.commandColumn.buttonOption.iconCss?.indexOf('fa-eye') > -1) {
            this.visualizzaNoteParzialiSinistro(args.rowData.id_sinistro, args.rowData.id_interno_sinistro, args.rowData.numero_sinistro_agenzia);
        }
        if (args.commandColumn.buttonOption.iconCss?.indexOf('fa-comment-dots') > -1) {
            this.emettiNotifiche(args.rowData.id_sinistro, args.rowData.id_interno_sinistro, true, args.rowData.id_compagnia);
        }
        if (args.commandColumn.buttonOption.iconCss?.indexOf('fa-exclamation-triangle') > -1) {
            this.emettiNotifiche(args.rowData.id_sinistro, args.rowData.id_interno_sinistro, true, args.rowData.id_compagnia, true, args.rowData.numero_sinistro_agenzia);
        }
        if (args.commandColumn.id === 'SollGiust') {
            if (CustomJS.isNullEmpty(args.rowData.sollecito_giustificativo) || args.rowData.sollecito_giustificativo === false) {
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = `Impostando il <b>Sollecito Documentazione</b> verrà inviata una email automatica, ogni 7 giorni a partire dal giorno <b>${moment(new Date().setDate(new Date().getDate() + 7)).format('DD/MM/YYYY')}</b>, di tipo <b>"ASSICURATO - Sollecito documentazione"</b>. Per interrompere il sollecito periodico sarà sufficiente rimuovere il check in questione. Confermare l'operazione?`;
                this.dialogInstance3.width = '25%';
                this.dialogInstance3.height = '23%';
                this.dialogInstance3.minHeight = '23%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.aggiornaDettaglioDaTabellaSinistri('sollecito_giustificativo', 1, 'INT', null, null, args.rowData.id_sinistro);
                        this.aggiornaDettaglioDaTabellaSinistri('data_ultimo_sollecito_giustif', moment(new Date()).format('YYYY-MM-DD'), 'DATE', null, null, args.rowData.id_sinistro);
                        this.dialogInstance3.hide();
                        setTimeout(() => {
                            document.getElementById('aggiornatabella').click();
                        }, 100);
                    }, 
                    buttonModel: { 
                        content: 'CONFERMA', 
                        cssClass: 'e-primary',
                        isPrimary: true 
                    }
                    },
                    {
                        click: () => {
                            this.dialogInstance3.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
                this.dialogInstance3.show(); 
            }
            else {
                this.aggiornaDettaglioDaTabellaSinistri('sollecito_giustificativo', 0, 'INT', null, null, args.rowData.id_sinistro);   
                document.getElementById('aggiornatabella').click();
            } 
        }
        if (args.commandColumn.id === 'SollAtto') {
            if (CustomJS.isNullEmpty(args.rowData.sollecito_atto) || args.rowData.sollecito_atto === false) {
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = `Impostando il <b>Sollecito Atto</b> verrà inviata una email automatica, ogni 7 giorni a partire dal giorno <b>${moment(new Date().setDate(new Date().getDate() + 7)).format('DD/MM/YYYY')}</b>, di tipo <b>"ASSICURATO - Sollecito atto"</b>. Per interrompere il sollecito periodico sarà sufficiente rimuovere il check in questione. Confermare l'operazione?`;
                this.dialogInstance3.width = '25%';
                this.dialogInstance3.height = '23%';
                this.dialogInstance3.minHeight = '23%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.aggiornaDettaglioDaTabellaSinistri('sollecito_atto', 1, 'INT', null, null, args.rowData.id_sinistro);
                        this.aggiornaDettaglioDaTabellaSinistri('data_ultimo_sollecito_atto', moment(new Date()).format('YYYY-MM-DD'), 'DATE', null, null, args.rowData.id_sinistro);
                        this.dialogInstance3.hide();
                        setTimeout(() => {
                            document.getElementById('aggiornatabella').click();
                        }, 100);
                    }, 
                    buttonModel: { 
                        content: 'CONFERMA', 
                        cssClass: 'e-primary',
                        isPrimary: true 
                    }
                    },
                    {
                        click: () => {
                            this.dialogInstance3.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
                this.dialogInstance3.show(); 
            }
            else {
                this.aggiornaDettaglioDaTabellaSinistri('sollecito_atto', 0, 'INT', null, null, args.rowData.id_sinistro);    
                document.getElementById('aggiornatabella').click();
            }
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-paper-plane') > -1) {
            this.dialogInstance3.header = 'Attenzione!!';
            this.dialogInstance3.content = `L'invio di una nuova comunicazione di <b>Accettazione Incarico</b> dovrebbe essere fatto solo a ragion veduta e se la comunicazione originale non è andata a buon fine per qualche motivo. Controllare il tempo intercorso dalla comunicazione originale, che dovrebbe essere consistente (non pochi minuti) e nel caso in cui questa operazione venga fatta, ricordarsi di controllare tra quache minuto la risposta da parte della Compagnia. Confermare l'operazione?`;
            this.dialogInstance3.width = '25%';
            this.dialogInstance3.height = '25%';
            this.dialogInstance3.minHeight = '25%';
            this.dialogInstance3.buttons = [
                { click: () => { 
                    this.toggleBlocking2(true);
                    this.invioAccettazioneIncaricoUnipolSAI(args.rowData.IdIncaricoUNI);
                    setTimeout(() => {
                        this.toggleBlocking2(false);
                    }, 2000);       
                    this.dialogInstance3.hide();
                }, 
                buttonModel: { 
                    content: 'CONFERMA', 
                    cssClass: 'e-primary',
                    isPrimary: true 
                }
                },
                {
                    click: () => {
                        this.dialogInstance3.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
            this.dialogInstance3.show(); 
        }
    }
    onCommandClickCat(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-times') > -1) {
            if (args.rowData.chiusa === false && args.rowData.revocata === false) {
                let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = "Revocare l'uscita del CAT?";
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '22%';
                this.dialogInstance3.minHeight = '22%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.dialogInstance3.hide();
                        setTimeout(() => { 
                            const dataObj = {
                                IdCollaboratore: args.rowData.id_collaboratore,
                                IdSinistro: document.getElementById(`idSinistro${idSin}`).value,
                                IdCat: args.rowData.id_cat,
                            };
                            const QueryExec = `UPDATE [01004] SET [01004].revocata = 1 WHERE [01004].id_cat= @id_cat AND [01004].id_sinistro = @id_sinistro`;        
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                this.getStatoAzione(4).then(ret => {
                                    const id_stato_tmp = ret.stato;
                                    if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                                        document.getElementById(`idStato${idSin}`).value = id_stato_tmp.toString();
                                        this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value }); 
                                        this.aggiornaStatoSinistro(true).then(ret2 => {
                                            if (ret2 === 'OK') {                
                                                document.getElementById(`stato${idSin}`).ej2_instances[0].value = document.getElementById(`ddlCambiaStato${idSin}`).ej2_instances[0].text;
                                            }
                                        }).catch(messaggio => { 
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,           
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true}
                                            );
                                        });                                     
                                    }
                                    this.getAzioneNota(4).then(ret => {
                                        if (ret === true) {
                                            const dataObj = {
                                                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                                DescrNota: `Revocato il CAT ${args.rowData.ragione_sociale}`,
                                                Stato: id_stato_tmp
                                            };
                                            const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                                ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                                this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,           
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true}
                                                );
                                            }, true, true);
                                        }
                                    }).catch(messaggio => { 
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    });                
                                }).catch(messaggio => { 
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                });
                                const QueryExec7 = `SELECT [00006].ragione_sociale, [00006].telefono1, [00006].telefono2, [00006].email1, [00006].email2, [00006].user_sito, [00006].password_sito, [01004].* FROM [01004] INNER JOIN [00006] ON [01004].id_collaboratore = [00006].id_collaboratore WHERE [01004].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec7, '', retData7 => {
                                    this.dsCatAssegnati = retData7.map(elm => { return {id_cat: elm.id_cat, id_collaboratore: elm.id_collaboratore, ragione_sociale: elm.ragione_sociale,  t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_sopralluogo: CustomJS.isNullEmpty(elm.t_sopralluogo) ? '' : moment(elm.t_sopralluogo).format('DD/MM/YYYY HH:mm'), t_riconsegnato: CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), telefono1: elm.telefono1, telefono2: elm.telefono2, email1: elm.email1, email2: elm.email2, note_cat: elm.note_cat, chiusa: elm.chiusa, revocata: elm.revocata, utente_JFCAT: elm.user_sito, password_JFCAT: elm.password_sito, inviato_JFCAT: elm.inviato_JFCAT, revocato_JFCAT: elm.revocato_JFCAT}}); 
                                    document.getElementById(`gridCatAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsCatAssegnati;
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                }, true, true);   
                                if (!CustomJS.isNullEmpty(args.rowData.utente_JFCAT))
                                    this.revocaIncaricoJFCAT(args.rowData.utente_JFCAT, args.rowData.password_JFCAT, args.rowData.id_cat);                                                                                                    
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            }, true, true);
                        }, 500);                           
                    }, 
                    buttonModel: { 
                        content: 'CONFERMA', 
                        cssClass: 'e-primary',
                        isPrimary: true 
                    }
                    },
                    {
                        click: () => {
                            this.dialogInstance3.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
                this.dialogInstance3.show();                                       
            }  
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-check') > -1) { 
            if(args.rowData.chiusa === false && args.rowData.revocata === false && !CustomJS.isNullEmpty(args.rowData.t_riconsegnato)){
                let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = "Chiudere l'uscita del CAT?";
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '22%';
                this.dialogInstance3.minHeight = '22%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.dialogInstance3.hide();
                        setTimeout(() => { 
                            const dataObj = {
                                IdCollaboratore: args.rowData.id_collaboratore,
                                IdSinistro: document.getElementById(`idSinistro${idSin}`).value,
                                IdCat: args.rowData.id_cat,
                            };
                            const QueryExec = `UPDATE [01004] SET [01004].chiusa = 1 WHERE [01004].id_cat= @id_cat AND [01004].id_sinistro = @id_sinistro`;        
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                this.getStatoAzione(5).then(ret => {
                                    const id_stato_tmp = ret.stato;
                                    if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                                        document.getElementById(`idStato${idSin}`).value = id_stato_tmp.toString();
                                        this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value }); 
                                        this.aggiornaStatoSinistro(true).then(ret2 => {
                                            if (ret2 === 'OK') {                
                                                document.getElementById(`stato${idSin}`).ej2_instances[0].value = ret.descr_stato;
                                            }
                                        }).catch(messaggio => { 
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,           
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true}
                                            );
                                        });        
                                    }
                                    this.getAzioneNota(5).then(ret3 => {
                                        if (ret3 === true) {
                                            const dataObj = {
                                                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                                DescrNota: `Chiuso il CAT ${args.rowData.ragione_sociale} con data ${args.rowData.t_riconsegnato}`,
                                                Stato: id_stato_tmp
                                            };
                                            const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                                ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                                this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,           
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true}
                                                );
                                            }, true, true);
                                        }
                                    }).catch(messaggio => { 
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    });                
                                }).catch(messaggio => { 
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                });
                                const QueryExec7 = `SELECT [00006].ragione_sociale, [00006].telefono1, [00006].telefono2, [00006].email1, [00006].email2, [00006].user_sito, [00006].password_sito, [01004].* FROM [01004] INNER JOIN [00006] ON [01004].id_collaboratore = [00006].id_collaboratore WHERE [01004].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec7, '', retData7 => {
                                    this.dsCatAssegnati = retData7.map(elm => { return {id_cat: elm.id_cat, id_collaboratore: elm.id_collaboratore, ragione_sociale: elm.ragione_sociale,  t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_sopralluogo: CustomJS.isNullEmpty(elm.t_sopralluogo) ? '' : moment(elm.t_sopralluogo).format('DD/MM/YYYY HH:mm'), t_riconsegnato: CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), telefono1: elm.telefono1, telefono2: elm.telefono2, email1: elm.email1, email2: elm.email2, note_cat: elm.note_cat, chiusa: elm.chiusa, revocata: elm.revocata, utente_JFCAT: elm.user_sito, password_JFCAT: elm.password_sito, inviato_JFCAT: elm.inviato_JFCAT, revocato_JFCAT: elm.revocato_JFCAT}}); 
                                    document.getElementById(`gridCatAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsCatAssegnati;
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                }, true, true);                                                                                                          
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            }, true, true);
                        }, 500);                           
                    }, 
                    buttonModel: { 
                        content: 'CONFERMA', 
                        cssClass: 'e-primary',
                        isPrimary: true 
                    }
                    },
                    {
                        click: () => {
                            this.dialogInstance3.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
                this.dialogInstance3.show();                                       
            } 
            else{
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = 'Inserire la data di riconsegna!';
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '21%';
                this.dialogInstance3.minHeight = '21%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show();                                        
            }
        }  
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-envelope') > -1) { 
            let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
            if (document.getElementById(`idUbicazBene${idSin}`).value === '2') {
                if (CustomJS.isNullEmpty(document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim())) {
                    this.dialogInstance3.header = 'Attenzione!!';
                    this.dialogInstance3.content = 'Manca il campo INDIRIZZO!!';
                    this.dialogInstance3.width = '23%';
                    this.dialogInstance3.height = '21%';
                    this.dialogInstance3.minHeight = '21%';
                    this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                    this.dialogInstance3.show(); 
                    return;                                       
                }
                if (CustomJS.isNullEmpty(document.getElementById(`capUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim())) {
                    this.dialogInstance3.header = 'Attenzione!!';
                    this.dialogInstance3.content = 'Manca il campo CAP!!';
                    this.dialogInstance3.width = '23%';
                    this.dialogInstance3.height = '21%';
                    this.dialogInstance3.minHeight = '21%';
                    this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                    this.dialogInstance3.show(); 
                    return;                                       
                }
                if (CustomJS.isNullEmpty(document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim())) {
                    this.dialogInstance3.header = 'Attenzione!!';
                    this.dialogInstance3.content = 'Manca il campo CITTA\'!!';
                    this.dialogInstance3.width = '23%';
                    this.dialogInstance3.height = '21%';
                    this.dialogInstance3.minHeight = '21%';
                    this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                    this.dialogInstance3.show(); 
                    return;                                       
                }
                if (CustomJS.isNullEmpty(document.getElementById(`provinciaUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim())) {
                    this.dialogInstance3.header = 'Attenzione!!';
                    this.dialogInstance3.content = 'Manca il campo PROVINCIA!!';
                    this.dialogInstance3.width = '23%';
                    this.dialogInstance3.height = '21%';
                    this.dialogInstance3.minHeight = '21%';
                    this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                    this.dialogInstance3.show(); 
                    return;                                       
                }
            }
            if (CustomJS.isNullEmpty(document.getElementById(`appVerif${idSin}`).ej2_instances[0].value)) {
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = 'Manca il campo Apparecchi da Verificare!!';
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '21%';
                this.dialogInstance3.minHeight = '21%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show(); 
                return;                                       
            }
            const QueryExec = `SELECT [01001].*, [00005].nominativo, [00005].telefono1 FROM [01001] INNER JOIN [00005] on [01001].id_sinistro = [00005].id_sinistro WHERE [01001].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) {
                    if (CustomJS.isNullEmpty(retData[0].nominativo)) {
                        this.dialogInstance3.header = 'Attenzione!!';
                        this.dialogInstance3.content = 'Manca il campo NOMINATIVO DANNEGGIATO!!';
                        this.dialogInstance3.width = '23%';
                        this.dialogInstance3.height = '21%';
                        this.dialogInstance3.minHeight = '21%';
                        this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                        this.dialogInstance3.show(); 
                        return; 
                    }
                    if (CustomJS.isNullEmpty(retData[0].telefono1)) {
                        this.dialogInstance3.header = 'Attenzione!!';
                        this.dialogInstance3.content = 'Manca il campo TELEFONO DANNEGGIATO!!';
                        this.dialogInstance3.width = '23%';
                        this.dialogInstance3.height = '21%';
                        this.dialogInstance3.minHeight = '21%';
                        this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                        this.dialogInstance3.show(); 
                        return; 
                    }    
                }
                else {
                    this.dialogInstance3.header = 'Attenzione!!';
                    this.dialogInstance3.content = 'Mancano i dati relativi al DANNEGGIATO!!';
                    this.dialogInstance3.width = '23%';
                    this.dialogInstance3.height = '21%';
                    this.dialogInstance3.minHeight = '21%';
                    this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                    this.dialogInstance3.show(); 
                    return; 
                }                   
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = "Inviare la Scheda Incarico al CAT?";
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '22%';
                this.dialogInstance3.minHeight = '22%';
                this.dialogInstance3.buttons = [
                    {   click: () => { 
                            this.dialogInstance3.hide();
                            this.generaInviaSchedaIncaricoCAT(args.rowData).then(ret => {
                                if (ret === 0) {
                                }
                                else if (ret === 1) {
                                    toast.success('Email inviata con successo!!', {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,            
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    }); 
                                    this.getStatoAzione(7).then(ret2 => {
                                        const id_stato_tmp = ret2.stato;
                                        if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                                            document.getElementById(`idStato${idSin}`).value = id_stato_tmp.toString();
                                            this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value }); 
                                            this.aggiornaStatoSinistro(true).then(ret3 => {
                                                if (ret3 === 'OK') {                
                                                    document.getElementById(`stato${idSin}`).ej2_instances[0].value = ret3.descr_stato;
                                                }
                                            }).catch(messaggio => { 
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,           
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true}
                                                );
                                            });        
                                        }
                                        this.getAzioneNota(7).then(ret3 => {
                                            if (ret3 === true) {
                                                const dataObj = {
                                                    IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                                    Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                                    DescrNota: `Generata EMAIL incarico CAT ${args.rowData.ragione_sociale}<br/>${args.rowData.note_cat}`,
                                                    Stato: id_stato_tmp
                                                };
                                                const QueryExec2 = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                                    ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec2, JSON.stringify(dataObj), retData2 => {
                                                    this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                                }, messaggio => {
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,           
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true}
                                                    );
                                                }, true, true);
                                            }
                                        }).catch(messaggio => { 
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,           
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true}
                                            );
                                        });                
                                    }).catch(messaggio => { 
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    });      
                                }
                                else if (ret.retCode === 2) {
                                    toast.error(`Attenzione!! Parametri per invio Email errati. L' errore riscontrato è il seguente : ${JSON.stringify(ret.msgErr)}`, {
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 15000,
                                        hideProgressBar: false,            
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });  
                                }    
                            }).catch(messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            });
                        }, 
                        buttonModel: { 
                            content: 'CONFERMA', 
                            cssClass: 'e-primary',
                            isPrimary: true 
                        }
                    },
                    {
                        click: () => {
                            this.dialogInstance3.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
                this.dialogInstance3.show();             
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);         
        }  
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-paper-plane') > -1 && args.rowData.revocata === false) {
            this.creaIncaricoJFCAT(args.rowData.utente_JFCAT, args.rowData.password_JFCAT, args.rowData.id_cat);
        }  
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-paper-plane') > -1 && args.rowData.revocata === true) {
            this.revocaIncaricoJFCAT(args.rowData.utente_JFCAT, args.rowData.password_JFCAT, args.rowData.id_cat);
        } 
    }
    onCommandClickRfd(args){
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-times') > -1) {
            if(args.rowData.chiusa === false && args.rowData.revocata === false) {
                let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = "Revocare l'uscita del RFD?";
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '22%';
                this.dialogInstance3.minHeight = '22%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.dialogInstance3.hide();
                        setTimeout(() => { 
                            const dataObj = {
                                IdCollaboratore: args.rowData.id_collaboratore,
                                IdSinistro: document.getElementById(`idSinistro${idSin}`).value,
                                IdRfd: args.rowData.id_rfd,
                            };
                            const QueryExec = `UPDATE [01014] SET [01014].revocata = 1 WHERE [01014].id_rfd= @id_rfd AND [01014].id_sinistro = @id_sinistro`;        
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                this.getStatoAzione(10).then(ret => {
                                    const id_stato_tmp = ret.stato;
                                    if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                                        document.getElementById(`idStato${idSin}`).value = id_stato_tmp.toString();
                                        this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value }); 
                                        this.aggiornaStatoSinistro(true).then(ret2 => {
                                            if (ret2 === 'OK') {                
                                                document.getElementById(`stato${idSin}`).ej2_instances[0].value = ret.descr_stato;
                                            }
                                        }).catch(messaggio => { 
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,           
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true}
                                            );
                                        });        
                                    }
                                    this.getAzioneNota(10).then(ret3 => {
                                        if (ret3 === true) {
                                            const dataObj = {
                                                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                                DescrNota: `Revocato il RFD ${args.rowData.ragione_sociale}`,
                                                Stato: id_stato_tmp
                                            };
                                            const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                                ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                                this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,           
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true}
                                                );
                                            }, true, true);
                                        }
                                    }).catch(messaggio => { 
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    });                
                                }).catch(messaggio => { 
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                });   
                                const QueryExec8 = `SELECT [00006].ragione_sociale, [00006].telefono1, [00006].telefono2, [00006].email1, [00006].email2, [01014].* FROM [01014] INNER JOIN [00006] ON [01014].id_collaboratore = [00006].id_collaboratore WHERE [01014].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec8, '', retData8 => {
                                    this.dsRfdAssegnati = retData8.map(elm => { return {id_rfd: elm.id_rfd, id_collaboratore: elm.id_collaboratore, ragione_sociale: elm.ragione_sociale, t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_riconsegnato: CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), telefono1: elm.telefono1, telefono2: elm.telefono2, email1: elm.email1, email2: elm.email2, note_rfd: elm.note_rfd, chiusa: elm.chiusa, revocata: elm.revocata}}); 
                                    document.getElementById(`gridRFDAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsRfdAssegnati;      
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                }, true, true);                                                        
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            }, true, true); 
                        }, 500);                           
                    }, 
                        buttonModel: { 
                            content: 'CONFERMA', 
                            cssClass: 'e-primary',
                            isPrimary: true 
                        }
                    },
                    {
                        click: () => {
                            this.dialogInstance3.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
                this.dialogInstance3.show();                                       
            }  
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-check') > -1) { 
            if(args.rowData.chiusa === false && args.rowData.revocata === false && !CustomJS.isNullEmpty(args.rowData.t_riconsegnato)){
                let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = "Chiudere l'uscita del RFD?";
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '22%';
                this.dialogInstance3.minHeight = '22%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.dialogInstance3.hide();
                        setTimeout(() => { 
                            const dataObj = {
                                IdCollaboratore: args.rowData.id_collaboratore,
                                IdSinistro: document.getElementById(`idSinistro${idSin}`).value,
                                IdRfd: args.rowData.id_rfd,
                            };
                            const QueryExec = `UPDATE [01014] SET [01014].chiusa = 1 WHERE [01014].id_rfd= @id_rfd AND [01014].id_sinistro = @id_sinistro`;        
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                this.getStatoAzione(11).then(ret => {
                                    const id_stato_tmp = ret.stato;
                                    if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                                        document.getElementById(`idStato${idSin}`).value = id_stato_tmp.toString();
                                        this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value }); 
                                        this.aggiornaStatoSinistro(true).then(ret2 => {
                                            if (ret2 === 'OK') {                
                                                document.getElementById(`stato${idSin}`).ej2_instances[0].value = ret.descr_stato;
                                            }
                                        }).catch(messaggio => { 
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,           
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true}
                                            );
                                        });        
                                    }
                                    this.getAzioneNota(11).then(ret3 => {
                                        if (ret3 === true) {
                                            const dataObj = {
                                                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                                DescrNota: `Chiuso RFD ${args.rowData.ragione_sociale} con data ${args.rowData.t_riconsegnato}`,
                                                Stato: id_stato_tmp
                                            };
                                            const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                                ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                                this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,           
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true}
                                                );
                                            }, true, true);
                                        }
                                    }).catch(messaggio => { 
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    });                
                                }).catch(messaggio => { 
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                });
                                const QueryExec8 = `SELECT [00006].ragione_sociale, [00006].telefono1, [00006].telefono2, [00006].email1, [00006].email2, [01014].* FROM [01014] INNER JOIN [00006] ON [01014].id_collaboratore = [00006].id_collaboratore WHERE [01014].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec8, '', retData8 => {
                                    this.dsRfdAssegnati = retData8.map(elm => { return {id_rfd: elm.id_rfd, id_collaboratore: elm.id_collaboratore, ragione_sociale: elm.ragione_sociale, t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_riconsegnato: CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), telefono1: elm.telefono1, telefono2: elm.telefono2, email1: elm.email1, email2: elm.email2, note_rfd: elm.note_rfd, chiusa: elm.chiusa, revocata: elm.revocata}}); 
                                    document.getElementById(`gridRFDAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsRfdAssegnati;      
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                }, true, true);                                                        
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            }, true, true);
                        }, 500);                           
                    }, 
                    buttonModel: { 
                        content: 'CONFERMA', 
                        cssClass: 'e-primary',
                        isPrimary: true 
                    }
                    },
                    {
                    click: () => {
                        this.dialogInstance3.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
                this.dialogInstance3.show();                                       
            } 
            else{
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = 'Inserire la data di riconsegna!';
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '21%';
                this.dialogInstance3.minHeight = '21%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show();                                        
            }
        } 
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-envelope') > -1) { 
            let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
            if (CustomJS.isNullEmpty(document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim())) {
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = 'Manca il campo INDIRIZZO!!';
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '21%';
                this.dialogInstance3.minHeight = '21%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show(); 
                return;                                       
            }
            if (CustomJS.isNullEmpty(document.getElementById(`capUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim())) {
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = 'Manca il campo CAP!!';
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '21%';
                this.dialogInstance3.minHeight = '21%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show(); 
                return;                                       
            }
            if (CustomJS.isNullEmpty(document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim())) {
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = 'Manca il campo CITTA\'!!';
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '21%';
                this.dialogInstance3.minHeight = '21%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show(); 
                return;                                       
            }
            if (CustomJS.isNullEmpty(document.getElementById(`provinciaUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim())) {
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = 'Manca il campo PROVINCIA!!';
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '21%';
                this.dialogInstance3.minHeight = '21%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show(); 
                return;                                       
            }
            if (CustomJS.isNullEmpty(document.getElementById(`appDaRip${idSin}`).ej2_instances[0].value)) {
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = 'Manca il campo Apparecchi da Riparare!!';
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '21%';
                this.dialogInstance3.minHeight = '21%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show(); 
                return;                                       
            }                                     
            const QueryExec = `SELECT [01001].*, [00005].nominativo, [00005].telefono1 FROM [01001] INNER JOIN [00005] on [01001].id_sinistro = [00005].id_sinistro WHERE [01001].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) {
                    if (CustomJS.isNullEmpty(retData[0].nominativo)) {
                        this.dialogInstance3.header = 'Attenzione!!';
                        this.dialogInstance3.content = 'Manca il campo NOMINATIVO DANNEGGIATO!!';
                        this.dialogInstance3.width = '23%';
                        this.dialogInstance3.height = '21%';
                        this.dialogInstance3.minHeight = '21%';
                        this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                        this.dialogInstance3.show(); 
                        return; 
                    }
                    if (CustomJS.isNullEmpty(retData[0].telefono1)) {
                        this.dialogInstance3.header = 'Attenzione!!';
                        this.dialogInstance3.content = 'Manca il campo TELEFONO DANNEGGIATO!!';
                        this.dialogInstance3.width = '23%';
                        this.dialogInstance3.height = '21%';
                        this.dialogInstance3.minHeight = '21%';
                        this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                        this.dialogInstance3.show(); 
                        return; 
                    }    
                }
                else {
                    this.dialogInstance3.header = 'Attenzione!!';
                    this.dialogInstance3.content = 'Mancano i dati relativi al DANNEGGIATO!!';
                    this.dialogInstance3.width = '23%';
                    this.dialogInstance3.height = '21%';
                    this.dialogInstance3.minHeight = '21%';
                    this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                    this.dialogInstance3.show(); 
                    return; 
                }    
                const QueryExec2 = `SELECT [00006].* FROM [00006] WHERE [00006].id_Collaboratore= ${args.rowData.id_collaboratore}`;        
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec2, '', retData2 => {
                    if (retData2.length > 0) {
                        //if (CustomJS.isNullEmpty(retData2[0].indirizzo)) {
                        //    this.dialogInstance3.header = 'Attenzione!!';
                        //    this.dialogInstance3.content = 'Manca l\'INDIRIZZO del RIPARATORE!!';
                        //    this.dialogInstance3.width = '23%';
                        //    this.dialogInstance3.height = '21%';
                        //    this.dialogInstance3.minHeight = '21%';
                        //    this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                        //    this.dialogInstance3.show(); 
                        //    return; 
                        //}
                        //if (CustomJS.isNullEmpty(retData2[0].cap)) {
                        //    this.dialogInstance3.header = 'Attenzione!!';
                        //    this.dialogInstance3.content = 'Manca il CAP del RIPARATORE!!';
                        //    this.dialogInstance3.width = '23%';
                        //    this.dialogInstance3.height = '21%';
                        //    this.dialogInstance3.minHeight = '21%';
                        //    this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                        //    this.dialogInstance3.show(); 
                        //    return; 
                        //}
                        //if (CustomJS.isNullEmpty(retData2[0].citta)) {
                        //    this.dialogInstance3.header = 'Attenzione!!';
                        //    this.dialogInstance3.content = 'Manca la CITTA\' del RIPARATORE!!';
                        //    this.dialogInstance3.width = '23%';
                        //    this.dialogInstance3.height = '21%';
                        //    this.dialogInstance3.minHeight = '21%';
                        //    this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                        //    this.dialogInstance3.show(); 
                        //    return; 
                        //}
                        //if (CustomJS.isNullEmpty(retData2[0].provincia)) {
                        //    this.dialogInstance3.header = 'Attenzione!!';
                        //    this.dialogInstance3.content = 'Manca la PROVINCIA del RIPARATORE!!';
                        //    this.dialogInstance3.width = '23%';
                        //    this.dialogInstance3.height = '21%';
                        //    this.dialogInstance3.minHeight = '21%';
                        //    this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                        //    this.dialogInstance3.show(); 
                        //    return; 
                        //}
                        this.dialogInstance3.header = 'Attenzione!!';
                        this.dialogInstance3.content = "Inviare la Scheda Incarico al RFD?";
                        this.dialogInstance3.width = '23%';
                        this.dialogInstance3.height = '22%';
                        this.dialogInstance3.minHeight = '22%';
                        this.dialogInstance3.buttons = [
                            {   click: () => { 
                                    this.dialogInstance3.hide();
                                    this.generaInviaSchedaIncaricoRFD(args.rowData).then(ret => {
                                        if (ret === 0) {
                                        }
                                        else if (ret === 1) {
                                            toast.success('Email inviata con successo!!', {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,            
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            }); 
                                            this.getStatoAzione(12).then(ret2 => {
                                                const id_stato_tmp = ret2.stato;
                                                if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                                                    document.getElementById(`idStato${idSin}`).value = id_stato_tmp.toString();
                                                    this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value }); 
                                                    this.aggiornaStatoSinistro(true).then(ret3 => {
                                                        if (ret3 === 'OK') {                
                                                            document.getElementById(`stato${idSin}`).ej2_instances[0].value = ret3.descr_stato;
                                                        }
                                                    }).catch(messaggio => { 
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,           
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true}
                                                        );
                                                    });        
                                                }
                                                this.getAzioneNota(12).then(ret3 => {
                                                    if (ret3 === true) {
                                                        const dataObj = {
                                                            IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                                            Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                                            DescrNota: `Generata EMAIL incarico RFD ${args.rowData.ragione_sociale}<br/>${args.rowData.note_rfd}`,
                                                            Stato: id_stato_tmp
                                                        };
                                                        const QueryExec2 = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                                            ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec2, JSON.stringify(dataObj), retData2 => {
                                                            this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,           
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true}
                                                            );
                                                        }, true, true);
                                                    }
                                                }).catch(messaggio => { 
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,           
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true}
                                                    );
                                                });                
                                            }).catch(messaggio => { 
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,           
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true}
                                                );
                                            });      
                                        }
                                        else if (ret.retCode === 2) {
                                            toast.error(`Attenzione!! Parametri per invio Email errati. L' errore riscontrato è il seguente : ${JSON.stringify(ret.msgErr)}`, {
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 15000,
                                                hideProgressBar: false,            
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            });  
                                        }    
                                    }).catch(messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    });
                                }, 
                                buttonModel: { 
                                    content: 'CONFERMA', 
                                    cssClass: 'e-primary',
                                    isPrimary: true 
                                }
                            },
                            {
                                click: () => {
                                    this.dialogInstance3.hide();
                                },
                                buttonModel: {
                                    content: 'ANNULLA',
                                }
                            }
                        ];
                        this.dialogInstance3.show();    
                    }
                    else {
                        this.dialogInstance3.header = 'Attenzione!!';
                        this.dialogInstance3.content = 'Mancano i dati relativi al RIPARATORE!!';
                        this.dialogInstance3.width = '23%';
                        this.dialogInstance3.height = '21%';
                        this.dialogInstance3.minHeight = '21%';
                        this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                        this.dialogInstance3.show(); 
                        return;         
                    }
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );
                }, true, true);
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);
        }      
    }
    onCommandClickPeriti(args){
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-times') > -1) {
            if(args.rowData.chiusa === false && args.rowData.revocata === false){
                let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = "Revocare questa perizia?";
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '22%';
                this.dialogInstance3.minHeight = '22%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.dialogInstance3.hide();
                        setTimeout(() => { 
                            const dataObj = {
                                IdUtente: args.rowData.id_utente,
                                IdSinistro: document.getElementById(`idSinistro${idSin}`).value,
                                IdPerizia: args.rowData.id_perizia,
                            };
                            const QueryExec = `UPDATE [01002] SET [01002].revocata = 1 WHERE [01002].id_perizia= @id_perizia AND [01002].id_sinistro = @id_sinistro`;        
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                this.getStatoAzione(3).then(ret => {
                                    const id_stato_tmp = ret.stato;
                                    if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                                        document.getElementById(`idStato${idSin}`).value = id_stato_tmp.toString();
                                        this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value }); 
                                        this.aggiornaStatoSinistro(true).then(ret2 => {
                                            if (ret2 === 'OK') {                
                                                document.getElementById(`stato${idSin}`).ej2_instances[0].value = ret.descr_stato;
                                            }
                                        }).catch(messaggio => { 
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,           
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true}
                                            );
                                        });        
                                    }
                                    this.getAzioneNota(3).then(ret3 => {
                                        if (ret3 === true) {
                                            const dataObj = {
                                                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                                DescrNota: `Perizia revocata`,
                                                Stato: id_stato_tmp
                                            };
                                            const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                                ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                                this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,           
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true}
                                                );
                                            }, true, true);
                                        }
                                    }).catch(messaggio => { 
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    });                
                                }).catch(messaggio => { 
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                });   
                                const QueryExec9 = `SELECT CONCAT([09001].nome, ' ', [09001].cognome) AS nomeperito, [09001].email1, [01002].* FROM [01002] INNER JOIN [09001] ON [01002].id_utente = [09001].id_utente WHERE [01002].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec9, '', retData9 => {
                                    this.dsPeritiAssegnati = retData9.map(elm => { return { id_perizia: elm.id_perizia, id_utente: elm.id_utente, nomeperito: elm.nomeperito, t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_riconsegnato:CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), chiusa: elm.chiusa, revocata: elm.revocata, email: elm.email1}}); 
                                    document.getElementById(`gridPeritiAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsPeritiAssegnati;          
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                }, true, true);
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            }, true, true); 
                        }, 500);                           
                    }, 
                    buttonModel: { 
                        content: 'CONFERMA', 
                        cssClass: 'e-primary',
                        isPrimary: true 
                    }
                    },
                    {
                    click: () => {
                        this.dialogInstance3.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
                this.dialogInstance3.show();                                       
            }  
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-check') > -1) { 
            if(args.rowData.chiusa === false && args.rowData.revocata === false && !CustomJS.isNullEmpty(args.rowData.t_riconsegnato)){
                let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = "Chiudere questa Perizia?";
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '22%';
                this.dialogInstance3.minHeight = '22%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.dialogInstance3.hide();
                        setTimeout(() => { 
                            const dataObj = {
                                IdUtente: args.rowData.id_utente,
                                IdSinistro: document.getElementById(`idSinistro${idSin}`).value,
                                IdPerizia: args.rowData.id_perizia,
                            };
                            const QueryExec = `UPDATE [01002] SET [01002].chiusa = 1 WHERE [01002].id_perizia = @id_perizia AND [01002].id_sinistro = @id_sinistro`;        
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                this.getStatoAzione(6).then(ret => {
                                    const id_stato_tmp = ret.stato;
                                    if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                                        document.getElementById(`idStato${idSin}`).value = id_stato_tmp.toString();
                                        this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value }); 
                                        this.aggiornaStatoSinistro(true).then(ret2 => {
                                            if (ret2 === 'OK') {                
                                                document.getElementById(`stato${idSin}`).ej2_instances[0].value = ret.descr_stato;
                                            }
                                        }).catch(messaggio => { 
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,           
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true}
                                            );
                                        });        
                                    }
                                    this.getAzioneNota(6).then(ret3 => {
                                        if (ret3 === true) {
                                            const dataObj = {
                                                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                                DescrNota: `Perizia chiusa con data ${args.rowData.t_riconsegnato}`,
                                                Stato: id_stato_tmp
                                            };
                                            const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                                ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                                this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,           
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true}
                                                );
                                            }, true, true);
                                        }
                                    }).catch(messaggio => { 
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    });                
                                }).catch(messaggio => { 
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                });
                                const QueryExec9 = `SELECT CONCAT([09001].nome, ' ', [09001].cognome) AS nomeperito, [09001].email1, [01002].* FROM [01002] INNER JOIN [09001] ON [01002].id_utente = [09001].id_utente WHERE [01002].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec9, '', retData9 => {
                                    this.dsPeritiAssegnati = retData9.map(elm => { return { id_perizia: elm.id_perizia, id_utente: elm.id_utente, nomeperito: elm.nomeperito, t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_riconsegnato:CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), chiusa: elm.chiusa, revocata: elm.revocata, email: elm.email1}}); 
                                    document.getElementById(`gridPeritiAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsPeritiAssegnati;          
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                }, true, true);
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            }, true, true); 
                        }, 500);                           
                    }, 
                        buttonModel: { 
                            content: 'CONFERMA', 
                            cssClass: 'e-primary',
                            isPrimary: true 
                        }
                    },
                    {
                    click: () => {
                        this.dialogInstance3.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
                this.dialogInstance3.show();                                       
            } 
            else{
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = 'Inserire la data di riconsegna!';
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '21%';
                this.dialogInstance3.minHeight = '21%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show();                                        
            }
        } 
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-file-alt') > -1) { 
            let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
            if (document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === '3') {
                //UNIPOL
                if (document.getElementById(`ripristinoFormaDiretta${idSin}`).ej2_instances[0].value === 'Si') 
                    this.esportaSchedaPeritale(args.rowData.id_perizia, 40, args.rowData.nomeperito, false);
                else
                    this.esportaSchedaPeritale(args.rowData.id_perizia, 5, args.rowData.nomeperito, false);
            }
            else
                //GENERICA
                this.esportaSchedaPeritale(args.rowData.id_perizia, 5, args.rowData.nomeperito, false);
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-google') > -1) {
            this.esportaSchedaPeritale(args.rowData.id_perizia, 38, args.rowData.nomeperito, false);
        }        
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-file-signature') > -1) { 
            this.dialogInstance1.header = `PERIZIA SINISTRO N° ${this.tabInstance.items[this.tabInstance.selectedItem].id}`;   
            this.dialogInstance1.width = '90%';
            this.dialogInstance1.height = '90%';
            this.dialogInstance1.minHeight = '90%';
            this.dialogInstance1.buttons = [];  
            this.dialogInstance1.beforeClose = (args) => {
                let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;

                var stringaChiusuraPrima = document.getElementById(`istruzioniChiusura${idSin}`).ej2_instances[0].value;
                let stringaIstrChius = 'DEFINIZIONE :\n' + (document.getElementById('definizione').ej2_instances[0].text ?? '').trim() + '\n';  
                if(document.getElementById('GridValutazCatPerizie').ej2_instances[0].dataSource.length === 1){
                    let descrBene = !CustomJS.isNullEmpty(document.getElementById('GridValutazCatPerizie').ej2_instances[0].dataSource[0].descrBene) ? 
                                     document.getElementById('GridValutazCatPerizie').ej2_instances[0].dataSource[0].descrBene?.trim() : 
                                     (document.getElementById('GridValutazCatPerizie').ej2_instances[0].dataSource[0].altroBene ?? '')?.trim();
                    //1) caso di un bene con GFK:
                    if (document.getElementById('GridValutazCatPerizie').ej2_instances[0].dataSource[0].gfk === false) {
                        stringaIstrChius += 'GFK: NO\nTIPO BENE: ' + descrBene + '\n';
                    }
                    //2) caso di un bene senza GFK:
                    if (document.getElementById('GridValutazCatPerizie').ej2_instances[0].dataSource[0].gfk === true) {
                        stringaIstrChius += 'GFK: SI\nTIPO BENE: ' + descrBene + '\n';
                    }
                }
                else if(document.getElementById('GridValutazCatPerizie').ej2_instances[0].dataSource.length > 1){
                    var quantiGfkSi = 0;
                    var tipoBenePrimoGfk = '';
                    var tipoBenePrimoSenzaGfk = '';
                    document.getElementById('GridValutazCatPerizie').ej2_instances[0].dataSource.forEach(elm =>{
                        if(elm.gfk === true){
                            quantiGfkSi++;
                            tipoBenePrimoGfk = !CustomJS.isNullEmpty(elm.descrBene?.trim()) ? elm.descrBene?.trim() : (elm.altroBene ?? '')?.trim();
                        }
                        else if(elm.gfk === false){
                            tipoBenePrimoSenzaGfk = !CustomJS.isNullEmpty(elm.descrBene?.trim()) ? elm.descrBene?.trim() : (elm.altroBene ?? '').trim();
                        }
                    })
                    //3) caso di piu beni di cui uno solo con GFK SI:
                    if(quantiGfkSi === 1){
                        stringaIstrChius += 'GFK: SI\nTIPO BENE: ' + tipoBenePrimoGfk + '\n';
                    }
                    //4) caso di piu beni e piu di uno ha GFK SI:
                    else if(quantiGfkSi > 1){
                        stringaIstrChius += 'GFK: SI\nTIPO BENE: ' + tipoBenePrimoGfk + '\n';
                    }
                    //5) caso di piu beni e tutti senza GFK
                    else if(quantiGfkSi === 0){
                        stringaIstrChius += 'GFK: NO\nTIPO BENE: ' + tipoBenePrimoSenzaGfk + '\n';
                    }
                } 
                
                stringaIstrChius += 'RIVALSA: ' + (document.getElementById('rivalsa').ej2_instances[0].value ?? '') + '\n';
                stringaIstrChius += 'N.SCHEDE: ' + (document.getElementById('n_schede_compilate').ej2_instances[0].value ?? '0') + ';';

                if(!CustomJS.isNullEmpty(stringaChiusuraPrima)){
                    var ind = stringaChiusuraPrima.indexOf(';');
                    var testoAggiunto = stringaChiusuraPrima.substring(ind+1,stringaChiusuraPrima.length);
                    stringaIstrChius +=  testoAggiunto;
                }
                document.getElementById(`istruzioniChiusura${idSin}`).ej2_instances[0].value = stringaIstrChius; 

                this.aggiornaDettaglio('istruzioni_chiusura', document.getElementById(`istruzioniChiusura${idSin}`).ej2_instances[0].value, 'CHAR');

                let lines = document.getElementById('GridValutazCatPerizie').ej2_instances[0].dataSource.length * 2 + 6;
                document.getElementById(`istruzioniChiusura${idSin}`).ej2_instances[0].addAttributes({ rows: lines });
            }; 
            this.dialogInstance1.close = (args) => {
                this.mostraAlertAnomaliaBeni();
            } 
            this.dialogInstance1.beforeOpen = (args) => {
                const container = document.getElementById('defaultDialogContent');
                const root = createRoot(container);      
                root.unmount();                   
            }
            this.dialogInstance1.open = (args) => {
                setTimeout(() => {
                    ++this.childKey;
                    let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
                    const container = document.getElementById('defaultDialogContent');
                    const root = createRoot(container);                         
                    root.render(<PaginaPerizia key={this.childKey} IdSinistro={this.tabInstance.items[this.tabInstance.selectedItem].id} idSin={document.getElementById(`idSinistro${idSin}`).value} IdCompagnia={document.getElementById(`gruppo${idSin}`).ej2_instances[0].value} 
                                    cat={_.filter(document.getElementById(`gridCatAssegnati${idSin}`).ej2_instances[0].dataSource, obj => { return obj.chiusa === true && obj.revocata === false})[0]?.ragione_sociale} idCat={_.filter(document.getElementById(`gridCatAssegnati${idSin}`).ej2_instances[0].dataSource, obj => { return obj.chiusa === true && obj.revocata === false})[0]?.id_cat} richiestaIndennizzo={document.getElementById(`richiestaDenuncia${idSin}`).ej2_instances[0].value}></PaginaPerizia>);                     
                }, 150);
            };  
            this.dialogInstance1.show();  
        }        
    }
    onCommandClickImportaUnipolSAI(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-download') > -1) {
            this.toggleBlocking2(true);
            const dataObj = {
                APIUrl: CustomJS.APIURLTokenUnipolSAIProd,
                APIPath: "/returnJWTPIVA",
                NomeCert: CustomJS.NomeCertificatoUnipolSAI
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/getAuthTokenUnipolSAI`, JSON.stringify(dataObj), 'POST',
                'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                if (retData.response === 'OK') {
                    const dataObj2 = {
                        TipoChiamata: "DatiIncarico",
                        Metodo: 'GET',
                        APIUrl: CustomJS.APIURLDatiUnipolSAIProd,
                        APIPath: `${CustomJS.PathURLDatiUnipolSAIProd}/sinistri/swperitali/espullpush/v1/eventi/${args.rowData.guid_incarico}`,
                        x_ibm_client_secret: CustomJS.ClientSecretProduzioneUnipolSAI,
                        accept: 'application/json',
                        x_ibm_client_id: CustomJS.ClientIdProduzioneUnipolSAI,
                        x_unipol_requestid: 'x-unipol-req-id',
                        authorization_token_unipolsai: `Bearer ${retData.data}`,
                        Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                        Timeout: 3500
                    };
                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIUnipolSAI`, JSON.stringify(dataObj2), 'POST',
                        'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData2 => { 
                            const QueryExec = 'SELECT * FROM [10001]';
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData3 => {
                                if (retData3.length > 0) {
                                    retData3.forEach(elm => {
                                        elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                                        if (CustomJS.isNullEmpty(elm.id_interno_sinistro))
                                            elm.status = 'Da importare'
                                        else
                                            elm.status = 'Importato'
                                    });
                                    this.gridInstance8.dataSource = retData3;
                                }
                                else
                                    this.gridInstance8.dataSource = null;
                                toast.success(retData2.message, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                                //RIMOSSO DA QUESTO PUNTO PERCHE' VIENE FATTO QUANDO IL SINISTRO VA IN STATO "PRIMO CONTATTO"
                                //this.invioAccettazioneIncaricoUnipolSAI(args.rowData.id_incarico);       
                                this.toggleBlocking2(false);
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                                this.toggleBlocking2(false);
                            }, true, true);       
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        this.toggleBlocking2(false);
                    }, true, false);            
                }
                else if (retData.response === 'KO') {
                    toast.warn(retData.message, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }, true, false);    
        }
    }
    async onCommandClickImportaGenerali(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-download') > -1) {
            this.toggleBlocking2(true);
            const dataObj = {
                APIUrl: CustomJS.APIURLTokenGeneraliProd,
                APIPath: '/nidp/oauth/nam/token',
                Parametri: {
                    resourceServer: 'ml-b2b-internet',
                    acr_values: 'urn:oasis:names:tc:SAML:2.0:ac:classes:generali:form:ggaccount',
                    client_id: CustomJS.ClientIdTokenGenerali,
                    client_secret: CustomJS.ClientSecretProduzioneGENERALI,
                    scope: "APP-284-PRF-77",
                    grant_type: "password",
                    username: CustomJS.UtenteGENERALI,
                    password: await CustomJS.getParametroDB(52)
                } 
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/getAuthTokenGenerali`, JSON.stringify(dataObj), 'POST',
                'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                if (retData.response === 'OK') {
                    const dataObj2 = {
                        TipoChiamata: "DatiIncarico",
                        APIUrl: CustomJS.APIURLDatiGeneraliProd,
                        APIPath: `${CustomJS.PathURLDatiGeneraliProd}/api/Incarichi/DatiIncarico`,
                        accept: null,
                        content_type: 'application/json',
                        x_ibm_client_id: CustomJS.ClientIdProduzioneGENERALI,
                        authorization_token_generali: `Bearer ${retData.data.access_token}`,
                        Timeout: 3500,
                        Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                        Parametri: {
                            idIncarico: args.rowData.id_incarico
                        }
                    };
                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIGenerali`, JSON.stringify(dataObj2), 'POST',
                        'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData2 => { 
                            const QueryExec = 'SELECT * FROM [12001]';
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData3 => {
                                if (retData3.length > 0) {
                                    retData3.forEach(elm => {
                                        elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                                        if (CustomJS.isNullEmpty(elm.id_interno_sinistro))
                                            elm.status = 'Da importare'
                                        else
                                            elm.status = 'Importato'
                                    });
                                    this.gridInstance9.dataSource = retData3;
                                }
                                else
                                    this.gridInstance9.dataSource = null;
                                toast.success(retData2.message, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                                this.toggleBlocking2(false);
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                                this.toggleBlocking2(false);
                            }, true, true);       
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        this.toggleBlocking2(false);
                    }, true, false);            
                }
                else if (retData.response === 'KO') {
                    toast.warn(retData.message, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }, true, false);    
        }
    }
    onRecordDoubleClick(args) {   
        this.toggleBlocking(); 
        setTimeout(() => { 
            this.creaTab(args.rowData, false);
            this.toggleBlocking();  
        }, 1000); 
    }
    tabSelecting(args) {        
        if (args.isSwiped) {
            args.cancel = true;
        }        
    }
    tabSelecting2(args) {        
        if (args.isSwiped) {
            args.cancel = true;
        }        
    }
    tabSelected(args) { 
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.fotoGEOUnipolSAI = false;
        this.fotoRischioGENERALI = false;
        this.uppy.getPlugin('XHRUpload').setOptions({ endpoint: `${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumenti/JFTECH/${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username}/${this.tabInstance.items[this.tabInstance.selectedItem].id}/DOCS` })
        if (args.selectedIndex === 0) {
            $('#btnFinePag').hide();
            $('#btnVediNotif').hide();
            $('#divBtnCreaDocumPerizia').hide();
        }
        else {
            $('#btnFinePag').show();
            $('#btnVediNotif').show();
            $('#divBtnCreaDocumPerizia').show();
        }
        toast.dismiss();
        if (args.isInteracted && args.selectedIndex > 0) {
            setTimeout(() => { this.mostraAlertAnomaliaBeni(); }, 200);      
            setTimeout(() => { 
                if (CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value))
                    $('#btnVediNotif').hide();
                if (document.getElementById(`gridPeritiAssegnati${idSin}`).ej2_instances[0].dataSource.filter(elm => { return elm.revocata === false }).length === 0)
                    $('#divBtnCreaDocumPerizia').hide();
            }, 200);    
        }
    }
    tabSelected2(args2) {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec10 = `SELECT COUNT(*) AS PresenzaStima FROM [11004] WHERE [11004].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec10, '', retData10 => {
            if (retData10[0].PresenzaStima === 0)
                document.getElementById(`elaboratoCalcoli${idSin}`).ej2_instances[0].disabled = true;
            else
                document.getElementById(`elaboratoCalcoli${idSin}`).ej2_instances[0].disabled = false;
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, true, true);

        if (args2.selectedIndex === 0) {
            if (document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === CustomJS.codiceCompagniaUnipolSAI && !CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value))
                this.getDatiUnipolSAI(idSin);
            if (document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === CustomJS.codiceCompagniaGenerali && !CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value)) {
                //this.controlloDanneggiati();   PER ORA NON SERVE
                //this.getNumeroSinistroPolizzaPrecedente(objDettaglio.numero_polizza);  CAPIRE PER COSA SERVE ED EVENTUALMENTE USARE. SEMBRA NON SERVIRE A NULLA
                this.getInfoRichieste();
                this.getDanneggiati();
                this.getProdotto(); 
                this.contaLiquidazioni(); 
                this.getImportoLiquidazione();  
                this.getFotoRischioAssicurato();
            }
        }
    }
    btnControlloCat(args){
        ++this.childKey;
        this.dialogInstance5.header = `CONTROLLO CAT`;   
        this.dialogInstance5.width = '85%';
        this.dialogInstance5.height = '85%';
        this.dialogInstance5.minHeight = '85%';
        this.dialogInstance5.beforeOpen = (args) => {
            const container = document.getElementById('defaultDialogContent2');
            const root = createRoot(container);   
            root.unmount();
        };
        this.dialogInstance5.open = (args) => {
            const container = document.getElementById('defaultDialogContent2');
            const root = createRoot(container);   
            root.render(this.controlloCat());     
        };  
        this.dialogInstance5.show();                                                            
    }
    btnEstraiSingoloCat(){
        if(CustomJS.isNullEmpty(document.getElementById('selezionaCat').ej2_instances[0].value) || CustomJS.isNullEmpty(this.dateRangeInstanceCat.startDate) || CustomJS.isNullEmpty(this.dateRangeInstanceCat.endDate)){
            this.dialogInstance3.header = 'Attenzione!!';
            this.dialogInstance3.content = 'Selezionare CAT e data di controllo.';
            this.dialogInstance3.width = '23%';
            this.dialogInstance3.height = '21%';
            this.dialogInstance3.minHeight = '21%';
            this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
            this.dialogInstance3.show();    
            return;                                   
        }
        const QueryExec = `SELECT [00006].ragione_sociale, [01001].citta, [01001].cap, [01001].provincia, [01004].commenti_valutazioni, [01001].id_interno_sinistro, (SELECT (SELECT CONCAT([010041].valutazione,' - ') FROM [010041] WHERE [010041].id_cat= [01004].id_cat FOR XML PATH(''))) AS valutazioni, [01001].t_sopralluogo, [01004].t_riconsegnato, [00006].fisso AS fisso_cat, [00003].costo_cat_sinistro AS fisso_divisione, IIF(ISNULL([costo_cat_sinistro], '') = '', [fisso], [costo_cat_sinistro]) AS costo FROM [01001] INNER JOIN [00006] INNER JOIN [01004] ON [00006].id_collaboratore = [01004].id_collaboratore ON [01001].id_sinistro = [01004].id_sinistro LEFT JOIN [00003] ON [01001].id_divisione = [00003].id_divisione WHERE CONVERT(VARCHAR, [01004].t_riconsegnato, 112) BETWEEN ${moment(this.dateRangeInstanceCat.startDate).format('YYYYMMDD')} AND ${moment(this.dateRangeInstanceCat.endDate).format('YYYYMMDD')} AND [01004].chiusa=1 AND [01004].revocata=0 AND [00006].id_collaboratore=${document.getElementById('selezionaCat').ej2_instances[0].value};`;     
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            this.statistCat = retData.map(elm => { return {ragione_sociale: elm.ragione_sociale, citta: elm.citta, cap: elm.cap, provincia: elm.provincia, commenti_valutazioni: elm.commenti_valutazioni, id_interno_sinistro: elm.id_interno_sinistro, valutazioni: elm.valutazioni, t_sopralluogo: elm.t_sopralluogo === null ? '' : moment(elm.t_sopralluogo).format('DD/MM/YYYY'), t_riconsegnato: moment(elm.t_riconsegnato).format('DD/MM/YYYY'), fisso_cat : elm.fisso_cat, fisso_divisione: elm.fisso_divisione, costo: elm.costo}}); 
            document.getElementById(`divHidden`).style.removeProperty('display');   
            document.getElementById(`GridGeneraStatistCat`).ej2_instances[0].dataSource = this.statistCat;  
            document.getElementById('righeEstrContrCAT').innerHTML = `Totale righe estratte: ${this.statistCat.length.toLocaleString()}`;
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, true, true);  
    }
    btnEstraiTuttiCat(){
        if(CustomJS.isNullEmpty(this.dateRangeInstanceCat.startDate) || CustomJS.isNullEmpty(this.dateRangeInstanceCat.endDate)){
            this.dialogInstance3.header = 'Attenzione!!';
            this.dialogInstance3.content = 'Selezionare la data di controllo.';
            this.dialogInstance3.width = '23%';
            this.dialogInstance3.height = '21%';
            this.dialogInstance3.minHeight = '21%';
            this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
            this.dialogInstance3.show();   
            return;                                                                       
        }
        const QueryExec = `SELECT [00006].ragione_sociale, [01001].citta, [01001].cap, [01001].provincia, [01004].commenti_valutazioni, [01001].id_interno_sinistro, (SELECT (SELECT CONCAT([010041].valutazione,' - ') FROM [010041] WHERE [010041].id_cat= [01004].id_cat FOR XML PATH(''))) AS valutazioni, [01001].t_sopralluogo, [01004].t_riconsegnato, [00006].fisso AS fisso_cat, [00003].costo_cat_sinistro AS fisso_divisione, IIF(ISNULL([costo_cat_sinistro], '') = '', [fisso], [costo_cat_sinistro]) AS costo FROM [01001] INNER JOIN ([00006] INNER JOIN [01004] ON [00006].id_collaboratore = [01004].id_collaboratore) ON [01001].id_sinistro = [01004].id_sinistro LEFT JOIN [00003] ON [01001].id_divisione = [00003].id_divisione WHERE CONVERT(VARCHAR, [01004].t_riconsegnato, 112) BETWEEN ${moment(this.dateRangeInstanceCat.startDate).format('YYYYMMDD')} AND ${moment(this.dateRangeInstanceCat.endDate).format('YYYYMMDD')} AND [01004].chiusa=1 AND [01004].revocata=0 ORDER BY [00006].ragione_sociale;`;     
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            this.statistCat = retData.map(elm => { return {ragione_sociale: elm.ragione_sociale, citta: elm.citta, cap: elm.cap, provincia: elm.provincia, commenti_valutazioni: elm.commenti_valutazioni, id_interno_sinistro: elm.id_interno_sinistro, valutazioni: elm.valutazioni, t_sopralluogo: elm.t_sopralluogo === null ? '' : moment(elm.t_sopralluogo).format('DD/MM/YYYY'), t_riconsegnato: moment(elm.t_riconsegnato).format('DD/MM/YYYY'), fisso_cat : elm.fisso_cat, fisso_divisione: elm.fisso_divisione, costo: elm.costo}}); 
            document.getElementById(`divHidden`).style.removeProperty('display');   
            document.getElementById(`GridGeneraStatistCat`).ej2_instances[0].dataSource = this.statistCat;  
            document.getElementById('selezionaCat').ej2_instances[0].value = null;
            document.getElementById('righeEstrContrCAT').innerHTML = `Totale righe estratte: ${this.statistCat.length.toLocaleString()}`;
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, true, true);  
    }
    controlloCat(){
        return(<React.Fragment>
            <div className='container-fluid'> 
                <div className='row'>
                    <div className='col-lg-5 col-md-5 col-sm-5 col-xs-5'> 
                        <DropDownListComponent id='selezionaCat' fields={this.ddlFields} dataSource={this.dsCATCompleto}
                            allowFiltering={true} filterType='Contains' showClearButton={true} key={this.childKey}
                            placeholder="Seleziona CAT" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                        </DropDownListComponent>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <DateRangePickerComponent id='RangeDateControlloCat' ref={drp => this.dateRangeInstanceCat = drp} cssClass='e-outline' format='dd/MM/yyyy'
                            showClearButton={true} placeholder='Data Controllo (Da - A)' floatLabelType='Always' key={this.childKey}></DateRangePickerComponent>
                    </div>   
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" >
                        <ProgressButtonComponent id='btnEstraiSingolo' content="ESTRAI SINGOLO" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }}
                            animationSettings={{effect: 'SlideRight'}} cssClass="e-fill e-info" onClick={this.btnEstraiSingoloCat.bind(this)}></ProgressButtonComponent> 
                    </div>     
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                        <ProgressButtonComponent id='btnEstraiTutti' content="ESTRAI TUTTI" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }}
                            animationSettings={{effect: 'SlideRight'}} cssClass="e-fill e-info" onClick={this.btnEstraiTuttiCat.bind(this)}></ProgressButtonComponent> 
                    </div>       
                </div>
                <div className='row'>  
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'> 
                        <div id='divHidden' style={{ display:'none'}} key={this.childKey}>
                            <GridComponent id="GridGeneraStatistCat" dataSource={this.state.statistCat} ref={ grid => this.gridInstance2 = grid } toolbar={this.toolbarOptionsControlloCat} toolbarClick={this.toolbarClickContrCAT.bind(this)} 
                                editSettings={this.editSettings} allowSelection={false} enableHover={true} showColumnMenu={false} gridLines='Both' allowExcelExport={true} height='400px' enableInfiniteScrolling={true}
                                allowTextWrap={true} allowPaging={false} allowSorting={true} allowFiltering={false} enableAltRow={false} enablePersistence={false} key={this.childKey}
                                pageSettings={this.pageSettingsControlloCat}>
                                <ColumnsDirective>
                                    <ColumnDirective field='id_interno_sinistro' headerText='Numero Sinistro' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='ragione_sociale' headerText="Ragione Sociale"  headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='commenti_valutazioni' headerText="Commenti Valutazioni" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='citta' headerText="Città" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='cap' headerText="CAP"  headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='provincia' headerText="Provincia"  headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='valutazioni' headerText="Valutazioni"  headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='t_sopralluogo' headerText="Data Sopralluogo" format='dd/MM/yyyy' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='t_riconsegnato' headerText="Data Riconsegna" format='dd/MM/yyyy' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='fisso_cat' headerText="Fisso CAT"  headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='fisso_divisione' headerText="Fisso Divisione"  headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='costo' headerText="Costo" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Group, Page, InfiniteScroll ]} />
                            </GridComponent> 
                            <br/> 
                            <span id='righeEstrContrCAT' style={{ fontSize: '0.9vw', fontWeight: '500' }}></span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
    btnControlloRfd(args){
        ++this.childKey;
        this.dialogInstance5.header = `CONTROLLO RFD`;    
        this.dialogInstance5.width = '75%';
        this.dialogInstance5.height = '85%';
        this.dialogInstance5.minHeight = '85%';
        this.dialogInstance5.beforeOpen = (args) => {
            const container = document.getElementById('defaultDialogContent2');
            const root = createRoot(container);   
            root.unmount();
        };
        this.dialogInstance5.open = (args) => {
            const container = document.getElementById('defaultDialogContent2');
            const root = createRoot(container);   
            root.render(this.controlloRfd());    
        };  
        this.dialogInstance5.show();  
    }
    btnEstraiSingoloRfd(){
        if(CustomJS.isNullEmpty(document.getElementById('selezionaRfd').ej2_instances[0].value) || CustomJS.isNullEmpty(this.dateRangeInstanceRfd.startDate) || CustomJS.isNullEmpty(this.dateRangeInstanceRfd.endDate)){
            this.dialogInstance3.header = 'Attenzione!!';
            this.dialogInstance3.content = 'Selezionare RFD e data di controllo.';
            this.dialogInstance3.width = '23%';
            this.dialogInstance3.height = '21%';
            this.dialogInstance3.minHeight = '21%';
            this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
            this.dialogInstance3.show();    
            return;                                   
        }
        const QueryExec = `SELECT [01001].id_interno_sinistro, [00006].ragione_sociale, [01001].ass_nominativo AS danneggiato, [01001].citta, [01001].cap, [01001].provincia, [01014].t_assegnato AS data_incarico, [01014].t_riconsegnato AS data_riconsegnato FROM [00006] INNER JOIN [01001] INNER JOIN [00003] ON [01001].id_divisione = [00003].id_divisione INNER JOIN [01014] ON [01001].id_sinistro = [01014].id_sinistro ON [00006].id_collaboratore = [01014].id_collaboratore WHERE CONVERT(VARCHAR, [01014].t_assegnato, 112) BETWEEN ${moment(this.dateRangeInstanceRfd.startDate).format('YYYYMMDD')} AND ${moment(this.dateRangeInstanceRfd.endDate).format('YYYYMMDD')} AND [00006].id_collaboratore=${document.getElementById('selezionaRfd').ej2_instances[0].value} AND [01014].revocata=0;`;     
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            this.statistRfd = retData.map(elm => { return {ragione_sociale: elm.ragione_sociale, citta: elm.citta, cap: elm.cap, provincia: elm.provincia, danneggiato: elm.danneggiato, id_interno_sinistro: elm.id_interno_sinistro, data_incarico: moment(elm.data_incarico).format('DD/MM/YYYY'), data_riconsegnato: CustomJS.isNullEmpty(elm.data_riconsegnato) ? null : moment(elm.data_riconsegnato).format('DD/MM/YYYY')}}); 
            document.getElementById(`divHidden`).style.removeProperty('display');   
            document.getElementById(`GridGeneraStatistRfd`).ej2_instances[0].dataSource = this.statistRfd;  
            document.getElementById('righeEstrContrRFD').innerHTML = `Totale righe estratte: ${this.statistRfd.length.toLocaleString()}`;
        }, messaggio => {
            toast.warn(messaggio, {
            containerId: 'toastContainer1',
            onClose: () => toast.dismiss(),
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,           
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            newestOnTop: true,
            rtl: false,
            pauseOnFocusLoss: true}
            );
        }, true, true);  
    }
    btnEstraiTuttiRfd(){
        if( CustomJS.isNullEmpty(this.dateRangeInstanceRfd.startDate) || CustomJS.isNullEmpty(this.dateRangeInstanceRfd.endDate)){
            this.dialogInstance3.header = 'Attenzione!!';
            this.dialogInstance3.content = 'Selezionare la data di controllo.';
            this.dialogInstance3.width = '23%';
            this.dialogInstance3.height = '21%';
            this.dialogInstance3.minHeight = '21%';
            this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
            this.dialogInstance3.show();
            return;                                                                          
        }
        const QueryExec = `SELECT [01001].id_interno_sinistro, [00006].ragione_sociale, [01001].ass_nominativo AS danneggiato, [01001].citta, [01001].cap, [01001].provincia, [01014].t_assegnato AS data_incarico, [01014].t_riconsegnato AS data_riconsegnato FROM [00006] INNER JOIN [01001] INNER JOIN [00003] ON [01001].id_divisione = [00003].id_divisione INNER JOIN [01014] ON [01001].id_sinistro = [01014].id_sinistro ON [00006].id_collaboratore = [01014].id_collaboratore WHERE CONVERT(VARCHAR, [01014].t_assegnato, 112) BETWEEN ${moment(this.dateRangeInstanceRfd.startDate).format('YYYYMMDD')} AND ${moment(this.dateRangeInstanceRfd.endDate).format('YYYYMMDD')} AND [01014].revocata=0 ORDER BY [00006].ragione_sociale;`;     
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            this.statistRfd = retData.map(elm => { return {ragione_sociale: elm.ragione_sociale, citta: elm.citta, cap: elm.cap, provincia: elm.provincia, danneggiato: elm.danneggiato, id_interno_sinistro: elm.id_interno_sinistro, data_incarico: moment(elm.data_incarico).format('DD/MM/YYYY'), data_riconsegnato: CustomJS.isNullEmpty(elm.data_riconsegnato) ? null : moment(elm.data_riconsegnato).format('DD/MM/YYYY')}}); 
            document.getElementById(`divHidden`).style.removeProperty('display');   
            document.getElementById(`GridGeneraStatistRfd`).ej2_instances[0].dataSource = this.statistRfd; 
            document.getElementById('selezionaRfd').ej2_instances[0].value = null; 
            document.getElementById('righeEstrContrRFD').innerHTML = `Totale righe estratte: ${this.statistRfd.length.toLocaleString()}`;
        }, messaggio => {
            toast.warn(messaggio, {
            containerId: 'toastContainer1',
            onClose: () => toast.dismiss(),
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,           
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            newestOnTop: true,
            rtl: false,
            pauseOnFocusLoss: true});
        }, true, true);  
    }
    reportSinistriImportatiRender() {
        return(<React.Fragment>
            <div className='container-fluid'> 
                <div className='row'>  
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'> 
                        <GridComponent id="GridSinistriImportati" ref={ grid => this.gridInstance4 = grid } toolbar={this.toolbarOptionsSinistriImport} toolbarClick={this.toolbarClickSinistriImport.bind(this)} 
                            editSettings={this.editSettings} allowSelection={false} enableHover={false} showColumnMenu={false} gridLines='Both' allowExcelExport={true} height='550px' 
                            allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={false} enableAltRow={true} queryCellInfo={this.onQueryCellInfoSinistriImport.bind(this)}
                            pageSettings={{pageSize : 1000, pageCount : 1, pageSizes: []}}>
                            <ColumnsDirective>
                                <ColumnDirective field='NumeroSinistro' headerText='Numero Sinistro Compagnia' headerTemplate={headerTemplate} disableHtmlEncode={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='NominativoContraente' headerText="Nominativo Contraente"  headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='DescrizioneSinistro' headerText="Descrizione Sinistro" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='Esito' headerText="Esito Importazione" headerTemplate={headerTemplate} textAlign='Center' template={fieldTemplate4} clipMode="EllipsisWithTooltip"></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Group, Page]} />
                        </GridComponent> 
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
    renderImportaUnipolSAI() {
        return (<React.Fragment>
            <div className="container-fluid">
                <div className='row' style={{ paddingTop: '50px' }}>
                    <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'>
                        <ButtonComponent id='sincronizzaIncarichiUnipolSAI' cssClass='e-fill e-info' onClick={this.sincronizzaIncarichiUnipolSAI.bind(this)}>Sincronizza Incarichi</ButtonComponent>
                    </div>
                    <div className='col-lg-2 offset-lg-7 col-md-2 offset-md-7 col-sm-2 offset-sm-7 col-xs-2 offset-xs-7'>
                        <ButtonComponent id='importaIncarichiSelezionatiUnipolSAI' cssClass='e-fill e-info' onClick={this.importaIncarichiSelezionatiUnipolSAI.bind(this)}>Importa Incarichi Selezionati</ButtonComponent>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <GridComponent id="GridImportUnipolSAI" rowDataBound={this.onRowDataBound2.bind(this)} ref={grid => this.gridInstance8 = grid} toolbar={this.toolbarOptions2} hierarchyPrintMode='All' commandClick={this.onCommandClickImportaUnipolSAI.bind(this)}
                            allowSelection={true} enableHover={false} sortSettings={{ columns: [{ field: 'status', direction: 'Ascending' }, { field: 'id_interno_sinistro', direction: 'Descending' }] }} allowTextWrap={true} toolbarClick={this.toolbarClickImportUnipolSAI.bind(this)} 
                            allowPaging={true} allowSorting={true} allowFiltering={true} enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings2} allowGrouping={false} 
                            allowExcelExport={true} filterSettings={this.filterSettings} selectionSettings={{ type: 'Multiple', checkboxMode: 'ResetOnRowClick', mode: 'Row', enableToggle: true, checkboxOnly: true }}>
                            <ColumnsDirective>
                                <ColumnDirective type='checkbox' allowSorting={false} allowFiltering={false} width='60'></ColumnDirective>
                                <ColumnDirective field='id_incarico' validationRules={{ required: true }} headerText='ID UnipolSAI' width='13%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='numero_sinistro' headerText="Num. Sinistro Compagnia" width='17%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='nome_contraente' headerText="Contraente " width='33%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='data_incarico' headerText="Data Incarico" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='id_interno_sinistro' headerText="ID Interno Sinistro" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='status' headerText="Status" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='command' commands={this.commands2} headerText="" width='50' headerTemplate={headerTemplate} textAlign='Center' allowFiltering={false} allowSorting={false} ></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Toolbar, ExcelExport, Filter, CommandColumn, Sort, Page]} />
                        </GridComponent>
                    </div>
                </div>
            </div>
        </React.Fragment >);
    }
    renderImportaGenerali() {
        return (<React.Fragment>
            <div className="container-fluid">
                <div className='row' style={{ marginTop: '50px' }}>
                    <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3' style={{ marginTop: '-30px', marginLeft: '40px' }}>
                        <DateRangePickerComponent style={{ marginLeft: '5%' }} id="dateRangeIncarichi" ref={drObj => this.drInstance1 = drObj} placeholder='Data Sincronizzazone Da-A' floatLabelType='Always' />
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'>
                        <ButtonComponent id='sincronizzaIncarichiGenerali' style={{ marginTop: '-10px' }} cssClass='e-fill e-info' onClick={this.sincronizzaIncarichiGenerali.bind(this)}>Sincronizza Incarichi</ButtonComponent>
                    </div>
                    <div className='col-lg-2 offset-lg-4 col-md-2 offset-md-4 col-sm-2 offset-sm-4 col-xs-2 offset-xs-4'>
                        <ButtonComponent id='importaIncarichiSelezionatiGenerali' cssClass='e-fill e-info' onClick={this.importaIncarichiSelezionatiGenerali.bind(this)}>Importa Incarichi Selezionati</ButtonComponent>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <GridComponent id="GridImportGenerali" rowDataBound={this.onRowDataBound2.bind(this)} ref={grid => this.gridInstance9 = grid} toolbar={this.toolbarOptions2} hierarchyPrintMode='All' commandClick={this.onCommandClickImportaGenerali.bind(this)}
                            allowSelection={true} enableHover={false} sortSettings={{ columns: [{ field: 'status', direction: 'Ascending' }, { field: 'id_interno_sinistro', direction: 'Descending' }] }} allowTextWrap={true} toolbarClick={this.toolbarClickImportGenerali.bind(this)} allowPaging={true} allowSorting={true} allowFiltering={true}
                            enableAltRow={true} enablePersistence={false} pageSettings={this.pageSettings2} allowGrouping={false} allowExcelExport={true} filterSettings={this.filterSettings}
                            selectionSettings={{ type: 'Multiple', checkboxMode: 'ResetOnRowClick', mode: 'Row', enableToggle: true, checkboxOnly: true }}>
                            <ColumnsDirective>
                                <ColumnDirective type='checkbox' allowSorting={false} allowFiltering={false} width='60'></ColumnDirective>
                                <ColumnDirective field='id_incarico' validationRules={{ required: true }} headerText='ID Generali' width='12%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='numero_sinistro' headerText="Num. Sinistro" width='20%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='nome_contraente' headerText="Contraente " width='33%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='data_incarico' headerText="Data Incarico" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='id_interno_sinistro' headerText="ID Interno Sinistro" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='status' headerText="Status" width='15%' headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                <ColumnDirective field='command' commands={this.commands2} headerText="" width='5%' headerTemplate={headerTemplate} textAlign='Center' allowFiltering={false} allowSorting={false} ></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Toolbar, ExcelExport, Filter, CommandColumn, Sort, Page]} />
                        </GridComponent>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
   btnEstraiStatisticheGenerali() {
        this.gridInstance5.dataSource = null;
        this.gridInstance5.columns = [];
        this.gridInstance5.refreshColumns();
        this.gridInstance5.refresh();
        const QueryExec = `SELECT * FROM [dati] WHERE t_incarico >= '${moment(this.dateRangeInstanceEstrFoglioStat.startDate).format('YYYYMMDD')}' AND t_incarico <= '${moment(this.dateRangeInstanceEstrFoglioStat.endDate).format('YYYYMMDD')}'`;
        this.toggleBlocking();     
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            if (retData.length === 0) {
                this.toggleBlocking();
                return;
            }
            let dsFoglioStat = retData;
            dsFoglioStat.forEach(elm => {
                elm.t_incarico = CustomJS.isNullEmpty(elm.t_incarico) ? '' : moment(elm.t_incarico, 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm["Data carico"] = CustomJS.isNullEmpty(elm["Data carico"]) ? '' : moment(elm["Data carico"], 'YYYY-MM-DD').format('DD/MM/YYYY'); 
                elm.t_chiusura = CustomJS.isNullEmpty(elm.t_chiusura) ? '' : moment(elm.t_chiusura, 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm.t_sopralluogo = CustomJS.isNullEmpty(elm.t_sopralluogo) ? '' : moment(elm.t_sopralluogo, 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm.t_sinistro = CustomJS.isNullEmpty(elm.t_sinistro) ? '' : moment(elm.t_sinistro, 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm.t_denuncia = CustomJS.isNullEmpty(elm.t_denuncia) ? '' : moment(elm.t_denuncia, 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm["Data cat"] = CustomJS.isNullEmpty(elm["Data cat"]) ? '' : moment(elm["Data cat"], 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm.data_chiusura_cat = CustomJS.isNullEmpty(elm.data_chiusura_cat) ? '' : moment(elm.data_chiusura_cat, 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm.data_chiusura_perizia = CustomJS.isNullEmpty(elm.data_chiusura_perizia) ? '' : moment(elm.data_chiusura_perizia, 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm.data_timer = CustomJS.isNullEmpty(elm.data_timer) ? '' : moment(elm.data_timer, 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm.t_assegnato = CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato, 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm.t_riconsegnato = CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato, 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm.data_fattura = CustomJS.isNullEmpty(elm.data_fattura) ? '' : moment(elm.data_fattura, 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm.dataAssegnCQ = CustomJS.isNullEmpty(elm.dataAssegnCQ) ? '' : moment(elm.dataAssegnCQ, 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm.dataAssegnCR = CustomJS.isNullEmpty(elm.dataAssegnCR) ? '' : moment(elm.dataAssegnCR, 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm["Data CQ"] = CustomJS.isNullEmpty(elm["Data CQ"]) ? '' : moment(elm["Data CQ"], 'YYYY-MM-DD').format('DD/MM/YYYY');
                elm["Data CR"] = CustomJS.isNullEmpty(elm["Data CR"]) ? '' : moment(elm["Data CR"], 'YYYY-MM-DD').format('DD/MM/YYYY');
            });
            // const dataObj = {
            //     datiStatistiche: retData,
            //     NomeUtente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username
            // };
            // CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/ExcelCreator/CreaFoglioStatisticheACT`, { datiStatisticheJSON: retData, NomeUtente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username }, 'POST', 
            //{ "Authorization" : CustomJS.tokenAuthAPIVarious }, retData2 => {
            //    this.toggleBlocking();     
            // }, error => {
            //     let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            //     toast.warn(messaggio, {
            //         containerId: 'toastContainer1',
            //         onClose: () => toast.dismiss(),                    
            //         position: "bottom-right",
            //         autoClose: 15000,
            //         hideProgressBar: false,            
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: false,
            //         progress: undefined,
            //         newestOnTop: true,
            //         rtl: false,
            //         pauseOnFocusLoss: true});
            //     this.toggleBlocking();
            // }, true, false);  
            this.gridInstance5.dataSource = dsFoglioStat;
            this.gridInstance5.excelExportComplete = (args) => {
                this.toggleBlocking();
                this.gridInstance5.dataSource = null;
                this.gridInstance5.columns = [];
                this.gridInstance5.refreshColumns();
                this.gridInstance5.refresh();
            };
            // this.gridInstance5.excelQueryCellInfo = (args) => {
            //     if (args.column.field === 'Data carico') {   
            //         let intl = new Internationalization();             
            //         let dFormatter = intl.getDateFormat({ format: "dd MMM yyyy", type: 'date', skeleton: "dd MMM yyyy" }); 
            //         let formattedString = dFormatter(new Date(args.value)); 
            //         args.value = formattedString;      
            //     } 
            // };
            setTimeout(() => { 
                const exportProperties = {
                    fileName : `${moment(new Date()).format('YYYYMMDD')}_${moment(new Date()).format('HHMMSS')}_StatisticheGenerali.xlsx`
                }
                this.gridInstance5.excelExport(exportProperties);
            }, 500);  
            if (this.chkInstance3.checked === true) {
                this.toggleBlocking();
            }
            else
                return;
            const QueryExec = `SELECT DISTINCT [01001].id_interno_sinistro AS 'ID Sinistro', [00002].descrizione AS Compagnia, CONCAT([09001].nome, ' ', [09001].cognome) AS Perito, [09009].bene AS Bene, [010041].altroBene AS 'Altro Bene', [01001].numero_sinistro_agenzia AS 'Numero Sinistro Compagnia', [02001].nome AS 'Tipo Polizza', [02001].modello AS 'Modello Polizza', [02001].edizione AS 'Edizione Polizza', [01001].t_chiusura FROM [01001] INNER JOIN [01004] ON [01001].id_sinistro = [01004].id_sinistro LEFT OUTER JOIN [010041] ON [01004].id_cat = [010041].id_cat LEFT OUTER JOIN [01002] ON [01001].id_sinistro = [01002].id_sinistro LEFT OUTER JOIN [00002] ON [01001].id_compagnia = [00002].id_compagnia LEFT OUTER JOIN [09009] ON [09009].ID = [010041].bene LEFT OUTER JOIN [09001] ON [09001].id_utente = [01002].id_utente LEFT OUTER JOIN [02001] ON [01001].id_tipo_polizza = [02001].id_tipo_polizza WHERE [01001].t_chiusura BETWEEN '${moment(this.dateRangeInstanceEstrFoglioStat.startDate).format('YYYYMMDD')}' AND '${moment(this.dateRangeInstanceEstrFoglioStat.endDate).format('YYYYMMDD')}' AND (ISNULL([09009].bene,'') <> '' OR ISNULL([010041].altroBene,'') <> '') AND [01002].chiusa = 1 AND [01002].revocata = 0 AND [01001].id_stato NOT IN (51,23,24,52,49) ORDER BY [01001].t_chiusura`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData2 => {
                if (retData2.length === 0)
                    return;
                let dsFoglioStat = retData2;
                this.gridInstance6.dataSource = dsFoglioStat;
                this.gridInstance6.excelExportComplete = (args) => {
                    this.toggleBlocking();
                    this.gridInstance6.dataSource = null;
                };
                setTimeout(() => { 
                    const exportProperties = {
                        fileName : `${moment(new Date()).format('YYYYMMDD')}_${moment(new Date()).format('HHMMSS')}_StatisticheSinistriConBeni.xlsx`,
                    }
                    this.gridInstance6.columns[9].visible = false;
                    this.gridInstance6.excelExport(exportProperties);
                }, 800);  
            }, messaggio => {
                toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
                );
            }, true, true);   
        }, messaggio => {
            toast.warn(messaggio, {
            containerId: 'toastContainer1',
            onClose: () => toast.dismiss(),
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,           
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            newestOnTop: true,
            rtl: false,
            pauseOnFocusLoss: true}
            );
        }, true, true);      
    }
    btnEstraiStatisticheBeni() {
        const QueryExec = `SELECT [00002].descrizione AS Compagnia,[09009].bene AS Bene,[010041].altroBene AS 'Altro Bene',SUM(CASE WHEN [010041].gfk = 1 THEN 1 ELSE 0 END) AS 'gfkSI (Totale)',SUM(CASE WHEN [010041].gfk = 0 THEN 1 ELSE 0 END) AS 'gfkNO (Totale)',SUM(CASE WHEN ([010041].gfk = 1 AND [010041].valore_bene_nuovo <= 500) THEN 1 ELSE 0 END) AS 'gfkSI (Valore a Nuovo <= 500)',SUM(CASE WHEN ([010041].gfk = 0 AND [010041].valore_bene_nuovo <= 500) THEN 1 ELSE 0 END) AS 'gfkNO (Valore a Nuovo <= 500)', SUM(CASE WHEN ([010041].gfk = 1 AND [010041].valore_bene_nuovo BETWEEN 501 AND 3000) THEN 1 ELSE 0 END) AS 'gfkSI (Valore a Nuovo tra 501 e 3000)', SUM(CASE WHEN ([010041].gfk = 0 AND [010041].valore_bene_nuovo BETWEEN 501 AND 3000) THEN 1 ELSE 0 END) AS 'gfkNO (Valore a Nuovo tra 501 e 3000)', SUM(CASE WHEN ([010041].gfk = 1 AND [010041].valore_bene_nuovo > 3000) THEN 1 ELSE 0 END) AS 'gfkSI (Valore a Nuovo > 3000)', SUM(CASE WHEN ([010041].gfk = 0 AND [010041].valore_bene_nuovo > 3000) THEN 1 ELSE 0 END) AS 'gfkNO (Valore a Nuovo > 3000)' FROM [01002] INNER JOIN [01001] ON [01001].id_sinistro = [01002].id_sinistro INNER JOIN [01004] ON [01002].id_sinistro = [01004].id_sinistro LEFT OUTER JOIN [010041] ON [01004].id_cat = [010041].id_cat LEFT OUTER JOIN [00002] ON [01001].id_compagnia = [00002].id_compagnia LEFT OUTER JOIN [09009] ON [09009].ID = [010041].bene WHERE [01002].chiusa = 1 AND [01002].revocata = 0 AND (ISNULL([09009].bene,'') <> '' OR ISNULL([010041].altroBene,'') <> '') AND [01001].id_stato NOT IN (51,23,24,52,49) AND [01001].t_chiusura BETWEEN '${moment(this.dateRangeInstanceEstrFoglioStat.startDate).format('YYYYMMDD')}' AND '${moment(this.dateRangeInstanceEstrFoglioStat.endDate).format('YYYYMMDD')}' GROUP BY [00002].descrizione,[09009].bene,[010041].altroBene ORDER BY 1,2,3`;
        this.toggleBlocking();     
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            if (retData.length === 0){
                this.toggleBlocking();     
                return;
            }
            // const resultArray = retData.reduce((acc, cur) => {
            //     const existingObj = acc.find(obj => obj.bene?.trim() === cur.bene?.trim() && obj.altroBene?.trim() === cur.altroBene?.trim());
            //     if (existingObj) {
            //         if (cur.gfk === true) {
            //             existingObj.gfkSi++;
            //         } else {
            //             existingObj.gfkNo++;
            //         }
            //     } else {
            //         const newObj = {
            //             bene: cur.bene,
            //             altroBene: cur.altroBene,
            //             gfkSi: cur.gfk === true ? 1 : 0,
            //             gfkNo: cur.gfk === false ? 1 : 0,
            //         };
            //         acc.push(newObj);
            //     }
            //     return acc;
            // }, []);
            let dsFoglioStat = retData;
            this.gridInstance7.dataSource = dsFoglioStat;
            this.gridInstance7.excelExportComplete = (args) => {
                this.toggleBlocking();
                this.gridInstance7.dataSource = null;
            };
            setTimeout(() => { 
                const exportProperties = {
                    fileName : `${moment(new Date()).format('YYYYMMDD')}_${moment(new Date()).format('HHMMSS')}_StatisticheBeni.xlsx`
                }
                this.gridInstance7.excelExport(exportProperties);
            }, 500);  
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking();
        }, true, true);      
    }
    btnEsportaInterlocutoria() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT [01001].*, [00002].descrizione AS compagnia FROM [01001] INNER JOIN [00002] ON [01001].id_compagnia = [00002].id_compagnia WHERE [01001].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;     
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            if (retData.length === 0)
                return;
            this.toggleBlocking();
            CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/WordCreator/CreaInterlocutoriaACT`, { datiSinistroJSON: JSON.stringify(retData[0]) }, 'POST', 
                { "Authorization" : CustomJS.tokenAuthAPIVarious }, retData2 => {
                    retData2 = retData2.replaceAll('/','[slash]');
                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumentoAWSS3/${encodeURIComponent(retData2)}`, '', 'GET',
                            '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData3 => { 
                        this.dialogInstance5.header = 'VISUALIZZAZIONE DOCUMENTO';    
                        this.dialogInstance5.width = '65%';
                        this.dialogInstance5.height = '90%';
                        this.dialogInstance5.minHeight = '95%';
                        this.dialogInstance5.buttons = [];  
                        this.dialogInstance5.beforeOpen = (args) => {
                            const container = document.getElementById('defaultDialogContent2');
                            const root = createRoot(container);   
                            root.unmount();
                        };   
                        this.dialogInstance5.open = (args) => {
                            const container = document.getElementById('defaultDialogContent2');
                            const root = createRoot(container);                         
                            root.render(<CallbackWrapper callback={() => {
                                setTimeout(() => {
                                    const URLVisualGoogle = `https://docs.google.com/gview?url=${encodeURIComponent(retData3.signedURL)}&embedded=true`;
                                    const URLVisualOffice365 = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(retData3.signedURL)}`;
                                    const encodedFilePath = encodeURIComponent(`${CustomJS.APIVariousJfishTechURLProd}/${retData3.filePath}`);
                                    const URLVisualLocale = `http://docs.google.com/viewer?url=${encodedFilePath}&embedded=true`;
                                    document.getElementById(`IframeDocumento${idSin}`).src = URLVisualOffice365;
                                    this.toggleBlocking();
                                }, 200);
                            }}>{this.otherDocumentRender()}</CallbackWrapper>);   
                        };    
                        this.dialogInstance5.show(); 
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),                    
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true});
                        this.toggleBlocking();
                    }, true, false);
                    const dataObj = {
                        IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                        Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                        DescrNota: 'Creato il file interlocutoria in WORD',
                    };
                    const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, 3, @descr_nota"  +
                                        ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, null)";
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                        this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                    }, true, true);        
            }, error => {
                let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking();
            }, true, false);    
        }, messaggio => {
            toast.warn(messaggio, {
            containerId: 'toastContainer1',
            onClose: () => toast.dismiss(),
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,           
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            newestOnTop: true,
            rtl: false,
            pauseOnFocusLoss: true}
            );
        }, true, true);  
    }
    btnEsportaRFDUnipolSai() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT [01001].*, [00002].descrizione AS compagnia FROM [01001] INNER JOIN [00002] ON [01001].id_compagnia = [00002].id_compagnia WHERE [01001].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;     
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            if (retData.length === 0)
                return;
            this.toggleBlocking();
            CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/WordCreator/CreaRFDUnipolSai`, { datiSinistroJSON: JSON.stringify(retData[0]) }, 'POST', 
                { "Authorization" : CustomJS.tokenAuthAPIVarious }, retData2 => {
                    retData2 = retData2.replaceAll('/','[slash]');
                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumentoAWSS3/${encodeURIComponent(retData2)}`, '', 'GET',
                            '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData3 => { 
                        this.dialogInstance5.header = 'VISUALIZZAZIONE DOCUMENTO';    
                        this.dialogInstance5.width = '65%';
                        this.dialogInstance5.height = '90%';
                        this.dialogInstance5.minHeight = '95%';
                        this.dialogInstance5.buttons = [];   
                        this.dialogInstance5.beforeOpen = (args) => {
                            const container = document.getElementById('defaultDialogContent2');
                            const root = createRoot(container);   
                            root.unmount();
                        };   
                        this.dialogInstance5.open = (args) => { 
                            const container = document.getElementById('defaultDialogContent2');
                            const root = createRoot(container);                         
                            root.render(<CallbackWrapper callback={() => {
                                setTimeout(() => {
                                    const URLVisualGoogle = `https://docs.google.com/gview?url=${encodeURIComponent(retData3.signedURL)}&embedded=true`;
                                    const URLVisualOffice365 = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(retData3.signedURL)}`;
                                    const encodedFilePath = encodeURIComponent(`${CustomJS.APIVariousJfishTechURLProd}/${retData3.filePath}`);
                                    const URLVisualLocale = `http://docs.google.com/viewer?url=${encodedFilePath}&embedded=true`;
                                    document.getElementById(`IframeDocumento${idSin}`).src = URLVisualOffice365;
                                    this.toggleBlocking();
                                }, 200);
                            }}>{this.otherDocumentRender()}</CallbackWrapper>); 
                        };  
                        this.dialogInstance5.show(); 
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),                    
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true});
                        this.toggleBlocking();
                    }, true, false);
                    const dataObj = {
                        IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                        Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                        DescrNota: 'Creato il file RFD UnipolSai in WORD',
                    };
                    const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, 3, @descr_nota"  +
                                        ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, null)";
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                        this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                    }, true, true);        
            }, error => {
                let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking();
            }, true, false);    
        }, messaggio => {
            toast.warn(messaggio, {
            containerId: 'toastContainer1',
            onClose: () => toast.dismiss(),
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,           
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            newestOnTop: true,
            rtl: false,
            pauseOnFocusLoss: true}
            );
        }, true, true);  
    }
    btnEsportaModuloPrimocontatto() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT [00005].nominativo, [00005].telefono1, [00005].telefono2 from [00005] where id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;     
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            this.toggleBlocking();
            if(retData < 1){
                retData.push({ 'data_incarico': CustomJS.isNullEmpty(document.getElementById(`incarico${idSin}`).ej2_instances[0].value) ? '' : moment(document.getElementById(`incarico${idSin}`).ej2_instances[0].value).format('DD/MM/YYYY'),
                               'numero_sinistro' : document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value,
                               'nominativo_agenzia' : document.getElementById(`agenzia${idSin}`).ej2_instances[0].text,
                               'id_interno_sinistro' : document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value})
            }
            else{
                retData[0]['data_incarico'] = moment(document.getElementById(`incarico${idSin}`).ej2_instances[0].value).format('DD/MM/YYYY');
                retData[0]['numero_sinistro'] = document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value;
                retData[0]['nominativo_agenzia'] = document.getElementById(`agenzia${idSin}`).ej2_instances[0].text;
                retData[0]['id_interno_sinistro'] = document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value;
            }
            CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/ExcelCreator/CreaModuloPrimoContattoACT`, { datiSinistroJSON: JSON.stringify(retData[0]) }, 'POST', 
            { "Authorization" : CustomJS.tokenAuthAPIVarious }, retData2 => {
                    retData2 = retData2.replaceAll('/','[slash]');
                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumentoAWSS3/${encodeURIComponent(retData2)}`, '', 'GET',
                            '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData3 => { 
                        this.dialogInstance5.header = 'VISUALIZZAZIONE DOCUMENTO';    
                        this.dialogInstance5.width = '65%';
                        this.dialogInstance5.height = '90%';
                        this.dialogInstance5.minHeight = '95%';
                        this.dialogInstance5.buttons = [];  
                        this.dialogInstance5.beforeOpen = (args) => {
                            const container = document.getElementById('defaultDialogContent2');
                            const root = createRoot(container);   
                            root.unmount();
                        };   
                        this.dialogInstance5.open = (args) => {
                            const container = document.getElementById('defaultDialogContent2');
                            const root = createRoot(container);                         
                            root.render(<CallbackWrapper callback={() => {
                                setTimeout(() => {
                                    const URLVisualGoogle = `https://docs.google.com/gview?url=${encodeURIComponent(retData3.signedURL)}&embedded=true`;
                                    const URLVisualOffice365 = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(retData3.signedURL)}`;
                                    const encodedFilePath = encodeURIComponent(`${CustomJS.APIVariousJfishTechURLProd}/${retData3.filePath}`);
                                    const URLVisualLocale = `http://docs.google.com/viewer?url=${encodedFilePath}&embedded=true`;
                                    document.getElementById(`IframeDocumento${idSin}`).src = URLVisualOffice365;
                                    this.toggleBlocking();
                                }, 200);
                            }}>{this.otherDocumentRender()}</CallbackWrapper>);   
                        };
                        this.dialogInstance5.show(); 
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),                    
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true});
                        this.toggleBlocking();
                    }, true, false);
                    const dataObj = {
                        IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                        Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                        DescrNota: 'Creato Modulo primo contatto',
                    };
                    const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, 3, @descr_nota"  +
                                        ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, null)";
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                        this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                    }, true, true);        
            }, error => {
                let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking();
            }, true, false);    
        }, messaggio => {
            toast.warn(messaggio, {
            containerId: 'toastContainer1',
            onClose: () => toast.dismiss(),
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,           
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            newestOnTop: true,
            rtl: false,
            pauseOnFocusLoss: true}
            );
        }, true, true);  
    }
    controlloRfd(){
        return(<React.Fragment>
            <div className='container-fluid'> 
                    <div className='row'>
                        <div className='col-lg-5 col-md-5 col-sm-5 col-xs-5'> 
                            <DropDownListComponent id='selezionaRfd' fields={this.ddlFields} dataSource={this.dsRFDCompleto}
                                allowFiltering={true} filterType='Contains' showClearButton={true} key={this.childKey}
                                placeholder="Seleziona Rfd" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                            </DropDownListComponent>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <DateRangePickerComponent id='RangeDateControlloRfd' ref={drp => this.dateRangeInstanceRfd = drp} cssClass='e-outline' format='dd/MM/yyyy'
                                showClearButton={true} placeholder='Data Controllo (Da - A)' floatLabelType='Always' key={this.childKey}></DateRangePickerComponent>
                        </div>   
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" >
                            <ProgressButtonComponent id='btnEstraiSingolo' content="ESTRAI SINGOLO" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }}
                                animationSettings={{effect: 'SlideRight'}} cssClass="e-fill e-info" onClick={this.btnEstraiSingoloRfd.bind(this)}></ProgressButtonComponent> 
                        </div>     
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <ProgressButtonComponent id='btnEstraiTutti' content="ESTRAI TUTTI" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }}
                                animationSettings={{effect: 'SlideRight'}} cssClass="e-fill e-info" onClick={this.btnEstraiTuttiRfd.bind(this)}></ProgressButtonComponent> 
                        </div>       
                    </div>
                    <div className='row'>  
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'> 
                            <div id='divHidden' style={{ display:'none'}} key={this.childKey}>
                                <GridComponent id="GridGeneraStatistRfd" dataSource={this.state.statistRfd} ref={ grid => this.gridInstance3 = grid } toolbar={this.toolbarOptionsControlloCat} toolbarClick={this.toolbarClickContrRFD.bind(this)} 
                                    editSettings={this.editSettings} allowSelection={false} enableHover={true} showColumnMenu={false} gridLines='Both' allowExcelExport={true} height='400px' enableInfiniteScrolling={true}
                                    allowTextWrap={true} allowPaging={false} allowSorting={true} allowFiltering={false} enableAltRow={false} enablePersistence={false} key={this.childKey}
                                    pageSettings={this.pageSettingsControlloCat}>
                                    <ColumnsDirective>
                                        <ColumnDirective field='id_interno_sinistro' headerText='Numero Sinistro' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='ragione_sociale' headerText="Ragione Sociale"  headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='danneggiato' headerText="Danneggiato" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='citta' headerText="Città" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                        <ColumnDirective field='cap' headerText="CAP"  headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='provincia' headerText="Provincia"  headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='data_incarico' headerText="Data Incarico" format='dd/MM/yyyy' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                        <ColumnDirective field='data_riconsegnato' headerText="Data Riconsegna" format='dd/MM/yyyy' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    </ColumnsDirective>
                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Group, Page, InfiniteScroll]} />
                                </GridComponent> 
                                <br/> 
                                <span id='righeEstrContrRFD' style={{ fontSize: '0.9vw', fontWeight: '500' }}></span>
                            </div>
                        </div>
                    </div>
            </div>
        </React.Fragment>);
    }
    tabContent0(args) { 
        return (<React.Fragment>
            <div id='contenutoSituazioneSinistri'></div>
        </React.Fragment>);
    }
    situazioneSinistriRender(args) { 
        return(<div className='container-fluid'>   
                    <div className='row'>
                        <AccordionComponent id='accordionFiltriAggiuntivi' expandMode='Single' expanding={this.onAccordExpanding.bind(this)} ref={accord => this.accordInstance = accord}>
                            <AccordionItemsDirective>
                                <AccordionItemDirective header={this.accordHeader[0]} expanded={true} content={this.accordContent0.bind(this)} iconCss="e-imageAccordion" />
                                <AccordionItemDirective header={this.accordHeader[1].replace('{0}', `${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].cognome} ${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].nome}`).replace('{1}', new Date().getFullYear().toString())} expanded={false} content={this.accordContent1.bind(this)} visible={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti Semplici') > -1 || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Team Leader') > -1 || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].id_utente === 3} iconCss="e-imageAccordion" />
                            </AccordionItemsDirective>
                        </AccordionComponent>   
                    </div>     
                    <div id='divEstrazioniVarie' className='row'>
                        <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'>
                           <ProgressButtonComponent id='btnEstraiStatisticheGenerali' content="Estrazione Statistiche Generali" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }}
                                animationSettings={{effect: 'SlideRight'}} cssClass="e-fill e-info" onClick={this.btnEstraiStatisticheGenerali.bind(this)} style={{marginRight: '10px', marginBottom: '5px'}}>
                        </ProgressButtonComponent> 
                        <CheckBoxComponent id='chkEstraiStatSinistriConBeni' label='Estrai anche sinistri con beni' ref={ chkObj => this.chkInstance3 = chkObj } checked={false}></CheckBoxComponent>
                  </div>
                        <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2'>
                           <ProgressButtonComponent id='btnEstraiStatisticheBeni' content="Estrazione Statistiche Beni" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }}
                                animationSettings={{effect: 'SlideRight'}} cssClass="e-fill e-info" onClick={this.btnEstraiStatisticheBeni.bind(this)} style={{marginRight: '10px'}}></ProgressButtonComponent> 
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3' id='campiSfondoBianco'>
                            <DateRangePickerComponent id='RangeDateEstrFoglioStat' ref={drp => this.dateRangeInstanceEstrFoglioStat = drp} cssClass='e-outline campiSfondoBianco' format='dd/MM/yyyy' style={{ width: '20vw' }}
                                showClearButton={false} placeholder='Periodo Estrazione (Data Incarico Da - A)' startDate={`1/1/${(new Date().getFullYear()-1).toString()}`} endDate={new Date(new Date().toDateString())}
                                floatLabelType='Always' key={this.childKey}></DateRangePickerComponent>
                        </div>
                        <div className='col-lg-4 offset-lg-1 col-md-4 offset-md-1 col-sm-4 offset-sm-1 col-xs-4 offset-xs-1 text-end'>
                            <SplitButtonComponent id='btnImportaSinistri' items={this.btnItems2} content='IMPORTAZIONE AUTOMATICA<br>SINISTRI' cssClass='e-fill e-warning' style={{ width: '220px', height:'50px' }} select={this.onSelectSplitBtn2.bind(this)}></SplitButtonComponent> 
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <ProgressButtonComponent id='btnControlloCat' content="Controllo CAT" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }}
                                animationSettings={{effect: 'SlideRight'}} cssClass="e-fill e-info" onClick={this.btnControlloCat.bind(this)} style={{marginRight: '10px'}}></ProgressButtonComponent> 
                            <ProgressButtonComponent id='btnControlloRfd' content="Controllo RFD" onClick={this.btnControlloRfd.bind(this)} enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }}
                                animationSettings={{effect: 'SlideRight'}} cssClass="e-fill e-info"></ProgressButtonComponent> 
                        </div>
                    </div>                    
                    <div className='row'>  
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'> 
                            <DialogComponent id="defaultDialog2" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog2} height='200px' minHeight='200px' width='35%'
                                ref={dialog => this.dialogInstance4 = dialog} header='Attenzione!!' isModal={true} buttons={this.dialogButtons1} >
                            </DialogComponent> 
                            <DialogComponent id="defaultDialo3" target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} height='80%' width='90%'
                                ref={dialog => this.dialogInstance3 = dialog} isModal={true} >
                            </DialogComponent> 
                            <GridComponent id="GridSituazioneSinistri" dataSource={this.state.GridData} ref={ grid => this.gridInstance = grid } toolbar={this.toolbarOptions} toolbarClick={this.toolbarClick.bind(this)} hierarchyPrintMode='All'
                                editSettings={this.editSettings} allowSelection={false} enableHover={false} rowDataBound={this.onRowDatabound} commandClick={this.onCommandClick.bind(this)} showColumnMenu={false} gridLines='Horizontal' excelExportComplete={this.onExcelExportComplete.bind(this)}
                                allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={true} filterSettings={this.filterSettings} enableAltRow={true} enablePersistence={true} actionBegin={this.onActionBegin.bind(this)} 
                                queryCellInfo={this.onQueryCellInfo.bind(this)} recordDoubleClick={this.onRecordDoubleClick.bind(this)} pageSettings={this.pageSettings} allowGrouping={true} groupSettings={this.groupSettings} 
                                childGrid={this.childGrid} dataBound={this.onDataBound.bind(this)} searchSettings={this.searchSettings} allowExcelExport={true}>
                                <ColumnsDirective>
                                    <ColumnDirective field='id_interno_sinistro' headerText='Num. Sinistro' width='7%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='numero_sinistro_agenzia' headerText='Num. Sinistro Agenzia'  visible={false}></ColumnDirective>
                                    <ColumnDirective field='contr_nominativo' headerText='Nominativo Contraente' visible={false}></ColumnDirective>
                                    <ColumnDirective field='ass_nominativo' headerText='Nominativo Assicurato' visible={false}></ColumnDirective>
                                    <ColumnDirective field='numero_polizza' headerText='Numero Polizza' visible={false}></ColumnDirective>
                                    <ColumnDirective field='descrizione' headerText="Stato"  headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='descrizione_divisione' headerText="Divisione" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='ragione_sociale_ag' headerText="Agenzia" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='data_timer' headerText="Data Reminder" format='dd/MM/yyyy' sortComparer={this.sortComparer1} headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='n_solleciti' headerText="Soll." format='n0' width='5%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='conta_Apertura' headerText="Rit." format='n0' width='5%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='strDataIncarico' headerText="Data Incarico" format='dd/MM/yyyy' sortComparer={this.sortComparer1} headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='data_invio_atto' headerText="Data Invio Atto" format='dd/MM/yyyy' sortComparer={this.sortComparer1} headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='data_chiusura_sinistro' headerText="Data Chiusura" format='dd/MM/yyyy' sortComparer={this.sortComparer1} headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='polizza' headerText="Polizza" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='danneggiato' headerText="Danneggiato" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='citta' headerText="Comune" headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='Comandi' headerText="" width='6%' commands={this.commands} headerTemplate={headerTemplate} textAlign='Right' allowSorting={false} allowFiltering={false}></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
                            </GridComponent>  
                            <GridComponent id="GridEstrazioneExcelStatisticheGenerali1" ref={ grid => this.gridInstance5 = grid } allowExcelExport={true} beforeExcelExport={this.onBeforeExcelExportStatistiche.bind(this)} style={{ display: 'none' }}>
                            <Inject services={[ExcelExport]} />
                            </GridComponent>
                            <GridComponent id="GridEstrazioneExcelStatisticheGenerali2" ref={ grid => this.gridInstance6 = grid } allowExcelExport={true} style={{ display: 'none' }}>
                            <Inject services={[ExcelExport]} />
                            </GridComponent>
                            <GridComponent id="GridEstrazioneExcelStatisticheBeni" ref={ grid => this.gridInstance7 = grid } allowExcelExport={true} style={{ display: 'none' }}>
                            <Inject services={[ExcelExport]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>);
    }
    accordContent0() {
        return (<div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="row card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">   
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">    
                                                <DropDownListComponent id='ddlStatoFiltro' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance2 = ddlObj } dataSource={this.dsStatoFiltro} 
                                                    allowFiltering={true} filterType='Contains' showClearButton={true}
                                                    placeholder="Selezionare uno Stato da filtrare" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                </DropDownListComponent>                                                                     
                                            </div>    
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">    
                                                <DropDownListComponent id='ddlGruppoFiltro' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance3 = ddlObj } dataSource={this.dsGruppoFiltro} 
                                                    allowFiltering={true} filterType='Contains' change={this.onChangeGruppoFiltro.bind(this)} showClearButton={true}
                                                    placeholder="Selezionare un Gruppo da filtrare" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                </DropDownListComponent>                                                                     
                                            </div>  
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">    
                                                <DropDownListComponent id='ddlCompDivFiltro' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance4 = ddlObj } dataSource={this.dsCompDivFiltro}  
                                                    allowFiltering={true} filterType='Contains' enabled={false} change={this.onChangeCompDivFiltro.bind(this)} showClearButton={true}
                                                    placeholder="Selezionare una Compagnia/Divisione da filtrare" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                </DropDownListComponent>                                                                     
                                            </div>        
                                            <div className="col-xs-12 col-sm-3 col-lg-3 col-md-3">    
                                                <DropDownListComponent id='ddlAgenziaFiltro' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance5 = ddlObj } dataSource={this.dsAgenziaFiltro}  
                                                    allowFiltering={true} filterType='Contains' enabled={false} showClearButton={true}
                                                    placeholder="Selezionare una Agenzia da filtrare" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                </DropDownListComponent>                                                                     
                                            </div>   
                                        </div> 
                                        <div className="row">
                                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">    
                                                <DropDownListComponent id='ddlPolizzaFiltro' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance6 = ddlObj } dataSource={this.dsPolizzaFiltro} 
                                                    allowFiltering={true} filterType='Contains' showClearButton={true}
                                                    placeholder="Selezionare una Polizza da filtrare" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                </DropDownListComponent>                                                                     
                                            </div>  
                                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                <DropDownListComponent id='ddlGradoSinistroFiltro' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance13 = ddlObj } dataSource={this.dsGradoSinistroDettaglio} 
                                                    allowFiltering={false} filterType='Contains' showClearButton={true}
                                                    placeholder="Selezionare un Grado Sinistro da filtrare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'> 
                                                </DropDownListComponent>     
                                            </div>
                                            <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3">
                                                <CheckBoxComponent id='chkVideoPerizia' label='Solo Videoperizia' ref={ chkObj => this.chkInstance1 = chkObj } checked={false}></CheckBoxComponent>
                                            </div>
                                            <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3">
                                                <CheckBoxComponent id='chkDocumentale' label='Solo Documentale' ref={ chkObj => this.chkInstance2 = chkObj } checked={false}></CheckBoxComponent>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <DateRangePickerComponent id='RangeDateReminder' ref={drp => this.dateRangeInstance6 = drp} cssClass='e-outline' format='dd/MM/yyyy'
                                                    allowEdit={false} showClearButton={true} placeholder='Data Reminder (Da - A)' floatLabelType='Always'></DateRangePickerComponent>
                                            </div> 
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <DateRangePickerComponent id='RangeDateIncarico' ref={drp => this.dateRangeInstance1 = drp} cssClass='e-outline' format='dd/MM/yyyy'
                                                    allowEdit={false} showClearButton={true} placeholder='Data Incarico (Da - A)' floatLabelType='Always'></DateRangePickerComponent>
                                            </div> 
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <DateRangePickerComponent id='RangeDateInvioAtto' ref={drp => this.dateRangeInstance7 = drp} cssClass='e-outline' format='dd/MM/yyyy'
                                                    allowEdit={false} showClearButton={true} placeholder='Data Invio Atto (Da - A)' floatLabelType='Always'></DateRangePickerComponent>
                                            </div> 
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <DateRangePickerComponent id='RangeDateChiusura' ref={drp => this.dateRangeInstance2 = drp} cssClass='e-outline' format='dd/MM/yyyy'
                                                    allowEdit={false} showClearButton={true} placeholder='Data Chiusura (Da - A)' floatLabelType='Always'></DateRangePickerComponent>
                                            </div> 
                                        </div>  
                                        <div className="row card-layout">
                                            <div className="col-xs-12 col-sm-4 col-lg-4 col-md-4">                                           
                                                <div tabIndex="0" className="e-card" id="main_card2">                                                    
                                                    <div className="e-card-content">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                                            <DropDownListComponent id='ddlCATFiltro' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance7 = ddlObj } dataSource={this.dsCATFiltro} 
                                                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                                                placeholder="Selezionare un CAT da filtrare" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                            </DropDownListComponent>                                                                     
                                                        </div>  
                                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9" style={{marginTop: '15px'}}>
                                                            <DateRangePickerComponent id='RangeDateChiusuraCAT' ref={drp => this.dateRangeInstance3 = drp} cssClass='e-outline' format='dd/MM/yyyy'
                                                                allowEdit={false} showClearButton={true} placeholder='Data Chiusura CAT (Da - A)' floatLabelType='Always'></DateRangePickerComponent>
                                                        </div> 
                                                    </div>  
                                                </div>  
                                            </div>
                                            <div className="col-xs-12 col-sm-4 col-lg-4 col-md-4">                                           
                                                <div tabIndex="0" className="e-card" id="main_card3">                                                   
                                                    <div className="e-card-content">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                                            <DropDownListComponent id='ddlPeritoFiltro' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance8 = ddlObj } dataSource={this.dsPeritoFiltro} 
                                                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                                                placeholder="Selezionare un Perito da filtrare" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                            </DropDownListComponent>                                                                     
                                                        </div>  
                                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9" style={{marginTop: '15px'}}>
                                                            <DateRangePickerComponent id='RangeDateChiusuraPerito' ref={drp => this.dateRangeInstance4 = drp} cssClass='e-outline' format='dd/MM/yyyy'
                                                                allowEdit={false} showClearButton={true} placeholder='Data Chiusura Perito (Da - A)' floatLabelType='Always'></DateRangePickerComponent>
                                                        </div>                                                        
                                                    </div>  
                                                </div>  
                                            </div> 
                                            <div className="col-xs-12 col-sm-4 col-lg-4 col-md-4">                                           
                                                <div tabIndex="0" className="e-card" id="main_card4">                                                   
                                                    <div className="e-card-content">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                                            <DropDownListComponent id='ddlCQFiltro' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance9 = ddlObj } dataSource={this.dsCQFiltro} 
                                                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                                                placeholder="Selezionare un CQ da filtrare" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                            </DropDownListComponent>                                                                     
                                                        </div>  
                                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9" style={{marginTop: '15px'}}>
                                                            <DateRangePickerComponent id='RangeDateControlloCQ' ref={drp => this.dateRangeInstance5 = drp} cssClass='e-outline' format='dd/MM/yyyy'
                                                                allowEdit={false} showClearButton={true} placeholder='Data Controllo CQ (Da - A)' floatLabelType='Always'></DateRangePickerComponent>
                                                        </div> 
                                                     </div>  
                                                </div>  
                                            </div> 
                                        </div>                                    
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6 text-end">                                                                         
                                                <ButtonComponent id='BtnApplicaFiltri' alt='Applica Filtri Aggiuntivi' onClick={this.caricaDatiFiltrati.bind(this)} cssClass='e-success'>Applica Filtri</ButtonComponent>
                                            </div>   
                                            <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">                                                                         
                                                <ButtonComponent id='BtnPulisciFiltri' alt='Pulisci Filtri Aggiuntivi' onClick={this.pulisciFiltri.bind(this)} cssClass='e-danger'>Pulisci Filtri</ButtonComponent>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>);
    }
    accordContent1() {
        return (
            <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className="col-xs-12 col-sm-8 offset-sm-2 col-lg-8 offset-lg-2 col-md-8 offset-md-2">                                           
                            <div tabIndex="0" className="e-card" id="main_card2">
                                <div className="e-card-content">
                                    <GridComponent id="GridStatPrestAnnoCorr" dataSource={this.state.GridData3} ref={ grid => this.gridInstance10 = grid }  
                                        allowSelection={false} enableHover={false} allowExcelExport={true} allowTextWrap={true} allowPaging={false} allowSorting={true} allowFiltering={false} enableAltRow={true} enablePersistence={false}
                                        queryCellInfo={this.onQueryCellInfo3.bind(this)} allowGrouping={false}>
                                        <ColumnsDirective>
                                            <ColumnDirective field='Utente' visible={false}></ColumnDirective>
                                            <ColumnDirective field='Compagnia' headerText='Compagnia' headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                            <ColumnDirective field='TotaleChiusi' headerText="Incarichi Chiusi Totali" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                            <ColumnDirective field='TotaleChiusiPD' headerText="Incarichi Chiusi in PD" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                            <ColumnDirective field='PercChiusiPD' headerText="Percentuale Incarichi chiusi in PD" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                            <ColumnDirective field='LiquidatoMedioChiusiPD' headerText="Liquidato Medio Incarichi chiusi in PD" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                            <ColumnDirective field='ChiusiNegativiPD' headerText="Chiusure Negative Incarichi in PD" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                            <ColumnDirective field='PercChiusiNegativiPD' headerText="Percentuale Chiusure Negative Incarichi in PD" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                            <ColumnDirective field='ChiusureTelematiche' headerText="Sinistri con Chiusura Telematica Effettuati" headerTextAlign='Center' textAlign='Center' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                        </ColumnsDirective>
                                        <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Group, Page]} />
                                    </GridComponent>  
                                </div>  
                            </div>  
                        </div>
                    </div>   
                </div>
            </div>
        );
    }
    onAccordExpanding(args) {
        if (!args.isExpanded)
            this.resettaFiltri();
    }
    resettaFiltri() {
        let oggettoFiltri = CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTechnologyFiltriAggiuntiviSinistri'))) ? {} : JSON.parse(sessionStorage.getItem('JellyfishTechnologyFiltriAggiuntiviSinistri'));
        this.ddlInstance2.value = oggettoFiltri.Stato;
        this.ddlInstance3.value = oggettoFiltri.Gruppo;
        setTimeout(() => {
            this.ddlInstance4.value = oggettoFiltri.CompDiv;
        });        
        this.ddlInstance5.value = oggettoFiltri.Agenzia;
        this.ddlInstance6.value = oggettoFiltri.Polizza;
        this.ddlInstance13.value = oggettoFiltri.GradoSinistro;
        this.dateRangeInstance1.startDate = CustomJS.isNullEmpty(oggettoFiltri.DataIncaricoDa) ? null : moment(oggettoFiltri.DataIncaricoDa).format('DD/MM/YYYY');
        this.dateRangeInstance1.endDate = CustomJS.isNullEmpty(oggettoFiltri.DataIncaricoA) ? null :moment(oggettoFiltri.DataIncaricoA).format('DD/MM/YYYY');
        this.dateRangeInstance2.startDate = CustomJS.isNullEmpty(oggettoFiltri.DataChiusuraDa) ? null : moment(oggettoFiltri.DataChiusuraDa).format('DD/MM/YYYY');
        this.dateRangeInstance2.endDate = CustomJS.isNullEmpty(oggettoFiltri.DataChiusuraA) ? null : moment(oggettoFiltri.DataChiusuraA).format('DD/MM/YYYY');
        this.ddlInstance7.value = oggettoFiltri.CAT;
        this.dateRangeInstance3.startDate = CustomJS.isNullEmpty(oggettoFiltri.DataChiusuraCATDa) ? null : moment(oggettoFiltri.DataChiusuraCATDa).format('DD/MM/YYYY');
        this.dateRangeInstance3.endDate = CustomJS.isNullEmpty(oggettoFiltri.DataChiusuraCATA) ? null : moment(oggettoFiltri.DataChiusuraCATA).format('DD/MM/YYYY');         
        this.ddlInstance8.value = oggettoFiltri.Perito;
        this.dateRangeInstance4.startDate = CustomJS.isNullEmpty(oggettoFiltri.DataChiusuraPeritoDa) ? null : moment(oggettoFiltri.DataChiusuraPeritoDa).format('DD/MM/YYYY');
        this.dateRangeInstance4.endDate = CustomJS.isNullEmpty(oggettoFiltri.DataChiusuraPeritoA) ? null : moment(oggettoFiltri.DataChiusuraPeritoA).format('DD/MM/YYYY');
        this.ddlInstance9.value = oggettoFiltri.CQ;
        this.dateRangeInstance5.startDate = CustomJS.isNullEmpty(oggettoFiltri.DataControlloCQDa) ? null : moment(oggettoFiltri.DataControlloCQDa).format('DD/MM/YYYY');
        this.dateRangeInstance5.endDate = CustomJS.isNullEmpty(oggettoFiltri.DataControlloCQA) ? null : moment(oggettoFiltri.DataControlloCQA).format('DD/MM/YYYY');
        this.dateRangeInstance6.startDate = CustomJS.isNullEmpty(oggettoFiltri.DataRemindDa) ? null : moment(oggettoFiltri.DataRemindDa).format('DD/MM/YYYY');
        this.dateRangeInstance6.endDate = CustomJS.isNullEmpty(oggettoFiltri.DataRemindA) ? null : moment(oggettoFiltri.DataRemindA).format('DD/MM/YYYY');
        this.dateRangeInstance7.startDate = CustomJS.isNullEmpty(oggettoFiltri.DataInvioAttoDa) ? null : moment(oggettoFiltri.DataInvioAttoDa).format('DD/MM/YYYY');
        this.dateRangeInstance7.endDate = CustomJS.isNullEmpty(oggettoFiltri.DataInvioAttoA) ? null : moment(oggettoFiltri.DataInvioAttoA).format('DD/MM/YYYY');
        this.chkInstance1.checked = oggettoFiltri.Videoperizia;
        this.chkInstance2.checked = oggettoFiltri.Documentale;
    }
    pulisciFiltri() {
        this.ddlInstance2.value = null;
        this.ddlInstance3.value = null;
        this.ddlInstance4.value = null; 
        this.ddlInstance5.value = null;
        this.ddlInstance6.value = null;
        this.ddlInstance13.value = null;
        this.dateRangeInstance1.startDate = null;
        this.dateRangeInstance1.endDate = null;
        this.dateRangeInstance2.startDate = null;
        this.dateRangeInstance2.endDate = null;
        this.ddlInstance7.value = null;
        this.dateRangeInstance3.startDate = null;
        this.dateRangeInstance3.endDate = null;
        if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti Semplici') === -1)
            this.ddlInstance8.value = null;
        this.dateRangeInstance4.startDate = null;
        this.dateRangeInstance4.endDate = null;
        this.ddlInstance9.value = null;
        this.dateRangeInstance5.startDate = null;
        this.dateRangeInstance5.endDate = null;
        this.dateRangeInstance6.startDate = null;
        this.dateRangeInstance6.endDate = null;
        this.dateRangeInstance7.startDate = null;
        this.dateRangeInstance7.endDate = null;
        this.chkInstance1.checked = null;
        this.chkInstance2.checked = null;      
        this.caricaDatiFiltrati();
    }
    onChangeFiltroSinistri(args) {
        sessionStorage.setItem('JellyfishTechnologyFiltroSituazioneSinistri', args.value);
        const oggettoFiltri = JSON.parse(sessionStorage.getItem('JellyfishTechnologyFiltriAggiuntiviSinistri'));
        this.caricaDati(oggettoFiltri, null, $('#GridSituazioneSinistri_searchbar').val()?.trim());
    }
    onChangeFiltroStati(args) {
        if (this.arrivoDaFiltriAgg && CustomJS.isNullEmpty(args.value)) {
            this.arrivoDaFiltriAgg = false;
            return;
        }
        let oggettoFiltri = JSON.parse(sessionStorage.getItem('JellyfishTechnologyFiltriAggiuntiviSinistri'));
        oggettoFiltri.Stato = args.value;
        this.ddlInstance2.value = args.value;
        sessionStorage.setItem('JellyfishTechnologyFiltriAggiuntiviSinistri', JSON.stringify(oggettoFiltri));
        this.caricaDati(oggettoFiltri, null, $('#GridSituazioneSinistri_searchbar').val()?.trim());
    }
    onChangeFiltroPeriti(args) {
        if (this.arrivoDaFiltriAgg && CustomJS.isNullEmpty(args.value)) {
            this.arrivoDaFiltriAgg = false;
            return;
        }
        let oggettoFiltri = JSON.parse(sessionStorage.getItem('JellyfishTechnologyFiltriAggiuntiviSinistri'));
        oggettoFiltri.Perito = args.value;
        this.ddlInstance8.value = args.value;
        sessionStorage.setItem('JellyfishTechnologyFiltriAggiuntiviSinistri', JSON.stringify(oggettoFiltri));
        this.caricaDati(oggettoFiltri, null, $('#GridSituazioneSinistri_searchbar').val()?.trim());
    }
    onChangeGruppoFiltro(args) {
        let ddlObj = document.getElementById('ddlCompDivFiltro').ej2_instances[0];
        ddlObj.enabled = !CustomJS.isNullEmpty(args.value);
        let tempQuery = new Query().where('ID_COMPAGNIA', 'equal', args.value);
        ddlObj.query = tempQuery;
        ddlObj.value = null;
        ddlObj.text = null;
        ddlObj.dataBind();
    }
    onChangeCompDivFiltro(args){
        let ddlObj = document.getElementById('ddlAgenziaFiltro').ej2_instances[0];
        //let btnObj = document.getElementById('btnAgenzia').ej2_instances[0];
        ddlObj.enabled = !CustomJS.isNullEmpty(args.value);
        //btnObj.enabled = !CustomJS.isNullEmpty(args.value);
        let tempQuery = new Query().where('id_divisione','equal', parseInt(args.value));
        ddlObj.query = tempQuery;
        ddlObj.value = null;
        ddlObj.text = null;
        ddlObj.dataBind();
    }
    onChangeGruppoDettaglio(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        //setTimeout(() => {
            let ddlObj = document.getElementById(`compagnia${idSin}`).ej2_instances[0];
            ddlObj.enabled = !CustomJS.isNullEmpty(args.value);
            let tempQuery = new Query().where('ID_COMPAGNIA', 'equal', args.value);
            ddlObj.query = tempQuery;
            ddlObj.value = null;
            ddlObj.text = null;
            ddlObj.dataBind();
            this.aggiornaDettaglio('id_compagnia', args.value, 'INT');    
        //}, 50);
        this.caricaTabelleCompagniaScelta(args.value);
    }
    onChangeAzioneDettaglio(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let ddlObj = document.getElementById(`ddlCosa${idSin}`).ej2_instances[0];
        ddlObj.enabled = !CustomJS.isNullEmpty(args.value);
        let predicate = new Predicate('id_stato', 'equal', document.getElementById(`idStato${idSin}`).value);
        predicate = predicate.or('id_stato', 'equal', '0');
        let tempQuery = new Query().where('id_tipo_dest', 'equal', document.getElementById(`ddlAChi${idSin}`).ej2_instances[0].value).where(predicate).where('id_tipo_messaggio', 'equal', args.value);
        ddlObj.query = tempQuery;
        ddlObj.value = null;
        ddlObj.text = null;
        ddlObj.dataBind();
        //this.aggiornaDettaglio('id_tipo_messaggio', args.value, 'INT');
    }
    onChangeAChiDettaglio(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let ddlObj = document.getElementById(`ddlCosa${idSin}`).ej2_instances[0];
        ddlObj.enabled = !CustomJS.isNullEmpty(args.value);
        let predicate = new Predicate('id_stato', 'equal', document.getElementById(`idStato${idSin}`).value);
        predicate = predicate.or('id_stato', 'equal', '0');
        let tempQuery = new Query().where('id_tipo_dest', 'equal', args.value).where(predicate).where('id_tipo_messaggio', 'equal', document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value);
        ddlObj.query = tempQuery;
        ddlObj.value = null;
        ddlObj.text = null;
        ddlObj.dataBind();
        // this.aggiornaDettaglio('id_tipo_dest', args.value, 'INT', '[02007]');
    }
    onChangeCompagniaDettaglio(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let ddlObj = document.getElementById(`agenzia${idSin}`).ej2_instances[0];
        ddlObj.enabled = !CustomJS.isNullEmpty(args.value);
        let tempQuery = new Query().where('id_divisione','equal', parseInt(args.value));
        ddlObj.query = tempQuery;
        ddlObj.value = null;
        ddlObj.text = null;
        ddlObj.dataBind();
        this.aggiornaDettaglio('id_divisione', args.value, 'INT');
    }
    onChangeAreaDettaglio(args){
        this.aggiornaDettaglio('id_area', args.value, 'INT'); 
    }
    onChangeTriageDettaglio(args){
        this.aggiornaDettaglio('triage', args.checked ? 1 : 0, 'INT');
    }
    onChangeProntaDefinizioneDettaglio(args){
       this.aggiornaDettaglio('pronta_definizione', args.checked ? 1 : 0, 'INT');
    }
    onChangeVideoperiziaDettaglio(args){
        this.aggiornaDettaglio('videoperizia', args.checked ? 1 : 0, 'INT');
    }
    onChangeRiaperturaDettaglio(args){
        this.aggiornaDettaglio('riapertura', args.checked ? 1 : 0, 'INT');
    }
    onChangeGradoSinistroDettaglio(args){
        if (args.value === '4') {
            let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
            if (document.getElementById(`gruppo${idSin}`).ej2_instances[0].value !== '3' &&  document.getElementById(`gruppo${idSin}`).ej2_instances[0].value !== '5'
                && document.getElementById(`gruppo${idSin}`).ej2_instances[0].value !== '6' && document.getElementById(`gruppo${idSin}`).ej2_instances[0].value !== '7' 
                && document.getElementById(`gruppo${idSin}`).ej2_instances[0].value !== '9') {
                this.aggiornaDettaglio('id_grado_sinistro', args.value, 'INT');
                return;
            }
            let destinatari = (document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === '3' || document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === '5') ? 'luigi.rizzi@actsrl.it' : (document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === '6' || document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === '9') ? 'michele.gambel@actsrl.it' : document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === '7' ? 'stefano.toccalino@actsrl.it' : 'IndirizzoEmailNonPrevisto@niente.com';
            let destinatariCC = document.getElementById(`gruppo${idSin}`).ej2_instances[0].value !== '7' ? 'stefano.toccalino@actsrl.it' : null;
            let corpoEmail = `Buongiorno,<br/><br/>con la presente si avvisa che il sinistro <b>${idSin}</b> è stato indicato come <b>COMPLESSO</b>, all'interno dell'applicazione Jellyfish Technology.<br/><br/><br/>Cordiali saluti<br/><br/><br/>&nbsp;&nbsp;&nbsp;Il Supporto Jellyfish Technology`;
            this.dialogInstance3.header = 'Attenzione!!';
            this.dialogInstance3.content = `Impostando il sinistro come <b>Complesso</b> verrà inviata una email di avviso all'indirizzo <b>${destinatari}</b> ed in copia all'indirizzo <b>${destinatariCC}</b>. Confermare l'operazione?`;
            this.dialogInstance3.width = '25%';
            this.dialogInstance3.height = '21%';
            this.dialogInstance3.minHeight = '21%';
            this.dialogInstance3.buttons = [
                { 
                    click: () => { 
                        this.aggiornaDettaglio('id_grado_sinistro', args.value, 'INT');
                        this.invioEmailGenerale('ACT', 'Notifica Sinistro Complesso', corpoEmail, destinatari, destinatariCC)
                        .then(ret => {
                            if (ret === 0) {
                            }
                            else if (ret === 1) {
                                this.dialogInstance3.hide();
                                toast.success('Email inviata con successo!!', {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });  
                            }
                            else if (ret.retCode === 2) {
                                toast.error(`Attenzione!! Parametri per invio Email errati. L' errore riscontrato è il seguente : ${JSON.stringify(ret.msgErr)}`, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 15000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });  
                            }
                        }).catch(messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });
                    }, 
                    buttonModel: { 
                        content: 'CONFERMA', 
                        cssClass: 'e-primary',
                        isPrimary: true 
                    }
                },
                {
                    click: () => {
                        document.getElementById(`gradoSinistro${idSin}`).ej2_instances[0].value = null;
                        this.dialogInstance3.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
            this.dialogInstance3.show(); 
        }
        else
            this.aggiornaDettaglio('id_grado_sinistro', args.value, 'INT');
    }
    onChangeSollGiustDettaglio(args){
        if (args.checked === true) {
            let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
            this.dialogInstance3.header = 'Attenzione!!';
            this.dialogInstance3.content = `Impostando il <b>Sollecito Documentazione</b> verrà inviata una email automatica, ogni 7 giorni a partire dal giorno <b>${moment(new Date().setDate(new Date().getDate() + 7)).format('DD/MM/YYYY')}</b>, di tipo <b>"ASSICURATO - Sollecito documentazione"</b>. Per interrompere il sollecito periodico sarà sufficiente rimuovere il check in questione. Confermare l'operazione?`;
            this.dialogInstance3.width = '25%';
            this.dialogInstance3.height = '23%';
            this.dialogInstance3.minHeight = '23%';
            this.dialogInstance3.buttons = [
                { click: () => { 
                    this.aggiornaDettaglio('sollecito_giustificativo', args.checked ? 1 : 0, 'INT');
                    this.aggiornaDettaglio('data_ultimo_sollecito_giustif', moment(new Date()).format('YYYY-MM-DD'), 'DATE');
                    this.dialogInstance3.hide();
                }, 
                buttonModel: { 
                    content: 'CONFERMA', 
                    cssClass: 'e-primary',
                    isPrimary: true 
                }
                },
                {
                    click: () => {
                        document.getElementById(`sollgiust${idSin}`).ej2_instances[0].checked = false;
                        this.dialogInstance3.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
            this.dialogInstance3.show(); 
        }
        else
            this.aggiornaDettaglio('sollecito_giustificativo', args.checked ? 1 : 0, 'INT');
    }
    onChangeSollAttoDettaglio(args){
        if (args.checked === true) {
            let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
            this.dialogInstance3.header = 'Attenzione!!';
            this.dialogInstance3.content = `Impostando il <b>Sollecito Atto</b> verrà inviata una email automatica, ogni 7 giorni a partire dal giorno <b>${moment(new Date().setDate(new Date().getDate() + 7)).format('DD/MM/YYYY')}</b>, di tipo <b>"ASSICURATO - Sollecito atto"</b>. Per interrompere il sollecito periodico sarà sufficiente rimuovere il check in questione. Confermare l'operazione?`;
            this.dialogInstance3.width = '25%';
            this.dialogInstance3.height = '23%';
            this.dialogInstance3.minHeight = '23%';
            this.dialogInstance3.buttons = [
                { click: () => { 
                    this.aggiornaDettaglio('sollecito_atto', args.checked ? 1 : 0, 'INT');
                    this.aggiornaDettaglio('data_ultimo_sollecito_atto', moment(new Date()).format('YYYY-MM-DD'), 'DATE');
                    this.dialogInstance3.hide();
                }, 
                buttonModel: { 
                    content: 'CONFERMA', 
                    cssClass: 'e-primary',
                    isPrimary: true 
                }
                },
                {
                    click: () => {
                        document.getElementById(`sollatto${idSin}`).ej2_instances[0].checked = false;
                        this.dialogInstance3.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
            this.dialogInstance3.show(); 
        }
        else
            this.aggiornaDettaglio('sollecito_atto', args.checked ? 1 : 0, 'INT');
    }
    onChangeDocumentaleDettaglio(args) {
        this.aggiornaDettaglio('documentale', args.checked ? 1 : 0, 'INT');
    }
    onChangePeriziaSemplificataDettaglio(args) {
        this.aggiornaDettaglio('perizia_semplificata', args.checked ? 1 : 0, 'INT');
    }
    onChangeCheckFulminiEntro3KM(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('fulminazione_entro_3KM', args.checked ? 1 : 0, 'INT');
        if (!args.checked) {
            this.aggiornaDettaglio('tipo_fulminazione_entro_3KM', '', 'CHAR');
            document.getElementById(`descrTipoFulminiEntro3KM${idSin}`).innerText = '';
        }
    }
    onChangeCheckFulminiOltre3KM(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('fulminazione_oltre_3KM', args.checked ? 1 : 0, 'INT');
        if (!args.checked) {
            this.aggiornaDettaglio('tipo_fulminazione_oltre_3KM', '', 'CHAR');
            document.getElementById(`descrTipoFulminiOltre3KM${idSin}`).innerText = '';
        }
   }
    onChangePreesistFabbricatoCAT(args) {
        //this.aggiornaDettaglio('pre_fabbricato', args.checked ? 1 : 0, 'INT', '[01004]');
    }
    onChangePreesistMacchinarioCAT(args) {
        //this.aggiornaDettaglio('pre_macchinario', args.checked ? 1 : 0, 'INT', '[01004]');
    }
    onChangePreesistContenutoCAT(args) {
        //this.aggiornaDettaglio('pre_contenuto', args.checked ? 1 : 0, 'INT', '[01004]');
    }
    onChangePreesistFabbricatoRFD(args) {
        //this.aggiornaDettaglio('pre_fabbricato', args.checked ? 1 : 0, 'INT', '[01014]');
    }
    onChangePreesistMacchinarioRFD(args) {
        //this.aggiornaDettaglio('pre_macchinario', args.checked ? 1 : 0, 'INT', '[01014]');
    }
    onChangePreesistContenutoRFD(args) {
        //this.aggiornaDettaglio('pre_contenuto', args.checked ? 1 : 0, 'INT', '[01014]');
    }
    onChangeAgenziaDettaglio(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('id_agenzia', args.value, 'INT');
        document.getElementById(`telefonoAgenzia${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(args.itemData) ? null : args.itemData?.telefono1;
        document.getElementById(`emailAgenzia${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(args.itemData) ? null : args.itemData?.email1;
        //document.getElementById(`area${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(args.itemData) ? null : args.itemData?.area;
    }
    onChangeComunicazionePerAgenziaDettaglio(args) {
        this.aggiornaDettaglio('comunicazione_per_agenzia', args.value, 'CHAR');
    }
    onChangeAgenziaTelDettaglio(args){
        this.aggiornaDettaglio('telefono1', args.value, 'CHAR', '[00004]');
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const tabella = '00004';
        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        let ddlAgenzia = `agenzia${idSin}`;
        let salvaValoreAgenzia = document.getElementById(ddlAgenzia).ej2_instances[0].value;
        setTimeout(() =>{
            CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData => {
                if (retData.length > 0) {
                    let emptyObj = {};
                    Object.keys(retData[0]).forEach(e => emptyObj[e] = '');
                    retData.unshift(emptyObj);
                }
                sessionStorage.setItem('JellyfishTechnologyTabellaAgenziaDettaglio', CustomJS.compressAndEncode(retData));
                this.dsAgenziaDettaglio = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaAgenziaDettaglio'))).map(elm => { return { VALUE: elm.id_agenzia?.toString(), TEXT: elm.ragione_sociale, id_divisione: elm.id_divisione, telefono1: elm.telefono1, email1: elm.email1, area: elm.descrizione} });
                document.getElementById(ddlAgenzia).ej2_instances[0].dataSource = this.dsAgenziaDettaglio;
                setTimeout(() =>{
                    document.getElementById(ddlAgenzia).ej2_instances[0].value = salvaValoreAgenzia;
                }, 200)
            }, messaggio => {
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, "SELECT [00004].id_agenzia, [00004].ragione_sociale, [00004].telefono1, [00004].email1, [00007].descrizione, [03002].id_divisione FROM ([00007] RIGHT JOIN [00004] ON [00007].id_area=[00004].id_area) INNER JOIN [03002] ON [00004].id_agenzia=[03002].id_agenzia ORDER BY [00004].ragione_sociale;");           
        }, 300)
    }
    onChangeAgenziaEmailDettaglio(args){
        this.aggiornaDettaglio('email1', args.value, 'CHAR', '[00004]');
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const tabella = '00004';
        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        let ddlAgenzia = `agenzia${idSin}`;
        let salvaValoreAgenzia = document.getElementById(ddlAgenzia).ej2_instances[0].value;
        setTimeout(() =>{
            CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData => {
                if (retData.length > 0) {
                    let emptyObj = {};
                    Object.keys(retData[0]).forEach(e => emptyObj[e] = '');
                    retData.unshift(emptyObj);
                }
                sessionStorage.setItem('JellyfishTechnologyTabellaAgenziaDettaglio', CustomJS.compressAndEncode(retData));
                this.dsAgenziaDettaglio = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaAgenziaDettaglio'))).map(elm => { return { VALUE: elm.id_agenzia?.toString(), TEXT: elm.ragione_sociale, id_divisione: elm.id_divisione, telefono1: elm.telefono1, email1: elm.email1, area: elm.descrizione} });
                document.getElementById(ddlAgenzia).ej2_instances[0].dataSource = this.dsAgenziaDettaglio;
                setTimeout(() =>{
                    document.getElementById(ddlAgenzia).ej2_instances[0].value = salvaValoreAgenzia;
                }, 200)
            }, messaggio => {
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, "SELECT [00004].id_agenzia, [00004].ragione_sociale, [00004].telefono1, [00004].email1, [00007].descrizione, [03002].id_divisione FROM ([00007] RIGHT JOIN [00004] ON [00007].id_area=[00004].id_area) INNER JOIN [03002] ON [00004].id_agenzia=[03002].id_agenzia ORDER BY [00004].ragione_sociale;");           
        }, 300)
    }
    onChangeGaranziaDettaglio(args){
        this.aggiornaDettaglio('id_tipo_danno_den', args.value, 'INT');
    }
    onChangeRamoDettaglio(args){
        this.aggiornaDettaglio('id_tipo_ramo', args.value, 'INT');
    }
    onChangeSinistriAssociatiDettaglio(args){
        this.aggiornaDettaglio('altri_sinistri', args.value, 'CHAR');
    }
    onChangeNumSinAgenzDettaglio(args){
        this.aggiornaDettaglio('numero_sinistro_agenzia', args.value, 'CHAR');
    }
    onChangeDataSinDettaglio(args){
        this.aggiornaDettaglio('t_sinistro', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE');
    }
    onChangeDataDenunciatoDettaglio(args){
        this.aggiornaDettaglio('t_denuncia', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE');
    }
    onChangeDataIncaricoDettaglio(args){
        this.aggiornaDettaglio('t_incarico', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE');
    }
    onChangeDataSopralluogoDettaglio(args){
        this.aggiornaDettaglio('t_sopralluogo', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE');
    }
    onChangeDataChiusuraDettaglio(args){
        this.aggiornaDettaglio('t_chiusura', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE');
    }
    onChangeLiquidatoreDettaglio(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        document.getElementById(`telefonoLiquidatore${idSin}`).ej2_instances[0].readonly = true;
        document.getElementById(`emailLiquidatore${idSin}`).ej2_instances[0].readonly = true;    
        document.getElementById(`telefonoLiquidatore${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(args.itemData) ? null : args.itemData?.telefono1;
        document.getElementById(`emailLiquidatore${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(args.itemData) ? null : args.itemData?.email1;
        this.aggiornaDettaglio('id_liquidatore', args.value, 'INT')
    }
    onChangeTelLiquidatoreDettaglio(args){
        // this.aggiornaDettaglio('telefono1', args.value, 'CHAR', '[00009]');
        // let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        // const tabella = '00009';
        // const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        // let liquid = `liquidatore${idSin}`;
        // setTimeout(() =>{
        //     CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData => {
        //         if (retData.length > 0) {
        //             let emptyObj = {};
        //             Object.keys(retData[0]).forEach(e => emptyObj[e] = '');
        //             retData.unshift(emptyObj);
        //         }
        //         sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData));
        //         this.dsLiquidatoreDettaglio = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00009')))?.map(elm => { return { VALUE: elm.id_liquidatore.toString(), TEXT: elm.ragione_sociale, telefono1:elm.telefono1, email1: elm.email1 } });
        //         document.getElementById(liquid).ej2_instances[0].dataSource = this.dsLiquidatoreDettaglio;
        //     }, messaggio => {
        //         toast.warn(messaggio, {
        //             onClose: () => toast.dismiss(),
        //             position: "bottom-right",
        //             autoClose: 5000,
        //             hideProgressBar: false,           
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: false,
        //             progress: undefined,
        //             newestOnTop: true,
        //             rtl: false,
        //             pauseOnFocusLoss: true}
        //         );
        //     });           
        // }, 300)
    }
    onChangeEmailLiquidatoreDettaglio(args){
        // this.aggiornaDettaglio('email1', args.value, 'CHAR', '[00009]');
        // let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        // const tabella = '00009';
        // const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        // let liquid = `liquidatore${idSin}`;
        // setTimeout(() =>{
        //     CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData => {
        //         if (retData.length > 0) {
        //             let emptyObj = {};
        //             Object.keys(retData[0]).forEach(e => emptyObj[e] = '');
        //             retData.unshift(emptyObj);
        //         }
        //         sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData));
        //         this.dsLiquidatoreDettaglio = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00009')))?.map(elm => { return { VALUE: elm.id_liquidatore.toString(), TEXT: elm.ragione_sociale, telefono1:elm.telefono1, email1: elm.email1 } });
        //         document.getElementById(liquid).ej2_instances[0].dataSource = this.dsLiquidatoreDettaglio;
        //     }, messaggio => {
        //         toast.warn(messaggio, {
        //             onClose: () => toast.dismiss(),
        //             position: "bottom-right",
        //             autoClose: 5000,
        //             hideProgressBar: false,           
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: false,
        //             progress: undefined,
        //             newestOnTop: true,
        //             rtl: false,
        //             pauseOnFocusLoss: true}
        //         );
        //     });           
        // }, 300)
    }
    onChangeBrokerDettaglio(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        document.getElementById(`telefonoBroker${idSin}`).ej2_instances[0].readonly = true;
        document.getElementById(`emailBroker${idSin}`).ej2_instances[0].readonly = true;    
        document.getElementById(`telefonoBroker${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(args.itemData) ? null : args.itemData?.telefono1;
        document.getElementById(`emailBroker${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(args.itemData) ? null : args.itemData?.email1;
        this.aggiornaDettaglio('id_broker', args.value, 'INT')
    }
    onChangeTelBrokerDettaglio(args){
        // this.aggiornaDettaglio('telefono1', args.value, 'CHAR', '[00008]');
        // let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        // const tabella = '00008';
        // const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        // let broker = `broker${idSin}`;
        // setTimeout(() =>{
        //     CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData => {
        //         if (retData.length > 0) {
        //             let emptyObj = {};
        //             Object.keys(retData[0]).forEach(e => emptyObj[e] = '');
        //             retData.unshift(emptyObj);
        //         }
        //         sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData));
        //         this.dsBrokerDettaglio = _.uniq(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00008')), elm => elm.id_broker)).map(elm => { return { VALUE: elm.id_broker.toString(), TEXT: elm.ragione_sociale, telefono1: elm.telefono1, email1: elm.email1 } });
        //         document.getElementById(broker).ej2_instances[0].dataSource = this.dsBrokerDettaglio;
        //     }, messaggio => {
        //         toast.warn(messaggio, {
        //             onClose: () => toast.dismiss(),
        //             position: "bottom-right",
        //             autoClose: 5000,
        //             hideProgressBar: false,           
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: false,
        //             progress: undefined,
        //             newestOnTop: true,
        //             rtl: false,
        //             pauseOnFocusLoss: true}
        //         );
        //     });           
        // }, 300)
    }
    onChangeEmailBrokerDettaglio(args){
        // this.aggiornaDettaglio('email1', args.value, 'CHAR', '[00008]');
        // let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        // const tabella = '00008';
        // const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        // let broker = `broker${idSin}`;
        // setTimeout(() =>{
        //     CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData => {
        //         if (retData.length > 0) {
        //             let emptyObj = {};
        //             Object.keys(retData[0]).forEach(e => emptyObj[e] = '');
        //             retData.unshift(emptyObj);
        //         }
        //         sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData));
        //         this.dsBrokerDettaglio = _.uniq(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00008')), elm => elm.id_broker)).map(elm => { return { VALUE: elm.id_broker.toString(), TEXT: elm.ragione_sociale, telefono1: elm.telefono1, email1: elm.email1 } });
        //         document.getElementById(broker).ej2_instances[0].dataSource = this.dsBrokerDettaglio;
        //     }, messaggio => {
        //         toast.warn(messaggio, {
        //             onClose: () => toast.dismiss(),
        //             position: "bottom-right",
        //             autoClose: 5000,
        //             hideProgressBar: false,           
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: false,
        //             progress: undefined,
        //             newestOnTop: true,
        //             rtl: false,
        //             pauseOnFocusLoss: true}
        //         );
        //     });           
        // }, 300)
    }
    onChangeNumPolizzaDettaglio(args){
        this.aggiornaDettaglio('numero_polizza', args.value, 'CHAR');
    }
    onChangeTipiPolizzaDettaglio(args){
        this.aggiornaDettaglio('id_tipo_polizza', args.value, 'INT');
    }
    onChangeContraenteNominativoDettaglio(args){
        this.aggiornaDettaglio('contr_nominativo', args.value, 'CHAR');
    }
    onChangeContraenteTel1Dettaglio(args){
        this.aggiornaDettaglio('contr_tel1', args.value, 'CHAR');
    }
    onChangeContraenteEmail1Dettaglio(args){
        this.aggiornaDettaglio('contr_email1', args.value, 'CHAR');
    }
    onChangeAssNominativoDettaglio(args){
        this.aggiornaDettaglio('ass_nominativo', args.value, 'CHAR');
    }
    onChangeAssTel1Dettaglio(args){
        this.aggiornaDettaglio('ass_tel1', args.value, 'CHAR');
    }
    onChangeAssEmail1Dettaglio(args){
        this.aggiornaDettaglio('ass_email1', args.value, 'CHAR');
    }
    onChangeAmministratoreDettaglio(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        document.getElementById(`telefonoAmministratore${idSin}`).ej2_instances[0].readonly = true;
        document.getElementById(`emailAmministratore${idSin}`).ej2_instances[0].readonly = true;    
        document.getElementById(`telefonoAmministratore${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(args.itemData) ? null : args.itemData?.telefono1;
        document.getElementById(`emailAmministratore${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(args.itemData) ? null : args.itemData?.email1;
        this.aggiornaDettaglio('id_amministratore', args.value, 'INT');
        this.aggiornaDettaglio('amm_nominativo', args.itemData?.TEXT, 'CHAR');
        this.aggiornaDettaglio('amm_tel1', document.getElementById(`telefonoAmministratore${idSin}`).ej2_instances[0].value, 'CHAR');
        this.aggiornaDettaglio('amm_email1', document.getElementById(`emailAmministratore${idSin}`).ej2_instances[0].value, 'CHAR');
    }
    onChangeAmministratoreTel1Dettaglio(args){
        // this.aggiornaDettaglio('telefono1', args.value, 'CHAR', '[00012]');
        // let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        // const tabella = '00012';
        // const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        // let amministratore = `amministratore${idSin}`;
        // setTimeout(() =>{
        //     CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData => {
        //         if (retData.length > 0) {
        //             let emptyObj = {};
        //             Object.keys(retData[0]).forEach(e => emptyObj[e] = '');
        //             retData.unshift(emptyObj);
        //         }
        //         sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData));
        //         this.dsAmministratoreFiltro = _.uniq(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00012')), elm => elm.id_amministratore)).map(elm => { return { VALUE: elm.id_amministratore.toString(), TEXT: elm.ragione_sociale, telefono1: elm.telefono1, email1: elm.email1 } });
        //         document.getElementById(amministratore).ej2_instances[0].dataSource = this.dsAmministratoreFiltro;
        //     }, messaggio => {
        //         toast.warn(messaggio, {
        //             onClose: () => toast.dismiss(),
        //             position: "bottom-right",
        //             autoClose: 5000,
        //             hideProgressBar: false,           
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: false,
        //             progress: undefined,
        //             newestOnTop: true,
        //             rtl: false,
        //             pauseOnFocusLoss: true}
        //         );
        //     });           
        // }, 300)
    }
    onChangeAmministratoreEmail1Dettaglio(args){
        // this.aggiornaDettaglio('email1', args.value, 'CHAR', '[00012]');
        // let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        // const tabella = '00012';
        // const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        // let amministratore = `amministratore${idSin}`;
        // setTimeout(() =>{
        //     CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData => {
        //         if (retData.length > 0) {
        //             let emptyObj = {};
        //             Object.keys(retData[0]).forEach(e => emptyObj[e] = '');
        //             retData.unshift(emptyObj);
        //         }
        //         sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData));
        //         this.dsAmministratoreFiltro = _.uniq(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00012')), elm => elm.id_amministratore)).map(elm => { return { VALUE: elm.id_amministratore.toString(), TEXT: elm.ragione_sociale, telefono1: elm.telefono1, email1: elm.email1 } });
        //         document.getElementById(amministratore).ej2_instances[0].dataSource = this.dsAmministratoreFiltro;
        //     }, messaggio => {
        //         toast.warn(messaggio, {
        //             onClose: () => toast.dismiss(),
        //             position: "bottom-right",
        //             autoClose: 5000,
        //             hideProgressBar: false,           
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: false,
        //             progress: undefined,
        //             newestOnTop: true,
        //             rtl: false,
        //             pauseOnFocusLoss: true}
        //         );
        //     });           
        // }, 300)
    }
    onChangeDanneggiatoDettaglio(args){
        this.aggiornaDettaglio('nominativo', args.value, 'CHAR', '[00005]', true);
    }
    onChangeDanneggiatoTel1Dettaglio(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('telefono1', args.value, 'CHAR', '[00005]', true);
        if (document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === CustomJS.codiceCompagniaGenerali && !CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value))
            this.aggiornaDettaglio('telefono', args.value, 'CHAR', '[12004]', false, null);
    }
    onChangeDanneggiatoEmail1Dettaglio(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('email1', args.value, 'CHAR', '[00005]', true);
        if (document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === CustomJS.codiceCompagniaGenerali && !CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value))
            this.aggiornaDettaglio('email', args.value, 'CHAR', '[12004]', false, null);
    }
    onChangeDanneggiatoCodFiscDettaglio(args) {
        this.aggiornaDettaglio('codice_fiscale', args.value?.toUpperCase(), 'CHAR', '[00005]', true);
    }
    onChangeDanneggiatoPartIVADettaglio(args) {
        this.aggiornaDettaglio('partita_iva', args.value?.toUpperCase(), 'CHAR', '[00005]', true);
    }
    onChangeDanneggiatoIBANDettaglio(args) {
        this.aggiornaDettaglio('IBAN', args.value?.toUpperCase(), 'CHAR', '[00005]', true);
    }
    onChangeIndirizzoDettaglio(args){
        this.aggiornaDettaglio('indirizzo', args.value, 'CHAR');
    }
    onChangeCapDettaglio(args){
        this.aggiornaDettaglio('cap', args.value, 'CHAR');
    }
    onChangeCittaDettaglio(args){
        this.aggiornaDettaglio('citta', args.value, 'CHAR');
    }
    onChangeProvinciaDettaglio(args){
        this.aggiornaDettaglio('provincia', args.value, 'CHAR');
    }
    onChangeEstrattoDenunciaDettaglio(args){
        this.aggiornaDettaglio('estratto_denuncia', args.value, 'CHAR');
    }
    onChangeDocGiustificativiDettaglio(args){
        this.aggiornaDettaglio('doc_giustificativi', args.value, 'INT');
    }
    onChangeVRiservaDettaglio(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if(!document.getElementById(`checkRiservaComunicata${idSin}`).ej2_instances[0].checked){
            document.getElementById(`riservaComunicata${idSin}`).ej2_instances[0].value = document.getElementById(`riservaDenuncia${idSin}`).ej2_instances[0].value;
            this.aggiornaDettaglio('riserva_comunicata', args.value, 'FLOAT');
        }
        if(document.getElementById(`riservaComunicata${idSin}`).ej2_instances[0].placeholder === 'Riserva Da Comunicare'){
            document.getElementById(`riservaComunicata${idSin}`).ej2_instances[0].value = document.getElementById(`riservaDenuncia${idSin}`).ej2_instances[0].value;
        }
        this.aggiornaDettaglio('v_riserva', args.value, 'FLOAT');
    }
    onChangeVRichiestaDettaglio(args){
        this.aggiornaDettaglio('v_richiesta', args.value, 'FLOAT');
    }
    onChangeAppVerificareDettaglio(args){
        this.aggiornaDettaglio('apparecchi_verificare', args.value, 'INT');
    }
    onChangeRipristinoFormaDirettaDettaglio(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if(document.getElementById(`ripristinoFormaDiretta${idSin}`).ej2_instances[0].value !== 'No'){
            document.getElementById(`motivazioneNo${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`motivazioneNo${idSin}`).ej2_instances[0].text = null;
        }
        this.aggiornaDettaglio('ripristino', args.value, 'CHAR');
    }
    onChangeMotivazioneNoDettaglio(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if(document.getElementById(`ripristinoFormaDiretta${idSin}`).ej2_instances[0].value !== 'No'){
            document.getElementById(`motivazioneNo${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`motivazioneNo${idSin}`).ej2_instances[0].text = null;
        }
        this.aggiornaDettaglio('IDMotivoNoRipristino', args.value, 'INT');
    }
    onChangeAppDaRipDettaglio(args){
        this.aggiornaDettaglio('apparecchi_verificare_RFD', args.value, 'INT');
    }
    onChangeAssegnaCQDettaglio(args){
        this.aggiornaDettaglio('CQ', args.value, 'INT');
    }
    onChangeAssegnaCRDettaglio(args){
        this.aggiornaDettaglio('CR', args.value, 'INT');
    }
    onChangeDataAssegnCQDettaglio(args){
        this.aggiornaDettaglio('dataAssegnCQ', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE');
    }
    onChangeDataContrCQDettaglio(args){
        this.aggiornaDettaglio('dataCQ', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE');
    }
    onChangeDataAssegnCRDettaglio(args){       
        this.aggiornaDettaglio('dataAssegnCR', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE');
    }
    onChangeDataContrCRDettaglio(args){       
        this.aggiornaDettaglio('dataCR', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE');
    }
    onChangeIstruzioniChiusura(args){
        this.aggiornaDettaglio('istruzioni_chiusura', args.value, 'CHAR');
    }
    onChangeParcellaDettaglio(args){
        this.aggiornaDettaglio('parcella', args.value, 'FLOAT');
    }
    onChangeParcellaPeritoDettaglio(args){
        this.aggiornaDettaglio('parcellaPerito', args.value, 'FLOAT');
    }
    onChangeParcellaCATDettaglio(args){
        this.aggiornaDettaglio('parcellaCAT', args.value, 'FLOAT');
    }  
    onChangeCambioStato(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (!CustomJS.isNullEmpty(args.itemData?.timer)) {
            document.getElementById(`dataAlert${idSin}`).ej2_instances[0].enabled = true;
            document.getElementById(`mask_dataAlert${idSin}`).ej2_instances[0].enabled = true;
            document.getElementById(`dataAlert${idSin}`).ej2_instances[0].value = moment(new Date().setDate(new Date().getDate() + args.itemData.timer)).format('DD/MM/YYYY');
            document.getElementById(`mask_dataAlert${idSin}`).ej2_instances[0].value = moment(new Date().setDate(new Date().getDate() + args.itemData.timer)).format('DD/MM/YYYY');
        }
        else {
            document.getElementById(`dataAlert${idSin}`).ej2_instances[0].enabled = false;
            document.getElementById(`mask_dataAlert${idSin}`).ej2_instances[0].enabled = false;
            document.getElementById(`dataAlert${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`mask_dataAlert${idSin}`).ej2_instances[0].value = null;
        }
    }
    onChangecheckRiservaComunicata(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if(args.checked === false){
            document.getElementById(`riservaComunicata${idSin}`).ej2_instances[0].placeholder = 'Riserva Da Comunicare';
            document.getElementById(`riservaComunicata${idSin}`).ej2_instances[0].value = document.getElementById(`riservaDenuncia${idSin}`).ej2_instances[0].value;
            this.aggiornaDettaglio('check_riserva_comunicata', args.checked, 'INT');   
        }
        if(args.checked === true && !CustomJS.isNullEmpty(document.getElementById(`riservaDenuncia${idSin}`).ej2_instances[0].value)){
            document.getElementById(`riservaComunicata${idSin}`).ej2_instances[0].value = document.getElementById(`riservaDenuncia${idSin}`).ej2_instances[0].value;
            document.getElementById(`riservaComunicata${idSin}`).ej2_instances[0].placeholder = 'Riserva Comunicata';
            this.aggiornaDettaglio('check_riserva_comunicata', args.checked, 'INT');   
        }
        this.aggiornaDettaglio('riserva_comunicata', document.getElementById(`riservaComunicata${idSin}`).ej2_instances[0].value, 'FLOAT');
    }
    onChangeEsitoPeriziaUnipol(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (args.value === '03' || args.value === '05') {
            document.getElementById(`noteGaranziaRamoCorrettoUnipol${idSin}`).ej2_instances[0].enabled = true;
        }
        else {
            document.getElementById(`noteGaranziaRamoCorrettoUnipol${idSin}`).ej2_instances[0].enabled = false;
            document.getElementById(`noteGaranziaRamoCorrettoUnipol${idSin}`).ej2_instances[0].value = null;
            this.aggiornaDettaglio('noteGaranziaRamoCorretto', null, 'CHAR', '[10003]');
            document.getElementById(`noteArticoloPolizzaImpattatoUnipol${idSin}`).ej2_instances[0].enabled = false;
            document.getElementById(`noteArticoloPolizzaImpattatoUnipol${idSin}`).ej2_instances[0].value = null;
            this.aggiornaDettaglio('noteArticoloPolizzaImpattato', null, 'CHAR', '[10003]');
        }
        if (args.value === '05') {
            document.getElementById(`noteArticoloPolizzaImpattatoUnipol${idSin}`).ej2_instances[0].enabled = true;
        }
        if (args.value !== '02') {
            document.getElementById(`noteMotivoNegativaUnipol${idSin}`).ej2_instances[0].enabled = false;
            document.getElementById(`noteMotivoNegativaUnipol${idSin}`).ej2_instances[0].value = null;
            this.aggiornaDettaglio('noteNegativa', null, 'CHAR', '[10003]');
        }
        else
            document.getElementById(`noteMotivoNegativaUnipol${idSin}`).ej2_instances[0].enabled = true;
        this.aggiornaDettaglio('codiceEsitoPerizia', args.value, 'CHAR', '[10003]');
    }
    onChangeDataFirmaAttoUnipol(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (CustomJS.isNullEmpty(args.value)) {
            document.getElementById(`mancataDefinizioneUnipol${idSin}`).ej2_instances[0].enabled = true;
            this.aggiornaDettaglio('attoAccertamentoSottoscritto', 0, 'INT', '[10003]');
            this.cancellaDefinizioneDannoUnipolSAI();
        }
        else {
            document.getElementById(`mancataDefinizioneUnipol${idSin}`).ej2_instances[0].enabled = false;
            document.getElementById(`mancataDefinizioneUnipol${idSin}`).ej2_instances[0].value = null;
            this.aggiornaDettaglio('codiceMotivoMancataDefinizione', null, 'CHAR', '[10003]');
            this.aggiornaDettaglio('attoAccertamentoSottoscritto', 1, 'INT', '[10003]');
            this.aggiungiDefinizioneDannoUnipolSAI();
        }
        this.aggiornaDettaglio('dataSottoscrizioneAtto', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE', '[10003]');
    }
    onChangeDataVerifSinistroUnipol(args) {
        this.aggiornaDettaglio('dataOraVerificataSinistro', moment(args.value, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'), 'DATE', '[10003]');
    }
    onChangeCoerenzaDataSinistroUnipol(args) {
        this.aggiornaDettaglio('coerenzaDataSinistro', args.checked ? 1 : 0, 'INT', '[10003]');
    }
    onChangeRiparazioneEffettuataUnipol(args) {
        this.aggiornaDettaglio('riparazioneEffettuata', args.checked ? 1 : 0, 'INT', '[10003]'); 
    }
    onChangeRiparazioneTecnDistruttUnipol(args) {
        this.aggiornaDettaglio('riparazioneConTecnicheDistruttive', args.checked ? 1 : 0, 'INT', '[10003]'); 
    }
    onChangeImportoImponibileRiparazioneUnipol(args) {
        this.aggiornaDettaglio('importoImponibileRiparazione', args.value, 'FLOAT', '[10003]');
    }
    onChangeImportoIVARiparazioneUnipol(args) {
        this.aggiornaDettaglio('importoIvaRiparazione', args.value, 'FLOAT', '[10003]');
    }
    onChangePercIVARiparazioneUnipol(args) {
        this.aggiornaDettaglio('percentualeIvaRiparazione', args.value, 'FLOAT', '[10003]');
    }
    onChangeUtilitaGFKUnipol(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (args.checked) {
            document.getElementById(`tipoBeneGFKNonPresenteUnipol${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`tipoBeneGFKNonPresenteUnipol${idSin}`).ej2_instances[0].enabled = false;
            document.getElementById(`tipoBeneGFKUnipol${idSin}`).ej2_instances[0].enabled = true;
            document.getElementById(`richiestaQuotazioneGFKUnipol${idSin}`).ej2_instances[0].checked = false;
            document.getElementById(`richiestaQuotazioneGFKUnipol${idSin}`).ej2_instances[0].disabled = true;
            this.aggiornaDettaglio('codiceTipoBeneGfkNonPresente', null, 'CHAR', '[10003]');
            this.aggiornaDettaglio('richiestaQuotazioneGfk', 0, 'INT', '[10003]');
        }
        else {
            document.getElementById(`tipoBeneGFKUnipol${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`tipoBeneGFKUnipol${idSin}`).ej2_instances[0].enabled = false;
            document.getElementById(`tipoBeneGFKNonPresenteUnipol${idSin}`).ej2_instances[0].enabled = true;
            document.getElementById(`richiestaQuotazioneGFKUnipol${idSin}`).ej2_instances[0].disabled = false;
            this.aggiornaDettaglio('codiceTipoBeneGfk', null, 'CHAR', '[10003]');
        }
        this.aggiornaDettaglio('utilitaGfk', args.checked ? 1 : 0, 'INT', '[10003]');
    }
    onChangeTipoBeneGFKUnipol(args) {
        this.aggiornaDettaglio('codiceTipoBeneGfk', args.value, 'CHAR', '[10003]');  
    }
    onChangeTipoBeneGFKNonPresenteUnipol(args) {
        this.aggiornaDettaglio('codiceTipoBeneGfkNonPresente', args.value, 'CHAR', '[10003]');  
    }
    onChangeRichiestaQuotazioneGFKUnipol(args) {
        this.aggiornaDettaglio('richiestaQuotazioneGfk', args.checked ? 1 : 0, 'INT', '[10003]');
    }
    onChangeConsultazioneLorentzUnipol(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (args.checked) {
            document.getElementById(`utilitaLorentzUnipol${idSin}`).ej2_instances[0].disabled = false;
        }
        else {
            document.getElementById(`utilitaLorentzUnipol${idSin}`).ej2_instances[0].checked = false;
            document.getElementById(`utilitaLorentzUnipol${idSin}`).ej2_instances[0].disabled = true;
            this.aggiornaDettaglio('utilitaLorentz', 0, 'INT', '[10003]');
        }
        this.aggiornaDettaglio('consultazioneLorentz', args.checked ? 1 : 0, 'INT', '[10003]');
    }
    onChangeUtilitaLorentzUnipol(args) {
        this.aggiornaDettaglio('utilitaLorentz', args.checked ? 1 : 0, 'INT', '[10003]');
    }
    onChangeTipoDefinizioneUnipol(args) {
        this.aggiornaDettaglio('codiceTipoDefinizione', args.value, 'CHAR', '[10003]');
    }
    onChangeImportoFranchigiaUnipol(args) {
        this.aggiornaDettaglio('importoFranchigia', args.value, 'FLOAT', '[10003]');
    }
    onChangeTipoSinistroCorrettoUnipol(args) {
        this.aggiornaDettaglio('tipoSinistroCorretto', args.checked ? 1 : 0, 'INT', '[10003]'); 
    }
    onChangeMancataDefinizioneUnipol(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (CustomJS.isNullEmpty(args.value)) {
            document.getElementById(`noteMotivoMancataDefinizioneUnipol${idSin}`).ej2_instances[0].value = null;
            this.aggiornaDettaglio('noteMotivoMancataDefinizione', null, 'CHAR', '[10003]');
        }
        this.aggiornaDettaglio('codiceMotivoMancataDefinizione', args.value, 'CHAR', '[10003]');
    }
    onChangeNoteMotivoMancataDefinizioneUnipol(args){
        this.aggiornaDettaglio('noteMotivoMancataDefinizione', args.value, 'CHAR', '[10003]');
    }
    onChangeMotivazioneNegativaUnipol(args) {
        this.aggiornaDettaglio('codiceMotivoNegativa', args.value, 'CHAR', '[10003]');
    }
    onChangeNoteMotivoNegativaUnipol(args) {
        this.aggiornaDettaglio('noteNegativa', args.value, 'CHAR', '[10003]');
    }
    onChangeNoteGaranziaRamoCorrettoUnipol(args) {
        this.aggiornaDettaglio('noteGaranziaRamoCorretto', args.value, 'CHAR', '[10003]');
    }
    onChangeDannoEventoNaturaleUnipol(args) {
        this.aggiornaDettaglio('dannoDaEventoNaturale', args.checked ? 1 : 0, 'INT', '[10003]'); 
    }
    onChangePresenzaContestazioniUnipol(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (args.checked) {
            document.getElementById(`notePresenzaContestazioniUnipol${idSin}`).ej2_instances[0].enabled = true;
        }
        else {
            document.getElementById(`notePresenzaContestazioniUnipol${idSin}`).ej2_instances[0].enabled = false;
            document.getElementById(`notePresenzaContestazioniUnipol${idSin}`).ej2_instances[0].value = null;
            this.aggiornaDettaglio('notePresenzaContestazioni', null, 'CHAR', '[10004]');
        }
        this.aggiornaDettaglio('presenzaContestazioni', args.checked ? 1 : 0, 'INT', '[10004]'); 
    }
    onChangeNotePresenzaContestazioniUnipol(args) {
        this.aggiornaDettaglio('notePresenzaContestazioni', args.value, 'CHAR', '[10004]');
    }
    onChangeMantenimentoPolizzaUnipol(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (args.checked === false) {
            document.getElementById(`noteMantenimentoPolizzaUnipol${idSin}`).ej2_instances[0].enabled = true;
        }
        else {
            document.getElementById(`noteMantenimentoPolizzaUnipol${idSin}`).ej2_instances[0].enabled = false;
            document.getElementById(`noteMantenimentoPolizzaUnipol${idSin}`).ej2_instances[0].value = null;
            this.aggiornaDettaglio('noteMantenimentoPolizza', null, 'CHAR', '[10004]');
        }
        this.aggiornaDettaglio('mantenimentoPolizza', args.checked ? 1 : 0, 'INT', '[10004]'); 
    }
    onChangeNoteMantenimentoPolizzaUnipol(args) {
        this.aggiornaDettaglio('noteMantenimentoPolizza', args.value, 'CHAR', '[10004]');
    }
    onChangeAdempimentoPolizzaUnipol(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (args.checked === false) {
            document.getElementById(`noteAdempimentoPolizzaUnipol${idSin}`).ej2_instances[0].enabled = true;
        }
        else {
            document.getElementById(`noteAdempimentoPolizzaUnipol${idSin}`).ej2_instances[0].enabled = false;
            document.getElementById(`noteAdempimentoPolizzaUnipol${idSin}`).ej2_instances[0].value = null;
            this.aggiornaDettaglio('noteAdempimentoPolizza', null, 'CHAR', '[10004]');
        }
        this.aggiornaDettaglio('adempimentoPolizza', args.checked ? 1 : 0, 'INT', '[10004]');
    }
    onChangeNoteAdempimentoPolizzaUnipol(args) {
        this.aggiornaDettaglio('noteAdempimentoPolizza', args.value, 'CHAR', '[10004]');
    }
    onChangeNoteArticoloPolizzaImpattatoUnipol(args) {
        this.aggiornaDettaglio('noteArticoloPolizzaImpattato', args.value, 'CHAR', '[10003]');
    }
    onChangeImportoConcordatoUnipol(args) {
        this.aggiornaDettaglio('importoConcordato', args.value, 'FLOAT', '[10003]');
    }
    onChangeImportoLiquidabileUnipol(args) {
        this.aggiornaDettaglio('importoLiquidabile', args.value, 'FLOAT', '[10003]');
    }
    onChangeMezzoPagamentoUnipol(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (args.value === '02') {
            document.getElementById(`divIBANBeneficiarioUnipolRiga1-${idSin}`).style.removeProperty('display');
            document.getElementById(`divDomiciliatarioUnipolRiga1-${idSin}`).style.display = 'none';
            document.getElementById(`divDomiciliatarioUnipolRiga2-${idSin}`).style.display = 'none';
            document.getElementById(`divDomiciliatarioUnipolRiga3-${idSin}`).style.display = 'none';
            document.getElementById(`divDomiciliatarioUnipolRiga4-${idSin}`).style.display = 'none';
            document.getElementById(`divDomiciliatarioUnipolRiga5-${idSin}`).style.display = 'none';
            document.getElementById(`tipoPersonaDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`cognomeDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`nomeDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`partitaIvaDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`codiceFiscaleDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`indirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`numeroCivicoIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`capIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`comuneIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`provinciaIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`nazioneIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`comuneEsteroIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`IBANBeneficiarioUnipol${idSin}`).ej2_instances[0].value = document.getElementById(`IBANDanneggiato${idSin}`).ej2_instances[0].value;
            this.onChangeTipoPersonaDomiciliatarioUnipol({ value: null });
            this.onChangeCognomeDomiciliatarioUnipol({ value: null });
            this.onChangeNomeDomiciliatarioUnipol({ value: null });
            this.onChangePartitaIvaDomiciliatarioUnipol({ value: null });
            this.onChangeCodiceFiscaleDomiciliatarioUnipol({ value: null });
            this.onChangeIndirizzoDomiciliatarioUnipol({ value: null });
            this.onChangeNumeroCivicoIndirizzoDomiciliatarioUnipol({ value: null });
            this.onChangeCapIndirizzoDomiciliatarioUnipol({ value: null });
            this.onChangeComuneIndirizzoDomiciliatarioUnipol({ value: null });
            this.onChangeProvinciaIndirizzoDomiciliatarioUnipol({ value: null });
            this.onChangeNazioneIndirizzoDomiciliatarioUnipol({ value: null });
            this.onChangeComuneEsteroIndirizzoDomiciliatarioUnipol({ value: null });
            this.onChangeIBANBeneficiarioUnipol({ value: document.getElementById(`IBANBeneficiarioUnipol${idSin}`).ej2_instances[0].value })
        }
        else if (args.value === '06') {
            document.getElementById(`divDomiciliatarioUnipolRiga1-${idSin}`).style.removeProperty('display');
            document.getElementById(`divDomiciliatarioUnipolRiga2-${idSin}`).style.removeProperty('display');
            document.getElementById(`divDomiciliatarioUnipolRiga3-${idSin}`).style.removeProperty('display');
            document.getElementById(`divDomiciliatarioUnipolRiga4-${idSin}`).style.removeProperty('display');
            document.getElementById(`divDomiciliatarioUnipolRiga5-${idSin}`).style.removeProperty('display');
            document.getElementById(`divIBANBeneficiarioUnipolRiga1-${idSin}`).style.display = 'none';
            document.getElementById(`tipoPersonaDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = document.getElementById(`tipoPersonaBeneficiarioUnipol${idSin}`).ej2_instances[0].value;
            document.getElementById(`cognomeDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = document.getElementById(`cognomeBeneficiarioUnipol${idSin}`).ej2_instances[0].value;
            document.getElementById(`nomeDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = document.getElementById(`nomeBeneficiarioUnipol${idSin}`).ej2_instances[0].value;
            document.getElementById(`partitaIvaDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = document.getElementById(`partitaIvaBeneficiarioUnipol${idSin}`).ej2_instances[0].value;
            document.getElementById(`codiceFiscaleDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = document.getElementById(`codiceFiscaleBeneficiarioUnipol${idSin}`).ej2_instances[0].value;
            document.getElementById(`indirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = document.getElementById(`indirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value;
            document.getElementById(`numeroCivicoIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = document.getElementById(`numeroCivicoIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value;
            document.getElementById(`capIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = document.getElementById(`capIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value;
            document.getElementById(`comuneIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value =  document.getElementById(`comuneIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value?.trim();
            document.getElementById(`provinciaIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = document.getElementById(`provinciaIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value?.trim();
            document.getElementById(`nazioneIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = document.getElementById(`nazioneIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value?.trim();
            document.getElementById(`comuneEsteroIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = document.getElementById(`comuneEsteroIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value;
            document.getElementById(`IBANBeneficiarioUnipol${idSin}`).ej2_instances[0].value = null;
            this.onChangeTipoPersonaDomiciliatarioUnipol({ value: document.getElementById(`tipoPersonaDomiciliatarioUnipol${idSin}`).ej2_instances[0].value });
            this.onChangeCognomeDomiciliatarioUnipol({ value: document.getElementById(`cognomeDomiciliatarioUnipol${idSin}`).ej2_instances[0].value });
            this.onChangeNomeDomiciliatarioUnipol({ value: document.getElementById(`nomeDomiciliatarioUnipol${idSin}`).ej2_instances[0].value });
            this.onChangePartitaIvaDomiciliatarioUnipol({ value: document.getElementById(`partitaIvaDomiciliatarioUnipol${idSin}`).ej2_instances[0].value });
            this.onChangeCodiceFiscaleDomiciliatarioUnipol({ value: document.getElementById(`codiceFiscaleDomiciliatarioUnipol${idSin}`).ej2_instances[0].value });
            this.onChangeIndirizzoDomiciliatarioUnipol({ value: document.getElementById(`indirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value });
            this.onChangeNumeroCivicoIndirizzoDomiciliatarioUnipol({ value: document.getElementById(`numeroCivicoIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value });
            this.onChangeCapIndirizzoDomiciliatarioUnipol({ value: document.getElementById(`capIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value });
            this.onChangeComuneIndirizzoDomiciliatarioUnipol({ value: document.getElementById(`comuneIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value });
            this.onChangeProvinciaIndirizzoDomiciliatarioUnipol({ value: document.getElementById(`provinciaIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value });
            this.onChangeNazioneIndirizzoDomiciliatarioUnipol({ value: document.getElementById(`nazioneIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value });
            this.onChangeComuneEsteroIndirizzoDomiciliatarioUnipol({ value: document.getElementById(`comuneEsteroIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value });
            this.onChangeIBANBeneficiarioUnipol({ value: null });
        }
        this.aggiornaDettaglio('codiceMezzoPagamento', args.value, 'CHAR', '[10006]', true);
    }
    onChangeTipoBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('codiceTipoBeneficiario', args.value, 'CHAR', '[10006]', true);
    }
    onChangeTipoPersonaBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('codiceTipoPersonaBeneficiario', args.value, 'CHAR', '[10006]', true);
    }
    onChangeCognomeBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('cognomeBeneficiario', args.value, 'CHAR', '[10006]', true); 
    }
    onChangeNomeBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('nomeBeneficiario', args.value, 'CHAR', '[10006]', true); 
    }
    onChangeCodiceFiscaleBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('codiceFiscaleBeneficiario', args.value, 'CHAR', '[10006]', true); 
    }
    onChangePartitaIvaBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('partitaIvaBeneficiario', args.value, 'CHAR', '[10006]', true); 
    }
    onChangeSessoBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('sessoBeneficiario', args.value, 'CHAR', '[10006]', true);
    }
    onChangeDataNascitaBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('dataNascitaBeneficiario', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE', '[10006]', true);
    }
    onChangeComuneNascitaBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('codiceComuneNascitaBeneficiario', args.value, 'CHAR', '[10006]', true);
    }
    onChangeProvinciaNascitaBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('siglaProvinciaNascitaBeneficiario', args.value, 'CHAR', '[10006]', true);
    }
    onChangeNazioneNascitaBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('codiceNazioneNascitaBeneficiario', args.value, 'CHAR', '[10006]', true);
    }
    onChangecomuneEsteroNascitaBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('comuneEsteroNascitaBeneficiario', args.value, 'CHAR', '[10006]', true);
    }
    onChangeIndirizzoBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('indirizzoBeneficiario', args.value, 'CHAR', '[10006]', true);
    }
    onChangeNumeroCivicoIndirizzoBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('numeroCivicoIndirizzoBeneficiario', args.value, 'CHAR', '[10006]', true);       
    }
    onChangeCapIndirizzoBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('capIndirizzoBeneficiario', args.value, 'CHAR', '[10006]', true);       
    }
    onChangeComuneIndirizzoBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('codiceComuneIndirizzoBeneficiario', args.value, 'CHAR', '[10006]', true);       
    }
    onChangeProvinciaIndirizzoBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('siglaProvinciaIndirizzoBeneficiario', args.value, 'CHAR', '[10006]', true);       
    }
    onChangeNazioneIndirizzoBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('codiceNazioneIndirizzoBeneficiario', args.value, 'CHAR', '[10006]', true);       
    }
    onChangeComuneEsteroIndirizzoBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('comuneEsteroIndirizzoBeneficiario', args.value, 'CHAR', '[10006]', true);  
    }
    onChangeTipoPersonaDomiciliatarioUnipol(args) {
        this.aggiornaDettaglio('codiceTipoPersonaDomiciliatario', args.value, 'CHAR', '[10006]', true);
    }
    onChangeCognomeDomiciliatarioUnipol(args) {
        this.aggiornaDettaglio('cognomeDomiciliatario', args.value, 'CHAR', '[10006]', true); 
    }
    onChangeNomeDomiciliatarioUnipol(args) {
        this.aggiornaDettaglio('nomeDomiciliatario', args.value, 'CHAR', '[10006]', true); 
    }
    onChangeCodiceFiscaleDomiciliatarioUnipol(args) {
        this.aggiornaDettaglio('codiceFiscaleDomiciliatario', args.value, 'CHAR', '[10006]', true); 
    }
    onChangePartitaIvaDomiciliatarioUnipol(args) {
        this.aggiornaDettaglio('partitaIvaDomiciliatario', args.value, 'CHAR', '[10006]', true); 
    }
    onChangeIndirizzoDomiciliatarioUnipol(args) {
        this.aggiornaDettaglio('indirizzoDomiciliatario', args.value, 'CHAR', '[10006]', true);
    }
    onChangeNumeroCivicoIndirizzoDomiciliatarioUnipol(args) {
        this.aggiornaDettaglio('numeroCivicoIndirizzoDomiciliatario', args.value, 'CHAR', '[10006]', true);       
    }
    onChangeCapIndirizzoDomiciliatarioUnipol(args) {
        this.aggiornaDettaglio('capIndirizzoDomiciliatario', args.value, 'CHAR', '[10006]', true);       
    }
    onChangeComuneIndirizzoDomiciliatarioUnipol(args) {
        this.aggiornaDettaglio('codiceComuneIndirizzoDomiciliatario', args.value, 'CHAR', '[10006]', true);       
    }
    onChangeProvinciaIndirizzoDomiciliatarioUnipol(args) {
        this.aggiornaDettaglio('siglaProvinciaIndirizzoDomiciliatario', args.value, 'CHAR', '[10006]', true);       
    }
    onChangeNazioneIndirizzoDomiciliatarioUnipol(args) {
        this.aggiornaDettaglio('codiceNazioneIndirizzoDomiciliatario', args.value, 'CHAR', '[10006]', true);       
    }
    onChangeComuneEsteroIndirizzoDomiciliatarioUnipol(args) {
        this.aggiornaDettaglio('comuneEsteroIndirizzoDomiciliatario', args.value, 'CHAR', '[10006]', true);  
    }
    onChangeIBANBeneficiarioUnipol(args) {
        this.aggiornaDettaglio('iban', args.value, 'CHAR', '[10006]', true);
    }
    onChangeEsitoPeriziaGenerali(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (args.value === '4' || args.value === '7' || args.value === '8' || args.value === '10') {
            const QueryExec = `SELECT count(*) AS num FROM [12012] T1 INNER JOIN [12004] T2 ON T1.id_danneggiato = T2.id_danneggiato WHERE T2.id_incarico = '${document.getElementById(`idIncarico${idSin}`).value}'`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData[0].num > 0) {
                    document.getElementById(`divBtnAttoUnico${idSin}`).style.display = 'none';
                    document.getElementById(`divBtnAttiSeparati${idSin}`).style.display = 'none';
                }
                else {
                    document.getElementById(`divBtnAttoUnico${idSin}`).style.display = 'block';
                    document.getElementById(`divBtnAttiSeparati${idSin}`).style.display = 'block';
                    this.items = [];
                    JSON.parse(sessionStorage.getItem('JellyfishTechnologyStrutturaDanneggiati')).forEach(dann => {
                        if (dann.stato === 'n' || dann.stato === 'o') {
                            let objItem = {
                                text: dann.nominativo,
                                id: dann.guidDanneggiato,
                            }
                            this.items.push(objItem);
                        }
                    });
                    setTimeout(() => {
                        document.getElementById(`btnAttoUnico${idSin}`).ej2_instances[0].items = this.items;
                    }, 300)
                }
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        }
        else {
            document.getElementById(`divBtnAttoUnico${idSin}`).style.display = 'none';
            document.getElementById(`divBtnAttiSeparati${idSin}`).style.display = 'none';
        }
        if (args.value === '14') {
            document.getElementById(`divDataFirmaAttoNonConcordato${idSin}`).style.display = 'block';
        }
        else {
            document.getElementById(`divDataFirmaAttoNonConcordato${idSin}`).style.display = 'none';
        }
        this.aggiornaDettaglio('esito_codice', args.value, 'CHAR', '[12004]', false, null);
        this.aggiornaDettaglio('esito_descrizione', args.target?.itemData?.TEXT ?? null, 'CHAR', '[12004]', false, null);
    }
    onChangePolizzaRegolaritaAmministrativa(args) {
        this.aggiornaDettaglio('polizza_regolaritaAmministrativa', args.checked, 'INT', '[12003]', null, null);
    }
    onChangePolizzaVincoli(args) {
        this.aggiornaDettaglio('polizza_vincoli', args.checked, 'INT', '[12003]', null, null);
    }
    onChangeProdotto(args) {
        this.aggiornaDettaglio('codice', args.value, 'CHAR', '[12018]', true, null);
        this.aggiornaDettaglio('prodotto', args.target.itemData.TEXT, 'CHAR', '[12018]', true, null);
        this.aggiornaDettaglio('descrizione', 'Nessuna', 'CHAR', '[12018]', true, null);
    }
    onChangeDescrizioneProdotto(args) {
        this.aggiornaDettaglio('descrizione', args.value, 'CHAR', '[12018]', true, null);
    }
    onChangeTipoServizio(args) {
        this.aggiornaDettaglio('servizioEseguito_codice', args.value, 'CHAR', '[12003]', null, null);
        this.aggiornaDettaglio('servizioEseguito_descrizione', args.target.itemData.TEXT, 'CHAR', '[12003]', null, null);
    }
    onChangeDataPagamentoPremio(args) {
        this.aggiornaDettaglio('polizza_dataPagamentoPremio', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE', '[12003]', null, null);
    }
    onChangeMantenimentoPolizza(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('mantenimento_polizza', args.checked, 'INT', '[12003]', null, null);
        if (args.checked === true) {
            document.getElementById(`notaMantenimentoPolizza${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`notaMantenimentoPolizza${idSin}`).ej2_instances[0].readonly = true;
            this.aggiornaDettaglio('mantenimento_polizza_nota', null, 'CHAR', '[12003]', null, null);
        }
        else 
            document.getElementById(`notaMantenimentoPolizza${idSin}`).ej2_instances[0].readonly = false;
    }
    onChangeNotaNonMantenimentoPolizza(args) {
        this.aggiornaDettaglio('mantenimento_polizza_nota', args.value, 'CHAR', '[12003]', null, null);
    }
    onChangeNotaRitardoConsegna(args) {
        this.aggiornaDettaglio('note_ritardo_consegna', args.value, 'CHAR', '[12003]', null, null);
    }
    onChangeTipoLiquidazione(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('tipoLiquidazione_codice', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        this.aggiornaDettaglio('tipoLiquidazione_descrizione', args.target?.itemData.TEXT ?? null, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeTipoLiquidazione2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('tipoLiquidazione_codice', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        this.aggiornaDettaglio('tipoLiquidazione_descrizione', args.text ?? args.target?.itemData.TEXT ?? null, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeTipoBeneficiario(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('tipoBeneficiario_codice', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        this.aggiornaDettaglio('tipoBeneficiario_descrizione', args.target?.itemData.TEXT ?? null, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeTipoBeneficiario2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('tipoBeneficiario_codice', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        this.aggiornaDettaglio('tipoBeneficiario_descrizione', args.text ?? args.target?.itemData.TEXT ?? null, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeRagioneSocialeAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (!CustomJS.isNullEmpty(args.value)) {
            document.getElementById(`nomeAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            document.getElementById(`cognomeAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            document.getElementById(`sessoAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            document.getElementById(`dataNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            document.getElementById(`provinciaNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            document.getElementById(`nazioneNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            document.getElementById(`nomeAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = false;
            document.getElementById(`cognomeAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = false;
            document.getElementById(`sessoAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = false;
            document.getElementById(`dataNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = false;
            document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = false;
            document.getElementById(`provinciaNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = false;
            document.getElementById(`nazioneNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = false;
            document.getElementById(`estrapolaCodiceFiscale${idSin}_${indice}`).ej2_instances[0].disabled = true;
            document.getElementById(`generaCodiceFiscale${idSin}_${indice}`).ej2_instances[0].disabled = true;
            this.aggiornaDettaglio('nome_liquidazione', null, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
            this.aggiornaDettaglio('cognome_liquidazione', null, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
            this.aggiornaDettaglio('sesso_liquidazione', null, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
            this.aggiornaDettaglio('data_nascita_liquidazione', null, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
            this.aggiornaDettaglio('luogoNascita_denominazione', null, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
            this.aggiornaDettaglio('luogoNascita_codiceBelfiore', null, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
            this.aggiornaDettaglio('luogoNascita_provincia', null, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
            this.aggiornaDettaglio('luogoNascita_nazione', null, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        }
        else {
            document.getElementById(`nomeAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = true;
            document.getElementById(`cognomeAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = true;
            document.getElementById(`sessoAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = true;
            document.getElementById(`dataNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = true;
            document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = true;
            document.getElementById(`provinciaNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = true;
            document.getElementById(`nazioneNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = true;
            document.getElementById(`estrapolaCodiceFiscale${idSin}_${indice}`).ej2_instances[0].disabled = false;
            document.getElementById(`generaCodiceFiscale${idSin}_${indice}`).ej2_instances[0].disabled = false;
        }
        this.aggiornaDettaglio('ragione_sociale_liquidazione', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegno_nome', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegno_cognome', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeRagioneSocialeAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (!CustomJS.isNullEmpty(args.value)) {
            document.getElementById(`nomeAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            document.getElementById(`cognomeAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            document.getElementById(`sessoAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            document.getElementById(`dataNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            document.getElementById(`provinciaNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            document.getElementById(`nazioneNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            document.getElementById(`nomeAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = false;
            document.getElementById(`cognomeAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = false;
            document.getElementById(`sessoAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = false;
            document.getElementById(`dataNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = false;
            document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = false;
            document.getElementById(`provinciaNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = false;
            document.getElementById(`nazioneNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = false;
            document.getElementById(`estrapolaCodiceFiscale${idSin}_${indice}`).ej2_instances[0].disabled = true;
            document.getElementById(`generaCodiceFiscale${idSin}_${indice}`).ej2_instances[0].disabled = true;
            this.aggiornaDettaglio('nome_liquidazione', null, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
            this.aggiornaDettaglio('cognome_liquidazione', null, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
            this.aggiornaDettaglio('sesso_liquidazione', null, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
            this.aggiornaDettaglio('data_nascita_liquidazione', null, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
            this.aggiornaDettaglio('luogoNascita_denominazione', null, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
            this.aggiornaDettaglio('luogoNascita_codiceBelfiore', null, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
            this.aggiornaDettaglio('luogoNascita_provincia', null, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
            this.aggiornaDettaglio('luogoNascita_nazione', null, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        }
        else {
            document.getElementById(`nomeAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = true;
            document.getElementById(`cognomeAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = true;
            document.getElementById(`sessoAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = true;
            document.getElementById(`dataNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = true;
            document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = true;
            document.getElementById(`provinciaNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = true;
            document.getElementById(`nazioneNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].enabled = true;
            document.getElementById(`estrapolaCodiceFiscale${idSin}_${indice}`).ej2_instances[0].disabled = false;
            document.getElementById(`generaCodiceFiscale${idSin}_${indice}`).ej2_instances[0].disabled = false;
        }
        this.aggiornaDettaglio('ragione_sociale_liquidazione', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegno_nome', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegno_cognome', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeNomeAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('nome_liquidazione', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegno_nome', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeNomeAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('nome_liquidazione', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegno_nome', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeCognomeAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('cognome_liquidazione', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegno_cognome', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeCognomeAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('cognome_liquidazione', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegno_cognome', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeCodiceFiscaleAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('codiceFiscale_partitaIVA', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        this.aggiornaDettaglio('codiceFiscale_partitaIVA', args.value, 'CHAR', '[12004]', false, null);
    }
    onChangeCodiceFiscaleAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('codiceFiscale_partitaIVA', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        this.aggiornaDettaglio('codiceFiscale_partitaIVA', args.value, 'CHAR', '[12004]', false, null);
    }
    onChangeSessoAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('sesso_liquidazione', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeSessoAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('sesso_liquidazione', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeDataNascitaAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('data_nascita_liquidazione', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeDataNascitaAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('data_nascita_liquidazione', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeProvinciaNascitaAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('luogoNascita_provincia', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeProvinciaNascitaAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('luogoNascita_provincia', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeNazioneNascitaAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('luogoNascita_nazione', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        if (args.value !== 'ITA') {
            this.aggiornaDettaglio('luogoNascita_denominazione', 'Città Estera', 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
            this.aggiornaDettaglio('luogoNascita_codiceBelfiore', args.target.itemData?.cod_ele, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        }
    }
    onChangeNazioneNascitaAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('luogoNascita_nazione', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        if (args.value !== 'ITA') {
            this.aggiornaDettaglio('luogoNascita_denominazione', 'Città Estera', 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
            this.aggiornaDettaglio('luogoNascita_codiceBelfiore', args.target.itemData?.cod_ele, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        }
    }
    onChangeViaResidenzaAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('indirizzo_viaPiazza', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_viaPiazza', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeViaResidenzaAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('indirizzo_viaPiazza', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_viaPiazza', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeCapResidenzaAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('indirizzo_CAP', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_CAP', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeCapResidenzaAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('indirizzo_CAP', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_CAP', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeProvinciaResidenzaAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;   
        this.aggiornaDettaglio('indirizzo_provincia', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_provincia', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeProvinciaResidenzaAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;   
        this.aggiornaDettaglio('indirizzo_provincia', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_provincia', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeNazioneResidenzaAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('indirizzo_nazione', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_nazione', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeNazioneResidenzaAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('indirizzo_nazione', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_nazione', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeNumeroAssegnoAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('numero_assegno', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeNumeroAssegnoAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('numero_assegno', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeDataEmissioneAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('data_emissione', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeDataEmissioneAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('data_emissione', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeIbanAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('IBAN_liquidazione', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
        this.aggiornaDettaglio('IBAN', args.value, 'CHAR', '[12004]', false, null);
    }
    onChangeIbanAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('IBAN_liquidazione', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        this.aggiornaDettaglio('IBAN', args.value, 'CHAR', '[12004]', false, null);
    }
    onChangeImportoRichiestoAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('importo_richiesto', args.value, 'FLOAT', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeImportoRichiestoAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('importo_richiesto', args.value, 'FLOAT', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeImportoLiquidazioneAssicurato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('importo_liquidazione', args.value, 'FLOAT', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeImportoLiquidazioneAssicurato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('importo_liquidazione', args.value, 'FLOAT', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeNoteDifformitaRichiestoCalcolato(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('note_difformita_richiesto_calcolato', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeNoteDifformitaRichiestoCalcolato2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('note_difformita_richiesto_calcolato', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeNoteLiquidazione(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('note_liquidazione', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
    }
    onChangeNoteLiquidazione2(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaDettaglio('note_liquidazione', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
    }
    onChangeTipoPagamento(indice, alternUpd, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (args.value === '2') {
            document.getElementById(`divIban${idSin}_${indice}`).style.setProperty('display', 'none');
            document.getElementById(`divNumeroAssegno${idSin}_${indice}`).style.setProperty('display', 'none');
            document.getElementById(`divDataAssegno${idSin}_${indice}`).style.setProperty('display', 'none');
        }
        if (args.value === '3') {
            document.getElementById(`divNumeroAssegno${idSin}_${indice}`).style.removeProperty('display');
            document.getElementById(`divDataAssegno${idSin}_${indice}`).style.removeProperty('display');
            document.getElementById(`divIban${idSin}_${indice}`).style.setProperty('display', 'none');
        }
        if (args.value === '4') {
            document.getElementById(`divIban${idSin}_${indice}`).style.removeProperty('display');
            if (alternUpd) {
                document.getElementById(`ibanAssicurato${idSin}_${indice}`).ej2_instances[0].value = document.getElementById(`IBANDanneggiato${idSin}`).ej2_instances[0].value;
                this.onChangeIbanAssicurato2(indice, { value: document.getElementById(`IBANDanneggiato${idSin}`).ej2_instances[0].value });
            }
            document.getElementById(`divNumeroAssegno${idSin}_${indice}`).style.setProperty('display', 'none');
            document.getElementById(`divDataAssegno${idSin}_${indice}`).style.setProperty('display', 'none');
        }
        if (alternUpd) {
            this.aggiornaDettaglio('modalitaPagamento_codice', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
            this.aggiornaDettaglio('modalitaPagamento_descrizione', args.text ?? args.target?.itemData.TEXT ?? null, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
        }
        else {
            this.aggiornaDettaglio('modalitaPagamento_codice', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
            this.aggiornaDettaglio('modalitaPagamento_descrizione', args.target?.itemData.TEXT ?? null, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
       }
    }
    onChangeDataFirmaAtto2(args) {
        this.aggiornaDettaglio('data_accertamento_conservativo', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE', '[12004]', null, null);
    }
    onChangeDataFirmaAttoNonConcordato(args) {
        this.aggiornaDettaglio('data_accertamento_conservativo', moment(args.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE', '[12004]', null, null);
    }
    onChangeComuneResidenzaAssicurato(indice, alternUpd, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT cod_ele, descriz, servizio_str1, servizio_str2 FROM [09011] WHERE cod_tab = 'codici_comuni' AND ISNULL(servizio_str1, '') <> '' AND cod_ele = '${args.value}'`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData6 => {
            if (CustomJS.isNullEmpty(args.value)) {
                document.getElementById(`capResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
                document.getElementById(`provinciaResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            }
            else {
                document.getElementById(`capResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value = retData6[0].servizio_str2;
                document.getElementById(`provinciaResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value = retData6[0].servizio_str1;
            }
            document.getElementById(`nazioneResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`comuneResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value) ? null : 'ITA';
            if (alternUpd) {
                this.aggiornaDettaglio('indirizzo_CAP', document.getElementById(`capResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                this.aggiornaDettaglio('indirizzo_denominazione', retData6[0].descriz ?? args.target?.itemData.DESCRIZ ?? null, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                this.aggiornaDettaglio('indirizzo_codiceBelfiore', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                this.aggiornaDettaglio('indirizzo_provincia', document.getElementById(`provinciaResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                this.aggiornaDettaglio('indirizzo_nazione', document.getElementById(`nazioneResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_CAP', document.getElementById(`capResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_denominazione', retData6[0].descriz ?? args.target?.itemData.DESCRIZ ?? null, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_codiceBelfiore', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_provincia', document.getElementById(`provinciaResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_nazione', document.getElementById(`nazioneResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
            }
            else {
                this.aggiornaDettaglio('indirizzo_CAP', document.getElementById(`capResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('indirizzo_denominazione', args.target?.itemData.DESCRIZ ?? null, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('indirizzo_codiceBelfiore', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('indirizzo_provincia', document.getElementById(`provinciaResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('indirizzo_nazione', document.getElementById(`nazioneResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_CAP', document.getElementById(`capResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_denominazione', args.target?.itemData.DESCRIZ ?? null, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_codiceBelfiore', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_provincia', document.getElementById(`provinciaResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('destinatarioAssegnoIndirizzo_nazione', document.getElementById(`nazioneResidenzaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
           }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    onChangeComuneNascitaAssicurato(indice, alternUpd, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT cod_ele, descriz, servizio_str1, servizio_str2 FROM [09011] WHERE cod_tab = 'codici_comuni' AND ISNULL(servizio_str1, '') <> '' AND cod_ele = '${args.value}'`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData6 => {
            if (CustomJS.isNullEmpty(args.value)) {
                document.getElementById(`provinciaNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
            }
            else {
                document.getElementById(`provinciaNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = retData6[0].servizio_str1;
            }
            document.getElementById(`nazioneNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value) ? null : 'ITA';
            if (alternUpd) {
                this.aggiornaDettaglio('luogoNascita_provincia', document.getElementById(`provinciaNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                this.aggiornaDettaglio('luogoNascita_denominazione', args.target.itemData.DESCRIZ, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                this.aggiornaDettaglio('luogoNascita_codiceBelfiore', args.value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                this.aggiornaDettaglio('luogoNascita_nazione', document.getElementById(`nazioneNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
            }
            else {
                this.aggiornaDettaglio('luogoNascita_provincia', document.getElementById(`provinciaNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('luogoNascita_denominazione', args.target.itemData.DESCRIZ, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('luogoNascita_codiceBelfiore', args.value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('luogoNascita_nazione', document.getElementById(`nazioneNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
   creaLiquidazione(atto, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let arrayLiquidazioni = JSON.parse(sessionStorage.getItem('JellyfishTechnologyStrutturaDanneggiati'));
        for (let i = 0; i < arrayLiquidazioni.length; i++) {
            if (arrayLiquidazioni[i].stato === 'n' || arrayLiquidazioni[i].stato === 'o') {
                var divElm = document.createElement("div");
                divElm.setAttribute("id", `divLiquidazione${idSin}_${i}`);
                document.getElementById(`divLiquidazioni${idSin}`).appendChild(divElm);
                const container = document.getElementById(`divLiquidazione${idSin}_${i}`);
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    if (!CustomJS.isNullEmpty(document.getElementById(`partitaIVADanneggiato${idSin}`).ej2_instances[0].value)) {
                        document.getElementById(`ragioneSocialeAssicurato${idSin}_${i}`).ej2_instances[0].value = document.getElementById(`danneggiato${idSin}`).ej2_instances[0].value?.trim();
                        this.onChangeRagioneSocialeAssicurato2(i, { value: document.getElementById(`danneggiato${idSin}`).ej2_instances[0].value?.trim() });
                        document.getElementById(`codiceFiscaleAssicurato${idSin}_${i}`).ej2_instances[0].value = document.getElementById(`partitaIVADanneggiato${idSin}`).ej2_instances[0].value?.trim();
                        this.onChangeCodiceFiscaleAssicurato2(i, { value: document.getElementById(`partitaIVADanneggiato${idSin}`).ej2_instances[0].value?.trim() });
                    }
                    else {
                        document.getElementById(`ragioneSocialeAssicurato${idSin}_${i}`).ej2_instances[0].value = null;
                        this.onChangeRagioneSocialeAssicurato2(i, { value: null });
                        document.getElementById(`codiceFiscaleAssicurato${idSin}_${i}`).ej2_instances[0].value = document.getElementById(`codiceFiscaleDanneggiato${idSin}`).ej2_instances[0].value?.trim();
                        this.onChangeCodiceFiscaleAssicurato2(i, { value: document.getElementById(`codiceFiscaleDanneggiato${idSin}`).ej2_instances[0].value?.trim() });
                        setTimeout(() => {
                            document.getElementById(`nomeAssicurato${idSin}_${i}`).ej2_instances[0].value = document.getElementById(`danneggiato${idSin}`).ej2_instances[0].value?.trim().split(' ')[1];
                            this.onChangeNomeAssicurato2(i, { value: document.getElementById(`danneggiato${idSin}`).ej2_instances[0].value?.trim().split(' ')[1] });
                            document.getElementById(`cognomeAssicurato${idSin}_${i}`).ej2_instances[0].value = document.getElementById(`danneggiato${idSin}`).ej2_instances[0].value?.trim().split(' ')[0];
                            this.onChangeCognomeAssicurato2(i, { value: document.getElementById(`danneggiato${idSin}`).ej2_instances[0].value?.trim().split(' ')[0] });
                            document.getElementById(`estrapolaCodiceFiscale${idSin}_${i}`).ej2_instances[0].click();
                        }, 800);
                    }
                    document.getElementById(`tipoLiquidazione${idSin}_${i}`).ej2_instances[0].value = '01';
                    this.onChangeTipoLiquidazione2(i, { value: '01', text: 'TOTALE' });
                    document.getElementById(`tipoPagamento${idSin}_${i}`).ej2_instances[0].value = '4';
                    this.onChangeTipoPagamento(i, true, { value: '4', text: 'BONIFICO BANCARIO' });
                    document.getElementById(`tipoBeneficiario${idSin}_${i}`).ej2_instances[0].value = '1';
                    this.onChangeTipoBeneficiario2(i, { value: '1', text: 'ASSICURATO' });
                    document.getElementById(`viaResidenzaAssicurato${idSin}_${i}`).ej2_instances[0].value = document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value;
                    this.onChangeViaResidenzaAssicurato2(i, { value: document.getElementById(`viaResidenzaAssicurato${idSin}_${i}`).ej2_instances[0].value });
                    document.getElementById(`comuneResidenzaAssicurato${idSin}_${i}`).ej2_instances[0].value = _.find(this.dsComuni, elm => { return elm.DESCRIZ?.trim() === document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim() })?.cod_ele ?? null;                    
                    this.onChangeComuneResidenzaAssicurato(i, true, { value: document.getElementById(`comuneResidenzaAssicurato${idSin}_${i}`).ej2_instances[0].value });
                    document.getElementById(`importoRichiestoAssicurato${idSin}_${i}`).ej2_instances[0].value = document.getElementById(`richiestaDenuncia${idSin}`).ej2_instances[0].value;
                    this.onChangeImportoRichiestoAssicurato2(i, { value: document.getElementById(`importoRichiestoAssicurato${idSin}_${i}`).ej2_instances[0].value });
                    this.getImportoLiquidazione(); 
                    setTimeout(() => {
                        this.onChangeImportoLiquidazioneAssicurato2(i, { value: document.getElementById(`importoLiquidazioneAssicurato${idSin}_${i}`).ej2_instances[0].value });
                    }, 150);
                    $(`[id=noteDifformitaImporto${idSin}_${i}_toolbar_FontColor_Target]`).hide();   
                    $(`[id=altreNote${idSin}_${i}_toolbar_BackgroundColor_Target]`).hide();   
                }}><div id={`divLiquidazioni${idSin}_${i}`}>
                    <input type="hidden" id={`guidDanneggiato${idSin}_${i}`} value={arrayLiquidazioni[i].guidDanneggiato}/>
                    <div className="row">
                        <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8">
                            <h5>Dati Beneficiario: {atto === 'unico' ? args.item.text : this.takeNomiDanneggiati[i]}</h5>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                            <ButtonComponent id={`generaCodiceFiscale${idSin}_${i}`} title='Genera Codice Fiscale' cssClass='e-fill e-info' type='button' onClick={this.onClickBtnGeneraCodiceFiscale.bind(this, i)}>GENERA CODICE FISCALE</ButtonComponent>
                            <ButtonComponent id={`estrapolaCodiceFiscale${idSin}_${i}`} title='Ottieni Dati dal Codice Fiscale' cssClass='e-fill e-info' style={{ marginTop: '5px' }} type='button' onClick={this.estrapolaDatiCodiceFiscale.bind(this, i, true)}>OTTIENI DATI DAL CODICE FISCALE</ButtonComponent>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <DropDownListComponent id={`tipoLiquidazione${idSin}_${i}`} fields={this.ddlFields} dataSource={this.dsTipoLiquidazione}
                                allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeTipoLiquidazione2.bind(this, i)}
                                placeholder="Tipo Liquidazione" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <DropDownListComponent id={`tipoBeneficiario${idSin}_${i}`} fields={this.ddlFields} dataSource={this.dsTipoBeneficiario} value={'1'}
                                allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeTipoBeneficiario.bind(this, i)}
                                placeholder="Tipo Beneficiario" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <DropDownListComponent id={`tipoPagamento${idSin}_${i}`} fields={this.ddlFields} dataSource={this.dsTipoPagamento} onChange={this.onChangeTipoPagamento.bind(this, i, true)} value=''
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="Tipo Pagamento" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <DatePickerComponent firstDayOfWeek={1} id={`dataFirmaAtto2${idSin}_${i}`} cssClass='e-outline' format='dd/MM/yyyy'
                                showClearButton={true} placeholder='Data Firma Atto' floatLabelType='Always' change={this.onChangeDataFirmaAtto2.bind(this,i)}></DatePickerComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <TextBoxComponent id={`ragioneSocialeAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='Ragione Sociale' cssClass='e-outline' blur={this.onChangeRagioneSocialeAssicurato2.bind(this, i)}></TextBoxComponent>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <TextBoxComponent id={`nomeAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='Nome' cssClass='e-outline' blur={this.onChangeNomeAssicurato2.bind(this, i)}></TextBoxComponent>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <TextBoxComponent id={`cognomeAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='Cognome' cssClass='e-outline' blur={this.onChangeCognomeAssicurato2.bind(this, i)}></TextBoxComponent>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <TextBoxComponent id={`codiceFiscaleAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='Codice Fiscale/P.Iva' cssClass='e-outline' blur={this.onChangeCodiceFiscaleAssicurato2.bind(this, i)}></TextBoxComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">
                            <DropDownListComponent id={`sessoAssicurato${idSin}_${i}`} fields={this.ddlFields} dataSource={this.dsSessoAssicurato} 
                                allowFiltering={true} filterType='Contains' showClearButton={false} onChange={this.onChangeSessoAssicurato2.bind(this, i)}
                                placeholder="Sesso" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" id='divDataNascita'>
                            <DatePickerComponent firstDayOfWeek={1} id={`dataNascitaAssicurato${idSin}_${i}`} cssClass='e-outline' format='dd/MM/yyyy'
                                showClearButton={true} placeholder='Data Nascita' floatLabelType='Always' change={this.onChangeDataNascitaAssicurato2.bind(this,i)}></DatePickerComponent>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                            <DropDownListComponent id={`luogoNascitaAssicurato${idSin}_${i}`} fields={{ text: 'DESCRIZ', value: 'cod_ele' }} onChange={this.onChangeComuneNascitaAssicurato.bind(this, i, true)}
                                allowFiltering={true} filterType='Contains' showClearButton={true} dataSource={this.dsComuni}
                                placeholder="Luogo Nascita" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <DropDownListComponent id={`provinciaNascitaAssicurato${idSin}_${i}`} fields={this.ddlFields} dataSource={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaProvince2')))}
                                allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeProvinciaNascitaAssicurato2.bind(this, i)}
                                placeholder="Provincia Nascita" floatLabelType='always' popupHeight="350px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <DropDownListComponent id={`nazioneNascitaAssicurato${idSin}_${i}`} fields={{ text: 'DESCRIZ', value: 'VALUE' }}
                                allowFiltering={true} filterType='Contains' showClearButton={true} dataSource={this.dsNazioni} value={'ITA'}
                                placeholder="Nazione Nascita" floatLabelType='always' popupHeight="250px" cssClass='e-outline' onChange={this.onChangeNazioneNascitaAssicurato2.bind(this, i)}>
                            </DropDownListComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <TextBoxComponent id={`viaResidenzaAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='Indirizzo' cssClass='e-outline' blur={this.onChangeViaResidenzaAssicurato2.bind(this, i)}></TextBoxComponent>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <DropDownListComponent id={`comuneResidenzaAssicurato${idSin}_${i}`} fields={{ text: 'DESCRIZ', value: 'cod_ele' }} onChange={this.onChangeComuneResidenzaAssicurato.bind(this, i, true)} 
                                allowFiltering={true} filterType='Contains' showClearButton={true} dataSource={this.dsComuni}
                                placeholder="Comune" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <TextBoxComponent id={`capResidenzaAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='CAP' cssClass='e-outline' blur={this.onChangeCapResidenzaAssicurato2.bind(this, i)}></TextBoxComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <DropDownListComponent id={`provinciaResidenzaAssicurato${idSin}_${i}`} fields={this.ddlFields} dataSource={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaProvince2')))} 
                                allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeProvinciaResidenzaAssicurato2.bind(this, i)}
                                placeholder="Provincia" floatLabelType='always' popupHeight="350px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <DropDownListComponent id={`nazioneResidenzaAssicurato${idSin}_${i}`} fields={{ text: 'DESCRIZ', value: 'VALUE' }} value='ITA'
                                allowFiltering={true} filterType='Contains' showClearButton={true} dataSource={this.dsNazioni}
                                placeholder="Nazione" floatLabelType='always' popupHeight="250px" cssClass='e-outline' onChange={this.onChangeNazioneResidenzaAssicurato2.bind(this, i)}>
                            </DropDownListComponent>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" id={`divNumeroAssegno${idSin}_${i}`} style={{ display: 'none' }}>
                            <TextBoxComponent id={`numeroAssegnoAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='N° Assegno' cssClass='e-outline' blur={this.onChangeNumeroAssegnoAssicurato2.bind(this, i)}></TextBoxComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" id={`divDataAssegno${idSin}_${i}`} style={{ display: 'none' }}>
                            <DatePickerComponent firstDayOfWeek={1} id={`dataEmissioneAssicurato${idSin}_${i}`} cssClass='e-outline' format='dd/MM/yyyy'
                                showClearButton={true} placeholder='Data Emissione' floatLabelType='Always' blur={this.onChangeDataEmissioneAssicurato2.bind(this, i)}></DatePickerComponent>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" id={`divIban${idSin}_${i}`}>
                            <TextBoxComponent id={`ibanAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='IBAN' cssClass='e-outline' blur={this.onChangeIbanAssicurato2.bind(this, i)}></TextBoxComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <NumericTextBoxComponent id={`importoRichiestoAssicurato${idSin}_${i}`} format="c2" decimals='2' floatLabelType='Auto' placeholder='Importo Richiesto' cssClass='e-outline' showSpinButton={false} change={this.onChangeImportoRichiestoAssicurato2.bind(this, i)}></NumericTextBoxComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <NumericTextBoxComponent id={`importoLiquidazioneAssicurato${idSin}_${i}`} format="c2" decimals='2' floatLabelType='Auto' placeholder='Importo Liquidazione' cssClass='e-outline' showSpinButton={false} disabled={true} value={atto === 'unico' ? this.state.importoLiquidazioneRiepilogo : null} change={this.onChangeImportoLiquidazioneAssicurato2.bind(this, i)}></NumericTextBoxComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" style={{ marginBottom: '15px' }}>
                            <h6>Note Difformità Importo</h6>
                            <RichTextEditorComponent id={`noteDifformitaImporto${idSin}_${i}`} showCharCount={true} enableHtmlEncode={true} placeholder='Note Difformità Importo Richiesto'
                                maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' change={this.onChangeNoteDifformitaRichiestoCalcolato2.bind(this, i)}>
                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table]} />
                            </RichTextEditorComponent>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" style={{ marginBottom: '15px' }}>
                            <h6>Altre Note</h6>
                            <RichTextEditorComponent id={`altreNote${idSin}_${i}`} showCharCount={true} enableHtmlEncode={true} placeholder='Altre Note'
                                maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' change={this.onChangeNoteLiquidazione2.bind(this, i)}>
                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table]} />
                            </RichTextEditorComponent>
                        </div>
                    </div>
                </div></CallbackWrapper>);
                arrayLiquidazioni.forEach(dann => {
                    dann.liquidazione = 's';
                });
                sessionStorage.setItem('JellyfishTechnologyStrutturaDanneggiati', JSON.stringify(arrayLiquidazioni));
                if (atto === 'unico') {
                    arrayLiquidazioni.forEach(dann => {
                        if (dann.guidDanneggiato !== args.item.id)
                            dann.liquidazione = 'n';
                    });
                    sessionStorage.setItem('JellyfishTechnologyStrutturaDanneggiati', JSON.stringify(arrayLiquidazioni));
                    return;
                }
            }
        }
    }
    aggiungiLiquidazione(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.toggleBlocking2(true);
        const QueryExec = `SELECT T1.*, T2.id_danneggiato AS guidDanneggiato, T2.id_danneggiato_jftech, T2.data_accertamento_conservativo, T2.esito_codice, T3.*, T4.finali_indenn_netti_arrotond FROM [12003] T1 LEFT OUTER JOIN [12004] T2 ON T1.id_incarico = T2.id_incarico LEFT OUTER JOIN [12012] T3 ON T2.id_danneggiato = T3.id_danneggiato LEFT OUTER JOIN [11012] T4 ON T1.id_sinistro = T4.id_sinistro WHERE T1.id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value} ORDER BY T3.id_liquidazione;`
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData1 => {
            let retData = retData1.filter(elm => { return elm.id_liquidazione.toString() !== '0' });
            console.log(retData);
            retData.forEach((elm, i) => {
                let divIbanStyle;
                let divNumeroAssegnoStyle;
                let divDataAssegnoStyle;
                if (CustomJS.isNullEmpty(elm.modalitaPagamento_codice?.trim()) || elm.modalitaPagamento_codice?.trim() === '4') {
                    divIbanStyle = 'block';
                    divNumeroAssegnoStyle = 'none';
                    divDataAssegnoStyle = 'none';
                }
                else if (elm.modalitaPagamento_codice?.trim() === '2') {
                    divIbanStyle = 'none';
                    divNumeroAssegnoStyle = 'none';
                    divDataAssegnoStyle = 'none';
                }
                else if (elm.modalitaPagamento_codice?.trim() === '3') {
                    divIbanStyle = 'none';
                    divNumeroAssegnoStyle = 'block';
                    divDataAssegnoStyle = 'block';
                }
                let valueImportoLiquidazione;
                if (CustomJS.isNullEmpty(elm.finali_indenn_netti_arrotond) || elm.finali_indenn_netti_arrotond.toString() === '0')
                    valueImportoLiquidazione = null;
                else if (retData.length === 1)
                    valueImportoLiquidazione = elm.finali_indenn_netti_arrotond;
                else
                    valueImportoLiquidazione = elm.importo_liquidazione;

                var divElm = document.createElement("div");
                divElm.setAttribute("id", `divLiquidazione${idSin}_${i}`);
                document.getElementById(`divLiquidazioni${idSin}`).appendChild(divElm);
                const container = document.getElementById(`divLiquidazione${idSin}_${i}`);
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    this.aggiornaDettaglio('importo_liquidazione', valueImportoLiquidazione, 'FLOAT', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${i}`).value);
                    $(`[id=noteDifformitaImporto${idSin}_${i}_toolbar_FontColor_Target]`).hide();   
                    $(`[id=altreNote${idSin}_${i}_toolbar_BackgroundColor_Target]`).hide();   
                }}><div id={`divLiquidazioni${idSin}_${i}`}>
                    <input type="hidden" id={`idLiquidazione${idSin}_${i}`} value={elm.id_liquidazione}/>
                    <input type="hidden" id={`guidDanneggiato${idSin}_${i}`} value={elm.guidDanneggiato}/>
                    <div className="row">
                        <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8">
                            <h5>Dati Beneficiario: {CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? ((elm.nome_liquidazione + ' ' + elm.cognome_liquidazione) ?? this.takeNomiDanneggiati[i]) : elm.ragione_sociale_liquidazione ?? this.takeNomiDanneggiati[i]}</h5>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                            <ButtonComponent id={`generaCodiceFiscale${idSin}_${i}`} title='Genera Codice Fiscale' cssClass='e-fill e-info' type='button' onClick={this.onClickBtnGeneraCodiceFiscale.bind(this, i)} disabled={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? false : true}>GENERA CODICE FISCALE</ButtonComponent>
                            <ButtonComponent id={`estrapolaCodiceFiscale${idSin}_${i}`} title='Ottieni Dati' cssClass='e-fill e-info' style={{ marginTop: '5px' }} type='button' onClick={this.estrapolaDatiCodiceFiscale.bind(this, i, false)} disabled={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? false : true}>OTTIENI DATI DAL CODICE FISCALE</ButtonComponent>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <DropDownListComponent id={`tipoLiquidazione${idSin}_${i}`} fields={this.ddlFields} dataSource={this.dsTipoLiquidazione} value={elm.tipoLiquidazione_codice?.trim()}
                                allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeTipoLiquidazione.bind(this, i)}
                                placeholder="Tipo Liquidazione" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <DropDownListComponent id={`tipoBeneficiario${idSin}_${i}`} fields={this.ddlFields} dataSource={this.dsTipoBeneficiario} value={elm.tipoBeneficiario_codice?.trim()}
                                allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeTipoBeneficiario.bind(this, i)}
                                placeholder="Tipo Beneficiario" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <DropDownListComponent id={`tipoPagamento${idSin}_${i}`} fields={this.ddlFields} dataSource={this.dsTipoPagamento} value={elm.modalitaPagamento_codice?.trim()}
                                allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeTipoPagamento.bind(this, i, false)}
                                placeholder="Tipo Pagamento" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <DatePickerComponent firstDayOfWeek={1} id={`dataFirmaAtto2${idSin}_${i}`} cssClass='e-outline' format='dd/MM/yyyy' value={elm.data_accertamento_conservativo}
                                showClearButton={true} placeholder='Data Firma Atto' floatLabelType='Always' change={this.onChangeDataFirmaAtto2.bind(this)}></DatePickerComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <TextBoxComponent id={`ragioneSocialeAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='Ragione Sociale' cssClass='e-outline' value={elm.ragione_sociale_liquidazione} blur={this.onChangeRagioneSocialeAssicurato.bind(this, i)}></TextBoxComponent>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <TextBoxComponent id={`nomeAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='Nome' cssClass='e-outline' value={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? elm.nome_liquidazione : null} disabled={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? false : true} blur={this.onChangeNomeAssicurato.bind(this, i)}></TextBoxComponent>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <TextBoxComponent id={`cognomeAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='Cognome' cssClass='e-outline' value={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? elm.cognome_liquidazione : null} disabled={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? false : true} blur={this.onChangeCognomeAssicurato.bind(this, i)}></TextBoxComponent>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <TextBoxComponent id={`codiceFiscaleAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='Codice Fiscale/P.Iva' cssClass='e-outline' value={elm.codiceFiscale_partitaIVA} blur={this.onChangeCodiceFiscaleAssicurato.bind(this, i)}></TextBoxComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">
                            <DropDownListComponent id={`sessoAssicurato${idSin}_${i}`} fields={this.ddlFields} dataSource={this.dsSessoAssicurato} value={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? elm.sesso_liquidazione : null}
                                allowFiltering={true} filterType='Contains' showClearButton={false} enabled={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? true : false} 
                                placeholder="Sesso" floatLabelType='always' popupHeight="250px" cssClass='e-outline' onChange={this.onChangeSessoAssicurato.bind(this, i)}>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" id='divDataNascita'>
                            <DatePickerComponent firstDayOfWeek={1} id={`dataNascitaAssicurato${idSin}_${i}`} cssClass='e-outline' format='dd/MM/yyyy' value={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? elm.data_nascita_liquidazione : null} disabled={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? false : true}
                                showClearButton={true} placeholder='Data Nascita' floatLabelType='Always' change={this.onChangeDataNascitaAssicurato.bind(this,i)}></DatePickerComponent>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                            <DropDownListComponent id={`luogoNascitaAssicurato${idSin}_${i}`} fields={{ text: 'DESCRIZ', value: 'cod_ele' }} value={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? elm.luogoNascita_codiceBelfiore?.trim() : null} onChange={this.onChangeComuneNascitaAssicurato.bind(this, i, false)} enabled={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? true : false}
                                allowFiltering={true} filterType='Contains' showClearButton={true} dataSource={this.dsComuni}
                                placeholder="Luogo Nascita" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <DropDownListComponent id={`provinciaNascitaAssicurato${idSin}_${i}`} fields={this.ddlFields} dataSource={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaProvince2')))} value={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? elm.luogoNascita_provincia?.trim() : null}
                                allowFiltering={true} filterType='Contains' showClearButton={true} enabled={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? true : false} onChange={this.onChangeProvinciaNascitaAssicurato.bind(this, i)}
                                placeholder="Provincia Nascita" floatLabelType='always' popupHeight="350px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <DropDownListComponent id={`nazioneNascitaAssicurato${idSin}_${i}`} fields={{ text: 'DESCRIZ', value: 'VALUE' }} value={elm.luogoNascita_nazione?.trim()}
                                allowFiltering={true} filterType='Contains' showClearButton={true} dataSource={this.dsNazioni} enabled={CustomJS.isNullEmpty(elm.ragione_sociale_liquidazione) ? true : false}
                                placeholder="Nazione Nascita" floatLabelType='always' popupHeight="250px" cssClass='e-outline' onChange={this.onChangeNazioneNascitaAssicurato.bind(this, i)}>
                            </DropDownListComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <TextBoxComponent id={`viaResidenzaAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='Via' cssClass='e-outline' value={elm.indirizzo_viaPiazza} blur={this.onChangeViaResidenzaAssicurato.bind(this, i)}></TextBoxComponent>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                            <DropDownListComponent id={`comuneResidenzaAssicurato${idSin}_${i}`} fields={{ text: 'DESCRIZ', value: 'cod_ele' }} onChange={this.onChangeComuneResidenzaAssicurato.bind(this, i, false)}
                                allowFiltering={true} filterType='Contains' showClearButton={true} dataSource={this.dsComuni} value={elm.indirizzo_codiceBelfiore?.trim()}
                                placeholder="Comune" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <TextBoxComponent id={`capResidenzaAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='CAP' cssClass='e-outline' value={elm.indirizzo_CAP} blur={this.onChangeCapResidenzaAssicurato.bind(this, i)}></TextBoxComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <DropDownListComponent id={`provinciaResidenzaAssicurato${idSin}_${i}`} fields={this.ddlFields} dataSource={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaProvince2')))} value={elm.indirizzo_provincia?.trim()}
                                allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeProvinciaResidenzaAssicurato.bind(this, i)}
                                placeholder="Provincia" floatLabelType='always' popupHeight="350px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <DropDownListComponent id={`nazioneResidenzaAssicurato${idSin}_${i}`} fields={{ text: 'DESCRIZ', value: 'VALUE' }} value={elm.indirizzo_nazione?.trim()}
                                allowFiltering={true} filterType='Contains' showClearButton={true} dataSource={this.dsNazioni} onChange={this.onChangeNazioneResidenzaAssicurato.bind(this, i)}
                                placeholder="Nazione" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" id={`divNumeroAssegno${idSin}_${i}`} style={{ display: divNumeroAssegnoStyle }}>
                            <TextBoxComponent id={`numeroAssegnoAssicuratoi${idSin}_${i}`} floatLabelType='Auto' placeholder='N° Assegno' cssClass='e-outline' value={elm.numero_assegno} blur={this.onChangeNumeroAssegnoAssicurato.bind(this, i)}></TextBoxComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" id={`divDataAssegno${idSin}_${i}`} style={{ display: divDataAssegnoStyle }}>
                            <DatePickerComponent firstDayOfWeek={1} id={`dataEmissioneAssicurato${idSin}_${i}`} cssClass='e-outline' format='dd/MM/yyyy' value={elm.data_emissione}
                                showClearButton={true} placeholder='Data Emissione' floatLabelType='Always' change={this.onChangeDataEmissioneAssicurato.bind(this, i)}></DatePickerComponent>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" id={`divIban${idSin}_${i}`} style={{ display: divIbanStyle }}>
                            <TextBoxComponent id={`ibanAssicurato${idSin}_${i}`} floatLabelType='Auto' placeholder='IBAN' cssClass='e-outline' value={elm.IBAN_liquidazione} blur={this.onChangeIbanAssicurato.bind(this, i)}></TextBoxComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <NumericTextBoxComponent id={`importoRichiestoAssicurato${idSin}_${i}`} format="c2" decimals='2' floatLabelType='Auto' placeholder='Importo Richiesto' showSpinButton={false} cssClass='e-outline' value={elm.importo_richiesto} change={this.onChangeImportoRichiestoAssicurato.bind(this, i)}></NumericTextBoxComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <NumericTextBoxComponent id={`importoLiquidazioneAssicurato${idSin}_${i}`} format="c2" decimals='2' floatLabelType='Auto' placeholder='Importo Liquidazione' cssClass='e-outline' value={valueImportoLiquidazione} showSpinButton={false} 
                                disabled={(CustomJS.isNullEmpty(elm.finali_indenn_netti_arrotond) || elm.finali_indenn_netti_arrotond.toString() === '0') ? false : true} change={this.onChangeImportoLiquidazioneAssicurato.bind(this, i)}></NumericTextBoxComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" style={{ marginBottom: '15px' }}>
                            <h6>Note Difformità Importo</h6>
                            <RichTextEditorComponent id={`noteDifformitaImporto${idSin}_${i}`} showCharCount={true} enableHtmlEncode={true} placeholder='Note Difformità Importo Richiesto' value={elm.note_difformita_richiesto_calcolato}
                                maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' change={this.onChangeNoteDifformitaRichiestoCalcolato.bind(this, i)}>
                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table]} />
                            </RichTextEditorComponent>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" style={{ marginBottom: '15px' }}>
                            <h6>Altre Note</h6>
                            <RichTextEditorComponent id={`altreNote${idSin}_${i}`} showCharCount={true} enableHtmlEncode={true} placeholder='Altre Note' value={elm.note_liquidazione}
                                maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' change={this.onChangeNoteLiquidazione.bind(this, i)}>
                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table]} />
                            </RichTextEditorComponent>
                        </div>
                    </div>
                </div></CallbackWrapper>);
                let arrayLiquidazioni = JSON.parse(sessionStorage.getItem('JellyfishTechnologyStrutturaDanneggiati'));
                arrayLiquidazioni.forEach(dann => {
                    if (dann.guidDanneggiato === elm.guidDanneggiato && (dann.stato === 'n' || dann.stato === 'o'))
                        dann.liquidazione = 's';
                });
                sessionStorage.setItem('JellyfishTechnologyStrutturaDanneggiati', JSON.stringify(arrayLiquidazioni));
            });
            setTimeout(() => {
                this.toggleBlocking2(false);
            }, 500)
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    resetLiquidazioni(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.cancellaLiquidazioni();
        var divs = document.querySelectorAll(`[id^='divLiquidazione${idSin}']`);
        for (let i = 0; i < divs.length; i++) {
            divs[i].remove();
        }
        document.getElementById(`divBtnVediLiquidazioni${idSin}`).style.display = 'none';
        //document.getElementById(`divBtnResetLiquidazioni${idSin}`).style.display = 'none';
        document.getElementById(`divBtnAttoUnico${idSin}`).style.display = 'block';
        document.getElementById(`divBtnAttiSeparati${idSin}`).style.display = 'block';
        document.getElementById(`esito${idSin}`).ej2_instances[0].value = null;
        this.onChangeEsitoPeriziaGenerali({ value: null });
    }
    estrapolaDatiCodiceFiscale(indice, alternUpd, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT cod_ele, descriz, servizio_str1 FROM [09011] WHERE cod_tab = 'codici_comuni';`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            let codFis = document.getElementById(`codiceFiscaleAssicurato${idSin}_${indice}`).ej2_instances[0].value;
            document.getElementById(`sessoAssicurato${idSin}_${indice}`).ej2_instances[0].value = CustomJS.isNullEmpty(codFis) ? null : codFis.substring(9, 11) > 40 ? "F" : "M";
            let [anno, giorno] = [codFis.substring(6, 8), codFis.substring(9, 11)];
            if (giorno > 40) {
                giorno -= 40;
                giorno = (giorno < 10) ? "0" + giorno : giorno;
            }
            document.getElementById(`dataNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = CustomJS.isNullEmpty(codFis) ? '' : moment((anno < 20 ? "20" : "19") + anno + "-" + MESI[codFis.charAt(8).toUpperCase()] + "-" + giorno).format('DD/MM/YYYY');
            let comune = retData.find(({ cod_ele }) => cod_ele === codFis.substring(11, 15).toUpperCase());
            if (codFis.charAt(11).toUpperCase() === 'Z') {
                document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
                document.getElementById(`provinciaNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = null;
                document.getElementById(`nazioneNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = comune.cod_ele;
            }
            else {
                document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = CustomJS.isNullEmpty(codFis) ? null : comune.cod_ele;
                document.getElementById(`provinciaNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = CustomJS.isNullEmpty(codFis) ? null : comune.servizio_str1;
                document.getElementById(`nazioneNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value = document.getElementById(`nazioneNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].dataSource.find(({ cod_ele }) => cod_ele === 'ITA').cod_ele;
            }
            if (alternUpd) {
                setTimeout(() => {
                    this.aggiornaDettaglio('sesso_liquidazione', document.getElementById(`sessoAssicurato${idSin}_${indice}`).ej2_instances[0].text, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                    this.aggiornaDettaglio('luogoNascita_denominazione', document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].text, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                    this.aggiornaDettaglio('luogoNascita_codiceBelfiore', document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                    this.aggiornaDettaglio('luogoNascita_provincia', document.getElementById(`provinciaNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                    this.aggiornaDettaglio('luogoNascita_nazione', document.getElementById(`nazioneNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', true, null, '[12012]', document.getElementById(`guidDanneggiato${idSin}_${indice}`).value);
                }, 150);
            }
            else {
                this.aggiornaDettaglio('sesso_liquidazione', document.getElementById(`sessoAssicurato${idSin}_${indice}`).ej2_instances[0].text, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('luogoNascita_denominazione', document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].text, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('luogoNascita_codiceBelfiore', document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('luogoNascita_provincia', document.getElementById(`provinciaNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
                this.aggiornaDettaglio('luogoNascita_nazione', document.getElementById(`nazioneNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value, 'CHAR', '[12012]', null, document.getElementById(`idLiquidazione${idSin}_${indice}`).value);
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    estrapolaDatiCodiceFiscaleUnipol() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT cod_ele, descriz, servizio_str1 FROM [09011] WHERE cod_tab = 'codici_comuni';`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            let codFis = document.getElementById(`codiceFiscaleBeneficiarioUnipol${idSin}`).ej2_instances[0].value;
            document.getElementById(`sessoBeneficiarioUnipol${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(codFis) ? null : codFis.substring(9, 11) > 40 ? "F" : "M";
            let [anno, giorno] = [codFis.substring(6, 8), codFis.substring(9, 11)];
            if (giorno > 40) {
                giorno -= 40;
                giorno = (giorno < 10) ? "0" + giorno : giorno;
            }
            document.getElementById(`dataNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(codFis) ? '' : moment((anno < 20 ? "20" : "19") + anno + "-" + MESI[codFis.charAt(8).toUpperCase()] + "-" + giorno).format('DD/MM/YYYY');
            let comune = retData.find(({ cod_ele }) => cod_ele === codFis.substring(11, 15).toUpperCase());
            if (codFis.charAt(11).toUpperCase() === 'Z') {
                document.getElementById(`comuneNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value = null;
                document.getElementById(`provinciaNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value = null;
                document.getElementById(`nazioneNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value = comune.cod_ele;
            }
            else {
                document.getElementById(`comuneNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(codFis) ? null : comune.cod_ele;
                document.getElementById(`provinciaNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(codFis) ? null : comune.servizio_str1;
                document.getElementById(`nazioneNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value = document.getElementById(`nazioneNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].dataSource.find(({ cod_ele }) => cod_ele === 'ITA').cod_ele;
            }
            this.aggiornaDettaglio('sessoBeneficiario', document.getElementById(`sessoBeneficiarioUnipol${idSin}`).ej2_instances[0].value, 'CHAR', '[10006]', true);
            this.aggiornaDettaglio('dataNascitaBeneficiario', moment(document.getElementById(`dataNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value,'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE', '[10006]', true);
            this.aggiornaDettaglio('codiceNazioneNascitaBeneficiario', document.getElementById(`nazioneNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value, 'CHAR', '[10006]', true);
            this.aggiornaDettaglio('siglaProvinciaNascitaBeneficiario', document.getElementById(`provinciaNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value, 'CHAR', '[10006]', true);
            this.aggiornaDettaglio('codiceComuneNascitaBeneficiario', document.getElementById(`comuneNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value, 'CHAR', '[10006]', true);
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    onClickBtnGeneraCodiceFiscale(indice, args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        var nome = document.getElementById(`nomeAssicurato${idSin}_${indice}`).ej2_instances[0].value;
        var cognome = document.getElementById(`cognomeAssicurato${idSin}_${indice}`).ej2_instances[0].value;
        var localita = document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value;
        var dataDiNascita = document.getElementById(`dataNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].value;
        var sesso = document.getElementById(`sessoAssicurato${idSin}_${indice}`).ej2_instances[0].value;
        if (CustomJS.isNullEmpty(nome) || CustomJS.isNullEmpty(cognome) || CustomJS.isNullEmpty(localita) || CustomJS.isNullEmpty(dataDiNascita) || CustomJS.isNullEmpty(sesso)) {
            return;
        }
        else {
            var data = new Date(dataDiNascita);
            var anno = data.getFullYear().toString().substr(-2);
            var cod_mese = ['A', 'B', 'C', 'D', 'G', 'H', 'L', 'M', 'P', 'R', 'S', 'T'];
            var mese = data.getMonth() + 1;
            var giorno = data.getDate() <= 10 ? '0' + data.getDate() : data.getDate();
            var consonanti_nome = [];
            var vocali_nome = [];
            var consonanti_cognome = [];
            var vocali_cognome = [];
            var consonanti;
            var vocali;
            var consonanti_prese_nome;
            var consonanti_prese_cognome;
            var n_cons = 0;
            if (nome.length < 3) {
                nome += 'x';
            }
            else {
                for (var i = 0; i <= nome.length; i++) {
                    if (nome.charAt(i).match(/[aeiouAEIOU]/)) {
                        vocali_nome[i] = nome.charAt(i).toUpperCase();
                        vocali = vocali_nome.filter(Boolean);
                    }
                    else if (nome.charAt(i).match(/[/',/"]/)) {
                        continue;
                    }
                    else {
                        consonanti_nome[i] = nome.charAt(i).toUpperCase();
                        consonanti = consonanti_nome.filter(Boolean);
                        if (consonanti_nome[i] !== "") { n_cons++; }
                    }

                }
            }
            //CALCOLO CONSONANTI
            if (consonanti.length >= 4) {
                //prendo le consonanti a posizione 0,2,3
                consonanti_prese_nome = [consonanti[0], consonanti[2], consonanti[3]];
            }
            else if (consonanti.length === 1) {
                consonanti_prese_nome = [consonanti[0], vocali[0], vocali[1]];
            }
            else if (consonanti.length < 3) {
                //prendo le consonanti a posizione 0,1 e la prima vocale
                consonanti_prese_nome = [consonanti[0], consonanti[1], vocali[0]];
            }
            else if (consonanti.length === 3) {
                //prendo le consonanti a posizione 0,1,2
                consonanti_prese_nome = [consonanti[0], consonanti[1], consonanti[2]];
            }
            // COGNOME                         
            if (cognome.length < 3) {
                cognome += 'x';
            }
            else {
                // DISTINZIONE TRA VOCALI E CONSONANTI
                for (var i = 0; i <= cognome.length; i++) {
                    if (cognome.charAt(i).match(/[aeiouAEIOU]/)) {
                        vocali_cognome[i] = cognome.charAt(i).toUpperCase();
                        vocali = vocali_cognome.filter(Boolean);

                    } else if (cognome.charAt(i).match(/[/',/"]/)) {
                        continue;
                    }
                    else {
                        consonanti_cognome[i] = cognome.charAt(i).toUpperCase();
                        //FILTRO PER GLI SPAZI ,,,, PULENDO L'ARRAY
                        consonanti = consonanti_cognome.filter(Boolean);
                        //#CONTA CONSONANTI
                        if (consonanti_cognome[i] !== "") { n_cons++; }
                    }
                }

            }
            //SE LE CONSONANTI SONO MINORI DI 3
            if (consonanti.length < 3) {
                //prendo le consonanti a posizione 0,1 e la prima vocale
                consonanti_prese_cognome = [consonanti[0], consonanti[1], vocali[0]];
            }

            else {
                //prendo le consonanti a posizione 0,1,2
                consonanti_prese_cognome = [consonanti[0], consonanti[1], consonanti[2]];
            }
            if (consonanti.length === 1) {
                consonanti_prese_cognome = [consonanti[0], vocali[0], vocali[1]];
            }
            switch (mese.toString()) {
                case '1':
                    mese = cod_mese[0];
                    break;
                case '2':
                    mese = cod_mese[1];
                    break;
                case '3':
                    mese = cod_mese[2];
                    break;
                case '4':
                    mese = cod_mese[3];
                    break;
                case '5':
                    mese = cod_mese[4];
                    break;
                case '6':
                    mese = cod_mese[5];
                    break;
                case '7':
                    mese = cod_mese[6];
                    break;
                case '8':
                    mese = cod_mese[7];
                    break;
                case '9':
                    mese = cod_mese[8];
                    break;
                case '10':
                    mese = cod_mese[9];
                    break;
                case '11':
                    mese = cod_mese[10];
                    break;
                case '12':
                    mese = cod_mese[11];
                    break;
            }
            switch (giorno) {
                case '01': case '02': case '03': case '04': case '05':
                case '06': case '07': case '08': case '09': case '10':
                case '11': case '12': case '13': case '14': case '15':
                case '16': case '17': case '18': case '19': case '20':
                case '21': case '22': case '23': case '24': case '25':
                case '26': case '27': case '28': case '29': case '30': case '31':
                    if (sesso === 'F') {
                        giorno += 40;
                    }
                    else if (sesso === 'M') {
                        giorno = giorno;
                    }
                    break;
            }
            var localita_presa;
            var provincia = document.getElementById(`luogoNascitaAssicurato${idSin}_${indice}`).ej2_instances[0].dataSource;
            for (var i = 0; i <= provincia.length - 1; i++) {
                if (localita.toUpperCase() === provincia[i].cod_ele) {
                    localita_presa = provincia[i].cod_ele;
                    switch (localita_presa) {
                        case localita_presa:
                            localita_presa = provincia[i].cod_ele;
                            break;
                    }
                }
            }
            var CF = consonanti_prese_cognome + consonanti_prese_nome + anno + mese + giorno + localita_presa;
            var CF_FX = GetCF(CF);
            var alpha_dispari = [];
            var alpha_pari = [];
            var somma_dispari = 0;
            var somma_pari = 0;
            var somma_controllo = 0;
            //#CARATTERE FINALE
            var lettera_controllo;
            for (var i = 0; i < CF_FX.length; i++) {
                if (i % 2 !== 1) {
                    alpha_dispari[i] = CF_FX.charAt(i);
                    switch (alpha_dispari[i]) {
                        case '0': case 'A': alpha_dispari[i] = 1;
                            break;
                        case '1': case 'B': alpha_dispari[i] = 0;
                            break;
                        case '2': case 'C': alpha_dispari[i] = 5;
                            break;
                        case '3': case 'D': alpha_dispari[i] = 7;
                            break;
                        case '4': case 'E': alpha_dispari[i] = 9;
                            break;
                        case '5': case 'F': alpha_dispari[i] = 13;
                            break;
                        case '6': case 'G': alpha_dispari[i] = 15;
                            break;
                        case '7': case 'H': alpha_dispari[i] = 17;
                            break;
                        case '8': case 'I': alpha_dispari[i] = 19;
                            break;
                        case '9': case 'J': alpha_dispari[i] = 21;
                            break;
                        case 'K': alpha_dispari[i] = 2;
                            break;
                        case 'L': alpha_dispari[i] = 4;
                            break;
                        case 'M': alpha_dispari[i] = 18;
                            break;
                        case 'N': alpha_dispari[i] = 20;
                            break;
                        case 'O': alpha_dispari[i] = 11;
                            break;
                        case 'P': alpha_dispari[i] = 3;
                            break;
                        case 'Q': alpha_dispari[i] = 6;
                            break;
                        case 'R': alpha_dispari[i] = 8;
                            break;
                        case 'S': alpha_dispari[i] = 12;
                            break;
                        case 'T': alpha_dispari[i] = 14;
                            break;
                        case 'U': alpha_dispari[i] = 16;
                            break;
                        case 'V': alpha_dispari[i] = 10;
                            break;
                        case 'W': alpha_dispari[i] = 22;
                            break;
                        case 'X': alpha_dispari[i] = 25;
                            break;
                        case 'Y': alpha_dispari[i] = 24;
                            break;
                        case 'Z': alpha_dispari[i] = 23;
                            break;
                    }
                    //SOMMA DEI CARATTERI IN POSIZIONE DISPARI
                    somma_dispari += alpha_dispari[i];
                }
                else {
                    alpha_pari[i] = CF_FX.charAt(i);
                    switch (alpha_pari[i]) {
                        case '0': case 'A': alpha_pari[i] = 0;
                            break;
                        case '1': case 'B': alpha_pari[i] = 1;
                            break;
                        case '2': case 'C': alpha_pari[i] = 2;
                            break;
                        case '3': case 'D': alpha_pari[i] = 3;
                            break;
                        case '4': case 'E': alpha_pari[i] = 4;
                            break;
                        case '5': case 'F': alpha_pari[i] = 5;
                            break;
                        case '6': case 'G': alpha_pari[i] = 6;
                            break;
                        case '7': case 'H': alpha_pari[i] = 7;
                            break;
                        case '8': case 'I': alpha_pari[i] = 8;
                            break;
                        case '9': case 'J': alpha_pari[i] = 9;
                            break;
                        case 'K': alpha_pari[i] = 10;
                            break;
                        case 'L': alpha_pari[i] = 11;
                            break;
                        case 'M': alpha_pari[i] = 12;
                            break;
                        case 'N': alpha_pari[i] = 13;
                            break;
                        case 'O': alpha_pari[i] = 14;
                            break;
                        case 'P': alpha_pari[i] = 15;
                            break;
                        case 'Q': alpha_pari[i] = 16;
                            break;
                        case 'R': alpha_pari[i] = 17;
                            break;
                        case 'S': alpha_pari[i] = 18;
                            break;
                        case 'T': alpha_pari[i] = 19;
                            break;
                        case 'U': alpha_pari[i] = 20;
                            break;
                        case 'V': alpha_pari[i] = 21;
                            break;
                        case 'W': alpha_pari[i] = 22;
                            break;
                        case 'X': alpha_pari[i] = 23;
                            break;
                        case 'Y': alpha_pari[i] = 24;
                            break;
                        case 'Z': alpha_pari[i] = 25;
                            break;
                    }
                    somma_pari += alpha_pari[i];
                }
            }
            //#SOMMA FINALE
            somma_controllo = (somma_dispari + somma_pari) % 26;
            //#ARRAY DELLE LETTERE CHE SI ASSOCIERANNO AL RISULTATO DELLA SOMMA CONTROLLO
            var caratteri_lista = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
            for (var i = 0; i <= 26; i++) {
                switch (somma_controllo) {
                    case i: lettera_controllo = caratteri_lista[i];
                        break;
                }
            }
            document.getElementById(`codiceFiscaleAssicurato${idSin}_${indice}`).ej2_instances[0].value = CF_FX + lettera_controllo;
            function GetCF(Cod) {
                let CF_MOD;
                let CF_MOD_ORA = '';
                for (var i = 0; i <= CF.length; ++i) {
                    CF_MOD = Cod.charAt(i);
                    if (CF_MOD === ',') {
                        continue;
                    }
                    else {
                        CF_MOD_ORA += Cod.charAt(i);
                    }
                }
                return CF_MOD_ORA;
            }
        }
    }
    cancellaLiquidazioni() {
        JSON.parse(sessionStorage.getItem('JellyfishTechnologyStrutturaDanneggiati')).forEach(elm => {
            const QueryExec = `DELETE FROM [12012] WHERE id_danneggiato = '${elm.guidDanneggiato}'`;
            let jsonObject = {
            }
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(jsonObject), retData => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        });
    }
    ddlFiltroSinistriToolbar() {
        return (
            <DropDownListComponent id='ddlFiltroSinistri' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance1 = ddlObj } dataSource={this.dsFiltroSinistri} change={this.onChangeFiltroSinistri.bind(this)} allowFiltering={false} filterType='Contains'
                style={{width: '10vw'}} placeholder="Selezionare i sinistri da visualizzare" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline' />
       );           
    }
    btnOrdinaPerRitardo() {
        return (
            <SplitButtonComponent id='btnOrdinaPerRitardo' items={this.btnItems3} content='ORDINA PER' cssClass='e-fill e-normal' style={{ width: '100px', height:'50px', marginTop: '-10px' }} select={this.onSelectSplitBtn4.bind(this)}></SplitButtonComponent> 
       );           
    }
    ddlFiltroStatiToolbar() {
        return (
            <DropDownListComponent id='ddlFiltroStati' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance10 = ddlObj } dataSource={this.dsStatoFiltro} change={this.onChangeFiltroStati.bind(this)} allowFiltering={true} filterType='Contains'
                style={{width: '15vw'}} placeholder="Selezionare lo stato da visualizzare" floatLabelType='Auto' popupHeight="500px" cssClass='e-outline' />
       );           
    }
    ddlFiltroPeritiToolbar() {
        return (<div id='divDDL3'>
                    <DropDownListComponent id='ddlFiltroPeriti' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance11 = ddlObj } dataSource={this.dsPeritoFiltro} change={this.onChangeFiltroPeriti.bind(this)} allowFiltering={true} filterType='Contains'
                        style={{width: '15vw'}} placeholder="Selezionare il perito da visualizzare" floatLabelType='Auto' popupHeight="500px" cssClass='e-outline' />
                </div>
       );           
    }
    onCreatedTabSinistri(args) {
        const container = document.getElementById('contenutoSituazioneSinistri');
        const root = createRoot(container);                         
        root.render(this.situazioneSinistriRender());
    }
    onActionBegin(args) {
        if (args.type === 'actionBegin' && args.requestType === 'searching') {
            if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti Semplici') === -1
                && JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Referenti ACE') === -1) {
                let oggettoFiltri = JSON.parse(sessionStorage.getItem('JellyfishTechnologyFiltriAggiuntiviSinistri'));
                this.caricaDati(oggettoFiltri, null, args.searchString.trim());
            }
        }
    }
    actionCompleteCAT(args) {
        if ((args.requestType === 'beginEdit')) { 
            args.dialog.width = '60%';
            args.dialog.height = '75%';
            args.dialog.header = `Dettaglio di ${args.rowData.ragione_sociale}`;
        }
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") { 
            const dataObj2 = {
                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,            
                IdCollaboratore : CustomJS.isNullEmpty(args.data[0]) ? args.data.id_collaboratore : args.data[0].id_collaboratore,
                IdCat: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_cat : args.data[0].id_cat,
                Telefono1 : CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono1 : args.data[0].telefono1,
                Telefono2 : CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono2 : args.data[0].telefono2,
                Email1 : CustomJS.isNullEmpty(args.data[0]) ? args.data.email1 : args.data[0].email1,
                Email2 : CustomJS.isNullEmpty(args.data[0]) ? args.data.email2 : args.data[0].email2,
                TAssegnato : CustomJS.isNullEmpty(args.data[0]) ? CustomJS.isNullEmpty(args.data.t_assegnato) ? null : moment(args.data.t_assegnato, 'DD/MM/YYYY').format('YYYY-MM-DD') : CustomJS.isNullEmpty(args.data[0].t_assegnato) ? null : moment(args.data[0].t_assegnato, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                TRiconsegnato : CustomJS.isNullEmpty(args.data[0]) ? CustomJS.isNullEmpty(args.data.t_riconsegnato) ? null : moment(args.data.t_riconsegnato, 'DD/MM/YYYY').format('YYYY-MM-DD') : CustomJS.isNullEmpty(args.data[0].t_riconsegnato) ? null : moment(args.data[0].t_riconsegnato, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                NoteCat : CustomJS.isNullEmpty(args.data[0]) ? args.data.note_cat : args.data[0].note_cat,
                Chiusa : CustomJS.isNullEmpty(args.data[0]) ? args.data.chiusa : args.data[0].chiusa,
                Revocata : CustomJS.isNullEmpty(args.data[0]) ? args.data.revocata : args.data[0].revocata,
            };
            const QueryExec2 = "BEGIN TRAN; UPDATE [00006] SET telefono1 = @telefono1, telefono2 = @telefono2, email1 = @email1, email2 = @email2 WHERE id_collaboratore = @id_collaboratore; UPDATE [01004] SET t_assegnato = @t_assegnato, t_riconsegnato = @t_riconsegnato, note_cat = @note_cat, chiusa = @chiusa, revocata = @revocata WHERE id_cat = @id_cat; COMMIT;";   
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec2, JSON.stringify(dataObj2), retData2 => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);
        }
    }
    actionCompleteRFD(args) {
        if ((args.requestType === 'beginEdit')) { 
            args.dialog.width = '60%';
            args.dialog.height = '75%';
            args.dialog.header = `Dettaglio di ${args.rowData.ragione_sociale}`;
        }
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") { 
            const dataObj2 = {
                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,            
                IdCollaboratore: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_collaboratore : args.data[0].id_collaboratore,
                IdRfd: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_rfd : args.data[0].id_rfd,
                Telefono1 : CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono1 : args.data[0].telefono1,
                Telefono2 : CustomJS.isNullEmpty(args.data[0]) ? args.data.telefono2 : args.data[0].telefono2,
                Email1 : CustomJS.isNullEmpty(args.data[0]) ? args.data.email1 : args.data[0].email1,
                Email2 : CustomJS.isNullEmpty(args.data[0]) ? args.data.email2 : args.data[0].email2,
                TAssegnato: CustomJS.isNullEmpty(args.data[0]) ? CustomJS.isNullEmpty(args.data.t_assegnato) ? null : moment(args.data.t_assegnato, 'DD/MM/YYYY').format('YYYY-MM-DD') : CustomJS.isNullEmpty(args.data[0].t_assegnato) ? null : moment(args.data[0].t_assegnato, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                TRiconsegnato : CustomJS.isNullEmpty(args.data[0]) ? CustomJS.isNullEmpty(args.data.t_riconsegnato) ? null : moment(args.data.t_riconsegnato, 'DD/MM/YYYY').format('YYYY-MM-DD') : CustomJS.isNullEmpty(args.data[0].t_riconsegnato) ? null : moment(args.data[0].t_riconsegnato, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                NoteRfd : CustomJS.isNullEmpty(args.data[0]) ? args.data.note_rfd : args.data[0].note_rfd,
                Chiusa : CustomJS.isNullEmpty(args.data[0]) ? args.data.chiusa : args.data[0].chiusa,
                Revocata : CustomJS.isNullEmpty(args.data[0]) ? args.data.revocata : args.data[0].revocata,
            };
            const QueryExec2 = "BEGIN TRAN; UPDATE [00006] SET telefono1 = @telefono1, telefono2 = @telefono2, email1 = @email1, email2 = @email2 WHERE id_collaboratore = @id_collaboratore; UPDATE [01014] SET t_assegnato = @t_assegnato, t_riconsegnato = @t_riconsegnato, note_rfd = @note_rfd, chiusa = @chiusa, revocata = @revocata WHERE id_rfd = @id_rfd; COMMIT;";   
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec2, JSON.stringify(dataObj2), retData2 => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);
        }
    }     
    actionCompletePeriti(args) {
        if ((args.requestType === 'beginEdit')) { 
            args.dialog.width = '60%';
            args.dialog.height = '75%';
            args.dialog.header = `Dettaglio di ${args.rowData.nomeperito}`;
        }
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") { 
            const dataObj2 = {
                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,            
                IdUtente: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_utente : args.data[0].id_utente,
                IdPerizia: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_perizia : args.data[0].id_perizia,
                TAssegnato : CustomJS.isNullEmpty(args.data[0]) ? CustomJS.isNullEmpty(args.data.t_assegnato) ? null : moment(args.data.t_assegnato, 'DD/MM/YYYY').format('YYYY-MM-DD') : CustomJS.isNullEmpty(args.data[0].t_assegnato) ? null : moment(args.data[0].t_assegnato, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                TRiconsegnato : CustomJS.isNullEmpty(args.data[0]) ? CustomJS.isNullEmpty(args.data.t_riconsegnato) ? null : moment(args.data.t_riconsegnato, 'DD/MM/YYYY').format('YYYY-MM-DD') : CustomJS.isNullEmpty(args.data[0].t_riconsegnato) ? null : moment(args.data[0].t_riconsegnato, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                Chiusa : CustomJS.isNullEmpty(args.data[0]) ? args.data.chiusa : args.data[0].chiusa,
                Revocata : CustomJS.isNullEmpty(args.data[0]) ? args.data.revocata : args.data[0].revocata,
            };
            const QueryExec2 = "BEGIN TRAN; UPDATE [01002] SET t_assegnato = @t_assegnato, t_riconsegnato = @t_riconsegnato, chiusa = @chiusa, revocata = @revocata WHERE id_perizia = @id_perizia; COMMIT;";   
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec2, JSON.stringify(dataObj2), retData2 => {
                // args.data.t_assegnato = moment(args.data.t_assegnato).format('DD/MM/YYYY');
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);
        }
    }   
    onCreatedBtn1() {
        this.element.addEventListener('click', function (args) {
            CustomJS.scrollAuto(500, 0, false, false);
        });
    }
    onCreatedBtn2() {
        this.element.addEventListener('click', function (args) {
            CustomJS.scrollAuto(500, 0, false, true);
        });       
    }
    btnVediNotif(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.emettiNotifiche(document.getElementById(`idSinistro${idSin}`).value, idSin, true, document.getElementById(`gruppo${idSin}`).ej2_instances[0].value, null, null, true);
    }
    onSelectSplitBtn(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (args.item.id === 'VediDocs') {
            let timeout = 0;
            if (this.dialogInstance7.visible === true) {
                this.dialogInstance7.hide();
                timeout = 500;
            }
            setTimeout(() => {
                this.dialogInstance7.header = `<div><span id='dialog-title-${this.dialogInstance7.id}' class='title'>DOCUMENTAZIONE SINISTRO NUM. ${document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value}</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance7.id}' title='Minimizza la finestra'></span></div>`;    
                this.dialogInstance7.width = '60%';
                this.dialogInstance7.height = '90%';
                this.dialogInstance7.minHeight = '90%';
                this.dialogInstance7.buttons = [];    
                this.dialogInstance7.beforeOpen = (args) => {                
                    const container = document.getElementById('defaultDialogContent4');
                    const root = createRoot(container);                         
                    root.unmount();                     
                };
                this.dialogInstance7.open = (args) => {                
                    this.onMinimizeDialogOpen(`DOCUMENTAZIONE SINISTRO NUM. ${document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value}`, '520px', '170', '90%', this.dialogInstance7);
                    setTimeout(() => {
                        const container = document.getElementById('defaultDialogContent4');
                        const root = createRoot(container);                         
                        root.render(this.fileManagerRender());                     
                    }, 300);
                };
                this.dialogInstance7.show();   
            }, timeout);
        }
        if (args.item.id === 'CaricaDocs') {
            this.fotoGEOUnipolSAI = false;
            this.fotoRischioGENERALI = false;
            this.uppy.setOptions({
                restrictions: {
                    allowedFileTypes: null
                }
            });   
            this.uppy.getPlugin('XHRUpload').setOptions({ endpoint: `${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumenti/JFTECH/${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username}/${this.tabInstance.items[this.tabInstance.selectedItem].id}/DOCS` })
            this.setState({
                showUppy: true
            })  
        }
        if (args.item.id === 'ImportaDocsGENERALI') {
            const dataObj = {
                idIncarico: document.getElementById(`idIncarico${idSin}`).value,
                idInternoSinistro: idSin
            };
            this.toggleBlocking2(true);
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/scaricaDocumentazioneGENERALI`, JSON.stringify(dataObj), 'POST',
                'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                    if (retData.response === 'OK') {
                        toast.success(retData.message, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 7000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }
                    else {
                        toast.warn(retData.message, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }
                    this.toggleBlocking2(false);
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }, true, false);   
        }
    }
    onSelectSplitBtn2(args) {
        if (args.item.id === 'ImportaUnipolSAIEmail') {
            CustomJS.getParametroDB(46).then(ret => {
                let arrParam = ret.split(';');
                this.dialogInstance3.header = 'Procedura importazione automatica sinistri UNIPOLSAI';
                this.dialogInstance3.content = `La procedura importa automaticamente i sinistri prendendoli dalle email ricevute da <b>UNIPOLSAI</b> presenti nella casella <b>${arrParam[2]}</b>. Le email considerate sono quelle nello stato <b>DA LEGGERE</b> e con la dicitura <b>${arrParam[5]}</b> nell'oggetto. Il file allegato che viene considerato per l'importazione è quello denominato <b>${arrParam[6]}</b>. Durante l'importazione verrà effettuato un controllo sul <b>Numero Sinistro Compagnia</b> per evitare che un sinistro già inserito possa essere replicato. Alla fine della procedura verrà presentato un resoconto dei sinistri importati. Confermare l'operazione?`;
                this.dialogInstance3.width = '35%';
                this.dialogInstance3.height = '25%';
                this.dialogInstance3.minHeight = '25%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.dialogInstance3.hide();
                        this.toggleBlocking();
                        const dataObj = {
                           ServerIMAP: arrParam[0],
                           PortaIMAP: arrParam[1],
                           UtenteIMAP: arrParam[2],
                           PasswordIMAP: arrParam[3], 
                           SSLIMAP: arrParam[4],
                           Compagnia: 'UNIPOLSAI',
                           StringaRicerca: arrParam[5],
                           NomeAllegato: arrParam[6],   
                           NomeCartellaRicerca: arrParam[7], 
                           SinistroDaCercare: null,
                           IdInternoSinistro: null,
                           Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase()
                        };
                        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/leggiEmailCreaSinistri`, JSON.stringify(dataObj), 'POST',
                                'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                            if (retData.response === 'KO') {
                                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                                toast.error(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),                    
                                    position: "bottom-right",
                                    autoClose: 15000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                                this.toggleBlocking();
                                return;
                            }            
                            this.toggleBlocking();
                            if (retData.length === 0) {
                                toast.warn(`Nessun sinistro da elaborare. Controllare il contenuto della casella email ${arrParam[2]} ed eventualmente riprovare!!`, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),                    
                                    position: "bottom-right",
                                    autoClose: 10000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true});    
                            }
                            else {
                                setTimeout(() => {
                                    document.getElementById('aggiornatabella').click();
                                }); 
                                this.dialogInstance1.header = `ELENCO SINISTRI IMPORTATI DA UNIPOLSAI`;  
                                this.dialogInstance1.width = '65%';
                                this.dialogInstance1.height = '90%';
                                this.dialogInstance1.minHeight = '90%';
                                this.dialogInstance1.beforeClose = undefined;
                                this.dialogInstance1.close = undefined;  
                                this.dialogInstance1.beforeOpen = (args) => {
                                    const container = document.getElementById('defaultDialogContent');
                                    const root = createRoot(container);      
                                    root.unmount();                   
                                }
                                this.dialogInstance1.open = (args) => { 
                                    const container = document.getElementById('defaultDialogContent');
                                    const root = createRoot(container);                         
                                    root.render(<CallbackWrapper callback={() => {
                                        this.gridInstance4.dataSource = retData; 
                                        setTimeout(() => {  
                                            this.gridInstance4.refresh();  
                                        }, 500);     
                                    }}>{this.reportSinistriImportatiRender()}</CallbackWrapper>);  
                                }                          
                                this.dialogInstance1.show();       
                            }
                        }, error => {
                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),                    
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true});
                            this.toggleBlocking();
                        }, true, false);            
                    }, 
                        buttonModel: { 
                            content: 'CONFERMA', 
                            cssClass: 'e-primary',
                            isPrimary: true 
                        }
                    },
                    {
                        click: () => {
                            this.dialogInstance3.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
                this.dialogInstance3.show();      
            }).catch(messaggio => { 
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            });     
        }
        if (args.item.id === 'ImportaUnipolSAIAPI') {
            this.dialogInstance1.header = "<div style='text-align: center;'><span style='font-weight: bold; font-size: 28px; color: #133B95'>Importazione Sinistri UNIPOLSAI</span></div>";
            this.dialogInstance1.height = '90%';
            this.dialogInstance1.minHeight = '90%';
            this.dialogInstance1.width = '65%';
            this.dialogInstance1.buttons = [];
            this.dialogInstance1.beforeClose = undefined;
            this.dialogInstance1.beforeOpen = (args) => {
                const container = document.getElementById('defaultDialogContent');
                const root = createRoot(container);
                root.unmount();
            }
            this.dialogInstance1.open = (args) => {
                const container = document.getElementById('defaultDialogContent');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    this.toggleBlocking2(true);
                    const QueryExec = 'SELECT * FROM [10001]';
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                        if (retData.length > 0) {
                            retData.forEach(elm => {
                                elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                                if (CustomJS.isNullEmpty(elm.id_interno_sinistro))
                                    elm.status = 'Da importare'
                                else
                                    elm.status = 'Importato'
                            });
                            this.gridInstance8.dataSource = retData;
                        }
                        else
                            this.gridInstance8.dataSource = null;
                        this.toggleBlocking2(false);
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        this.toggleBlocking2(false);
                    }, true, true);
                }}>{this.renderImportaUnipolSAI()}</CallbackWrapper>);
            };
            this.dialogInstance1.close = (args) => {
                setTimeout(() => {
                    document.getElementById('aggiornatabella').click();
                }, 100);
            };
            this.dialogInstance1.show();
        }
        if (args.item.id === 'ImportaGeneraliAPI') {
            this.dialogInstance1.header = "<div style='text-align: center;'><span style='font-weight: bold; font-size: 28px; color: #133B95'>Importazione Sinistri GENERALI</span></div>";
            this.dialogInstance1.height = '90%';
            this.dialogInstance1.minHeight = '90%';
            this.dialogInstance1.width = '65%';
            this.dialogInstance1.buttons = [];
            this.dialogInstance1.beforeClose = undefined;
            this.dialogInstance1.beforeOpen = (args) => {
                const container = document.getElementById('defaultDialogContent');
                const root = createRoot(container);
                root.unmount();
            }
            this.dialogInstance1.open = (args) => {
                const container = document.getElementById('defaultDialogContent');
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    this.toggleBlocking2(true);
                    const QueryExec = 'SELECT * FROM [12001]';
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                        if (retData.length > 0) {
                            retData.forEach(elm => {
                                elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                                if (CustomJS.isNullEmpty(elm.id_interno_sinistro))
                                    elm.status = 'Da importare'
                                else
                                    elm.status = 'Importato'
                            });
                            this.gridInstance9.dataSource = retData;                            
                        }
                        else
                            this.gridInstance9.dataSource = null;
                        let startDate = new Date();
                        startDate.setDate(startDate.getDate() - 1);
                        this.drInstance1.startDate = startDate;
                        this.drInstance1.endDate = new Date();    
                        this.toggleBlocking2(false);
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                        this.toggleBlocking2(false);
                    }, true, true);
                }}>{this.renderImportaGenerali()}</CallbackWrapper>);
            };
            this.dialogInstance1.close = (args) => {
                setTimeout(() => {
                    document.getElementById('aggiornatabella').click();
                }, 100);
            };
            this.dialogInstance1.show();
        }
        if (args.item.id === 'ImportaZurich') {
            CustomJS.getParametroDB(47).then(ret => {
                let arrParam = ret.split(';');
                this.dialogInstance3.header = 'Procedura importazione automatica sinistri ZURICH';
                this.dialogInstance3.content = `La procedura importa automaticamente i sinistri prendendoli dalle email ricevute da <b>ZURICH</b> presenti nella casella <b>${arrParam[2]}</b>. Le email considerate sono quelle nello stato <b>DA LEGGERE</b> e con la dicitura <b>${arrParam[5]}</b> nell'oggetto. Il file allegato che viene considerato per l'importazione è quello denominato <b>${arrParam[6]}</b>. Durante l'importazione verrà effettuato un controllo sul <b>Numero Sinistro Compagnia</b> per evitare che un sinistro già inserito possa essere replicato. Alla fine della procedura verrà presentato un resoconto dei sinistri importati. Confermare l'operazione?`;
                this.dialogInstance3.width = '35%';
                this.dialogInstance3.height = '25%';
                this.dialogInstance3.minHeight = '25%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.dialogInstance3.hide();
                        this.toggleBlocking();
                        const dataObj = {
                           ServerIMAP: arrParam[0],
                           PortaIMAP: arrParam[1],
                           UtenteIMAP: arrParam[2],
                           PasswordIMAP: arrParam[3], 
                           SSLIMAP: arrParam[4],
                           Compagnia: 'ZURICH',
                           StringaRicerca: arrParam[5],
                           NomeAllegato: arrParam[6],   
                           NomeCartellaRicerca: arrParam[7], 
                           SinistroDaCercare: null,
                           IdInternoSinistro: null,
                           Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase()
                        };
                        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/leggiEmailCreaSinistri`, JSON.stringify(dataObj), 'POST',
                                'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                            if (retData.response === 'KO') {
                                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                                toast.error(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),                    
                                    position: "bottom-right",
                                    autoClose: 15000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                                this.toggleBlocking();
                                return;
                            }            
                            this.toggleBlocking();
                            if (retData.length === 0) {
                                toast.warn(`Nessun sinistro da elaborare. Controllare il contenuto della casella email ${arrParam[2]} ed eventualmente riprovare!!`, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),                    
                                    position: "bottom-right",
                                    autoClose: 10000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true});    
                            }
                            else {
                                setTimeout(() => {
                                    document.getElementById('aggiornatabella').click();
                                }); 
                                this.dialogInstance1.header = `ELENCO SINISTRI IMPORTATI DA ZURICH`;  
                                this.dialogInstance1.width = '65%';
                                this.dialogInstance1.height = '90%';
                                this.dialogInstance1.minHeight = '90%';
                                this.dialogInstance1.beforeClose = undefined;
                                this.dialogInstance1.close = undefined;  
                                this.dialogInstance1.beforeOpen = (args) => {
                                    const container = document.getElementById('defaultDialogContent');
                                    const root = createRoot(container);      
                                    root.unmount();                   
                                }
                                this.dialogInstance1.open = (args) => { 
                                    const container = document.getElementById('defaultDialogContent');
                                    const root = createRoot(container);                         
                                    root.render(<CallbackWrapper callback={() => {
                                        this.gridInstance4.dataSource = retData; 
                                        setTimeout(() => {  
                                            this.gridInstance4.refresh();  
                                        }, 500);     
                                    }}>{this.reportSinistriImportatiRender()}</CallbackWrapper>);  
                                }                          
                                this.dialogInstance1.show();       
                            }
                        }, error => {
                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),                    
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true});
                            this.toggleBlocking();
                        }, true, false);            
                    }, 
                        buttonModel: { 
                            content: 'CONFERMA', 
                            cssClass: 'e-primary',
                            isPrimary: true 
                        }
                    },
                    {
                        click: () => {
                            this.dialogInstance3.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
                this.dialogInstance3.show();      
            }).catch(messaggio => { 
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            });     
        }
        if (args.item.id === 'ImportaAssimoco') {
            CustomJS.getParametroDB(50).then(ret => {
                let arrParam = ret.split(';');
                this.dialogInstance3.header = 'Procedura importazione automatica sinistri ASSIMOCO';
                this.dialogInstance3.content = `La procedura importa automaticamente i sinistri prendendoli dalle email ricevute da <b>ASSIMOCO</b> presenti nella casella <b>${arrParam[2]}</b>. Le email considerate sono quelle nello stato <b>DA LEGGERE</b> e con la dicitura <b>${arrParam[5]}</b> nell'oggetto. Il file allegato che viene considerato per l'importazione è quello denominato <b>${arrParam[6]}</b>. Durante l'importazione verrà effettuato un controllo sul <b>Numero Sinistro Compagnia</b> per evitare che un sinistro già inserito possa essere replicato. Alla fine della procedura verrà presentato un resoconto dei sinistri importati. Confermare l'operazione?`;
                this.dialogInstance3.width = '35%';
                this.dialogInstance3.height = '25%';
                this.dialogInstance3.minHeight = '25%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.dialogInstance3.hide();
                        this.toggleBlocking2(true);
                        const dataObj = {
                           ServerIMAP: arrParam[0],
                           PortaIMAP: arrParam[1],
                           UtenteIMAP: arrParam[2],
                           PasswordIMAP: arrParam[3], 
                           SSLIMAP: arrParam[4],
                           Compagnia: 'ASSIMOCO',
                           StringaRicerca: arrParam[5],
                           NomeAllegato: arrParam[6],   
                           NomeCartellaRicerca: arrParam[7], 
                           SinistroDaCercare: null,
                           IdInternoSinistro: null,
                           Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase()
                        };
                        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/leggiEmailCreaSinistri`, JSON.stringify(dataObj), 'POST',
                                'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                            if (retData.response === 'KO') {
                                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                                toast.error(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),                    
                                    position: "bottom-right",
                                    autoClose: 15000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                                this.toggleBlocking2(false);
                                return;
                            }            
                            this.toggleBlocking2(false);
                            if (retData.length === 0) {
                                toast.warn(`Nessun sinistro da elaborare. Controllare il contenuto della casella email ${arrParam[2]} ed eventualmente riprovare!!`, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),                    
                                    position: "bottom-right",
                                    autoClose: 10000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true});    
                            }
                            else {
                                setTimeout(() => {
                                    document.getElementById('aggiornatabella').click();
                                }); 
                                this.dialogInstance1.header = `ELENCO SINISTRI IMPORTATI DA ASSIMOCO`;  
                                this.dialogInstance1.width = '65%';
                                this.dialogInstance1.height = '90%';
                                this.dialogInstance1.minHeight = '90%';
                                this.dialogInstance1.beforeClose = undefined;
                                this.dialogInstance1.close = undefined;  
                                this.dialogInstance1.beforeOpen = (args) => {
                                    const container = document.getElementById('defaultDialogContent');
                                    const root = createRoot(container);      
                                    root.unmount();                   
                                }
                                this.dialogInstance1.open = (args) => { 
                                    const container = document.getElementById('defaultDialogContent');
                                    const root = createRoot(container);                         
                                    root.render(<CallbackWrapper callback={() => {
                                        this.gridInstance4.dataSource = retData; 
                                        setTimeout(() => {  
                                            this.gridInstance4.refresh();  
                                        }, 500);     
                                    }}>{this.reportSinistriImportatiRender()}</CallbackWrapper>);  
                                }                          
                                this.dialogInstance1.show();       
                            }
                        }, error => {
                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),                    
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true});
                            this.toggleBlocking2(false);
                        }, true, false);            
                    }, 
                        buttonModel: { 
                            content: 'CONFERMA', 
                            cssClass: 'e-primary',
                            isPrimary: true 
                        }
                    },
                    {
                        click: () => {
                            this.dialogInstance3.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
                this.dialogInstance3.show();      
            }).catch(messaggio => { 
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            });     
        }
        if (args.item.id === 'ImportaGeneraliCattolica') {
            CustomJS.getParametroDB(51).then(ret => {
                let arrParam = ret.split(';');
                this.dialogInstance3.header = 'Procedura importazione automatica sinistri GENERALI/CATTOLICA';
                this.dialogInstance3.content = `La procedura importa automaticamente i sinistri prendendoli dalle email ricevute da <b>GENERALI/CATTOLICA</b> presenti nella casella <b>${arrParam[2]}</b>. Le email considerate sono quelle nello stato <b>DA LEGGERE</b> e con la dicitura <b>${arrParam[5]}</b> nell'oggetto. Il file allegato che viene considerato per l'importazione è quello denominato <b>${arrParam[6]}</b>. Durante l'importazione verrà effettuato un controllo sul <b>Numero Sinistro Compagnia</b> per evitare che un sinistro già inserito possa essere replicato. Alla fine della procedura verrà presentato un resoconto dei sinistri importati. Confermare l'operazione?`;
                this.dialogInstance3.width = '38%';
                this.dialogInstance3.height = '25%';
                this.dialogInstance3.minHeight = '25%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.dialogInstance3.hide();
                        this.toggleBlocking2(true);
                        const dataObj = {
                           ServerIMAP: arrParam[0],
                           PortaIMAP: arrParam[1],
                           UtenteIMAP: arrParam[2],
                           PasswordIMAP: arrParam[3], 
                           SSLIMAP: arrParam[4],
                           Compagnia: 'GENERALI/CATTOLICA',
                           StringaRicerca: arrParam[5],
                           NomeAllegato: arrParam[6],   
                           NomeCartellaRicerca: arrParam[7], 
                           SinistroDaCercare: null,
                           IdInternoSinistro: null,
                           Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase()
                        };
                        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/leggiEmailCreaSinistri`, JSON.stringify(dataObj), 'POST',
                                'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                            if (retData.response === 'KO') {
                                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                                toast.error(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),                    
                                    position: "bottom-right",
                                    autoClose: 15000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                                this.toggleBlocking2(false);
                                return;
                            }            
                            this.toggleBlocking2(false);
                            if (retData.length === 0) {
                                toast.warn(`Nessun sinistro da elaborare. Controllare il contenuto della casella email ${arrParam[2]} ed eventualmente riprovare!!`, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),                    
                                    position: "bottom-right",
                                    autoClose: 10000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true});    
                            }
                            else {
                                setTimeout(() => {
                                    document.getElementById('aggiornatabella').click();
                                }); 
                                this.dialogInstance1.header = `ELENCO SINISTRI IMPORTATI DA GENERALI/CATTOLICA`;  
                                this.dialogInstance1.width = '65%';
                                this.dialogInstance1.height = '90%';
                                this.dialogInstance1.minHeight = '90%';
                                this.dialogInstance1.beforeClose = undefined;
                                this.dialogInstance1.close = undefined;  
                                this.dialogInstance1.beforeOpen = (args) => {
                                    const container = document.getElementById('defaultDialogContent');
                                    const root = createRoot(container);      
                                    root.unmount();                   
                                }
                                this.dialogInstance1.open = (args) => { 
                                    const container = document.getElementById('defaultDialogContent');
                                    const root = createRoot(container);                         
                                    root.render(<CallbackWrapper callback={() => {
                                        this.gridInstance4.dataSource = retData; 
                                        setTimeout(() => {  
                                            this.gridInstance4.refresh();  
                                        }, 500);     
                                    }}>{this.reportSinistriImportatiRender()}</CallbackWrapper>);  
                                }                          
                                this.dialogInstance1.show();       
                            }
                        }, error => {
                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),                    
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true});
                            this.toggleBlocking2(false);
                        }, true, false);            
                    }, 
                        buttonModel: { 
                            content: 'CONFERMA', 
                            cssClass: 'e-primary',
                            isPrimary: true 
                        }
                    },
                    {
                        click: () => {
                            this.dialogInstance3.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
                this.dialogInstance3.show();      
            }).catch(messaggio => { 
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            });     
        }
    }
    onSelectSplitBtn3(args) {
        if (args.item.id === 'ImportaUnipolSAI') {
            const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
            if (CustomJS.isNullEmpty(document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value))
                return;
            this.toggleBlocking();
            const QueryExec = `SELECT TOP 1 [01001].id_interno_sinistro, [01001].t_incarico FROM [01001] WHERE [01001].numero_sinistro_agenzia = '${document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value}' AND [01001].id_interno_sinistro <> ${document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value} ORDER BY [01001].t_incarico DESC`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                this.toggleBlocking();
                if (retData.length === 0) {
                    this.eseguiImportazioneSinistroUnipolSAI();
                }
                else {
                    this.dialogInstance3.header = 'Procedura importazione automatica sinistri UNIPOLSAI';
                    this.dialogInstance3.content = `Il sinistro numero <b>${document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value}</b> è già esistente all'interno del sistema con numero sinistro interno <b>${retData[0].id_interno_sinistro}</b> e con Data Incarico <b>${moment(retData[0].t_incarico).format('DD/MM/YYYY')}</b>. Confermi di voler effettuare comunque l'importazione del sinistro medesimo?`;
                    this.dialogInstance3.width = '32%';
                    this.dialogInstance3.height = '20%';
                    this.dialogInstance3.minHeight = '20%';
                    this.dialogInstance3.buttons = [
                        { click: () => { 
                            this.eseguiImportazioneSinistroUnipolSAI();
                        }, 
                        buttonModel: { 
                            content: 'CONFERMA', 
                            cssClass: 'e-primary',
                            isPrimary: true 
                        }
                        },
                        {
                            click: () => {
                                this.dialogInstance3.hide();
                            },
                            buttonModel: {
                                content: 'ANNULLA',
                            }
                        }
                    ];   
                    this.dialogInstance3.show();      
                }
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);
        }
        if (args.item.id === 'ImportaZurich') {
            const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
            if (CustomJS.isNullEmpty(document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value))
                return;
            this.toggleBlocking();
            const QueryExec = `SELECT TOP 1 [01001].id_interno_sinistro, [01001].t_incarico FROM [01001] WHERE [01001].numero_sinistro_agenzia = '${document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value}' AND [01001].id_interno_sinistro <> ${document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value} ORDER BY [01001].t_incarico DESC`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                this.toggleBlocking();
                if (retData.length === 0) {
                    this.eseguiImportazioneSinistroZurich();
                }
                else {
                    this.dialogInstance3.header = 'Procedura importazione automatica sinistri ZURICH';
                    this.dialogInstance3.content = `Il sinistro numero <b>${document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value}</b> è già esistente all'interno del sistema con numero sinistro interno <b>${retData[0].id_interno_sinistro}</b> e con Data Incarico <b>${moment(retData[0].t_incarico).format('DD/MM/YYYY')}</b>. Confermi di voler effettuare comunque l'importazione del sinistro medesimo?`;
                    this.dialogInstance3.width = '32%';
                    this.dialogInstance3.height = '20%';
                    this.dialogInstance3.minHeight = '20%';
                    this.dialogInstance3.buttons = [
                        { click: () => { 
                            this.eseguiImportazioneSinistroZurich();
                        }, 
                        buttonModel: { 
                            content: 'CONFERMA', 
                            cssClass: 'e-primary',
                            isPrimary: true 
                        }
                        },
                        {
                            click: () => {
                                this.dialogInstance3.hide();
                            },
                            buttonModel: {
                                content: 'ANNULLA',
                            }
                        }
                    ];   
                    this.dialogInstance3.show();      
                }
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);
        }
        if (args.item.id === 'ImportaAssimoco') {
            const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
            if (CustomJS.isNullEmpty(document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value))
                return;
            this.toggleBlocking();
            const QueryExec = `SELECT TOP 1 [01001].id_interno_sinistro, [01001].t_incarico FROM [01001] WHERE [01001].numero_sinistro_agenzia = '${document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value}' AND [01001].id_interno_sinistro <> ${document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value} ORDER BY [01001].t_incarico DESC`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                this.toggleBlocking();
                if (retData.length === 0) {
                    this.eseguiImportazioneSinistroAssimoco();
                }
                else {
                    this.dialogInstance3.header = 'Procedura importazione automatica sinistri ASSIMOCO';
                    this.dialogInstance3.content = `Il sinistro numero <b>${document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value}</b> è già esistente all'interno del sistema con numero sinistro interno <b>${retData[0].id_interno_sinistro}</b> e con Data Incarico <b>${moment(retData[0].t_incarico).format('DD/MM/YYYY')}</b>. Confermi di voler effettuare comunque l'importazione del sinistro medesimo?`;
                    this.dialogInstance3.width = '32%';
                    this.dialogInstance3.height = '20%';
                    this.dialogInstance3.minHeight = '20%';
                    this.dialogInstance3.buttons = [
                        { click: () => { 
                            this.eseguiImportazioneSinistroAssimoco();
                        }, 
                        buttonModel: { 
                            content: 'CONFERMA', 
                            cssClass: 'e-primary',
                            isPrimary: true 
                        }
                        },
                        {
                            click: () => {
                                this.dialogInstance3.hide();
                            },
                            buttonModel: {
                                content: 'ANNULLA',
                            }
                        }
                    ];   
                    this.dialogInstance3.show();      
                }
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);
        }
    }
    onSelectSplitBtn4(args) {
        let oggettoFiltri = JSON.parse(sessionStorage.getItem('JellyfishTechnologyFiltriAggiuntiviSinistri'));
        this.caricaDati(oggettoFiltri, args.item.id, $('#GridSituazioneSinistri_searchbar').val()?.trim());
    }
    eseguiImportazioneSinistroUnipolSAI() {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        CustomJS.getParametroDB(46).then(ret => {
            let arrParam = ret.split(';');
            this.dialogInstance3.header = 'Procedura importazione automatica sinistri UNIPOLSAI';
            this.dialogInstance3.content = `La procedura importerà automaticamente il sinistro numero <b>${document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value}</b> cercandolo nelle email ricevute da <b>UNIPOLSAI</b> presenti nella casella <b>${arrParam[2]}</b>. Le email considerate sono quelle nello stato <b>LETTE</b>, ricevute <b>nell'ultima settimana</b> e con la dicitura <b>${arrParam[5]}</b> nell'oggetto. Il file allegato che viene considerato per l'importazione è quello denominato <b>${arrParam[6]}</b>. Confermare l'operazione?`;
            this.dialogInstance3.width = '35%';
            this.dialogInstance3.height = '24%';
            this.dialogInstance3.minHeight = '24%';
            this.dialogInstance3.buttons = [
                { click: () => { 
                    this.dialogInstance3.hide();
                    this.toggleBlocking();
                    const dataObj = {
                        ServerIMAP: arrParam[0],
                        PortaIMAP: arrParam[1],
                        UtenteIMAP: arrParam[2],
                        PasswordIMAP: arrParam[3], 
                        SSLIMAP: arrParam[4],
                        Compagnia: 'UNIPOLSAI',
                        StringaRicerca: arrParam[5],
                        NomeAllegato: arrParam[6],    
                        NomeCartellaRicerca: arrParam[7], 
                        SinistroDaCercare: document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value,
                        IdInternoSinistro: document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value,
                        Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase()
                    };
                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/leggiEmailCreaSinistri`, JSON.stringify(dataObj), 'POST',
                            'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                        if (retData.response === 'KO') {
                            let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                            toast.error(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),                    
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            this.toggleBlocking();
                            return;
                        }            
                        this.toggleBlocking();
                        if (retData.length === 0) {
                            toast.warn(`Sinistro da importare non trovato. Controllare il contenuto della casella email ${arrParam[2]} ed eventualmente riprovare!!`, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),                    
                                position: "bottom-right",
                                autoClose: 10000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true});    
                        }
                        else {
                            this.dialogInstance1.header = `ELENCO SINISTRI IMPORTATI DA UNIPOLSAI`;  
                            this.dialogInstance1.width = '65%';
                            this.dialogInstance1.height = '60%';
                            this.dialogInstance1.minHeight = '60%';
                            this.dialogInstance1.beforeOpen = (args) => {
                                const container = document.getElementById('defaultDialogContent');
                                const root = createRoot(container);      
                                root.unmount();                   
                            }
                            this.dialogInstance1.open = (args) => {
                                const container = document.getElementById('defaultDialogContent');
                                const root = createRoot(container);                         
                                root.render(<CallbackWrapper callback={() => {
                                    this.gridInstance4.dataSource = retData;     
                                    setTimeout(() => {  
                                        this.gridInstance4.refresh();    
                                    }, 200);   
                                }}>{this.reportSinistriImportatiRender()}</CallbackWrapper>);  
                            }   
                            this.dialogInstance1.beforeClose = undefined;
                            this.dialogInstance1.close = (args) => {
                                document.getElementById(`btnEsci${idSin}`).ej2_instances[0].click();
                            };                                          
                            this.dialogInstance1.show();       
                        }
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),                    
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true});
                        this.toggleBlocking();
                    }, true, false);            
                }, 
                    buttonModel: { 
                        content: 'CONFERMA', 
                        cssClass: 'e-primary',
                        isPrimary: true 
                    }
                },
                {
                    click: () => {
                        this.dialogInstance3.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
            this.dialogInstance3.show();      
        }).catch(messaggio => { 
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        });     
    }
    eseguiImportazioneSinistroZurich() {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        CustomJS.getParametroDB(47).then(ret => {
            let arrParam = ret.split(';');
            this.dialogInstance3.header = 'Procedura importazione automatica sinistri ZURICH';
            this.dialogInstance3.content = `La procedura importerà automaticamente il sinistro numero <b>${document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value}</b> cercandolo nelle email ricevute da <b>ZURICH</b> presenti nella casella <b>${arrParam[2]}</b>. Le email considerate sono quelle nello stato <b>LETTE</b>, ricevute <b>nell'ultima settimana</b> e con la dicitura <b>${arrParam[5]}</b> nell'oggetto. Il file allegato che viene considerato per l'importazione è quello denominato <b>${arrParam[6]}</b>. Confermare l'operazione?`;
            this.dialogInstance3.width = '35%';
            this.dialogInstance3.height = '24%';
            this.dialogInstance3.minHeight = '24%';
            this.dialogInstance3.buttons = [
                { click: () => { 
                    this.dialogInstance3.hide();
                    this.toggleBlocking();
                    const dataObj = {
                        ServerIMAP: arrParam[0],
                        PortaIMAP: arrParam[1],
                        UtenteIMAP: arrParam[2],
                        PasswordIMAP: arrParam[3], 
                        SSLIMAP: arrParam[4],
                        Compagnia: 'ZURICH',
                        StringaRicerca: arrParam[5],
                        NomeAllegato: arrParam[6],    
                        NomeCartellaRicerca: arrParam[7], 
                        SinistroDaCercare: document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value,
                        IdInternoSinistro: document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value,
                        Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase()
                    };
                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/leggiEmailCreaSinistri`, JSON.stringify(dataObj), 'POST',
                            'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                        if (retData.response === 'KO') {
                            let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                            toast.error(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),                    
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            this.toggleBlocking();
                            return;
                        }            
                        this.toggleBlocking();
                        if (retData.length === 0) {
                            toast.warn(`Sinistro da importare non trovato. Controllare il contenuto della casella email ${arrParam[2]} ed eventualmente riprovare!!`, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),                    
                                position: "bottom-right",
                                autoClose: 10000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true});    
                        }
                        else {
                            this.dialogInstance1.header = `ELENCO SINISTRI IMPORTATI DA ZURICH`;  
                            this.dialogInstance1.width = '65%';
                            this.dialogInstance1.height = '60%';
                            this.dialogInstance1.minHeight = '60%';
                            this.dialogInstance1.beforeClose = undefined;
                            this.dialogInstance1.beforeOpen = (args) => {
                                const container = document.getElementById('defaultDialogContent');
                                const root = createRoot(container);      
                                root.unmount();                   
                            }
                            this.dialogInstance1.open = (args) => {
                                const container = document.getElementById('defaultDialogContent');
                                const root = createRoot(container);                         
                                root.render(<CallbackWrapper callback={() => {
                                    this.gridInstance4.dataSource = retData;     
                                    setTimeout(() => {  
                                        this.gridInstance4.refresh();    
                                    }, 200);   
                                }}>{this.reportSinistriImportatiRender()}</CallbackWrapper>);  
                            }   
                            this.dialogInstance1.close = (args) => {
                                document.getElementById(`btnEsci${idSin}`).ej2_instances[0].click();
                            };                                          
                            this.dialogInstance1.show();       
                        }
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),                    
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true});
                        this.toggleBlocking();
                    }, true, false);            
                }, 
                    buttonModel: { 
                        content: 'CONFERMA', 
                        cssClass: 'e-primary',
                        isPrimary: true 
                    }
                },
                {
                    click: () => {
                        this.dialogInstance3.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
            this.dialogInstance3.show();      
        }).catch(messaggio => { 
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        });     
    }
    sincronizzaIncarichiUnipolSAI() {
        this.toggleBlocking2(true);
        const dataObj = {
            APIUrl: CustomJS.APIURLTokenUnipolSAIProd,
            APIPath: "/returnJWTPIVA",
            NomeCert: CustomJS.NomeCertificatoUnipolSAI
        };
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/getAuthTokenUnipolSAI`, JSON.stringify(dataObj), 'POST',
            'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
            if (retData.response === 'OK') {
                const dataObj2 = {
                    TipoChiamata: "ListaIncarichi",
                    Metodo: 'GET',
                    APIUrl: CustomJS.APIURLDatiUnipolSAIProd,
                    APIPath: `${CustomJS.PathURLDatiUnipolSAIProd}/sinistri/swperitali/espullpush/v1/eventi`,
                    x_ibm_client_secret: CustomJS.ClientSecretProduzioneUnipolSAI,
                    accept: 'application/json',
                    x_ibm_client_id: CustomJS.ClientIdProduzioneUnipolSAI,
                    x_unipol_requestid: 'x-unipol-req-id',
                    authorization_token_unipolsai: `Bearer ${retData.data}`
                };
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIUnipolSAI`, JSON.stringify(dataObj2), 'POST',
                    'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData2 => { 
                        const QueryExec = 'SELECT * FROM [10001]';
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData3 => {
                            console.log(retData3);
                            if (retData3.length > 0) {
                                retData3.forEach(elm => {
                                    elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                                    if (CustomJS.isNullEmpty(elm.id_interno_sinistro))
                                        elm.status = 'Da importare'
                                    else
                                        elm.status = 'Importato'
                                });
                                this.gridInstance8.dataSource = retData3;
                            }
                            else
                                this.gridInstance8.dataSource = null;
                            toast.success(retData2.message, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            this.toggleBlocking2(false);
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            this.toggleBlocking2(false);
                        }, true, true);       
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }, true, false);        
            }
            else if (retData.response === 'KO') {
                toast.warn(retData.message, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking2(false);
        }, true, false)
    }
    importaIncarichiSelezionatiUnipolSAI() { 
        document.getElementById('importaIncarichiSelezionatiUnipolSAI').disabled = true;
        let funcList = [], listaIncarichiImportati = [];
        this.gridInstance8.getSelectedRecords().forEach(elm => {
            const esegui = () => {
                return new Promise((resolve, reject) => {
                    const dataObj = {
                        APIUrl: CustomJS.APIURLTokenUnipolSAIProd,
                        APIPath: "/returnJWTPIVA",
                        NomeCert: CustomJS.NomeCertificatoUnipolSAI
                    };       
                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/getAuthTokenUnipolSAI`, JSON.stringify(dataObj), 'POST',
                        'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                        if (retData.response === 'OK') {
                            const dataObj2 = {
                                TipoChiamata: "DatiIncarico",
                                Metodo: 'GET',
                                APIUrl: CustomJS.APIURLDatiUnipolSAIProd,
                                APIPath: `${CustomJS.PathURLDatiUnipolSAIProd}/sinistri/swperitali/espullpush/v1/eventi/${elm.guid_incarico}`,
                                x_ibm_client_secret: CustomJS.ClientSecretProduzioneUnipolSAI,
                                accept: 'application/json',
                                x_ibm_client_id: CustomJS.ClientIdProduzioneUnipolSAI,
                                x_unipol_requestid: 'x-unipol-req-id',
                                authorization_token_unipolsai: `Bearer ${retData.data}`,
                                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                Timeout: this.gridInstance8.getSelectedRecords().length === 1 ? 3500 : this.gridInstance8.getSelectedRecords().length <= 5 ? 100 : 200
                            };
                            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIUnipolSAI`, JSON.stringify(dataObj2), 'POST',
                                'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData2 => { 
                                    if (retData2.response === 'OK') {
                                        listaIncarichiImportati.push({ guidIncarico: elm.guid_incarico, incarico: elm.id_incarico, esito: 'OK' });
                                        resolve(retData2.message);
                                    }
                                    else if (retData2.response === 'KO') {
                                        listaIncarichiImportati.push({ incarico: elm.id_incarico, esito: 'KO' });
                                        reject(retData2.message);
                                    }           
                            }, error => {
                                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                reject(messaggio);                        
                            }, true, false);                    
                        }
                        else if (retData.response === 'KO') {
                            let messaggio = retData.message;
                            reject(messaggio);                        
                        }
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        reject(messaggio);                        
                    }, true, false);    
                });
            };
            funcList.push(esegui);    
        });

        const eseguiCiclica = (ind) => {
            if (CustomJS.isNullEmpty(funcList[ind])) {
                document.getElementById('importaIncarichiSelezionatiUnipolSAI').disabled = false;
                const QueryExec = 'SELECT * FROM [10001]';
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData3 => {
                    if (retData3.length > 0) {
                        retData3.forEach(elm => {
                            elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                            if (CustomJS.isNullEmpty(elm.id_interno_sinistro))
                                elm.status = 'Da importare'
                            else
                                elm.status = 'Importato'
                        });
                        this.gridInstance8.dataSource = retData3;
                    }
                    else
                        this.gridInstance8.dataSource = null;
                    //RIMOSSO DA QUESTO PUNTO PERCHE' VIENE FATTO QUANDO IL SINISTRO VA IN STATO "PRIMO CONTATTO"
                    // listaIncarichiImportati.filter(obj => { return obj.esito === 'OK' }).forEach(elm => {
                    //     this.invioAccettazioneIncaricoUnipolSAI(elm.incarico); 
                    // });
                    this.toggleBlocking2(false);
                    if (listaIncarichiImportati.filter(obj => { return obj.esito === 'OK' }).length > 0)
                        toast.success(`${CustomJS.messaggioImportaSelezionatiOK} ${listaIncarichiImportati.filter(obj => { return obj.esito === 'OK' }).map(obj => { return obj.incarico }).join(', ')}`, {
                            containerId: 'toastContainer1',
                            //onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });   
                    if (listaIncarichiImportati.filter(obj => { return obj.esito === 'KO' }).length > 0)
                        toast.error(`${CustomJS.messaggioImportaSelezionatiKO} ${listaIncarichiImportati.filter(obj => { return obj.esito === 'KO' }).map(obj => { return obj.incarico }).join(', ')}`, {
                            containerId: 'toastContainer1',
                            //onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });    
                    this.toggleBlocking2(false);
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }, true, true);       
            }
            else {
                funcList[ind]().then(ret => {
                    if(!CustomJS.isNullEmpty(ret)){
                        eseguiCiclica(ind + 1);
                    }
                }).catch(err => { 
                    eseguiCiclica(ind + 1);
                });
            } 
        }

        if (funcList.length === 0) { 
            document.getElementById('importaIncarichiSelezionatiUnipolSAI').disabled = false;
            return;
        }
        this.toggleBlocking2(true);
        eseguiCiclica(0);    
    }
    async sincronizzaIncarichiGenerali() {
        if (CustomJS.isNullEmpty(this.drInstance1.value))
            return;
        this.toggleBlocking2(true); 
        const dataObj = {
            APIUrl: CustomJS.APIURLTokenGeneraliProd,
            APIPath: '/nidp/oauth/nam/token',
            Parametri: {
                resourceServer: 'ml-b2b-internet',
                acr_values: 'urn:oasis:names:tc:SAML:2.0:ac:classes:generali:form:ggaccount',
                client_id: CustomJS.ClientIdTokenGenerali,
                client_secret: CustomJS.ClientSecretProduzioneGENERALI,
                scope: "APP-284-PRF-77",
                grant_type: "password",
                username: CustomJS.UtenteGENERALI,
                password: await CustomJS.getParametroDB(52)
            } 
        };
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/getAuthTokenGenerali`, JSON.stringify(dataObj), 'POST',
            'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
            if (retData.response === 'OK') {
                const dataObj2 = {
                    TipoChiamata: "ListaIncarichi",
                    APIUrl: CustomJS.APIURLDatiGeneraliProd,
                    APIPath: `${CustomJS.PathURLDatiGeneraliProd}/api/Incarichi/ListaIncarichi`,
                    accept: null,
                    content_type: 'application/json',
                    x_ibm_client_id: CustomJS.ClientIdProduzioneGENERALI,
                    authorization_token_generali: `Bearer ${retData.data.access_token}`,
                    Parametri: {
                        dataDa: `${moment(this.drInstance1.startDate).format('YYYY-MM-DD')}T00:01:00.000Z`,
                        dataA: `${moment(this.drInstance1.endDate).format('YYYY-MM-DD')}T23:59:59.000Z`
                    }
                };
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIGenerali`, JSON.stringify(dataObj2), 'POST',
                    'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData2 => { 
                        const QueryExec = 'SELECT * FROM [12001]';
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData3 => {
                            if (retData3.length > 0) {
                                retData3.forEach(elm => {
                                    elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                                    if (CustomJS.isNullEmpty(elm.id_interno_sinistro))
                                        elm.status = 'Da importare'
                                    else
                                        elm.status = 'Importato'
                                });
                                this.gridInstance9.dataSource = retData3;
                            }
                            else
                                this.gridInstance9.dataSource = null;
                            toast.success(retData2.message, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            this.toggleBlocking2(false);
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            this.toggleBlocking2(false);
                        }, true, true);       
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }, true, false);        
            }
            else if (retData.response === 'KO') {
                toast.warn(retData.message, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking2(false);
        }, true, false);
    }
    importaIncarichiSelezionatiGenerali() { 
        document.getElementById('importaIncarichiSelezionatiGenerali').disabled = true;
        let funcList = [], listaIncarichiImportati = [];
        this.gridInstance9.getSelectedRecords().forEach(elm => {
            const esegui = () => {
                return new Promise(async (resolve, reject) => {
                    const dataObj = {
                        APIUrl: CustomJS.APIURLTokenGeneraliProd,
                        APIPath: '/nidp/oauth/nam/token',
                        Parametri: {
                            resourceServer: 'ml-b2b-internet',
                            acr_values: 'urn:oasis:names:tc:SAML:2.0:ac:classes:generali:form:ggaccount',
                            client_id: CustomJS.ClientIdTokenGenerali,
                            client_secret: CustomJS.ClientSecretProduzioneGENERALI,
                            scope: "APP-284-PRF-77",
                            grant_type: "password",
                            username: CustomJS.UtenteGENERALI,
                            password: await CustomJS.getParametroDB(52)
                        } 
                    };
                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/getAuthTokenGenerali`, JSON.stringify(dataObj), 'POST',
                        'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                        if (retData.response === 'OK') {
                            const dataObj2 = {
                                TipoChiamata: "DatiIncarico",
                                APIUrl: CustomJS.APIURLDatiGeneraliProd,
                                APIPath: `${CustomJS.PathURLDatiGeneraliProd}/api/Incarichi/DatiIncarico`,
                                accept: null,
                                content_type: 'application/json',
                                x_ibm_client_id: CustomJS.ClientIdProduzioneGENERALI,
                                authorization_token_generali: `Bearer ${retData.data.access_token}`,
                                Timeout: 3500,
                                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                Parametri: {
                                    idIncarico: elm.id_incarico
                                }
                            };
                            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIGenerali`, JSON.stringify(dataObj2), 'POST',
                                'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData2 => { 
                                    if (retData2.response === 'OK') {
                                        listaIncarichiImportati.push({ incarico: elm.id_incarico, esito: 'OK' });
                                        resolve(retData2.message);
                                    }
                                    else if (retData2.response === 'KO') {
                                        listaIncarichiImportati.push({ incarico: elm.id_incarico, esito: 'KO' });
                                        reject(retData2.message);
                                    }           
                            }, error => {
                                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                reject(messaggio);                        
                            }, true, false);                    
                        }
                        else if (retData.response === 'KO') {
                            let messaggio = retData.message;
                            reject(messaggio);                        
                        }
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        reject(messaggio);                        
                    }, true, false);    
                });
            };
            funcList.push(esegui);    
        });

        const eseguiCiclica = (ind) => {
            if (CustomJS.isNullEmpty(funcList[ind])) {
                document.getElementById('importaIncarichiSelezionatiGenerali').disabled = false;
                const QueryExec = 'SELECT * FROM [12001]';
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData3 => {
                    if (retData3.length > 0) {
                        retData3.forEach(elm => {
                            elm.data_incarico = moment(elm.data_incarico).format('DD/MM/YYYY');
                            if (CustomJS.isNullEmpty(elm.id_interno_sinistro))
                                elm.status = 'Da importare'
                            else
                                elm.status = 'Importato'
                        });
                        this.gridInstance9.dataSource = retData3;
                    }
                    else
                        this.gridInstance9.dataSource = null;
                    this.toggleBlocking2(false);
                    if (listaIncarichiImportati.filter(obj => { return obj.esito === 'OK' }).length > 0)
                        toast.success(`${CustomJS.messaggioImportaSelezionatiOK2} ${listaIncarichiImportati.filter(obj => { return obj.esito === 'OK' }).map(obj => { return obj.incarico }).join(', ')}`, {
                            containerId: 'toastContainer1',
                            //onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });   
                    if (listaIncarichiImportati.filter(obj => { return obj.esito === 'KO' }).length > 0)
                        toast.error(`${CustomJS.messaggioImportaSelezionatiKO} ${listaIncarichiImportati.filter(obj => { return obj.esito === 'KO' }).map(obj => { return obj.incarico }).join(', ')}`, {
                            containerId: 'toastContainer1',
                            //onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });    
                    this.toggleBlocking2(false);
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }, true, true);       
            }
            else {
                funcList[ind]().then(ret => {
                    if(!CustomJS.isNullEmpty(ret)){
                        eseguiCiclica(ind + 1);
                    }
                }).catch(err => { 
                    eseguiCiclica(ind + 1);
                });
            } 
        }

        if (funcList.length === 0) {
            document.getElementById('importaIncarichiSelezionatiGenerali').disabled = false;
            return;
        };
        this.toggleBlocking2(true);
        eseguiCiclica(0);    
    }
    invioChiusuraGENERALI(idSinistro, idInternoSinistro, idIncarico, numeroSinistro) {
        return new Promise(async (resolve, reject) => {
            const dataObj = {
                APIUrl: CustomJS.APIURLTokenGeneraliProd,
                APIPath: '/nidp/oauth/nam/token',
                Parametri: {
                    resourceServer: 'ml-b2b-internet',
                    acr_values: 'urn:oasis:names:tc:SAML:2.0:ac:classes:generali:form:ggaccount',
                    client_id: CustomJS.ClientIdTokenGenerali,
                    client_secret: CustomJS.ClientSecretProduzioneGENERALI,
                    scope: "APP-284-PRF-77",
                    grant_type: "password",
                    username: CustomJS.UtenteGENERALI,
                    password: await CustomJS.getParametroDB(52)
                } 
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/getAuthTokenGenerali`, JSON.stringify(dataObj), 'POST',
                'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                if (retData.response === 'OK') {
                    const dataObj2 = {
                        TipoChiamata: "ChiusuraDanneggiato",
                        APIUrl: CustomJS.APIURLDatiGeneraliProd,
                        APIPath: `${CustomJS.PathURLDatiGeneraliProd}/api/Chiusure/ChiusuraDanneggiato`,
                        accept: null,
                        content_type: 'application/json',
                        x_ibm_client_id: CustomJS.ClientIdProduzioneGENERALI,
                        authorization_token_generali: `Bearer ${retData.data.access_token}`,
                        Timeout: 3500,
                        Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                        Parametri: {
                            IdSinistro: idSinistro,
                            IdInternoSinistro: idInternoSinistro,
                            IdIncarico: idIncarico
                        }
                    };
                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIGenerali`, JSON.stringify(dataObj2), 'POST',
                        'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData2 => { 
                            if (retData2.response === 'OK') {
                                toast.success(retData2.message, {
                                    containerId: 'toastContainer1',
                                    //onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 15000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true        
                                });
                                const dataObj3 = {
                                    TipoChiamata: "ChiusuraIncarico",
                                    APIUrl: CustomJS.APIURLDatiGeneraliProd,
                                    APIPath: `${CustomJS.PathURLDatiGeneraliProd}/api/Chiusure/ChiusuraIncarico`,
                                    accept: null,
                                    content_type: 'application/json',
                                    x_ibm_client_id: CustomJS.ClientIdProduzioneGENERALI,
                                    authorization_token_generali: `Bearer ${retData.data.access_token}`,
                                    Timeout: 3500,
                                    Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                    Parametri: {
                                        IdSinistro: idSinistro,
                                        IdInternoSinistro: idInternoSinistro,
                                        IdIncarico: idIncarico,
                                        NumeroSinistro: numeroSinistro
                                    }
                                };
                                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIGenerali`, JSON.stringify(dataObj3), 'POST',
                                    'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData3 => { 
                                        if (retData3.response === 'OK') {
                                            toast.success(retData3.message, {
                                                containerId: 'toastContainer1',
                                                //onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 15000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true        
                                            });
                                            resolve({ response: retData3.response });
                                        }
                                        else if (retData3.response === 'KO') {
                                            resolve({ response: retData3.response });
                                        }           
                                }, error => {
                                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                    reject(messaggio);                        
                                }, true, false);   
                            }
                            else if (retData2.response === 'KO') {
                                resolve({ response: retData2.response });
                            }           
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        reject(messaggio);                        
                    }, true, false);                    
                }
                else if (retData.response === 'KO') {
                    let messaggio = retData.message;
                    reject(messaggio);                        
                }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                reject(messaggio);                        
            }, true, false);    
        });
    }
    btnVisualizzaDocumentoGENERALI() {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.visualizzaDocumentoGENERALI(document.getElementById(`idIncarico${idSin}`).value);
    }
    async visualizzaDocumentoGENERALI(idIncarico) {
        this.toggleBlocking2(true);
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const dataObj = {
            APIUrl: CustomJS.APIURLTokenGeneraliProd,
            APIPath: '/nidp/oauth/nam/token',
            Parametri: {
                resourceServer: 'ml-b2b-internet',
                acr_values: 'urn:oasis:names:tc:SAML:2.0:ac:classes:generali:form:ggaccount',
                client_id: CustomJS.ClientIdTokenGenerali,
                client_secret: CustomJS.ClientSecretProduzioneGENERALI,
                scope: "APP-284-PRF-77",
                grant_type: "password",
                username: CustomJS.UtenteGENERALI,
                password: await CustomJS.getParametroDB(52)
            } 
        };
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/getAuthTokenGenerali`, JSON.stringify(dataObj), 'POST',
            'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
            if (retData.response === 'OK') {
                const dataObj2 = {
                    TipoChiamata: "DocumentoPerizia",
                    APIUrl: CustomJS.APIURLDatiGeneraliProd,
                    APIPath: `${CustomJS.PathURLDatiGeneraliProd}/api/Chiusure/ScaricaAllegatoPerizia`,
                    accept: null,
                    content_type: 'application/json',
                    x_ibm_client_id: CustomJS.ClientIdProduzioneGENERALI,
                    authorization_token_generali: `Bearer ${retData.data.access_token}`,
                    Timeout: 3500,
                    Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                    Parametri: {
                        idIncarico: idIncarico
                    }
                };
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIGenerali`, JSON.stringify(dataObj2), 'POST',
                    'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData2 => { 
                        if (retData2.response === 'OK') {
                            this.toggleBlocking2(false);
                            let timeout = 0;
                            if (this.dialogInstance8.visible === true) {
                                this.dialogInstance8.hide();
                                timeout = 500;
                            }
                            setTimeout(() => {                
                                this.dialogInstance8.header = `<div><span id='dialog-title-${this.dialogInstance8.id}' class='title'>VISUALIZZAZIONE DOCUMENTO PDF</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance8.id}' title='Minimizza la finestra'></span></div>`;    
                                this.dialogInstance8.width = '65%';
                                this.dialogInstance8.height = '90%';
                                this.dialogInstance8.minHeight = '90%';
                                this.dialogInstance8.beforeOpen = (args) => {
                                    const container = document.getElementById('defaultDialogContent5');
                                    const root = createRoot(container); 
                                    root.unmount();
                                };
                                this.dialogInstance8.open = (args) => {
                                    this.onMinimizeDialogOpen(`VISUALIZZAZIONE DOCUMENTO PDF`, '440px', '240', '90%', this.dialogInstance8);
                                    const container = document.getElementById('defaultDialogContent5');
                                    const root = createRoot(container);  
                                    root.render(<CallbackWrapper callback={() => {
                                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].enableDownload = true;
                                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].downloadFileName = `DocumentoPeriziaGENERALI_${idSin}.pdf`;
                                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].serviceUrl = `${CustomJS.APIVariousJfishTechURLProd}/api/PdfViewer`;
                                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                                        //this.PDFViewerInstance.dataBind();  //NON SEMBRA SERVIRE
                                        setTimeout(() => {
                                            document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].load(`data:application/pdf;base64,${retData2.data.content}`, null);
                                        }, 250);  
                                        this.toggleBlocking2(false);
                                    }}>{this.PDFViewerRender()}</CallbackWrapper>);   
                                };  
                                this.dialogInstance8.show();         
                            }, timeout);
                        }
                        else {
                            let messaggio = _.isString(retData.message) && retData.message.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),                    
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            ); 
                            this.toggleBlocking2(false);
                        }            
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),                    
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    ); 
                    this.toggleBlocking2(false);
                }, true, false); 
            }
            else {
                let messaggio = _.isString(retData.message) && retData.message.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                ); 
                this.toggleBlocking2(false);
            }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            ); 
            this.toggleBlocking2(false);
        });
    }
    aggiungiDefinizioneDannoUnipolSAI() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.toggleBlocking2(true);
        const QueryExec = `SELECT T1.* FROM [10006] T1 WHERE T1.id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value};`
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            const container = document.getElementById(`divDefinizioneDannoUnipolSAI${idSin}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                document.getElementById(`mezzoPagamentoUnipol${idSin}`).ej2_instances[0].dataSource = this.dsMezzoPagamento ?? null;
                document.getElementById(`tipoBeneficiarioUnipol${idSin}`).ej2_instances[0].dataSource = this.dsTipoBeneficiarioUNI ?? null;
                document.getElementById(`tipoPersonaBeneficiarioUnipol${idSin}`).ej2_instances[0].dataSource = this.dsTipoPersona ?? null;  
                document.getElementById(`tipoPersonaDomiciliatarioUnipol${idSin}`).ej2_instances[0].dataSource = this.dsTipoPersona ?? null;
                document.getElementById(`sessoBeneficiarioUnipol${idSin}`).ej2_instances[0].dataSource = this.dsSessoBeneficiario ?? null; 
                document.getElementById(`comuneNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].dataSource = this.dsComuni; 
                document.getElementById(`comuneIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].dataSource = this.dsComuni; 
                document.getElementById(`comuneIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].dataSource = this.dsComuni;
                document.getElementById(`provinciaNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].dataSource = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaProvince2')));
                document.getElementById(`provinciaIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].dataSource = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaProvince2')));
                document.getElementById(`provinciaIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].dataSource = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaProvince2')));
                document.getElementById(`nazioneNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].dataSource = this.dsNazioni; 
                document.getElementById(`nazioneIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].dataSource = this.dsNazioni;
                document.getElementById(`nazioneIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].dataSource = this.dsNazioni; 
                document.getElementById(`mezzoPagamentoUnipol${idSin}`).ej2_instances[0].value = retData[0]?.codiceMezzoPagamento?.trim() ?? null;   
                document.getElementById(`tipoBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.codiceTipoBeneficiario?.trim() ?? null;
                document.getElementById(`tipoPersonaBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.codiceTipoPersonaBeneficiario?.trim() ?? CustomJS.isNullEmpty(document.getElementById(`partitaIVADanneggiato${idSin}`).ej2_instances[0].value?.trim()) ? 'F' : 'G';  
                document.getElementById(`sessoBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.sessoBeneficiario?.trim() ?? null;
                document.getElementById(`cognomeBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.cognomeBeneficiario?.trim() ?? document.getElementById(`danneggiato${idSin}`).ej2_instances[0].value?.trim().split(' ')[0]; 
                document.getElementById(`nomeBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.nomeBeneficiario?.trim() ?? document.getElementById(`danneggiato${idSin}`).ej2_instances[0].value?.trim().split(' ')[1]; 
                document.getElementById(`codiceFiscaleBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.codiceFiscaleBeneficiario?.trim() ?? document.getElementById(`codiceFiscaleDanneggiato${idSin}`).ej2_instances[0].value?.trim(); 
                document.getElementById(`partitaIvaBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.partitaIvaBeneficiario?.trim() ?? document.getElementById(`partitaIVADanneggiato${idSin}`).ej2_instances[0].value?.trim(); 
                document.getElementById(`dataNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.dataNascitaBeneficiario?.trim() ?? null; 
                document.getElementById(`comuneNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.codiceComuneNascitaBeneficiario?.trim() ?? null; 
                document.getElementById(`provinciaNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.siglaProvinciaNascitaBeneficiario?.trim() ?? null;
                document.getElementById(`nazioneNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.codiceNazioneNascitaBeneficiario?.trim() ?? null; 
                document.getElementById(`comuneEsteroNascitaBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.comuneEsteroNascitaBeneficiario?.trim() ?? null; 
                document.getElementById(`indirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.indirizzoBeneficiario?.trim() ?? document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value?.split(',')[0]; 
                document.getElementById(`numeroCivicoIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.numeroCivicoIndirizzoBeneficiario?.trim() ?? document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value?.split(',')[1] ?? ''; 
                document.getElementById(`capIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.capIndirizzoBeneficiario?.trim() ?? document.getElementById(`capUbicazioneRischio${idSin}`).ej2_instances[0].value; 
                document.getElementById(`comuneIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.codiceComuneIndirizzoBeneficiario?.trim() ?? _.find(this.dsComuni, elm => { return elm.DESCRIZ?.trim() === document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim() })?.cod_ele ?? ''; 
                document.getElementById(`provinciaIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.siglaProvinciaIndirizzoBeneficiario?.trim() ?? document.getElementById(`provinciaUbicazioneRischio${idSin}`).ej2_instances[0].value; 
                document.getElementById(`nazioneIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.codiceNazioneIndirizzoBeneficiario?.trim() ?? 'ITA'; 
                document.getElementById(`comuneEsteroIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.comuneEsteroIndirizzoBeneficiario?.trim() ?? null; 
                document.getElementById(`tipoPersonaDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.codiceTipoPersonaDomiciliatario?.trim() ?? null;;
                document.getElementById(`cognomeDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.cognomeDomiciliatario?.trim() ?? null;
                document.getElementById(`nomeDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.nomeDomiciliatario?.trim() ?? null;
                document.getElementById(`partitaIvaDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.partitaIvaDomiciliatario?.trim() ?? null;
                document.getElementById(`codiceFiscaleDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.codiceFiscaleDomiciliatario?.trim() ?? null;
                document.getElementById(`indirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.indirizzoDomiciliatario?.trim() ?? null;
                document.getElementById(`numeroCivicoIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.numeroCivicoIndirizzoDomiciliatario?.trim() ?? null;
                document.getElementById(`capIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.capIndirizzoDomiciliatario?.trim() ?? null;
                document.getElementById(`comuneIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.codiceComuneIndirizzoDomiciliatario?.trim() ?? null;
                document.getElementById(`provinciaIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.siglaProvinciaIndirizzoDomiciliatario?.trim() ?? null;
                document.getElementById(`nazioneIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.codiceNazioneIndirizzoDomiciliatario?.trim() ?? null;
                document.getElementById(`comuneEsteroIndirizzoDomiciliatarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.comuneEsteroIndirizzoDomiciliatario?.trim() ?? null;
                document.getElementById(`IBANBeneficiarioUnipol${idSin}`).ej2_instances[0].value = retData[0]?.iban?.trim() ?? null;
                this.onChangeTipoPersonaBeneficiarioUnipol({ value: document.getElementById(`tipoPersonaBeneficiarioUnipol${idSin}`).ej2_instances[0].value });
                this.onChangeCognomeBeneficiarioUnipol({ value: document.getElementById(`cognomeBeneficiarioUnipol${idSin}`).ej2_instances[0].value });
                this.onChangeNomeBeneficiarioUnipol({ value: document.getElementById(`nomeBeneficiarioUnipol${idSin}`).ej2_instances[0].value });
                this.onChangeCodiceFiscaleBeneficiarioUnipol({ value: document.getElementById(`codiceFiscaleBeneficiarioUnipol${idSin}`).ej2_instances[0].value });
                this.onChangePartitaIvaBeneficiarioUnipol({ value: document.getElementById(`partitaIvaBeneficiarioUnipol${idSin}`).ej2_instances[0].value });
                this.onChangeIndirizzoBeneficiarioUnipol({ value: document.getElementById(`indirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value });
                this.onChangeNumeroCivicoIndirizzoBeneficiarioUnipol({ value: document.getElementById(`numeroCivicoIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value });
                this.onChangeCapIndirizzoBeneficiarioUnipol({ value: document.getElementById(`capIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value });
                this.onChangeComuneIndirizzoBeneficiarioUnipol({ value: document.getElementById(`comuneIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value });
                this.onChangeProvinciaIndirizzoBeneficiarioUnipol({ value: document.getElementById(`provinciaIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value });
                this.onChangeNazioneIndirizzoBeneficiarioUnipol({ value: document.getElementById(`nazioneIndirizzoBeneficiarioUnipol${idSin}`).ej2_instances[0].value });   
                if (retData[0]?.codiceMezzoPagamento?.trim() === '02') {
                    document.getElementById(`divIBANBeneficiarioUnipolRiga1-${idSin}`).style.removeProperty('display');
                    document.getElementById(`divDomiciliatarioUnipolRiga1-${idSin}`).style.display = 'none';
                    document.getElementById(`divDomiciliatarioUnipolRiga2-${idSin}`).style.display = 'none';
                    document.getElementById(`divDomiciliatarioUnipolRiga3-${idSin}`).style.display = 'none';
                    document.getElementById(`divDomiciliatarioUnipolRiga4-${idSin}`).style.display = 'none';
                    document.getElementById(`divDomiciliatarioUnipolRiga5-${idSin}`).style.display = 'none';
                }
                else if (retData[0]?.codiceMezzoPagamento?.trim() === '06') {
                    document.getElementById(`divDomiciliatarioUnipolRiga1-${idSin}`).style.removeProperty('display');
                    document.getElementById(`divDomiciliatarioUnipolRiga2-${idSin}`).style.removeProperty('display');
                    document.getElementById(`divDomiciliatarioUnipolRiga3-${idSin}`).style.removeProperty('display');
                    document.getElementById(`divDomiciliatarioUnipolRiga4-${idSin}`).style.removeProperty('display');
                    document.getElementById(`divDomiciliatarioUnipolRiga5-${idSin}`).style.removeProperty('display');
                    document.getElementById(`divIBANBeneficiarioUnipolRiga1-${idSin}`).style.display = 'none';
                }       
            }}><React.Fragment>
                <h4 className="card-title" style={{ marginBottom: '15px', fontWeight: 'bolder' }}>Definizione del Danno</h4>
                <div className='row'>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <DropDownListComponent id={`mezzoPagamentoUnipol${idSin}`} fields={this.ddlFields} 
                            allowFiltering={false} filterType='Contains' showClearButton={true} onChange={this.onChangeMezzoPagamentoUnipol.bind(this)}
                            placeholder="Mezzo Pagamento" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <DropDownListComponent id={`tipoBeneficiarioUnipol${idSin}`} fields={this.ddlFields} 
                            allowFiltering={false} filterType='Contains' showClearButton={true} onChange={this.onChangeTipoBeneficiarioUnipol.bind(this)}
                            placeholder="Tipo Beneficiario" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <DropDownListComponent id={`tipoPersonaBeneficiarioUnipol${idSin}`} fields={this.ddlFields} 
                            allowFiltering={false} filterType='Contains' showClearButton={true} onChange={this.onChangeTipoPersonaBeneficiarioUnipol.bind(this)}
                            placeholder="Tipo Persona" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <TextBoxComponent id={`cognomeBeneficiarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Cognome Beneficiario' cssClass='e-outline' onChange={CustomJS.onChangeCampoTestoMaiuscolo} blur={this.onChangeCognomeBeneficiarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <TextBoxComponent id={`nomeBeneficiarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Nome Beneficiario' cssClass='e-outline' onChange={CustomJS.onChangeCampoTestoMaiuscolo} blur={this.onChangeNomeBeneficiarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <TextBoxComponent id={`codiceFiscaleBeneficiarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Codice Fiscale Beneficiario' cssClass='e-outline' onChange={CustomJS.onChangeCampoTestoMaiuscolo} blur={this.onChangeCodiceFiscaleBeneficiarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <TextBoxComponent id={`partitaIvaBeneficiarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Partita IVA Beneficiario' cssClass='e-outline' blur={this.onChangePartitaIvaBeneficiarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <ButtonComponent id={`estrapolaCodiceFiscaleUnipol${idSin}`} title='Ottieni Dati dal Codice Fiscale' cssClass='e-fill e-info' style={{ marginTop: '5px' }} type='button' onClick={this.estrapolaDatiCodiceFiscaleUnipol.bind(this)}>OTTIENI DATI DAL CODICE FISCALE</ButtonComponent>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                        <DropDownListComponent id={`sessoBeneficiarioUnipol${idSin}`} fields={this.ddlFields} 
                            allowFiltering={false} filterType='Contains' showClearButton={false}  
                            placeholder="Sesso Beneficiario" floatLabelType='always' popupHeight="250px" cssClass='e-outline' onChange={this.onChangeSessoBeneficiarioUnipol.bind(this)}>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" id='divDataNascita'>
                        <DatePickerComponent firstDayOfWeek={1} id={`dataNascitaBeneficiarioUnipol${idSin}`} cssClass='e-outline' format='dd/MM/yyyy' 
                            showClearButton={true} placeholder='Data Nascita Beneficiario' floatLabelType='Always' change={this.onChangeDataNascitaBeneficiarioUnipol.bind(this)}></DatePickerComponent>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <DropDownListComponent id={`comuneNascitaBeneficiarioUnipol${idSin}`} fields={{ text: 'DESCRIZ', value: 'cod_ele' }}
                            onChange={this.onChangeComuneNascitaBeneficiarioUnipol.bind(this)} allowFiltering={true} filterType='Contains' showClearButton={true} 
                            placeholder="Comune Nascita Beneficiario" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <DropDownListComponent id={`provinciaNascitaBeneficiarioUnipol${idSin}`} fields={this.ddlFields} 
                            allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeProvinciaNascitaBeneficiarioUnipol.bind(this)}
                            placeholder="Provincia Nascita Beneficiario" floatLabelType='always' popupHeight="350px" cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>                    
                </div>
                <div className="row">
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <DropDownListComponent id={`nazioneNascitaBeneficiarioUnipol${idSin}`} fields={{ text: 'DESCRIZ', value: 'cod_ele' }}
                            allowFiltering={true} filterType='Contains' showClearButton={true} placeholder="Nazione Nascita Beneficiario" floatLabelType='always' popupHeight="250px" 
                            cssClass='e-outline' onChange={this.onChangeNazioneNascitaBeneficiarioUnipol.bind(this)}>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <TextBoxComponent id={`comuneEsteroNascitaBeneficiarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Comune Estero Nascita Beneficiario' cssClass='e-outline' onChange={CustomJS.onChangeCampoTestoMaiuscolo} blur={this.onChangecomuneEsteroNascitaBeneficiarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5">
                        <TextBoxComponent id={`indirizzoBeneficiarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Indirizzo Beneficiario' cssClass='e-outline' onChange={CustomJS.onChangeCampoTestoMaiuscolo} blur={this.onChangeIndirizzoBeneficiarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                        <TextBoxComponent id={`numeroCivicoIndirizzoBeneficiarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Num. Civico' cssClass='e-outline' onChange={CustomJS.onChangeCampoTestoMaiuscolo} blur={this.onChangeNumeroCivicoIndirizzoBeneficiarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                        <TextBoxComponent id={`capIndirizzoBeneficiarioUnipol${idSin}`} floatLabelType='Auto' placeholder='CAP' cssClass='e-outline' blur={this.onChangeCapIndirizzoBeneficiarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <DropDownListComponent id={`comuneIndirizzoBeneficiarioUnipol${idSin}`} fields={{ text: 'DESCRIZ', value: 'cod_ele' }} 
                            allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeComuneIndirizzoBeneficiarioUnipol.bind(this)}
                            placeholder="Comune Beneficiario" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <DropDownListComponent id={`provinciaIndirizzoBeneficiarioUnipol${idSin}`} fields={this.ddlFields} 
                            allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeProvinciaIndirizzoBeneficiarioUnipol.bind(this)}
                            placeholder="Provincia Beneficiario" floatLabelType='always' popupHeight="350px" cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <DropDownListComponent id={`nazioneIndirizzoBeneficiarioUnipol${idSin}`} fields={{ text: 'DESCRIZ', value: 'cod_ele' }} 
                            allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeNazioneIndirizzoBeneficiarioUnipol.bind(this)}
                            placeholder="Nazione Beneficiario" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <TextBoxComponent id={`comuneEsteroIndirizzoBeneficiarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Comune Estero Beneficiario' cssClass='e-outline' onChange={CustomJS.onChangeCampoTestoMaiuscolo} blur={this.onChangeComuneEsteroIndirizzoBeneficiarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                </div>
                <div className='row' id={`divDomiciliatarioUnipolRiga1-${idSin}`}>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <DropDownListComponent id={`tipoPersonaDomiciliatarioUnipol${idSin}`} fields={this.ddlFields} 
                            allowFiltering={false} filterType='Contains' showClearButton={true} onChange={this.onChangeTipoPersonaDomiciliatarioUnipol.bind(this)}
                            placeholder="Tipo Persona Domiciliatario" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                </div>
                <div className="row" id={`divDomiciliatarioUnipolRiga2-${idSin}`}>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <TextBoxComponent id={`cognomeDomiciliatarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Cognome Domiciliatario' cssClass='e-outline' onChange={CustomJS.onChangeCampoTestoMaiuscolo} blur={this.onChangeCognomeDomiciliatarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <TextBoxComponent id={`nomeDomiciliatarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Nome Domiciliatario' cssClass='e-outline' onChange={CustomJS.onChangeCampoTestoMaiuscolo} blur={this.onChangeNomeDomiciliatarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <TextBoxComponent id={`codiceFiscaleDomiciliatarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Codice Fiscale Domiciliatario' cssClass='e-outline' onChange={CustomJS.onChangeCampoTestoMaiuscolo} blur={this.onChangeCodiceFiscaleDomiciliatarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <TextBoxComponent id={`partitaIvaDomiciliatarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Partita IVA Domiciliatario' cssClass='e-outline' blur={this.onChangePartitaIvaDomiciliatarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                </div>
                <div className='row' id={`divDomiciliatarioUnipolRiga3-${idSin}`}>
                    <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5">
                        <TextBoxComponent id={`indirizzoDomiciliatarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Indirizzo Domiciliatario' cssClass='e-outline' onChange={CustomJS.onChangeCampoTestoMaiuscolo} blur={this.onChangeIndirizzoDomiciliatarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                        <TextBoxComponent id={`numeroCivicoIndirizzoDomiciliatarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Num. Civico' cssClass='e-outline' onChange={CustomJS.onChangeCampoTestoMaiuscolo} blur={this.onChangeNumeroCivicoIndirizzoDomiciliatarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                        <TextBoxComponent id={`capIndirizzoDomiciliatarioUnipol${idSin}`} floatLabelType='Auto' placeholder='CAP' cssClass='e-outline' blur={this.onChangeCapIndirizzoDomiciliatarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                </div>
                <div className='row' id={`divDomiciliatarioUnipolRiga4-${idSin}`}>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <DropDownListComponent id={`comuneIndirizzoDomiciliatarioUnipol${idSin}`} fields={{ text: 'DESCRIZ', value: 'cod_ele' }} 
                            allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeComuneIndirizzoDomiciliatarioUnipol.bind(this)}
                            placeholder="Comune Domiciliatario" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <DropDownListComponent id={`provinciaIndirizzoDomiciliatarioUnipol${idSin}`} fields={this.ddlFields} 
                            allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeProvinciaIndirizzoDomiciliatarioUnipol.bind(this)}
                            placeholder="Provincia Domiciliatario" floatLabelType='always' popupHeight="350px" cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <DropDownListComponent id={`nazioneIndirizzoDomiciliatarioUnipol${idSin}`} fields={{ text: 'DESCRIZ', value: 'cod_ele' }} 
                            allowFiltering={true} filterType='Contains' showClearButton={true} onChange={this.onChangeNazioneIndirizzoDomiciliatarioUnipol.bind(this)}
                            placeholder="Nazione Domiciliatario" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                </div>
                <div className='row' id={`divDomiciliatarioUnipolRiga5-${idSin}`}>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <TextBoxComponent id={`comuneEsteroIndirizzoDomiciliatarioUnipol${idSin}`} floatLabelType='Auto' placeholder='Comune Estero Domiciliatario' cssClass='e-outline' onChange={CustomJS.onChangeCampoTestoMaiuscolo} blur={this.onChangeComuneEsteroIndirizzoDomiciliatarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                </div>
                <div className='row' id={`divIBANBeneficiarioUnipolRiga1-${idSin}`}>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <TextBoxComponent id={`IBANBeneficiarioUnipol${idSin}`} floatLabelType='Auto' placeholder='IBAN' cssClass='e-outline' onChange={CustomJS.onChangeCampoTestoMaiuscolo} blur={this.onChangeIBANBeneficiarioUnipol.bind(this)}></TextBoxComponent>
                    </div>
                </div>
            </React.Fragment></CallbackWrapper>);
            setTimeout(() => {
                this.toggleBlocking2(false);
            }, 500)
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    cancellaDefinizioneDannoUnipolSAI() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let jsonObject = {
        }
        const QueryExec = `DELETE FROM [10006] WHERE id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(jsonObject), retData => {
            document.getElementById(`divDefinizioneDannoUnipolSAI${idSin}`).innerHTML = '';
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    onFileOpen(rootName, args) {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (args.fileDetails.isFile === true && (args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'JPEG' 
                                     || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'JPG'
                                     || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'PNG'
                                     || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'TIF'
                                     || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'TIFF'
                                     || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'GIF'
                                     || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'JFIF'
                                     || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'HEIC')) {
            args.cancel = true;  
            let filePath = `${rootName}${args.fileDetails.filterPath}${args.fileDetails.name}`;
            filePath = encodeURIComponent(filePath.replaceAll('/','[slash]'));
            this.toggleBlocking();
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/caricaImmagineVideoAWSS3/${filePath}`, '', 'GET',
                '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                    this.lightboxImage = retData.signedURL;
                    this.setState({showLightbox: true});  
                    this.toggleBlocking();    
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking();
            }, true, false);
        }
        if (args.fileDetails.isFile === true && (args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'MP4' 
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'MOV'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'WMV'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'AVI'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'AVCHD'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'FLV')) {
            args.cancel = true;  
            let filePath = `${rootName}${args.fileDetails.filterPath}${args.fileDetails.name}`;
            filePath = encodeURIComponent(filePath.replaceAll('/','[slash]'));
            this.toggleBlocking();
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/caricaImmagineVideoAWSS3/${filePath}`, '', 'GET',
                    '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                let timeout = 0;
                if (this.dialogInstance10.visible === true) {
                    this.dialogInstance10.hide();
                    timeout = 500;
                }
                setTimeout(() => {
                    this.dialogInstance10.header = `<div><span id='dialog-title-${this.dialogInstance10.id}' class='title'>VISUALIZZAZIONE VIDEO</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance10.id}' title='Minimizza la finestra'></span></div>`;    
                    this.dialogInstance10.width = '65%';
                    this.dialogInstance10.height = '70%';
                    this.dialogInstance10.minHeight = '70%';
                    this.dialogInstance10.beforeClose = (args) => {
                        this.setState({ playVideo: false });
                    };
                    this.dialogInstance10.beforeOpen = (args) => {
                        this.setState({ playVideo: true });
                    };
                    this.dialogInstance10.buttons = [];    
                    this.dialogInstance10.open = (args) => {
                        this.onMinimizeDialogOpen(`VISUALIZZAZIONE VIDEO`, '400px', '360', '60%', this.dialogInstance10);
                        const container = document.getElementById('defaultDialogContent7');
                        const root = createRoot(container);                         
                        root.render(<CallbackWrapper callback={() => {
                        }}>{this.videoPlayerRender(retData.signedURL)}</CallbackWrapper>);
                        this.toggleBlocking();
                    };  
                    this.dialogInstance10.show(); 
                }, timeout);
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking();
            }, true, false);
        }
        if (args.fileDetails.isFile === true && args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'PDF') {
            let filePath = `${rootName}${args.fileDetails.filterPath}${args.fileDetails.name}`;
            filePath = encodeURIComponent(filePath.replaceAll('/','[slash]'));
            this.toggleBlocking2(true);
            if (this.dialogInstance8.visible === true)
                this.dialogInstance8.hide();
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/caricaDocumentoAWSS3/${filePath}`, '', 'GET',
                    '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                this.dialogInstance8.header = `<div><span id='dialog-title-${this.dialogInstance8.id}' class='title'>VISUALIZZAZIONE DOCUMENTO PDF</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance8.id}' title='Minimizza la finestra'></span></div>`;    
                this.dialogInstance8.width = '65%';
                this.dialogInstance8.height = '90%';
                this.dialogInstance8.minHeight = '90%';
                this.dialogInstance8.buttons = [];   
                this.dialogInstance8.beforeOpen = (args) => {
                    const container = document.getElementById('defaultDialogContent5');
                    const root = createRoot(container); 
                    root.unmount();
                };
                this.dialogInstance8.open = (args) => {                
                    this.onMinimizeDialogOpen(`VISUALIZZAZIONE DOCUMENTO PDF`, '440px', '240', '90%', this.dialogInstance8);
                    const container = document.getElementById('defaultDialogContent5');
                    const root = createRoot(container);                         
                    root.render(<CallbackWrapper callback={() => {
                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].enableDownload = false;
                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].serviceUrl = `${CustomJS.APIVariousJfishTechURLProd}/api/PdfViewer`;
                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                        //this.PDFViewerInstance.dataBind();  //NON SEMBRA SERVIRE
                        setTimeout(() => {
                            document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].load(`data:application/pdf;base64,${retData.base64Doc}`, null);
                            this.toggleBlocking2(false);
                        }, 250);
                    }}>{this.PDFViewerRender()}</CallbackWrapper>);
                };  
                this.dialogInstance8.show(); 
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking2(false);
            }, true, false);
        }
        if (args.fileDetails.isFile === true && (args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'DOC' 
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'DOCX'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'XLS'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'XLSX'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'XLSM'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'CSV'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'PPS'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'PPSX'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'PPT'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'PPTX'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'RTF'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'TXT')) {
            let filePath = `${rootName}${args.fileDetails.filterPath}${args.fileDetails.name}`;
            filePath = encodeURIComponent(filePath.replaceAll('/','[slash]'));
            this.toggleBlocking2(true);
            let timeout = 0;
            if (this.dialogInstance9.visible === true) {
                this.dialogInstance9.hide();
                timeout = 800;
            }
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/caricaImmagineVideoAWSS3/${filePath}`, '', 'GET',
                    '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                setTimeout(() => {
                    this.dialogInstance9.header = `<div><span id='dialog-title-${this.dialogInstance9.id}' class='title'>VISUALIZZAZIONE DOCUMENTO</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance9.id}' title='Minimizza la finestra'></span></div>`;    
                    this.dialogInstance9.width = '65%';
                    this.dialogInstance9.height = '90%';
                    this.dialogInstance9.minHeight = '90%';
                    this.dialogInstance9.buttons = [];    
                    this.dialogInstance9.open = (args2) => {                
                        this.onMinimizeDialogOpen(`VISUALIZZAZIONE DOCUMENTO`, '400px', '300', '90%', this.dialogInstance9);
                        const container = document.getElementById('defaultDialogContent6');
                        const root = createRoot(container);                         
                        root.render(<CallbackWrapper callback={() => {
                            setTimeout(() => {
                                const URLVisualGoogle = `https://docs.google.com/viewer?url=${encodeURIComponent(retData.signedURL)}&embedded=true`;
                                const URLVisualOffice365 = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(retData.signedURL)}`;
                                const encodedFilePath = encodeURIComponent(`${CustomJS.APIVariousJfishTechURLProd}/${retData.filePath}`);
                                const URLVisualLocale = `http://docs.google.com/viewer?url=${encodedFilePath}&embedded=true`;
                                document.getElementById(`IframeDocumento${idSin}`).src = (args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'TXT' || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'RTF') ? URLVisualGoogle : URLVisualOffice365;
                                this.toggleBlocking2(false);
                            }, 200);    
                        }}>{this.otherDocumentRender()}</CallbackWrapper>);    
                    };    
                    this.dialogInstance9.show(); 
                }, timeout);
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking2(false);
            }, true, false);
        }
        if (args.fileDetails.isFile === true && (args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'ZIP' 
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'RAR')) {
            let filePath = `${rootName}${args.fileDetails.filterPath}${args.fileDetails.name}`;
            filePath = encodeURIComponent(filePath.replaceAll('/','[slash]'));
            this.toggleBlocking();
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/caricaImmagineVideoAWSS3/${filePath}`, '', 'GET',
                    '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                document.getElementById('iframeGenerico').src = retData.signedURL;
                this.toggleBlocking();
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking();
            }, true, false);
        }
    }
    onMenuClick(args) {
        if (args.item.text === "Crea File Foto") {
            const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
            this.arrFilePath = [];
            if ($(`#FileManager${idSin}_cm_open.e-disabled`).length > 0)
                return;
            document.getElementById(`FileManager${idSin}_cm_open`).click();
            this.toggleBlocking2(true);
            setTimeout(() => {
                if (this.arrFilePath.length === 0) {
                    this.toggleBlocking2(false);
                    return;
                }
                else {
                    const dataObj = {
                        ArrayFilePath: this.arrFilePath
                    };
                    let timeout = 0;
                    if (this.dialogInstance11.visible === true) {
                        this.dialogInstance11.hide();
                        timeout = 800;
                    }
                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/caricaImmaginiAWSS3SlideShow`, JSON.stringify(dataObj), 'POST',
                        'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => {
                            var arrayFoto = [];
                            let arrayDicitureNuove = [];
                            var count = 0;
                            retData.arrSignedURL.forEach(elm => {
                                arrayFoto.push({
                                    Id: count,
                                    Gruppo: count % 2 === 0 ? "G1" : "G2",
                                    Descrizione: "",
                                    DescrizioneNuova: "",
                                    Foto: elm
                                })
                                count++;
                            });
                            this.dataFotoKanban = arrayFoto;
                            this.argsRowKanban = []
                            this.idCardKanban = 0;
                            setTimeout(() => {
                                this.dialogInstance11.header = `<div><span id='dialog-title-${this.dialogInstance11.id}' class='title'>ANTEPRIMA CREAZIONE FILE FOTO</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance11.id}' title='Minimizza la finestra'></span></div>`;
                                this.dialogInstance11.width = '65%';
                                this.dialogInstance11.height = '90%';
                                this.dialogInstance11.minHeight = '90%';
                                this.dialogInstance11.beforeClose = undefined;
                                this.dialogInstance11.close = undefined;
                                this.dialogInstance11.beforeOpen = (args) => {
                                    const container = document.getElementById('defaultDialogContent8');
                                    const root = createRoot(container);
                                    root.unmount();
                                }
                                this.dialogInstance11.open = (args) => {
                                    this.onMinimizeDialogOpen(`ANTEPRIMA CREAZIONE FILE FOTO`, '460px', '240', '90%', this.dialogInstance11);
                                    const container = document.getElementById('defaultDialogContent8');
                                    const root = createRoot(container);
                                    root.render(<CallbackWrapper callback={() => {
                                        this.toggleBlocking2(false);
                                    }}>{this.kanbanRender()}</CallbackWrapper>);
                                }
                                this.dialogInstance11.buttons = [
                                    {
                                        click: () => {
                                            var esegui = true;
                                            this.argsRowKanban.forEach(row => {
                                                var descrizione = CustomJS.isNullEmpty(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value?.trim()) ? document.getElementById(`descrizioneK${row.id}_${idSin}`).ej2_instances[0].text : document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value
                                                if (CustomJS.isNullEmpty(descrizione)) {
                                                    esegui = false;
                                                }
                                            })
                                            if (esegui === true && this.argsRowKanban.length > 0) {
                                                //------METODO 1 DIVISI ORIZZONTALI E VERTICALI---------
                                                // var arrayAct = []
                                                // this.argsRowKanban.forEach(row => {
                                                //     var descrizione = CustomJS.isNullEmpty(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value?.trim()) ? document.getElementById(`descrizioneK${row.id}_${idSin}`).ej2_instances[0].text : document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value
                                                //     arrayAct.push({
                                                //         url: row.foto,
                                                //         dicitura: CustomJS.isNullEmpty(descrizione) ? '' : descrizione
                                                //     })
                                                // })
                                                //--------METODO 2 DISTRIBUZIONE KANBAN----------
                                                var g1 = document.querySelector(`#KanbanFileFoto${idSin} > div.e-kanban-content > div > table > tbody > tr [data-key="G1"] .e-card-wrapper`);
                                                var g2 = document.querySelector(`#KanbanFileFoto${idSin} > div.e-kanban-content > div > table > tbody > tr [data-key="G2"] .e-card-wrapper`);
                                                var childG1 = g1.childNodes;
                                                var ordineColonnaG1 = [];
                                                childG1.forEach(elm => {
                                                    ordineColonnaG1.push(elm.id);
                                                });
                                                var childG2 = g2.childNodes;
                                                var ordineColonnaG2 = [];
                                                childG2.forEach(elm => {
                                                    ordineColonnaG2.push(elm.id);
                                                });
                                                var arraySinistra = []
                                                ordineColonnaG1.forEach(id => {
                                                    this.argsRowKanban.forEach(row => {
                                                        if (id === row.idBox) {
                                                            var descrizione = CustomJS.isNullEmpty(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value?.trim()) ? document.getElementById(`descrizioneK${row.id}_${idSin}`).ej2_instances[0].text : document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value
                                                            arraySinistra.push({
                                                                url: row.foto,
                                                                dicitura: CustomJS.isNullEmpty(descrizione) ? '' : descrizione
                                                            });
                                                            if (!CustomJS.isNullEmpty(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value?.trim()))
                                                                arrayDicitureNuove.push(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value);
                                                        }
                                                    })
                                                })
                                                var arrayDestra = []
                                                ordineColonnaG2.forEach(id => {
                                                    this.argsRowKanban.forEach(row => {
                                                        if (id === row.idBox) {
                                                            var descrizione = CustomJS.isNullEmpty(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value?.trim()) ? document.getElementById(`descrizioneK${row.id}_${idSin}`).ej2_instances[0].text : document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value
                                                            arrayDestra.push({
                                                                url: row.foto,
                                                                dicitura: CustomJS.isNullEmpty(descrizione) ? '' : descrizione
                                                            });
                                                            if (!CustomJS.isNullEmpty(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value?.trim()))
                                                                arrayDicitureNuove.push(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value);
                                                        }
                                                    })
                                                })
                                                var numRigheTot = arraySinistra.length > arrayDestra.length ? arraySinistra.length : arrayDestra.length;
                                                var jsonObject = {
                                                    arraySinistra: arraySinistra,
                                                    arrayDestra: arrayDestra,
                                                    numRigheTot: numRigheTot
                                                }
                                                this.toggleBlocking2(true);
                                                CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/WordCreator/CreaFileFotoACT/${idSin}`, { datiFileFotoJSON: JSON.stringify(jsonObject) }, 'POST',
                                                    { "Authorization": CustomJS.tokenAuthAPIVarious }, retData2 => {
                                                    this.dialogInstance11.hide();
                                                    this.dialogInstance7.hide();
                                                    retData2.pathTempFileDOCX = retData2.pathTempFileDOCX.replaceAll('/','[slash]');
                                                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumentoAWSS3/${encodeURIComponent(retData2.pathTempFileDOCX)}`, '', 'GET',
                                                        '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData3 => { 
                                                            this.dialogInstance5.header = 'VISUALIZZAZIONE DOCUMENTO';    
                                                            this.dialogInstance5.width = '65%';
                                                            this.dialogInstance5.height = '90%';
                                                            this.dialogInstance5.minHeight = '95%';
                                                            this.dialogInstance5.buttons = [];  
                                                            this.dialogInstance5.beforeOpen = (args) => {
                                                                const container = document.getElementById('defaultDialogContent2');
                                                                const root = createRoot(container);   
                                                                root.unmount();
                                                            };   
                                                            this.dialogInstance5.open = (args) => {
                                                                const container = document.getElementById('defaultDialogContent2');
                                                                const root = createRoot(container);                         
                                                                root.render(<CallbackWrapper callback={() => {
                                                                    setTimeout(() => {
                                                                        const URLVisualGoogle = `https://docs.google.com/gview?url=${encodeURIComponent(retData3.signedURL)}&embedded=true`;
                                                                        const URLVisualOffice365 = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(retData3.signedURL)}`;
                                                                        const encodedFilePath = encodeURIComponent(`${CustomJS.APIVariousJfishTechURLProd}/${retData3.filePath}`);
                                                                        const URLVisualLocale = `http://docs.google.com/viewer?url=${encodedFilePath}&embedded=true`;
                                                                        document.getElementById(`IframeDocumento${idSin}`).src = URLVisualOffice365;
                                                                        this.toggleBlocking2(false);
                                                                    }, 200);
                                                                }}>{this.otherDocumentRender()}</CallbackWrapper>);   
                                                            };    
                                                            this.dialogInstance5.show(); 
                                                            toast.success(`Documento con le foto creato e allegato correttamente per il sinistro ${idSin}!!`, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 7000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            });        
                                                            arrayDicitureNuove.forEach(elm => {
                                                                const dataObj = {
                                                                    DescrDicitura : elm            
                                                                };
                                                                const QueryExec = 'INSERT INTO [09008] (descr_dicitura) SELECT @descr_dicitura WHERE NOT EXISTS (SELECT 1 FROM [09008] WHERE descr_dicitura LIKE CONCAT('%', @descr_dicitura, '%') ';        
                                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,           
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    });
                                                                }, true, true);                                        
                                                            });    
                                                            setTimeout(() => {
                                                                const tabella = '09008';
                                                                const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
                                                                CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData4 => {
                                                                    if (retData4.length > 0) {
                                                                        let emptyObj = {};
                                                                        Object.keys(retData4[0]).forEach(e => emptyObj[e] = '');
                                                                        retData4.unshift(emptyObj);
                                                                    }
                                                                    sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData4));
                                                                    this.dsDiciture = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella09008')))?.map(elm => { return { VALUE: elm.id_dicitura.toString(), TEXT: elm.descr_dicitura } });
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,           
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true}
                                                                    );
                                                                });           
                                                            }, 1500);
                                                    }, error => {
                                                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),                    
                                                            position: "bottom-right",
                                                            autoClose: 15000,
                                                            hideProgressBar: false,            
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                        this.toggleBlocking2(false);
                                                    }, true, false); 
                                                    retData2.pathTempFilePDF = retData2.pathTempFilePDF.replaceAll('/','[slash]');
                                                    const pathFileAWS = retData2.pathTempFilePDF.substring(0, retData2.pathTempFilePDF.indexOf('[slash]')) + '[slash]Da Chiudere[slash]' + retData2.pathTempFilePDF.substring(retData2.pathTempFilePDF.indexOf('[slash]')+7);
                                                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumentoAWSS3/${encodeURIComponent(retData2.pathTempFilePDF)}/${encodeURIComponent(pathFileAWS)}`, '', 'GET',
                                                        '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData3 => {
                                                    }, error => {
                                                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),                    
                                                            position: "bottom-right",
                                                            autoClose: 15000,
                                                            hideProgressBar: false,            
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    }, true, false); 
                                                }, error => {
                                                    let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 15000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    });
                                                    this.toggleBlocking2(false);
                                                }, true, false);
                                            }
                                            else {
                                                toast.warn("Attenzione!! Nessun elemento presente oppure è necessario inserire tutte le diciture per effettuare la creazione del documento con le foto...", {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-left",
                                                    autoClose: 7000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                });
                                            }        
                                        },
                                        buttonModel: {
                                            content: 'CREA E ALLEGA FILE FOTO',
                                            cssClass: 'e-primary',
                                            isPrimary: true
                                        }
                                    },
                                    {
                                        click: () => {
                                            this.dialogInstance11.hide();
                                        },
                                        buttonModel: {
                                            content: 'ANNULLA',
                                        }
                                    }
                                ];
                                this.dialogInstance11.show();
                            }, timeout);
                        }, error => {
                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            this.toggleBlocking();
                        }, true, false);
                }
            }, 3000);
        }
        if (args.item.text === "Invia interlocutoria UNIPOLSAI") {
            const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
            let arrFilePath = [];
            document.getElementById(`FileManager${idSin}`).ej2_instances[0].getSelectedFiles().forEach(elm => {
                arrFilePath.push(`${this.tabInstance.items[this.tabInstance.selectedItem].id}${elm.filterPath}${elm.name}`);
            });
            let filePath = arrFilePath[0];
            filePath = encodeURIComponent(filePath.replaceAll('/','[slash]'));
            let timeout = 0;
            if (this.dialogInstance6.visible === true) {
                this.dialogInstance6.hide();
                timeout = 1000;
            }
            this.toggleBlocking2(true);
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/caricaDocumentoAWSS3/${filePath}`, '', 'GET',
                '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                    this.toggleBlocking2(false);
                    const container = document.getElementById('defaultDialogContent3');
                    const root = createRoot(container);                         
                    root.render(<CallbackWrapper callback={() => {
                        setTimeout(() => {
                            this.dialogInstance6.header = `<div><span id='dialog-title-${this.dialogInstance6.id}' class='title'>INVIO INTERLOCUTORIA DOCUMENTALE AD UNIPOLSAI</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance6.id}' title='Minimizza la finestra'></span></div>`;
                            this.dialogInstance6.width = '50%';
                            this.dialogInstance6.height = '55%';
                            this.dialogInstance6.minHeight = '55%';
                            this.dialogInstance6.open = (args) => { 
                                this.onMinimizeDialogOpen('INVIO INTERLOCUTORIA DOCUMENTALE AD UNIPOLSAI', '650px', '95', '70%', this.dialogInstance6);
                                setTimeout(() => {
                                    this.textBoxInstance6.addAttributes({ rows: 15 });        
                                    document.getElementById('min-btn-defaultDialog7').click();     
                                }, 100);
                            }                              
                            this.dialogInstance6.beforeClose = undefined;
                            this.dialogInstance6.buttons = [{ click: () => {
                                if (CustomJS.isNullEmpty(document.getElementById('testoInterlocutoria').ej2_instances[0].value?.trim()))
                                    return;
                                if (document.getElementById('min-btn-defaultDialog6').classList.contains('fa-window-restore') === false)
                                    document.getElementById('min-btn-defaultDialog6').click();
                                this.dialogInstance3.header = 'Attenzione!!';
                                this.dialogInstance3.content = `Verrà inviata una comunicazione ad <b>UnipolSAI</b> di tipo <b>Interlocutoria Documentale</b>, contenente il testo inserito ed il documento allegato. Confermare l'operazione?`;
                                this.dialogInstance3.width = '25%';
                                this.dialogInstance3.height = '20%';
                                this.dialogInstance3.minHeight = '20%';
                                this.dialogInstance3.buttons = [
                                    { click: () => { 
                                        this.dialogInstance3.hide();
                                        this.toggleBlocking2(true);
                                        this.invioInterlocutoriaUnipolSAI(document.getElementById(`idIncarico${idSin}`).value, document.getElementById('pathAllegatoInterlocutoria').value, 'InterlocutoriaDocumentale');
                                    }, 
                                    buttonModel: { 
                                        content: 'CONFERMA', 
                                        cssClass: 'e-primary',
                                        isPrimary: true 
                                    }
                                    },
                                    {
                                        click: () => {
                                            this.dialogInstance3.hide();
                                            if (document.getElementById('min-btn-defaultDialog6').classList.contains('fa-window-restore') === true)
                                                document.getElementById('min-btn-defaultDialog6').click();
                                        },
                                        buttonModel: {
                                            content: 'ANNULLA',
                                        }
                                    }
                                ];
                                this.dialogInstance3.show();            
                            },
                            buttonModel: { content: 'INVIA INTERLOCUTORIA', cssClass: 'e-success', isPrimary: true } },
                            { 
                                click: () => {         
                                    if (document.getElementById('min-btn-defaultDialog7').classList.contains('fa-window-restore') === true)
                                        document.getElementById('min-btn-defaultDialog7').click(); 
                                        this.dialogInstance6.hide(); 
                                }, buttonModel: { content: 'ANNULLA' } 
                            }];
                            this.dialogInstance6.show();     
                        }, timeout)
                    }}>{this.creaInterlocutoriaDocumentale(arrFilePath[0], retData.base64Doc)}</CallbackWrapper>);
            }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
            }, true, false);
        }
    }
    creaCardPersonalizzate(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (!CustomJS.isNullEmpty(args)) {
            if (args.requestType === "cardRemoved") {
                let elmEliminati = []
                for (let numCard = 0; numCard < this.idCardKanban; numCard++) {
                    if (document.getElementById(`boxCardK${numCard}_${idSin}`)) {
                    }
                    else {
                        elmEliminati.push(numCard)
                    }
                }
                elmEliminati.forEach(elimin => {
                    this.argsRowKanban = this.argsRowKanban.filter(function (obj) {
                        return obj.id !== elimin
                    });
                })
            }
        }

        for (let numCard = 0; numCard < this.idCardKanban; numCard++) {
            if (document.getElementById(`boxCardK${numCard}_${idSin}`)) {
                const container2 = document.getElementById(`boxCardK${numCard}_${idSin}`);
                const root2 = createRoot(container2);
                this.argsRowKanban.forEach(row => {
                    if (row.idBox === `boxCardK${numCard}_${idSin}`) {
                        root2.render(<CallbackWrapper callback={() => {
                            if (!CustomJS.isNullEmpty(row.descrizione)) {
                            }
                            document.getElementById(`descrizioneK${numCard}_${idSin}`).ej2_instances[0].value = row.descrizione;
                            document.getElementById(`fotoK${numCard}_${idSin}`).src = row.foto;
                            document.getElementById(`nuovaDescrizioneK${numCard}_${idSin}`).ej2_instances[0].value = row.descrizioneNuova;
                        }}>{this.cardTemplate(numCard, row)}</CallbackWrapper>);
                    }
                })
            }
        }
    }
    trasportaValueInRowKanban(row, descr, args) {
        this.argsRowKanban.forEach(row2 => {
            if (row2.idBox === row.idBox) {
                if (descr === 'nuova')
                    row2.descrizioneNuova = args.value
                else
                    row2.descrizione = args.value
            }
        })
    }
    cardTemplate(num, row) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        return <>
            <div style={{ textAlign: 'center' }}>
                <img id={`fotoK${num}_${idSin}`} src={ImmagineImgVarie} style={{ width: '150x', height: '150px' }} alt='' />
                <DropDownListComponent onChange={this.trasportaValueInRowKanban.bind(this, row, '')} id={`descrizioneK${num}_${idSin}`} fields={this.ddlFields} dataSource={this.dsDiciture}
                    allowFiltering={false} filterType='Contains' showClearButton={false}
                    placeholder="" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'>
                </DropDownListComponent>
                <TextBoxComponent onChange={this.trasportaValueInRowKanban.bind(this, row, 'nuova')} id={`nuovaDescrizioneK${num}_${idSin}`} key={this.childKey} floatLabelType='Auto' placeholder='Inserisci nuova descrizione' cssClass='e-outline'></TextBoxComponent>
            </div>
        </>
    }
    cardRendered(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        //caricamento dati
        if (this.idCardKanban < this.dataFotoKanban.length) {
            var num = this.idCardKanban;
            args.element.id = `boxCardK${num}_${idSin}`;
            this.idCardKanban++;
            this.argsRowKanban.push({
                foto: args.data.Foto,
                descrizione: args.data.Descrizione,
                descrizioneNuova: args.data.DescrizioneNuova,
                idBox: `boxCardK${num}_${idSin}`,
                id: num
            })
        }
        else {
            //spostamento
            this.argsRowKanban.forEach(row => {
                if (!document.getElementById(row.idBox)) {
                    if (CustomJS.isNullEmpty(args.element.id)) {
                        args.element.id = row.idBox
                    }
                }
            })
        }
    }
    kanbanRender(arrSignedURL) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        ++this.childKey;
        return (<>
            <KanbanComponent id={`KanbanFileFoto${idSin}`} cssClass="kanban-overview" dataSource={this.dataFotoKanban}
                keyField="Gruppo" dialogOpen={(args) => { args.cancel = true; }} key={this.childkey}
                cardSettings={{ contentField: "Descrizione", selectionType: 'Multiple', headerField: "Id" }}
                cardRendered={this.cardRendered.bind(this)} dataBound={this.creaCardPersonalizzate.bind(this)}>
                <ColumnsDirectiveKanban >
                    <ColumnDirectiveKanban headerText="Lato Sinistro" keyField="G1" allowToggle={true} />
                    <ColumnDirectiveKanban headerText="Lato Destro" keyField="G2" allowToggle={true} />
                </ColumnsDirectiveKanban>
            </KanbanComponent>
        </>);
    }
    onMenuOpen(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        for (const i in args.items) {
            if (args.items[i].id === this.FMInstance.element.id + '_cm_crea file foto') {
                args.items[i].iconCss = 'fas fa-images';
            }
            if (args.items[i].id === this.FMInstance.element.id + '_cm_invia interlocutoria unipolsai') {
                args.items[i].iconCss = 'fas fa-book';
            }
        }
        setTimeout(() => {
            let alcuniNoPDF = document.getElementById(`FileManager${idSin}`).ej2_instances[0].getSelectedFiles().filter(elm => { return elm.name.toUpperCase().indexOf("PDF") === -1; });
            if (document.getElementById(`gruppo${idSin}`).ej2_instances[0].value !== CustomJS.codiceCompagniaUnipolSAI 
                || alcuniNoPDF.length > 0 || CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value))
                $(`[id='FileManager${idSin}_cm_invia interlocutoria unipolsai']`).addClass('elm-disabled');
            else
                $(`[id='FileManager${idSin}_cm_invia interlocutoria unipolsai']`).removeClass('elm-disabled');    
        }, 50)
    }
    onBeforeDownload(rootName, args) {
        let includeCustomAttribute = args.data; 
        includeCustomAttribute.rootName = rootName; 
        args.data = includeCustomAttribute; 
    }
    onBeforeImageLoad(args) {
        const rootFileName = `${this.tabInstance.items[this.tabInstance.selectedItem].id}`; 
        args.imageUrl = `${args.imageUrl}&rootName=${rootFileName}`; 
    }
    onBeforeImageLoad2(args) {
        const rootFileName = CustomJS.CartellaProdCGAPolizzeACT; 
        args.imageUrl = `${args.imageUrl}&rootName=${rootFileName}`; 
    }
    onBeforeImageLoad3(args) {
        const rootFileName = CustomJS.CartellaProdDocumentazioneACT; 
        args.imageUrl = `${args.imageUrl}&rootName=${rootFileName}`; 
    }
    onBeforeSend(rootName, args) {
        let includeCustomAttribute = JSON.parse(args.ajaxSettings.data); 
        if (args.action !== 'Upload') 
            includeCustomAttribute.RootName = rootName; 
        else 
            includeCustomAttribute.push({ rootName: rootName });         
        args.ajaxSettings.data = JSON.stringify(includeCustomAttribute); 
        args.ajaxSettings.beforeSend = function (args2) {
            args2.httpRequest.setRequestHeader('Authorization', CustomJS.tokenAuthAPIVarious); 
        }
    }
    onFileLoad(args) {
        if (args.fileDetails.isFile === true && (args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'JPEG' 
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'JPG'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'PNG'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'GIF'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'JFIF')) {
            let filePath = `${this.tabInstance.items[this.tabInstance.selectedItem].id}${args.fileDetails.filterPath}${args.fileDetails.name}`;
            this.arrFilePath.push(filePath);
        }
    }
    onFileLoad2(args) {
        if (args.fileDetails.isFile === true && (args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'JPEG' 
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'JPG'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'PNG'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'GIF'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'JFIF')) {
            let filePath = `${CustomJS.CartellaProdCGAPolizzeACT}${args.fileDetails.filterPath}${args.fileDetails.name}`;
            this.arrFilePath.push(filePath);
        }
    }
    onFileLoad3(args) {
        if (args.fileDetails.isFile === true && (args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'JPEG' 
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'JPG'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'PNG'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'GIF'
            || args.fileDetails.name.substring(args.fileDetails.name.lastIndexOf('.')+1).toUpperCase() === 'JFIF')) {
            let filePath = `${CustomJS.CartellaProdDocumentazioneACT}${args.fileDetails.filterPath}${args.fileDetails.name}`;
            this.arrFilePath.push(filePath);
        }
    }
    onSuccess(args) {
        if (args.action === 'read')
            this.arrFilePath = [];
    }
    onSuccess2(args) {
        if (args.action === 'read')
            this.arrFilePath = [];
    }
    onSuccess3(args) {
        if (args.action === 'read')
            this.arrFilePath = [];
    }
    btnGalleriaImmagini(args){
        if(this.arrFilePath.length === 0){
            return;
        }  
        else {
            const dataObj = {
                ArrayFilePath : this.arrFilePath
            };
            this.toggleBlocking();  
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/caricaImmaginiAWSS3SlideShow`, JSON.stringify(dataObj), 'POST',
                'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                    this.lightboxImages = retData.arrSignedURL;
                    this.setState({showLightbox2: true});  
                    this.toggleBlocking();    
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking();
            }, true, false);
        }
    }
    btnCreaFileFoto(args) {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (this.arrFilePath.length === 0) {
            return;
        }
        else {
            const dataObj = {
                ArrayFilePath: this.arrFilePath
            };
            let timeout = 0;
            if (this.dialogInstance11.visible === true) {
                this.dialogInstance11.hide();
                timeout = 800;
            }
            this.toggleBlocking2(true);
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/caricaImmaginiAWSS3SlideShow`, JSON.stringify(dataObj), 'POST',
                'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => {
                    var arrayFoto = [];
                    let arrayDicitureNuove = [];
                    var count = 0;
                    retData.arrSignedURL.forEach(elm => {
                        arrayFoto.push({
                            Id: count,
                            Gruppo: count % 2 === 0 ? "G1" : "G2",
                            Descrizione: '',
                            DescrizioneNuova: '',
                            Foto: elm
                        })
                        count++;
                    });
                    this.dataFotoKanban = arrayFoto;
                    this.argsRowKanban = []
                    this.idCardKanban = 0;
                    setTimeout(() => {
                        this.dialogInstance11.header = `<div><span id='dialog-title-${this.dialogInstance11.id}' class='title'>ANTEPRIMA CREAZIONE FILE FOTO</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance11.id}' title='Minimizza la finestra'></span></div>`;
                        this.dialogInstance11.width = '65%';
                        this.dialogInstance11.height = '90%';
                        this.dialogInstance11.minHeight = '90%';
                        this.dialogInstance11.beforeClose = undefined;
                        this.dialogInstance11.close = undefined;
                        this.dialogInstance11.beforeOpen = (args) => {
                            const container = document.getElementById('defaultDialogContent8');
                            const root = createRoot(container);
                            root.unmount();
                        }
                        this.dialogInstance11.open = (args) => {
                            this.onMinimizeDialogOpen(`ANTEPRIMA CREAZIONE FILE FOTO`, '460px', '240', '90%', this.dialogInstance11);
                            const container = document.getElementById('defaultDialogContent8');
                            const root = createRoot(container);
                            root.render(<CallbackWrapper callback={() => {
                                this.toggleBlocking2(false);
                            }}>{this.kanbanRender()}</CallbackWrapper>);
                        }
                        this.dialogInstance11.buttons = [
                            {
                                click: () => {
                                    var esegui = true;
                                    this.argsRowKanban.forEach(row => {
                                        var descrizione = CustomJS.isNullEmpty(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value) ? document.getElementById(`descrizioneK${row.id}_${idSin}`).ej2_instances[0].text : document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value
                                        if (CustomJS.isNullEmpty(descrizione)) {
                                            esegui = false;
                                        }
                                    })
                                    if (esegui === true && this.argsRowKanban.length > 0) {
                                        //------METODO 1 DIVISI ORIZZONTALI E VERTICALI---------
                                        // var arrayAct = []
                                        // this.argsRowKanban.forEach(row => {
                                        //     var descrizione = CustomJS.isNullEmpty(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value) ? document.getElementById(`descrizioneK${row.id}_${idSin}`).ej2_instances[0].text : document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value
                                        //     arrayAct.push({
                                        //         url: row.foto,
                                        //         dicitura: CustomJS.isNullEmpty(descrizione) ? '' : descrizione
                                        //     })
                                        // })
                                        //--------METODO 2 DISTRIBUZIONE KANBAN----------
                                        var g1 = document.querySelector(`#KanbanFileFoto${idSin} > div.e-kanban-content > div > table > tbody > tr [data-key="G1"] .e-card-wrapper`);
                                        var g2 = document.querySelector(`#KanbanFileFoto${idSin} > div.e-kanban-content > div > table > tbody > tr [data-key="G2"] .e-card-wrapper`);
                                        var childG1 = g1.childNodes;
                                        var ordineColonnaG1 = []
                                        for (var i = 0; i < childG1.length; i++) {
                                            ordineColonnaG1.push(childG1[i].id);
                                        }
                                        var childG2 = g2.childNodes;
                                        var ordineColonnaG2 = []
                                        for (var i = 0; i < childG2.length; i++) {
                                            ordineColonnaG2.push(childG2[i].id);
                                        }
                                        var arraySinistra = []
                                        ordineColonnaG1.forEach(id => {
                                            this.argsRowKanban.forEach(row => {
                                                if (id === row.idBox) {
                                                    var descrizione = CustomJS.isNullEmpty(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value) ? document.getElementById(`descrizioneK${row.id}_${idSin}`).ej2_instances[0].text : document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value
                                                    arraySinistra.push({
                                                        url: row.foto,
                                                        dicitura: CustomJS.isNullEmpty(descrizione) ? '' : descrizione
                                                    });
                                                    if (!CustomJS.isNullEmpty(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value?.trim()))
                                                        arrayDicitureNuove.push(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value);
                                                }
                                            })
                                        })
                                        var arrayDestra = []
                                        ordineColonnaG2.forEach(id => {
                                            this.argsRowKanban.forEach(row => {
                                                if (id === row.idBox) {
                                                    var descrizione = CustomJS.isNullEmpty(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value) ? document.getElementById(`descrizioneK${row.id}_${idSin}`).ej2_instances[0].text : document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value
                                                    arrayDestra.push({
                                                        url: row.foto,
                                                        dicitura: CustomJS.isNullEmpty(descrizione) ? '' : descrizione
                                                    });
                                                    if (!CustomJS.isNullEmpty(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value?.trim()))
                                                        arrayDicitureNuove.push(document.getElementById(`nuovaDescrizioneK${row.id}_${idSin}`).ej2_instances[0].value);
                                                }
                                            })
                                        })
                                        var numRigheTot = arraySinistra.length > arrayDestra.length ? arraySinistra.length : arrayDestra.length;
                                        var jsonObject = {
                                            arraySinistra: arraySinistra,
                                            arrayDestra: arrayDestra,
                                            numRigheTot: numRigheTot
                                        }
                                        this.toggleBlocking2(true);
                                        CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/WordCreator/CreaFileFotoACT/${idSin}`, { datiFileFotoJSON: JSON.stringify(jsonObject) }, 'POST',
                                            { "Authorization": CustomJS.tokenAuthAPIVarious }, retData2 => {
                                            this.dialogInstance11.hide();
                                            this.dialogInstance7.hide();
                                            retData2.pathTempFileDOCX = retData2.pathTempFileDOCX.replaceAll('/','[slash]');
                                            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumentoAWSS3/${encodeURIComponent(retData2.pathTempFileDOCX)}`, '', 'GET',
                                                '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData3 => { 
                                                    this.dialogInstance5.header = 'VISUALIZZAZIONE DOCUMENTO';    
                                                    this.dialogInstance5.width = '65%';
                                                    this.dialogInstance5.height = '90%';
                                                    this.dialogInstance5.minHeight = '95%';
                                                    this.dialogInstance5.buttons = [];  
                                                    this.dialogInstance5.beforeOpen = (args) => {
                                                        const container = document.getElementById('defaultDialogContent2');
                                                        const root = createRoot(container);   
                                                        root.unmount();
                                                    };   
                                                    this.dialogInstance5.open = (args) => {
                                                        const container = document.getElementById('defaultDialogContent2');
                                                        const root = createRoot(container);                         
                                                        root.render(<CallbackWrapper callback={() => {
                                                            setTimeout(() => {
                                                                const URLVisualGoogle = `https://docs.google.com/gview?url=${encodeURIComponent(retData3.signedURL)}&embedded=true`;
                                                                const URLVisualOffice365 = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(retData3.signedURL)}`;
                                                                const encodedFilePath = encodeURIComponent(`${CustomJS.APIVariousJfishTechURLProd}/${retData3.filePath}`);
                                                                const URLVisualLocale = `http://docs.google.com/viewer?url=${encodedFilePath}&embedded=true`;
                                                                document.getElementById(`IframeDocumento${idSin}`).src = URLVisualOffice365;
                                                                this.toggleBlocking2(false);
                                                            }, 200);
                                                        }}>{this.otherDocumentRender()}</CallbackWrapper>);   
                                                    };    
                                                    this.dialogInstance5.show(); 
                                                    toast.success(`Documento con le foto creato e allegato correttamente per il sinistro ${idSin}!!`, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 15000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    });
                                                    arrayDicitureNuove.forEach(elm => {
                                                        const dataObj = {
                                                            DescrDicitura : elm            
                                                        };
                                                        const QueryExec = 'INSERT INTO [09008] (descr_dicitura) SELECT @descr_dicitura WHERE NOT EXISTS (SELECT 1 FROM [09008] WHERE descr_dicitura LIKE CONCAT('%', @descr_dicitura, '%') ';        
                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,           
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true}
                                                            );
                                                        }, true, true);                                        
                                                    });
                                                    setTimeout(() => {
                                                        const tabella = '09008';
                                                        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
                                                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData4 => {
                                                            if (retData4.length > 0) {
                                                                let emptyObj = {};
                                                                Object.keys(retData4[0]).forEach(e => emptyObj[e] = '');
                                                                retData4.unshift(emptyObj);
                                                            }
                                                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData4));
                                                            this.dsDiciture = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella09008')))?.map(elm => { return { VALUE: elm.id_dicitura.toString(), TEXT: elm.descr_dicitura } });
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,           
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true}
                                                            );
                                                        });           
                                                    }, 1500);
                                            }, error => {
                                                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),                    
                                                    position: "bottom-right",
                                                    autoClose: 15000,
                                                    hideProgressBar: false,            
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                });
                                                this.toggleBlocking2(false);
                                            }, true, false);      
                                            retData2.pathTempFilePDF = retData2.pathTempFilePDF.replaceAll('/','[slash]');
                                            const pathFileAWS = retData2.pathTempFilePDF.substring(0, retData2.pathTempFilePDF.indexOf('[slash]')) + '[slash]Da Chiudere[slash]' + retData2.pathTempFilePDF.substring(retData2.pathTempFilePDF.indexOf('[slash]')+7);
                                            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumentoAWSS3/${encodeURIComponent(retData2.pathTempFilePDF)}/${encodeURIComponent(pathFileAWS)}`, '', 'GET',
                                                '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData3 => {
                                            }, error => {
                                                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),                    
                                                    position: "bottom-right",
                                                    autoClose: 15000,
                                                    hideProgressBar: false,            
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                });
                                            }, true, false); 
                                        }, error => {
                                            let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 15000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            });
                                            this.toggleBlocking2(false);
                                        }, true, false);
                                    }
                                    else {
                                        toast.warn("Attenzione!! Nessun elemento presente oppure è necessario inserire tutte le diciture per effettuare la creazione del documento con le foto...", {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-left",
                                            autoClose: 7000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });
                                    }
                                },
                                buttonModel: {
                                    content: 'CREA E ALLEGA FILE FOTO',
                                    cssClass: 'e-primary',
                                    isPrimary: true
                                }
                            },
                            {
                                click: () => {
                                    this.dialogInstance11.hide();
                                },
                                buttonModel: {
                                    content: 'ANNULLA',
                                }
                            }
                        ];
                        this.dialogInstance11.show();
                    }, timeout);
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking();
                }, true, false);
        }
    }
    fileManagerRender() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        ++this.childKey;
        return(<React.Fragment>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">
                                        <div className='row'>
                                            <div className="col-xs-3 offset-xs-6 col-sm-3 offset-sm-6 col-lg-2 offset-lg-7 col-md-2 offest-md-7">
                                                <ButtonComponent id={`btnCreazioneFileFoto${idSin}`} title='Crea File Foto' onClick={this.btnCreaFileFoto.bind(this)} cssClass='e-info'>CREA FILE FOTO</ButtonComponent>
                                            </div>
                                            <div className="col-xs-3 col-sm-3 col-lg-2 col-md-2">
                                                <ButtonComponent id={`btnGalleriaImmagini${idSin}`} title='Galleria Immagini' onClick={this.btnGalleriaImmagini.bind(this)} cssClass='e-info'>GALLERIA IMMAGINI</ButtonComponent>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-10 offset-xs-1 col-sm-10 offset-sm-1 col-lg-10 offset-lg-1 col-md-10 offest-md-1">
                                                <FileManagerComponent id={`FileManager${idSin}`} ref={fmObj => this.FMInstance = fmObj} key={this.childKey} height='680px' ajaxSettings={{
                                                    url: `${CustomJS.APIVariousJfishTechURLProd}/api/AmazonS3ProviderACT/AmazonS3FileOperations`,
                                                    getImageUrl: `${CustomJS.APIVariousJfishTechURLProd}/api/AmazonS3ProviderACT/AmazonS3GetImage`,
                                                    uploadUrl: `${CustomJS.APIVariousJfishTechURLProd}/api/AmazonS3ProviderACT/AmazonS3Upload`,
                                                    downloadUrl: `${CustomJS.APIVariousJfishTechURLProd}/api/AmazonS3ProviderACT/AmazonS3Download`                                                    
                                                    }} contextMenuSettings={{
                                                        file: ['Open', '|', 'Cut', 'Copy', '|', 'Delete', 'Download', 'Rename', '|', 'Invia interlocutoria UNIPOLSAI', '|', 'Details'],
                                                        folder: ['Open', '|', 'Cut', 'Copy', 'Paste', '|', 'Delete', 'Rename', 'Download', '|', 'Crea File Foto', '|', 'Details'],
                                                    }} menuClick={this.onMenuClick.bind(this)} menuOpen={this.onMenuOpen.bind(this)} fileOpen={this.onFileOpen.bind(this, idSin)} view={"Details"} beforeSend={this.onBeforeSend.bind(this, idSin)}
                                                        searchSettings={{ allowSearchOnTyping: false }} beforeDownload={this.onBeforeDownload.bind(this, idSin)}
                                                        beforeImageLoad={this.onBeforeImageLoad.bind(this)} allowDragAndDrop={true} fileLoad={this.onFileLoad.bind(this)}
                                                        success={this.onSuccess.bind(this)} created={this.onCreatedFileManager.bind(this)}>
                                                <FMInject services={[NavigationPane, DetailsView, FMToolbar, ContextMenu]}/>
                                                </FileManagerComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>);
    }
    fileManagerRender2() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        ++this.childKey;
        return(<React.Fragment>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">
                                        <div className='row'>
                                            <div className="col-xs-3 offset-xs-9 col-sm-3 offset-sm-9 col-lg-2 offset-lg-10 col-md-2 offest-md-10">
                                                <ButtonComponent id={`btnGalleriaImmagini${idSin}`} title='Galleria Immagini' onClick={this.btnGalleriaImmagini.bind(this)} cssClass='e-info'>GALLERIA IMMAGINI</ButtonComponent>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <FileManagerComponent id={`FileManager2${idSin}`} ref={fmObj => this.FMInstance2 = fmObj} key={this.childKey} height='680px' ajaxSettings={{
                                                    url: `${CustomJS.APIVariousJfishTechURLProd}/api/AmazonS3ProviderACT/AmazonS3FileOperations`,
                                                    getImageUrl: `${CustomJS.APIVariousJfishTechURLProd}/api/AmazonS3ProviderACT/AmazonS3GetImage`,
                                                    uploadUrl: `${CustomJS.APIVariousJfishTechURLProd}/api/AmazonS3ProviderACT/AmazonS3Upload`,
                                                    downloadUrl: `${CustomJS.APIVariousJfishTechURLProd}/api/AmazonS3ProviderACT/AmazonS3Download`                                                    
                                                    }} contextMenuSettings={{
                                                        file: ['Open', '|', 'Cut', 'Copy', '|', 'Delete', 'Download', 'Rename', '|', 'Details'],
                                                        folder: ['Open', '|', 'Cut', 'Copy', 'Paste', '|', 'Delete', 'Rename', 'Download', '|', 'Details'],
                                                    }} fileOpen={this.onFileOpen.bind(this, CustomJS.CartellaProdCGAPolizzeACT)} view={"Details"} beforeSend={this.onBeforeSend.bind(this, CustomJS.CartellaProdCGAPolizzeACT)}
                                                        searchSettings={{ allowSearchOnTyping: false }} beforeDownload={this.onBeforeDownload.bind(this, CustomJS.CartellaProdCGAPolizzeACT)}
                                                        beforeImageLoad={this.onBeforeImageLoad2.bind(this)} allowDragAndDrop={true} fileLoad={this.onFileLoad2.bind(this)} 
                                                        success={this.onSuccess2.bind(this)} created={this.onCreatedFileManager2.bind(this)}>
                                                <FMInject services={[NavigationPane, DetailsView, FMToolbar, ContextMenu]}/>
                                                </FileManagerComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>);
    }
    fileManagerRender3() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        ++this.childKey;
        return(<React.Fragment>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">
                                        <div className='row'>
                                            <div className="col-xs-3 offset-xs-9 col-sm-3 offset-sm-9 col-lg-2 offset-lg-10 col-md-2 offest-md-10">
                                                <ButtonComponent id={`btnGalleriaImmagini${idSin}`} title='Galleria Immagini' onClick={this.btnGalleriaImmagini.bind(this)} cssClass='e-info'>GALLERIA IMMAGINI</ButtonComponent>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <FileManagerComponent id={`FileManager3${idSin}`} ref={fmObj => this.FMInstance3 = fmObj} key={this.childKey} height='680px' ajaxSettings={{
                                                    url: `${CustomJS.APIVariousJfishTechURLProd}/api/AmazonS3ProviderACT/AmazonS3FileOperations`,
                                                    getImageUrl: `${CustomJS.APIVariousJfishTechURLProd}/api/AmazonS3ProviderACT/AmazonS3GetImage`,
                                                    uploadUrl: `${CustomJS.APIVariousJfishTechURLProd}/api/AmazonS3ProviderACT/AmazonS3Upload`,
                                                    downloadUrl: `${CustomJS.APIVariousJfishTechURLProd}/api/AmazonS3ProviderACT/AmazonS3Download`                                                    
                                                    }} contextMenuSettings={{
                                                        file: ['Open', '|', 'Cut', 'Copy', '|', 'Delete', 'Download', 'Rename', '|', 'Details'],
                                                        folder: ['Open', '|', 'Cut', 'Copy', 'Paste', '|', 'Delete', 'Rename', 'Download', '|', 'Details'],
                                                    }} fileOpen={this.onFileOpen.bind(this, CustomJS.CartellaProdDocumentazioneACT)} view={"Details"} beforeSend={this.onBeforeSend.bind(this, CustomJS.CartellaProdDocumentazioneACT)}
                                                        searchSettings={{ allowSearchOnTyping: false }} beforeDownload={this.onBeforeDownload.bind(this, CustomJS.CartellaProdDocumentazioneACT)}
                                                        beforeImageLoad={this.onBeforeImageLoad3.bind(this)} allowDragAndDrop={true} fileLoad={this.onFileLoad3.bind(this)} 
                                                        success={this.onSuccess3.bind(this)} created={this.onCreatedFileManager3.bind(this)}>
                                                <FMInject services={[NavigationPane, DetailsView, FMToolbar, ContextMenu]}/>
                                                </FileManagerComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>);
    }
    onCreatedFileManager(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        document.getElementById(`FileManager${idSin}_tb_upload`).onclick = (e) => {
            e.stopPropagation();
        };
        let drpDownBtn = new DropDownButton({
            items: this.itemsDropDownBtn,
            select: (args) => {
                if (args.item.text === 'Cartelle') {
                    this.FMInstance.uploadSettings.directoryUpload = true;
                }
                else {
                    this.FMInstance.uploadSettings.directoryUpload = false;
                }
                setTimeout(function () {
                    let uploadBtn = document.querySelector('.e-file-select-wrap button');
                    uploadBtn.click();
                }, 100);
            },
        }, `#FileManager${idSin}_tb_upload`);
    }
    onCreatedFileManager2(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        document.getElementById(`FileManager2${idSin}_tb_upload`).onclick = (e) => {
            e.stopPropagation();
        };
        let drpDownBtn = new DropDownButton({
            items: this.itemsDropDownBtn,
            select: (args) => {
                if (args.item.text === 'Cartelle') {
                    this.FMInstance2.uploadSettings.directoryUpload = true;
                }
                else {
                    this.FMInstance2.uploadSettings.directoryUpload = false;
                }
                setTimeout(function () {
                    let uploadBtn = document.querySelector('.e-file-select-wrap button');
                    uploadBtn.click();
                }, 100);
            },
        }, `#FileManager2${idSin}_tb_upload`);
    }
    onCreatedFileManager3(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        document.getElementById(`FileManager3${idSin}_tb_upload`).onclick = (e) => {
            e.stopPropagation();
        };
        let drpDownBtn = new DropDownButton({
            items: this.itemsDropDownBtn,
            select: (args) => {
                if (args.item.text === 'Cartelle') {
                    this.FMInstance3.uploadSettings.directoryUpload = true;
                }
                else {
                    this.FMInstance3.uploadSettings.directoryUpload = false;
                }
                setTimeout(function () {
                    let uploadBtn = document.querySelector('.e-file-select-wrap button');
                    uploadBtn.click();
                }, 100);
            },
        }, `#FileManager3${idSin}_tb_upload`);
    }
    PDFViewerRender() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        ++this.childKey;
        return(<React.Fragment>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <PdfViewerComponent id={`PDFViewer${idSin}`} key={this.childKey} width="auto" style={{ 'height': '720px' }} 
                                                    enablePrint={true} enableDownload={true} enableAnnotation={false} enableCommentPanel={false}
                                                    serviceUrl={`${CustomJS.APIVariousJfishTechURLProd}/api/PdfViewer`}>
                                                <PDFVInject services={[PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner]} />
                                                </PdfViewerComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>);
    }
    videoPlayerRender(fileURL) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        ++this.childKey;
        this.setState({ playVideo: true });
        return(<React.Fragment>
            <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                <div className="e-card-content">
                                    <div className='row'>
                                        <div className="col-xs-10 offset-xs-2 col-sm-10 offset-sm-2 col-lg-10 offset-lg-2 col-md-10 offset-md-2 text-center">
                                            <ReactPlayer id={`VideoPlayer${idSin}`} key={this.childKey} url={fileURL} pip={true} controls={true} playing={this.state.playVideo}
                                                  width='800px' height='400px'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
    otherDocumentRender() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        return(<React.Fragment>
            <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                <div className="e-card-content">
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center">
                                            <iframe id={`IframeDocumento${idSin}`} title='Documenti' src='' style={{width: '950px', height: '750px', border: '1px solid black'}}></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
    esitoReportFulminiRender() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        return(<React.Fragment>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "10px", justifyContent: "space-between" }}>
                <div id={`contenutoEsitoReportFulmini${idSin}`}>
                    <br />
                    <span style={{ fontSize: "20px", color: "black", fontWeight: "bolder", marginTop: "10px", marginBottom: "10px" }}>
                        Ultimo esito Report Fulmini :
                    </span>
                    <br />
                    <br />
                    <span id={`descrEsitoAutomaticoReportFulmini${idSin}`} style={{ fontSize: "26px", fontWeight: "bolder", marginTop: "10px", marginBottom: "10px" }}></span>
                    <br />
                    <br />
                    <div className="checkbox-molto-grande" style={{ marginBottom: "30px", marginLeft: "100px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CheckBoxComponent 
                                id={`checkFulminazioneEntro3KM${idSin}`} 
                                label="FULMINAZIONE ENTRO 3 KM" 
                                labelPosition="Before" 
                                checked={false} 
                                change={this.onChangeCheckFulminiEntro3KM.bind(this)} 
                                disabled={false} 
                            />
                            <span id={`descrTipoFulminiEntro3KM${idSin}`} style={{ fontSize: "18px", fontWeight: "bolder", marginLeft: "10px" }}></span>
                        </div>
                    </div>
                    <div className="checkbox-molto-grande" style={{ marginLeft: "100px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CheckBoxComponent 
                                id={`checkFulminazioneOltre3KM${idSin}`} 
                                label="FULMINAZIONE OLTRE 3 KM" 
                                labelPosition="Before" 
                                checked={false} 
                                change={this.onChangeCheckFulminiOltre3KM.bind(this)} 
                                disabled={false} 
                            />
                            <span id={`descrTipoFulminiOltre3KM${idSin}`} style={{ fontSize: "18px", fontWeight: "bolder", marginLeft: "10px" }}></span>
                        </div>
                    </div>
                </div>
                <button id={`btnVisualNascEsitoReportFulmini${idSin}`}
                    style={{ background: "transparent", border: "none", cursor: "pointer", fontSize: "24px", marginLeft: "10px", marginTop: "10px" }} 
                    title="Nascondi Esito Report Fulmini"
                    onClick={this.toggleVisibility}
                >
                    <FontAwesomeIcon icon={this.state.isVisible ? faEyeSlash : faEye} id={`iconaBtnVisualNascEsitoReportFulmini${idSin}`} ref={iconRef => {this.iconRef1 = iconRef}} icon={faEyeSlash} />
                </button>
            </div>
        </React.Fragment>);
    }
    btnEsci(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if((document.getElementById(`ripristinoFormaDiretta${idSin}`).ej2_instances[0].value === 'No') & CustomJS.isNullEmpty(document.getElementById(`motivazioneNo${idSin}`).ej2_instances[0].value)){
            this.dialogInstance3.header = 'Attenzione!!';
            this.dialogInstance3.content = "E' necessario compilare la motivazione del mancato ripristino in forma diretta.";
            this.dialogInstance3.width = '23%';
            this.dialogInstance3.height = '21%';
            this.dialogInstance3.minHeight = '21%';
            this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
            this.dialogInstance3.show();                                       
            return;
        }
        this.tabInstance.removeTab(this.tabInstance.selectedItem);
        this[`tabContent${idSin}`] = null;
        this.tabInstance.select(0);  
        setTimeout(() => {
            document.getElementById('aggiornatabella').click();
        }, 300);
    }
    btnStampa(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let dataObj = {
            IdInternoSinistro: document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value,
            Gruppo: document.getElementById(`gruppo${idSin}`).ej2_instances[0].text,
            CompagniaDivisione: document.getElementById(`compagnia${idSin}`).ej2_instances[0].text,
            AreaIspettorato: document.getElementById(`area${idSin}`).ej2_instances[0].text,
            Triage: document.getElementById(`triage${idSin}`).ej2_instances[0].checked,
            Agenzia: document.getElementById(`agenzia${idSin}`).ej2_instances[0].text,
            ProntaDefinizione: document.getElementById(`prontaDefinizione${idSin}`).ej2_instances[0].checked,
            TelefonoAgenzia: document.getElementById(`telefonoAgenzia${idSin}`).ej2_instances[0].value,
            EmailAgenzia: document.getElementById(`emailAgenzia${idSin}`).ej2_instances[0].value,
            Garanzia: document.getElementById(`garanzia${idSin}`).ej2_instances[0].text,
            SinistriAssociati: document.getElementById(`sinistroAssociato${idSin}`).ej2_instances[0].value,
            NumeroSinistro: document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value,
            DataSinistro: CustomJS.isNullEmpty(document.getElementById(`dataSinistro${idSin}`).ej2_instances[0].value) ? '' : moment(document.getElementById(`dataSinistro${idSin}`).ej2_instances[0].value).format('DD/MM/YYYY'),
            DataDenunciato: CustomJS.isNullEmpty(document.getElementById(`denunciato${idSin}`).ej2_instances[0].value) ? '' : moment(document.getElementById(`denunciato${idSin}`).ej2_instances[0].value).format('DD/MM/YYYY'),
            DataIncarico: CustomJS.isNullEmpty(document.getElementById(`incarico${idSin}`).ej2_instances[0].value) ? '' : moment(document.getElementById(`incarico${idSin}`).ej2_instances[0].value).format('DD/MM/YYYY'),
            DataSopralluogo: CustomJS.isNullEmpty(document.getElementById(`dataSopralluogo${idSin}`).ej2_instances[0].value) ? '' : moment(document.getElementById(`dataSopralluogo${idSin}`).ej2_instances[0].value).format('DD/MM/YYYY'),
            DataChiusura: CustomJS.isNullEmpty(document.getElementById(`chiusura${idSin}`).ej2_instances[0].value) ? '' : moment(document.getElementById(`chiusura${idSin}`).ej2_instances[0].value).format('DD/MM/YYYY'),
            Liquidatore: document.getElementById(`liquidatore${idSin}`).ej2_instances[0].text,
            TelLiquidatore: document.getElementById(`telefonoLiquidatore${idSin}`).ej2_instances[0].value,
            EmailLiquidatore: document.getElementById(`emailLiquidatore${idSin}`).ej2_instances[0].value,
            Broker: document.getElementById(`broker${idSin}`).ej2_instances[0].text,
            TelBroker: document.getElementById(`telefonoBroker${idSin}`).ej2_instances[0].value,
            EmailBroker: document.getElementById(`emailBroker${idSin}`).ej2_instances[0].value,
            NumeroPolizza: document.getElementById(`numeroPolizza${idSin}`).ej2_instances[0].value,
            TipoPolizza: document.getElementById(`tipoPolizza${idSin}`).ej2_instances[0].text,
            Contraente: document.getElementById(`contraente${idSin}`).ej2_instances[0].value,
            TelContraente: document.getElementById(`telefonoContraente${idSin}`).ej2_instances[0].value,
            EmailContraente: document.getElementById(`emailContraente${idSin}`).ej2_instances[0].value,
            Assicurato: document.getElementById(`assicurato${idSin}`).ej2_instances[0].value,
            TelAssicurato: document.getElementById(`telefonoAssicurato${idSin}`).ej2_instances[0].value,
            EmailAssicurato: document.getElementById(`emailAssicurato${idSin}`).ej2_instances[0].value,
            Amministratore: document.getElementById(`amministratore${idSin}`).ej2_instances[0].text,
            TelAmministratore: document.getElementById(`telefonoAmministratore${idSin}`).ej2_instances[0].value,
            EmailAmministratore: document.getElementById(`emailAmministratore${idSin}`).ej2_instances[0].value,
            Danneggiato: document.getElementById(`danneggiato${idSin}`).ej2_instances[0].value,
            TelDanneggiato: document.getElementById(`telefonoDanneggiato${idSin}`).ej2_instances[0].value,
            EmailDanneggiato: document.getElementById(`emailDanneggiato${idSin}`).ej2_instances[0].value,
            Indirizzo: document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value,
            Citta: document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value,
            Cap: document.getElementById(`capUbicazioneRischio${idSin}`).ej2_instances[0].value,
            Provincia: document.getElementById(`provinciaUbicazioneRischio${idSin}`).ej2_instances[0].value,
            EstrattoDenuncia: document.getElementById(`noteEstrattoDenuncia${idSin}`).ej2_instances[0].value,
            Giustificativi: document.getElementById(`giustificativiDenuncia${idSin}`).ej2_instances[0].text,
            Riserva: document.getElementById(`riservaDenuncia${idSin}`).ej2_instances[0].value,
            Richiesta: document.getElementById(`richiestaDenuncia${idSin}`).ej2_instances[0].value,
            PreesFabbr: document.getElementById(`preesistenzaFabbricatoCat${idSin}`).ej2_instances[0].checked,
            PreesMacch: document.getElementById(`preesistenzaMacchinarioCat${idSin}`).ej2_instances[0].checked,
            PreesCont: document.getElementById(`preesistenzaContenutoCat${idSin}`).ej2_instances[0].checked,
            UbicazBene: document.getElementById(`idUbicazBene${idSin}`).value,
            AppVerif: document.getElementById(`appVerif${idSin}`).ej2_instances[0].value,
            GridCat: document.getElementById(`gridCatAssegnati${idSin}`).ej2_instances[0].dataSource,
            GridPeriti: document.getElementById(`gridPeritiAssegnati${idSin}`).ej2_instances[0].dataSource,
        };
        this.toggleBlocking();
        CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/PdfCreator/CreaDocumentoSinistroACT`, { datiSinistroJSON: JSON.stringify(dataObj) }, 'POST', 
            { "Authorization" : CustomJS.tokenAuthAPIVarious }, retData => {
                this.dialogInstance5.header = `VISUALIZZAZIONE DOCUMENTO RIEPILOGO SINISTRO NUM. ${document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value}`;    
                this.dialogInstance5.width = '65%';
                this.dialogInstance5.height = '90%';
                this.dialogInstance5.minHeight = '90%';
                this.dialogInstance5.buttons = []; 
                this.dialogInstance5.beforeOpen = (args) => {
                    const container = document.getElementById('defaultDialogContent2');
                    const root = createRoot(container); 
                    root.unmount();
                };
                this.dialogInstance5.open = (args) => {
                    const container = document.getElementById('defaultDialogContent2');
                    const root = createRoot(container);                         
                    root.render(<CallbackWrapper callback={() => {
                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].enableDownload = true;
                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].serviceUrl = `${CustomJS.APIVariousJfishTechURLProd}/api/PdfViewer`;
                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                        //this.PDFViewerInstance.dataBind();  //NON SEMBRA SERVIRE
                        setTimeout(() => {
                            document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].load(retData, null);
                            //document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].load(`data:application/pdf;base64,${retData}`, null);
                            this.toggleBlocking();
                        }, 250);
                    }}>{this.PDFViewerRender()}</CallbackWrapper>);
                };  
                this.dialogInstance5.show(); 
        }, error => {
            let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            this.toggleBlocking();
        }, true, false);
    }   
    btnSblocca(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        document.getElementById(`gruppo${idSin}`).ej2_instances[0].enabled = CustomJS.isNullEmpty(document.getElementById(`gruppo${idSin}`).value);
        document.getElementById(`gruppo${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`compagnia${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`area${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`triage${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`prontaDefinizione${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`videoperizia${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`riapertura${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`gradoSinistro${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`sollgiust${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`sollatto${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`documentale${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`periziaSemplificata${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`agenzia${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`comunicazionePerAgenzia${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`btnReportFulminiCESI${idSin}`).style.removeProperty('display');
        document.getElementById(`btnReportFulminiMETEOCAST${idSin}`).style.removeProperty('display');
        //document.getElementById(`btnDialogoIntelligenzaArtificiale${idSin}`).style.removeProperty('display');
        document.getElementById(`divReportFulminiIA${idSin}`).style.removeProperty('display');
        document.getElementById(`garanzia${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`ramo${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].readonly = !CustomJS.isNullEmpty(document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value);
        if (CustomJS.isNullEmpty(document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value))
            document.getElementById(`divBtnImportaSin${idSin}`).style.removeProperty('display');
        //document.getElementById(`btnSin${idSin}`).style.removeProperty('display');
        document.getElementById(`sinistroAssociato${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`dataSinistro${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`mask_dataSinistro${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`denunciato${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`mask_denunciato${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`incarico${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`mask_incarico${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`dataSopralluogo${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`mask_dataSopralluogo${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`chiusura${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`mask_chiusura${idSin}`).ej2_instances[0].readonly = false;
        //document.getElementById(`dataSinistro${idSin}`).ej2_instances[0].showClearButton = true;
        document.getElementById(`mask_dataSinistro${idSin}`).ej2_instances[0].showClearButton = true;
        //document.getElementById(`denunciato${idSin}`).ej2_instances[0].showClearButton = true;
        document.getElementById(`mask_denunciato${idSin}`).ej2_instances[0].showClearButton = true;
        //document.getElementById(`incarico${idSin}`).ej2_instances[0].showClearButton = true;
        document.getElementById(`mask_incarico${idSin}`).ej2_instances[0].showClearButton = true;
        //document.getElementById(`dataSopralluogo${idSin}`).ej2_instances[0].showClearButton = true;
        document.getElementById(`mask_dataSopralluogo${idSin}`).ej2_instances[0].showClearButton = true;
        //document.getElementById(`chiusura${idSin}`).ej2_instances[0].showClearButton = true;
        document.getElementById(`mask_chiusura${idSin}`).ej2_instances[0].showClearButton = true;
        document.getElementById(`liquidatore${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`broker${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`numeroPolizza${idSin}`).ej2_instances[0].readonly = !CustomJS.isNullEmpty(document.getElementById(`numeroPolizza${idSin}`).value);
        if (document.getElementById(`numeroPolizza${idSin}`).ej2_instances[0].readonly === false) 
            document.getElementById(`btnPolizza${idSin}`).style.removeProperty('display');
        document.getElementById(`btnVediCercaCGAPolizze${idSin}`).style.removeProperty('display');
        document.getElementById(`btnVediCercaDocsPolizze${idSin}`).style.removeProperty('display');
        document.getElementById(`btnSinAssociato${idSin}`).style.removeProperty('display');
        document.getElementById(`btnSinAltriAssic${idSin}`).style.removeProperty('display');
        document.getElementById(`btnContraente${idSin}`).style.removeProperty('display');
        document.getElementById(`associazionePolizza${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`tipoPolizza${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`altriSinistriAssicurato${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`contraente${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`telefonoContraente${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`emailContraente${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`assicurato${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`telefonoAssicurato${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`emailAssicurato${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`amministratore${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`danneggiato${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`telefonoDanneggiato${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`emailDanneggiato${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`codiceFiscaleDanneggiato${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`partitaIVADanneggiato${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`IBANDanneggiato${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`capUbicazioneRischio${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`provinciaUbicazioneRischio${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`btnUbicazioneRischio${idSin}`).style.removeProperty('display');
        document.getElementById(`noteEstrattoDenuncia${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`giustificativiDenuncia${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`riservaDenuncia${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`richiestaDenuncia${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`preesistenzaFabbricatoCat${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`preesistenzaMacchinarioCat${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`preesistenzaContenutoCat${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`noteCat${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`btnIncarico${idSin}`).style.removeProperty('display');
        document.getElementById(`appVerif${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`gridCatAssegnati${idSin}`).ej2_instances[0].element.classList.remove('disablegrid');
        document.getElementById(`gridCatAssegnatiParent${idSin}`).classList.remove('wrapper');
        document.getElementById(`gridRFDAssegnati${idSin}`).ej2_instances[0].element.classList.remove('disablegrid');
        document.getElementById(`gridRFDAssegnatiParent${idSin}`).classList.remove('wrapper');
        document.getElementById(`ripristinoFormaDiretta${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`motivazioneNo${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`assegnaRfd${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`btnEsporta${idSin}`).style.removeProperty('display');
        document.getElementById(`preesistenzaFabbricatoRfd${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`preesistenzaMacchinarioRfd${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`preesistenzaContenutoRfd${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`appDaRip${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`noteRfd${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`btnIncaricoRfd${idSin}`).style.removeProperty('display');
        document.getElementById(`gridPeritiAssegnati${idSin}`).ej2_instances[0].element.classList.remove('disablegrid');
        document.getElementById(`gridPeritiAssegnatiParent${idSin}`).classList.remove('wrapper');
        document.getElementById(`assegnaPerito${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`assegnaCQ${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`assegnaCR${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`dataAssegnCQ${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`mask_dataAssegnCQ${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`dataContrCQ${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`mask_dataContrCQ${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`dataAssegnCR${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`mask_dataAssegnCR${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`dataContrCR${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`mask_dataContrCR${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`mask_dataAssegnCQ${idSin}`).ej2_instances[0].showClearButton = true;
        document.getElementById(`mask_dataContrCQ${idSin}`).ej2_instances[0].showClearButton = true;
        document.getElementById(`mask_dataAssegnCR${idSin}`).ej2_instances[0].showClearButton = true;
        document.getElementById(`mask_dataContrCR${idSin}`).ej2_instances[0].showClearButton = true;
        document.getElementById(`interlocutoriePresenti${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`istruzioniChiusura${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`btnEsportaInterlocutoria${idSin}`).style.removeProperty('display');
        document.getElementById(`btnEsportaModuloPrimoContatto${idSin}`).style.removeProperty('display');
        document.getElementById(`checkRiservaComunicata${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`parcella${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`parcellaPerito${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`parcellaCAT${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`btnParcellaPerito${idSin}`).style.removeProperty('display');
        if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti Semplici') === -1)
            document.getElementById(`btnParcellaCAT${idSin}`).style.removeProperty('display');
        document.getElementById(`ddlSollecito${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`ddlCambiaStato${idSin}`).ej2_instances[0].enabled = document.getElementById(`idStato${idSin}`).value !== '3' || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Segreteria') > -1
                                                                                     || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Amministratori') > -1
                                                                                     || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('SysAdmin') > -1;
        if (document.getElementById(`idStato${idSin}`).value !== '3'
            || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Segreteria') > -1
            || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Amministratori') > -1
            || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('SysAdmin') > -1) {
            document.getElementById(`btnCambiaStato${idSin}`).style.removeProperty('display');
            if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti Semplici') === -1)
                document.getElementById(`btnParcella${idSin}`).style.removeProperty('display');
        }
        document.getElementById(`dataAlert${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`mask_dataAlert${idSin}`).ej2_instances[0].readonly = false;
        //document.getElementById(`dataAlert${idSin}`).ej2_instances[0].showClearButton = true;
        document.getElementById(`mask_dataAlert${idSin}`).ej2_instances[0].showClearButton = true;
        document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`ddlAChi${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`ddlCosa${idSin}`).ej2_instances[0].readonly = false;
        document.getElementById(`btnStampa${idSin}`).style.removeProperty('display');
        document.getElementById(`btnDocs${idSin}`).ej2_instances[0].disabled = false;
        document.getElementById(`btnAgenzia${idSin}`).style.removeProperty('display');
        document.getElementById(`btnLiquidatore${idSin}`).style.removeProperty('display');
        document.getElementById(`btnBroker${idSin}`).style.removeProperty('display');
        document.getElementById(`btnAmministratore${idSin}`).style.removeProperty('display');
        if (!this.noSblocco) {
            document.getElementById(`btnTipoPolizza${idSin}`).style.removeProperty('display');
        }
        document.getElementById(`btnAzione${idSin}`).style.removeProperty('display');
        document.getElementById(`btnSoll${idSin}`).style.removeProperty('display');
        document.getElementById(`btnApriMaschera${idSin}`).style.removeProperty('display');
        document.getElementById(`btnAggiornaNota${idSin}`).style.removeProperty('display');
        document.getElementById(`btnCancellaNota${idSin}`).style.removeProperty('display');
        document.getElementById(`btnPerito${idSin}`).style.removeProperty('display');
        //document.getElementById(`btnSblocca${idSin}`).style.visibility = 'hidden';
        $(`#btnSblocca${idSin}`).hide();
        if ((document.getElementById(`idStato${idSin}`).value === '32' 
            || (document.getElementById(`idStato${idSin}`).value === '33' && !CustomJS.isNullEmpty(document.getElementById(`dataOraSopralluogoConcordata${idSin}`).value))
            || (document.getElementById(`idStato${idSin}`).value === '50' && !CustomJS.isNullEmpty(document.getElementById(`dataSopralluogo${idSin}`).ej2_instances[0].value)))
              && document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === CustomJS.codiceCompagniaUnipolSAI && !CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value)) {
            document.getElementById(`btnInvioComunicazioneUNI${idSin}`).style.removeProperty('display');
            document.getElementById(`btnInvioChiusuraGEN${idSin}`).style.display = 'none';
            document.getElementById(`btnVisualizzaDocumentoGEN${idSin}`).style.display = 'none';
            if (document.getElementById(`idStato${idSin}`).value === '32' || document.getElementById(`idStato${idSin}`).value === '33')
                document.getElementById(`btnInvioComunicazioneUNI${idSin}`).setAttribute('title', 'Invia comunicazione Esito Contatto Danneggiato ad UnipolSAI');
            else if (document.getElementById(`idStato${idSin}`).value === '22' || document.getElementById(`idStato${idSin}`).value === '3')
                document.getElementById(`btnInvioComunicazioneUNI${idSin}`).setAttribute('title', 'Invia comunicazione Restituzione Perizia ad UnipolSAI');
            else if (document.getElementById(`idStato${idSin}`).value === '50')
                document.getElementById(`btnInvioComunicazioneUNI${idSin}`).setAttribute('title', 'Invia comunicazione Dati Sopralluogo ad UnipolSAI');
        }

        if (document.getElementById(`idStato${idSin}`).value === '22' && document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === CustomJS.codiceCompagniaGenerali 
            && !CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value)) {
            document.getElementById(`btnInvioComunicazioneUNI${idSin}`).style.display = 'none';
            document.getElementById(`btnInvioChiusuraGEN${idSin}`).style.removeProperty('display');
            document.getElementById(`btnInvioChiusuraGEN${idSin}`).setAttribute('title', 'Invia chiusura Danneggiato e Incarico a GENERALI');
        }

        if ((document.getElementById(`idStato${idSin}`).value === '22' || document.getElementById(`idStato${idSin}`).value === '3') && document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === CustomJS.codiceCompagniaUnipolSAI 
            && !CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value)) {
            document.getElementById(`btnInvioComunicazioneUNI${idSin}`).style.removeProperty('display');
            document.getElementById(`btnInvioComunicazioneUNI${idSin}`).setAttribute('title', 'Invia comunicazione Restituzione Perizia ad UnipolSAI');
           document.getElementById(`btnInvioChiusuraGEN${idSin}`).style.display = 'none';
        }

        if (document.getElementById(`idStato${idSin}`).value === '3' && document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === CustomJS.codiceCompagniaGenerali
            && !CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value)) {
            document.getElementById(`btnInvioComunicazioneUNI${idSin}`).style.display = 'none';
            document.getElementById(`btnInvioChiusuraGEN${idSin}`).style.display = 'none';
            document.getElementById(`btnVisualizzaDocumentoGEN${idSin}`).style.removeProperty('display');
            document.getElementById(`btnVisualizzaDocumentoGEN${idSin}`).setAttribute('title', 'Visualizza e scarica documento di perizia GENERALI');
        }

        if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti') > -1 
            || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Referenti ACE') > -1) {
                document.getElementById(`btnInvioComunicazioneUNI${idSin}`).style.display = 'none';
                document.getElementById(`btnInvioChiusuraGEN${idSin}`).style.display = 'none';
        }

        let currentUser = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username;
        const QueryExec5 = `SELECT [09001].username FROM [09001] INNER JOIN [09002] ON [09001].id_utente = [09002].id_utente INNER JOIN [09000] ON [09002].id_gruppo = [09000].id_gruppo WHERE [09002].id_gruppo <= 2 AND [09001].username = '${currentUser}'`;        
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec5, '', retData5 => {
            if (retData5.length === 0) {
                if (currentUser === 'j.dimunno' || currentUser === 'a.filannino' || currentUser === 'm.mantovani') {
                    document.getElementById(`btnParcella${idSin}`).ej2_instances[0].disabled = document.getElementById(`idStato${idSin}`).value === '3';
                    document.getElementById(`btnParcellaCAT${idSin}`).ej2_instances[0].disabled = document.getElementById(`idStato${idSin}`).value === '3';
                }
                else {
                    document.getElementById(`btnParcella${idSin}`).ej2_instances[0].disabled = true;
                    document.getElementById(`btnParcellaCAT${idSin}`).ej2_instances[0].disabled = true;
                }
            }
            else {
                document.getElementById(`btnParcella${idSin}`).ej2_instances[0].disabled = document.getElementById(`idStato${idSin}`).value === '3'; 
                document.getElementById(`btnParcellaCAT${idSin}`).ej2_instances[0].disabled = document.getElementById(`idStato${idSin}`).value === '3';
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, true, true);
    }
    btnInvioComunicazioneUNI(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.emettiNotifiche(document.getElementById(`idSinistro${idSin}`).value, idSin, false, document.getElementById(`gruppo${idSin}`).ej2_instances[0].value, null, null, true);
        let payloadMessaggio = CustomJS.isNullEmpty(document.getElementById(`dataOraSopralluogoConcordata${idSin}`).value) ? `<b>Data/Ora Contatto : ${moment(new Date()).format('DD/MM/YYYY HH:mm')}<br/>Esito Contatto : CONTATTO NON RIUSCITO<br/>Data Prossimo Contatto : N/A<br/>Cellulare Danneggiato : ${document.getElementById(`telefonoDanneggiato${idSin}`).ej2_instances[0].value}<br/>Email Danneggiato : ${document.getElementById(`emailDanneggiato${idSin}`).ej2_instances[0].value}</b>`
                               : (document.getElementById(`idStato${idSin}`).value === '32' || document.getElementById(`idStato${idSin}`).value === '33') ? `<b>Data/Ora Contatto : ${moment(new Date()).format('DD/MM/YYYY HH:mm')}<br/>Esito Contatto : SOPRALLUOGO FISSATO<br/>Data/Ora Concordato Sopralluogo : ${moment(document.getElementById(`dataOraSopralluogoConcordata${idSin}`).value).format('DD/MM/YYYY HH:mm')}<br/>Indirizzo Sopralluogo : ${document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value} ${document.getElementById(`capUbicazioneRischio${idSin}`).ej2_instances[0].value} ${document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value} (${document.getElementById(`provinciaUbicazioneRischio${idSin}`).ej2_instances[0].value})<br/>Cellulare Danneggiato : ${document.getElementById(`telefonoDanneggiato${idSin}`).ej2_instances[0].value}<br/>Email Danneggiato : ${document.getElementById(`emailDanneggiato${idSin}`).ej2_instances[0].value}</b>` 
                               : document.getElementById(`idStato${idSin}`).value === '50' ? `<b>Soprallugo Effettuato : SI<br/>Data Avvenuto Sopralluogo : ${moment(document.getElementById(`dataSopralluogo${idSin}`).ej2_instances[0].value).format('DD/MM/YYYY')}<br/>Indirizzo Sopralluogo : ${document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value} ${document.getElementById(`capUbicazioneRischio${idSin}`).ej2_instances[0].value} ${document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value} (${document.getElementById(`provinciaUbicazioneRischio${idSin}`).ej2_instances[0].value})<br/>Note Sopralluogo : N/A</b>` : '';
        let messaggio = (document.getElementById(`idStato${idSin}`).value === '32' || document.getElementById(`idStato${idSin}`).value === '33') ? `Si vuole inviare una comunicazione ad <b>UNIPOLSAI</b> relativa al <b>Esito Contatto Danneggiato</b>, contenente le seguenti informazioni : <br/></br>${payloadMessaggio}<br/></br><b>NOTA BENE : Verificare nel pannello delle notifiche lo stato dell'ultimo invio della medesima comunicazione e per evitare ridondanze inviarla nuovamente <b>SOLO</b> nel caso di correzioni di eventuali errori segnalati oppure se è stata cambiata qualche informazione inviata in precedenza. Verificare anche che non manchino informazioni ritenute obbligatorie (es. Cellulare e/o Email de Danneggiato) perché in caso contrario la Compagnia risponderà con segnalazioni di errore.</b><br/><br/>Confermi l'invio della comunicazione?`
                         : document.getElementById(`idStato${idSin}`).value === '50' ? `Si vuole inviare una comunicazione ad <b>UNIPOLSAI</b> relativa ai <b>Dati di Sopralluogo</b>, contenente le seguenti informazioni : <br/></br>${payloadMessaggio}<br/></br><b>NOTA BENE : Verificare nel pannello delle notifiche lo stato dell'ultimo invio della medesima comunicazione e per evitare ridondanze inviarla nuovamente <b>SOLO</b> nel caso di correzioni di eventuali errori segnalati oppure se è stata cambiata qualche informazione inviata in precedenza. Verificare anche che non manchino informazioni ritenute obbligatorie perché in caso contrario la Compagnia risponderà con segnalazioni di errore.</b><br/><br/>Confermi l'invio della comunicazione?` 
                         : (document.getElementById(`idStato${idSin}`).value === '22' || document.getElementById(`idStato${idSin}`).value === '3') ? `Si vuole inviare una comunicazione a <b>UNIPOLSAI</b> relativa alla <b>Restituzione Perizia (con documentazione)</b>. Prima di procedere, effettuare le seguenti verifiche :<br/><br/>1) Perizia online compilata e conclusa correttamente.<br/><br/>2) Documento di perizia compilato, stampato ed allegato all'interno della cartella <b>Da Chiudere</b> del sinistro<br/><br/>3) Tutti i documenti e/o tutte le foto (geolocalizzate e non) necessarie, allegati all'interno della cartella <b>Da Chiudere</b> del sinistro<br/><br/>4) Sezioni <b>Dati per Chiusura UnipolSAI</b> e <b>Definizione del danno</b> compilate correttamente<br/><br/>La risposta della Compagnia con l'esito sarà differita di qualche minuto e sarà visibile attivando le notifiche del sinistro. Nel caso in cui la risposta evidenzi qualche errore, riportare il sinistro nello stato <b>Incarico da chiudere a sistema</b>, correggere gli errori medesimi e ripetere l'operazione. Confermi l'invio della comunicazione?` : '';
        this.dialogInstance3.header = 'Invio comunicazione ad UNIPOLSAI';
        this.dialogInstance3.content = messaggio;
        this.dialogInstance3.width = '35%';
        this.dialogInstance3.height = CustomJS.isNullEmpty(document.getElementById(`dataOraSopralluogoConcordata${idSin}`).value) ? '39%' : (document.getElementById(`idStato${idSin}`).value === '22' || document.getElementById(`idStato${idSin}`).value === '32' || document.getElementById(`idStato${idSin}`).value === '33') ? '41%' : '39%';
        this.dialogInstance3.minHeight = CustomJS.isNullEmpty(document.getElementById(`dataOraSopralluogoConcordata${idSin}`).value) ? '39%' : (document.getElementById(`idStato${idSin}`).value === '22' || document.getElementById(`idStato${idSin}`).value === '32' || document.getElementById(`idStato${idSin}`).value === '33') ? '41%' : '39%';
        this.dialogInstance3.buttons = [
            { click: () => { 
                this.dialogInstance3.hide();
                if (document.getElementById(`idStato${idSin}`).value === '32' || document.getElementById(`idStato${idSin}`).value === '33')
                    this.invioEsitoContattoDanneggiatoUnipolSAI(document.getElementById(`idIncarico${idSin}`).value)
                else if (document.getElementById(`idStato${idSin}`).value === '50')
                    this.invioDatiSopralluogoUnipolSAI(document.getElementById(`idIncarico${idSin}`).value);
                else if (document.getElementById(`idStato${idSin}`).value === '22' || document.getElementById(`idStato${idSin}`).value === '3')
                    this.invioRestituzionePeriziaUnipolSAI(document.getElementById(`idIncarico${idSin}`).value, document.getElementById(`idSinistro${idSin}`).value);
            }, 
            buttonModel: { 
                content: 'CONFERMA', 
                cssClass: 'e-primary',
                isPrimary: true 
            }
            },
            {
                click: () => {
                    this.dialogInstance3.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];   
        this.dialogInstance3.show();
    }
    async btnInvioChiusuraGEN(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;

        const leggiImportoLiquidazione = () => {
            return new Promise((resolve,reject) => {
                const QueryExec = `SELECT TOP 1 T3.* FROM [12003] T1 INNER JOIN [12004] T2 ON T1.id_incarico = T2.id_incarico 
                                   INNER JOIN [12012] T3 ON T2.id_danneggiato = T3.id_danneggiato WHERE T1.id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;                             
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                    resolve(retData[0]?.importo_liquidazione ?? null);
                }, messaggio => {   
                    reject(messaggio);  
                }, true, true);
            });    
        };

        const importoLiquidazione = await leggiImportoLiquidazione();

        const messaggio = `Si vuole inviare una comunicazione a <b>GENERALI</b> relativa alla <b>Chiusura Danneggiato e Incarico (con documentazione)</b>. Prima di procedere, effettuare le seguenti verifiche :<br/><br/>1) Perizia online compilata e conclusa correttamente.<br/><br/>2) Documento di perizia compilato, stampato ed allegato all'interno della cartella <b>Da Chiudere</b> del sinistro<br/><br/>3) Documento con le foto compilato correttamente e/o tutte le altre foto necessarie, allegate all'interno della cartella <b>Da Chiudere</b> del sinistro<br/><br/>4) Sezioni <b>Definizione del Danno/Liquidazioni ed Info Richieste</b> compilate correttamente<br/><br/>5) Foto del rischio caricata tramite bottone apposito presente nella sezione <b>Definizione del Danno</b><br/><br/><span style="font-size: 19px; font-weight: bolder; text-decoration: underline;">NOTA BENE: ${CustomJS.isNullEmpty(importoLiquidazione) ? 'LIQUIDAZIONE NON PRESENTE PER IL SINISTRO IN OGGETTO' : `l'importo della liquidazione che verrà inviato ammonta ad € ${new Intl.NumberFormat('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(importoLiquidazione))}`}</span><br/><br/>La risposta della Compagnia con l'esito sarà immediata e quindi, fino a quando i dati inviati non saranno tutti corretti, la risposta sarà errata. Confermi l'invio della comunicazione?`;
        this.dialogInstance3.header = 'Invio chiusura Danneggiato ed Incarico a GENERALI';
        this.dialogInstance3.content = messaggio;
        this.dialogInstance3.width = '40%';
        this.dialogInstance3.height = '45%';
        this.dialogInstance3.minHeight = '45%';
        this.dialogInstance3.buttons = [
            { click: () => { 
                this.dialogInstance3.hide();
                this.toggleBlocking2(true)
                this.invioChiusuraGENERALI(document.getElementById(`idSinistro${idSin}`).value, idSin, document.getElementById(`idIncarico${idSin}`).value, document.getElementById(`numeroSinistro${idSin}`).ej2_instances[0].value)
                .then(ret => {
                    if (ret.response === "OK") {
                        document.getElementById(`ddlCambiaStato${idSin}`).ej2_instances[0].value = '3';
                        document.getElementById(`btnInvioChiusuraGEN${idSin}`).style.display = 'none';
                        document.getElementById(`btnVisualizzaDocumentoGEN${idSin}`).style.removeProperty('display');
                        this.aggiornaStatoCambioStato();
                        const dataObj = {
                            IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                        };  
                        const QueryExec = `UPDATE [01001] SET [01001].chiusura_telematica = 1 WHERE [01001].id_sinistro = @id_sinistro`;        
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 
                            'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                document.getElementById(`parcella${idSin}`).ej2_instances[0].value = 105.00;
                                this.onChangeParcellaDettaglio({ value: 105.00 });
                        }, error => {
                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),                    
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );    
                        }, true, true);  
                        this.visualizzaDocumentoGENERALI(document.getElementById(`idIncarico${idSin}`).value);
                    }
                    else {
                        setTimeout(() => {
                            this.emettiNotifiche(document.getElementById(`idSinistro${idSin}`).value, idSin, false, document.getElementById(`gruppo${idSin}`).ej2_instances[0].value, null, null, true);
                        }, 500);
                    }
                    this.toggleBlocking2(false);
                })
                .catch(error => {
                    this.toggleBlocking2(false);
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),                    
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );    
                });
            }, 
                buttonModel: { 
                    content: 'CONFERMA', 
                    cssClass: 'e-primary',
                    isPrimary: true 
                }
            },
            {
                click: () => {
                    this.dialogInstance3.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];   
        this.dialogInstance3.show();

    }
    btnAgenzia() {  
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.dialogInstance1.header = 'TABELLA AGENZIE'; 
        this.dialogInstance1.width = '90%';
        this.dialogInstance1.height = '85%';
        this.dialogInstance1.minHeight = '85%';
        this.dialogInstance1.buttons = [];   
        this.dialogInstance1.beforeClose = undefined;
        this.dialogInstance1.beforeOpen = (args) => {
            const container = document.getElementById('defaultDialogContent');
            const root = createRoot(container);      
            root.unmount();                   
        }
        this.dialogInstance1.open =  (args) => {
            ++this.childKey;
            const container = document.getElementById('defaultDialogContent');
            const root = createRoot(container);                         
            root.render(<CallbackWrapper callback={() => {
                setTimeout(() => {                 
                    document.getElementById('GridAgenzie').ej2_instances[0].pageSettings.pageSize = 4;
                }, 300);    
            }}><PaginaAgenzie key={this.childKey} DescrAgenzia={document.getElementById(`agenzia${idSin}`).ej2_instances[0].text} ArrivaDaDettSinistro={true}></PaginaAgenzie></CallbackWrapper>);                     
        }; 
        this.dialogInstance1.close = (args) => {
            let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
            const tabella = '00004';
            const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
            let ddlAgenzia = `agenzia${idSin}`; 
            let salvaAge = document.getElementById(ddlAgenzia).ej2_instances[0].value;            
            CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData => {
                if (retData.length > 0) {
                    let emptyObj = {};
                    Object.keys(retData[0]).forEach(e => emptyObj[e] = '');
                    retData.unshift(emptyObj);
                }
                sessionStorage.setItem('JellyfishTechnologyTabellaAgenziaDettaglio', CustomJS.compressAndEncode(retData));
                this.dsAgenziaDettaglio = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaAgenziaDettaglio'))).map(elm => { return { VALUE: elm.id_agenzia?.toString(), TEXT: elm.ragione_sociale, id_divisione: elm.id_divisione, telefono1: elm.telefono1, email1: elm.email1, area: elm.descrizione} });
                document.getElementById(ddlAgenzia).ej2_instances[0].dataSource = this.dsAgenziaDettaglio;
                setTimeout(() => {  
                    this.onChangeCompagniaDettaglio({ value: document.getElementById(`compagnia${idSin}`).ej2_instances[0].value }); 
                    document.getElementById(ddlAgenzia).ej2_instances[0].value = salvaAge;
                    setTimeout(() => {
                        document.getElementById(`telefonoAgenzia${idSin}`).ej2_instances[0].value = document.getElementById(ddlAgenzia).ej2_instances[0].itemData == null ? '' :  document.getElementById(ddlAgenzia).ej2_instances[0].itemData?.telefono1;
                        document.getElementById(`emailAgenzia${idSin}`).ej2_instances[0].value = document.getElementById(ddlAgenzia).ej2_instances[0].itemData == null ? '' : document.getElementById(ddlAgenzia).ej2_instances[0].itemData?.email1;
                    }, 600);        
                }, 300);    
            }, messaggio => {
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, "SELECT [00004].id_agenzia, [00004].ragione_sociale, [00004].telefono1, [00004].email1, [00007].descrizione, [03002].id_divisione FROM ([00007] RIGHT JOIN [00004] ON [00007].id_area=[00004].id_area) INNER JOIN [03002] ON [00004].id_agenzia=[03002].id_agenzia ORDER BY [00004].ragione_sociale;");
        }; 
        this.dialogInstance1.show();  
    }
    btnLiquidatore() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.dialogInstance1.header = 'TABELLA LIQUIDATORI';
        this.dialogInstance1.width = '90%';
        this.dialogInstance1.height = '85%';       
        this.dialogInstance1.minHeight = '85%';
        this.dialogInstance1.buttons = [];   
        this.dialogInstance1.beforeClose = undefined;
        this.dialogInstance1.beforeOpen = (args) => {
            const container = document.getElementById('defaultDialogContent');
            const root = createRoot(container);      
            root.unmount();                   
        }
        this.dialogInstance1.open = (args) => {
            ++this.childKey;
            const container = document.getElementById('defaultDialogContent');
            const root = createRoot(container);                         
            root.render(<CallbackWrapper callback={() => {
                setTimeout(() => { 
                    document.getElementById('GridLiquidatori').ej2_instances[0].pageSettings.pageSize = 8; 
                }, 300);           
            }}><PaginaLiquidatori key={this.childKey} DescrLiquidatore={document.getElementById(`liquidatore${idSin}`).ej2_instances[0].text} ArrivaDaDettSinistro={true}></PaginaLiquidatori></CallbackWrapper>);                     
        }; 
        this.dialogInstance1.close = (args) => {
            let tabella = '00009';
            this.dsLiquidatoreDettaglio = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella' + tabella)))?.map(elm => { return { VALUE: elm.id_liquidatore.toString(), TEXT: elm.ragione_sociale, telefono1:elm.telefono1, email1: elm.email1 } });
            document.getElementById(`liquidatore${idSin}`).ej2_instances[0].dataSource = this.dsLiquidatoreDettaglio;
            let salvaLiq = document.getElementById(`liquidatore${idSin}`).ej2_instances[0].value; 
            document.getElementById(`liquidatore${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`liquidatore${idSin}`).ej2_instances[0].text = null;     
            setTimeout(() => {  
                document.getElementById(`liquidatore${idSin}`).ej2_instances[0].value = salvaLiq;
            }, 300);    
        }; 
        this.dialogInstance1.show();
    }
    btnBroker() {        
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.dialogInstance1.header = 'TABELLA BROKER';
        this.dialogInstance1.width = '90%';
        this.dialogInstance1.height = '85%';
        this.dialogInstance1.minHeight = '85%';
        this.dialogInstance1.buttons = [];    
        this.dialogInstance1.beforeClose = undefined;
        this.dialogInstance1.beforeOpen = (args) => {
            const container = document.getElementById('defaultDialogContent');
            const root = createRoot(container);      
            root.unmount();                   
        }
        this.dialogInstance1.open = (args) => {
            ++this.childKey;
            const container = document.getElementById('defaultDialogContent');
            const root = createRoot(container);                         
            root.render(<CallbackWrapper callback={() => {
                setTimeout(() => { 
                    document.getElementById('GridBroker').ej2_instances[0].pageSettings.pageSize = 5;
                }, 300);           
            }}><PaginaBroker key={this.childKey} DescrBroker={document.getElementById(`broker${idSin}`).ej2_instances[0].text} ArrivaDaDettSinistro={true}></PaginaBroker></CallbackWrapper>);                     
        };  
        this.dialogInstance1.close = (args) => {
            this.dsBrokerDettaglio = _.sortBy(_.uniq(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00008'))), elm => elm.id_broker).map(elm => { return { VALUE: elm.id_broker.toString(), TEXT: elm.ragione_sociale, telefono1: elm.telefono1, email1: elm.email1 } }), 'TEXT');
            document.getElementById(`broker${idSin}`).ej2_instances[0].dataSource = this.dsBrokerDettaglio;
            let salvaBroker = document.getElementById(`broker${idSin}`).ej2_instances[0].value; 
            document.getElementById(`broker${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`broker${idSin}`).ej2_instances[0].text = null;     
            setTimeout(() => {  
                document.getElementById(`broker${idSin}`).ej2_instances[0].value = salvaBroker;
            }, 300);    
        };
        this.dialogInstance1.show(); 
    }
    btnTipoPolizza() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.dialogInstance1.header = 'TABELLA TIPI POLIZZE';
        this.dialogInstance1.width = '90%';
        this.dialogInstance1.height = '85%';
        this.dialogInstance1.minHeight = '85%';
        this.dialogInstance1.buttons = [];   
        this.dialogInstance1.beforeClose = undefined;
        this.dialogInstance1.beforeOpen = (args) => {
            const container = document.getElementById('defaultDialogContent');
            const root = createRoot(container);      
            root.unmount();                   
        }
        this.dialogInstance1.open = (args) => {
            ++this.childKey;
            const container = document.getElementById('defaultDialogContent');
            const root = createRoot(container);                         
            root.render(<CallbackWrapper callback={() => {
                setTimeout(() => { 
                    document.getElementById('GridTipiPolizze').ej2_instances[0].pageSettings.pageSize = 5; 
                }, 300);           
            }}><PaginaTipiPolizze key={this.childKey} DescrTipoPolizza={document.getElementById(`tipoPolizza${idSin}`).ej2_instances[0].itemData?.nome} ArrivaDaDettSinistro={true}></PaginaTipiPolizze></CallbackWrapper>);                     
        };
        this.dialogInstance1.close = (args) => {
            let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
            const tabella = 'PolizzaFiltro2';
            const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
            CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData => {
                if (retData.length > 0) {
                    let emptyObj = {};
                    Object.keys(retData[0]).forEach(e => emptyObj[e] = '');
                    retData.unshift(emptyObj);
                }
                sessionStorage.setItem('JellyfishTechnologyTabellaPolizzaFiltro2', CustomJS.compressAndEncode(retData));
                this.dsPolizzaFiltro2 = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaPolizzaFiltro2')))?.map(elm => { return { VALUE: elm.id_tipo_polizza.toString(), TEXT: elm.descrizione, nome: elm.nome } }), 'TEXT');
                document.getElementById(`tipoPolizza${idSin}`).ej2_instances[0].dataSource = this.dsPolizzaFiltro2;
                let salvaTipoPolizza = document.getElementById(`tipoPolizza${idSin}`).ej2_instances[0].value; 
                document.getElementById(`tipoPolizza${idSin}`).ej2_instances[0].value = null;
                document.getElementById(`tipoPolizza${idSin}`).ej2_instances[0].text = null;     
                setTimeout(() => {  
                    document.getElementById(`tipoPolizza${idSin}`).ej2_instances[0].value = salvaTipoPolizza;
                }, 300);    
            }, messaggio => {
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );

            }, "SELECT [02001].id_tipo_polizza, [02001].nome, CONCAT([nome], '  Mod. ', [modello], ' Ed. ', [edizione]) AS descrizione FROM [02001] ORDER BY CONCAT([nome],'   Mod. ', [modello], ' Ed. ', [edizione]);");
        };
        this.dialogInstance1.show(); 
    }
    btnAmministratori() {  
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.dialogInstance1.header = 'TABELLA AMMINISTRATORI';  
        this.dialogInstance1.width = '90%';
        this.dialogInstance1.height = '85%';
        this.dialogInstance1.minHeight = '85%';
        this.dialogInstance1.buttons = [];   
        this.dialogInstance1.beforeClose = undefined;
        this.dialogInstance1.beforeOpen = (args) => {
            const container = document.getElementById('defaultDialogContent');
            const root = createRoot(container);      
            root.unmount();                   
        }
        this.dialogInstance1.open = (args) => {
            ++this.childKey;
            const container = document.getElementById('defaultDialogContent');
            const root = createRoot(container);                         
            root.render(<CallbackWrapper callback={() => {
                setTimeout(() => { 
                    document.getElementById('GridAmministratori').ej2_instances[0].pageSettings.pageSize = 6;
                }, 300);           
            }}><PaginaAmministratori key={this.childKey} DescrAmministratore={document.getElementById(`amministratore${idSin}`).ej2_instances[0].text} ArrivaDaDettSinistro={true}></PaginaAmministratori></CallbackWrapper>);                     
        };  
        this.dialogInstance1.close = (args) => {
            this.dsAmministratoreFiltro = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00012'))).map(elm => { return { VALUE: elm.id_amministratore.toString(), TEXT: elm.ragione_sociale, telefono1: elm.telefono1, email1: elm.email1 } }), 'TEXT');
            document.getElementById(`amministratore${idSin}`).ej2_instances[0].dataSource = this.dsAmministratoreFiltro;
            let salvaAmm = document.getElementById(`amministratore${idSin}`).ej2_instances[0].value; 
            document.getElementById(`amministratore${idSin}`).ej2_instances[0].value = null;
            document.getElementById(`amministratore${idSin}`).ej2_instances[0].text = null;     
            setTimeout(() => {  
                document.getElementById(`amministratore${idSin}`).ej2_instances[0].value = salvaAmm;
            }, 300);   
        };
        this.dialogInstance1.show(); 
    }
    btnUbicazioneRischio(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let indirizzo = `${document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value}+${document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value}+${document.getElementById(`capUbicazioneRischio${idSin}`).ej2_instances[0].value}+${document.getElementById(`provinciaUbicazioneRischio${idSin}`).ej2_instances[0].value}`;
        this.dialogInstance2.buttons = [];
        this.dialogInstance2.height = '90%';
        this.dialogInstance2.minHeight='80%';
        this.dialogInstance2.width = '80%';  
        document.getElementById('mappaGoogle').src=`https://www.google.com/maps/embed/v1/place?key=AIzaSyDIusSsqd2WctS-RfkcEJjFLuP7q3d0pcI&q=${indirizzo}`;  
        this.dialogInstance2.show();   
    }
    btnNumPolizza(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (CustomJS.isNullEmpty(document.getElementById(`numeroPolizza${idSin}`).ej2_instances[0].value?.trim())) {
            return;
        }
        else{
            const QueryExec = `SELECT [01001].* FROM [01001] WHERE [01001].id_sinistro <> ${document.getElementById(`idSinistro${idSin}`).value} AND [01001].numero_polizza = '${document.getElementById(`numeroPolizza${idSin}`).value}' ORDER BY [01001].id_sinistro DESC`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) {
                    document.getElementById(`contraente${idSin}`).ej2_instances[0].value = retData[0].contr_nominativo;  
                    document.getElementById(`telefonoContraente${idSin}`).ej2_instances[0].value = retData[0].contr_tel1;       
                    document.getElementById(`emailContraente${idSin}`).ej2_instances[0].value = retData[0].contr_email1;       
                    document.getElementById(`assicurato${idSin}`).ej2_instances[0].value = retData[0].ass_nominativo;  
                    document.getElementById(`telefonoAssicurato${idSin}`).ej2_instances[0].value = retData[0].ass_tel1;       
                    document.getElementById(`emailAssicurato${idSin}`).ej2_instances[0].value = retData[0].ass_email1;       
                    document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value = retData[0].indirizzo;       
                    document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value = retData[0].citta;  
                    document.getElementById(`capUbicazioneRischio${idSin}`).ej2_instances[0].value = retData[0].cap;       
                    document.getElementById(`provinciaUbicazioneRischio${idSin}`).ej2_instances[0].value = retData[0].provincia; 
                    this.aggiornaDettaglio('contr_nominativo', retData[0].contr_nominativo, 'CHAR');
                    this.aggiornaDettaglio('contr_tel1', retData[0].contr_tel1, 'CHAR');
                    this.aggiornaDettaglio('contr_email1', retData[0].contr_email1, 'CHAR');
                    this.aggiornaDettaglio('ass_nominativo', retData[0].ass_nominativo, 'CHAR');
                    this.aggiornaDettaglio('ass_tel1', retData[0].ass_tel1, 'CHAR');
                    this.aggiornaDettaglio('ass_email1', retData[0].ass_email1, 'CHAR');
                    this.aggiornaDettaglio('indirizzo', retData[0].indirizzo, 'CHAR');
                    this.aggiornaDettaglio('citta', retData[0].citta, 'CHAR');
                    this.aggiornaDettaglio('cap', retData[0].cap, 'CHAR');
                    this.aggiornaDettaglio('provincia', retData[0].provincia, 'CHAR');   
                }
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true); 
        }
    }
    btnVediCercaCGAPolizze() {
        let timeout = 0;
        if (this.dialogInstance7.visible === true) {
            this.dialogInstance7.hide();
            timeout = 500;
        }
        setTimeout(() => {
            this.dialogInstance7.header = `<div><span id='dialog-title-${this.dialogInstance7.id}' class='title'>VISUALIZZAZIONE CGA POLIZZE</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance7.id}' title='Minimizza la finestra'></span></div>`;    
            this.dialogInstance7.width = '60%';
            this.dialogInstance7.height = '90%';
            this.dialogInstance7.minHeight = '90%';
            this.dialogInstance7.buttons = [];    
            this.dialogInstance7.beforeOpen = (args) => {                
                const container = document.getElementById('defaultDialogContent4');
                const root = createRoot(container);                         
                root.unmount();                     
            };
            this.dialogInstance7.open = (args) => {                
                this.onMinimizeDialogOpen(`VISUALIZZAZIONE CGA POLIZZE`, '520px', '170', '90%', this.dialogInstance7);
                setTimeout(() => {
                    const container = document.getElementById('defaultDialogContent4');
                    const root = createRoot(container);                         
                    root.render(this.fileManagerRender2());                     
                }, 300);
            };
            this.dialogInstance7.show();   
        }, timeout);
    }
    btnVediCercaDocsPolizze() {
        let timeout = 0;
        if (this.dialogInstance7.visible === true) {
            this.dialogInstance7.hide();
            timeout = 500;
        }
        setTimeout(() => {
            this.dialogInstance7.header = `<div><span id='dialog-title-${this.dialogInstance7.id}' class='title'>VISUALIZZAZIONE DOCUMENTAZIONE VARIA</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance7.id}' title='Minimizza la finestra'></span></div>`;    
            this.dialogInstance7.width = '60%';
            this.dialogInstance7.height = '90%';
            this.dialogInstance7.minHeight = '90%';
            this.dialogInstance7.buttons = [];    
            this.dialogInstance7.beforeOpen = (args) => {                
                const container = document.getElementById('defaultDialogContent4');
                const root = createRoot(container);                         
                root.unmount();                     
            };
            this.dialogInstance7.open = (args) => {                
                this.onMinimizeDialogOpen(`VISUALIZZAZIONE DOCUMENTAZIONE VARIA`, '520px', '170', '90%', this.dialogInstance7);
                setTimeout(() => {
                    const container = document.getElementById('defaultDialogContent4');
                    const root = createRoot(container);                         
                    root.render(this.fileManagerRender3());                     
                }, 300);
            };
            this.dialogInstance7.show();   
        }, timeout);
    }
    btnSinAssociato(){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if(CustomJS.isNullEmpty(document.getElementById(`associazionePolizza${idSin}`).value)){
            return;
        }
        else{
            let FiltroAggiuntivo = `id_interno_sinistro = ${document.getElementById(`associazionePolizza${idSin}`).value}`;  
            if (!CustomJS.isNullEmpty(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].compagnie)) {
                FiltroAggiuntivo = FiltroAggiuntivo.concat(` AND id_compagnia IN (${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].compagnie})`);
            }
            this.toggleBlocking();
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/situazioneSinistri/Tutti?FiltroAggiuntivo=${FiltroAggiuntivo}`, '', 'GET',
            '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                if (retData.response === 'KO') {
                    let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),                    
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking();
                    return;
                }
                retData.forEach(elm => {
                    elm.data_timer = CustomJS.isNullEmpty(elm.data_timer) ? '' : moment(elm.data_timer).format('DD/MM/YYYY');
                    elm.strDataIncarico = CustomJS.isNullEmpty(elm.strDataIncarico) ? '' : moment(elm.strDataIncarico).format('DD/MM/YYYY');
                    elm.data_invio_atto = CustomJS.isNullEmpty(elm.data_invio_atto) ? '' : moment(elm.data_invio_atto).format('DD/MM/YYYY');
                    elm.data_chiusura_sinistro = CustomJS.isNullEmpty(elm.data_chiusura_sinistro) ? '' : moment(elm.data_chiusura_sinistro).format('DD/MM/YYYY');
                }); 
               if(retData.length > 0){
                    setTimeout(() => { 
                        this.creaTab(retData[0], false);
                        this.toggleBlocking();  
                    }, 1000); 
               }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking();
            }, true, false);
        }
    }
    btnSinAltriAssic(){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if(CustomJS.isNullEmpty(document.getElementById(`altriSinistriAssicurato${idSin}`).value)){
            return;
        }
        else{
            let FiltroAggiuntivo = `id_interno_sinistro = ${document.getElementById(`altriSinistriAssicurato${idSin}`).value}`;  
            if (!CustomJS.isNullEmpty(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].compagnie)) {
                FiltroAggiuntivo = FiltroAggiuntivo.concat(` AND id_compagnia IN (${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].compagnie})`);
            }
            this.toggleBlocking();
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/situazioneSinistri/Tutti?FiltroAggiuntivo=${FiltroAggiuntivo}`, '', 'GET',
            '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                if (retData.response === 'KO') {
                    let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),                    
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking();
                    return;
                }
                retData.forEach(elm => {
                    elm.data_timer = CustomJS.isNullEmpty(elm.data_timer) ? '' : moment(elm.data_timer).format('DD/MM/YYYY');
                    elm.strDataIncarico = CustomJS.isNullEmpty(elm.strDataIncarico) ? '' : moment(elm.strDataIncarico).format('DD/MM/YYYY');
                    elm.data_invio_atto = CustomJS.isNullEmpty(elm.data_invio_atto) ? '' : moment(elm.data_invio_atto).format('DD/MM/YYYY');
                    elm.data_chiusura_sinistro = CustomJS.isNullEmpty(elm.data_chiusura_sinistro) ? '' : moment(elm.data_chiusura_sinistro).format('DD/MM/YYYY');
                }); 
               if(retData.length > 0){
                    setTimeout(() => { 
                        this.creaTab(retData[0], false);
                        this.toggleBlocking();  
                    }, 1000); 
               }
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking();
            }, true, false);
        }
    }  
    btnContraente(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        document.getElementById(`assicurato${idSin}`).ej2_instances[0].value = document.getElementById(`contraente${idSin}`).ej2_instances[0].value;  
        document.getElementById(`telefonoAssicurato${idSin}`).ej2_instances[0].value = document.getElementById(`telefonoContraente${idSin}`).ej2_instances[0].value;       
        document.getElementById(`emailAssicurato${idSin}`).ej2_instances[0].value = document.getElementById(`emailContraente${idSin}`).ej2_instances[0].value;       
        document.getElementById(`danneggiato${idSin}`).ej2_instances[0].value = document.getElementById(`contraente${idSin}`).ej2_instances[0].value;  
        document.getElementById(`telefonoDanneggiato${idSin}`).ej2_instances[0].value = document.getElementById(`telefonoContraente${idSin}`).ej2_instances[0].value;       
        document.getElementById(`emailDanneggiato${idSin}`).ej2_instances[0].value = document.getElementById(`emailContraente${idSin}`).ej2_instances[0].value;       
        this.aggiornaDettaglio('ass_nominativo', document.getElementById(`assicurato${idSin}`).ej2_instances[0].value, 'CHAR');
        this.aggiornaDettaglio('ass_tel1', document.getElementById(`telefonoAssicurato${idSin}`).ej2_instances[0].value, 'CHAR');
        this.aggiornaDettaglio('ass_email1', document.getElementById(`emailAssicurato${idSin}`).ej2_instances[0].value, 'CHAR');
        this.aggiornaDettaglio('nominativo', document.getElementById(`danneggiato${idSin}`).ej2_instances[0].value, 'CHAR', '[00005]', true);
        this.aggiornaDettaglio('telefono1', document.getElementById(`telefonoDanneggiato${idSin}`).ej2_instances[0].value, 'CHAR', '[00005]', true);
        this.aggiornaDettaglio('email1', document.getElementById(`emailDanneggiato${idSin}`).ej2_instances[0].value, 'CHAR', '[00005]', true);
    }
    btnApriMaschera(){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (CustomJS.isNullEmpty(document.getElementById(`divNotaManuale${idSin}`).offsetParent))
            document.getElementById(`divNotaManuale${idSin}`).style.removeProperty('display');
        else
            document.getElementById(`divNotaManuale${idSin}`).style.display = 'none';
    }
    btnAggiornaNota() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
    }
    btnCancellaNota() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.dialogInstance3.header = 'Attenzione!!';
        this.dialogInstance3.content = "Confermare la cancellazione dell'ultima NOTA inserita?";
        this.dialogInstance3.width = '23%';
        this.dialogInstance3.height = '22%';
        this.dialogInstance3.minHeight = '22%';
        this.dialogInstance3.buttons = [
            { click: () => { 
                this.dialogInstance3.hide();
                setTimeout(() => { 
                    const dataObj = {
                        IdSinistro : document.getElementById(`idSinistro${idSin}`).value            
                    };
                    const QueryExec = `DELETE FROM [01003] WHERE [01003].id_nota = (SELECT MAX([01003_1].id_nota) FROM [01003] AS [01003_1] WHERE [01003_1].id_sinistro = @id_sinistro)`;        
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                        toast.success('Ultima nota del sinistro cancellata con successo!!', {
                            containerId: 'toastContainer1',
                            //onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 8000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}); 
                        this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                    }, true, true);
                }, 500);                           
            }, 
            buttonModel: { 
                content: 'CONFERMA', 
                cssClass: 'e-primary',
                isPrimary: true 
            }
            },
            {
                click: () => {
                    this.dialogInstance3.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.dialogInstance3.show();   
    }
    btnCancellaNotaInterl(){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        document.getElementById(`mascheraInterl${idSin}`).ej2_instances[0].value = null;  
    }
    btnInviaInterl(){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (CustomJS.isNullEmpty(document.getElementById(`mascheraInterl${idSin}`).ej2_instances[0].value?.trim())) {
            return;
        }
        else{
            const dataObj = {
                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                DescrNota: `${document.getElementById(`mascheraInterl${idSin}`).ej2_instances[0].value.replaceAll('<','[').replaceAll('>',']')}`,
                Stato: null,
                CheckInterl: document.getElementById(`checkInterl${idSin}`).ej2_instances[0].checked === true ? 2 : null
            };
            const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, @check_interl, @descr_nota"  +
                                ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 0, null)";
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                if (document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === CustomJS.codiceCompagniaUnipolSAI
                    && !CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value) 
                    && document.getElementById(`checkInterl${idSin}`).ej2_instances[0].checked === true) {
                    this.dialogInstance3.header = 'Attenzione!!';
                    this.dialogInstance3.content = `Verrà inviata una comunicazione ad <b>UnipolSAI</b> di tipo <b>Interlocutoria Testuale</b>, contenente il testo inserito nel pannello delle note. Confermare l'operazione?`;
                    this.dialogInstance3.width = '25%';
                    this.dialogInstance3.height = '20%';
                    this.dialogInstance3.minHeight = '20%';
                    this.dialogInstance3.buttons = [
                        { click: () => { 
                            this.dialogInstance3.hide();
                            this.invioInterlocutoriaUnipolSAI(document.getElementById(`idIncarico${idSin}`).value, null, 'InterlocutoriaTestuale');
                        }, 
                        buttonModel: { 
                            content: 'CONFERMA', 
                            cssClass: 'e-primary',
                            isPrimary: true 
                        }
                        },
                        {
                            click: () => {
                                this.dialogInstance3.hide();
                                document.getElementById(`mascheraInterl${idSin}`).ej2_instances[0].value = null; 
                                document.getElementById(`checkInterl${idSin}`).ej2_instances[0].checked = false;
                                document.getElementById(`divNotaManuale${idSin}`).style.display = 'none';              
                            },
                            buttonModel: {
                                content: 'ANNULLA',
                            }
                        }
                    ];
                    this.dialogInstance3.show();
                }
                else {
                    document.getElementById(`mascheraInterl${idSin}`).ej2_instances[0].value = null; 
                    document.getElementById(`checkInterl${idSin}`).ej2_instances[0].checked = false;
                    document.getElementById(`divNotaManuale${idSin}`).style.display = 'none';     
                }
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);
        }
    }
    btnSollecito(args) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.dialogInstance3.header = 'Attenzione!!';
        this.dialogInstance3.content = "Inserire un sollecito per questo sinistro?";
        this.dialogInstance3.width = '23%';
        this.dialogInstance3.height = '22%';
        this.dialogInstance3.minHeight = '22%';
        this.dialogInstance3.buttons = [
            { click: () => { 
                this.dialogInstance3.hide();
                setTimeout(() => { 
                    const dataObj = {
                        IdSinistro: document.getElementById(`idSinistro${idSin}`).value,
                        IdTipoSollecito:  document.getElementById(`ddlSollecito${idSin}`).ej2_instances[0].value,
                    };
                    const QueryExec = `INSERT INTO [01007] (id_sinistro, data_sollecito, id_fonte_sollecito ) VALUES (@id_sinistro, CURRENT_TIMESTAMP, @id_tipo_sollecito)`;        
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                        this.getStatoAzione(8).then(ret => {
                            const id_stato_tmp = ret.stato;
                            if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                                document.getElementById(`idStato${idSin}`).value = id_stato_tmp.toString();
                                this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value }); 
                                this.aggiornaStatoSinistro(true).then(ret2 => {
                                    if (ret2 === 'OK') {                
                                        document.getElementById(`stato${idSin}`).ej2_instances[0].value = ret.descr_stato;
                                    }
                                }).catch(messaggio => { 
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                });        
                            }
                            this.getAzioneNota(8).then(ret3 => {
                                if (ret3 === true) {
                                    const dataObj = {
                                        IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                        DescrNota: `Sollecitato da ${document.getElementById(`ddlSollecito${idSin}`).ej2_instances[0].text}`,
                                        Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                        Stato: null
                                    };
                                    const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                        ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                        this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    });
                                }
                                document.getElementById(`ddlSollecito${idSin}`).ej2_instances[0].value = null;  
                                document.getElementById(`ddlSollecito${idSin}`).ej2_instances[0].text = null;           
                            }).catch(messaggio => { 
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            });                
                        }).catch(messaggio => { 
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        }); 
                        CustomJS.getParametroDB(9).then(ret => {
                            if (ret === 1) 
                                this.preparaEmailStato(document.getElementById(`idStato${idSin}`).value, true);
                        }).catch(messaggio => { 
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });  
                        const QueryExec6 = `SELECT [01007].id_sinistro, Count([01007].id) AS conteggio, [02019].descrizione FROM [01007] INNER JOIN [02019] ON [01007].id_fonte_sollecito = [02019].id_tipo_sollecito where id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value} GROUP BY [01007].id_sinistro, [02019].descrizione`;        
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec6, '', retData6 => {
                            let stringa = '';
                            retData6.forEach(elm =>  { 
                                stringa += elm.descrizione + " " + elm.conteggio + " - ";
                            });     
                            document.getElementById(`noteSollecito${idSin}`).ej2_instances[0].value = stringa;       
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        }, true, true);    
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                    }); 
                }, 500);                         
            }, 
            buttonModel: { 
                content: 'CONFERMA', 
                cssClass: 'e-primary',
                isPrimary: true 
            }
            },
            {
            click: () => {
                this.dialogInstance3.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];
        this.dialogInstance3.show();                                       
    }
    btnIncaricaCat(){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if(document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].value === null){
            return;
        }
        this.searchCATAperta().then(ret => {
            if (ret === true) {
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = 'Un controllo da parte di 2 CAT è ancora in corso per questo sinistro, chiuderne almeno uno prima di aggiungerne un altro';
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '21%';
                this.dialogInstance3.minHeight = '21%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show();                                       
            }
            else {
                const findObj = _.find(document.getElementById(`gridCatAssegnati${idSin}`).ej2_instances[0].dataSource, elm => { return elm.id_collaboratore === parseInt(document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].value) && elm.chiusa === false & elm.revocata === false; });
                if (!CustomJS.isNullEmpty(findObj)) {
                    this.dialogInstance3.header = 'Attenzione!!';
                    this.dialogInstance3.content = `Il CAT ${document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].text} non è asseganbile in quanto ha già un incarico attivo su questo sinistro`;
                    this.dialogInstance3.width = '23%';
                    this.dialogInstance3.height = '20%';
                    this.dialogInstance3.minHeight = '20%';
                    this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                    this.dialogInstance3.show();                                       
                    return;    
                }
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = `Assegnare il CAT ${document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].text} a questo sinistro?`;
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '22%';
                this.dialogInstance3.minHeight = '22%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.dialogInstance3.hide();
                        document.getElementById(`noteCat${idSin}`).ej2_instances[0].refresh();
                        setTimeout(() => { 
                            const dataObj = {
                                IdCollaboratore: document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].value,
                                IdSinistro: document.getElementById(`idSinistro${idSin}`).value,
                                NoteCat: document.getElementById(`noteCat${idSin}`).ej2_instances[0].value,
                                FFabbricato : document.getElementById(`preesistenzaFabbricatoCat${idSin}`).ej2_instances[0].checked,                              
                                FContenuto: document.getElementById(`preesistenzaMacchinarioCat${idSin}`).ej2_instances[0].checked,                              
                                FMacchinario: document.getElementById(`preesistenzaContenutoCat${idSin}`).ej2_instances[0].checked                                                          
                            };
                            const QueryExec = "INSERT INTO [01004] (id_collaboratore, t_assegnato,id_sinistro,note_cat,pre_fabbricato,pre_contenuto,pre_macchinario ) VALUES (@id_collaboratore, CURRENT_TIMESTAMP, @id_sinistro, @note_cat, @f_fabbricato, @f_contenuto, @f_macchinario)";        
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                this.getStatoAzione(2).then(ret => {
                                    const id_stato_tmp = ret.stato;
                                    if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                                        document.getElementById(`idStato${idSin}`).value = id_stato_tmp.toString();
                                        this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value }); 
                                        this.aggiornaStatoSinistro(true).then(ret2 => {
                                            if (ret2 === 'OK') {                
                                                document.getElementById(`stato${idSin}`).ej2_instances[0].value = ret.descr_stato;
                                            }
                                        }).catch(messaggio => { 
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,           
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true}
                                            );
                                        });        
                                    }
                                    this.getAzioneNota(2).then(ret3 => {
                                        if (ret3 === true) {
                                            const dataObj = {
                                                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                                DescrNota: `Assegnato il CAT ${document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].text}`,
                                                Stato: id_stato_tmp
                                            };
                                            const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                                ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                                this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                                document.getElementById(`noteCat${idSin}`).ej2_instances[0].value = null;  
                                                document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].value = null; 
                                                document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].text = null;                                                           
                                                document.getElementById(`preesistenzaFabbricatoCat${idSin}`).ej2_instances[0].checked = false;                              
                                                document.getElementById(`preesistenzaMacchinarioCat${idSin}`).ej2_instances[0].checked = false;                              
                                                document.getElementById(`preesistenzaContenutoCat${idSin}`).ej2_instances[0].checked = false;                  
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,           
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true}
                                                );
                                            }, true, true);
                                        }
                                        else {
                                            document.getElementById(`noteCat${idSin}`).ej2_instances[0].value = null;  
                                            document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].value = null; 
                                            document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].text = null;                                                           
                                            document.getElementById(`preesistenzaFabbricatoCat${idSin}`).ej2_instances[0].checked = false;                              
                                            document.getElementById(`preesistenzaMacchinarioCat${idSin}`).ej2_instances[0].checked = false;                              
                                            document.getElementById(`preesistenzaContenutoCat${idSin}`).ej2_instances[0].checked = false; 
                                        }
                                    }).catch(messaggio => { 
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    });                
                                }).catch(messaggio => { 
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                });   
                                const QueryExec7 = `SELECT [00006].ragione_sociale, [00006].telefono1, [00006].telefono2, [00006].email1, [00006].email2, [00006].user_sito, [00006].password_sito, [01004].* FROM [01004] INNER JOIN [00006] ON [01004].id_collaboratore = [00006].id_collaboratore WHERE [01004].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec7, '', retData7 => {
                                    this.dsCatAssegnati = retData7.map(elm => { return {id_cat: elm.id_cat, id_collaboratore: elm.id_collaboratore, ragione_sociale: elm.ragione_sociale,  t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_sopralluogo: CustomJS.isNullEmpty(elm.t_sopralluogo) ? '' : moment(elm.t_sopralluogo).format('DD/MM/YYYY HH:mm'), t_riconsegnato: CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), telefono1: elm.telefono1, telefono2: elm.telefono2, email1: elm.email1, email2: elm.email2, note_cat: elm.note_cat, chiusa: elm.chiusa, revocata: elm.revocata, utente_JFCAT: elm.user_sito, password_JFCAT: elm.password_sito, inviato_JFCAT: elm.inviato_JFCAT, revocato_JFCAT: elm.revocato_JFCAT}}); 
                                    document.getElementById(`gridCatAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsCatAssegnati;
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                }, true, true);  
                                const QueryExec8 = `SELECT MAX([01004].id_cat) AS MaxIdCAT FROM [01004] WHERE [01004].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec8, '', retData8 => {
                                    if (!CustomJS.isNullEmpty(document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].itemData.utente_JFCAT))
                                        this.creaIncaricoJFCAT(document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].itemData.utente_JFCAT, document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].itemData.password_JFCAT, retData8[0].MaxIdCAT);                                                    
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                }, true, true);  
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            }, true, true); 
                        }, 700);                           
                    }, 
                    buttonModel: { 
                        content: 'CONFERMA', 
                        cssClass: 'e-primary',
                        isPrimary: true 
                    }
                 },
                 {
                    click: () => {
                        this.dialogInstance3.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                 }
                ];
                this.dialogInstance3.show();                                       
            }
        }).catch(messaggio => { 
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        });
    }
    creaIncaricoJFCAT(utenteJFCAT, passwordJFCAT, idCat) { 
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        const dataObj = {
            GuidIncarico: CustomJS.createGuid4(),
            IdIncarico: document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value.trim(),
            IdIncaricoCompagnia: !CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value) ? document.getElementById(`idIncarico${idSin}`).value : null,
            IdCAT: idCat,
            DataIncarico: new Date(),  
            Stato: 'DAINV',
            StatoRevoca : 'REVOC',
            StatoArchivio: 'ARCH',
            Nominativo: document.getElementById(`danneggiato${idSin}`).ej2_instances[0].value,         
            Indirizzo: document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value,
            Citta: document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value,
            CAP: document.getElementById(`capUbicazioneRischio${idSin}`).ej2_instances[0].value,
            Provincia: document.getElementById(`provinciaUbicazioneRischio${idSin}`).ej2_instances[0].value,
            Nazione: 'ITA',
            Telefono: document.getElementById(`telefonoDanneggiato${idSin}`).ej2_instances[0].value,
            Contatto: document.getElementById(`danneggiato${idSin}`).ej2_instances[0].value,
            Email: document.getElementById(`emailDanneggiato${idSin}`).ej2_instances[0].value,            
            CodUtente: utenteJFCAT,
            PasswordUtente: passwordJFCAT 
        };
        this.toggleBlocking();
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd2}/api/JellyfishTech/creaIncaricoDaJFTECH`, JSON.stringify(dataObj), 'POST',
            'application/json', token, retData => { 
                if (retData.response === 'KO') {
                    if (retData.msg === 'UtenteErrato' || retData.msg === 'IncaricoPresente') {
                        this.toggleBlocking();
                        toast.error(CustomJS.messaggioIncCreatoJFCATKO, {
                            containerId: 'toastContainer1',
                            //onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 8000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true});
                        this.dialogInstance3.header = 'Attenzione!!';
                        this.dialogInstance3.content = CustomJS.messaggioInvioErratoJFCAT;
                        this.dialogInstance3.width = '25%';
                        this.dialogInstance3.height = '22%';
                        this.dialogInstance3.minHeight = '22%';
                        this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                        this.dialogInstance3.show(); 
                    }
                    else {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true});
                        this.toggleBlocking();
                        return;
                    }
                }    
                if (retData.response === 'OK') {   
                    toast.success(CustomJS.messaggioIncCreatoJFCAT, {
                        containerId: 'toastContainer1',
                        //onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 8000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true});  
                    const dataObj = {
                        IdSinistro: document.getElementById(`idSinistro${idSin}`).value,
                        IdCat: idCat,
                    };
                    const strQuery =  !CustomJS.isNullEmpty(idCat) ? '[01004].id_cat = @id_cat' : '[01004].id_cat = (SELECT MAX([01004_1].id_cat) FROM [01004] AS [01004_1] WHERE [01004_1].id_sinistro = [01004].id_sinistro)';
                    const QueryExec = `UPDATE [01004] SET [01004].inviato_JFCAT = 1 WHERE ${strQuery} AND [01004].id_sinistro = @id_sinistro`;        
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                        const QueryExec2 = `SELECT [00006].ragione_sociale, [00006].telefono1, [00006].telefono2, [00006].email1, [00006].email2, [00006].user_sito, [00006].password_sito, [01004].* FROM [01004] INNER JOIN [00006] ON [01004].id_collaboratore = [00006].id_collaboratore WHERE [01004].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec2, '', retData2 => {
                           this.dsCatAssegnati = retData2.map(elm => { return {id_cat: elm.id_cat, id_collaboratore: elm.id_collaboratore, ragione_sociale: elm.ragione_sociale,  t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_sopralluogo: CustomJS.isNullEmpty(elm.t_sopralluogo) ? '' : moment(elm.t_sopralluogo).format('DD/MM/YYYY HH:mm'), t_riconsegnato: CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), telefono1: elm.telefono1, telefono2: elm.telefono2, email1: elm.email1, email2: elm.email2, note_cat: elm.note_cat, chiusa: elm.chiusa, revocata: elm.revocata, utente_JFCAT: elm.user_sito, password_JFCAT: elm.password_sito, inviato_JFCAT: elm.inviato_JFCAT, revocato_JFCAT: elm.revocato_JFCAT}}); 
                           document.getElementById(`gridCatAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsCatAssegnati;
                           this.toggleBlocking();
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        }, true, true); 
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                        this.toggleBlocking();
                    }, true, true);
                }  
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            this.toggleBlocking();
        }, true, false);
    }
    revocaIncaricoJFCAT(utenteJFCAT, passwordJFCAT, idCat) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        const dataObj = {
            IdIncarico: document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value.trim(),
            Stato: 'REVOC',
            CodUtente: utenteJFCAT, 
            PasswordUtente: passwordJFCAT 
        };
        this.toggleBlocking();
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd2}/api/JellyfishTech/revocaIncaricoDaJFTECH`, JSON.stringify(dataObj), 'POST',
            'application/json', token, retData => { 
                if (retData.response === 'KO') {
                    if (retData.msg === 'IncaricoNonPresente') {
                        this.toggleBlocking();
                        toast.error(CustomJS.messaggioRevocaJFCATKO, {
                            containerId: 'toastContainer1',
                            //onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 8000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true});
                        this.dialogInstance3.header = 'Attenzione!!';
                        this.dialogInstance3.content = CustomJS.messaggioRevocaErrataJFCAT;
                        this.dialogInstance3.width = '25%';
                        this.dialogInstance3.height = '23%';
                        this.dialogInstance3.minHeight = '23%';
                        this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                        this.dialogInstance3.show(); 
                    }
                    else {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true});
                        this.toggleBlocking();
                        return;
                    }
                }    
                if (retData.response === 'OK') {   
                    toast.success(CustomJS.messaggioRevocaJFCAT, {
                        containerId: 'toastContainer1',
                        //onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 8000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true});   
                    const dataObj = {
                        IdSinistro: document.getElementById(`idSinistro${idSin}`).value,
                        IdCat: idCat,
                    };
                    const QueryExec = `UPDATE [01004] SET [01004].revocato_JFCAT = 1 WHERE [01004].id_cat = @id_cat AND [01004].id_sinistro = @id_sinistro`;        
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                        const QueryExec2 = `SELECT [00006].ragione_sociale, [00006].telefono1, [00006].telefono2, [00006].email1, [00006].email2, [00006].user_sito, [00006].password_sito, [01004].* FROM [01004] INNER JOIN [00006] ON [01004].id_collaboratore = [00006].id_collaboratore WHERE [01004].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec2, '', retData2 => {
                           this.dsCatAssegnati = retData2.map(elm => { return {id_cat: elm.id_cat, id_collaboratore: elm.id_collaboratore, ragione_sociale: elm.ragione_sociale,  t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_sopralluogo: CustomJS.isNullEmpty(elm.t_sopralluogo) ? '' : moment(elm.t_sopralluogo).format('DD/MM/YYYY HH:mm'), t_riconsegnato: CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), telefono1: elm.telefono1, telefono2: elm.telefono2, email1: elm.email1, email2: elm.email2, note_cat: elm.note_cat, chiusa: elm.chiusa, revocata: elm.revocata, utente_JFCAT: elm.user_sito, password_JFCAT: elm.password_sito, inviato_JFCAT: elm.inviato_JFCAT, revocato_JFCAT: elm.revocato_JFCAT}}); 
                           document.getElementById(`gridCatAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsCatAssegnati;
                           this.toggleBlocking();
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                            this.toggleBlocking();
                        }, true, true); 
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                        this.toggleBlocking();
                    }, true, true);                                  
                }  
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            this.toggleBlocking();
        }, true, false);
    }
    btnIncaricaRfd(){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if(document.getElementById(`assegnaRfd${idSin}`).ej2_instances[0].value === null){
            return;
        }
        this.searchRfdAperta().then(ret => {
            if (ret === true) {
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = 'Un controllo di RFD è ancora in corso per questo sinistro, chiuderlo prima di aggiungerne un altro';
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '21%';
                this.dialogInstance3.minHeight = '21%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show();                                       
            }
            else {
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = `Assegnare un RFD ${document.getElementById(`assegnaRfd${idSin}`).ej2_instances[0].text} a questo sinistro?`;
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '22%';
                this.dialogInstance3.minHeight = '22%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.dialogInstance3.hide();
                        setTimeout(() => { 
                            const dataObj = {
                                IdCollaboratore: document.getElementById(`assegnaRfd${idSin}`).ej2_instances[0].value,
                                IdSinistro: document.getElementById(`idSinistro${idSin}`).value,
                                NoteRfd: document.getElementById(`noteRfd${idSin}`).ej2_instances[0].value,
                                FFabbricato : document.getElementById(`preesistenzaFabbricatoRfd${idSin}`).ej2_instances[0].checked,                              
                                FContenuto: document.getElementById(`preesistenzaMacchinarioRfd${idSin}`).ej2_instances[0].checked,                              
                                FMacchinario: document.getElementById(`preesistenzaContenutoRfd${idSin}`).ej2_instances[0].checked                                                          
                            };
                            const QueryExec = "INSERT INTO [01014] (id_collaboratore, t_assegnato,id_sinistro,note_rfd,pre_fabbricato,pre_contenuto,pre_macchinario) VALUES (@id_collaboratore, CURRENT_TIMESTAMP, @id_sinistro, @note_rfd, @f_fabbricato, @f_contenuto, @f_macchinario)";        
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                this.getStatoAzione(11).then(ret => {
                                    const id_stato_tmp = ret.stato;
                                    if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                                        document.getElementById(`idStato${idSin}`).value = id_stato_tmp.toString();
                                        this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value }); 
                                        this.aggiornaStatoSinistro(true).then(ret2 => {
                                            if (ret2 === 'OK') {                
                                                document.getElementById(`stato${idSin}`).ej2_instances[0].value = ret.descr_stato;
                                            }
                                        }).catch(messaggio => { 
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,           
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true}
                                            );
                                        });        
                                    }
                                    this.getAzioneNota(11).then(ret3 => {
                                        if (ret3 === true) {
                                            const dataObj = {
                                                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                                DescrNota: `Assegnato il RFD ${document.getElementById(`assegnaRfd${idSin}`).ej2_instances[0].text}`,
                                                Stato: id_stato_tmp
                                            };
                                            const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                                ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                                this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                                document.getElementById(`noteRfd${idSin}`).ej2_instances[0].value = null;  
                                                document.getElementById(`assegnaRfd${idSin}`).ej2_instances[0].value = null; 
                                                document.getElementById(`assegnaRfd${idSin}`).ej2_instances[0].text = null;                                                           
                                                document.getElementById(`preesistenzaFabbricatoRfd${idSin}`).ej2_instances[0].checked = false;                              
                                                document.getElementById(`preesistenzaMacchinarioRfd${idSin}`).ej2_instances[0].checked = false;                              
                                                document.getElementById(`preesistenzaContenutoRfd${idSin}`).ej2_instances[0].checked = false;                  
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,           
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true}
                                                );
                                            }, true, true);
                                        }
                                        else {
                                            document.getElementById(`noteRfd${idSin}`).ej2_instances[0].value = null;  
                                            document.getElementById(`assegnaRfd${idSin}`).ej2_instances[0].value = null; 
                                            document.getElementById(`assegnaRfd${idSin}`).ej2_instances[0].text = null;                                                           
                                            document.getElementById(`preesistenzaFabbricatoRfd${idSin}`).ej2_instances[0].checked = false;                              
                                            document.getElementById(`preesistenzaMacchinarioRfd${idSin}`).ej2_instances[0].checked = false;                              
                                            document.getElementById(`preesistenzaContenutoRfd${idSin}`).ej2_instances[0].checked = false;  
                                        }
                                    }).catch(messaggio => { 
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    });                
                                }).catch(messaggio => { 
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                });   
                                const QueryExec8 = `SELECT [00006].ragione_sociale, [00006].telefono1, [00006].telefono2, [00006].email1, [00006].email2, [01014].* FROM [01014] INNER JOIN [00006] ON [01014].id_collaboratore = [00006].id_collaboratore WHERE [01014].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec8, '', retData8 => {
                                    this.dsRfdAssegnati = retData8.map(elm => { return { id_rfd: elm.id_rfd, id_collaboratore: elm.id_collaboratore, ragione_sociale: elm.ragione_sociale, t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_riconsegnato: CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), telefono1: elm.telefono1, telefono2: elm.telefono2, email1: elm.email1, email2: elm.email2, note_rfd: elm.note_rfd, chiusa: elm.chiusa, revocata: elm.revocata}}); 
                                    document.getElementById(`gridRFDAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsRfdAssegnati;      
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                }, true, true);                                                      
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            }, true, true); 
                        }, 500);                           
                    }, 
                    buttonModel: { 
                        content: 'CONFERMA', 
                        cssClass: 'e-primary',
                        isPrimary: true 
                    }
                 },
                 {
                    click: () => {
                        this.dialogInstance3.hide();
                    },
                    buttonModel: {
                        content: 'ANNULLA',
                    }
                }
            ];
                this.dialogInstance3.show();                                       
            }
        }).catch(messaggio => { 
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        });
    }
    btnIncaricaPerito(){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (CustomJS.isNullEmpty(document.getElementById(`assegnaPerito${idSin}`).ej2_instances[0].value)) {
            return;
        }
        if (CustomJS.isNullEmpty(document.getElementById(`gradoSinistro${idSin}`).ej2_instances[0].value)) {
            this.dialogInstance3.header = 'Attenzione!!';
            this.dialogInstance3.content = "Per assegnare il Perito è necessario selezionare il Grado Sinistro!!";
            this.dialogInstance3.width = '22%';
            this.dialogInstance3.height = '21%';
            this.dialogInstance3.minHeight = '21%';
            this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
            this.dialogInstance3.show(); 
            return;
        }
        this.searchPeritoAperto().then(ret => {
            if(ret === true){
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = "Una perizia è ancora in corso per questo sinistro, revocare la perizia prima di aggiungerne un'altra";
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '21%';
                this.dialogInstance3.minHeight = '21%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show();                                       
            }
            else{
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = `Assegnare il perito ${document.getElementById(`assegnaPerito${idSin}`).ej2_instances[0].text} a questo sinistro?`;
                this.dialogInstance3.width = '23%';
                this.dialogInstance3.height = '22%';
                this.dialogInstance3.minHeight = '22%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.dialogInstance3.hide();
                        setTimeout(() => { 
                            const dataObj = {
                                IdUtente: document.getElementById(`assegnaPerito${idSin}`).ej2_instances[0].value,
                                IdSinistro: document.getElementById(`idSinistro${idSin}`).value,
                            };
                            const QueryExec = "INSERT INTO [01002] ( id_utente, t_assegnato,id_sinistro ) VALUES (@id_utente, CURRENT_TIMESTAMP, @id_sinistro)";        
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                this.getStatoAzione(1).then(ret => {
                                    const id_stato_tmp = ret.stato;
                                    if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                                        document.getElementById(`idStato${idSin}`).value = id_stato_tmp.toString();
                                        this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value }); 
                                        this.aggiornaStatoSinistro(true).then(ret2 => {
                                            if (ret2 === 'OK') {                
                                                document.getElementById(`stato${idSin}`).ej2_instances[0].value = ret.descr_stato;
                                            }
                                        }).catch(messaggio => { 
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,           
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true}
                                            );
                                        });        
                                    }
                                    this.getAzioneNota(1).then(ret3 => {
                                        if (ret3 === true) {
                                            const dataObj = {
                                                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                                DescrNota: `Assegnato il perito ${document.getElementById(`assegnaPerito${idSin}`).ej2_instances[0].text}`,
                                                Stato: id_stato_tmp
                                            };
                                            const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                                ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                                this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                                document.getElementById(`assegnaPerito${idSin}`).ej2_instances[0].value = null;
                                                document.getElementById(`assegnaPerito${idSin}`).ej2_instances[0].text = null;
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,           
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true}
                                                );
                                            }, true, true);
                                        }
                                        else {
                                            document.getElementById(`assegnaPerito${idSin}`).ej2_instances[0].value = null;
                                            document.getElementById(`assegnaPerito${idSin}`).ej2_instances[0].text = null;
                                        }
                                    }).catch(messaggio => { 
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    });  
                                }).catch(messaggio => { 
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                });   
                                const QueryExec9 = `SELECT CONCAT([09001].nome, ' ', [09001].cognome) AS nomeperito, [01002].* FROM [01002] INNER JOIN [09001] ON [01002].id_utente = [09001].id_utente WHERE [01002].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec9, '', retData9 => {
                                    this.dsPeritiAssegnati = retData9.map(elm => { return { id_perizia: elm.id_perizia, id_utente: elm.id_utente, nomeperito: elm.nomeperito, t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_riconsegnato:CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), chiusa: elm.chiusa, revocata: elm.revocata}}); 
                                    document.getElementById(`gridPeritiAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsPeritiAssegnati;         
                                    let dsPeritiSorted = _.sortBy(this.dsPeritiAssegnati, 't_assegnato').reverse(); 
                                    if (document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === '3') {
                                        //UNIPOL
                                        if (document.getElementById(`ripristinoFormaDiretta${idSin}`).ej2_instances[0].value === 'Si') 
                                            this.esportaSchedaPeritale(dsPeritiSorted[0].id_perizia, 40, dsPeritiSorted[0].nomeperito, false);
                                        else
                                            this.esportaSchedaPeritale(dsPeritiSorted[0].id_perizia, 5, dsPeritiSorted[0].nomeperito, false);
                                    }
                                    else
                                        //GENERICA
                                        this.esportaSchedaPeritale(dsPeritiSorted[0].id_perizia, 5, dsPeritiSorted[0].nomeperito, false);  
                                    if (this.dsPeritiAssegnati.filter(elm => { return elm.revocata === false }).length === 0) { 
                                        document.getElementById(`datiGenerali${idSin}`).style.display = 'none';
                                        document.getElementById(`datiUnipolSAI${idSin}`).style.display = 'none';
                                        document.getElementById(`footerDettaglio${idSin}`).style.display = 'none';
                                    }
                                    else {
                                        document.getElementById(`datiGenerali${idSin}`)?.style.removeProperty('display');
                                        document.getElementById(`datiUnipolSAI${idSin}`)?.style.removeProperty('display');
                                        document.getElementById(`footerDettaglio${idSin}`).style.removeProperty('display');
                                    }    
                                    if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti Semplici') === -1
                                        //&& JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Team Leader') === -1   //RIMOSSO SU RICHIESTA DI STARRANTINO PER ABILITARE INNANZITUTTO IL PERITO ANGRISANI
                                        && JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Referenti ACE') === -1) {
                                        const sortedDesc = _.sortBy(this.dsPeritiAssegnati.filter(elm => { return elm.revocata === false }), item => {
                                                                                                    const [day, month, year] = item.t_assegnato.split('/');
                                                                                                    return new Date(`${year}-${month}-${day}`);}).reverse();
                                        const objUtente = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella09001'))).filter(elm => { return elm.id_utente === sortedDesc[0].id_utente });
                                        const parEmail = (objUtente[0].smtp_server?.trim() ?? '') + ';' + (objUtente[0].smtp_porta?.trim() ?? '') + ';' + (objUtente[0].smtp_utente?.trim() ?? '') + ';' +
                                                        (objUtente[0].smtp_password?.trim() ?? '') + ';' + objUtente[0].smtp_ssl + ';' + (objUtente[0].smtp_mittente?.trim() ?? '') + ';' +
                                                        (objUtente[0].smtp_email?.trim() ?? '') + ';' + (objUtente[0].oauth2_client_id?.trim() ?? '') + ';' + (objUtente[0].oauth2_client_secret?.trim() ?? '') + ';' +
                                                        (objUtente[0].oauth2_refresh_token?.trim() ?? '');
                                        this.dsMittentiEmail = this.dsMittentiEmail.filter(elm => { return elm.VALUE !== 'PERITO' })
                                        this.dsMittentiEmail.push({ VALUE: 'PERITO', TEXT: `${parEmail.split(';')[5]} <${parEmail.split(';')[6]}>`, ParametriEmail: parEmail });   
                                    }             
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                }, true, true);
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            }, true, true); 
                            if (CustomJS.isNullEmpty(document.getElementById(`assegnaPerito${idSin}`).ej2_instances[0].itemData.email)){
                                this.dialogInstance3.header = 'Attenzione!!';
                                this.dialogInstance3.content = "Impossibile inviare mail al perito. Indirizzo assente";
                                this.dialogInstance3.width = '23%';
                                this.dialogInstance3.height = '21%';
                                this.dialogInstance3.minHeight = '21%';
                                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                                this.dialogInstance3.show();  
                            }
                            else{
                               let indirEmail = document.getElementById(`assegnaPerito${idSin}`).ej2_instances[0].itemData.email
                               let testoEmail = `E' stato assegnato il perito ${document.getElementById(`assegnaPerito${idSin}`).ej2_instances[0].text} per il sinistro ${document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value}.\n\nAttenzione, mail generata automaticamente. Si prega di non rispondere.`;
                               this.invioEmailGenerale('ACT', 'Assegnazione perito', testoEmail, indirEmail)
                               .then(ret => {
                                    if (ret === 0) {
                                    }
                                    else if (ret === 1) {
                                        toast.success('Email inviata con successo!!', {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,            
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });  
                                    }
                                    else if (ret.retCode === 2) {
                                        toast.error(`Attenzione!! Parametri per invio Email errati. L' errore riscontrato è il seguente : ${JSON.stringify(ret.msgErr)}`, {
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 15000,
                                            hideProgressBar: false,            
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });  
                                    }
                                }).catch(messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                });
                            }
                        }, 500);                           
                    }, 
                        buttonModel: { 
                            content: 'CONFERMA', 
                            cssClass: 'e-primary',
                            isPrimary: true 
                        }
                    },
                    {
                        click: () => {
                            this.dialogInstance3.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
                this.dialogInstance3.show();                                       
            }
        }).catch(messaggio => { 
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        });
    }
    invioEmailGenerale(mittente, oggetto, corpo, dest, destCC, destCCN) {
        return new Promise((resolve,reject) => {
            if(dest.trim() === '[PERITO]'|| dest.trim() === '[CQ]'){            
                resolve(0);
            }
            else {
                CustomJS.getParametroDB(45).then(ret => {                
                    let valoriSplit = ret.split(';');
                    let sendersName = '';
                    let finalSendersName = '';
                    let testoHtml = '<html><head></head><body>';
                    testoHtml += corpo.replaceAll('\n', '<br>');
                    testoHtml += '</body></html>';
                    if (mittente === 'ACT'){
                        sendersName = 'ACT';
                        finalSendersName = `${sendersName} ${valoriSplit[6]}`;
                    } 
                    else{
                        finalSendersName = mittente;
                    }  
                    let dataObj = {
                        server:valoriSplit[0],
                        porta:valoriSplit[1],
                        utente:valoriSplit[2],
                        password:valoriSplit[3],
                        SSL:valoriSplit[4],
                        oauth2ClientID: valoriSplit[7],
                        oauth2ClientSecret: valoriSplit[8],
                        oauth2RefreshToken: valoriSplit[9],
                        mittente: finalSendersName,
                        destinatario: dest,
                        emailMittente:valoriSplit[6],
                        emailCC: destCC,
                        emailBCC: destCCN,
                        soggetto: oggetto,
                        messaggioHtml: testoHtml
                    };
                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/sendEmail`, JSON.stringify(dataObj), 'POST',
                        'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => {
                            if (retData.response === 'KO')
                                resolve({ retCode: 2, msgErr: retData.msg }); 
                            else                            
                                resolve(1);               
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        reject(messaggio); 
                    }, true, false)
                }).catch(messaggio => { 
                    reject(messaggio);                    
                }); 
            }
        });
    }
    invioEmailRemake(dest, idEmail, matrice) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let testo_email = '';
        let oggetto_email = '';   
        return new Promise((resolve,reject) => {
            const QueryExec = `SELECT [01006].* from [01006] WHERE [01006].id_email = ${idEmail}`;                             
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) {
                    testo_email = retData[0].testo;
                    oggetto_email = retData[0].oggetto; 
                    matrice.forEach(elm2 =>  {
                        testo_email = testo_email.replace(elm2[0], elm2[1]);  
                        oggetto_email = oggetto_email.replace(elm2[0], elm2[1]);
                    }); 
                    this.dialogInstance6.header = `<div><span id='dialog-title-${this.dialogInstance6.id}' class='title'>AZIONE INVIO EMAIL</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance6.id}' title='Minimizza la finestra'></span></div>`;
                    ++this.childKey;
                    if (this.dialogInstance6.visible === true)
                        this.dialogInstance6.hide();
                    const container = document.getElementById('defaultDialogContent3');
                    const root = createRoot(container);                         
                    root.render(<CallbackWrapper callback={() => {
                        setTimeout(() => { 
                            let objTmp = _.filter(this.ddlInstance12.dataSource, elm => { return elm.VALUE === 'DEF' });
                            objTmp[0].TEXT = objTmp[0].TEXT.replace('cat@', 'info@');
                            this.ddlInstance12.refresh();  
                            if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti Semplici') > -1
                                || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Team Leader') > -1) {
                                    document.getElementById('ddlMittentiEmail').ej2_instances[0].value = 'PERS'; 
                            }
                            else
                                document.getElementById('ddlMittentiEmail').ej2_instances[0].value = 'DEF'; 
                            document.getElementById('ddlMittentiEmail').ej2_instances[0].readonly = false;
                            document.getElementById('destinatarioEmail_azioneEmail').ej2_instances[0].value = dest; 
                            if (document.getElementById(`ddlAChi${idSin}`).ej2_instances[0].value === '6' || document.getElementById(`ddlAChi${idSin}`).ej2_instances[0].value === '7')
                                document.getElementById('destinatarioCCEmail_azioneEmail').ej2_instances[0].value = document.getElementById(`emailAgenzia${idSin}`).ej2_instances[0].value;
                            document.getElementById('oggettoEmail_azioneEmail').ej2_instances[0].value = oggetto_email; 
                            document.getElementById('testoEmail_azioneEmail').ej2_instances[0].value = testo_email.replaceAll('\n', '<br>');
                            setTimeout(() => {
                                document.getElementById('testoEmail_azioneEmail_toolbar_SourceCode').click();
                                document.getElementById('testoEmail_azioneEmail_toolbar_Preview').click();
                            }, 500);
                            this.dialogInstance6.width = '60%';
                            this.dialogInstance6.height = '90%';
                            this.dialogInstance6.open = (args) => { 
                                this.onMinimizeDialogOpen('AZIONE INVIO EMAIL', '300px', '95', '90%', this.dialogInstance6);
                            }                              
                            this.dialogInstance6.beforeClose = (args) => {
                                 if (document.getElementById('testoEmail_azioneEmail').ej2_instances[0]) {
                                     document.getElementById('testoEmail_azioneEmail').ej2_instances[0].destroy();
                                     $('[id=testoEmail_azioneEmail_toolbar_FontColor_Target]').hide();
                                     $('[id=testoEmail_azioneEmail_toolbar_BackgroundColor_Target]').hide();    
                                 }
                                 $(`div[id^="allegato"]`).remove();
                            };    
                            this.dialogInstance6.buttons = [{ click: () => {
                                if (CustomJS.isNullEmpty(document.getElementById('destinatarioEmail_azioneEmail').ej2_instances[0].value?.trim())
                                    || CustomJS.validateEmails(document.getElementById('destinatarioEmail_azioneEmail').ej2_instances[0].value?.trim()) === false
                                    || CustomJS.validateEmails(document.getElementById('destinatarioCCEmail_azioneEmail').ej2_instances[0].value?.trim()) === false
                                    || CustomJS.validateEmails(document.getElementById('destinatarioCCNEmail_azioneEmail').ej2_instances[0].value?.trim()) === false) {            
                                    return;
                                }
                                else {
                                    CustomJS.getParametroDB(45).then(ret => { 
                                        this.toggleBlocking2(true);               
                                        let valoriSplit = ret.split(';');
                                        let valoriSplitPers = (this.ddlInstance12.value === 'PERS' || this.ddlInstance12.value === 'PERITO') ? this.ddlInstance12.itemData.ParametriEmail.split(';') : [];
                                        let finalSendersName = this.ddlInstance12.text.substring(0, this.ddlInstance12.text.indexOf('<'));   
                                        let oggetto = document.getElementById('oggettoEmail_azioneEmail').ej2_instances[0].value?.trim();
                                        dest = document.getElementById('destinatarioEmail_azioneEmail').ej2_instances[0].value?.trim();
                                        let destCC = document.getElementById('destinatarioCCEmail_azioneEmail').ej2_instances[0].value?.trim(); 
                                        let destCCN = document.getElementById('destinatarioCCNEmail_azioneEmail').ej2_instances[0].value?.trim();
                                        let arrAllegati = [];
                                        document.querySelectorAll(`div[id^="allegato"]`).forEach(elm => {
                                            let pathAllegato = elm.querySelector(`input[id^="pathAllegato"]`).value;
                                            arrAllegati.push({ filename: pathAllegato.substring(pathAllegato.lastIndexOf('/')+1), path: pathAllegato })
                                        });    
                                        let dataObj = {
                                            server: this.ddlInstance12.value === 'DEF' ? valoriSplit[0] : valoriSplitPers[0],
                                            porta: this.ddlInstance12.value === 'DEF' ? valoriSplit[1] : valoriSplitPers[1],
                                            utente: this.ddlInstance12.value === 'DEF' ? valoriSplit[2] : valoriSplitPers[2],
                                            password: this.ddlInstance12.value === 'DEF' ? valoriSplit[3] : valoriSplitPers[3],
                                            SSL: this.ddlInstance12.value === 'DEF' ? valoriSplit[4] : valoriSplitPers[4],
                                            oauth2ClientID: this.ddlInstance12.value === 'DEF' ? valoriSplit[7] : valoriSplitPers[7],
                                            oauth2ClientSecret: this.ddlInstance12.value === 'DEF' ? valoriSplit[8] : valoriSplitPers[8],
                                            oauth2RefreshToken: this.ddlInstance12.value === 'DEF' ? valoriSplit[9] : valoriSplitPers[9],
                                            mittente: finalSendersName,
                                            destinatario: dest,
                                            emailMittente: this.ddlInstance12.value === 'DEF' ? valoriSplit[6] : valoriSplitPers[6],
                                            emailCC: destCC,
                                            emailBCC: destCCN,
                                            soggetto: oggetto,
                                            messaggioHtml: document.getElementById('testoEmail_azioneEmail').ej2_instances[0].value,
                                            allegati: arrAllegati,
                                        };
                                        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/sendEmail`, JSON.stringify(dataObj), 'POST',
                                            'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => {
                                                this.toggleBlocking2(false);
                                                if (retData.response === 'KO') {
                                                    resolve({ retCode: 2, msgErr: retData.msg }); 
                                                }
                                                else { 
                                                    const testoEmail = document.getElementById('testoEmail_azioneEmail').ej2_instances[0].value;
                                                    this.dialogInstance6.hide();                           
                                                    resolve({ retCode: 1, testoEmail: testoEmail });       
                                                }        
                                            }, error => {
                                                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                                reject(messaggio); 
                                        }, true, false)
                                    }).catch(messaggio => { 
                                        reject(messaggio);                    
                                    }); 
                                }                    
                            },
                            buttonModel: { content: 'INVIA EMAIL', cssClass: 'e-success', isPrimary: true } },
                            { click: () => { this.dialogInstance6.hide(); }, buttonModel: { content: 'ANNULLA' } }];
                            this.dialogInstance6.show(); 
                            this.toggleBlocking();
                        }, 700);  
                    }}>{this.creaDialogEmail(null, 'azioneEmail')}</CallbackWrapper>);
                }                
            }, messaggio => {   
                reject(messaggio);  
            }, true, true);
        });
    }
    invioSMSRemake(dest, idSMS, matrice) {
        let testo_SMS = '';
        let oggetto_SMS = '';   
        return new Promise((resolve,reject) => {
            const QueryExec = `SELECT [01006].* from [01006] WHERE [01006].id_email = ${idSMS}`;                             
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) {
                    testo_SMS = retData[0].testo;
                    oggetto_SMS = retData[0].oggetto; 
                    matrice.forEach(elm2 =>  {
                        testo_SMS = testo_SMS.replace(elm2[0], elm2[1]);  
                        oggetto_SMS = oggetto_SMS.replace(elm2[0], elm2[1]);
                    }); 
                    this.dialogInstance6.header = `<div><span id='dialog-title-${this.dialogInstance6.id}' class='title'>AZIONE INVIO SMS</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance6.id}' title='Minimizza la finestra'></span></div>`;
                    ++this.childKey;
                    if (this.dialogInstance6.visible === true)
                        this.dialogInstance6.hide();
                    const container = document.getElementById('defaultDialogContent3');
                    const root = createRoot(container);                         
                    root.render(<CallbackWrapper callback={() => {
                        setTimeout(() => { 
                            CustomJS.getParametroDB(49).then(ret => { 
                                document.getElementById('mittenteSMS_azioneSMS').ej2_instances[0].value = ret;   
                                document.getElementById('mittenteSMS_azioneSMS').ej2_instances[0].readonly = true;
                                document.getElementById('destinatarioSMS_azioneSMS').ej2_instances[0].value = dest; 
                                document.getElementById('oggettoSMS_azioneSMS').ej2_instances[0].value = oggetto_SMS; 
                                document.getElementById('testoSMS_azioneSMS').ej2_instances[0].value = testo_SMS;
                                this.dialogInstance6.width = '60%';
                                this.dialogInstance6.height = '65%';
                                this.dialogInstance6.open = (args) => { 
                                    this.onMinimizeDialogOpen('AZIONE INVIO SMS/WhatsApp', '300px', '95', '65%', this.dialogInstance6);
                                    this.textBoxInstance5.addAttributes({ rows: 10 });              
                                }                              
                                this.dialogInstance6.beforeClose = (args) => {
                                };    
                                this.dialogInstance6.buttons = [{ click: () => {
                                    if ((document.getElementById('tipoSMS_azioneSMS').ej2_instances[0].checked === false && document.getElementById('tipoWA_azioneSMS').ej2_instances[0].checked === false)
                                        || CustomJS.isNullEmpty(document.getElementById('destinatarioSMS_azioneSMS').ej2_instances[0].value?.trim())
                                        || CustomJS.validatePhoneNumber(document.getElementById('destinatarioSMS_azioneSMS').ej2_instances[0].value?.trim()) === false
                                        || CustomJS.isNullEmpty(document.getElementById('testoSMS_azioneSMS').ej2_instances[0].value?.trim())) {            
                                        return;
                                    }
                                    else {
                                        let funcListSMS = [];
                                        const eseguiSMS = async (numDest) => {
                                            return new Promise((resolve, reject) => {
                                                let dataObj = {
                                                    tipoMessaggioSMS: document.getElementById('tipoSMS_azioneSMS').ej2_instances[0].checked,
                                                    tipoMessaggioWA: document.getElementById('tipoWA_azioneSMS').ej2_instances[0].checked,
                                                    numeroMittente: document.getElementById('mittenteSMS_azioneSMS').ej2_instances[0].value?.trim(),
                                                    numeroDestinatario: numDest,
                                                    oggettoMessaggio: document.getElementById('oggettoSMS_azioneSMS').ej2_instances[0].value?.trim(),
                                                    testoMessaggio: document.getElementById('testoSMS_azioneSMS').ej2_instances[0].value?.trim()
                                                };                                                              
                                                CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/Notifica/InvioNotifica`, { dati_notifica_json: JSON.stringify(dataObj) }, 'POST', 
                                                    { "Authorization" : CustomJS.tokenAuthAPIVarious }, retData => {                                                        
                                                        if (retData.response === 'KO') {
                                                            toast.error(`Attenzione!! Parametri per invio SMS errati. L' errore riscontrato è il seguente : ${JSON.stringify(retData.msg)}  `, {
                                                                //onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 20000,
                                                                hideProgressBar: false,            
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            });  
                                                            resolve("Prossima");
                                                        }
                                                        else { 
                                                            resolve("Prossima");       
                                                        }        
                                                }, error => {
                                                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                                    toast.warn(messaggio, {
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,           
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true}
                                                    );
                                                    resolve("Prossima"); 
                                                }, true, false);        
                                            });
                                        };
                                        console.log(document.getElementById('destinatarioSMS_azioneSMS').ej2_instances[0].value.split(';'));
                                        document.getElementById('destinatarioSMS_azioneSMS').ej2_instances[0].value.split(';')?.forEach((elm,idx) => {
                                            if (!CustomJS.isNullEmpty(elm.trim()))
                                                funcListSMS.push(eseguiSMS(elm.trim()));
                                        });
                                        const eseguiCiclicaSMS = (ind) => {
                                            if (CustomJS.isNullEmpty(funcListSMS[ind])) {
                                                this.dialogInstance6.hide();                           
                                                this.toggleBlocking(); 
                                                resolve({ retCode: 1, testoSMS: document.getElementById('testoSMS_azioneSMS').ej2_instances[0].value });
                                            } 
                                            funcListSMS[ind].then(ret => {                 
                                                if (ret === "Prossima") {
                                                    eseguiCiclicaSMS(ind+1);
                                                }
                                            }).catch(err => { });
                                        };
                                        this.toggleBlocking();                 
                                        eseguiCiclicaSMS(0);   
                                    }                    
                                },
                                buttonModel: { content: 'INVIA SMS', cssClass: 'e-success', isPrimary: true } },
                                { click: () => { this.dialogInstance6.hide(); }, buttonModel: { content: 'ANNULLA' } }];
                                this.dialogInstance6.show(); 
                                this.toggleBlocking();
                            }).catch(messaggio => { 
                                reject(messaggio);                    
                            }); 
                        }, 700);  
                    }}>{this.creaDialogSMS()}</CallbackWrapper>);
                }                
            }, messaggio => {   
                reject(messaggio);  
            }, true, true);
        });
    }
   invioEmailSchedaIncaricoCAT(rowData, pathAllegato) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let testo_email = '';
        let oggetto_email = ''; 
        return new Promise((resolve,reject) => {
            const QueryExec = `SELECT [01004].id_cat, [00006].email1 FROM [00006] INNER JOIN [01004] ON [00006].id_collaboratore = [01004].id_collaboratore WHERE [01004].id_cat = ${rowData.id_cat}`;                             
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                testo_email = 'Con la presente inviamo la documentazione per l\'incarico in oggetto\n\nCordiali Saluti\n\n\n     All Consulting  Technology';
                oggetto_email = `Nuovo incarico da ACT Consulting ${document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value}`; 
                this.dialogInstance1.header = 'INVIO EMAIL SCHEDA INCARICO A CAT';
                ++this.childKey;
                const container = document.getElementById('defaultDialogContent');
                const root = createRoot(container);                         
                root.render(<CallbackWrapper callback={() => {    
                    setTimeout(() => { 
                        let objTmp = _.filter(this.ddlInstance12.dataSource, elm => { return elm.VALUE === 'DEF' });
                        objTmp[0].TEXT = objTmp[0].TEXT.replace('info@', 'cat@');
                        this.ddlInstance12.refresh();
                        document.getElementById('ddlMittentiEmail').ej2_instances[0].value = `DEF`; 
                        document.getElementById('ddlMittentiEmail').ej2_instances[0].readonly = true;
                        document.getElementById('destinatarioEmail_incaricoCAT').ej2_instances[0].value = retData.length > 0 ? retData[0].email1 : ''; 
                        document.getElementById('oggettoEmail_incaricoCAT').ej2_instances[0].value = oggetto_email; 
                        document.getElementById('testoEmail_incaricoCAT').ej2_instances[0].value = testo_email.replaceAll('\n', '<br>');
                        setTimeout(() => {
                            document.getElementById('testoEmail_incaricoCAT_toolbar_SourceCode').click();
                            document.getElementById('testoEmail_incaricoCAT_toolbar_Preview').click();
                        }, 300);
                        this.dialogInstance1.width = '60%';
                        this.dialogInstance1.height = '90%';
                        this.dialogInstance1.minHeight = '90%';
                        this.dialogInstance1.beforeOpen = undefined;
                        this.dialogInstance1.open = undefined;  
                        this.dialogInstance1.close = undefined;  
                        this.dialogInstance1.beforeClose = (args) => {
                            if (document.getElementById('testoEmail_incaricoCAT').ej2_instances[0]) {
                                document.getElementById('testoEmail_incaricoCAT').ej2_instances[0].destroy();
                                $('[id=testoEmail_incaricoCAT_toolbar_FontColor_Target]').hide();
                                $('[id=testoEmail_incaricoCAT_toolbar_BackgroundColor_Target]').hide();
                            }
                            $(`div[id^="allegato"]`).remove();
                            document.getElementById('ddlMittentiEmail').ej2_instances[0].text = null;
                            document.getElementById('ddlMittentiEmail').ej2_instances[0].value = null;
                        };    
                        this.dialogInstance1.buttons = [{ click: () => {
                            if (CustomJS.isNullEmpty(document.getElementById('destinatarioEmail_incaricoCAT').ej2_instances[0].value?.trim())
                                || CustomJS.validateEmails(document.getElementById('destinatarioEmail_incaricoCAT').ej2_instances[0].value?.trim()) === false
                                || CustomJS.validateEmails(document.getElementById('destinatarioCCEmail_incaricoCAT').ej2_instances[0].value?.trim()) === false
                                || CustomJS.validateEmails(document.getElementById('destinatarioCCNEmail_incaricoCAT').ej2_instances[0].value?.trim()) === false) {            
                                return;
                            }
                            else {
                                CustomJS.getParametroDB(45).then(ret => { 
                                    this.toggleBlocking();               
                                    let valoriSplit = ret.split(';');
                                    let valoriSplitPers = this.ddlInstance12.value === 'PERS' ? this.ddlInstance12.itemData.ParametriEmail.split(';') : [];
                                    let finalSendersName = this.ddlInstance12.text.substring(0, this.ddlInstance12.text.indexOf('<'));
                                    let oggetto = document.getElementById('oggettoEmail_incaricoCAT').ej2_instances[0].value?.trim();
                                    let dest = document.getElementById('destinatarioEmail_incaricoCAT').ej2_instances[0].value?.trim();
                                    let destCC = document.getElementById('destinatarioCCEmail_incaricoCAT').ej2_instances[0].value?.trim(); 
                                    let destCCN = document.getElementById('destinatarioCCNEmail_incaricoCAT').ej2_instances[0].value?.trim();
                                    let arrAllegati = [{ filename: pathAllegato.substring(pathAllegato.lastIndexOf('/')+1), path: pathAllegato }];
                                    document.querySelectorAll(`div[id^="allegato"]`).forEach(elm => {
                                        let pathAllegato = elm.querySelector(`input[id^="pathAllegato"]`).value;
                                        arrAllegati.push({ filename: pathAllegato.substring(pathAllegato.lastIndexOf('/')+1), path: pathAllegato })
                                    });
                                    let dataObj = {
                                        server: this.ddlInstance12.value === 'DEF' ? valoriSplit[0] : valoriSplitPers[0],
                                        porta: this.ddlInstance12.value === 'DEF' ? valoriSplit[1] : valoriSplitPers[1],
                                        utente: this.ddlInstance12.value === 'DEF' ? valoriSplit[2] : valoriSplitPers[2],
                                        password: this.ddlInstance12.value === 'DEF' ? valoriSplit[3] : valoriSplitPers[3],
                                        SSL: this.ddlInstance12.value === 'DEF' ? valoriSplit[4] : valoriSplitPers[4],
                                        oauth2ClientID: this.ddlInstance12.value === 'DEF' ? valoriSplit[7] : valoriSplitPers[7],
                                        oauth2ClientSecret: this.ddlInstance12.value === 'DEF' ? valoriSplit[8] : valoriSplitPers[8],
                                        oauth2RefreshToken: this.ddlInstance12.value === 'DEF' ? valoriSplit[9] : valoriSplitPers[9],
                                        mittente: finalSendersName,
                                        destinatario: dest,
                                        emailMittente: this.ddlInstance12.text.substring(this.ddlInstance12.text.indexOf('<')+1, this.ddlInstance12.text.indexOf('>')).replace('\r','').replace('\n',''),
                                        emailCC: destCC,
                                        emailBCC: destCCN,
                                        soggetto: oggetto,
                                        messaggioHtml: document.getElementById('testoEmail_incaricoCAT').ej2_instances[0].value,
                                        allegati: arrAllegati,
                                    };
                                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/sendEmail`, JSON.stringify(dataObj), 'POST',
                                        'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => {
                                            this.toggleBlocking();
                                            if (retData.response === 'KO') {
                                                resolve({ retCode: 2, msgErr: retData.msg }); 
                                            }
                                            else { 
                                                this.dialogInstance1.hide();                           
                                                resolve(1);       
                                            }        
                                        }, error => {
                                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                            reject(messaggio); 
                                    }, true, false)
                                }).catch(messaggio => { 
                                    reject(messaggio);                    
                                }); 
                            }                    
                        },
                        buttonModel: { content: 'INVIA EMAIL', cssClass: 'e-success', isPrimary: true } },
                        { click: () => { this.dialogInstance1.hide(); }, buttonModel: { content: 'ANNULLA' } }];
                        this.dialogInstance1.show(); 
                        this.toggleBlocking();
                    }, 500);     
                }}>{this.creaDialogEmail(pathAllegato, 'incaricoCAT')}</CallbackWrapper>);
            }, messaggio => {   
                reject(messaggio);  
            }, true, true);
        });
    }
    invioEmailSchedaIncaricoRFD(rowData, pathAllegato) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let testo_email = '';
        let oggetto_email = '';  
        return new Promise((resolve,reject) => {
            const QueryExec = `SELECT [01014].id_RFD, [00006].email1 FROM [00006] INNER JOIN [01014] ON [00006].id_collaboratore = [01014].id_collaboratore WHERE [01014].id_RFD = ${rowData.id_rfd}`;                             
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                testo_email = 'Con la presente inviamo la documentazione per l\'incarico in oggetto\n\nCordiali Saluti\n\n\n     All Consulting  Technology';
                oggetto_email = `Nuovo incarico da ACT Consulting ${document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value}`; 
                this.dialogInstance1.header = 'INVIO EMAIL SCHEDA INCARICO A RFD';
                ++this.childKey;
                const container = document.getElementById('defaultDialogContent');
                const root = createRoot(container);                         
                root.render(<CallbackWrapper callback={() => {    
                    setTimeout(() => { 
                        let objTmp = _.filter(this.ddlInstance12.dataSource, elm => { return elm.VALUE === 'DEF' });
                        objTmp[0].TEXT = objTmp[0].TEXT.replace('cat@', 'info@');
                        this.ddlInstance12.refresh();
                        document.getElementById('ddlMittentiEmail').ej2_instances[0].value = 'DEF'; 
                        document.getElementById('ddlMittentiEmail').ej2_instances[0].readonly = true;
                        document.getElementById('destinatarioEmail_incaricoRFD').ej2_instances[0].value = retData.length > 0 ? retData[0].email1 : ''; 
                        document.getElementById('oggettoEmail_incaricoRFD').ej2_instances[0].value = oggetto_email; 
                        document.getElementById('testoEmail_incaricoRFD').ej2_instances[0].value = testo_email.replaceAll('\n', '<br>');
                        setTimeout(() => {
                            document.getElementById('testoEmail_incaricoRFD_toolbar_SourceCode').click();
                            document.getElementById('testoEmail_incaricoRFD_toolbar_Preview').click();
                        }, 300);
                        this.dialogInstance1.width = '60%';
                        this.dialogInstance1.height = '90%';
                        this.dialogInstance1.minHeight = '90%';
                        this.dialogInstance1.beforeOpen = undefined;
                        this.dialogInstance1.open = undefined;  
                        this.dialogInstance1.close = undefined;  
                        this.dialogInstance1.beforeClose = (args) => {
                            if (document.getElementById('testoEmail_incaricoRFD').ej2_instances[0]) {
                                document.getElementById('testoEmail_incaricoRFD').ej2_instances[0].destroy();
                                $('[id=testoEmail_incaricoRFD_toolbar_FontColor_Target]').hide();
                                $('[id=testoEmail_incaricoRFD_toolbar_BackgroundColor_Target]').hide();
                            }
                            $(`div[id^="allegato"]`).remove();
                            document.getElementById('ddlMittentiEmail').ej2_instances[0].text = null;
                            document.getElementById('ddlMittentiEmail').ej2_instances[0].value = null;
                        };    
                        this.dialogInstance1.buttons = [{ click: () => {
                            if (CustomJS.isNullEmpty(document.getElementById('destinatarioEmail_incaricoRFD').ej2_instances[0].value?.trim())
                                || CustomJS.validateEmails(document.getElementById('destinatarioEmail_incaricoRFD').ej2_instances[0].value?.trim()) === false
                                || CustomJS.validateEmails(document.getElementById('destinatarioCCEmail_incaricoRFD').ej2_instances[0].value?.trim()) === false
                                || CustomJS.validateEmails(document.getElementById('destinatarioCCNEmail_incaricoRFD').ej2_instances[0].value?.trim()) === false) {            
                                return;
                            }
                            else {
                                CustomJS.getParametroDB(45).then(ret => { 
                                    this.toggleBlocking();               
                                    let valoriSplit = ret.split(';');
                                    let valoriSplitPers = this.ddlInstance12.value === 'PERS' ? this.ddlInstance12.itemData.ParametriEmail.split(';') : [];
                                    let finalSendersName = this.ddlInstance12.text.substring(0, this.ddlInstance12.text.indexOf('<'));
                                    let oggetto = document.getElementById('oggettoEmail_incaricoRFD').ej2_instances[0].value?.trim();
                                    let dest = document.getElementById('destinatarioEmail_incaricoRFD').ej2_instances[0].value?.trim();
                                    let destCC = document.getElementById('destinatarioCCEmail_incaricoRFD').ej2_instances[0].value?.trim(); 
                                    let destCCN = document.getElementById('destinatarioCCNEmail_incaricoRFD').ej2_instances[0].value?.trim();
                                    let arrAllegati = [{ filename: pathAllegato.substring(pathAllegato.lastIndexOf('/')+1), path: pathAllegato }];
                                    document.querySelectorAll(`div[id^="allegato"]`).forEach(elm => {
                                        let pathAllegato = elm.querySelector(`input[id^="pathAllegato"]`).value;
                                        arrAllegati.push({ filename: pathAllegato.substring(pathAllegato.lastIndexOf('/')+1), path: pathAllegato })
                                    });
                                    let dataObj = {
                                        server: this.ddlInstance12.value === 'DEF' ? valoriSplit[0] : valoriSplitPers[0],
                                        porta: this.ddlInstance12.value === 'DEF' ? valoriSplit[1] : valoriSplitPers[1],
                                        utente: this.ddlInstance12.value === 'DEF' ? valoriSplit[2] : valoriSplitPers[2],
                                        password: this.ddlInstance12.value === 'DEF' ? valoriSplit[3] : valoriSplitPers[3],
                                        SSL: this.ddlInstance12.value === 'DEF' ? valoriSplit[4] : valoriSplitPers[4],
                                        oauth2ClientID: this.ddlInstance12.value === 'DEF' ? valoriSplit[7] : valoriSplitPers[7],
                                        oauth2ClientSecret: this.ddlInstance12.value === 'DEF' ? valoriSplit[8] : valoriSplitPers[8],
                                        oauth2RefreshToken: this.ddlInstance12.value === 'DEF' ? valoriSplit[9] : valoriSplitPers[9],
                                        mittente: finalSendersName,
                                        destinatario: dest,
                                        emailMittente: this.ddlInstance12.value === 'DEF' ? valoriSplit[6] : valoriSplitPers[6],
                                        emailCC: destCC,
                                        emailBCC: destCCN,
                                        soggetto: oggetto,
                                        messaggioHtml: document.getElementById('testoEmail_incaricoRFD').ej2_instances[0].value,
                                        allegati: arrAllegati,
                                    };
                                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/sendEmail`, JSON.stringify(dataObj), 'POST',
                                        'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => {
                                            this.toggleBlocking();
                                            if (retData.response === 'KO') {
                                                resolve({ retCode: 2, msgErr: retData.msg }); 
                                            }
                                            else { 
                                                this.dialogInstance1.hide();                           
                                                resolve(1);       
                                            }        
                                        }, error => {
                                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                            reject(messaggio); 
                                    }, true, false)
                                }).catch(messaggio => { 
                                    reject(messaggio);                    
                                }); 
                            }                    
                        },
                        buttonModel: { content: 'INVIA EMAIL', cssClass: 'e-success', isPrimary: true } },
                        { click: () => { this.dialogInstance1.hide(); }, buttonModel: { content: 'ANNULLA' } }];
                        this.dialogInstance1.show(); 
                        this.toggleBlocking();
                    }, 500);           
                }}>{this.creaDialogEmail(pathAllegato, 'incaricoRFD')}</CallbackWrapper>);                          
            }, messaggio => {   
                reject(messaggio);  
            }, true, true);
        });
    }
    creaDialogSMS() {
        ++this.childKey;
        return(<div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                        <div className='row'>
                                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                                <TextBoxComponent id='mittenteSMS_azioneSMS' key={this.childKey} floatLabelType='Auto' placeholder='Numero Mittente SMS/WhatsApp' cssClass='e-outline'></TextBoxComponent>
                                            </div>                                                                   
                                            <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8">
                                                <TextBoxComponent id='destinatarioSMS_azioneSMS' key={this.childKey} floatLabelType='Auto' placeholder='Destinatario SMS/WhatsApp (Il + è obbligatorio come primo carattere. Il numero deve essere privo di spazi e caratteri speciali)' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8">    
                                                <CheckBoxComponent id='tipoSMS_azioneSMS' label='Messaggio SMS' checked={true}></CheckBoxComponent>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <CheckBoxComponent id='tipoWA_azioneSMS' label='Messaggio WhatsApp (non ancora attivo)' checked={false}></CheckBoxComponent>
                                            </div>  
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <TextBoxComponent id='oggettoSMS_azioneSMS' key={this.childKey} floatLabelType='Auto' placeholder='Oggetto SMS/WhatsApp' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                              <TextBoxComponent id='testoSMS_azioneSMS' floatLabelType='Auto' placeholder='Testo SMS/WhatsApp' ref = {txtObj => {this.textBoxInstance5 = txtObj}} multiline={true} cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>);
    }
    creaDialogEmail(pathAllegato, suffisso) {
        ++this.childKey;
        this.suffissoEmail = suffisso;
        let stileAllegato = CustomJS.isNullEmpty(pathAllegato) ? { display: 'none'} : { display: 'inline'};
        return(<div className="control-section card-control-section basic_card_layout">
                    <input type="hidden" id='pathAllegato' value={CustomJS.isNullEmpty(pathAllegato) ? '' : pathAllegato.replace(CustomJS.APIVariousJfishTechURLProd,'')}/>
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                        <div className='row'>
                                            <div id='uploadAllegati' style={{display: 'none'}}><UploaderComponent autoUpload={true} ref={upload => {this.uploadObj = upload }} asyncSettings={this.asyncSettings} uploading={this.onUploading} 
                                                    success={this.onUploadSuccess.bind(this)} allowedExtensions='.doc, .docx, .pdf, .jpeg, .jpg, .png, .jfif, .gif, .tiff, .tif, .zip, .rar, .xls, .xlsx' multiple={false} /></div>
                                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                                <DropDownListComponent id='ddlMittentiEmail' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance12 = ddlObj } dataSource={this.dsMittentiEmail}  
                                                        allowFiltering={false} filterType='Contains' showClearButton={false} value='DEF'
                                                        placeholder="Selezionare un mittente" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                </DropDownListComponent>  
                                            </div>                                                                   
                                            <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8">
                                                <TextBoxComponent id={`destinatarioEmail_${suffisso}`} key={this.childKey} floatLabelType='Auto' placeholder='Destinatario Email (valori multipli separati da punto e virgola)' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                <TextBoxComponent id={`destinatarioCCEmail_${suffisso}`} key={this.childKey} floatLabelType='Auto' placeholder='Destinatario Email CC (valori multipli separati da punto e virgola)' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                <TextBoxComponent id={`destinatarioCCNEmail_${suffisso}`} key={this.childKey} floatLabelType='Auto' placeholder='Destinatario Email CCN (valori multipli separati da punto e virgola)' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <TextBoxComponent id={`oggettoEmail_${suffisso}`} key={this.childKey} floatLabelType='Auto' placeholder='Oggetto Email' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                <span style={{ fontSize: '0.7vw', fontWeight: '700' }}>Aggiungi allegati</span>&nbsp;&nbsp;
                                                <ButtonComponent id='btnAggiungiAllegati' title='Aggiungi Allegati' onClick={this.btnAggiungiAllegati.bind(this)} iconCss='fas fa-plus fa-7x' cssClass='e-info'></ButtonComponent>
                                            </div>
                                        </div>
                                        <div id='divAllegatoEmail' className='row' style={stileAllegato}>
                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                <span style={{ fontSize: '0.7vw', fontWeight: '700' }}>Allegato:&nbsp;&nbsp;<img src={ImmaginePDF} alt='Allegato PDF' style={{ width: '25px', height: '25px' }} />&nbsp;&nbsp;{pathAllegato?.substring(pathAllegato?.lastIndexOf('/')+1)}&nbsp;&nbsp;&nbsp;&nbsp;<ButtonComponent id='vediAllegato' key={this.childKey} title='Visualizza Allegato' cssClass='e-details' iconCss='fas fa-eye fa-6x' onClick={this.visualizzaAllegato.bind(this)}></ButtonComponent></span>
                                            </div>
                                        </div>
                                        <div id={`divAllegatiEmail_${suffisso}`} className='row'></div>
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <RichTextEditorComponent id={`testoEmail_${suffisso}`} key={this.childKey} showCharCount={true} enableHtmlEncode={false} placeholder='Testo Email' 
                                                    maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width='100%' height='500px'>
                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table]}/>
                                                </RichTextEditorComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>);
    }
    btnAggiungiAllegati(){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let utente = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username;
        this.uploadObj.asyncSettings.saveUrl = `${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumenti/JFTECH/${utente}/${idSin}/ALLEGATI`;
        document.getElementById('uploadAllegati').querySelector('button.e-css.e-btn').click(); 
    }
    onUploadSuccess(args){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.indiceAll++;
        let nomeElm = 'allegato' + this.indiceAll.toString();
        let nomeElm2 = 'pathAllegato' + this.indiceAll.toString();
        let utente = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username;
        let img = (args.file.type) === 'pdf' ? ImmaginePDF : (args.file.type) === 'doc' || (args.file.type) === 'docx' ? ImmagineDOC : (args.file.type) === 'xls' || (args.file.type) === 'xlsx' ? ImmagineEXCEL : (args.file.type) === 'zip' || (args.file.type) === 'rar' ? ImmagineZIP : ImmagineImgVarie;
        let nomeButtVediAll = 'vediAllegati' + this.indiceAll.toString();
        let nomeButtRimuoviAll = 'rimuoviAllegati' + this.indiceAll.toString();
        $(`<div id=${nomeElm}></div>`).appendTo(`#divAllegatiEmail_${this.suffissoEmail}`);
        setTimeout(() => {
            const container = document.getElementById(nomeElm);
            const root = createRoot(container);                         
            root.render(<div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" style={{ marginTop: '2px' }}><input type="hidden" id={nomeElm2} value={`${CustomJS.APIVariousJfishTechURLProd}/${CustomJS.CartellaTemporaneaACT}/${utente}/${idSin}/${args.file.name}`}></input><span style={{ fontSize: '0.7vw', fontWeight: '700' }}>Allegato:&nbsp;&nbsp;<img src={img} alt='' style={{ width: '25x', height: '25px' }} />&nbsp;&nbsp;{args.file.name}&nbsp;&nbsp;&nbsp;&nbsp;<ButtonComponent id={nomeButtVediAll} title='Visualizza Allegato' cssClass='e-details' iconCss='fas fa-eye fa-6x' onClick={this.visualizzaAllegati.bind(this, args.file.name, this.indiceAll)}></ButtonComponent>&nbsp;&nbsp;&nbsp;<ButtonComponent id={nomeButtRimuoviAll} title='Rimuovi Allegato' cssClass='e-details' iconCss='fas fa-times fa-6x' onClick={this.rimuoviAllegato.bind(this, this.indiceAll)}></ButtonComponent></span></div>);                     
        }, 200);
    }
    onUploading(args) {
        args.currentRequest.setRequestHeader("Authorization", JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token);
    }
    rimuoviAllegato(indiceAll){
        $(`div[id^="allegato${indiceAll}"]`).remove();
    }
    visualizzaAllegato() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let timeout = 0;
        if (this.dialogInstance8.visible === true) {
            this.dialogInstance8.hide();
            timeout = 500;
        }
        setTimeout(() => {
            this.dialogInstance8.header = `<div><span id='dialog-title-${this.dialogInstance8.id}' class='title'>VISUALIZZAZIONE DOCUMENTO PDF</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance8.id}' title='Minimizza la finestra'></span></div>`;    
            this.dialogInstance8.width = '65%';
            this.dialogInstance8.height = '90%';
            this.dialogInstance8.minHeight = '90%';
            this.dialogInstance8.buttons = [];  
            this.dialogInstance8.beforeOpen = (args) => {
                const container = document.getElementById('defaultDialogContent5');
                const root = createRoot(container); 
                root.unmount();
            };
            this.dialogInstance8.open = (args) => {
                this.onMinimizeDialogOpen(`VISUALIZZAZIONE DOCUMENTO PDF`, '440px', '240', '90%', this.dialogInstance8);
                const container = document.getElementById('defaultDialogContent5');
                const root = createRoot(container);  
                root.render(<CallbackWrapper callback={() => {
                    document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].enableDownload = true;
                    document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].serviceUrl = `${CustomJS.APIVariousJfishTechURLProd}/api/PdfViewer`;
                    document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                    //this.PDFViewerInstance.dataBind();  //NON SEMBRA SERVIRE
                    setTimeout(() => {
                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].load(document.getElementById('pathAllegato').value, null);
                    }, 250);
                }}>{this.PDFViewerRender()}</CallbackWrapper>);      
            };  
            this.dialogInstance8.show(); 
        }, timeout);
    }
    visualizzaAllegati(nomeFile, indiceAll) {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'JPEG' || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'JPG'
            || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'PNG' || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'TIF'
            || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'TIFF' || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'GIF'
            ||  nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'JFIF') { 
                this.lightboxImage = document.getElementById('pathAllegato' + indiceAll.toString()).value;
                this.setState({showLightbox: true});  
        }
        if (nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'MP4' || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'MOV'
            || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'WMV' || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'AVI'
            || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'AVCHD' || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'FLV') {
                let timeout = 0;
                if (this.dialogInstance10.visible === true) {
                    this.dialogInstance10.hide();
                    timeout = 500;
                }
                setTimeout(() => {
                    this.dialogInstance10.header = `<div><span id='dialog-title-${this.dialogInstance10.id}' class='title'>VISUALIZZAZIONE VIDEO</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance10.id}' title='Minimizza la finestra'></span></div>`;    
                    this.dialogInstance10.width = '65%';
                    this.dialogInstance10.height = '70%';
                    this.dialogInstance10.minHeight = '70%';
                    this.dialogInstance10.beforeOpen = (args) => {
                        this.setState({ playVideo: true });
                    };
                    this.dialogInstance10.beforeClose = (args) => {
                        this.setState({ playVideo: false });
                    };
                    this.dialogInstance10.buttons = [];   
                    this.dialogInstance10.open = (args) => {
                        this.onMinimizeDialogOpen(`VISUALIZZAZIONE VIDEO`, '400px', '360', '60%', this.dialogInstance10);
                        const container = document.getElementById('defaultDialogContent7');
                        const root = createRoot(container);   
                        root.render(this.videoPlayerRender(document.getElementById('pathAllegato' + indiceAll.toString()).value)); 
                    };  
                    this.dialogInstance10.show(); 
                }, timeout);
        }
        if (nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'PDF') {
            let timeout = 0;
            if (this.dialogInstance8.visible === true) {
                this.dialogInstance8.hide();
                timeout = 500;
            }
            setTimeout(() => {
                this.dialogInstance8.header = `<div><span id='dialog-title-${this.dialogInstance8.id}' class='title'>VISUALIZZAZIONE DOCUMENTO PDF</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance8.id}' title='Minimizza la finestra'></span></div>`;    
                this.dialogInstance8.width = '65%';
                this.dialogInstance8.height = '90%';
                this.dialogInstance8.minHeight = '90%';
                this.dialogInstance8.buttons = [];    
                this.dialogInstance8.beforeOpen = (args) => {
                    const container = document.getElementById('defaultDialogContent5');
                    const root = createRoot(container); 
                    root.unmount();
                };
                this.dialogInstance8.open = (args) => {
                    this.onMinimizeDialogOpen(`VISUALIZZAZIONE DOCUMENTO PDF`, '440px', '240', '90%', this.dialogInstance8);
                    const container = document.getElementById('defaultDialogContent5');
                    const root = createRoot(container);  
                    root.render(<CallbackWrapper callback={() => {
                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].enableDownload = false;
                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].serviceUrl = `${CustomJS.APIVariousJfishTechURLProd}/api/PdfViewer`;
                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                        //this.PDFViewerInstance.dataBind();  //NON SEMBRA SERVIRE
                        setTimeout(() => {
                            document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].load(document.getElementById('pathAllegato' + indiceAll.toString()).value?.substring(document.getElementById('pathAllegato' + indiceAll.toString()).value?.indexOf('/' + CustomJS.CartellaTemporaneaACT)), null);
                        }, 250);    
                    }}>{this.PDFViewerRender()}</CallbackWrapper>);   
                };  
                this.dialogInstance8.show(); 
            }, timeout);
        }
        if (nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'DOC' || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'DOCX'
            || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'XLS' || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'XLSX'
            || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'XLSM' || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'CSV'
            || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'PPS' || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'PPSX'
            || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'PPT' || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'PPTX') {
                let timeout = 0;
                if (this.dialogInstance9.visible === true) {
                    this.dialogInstance9.hide();
                    timeout = 800;
                }
                setTimeout(() => {
                    this.dialogInstance9.header = `<div><span id='dialog-title-${this.dialogInstance9.id}' class='title'>VISUALIZZAZIONE DOCUMENTO</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance9.id}' title='Minimizza la finestra'></span></div>`;    
                    this.dialogInstance9.width = '65%';
                    this.dialogInstance9.height = '90%';
                    this.dialogInstance9.minHeight = '90%';
                    this.dialogInstance9.buttons = [];    
                    this.dialogInstance9.open = (args) => {
                        this.onMinimizeDialogOpen(`VISUALIZZAZIONE DOCUMENTO`, '400px', '300', '90%', this.dialogInstance9);
                        const container = document.getElementById('defaultDialogContent6');
                        const root = createRoot(container);  
                        root.render(<CallbackWrapper callback={() => {
                            setTimeout(() => {
                                const URLVisualGoogle = `https://docs.google.com/gview?url=${encodeURIComponent(document.getElementById('pathAllegato' + indiceAll.toString()).value)}&embedded=true`;
                                const URLVisualOffice365 = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(document.getElementById('pathAllegato' + indiceAll.toString()).value)}`;
                                const encodedFilePath = encodeURIComponent(`${CustomJS.APIVariousJfishTechURLProd}/${document.getElementById('pathAllegato' + indiceAll.toString()).value}`);
                                const URLVisualLocale = `http://docs.google.com/viewer?url=${encodedFilePath}&embedded=true`;
                                document.getElementById(`IframeDocumento${idSin}`).src = URLVisualOffice365;
                            }, 400);      
                        }}>{this.otherDocumentRender()}</CallbackWrapper>);  
                    };  
                    this.dialogInstance9.show(); 
                }, timeout);
        }
        if (nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'ZIP'  
            || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'RAR') {
                document.getElementById('iframeGenerico').src = document.getElementById('pathAllegato' + indiceAll.toString()).value;
        }
    }
    visualizzaAllegatoInterlocutoria() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let timeout = 0;
        if (this.dialogInstance8.visible === true) {
            this.dialogInstance8.hide();
            timeout = 500;
        }
        setTimeout(() => {
            this.dialogInstance8.header = `<div><span id='dialog-title-${this.dialogInstance8.id}' class='title'>VISUALIZZAZIONE DOCUMENTO PDF</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance8.id}' title='Minimizza la finestra'></span></div>`;    
            this.dialogInstance8.width = '65%';
            this.dialogInstance8.height = '90%';
            this.dialogInstance8.minHeight = '90%';
            this.dialogInstance8.buttons = [];  
            this.dialogInstance8.beforeOpen = (args) => {
                const container = document.getElementById('defaultDialogContent5');
                const root = createRoot(container); 
                root.unmount();
            };
            this.dialogInstance8.open = (args) => {
                this.onMinimizeDialogOpen(`VISUALIZZAZIONE DOCUMENTO PDF`, '440px', '240', '90%', this.dialogInstance8);
                const container = document.getElementById('defaultDialogContent5');
                const root = createRoot(container);  
                root.render(<CallbackWrapper callback={() => {
                    document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].enableDownload = true;
                    document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].serviceUrl = `${CustomJS.APIVariousJfishTechURLProd}/api/PdfViewer`;
                    document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                    //document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].dataBind();  //NON SEMBRA SERVIRE
                    setTimeout(() => {
                        document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].load(`data:application/pdf;base64,${document.getElementById('base64AllegatoInterlocutoria').value}`, null);
                    }, 250);
                }}>{this.PDFViewerRender()}</CallbackWrapper>);      
            };  
            this.dialogInstance8.show(); 
        }, timeout);
    }
    btnCambiaStato() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let nuovoStato = document.getElementById(`ddlCambiaStato${idSin}`).ej2_instances[0].value;
        if (CustomJS.isNullEmpty(nuovoStato))
            return;
        if (nuovoStato === '22') {
            this.azioniStato22();
        }
        else if (nuovoStato === '54') {
            this.azioniStato54();
            this.aggiornaStatoCambioStato();
        }
        else if (nuovoStato === '3' || nuovoStato === '49') {
            const QueryExec = `SELECT Max([01002].id_perizia) AS per, [01002].id_sinistro From [01002] WHERE ((([01002].revocata) = 0) And (([01002].id_sinistro) = ${document.getElementById(`idSinistro${idSin}`).value}) And (([01002].chiusa) = 0)) GROUP BY [01002].id_sinistro`;                             
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) {
                    this.dialogInstance3.header = 'Attenzione!!';
                    this.dialogInstance3.content = 'Impossibile chiudere il sinistro : PERIZIA ANCORA APERTA';
                    this.dialogInstance3.width = '19%';
                    this.dialogInstance3.height = '17%';
                    this.dialogInstance3.minHeight = '17%';
                    this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                    this.dialogInstance3.show();                                       
                }
                else {
                    const QueryExec = `SELECT Max([01002].id_perizia) AS per, [01002].id_sinistro From [01002] WHERE ((([01002].revocata) = 0) And (([01002].id_sinistro) = ${document.getElementById(`idSinistro${idSin}`).value}) And (([01002].chiusa) = 1)) GROUP BY [01002].id_sinistro`;                             
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                        if (retData.length === 0) {
                            this.dialogInstance3.header = 'Attenzione!!';
                            this.dialogInstance3.content = 'Impossibile chiudere il sinistro : nessuna PERIZIA VALIDA (chiusa e non revocata)';
                            this.dialogInstance3.width = '19%';
                            this.dialogInstance3.height = '17%';
                            this.dialogInstance3.minHeight = '17%';
                            this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                            this.dialogInstance3.show();                                       
                        }
                        else if (CustomJS.isNullEmpty(document.getElementById(`parcella${idSin}`).ej2_instances[0].value) 
                                 || CustomJS.isNullEmpty(document.getElementById(`parcellaPerito${idSin}`).ej2_instances[0].value)) {
                                 this.dialogInstance3.header = 'Attenzione!!';
                                 this.dialogInstance3.content = 'Impossibile chiudere il sinistro : PARCELLA e/o PARCELLA PERITO sono mancanti';
                                 this.dialogInstance3.width = '19%';
                                 this.dialogInstance3.height = '17%';
                                 this.dialogInstance3.minHeight = '17%';
                                 this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                                 this.dialogInstance3.show();    
                        }
                        else {
                            this.aggiornaStatoCambioStato();
                        }
                    }, messaggio => {               
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                    }, true, true);
                }
            }, messaggio => {               
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);
        }
        else if (nuovoStato === '29' && document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === CustomJS.codiceCompagniaUnipolSAI
                 && !CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value)) {
            this.invioAccettazioneIncaricoUnipolSAI(document.getElementById(`idIncarico${idSin}`).value);
            this.aggiornaStatoCambioStato();
        }
        else if (nuovoStato === '52' && document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === CustomJS.codiceCompagniaUnipolSAI
                 && !CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value)) {
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = `Verrà inviata una comunicazione ad <b>UnipolSAI</b> di tipo <b>Rifiuto Incarico</b> e questo non è reversibie. Confermare l'operazione?`;
                this.dialogInstance3.width = '25%';
                this.dialogInstance3.height = '20%';
                this.dialogInstance3.minHeight = '20%';
                this.dialogInstance3.buttons = [
                    { click: () => { 
                        this.dialogInstance3.hide();
                        this.invioRifiutoIncaricoUnipolSAI(document.getElementById(`idIncarico${idSin}`).value);
                        this.aggiornaStatoCambioStato();            
                      }, 
                      buttonModel: { 
                        content: 'CONFERMA', 
                        cssClass: 'e-primary',
                        isPrimary: true 
                      }
                    },
                    {
                        click: () => {
                            this.dialogInstance3.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
                this.dialogInstance3.show(); 
        }
        else if (nuovoStato === '32' && document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === CustomJS.codiceCompagniaUnipolSAI
                 && !CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value)) {
            this.invioEsitoContattoDanneggiatoUnipolSAI(document.getElementById(`idIncarico${idSin}`).value);
            this.aggiornaStatoCambioStato();
        }
        else if (nuovoStato === '45') {
            if (CustomJS.isNullEmpty(document.getElementById(`assegnaCQ${idSin}`).ej2_instances[0].value)) {
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = 'E\' necessario inserire il CQ!!';
                this.dialogInstance3.width = '17%';
                this.dialogInstance3.height = '15%';
                this.dialogInstance3.minHeight = '15%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show();
                return;
            }
            else {
                document.getElementById(`dataContrCQ${idSin}`).ej2_instances[0].value = moment(new Date()).format('DD/MM/YYYY');
                this.aggiornaDettaglio('dataCQ', moment(document.getElementById(`dataContrCQ${idSin}`).ej2_instances[0].value,'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE');
            }
            this.aggiornaStatoCambioStato();
        }
        else if (nuovoStato === '42') {
            if (CustomJS.isNullEmpty(document.getElementById(`assegnaCQ${idSin}`).ej2_instances[0].value)) {
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = 'E\' necessario inserire il CQ!!';
                this.dialogInstance3.width = '17%';
                this.dialogInstance3.height = '15%';
                this.dialogInstance3.minHeight = '15%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show();
                return;
            }
            else {
                document.getElementById(`dataAssegnCQ${idSin}`).ej2_instances[0].value = moment(new Date()).format('DD/MM/YYYY');
                this.aggiornaDettaglio('dataAssegnCQ', moment(document.getElementById(`dataAssegnCQ${idSin}`).ej2_instances[0].value,'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE');
            }
            this.aggiornaStatoCambioStato();
        }
        else 
            this.aggiornaStatoCambioStato();
    }
    aggiornaStatoCambioStato() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let nota_tmp = !CustomJS.isNullEmpty(document.getElementById(`dataAlert${idSin}`).ej2_instances[0].value) ? `Da ricontattare entro il ${moment(document.getElementById(`dataAlert${idSin}`).ej2_instances[0].value, 'DD/MM/YYYY').format('DD/MM/YYYY')}` : ''; 
        document.getElementById(`idStato${idSin}`).value = document.getElementById(`ddlCambiaStato${idSin}`).ej2_instances[0].value;
        this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value });
        const dataObj = {
            IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
            Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
            DescrNota: nota_tmp,
            Stato: document.getElementById(`idStato${idSin}`).value
        };
        const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                            ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
            this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, true, true);
        const dataObj2 = {
            IdSinistro : document.getElementById(`idSinistro${idSin}`).value            
        };
        const QueryExec2 = `DELETE FROM [02018] WHERE [02018].id_sinistro = @id_sinistro`;        
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec2, JSON.stringify(dataObj2), retData2 => {
            if (!CustomJS.isNullEmpty(document.getElementById(`dataAlert${idSin}`).ej2_instances[0].value)) {
                const dataObj = {
                    IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                    DataRicontatto : moment(document.getElementById(`dataAlert${idSin}`).ej2_instances[0].value, 'DD/MM/YYYY').format('YYYY-MM-DD')
                };
                const QueryExec = "INSERT INTO [02018] (id_sinistro, data) VALUES (@id_sinistro, @data_ricontatto)";        
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );
                }, true, true);
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, true, true);
        if (document.getElementById(`idStato${idSin}`).value === '3' || document.getElementById(`idStato${idSin}`).value === '49') {
            document.getElementById(`chiusura${idSin}`).ej2_instances[0].value = moment(new Date()).format('DD/MM/YYYY');
            this.aggiornaDettaglio('t_chiusura', moment(document.getElementById(`chiusura${idSin}`).ej2_instances[0].value, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'DATE');
        }
        this.aggiornaStatoSinistro(true).then(ret => {
            if (ret === 'OK') {                
                document.getElementById(`stato${idSin}`).ej2_instances[0].value = document.getElementById(`ddlCambiaStato${idSin}`).ej2_instances[0].text;
                document.getElementById(`ddlCambiaStato${idSin}`).ej2_instances[0].value = null;
                document.getElementById(`ddlCambiaStato${idSin}`).ej2_instances[0].text = null;
            }
        }).catch(messaggio => { 
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }); 
    }
    azioniStato22() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.dialogInstance3.header = 'Attenzione!!';
        this.dialogInstance3.content = 'Verrà chiusa contemporaneamente la perizia, continuare?';
        this.dialogInstance3.width = '20%';
        this.dialogInstance3.height = '17%';
        this.dialogInstance3.minHeight = '17%';
        this.dialogInstance3.buttons = [
            {
                click: () => {
                    this.dialogInstance3.hide();
                    setTimeout(() => {
                        if (document.getElementById(`documentale${idSin}`).ej2_instances[0].checked === 1) {
                            document.getElementById(`dataSopralluogo${idSin}`).ej2_instances[0].value = document.getElementById(`incarico${idSin}`).ej2_instances[0].value;
                            document.getElementById(`mask_dataSopralluogo${idSin}`).ej2_instances[0].value = moment(document.getElementById(`incarico${idSin}`).ej2_instances[0].value).format('DD/MM/YYYY')
                            this.onChangeDataSopralluogoDettaglio({ value: document.getElementById(`dataSopralluogo${idSin}`).ej2_instances[0].value });
                        }
                        if (CustomJS.isNullEmpty(document.getElementById(`dataSinistro${idSin}`).ej2_instances[0].value) 
                            || CustomJS.isNullEmpty(document.getElementById(`denunciato${idSin}`).ej2_instances[0].value)
                            || CustomJS.isNullEmpty(document.getElementById(`incarico${idSin}`).ej2_instances[0].value)
                            || CustomJS.isNullEmpty(document.getElementById(`dataSopralluogo${idSin}`).ej2_instances[0].value)) {
                            this.dialogInstance3.content = 'E\' necessario compilare i campi DATA SINISTRO, DATA DENUNCIATO, DATA INCARICO e DATA SOPRALLUOGO per poter chiudere una perizia.';
                            this.dialogInstance3.width = '22%';
                            this.dialogInstance3.height = '19%';
                            this.dialogInstance3.minHeight = '19%';
                            this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                            this.dialogInstance3.show();
                        }
                        else {
                            const QueryExec = `SELECT [01002].* FROM [01002] WHERE id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value} and revocata = 0`;        
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                                if (retData.length === 0) {
                                    this.dialogInstance3.content = 'E\' necessario inserire i dati nel tabellino del perito per poter chiudere una perizia';
                                    this.dialogInstance3.width = '20%';
                                    this.dialogInstance3.height = '17%';
                                    this.dialogInstance3.minHeight = '17%';
                                    this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                                    this.dialogInstance3.show();
                                }
                                else if (CustomJS.isNullEmpty(retData[0].v_stima_danno) || CustomJS.isNullEmpty(retData[0].id_tipo_indennizzo) 
                                         || CustomJS.isNullEmpty(document.getElementById(`richiestaDenuncia${idSin}`).ej2_instances[0].value)) {
                                         this.dialogInstance3.content = 'E\' necessario compilare i campi RICHIESTA INDENNIZZO, STIMA DEL DANNO e DEFINIZIONE per poter chiudere una perizia.';
                                         this.dialogInstance3.width = '22%';
                                         this.dialogInstance3.height = '19%';
                                         this.dialogInstance3.minHeight = '19%';
                                         this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                                         this.dialogInstance3.show();
                                }     
                                else {
                                    this.getStatoAzione(6).then(ret => {
                                        const id_stato_tmp = ret.stato;
                                        if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                                            document.getElementById(`idStato${idSin}`).value = id_stato_tmp.toString();
                                            this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value });
                                            this.aggiornaStatoSinistro(true).then(ret2 => {
                                                if (ret2 === 'OK') {                
                                                    document.getElementById(`stato${idSin}`).ej2_instances[0].value = ret.descr_stato;
                                                }
                                            }).catch(messaggio => { 
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,           
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true}
                                                );
                                            });         
                                        }
                                        else {
                                            document.getElementById(`idStato${idSin}`).value = '22';
                                            this.onChangeAzioneDettaglio({ value: document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value });
                                            this.aggiornaStatoSinistro(true).then(ret2 => {
                                                if (ret2 === 'OK') {                
                                                }
                                            }).catch(messaggio => { 
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,           
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true}
                                                );
                                            });  
                                        }
                                        this.getAzioneNota(6).then(ret3 => {
                                            if (ret3 === true) {
                                                const dataObj = {
                                                    IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                                    Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                                    DescrNota: `Perizia chiusa con data ${moment(new Date()).format('DD/MM/YYYY HH:mm:ss')}`,
                                                    Stato: id_stato_tmp
                                                };
                                                const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                                    ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                                    this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                                }, messaggio => {
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,           
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true}
                                                    );
                                                }, true, true);
                                            }
                                        }).catch(messaggio => { 
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,           
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true}
                                            );
                                        });                
                                    }).catch(messaggio => { 
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    });                                    
                                    const dataObj = {
                                        IdSinistro : document.getElementById(`idSinistro${idSin}`).value                                       
                                    };
                                    const QueryExec = "UPDATE [01002] SET [01002].chiusa = 1, [01002].t_riconsegnato=GETDATE() WHERE [01002].id_sinistro = @id_sinistro and chiusa = 0 and revocata = 0";        
                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                        document.getElementById(`stato${idSin}`).ej2_instances[0].value = document.getElementById(`ddlCambiaStato${idSin}`).ej2_instances[0].text;
                                        const QueryExec2 = `SELECT CONCAT([09001].nome, ' ', [09001].cognome) AS nomeperito, [09001].email1, [01002].* FROM [01002] INNER JOIN [09001] ON [01002].id_utente = [09001].id_utente WHERE [01002].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec2, '', retData2 => {
                                            this.dsPeritiAssegnati = retData2.map(elm => { return { id_perizia: elm.id_perizia, id_utente: elm.id_utente, nomeperito: elm.nomeperito, t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_riconsegnato:CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), chiusa: elm.chiusa, revocata: elm.revocata, email: elm.email1}}); 
                                            document.getElementById(`gridPeritiAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsPeritiAssegnati;          
                                        }, messaggio => {
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,           
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true}
                                            );
                                        }, true, true);    
                                        if (document.getElementById(`gruppo${idSin}`).ej2_instances[0].value === CustomJS.codiceCompagniaUnipolSAI && !CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value)) {
                                            const QueryExec2 = `UPDATE [10003] SET dataPerizia = CASE WHEN (ISNULL(dataSottoscrizioneAtto,'') = '' OR @dataPerizia < dataSottoscrizioneAtto) THEN @dataPerizia ELSE dataSottoscrizioneAtto END, importoConcordato = @importoConcordato, importoLiquidabile = @importoLiquidabile, codiceFiscalePerito = (SELECT TOP 1 T3.cod_fiscale FROM [01001] T1 LEFT OUTER JOIN [01002] T2 ON T1.id_sinistro = T2.id_sinistro LEFT OUTER JOIN [09001] T3 ON T2.id_utente = T3.id_utente WHERE T1.id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value} AND T2.chiusa = 1 AND T2.revocata = 0 ORDER BY T2.t_riconsegnato DESC) WHERE id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value};`
                                            var dataObj2 = {
                                                DataPerizia: moment(new Date()).format('YYYY-MM-DD'),
                                                //ImportoPeriziato: 1,  //DA VERIFICARE SE E' DA IMPOSTARE SEMPRE A 1 OPPURE A NULL
                                                ImportoConcordato: CustomJS.isNullEmpty(document.getElementById(`importoConcordatoUnipol${idSin}`).ej2_instances[0].value) ? 0 : document.getElementById(`importoConcordatoUnipol${idSin}`).ej2_instances[0].value,
                                                ImportoLiquidabile: CustomJS.isNullEmpty(document.getElementById(`importoLiquidabileUnipol${idSin}`).ej2_instances[0].value) ? 0 : document.getElementById(`importoLiquidabileUnipol${idSin}`).ej2_instances[0].value,
                                            }
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec2, JSON.stringify(dataObj2), retData7 => {
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                });
                                            }, true, true);                        
                                        }                       
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    }, true, true);
                                }        
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            }, true, true);
                        }                        
                    }, 600);                    
                },
                buttonModel: {
                    content: 'CONFERMA',
                    cssClass: 'e-primary',
                    isPrimary: true
                }
            },
            {
                click: () => {
                    this.dialogInstance3.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.dialogInstance3.show();
    }
    azioniStato54() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const dataObj = {
            IdSinistro : document.getElementById(`idSinistro${idSin}`).value                                       
        };
        const QueryExec = "UPDATE [01002] SET chiusa = 0, t_riconsegnato = NULL WHERE id_sinistro = @id_sinistro AND revocata = 0 AND t_assegnato = (SELECT MAX(t_assegnato) FROM [01002] WHERE id_sinistro = @id_sinistro AND revocata = 0)";
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
            const QueryExec2 = `SELECT CONCAT([09001].nome, ' ', [09001].cognome) AS nomeperito, [09001].email1, [01002].* FROM [01002] INNER JOIN [09001] ON [01002].id_utente = [09001].id_utente WHERE [01002].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec2, '', retData2 => {
                this.dsPeritiAssegnati = retData2.map(elm => { return { id_perizia: elm.id_perizia, id_utente: elm.id_utente, nomeperito: elm.nomeperito, t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_riconsegnato:CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), chiusa: elm.chiusa, revocata: elm.revocata, email: elm.email1}}); 
                document.getElementById(`gridPeritiAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsPeritiAssegnati;          
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, true, true);
    }
    aggiornaStatoSinistro(prepEmailStato) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        return new Promise((resolve,reject) => {  
            const dataObj3 = {
                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                Stato: document.getElementById(`idStato${idSin}`).value            
            };  
            const QueryExec3 = `UPDATE [01001] SET [01001].id_stato = @stato WHERE [01001].id_sinistro = @id_sinistro`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec3, JSON.stringify(dataObj3), retData3 => {
                if (prepEmailStato === true) {
                    CustomJS.getParametroDB(9).then(ret => {
                        if (ret === '1') 
                            this.preparaEmailStato(document.getElementById(`idStato${idSin}`).value, false);
                    }).catch(messaggio => { 
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                    }); 
                }
                resolve('OK');  
            }, messaggio => {
                reject(messaggio);
            }, true, true);  
        });      
    }
    btnAzione() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let email_da_inviare = '', sms_da_inviare = '';
        if (CustomJS.isNullEmpty(document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value) 
            || CustomJS.isNullEmpty(document.getElementById(`ddlAChi${idSin}`).ej2_instances[0].value)
            || CustomJS.isNullEmpty(document.getElementById(`ddlCosa${idSin}`).ej2_instances[0].value))
            return;
        this.toggleBlocking();
        switch (document.getElementById(`ddlAChi${idSin}`).ej2_instances[0].value) {
            case '1':
                email_da_inviare = !CustomJS.isNullEmpty(document.getElementById(`emailAgenzia${idSin}`).ej2_instances[0].value?.trim()) ? document.getElementById(`emailAgenzia${idSin}`).ej2_instances[0].value?.trim() : '';
                sms_da_inviare = !CustomJS.isNullEmpty(document.getElementById(`telefonoAgenzia${idSin}`).ej2_instances[0].value?.trim()) ? `+39${document.getElementById(`telefonoAgenzia${idSin}`).ej2_instances[0].value?.replace(/\s/g, '').replace(/[^\w\s]+/g, '').trim()}`.replace('+3939', '+39') : '+39XXXXXXXXXX';
                break;
            case '2':
                email_da_inviare = !CustomJS.isNullEmpty(document.getElementById(`emailBroker${idSin}`).ej2_instances[0].value?.trim()) ? document.getElementById(`emailBroker${idSin}`).ej2_instances[0].value?.trim() : '';
                sms_da_inviare = !CustomJS.isNullEmpty(document.getElementById(`telefonoBroker${idSin}`).ej2_instances[0].value?.trim()) ? `+39${document.getElementById(`telefonoBroker${idSin}`).ej2_instances[0].value?.replace(/\s/g, '').replace(/[^\w\s]+/g, '').trim()}`.replace('+3939', '+39') : '+39XXXXXXXXXX';
                break;
            case '3':
                email_da_inviare = !CustomJS.isNullEmpty(document.getElementById(`emailContraente${idSin}`).ej2_instances[0].value?.trim()) ? document.getElementById(`emailContraente${idSin}`).ej2_instances[0].value?.trim() : '';
                sms_da_inviare = !CustomJS.isNullEmpty(document.getElementById(`telefonoContraente${idSin}`).ej2_instances[0].value?.trim()) ? `+39${document.getElementById(`telefonoContraente${idSin}`).ej2_instances[0].value?.replace(/\s/g, '').replace(/[^\w\s]+/g, '').trim()}`.replace('+3939', '+39') : '+39XXXXXXXXXX';
                break;
            case '4':
                const QueryExec2 = `SELECT [00006].email1, [00006].telefono1 FROM [00006] INNER JOIN [01004] ON [00006].id_collaboratore = [01004].id_collaboratore WHERE [01004].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value} AND [01004].chiusa = 0 AND [01004].revocata = 0`;        
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec2, '', retData2 => {
                    if (retData2.length > 0) {
                        email_da_inviare = retData2[0].email1;
                        sms_da_inviare = `+39${retData2[0].telefono1?.replace(/\s/g, '').replace(/[^\w\s]+/g, '')}`.replace('+3939', '+39');
                    }
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );
                    this.toggleBlocking();
                }, true, true);                 
                break;
            case '5':
                email_da_inviare = !CustomJS.isNullEmpty(document.getElementById(`emailLiquidatore${idSin}`).ej2_instances[0].value?.trim()) ? document.getElementById(`emailLiquidatore${idSin}`).ej2_instances[0].value?.trim() : '';
                sms_da_inviare = !CustomJS.isNullEmpty(document.getElementById(`telefonoLiquidatore${idSin}`).ej2_instances[0].value?.trim()) ? `+39${document.getElementById(`telefonoLiquidatore${idSin}`).ej2_instances[0].value?.replace(/\s/g, '').replace(/[^\w\s]+/g, '').trim()}`.replace('+3939', '+39') : '+39XXXXXXXXXX';
                break;
            case '6':
                email_da_inviare = !CustomJS.isNullEmpty(document.getElementById(`emailAssicurato${idSin}`).ej2_instances[0].value?.trim()) ? document.getElementById(`emailAssicurato${idSin}`).ej2_instances[0].value?.trim() : '';
                sms_da_inviare = !CustomJS.isNullEmpty(document.getElementById(`telefonoAssicurato${idSin}`).ej2_instances[0].value?.trim()) ? `+39${document.getElementById(`telefonoAssicurato${idSin}`).ej2_instances[0].value?.replace(/\s/g, '').replace(/[^\w\s]+/g, '').trim()}`.replace('+3939', '+39') : '+39XXXXXXXXXX';
                break;
            case '7':
                email_da_inviare = !CustomJS.isNullEmpty(document.getElementById(`emailAmministratore${idSin}`).ej2_instances[0].value?.trim()) ? document.getElementById(`emailAmministratore${idSin}`).ej2_instances[0].value?.trim() : '';
                sms_da_inviare = !CustomJS.isNullEmpty(document.getElementById(`telefonoAmministratore${idSin}`).ej2_instances[0].value?.trim()) ? `+39${document.getElementById(`telefonoAmministratore${idSin}`).ej2_instances[0].value?.replace(/\s/g, '').replace(/[^\w\s]+/g, '').trim()}`.replace('+3939', '+39') : '+39XXXXXXXXXX';
                break;
            case '8':
                const QueryExec3 = `SELECT [09001].email1, [09001].telefono1 FROM [01002] INNER JOIN [09001] ON [01002].id_utente = [09001].id_utente WHERE [01002].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value} AND [01002].chiusa = 0 AND [01002].revocata = 0`;        
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec3, '', retData3 => {
                    if (retData3.length > 0) {
                        email_da_inviare = retData3[0].email1;
                        sms_da_inviare = `+39${retData3[0].telefono1?.replace(/\s/g, '').replace(/[^\w\s]+/g, '')}`.replace('+3939', '+39');
                    }
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );
                    this.toggleBlocking();
                }, true, true); 
                break;
            default:
        }
        setTimeout(() => {
            if (document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value === '1') {
                this.aggiornaCampiEmail().then(ret => {
                    this.invioEmailRemake(email_da_inviare, document.getElementById(`ddlCosa${idSin}`).ej2_instances[0].value, ret)
                    .then(ret2 => {
                        if (ret2 === 0) {
                        }
                        else if (ret2.retCode === 1) {
                            toast.success('Email inviata con successo!!', {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });  
                            const dataObj = {
                                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                DescrNota: `Da: ${(document.getElementById('ddlMittentiEmail').ej2_instances[0].text).replace('<', '[mailto:').replace('>', ']')}<br>Inviato il: ${moment(new Date()).format('dddd DD MMMM YYYY HH:mm:ss')}<br>A: ${document.getElementById('destinatarioEmail_azioneEmail').ej2_instances[0].value}<br>CC: ${CustomJS.isNullEmpty(document.getElementById('destinatarioCCEmail_azioneEmail').ej2_instances[0].value) ? '' : document.getElementById('destinatarioCCEmail_azioneEmail').ej2_instances[0].value}<br>CCN: ${CustomJS.isNullEmpty(document.getElementById('destinatarioCCNEmail_azioneEmail').ej2_instances[0].value) ? '' : document.getElementById('destinatarioCCNEmail_azioneEmail').ej2_instances[0].value}<br>Oggetto: ${document.getElementById('oggettoEmail_azioneEmail').ej2_instances[0].value}<br>Inviata ${document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].text} ${document.getElementById(`ddlAChi${idSin}`).ej2_instances[0].text} : ${document.getElementById(`ddlCosa${idSin}`).ej2_instances[0].text} : <br/><br/>${ret2.testoEmail}<br>`,
                                Stato: null
                            };
                            const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value = null;
                                document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].text = null;
                                document.getElementById(`ddlAChi${idSin}`).ej2_instances[0].value = null;
                                document.getElementById(`ddlAChi${idSin}`).ej2_instances[0].text = null;
                                document.getElementById(`ddlCosa${idSin}`).ej2_instances[0].value = null;
                                document.getElementById(`ddlCosa${idSin}`).ej2_instances[0].text = null;
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            }, true, true);
                        }
                        else if (ret2.retCode === 2) {
                            toast.error(`Attenzione!! Parametri per invio Email errati. L' errore riscontrato è il seguente : ${JSON.stringify(ret2.msgErr)}  `, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 15000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });  
                        }
                    }).catch(messaggio2 => {
                        toast.warn(messaggio2, {
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                    })
                }).catch(messaggio => { 
                    toast.warn(messaggio, {
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );
                });
            }
            else if (document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value === '2') {
                this.aggiornaCampiSMS().then(ret => {
                    this.invioSMSRemake(sms_da_inviare, document.getElementById(`ddlCosa${idSin}`).ej2_instances[0].value, ret)
                    .then(ret2 => {
                        if (ret2 === 0) {
                        }
                        else if (ret2.retCode === 1) {
                            toast.success('Messaggio/i SMS e/o WhatsApp inviato/i con successo!!', {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });  
                            const dataObj = {
                                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                DescrNota: `Da: ${document.getElementById('mittenteSMS_azioneSMS').ej2_instances[0].value}<br>Inviato il: ${moment(new Date()).format('dddd DD MMMM YYYY HH:mm:ss')}<br>A: ${document.getElementById('destinatarioSMS_azioneSMS').ej2_instances[0].value}<br>Inviato ${document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].text} ${document.getElementById(`ddlAChi${idSin}`).ej2_instances[0].text} : ${document.getElementById(`ddlCosa${idSin}`).ej2_instances[0].text} : <br/><br/>${ret2.testoSMS}<br>`,
                                Stato: null
                            };
                            const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value = null;
                                document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].text = null;
                                document.getElementById(`ddlAChi${idSin}`).ej2_instances[0].value = null;
                                document.getElementById(`ddlAChi${idSin}`).ej2_instances[0].text = null;
                                document.getElementById(`ddlCosa${idSin}`).ej2_instances[0].value = null;
                                document.getElementById(`ddlCosa${idSin}`).ej2_instances[0].text = null;
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            }, true, true);
                        }
                        else if (ret2.retCode === 2) {
                            toast.error(`Attenzione!! Parametri per invio SMS errati. L' errore riscontrato è il seguente : ${JSON.stringify(ret2.msgErr)}  `, {
                                //onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 20000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });  
                        }
                    }).catch(messaggio2 => {
                        toast.warn(messaggio2, {
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                    })
                }).catch(messaggio => { 
                    toast.warn(messaggio, {
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );
                });  
            }
        }, 1500);        
    }
    getStatoAzione(IdAzione) {
        const QueryExec = `SELECT [02010].*, [02004].descrizione AS descr_stato FROM [02010] LEFT JOIN [02004] ON [02010].id_stato = [02004].id_tipo_stato WHERE [02010].id_azione = ${IdAzione}`; 
        let num = 0, descr_stato = ''; 
        return new Promise((resolve,reject) => {                 
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) {                    
                    num = retData[0].id_stato;
                    descr_stato = retData[0].descr_stato;
                } 
                resolve({ stato: num, descr_stato: descr_stato });
            }, messaggio => {               
                reject(messaggio);
            }, true, true);           
        });
    }
    getAzioneNota(IdAzione) {
        const QueryExec = `SELECT [02010].* FROM [02010] WHERE [02010].id_azione = ${IdAzione}`; 
        let num = false;  
        return new Promise((resolve,reject) => {                 
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) 
                   num = retData[0].genera_nota;
                resolve(num);
            }, messaggio => {
                reject(messaggio);
            }, true, true);                     
        });
    }
    searchCATAperta(){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT [01004].id_sinistro, [01004].chiusa FROM [01004] WHERE [01004].id_sinistro= ${document.getElementById(`idSinistro${idSin}`).value} AND [01004].chiusa=0 AND [01004].revocata=0`; 
        return new Promise((resolve,reject) => {                 
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length === 2) 
                   resolve(true);
                else
                   resolve(false);
            }, messaggio => {
                reject(messaggio);
            }, true, true);                     
        });
    }
    searchRfdAperta(){
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT [01014].id_sinistro, [01014].chiusa FROM [01014] WHERE [01014].id_sinistro= ${document.getElementById(`idSinistro${idSin}`).value} AND [01014].chiusa=0 AND [01014].revocata=0`; 
        return new Promise((resolve,reject) => {                 
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) 
                   resolve(true);
                else
                   resolve(false);
            }, messaggio => {
                reject(messaggio);
            }, true, true);                     
        });
    }
    searchPeritoAperto(){
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT [01002].id_sinistro, [01002].chiusa FROM [01002] WHERE [01002].id_sinistro= ${document.getElementById(`idSinistro${idSin}`).value} AND [01002].chiusa=0 AND [01002].revocata=0`; 
        return new Promise((resolve,reject) => {                 
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) 
                   resolve(true);
                else
                   resolve(false);
            }, messaggio => {
                reject(messaggio);
            }, true, true);                     
        });
    }
    generaNoteCAT(IdSinistro, IdCAT) {
        let noteCAT = '';
        return new Promise((resolve,reject) => {
            const QueryExec = `SELECT [00006].ragione_sociale, [00005].nominativo, [00005].telefono1, IIf([id_ub_bene]=1,[00010].[citta],IIf([id_ub_bene]=2,[01001].citta,'VEDI NOTE')) AS r_citta, IIf([id_ub_bene]=1,[00010].[provincia],IIf([id_ub_bene]=2,[01001].provincia,'VEDI NOTE')) AS r_provincia, IIf([id_ub_bene]=1,[00010].[indirizzo],IIf([id_ub_bene]=2,[01001].indirizzo,'VEDI NOTE')) AS r_indirizzo, IIf([id_ub_bene]=1,[00010].[cap],IIf([id_ub_bene]=2,[01001].cap,'VEDI NOTE')) AS r_cap, [01004].note_cat, [01001].id_interno_sinistro, [01004].pre_fabbricato, [01004].pre_contenuto, [01004].pre_macchinario, [01001].id_ub_bene, [01001].id_riparatore, [01001].indirizzo, [01001].citta, [01001].cap, [01001].provincia, [00010].ragione_sociale AS riparatore FROM ([00005] INNER JOIN ([00006] INNER JOIN ([01001] INNER JOIN [01004] ON [01001].id_sinistro = [01004].id_sinistro) ON [00006].id_collaboratore = [01004].id_collaboratore) ON [00005].id_sinistro = [01001].id_sinistro) LEFT JOIN [00010] ON [01001].id_riparatore = [00010].id_riparatore WHERE  [01004].id_cat = ${IdCAT}`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) {
                    noteCAT = CustomJS.isNullEmpty(retData[0].note_cat) ? "" : retData[0].note_cat;
                    noteCAT = noteCAT.indexOf('font-size') === -1 ? `<p><span style="font-size: 23px">${noteCAT.replaceAll('<p>','').replaceAll('</p>','<br>')}</span></p>` : (noteCAT.indexOf('font-size: 8pt') > -1 || noteCAT.indexOf('font-size: 10pt') > -1 || noteCAT.indexOf('font-size: 12pt') > -1 || noteCAT.indexOf('font-size: 14pt') > -1 || noteCAT.indexOf('font-size: 16pt') > -1)  ?
                              noteCAT.replaceAll('font-size: 8pt', 'font-size: 23px').replaceAll('font-size: 10pt','font-size: 23px').replaceAll('font-size: 12pt', 'font-size: 23px').replaceAll('font-size: 14pt', 'font-size: 23px').replaceAll('font-size: 16pt', 'font-size: 23px') : noteCAT;
                    noteCAT = noteCAT.replaceAll('<p><br></p>', '<br>');
                    if (retData[0].id_ub_bene === 1)
                        noteCAT += `<p><span style="font-size: 23px">La verifica è da effettuarsi presso il tecnico riparatore:<br/>${retData[0].riparatore}<br/>${retData[0].r_indirizzo}, ${retData[0].r_cap}  ${retData[0].r_citta}  ${retData[0].r_provincia}</span></p>`;
                    else if (retData[0].id_ub_bene === 2)
                        noteCAT += '<p><span style="font-size: 23px">La verifica è da effettuarsi presso l\'assicurato.</span></p>';
                }
                resolve(noteCAT.replace(/'/g, "''"));                 
            }, messaggio => {
                reject(messaggio);                
            }, true, true);     
        });
    }
    generaNoteRFD(IdSinistro, IdRFD) {
        let noteRFD = '';
        return new Promise((resolve,reject) => {
            const QueryExec = `SELECT [00006].ragione_sociale, [00005].nominativo, [00005].telefono1, IIf([id_ub_bene]=1,[00010].[citta],IIf([id_ub_bene]=2,[01001].citta,'VEDI NOTE')) AS r_citta, IIf([id_ub_bene]=1,[00010].[provincia],IIf([id_ub_bene]=2,[01001].provincia,'VEDI NOTE')) AS r_provincia, IIf([id_ub_bene]=1,[00010].[indirizzo],IIf([id_ub_bene]=2,[01001].indirizzo,'VEDI NOTE')) AS r_indirizzo, IIf([id_ub_bene]=1,[00010].[cap],IIf([id_ub_bene]=2,[01001].cap,'VEDI NOTE')) AS r_cap, [01014].note_rfd, [01001].id_interno_sinistro, [01014].pre_fabbricato, [01014].pre_contenuto, [01014].pre_macchinario, [01001].id_ub_bene, [01001].id_riparatore, [01001].indirizzo, [01001].citta, [01001].cap, [01001].provincia, [00010].ragione_sociale AS riparatore FROM ([00005] INNER JOIN ([00006] INNER JOIN ([01001] INNER JOIN [01014] ON [01001].id_sinistro = [01014].id_sinistro) ON [00006].id_collaboratore = [01014].id_collaboratore) ON [00005].id_sinistro = [01001].id_sinistro) LEFT JOIN [00010] ON [01001].id_riparatore = [00010].id_riparatore WHERE  [01014].id_rfd = ${IdRFD}`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) {
                    noteRFD = CustomJS.isNullEmpty(retData[0].note_rfd) ? "" : retData[0].note_rfd;
                    noteRFD = noteRFD.indexOf('font-size') === -1 ? `<p><span style="font-size: 23px">${noteRFD.replaceAll('<p>','').replaceAll('</p>','<br>')}</span></p>` : (noteRFD.indexOf('font-size: 8pt') > -1 || noteRFD.indexOf('font-size: 10pt') > -1 || noteRFD.indexOf('font-size: 12pt') > -1 || noteRFD.indexOf('font-size: 14pt') > -1 || noteRFD.indexOf('font-size: 16pt') > -1)  ?
                              noteRFD.replaceAll('font-size: 8pt', 'font-size: 23px').replaceAll('font-size: 10pt','font-size: 23px').replaceAll('font-size: 12pt', 'font-size: 23px').replaceAll('font-size: 14pt', 'font-size: 23px').replaceAll('font-size: 16pt', 'font-size: 23px') : noteRFD;
                    noteRFD = noteRFD.replaceAll('<p><br></p>', '<br>');
                    noteRFD += '<p><span style="font-size: 23px">La riparazione è da effettuarsi presso l\'assicurato.</span></p>';
                }
                resolve(noteRFD.replace(/'/g, "''"));                 
            }, messaggio => {
                reject(messaggio);                
            }, true, true);     
        });
    }
    preparaEmailStato(Stato, Sollecito) {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const esegui = async (elm) => {
            return new Promise((resolve,reject) => {
                if (elm.destinataria !== '[CQ]' || !CustomJS.isNullEmpty(document.getElementById(`assegnaCQ${idSin}`).ej2_instances[0].value)) {
                    if (!CustomJS.isNullEmpty(elm.destinataria))
                        destinazionia_s = elm.destinataria;
                    if (!CustomJS.isNullEmpty(elm.destinataricc))
                        destinazionicc_s = elm.destinataricc;
                    if (!CustomJS.isNullEmpty(elm.destinatariccn))
                        destinazioniccn_s = elm.destinatariccn;
                    oggetto_email = elm.oggetto;
                    testo_email = elm.testo;
                    if (!CustomJS.isNullEmpty(document.getElementById(`emailAgenzia${idSin}`).ej2_instances[0].value)) {
                        destinazionia_s = destinazionia_s.replace('[AGENZIA]', document.getElementById(`emailAgenzia${idSin}`).ej2_instances[0].value);
                        destinazionicc_s = destinazionicc_s.replace('[AGENZIA]', document.getElementById(`emailAgenzia${idSin}`).ej2_instances[0].value);
                        destinazioniccn_s = destinazioniccn_s.replace('[AGENZIA]', document.getElementById(`emailAgenzia${idSin}`).ej2_instances[0].value);
                    }
                    if (!CustomJS.isNullEmpty(document.getElementById(`emailBroker${idSin}`).ej2_instances[0].value)) {
                        destinazionia_s = destinazionia_s.replace('[BROKER]', document.getElementById(`emailBroker${idSin}`).ej2_instances[0].value);
                        destinazionicc_s = destinazionicc_s.replace('[BROKER]', document.getElementById(`emailBroker${idSin}`).ej2_instances[0].value);
                        destinazioniccn_s = destinazioniccn_s.replace('[BROKER]', document.getElementById(`emailBroker${idSin}`).ej2_instances[0].value);
                    }  
                    if (!CustomJS.isNullEmpty(document.getElementById(`emailContraente${idSin}`).ej2_instances[0].value)) {
                        destinazionia_s = destinazionia_s.replace('[CONTRAENTE]', document.getElementById(`emailContraente${idSin}`).ej2_instances[0].value);
                        destinazionicc_s = destinazionicc_s.replace('[CONTRAENTE]', document.getElementById(`emailContraente${idSin}`).ej2_instances[0].value);
                        destinazioniccn_s = destinazioniccn_s.replace('[CONTRAENTE]', document.getElementById(`emailContraente${idSin}`).ej2_instances[0].value);
                    }    
                    const QueryExec2 = `SELECT [00006].email1 FROM [00006] INNER JOIN [01004] ON [00006].id_collaboratore = [01004].id_collaboratore WHERE [01004].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value} AND [01004].chiusa = 0 AND [01004].revocata = 0`;        
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec2, '', retData2 => {
                        if (retData2.length > 0) {
                            destinazionia_s = destinazionia_s.replace('[CAT]', retData2[0].email1);
                            destinazionicc_s = destinazionicc_s.replace('[CAT]', retData2[0].email1);
                            destinazioniccn_s = destinazioniccn_s.replace('[CAT]', retData2[0].email1);
                        }
                        if (!CustomJS.isNullEmpty(document.getElementById(`emailLiquidatore${idSin}`).ej2_instances[0].value)) {
                            destinazionia_s = destinazionia_s.replace('[LIQUIDATORE]', document.getElementById(`emailLiquidatore${idSin}`).ej2_instances[0].value);
                            destinazionicc_s = destinazionicc_s.replace('[LIQUIDATORE]', document.getElementById(`emailLiquidatore${idSin}`).ej2_instances[0].value);
                            destinazioniccn_s = destinazioniccn_s.replace('[LIQUIDATORE]', document.getElementById(`emailLiquidatore${idSin}`).ej2_instances[0].value);
                        }                  
                        if (!CustomJS.isNullEmpty(document.getElementById(`emailAssicurato${idSin}`).ej2_instances[0].value)) {
                            destinazionia_s = destinazionia_s.replace('[ASSICURATO]', document.getElementById(`emailAssicurato${idSin}`).ej2_instances[0].value);
                            destinazionicc_s = destinazionicc_s.replace('[ASSICURATO]', document.getElementById(`emailAssicurato${idSin}`).ej2_instances[0].value);
                            destinazioniccn_s = destinazioniccn_s.replace('[ASSICURATO]', document.getElementById(`emailAssicurato${idSin}`).ej2_instances[0].value);
                        }
                        if (!CustomJS.isNullEmpty(document.getElementById(`emailAmministratore${idSin}`).ej2_instances[0].value)) {
                            destinazionia_s = destinazionia_s.replace('[AMMINISTRATORE]', document.getElementById(`emailAmministratore${idSin}`).ej2_instances[0].value);
                            destinazionicc_s = destinazionicc_s.replace('[AMMINISTRATORE]', document.getElementById(`emailAmministratore${idSin}`).ej2_instances[0].value);
                            destinazioniccn_s = destinazioniccn_s.replace('[AMMINISTRATORE]', document.getElementById(`emailAmministratore${idSin}`).ej2_instances[0].value);
                        }
                        const QueryExec3 = `SELECT [09001].email1 FROM [01002] INNER JOIN [09001] ON [01002].id_utente = [09001].id_utente WHERE [01002].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value} AND [01002].chiusa = 0 AND [01002].revocata = 0`;        
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec3, '', retData3 => {
                            if (retData3.length > 0) {
                                destinazionia_s = destinazionia_s.replace('[PERITO]', retData3[0].email1);
                                destinazionicc_s = destinazionicc_s.replace('[PERITO]', retData3[0].email1);
                                destinazioniccn_s = destinazioniccn_s.replace('[PERITO]', retData3[0].email1);
                            }
                            const QueryExec4 = `SELECT [09001].email1 FROM [09001] INNER JOIN [01001] ON [09001].id_utente = [01001].CQ WHERE [01001].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;        
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec4, '', retData4 => {
                                if (retData4.length > 0) {
                                    destinazionia_s = destinazionia_s.replace('[CQ]', retData4[0].email1);
                                    destinazionicc_s = destinazionicc_s.replace('[CQ]', retData4[0].email1);
                                    destinazioniccn_s = destinazioniccn_s.replace('[CQ]', retData4[0].email1);
                                }
                                this.aggiornaCampiEmail().then(ret => {
                                    ret.forEach(elm2 =>  {
                                        testo_email = testo_email.replace(elm2[0], elm2[1]);  
                                        oggetto_email = oggetto_email.replace(elm2[0], elm2[1]);
                                    });  
                                    this.invioEmailGenerale(elm.mittente, oggetto_email, testo_email, destinazionia_s, destinazionicc_s, destinazioniccn_s)
                                    .then(ret => {
                                        if (ret === 0) {
                                            toast.warn(`MAIL NON INVIATA - Mancano i destinatari (${elm.destinataria})`, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 10000,
                                                hideProgressBar: false,           
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true}
                                            );
                                            resolve("Prossima");                          
                                        }
                                        else if (ret === 1) {
                                            toast.success('Email inviata con successo!!', {
                                                containerId: 'toastContainer1',
                                                //onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,            
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            });  
                                            const dataObj = {
                                                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                                DescrNota: `Inviata ${document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].text} ${document.getElementById(`ddlAchi${idSin}`).ej2_instances[0].text} : ${document.getElementById(`ddlCosa${idSin}`).ej2_instances[0].text}`,
                                                Stato: null
                                            };
                                            const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                                ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                                this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                                document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].value = null;
                                                document.getElementById(`ddlAzione${idSin}`).ej2_instances[0].text = null;
                                                document.getElementById(`ddlAchi${idSin}`).ej2_instances[0].value = null;
                                                document.getElementById(`ddlAchi${idSin}`).ej2_instances[0].text = null;
                                                document.getElementById(`ddlCosa${idSin}`).ej2_instances[0].value = null;
                                                document.getElementById(`ddlCosa${idSin}`).ej2_instances[0].text = null;
                                                resolve("Prossima");                          
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,           
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true}
                                                );
                                            }, true, true);
                                        }
                                        else if (ret.retCode === 2) {
                                            toast.error(`Attenzione!! Parametri per invio Email errati. L' errore riscontrato è il seguente : ${JSON.stringify(ret.msgErr)}  `, {
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 15000,
                                                hideProgressBar: false,            
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            });  
                                            resolve("Prossima");                          
                                        }
                                    }).catch(messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                        resolve("Prossima");                          
                                    });
                                }).catch(messaggio => { 
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                    resolve("Prossima");                          
                                });
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                                resolve("Prossima");                          
                            }, true, true); 
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                            resolve("Prossima");                          
                        }, true, true); 
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                        resolve("Prossima");                          
                    }, true, true); 
                }
            });
        };
        let destinazionia_s = '';
        let destinazionicc_s = '';
        let destinazioniccn_s = '';
        let oggetto_email = '';
        let testo_email = '';
        const soll = Sollecito === true ? ' and sollecito= 1' : '';
        const QueryExec = `SELECT [01008].*, [020072].id_stato FROM [020072] RIGHT JOIN [01008] ON [020072].id_email = [01008].id_email WHERE [020072].id_stato = ${Stato} ${soll}`;        
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            let arrFunc = [];
            retData.forEach(elm => { 
                arrFunc.push(esegui);
            });
            const eseguiCiclica = (ind) => {
                if (CustomJS.isNullEmpty(arrFunc[ind])) {
                    return;
                } 
                arrFunc[ind](retData[ind]).then(ret => {                 
                    if (ret === "Prossima") {
                        eseguiCiclica(ind+1);
                    }
                }).catch(err => { });
            }                  
            eseguiCiclica(0);    
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, true, true);         
    }
    aggiornaCampiEmail() {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        return new Promise((resolve,reject) => {
            this.matriceEmail = [];    
            this.matriceEmail.push(['[ragione_sociale_agenzia]', document.getElementById(`agenzia${idSin}`).ej2_instances[0].text]);  
            this.matriceEmail.push(['[nominativo_assicurato]', document.getElementById(`assicurato${idSin}`).ej2_instances[0].value]);
            this.matriceEmail.push(['[numero_sinistro]', document.getElementById(`numeroSinistro${idSin}`).value]);
            this.matriceEmail.push(['[numero_sinistro_interno]', document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value]);
            this.matriceEmail.push(['[numero_polizza]', document.getElementById(`numeroPolizza${idSin}`).ej2_instances[0].value]);
            const QueryExec = `SELECT [01004].*, [00006].ragione_sociale FROM [00006] INNER JOIN [01004] ON [00006].id_collaboratore = [01004].id_collaboratore WHERE [01004].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value} AND [01004].chiusa = 0 AND [01004].revocata = 0`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0)
                    this.matriceEmail.push(['[ragione_sociale_cat]', retData[0].ragione_sociale]);           
                else
                    this.matriceEmail.push(['[ragione_sociale_cat]', '']);
                this.matriceEmail.push(['[nominativo_contraente]', document.getElementById(`contraente${idSin}`).ej2_instances[0].value]);
                this.matriceEmail.push(['[ragione_sociale_amministratore]', document.getElementById(`amministratore${idSin}`).ej2_instances[0].value]);
                this.matriceEmail.push(['[ragione_sociale_liquidatore]', document.getElementById(`liquidatore${idSin}`).ej2_instances[0].text]);
                this.matriceEmail.push(['[ragione_sociale_broker]', document.getElementById(`broker${idSin}`).ej2_instances[0].text]);
                resolve(this.matriceEmail);
            }, messaggio => {
                reject(messaggio);                
            }, true, true); 
        });
    }
    aggiornaCampiSMS() {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        return new Promise((resolve,reject) => {
            this.matriceSMS = [];    
            this.matriceSMS.push(['[ragione_sociale_agenzia]', document.getElementById(`agenzia${idSin}`).ej2_instances[0].text]);  
            this.matriceSMS.push(['[nominativo_assicurato]', document.getElementById(`assicurato${idSin}`).ej2_instances[0].value]);
            this.matriceSMS.push(['[numero_sinistro]', document.getElementById(`numeroSinistro${idSin}`).value]);
            this.matriceSMS.push(['[numero_sinistro_interno]', document.getElementById(`id_interno_sinistro${idSin}`).ej2_instances[0].value]);
            this.matriceSMS.push(['[numero_polizza]', document.getElementById(`numeroPolizza${idSin}`).ej2_instances[0].value]);
            const QueryExec = `SELECT [01004].*, [00006].ragione_sociale FROM [00006] INNER JOIN [01004] ON [00006].id_collaboratore = [01004].id_collaboratore WHERE [01004].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value} AND [01004].chiusa = 0 AND [01004].revocata = 0`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0)
                    this.matriceSMS.push(['[ragione_sociale_cat]', retData[0].ragione_sociale]);           
                else
                    this.matriceSMS.push(['[ragione_sociale_cat]', '']);
                this.matriceSMS.push(['[nominativo_contraente]', document.getElementById(`contraente${idSin}`).ej2_instances[0].value]);
                this.matriceSMS.push(['[ragione_sociale_amministratore]', document.getElementById(`amministratore${idSin}`).ej2_instances[0].value]);
                this.matriceSMS.push(['[ragione_sociale_liquidatore]', document.getElementById(`liquidatore${idSin}`).ej2_instances[0].text]);
                this.matriceSMS.push(['[ragione_sociale_broker]', document.getElementById(`broker${idSin}`).ej2_instances[0].text]);
                resolve(this.matriceSMS);
            }, messaggio => {
                reject(messaggio);                
            }, true, true); 
        });
    }
    generaInviaSchedaIncaricoCAT(rowData) {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        return new Promise((resolve,reject) => {
            this.toggleBlocking();
            this.generaNoteCAT(document.getElementById(`idSinistro${idSin}`).value,rowData.id_cat).then(noteCAT => {
                const QueryExec = `SELECT [00006].ragione_sociale, [00005].nominativo, [00005].telefono1, [01001].id_interno_sinistro, [01004].id_cat, [01004].pre_fabbricato, [01004].pre_contenuto, [01004].pre_macchinario, '${!CustomJS.isNullEmpty(document.getElementById(`appVerif${idSin}`).ej2_instances[0].value) && document.getElementById(`appVerif${idSin}`).ej2_instances[0].value !== 0 ? 'Apparecchi da verificare: ' + document.getElementById(`appVerif${idSin}`).ej2_instances[0].value?.toString() : ''}' AS app_ver, [01001].indirizzo, [01001].citta, [01001].cap, [01001].provincia, [00003].descrizione AS divisione FROM [01001] INNER JOIN [00005] ON [01001].id_sinistro=[00005].id_sinistro INNER JOIN [01004] ON [01001].id_sinistro=[01004].id_sinistro INNER JOIN [00006] ON [01004].id_collaboratore=[00006].id_collaboratore LEFT JOIN [00003] ON [01001].id_divisione=[00003].id_divisione WHERE [01004].id_cat = ${rowData.id_cat}`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                    if (retData.length > 0) 
                        retData[0].note_cat_prt = noteCAT;
                    CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/PdfCreator/CreaSchedaIncaricoCATACT`, { datiSchedaIncaricoJSON: JSON.stringify(retData[0]) }, 'POST', 
                    { "Authorization" : CustomJS.tokenAuthAPIVarious }, retData2 => {
                        retData2 = retData2.replaceAll('/','[slash]');
                        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumentoAWSS3/${encodeURIComponent(retData2)}`, '', 'GET',
                        '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData3 => { 
                            retData2 = retData2.replaceAll('[slash]','/');
                            this.invioEmailSchedaIncaricoCAT(rowData, `${CustomJS.APIVariousJfishTechURLProd}/${CustomJS.CartellaTemporaneaACT}/${retData2}`)
                            .then(ret2 => {
                                resolve(ret2);
                            }).catch(messaggio2 => {
                                reject(messaggio2);
                            })
                        }, error => {
                            this.toggleBlocking();
                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                            reject(messaggio);
                        }, true, false);
                    }, error => {
                        this.toggleBlocking();
                        let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        reject(messaggio);                
                    }, true, false);   
                }, messaggio => {
                    this.toggleBlocking();
                    reject(messaggio);                
                }, true, true); 
            }).catch(messaggio2 => {
                this.toggleBlocking();
                reject(messaggio2);
            });
        });
    }
    generaInviaSchedaIncaricoRFD(rowData) {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        return new Promise((resolve,reject) => {
            this.toggleBlocking();
            this.generaNoteRFD(document.getElementById(`idSinistro${idSin}`).value, rowData.id_rfd).then(noteRFD => {
                const QueryExec = `SELECT [00006].ragione_sociale, [00005].nominativo, [00005].telefono1, [01001].id_interno_sinistro, [01014].id_rfd, [01014].pre_fabbricato, [01014].pre_contenuto, [01014].pre_macchinario, '${!CustomJS.isNullEmpty(document.getElementById(`appDaRip${idSin}`).ej2_instances[0].value) && document.getElementById(`appDaRip${idSin}`).ej2_instances[0].value !== 0 ? 'Apparecchi da riparare: ' + document.getElementById(`appDaRip${idSin}`).ej2_instances[0].value?.toString() : ''}' AS app_ver, [01001].indirizzo, [01001].citta, [01001].cap, [01001].provincia, [00003].descrizione AS divisione FROM [01001] INNER JOIN [01014] ON [01001].id_sinistro = [01014].id_sinistro INNER JOIN [00006] ON [01014].id_collaboratore = [00006].id_Collaboratore INNER JOIN [00005] ON [01001].id_sinistro = [00005].id_sinistro LEFT JOIN [00003] ON [01001].id_divisione = [00003].id_divisione WHERE [01014].id_RFD = ${rowData.id_rfd}`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                    if (retData.length > 0) 
                        retData[0].note_rfd_prt = noteRFD;                    
                    CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/PdfCreator/CreaSchedaIncaricoRFDACT`, { datiSchedaIncaricoJSON: JSON.stringify(retData[0]) }, 'POST', 
                    { "Authorization" : CustomJS.tokenAuthAPIVarious }, retData2 => {
                        retData2 = retData2.replaceAll('/','[slash]');
                        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumentoAWSS3/${encodeURIComponent(retData2)}`, '', 'GET',
                        '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData3 => {
                            retData2 = retData2.replaceAll('[slash]','/'); 
                            this.invioEmailSchedaIncaricoRFD(rowData, `${CustomJS.APIVariousJfishTechURLProd}/${CustomJS.CartellaTemporaneaACT}/${retData2}`)
                            .then(ret2 => {
                                resolve(ret2);
                            }).catch(messaggio2 => {
                                reject(messaggio2);
                            })
                        }, error => {
                            this.toggleBlocking();
                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                            reject(messaggio);
                        }, true, false);
                    }, error => {
                        this.toggleBlocking();
                        let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        reject(messaggio);                
                    }, true, false);   
                }, messaggio => {
                    this.toggleBlocking();
                    reject(messaggio);                
                }, true, true); 
            }).catch(messaggio2 => {
                this.toggleBlocking();
                reject(messaggio2);
            });
        });
    }
    esportaSchedaPeritale(IdPerizia, NumeroModello, NomePerito, SaltaErrore) {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const dicitureValidePolizza = ["sole", "fotovoltaici", "fotovoltaico", "eolico", "solaria", "aria", "solar", "energia"];
        let QueryExec = '';
        if (NumeroModello === 38)
            QueryExec = `SELECT [01001].*, '${NomePerito.replaceAll('\'','`')}' AS NomePerito, [01002].id_perizia, [00002].id_compagnia AS IdCompagnia, [00002].descrizione AS compagnia, [00003].descrizione AS divisione, [00004].ragione_sociale AS agenzia, [00007].descrizione AS area, [02002].descrizione AS danno_riscontrato, CONCAT([nome], ' ', [modello], ' ', [edizione]) AS polizza FROM ([00007] INNER JOIN ((((([01001] INNER JOIN [01002] ON [01001].id_sinistro = [01002].id_sinistro) INNER JOIN [00002] ON [01001].id_compagnia = [00002].id_compagnia) INNER JOIN [00003] ON [01001].id_divisione = [00003].id_divisione) INNER JOIN [00004] ON [01001].id_agenzia = [00004].id_agenzia) INNER JOIN [02002] ON [01001].id_tipo_danno_den = [02002].id_tipo_fenomeno) ON [00007].id_area = [01001].id_area) INNER JOIN [02001] ON [01001].id_tipo_polizza = [02001].id_tipo_polizza WHERE [01002].id_perizia = ${IdPerizia}`;
        else
            QueryExec = `SELECT [01001].*, '${NomePerito.replaceAll('\'','`')}' AS NomePerito,[01002].id_perizia, [00002].id_compagnia AS IdCompagnia, [00002].descrizione AS compagnia,[00003].descrizione AS divisione,[00004].ragione_sociale AS agenzia,[00007].descrizione AS area,[02002].descrizione AS danno_riscontrato, CONCAT([nome],' ',[modello],' ',[edizione]) AS polizza,[00012].[ragione_sociale] as nominativoAmm,[00008].[ragione_sociale] as nominativoBrok,ISNULL([00005].nominativo, '') AS danneggiato,ISNULL([00005].codice_fiscale, '') AS cod_fiscale_danneggiato,ISNULL([00005].partita_iva, '') AS partita_iva_danneggiato,ISNULL([00005].IBAN, '') AS IBAN_danneggiato,CONCAT([00012].[email1],IIF([00012].[email2] IS NULL,'',CONCAT(' - ',[00012].[email2]))) as emailAmm,CONCAT([00012].[telefono1],IIF([00012].[telefono2] IS NULL,'',CONCAT(' - ',[00012].[telefono2]))) as telAmm,CONCAT([00008].[email1],IIF([00008].[email2] IS NULL,'',CONCAT(' - ',[00008].[email2]))) as emailBrok,CONCAT([00008].[telefono1],IIF([00008].[telefono2] IS NULL,'',CONCAT(' - ',[00008].[telefono2]))) as telBrok, [02035].descrizione AS DescrRamo FROM (([00007] INNER JOIN ((((([01001] INNER JOIN [01002] ON [01001].id_sinistro=[01002].id_sinistro) INNER JOIN [00002] ON [01001].id_compagnia=[00002].id_compagnia) INNER JOIN [00003] ON [01001].id_divisione=[00003].id_divisione) INNER JOIN [00004] ON [01001].id_agenzia=[00004].id_agenzia) INNER JOIN [02002] ON [01001].id_tipo_danno_den=[02002].id_tipo_fenomeno) ON [00007].id_area=[01001].id_area) INNER JOIN [02001] ON [01001].id_tipo_polizza=[02001].id_tipo_polizza) LEFT JOIN [00012] ON [01001].id_amministratore=[00012].id_amministratore LEFT JOIN [00008] ON [01001].id_broker=[00008].id_broker LEFT JOIN [00005] ON [01001].id_sinistro=[00005].id_sinistro LEFT JOIN [02035] ON [01001].id_tipo_ramo=[02035].id_tipo_ramo WHERE [01002].id_perizia=${IdPerizia}`;
        this.toggleBlocking();
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            if (retData.length === 0) {
                this.toggleBlocking();
                this.dialogInstance3.header = 'Attenzione!!';
                this.dialogInstance3.content = 'Esportazione scheda peritale non possibile : i dati del sinistro sono incompleti!!';
                this.dialogInstance3.width = '19%';
                this.dialogInstance3.height = '17%';
                this.dialogInstance3.minHeight = '17%';
                this.dialogInstance3.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance3.show();
                return;
            }
            CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/ExcelCreator/CreaSchedaPeritaleACT`, { datiSchedaPeritaleJSON: JSON.stringify(retData[0]), NumeroModello: NumeroModello, ControlloEsistenza: true, DicitureValidePolizza: dicitureValidePolizza }, 'POST', 
            { "Authorization" : CustomJS.tokenAuthAPIVarious }, retData2 => {
                if (retData2 === 'PeriziaEsiste') {
                    this.toggleBlocking();
                    this.dialogInstance3.header = 'Attenzione!!';
                    this.dialogInstance3.content = "E' già presente un file di perizia all'interno della cartella del sinistro, se si continua verrà sovrascritto. Continuare?";
                    this.dialogInstance3.width = '25%';
                    this.dialogInstance3.height = '24%';
                    this.dialogInstance3.minHeight = '24%';
                    this.dialogInstance3.buttons = [
                        {   click: () => { 
                                this.dialogInstance3.hide();
                                this.toggleBlocking();
                                CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/ExcelCreator/CreaSchedaPeritaleACT`, { datiSchedaPeritaleJSON: JSON.stringify(retData[0]), NumeroModello: NumeroModello, ControlloEsistenza: false, DicitureValidePolizza: dicitureValidePolizza }, 'POST', 
                                { "Authorization" : CustomJS.tokenAuthAPIVarious }, retData3 => {
                                    retData3[0] = retData3[0].replaceAll('/','[slash]');
                                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumentoAWSS3/${encodeURIComponent(retData3[0])}`, '', 'GET',
                                    '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData4 => {
                                        retData3[0] = retData3[0].replaceAll('[slash]','/');
                                        const container = document.getElementById('defaultDialogContent2');
                                        const root = createRoot(container);  
                                        root.render(<CallbackWrapper callback={() => {
                                            this.dialogInstance3.header = 'Attenzione!!';
                                            this.dialogInstance3.content = `Scheda peritale esportata con successo nel file ${retData3[0]} e salvata nella cartella della documentazione del sinistro!!`;
                                            this.dialogInstance3.width = '25%';
                                            this.dialogInstance3.height = '23%';
                                            this.dialogInstance3.minHeight = '23%';
                                            this.dialogInstance3.buttons = [{ click: () => {   
                                                this.dialogInstance3.hide();
                                                //VISUALIZZAZIONE DEL DOCUMENTO DI PERIZIA RIMOSSO SU RICHIESTA DEGLI UTENTI, IN QUANTO NON STRETTAMENTE NECESSARIO
                                                // this.dialogInstance5.header = 'VISUALIZZAZIONE DOCUMENTO';    
                                                // this.dialogInstance5.width = '65%';
                                                // this.dialogInstance5.height = '90%';
                                                // this.dialogInstance5.minHeight = '95%';
                                                // this.dialogInstance5.beforeOpen = undefined;
                                                // this.dialogInstance5.open = undefined;  
                                                // this.dialogInstance5.buttons = [];    
                                                // this.dialogInstance5.show(); 
                                                // setTimeout(() => {
                                                //     const URLVisualGoogle = `https://docs.google.com/gview?url=${encodeURIComponent(retData4.signedURL)}&embedded=true`;
                                                //     const URLVisualOffice365 = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(retData4.signedURL)}`;
                                                //     const encodedFilePath = encodeURIComponent(`${CustomJS.APIVariousJfishTechURLProd}/${retData4.filePath}`);
                                                //     const URLVisualLocale = `http://docs.google.com/viewer?url=${encodedFilePath}&embedded=true`;
                                                //     document.getElementById(`IframeDocumento${idSin}`).src = URLVisualOffice365;
                                                // }, 200);  
                                            }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                                            this.dialogInstance3.show(); 
                                            this.toggleBlocking();
                                        }}>{this.otherDocumentRender()}</CallbackWrapper>);      
                                    }, error => {
                                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                        this.toggleBlocking();
                                    }, true, false);
                                    if (!CustomJS.isNullEmpty(retData3[1])) {
                                        retData3[1] = retData3[1].replaceAll('/','[slash]');
                                        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumentoAWSS3/${encodeURIComponent(retData3[1])}`, '', 'GET',
                                        '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData5 => { 
                                        }, error => {
                                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,           
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true}
                                            );
                                        }, true, false);    
                                    }
                                }, error => {
                                    let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                    this.toggleBlocking();
                                }, true, false); 
                            }, 
                            buttonModel: { 
                                content: 'CONFERMA', 
                                cssClass: 'e-primary',
                                isPrimary: true 
                            }
                        },
                        {
                            click: () => {
                                this.dialogInstance3.hide();
                            },
                            buttonModel: {
                                content: 'ANNULLA',
                            }
                        }
                    ];
                    this.dialogInstance3.show();
                }
                else {
                    retData2[0] = retData2[0].replaceAll('/','[slash]');
                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumentoAWSS3/${encodeURIComponent(retData2[0])}`, '', 'GET',
                    '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData3 => { 
                        retData2[0] = retData2[0].replaceAll('[slash]','/');
                        const container = document.getElementById('defaultDialogContent2');
                        const root = createRoot(container);  
                        root.render(<CallbackWrapper callback={() => {
                            this.dialogInstance3.header = 'Attenzione!!';
                            this.dialogInstance3.content = `Scheda peritale esportata con successo nel file ${retData2[0]} e salvata nella cartella della documentazione del sinistro!!`;
                            this.dialogInstance3.width = '23%';
                            this.dialogInstance3.height = '21%';
                            this.dialogInstance3.minHeight = '21%';
                            this.dialogInstance3.buttons = [{ click: () => {   
                                this.dialogInstance3.hide();
                                //VISUALIZZAZIONE DEL DOCUMENTO DI PERIZIA RIMOSSO SU RICHIESTA DEGLI UTENTI, IN QUANTO NON STRETTAMENTE NECESSARIO
                                // this.dialogInstance5.header = 'VISUALIZZAZIONE DOCUMENTO';    
                                // this.dialogInstance5.width = '65%';
                                // this.dialogInstance5.height = '90%';
                                // this.dialogInstance5.minHeight = '95%';
                                // this.dialogInstance5.beforeOpen = undefined;
                                // this.dialogInstance5.open = undefined;  
                                // this.dialogInstance5.buttons = [];    
                                // this.dialogInstance5.show(); 
                                // setTimeout(() => {
                                //     const URLVisualGoogle = `https://docs.google.com/gview?url=${encodeURIComponent(retData3.signedURL)}&embedded=true`;
                                //     const URLVisualOffice365 = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(retData3.signedURL)}`;
                                //     const encodedFilePath = encodeURIComponent(`${CustomJS.APIVariousJfishTechURLProd}/${retData3.filePath}`);
                                //     const URLVisualLocale = `http://docs.google.com/viewer?url=${encodedFilePath}&embedded=true`;
                                //     document.getElementById(`IframeDocumento${idSin}`).src = URLVisualOffice365;
                                // }, 200);  
                            }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                            this.dialogInstance3.show();  
                            this.toggleBlocking();  
                        }}>{this.otherDocumentRender()}</CallbackWrapper>);      
                    }, error => {
                        this.toggleBlocking();
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                        this.toggleBlocking();
                    }, true, false);
                    if (!CustomJS.isNullEmpty(retData2[1])) {
                        retData2[1] = retData2[1].replaceAll('/','[slash]');
                        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumentoAWSS3/${encodeURIComponent(retData2[1])}`, '', 'GET',
                        '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData5 => { 
                        }, error => {
                            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        }, true, false);    
                    }
                }
            }, error => {
                let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
                this.toggleBlocking();
            }, true, false); 
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
            this.toggleBlocking();
        }, true, true);
    }
    duplicaSinistro() {
        this.toggleBlocking();
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/duplicaSinistro/${this.salvaRigaSel.IdInternoSinistro}`, '', 'POST',
        'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking();
                return;
            }    
            const dataObj = {
                IdSinistro : retData.id_sinistro,
                Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                DescrNota: `SINISTRO COPIATO DA ${this.salvaRigaSel.IdInternoSinistro}`,
            };
            const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, null)";        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData2 => {
                this.toggleBlocking();
                setTimeout(() => {
                    document.getElementById('aggiornatabella').click();
                }, 80);
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/creaCartellaIniziale/${retData.id_interno_sinistro}`, '', 'POST',
            '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, false);    
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            this.toggleBlocking();
        }, true, false); 
    }
    creaTab(objDettaglio, nuovo) {  
        let idSin = objDettaglio.id_interno_sinistro;
        let funcName = `tabContent${idSin}`;
        let tabDettaglio = `tabDettaglio${idSin}`;
        if (!CustomJS.isNullEmpty(this[funcName])){
            this.tabInstance.select(this.tabInstance.items.getIndexBy('id', idSin));
            return;
        }  
        this[funcName] = () => {
            return (<React.Fragment> 
                        <TabComponent id={tabDettaglio} cssClass='e-fill' headerPlacement='Top' width='100%' selecting={this.tabSelecting2.bind(this)} clearTemplates={false} selected={this.tabSelected2.bind(this)} 
                                animation={{ previous: { effect: 'FadeIn', duration: 500, easing: 'ease' }, next: { effect: 'FadeIn', duration: 500, easing: 'ease' } }} style={{ marginLeft: '5px', marginTop: '30px' }}>
                            <TabItemsDirective>
                                <TabItemDirective header={this.tabHeaderText2[0]} content={this.tabContentDettaglio1Render.bind(this, idSin, objDettaglio)}/>
                            </TabItemsDirective>
                        </TabComponent> 
            </React.Fragment>);
        };
        this.tabInstance.addTab([{ id: idSin, header: { text: nuovo ? `Inserimento sinistro num. ${idSin}` : `Modifica sinistro num. ${idSin}` }, content: this[funcName], nuovo: nuovo }], this.tabInstance.items.length); 
        this.tabInstance.select(this.tabInstance.items.length -1);         
        //TODO SPOSTATI DENTRO IL SETTIMEOUT PER RISOLVERE ERRORE OCCASIONALE LATO CLIENT. SE FUNZIONANTE RIMUOVERE LE RIGHE COMMENTATE 
        // this.onChangeGruppoDettaglio({ value: objDettaglio.id_compagnia?.toString() }); 
        // this.onChangeCompagniaDettaglio({ value: objDettaglio.id_divisione?.toString() }); 
        // this.onChangeAChiDettaglio({ value: objDettaglio.id_tipo_dest?.toString() });  
        // this.onChangeAzioneDettaglio({ value: objDettaglio.id_tipo_messaggio?.toString() });         

        setTimeout(() => {  
            this.onChangeGruppoDettaglio({ value: objDettaglio.id_compagnia?.toString() }); 
            this.onChangeCompagniaDettaglio({ value: objDettaglio.id_divisione?.toString() }); 
            this.onChangeAChiDettaglio({ value: objDettaglio.id_tipo_dest?.toString() });  
            this.onChangeAzioneDettaglio({ value: objDettaglio.id_tipo_messaggio?.toString() }); 
            if ($('#divBtnFinePag').length === 0) { 
                $(`<div id="divBtnFinePag"></div>`).appendTo('body');
                $('#divBtnFinePag').css({ 
                    position: "absolute",
                    marginLeft: 0, marginTop: 0,
                    top: 135, left: window.innerWidth - 190
                });   
                document.getElementById('divBtnFinePag').style.removeProperty('display');     
                const container = document.getElementById('divBtnFinePag');
                const root = createRoot(container);                         
                root.render(<CallbackWrapper callback={() => {
                }}><ButtonComponent id='btnFinePag' cssClass='e-info' created={this.onCreatedBtn1}>Vai a fine pagina</ButtonComponent></CallbackWrapper>);   
            }   
            else
                document.getElementById('divBtnFinePag').style.removeProperty('display'); 
            if (!CustomJS.isNullEmpty(document.getElementById(`idIncarico${idSin}`).value)) {
                if ($('#divBtnVediNotif').length === 0) { 
                    $(`<div id="divBtnVediNotif"></div>`).appendTo('body');
                    $('#divBtnVediNotif').css({ 
                        position: "absolute",
                        marginLeft: 0, marginTop: 0,
                        top: 135, left: window.innerWidth - 328
                    });   
                    document.getElementById('divBtnVediNotif').style.removeProperty('display');     
                    const container = document.getElementById('divBtnVediNotif');
                    const root = createRoot(container);                         
                    root.render(<CallbackWrapper callback={() => {
                    }}><ButtonComponent id='btnVediNotif' cssClass='e-warning' onClick={this.btnVediNotif.bind(this)}>Vedi Notifiche</ButtonComponent></CallbackWrapper>);   
                }   
                else
                    document.getElementById('divBtnVediNotif').style.removeProperty('display');   
            }  
            else if ($('#divBtnVediNotif').length > 0)
                document.getElementById('divBtnVediNotif').style.display = 'none';

            if ($('#divBtnCreaDocumPerizia').length === 0) { 
                $(`<div id="divBtnCreaDocumPerizia"></div>`).appendTo('body');
                $('#divBtnCreaDocumPerizia').css({ 
                    position: "absolute",
                    marginLeft: 0, marginTop: 0,
                    top: 135, left: window.innerWidth - 560
                });   
                document.getElementById('divBtnCreaDocumPerizia').style.removeProperty('display');     
                const container = document.getElementById('divBtnCreaDocumPerizia');
                const root = createRoot(container);                         
                root.render(<CallbackWrapper callback={() => {
                }}><ButtonComponent id="btnCreaDocumPerizia" alt='CREA DOCUMENTO PERIZIA' cssClass='e-fill' style={{ backgroundColor: 'yellow', color: 'DarkBlue' }} onClick={this.caricaDatiDocumentoPerizia.bind(this)} type='button'>CREA DOCUMENTO DI PERIZIA</ButtonComponent></CallbackWrapper>);   
            }   
            else
                document.getElementById('divBtnCreaDocumPerizia').style.removeProperty('display'); 

            document.getElementById(`gridCatAssegnati${idSin}`).ej2_instances[0].element.classList.add('disablegrid');
            document.getElementById(`gridCatAssegnatiParent${idSin}`).classList.add('wrapper');  
            document.getElementById(`gridRFDAssegnati${idSin}`).ej2_instances[0].element.classList.add('disablegrid');
            document.getElementById(`gridRFDAssegnatiParent${idSin}`).classList.add('wrapper');  
            document.getElementById(`gridPeritiAssegnati${idSin}`).ej2_instances[0].element.classList.add('disablegrid');
            document.getElementById(`gridPeritiAssegnatiParent${idSin}`).classList.add('wrapper');  
            this.textBoxInstance1.addAttributes({ rows: 4 });   
            this.textBoxInstance2.addAttributes({ rows: 3 });
            this.textBoxInstance3.addAttributes({ rows: 10 }); 
            this.textBoxInstance4.addAttributes({ rows: 6 });              
            document.getElementById(`compagnia${idSin}`).ej2_instances[0].value = objDettaglio.id_divisione?.toString();
            setTimeout(() => {
                document.getElementById(`agenzia${idSin}`).ej2_instances[0].value = objDettaglio.id_agenzia?.toString();
            }, 600);
            document.getElementById(`ddlCosa${idSin}`).ej2_instances[0].value = objDettaglio.titolo_email;
            document.getElementById(`telefonoAgenzia${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`agenzia${idSin}`).ej2_instances[0]?.itemData) ? null : document.getElementById(`agenzia${idSin}`).ej2_instances[0].itemData?.telefono1;
            document.getElementById(`emailAgenzia${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`agenzia${idSin}`).ej2_instances[0].itemData) ? null : document.getElementById(`agenzia${idSin}`).ej2_instances[0].itemData?.email1;
            document.getElementById(`telefonoLiquidatore${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`liquidatore${idSin}`).ej2_instances[0].itemData) ? null : document.getElementById(`liquidatore${idSin}`).ej2_instances[0].itemData?.telefono1;
            document.getElementById(`emailLiquidatore${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`liquidatore${idSin}`).ej2_instances[0].itemData) ? null : document.getElementById(`liquidatore${idSin}`).ej2_instances[0].itemData?.email1;            
            document.getElementById(`telefonoBroker${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`broker${idSin}`).ej2_instances[0].itemData) ? null : document.getElementById(`broker${idSin}`).ej2_instances[0].itemData?.telefono1;
            document.getElementById(`emailBroker${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`broker${idSin}`).ej2_instances[0]?.itemData) ? null : document.getElementById(`broker${idSin}`).ej2_instances[0].itemData?.email1;
            document.getElementById(`telefonoAmministratore${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`amministratore${idSin}`).ej2_instances[0].itemData) ? null : document.getElementById(`amministratore${idSin}`).ej2_instances[0].itemData?.telefono1;
            document.getElementById(`emailAmministratore${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`amministratore${idSin}`).ej2_instances[0]?.itemData) ? null : document.getElementById(`amministratore${idSin}`).ej2_instances[0].itemData?.email1;
            if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti Semplici') > -1) {
                document.getElementById(`parcellaParent${idSin}`).style.display = 'none';
                document.getElementById(`parcellaCATParent${idSin}`).style.display = 'none';
            }
            if (objDettaglio.check_riserva_comunicata === true)
                document.getElementById(`riservaComunicata${idSin}`).ej2_instances[0].placeholder = 'Riserva Comunicata';
            const QueryExec = `SELECT [01001].id_sinistro, [01001].id_interno_sinistro, [01001].numero_polizza FROM [01001] WHERE [01001].id_sinistro<>${objDettaglio.id_sinistro} AND [01001].numero_polizza='${objDettaglio.numero_polizza?.replaceAll('\'', '\'\'')}';`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                this.dsSinStessaPolizza = retData.map(elm => { return { VALUE: elm.id_sinistro?.toString(), TEXT: elm.id_interno_sinistro}});
                document.getElementById(`associazionePolizza${idSin}`).ej2_instances[0].dataSource = this.dsSinStessaPolizza;
                if (this.dsSinStessaPolizza.length > 0)
                    document.getElementById(`associazionePolizza${idSin}`).style.backgroundColor = '#F39F9C';

                const QueryExec2 = `SELECT [01001].id_sinistro, [01001].id_interno_sinistro, [01001].ass_nominativo FROM [01001] WHERE [01001].id_sinistro<>${objDettaglio.id_sinistro} AND [01001].ass_nominativo=\'${objDettaglio.ass_nominativo?.replaceAll('\'', '\'\'')}\';`;        
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec2, '', retData2 => {
                    this.altriSinAssic = retData2.map(elm => { return { VALUE: elm.id_sinistro?.toString(), TEXT: elm.id_interno_sinistro}});
                    document.getElementById(`altriSinistriAssicurato${idSin}`).ej2_instances[0].dataSource = this.altriSinAssic;
                    if (this.altriSinAssic.length > 0)
                        document.getElementById(`altriSinistriAssicurato${idSin}`).style.backgroundColor = '#F39F9C';
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );
                }, true, true);
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true); 
            document.querySelectorAll(`input[id*="${idSin}"].e-datepicker`).forEach(el => {
                var id = el.id;        
                let dateObj = el.ej2_instances[0];
                dateObj.inputWrapper.container.style.display = "none";        
                let maskCheck = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
                const maskFormat = '[0-3][0-9]/[0-1][0-9]/[1-2][0-9][0-9][0-9]';
                let mask = new MaskedTextBox({
                    mask: maskFormat,
                    showClearButton: true,
                    placeholder: dateObj.placeholder,
                    readonly: dateObj.readonly,
                    enabled: dateObj.enabled,
                    floatLabelType: 'Auto',
                    created: function() {
                        var spanEle = dateObj.inputWrapper.buttons[0].cloneNode();
                        this.inputObj.container.appendChild(spanEle);
                        spanEle.addEventListener("click", (event) => {
                            dateObj.show();
                        });
                        this.showClearButton = false;
                    },
                    cssClass: 'e-outline',
                    blur: function () {
                        if (CustomJS.isNullEmpty(this.value)) {
                            dateObj.value = null;
                            if (document.getElementById('err_data_' + id)) {
                                document.getElementById('err_data_' + id).innerText = '';
                            }
                            return;
                        }
                        if (maskCheck.test(this.getMaskedValue())) {
                            if (document.getElementById('err_data_' + id)) {
                                document.getElementById('err_data_' + id).innerText = '';
                            }
                            dateObj.value = moment(this.getMaskedValue(), dateObj.format.toUpperCase()).format('DD/MM/YYYY');
                        }
                        else {
                            if (document.getElementById('err_data_' + id)) {
                                document.getElementById('err_data_' + id).innerText = 'Formato Data Non Valido';
                            }
                            else {
                                $('<span id="err_data_' + id + '" style="color:red;font-weight:500;font-size:10px;">Formato Data Non Valido</span>').insertAfter($(this.element))
                            }
                        }
                    },
                    value: moment(dateObj.value).format(dateObj.format.toUpperCase())
                });
                $('<input id="mask_' + id + '" type="text" />').insertAfter($(dateObj.inputWrapper.container));
                mask.appendTo('#mask_' + id);
        
                dateObj.change = (args) => {
                    if (args.isInteracted) {
                        mask.value = moment(args.value).format(dateObj.format.toUpperCase()); 
                        mask.focusIn();
                    }
                    if (id.indexOf('dataSinistro') > -1)
                        this.onChangeDataSinDettaglio(args);
                    else if (id.indexOf('denunciato') > -1)
                        this.onChangeDataDenunciatoDettaglio(args);
                    else if (id.indexOf('incarico') > -1)
                        this.onChangeDataIncaricoDettaglio(args);
                    else if (id.indexOf('dataSopralluogo') > -1)
                        this.onChangeDataSopralluogoDettaglio(args)
                    else if (id.indexOf('chiusura') > -1)
                        this.onChangeDataChiusuraDettaglio(args);
                    else if (id.indexOf('dataAssegnCQ') > -1)
                        this.onChangeDataAssegnCQDettaglio(args);
                    else if (id.indexOf('dataContrCQ') > -1)
                        this.onChangeDataContrCQDettaglio(args);
                    else if (id.indexOf('dataAssegnCR') > -1)
                        this.onChangeDataAssegnCRDettaglio(args);
                    else if (id.indexOf('dataContrCR') > -1)
                        this.onChangeDataContrCRDettaglio(args);
                    else if (id.indexOf('dataFirmaAttoUnipol') > -1)
                        this.onChangeDataFirmaAttoUnipol(args);
                    else if (id.indexOf('dataPagamentoPremio') > -1)
                        this.onChangeDataPagamentoPremio(args)
                    else if (id.indexOf('dataFirmaAttoNonConcordato') > -1)
                        this.onChangeDataFirmaAttoNonConcordato(args)
                };
                dateObj.showClearButton = false;
                dateObj.open = function(args) {
                    args.popup.relateTo = mask.inputObj.container;
                };     
            });

            document.querySelectorAll(`input[id="partitaIVADanneggiato${idSin}"]`).forEach(el => {
                let textObj = el.ej2_instances[0];        
                textObj.blur = (args) => {
                    if($(`#imgOKPIVA${idSin}`).length === 0){
                        $(`<div id='imgOKPIVA${idSin}'><img src=${ImageOK} width='20px' height='20px'/></div>`).insertAfter(el).hide();
                    }
                    if($(`#imgKOPIVA${idSin}`).length === 0){
                        $(`<div id='imgKOPIVA${idSin}'><img src=${ImageKO} width='20px' height='20px'/></div>`).insertAfter(el).hide();
                    }
                    if (CustomJS.isNullEmpty(args.value)) {
                        $(`#imgOKPIVA${idSin}`).hide();
                        $(`#imgKOPIVA${idSin}`).hide();
                        this.aggiornaDettaglio('partita_iva', args.value?.toUpperCase(), 'CHAR', '[00005]', true);
                    }
                    else{
                        if(CustomJS.controllaPIVA(args.value) === true){
                            this.aggiornaDettaglio('partita_iva', args.value?.toUpperCase(), 'CHAR', '[00005]', true);
                            $(`#imgOKPIVA${idSin}`).show();
                            $(`#imgKOPIVA${idSin}`).hide();
                            }
                        else{
                            $(`#imgOKPIVA${idSin}`).hide();
                            $(`#imgKOPIVA${idSin}`).show();
                        }
                    }
                };
            });
            document.querySelectorAll(`input[id="codiceFiscaleDanneggiato${idSin}"]`).forEach(el => {
                let textObj = el.ej2_instances[0];        
                textObj.blur = (args) => {
                    if($(`#imgOKCF${idSin}`).length === 0){
                        $(`<div id='imgOKCF${idSin}'><img src=${ImageOK} width='20px' height='20px'/></div>`).insertAfter(el).hide();
                    }
                    if($(`#imgKOCF${idSin}`).length === 0){
                        $(`<div id='imgKOCF${idSin}'><img src=${ImageKO} width='20px' height='20px'/></div>`).insertAfter(el).hide();
                    }
                    if (CustomJS.isNullEmpty(args.value)) {
                        $(`#imgOKCF${idSin}`).hide();
                        $(`#imgKOCF${idSin}`).hide();
                        this.aggiornaDettaglio('codice_fiscale', args.value?.toUpperCase(), 'CHAR', '[00005]', true);
                    }
                    else{
                        if(CustomJS.controllaCodiceFiscale(args.value) === true){
                            this.aggiornaDettaglio('codice_fiscale', args.value?.toUpperCase(), 'CHAR', '[00005]', true);
                            $(`#imgOKCF${idSin}`).show();
                            $(`#imgKOCF${idSin}`).hide();
                            }
                        else{
                            $(`#imgOKCF${idSin}`).hide();
                            $(`#imgKOCF${idSin}`).show();
                        }
                    }
                };
            });
            document.querySelectorAll(`input[id="IBANDanneggiato${idSin}"]`).forEach(el => {
                let textObj = el.ej2_instances[0];        
                textObj.blur = (args) => {
                    if($(`#imgOKIBAN${idSin}`).length === 0){
                        $(`<div id='imgOKIBAN${idSin}'><img src=${ImageOK} width='20px' height='20px'/></div>`).insertAfter(el).hide();
                    }
                    if($(`#imgKOIBAN${idSin}`).length === 0){
                        $(`<div id='imgKOIBAN${idSin}'><img src=${ImageKO} width='20px' height='20px'/></div>`).insertAfter(el).hide();
                    }
                    if (CustomJS.isNullEmpty(args.value)) {
                        $(`#imgOKIBAN${idSin}`).hide();
                        $(`#imgKOIBAN${idSin}`).hide();
                        this.aggiornaDettaglio('IBAN', args.value?.toUpperCase(), 'CHAR', '[00005]', true);
                    }
                    else{
                        if(CustomJS.validIban(args.value) === true){
                            this.aggiornaDettaglio('IBAN', args.value?.toUpperCase(), 'CHAR', '[00005]', true);
                            $(`#imgOKIBAN${idSin}`).show();
                            $(`#imgKOIBAN${idSin}`).hide();
                            }
                        else{
                            $(`#imgOKIBAN${idSin}`).hide();
                            $(`#imgKOIBAN${idSin}`).show();
                        }
                    }
                };
            });
            this.aggiornaCampoNote(objDettaglio.id_sinistro);  

            const QueryExec6 = `SELECT [01007].id_sinistro, Count([01007].id) AS conteggio, [02019].descrizione FROM [01007] INNER JOIN [02019] ON [01007].id_fonte_sollecito = [02019].id_tipo_sollecito where id_sinistro = ${objDettaglio.id_sinistro} GROUP BY [01007].id_sinistro, [02019].descrizione`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec6, '', retData6 => {
                let stringa = '';
                retData6.forEach(elm =>  { 
                    stringa += elm.descrizione + " " + elm.conteggio + " - ";
                });     
                document.getElementById(`noteSollecito${idSin}`).ej2_instances[0].value = stringa;       
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);

            const QueryExec7 = `SELECT [00006].ragione_sociale, [00006].telefono1, [00006].telefono2, [00006].email1, [00006].email2, [00006].user_sito, [00006].password_sito, [01004].* FROM [01004] INNER JOIN [00006] ON [01004].id_collaboratore = [00006].id_collaboratore WHERE [01004].id_sinistro = ${objDettaglio.id_sinistro}`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec7, '', retData7 => {
                this.dsCatAssegnati = retData7.map(elm => { return {id_cat: elm.id_cat, id_collaboratore: elm.id_collaboratore, ragione_sociale: elm.ragione_sociale, t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_sopralluogo: CustomJS.isNullEmpty(elm.t_sopralluogo) ? '' : moment(elm.t_sopralluogo).format('DD/MM/YYYY HH:mm'), t_riconsegnato: CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), telefono1: elm.telefono1, telefono2: elm.telefono2, email1: elm.email1, email2: elm.email2, note_cat: elm.note_cat, chiusa: elm.chiusa, revocata: elm.revocata, utente_JFCAT: elm.user_sito, password_JFCAT: elm.password_sito, inviato_JFCAT: elm.inviato_JFCAT, revocato_JFCAT: elm.revocato_JFCAT}}); 
                document.getElementById(`gridCatAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsCatAssegnati;
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);

            const QueryExec8 = `SELECT [00006].ragione_sociale, [00006].telefono1, [00006].telefono2, [00006].email1, [00006].email2, [01014].* FROM [01014] INNER JOIN [00006] ON [01014].id_collaboratore = [00006].id_collaboratore WHERE [01014].id_sinistro = ${objDettaglio.id_sinistro}`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec8, '', retData8 => {
                this.dsRfdAssegnati = retData8.map(elm => { return { id_rfd: elm.id_rfd, id_collaboratore: elm.id_collaboratore, ragione_sociale: elm.ragione_sociale, t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' : moment(elm.t_assegnato).format('DD/MM/YYYY'), t_riconsegnato: CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), telefono1: elm.telefono1, telefono2: elm.telefono2, email1: elm.email1, email2: elm.email2, note_rfd: elm.note_rfd, chiusa: elm.chiusa, revocata: elm.revocata}}); 
                document.getElementById(`gridRFDAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsRfdAssegnati;      
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);

            const QueryExec9 = `SELECT CONCAT([09001].nome, ' ', [09001].cognome) AS nomeperito, [09001].email1, [01002].* FROM [01002] INNER JOIN [09001] ON [01002].id_utente = [09001].id_utente WHERE [01002].id_sinistro = ${objDettaglio.id_sinistro}`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec9, '', retData9 => {
                this.dsPeritiAssegnati = retData9.map(elm => { return { id_perizia: elm.id_perizia, id_utente: elm.id_utente, nomeperito: elm.nomeperito, t_assegnato: CustomJS.isNullEmpty(elm.t_assegnato) ? '' :  moment(elm.t_assegnato).format('DD/MM/YYYY'), t_riconsegnato:CustomJS.isNullEmpty(elm.t_riconsegnato) ? '' : moment(elm.t_riconsegnato).format('DD/MM/YYYY'), chiusa: elm.chiusa, revocata: elm.revocata, email: elm.email1}}); 
                document.getElementById(`gridPeritiAssegnati${idSin}`).ej2_instances[0].dataSource = this.dsPeritiAssegnati;          
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);

            const QueryExec10 = `SELECT COUNT(*) AS PresenzaStima FROM [11004] WHERE [11004].id_sinistro = ${objDettaglio.id_sinistro}`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec10, '', retData10 => {
                if (retData10[0].PresenzaStima === 0)
                    document.getElementById(`elaboratoCalcoli${idSin}`).ej2_instances[0].disabled = true;
                else
                    document.getElementById(`elaboratoCalcoli${idSin}`).ej2_instances[0].disabled = false;
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);

            // UNIPOLSAI
            if (objDettaglio.id_compagnia?.toString() === CustomJS.codiceCompagniaUnipolSAI && !CustomJS.isNullEmpty(objDettaglio.IdIncaricoUNI)) {
                this.getDatiUnipolSAI(idSin);
            }

            // GENERALI
            if (objDettaglio.id_compagnia?.toString() === CustomJS.codiceCompagniaGenerali && !CustomJS.isNullEmpty(objDettaglio.IdIncaricoGEN)) {
                //this.controlloDanneggiati();   PER ORA NON SERVE
                //this.getNumeroSinistroPolizzaPrecedente(objDettaglio.numero_polizza);  CAPIRE PER COSA SERVE ED EVENTUALMENTE USARE. SEMBRA NON SERVIRE A NULLA
                this.getInfoRichieste();
                this.getDanneggiati();
                this.getProdotto(); 
                this.contaLiquidazioni(); 
                this.getImportoLiquidazione();  
                this.getFotoRischioAssicurato();
                if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti Semplici') > -1) {
                    document.getElementById(`btnDocs${idSin}`).ej2_instances[0].items.splice(2, 1);          
                    document.getElementById(`btnDocs${idSin}`).ej2_instances[0].refresh();    
                }
            } 
            else {
                document.getElementById(`btnDocs${idSin}`).ej2_instances[0].items.splice(2, 1);          
                document.getElementById(`btnDocs${idSin}`).ej2_instances[0].refresh();    
            }

            this.mostraAlertAnomaliaBeni();

            if (nuovo) {
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/creaCartellaIniziale/${idSin}`, '', 'POST',
                '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );
                }, true, false);    
            }
            this.emettiNotifiche(objDettaglio.id_sinistro, objDettaglio.id_interno_sinistro, false, objDettaglio.id_compagnia, null, null, true);
            setTimeout(() => {
                if (this.dsPeritiAssegnati.filter(elm => { return elm.revocata === false }).length === 0) {
                    document.getElementById(`datiGenerali${objDettaglio.id_interno_sinistro}`).style.display = 'none';
                    document.getElementById(`datiUnipolSAI${objDettaglio.id_interno_sinistro}`).style.display = 'none';
                    document.getElementById(`footerDettaglio${objDettaglio.id_interno_sinistro}`).style.display = `none`;
                    document.getElementById(`divBtnCreaDocumPerizia`).style.display = 'none';
                }
                else {
                    if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti Semplici') === -1
                        //&& JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Team Leader') === -1  //RIMOSSO SU RICHIESTA DI STARRANTINO PER ABILITARE INNANZITUTTO IL PERITO ANGRISANI
                        && JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Referenti ACE') === -1) {
                            const sortedDesc = _.sortBy(this.dsPeritiAssegnati.filter(elm => { return elm.revocata === false }), item => {
                                                                                        const [day, month, year] = item.t_assegnato.split('/');
                                                                                        return new Date(`${year}-${month}-${day}`);}).reverse();
                            const objUtente = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella09001'))).filter(elm => { return elm.id_utente === sortedDesc[0].id_utente });
                            const parEmail = (objUtente[0].smtp_server?.trim() ?? '') + ';' + (objUtente[0].smtp_porta?.trim() ?? '') + ';' + (objUtente[0].smtp_utente?.trim() ?? '') + ';' +
                                             (objUtente[0].smtp_password?.trim() ?? '') + ';' + objUtente[0].smtp_ssl + ';' + (objUtente[0].smtp_mittente?.trim() ?? '') + ';' +
                                             (objUtente[0].smtp_email?.trim() ?? '') + ';' + (objUtente[0].oauth2_client_id?.trim() ?? '') + ';' + (objUtente[0].oauth2_client_secret?.trim() ?? '') + ';' +
                                             (objUtente[0].oauth2_refresh_token?.trim() ?? '');
                            this.dsMittentiEmail = this.dsMittentiEmail.filter(elm => { return elm.VALUE !== 'PERITO' })
                            this.dsMittentiEmail.push({ VALUE: 'PERITO', TEXT: `${parEmail.split(';')[5]} <${parEmail.split(';')[6]}>`, ParametriEmail: parEmail });   
                    } 
                }
            }, 500);

            if (objDettaglio.esito_automatico_fulminazioni === true) {
                const container = document.getElementById(`divEsitoFulminazione${idSin}`);
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    setTimeout(() => {
                        if (objDettaglio.fulminazione_entro_3KM === true || objDettaglio.fulminazione_oltre_3KM === true) {
                            document.getElementById(`descrEsitoAutomaticoReportFulmini${idSin}`).innerText = 'PRESENZA FULMINAZIONI';
                            document.getElementById(`descrEsitoAutomaticoReportFulmini${idSin}`).style.color = 'green';
                        }
                        else {  
                            document.getElementById(`descrEsitoAutomaticoReportFulmini${idSin}`).innerText = 'NESSUNA FULMINAZIONE';
                            document.getElementById(`descrEsitoAutomaticoReportFulmini${idSin}`).style.color = 'red';
                        }
                        document.getElementById(`checkFulminazioneEntro3KM${idSin}`).ej2_instances[0].checked = objDettaglio.fulminazione_entro_3KM === true;
                        document.getElementById(`checkFulminazioneOltre3KM${idSin}`).ej2_instances[0].checked = objDettaglio.fulminazione_oltre_3KM === true;
                        document.getElementById(`descrTipoFulminiEntro3KM${idSin}`).innerText = objDettaglio.tipo_fulminazione_entro_3KM;
                        document.getElementById(`descrTipoFulminiOltre3KM${idSin}`).innerText = objDettaglio.tipo_fulminazione_oltre_3KM;
                    }, 100);
                }}>{this.esitoReportFulminiRender()}</CallbackWrapper>);    
            }

            $(`[id=noteEstrattoDenuncia${objDettaglio.id_interno_sinistro}_toolbar_FontColor_Target]`).hide();
            $(`[id=noteEstrattoDenuncia${objDettaglio.id_interno_sinistro}_toolbar_BackgroundColor_Target]`).hide();
            $(`[id=noteCat${objDettaglio.id_interno_sinistro}_toolbar_FontColor_Target]`).hide();
            $(`[id=noteCat${objDettaglio.id_interno_sinistro}_toolbar_BackgroundColor_Target]`).hide();
            $(`[id=noteRfd${objDettaglio.id_interno_sinistro}_toolbar_FontColor_Target]`).hide();
            $(`[id=noteRfd${objDettaglio.id_interno_sinistro}_toolbar_BackgroundColor_Target]`).hide();   
        }, 900);
    }
    tabContentDettaglio1Render(idSin, objDettaglio) {
        let idSinistro = `idSinistro${idSin}`;
        let idIncarico = `idIncarico${idSin}`;
        let idStato = `idStato${idSin}`;
        let idUbicazBene = `idUbicazBene${idSin}`;
        let numBoxID1 = `id_interno_sinistro${idSin}`;
        let numBoxID2 = `stato${idSin}`;
        let ddlGruppo = `gruppo${idSin}`;
        let ddlCompagnia = `compagnia${idSin}`;
        let ddlArea = `area${idSin}`;
        let check1 = `triage${idSin}`;
        let check2 = `prontaDefinizione${idSin}`;
        let check3 = `videoperizia${idSin}`;
        let check4 = `riapertura${idSin}`;
        let check6 = `sollgiust${idSin}`;
        let check7 = `sollatto${idSin}`;
        let check8 = `documentale${idSin}`;
        let check9 = `periziaSemplificata${idSin}`;
        let ddlGradoSinistro = `gradoSinistro${idSin}`;
        let ddlAgenzia = `agenzia${idSin}`;
        let comunicazionePerAgenzia = `comunicazionePerAgenzia${idSin}`;
        let btnReportFulminiCESI = `btnReportFulminiCESI${idSin}`;
        let btnReportFulminiMETEOCAST = `btnReportFulminiMETEOCAST${idSin}`;
        let btnDialogoIntelligenzaArtificiale = `btnDialogoIntelligenzaArtificiale${idSin}`;
        let divReportFulminiIA = `divReportFulminiIA${idSin}`;
        let divEsitoFulminazione = `divEsitoFulminazione${idSin}`
        let telAgenzia = `telefonoAgenzia${idSin}`;
        let emailAgenzia = `emailAgenzia${idSin}`;
        let garanzia = `garanzia${idSin}`;
        let ramo = `ramo${idSin}`;
        let numSin = `numeroSinistro${idSin}`;
        let btnSin = `btnSin${idSin}`;
        let btnImportaSin = `btnImportaSin${idSin}`;
        let divBtnImportaSin = `divBtnImportaSin${idSin}`;
        let sinAssoc = `sinistroAssociato${idSin}`;
        let dataSin = `dataSinistro${idSin}`;
        let denunciato = `denunciato${idSin}`;
        let incarico = `incarico${idSin}`;
        let btnIncarico = `btnIncarico${idSin}`;
        let dataSopr = `dataSopralluogo${idSin}`;
        let dataOraSoprConcord = `dataOraSopralluogoConcordata${idSin}`;
        let chiusura = `chiusura${idSin}`;
        let liquid = `liquidatore${idSin}`;
        let telLiquid = `telefonoLiquidatore${idSin}`;
        let emailLiquid = `emailLiquidatore${idSin}`;
        let broker = `broker${idSin}`;
        let telBroker = `telefonoBroker${idSin}`;
        let emailBroker = `emailBroker${idSin}`;
        let numPoliz = `numeroPolizza${idSin}`;
        let btnPolizza = `btnPolizza${idSin}`;
        let btnVediCercaCGAPolizze = `btnVediCercaCGAPolizze${idSin}`;
        let btnVediCercaDocsPolizze = `btnVediCercaDocsPolizze${idSin}`;
        let associazPoliz = `associazionePolizza${idSin}`;
        let btnSinAssociato = `btnSinAssociato${idSin}`;
        let tipoPoliz = `tipoPolizza${idSin}`;
        let altriSinAssic = `altriSinistriAssicurato${idSin}`;
        let btnSinAltriAssic = `btnSinAltriAssic${idSin}`;
        let contraente = `contraente${idSin}`;
        let btnContraente = `btnContraente${idSin}`;
        let telContraente = `telefonoContraente${idSin}`;
        let emailContraente = `emailContraente${idSin}`;
        let assicurato = `assicurato${idSin}`;
        let telAssicurato = `telefonoAssicurato${idSin}`;
        let emailAssicurato = `emailAssicurato${idSin}`;
        let amministratore = `amministratore${idSin}`;
        let btnAmministratore = `btnAmministratore${idSin}`;
        let telAmministratore = `telefonoAmministratore${idSin}`;
        let emailAmministratore = `emailAmministratore${idSin}`;
        let danneggiato = `danneggiato${idSin}`;
        let telDanneggiato = `telefonoDanneggiato${idSin}`;
        let emailDanneggiato = `emailDanneggiato${idSin}`;
        let codFiscDanneggiato = `codiceFiscaleDanneggiato${idSin}`;
        let partIVADanneggiato = `partitaIVADanneggiato${idSin}`;
        let IBANDanneggiato = `IBANDanneggiato${idSin}`;
        let indUbiRisc = `indirizzoUbicazioneRischio${idSin}`;
        let cittaUbiRisc = `cittaUbicazioneRischio${idSin}`;
        let capUbiRisc = `capUbicazioneRischio${idSin}`;
        let provUbiRisc = `provinciaUbicazioneRischio${idSin}`;
        let btnUbiRisc = `btnUbicazioneRischio${idSin}`;
        let noteDenunc = `noteEstrattoDenuncia${idSin}`;
        let giustifDenunc = `giustificativiDenuncia${idSin}`;
        let riservaDenunc = `riservaDenuncia${idSin}`;
        let richiestaDenunc = `richiestaDenuncia${idSin}`;
        let assegnaCat = `assegnaCat${idSin}`;
        let preesFabbCat = `preesistenzaFabbricatoCat${idSin}`;
        let preesMaccCat = `preesistenzaMacchinarioCat${idSin}`;
        let preesContCat = `preesistenzaContenutoCat${idSin}`;
        let noteCat = `noteCat${idSin}`;
        let appVerif = `appVerif${idSin}`;
        let gridCatAssegnati = `gridCatAssegnati${idSin}`;
        let gridCatAssegnatiParent = `gridCatAssegnatiParent${idSin}`;  
        let gridRFDAssegnati = `gridRFDAssegnati${idSin}`;    
        let gridRFDAssegnatiParent = `gridRFDAssegnatiParent${idSin}`;      
        let ripristFormDir = `ripristinoFormaDiretta${idSin}`;
        let motivazNo = `motivazioneNo${idSin}`;
        let assegnaRfd = `assegnaRfd${idSin}`;
        let btnEsporta = `btnEsporta${idSin}`;
        let preesFabbRfd = `preesistenzaFabbricatoRfd${idSin}`;
        let preesMaccRfd = `preesistenzaMacchinarioRfd${idSin}`;
        let preesContRfd = `preesistenzaContenutoRfd${idSin}`;
        let appDaRip = `appDaRip${idSin}`;
        let noteRfd = `noteRfd${idSin}`;
        let btnIncaricoRfd = `btnIncaricoRfd${idSin}`;
        let gridPeritiAssegnati = `gridPeritiAssegnati${idSin}`;
        let gridPeritiAssegnatiParent = `gridPeritiAssegnatiParent${idSin}`;
        let assegnaPerito = `assegnaPerito${idSin}`;
        let assegnaCQ = `assegnaCQ${idSin}`;
        let assegnaCR = `assegnaCR${idSin}`;        
        let dataAssegnCQ = `dataAssegnCQ${idSin}`;
        let dataAssegnCR = `dataAssegnCR${idSin}`;
        let dataContrCQ = `dataContrCQ${idSin}`;
        let dataContrCR = `dataContrCR${idSin}`;
        let interlocPres = `interlocutoriePresenti${idSin}`;
        let istruzioniChiusura = `istruzioniChiusura${idSin}`;
        let btnEsportaInterlocutoria = `btnEsportaInterlocutoria${idSin}`;
        let btnEsportaPrimoCont = `btnEsportaModuloPrimoContatto${idSin}`;
        let checkRiservaComunicata = `checkRiservaComunicata${idSin}`;
        let riservaComunicata = `riservaComunicata${idSin}`;
        let parcella = `parcella${idSin}`;
        let parcellaParent = `parcellaParent${idSin}`;
        let parcellaPerito = `parcellaPerito${idSin}`;
        let parcellaCAT = `parcellaCAT${idSin}`;
        let parcellaCATParent = `parcellaCATParent${idSin}`;
        let btnParcella = `btnParcella${idSin}`;
        let btnParcellaPerito = `btnParcellaPerito${idSin}`;
        let btnParcellaCAT = `btnParcellaCAT${idSin}`;
        let ddlSoll = `ddlSollecito${idSin}`;
        let noteSoll = `noteSollecito${idSin}`;
        let ddlCambiaStato = `ddlCambiaStato${idSin}`;
        let btnCambiaStato = `btnCambiaStato${idSin}`;
        let dataAlert = `dataAlert${idSin}`;
        let ddlAzione = `ddlAzione${idSin}`;
        let ddlAchi = `ddlAChi${idSin}`;
        let ddlCosa = `ddlCosa${idSin}`;
        let divNotaManuale = `divNotaManuale${idSin}`;
        let mascheraInterl = `mascheraInterl${idSin}`;
        let checkInterl = `checkInterl${idSin}`;
        let NoteFin = `noteFin${idSin}`;
        let btnEsci = `btnEsci${idSin}`;
        let btnEsci2 = `btnEsci2${idSin}`;
        let btnStampa = `btnStampa${idSin}`;
        let btnDocs = `btnDocs${idSin}`;
        let btnSblocca = `btnSblocca${idSin}`;
        let btnInvioComunicazioneUNI = `btnInvioComunicazioneUNI${idSin}`;
        let btnInvioChiusuraGEN = `btnInvioChiusuraGEN${idSin}`;
        let btnVisualizzaDocumentoGEN = `btnVisualizzaDocumentoGEN${idSin}`;
        let btnAgenzia = `btnAgenzia${idSin}`;
        let btnLiquidatore = `btnLiquidatore${idSin}`;
        let btnBroker = `btnBroker${idSin}`;
        let btnTipoPolizza = `btnTipoPolizza${idSin}`;
        let btnAzione = `btnAzione${idSin}`;
        let btnSoll = `btnSoll${idSin}`;
        let btnApriMaschera = `btnApriMaschera${idSin}`;
        let btnAggiornaNota = `btnAggiornaNota${idSin}`;
        let btnCancellaNota = `btnCancellaNota${idSin}`;
        let btnPerito = `btnPerito${idSin}`;
        let btnCancInterl = `btnCancInterl${idSin}`;
        let btnInvioInterl = `btnInvioInterl${idSin}`;
        let btnInizioPag1 = `btnInizioPag1${idSin}`;
        let btnInizioPag2 = `btnInizioPag2${idSin}`;  
        let footerDettaglio = `footerDettaglio${idSin}`; 
        let statoNonModificabile = `statoNonModificabile${idSin}`;  
        let elaboratoPeritale = `elaboratoPeritale${idSin}`;   
        let elaboratoCalcoli = `elaboratoCalcoli${idSin}`;   

        this.prefissoCompagnia = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaCompagnie_prefissi')))?.find(({ cod_ele }) => cod_ele === objDettaglio.id_compagnia?.toString())?.descriz;
        this.dsEsito = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_esito`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        //this.dsEsitoDanno = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_esito_danno`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoLiquidazione = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_liquidazione`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoBeneficiario = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_beneficiario`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoPagamento = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_pagamento`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        //this.dsFormulaAssicurazione = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_formula_ass`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoServizio = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_servizio`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz, SERVIZIO_STR2: elm.servizio_str2 } });
        this.dsEsitoPerizia = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_esito_perizia`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
        this.dsMotivoMancataDefinizione = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_motivo_mancata_definizione`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
        this.dsMotivoNegativa = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_motivo_negativa_videoper`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsComuni = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaCodici_comuni'))).filter(elm => { return !CustomJS.isNullEmpty(elm.servizio_str1) })?.map(elm => { return { cod_ele: elm.cod_ele.toString(), DESCRIZ: elm.descriz } });
        this.dsCap = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaCodici_comuni')))?.map(elm => { return { cod_ele: elm.cod_ele.toString(), DESCRIZ: elm.servizio_str2 } });
        this.dsNazioni = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaCodici_comuni'))).filter(elm => { return CustomJS.isNullEmpty(elm.servizio_str1) })?.map(elm => { return { cod_ele: elm.cod_ele.toString(), DESCRIZ: elm.descriz, VALUE: elm.servizio_str3 } });
        this.dsMotivoInterlocutoria = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_motivo_interlocutoria`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } }).slice(1);
        this.dsTipoBeneGFK = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_tipo_bene_GFK`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoBeneGFKNonPresente = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_tipo_bene_GFK_non_presente`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoDefinizione = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_tipo_definizione`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsMezzoPagamento = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_mezzo_pagamento`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoBeneficiarioUNI = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_tipo_beneficiario`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoPersona = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_tipo_persona`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsSessoAssicurato = [{ VALUE: 'M', TEXT: 'M' }, { VALUE: 'F', TEXT: 'F' }];
        this.dsSessoBeneficiario = [{ VALUE: 'M', TEXT: 'MASCHIO' }, { VALUE: 'F', TEXT: 'FEMMINA' }];

        return (<React.Fragment>
                    <input type="hidden" id={idStato} value={objDettaglio.id_stato}/>
                    <input type="hidden" id={idSinistro} value={objDettaglio.id_sinistro}/>
                    <input type="hidden" id={idIncarico} value={!CustomJS.isNullEmpty(objDettaglio.IdIncaricoUNI) ? objDettaglio.IdIncaricoUNI : !CustomJS.isNullEmpty(objDettaglio.IdIncaricoGEN) ? objDettaglio.IdIncaricoGEN : ''}/>
                    <input type="hidden" id={idUbicazBene} value={objDettaglio.id_ub_bene}/>
                    <input type="hidden" id={dataOraSoprConcord} value={objDettaglio.DataOraSopralluogoConcordata}/>
                    <div className='container-fluid' style={{display:'flex'}}>
                    <div className="control-section card-control-section basic_card_layout" style={{width: '60%'}}>
                        <div className="e-card-resize-container">
                            <div className="row card-layout">
                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                    <div tabIndex="0" className="e-card" id="main_card">                                                
                                        <div className="e-card-content"> 
                                            <div className='row'>
                                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">  
                                                    <TextBoxComponent id={numBoxID1} floatLabelType='Auto' placeholder='ID Sinistro' readonly={true} value={objDettaglio.id_interno_sinistro} cssClass='e-outline' style={{backgroundColor:'yellow'}}></TextBoxComponent>
                                                </div>
                                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">  
                                                    <TextBoxComponent id={numBoxID2} floatLabelType='Auto' placeholder='Stato' readonly={true} value={objDettaglio.descrizione} cssClass='e-outline' style={{backgroundColor:'yellow'}}></TextBoxComponent>
                                                </div>
                                                <div className="col-xs-6 col-sm-6 col-lg-6 col-md-5">  
                                                    <ButtonComponent id={btnStampa} alt='Stampa' onClick={this.btnStampa.bind(this)} cssClass='e-fill e-normal' style={{marginBottom: '5px', display: 'none'}}>Stampa</ButtonComponent>
                                                    &nbsp;&nbsp;&nbsp; 
                                                    <SplitButtonComponent id={btnDocs} items={this.btnItems} content='DOCUMENTAZIONE' cssClass='e-fill e-normal' style={{ height:'35px' }} disabled={true} select={this.onSelectSplitBtn.bind(this)}></SplitButtonComponent> 
                                                    &nbsp;&nbsp;&nbsp;
                                                    <ButtonComponent id={btnSblocca} alt='Sblocca Aggiornamenti' onClick={this.btnSblocca.bind(this)} cssClass='e-fill e-info'>SBLOCCA</ButtonComponent>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <ButtonComponent id={btnInvioComunicazioneUNI} title='' onClick={this.btnInvioComunicazioneUNI.bind(this)} style={{ width: '58px', height: '45px', background: 'gold', alignContent: 'center', display: 'none' }} cssClass='e-fill fas fa-paper-plane fa-2x'></ButtonComponent>
                                                    <ButtonComponent id={btnInvioChiusuraGEN} title='' onClick={this.btnInvioChiusuraGEN.bind(this)} style={{ width: '58px', height: '45px', background: 'gold', alignContent: 'center', display: 'none' }} cssClass='e-fill fas fa-paper-plane fa-2x'></ButtonComponent>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                                    <ButtonComponent id={btnVisualizzaDocumentoGEN} title='' onClick={this.btnVisualizzaDocumentoGENERALI.bind(this)} style={{ width: '58px', height: '45px', background: 'gold', alignContent: 'center', display: 'none' }} cssClass='e-fill fas fa-file-pdf fa-2x'></ButtonComponent>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                                    <ButtonComponent id={btnEsci} alt='Esci' onClick={this.btnEsci.bind(this)} cssClass='e-fill e-danger'>Esci</ButtonComponent>
                                                </div>
                                            </div> 
                                            <div className="control-section card-control-section basic_card_layout" style={{marginTop: '-15px'}}>
                                                <div className="e-card-resize-container">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                                <div className="e-card-content" style={{ backgroundColor:'#E0FFFF'}}> 
                                                                    <div className='row' style={{marginTop: '-5px'}}>  
                                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" id='campi1'>    
                                                                            <DropDownListComponent id={ddlGruppo} fields={this.ddlFields} dataSource={this.dsGruppoFiltro} readonly={true} enabled={false}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} value={objDettaglio.id_compagnia?.toString()} change={this.onChangeGruppoDettaglio.bind(this)}
                                                                                placeholder="Gruppo" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline' > 
                                                                            </DropDownListComponent>                                                                     
                                                                        </div>    
                                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" id='campiSfondoBianco'>    
                                                                            <DropDownListComponent id={ddlCompagnia} fields={this.ddlFields} dataSource={this.dsCompDivFiltro} readonly={true}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} value={objDettaglio.id_divisione?.toString()}  change={this.onChangeCompagniaDettaglio.bind(this)}
                                                                                placeholder="Compagnia/Divisione" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                            </DropDownListComponent>                                                                     
                                                                        </div>  
                                                                    </div>
                                                                    <div className='row' style={{marginTop: '-15px'}}>
                                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4"> 
                                                                            <div className='row'>
                                                                                <div id='campiSfondoBianco'>   
                                                                                    <DropDownListComponent id={ddlArea} fields={this.ddlFields} dataSource={this.dsAreaFiltro} readonly={true} 
                                                                                        allowFiltering={true} filterType='Contains' showClearButton={true} value={objDettaglio.id_area?.toString()} change={this.onChangeAreaDettaglio.bind(this)}
                                                                                        placeholder="Area/Ispettorato" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                                    </DropDownListComponent> 
                                                                                    <DropDownListComponent id={ddlGradoSinistro} fields={this.ddlFields} dataSource={this.dsGradoSinistroDettaglio} readonly={true} style={{ marginTop: '10px' }}
                                                                                        allowFiltering={false} filterType='Contains' showClearButton={true} value={objDettaglio.id_grado_sinistro?.toString()} change={this.onChangeGradoSinistroDettaglio.bind(this)}
                                                                                        placeholder="Grado Sinistro" floatLabelType='always' popupHeight="250px" cssClass='e-outline'> 
                                                                                    </DropDownListComponent> 
                                                                                    {/* </div>  */}
                                                                                </div>   
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8 gestione-check" >    
                                                                            <div className='row'>
                                                                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">    
                                                                                    <CheckBoxComponent id={check4} label='Riapertura' checked={objDettaglio.riapertura} change={this.onChangeRiaperturaDettaglio.bind(this)} disabled={true}></CheckBoxComponent>
                                                                                </div> 
                                                                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">    
                                                                                    <CheckBoxComponent id={check1} label='Triage' checked={objDettaglio.triage} change={this.onChangeTriageDettaglio.bind(this)} disabled={true}></CheckBoxComponent>
                                                                                </div> 
                                                                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3 ultimo-check-aggiustamento" >   
                                                                                    <CheckBoxComponent id={check2} label='Pronta Definizione' checked={objDettaglio.pronta_definizione} change={this.onChangeProntaDefinizioneDettaglio.bind(this)} disabled={true}></CheckBoxComponent>
                                                                                </div>  
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">  
                                                                                    <CheckBoxComponent id={check3} label='Videoperizia' checked={objDettaglio.videoperizia} change={this.onChangeVideoperiziaDettaglio.bind(this)} disabled={true}></CheckBoxComponent>
                                                                                </div>  
                                                                                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                                                                    <CheckBoxComponent id={check6} label='Sollecito Documentazione' checked={objDettaglio.sollecito_giustificativo} change={this.onChangeSollGiustDettaglio.bind(this)} disabled={true}></CheckBoxComponent>
                                                                                </div> 
                                                                                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">    
                                                                                    <CheckBoxComponent id={check7} label='Sollecito Atto' checked={objDettaglio.sollecito_atto} change={this.onChangeSollAttoDettaglio.bind(this)} disabled={true}></CheckBoxComponent>
                                                                                </div> 
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">  
                                                                                    <CheckBoxComponent id={check8} label='Solo Documentale' checked={objDettaglio.documentale} change={this.onChangeDocumentaleDettaglio.bind(this)} disabled={true}></CheckBoxComponent>
                                                                                </div>  
                                                                                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 ms-4 checkbox-grande">  
                                                                                    <CheckBoxComponent id={check9} label='Perizia Semplificata (No Excel)' checked={objDettaglio.perizia_semplificata} change={this.onChangePeriziaSemplificataDettaglio.bind(this)} disabled={true}></CheckBoxComponent>
                                                                                </div>  
                                                                            </div>
                                                                        </div>   
                                                                    </div>
                                                                    <div className='row' style={{marginTop: '-5px', height:'45px'}}>  
                                                                        <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5" id='campi1'>  
                                                                            <DropDownListComponent id={ddlAgenzia} fields={this.ddlFields} dataSource={this.dsAgenziaDettaglio} readonly={true}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} value={objDettaglio.id_agenzia?.toString()} change={this.onChangeAgenziaDettaglio.bind(this)}
                                                                                placeholder="Agenzia" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                            </DropDownListComponent>  
                                                                        </div>  
                                                                        <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                            <ButtonComponent id={btnAgenzia} title='Apri Tabella Agenzie' cssClass='e-details' iconCss='fas fa-edit fa-10x' onClick={this.btnAgenzia.bind(this)} style={{ display: 'none' }} ></ButtonComponent>
                                                                        </div>
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>  
                                                                            <TextBoxComponent id={telAgenzia} floatLabelType='Auto' placeholder='Telefono' cssClass='e-outline'  blur={this.onChangeAgenziaTelDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                        </div>
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>  
                                                                            <TextBoxComponent id={emailAgenzia} floatLabelType='Auto' placeholder='Email' cssClass='e-outline'  blur={this.onChangeAgenziaEmailDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row' style={{marginTop: '10px'}}>
                                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" id='campiSfondoBianco'>
                                                                            <span style={{ fontWeight: 'bolder', fontSize: '18px', color: 'red' }}>ATTENZIONE! COMUNICAZIONE VISIBILE ALL’AGENZIA</span>
                                                                            <TextBoxComponent id={comunicazionePerAgenzia} floatLabelType='Auto' placeholder='Comunicazione per Agenzia' ref = {txtObj => {this.textBoxInstance6 = txtObj}} multiline={true}  cssClass='e-outline' value={objDettaglio.comunicazione_per_agenzia} blur={this.onChangeComunicazionePerAgenziaDettaglio.bind(this)} readOnly={true} style={{ marginTop: '3px' }}></TextBoxComponent>
                                                                        </div>
                                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                                            <div id={divReportFulminiIA} className="report-container" style={{ display: 'none' }}>
                                                                                <fieldset className="report-fieldset">
                                                                                    <legend><span style={{ width: '180px', display: 'inline-block', backgroundColor: '#e0ffff' }}>REPORT FULMINI</span></legend>
                                                                                    <ButtonComponent id={btnReportFulminiCESI} title='Scarica il report fulmini CESI (Meteorage) e visualizza esito' onClick={this.scaricoReportFulmini.bind(this, 'CESI')} style={{ width: '85px', height: '45px', background: '#ccffcc', alignContent: 'center', display: 'none' }} alt="Scarica il report fulmini CESI (Meteorage) e visualizza esito" cssClass='e-fill fa-2x'>CESI</ButtonComponent>
                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                    <ButtonComponent id={btnReportFulminiMETEOCAST} title='Scarica il report fulmini Meteocast e visualizza esito' onClick={this.scaricoReportFulmini.bind(this, 'MCAST')} style={{ width: '190px', height: '45px', background: '#3396f3', color: 'whitesmoke', alignContent: 'center', display: 'none' }} alt="Scarica il report fulmini Meteocast e visualizza esito" cssClass='e-fill fa-2x'>METEOCAST</ButtonComponent>
                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                    {/* <ButtonComponent id={btnDialogoIntelligenzaArtificiale} title="Dialoga con l'intelligenza artificiale" onClick={this.dialogoIntelligenzaArtificiale.bind(this)} style={{ width: '70px', height: '60px', background: 'gold', color: 'purple', alignContent: 'center', display: 'none' }} alt="Dialoga con l'intelligenza artificiale" cssClass='e-fill fas fa-hexagon-nodes fa-3x'>IA</ButtonComponent> */}
                                                                                    <div id={divEsitoFulminazione}></div>
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="control-section card-control-section basic_card_layout" style={{marginTop: '-15px'}}>
                                                <div className="e-card-resize-container">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                                <div className="e-card-content" style={{ backgroundColor:'#E0FFFF'}}> 
                                                                <div className='row' style={{marginTop: '-5px'}}>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <DropDownListComponent id={garanzia} fields={this.ddlFields} dataSource={this.dsTipiDannoFiltro}  readonly={true}
                                                                            allowFiltering={true} filterType='Contains' showClearButton={true} value={objDettaglio.id_tipo_danno_den?.toString()} change={this.onChangeGaranziaDettaglio.bind(this)}
                                                                            placeholder="Tipo Danno" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                        </DropDownListComponent>                                                                     
                                                                    </div>    
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <DropDownListComponent id={ramo} fields={this.ddlFields} dataSource={this.dsTipiRamoFiltro}  readonly={true}
                                                                            allowFiltering={true} filterType='Contains' showClearButton={true} value={objDettaglio.id_tipo_ramo?.toString()} change={this.onChangeRamoDettaglio.bind(this)}
                                                                            placeholder="Ramo" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                        </DropDownListComponent>                                                                     
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" id='campi3'>    
                                                                        <span style={{ fontWeight: 'bolder', fontSize: '15px', color: 'red' }}>NOTE OPERATIVE</span>
                                                                        <TextBoxComponent id={sinAssoc} floatLabelType='Auto' placeholder='' ref = {txtObj => {this.textBoxInstance2 = txtObj}} multiline={true}  cssClass='e-outline' value={objDettaglio.altri_sinistri} blur={this.onChangeSinistriAssociatiDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div> 
                                                                    <div className='row' style={{marginTop: '-48px', height:'18px'}}>
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campi1'>    
                                                                            <TextBoxComponent id={numSin} floatLabelType='Auto' placeholder='Numero Sinistro Agenzia' cssClass='e-outline' value={objDettaglio.numero_sinistro_agenzia?.toString()} blur={this.onChangeNumSinAgenzDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                        </div>  
                                                                        <div id={divBtnImportaSin} className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{ display: 'none' }}>    
                                                                            <SplitButtonComponent id={btnImportaSin} items={this.btnItems2} content='IMPORTAZIONE<br/>SINISTRO' cssClass='e-fill e-warning' style={{ width: '120px', height:'45px' }} select={this.onSelectSplitBtn3.bind(this)}></SplitButtonComponent> 
                                                                        </div>  
                                                                        <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                            <ButtonComponent id={btnSin} title='' cssClass='e-details' iconCss='fas fa-search fa-10x' style={{ display: 'none' }}></ButtonComponent>
                                                                        </div>
                                                                    </div> 
                                                                </div>
                                                                <div className='row' style={{marginTop: '-10px'}}>   
                                                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{width: '210px'}} id='campiSfondoBianco'>    
                                                                        <DatePickerComponent id={dataSin} cssClass='e-outline' format='dd/MM/yyyy' 
                                                                            showClearButton={false} placeholder='Data Sinistro' floatLabelType='Always' value={CustomJS.isNullEmpty(objDettaglio.t_sinistro) ? null : moment(objDettaglio.t_sinistro).format('DD/MM/YYYY')} blur={this.onChangeDataSinDettaglio.bind(this)} readOnly={true}></DatePickerComponent>
                                                                    </div>   
                                                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{width: '210px'}} id='campiSfondoBianco'>    
                                                                        <DatePickerComponent id={denunciato} cssClass='e-outline' format='dd/MM/yyyy'
                                                                            showClearButton={false} placeholder='Data Denunciato' floatLabelType='Always' value={CustomJS.isNullEmpty(objDettaglio.t_denuncia) ? null : moment(objDettaglio.t_denuncia).format('DD/MM/YYYY')} blur={this.onChangeDataDenunciatoDettaglio.bind(this)} readOnly={true}></DatePickerComponent>
                                                                    </div>  
                                                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{width: '210px'}} id='campiSfondoBianco'>    
                                                                        <DatePickerComponent id={incarico} cssClass='e-outline' format='dd/MM/yyyy'
                                                                            showClearButton={false} placeholder=' Data Incarico' floatLabelType='Always'  value={CustomJS.isNullEmpty(objDettaglio.t_incarico) ? null : moment(objDettaglio.t_incarico).format('DD/MM/YYYY')} blur={this.onChangeDataIncaricoDettaglio.bind(this)} readOnly={true}></DatePickerComponent>
                                                                    </div>  
                                                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{width: '210px'}} id='campiSfondoBianco'>    
                                                                        <DatePickerComponent id={dataSopr} cssClass='e-outline' format='dd/MM/yyyy'
                                                                            showClearButton={false} placeholder='Data Sopralluogo' floatLabelType='Always' value={CustomJS.isNullEmpty(objDettaglio.t_sopralluogo) ? null : moment(objDettaglio.t_sopralluogo).format('DD/MM/YYYY')} blur={this.onChangeDataSopralluogoDettaglio.bind(this)} readOnly={true}></DatePickerComponent>
                                                                    </div>  
                                                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{width: '210px'}} id='campiSfondoBianco'>    
                                                                        <DatePickerComponent id={chiusura} cssClass='e-outline' format='dd/MM/yyyy'
                                                                            showClearButton={false} placeholder='Data Chiusura' floatLabelType='Always' value={CustomJS.isNullEmpty(objDettaglio.t_chiusura) ? null : moment(objDettaglio.t_chiusura).format('DD/MM/YYYY')} blur={this.onChangeDataChiusuraDettaglio.bind(this)} readOnly={true}></DatePickerComponent>
                                                                    </div>  
                                                                </div>
                                                                <div className='row' style={{marginTop: '-15px'}}>   
                                                                    <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5" id='campiSfondoBianco'>    
                                                                        <DropDownListComponent id={liquid} fields={this.ddlFields} dataSource={this.dsLiquidatoreDettaglio}  readonly={true}
                                                                            allowFiltering={true} filterType='Contains' showClearButton={true} value={objDettaglio.id_liquidatore?.toString()} change={this.onChangeLiquidatoreDettaglio.bind(this)}
                                                                            placeholder="Liquidatore" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                        </DropDownListComponent>    
                                                                    </div>  
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                        <ButtonComponent id={btnLiquidatore} title='Apri Tabella Liquidatori' cssClass='e-details' iconCss='fas fa-edit fa-10x' onClick={this.btnLiquidatore.bind(this)} style={{ display: 'none' }}></ButtonComponent>
                                                                    </div>
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={telLiquid} floatLabelType='Auto' placeholder='Telefono' cssClass='e-outline' blur={this.onChangeTelLiquidatoreDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={emailLiquid} floatLabelType='Auto' placeholder='Email' cssClass='e-outline' blur={this.onChangeEmailLiquidatoreDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                </div>
                                                                <div className='row' style={{marginTop: '-15px' , height:'45px'}}>   
                                                                    <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5" id='campiSfondoBianco'>    
                                                                        <DropDownListComponent id={broker} fields={this.ddlFields} dataSource={this.dsBrokerDettaglio}  readonly={true}
                                                                            allowFiltering={true} filterType='Contains' showClearButton={true} value={objDettaglio.id_broker?.toString()} change={this.onChangeBrokerDettaglio.bind(this)}
                                                                            placeholder="Broker" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                        </DropDownListComponent>    
                                                                    </div>  
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                        <ButtonComponent id={btnBroker} title='Apri Tabella Broker' cssClass='e-details' iconCss='fas fa-edit fa-10x' onClick={this.btnBroker.bind(this)} style={{ display: 'none' }}></ButtonComponent>
                                                                    </div>
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={telBroker} floatLabelType='Auto' placeholder='Telefono' cssClass='e-outline' blur={this.onChangeTelBrokerDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={emailBroker} floatLabelType='Auto' placeholder='Email' cssClass='e-outline' blur={this.onChangeEmailBrokerDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                </div>
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="control-section card-control-section basic_card_layout" style={{marginTop: '-15px'}}>
                                                <div className="e-card-resize-container">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                                <div className="e-card-content" style={{ backgroundColor:'#F8F8FF'}}> 
                                                                <div className='row' style={{marginTop: '-5px'}}>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campi1'>   
                                                                        <TextBoxComponent id={numPoliz} floatLabelType='Auto' placeholder='Numero Polizza' cssClass='e-outline' value={objDettaglio.numero_polizza} blur={this.onChangeNumPolizzaDettaglio.bind(this)} readOnly={true}></TextBoxComponent> 
                                                                    </div>  
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1" id='campi1'>   
                                                                        <ButtonComponent id={btnPolizza} title='Carica Dati Polizza' cssClass='e-details' iconCss='fas fa-search fa-10x' style={{ display: 'none' }} onClick={this.btnNumPolizza.bind(this)}></ButtonComponent>
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <DropDownListComponent id={associazPoliz} fields={this.ddlFields} dataSource={this.dsSinStessaPolizza} readonly={true}
                                                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                                                            placeholder="Associazioni Polizza" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                        </DropDownListComponent>   
                                                                    </div> 
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                        <ButtonComponent id={btnSinAssociato} title='Apri Sinistro Associato Polizza' cssClass='e-details' iconCss='fas fa-search fa-10x' style={{ display: 'none' }} onClick={this.btnSinAssociato.bind(this)} ></ButtonComponent>                                                                  
                                                                    </div> 
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <DropDownListComponent id={altriSinAssic} fields={this.ddlFields}  dataSource={this.altriSinAssic}  readonly={true}
                                                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                                                            placeholder="Altri sinistri Assicurato" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                        </DropDownListComponent>    
                                                                    </div> 
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                        <ButtonComponent id={btnSinAltriAssic} title='Apri Altro Sinistro Assicurato' cssClass='e-details' iconCss='fas fa-search fa-10x' style={{ display: 'none' }} onClick={this.btnSinAltriAssic.bind(this)} ></ButtonComponent>                                                                  
                                                                    </div> 
                                                                </div> 
                                                                <div className='row' style={{marginTop: '-15px'}}>
                                                                    <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8" id='campiSfondoBianco'>    
                                                                        <DropDownListComponent id={tipoPoliz} fields={this.ddlFields} dataSource={this.dsPolizzaFiltro2} readonly={true} 
                                                                            allowFiltering={true} filterType='Contains' showClearButton={true} value={objDettaglio.id_tipo_polizza?.toString()} change={this.onChangeTipiPolizzaDettaglio.bind(this)}
                                                                            placeholder="Tipo Polizza" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                        </DropDownListComponent>                                                                     
                                                                    </div>
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                        <ButtonComponent id={btnTipoPolizza} title='Apri Tabella Polizze' cssClass='e-details' iconCss='fas fa-edit fa-10x' onClick={this.btnTipoPolizza.bind(this)} style={{ display: 'none' }}></ButtonComponent>
                                                                    </div> 
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                        <ButtonComponent id={btnVediCercaCGAPolizze} title='Visualizza/Ricerca CGA Polizze' cssClass='e-details' style={{ display: 'none', background: 'yellow' }} onClick={this.btnVediCercaCGAPolizze.bind(this)}>CGA</ButtonComponent>
                                                                    </div>                                                                                                                                        
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                        <ButtonComponent id={btnVediCercaDocsPolizze} title='Visualizza/Ricerca Documentazione Polizze' cssClass='e-details' style={{ display: 'none', background: 'cyan' }} onClick={this.btnVediCercaDocsPolizze.bind(this)}>DOCUMENTAZIONE</ButtonComponent>
                                                                    </div>
                                                                </div>
                                                                <div className='row' style={{marginTop: '-15px'}}>   
                                                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" id='campi1'>    
                                                                        <TextBoxComponent id={contraente} floatLabelType='Auto' placeholder='Contraente' cssClass='e-outline' value={objDettaglio.contr_nominativo} blur={this.onChangeContraenteNominativoDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">
                                                                        <ButtonComponent id={btnContraente} title='Compila Dati Assicurato e Danneggiato' cssClass='e-details' iconCss='fas fa-file-signature fa-10x' style={{ display: 'none' }} onClick={this.btnContraente.bind(this)}></ButtonComponent>
                                                                    </div>
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={telContraente} floatLabelType='Auto' placeholder='Telefono' cssClass='e-outline' value={objDettaglio.contr_tel1} blur={this.onChangeContraenteTel1Dettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div> 
                                                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={emailContraente} floatLabelType='Auto' placeholder='Email' cssClass='e-outline' value={objDettaglio.contr_email1} blur={this.onChangeContraenteEmail1Dettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                </div>
                                                                <div className='row' style={{marginTop: '-15px'}}>   
                                                                    <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5" id='campi1'>    
                                                                        <TextBoxComponent id={assicurato} floatLabelType='Auto' placeholder='Assicurato' cssClass='e-outline' value={objDettaglio.ass_nominativo} blur={this.onChangeAssNominativoDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={telAssicurato} floatLabelType='Auto' placeholder='Telefono' cssClass='e-outline' value={objDettaglio.ass_tel1} blur={this.onChangeAssTel1Dettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div> 
                                                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={emailAssicurato} floatLabelType='Auto' placeholder='Email' cssClass='e-outline' value={objDettaglio.ass_email1} blur={this.onChangeAssEmail1Dettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                </div>
                                                                <div className='row' style={{marginTop: '-15px'}}>   
                                                                    <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5" id='campiSfondoBianco'>    
                                                                        <DropDownListComponent id={amministratore} fields={this.ddlFields} dataSource={this.dsAmministratoreFiltro} readonly={true} 
                                                                            allowFiltering={true} filterType='Contains' showClearButton={true} value={objDettaglio.id_amministratore?.toString()} change={this.onChangeAmministratoreDettaglio.bind(this)}
                                                                            placeholder="Amministratore" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                        </DropDownListComponent>    
                                                                    </div>  
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                        <ButtonComponent id={btnAmministratore} title='Apri Tabella Amministratori' cssClass='e-details' iconCss='fas fa-edit fa-10x' style={{ display: 'none' }} onClick={this.btnAmministratori.bind(this)}></ButtonComponent>
                                                                    </div>
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={telAmministratore} floatLabelType='Auto' placeholder='Telefono' cssClass='e-outline' blur={this.onChangeAmministratoreTel1Dettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={emailAmministratore} floatLabelType='Auto' placeholder='Email' cssClass='e-outline' blur={this.onChangeAmministratoreEmail1Dettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                </div>
                                                                <div className='row' style={{marginTop: '-15px'}}>   
                                                                    <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5" id='campi1'>    
                                                                        <TextBoxComponent id={danneggiato} floatLabelType='Auto' placeholder='Danneggiato' cssClass='e-outline' value={objDettaglio.danneggiato} blur={this.onChangeDanneggiatoDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={telDanneggiato} floatLabelType='Auto' placeholder='Telefono' cssClass='e-outline' value={objDettaglio.tel_danneggiato} blur={this.onChangeDanneggiatoTel1Dettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div> 
                                                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={emailDanneggiato} floatLabelType='Auto' placeholder='Email' cssClass='e-outline' value={objDettaglio.email_danneggiato} blur={this.onChangeDanneggiatoEmail1Dettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div> 
                                                                </div>
                                                                <div className='row' style={{marginTop: '-15px'}}>   
                                                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" id='campi3'>    
                                                                        <TextBoxComponent id={codFiscDanneggiato} floatLabelType='Auto' placeholder='Codice Fiscale' cssClass='e-outline' value={objDettaglio.cod_fiscale_danneggiato} readOnly={true} ></TextBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campi3'>    
                                                                        <TextBoxComponent id={partIVADanneggiato} floatLabelType='Auto' placeholder='Partita IVA' cssClass='e-outline' value={objDettaglio.partita_iva_danneggiato} readOnly={true}></TextBoxComponent>
                                                                    </div> 
                                                                    <div className="col-xs-4 col-sm-4 col-lg-5 col-md-5" id='campi3'>    
                                                                        <TextBoxComponent id={IBANDanneggiato} floatLabelType='Auto' placeholder='IBAN' cssClass='e-outline' value={objDettaglio.IBAN_danneggiato} readOnly={true} ></TextBoxComponent>
                                                                    </div> 
                                                                </div>
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="control-section card-control-section basic_card_layout">
                                                <div className="e-card-resize-container">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                                <div className="e-card-content" style={{ backgroundColor:'lightyellow'}}> 
                                                                <h5 className="card-title">Ubicazione Rischio</h5>
                                                                <div className='row'>   
                                                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={indUbiRisc} floatLabelType='Auto' placeholder='Indirizzo' cssClass='e-outline' value={objDettaglio.indirizzo} blur={this.onChangeIndirizzoDettaglio.bind(this)}  readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={cittaUbiRisc} floatLabelType='Auto' placeholder='Città' cssClass='e-outline' value={objDettaglio.citta} blur={this.onChangeCittaDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div> 
                                                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={capUbiRisc} floatLabelType='Auto' placeholder='CAP' cssClass='e-outline' value={objDettaglio.cap} blur={this.onChangeCapDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div> 
                                                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" id='campiSfondoBianco'>  
                                                                        <DropDownListComponent id={provUbiRisc} fields={this.ddlFields} dataSource={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaProvince1')))} readonly={true}
                                                                            allowFiltering={true} filterType='Contains' showClearButton={true} value={objDettaglio.provincia} change={this.onChangeProvinciaDettaglio.bind(this)} readOnly={true}
                                                                            placeholder="Provincia" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                        </DropDownListComponent> 
                                                                    </div> 
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                        <ButtonComponent id={btnUbiRisc} title='Modifica' cssClass='e-details' iconCss='fas fa-search fa-10x' style={{ display: 'none' }} onClick={this.btnUbicazioneRischio.bind(this)}></ButtonComponent>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="control-section card-control-section basic_card_layout">
                                                <div className="e-card-resize-container">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                                <div className="e-card-content" style={{ backgroundColor:'#98FB98'}}> 
                                                                    <h5 className="card-title">Estratto Denuncia</h5>
                                                                    <div className='row'>   
                                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                                                        <RichTextEditorComponent id={noteDenunc}  showCharCount={true} enableHtmlEncode={true} value={objDettaglio.estratto_denuncia} placeholder='Note estratto denuncia' change={this.onChangeEstrattoDenunciaDettaglio.bind(this)} readonly={true}
                                                                            maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px'>
                                                                        <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table]}/>
                                                                        </RichTextEditorComponent>
                                                                        </div>  
                                                                    </div>
                                                                    <div className='row'>   
                                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 mt-4" id='campiSfondoBianco'>    
                                                                            <DropDownListComponent id={giustifDenunc} fields={this.ddlFields}dataSource={this.dsGiustificativi} readonly={true}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} value={objDettaglio.doc_giustificativi?.toString()} change={this.onChangeDocGiustificativiDettaglio.bind(this)} readOnly={true}
                                                                                placeholder="Giustificativi" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                            </DropDownListComponent>    
                                                                        </div>  
                                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 mt-4" id='campiSfondoBianco'>    
                                                                            <NumericTextBoxComponent id={riservaDenunc} floatLabelType='Auto' format='N2' decimals='2' placeholder='Riserva' cssClass='e-outline' value={objDettaglio.v_riserva} blur={this.onChangeVRiservaDettaglio.bind(this)} readOnly={true}></NumericTextBoxComponent>
                                                                        </div> 
                                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 mt-4" id='campiSfondoBianco'>    
                                                                            <NumericTextBoxComponent id={richiestaDenunc} floatLabelType='Auto' format='N2' decimals='2' placeholder='Richiesta' cssClass='e-outline' value={objDettaglio.v_richiesta} blur={this.onChangeVRichiestaDettaglio.bind(this)} readOnly={true}></NumericTextBoxComponent>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div id={`datiGenerali${idSin}`} style={{ display: (objDettaglio.id_compagnia?.toString() === CustomJS.codiceCompagniaGenerali && !CustomJS.isNullEmpty(objDettaglio.IdIncaricoGEN)) ? 'block' : 'none' }}>
                                                <div className="control-section card-control-section basic_card_layout">
                                                    <div className="e-card-resize-container">
                                                        <div className="row card-layout">
                                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" >
                                                                <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                                    <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                                        <div className='row'>
                                                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-left">
                                                                                <h5 className="card-title" style={{ color: 'black', fontWeight: '500' }}>Info Richieste</h5>
                                                                            </div>
                                                                        </div>
                                                                        <div id={`divInfoRichieste${idSin}`}>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="control-section card-control-section basic_card_layout">
                                                    <div className="e-card-resize-container">
                                                        <div className="row card-layout">
                                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" >
                                                                <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                                    <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                                        <div className='row'>
                                                                            <div className="col-xs-7 col-sm-7 col-lg-7 col-md-7">
                                                                                <h5 className="card-title" style={{ color: 'black', fontWeight: '500' }}>Definizione Del Danno</h5>
                                                                            </div>
                                                                            <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5 text-center">
                                                                                <input type="hidden" id={`fotoRischioBase64${idSin}`}/>
                                                                                <ButtonComponent id={`caricaFotoRischioGENERALI${idSin}`} cssClass='e-fill e-info' onClick={this.caricaFotoRischioGENERALI.bind(this)}>Carica Foto Rischio Assicurato</ButtonComponent>
                                                                                &nbsp;&nbsp;
                                                                                <ButtonComponent id={`btnVisualizzaFotoRischioGENERALI${idSin}`} key={this.childKey} title='Visualizza Foto Rischio Assicurato' cssClass='e-details' iconCss='fas fa-eye fa-6x' onClick={this.visualizzaFotoRischioGENERALI.bind(this)}></ButtonComponent>
                                                                                &nbsp;&nbsp;
                                                                                <ButtonComponent id={`btnCancellaFotoRischioGENERALI${idSin}`} key={this.childKey} title='Cancella Foto Rischio Assicurato' cssClass='e-details' iconCss='fas fa-trash fa-6x' onClick={this.cancellaFotoRischioGENERALI.bind(this)}></ButtonComponent>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                                <DropDownListComponent id={`esito${idSin}`} fields={this.ddlFields} dataSource={this.dsEsito} onChange={this.onChangeEsitoPeriziaGenerali.bind(this)}
                                                                                    allowFiltering={true} filterType='Contains' showClearButton={true}
                                                                                    placeholder="Esito Perizia" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                                                                </DropDownListComponent>
                                                                            </div>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                                <CheckBoxComponent id={`checkRegolaritaAmministrativa${idSin}`} label='Regolarità Amministrativa' labelPosition='Before' change={this.onChangePolizzaRegolaritaAmministrativa.bind(this)}></CheckBoxComponent>
                                                                            </div>
                                                                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                                <CheckBoxComponent id={`checkVincoli${idSin}`} label='Vincoli' labelPosition='Before' change={this.onChangePolizzaVincoli.bind(this)}></CheckBoxComponent>
                                                                            </div>
                                                                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" id={`divBtnVediLiquidazioni${idSin}`}>
                                                                                <ButtonComponent id={`btnVediLiquidazioni${idSin}`} alt='Vedi Liquidazioni' cssClass='e-fill e-info' onClick={this.aggiungiLiquidazione.bind(this)} type='button' style={{ width: '200px' }}>VISUALIZZA LIQUIDAZIONI</ButtonComponent>
                                                                                <ButtonComponent id={`btnResetLiquidazioni${idSin}`} alt='Reset Liquidazioni' cssClass='e-fill e-info' onClick={this.resetLiquidazioni.bind(this)} type='button' style={{ width: '170px', marginTop: '5px' }}>RESET LIQUIDAZIONI</ButtonComponent>
                                                                            </div>
                                                                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" id={`divBtnAttoUnico${idSin}`} style={{ display: 'none' }}>
                                                                                <DropDownButtonComponent id={`btnAttoUnico${idSin}`} items={this.items} content='ATTO UNICO' cssClass='e-fill e-success' style={{ width: '150px' }} select={this.creaLiquidazione.bind(this, 'unico')}></DropDownButtonComponent >
                                                                            </div>
                                                                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" id={`divBtnAttiSeparati${idSin}`} style={{ display: 'none' }}>
                                                                                <ButtonComponent id={`btnAttiSeparati${idSin}`} alt='Atti Separati' cssClass='e-fill e-success' onClick={this.creaLiquidazione.bind(this, 'separati')} type='button' style={{ width: '150px' }}>ATTI SEPARATI</ButtonComponent>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id={`divDataFirmaAttoNonConcordato${idSin}`} style={{ display: 'none' }}>
                                                                                <DatePickerComponent firstDayOfWeek={1} id={`dataFirmaAttoNonConcordato${idSin}`} cssClass='e-outline' format='dd/MM/yyyy'
                                                                                    blur={this.onChangeDataFirmaAttoNonConcordato.bind(this)} showClearButton={true} placeholder='Data Firma Atto Non Concordato' 
                                                                                    floatLabelType='Always'>                                                                                    
                                                                                </DatePickerComponent>
                                                                            </div>
                                                                        </div>
                                                                        <div id={`divLiquidazioni${idSin}`}></div>
                                                                        <div className="row">
                                                                            <h6>Clausole Speciali</h6>
                                                                            <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8">
                                                                                <DropDownListComponent id={`prodotto${idSin}`} fields={this.ddlFields} dataSource={this.dsPolizzaFiltro}
                                                                                    allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='500px' onChange={this.onChangeProdotto.bind(this)}
                                                                                    placeholder="Prodotto" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                                                                </DropDownListComponent>
                                                                            </div>
                                                                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                                                                <TextBoxComponent id={`descrizioneProdotto${idSin}`} floatLabelType='Auto' placeholder='Descrizione' cssClass='e-outline' blur={this.onChangeDescrizioneProdotto.bind(this)}></TextBoxComponent>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="control-section card-control-section basic_card_layout">
                                                    <div className="e-card-resize-container">
                                                        <div className="row card-layout">
                                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                                <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                                    <div className="e-card-content" style={{ backgroundColor: '#eaeefb' }}>
                                                                        <div className='row'>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                                <DropDownListComponent id={`tipoServizio${idSin}`} fields={this.ddlFields} dataSource={this.dsTipoServizio}
                                                                                    allowFiltering={true} filterType='Contains' showClearButton={true} placeholder='Tipo Servizio'
                                                                                    floatLabelType='always' popupHeight="350px" cssClass='e-outline' onChange={this.onChangeTipoServizio.bind(this)}>
                                                                                </DropDownListComponent>
                                                                            </div>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                                <DatePickerComponent firstDayOfWeek={1} id={`dataPagamentoPremio${idSin}`} cssClass='e-outline' format='dd/MM/yyyy'
                                                                                    showClearButton={true} placeholder='Pagamento Premio' floatLabelType='Always' blur={this.onChangeDataPagamentoPremio.bind(this)}></DatePickerComponent>
                                                                            </div>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                                <CheckBoxComponent id={`checkMantenimentoPolizza${idSin}`} label='Mantenimento Polizza' labelPosition='Before' change={this.onChangeMantenimentoPolizza.bind(this)}></CheckBoxComponent>
                                                                            </div>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                                <TextBoxComponent id={`notaMantenimentoPolizza${idSin}`} floatLabelType='Auto' placeholder='Nota Non Mantenimento Polizza' cssClass='e-outline' blur={this.onChangeNotaNonMantenimentoPolizza.bind(this)}></TextBoxComponent>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6"> 
                                                                                <TextBoxComponent id={`notaRitardoConsegna${idSin}`} floatLabelType='Auto' placeholder='Nota Ritardo Consegna (inserire SOLO se richiesta dalla Compagnia)' cssClass='e-outline' showClearButton={true} blur={this.onChangeNotaRitardoConsegna.bind(this)}></TextBoxComponent>
                                                                            </div>   
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id={`datiUnipolSAI${idSin}`} className="control-section card-control-section basic_card_layout" style={{ display: (objDettaglio.id_compagnia?.toString() === CustomJS.codiceCompagniaUnipolSAI && !CustomJS.isNullEmpty(objDettaglio.IdIncaricoUNI)) ? 'block' : 'none' }}>
                                                <div className="e-card-resize-container">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" >
                                                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                                    <h4 className="card-title" style={{ marginBottom: '15px', fontWeight: 'bolder' }}>Dati per chiusura telematica UNIPOLSAI</h4>
                                                                    <div className="row">
                                                                        <div className="col-xs-7 col-sm-7 col-lg-7 col-md-7">
                                                                            <DropDownListComponent id={`esitoUnipol${idSin}`} fields={this.ddlFields} dataSource={this.dsEsitoPerizia} change={this.onChangeEsitoPeriziaUnipol.bind(this)}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} 
                                                                                placeholder="Esito Perizia" floatLabelType='Auto' popupHeight="350px" cssClass='e-outline'>
                                                                            </DropDownListComponent>
                                                                        </div>
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                            <DatePickerComponent firstDayOfWeek={1} id={`dataFirmaAttoUnipol${idSin}`} cssClass='e-outline' format='dd/MM/yyyy' blur={this.onChangeDataFirmaAttoUnipol.bind(this)}
                                                                                showClearButton={true} placeholder='Data Firma Atto' floatLabelType='Always'></DatePickerComponent>
                                                                        </div>                                                                        
                                                                    </div>
                                                                    <div className="row">                                                                    
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                            <DateTimePickerComponent firstDayOfWeek={1} id={`dataOraVerificataSinistroUnipol${idSin}`} cssClass='e-outline' format='dd/MM/yyyy HH:mm' change={this.onChangeDataVerifSinistroUnipol.bind(this)}
                                                                                showClearButton={true} placeholder='Data Verificata Sinistro' maskPlaceholder={'day, month, year, hour, minute'} floatLabelType='Always'></DateTimePickerComponent>
                                                                        </div>
                                                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                            <CheckBoxComponent id={`coerenzaDataSinistroUnipol${idSin}`} label='Coerenza Data Sinistro' labelPosition='Before' change={this.onChangeCoerenzaDataSinistroUnipol.bind(this)}></CheckBoxComponent>
                                                                        </div>                                                                        
                                                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                            <CheckBoxComponent id={`riparazioneEffettuataUnipol${idSin}`} label='Riparazione Effettuata' labelPosition='Before' change={this.onChangeRiparazioneEffettuataUnipol.bind(this)}></CheckBoxComponent>
                                                                        </div>
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                            <CheckBoxComponent id={`riparazioneTecnicheDistruttiveUnipol${idSin}`} label='Riparazione Con Tecniche Distruttive' labelPosition='Before' change={this.onChangeRiparazioneTecnDistruttUnipol.bind(this)}></CheckBoxComponent>
                                                                        </div>
                                                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                            <CheckBoxComponent id={`tipoSinistroCorrettoUnipol${idSin}`} label='Tipo Sinistro Corretto' labelPosition='Before' change={this.onChangeTipoSinistroCorrettoUnipol.bind(this)}></CheckBoxComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                            <NumericTextBoxComponent id={`importoImponibileRiparazioneUnipol${idSin}`} floatLabelType='Auto' placeholder='Importo Imponibile Riparazione' blur={this.onChangeImportoImponibileRiparazioneUnipol.bind(this)} cssClass='e-outline' showSpinButton={false}></NumericTextBoxComponent>
                                                                        </div>
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                            <NumericTextBoxComponent id={`importoIVARiparazioneUnipol${idSin}`} floatLabelType='Auto' placeholder='Importo IVA Riparazione' blur={this.onChangeImportoIVARiparazioneUnipol.bind(this)} cssClass='e-outline' showSpinButton={false}></NumericTextBoxComponent>
                                                                        </div>
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                            <NumericTextBoxComponent id={`percIVARiparazioneUnipol${idSin}`} floatLabelType='Auto' placeholder='Percentuale IVA Riparazione' blur={this.onChangePercIVARiparazioneUnipol.bind(this)} min={0} max={100} cssClass='e-outline' showSpinButton={false}></NumericTextBoxComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                            <CheckBoxComponent id={`utilitaGFKUnipol${idSin}`} label='Utilità GFK' labelPosition='Before' change={this.onChangeUtilitaGFKUnipol.bind(this)}></CheckBoxComponent>
                                                                        </div> 
                                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                                                            <DropDownListComponent id={`tipoBeneGFKUnipol${idSin}`} fields={this.ddlFields} dataSource={this.dsTipoBeneGFK}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeTipoBeneGFKUnipol.bind(this)} 
                                                                                placeholder="Tipo Bene GFK" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                                                            </DropDownListComponent>
                                                                        </div>
                                                                        <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5">
                                                                            <DropDownListComponent id={`tipoBeneGFKNonPresenteUnipol${idSin}`} fields={this.ddlFields} dataSource={this.dsTipoBeneGFKNonPresente}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeTipoBeneGFKNonPresenteUnipol.bind(this)} 
                                                                                placeholder="Tipo Bene GFK Non Presente" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                                                            </DropDownListComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                            <CheckBoxComponent id={`richiestaQuotazioneGFKUnipol${idSin}`} label='Richiesta Quotazione GFK' labelPosition='Before' change={this.onChangeRichiestaQuotazioneGFKUnipol.bind(this)}></CheckBoxComponent>
                                                                        </div> 
                                                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                            <CheckBoxComponent id={`consultazioneLorentzUnipol${idSin}`} label='Consultazione Lorentz' labelPosition='Before' change={this.onChangeConsultazioneLorentzUnipol.bind(this)}></CheckBoxComponent>
                                                                        </div>
                                                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                            <CheckBoxComponent id={`utilitaLorentzUnipol${idSin}`} label='Utilità Lorentz' labelPosition='Before' change={this.onChangeUtilitaLorentzUnipol.bind(this)}></CheckBoxComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                                            <DropDownListComponent id={`tipoDefinizioneUnipol${idSin}`} fields={this.ddlFields} dataSource={this.dsTipoDefinizione}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeTipoDefinizioneUnipol.bind(this)} 
                                                                                placeholder="Tipo Definizione" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                                                            </DropDownListComponent>
                                                                        </div>
                                                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                            <NumericTextBoxComponent id={`importoFranchigiaUnipol${idSin}`} floatLabelType='Auto' placeholder='Importo Franchigia' blur={this.onChangeImportoFranchigiaUnipol.bind(this)} cssClass='e-outline' showSpinButton={false}></NumericTextBoxComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                                            <DropDownListComponent id={`mancataDefinizioneUnipol${idSin}`} fields={this.ddlFields} dataSource={this.dsMotivoMancataDefinizione}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeMancataDefinizioneUnipol.bind(this)} 
                                                                                placeholder="Mancata Definizione" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                                                            </DropDownListComponent>
                                                                        </div>
                                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                                                                            <TextBoxComponent id={`noteMotivoMancataDefinizioneUnipol${idSin}`} floatLabelType='Auto' placeholder='Note Motivo Mancata Definizione' cssClass='e-outline' blur={this.onChangeNoteMotivoMancataDefinizioneUnipol.bind(this)}></TextBoxComponent>
                                                                        </div>                                                                        
                                                                    </div>
                                                                    <div className="row">                                                                        
                                                                        <div className="col-xs-7 col-sm-7 col-lg-7 col-md-7">
                                                                            <DropDownListComponent id={`motivazioneNegativaUnipol${idSin}`} fields={this.ddlFields} dataSource={this.dsMotivoNegativa}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeMotivazioneNegativaUnipol.bind(this)}
                                                                                placeholder="Motivazione Negativa Videoperizia" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                                                            </DropDownListComponent>
                                                                        </div>
                                                                        <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5">
                                                                            <TextBoxComponent id={`noteMotivoNegativaUnipol${idSin}`} floatLabelType='Auto' placeholder='Note Motivo Negativa' blur={this.onChangeNoteMotivoNegativaUnipol.bind(this)} cssClass='e-outline'></TextBoxComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-xs-7 col-sm-7 col-lg-7 col-md-7">
                                                                            <TextBoxComponent id={`noteGaranziaRamoCorrettoUnipol${idSin}`} floatLabelType='Auto' placeholder='Note Garanzia Ramo Corretto' blur={this.onChangeNoteGaranziaRamoCorrettoUnipol.bind(this)} cssClass='e-outline'></TextBoxComponent>
                                                                        </div>
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                            <CheckBoxComponent id={`dannoDaEventoNaturaleUnipol${idSin}`} label='Danno Da Evento Naturale' labelPosition='Before' change={this.onChangeDannoEventoNaturaleUnipol.bind(this)}></CheckBoxComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                            <CheckBoxComponent id={`presenzaContestazioniUnipol${idSin}`} label='Presenza Contestazioni' labelPosition='Before' change={this.onChangePresenzaContestazioniUnipol.bind(this)}></CheckBoxComponent>
                                                                        </div>
                                                                        <div className="col-xs-7 col-sm-7 col-lg-7 col-md-7">
                                                                            <TextBoxComponent id={`notePresenzaContestazioniUnipol${idSin}`} floatLabelType='Auto' placeholder='Note Presenza Contestazioni' blur={this.onChangeNotePresenzaContestazioniUnipol.bind(this)} cssClass='e-outline'></TextBoxComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                            <CheckBoxComponent id={`mantenimentoPolizzaUnipol${idSin}`} label='Mantenimento Polizza' labelPosition='Before' change={this.onChangeMantenimentoPolizzaUnipol.bind(this)}></CheckBoxComponent>
                                                                        </div>
                                                                        <div className="col-xs-7 col-sm-7 col-lg-7 col-md-7">
                                                                            <TextBoxComponent id={`noteMantenimentoPolizzaUnipol${idSin}`} floatLabelType='Auto' placeholder='Note Mantenimento Polizza' blur={this.onChangeNoteMantenimentoPolizzaUnipol.bind(this)} cssClass='e-outline'></TextBoxComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                            <CheckBoxComponent id={`adempimentoPolizzaUnipol${idSin}`} label='Adempimento Polizza' labelPosition='Before' checked={true} change={this.onChangeAdempimentoPolizzaUnipol.bind(this)}></CheckBoxComponent>
                                                                        </div>
                                                                        <div className="col-xs-7 col-sm-7 col-lg-7 col-md-7">
                                                                            <TextBoxComponent id={`noteAdempimentoPolizzaUnipol${idSin}`} floatLabelType='Auto' placeholder='Note Adempimento Polizza' blur={this.onChangeNoteAdempimentoPolizzaUnipol.bind(this)} cssClass='e-outline'></TextBoxComponent>
                                                                        </div>
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                            <ButtonComponent id={`caricaFotoGEOUnipolSAI${idSin}`} cssClass='e-fill e-info' onClick={this.caricaFotoGEOUnipolSAI.bind(this)}>Carica Foto Geolocalizzate</ButtonComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-xs-7 col-sm-7 col-lg-7 col-md-7">
                                                                            <TextBoxComponent id={`noteArticoloPolizzaImpattatoUnipol${idSin}`} floatLabelType='Auto' placeholder='Note Articolo Polizza Impattato' blur={this.onChangeNoteArticoloPolizzaImpattatoUnipol.bind(this)} cssClass='e-outline'></TextBoxComponent>
                                                                        </div> 
                                                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                            <NumericTextBoxComponent id={`importoConcordatoUnipol${idSin}`} floatLabelType='Auto' placeholder='Importo Concordato' blur={this.onChangeImportoConcordatoUnipol.bind(this)} cssClass='e-outline' showSpinButton={false}></NumericTextBoxComponent>
                                                                        </div>
                                                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                            <NumericTextBoxComponent id={`importoLiquidabileUnipol${idSin}`} floatLabelType='Auto' placeholder='Importo Liquidabile' blur={this.onChangeImportoLiquidabileUnipol.bind(this)} cssClass='e-outline' showSpinButton={false}></NumericTextBoxComponent>
                                                                        </div>
                                                                    </div>
                                                                    <hr className="rounded"></hr>
                                                                    <div id={`divDefinizioneDannoUnipolSAI${idSin}`}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="control-section card-control-section basic_card_layout">
                                                <div className="e-card-resize-container">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                                <div className="e-card-content" style={{ backgroundColor:'#E0FFFF'}}> 
                                                                <div className='row'>   
                                                                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" id='campiSfondoBianco'>    
                                                                        <DropDownListComponent id={assegnaCat} fields={this.ddlFields} dataSource={this.dsCATFiltro}  readonly={true}
                                                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                                                            placeholder="Assegna CAT" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                        </DropDownListComponent>    
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={appVerif} floatLabelType='Auto' placeholder='Apparecchi da Verificare' cssClass='e-outline' value={objDettaglio.apparecchi_verificare} blur={this.onChangeAppVerificareDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">    
                                                                        <CheckBoxComponent id={preesFabbCat} label='Preesistenza Fabbricato' change={this.onChangePreesistFabbricatoCAT.bind(this)} disabled={true}></CheckBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">    
                                                                        <CheckBoxComponent id={preesMaccCat} label='Preesistenza Macchinario' change={this.onChangePreesistMacchinarioCAT.bind(this)} disabled={true}></CheckBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">    
                                                                        <CheckBoxComponent id={preesContCat} label='Preesistenza Contenuto' change={this.onChangePreesistContenutoCAT.bind(this)} disabled={true}></CheckBoxComponent>
                                                                    </div>  
                                                                </div>
                                                                <div className='row'>   
                                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                                                        <RichTextEditorComponent id={noteCat} showCharCount={true} placeholder='Note CAT' enableHtmlEncode={false} readonly={true}
                                                                            maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} value={objDettaglio.note_cat} width={Browser.isDevice ? '94%' : '98%'} height='200px'>
                                                                        <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table]}/>
                                                                        </RichTextEditorComponent>
                                                                    </div>  
                                                                </div>
                                                                <div className='row mt-3'>   
                                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center">    
                                                                        <ButtonComponent id={btnIncarico} title='Incarica il CAT' cssClass='e-info' style={{ display: 'none' }} onClick={this.btnIncaricaCat.bind(this)}>Incarica</ButtonComponent>
                                                                    </div>  
                                                                </div>
                                                                <div className='row'>   
                                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                                                        <h5 className="card-title">CAT Assegnati</h5>   
                                                                    </div>  
                                                                </div>
                                                                <div className='row'>   
                                                                    <div id={gridCatAssegnatiParent} className="col-xs-12 col-sm-12 col-lg-12 col-md-12" >    
                                                                        <GridComponent id={gridCatAssegnati} allowSelection={true} enableHover={true} allowTextWrap={true}
                                                                            allowPaging={true} allowSorting={false} allowFiltering={false} enableAltRow={true} actionComplete={this.actionCompleteCAT.bind(this)}
                                                                            pageSettings={this.pageSettingsCat} filterSettings={this.filterSettingsCat}  commandClick={this.onCommandClickCat.bind(this)} rowDataBound={this.onRowDataboundCat.bind(this)}
                                                                            toolbar={this.toolbarOptionsCat} editSettings={this.editSettingsCat} showColumnChooser={true}>
                                                                            <ColumnsDirective>
                                                                                <ColumnDirective field='ragione_sociale' headerText="Ragione sociale"  width='20%' validationRules={{ required: true }} isPrimaryKey='true' readonly={true} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='telefono1' headerText="Telefono"  width='20%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='email1' headerText="Email" visible={false} width='18%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='t_assegnato' headerText="Assegnato" width='20%' format='dd/MM/yyyy' edit={this.editTemplate1} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='t_sopralluogo' headerText="Sopralluogo" width='21%' format='dd/MM/yyyy HH:mm' allowEditing={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='t_riconsegnato' headerText="Riconsegnato" width='21%' format='dd/MM/yyyy' edit={this.editTemplate2} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='telefono2' headerText="Telefono2" visible={false} width='20%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='email2' headerText="Email2" visible={false} width='15%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='note_cat' headerText="Note"  width='35%'  textAlign='Center' headerTemplate={headerTemplate} disableHtmlEncode={false} edit={this.editTemplate3} template={fieldTemplate2}></ColumnDirective>
                                                                                <ColumnDirective field='chiusa' headerText="Chiusa"  width='15%' textAlign='Center' headerTemplate={headerTemplate} displayAsCheckBox={true} editType='booleanedit' allowEditing={false}></ColumnDirective>
                                                                                <ColumnDirective field='revocata' headerText="Revocata"  width='20%' textAlign='Center' headerTemplate={headerTemplate} displayAsCheckBox={true} editType='booleanedit' allowEditing={false}></ColumnDirective>
                                                                                <ColumnDirective field='Comandi' headerText="" width='10%' commands={this.commandsCat} textAlign='Right'></ColumnDirective>
                                                                            </ColumnsDirective>
                                                                            <Inject services={[Toolbar, ColumnChooser, Filter, CommandColumn, Sort, Edit, DetailRow, Page]} />
                                                                        </GridComponent>  
                                                                    </div>  
                                                                </div>
                                                              </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="control-section card-control-section basic_card_layout">
                                                <div className="e-card-resize-container">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                                <div className="e-card-content" style={{ backgroundColor:'#FFEBCD'}}> 
                                                                <div className='row'>   
                                                                    <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5" id='campiSfondoBianco'>    
                                                                        <DropDownListComponent id={ripristFormDir} fields={this.ddlFields} dataSource={this.dsRipristFormaDir} readonly={true} value={objDettaglio.ripristino}
                                                                            allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeRipristinoFormaDirettaDettaglio.bind(this)}
                                                                            placeholder="Ripristino in forma diretta" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                        </DropDownListComponent> 
                                                                    </div>  
                                                                    <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5" id='campiSfondoBianco'>    
                                                                        <DropDownListComponent id={motivazNo} fields={this.ddlFields} dataSource={this.dsMotivazNO}  readonly={true} value={objDettaglio.IDMotivoNoRipristino?.toString()}
                                                                            allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeMotivazioneNoDettaglio.bind(this)}
                                                                            placeholder="Motivazione NO" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                        </DropDownListComponent>  
                                                                    </div>   
                                                                </div>
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="control-section card-control-section basic_card_layout">
                                                <div className="e-card-resize-container">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                                <div className="e-card-content" style={{backgroundColor:'#FFEBCD'}}> 
                                                                <div className='row'>   
                                                                <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5" id='campiSfondoBianco'>    
                                                                        <DropDownListComponent id={assegnaRfd} fields={this.ddlFields} dataSource={this.dsAssegnaRFD}  readonly={true}
                                                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                                                            placeholder="Assegna RFD" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                        </DropDownListComponent>  
                                                                    </div>   
                                                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">    
                                                                        <ButtonComponent id={btnEsporta} title='Esporta doc RFD UnipolSai' cssClass='e-info' style={{ display: 'none' }} onClick={this.btnEsportaRFDUnipolSai.bind(this)}>Esporta doc RFD UnipolSai</ButtonComponent>
                                                                    </div>  
                                                                </div>
                                                                <div className='row'>   
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">    
                                                                        <CheckBoxComponent id={preesFabbRfd} label='Preesistenza Fabbricato' change={this.onChangePreesistFabbricatoRFD.bind(this)} disabled={true}></CheckBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">    
                                                                        <CheckBoxComponent id={preesMaccRfd} label='Preesistenza Macchinario' change={this.onChangePreesistMacchinarioRFD.bind(this)} disabled={true}></CheckBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">    
                                                                        <CheckBoxComponent id={preesContRfd} label='Preesistenza Contenuto' change={this.onChangePreesistContenutoRFD.bind(this)} disabled={true}></CheckBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={appDaRip} floatLabelType='Auto' placeholder='Apparecchi da Riparare' cssClass='e-outline' value={objDettaglio.apparecchi_verificare_RFD} blur={this.onChangeAppDaRipDettaglio.bind(this)} readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                </div>
                                                                <div className='row'>   
                                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                                                    <RichTextEditorComponent id={noteRfd} showCharCount={true} enableHtmlEncode={false} placeholder='Note RFD' readonly={true}
                                                                        maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px'>
                                                                    <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table]}/>
                                                                    </RichTextEditorComponent>
                                                                    </div>  
                                                                </div>
                                                                <div className='row mt-3'>   
                                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center">    
                                                                    <ButtonComponent id={btnIncaricoRfd} title='Incarica il RFD' cssClass='e-info' style={{ display: 'none' }} onClick={this.btnIncaricaRfd.bind(this)}>Incarica</ButtonComponent>
                                                                    </div>  
                                                                </div>
                                                                <div className='row'>   
                                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                                                        <h5 className="card-title">RFD Assegnati</h5>   
                                                                    </div>  
                                                                </div>
                                                                <div className='row'>   
                                                                    <div id={gridRFDAssegnatiParent} className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                                                        <GridComponent id={gridRFDAssegnati} allowSelection={true} enableHover={true} allowTextWrap={true}
                                                                            allowPaging={true} allowSorting={false} allowFiltering={false} enableAltRow={true}  commandClick={this.onCommandClickRfd.bind(this)}
                                                                            pageSettings={this.pageSettingsCat} filterSettings={this.filterSettingsCat} actionComplete={this.actionCompleteRFD.bind(this)} rowDataBound={this.onRowDataboundRfd.bind(this)}
                                                                            toolbar={this.toolbarOptionsCat} editSettings={this.editSettingsCat} showColumnChooser={true}>
                                                                            <ColumnsDirective>
                                                                                <ColumnDirective field='id_rfd' headerText="Ragione sociale"  width='25%' validationRules={{ required: true }} isPrimaryKey='true' readonly={true} visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='ragione_sociale' headerText="Ragione sociale"  width='25%' validationRules={{ required: true }}  readonly={true} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='t_assegnato' headerText="Assegnato" width='20%' format='dd/MM/yyyy' edit={this.editTemplate1} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='t_riconsegnato' headerText="Riconsegnato"  width='20%' format='dd/MM/yyyy' edit={this.editTemplate2} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='telefono1' headerText="Telefono1" visible={false} width='20%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='telefono2' headerText="Telefono2" visible={false} width='20%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='email1' headerText="Email1" visible={false} width='15%'  textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='email2' headerText="Email2" visible={false} width='15%'  textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                <ColumnDirective field='note_rfd' headerText="Note"  width='35%'  textAlign='Center' headerTemplate={headerTemplate} disableHtmlEncode={false} edit={this.editTemplate4} template={fieldTemplate3}></ColumnDirective>
                                                                                <ColumnDirective field='chiusa' headerText="Chiusa"  width='15%' textAlign='Center' headerTemplate={headerTemplate} displayAsCheckBox={true} editType='booleanedit' allowEditing={false}></ColumnDirective>
                                                                                <ColumnDirective field='revocata' headerText="Revocata"  width='20%' textAlign='Center' headerTemplate={headerTemplate} displayAsCheckBox={true} editType='booleanedit' allowEditing={false}></ColumnDirective>
                                                                                <ColumnDirective field='Comandi' headerText="" width='10%' commands={this.commandsRfd} textAlign='Right'></ColumnDirective>
                                                                            </ColumnsDirective>
                                                                            <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, DetailRow, Page]} />
                                                                        </GridComponent>  
                                                                    </div>  
                                                                </div>
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="control-section card-control-section basic_card_layout">
                                                <div className="e-card-resize-container">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                                <div className="e-card-content" style={{ backgroundColor:'#98FB98'}}> 
                                                                    <div className='row'>   
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                            <DropDownListComponent id={assegnaPerito} fields={this.ddlFields} dataSource={this.dsPeritoFiltro.filter(elm => { return elm.VALUE !== '99999'})}  readonly={true}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                                                                placeholder="Assegna un perito" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                            </DropDownListComponent> 
                                                                        </div> 
                                                                        <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                            <ButtonComponent id={btnPerito} title='Modifica' cssClass='e-details' iconCss='fas fa-check fa-10x' style={{ display: 'none' }} onClick={this.btnIncaricaPerito.bind(this)}></ButtonComponent>
                                                                        </div> 
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                            <DropDownListComponent id={assegnaCQ} fields={this.ddlFields} dataSource={this.dsCQFiltro} value={objDettaglio.IDCQ?.toString()} readonly={true}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeAssegnaCQDettaglio.bind(this)}
                                                                                placeholder="Assegna un CQ" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                            </DropDownListComponent>  
                                                                        </div> 
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                            <DropDownListComponent id={assegnaCR} fields={this.ddlFields} dataSource={this.dsCRFiltro} value={objDettaglio.IDCR?.toString()} readonly={true}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeAssegnaCRDettaglio.bind(this)}
                                                                                placeholder="Assegna un CR" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                            </DropDownListComponent>  
                                                                        </div>    
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-xs-3 offset-xs-4 col-sm-3 offset-sm-4 col-lg-3 offset-lg-4 col-md-3 offset-md-4" id='campiSfondoBianco'>    
                                                                            <DatePickerComponent id={dataAssegnCQ} cssClass='e-outline' format='dd/MM/yyyy' blur={this.onChangeDataAssegnCQDettaglio.bind(this)} value={objDettaglio.dataAssegnCQ} readOnly={true}
                                                                                showClearButton={false} readonly={true} placeholder='Data Assegnazione CQ' floatLabelType='Always'></DatePickerComponent>
                                                                        </div>  
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                            <DatePickerComponent id={dataAssegnCR} cssClass='e-outline' format='dd/MM/yyyy' blur={this.onChangeDataAssegnCRDettaglio.bind(this)} value={objDettaglio.dataAssegnCR} readOnly={true}
                                                                                showClearButton={false} placeholder='Data Assegnazione CR' floatLabelType='Always'></DatePickerComponent>
                                                                        </div>  
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-xs-3 offset-xs-4 col-sm-3 offset-sm-4 col-lg-3 offset-lg-4 col-md-3 offset-md-4" id='campiSfondoBianco'>    
                                                                            <DatePickerComponent id={dataContrCQ} cssClass='e-outline' format='dd/MM/yyyy' blur={this.onChangeDataContrCQDettaglio.bind(this)} value={objDettaglio.dataCQ} readOnly={true}
                                                                                showClearButton={false} readonly={true} placeholder='Data Controllo CQ' floatLabelType='Always'></DatePickerComponent>
                                                                        </div>  
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id='campiSfondoBianco'>    
                                                                            <DatePickerComponent id={dataContrCR} cssClass='e-outline' format='dd/MM/yyyy' blur={this.onChangeDataContrCRDettaglio.bind(this)} value={objDettaglio.dataCR} readOnly={true}
                                                                                showClearButton={false} placeholder='Data Controllo CR' floatLabelType='Always'></DatePickerComponent>
                                                                        </div>  
                                                                    </div>
                                                                    <div className='row'>   
                                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                                                            <h5 className="card-title">Perizie</h5>   
                                                                        </div>  
                                                                    </div>
                                                                    <div className='row'>   
                                                                        <div id={gridPeritiAssegnatiParent} className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                                                            <GridComponent id={gridPeritiAssegnati} allowSelection={true} enableHover={true} allowTextWrap={true}
                                                                                allowPaging={true} allowSorting={false} allowFiltering={false} enableAltRow={true} actionComplete={this.actionCompletePeriti.bind(this)}
                                                                                pageSettings={this.pageSettingsPeriti} filterSettings={this.filterSettingsPeriti} commandClick={this.onCommandClickPeriti.bind(this)} rowDataBound={this.onRowDataboundPeriti.bind(this)}
                                                                                toolbar={this.toolbarOptionsPeriti} editSettings={this.editSettingsPeriti} >
                                                                                <ColumnsDirective>
                                                                                    <ColumnDirective field='id_perizia' headerText='ID perizia' isPrimaryKey='true'  visible={false} width='25%' textAlign='Center'headerTemplate={headerTemplate} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                                                    <ColumnDirective field='id_utente' headerText='ID Utente'  visible={false} width='25%' textAlign='Center'headerTemplate={headerTemplate} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                                                    <ColumnDirective field='nomeperito' headerText="Perito"  width='25%' validationRules={{ required: true }} isPrimaryKey='true' readonly={true} textAlign='Center'headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='t_assegnato' headerText="Assegnato" width='20%' format='dd/MM/yyyy' edit={this.editTemplate5} textAlign='Center'headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='t_riconsegnato' headerText="Riconsegnato"  width='20%' format='dd/MM/yyyy' edit={this.editTemplate6} textAlign='Center'headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='chiusa' headerText="Chiusa"  width='15%' textAlign='Center'headerTemplate={headerTemplate} displayAsCheckBox={true} editType='booleanedit' allowEditing={false}></ColumnDirective>
                                                                                    <ColumnDirective field='revocata' headerText="Revocata"  width='15%' textAlign='Center'headerTemplate={headerTemplate} displayAsCheckBox={true} editType='booleanedit' allowEditing={false}></ColumnDirective>
                                                                                    <ColumnDirective field='Comandi' headerText="" width='20%' commands={this.commandsPeriti} textAlign='Right'></ColumnDirective>
                                                                                </ColumnsDirective>
                                                                                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, DetailRow, Page]} />
                                                                            </GridComponent>  
                                                                        </div>  
                                                                    </div>                                                                   
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className='row'>   
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                            <ButtonComponent id={btnInizioPag1} cssClass='e-info' created={this.onCreatedBtn2}>Vai ad inizio pagina</ButtonComponent>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div className="control-section card-control-section basic_card_layout" style={{width: '40%'}}>
                        <div className="e-card-resize-container">
                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                    <div tabIndex="0" className="e-card" id="main_card">                                                
                                        <div className="e-card-content"> 
                                            <div className="control-section card-control-section basic_card_layout">
                                                <div className="e-card-resize-container">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                                <div className="e-card-content" style={{ backgroundColor:'#E0FFFF'}}> 
                                                                <div className='row'>   
                                                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" id='sfondoRosaTextArea'>    
                                                                        <TextBoxComponent id={istruzioniChiusura} floatLabelType='Auto' placeholder='Istruzioni chiusura' ref = {txtObj => {this.textBoxInstance4 = txtObj}} multiline={true} cssClass='e-outline' readOnly={true} value={objDettaglio.istruzioni_chiusura} blur={this.onChangeIstruzioniChiusura.bind(this)}></TextBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={interlocPres} floatLabelType='Auto' placeholder='' ref = {txtObj => {this.textBoxInstance1 = txtObj}} multiline={true} cssClass='e-outline' readOnly={true}></TextBoxComponent>
                                                                    </div>  
                                                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" >
                                                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                                                            <div id='campiSfondoBianco' style={{marginBottom: '10px'}}>    
                                                                                <ButtonComponent id={btnEsportaInterlocutoria} cssClass='e-info' onClick={this.btnEsportaInterlocutoria.bind(this)} style={{ display: 'none', width: '200px', fontSize: '14px' }}>Esporta Interlocutoria</ButtonComponent>
                                                                            </div> 
                                                                            <div id='campiSfondoBianco'>    
                                                                                <ButtonComponent id={btnEsportaPrimoCont} cssClass='e-info' style={{ display: 'none', width: '200px', fontSize: '14px' }} onClick={this.btnEsportaModuloPrimocontatto.bind(this)}>Esporta Primo Contatto</ButtonComponent>
                                                                            </div> 
                                                                        </div>
                                                                        <div style={{display: 'flex', marginTop: '15px'}}>
                                                                            <div>
                                                                                <CheckBoxComponent id={checkRiservaComunicata} checked={objDettaglio.check_riserva_comunicata} change={this.onChangecheckRiservaComunicata.bind(this)} disabled={true}></CheckBoxComponent>
                                                                            </div>
                                                                            <div style={{marginLeft: '5px'}}>
                                                                                <NumericTextBoxComponent id={riservaComunicata} floatLabelType='Auto' placeholder='Riserva Da Comunicare' cssClass='e-outline' readOnly={true} style={{marginLeft: '12px', width: '165px', backgroundColor: '#FFB6C1'}} showSpinButton={false} value={objDettaglio.riserva_comunicata} ></NumericTextBoxComponent>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>   
                                                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" id='campiSfondoBianco'>    
                                                                        <DropDownListComponent id={ddlSoll} fields={this.ddlFields} dataSource={this.dsSollecitoDettaglio} readonly={true} 
                                                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                                                            placeholder="Sollecitato da" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                        </DropDownListComponent>  
                                                                    </div>  
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                        <ButtonComponent id={btnSoll} title='Modifica' cssClass='e-details' iconCss='fas fa-exclamation-triangle fa-10x' onClick={this.btnSollecito.bind(this)} style={{ display: 'none' }}></ButtonComponent>
                                                                    </div>
                                                                    <div className="col-xs-7 col-sm-7 col-lg-7 col-md-7" id='campiSfondoBianco'>    
                                                                        <TextBoxComponent id={noteSoll} floatLabelType='Auto' placeholder='Note sollecito' cssClass='e-outline' readOnly={true}></TextBoxComponent>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>    
                                                                    <div id={parcellaCATParent} className="col-xs-3 col-sm-3 col-lg-3 col-md-3">    
                                                                        <NumericTextBoxComponent id={parcellaCAT} floatLabelType='Auto' format='N2' decimals='2' placeholder='Parcella CAT' cssClass='e-outline' value={objDettaglio.parcellaCAT} blur={this.onChangeParcellaCATDettaglio.bind(this)} readOnly={true} showSpinButton={false} style={{ backgroundColor:'#ADD8E6' }}></NumericTextBoxComponent>
                                                                    </div> 
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                        <ButtonComponent id={btnParcellaCAT} iconCss='fas fa-edit fa-10x' style={{ display: 'none' }}></ButtonComponent>
                                                                    </div>  
                                                                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">    
                                                                        <NumericTextBoxComponent id={parcellaPerito} floatLabelType='Auto' format='N2' decimals='2' placeholder='Parcella Perito' cssClass='e-outline' value={objDettaglio.parcellaPerito} blur={this.onChangeParcellaPeritoDettaglio.bind(this)} readOnly={true} showSpinButton={false} style={{backgroundColor:'#98FB98'}}></NumericTextBoxComponent>
                                                                    </div> 
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                        <ButtonComponent id={btnParcellaPerito} iconCss='fas fa-edit fa-10x' style={{ display: 'none' }}></ButtonComponent>
                                                                    </div>  
                                                                    <div id={parcellaParent} className="col-xs-3 col-sm-3 col-lg-3 col-md-3">    
                                                                        <NumericTextBoxComponent id={parcella} floatLabelType='Auto' format='N2' decimals='2' placeholder='Parcella' cssClass='e-outline' value={objDettaglio.parcella} blur={this.onChangeParcellaDettaglio.bind(this)} readOnly={true} showSpinButton={false} style={{ backgroundColor:'yellow' }}></NumericTextBoxComponent>
                                                                    </div> 
                                                                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">    
                                                                        <ButtonComponent id={btnParcella} iconCss='fas fa-edit fa-10x' style={{ display: 'none' }}></ButtonComponent>
                                                                    </div> 
                                                                </div>
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>   
                                                <div className="control-section card-control-section basic_card_layout">
                                                    <div className="e-card-resize-container">
                                                        <div className="row card-layout">
                                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                                                    <div className="e-card-content" style={{ backgroundColor:'lightyellow'}}>  
                                                                        <div className='row'>   
                                                                            <div className="col-xs-7 col-sm-7 col-lg-7 col-md-7" id='campiSfondoBianco'>    
                                                                                <DropDownListComponent id={ddlCambiaStato} fields={this.ddlFields} dataSource={this.dsStatoDettaglio} enabled={false}
                                                                                    allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeCambioStato.bind(this)}
                                                                                    placeholder="Cambio Stato" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                                </DropDownListComponent>  
                                                                            </div>  
                                                                            <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">
                                                                                <ButtonComponent id={btnCambiaStato} cssClass='e-details' iconCss='fas fa-exclamation fa-10x' onClick={this.btnCambiaStato.bind(this)} style={{ display: 'none' }}></ButtonComponent>
                                                                            </div>
                                                                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" id='campiSfondoBianco'>
                                                                                <DatePickerComponent id={dataAlert} cssClass='e-outline' format='dd/MM/yyyy' enabled={false} readOnly={true}
                                                                                    allowEdit={false} showClearButton={false} placeholder='Data Alert' floatLabelType='Always'></DatePickerComponent>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>  
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            <div className="control-section card-control-section basic_card_layout">
                                                <div className="e-card-resize-container">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                                <div className="e-card-content" style={{ backgroundColor:'#98FB98'}}>  
                                                                    <div className='row'>   
                                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" id='campiSfondoBianco'>    
                                                                            <DropDownListComponent id={ddlAzione} fields={this.ddlFields} dataSource={this.dsAzioneDettaglio}  readonly={true}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeAzioneDettaglio.bind(this)}
                                                                                placeholder="Azione" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                                            </DropDownListComponent>  
                                                                        </div>  
                                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" id='campiSfondoBianco'>    
                                                                            <DropDownListComponent id={ddlAchi} fields={this.ddlFields} dataSource={this.dsAChiDettaglio}  readonly={true}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeAChiDettaglio.bind(this)}
                                                                                placeholder="A chi" floatLabelType='Auto' popupHeight="4000px" cssClass='e-outline'> 
                                                                            </DropDownListComponent>  
                                                                        </div> 
                                                                    </div>  
                                                                    <div className='row'>
                                                                        <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10" id='campiSfondoBianco'>    
                                                                            <DropDownListComponent id={ddlCosa} fields={this.ddlFields}  readonly={true}
                                                                                allowFiltering={true} filterType='Contains' showClearButton={true} dataSource={this.dsAzioneCosaDettaglio} 
                                                                                placeholder="Cosa" floatLabelType='Auto' popupHeight="4000px" cssClass='e-outline'> 
                                                                            </DropDownListComponent>  
                                                                        </div>  
                                                                        <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">
                                                                            <ButtonComponent id={btnAzione} cssClass='e-details' iconCss='fas fa-exclamation fa-10x' onClick={this.btnAzione.bind(this)} style={{ display: 'none' }}></ButtonComponent>
                                                                       </div>
                                                                    </div>                   
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="control-section card-control-section basic_card_layout">  
                                                <div className='row'>  
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center">    
                                                        <ButtonComponent id={btnApriMaschera} cssClass='e-info' style={{ display: 'none' }} onClick={this.btnApriMaschera.bind(this)}>INSERISCI NOTA</ButtonComponent>
                                                    </div> 
                                                </div>  
                                                <div id={divNotaManuale} style={{display: 'none'}}>
                                                    <div className='row'>
                                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-start">    
                                                            <CheckBoxComponent id={checkInterl} label='Interlocutoria' checked={false}></CheckBoxComponent>
                                                        </div> 
                                                    </div>                                  
                                                    <div className='row'>  
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" >    
                                                            <TextBoxComponent id={mascheraInterl} floatLabelType='Auto' placeholder='Testo Nota' ref = {txtObj => {this.textBoxInstance3 = txtObj}} multiline={true} cssClass='e-outline' ></TextBoxComponent>
                                                        </div>  
                                                    </div>
                                                    <div className='row'>  
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 text-end">     
                                                            <ButtonComponent id={btnCancInterl} title='Cancella' cssClass='e-details' iconCss='fas fa-trash fa-10x' onClick={this.btnCancellaNotaInterl.bind(this)}></ButtonComponent>
                                                        </div> 
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 text-start">    
                                                            <ButtonComponent id={btnInvioInterl} title='Invia' cssClass='e-details' iconCss='fas fa-check fa-10x' onClick={this.btnInviaInterl.bind(this)}></ButtonComponent>
                                                        </div> 
                                                    </div>  
                                                </div>
                                                <div className='row'>
                                                    <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">    
                                                        <ButtonComponent id={btnCancellaNota} cssClass='e-danger' style={{ display: 'none' }} onClick={this.btnCancellaNota.bind(this)}>CANCELLA ULTIMA NOTA</ButtonComponent>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6 text-end">    
                                                        <ButtonComponent id={btnAggiornaNota} cssClass='e-info' style={{ display: 'none' }} onClick={this.btnAggiornaNota.bind(this)}>RICARICA NOTE</ButtonComponent>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="control-section card-control-section basic_card_layout">
                                                <div className="e-card-resize-container">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                                <div className="e-card-content" style={{ backgroundColor:'lightgreen'}}>  
                                                                    <div className='row'>   
                                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                                                            <RichTextEditorComponent id={NoteFin} enableHtmlEncode={true} toolbarSettings={this.RTEtoolbarSettings2} 
                                                                                readonly={true} width={Browser.isDevice ? '94%' : '98%'} height='auto'>
                                                                            <RTEInject services={[Image, Link, HtmlEditor, Count, QuickToolbar, Table]}/>
                                                                            </RichTextEditorComponent>
                                                                        </div>  
                                                                    </div>                     
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                                <div className='row'>   
                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                        <ButtonComponent id={btnInizioPag2} cssClass='e-info' created={this.onCreatedBtn2}>Vai ad inizio pagina</ButtonComponent>
                                    </div>  
                                </div>
                            </div>  
                        </div>  
                    </div>
                </div> 
                <MDBFooter id={footerDettaglio} bgColor='light' className='fixed-bottom ' style={{ marginLeft: '65px', zIndex: 10 }}>
                    <div className='row' style={{ backgroundColor: 'rgb(239, 239, 239)', borderTop: '1px solid #202351' }}>
                        <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1 text-start">
                            <ButtonComponent style={{ marginLeft: '20px', width: '80px' }} id={btnEsci2} alt='Esci' cssClass='e-fill e-danger' onClick={this.btnEsci.bind(this)} type='button'>ESCI</ButtonComponent>
                        </div>
                        <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5 text-center">
                            <h3 id={statoNonModificabile} style={{ color: 'red' }}></h3>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-end">
                            <ButtonComponent id={elaboratoPeritale} onClick={this.goToPeriziaOnline.bind(this, 'P1', objDettaglio)} cssClass='e-fill e-info' type='button' >Elaborato Peritale →</ButtonComponent>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-end">
                            <ButtonComponent id={elaboratoCalcoli} style={{ marginRight: '20px' }} onClick={this.goToPeriziaOnline.bind(this, 'P2', objDettaglio)} cssClass='e-fill e-info' type='button' >Elaborato Calcoli →→</ButtonComponent>
                        </div>
                    </div>
                </MDBFooter>
                <div style={{ marginTop: '75px' }}>
                    {/* divisore spazio vuoto per il footer */}
                </div>
        </React.Fragment>);
    }
    periziaOnlineElaboratoPeritaleRender(idSin, objDettaglio) {
        ++this.childKey;
        const tipoSinistro = '';  //SEMBRA NON SERVIRE E QUINDI SE CONFERMATO TOGLIERLO ANCHE DAI PARAMETRI
        return(
            <PaginaPeriziaOnline key={this.childKey} IdInternoSinistro={idSin} IdSinistro={objDettaglio.id_sinistro} IdIncarico={objDettaglio.IdIncaricoGEN} IdIncaricoUnipol={objDettaglio.IdIncaricoUNI} IdCompagnia={objDettaglio.id_compagnia} tipoSinistro={tipoSinistro} numeroPolizza={objDettaglio.numero_polizza}></PaginaPeriziaOnline>
        );        
    }
    periziaOnlineElaboratoCalcoliRender(idSin, objDettaglio) {
        ++this.childKey;
        const tipoSinistro = '';  //SEMBRA NON SERVIRE E QUINDI SE CONFERMATO TOGLIERLO ANCHE DAI PARAMETRI
        return(
            <PaginaPeriziaOnlineCalcoli key={this.childKey} IdInternoSinistro={idSin} IdSinistro={objDettaglio.id_sinistro} IdIncarico={objDettaglio.IdIncaricoGEN ?? ''} IdIncaricoUnipol={objDettaglio.IdIncaricoUNI ?? ''} IdCompagnia={objDettaglio.id_compagnia}  tipoSinistro={tipoSinistro} numeroPolizza={objDettaglio.numero_polizza ??''}></PaginaPeriziaOnlineCalcoli>  
        );
    }
    goToPeriziaOnline(pagina, objDettaglio) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;       
        if (pagina === 'P1') {
            document.getElementById(`tabDettaglio${idSin}`).ej2_instances[0].addTab([{ header: { text: 'Perizia Online - Elaborato Peritale'}, content: this.periziaOnlineElaboratoPeritaleRender.bind(this,idSin, objDettaglio) }], document.getElementById(`tabDettaglio${idSin}`).ej2_instances[0].items.length); 
            document.getElementById(`tabDettaglio${idSin}`).ej2_instances[0].select(document.getElementById(`tabDettaglio${idSin}`).ej2_instances[0].items.length -1);    
            document.getElementById(`elaboratoPeritale${idSin}`).ej2_instances[0].disabled = true;
            document.getElementById(`elaboratoCalcoli${idSin}`).ej2_instances[0].disabled = true;
            document.getElementById(`tabDettaglio${idSin}`).ej2_instances[0].enableTab(0, false);
        }
        else if (pagina === 'P2') {
            document.getElementById(`tabDettaglio${idSin}`).ej2_instances[0].addTab([{ header: { text: 'Perizia Online - Elaborato Calcoli'}, content: this.periziaOnlineElaboratoCalcoliRender.bind(this,idSin, objDettaglio) }], document.getElementById(`tabDettaglio${idSin}`).ej2_instances[0].items.length); 
            document.getElementById(`tabDettaglio${idSin}`).ej2_instances[0].select(document.getElementById(`tabDettaglio${idSin}`).ej2_instances[0].items.length -1);    
            document.getElementById(`elaboratoPeritale${idSin}`).ej2_instances[0].disabled = true;
            document.getElementById(`elaboratoCalcoli${idSin}`).ej2_instances[0].disabled = true;
            document.getElementById(`tabDettaglio${idSin}`).ej2_instances[0].enableTab(0, false);
        }
    }
    aggiornaDettaglio(nomeCampo, valoreCampo, tipoCampo, tabella, insertSeNonEsiste, valoreChiave, tabellaAlt, valoreChiave2) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        tabella = CustomJS.isNullEmpty(tabella) ? '[01001]' : tabella;
        if (tipoCampo === 'DATE' && (valoreCampo === 'Invalid date' || CustomJS.isNullEmpty(valoreCampo)))
            valoreCampo = null;
        const dataObj = {
            IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
            ValoreCampo: valoreCampo,
            TipoCampo: tipoCampo,
            IdLiquidatore: document.getElementById(`liquidatore${idSin}`).ej2_instances[0].value,
            IdBroker: document.getElementById(`broker${idSin}`).ej2_instances[0].value,
            IdCollaboratore: document.getElementById(`assegnaCat${idSin}`).ej2_instances[0].value,
            IdAmministratore: document.getElementById(`amministratore${idSin}`).ej2_instances[0].value,
            IdTipoDest: document.getElementById(`ddlAChi${idSin}`).ej2_instances[0].value,
            IdAgenzia: document.getElementById(`agenzia${idSin}`).ej2_instances[0].value,
            IdInfoRichiesta: valoreChiave ?? '',
            IdIncarico: document.getElementById(`idIncarico${idSin}`).value,
            IdLiquidazione: parseInt(valoreChiave ?? '0'),
            GuidDanneggiato: CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTechnologyStrutturaDanneggiati'))) ? null : JSON.parse(sessionStorage.getItem('JellyfishTechnologyStrutturaDanneggiati'))[0]?.guidDanneggiato,
            GuidDanneggiato2: valoreChiave2 ?? ''
        };
        let condizione = tabella === '[01001]' ? 'id_sinistro = @id_sinistro' : tabella === '[00009]' ? 'id_liquidatore = @id_liquidatore' : tabella === '[00008]' ? 'id_broker = @id_broker'
                         : tabella === '[00006]' ? 'id_collaboratore = @id_collaboratore' : tabella === '[00012]' ? 'id_amministratore = @id_amministratore' : tabella === '[00005]' ? 'id_sinistro = @id_sinistro'
                         : tabella === '[00004]' ? 'id_agenzia = @id_agenzia' : tabella === '[10003]' ? 'id_sinistro = @id_sinistro' : tabella === '[10004]' ? 'id_sinistro = @id_sinistro' : tabella === '[12009]' ? 'id_info_richiesta = @id_info_richiesta' 
                         : tabella === '[12003]' ? 'id_incarico = @idIncarico' : tabella === '[12004]' ? 'id_danneggiato = @guidDanneggiato' : tabella === '[12018]' ? 'id_incarico = @idIncarico' : tabella === '[12012]' ? 'id_liquidazione = @idLiquidazione' 
                         : tabella === '[10006]' ? 'id_sinistro = @id_sinistro' : '';
        condizione = !CustomJS.isNullEmpty(tabellaAlt) ? tabellaAlt === '[12012]' ? 'id_danneggiato = @guidDanneggiato2' : condizione : condizione;
        let campiInsertSeNonEsisteStr = tabella === '[00005]' ? [{ campo: 'id_sinistro', valore: '@id_sinistro' }, { campo: nomeCampo, valore: '@valore_campo' }] : tabella === '[12012]' ? [{ campo: 'id_danneggiato', valore: '@guidDanneggiato2' }, { campo: nomeCampo, valore: '@valore_campo' }]
                                        : tabella === '[12018]' ? [{ campo: 'id_incarico', valore: '@idIncarico' }, { campo: nomeCampo, valore: '@valore_campo' }] : tabella === '[10006]' ? [{ campo: 'id_sinistro', valore: '@id_sinistro' }, { campo: nomeCampo, valore: '@valore_campo' }] : [];
        let insertSeNonEsisteStr = (CustomJS.isNullEmpty(insertSeNonEsiste) || insertSeNonEsiste === false) ? '' : `;IF @@ROWCOUNT = 0 BEGIN INSERT INTO ${tabella} (${campiInsertSeNonEsisteStr.map(elm => elm.campo).join(',')}) VALUES (${campiInsertSeNonEsisteStr.map(elm => elm.valore).join(',')}) END`; 
        const QueryExec = `UPDATE ${tabella} SET ${nomeCampo} = @valore_campo WHERE ${condizione}${insertSeNonEsisteStr}`;        
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData2 => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, true, true);        
    }
    aggiornaDettaglioDaTabellaSinistri(nomeCampo, valoreCampo, tipoCampo, tabella, insertSeNonEsiste, idSinistro) {
        tabella = CustomJS.isNullEmpty(tabella) ? '[01001]' : tabella;
        if (tipoCampo === 'DATE' && (valoreCampo === 'Invalid date' || CustomJS.isNullEmpty(valoreCampo)))
            valoreCampo = null;
        const dataObj = {
            IdSinistro : idSinistro,
            ValoreCampo: valoreCampo,
            TipoCampo: tipoCampo,
        };
        let condizione = tabella === '[01001]' ? 'id_sinistro = @id_sinistro' : '';
        let campiInsertSeNonEsisteStr = [];
        let insertSeNonEsisteStr = (CustomJS.isNullEmpty(insertSeNonEsiste) || insertSeNonEsiste === false) ? '' : `;IF @@ROWCOUNT = 0 BEGIN INSERT INTO ${tabella} (${campiInsertSeNonEsisteStr.map(elm => elm.campo).join(',')}) VALUES (${campiInsertSeNonEsisteStr.map(elm => elm.valore).join(',')}) END`; 
        const QueryExec = `UPDATE ${tabella} SET ${nomeCampo} = @valore_campo WHERE ${condizione}${insertSeNonEsisteStr}`;        
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData2 => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, true, true);  
    }
    aggiornaCampoNote(IdSinistro) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec3 = `SELECT [01003].sistema, [01003].id_tipo_nota, [01003].data_nota, [01003].nota, [02004].descrizione, [01003].[user] AS utente, '1' as origine FROM ([01003] LEFT JOIN [02005] ON [01003].id_tipo_nota = [02005].id_tipo_nota) LEFT JOIN [02004] ON [01003].id_stato = [02004].id_tipo_stato  WHERE [01003].id_sinistro = ${IdSinistro}  Union all  SELECT [01010].sistema, [01010].id_tipo_nota, [01010].data_nota, [01010].nota, [02029].descrizione, [00006].ragione_sociale AS utente, '2' as origine FROM ([01010] LEFT JOIN [00006] ON [01010].id_utente = [00006].id_collaboratore) LEFT JOIN [02029] ON [01010].id_stato_cat = [02029].id_stato_cat WHERE [01010].id_sinistro = ${IdSinistro} order by data_nota desc`;        
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec3, '', retData3 => {
            this.noteFin = '';
            retData3.forEach(elm =>  { 
                if (elm.sistema === true)
                    this.noteFin += "****************INSERITA DA SISTEMA****************<BR>";  
                if (elm.id_tipo_nota === 2)
                    this.noteFin += "<font style='BACKGROUND-COLOR:#FFFF00'>INTERLOCUTORIA</font><BR>";
                else if (elm.origine === "2")
                    this.noteFin += "<font color=white style='BACKGROUND-COLOR:#1F508B'>----   CAT   ----</font><BR>"            
                this.noteFin += moment(elm.data_nota,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') + " - " + elm.utente + " <BR>";
                if (!CustomJS.isNullEmpty(elm.descrizione)) {
                    this.noteFin += "<font style='BACKGROUND-COLOR:#B5CB88'>" + elm.descrizione + "</font><BR>";
                    this.noteFin += "<BR>";
                }            
                if (!CustomJS.isNullEmpty(elm.nota))
                    this.noteFin += elm.nota.replace(/(?:\r\n|\r|\n)/g, '<br>') + "<BR>";   
                this.noteFin += "<BR>-------------------------------<BR><BR>";
            });
            document.getElementById(`noteFin${idSin}`).ej2_instances[0].value = this.noteFin;
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, true, true);

        this.aggiornaInterlocutorie(IdSinistro);
    }
    visualizzaNoteParzialiSinistro(IdSinistro, IdInternoSinistro, NumSinistroAgenzia) {
        this.toggleBlocking();
        const QueryExec = `SELECT [01003].sistema, [01003].id_tipo_nota, [01003].data_nota, [01003].nota, [02004].descrizione, [01003].[user] AS utente, '1' as origine FROM ([01003] LEFT JOIN [02005] ON [01003].id_tipo_nota = [02005].id_tipo_nota) LEFT JOIN [02004] ON [01003].id_stato = [02004].id_tipo_stato WHERE [01003].id_sinistro = ${IdSinistro} Union all SELECT [01010].sistema, [01010].id_tipo_nota, [01010].data_nota, [01010].nota, [02029].descrizione, [00006].ragione_sociale AS utente, '2' as origine FROM ([01010] LEFT JOIN [00006] ON [01010].id_utente = [00006].id_collaboratore) LEFT JOIN [02029] ON [01010].id_stato_cat = [02029].id_stato_cat WHERE [01010].id_sinistro = ${IdSinistro} order by data_nota desc`;        
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            this.noteFin = '';
            _.first(retData, [10]).forEach(elm =>  { 
                if (elm.sistema === true)
                    this.noteFin += "****************INSERITA DA SISTEMA****************<BR>";  
                if (elm.id_tipo_nota === 2)
                    this.noteFin += "<font style='BACKGROUND-COLOR:#FFFF00'>INTERLOCUTORIA</font><BR>";
                else if (elm.origine === "2")
                    this.noteFin += "<font color=white style='BACKGROUND-COLOR:#1F508B'>----   CAT   ----</font><BR>"            
                this.noteFin += moment(elm.data_nota,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') + " - " + elm.utente + " <BR>";
                if (!CustomJS.isNullEmpty(elm.descrizione)) {
                    this.noteFin += "<font style='BACKGROUND-COLOR:#B5CB88'>" + elm.descrizione + "</font><BR>";
                    this.noteFin += "<BR>";
                }            
                if (!CustomJS.isNullEmpty(elm.nota))
                    this.noteFin += elm.nota.replace(/(?:\r\n|\r|\n)/g, '<br>') + "<BR>";   
                this.noteFin += "<BR>-------------------------------<BR><BR>";
            });
            let container = document.getElementById('elencoNoteParzialiSinistro');
            let root = createRoot(container);   
            root.unmount();    
            setTimeout(() => {
                root = createRoot(container);   
                root.render(<CallbackWrapper callback={() => {
                    $(".sidebarNoteParzialiSinistro").toggleClass('active');
                    $('.chiudiNoteParzialiSinistro').off();
                    $('.chiudiNoteParzialiSinistro').on('click', function (e) {
                        $(".sidebarNoteParzialiSinistro").toggleClass('active');
                    });
                    //RIMOSSA CHIUSURA DEL PANNELLO DELLE NOTE AD OGNI CLICK SU QUALSIASI PARTE DELLO SCHERMO (RICHIESTA ACT)
                    // $('body,html').off('click', function(e){
                    //     $('.sidebarNoteParzialiSinistro').removeClass('active');
                    // });
                    // $('body,html').on('click', function(e){
                    //     $('.sidebarNoteParzialiSinistro').removeClass('active');
                    // });
                    document.getElementById('noteParzialiSinistro').ej2_instances[0].value = this.noteFin;
                    this.setState({ NumSinistro: IdInternoSinistro, NumSinistroAgenzia: NumSinistroAgenzia });
                    this.toggleBlocking();
                }}><RichTextEditorComponent id='noteParzialiSinistro' enableHtmlEncode={true} toolbarSettings={this.RTEtoolbarSettings2}
                    readonly={true} width='98%' height='auto'>
                <RTEInject services={[Image, Link, HtmlEditor, Count, QuickToolbar, Table]}/>
                </RichTextEditorComponent></CallbackWrapper>);   
            });                

        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, true, true);
    }
    emettiNotifiche(IdSinistro, IdInternoSinistro, vediNessunaNotifica, IdCompagnia, SoloSinUguali, NumSinCompagnia, DaInterno) {
        this.toggleBlocking();
        let QueryExec = SoloSinUguali === true ? `SELECT 2 AS TipoNotifica, CONCAT('Num. Sinistro: ', [01001].id_interno_sinistro, ' - ', 'Perito: ', [09001].cognome, ' ', [09001].nome,  ' - ', 'Stato: ', [02004].descrizione) AS risposta_evento  FROM [01001] LEFT OUTER JOIN [01002] ON [01001].id_sinistro = [01002].id_sinistro LEFT OUTER JOIN [09001] ON [01002].id_utente = [09001].id_utente LEFT OUTER JOIN [02004] ON [01001].id_stato = [02004].id_tipo_stato WHERE [01001].id_interno_sinistro <> ${IdInternoSinistro} AND ISNULL([01002].revocata,0) = 0 AND [01001].numero_sinistro_agenzia LIKE CONCAT('%', '${NumSinCompagnia}', '%')` 
                        : `SELECT 1 AS TipoNotifica, [10002].guid_evento, [10002].esito_evento, [10002].data_evento, [10002].risposta_evento, [09011].descriz AS DescrEventoRiferimento FROM [10002] INNER JOIN [10001] ON [10002].id_incarico = [10001].id_incarico LEFT OUTER JOIN [09011] ON [09011].cod_tab = 'UNI_evento' AND [09011].cod_ele = [10002].tipo_evento_riferimento WHERE [10001].id_interno_sinistro = '${IdInternoSinistro}' UNION SELECT 3 AS TipoNotifica, [12002].guid_evento, [12002].esito_evento, [12002].data_evento, [12002].risposta_evento, CASE WHEN [12002].tipo_evento = 'CHIUSDANN' THEN 'Chiusura Danneggiato' WHEN [12002].tipo_evento = 'CHIUSINC' THEN 'Chiusura Incarico' WHEN [12002].tipo_evento = 'INVIOALLEG' THEN 'Invio Allegato' END AS DescrEventoRiferimento FROM [12002] INNER JOIN [12003] ON [12002].id_incarico = [12003].id_incarico WHERE [12003].id_sinistro = ${IdSinistro} ORDER BY 4 DESC`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            this.setState({ NumSinistro: IdInternoSinistro });
            this.toggleBlocking();
            if (retData.length > 0) {
                let elencoNotifiche = [];
                retData.forEach(elm => {
                    const abilitaCancBox = IdCompagnia.toString() === CustomJS.codiceCompagniaUnipolSAI ? 'none' : 'inline';
                    if (elm.TipoNotifica === 1) {
                        const notifyColor = elm.esito_evento.trim() === 'OK' ? 'Chartreuse' : 'Red';
                        elencoNotifiche.push(<div className='notibox' id={elm.guid_evento}><span style={{ fontWeight: 700, fontSize: '16px', textDecorationLine: 'underline' }}>Messaggio da UnipolSAI del {moment.utc(elm.data_evento).format('DD/MM/YYYY HH:mm')}:</span><br /><span style={{ fontWeight: 600, fontSize: '16px' }}>[Evento: {elm.DescrEventoRiferimento}]</span><br /><span style={{ fontWeight: 700, fontSize: '16px', color: notifyColor }}>{elm.risposta_evento}</span><div className='cancelNotibox' title='Cancella Notifica' style={{ display: abilitaCancBox }}>✕</div></div>);
                    }
                    else if (elm.TipoNotifica === 2) {
                        elencoNotifiche.push(<div className='notibox'><span style={{ fontWeight: 700, fontSize: '16px', textDecorationLine: 'underline' }}>Presenza altro sinistro Compagnia uguale:</span><br /><br /><span style={{ fontWeight: 700, fontSize: '16px', color: 'cyan' }}>{elm.risposta_evento}</span></div>);
                    }
                    else if (elm.TipoNotifica === 3) {
                        let notifyColor = elm.esito_evento.trim() === 'OK' ? 'Chartreuse' : 'Red';
                        elencoNotifiche.push(<div className='notibox' id={elm.guid_evento}><span style={{ fontWeight: 700, fontSize: '16px', textDecorationLine: 'underline' }}>Messaggio da GENERALI del {moment.utc(elm.data_evento).format('DD/MM/YYYY HH:mm')}:</span><br /><span style={{ fontWeight: 600, fontSize: '16px' }}>[Evento: {elm.DescrEventoRiferimento}]</span><br /><span style={{ fontWeight: 700, fontSize: '16px', color: notifyColor }}>{elm.risposta_evento}</span><div className='cancelNotibox' title='Cancella Notifica' style={{ display: abilitaCancBox }}>✕</div></div>);
                    }
                });
                let container = document.getElementById('elencoNotifiche');
                let root = createRoot(container);
                root.unmount();
                setTimeout(() => {
                    container = document.getElementById('elencoNotifiche');
                    root = createRoot(container);
                    root.render(<CallbackWrapper callback={() => {
                        $(".sidebarNotifiche").toggleClass('active');
                        $(".cancelNotibox").on('click', function (e) {
                            $(this).parent().toggleClass('goneNotibox');
                            let dataObj = {
                                GuidEvento: $(this).parent().attr('id'),
                            };
                            const QueryExec2 = IdCompagnia.toString() === CustomJS.codiceCompagniaUnipolSAI ? `DELETE FROM [10002] WHERE guid_evento = @guid_evento;` : IdCompagnia.toString() === CustomJS.codiceCompagniaGenerali ? 'DELETE FROM [12002] WHERE guid_evento = @guid_evento;' : '';
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec2, JSON.stringify(dataObj), retData => {
                                if (retData.response === 'OK') {
                                    toast.success('Notifica cancellata con successo!!', {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                    if (parseInt(retData.data) === 0)
                                        $(".sidebarNotifiche").toggleClass('active');
                                    if (CustomJS.isNullEmpty(DaInterno) || DaInterno === false)
                                        setTimeout(() => {
                                            document.getElementById('aggiornatabella').click();
                                        }, 500);
                                }
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            }, true, true);
                        });
                        $('.chiudiNotifiche').off();
                        $('.chiudiNotifiche').on('click', function (e) {
                            $(".sidebarNotifiche").toggleClass('active');
                        });
                        $('body,html').off('click', function (e) {
                            $('.sidebarNotifiche').removeClass('active');
                        });
                        $('body,html').on('click', function (e) {
                            $('.sidebarNotifiche').removeClass('active');
                        });
                    }}>{elencoNotifiche}</CallbackWrapper>);
                }, 100);
            }
            else if (vediNessunaNotifica) {
                let container = document.getElementById('elencoNotifiche');
                let root = createRoot(container);
                root.unmount();
                setTimeout(() => {
                    container = document.getElementById('elencoNotifiche');
                    root = createRoot(container);
                    root.render(<CallbackWrapper callback={() => {
                        $(".sidebarNotifiche").toggleClass('active');
                        $(".chiudiNotifiche").off();
                        $(".chiudiNotifiche").on('click', function (e) {
                            $(".sidebarNotifiche").toggleClass('active');
                        });
                        $('body,html').off('click', function (e) {
                            $('.sidebarNotifiche').removeClass('active');
                        });
                        $('body,html').on('click', function (e) {
                            $('.sidebarNotifiche').removeClass('active');
                        });
                    }}><div style={{ textAlign: 'center' }}><span style={{ fontWeight: 700, fontSize: '1.4em', color: 'cornsilk' }}>Nessuna notifica presente</span></div></CallbackWrapper>);
                }, 100);
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    aggiornaInterlocutorie(IdSinistro) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec4 = `SELECT [01003].id_sinistro, [01003].id_tipo_nota, [01003].data_nota From [01003] WHERE [01003].id_sinistro = ${IdSinistro} And [01003].id_tipo_nota = 2 ORDER BY [01003].data_nota`;        
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec4, '', retData4 => {
            let cont = 0, str = '';
            retData4.forEach(elm =>  { 
                str += moment.utc(elm.data_nota).format('DD/MM/YYYY HH:mm:ss') + '\n';
                cont++;
            });
            document.getElementById(`interlocutoriePresenti${idSin}`).ej2_instances[0].value = "Interlocutorie presenti n° " + cont + '\n' + str;
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, true, true);
    }
    invioAccettazioneIncaricoUnipolSAI(idIncarico) {
        const dataObj = {
            APIUrl: CustomJS.APIURLTokenUnipolSAIProd,
            APIPath: "/returnJWTPIVA",
            NomeCert: CustomJS.NomeCertificatoUnipolSAI
        };
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/getAuthTokenUnipolSAI`, JSON.stringify(dataObj), 'POST',
            'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
            if (retData.response === 'OK') {
                const dataObj2 = {
                    TipoChiamata: "AccettazioneIncarico",
                    Metodo: 'POST',
                    APIUrl: CustomJS.APIURLDatiUnipolSAIProd,
                    APIPath: `${CustomJS.PathURLDatiUnipolSAIProd}/sinistri/swperitali/espullpush/v1/eventi`,
                    x_ibm_client_secret: CustomJS.ClientSecretProduzioneUnipolSAI,
                    accept: 'application/json',
                    content_type: 'application/json',
                    x_ibm_client_id: CustomJS.ClientIdProduzioneUnipolSAI,
                    x_unipol_requestid: 'x-unipol-req-id',
                    authorization_token_unipolsai: `Bearer ${retData.data}`,
                    IdIncarico: idIncarico,
                    CodiceFiscalePerito: "TCCSFN68T26B490J"      //VIENE INVIATO SEMPRE IL CODICE FISCALE DI STEFANO TOCCALINO
                };
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIUnipolSAI`, JSON.stringify(dataObj2), 'POST',
                    'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData2 => { 
                        if (retData2.response === 'OK')
                            toast.success(retData2.message, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }, true, false);
            }
            else if (retData.response === 'KO') {
                toast.warn(retData.message, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking2(false);
        }, true, false);
    }
    invioEsitoContattoDanneggiatoUnipolSAI(idIncarico) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const dataObj = {
            APIUrl: CustomJS.APIURLTokenUnipolSAIProd,
            APIPath: "/returnJWTPIVA",
            NomeCert: CustomJS.NomeCertificatoUnipolSAI
        };
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/getAuthTokenUnipolSAI`, JSON.stringify(dataObj), 'POST',
            'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
            if (retData.response === 'OK') {
                const dataObj2 = {
                    TipoChiamata: "EsitoContattoDanneggiato",
                    Metodo: 'POST',
                    APIUrl: CustomJS.APIURLDatiUnipolSAIProd,
                    APIPath: `${CustomJS.PathURLDatiUnipolSAIProd}/sinistri/swperitali/espullpush/v1/eventi`,
                    x_ibm_client_secret: CustomJS.ClientSecretProduzioneUnipolSAI,
                    accept: 'application/json',
                    content_type: 'application/json',
                    x_ibm_client_id: CustomJS.ClientIdProduzioneUnipolSAI,
                    x_unipol_requestid: 'x-unipol-req-id',
                    authorization_token_unipolsai: `Bearer ${retData.data}`,
                    IdIncarico: idIncarico,
                    DataOraContatto: moment(new Date()).format('YYYY-MM-DD HH:mm'),
                    CodiceEsitoContatto: CustomJS.isNullEmpty(document.getElementById(`dataOraSopralluogoConcordata${idSin}`).value) ? '02' : '01',
                    DataOraConcordataSopralluogo: !CustomJS.isNullEmpty(document.getElementById(`dataOraSopralluogoConcordata${idSin}`).value) ? moment(document.getElementById(`dataOraSopralluogoConcordata${idSin}`).value).format('YYYY-MM-DD HH:mm') : '',
                    DataProssimoContatto: null,
                    CodiceNazioneIndirizzoSpralluogo: !CustomJS.isNullEmpty(document.getElementById(`dataOraSopralluogoConcordata${idSin}`).value) ? 'IT' : '',
                    SiglaProvinciaIndirizzoSopralluogo: !CustomJS.isNullEmpty(document.getElementById(`dataOraSopralluogoConcordata${idSin}`).value) ? document.getElementById(`provinciaUbicazioneRischio${idSin}`).ej2_instances[0].value : '',
                    CodiceComuneIndirizzoSopralluogo: !CustomJS.isNullEmpty(document.getElementById(`dataOraSopralluogoConcordata${idSin}`).value) ? _.find(ElencoComuni, elm => { 
                                    return elm.comune.toUpperCase() === document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim().toUpperCase()
                                            || (elm.comune2?.toUpperCase() ?? '') === document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim().toUpperCase() 
                                            || (elm.comune3?.toUpperCase() ?? '') === document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim().toUpperCase()}).cod_fisco : '',
                    CapIndirizzoSopralluogo: !CustomJS.isNullEmpty(document.getElementById(`dataOraSopralluogoConcordata${idSin}`).value) ? document.getElementById(`capUbicazioneRischio${idSin}`).ej2_instances[0].value : '',
                    IndirizzoIndirizzoSopralluogo: !CustomJS.isNullEmpty(document.getElementById(`dataOraSopralluogoConcordata${idSin}`).value) ? document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value : '',
                    NumeroCivicoIndirizzoSopralluogo: !CustomJS.isNullEmpty(document.getElementById(`dataOraSopralluogoConcordata${idSin}`).value) ? /^[0-9\s]+$/.test(document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim().slice(-3).replace(/\s/g, '')) ? document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim().slice(-3).replace(/\s/g, '') : 'SNC' : '',
                    CellulareDanneggiato: document.getElementById(`telefonoDanneggiato${idSin}`).ej2_instances[0].value?.trim(),
                    EmailDanneggiato: document.getElementById(`emailDanneggiato${idSin}`).ej2_instances[0].value?.trim(),
                };
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIUnipolSAI`, JSON.stringify(dataObj2), 'POST',
                    'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData2 => { 
                        if (retData2.response === 'OK')
                            toast.success(retData2.message, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }, true, false);
            }
            else if (retData.response === 'KO') {
                toast.warn(retData.message, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking2(false);
        }, true, false);
    }
    invioDatiSopralluogoUnipolSAI(idIncarico) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const dataObj = {
            APIUrl: CustomJS.APIURLTokenUnipolSAIProd,
            APIPath: "/returnJWTPIVA",
            NomeCert: CustomJS.NomeCertificatoUnipolSAI
        };
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/getAuthTokenUnipolSAI`, JSON.stringify(dataObj), 'POST',
            'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
            if (retData.response === 'OK') {
                const dataObj2 = {
                    TipoChiamata: "DatiSopralluogo",
                    Metodo: 'POST',
                    APIUrl: CustomJS.APIURLDatiUnipolSAIProd,
                    APIPath: `${CustomJS.PathURLDatiUnipolSAIProd}/sinistri/swperitali/espullpush/v1/eventi`,
                    x_ibm_client_secret: CustomJS.ClientSecretProduzioneUnipolSAI,
                    accept: 'application/json',
                    content_type: 'application/json',
                    x_ibm_client_id: CustomJS.ClientIdProduzioneUnipolSAI,
                    x_unipol_requestid: 'x-unipol-req-id',
                    authorization_token_unipolsai: `Bearer ${retData.data}`,
                    IdIncarico: idIncarico,
                    SopralluogoEffettuato: 'S',
                    DataAvvenutoSopralluogo: moment(document.getElementById(`dataSopralluogo${idSin}`).ej2_instances[0].value).format('YYYY-MM-DD'),
                    CodiceNazioneIndirizzoSpralluogo: 'IT',
                    SiglaProvinciaIndirizzoSopralluogo: document.getElementById(`provinciaUbicazioneRischio${idSin}`).ej2_instances[0].value,
                    CodiceComuneIndirizzoSopralluogo: _.find(ElencoComuni, elm => { return elm.comune.toUpperCase() === document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim().toUpperCase() 
                                                                                        || (elm.comune2?.toUpperCase() ?? '') === document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim().toUpperCase() 
                                                                                        || (elm.comune3?.toUpperCase() ?? '') === document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim().toUpperCase() })?.cod_fisco,
                    CapIndirizzoSopralluogo: document.getElementById(`capUbicazioneRischio${idSin}`).ej2_instances[0].value,
                    IndirizzoIndirizzoSopralluogo: document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value,
                    NumeroCivicoIndirizzoSopralluogo: /^[0-9\s]+$/.test(document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim().slice(-3).replace(/\s/g, '')) ? document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value?.trim().slice(-3).replace(/\s/g, '') : 'SNC',
                    NoteSopralluogo: null,
                    PresenzaCriticitaSinistro: 'N',
                    NoteCriticitaSinistro: null
                };
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIUnipolSAI`, JSON.stringify(dataObj2), 'POST',
                    'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData2 => { 
                        if (retData2.response === 'OK')
                            toast.success(retData2.message, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }, true, false);
            }
            else if (retData.response === 'KO') {
                toast.warn(retData.message, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1', 
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking2(false);
        }, true, false);
    }
    invioRifiutoIncaricoUnipolSAI(idIncarico) {
        const dataObj = {
            APIUrl: CustomJS.APIURLTokenUnipolSAIProd,
            APIPath: "/returnJWTPIVA",
            NomeCert: CustomJS.NomeCertificatoUnipolSAI
        };
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/getAuthTokenUnipolSAI`, JSON.stringify(dataObj), 'POST',
            'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
            if (retData.response === 'OK') {
                const dataObj2 = {
                    TipoChiamata: "RifiutoIncarico",
                    Metodo: 'POST',
                    APIUrl: CustomJS.APIURLDatiUnipolSAIProd,
                    APIPath: `${CustomJS.PathURLDatiUnipolSAIProd}/sinistri/swperitali/espullpush/v1/eventi`,
                    x_ibm_client_secret: CustomJS.ClientSecretProduzioneUnipolSAI,
                    accept: 'application/json',
                    content_type: 'application/json',
                    x_ibm_client_id: CustomJS.ClientIdProduzioneUnipolSAI,
                    x_unipol_requestid: 'x-unipol-req-id',
                    authorization_token_unipolsai: `Bearer ${retData.data}`,
                    IdIncarico: idIncarico,
                    TestoRifiuto: 'Incarico Fuori Competenza'
                };
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIUnipolSAI`, JSON.stringify(dataObj2), 'POST',
                    'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData2 => { 
                        if (retData2.response === 'OK')
                            toast.success(retData2.message, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }, true, false);
            }
            else if (retData.response === 'KO') {
                toast.warn(retData.message, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking2(false);
        }, true, false);
    }
    invioInterlocutoriaUnipolSAI(idIncarico, pathAllegato, tipo) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const dataObj = {
            APIUrl: CustomJS.APIURLTokenUnipolSAIProd,
            APIPath: "/returnJWTPIVA",
            NomeCert: CustomJS.NomeCertificatoUnipolSAI
        };
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/getAuthTokenUnipolSAI`, JSON.stringify(dataObj), 'POST',
            'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
            if (retData.response === 'OK') {
                const dataObj2 = {
                    TipoChiamata: tipo,
                    Metodo: 'POST',
                    APIUrl: CustomJS.APIURLDatiUnipolSAIProd,
                    APIPath: `${CustomJS.PathURLDatiUnipolSAIProd}/sinistri/swperitali/espullpush/v1/eventi`,
                    x_ibm_client_secret: CustomJS.ClientSecretProduzioneUnipolSAI,
                    accept: 'application/json',
                    content_type: 'application/json',
                    x_ibm_client_id: CustomJS.ClientIdProduzioneUnipolSAI,
                    x_unipol_requestid: 'x-unipol-req-id',
                    authorization_token_unipolsai: `Bearer ${retData.data}`,
                    IdIncarico: idIncarico,
                    CodiceMotivoInterlocutoria: tipo === 'InterlocutoriaTestuale' ? '31' : document.getElementById('motivoInterlocutoria').ej2_instances[0].value,
                    TestoInterlocutoria: tipo === 'InterlocutoriaTestuale' ? document.getElementById(`mascheraInterl${idSin}`).ej2_instances[0].value?.trim() : document.getElementById('testoInterlocutoria').ej2_instances[0].value?.trim(),
                    PathDocumentoInterloc: tipo === 'InterlocutoriaDocumentale' ? pathAllegato : null
                };
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIUnipolSAI`, JSON.stringify(dataObj2), 'POST',
                    'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData2 => { 
                        if (retData2.response === 'OK') {
                            toast.success(retData2.message, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            if (tipo === 'InterlocutoriaTestuale') {
                                document.getElementById(`mascheraInterl${idSin}`).ej2_instances[0].value = null; 
                                document.getElementById(`checkInterl${idSin}`).ej2_instances[0].checked = false;
                                document.getElementById(`divNotaManuale${idSin}`).style.display = 'none';     
                            }
                            else {
                                const dataObj = {
                                    IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                                    Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                    DescrNota: document.getElementById('testoInterlocutoria').ej2_instances[0].value?.trim(),
                                    Stato: null,
                                    CheckInterl: 2
                                };       
                                const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, @check_interl, @descr_nota"  +
                                                    ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 0, null)";
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                    this.aggiornaCampoNote(document.getElementById(`idSinistro${idSin}`).value);
                                    this.dialogInstance6.hide(); 
                                    this.dialogInstance7.hide(); 
                                    this.toggleBlocking2(false);
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                }, true, true);
                            }
                        }
                        else {
                            toast.warn(retData2.message, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }, true, false);
            }
            else if (retData.response === 'KO') {
                toast.warn(retData.message, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking2(false);
        }, true, false);
    }
    invioRestituzionePeriziaUnipolSAI(idIncarico, idSinistro) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const dataObj = {
            APIUrl: CustomJS.APIURLTokenUnipolSAIProd,
            APIPath: "/returnJWTPIVA",
            NomeCert: CustomJS.NomeCertificatoUnipolSAI
        };
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/getAuthTokenUnipolSAI`, JSON.stringify(dataObj), 'POST',
            'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
            if (retData.response === 'OK') {
                const dataObj2 = {
                    TipoChiamata: "RestituzionePerizia",
                    Metodo: 'POST',
                    APIUrl: CustomJS.APIURLDatiUnipolSAIProd,
                    APIPath: `${CustomJS.PathURLDatiUnipolSAIProd}/sinistri/swperitali/espullpush/v1/eventi`,
                    x_ibm_client_secret: CustomJS.ClientSecretProduzioneUnipolSAI,
                    accept: 'application/json',
                    content_type: 'application/json',
                    x_ibm_client_id: CustomJS.ClientIdProduzioneUnipolSAI,
                    x_unipol_requestid: 'x-unipol-req-id',
                    authorization_token_unipolsai: `Bearer ${retData.data}`,
                    IdIncarico: idIncarico,
                    IdSinistro: idSinistro,
                    IdInternoSinistro: idSin
                };
                CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/chiamataAPIUnipolSAI`, JSON.stringify(dataObj2), 'POST',
                    'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData2 => { 
                        if (retData2.response === 'OK') {
                            toast.success(retData2.message, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                            document.getElementById(`ddlCambiaStato${idSin}`).ej2_instances[0].value = '3';
                            this.aggiornaStatoCambioStato();
                            const dataObj = {
                                IdSinistro : document.getElementById(`idSinistro${idSin}`).value,
                            };  
                            const QueryExec = `UPDATE [01001] SET [01001].chiusura_telematica = 1 WHERE [01001].id_sinistro = @id_sinistro`;        
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 
                                'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                    // document.getElementById(`parcella${idSin}`).ej2_instances[0].value = 105.00;
                                    // this.onChangeParcellaDettaglio({ value: 105.00 });
                            }, error => {
                                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),                    
                                    position: "bottom-right",
                                    autoClose: 15000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );    
                            }, true, true);      
                        }
                }, error => {
                    let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                }, true, false);
            }
            else if (retData.response === 'KO') {
                toast.warn(retData.message, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1', 
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking2(false);
        }, true, false);
    }
    creaInterlocutoriaDocumentale(filePath, base64Doc) {
        return(<div className="control-section card-control-section basic_card_layout">
            <input type="hidden" id='pathAllegatoInterlocutoria' value={filePath}/>
            <input type="hidden" id='base64AllegatoInterlocutoria' value={base64Doc}/>
            <div className="e-card-resize-container">
                <div className="card-layout">
                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                        <div tabIndex="0" className="e-card" id="main_card">                                                
                            <div className="e-card-content"> 
                                <div className='row'>
                                    <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5">
                                        <DropDownListComponent id='motivoInterlocutoria' fields={this.ddlFields} dataSource={this.dsMotivoInterlocutoria}  
                                                allowFiltering={false} filterType='Contains' showClearButton={false} value='31'
                                                placeholder="Selezionare un motivo interlocutoria" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                        </DropDownListComponent>  
                                    </div>                                                                   
                                </div>
                                <div className='row'>
                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                        <span style={{ fontSize: '0.7vw', fontWeight: '700' }}>Allegato:&nbsp;&nbsp;<img src={ImmaginePDF} alt='Allegato PDF' style={{ width: '25px', height: '25px' }} />&nbsp;&nbsp;{filePath.substring(filePath.lastIndexOf('/')+1)}&nbsp;&nbsp;&nbsp;&nbsp;<ButtonComponent id='vediAllegato' key={this.childKey} title='Visualizza Allegato' cssClass='e-details' iconCss='fas fa-eye fa-6x' onClick={this.visualizzaAllegatoInterlocutoria.bind(this)}></ButtonComponent></span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                        <TextBoxComponent id='testoInterlocutoria' floatLabelType='Auto' placeholder='Testo Interlocutoria' ref = {txtObj => {this.textBoxInstance6 = txtObj}} multiline={true} cssClass='e-outline' ></TextBoxComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
    caricaDatiDocumentoPerizia() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const idSinistro = document.getElementById(`idSinistro${idSin}`).value;
        this.toggleBlocking2(true);
    /*0 0*/const intestazione = `SELECT T1.id_interno_sinistro, T1.t_sinistro, T1.indirizzo, T1.cap, T1.citta, T1.provincia, T1.numero_sinistro_agenzia, T1.id_tipo_polizza, T1.numero_polizza, T1.t_incarico, T1.t_sopralluogo AS data_sopralluogo, IIF(T1.videoperizia = 1, T1.t_sopralluogo, NULL) as data_videoperizia, T2.descrizione AS compagnia, T3.nome AS tipo_polizza, T1.contr_nominativo, T1.indirizzo AS indir_contr, T1.cap AS cap_contr, T1.citta as citta_contr, T1.provincia as prov_contr, T1.ass_nominativo as assicurato, T1.indirizzo as indir_ass, T1.cap as cap_ass, T1.citta as citta_ass, T1.provincia as prov_ass, T1.amm_nominativo as amministratore, IIF(ISNULL(T1.amm_nominativo,'') != '', T1.indirizzo, NULL) as indir_amm, IIF(ISNULL(T1.amm_nominativo,'') != '', T1.cap, NULL) AS cap_amm, IIF(ISNULL(T1.amm_nominativo,'') != '', T1.citta, NULL) AS citta_amm, IIF(ISNULL(T1.amm_nominativo,'') != '', T1.provincia, NULL) AS prov_amm, T4.polizza_vincoli FROM [01001] T1 LEFT OUTER JOIN [00002] T2 on T1.id_compagnia = T2.id_compagnia LEFT OUTER JOIN [02001] T3 on T1.id_tipo_polizza = T3.id_tipo_polizza LEFT OUTER JOIN [12003] T4 on T1.id_sinistro = T4.id_sinistro WHERE T1.id_sinistro = ${idSinistro};`;
    /*1 n*/const danneggiatiIntes = `SELECT nominativo, T2.indirizzo AS indir_dann, T2.cap AS cap_dann, T2.citta AS citta_dann, T2.provincia AS prov_dann FROM [00005] T1 INNER JOIN [01001] T2 ON T1.id_sinistro = T2.id_sinistro WHERE T1.id_sinistro = ${idSinistro};`;
    /*2 0*/const elabPeritale1 = `SELECT RA_note_fabbr,SM_note, '' AS OP_note,R_note,D_note, Ris_note FROM [11001] WHERE id_sinistro = ${idSinistro};`;
    /*3 0*/const riep0 = `SELECT * FROM [11012] where id_sinistro = ${idSinistro};`;
    /*4 n*/const riepDanneggiati = `SELECT T1.*, T3.indirizzo, T3.citta, T3.provincia, T3.cap, T2.telefono1, T5.IBAN_liquidazione, T4.codiceFiscale_partitaIVA FROM [11013] T1 LEFT OUTER JOIN [00005] T2 on T1.id_danneggiato = T2.id_contatto LEFT OUTER JOIN [01001] T3 ON T3.id_sinistro = T2.id_sinistro LEFT OUTER JOIN [12004] T4 on T1.id_danneggiato = T4.id_danneggiato_jftech LEFT OUTER JOIN [12012] T5 on T4.id_danneggiato = T5.id_danneggiato WHERE T1.id_sinistro = ${idSinistro};`;
    /*5 n*/const riepRighe = `SELECT T1.*,T2.danneggiato FROM [11014] T1 LEFT OUTER JOIN [11013] T2 on T1.id_riep_danneggiato = T2.idDanneggiatoRiep where T1.id_sinistro = ${idSinistro};`;
    /*6 n*/const preesistenze = `SELECT T2.partitaDescrizione,T2.id_partita,T2.formaGaranziaDescrizione, T1.* FROM [11006] T1 LEFT OUTER JOIN [11002] T2 on T1.id_partitaPeriziaOnline = T2. id_partitaPeriziaOnline where T1.id_sinistro = ${idSinistro};`;
    /*7 n*/const costruzioni = `SELECT * FROM [11007] where id_sinistro = ${idSinistro};`;
    /*8 n*/const descrizioni = `SELECT * FROM [11008] where id_sinistro = ${idSinistro};`;
    /*9 n*/const stime = `SELECT T2.nominativo,T3.partitaDescrizione,T4.garanziaDescrizione,T4.descrizioneDetermDanno,T4.id_determinazioneDanno,T1.* FROM [11004] T1 LEFT OUTER JOIN [00005] T2 on T1.id_danneggiato = T2.id_contatto LEFT OUTER JOIN [11002] T3 on T1.id_partita = T3.id_partitaPeriziaOnline LEFT OUTER JOIN [11003] T4 on T1.id_garanzia = T4.id_garanziaPeriziaOnline WHERE T1.id_sinistro =  ${idSinistro};`;
    /*10 n*/const prezziari = `SELECT * FROM [11009] where id_sinistro = ${idSinistro};`;
    /*11 0*/const sede = `SELECT 'MILANO' AS valore FROM [01001] where id_sinistro = ${idSinistro};`;
    /*12 0*/const perito = `SELECT DISTINCT CONCAT(T3.cognome, ' ', T3.nome) AS denom FROM [01001] T1 LEFT OUTER JOIN [01002] T2 on T2.id_sinistro = T1.id_sinistro LEFT OUTER JOIN [09001] T3 ON T3.id_utente = T2.id_utente where T1.id_sinistro = ${idSinistro};`;
    /*13 n*/const coass = `SELECT * FROM [11010] where id_sinistro = ${idSinistro};`;
    /*14 n*/const preesistenzePRA = `SELECT * FROM [11002] where id_sinistro = ${idSinistro} and formaGaranzia = 1;`;

        var tutteQuery = [intestazione, danneggiatiIntes, elabPeritale1, riep0, riepDanneggiati, riepRighe, preesistenze, costruzioni, descrizioni, stime, prezziari, sede, perito, coass, preesistenzePRA];
        var arrayRetData = []
        let funcList = [];

        tutteQuery.forEach(query => {
            const esegui = () => {
                return new Promise((resolve, reject) => {
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', query, '', retData => {
                        arrayRetData.push(retData)
                        resolve('Prossima');
                    }, (err) => { reject(err) }, true, true);
                })
            }
            funcList.push(esegui);
        })

        const eseguiCiclica = (ind) => {
            if (CustomJS.isNullEmpty(funcList[ind])) {
                this.creaDocumentoPerizia(arrayRetData);
            }
            else {
                funcList[ind]().then(ret => {
                    if (ret === 'Prossima') {
                        eseguiCiclica(ind + 1);
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
        }
        eseguiCiclica(0);
    }
    creaDocumentoPerizia(arrayPerizia) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        //carico ulteriori dati
        const timeElapsed = Date.now();
        const today = (new Date(timeElapsed)).toLocaleDateString();
        var options = { 'weekday': 'long', 'day': '2-digit', 'month': '2-digit', 'year': "numeric", };
        var today2 = new Date().toLocaleString('it-IT', options);
        var arrayDate = [today, today2];
        arrayPerizia.push(arrayDate) // ultimo id === 16 (0, 1)
        if (arrayPerizia[13].length === 0) {
            arrayPerizia[13] = ['No']
        }
        else {
            arrayPerizia[13] = ['Si']
        }

        //controllo se possibile effettuare la chiamata
        var crea = true;
        for (let index = 0; index < arrayPerizia.length; index++) {
            //preesistenze costruzioni e descrizioni(nel caso sia pra) e allegati
            if (index !== 6 && index !== 7 && index !== 8 && index !== 14) {
                if (arrayPerizia[index].length === 0) {
                    crea = false;
                }
            }
        }
        
        //data format data_inc_stu
        var dataInFormatoISO_incStu = arrayPerizia[0][0].t_incarico;
        var data_incStu = new Date(dataInFormatoISO_incStu);
        var giorno_incStu = data_incStu.getDate();
        var mese_incStu = data_incStu.getMonth() + 1;
        var anno_incStu = data_incStu.getFullYear();
        if (giorno_incStu < 10) giorno_incStu = '0' + giorno_incStu;
        if (mese_incStu < 10) mese_incStu = '0' + mese_incStu;
        arrayPerizia[0][0].data_inc_stu = giorno_incStu + '/' + mese_incStu + '/' + anno_incStu;

        //data format sin_acc
        var dataInFormatoISO_sinAcc = arrayPerizia[0][0].t_sinistro;
        var data_sinAcc = new Date(dataInFormatoISO_sinAcc);
        var giorno_sinAcc = data_sinAcc.getDate();
        var mese_sinAcc = data_sinAcc.getMonth() + 1;
        var anno_sinAcc = data_sinAcc.getFullYear();
        if (giorno_sinAcc < 10) giorno_sinAcc = '0' + giorno_sinAcc;
        if (mese_sinAcc < 10) mese_sinAcc = '0' + mese_sinAcc;
        arrayPerizia[0][0].sin_acc = giorno_sinAcc + '/' + mese_sinAcc + '/' + anno_sinAcc;

        //data format data_sopralluogo_xpert
        if (!CustomJS.isNullEmpty(arrayPerizia[0][0].data_sopralluogo)) {
            var dataInFormatoISO_soprallEff = arrayPerizia[0][0].data_sopralluogo;
            var data_soprallEff = new Date(dataInFormatoISO_soprallEff);
            var giorno_soprallEff = data_soprallEff.getDate();
            var mese_soprallEff = data_soprallEff.getMonth() + 1;
            var anno_soprallEff = data_soprallEff.getFullYear();
            if (giorno_soprallEff < 10) giorno_soprallEff = '0' + giorno_soprallEff;
            if (mese_soprallEff < 10) mese_soprallEff = '0' + mese_soprallEff;
            arrayPerizia[0][0].data_sopralluogo = giorno_soprallEff + '/' + mese_soprallEff + '/' + anno_soprallEff;
        }
        
        //data format data_videoperizia_xpert
        if (!CustomJS.isNullEmpty(arrayPerizia[0][0].data_videoperizia)) {
            var dataInFormatoISO_videoperEff = arrayPerizia[0][0].data_videoperizia;
            var data_videoperEff = new Date(dataInFormatoISO_videoperEff);
            var giorno_videoperEff = data_videoperEff.getDate();
            var mese_videoperEff = data_videoperEff.getMonth() + 1;
            var anno_videoperEff = data_videoperEff.getFullYear();
            if (giorno_videoperEff < 10) giorno_videoperEff = '0' + giorno_videoperEff;
            if (mese_videoperEff < 10) mese_videoperEff = '0' + mese_videoperEff;
            arrayPerizia[0][0].data_videoperizia = giorno_videoperEff + '/' + mese_videoperEff + '/' + anno_videoperEff;
        }
        
        if (crea === true) {
            CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/WordCreator/CreaDocumentoPeriziaACT`, { datiPeriziaJSON: JSON.stringify(arrayPerizia) }, 'POST', 
                { "Authorization" : CustomJS.tokenAuthAPIVarious }, retData => {
                    this.dialogInstance8.header = `<div><span id='dialog-title-${this.dialogInstance8.id}' class='title'>VISUALIZZAZIONE DOCUMENTO PDF</span><span class='fas fa-window-minimize' style='float: right; cursor: pointer;' id='min-btn-${this.dialogInstance8.id}' title='Minimizza la finestra'></span></div>`;    
                    this.dialogInstance8.width = '65%';
                    this.dialogInstance8.height = '90%';
                    this.dialogInstance8.minHeight = '90%';
                    this.dialogInstance8.buttons = [
                        {
                            click: () => {
                            },
                            buttonModel: {
                                content: 'ALLEGA DOCUMENTO',
                                cssClass: 'e-info',
                                isPrimary: true
                            }
                        },
                        {
                            click: () => {
                                this.dialogInstance8.hide();
                            },
                            buttonModel: {
                                content: 'CHIUDI'
                            }
                        }
                    ];    
                    this.dialogInstance8.beforeOpen = (args) => {
                        const container = document.getElementById('defaultDialogContent5');
                        const root = createRoot(container); 
                        root.unmount();
                    };
                    this.dialogInstance8.open = (args) => {
                        this.onMinimizeDialogOpen(`VISUALIZZAZIONE DOCUMENTO PDF`, '440px', '240', '90%', this.dialogInstance8);
                        const container = document.getElementById('defaultDialogContent5');
                        const root = createRoot(container);  
                        root.render(<CallbackWrapper callback={() => {
                            document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].enableDownload = true;
                            document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].downloadFileName = `DocumentoPerizia_${idSin}.pdf`;
                            document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].serviceUrl = `${CustomJS.APIVariousJfishTechURLProd}/api/PdfViewer`;
                            document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                            //this.PDFViewerInstance.dataBind();  //NON SEMBRA SERVIRE
                            setTimeout(() => {
                                document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].load(`data:application/pdf;base64,${retData}`, null);
                            }, 250);  
                            this.toggleBlocking2(false);
                        }}>{this.PDFViewerRender()}</CallbackWrapper>);   
                    };  
                    this.dialogInstance8.show(); 
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking2(false);
            }, true, false);
        }
        else {
            toast.warn("Attenzione!! Prima di creare il documento è necessario completare la perizia!", {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking2(false);
        }
    }
    scaricoReportFulmini(tipo) {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let suffissoAPIUrl = tipo === 'CESI' ? 'METEORAGE' : 'METEOCAST'; 
        let indirizzo = `${document.getElementById(`indirizzoUbicazioneRischio${idSin}`).ej2_instances[0].value} ${document.getElementById(`cittaUbicazioneRischio${idSin}`).ej2_instances[0].value} ${document.getElementById(`capUbicazioneRischio${idSin}`).ej2_instances[0].value} ${document.getElementById(`provinciaUbicazioneRischio${idSin}`).ej2_instances[0].value}`;
        let dataSinistro = document.getElementById(`dataSinistro${idSin}`).ej2_instances[0].value;
        const dataObj = {
            luogoSinistro: indirizzo,
            dataSinistro: tipo === 'CESI'? moment(dataSinistro).format('YYYY/MM/DD') : moment(dataSinistro).format('YYYY-MM-DD'),
            idInternoSinistro: idSin
        };
        this.toggleBlocking2(true);
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/scaricaReportFulmini${suffissoAPIUrl}`, JSON.stringify(dataObj), 'POST',
            'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
                console.log(retData);
                if (retData.response === 'OK') {
                    toast.success(retData.message, {
                        containerId: 'toastContainer1',
                        //onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    if (tipo === 'CESI') {
                        const container = document.getElementById(`divEsitoFulminazione${idSin}`);
                        const root = createRoot(container);
                        root.render(<CallbackWrapper callback={() => {
                            setTimeout(() => {
                                if (retData.columnData.length === 0) {
                                    document.getElementById(`descrEsitoAutomaticoReportFulmini${idSin}`).innerText = 'NESSUNA FULMINAZIONE';
                                    document.getElementById(`descrEsitoAutomaticoReportFulmini${idSin}`).style.color = 'red';
                                    document.getElementById(`checkFulminazioneEntro3KM${idSin}`).ej2_instances[0].checked = false;
                                    document.getElementById(`checkFulminazioneOltre3KM${idSin}`).ej2_instances[0].checked = false; 
                                    document.getElementById(`descrTipoFulminiEntro3KM${idSin}`).innerText = '';
                                    document.getElementById(`descrTipoFulminiOltre3KM${idSin}`).innerText = '';
                                }
                                else {  
                                    document.getElementById(`descrEsitoAutomaticoReportFulmini${idSin}`).innerText = 'PRESENZA FULMINAZIONI';
                                    document.getElementById(`descrEsitoAutomaticoReportFulmini${idSin}`).style.color = 'green';
                                    document.getElementById(`checkFulminazioneEntro3KM${idSin}`).ej2_instances[0].checked = retData.columnData.some(obj => { return parseFloat(obj.distanza) <= 3; });
                                    document.getElementById(`checkFulminazioneOltre3KM${idSin}`).ej2_instances[0].checked = retData.columnData.some(obj => { return parseFloat(obj.distanza) > 3; });
                                    document.getElementById(`descrTipoFulminiEntro3KM${idSin}`).innerText = CustomJS.checkColumnConditions(retData.columnData, 'nuvola', 'terra', 'Entro3KM', 'CESI');
                                    document.getElementById(`descrTipoFulminiOltre3KM${idSin}`).innerText = CustomJS.checkColumnConditions(retData.columnData, 'nuvola', 'terra', 'Oltre3KM', 'CESI');
                                }   
                                this.aggiornaDettaglio('fulminazione_entro_3KM', document.getElementById(`checkFulminazioneEntro3KM${idSin}`).ej2_instances[0].checked ? 1 : 0, 'INT');
                                this.aggiornaDettaglio('fulminazione_oltre_3KM', document.getElementById(`checkFulminazioneOltre3KM${idSin}`).ej2_instances[0].checked ? 1 : 0, 'INT');
                                this.aggiornaDettaglio('esito_automatico_fulminazioni ', 1, 'INT');    
                                this.aggiornaDettaglio('tipo_fulminazione_entro_3KM', document.getElementById(`descrTipoFulminiEntro3KM${idSin}`).innerText, 'CHAR');
                                this.aggiornaDettaglio('tipo_fulminazione_oltre_3KM', document.getElementById(`descrTipoFulminiOltre3KM${idSin}`).innerText, 'CHAR');        
                            }, 100);
                        }}>{this.esitoReportFulminiRender()}</CallbackWrapper>);    
                    }
                    else {
                        const container = document.getElementById(`divEsitoFulminazione${idSin}`);
                        const root = createRoot(container);
                        root.render(<CallbackWrapper callback={() => {
                            console.log(retData);
                            setTimeout(() => {
                                if (retData.columnData[0].values.length === 0) {
                                    document.getElementById(`descrEsitoAutomaticoReportFulmini${idSin}`).innerText = 'NESSUNA FULMINAZIONE';
                                    document.getElementById(`descrEsitoAutomaticoReportFulmini${idSin}`).style.color = 'red';
                                    document.getElementById(`checkFulminazioneEntro3KM${idSin}`).ej2_instances[0].checked = false;
                                    document.getElementById(`checkFulminazioneOltre3KM${idSin}`).ej2_instances[0].checked = false;
                                    document.getElementById(`descrTipoFulminiEntro3KM${idSin}`).innerText = '';
                                    document.getElementById(`descrTipoFulminiOltre3KM${idSin}`).innerText = '';
                                    this.aggiornaDettaglio('fulminazione_entro_3KM', 0, 'INT');
                                    this.aggiornaDettaglio('fulminazione_oltre_3KM', 0, 'INT');
                                    this.aggiornaDettaglio('esito_automatico_fulminazioni ', 1, 'INT');
                                    this.aggiornaDettaglio('tipo_fulminazione_entro_3KM', '', 'CHAR');
                                    this.aggiornaDettaglio('tipo_fulminazione_oltre_3KM', '', 'CHAR');        
                                }
                                else {
                                    document.getElementById(`descrEsitoAutomaticoReportFulmini${idSin}`).innerText = 'PRESENZA FULMINAZIONI';
                                    document.getElementById(`descrEsitoAutomaticoReportFulmini${idSin}`).style.color = 'green'; 
                                    document.getElementById(`checkFulminazioneEntro3KM${idSin}`).ej2_instances[0].checked = retData.columnData[0].values.slice(1).some(value => { const num = parseFloat(value.column1); return !isNaN(num) && num <= 3; });
                                    this.aggiornaDettaglio('fulminazione_entro_3KM', document.getElementById(`checkFulminazioneEntro3KM${idSin}`).ej2_instances[0].checked ? 1 : 0, 'INT');
                                    document.getElementById(`checkFulminazioneOltre3KM${idSin}`).ej2_instances[0].checked = retData.columnData[0].values.slice(1).some(value => { const num = parseFloat(value.column1); return !isNaN(num) && num > 3; });
                                    this.aggiornaDettaglio('fulminazione_oltre_3KM', document.getElementById(`checkFulminazioneOltre3KM${idSin}`).ej2_instances[0].checked ? 1 : 0, 'INT');    
                                    this.aggiornaDettaglio('esito_automatico_fulminazioni ', 1, 'INT');     
                                    document.getElementById(`descrTipoFulminiEntro3KM${idSin}`).innerText = CustomJS.checkColumnConditions(retData.columnData[0].values.slice(1), 'Cloud-to-Cloud', 'Cloud-to-Ground', 'Entro3KM', 'MCAST');
                                    document.getElementById(`descrTipoFulminiOltre3KM${idSin}`).innerText = CustomJS.checkColumnConditions(retData.columnData[0].values.slice(1), 'Cloud-to-Cloud', 'Cloud-to-Ground', 'Oltre3KM', 'MCAST');
                                    this.aggiornaDettaglio('tipo_fulminazione_entro_3KM', document.getElementById(`descrTipoFulminiEntro3KM${idSin}`).innerText, 'CHAR');
                                    this.aggiornaDettaglio('tipo_fulminazione_oltre_3KM', document.getElementById(`descrTipoFulminiOltre3KM${idSin}`).innerText, 'CHAR');        
                                }
                            }, 100);
                        }}>{this.esitoReportFulminiRender()}</CallbackWrapper>);    
                    }
                    this.toggleBlocking2(false);
                }
                else if (retData.response === 'KO') {
                    toast.warn(retData.message, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    this.toggleBlocking2(false);
                    if (!retData.message.includes('Nessun report fulmini METEOCAST disponibile')) {
                        const container = document.getElementById(`divEsitoFulminazione${idSin}`);
                        const root = createRoot(container);
                        root.render(<CallbackWrapper callback={() => {
                            setTimeout(() => {
                                document.getElementById(`descrEsitoAutomaticoReportFulmini${idSin}`).innerText = 'NESSUNA FULMINAZIONE';
                                document.getElementById(`descrEsitoAutomaticoReportFulmini${idSin}`).style.color = 'red';
                                document.getElementById(`checkFulminazioneEntro3KM${idSin}`).ej2_instances[0].checked = false;
                                document.getElementById(`checkFulminazioneOltre3KM${idSin}`).ej2_instances[0].checked = false;
                                document.getElementById(`descrTipoFulminiEntro3KM${idSin}`).innerText = '';
                                document.getElementById(`descrTipoFulminiOltre3KM${idSin}`).innerText = '';
                                this.aggiornaDettaglio('fulminazione_entro_3KM', 0, 'INT');
                                this.aggiornaDettaglio('fulminazione_oltre_3KM', 0, 'INT');
                                this.aggiornaDettaglio('esito_automatico_fulminazioni', 1, 'INT');
                                this.aggiornaDettaglio('tipo_fulminazione_entro_3KM', '', 'CHAR');
                                this.aggiornaDettaglio('tipo_fulminazione_oltre_3KM', '', 'CHAR');
                            }, 100);
                        }}>{this.esitoReportFulminiRender()}</CallbackWrapper>);        
                    }
                }
                else if (retData.response === 'Errore') {
                    toast.warn(retData.message, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1', 
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            this.toggleBlocking2(false);
        }, true, false);
    }
    dialogoIntelligenzaArtificiale() {

    }

    render() {
        return (<React.Fragment>   
                    <div className="sidebarNoteParzialiSinistro" style={{ textAlign: 'center' }}>
                        <h3>Note Sinistro {this.state.NumSinistro}<span style={{ fontSize: '1.1vw'}}>({this.state.NumSinistroAgenzia})</span></h3>
                        <div className='chiudiNoteParzialiSinistro' title='Chiudi Note'>✕</div>
                        <div id='elencoNoteParzialiSinistro' style={{ textAlign: 'left', marginLeft: '10px' }}></div>
                    </div>
                    <div className="sidebarNotifiche" style={{ textAlign: 'center' }}>
                        <h3>Notifiche<br />Sinistro {this.state.NumSinistro}</h3>
                        <div className='chiudiNotifiche' title='Chiudi Notifiche'>✕</div>
                        <div id='elencoNotifiche'></div>
                    </div>
                    <div>{this.state.showLightbox ? <Lightbox image={this.lightboxImage} onClose={()=>this.setState({showLightbox: false})}></Lightbox> : null}</div> 
                    <div>{this.state.showLightbox2 ? <Lightbox images={this.lightboxImages} onClose={()=>this.setState({showLightbox2: false})}></Lightbox> : null}</div> 
                    <iframe id='iframeGenerico' title='Iframe Generico per utilizzi vari' src='' style={{width: '50px', height: '50px', border: '0px solid black'}}></iframe>
                    <DashboardModal id='uppyDashboard' uppy={this.uppy} width='40%' height='40%' closeModalOnClickOutside open={this.state.showUppy} onRequestClose={this.handleClose}
                         plugins={['ImageEditor']} ref={ uppyObj => this.uppyInstance = uppyObj } />
                    <DialogComponent id="defaultDialog" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                            ref={dialog => this.dialogInstance1 = dialog} isModal={true} height='80%' width='90%' minHeight='90%'>   
                            <div id='defaultDialogContent'></div>                         
                    </DialogComponent> 
                    <DialogComponent id="defaultDialog2" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                        ref={dialog => this.dialogInstance2 = dialog} header='<span style="color:#133B95;font-weight:bolder;font-size:1.3em;">Visualizzazione Georeferenza</span>' isModal={true} height='90%' minHeight='90%'
                        content=''>
                            <iframe id='mappaGoogle' src="" frameBorder="1" width='100%' height='99%' allowFullScreen></iframe>
                    </DialogComponent> 
                    <DialogComponent id="defaultDialog5" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                            ref={dialog => this.dialogInstance5 = dialog} isModal={true} enableResize={true} height='80%' width='90%' minHeight='90%'>   
                            <div id='defaultDialogContent2'></div>                         
                    </DialogComponent> 
                    <DialogComponent id="defaultDialog6" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                            ref={dialog => this.dialogInstance6 = dialog} isModal={false} enableResize={true} height='80%' minHeight='90%'>   
                            <div id='defaultDialogContent3'></div>                         
                    </DialogComponent> 
                    <DialogComponent id="defaultDialog7" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                            ref={dialog => this.dialogInstance7 = dialog} isModal={false} enableResize={true} height='80%' minHeight='90%'>   
                            <div id='defaultDialogContent4'></div>                         
                    </DialogComponent> 
                    <DialogComponent id="defaultDialog8" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                            ref={dialog => this.dialogInstance8 = dialog} isModal={false} enableResize={true} height='80%' minHeight='90%'>   
                            <div id='defaultDialogContent5'></div>                         
                    </DialogComponent> 
                    <DialogComponent id="defaultDialog9" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                            ref={dialog => this.dialogInstance9 = dialog} isModal={false} enableResize={true} height='80%' minHeight='90%'>   
                            <div id='defaultDialogContent6'></div>                         
                    </DialogComponent> 
                    <DialogComponent id="defaultDialog10" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                            ref={dialog => this.dialogInstance10 = dialog} isModal={false} enableResize={true} height='70%' minHeight='70%'>   
                            <div id='defaultDialogContent7'></div>                         
                    </DialogComponent>
                    <DialogComponent id="defaultDialog11" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1}
                        ref={dialog => this.dialogInstance11 = dialog} isModal={false} enableResize={true} height='90%' minHeight='90%'>
                        <div id='defaultDialogContent8'></div>
                    </DialogComponent>
                    <BlockUi id='blockUIPagina' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                        <ToastContainer enableMultiContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                        <div className='container-fluid'> 
                            <div className='row'>  
                                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>   
                                    <TabComponent id='TabSituazioneSinistri' cssClass='e-fill' headerPlacement='Top' width='98%' ref={tabComp => this.tabInstance = tabComp} selecting={this.tabSelecting.bind(this)} created={this.onCreatedTabSinistri.bind(this)} clearTemplates={false} 
                                            selected={this.tabSelected.bind(this)} animation={{ previous: { effect: 'FadeIn', duration: 500, easing: 'ease' }, next: { effect: 'FadeIn', duration: 500, easing: 'ease' } }} style={{ marginLeft: '40px', marginTop: '10px' }}>
                                        <TabItemsDirective>
                                            <TabItemDirective header={this.tabHeaderText[0]} content={this.tabContent0}/> 
                                        </TabItemsDirective>
                                    </TabComponent>  
                                </div>
                            </div>
                        </div>
                    </BlockUi>
                </React.Fragment>);
    }    
    rendereComplete() {
        document.getElementById('titoloPagina').innerText = 'Gestione Sinistri';
        setTimeout(() => { 
            if (CustomJS.isNullEmpty(sessionStorage.getItem('JellyfishTechnologyFiltroSituazioneSinistri')))
                sessionStorage.setItem('JellyfishTechnologyFiltroSituazioneSinistri', 'Recenti');
            if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti Semplici') > -1) {
                let oggettoFiltri = CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTechnologyFiltriAggiuntiviSinistri'))) ? {} : JSON.parse(sessionStorage.getItem('JellyfishTechnologyFiltriAggiuntiviSinistri'));
                oggettoFiltri.Perito = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].id_utente.toString();
                sessionStorage.setItem('JellyfishTechnologyFiltriAggiuntiviSinistri', JSON.stringify(oggettoFiltri));
                this.ddlInstance8.readonly = true;      
                this.gridInstance.toolbarModule.enableItems(['GridSituazioneSinistri_add'], false);          
            }
            if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Amministratori') === -1
                && JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('SysAdmin') === -1
                && JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Segreteria') === -1
                && JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].id_utente !== 21
                && JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].id_utente !== 50) {
                $('#divEstrazioniVarie').hide();
                if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti Semplici') > -1)
                    document.getElementById('divDDL3').style.display = 'none';
            }
            this.resettaFiltri();
            if (!CustomJS.isNullEmpty(this.ddlInstance1))
                this.ddlInstance1.value = sessionStorage.getItem('JellyfishTechnologyFiltroSituazioneSinistri');  
            else
               setTimeout(() => { this.ddlInstance1.value = sessionStorage.getItem('JellyfishTechnologyFiltroSituazioneSinistri'); }, 700);  
            if (!CustomJS.isNullEmpty(this.ddlInstance10))
                 this.ddlInstance10.value = null;
            if (!CustomJS.isNullEmpty(this.ddlInstance11))
                this.ddlInstance11.value = null;
            this.accordInstance.expandItem(false, 0);
            this.noSblocco = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti') > -1 
                             || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Team Leader') > -1
                             || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Referenti ACE') > -1;
            if (JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti Semplici') > -1 
                || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Team Leader') > -1
                || JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].id_utente === 3) {
                const QueryExec = "SELECT COUNT(*) AS NumeroSinistri, AVG([01002].v_stima_danno) AS MediaLiquidato, [00002].id_compagnia, [09001].username, [01001].pronta_definizione, [01002].id_tipo_indennizzo " +
                                  "FROM [00002] INNER JOIN [01001] ON [00002].id_compagnia = [01001].id_compagnia INNER JOIN [01002] ON [01001].id_sinistro = [01002].id_sinistro " +
                                  "INNER JOIN [09001] ON [01002].id_utente = [09001].id_utente WHERE ([01002].chiusa = 1) AND ([01002].revocata = 0) AND (YEAR([01001].t_incarico) = YEAR(GETDATE())) " +
                                  "AND (YEAR([01001].t_chiusura) = YEAR(GETDATE())) AND [01001].id_stato IN (3,49) AND [01002].id_tipo_indennizzo IN (17, 9, 8, 5, 4, 11, 10, 3, 15, 20, 21, 14, 22, 6) " +
                                  "GROUP BY [00002].id_compagnia, [09001].username, [01001].pronta_definizione, [01002].id_tipo_indennizzo; SELECT COUNT(*) AS ChiusureTelematiche, [00002].id_compagnia, " +
                                  "[09001].username FROM [01001] INNER JOIN [01002] ON [01001].id_sinistro = [01002].id_sinistro INNER JOIN [09001] ON [01002].id_utente = [09001].id_utente " +
                                  "INNER JOIN [00002] ON [01001].id_compagnia = [00002].id_compagnia WHERE [01001].chiusura_telematica = 1 AND (YEAR([01001].t_chiusura) = YEAR(GETDATE())) GROUP BY [00002].id_compagnia, [09001].username";
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                    let gridData = [];
                    let salvaCompagnia = 0, salvaPerito = '', contaSinistriChiusiTot = 0, contaSinistriChiusiTotPD = 0, contaChiusiNegativiPD = 0, pesoTotaleLiquidatoChiusiPD = 0, pesoTotaleLiquidatoChiusiPDUnipolSAI = 0, contaSinistriChiusiTotPDUnipolSAI = 0;
                    //console.log(retData);
                    retData[0].forEach(elm => {
                        if (!CustomJS.isNullEmpty(salvaCompagnia) && !CustomJS.isNullEmpty(salvaPerito) && (salvaCompagnia !== elm.id_compagnia || salvaPerito !== elm.username)) {
                            gridData.push({ 
                                IdCompagnia: salvaCompagnia,
                                Compagnia: _.find(this.dsGruppoFiltro, elm => { return elm.VALUE === salvaCompagnia.toString() })?.TEXT, 
                                Utente: salvaPerito, 
                                TotaleChiusi: parseInt(contaSinistriChiusiTot ?? 0).toLocaleString(),
                                TotaleChiusiPD: parseInt(contaSinistriChiusiTotPD ?? 0).toLocaleString(),
                                PercChiusiPD: `${parseFloat(parseFloat(contaSinistriChiusiTotPD / contaSinistriChiusiTot * 100).toFixed(1)).toLocaleString()}%`,
                                LiquidatoMedioChiusiPD: ((salvaCompagnia !== 3 && contaSinistriChiusiTotPD === 0) || (salvaCompagnia === 3 && contaSinistriChiusiTotPDUnipolSAI === 0)) ? '0' : salvaCompagnia === 3 ? `€  ${parseFloat(parseFloat(pesoTotaleLiquidatoChiusiPDUnipolSAI / contaSinistriChiusiTotPDUnipolSAI).toFixed(2)).toLocaleString()}` : `€  ${parseFloat(parseFloat(pesoTotaleLiquidatoChiusiPD / contaSinistriChiusiTotPD).toFixed(2)).toLocaleString()}`,
                                ChiusiNegativiPD: parseInt(contaChiusiNegativiPD ?? 0).toLocaleString(),
                                PercChiusiNegativiPD: `${contaSinistriChiusiTotPD === 0 ? '0' : parseFloat(parseFloat(contaChiusiNegativiPD / contaSinistriChiusiTotPD * 100).toFixed(1)).toLocaleString()}%`,
                                ChiusureTelematiche: _.find(retData[1], elm => { return elm.id_compagnia === salvaCompagnia && elm.username === salvaPerito })?.ChiusureTelematiche ?? 0
                            });  
                            contaSinistriChiusiTot = 0; contaSinistriChiusiTotPD = 0; contaChiusiNegativiPD = 0; pesoTotaleLiquidatoChiusiPD = 0; pesoTotaleLiquidatoChiusiPDUnipolSAI = 0; contaSinistriChiusiTotPDUnipolSAI = 0;
                        }
                        salvaCompagnia = elm.id_compagnia; salvaPerito = elm.username;
                        contaSinistriChiusiTot += elm.NumeroSinistri;
                        contaSinistriChiusiTotPD += elm.pronta_definizione === true ? elm.NumeroSinistri : 0;
                        contaChiusiNegativiPD += (elm.pronta_definizione === true && (elm.id_tipo_indennizzo === 3 || elm.id_tipo_indennizzo === 15 || elm.id_tipo_indennizzo === 20
                                            || elm.id_tipo_indennizzo === 21 || elm.id_tipo_indennizzo === 14 || elm.id_tipo_indennizzo === 22 || elm.id_tipo_indennizzo === 6)) ? elm.NumeroSinistri : 0;
                        pesoTotaleLiquidatoChiusiPD += elm.pronta_definizione === true ? (elm.MediaLiquidato * elm.NumeroSinistri) : 0;
                        pesoTotaleLiquidatoChiusiPDUnipolSAI += (elm.pronta_definizione === true && elm.id_compagnia === 3 && elm.MediaLiquidato >= 251 && elm.MediaLiquidato <= 5000) ? (elm.MediaLiquidato * elm.NumeroSinistri) : 0;
                        contaSinistriChiusiTotPDUnipolSAI += (elm.pronta_definizione === true && elm.id_compagnia === 3 && elm.MediaLiquidato >= 251 && elm.MediaLiquidato <= 5000) ? elm.NumeroSinistri : 0;
                    });   
                    gridData.push({ 
                        IdCompagnia: salvaCompagnia,
                        Compagnia: _.find(this.dsGruppoFiltro, elm => { return elm.VALUE === salvaCompagnia.toString() })?.TEXT, 
                        Utente: salvaPerito, 
                        TotaleChiusi: parseInt(contaSinistriChiusiTot ?? 0).toLocaleString(),
                        TotaleChiusiPD: parseInt(contaSinistriChiusiTotPD ?? 0).toLocaleString(),
                        PercChiusiPD: parseFloat(parseFloat(contaSinistriChiusiTotPD / contaSinistriChiusiTot * 100).toFixed(1)).toLocaleString(),
                        LiquidatoMedioChiusiPD: contaSinistriChiusiTotPD === 0 ? '0' : `€  ${parseFloat(parseFloat(pesoTotaleLiquidatoChiusiPD / contaSinistriChiusiTotPD).toFixed(2)).toLocaleString()}`,
                        ChiusiNegativiPD: parseInt(contaChiusiNegativiPD ?? 0).toLocaleString(),
                        PercChiusiNegativiPD: contaSinistriChiusiTotPD === 0 ? '0' : parseFloat(parseFloat(contaChiusiNegativiPD / contaSinistriChiusiTotPD * 100).toFixed(1)).toLocaleString(),
                        ChiusureTelematiche: _.find(retData[1], elm => { return elm.id_compagnia === salvaCompagnia && elm.username === salvaPerito })?.ChiusureTelematiche ?? 0
                    });  
                    //console.log(gridData);  
                    gridData = gridData.filter(elm => { return elm.Utente === JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username });  
                    this.setState({ GridData3: gridData });
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );
                }, true, true);
            }
        }, 300);
        this.refreshStateInterval = setInterval(() => {
            this.gridInstance.currentViewData.filter(elm => { return !CustomJS.isNullEmpty(elm.IdIncaricoUNI) }).forEach(elm => { 
                const QueryExec = `SELECT TOP (1) [10002].esito_evento AS Esito_Ultimo_Evento_UNI FROM [10001] LEFT OUTER JOIN [10002] ON [10001].id_incarico = [10002].id_incarico WHERE [10001].id_interno_sinistro = '${elm.id_interno_sinistro}' ORDER BY [10002].data_evento DESC; SELECT [10002].tipo_evento_riferimento AS EventoAccettazioneUNI FROM [10001] INNER JOIN [10002] ON [10001].id_incarico = [10002].id_incarico WHERE [10001].id_interno_sinistro = '${elm.id_interno_sinistro}' AND (([10002].tipo_evento_riferimento = 'S_ACC' AND [10002].esito_evento = 'OK') OR ([10002].tipo_evento_riferimento = 'C_INC' AND [10002].esito_evento = 'KO'));`;        
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                    //console.log($($(`#GridSituazioneSinistri tr td:nth-child(2) span:contains("${elm.id_interno_sinistro}")`).closest('tr').find('button')[4]));
                    if (retData[0][0]?.Esito_Ultimo_Evento_UNI?.trim() === 'OK' )
                        $($(`#GridSituazioneSinistri tr td:nth-child(2) span:contains("${elm.id_interno_sinistro}")`).closest('tr').find('button')[4]).css('background', 'lawngreen');
                    else if (retData[0][0]?.Esito_Ultimo_Evento_UNI?.trim() === 'KO')
                        $($(`#GridSituazioneSinistri tr td:nth-child(2) span:contains("${elm.id_interno_sinistro}")`).closest('tr').find('button')[4]).css('background', 'orangered'); 
                    if (CustomJS.isNullEmpty(retData[1][0]?.EventoAccettazioneUNI?.trim()) && elm.id_stato === 29 && JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Periti') === -1 
                        && JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Team Leader') === -1
                        && JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].descrizione.indexOf('Referenti ACE') === -1) {
                        // && new Date(elm.data_ora_importazione) < new Date().setMinutes(new Date().getMinutes() - 60)) {  SOLO SE ACCETTAZIONE INVIATA DA IMPORTAZIONE
                        $($(`#GridSituazioneSinistri tr td:nth-child(2) span:contains("${elm.id_interno_sinistro}")`).closest('tr').find('button')[5]).show();
                        $($(`#GridSituazioneSinistri tr td:nth-child(2) span:contains("${elm.id_interno_sinistro}")`).closest('tr').find('button')[5]).css('background', 'gold');
                    } 
                    else
                        $($(`#GridSituazioneSinistri tr td:nth-child(2) span:contains("${elm.id_interno_sinistro}")`).closest('tr').find('button')[5]).hide();       
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,           
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    );
                }, true, true);     
            });
        }, 60*1000)
    }
    componentWillUnmount() {        
        $('#divBtnFinePag').remove();
        $('#divBtnVediNotif').remove();
        $('#divBtnCreaDocumPerizia').remove();
        this.refreshStateInterval = null;
    }
    caricaDati(oggettoFiltri, ordinaPerRitardo, stringaDaRicerca) {
        const FiltroSinistri = sessionStorage.getItem('JellyfishTechnologyFiltroSituazioneSinistri');
        stringaDaRicerca = CustomJS.sanitizeInput(stringaDaRicerca);
        //TODO APPENA POSSIBILE CAPIRE PER QUALE MOTIVO CON COMBINAZIONE DI ALCUNI FILTRI (DI SOLITO 3 DATE OPPURE DATE + ALTRI) NON RIESCE A CHIAMARE LA API 
        //TODO (IL PROBLEMA E' A LIVELLO DI IIS CHE HA QUALCHE LIMITAZIONE SULLA URL REWRITE VERSO API CALLER)
        let FiltroAggiuntivo = CustomJS.isNullEmpty(oggettoFiltri) ? 'NoFiltroAgg' : this.componiFiltroAgg(oggettoFiltri);  
        if (!CustomJS.isNullEmpty(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].compagnie)) {
            if (FiltroAggiuntivo === 'NoFiltroAgg')
                FiltroAggiuntivo = FiltroAggiuntivo.replace('NoFiltroAgg','').concat(`id_compagnia IN (${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].compagnie})`);
            else
                FiltroAggiuntivo = FiltroAggiuntivo.concat(`AND id_compagnia IN (${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].compagnie})`);
        }
        FiltroAggiuntivo = FiltroAggiuntivo === 'NoFiltroAgg' ? CustomJS.isNullEmpty(stringaDaRicerca) ? FiltroAggiuntivo.replace('NoFiltroAgg','').concat('id_stato NOT IN (3, 23, 24, 51, 52)') : CustomJS.compressString(FiltroAggiuntivo.replace('NoFiltroAgg','').concat(`id_interno_sinistro LIKE '%${stringaDaRicerca}%' OR numero_sinistro_agenzia LIKE '%${stringaDaRicerca}%' OR danneggiato LIKE '%${stringaDaRicerca}%' OR contr_nominativo LIKE '%${stringaDaRicerca}%' OR ass_nominativo LIKE '%${stringaDaRicerca}%' OR numero_polizza LIKE '%${stringaDaRicerca}%'`)) : FiltroAggiuntivo;
        this.toggleBlocking();
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/situazioneSinistri/${FiltroSinistri}?FiltroAggiuntivo=${encodeURIComponent(FiltroAggiuntivo)}`, '', 'GET',              
        '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking();
                return;
            }
            retData = _.uniq(retData, elm => { return elm.id_interno_sinistro });
            retData.forEach(elm => {
                elm.data_timer = CustomJS.isNullEmpty(elm.data_timer) ? '' : moment(elm.data_timer).format('DD/MM/YYYY');
                elm.strDataIncarico = CustomJS.isNullEmpty(elm.strDataIncarico) ? '' : moment(elm.strDataIncarico).format('DD/MM/YYYY');
                elm.data_invio_atto = CustomJS.isNullEmpty(elm.data_invio_atto) ? '' : moment(elm.data_invio_atto).format('DD/MM/YYYY');
                elm.data_chiusura_sinistro = CustomJS.isNullEmpty(elm.data_chiusura_sinistro) ? '' : moment(elm.data_chiusura_sinistro).format('DD/MM/YYYY');
                elm.conta_Apertura = (elm.id_stato === 23 || elm.id_stato === 24 || elm.id_stato === 51 || elm.id_stato === 52) ? null : elm.conta_Apertura;
            }); 
            if (ordinaPerRitardo === 'OrdinaRitardoCrescentePerito')
                retData = _.sortBy(retData, 'ritardo_perito');
            else if (ordinaPerRitardo === 'OrdinaRitardoDecrescentePerito')
                retData = _.sortBy(retData, 'ritardo_perito').reverse();
            else if (ordinaPerRitardo === 'OrdinaRitardoCrescenteCQ')
                retData = _.sortBy(retData, 'ritardo_cq');
            else if (ordinaPerRitardo === 'OrdinaRitardoDecrescenteCQ')
                retData = _.sortBy(retData, 'ritardo_cq').reverse();
            else if (ordinaPerRitardo === 'OrdinaDataSopralluogoDecrescente')
                retData = _.sortBy(retData, 't_sopralluogo').reverse();
            else if (ordinaPerRitardo === 'OrdinaDataSopralluogoCrescente')
                retData = _.sortBy(retData, 't_sopralluogo');

            const QueryExec = "SELECT * FROM [lstCATPeritiCQSinistri] WHERE ISNULL(RagioneSocialeCAT,'') <> '' OR ISNULL(NominativoPerito,'') <> '' OR ISNULL(NominativoCQ, '') <> ''";        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                let gridData2 = [];
                retData.forEach(elm => { 
                    gridData2.push({ 
                        id_sinistro: elm.IdSinistro, 
                        CAT: elm.RagioneSocialeCAT, 
                        DataChiusuraCAT: CustomJS.isNullEmpty(elm.DataChiusuraCAT) ? '' : moment(elm.DataChiusuraCAT).format('DD/MM/YYYY'), 
                        RitardoCAT: elm.RitardoCAT,
                        Perito: elm.NominativoPerito,
                        DataChiusuraPerito: CustomJS.isNullEmpty(elm.DataChiusuraPerizia) ? '' : moment(elm.DataChiusuraPerizia).format('DD/MM/YYYY'),
                        RitardoPerito: elm.RitardoPerito,
                        CQ: elm.NominativoCQ,
                        DataCQ: CustomJS.isNullEmpty(elm.dataCQ) ? '' : moment(elm.dataCQ).format('DD/MM/YYYY'),
                        RitardoCQ: elm.RitardoCQ,
                    });  
                });
                this.gridInstance.childGrid.dataSource = gridData2;  
                this.gridInstance.refresh();
                this.toggleBlocking();
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            }, true, true);              
            this.gridInstance.dataSource = retData; 
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            this.toggleBlocking();
        }, true, false);
    }
    caricaDatiFiltrati() {
        let oggettoFiltri = CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTechnologyFiltriAggiuntiviSinistri'))) ? {} : JSON.parse(sessionStorage.getItem('JellyfishTechnologyFiltriAggiuntiviSinistri'));
        oggettoFiltri.Stato = this.ddlInstance2.value;
        oggettoFiltri.Gruppo = this.ddlInstance3.value;
        oggettoFiltri.CompDiv = this.ddlInstance4.value;
        oggettoFiltri.Agenzia = this.ddlInstance5.value;
        oggettoFiltri.Polizza = this.ddlInstance6.value;
        oggettoFiltri.GradoSinistro = this.ddlInstance13.value;
        oggettoFiltri.DataRemindDa = CustomJS.isNullEmpty(this.dateRangeInstance6.startDate) ? null : moment(this.dateRangeInstance6.startDate).format('YYYY/MM/DD');
        oggettoFiltri.DataRemindA = CustomJS.isNullEmpty(this.dateRangeInstance6.endDate) ? null : moment(this.dateRangeInstance6.endDate).format('YYYY/MM/DD');
        oggettoFiltri.DataIncaricoDa = CustomJS.isNullEmpty(this.dateRangeInstance1.startDate) ? null : moment(this.dateRangeInstance1.startDate).format('YYYY/MM/DD');
        oggettoFiltri.DataIncaricoA = CustomJS.isNullEmpty(this.dateRangeInstance1.endDate) ? null : moment(this.dateRangeInstance1.endDate).format('YYYY/MM/DD');;
        oggettoFiltri.DataInvioAttoDa = CustomJS.isNullEmpty(this.dateRangeInstance7.startDate) ? null : moment(this.dateRangeInstance7.startDate).format('YYYY/MM/DD');
        oggettoFiltri.DataInvioAttoA = CustomJS.isNullEmpty(this.dateRangeInstance7.endDate) ? null : moment(this.dateRangeInstance7.endDate).format('YYYY/MM/DD');
        oggettoFiltri.DataChiusuraDa = CustomJS.isNullEmpty(this.dateRangeInstance2.startDate) ? null : moment(this.dateRangeInstance2.startDate).format('YYYY/MM/DD');
        oggettoFiltri.DataChiusuraA = CustomJS.isNullEmpty(this.dateRangeInstance2.endDate) ? null : moment(this.dateRangeInstance2.endDate).format('YYYY/MM/DD');        
        oggettoFiltri.CAT = this.ddlInstance7.value;
        oggettoFiltri.DataChiusuraCATDa = CustomJS.isNullEmpty(this.dateRangeInstance3.startDate) ? null : moment(this.dateRangeInstance3.startDate).format('YYYY/MM/DD');
        oggettoFiltri.DataChiusuraCATA = CustomJS.isNullEmpty(this.dateRangeInstance3.endDate) ? null : moment(this.dateRangeInstance3.endDate).format('YYYY/MM/DD');
        oggettoFiltri.Perito = this.ddlInstance8.value;
        oggettoFiltri.DataChiusuraPeritoDa = CustomJS.isNullEmpty(this.dateRangeInstance4.startDate) ? null : moment(this.dateRangeInstance4.startDate).format('YYYY/MM/DD');
        oggettoFiltri.DataChiusuraPeritoA = CustomJS.isNullEmpty(this.dateRangeInstance4.endDate) ? null : moment(this.dateRangeInstance4.endDate).format('YYYY/MM/DD');
        oggettoFiltri.CQ = this.ddlInstance9.value;
        oggettoFiltri.DataControlloCQDa = CustomJS.isNullEmpty(this.dateRangeInstance5.startDate) ? null : moment(this.dateRangeInstance5.startDate).format('YYYY/MM/DD');
        oggettoFiltri.DataControlloCQA = CustomJS.isNullEmpty(this.dateRangeInstance5.endDate) ? null : moment(this.dateRangeInstance5.endDate).format('YYYY/MM/DD');
        oggettoFiltri.Videoperizia = this.chkInstance1.checked;
        oggettoFiltri.Complessi = this.chkInstance2.checked;
        oggettoFiltri.Documentale = this.chkInstance2.checked;
        sessionStorage.setItem('JellyfishTechnologyFiltriAggiuntiviSinistri', JSON.stringify(oggettoFiltri));
        this.arrivoDaFiltriAgg = true;
        this.ddlInstance10.value = null;
        this.ddlInstance11.value = null;
        this.caricaDati(oggettoFiltri, null, $('#GridSituazioneSinistri_searchbar').val()?.trim());
        this.accordInstance.expandItem(false, 0);
    }
    caricaFotoGEOUnipolSAI() {
        this.fotoGEOUnipolSAI = true;
        this.uppy.setOptions({
            restrictions: {
                allowedFileTypes: ['.jpg', '.jpeg']
            }
        });
        this.uppy.getPlugin('XHRUpload').setOptions({ endpoint: `${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumenti/JFTECH/${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username}/${this.tabInstance.items[this.tabInstance.selectedItem].id}/FOTOGEOUNIPOLSAI` })
        this.setState({
            showUppy: true
        });
    }
    caricaFotoRischioGENERALI() {
        this.fotoRischioGENERALI = true;
        this.uppy.setOptions({
            restrictions: {
                maxNumberOfFiles: 1,
                allowedFileTypes: ['.jpg', '.jpeg', '.png']
            }
        });
        this.uppy.getPlugin('XHRUpload').setOptions({ endpoint: `${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumenti/JFTECH/${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username}/${this.tabInstance.items[this.tabInstance.selectedItem].id}/FOTORISCHIOGENERALI` })
        this.setState({
            showUppy: true
        });
    }
    visualizzaFotoRischioGENERALI() {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (!CustomJS.isNullEmpty(document.getElementById(`fotoRischioBase64${idSin}`).value)) {
            this.lightboxImage = document.getElementById(`fotoRischioBase64${idSin}`).value;
            this.setState({showLightbox: true});  
        }
    }
    cancellaFotoRischioGENERALI() {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        let dataObj = {
            IdSinistro: document.getElementById(`idSinistro${idSin}`).value,
        };
        const QueryExec = 'UPDATE [12003] SET polizza_fotografiaRischioAssicurato = NULL WHERE id_sinistro = @id_sinistro';
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST',
                QueryExec, JSON.stringify(dataObj), retData => {
                    document.getElementById(`fotoRischioBase64${idSin}`).value = '';
                    document.getElementById(`btnVisualizzaFotoRischioGENERALI${idSin}`).style.display = 'none';
                    document.getElementById(`btnCancellaFotoRischioGENERALI${idSin}`).style.display = 'none';
                    toast.success('Foto del rischio assicurato rimossa dall\'incarico con successo!!', {
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    getDatiUnipolSAI(idSin) {
        const QueryExec = `SELECT [10003].*, [10004].* FROM [10003] LEFT OUTER JOIN [10004] ON [10003].id_sinistro = [10004].id_sinistro WHERE [10003].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            if (retData.length > 0) {
                document.getElementById(`esitoUnipol${idSin}`).ej2_instances[0].value = retData[0].codiceEsitoPerizia?.trim();   
                document.getElementById(`dataFirmaAttoUnipol${idSin}`).ej2_instances[0].value = moment(retData[0].dataSottoscrizioneAtto).format('DD/MM/YYYY');  
                document.getElementById(`mask_dataFirmaAttoUnipol${idSin}`).ej2_instances[0].value = moment(retData[0].dataSottoscrizioneAtto).format('DD/MM/YYYY');     
                document.getElementById(`dataOraVerificataSinistroUnipol${idSin}`).ej2_instances[0].value = retData[0].dataOraVerificataSinistro ?? document.getElementById(`dataSinistro${idSin}`).ej2_instances[0].value; 
                this.onChangeDataVerifSinistroUnipol({ value: document.getElementById(`dataOraVerificataSinistroUnipol${idSin}`).ej2_instances[0].value });  
                document.getElementById(`coerenzaDataSinistroUnipol${idSin}`).ej2_instances[0].checked = (retData[0].coerenzaDataSinistro ?? true) === true;   
                this.onChangeCoerenzaDataSinistroUnipol({ checked: document.getElementById(`coerenzaDataSinistroUnipol${idSin}`).ej2_instances[0].checked });
                document.getElementById(`riparazioneEffettuataUnipol${idSin}`).ej2_instances[0].checked = retData[0].riparazioneEffettuata === true;   
                document.getElementById(`riparazioneTecnicheDistruttiveUnipol${idSin}`).ej2_instances[0].checked = retData[0].riparazioneConTecnicheDistruttive === true; 
                document.getElementById(`importoImponibileRiparazioneUnipol${idSin}`).ej2_instances[0].value = retData[0].importoImponibileRiparazione; 
                document.getElementById(`importoIVARiparazioneUnipol${idSin}`).ej2_instances[0].value = retData[0].importoIvaRiparazione; 
                document.getElementById(`percIVARiparazioneUnipol${idSin}`).ej2_instances[0].value = retData[0].percentualeIvaRiparazione;   
                document.getElementById(`utilitaGFKUnipol${idSin}`).ej2_instances[0].checked = retData[0].utilitaGfk === true; 
                document.getElementById(`tipoBeneGFKUnipol${idSin}`).ej2_instances[0].value = retData[0].codiceTipoBeneGfk?.trim();   
                document.getElementById(`tipoBeneGFKNonPresenteUnipol${idSin}`).ej2_instances[0].value = retData[0].codiceTipoBeneGfkNonPresente?.trim(); 
                document.getElementById(`richiestaQuotazioneGFKUnipol${idSin}`).ej2_instances[0].checked = retData[0].richiestaQuotazioneGfk === true; 
                document.getElementById(`consultazioneLorentzUnipol${idSin}`).ej2_instances[0].checked = retData[0].consultazioneLorentz === true; 
                document.getElementById(`utilitaLorentzUnipol${idSin}`).ej2_instances[0].checked = retData[0].utilitaLorentz === true; 
                document.getElementById(`tipoDefinizioneUnipol${idSin}`).ej2_instances[0].value = retData[0].codiceTipoDefinizione?.trim(); 
                document.getElementById(`importoFranchigiaUnipol${idSin}`).ej2_instances[0].value = retData[0].importoFranchigia; 
                document.getElementById(`tipoSinistroCorrettoUnipol${idSin}`).ej2_instances[0].checked = (retData[0].tipoSinistroCorretto ?? true) === true; 
                this.onChangeTipoSinistroCorrettoUnipol({ checked: document.getElementById(`tipoSinistroCorrettoUnipol${idSin}`).ej2_instances[0].checked });
                document.getElementById(`mancataDefinizioneUnipol${idSin}`).ej2_instances[0].value = retData[0].codiceMotivoMancataDefinizione?.trim(); 
                document.getElementById(`noteMotivoMancataDefinizioneUnipol${idSin}`).ej2_instances[0].value = retData[0].noteMotivoMancataDefinizione; 
                document.getElementById(`motivazioneNegativaUnipol${idSin}`).ej2_instances[0].value = retData[0].codiceMotivoNegativa?.trim(); 
                document.getElementById(`noteMotivoNegativaUnipol${idSin}`).ej2_instances[0].value = retData[0].noteNegativa; 
                document.getElementById(`noteGaranziaRamoCorrettoUnipol${idSin}`).ej2_instances[0].value = retData[0].noteGaranziaRamoCorretto; 
                document.getElementById(`dannoDaEventoNaturaleUnipol${idSin}`).ej2_instances[0].checked = retData[0].dannoDaEventoNaturale === true;
                document.getElementById(`presenzaContestazioniUnipol${idSin}`).ej2_instances[0].checked = retData[0].presenzaContestazioni === true;
                document.getElementById(`notePresenzaContestazioniUnipol${idSin}`).ej2_instances[0].value = retData[0].notePresenzaContestazioni; 
                document.getElementById(`mantenimentoPolizzaUnipol${idSin}`).ej2_instances[0].checked = retData[0].mantenimentoPolizza === true;
                document.getElementById(`noteMantenimentoPolizzaUnipol${idSin}`).ej2_instances[0].value = retData[0].noteMantenimentoPolizza; 
                document.getElementById(`adempimentoPolizzaUnipol${idSin}`).ej2_instances[0].checked = retData[0].adempimentoPolizza === true;
                document.getElementById(`noteAdempimentoPolizzaUnipol${idSin}`).ej2_instances[0].value = retData[0].noteAdempimentoPolizza; 
                document.getElementById(`noteArticoloPolizzaImpattatoUnipol${idSin}`).ej2_instances[0].value = retData[0].noteArticoloPolizzaImpattato; 
                document.getElementById(`importoConcordatoUnipol${idSin}`).ej2_instances[0].value = retData[0].importoConcordato; 
                document.getElementById(`importoLiquidabileUnipol${idSin}`).ej2_instances[0].value = retData[0].importoLiquidabile; 
                if (document.getElementById(`importoLiquidabileUnipol${idSin}`).ej2_instances[0].value > 0)
                    document.getElementById(`importoLiquidabileUnipol${idSin}`).ej2_instances[0].enabled = false;
                if (document.getElementById(`importoConcordatoUnipol${idSin}`).ej2_instances[0].value > 0)
                    document.getElementById(`importoConcordatoUnipol${idSin}`).ej2_instances[0].enabled = false;
                document.getElementById(`tipoBeneGFKNonPresenteUnipol${idSin}`).ej2_instances[0].enabled = retData[0].utilitaGfk === false;
                document.getElementById(`tipoBeneGFKUnipol${idSin}`).ej2_instances[0].enabled = retData[0].utilitaGfk === true; 
                document.getElementById(`tipoBeneGFKUnipol${idSin}`).ej2_instances[0].enabled = retData[0].utilitaGfk === true;  
                document.getElementById(`utilitaLorentzUnipol${idSin}`).ej2_instances[0].disabled = retData[0].consultazioneLorentz === false; 
                document.getElementById(`datiUnipolSAI${idSin}`).style.removeProperty('display');
            }                    
            else
                document.getElementById(`datiUnipolSAI${idSin}`).style.display = 'none';
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }, true, true);
    }
    getInfoRichieste() {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        if (document.getElementById(`divInfoRichieste${idSin}`))
            $(`divInfoRichieste${idSin}`).remove();
        const QueryExec = `SELECT ripristini_ultimati, coassicurazione_indiretta_presente FROM [12011] WHERE id_incarico = '${document.getElementById(`idIncarico${idSin}`).value}'`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            const falseElements = retData.filter(elm => elm.ripristini_ultimati === false);
            const trueElementsCoass = retData.filter(elm => elm.coassicurazione_indiretta_presente === true);
            const QueryExec2 = `SELECT * FROM [12009] WHERE id_incarico = '${document.getElementById(`idIncarico${idSin}`).value}'`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec2, '', retData1 => {
                const QueryExec3 = `SELECT * FROM [12010] WHERE id_incarico = '${document.getElementById(`idIncarico${idSin}`).value}'`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec3, '', retData2 => {
                    retData1.forEach((elm, i) => {
                        let valueCheckSi;
                        let valueCheckNo;
                        let note = '';
                        let valueDisabled;
                        let placeHolder;
                        if (elm.codice.trim() === '3') {
                            valueCheckSi = falseElements.length > 0 ? false : true;
                            valueCheckNo = falseElements.length > 0 ? true : false;
                            valueDisabled = falseElements.length > 0 ? true : false;
                            if (falseElements.length > 0)
                                note = 'Si veda perizia';                           
                            else
                                note = elm.nota;
                        }
                        else if (elm.codice.trim() === '7') {
                            valueCheckSi = trueElementsCoass.length > 0 ? true : false;
                            valueCheckNo = trueElementsCoass.length > 0 ? false : true;
                            valueDisabled = trueElementsCoass.length > 0 ? true : false;
                            if (trueElementsCoass.length > 0) 
                                note = 'Si veda in reparto in stima';
                            else
                                note = elm.nota;                            
                        }
                        else if (elm.codice.trim() === '4') {
                            valueCheckSi = retData2.length > 0 ? true : false;
                            valueCheckNo = retData2.length > 0 ? false : true;
                            valueDisabled = true;
                            if (retData2.length > 0) {
                                retData2.forEach((elm2, i) => {
                                    if (i === retData2.length - 1) {
                                        note += elm2.numero_sinistro;
                                    } else {
                                        note += elm2.numero_sinistro + ' - ';
                                    }
                                });
                            }
                            else
                                note = elm.nota;
                        }
                        else {
                            valueCheckSi = elm.risposta === true ? true : false;
                            valueCheckNo = elm.risposta === false ? true : false;
                            valueDisabled = false;
                            note = elm.nota;
                        }
                        placeHolder = `Nota ${elm.nota_obbligatoriaSuSi === true ? '(obbligatoria per valore SI)' : elm.nota_obbligatoriaSuNo === true ? '(obbligatoria per valore NO)' : '(non obbligatoria)'}`;
                        let divElm = document.createElement("div");
                        divElm.setAttribute("id", `divInfo${idSin}_${i}`);
                        document.getElementById(`divInfoRichieste${idSin}`).appendChild(divElm);
                        const container = document.getElementById(`divInfo${idSin}_${i}`);
                        const root = createRoot(container);
                        root.render(<CallbackWrapper callback={() => {
                            this.aggiornaDettaglio('risposta', $(`input[name='rispostaInfoRich${idSin}_${i}']:checked`).val() === 'SI' ? 1 : 0, 'INT', '[12009]', false, document.getElementById(`keyInfoRich${idSin}_${i}`).value);
                            this.aggiornaDettaglio('nota', document.getElementById(`nota${idSin}_${i}`).ej2_instances[0].value, 'CHAR', '[12009]', false, document.getElementById(`keyInfoRich${idSin}_${i}`).value);
                            this.aggiornaDettaglio('non_pertinente', document.getElementById(`checkPertinenza${idSin}_${i}`).ej2_instances[0].checked ? 1 : 0, 'INT', '[12009]', false, document.getElementById(`keyInfoRich${idSin}_${i}`).value);
                        }}><div id={`divInfoRichieste${idSin}_${i}`}>
                            <div className='row'>
                                <input type="hidden" id={`keyInfoRich${idSin}_${i}`} value={elm.id_info_richiesta}/>
                                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                    <TextBoxComponent id={`domanda${idSin}_${i}`} floatLabelType='Auto' placeholder='Domanda' cssClass='e-outline' value={elm.descrizione} readOnly={true}></TextBoxComponent>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <RadioButtonComponent id={`checkSi${idSin}_${i}`} label="Si" name={`rispostaInfoRich${idSin}_${i}`} checked={valueCheckSi} onChange={this.onChangeCheckInfoRisp.bind(this)} value='SI' disabled={valueDisabled} />
                                        <RadioButtonComponent id={`checkNo${idSin}_${i}`} label="No" name={`rispostaInfoRich${idSin}_${i}`} checked={valueCheckNo} onChange={this.onChangeCheckInfoRisp.bind(this)} value='NO' disabled={valueDisabled} />
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                    <TextBoxComponent id={`nota${idSin}_${i}`} floatLabelType='Auto' placeholder={placeHolder} cssClass='e-outline' value={note} onBlur={this.onChangeNotaInfoRich.bind(this)} showClearButton={true} disabled={false}></TextBoxComponent>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                    <CheckBoxComponent id={`checkPertinenza${idSin}_${i}`} label='Non Pertinente' labelPosition='Before' checked={elm.non_pertinente} onChange={this.onChangeCheckInfoNonPert.bind(this)} disabled={false}></CheckBoxComponent>
                                </div>
                            </div>
                        </div></CallbackWrapper>);
                    });
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    onChangeCheckInfoRisp(args) {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const idx = args.target.id.substring(args.target.id.indexOf('_')+1);        
        this.aggiornaDettaglio('risposta', $(`input[name='rispostaInfoRich${idSin}_${idx}']:checked`).val() === 'SI' ? 1 : 0, 'INT', '[12009]', false, document.getElementById(`keyInfoRich${idSin}_${idx}`).value);
    }
    onChangeNotaInfoRich(args) {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const idx = args.target.id.substring(args.target.id.indexOf('_')+1);        
        this.aggiornaDettaglio('nota', args.target.value, 'CHAR', '[12009]', false, document.getElementById(`keyInfoRich${idSin}_${idx}`).value);
    }
    onChangeCheckInfoNonPert(args) {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const idx = args.target.id.substring(args.target.id.indexOf('_')+1);        
        this.aggiornaDettaglio('non_pertinente', document.getElementById(`checkPertinenza${idSin}_${idx}`).ej2_instances[0].checked  ? 1 : 0, 'INT', '[12009]', false, document.getElementById(`keyInfoRich${idSin}_${idx}`).value);
    }
    controlloDanneggiati() {
        var jsonObject = {
            QueryExec: `INSERT INTO GENERALI_danneggiati (id_danneggiato, id_danneggiato_xpert, id_incarico, nominativo, telefono, email, indirizzo_viaPiazza, indirizzo_CAP, indirizzo_denominazione, indirizzo_provincia, indirizzo_nazione, importato) SELECT UUID(), T2.id_danneggiato, T3.id_incarico, CASE WHEN IFNULL(T2.danneggiato,'') != '' THEN T2.danneggiato ELSE CONCAT(T2.cognome, ' ', T2.nome) END, T2.tel, T2.posta, T2.indir, T2.cap, T2.citta, T2.prov, 'ITA', 0 FROM acdanneggiati T2 INNER JOIN GENERALI_Incarico T3 ON T2.id_sinistro = T3.id_sinistro_xpert WHERE NOT EXISTS (SELECT * FROM GENERALI_danneggiati T4 WHERE T4.id_incarico = T3.id_incarico AND T4.id_danneggiato_xpert = T2.id_danneggiato) AND T2.id_sinistro = ${this.props.IdSinistro}; SELECT ROW_COUNT();`,
            CodiceRitorno: 'true',
        }
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', '', JSON.stringify(jsonObject), retData => {
            if (retData.response === 'OK') {
                for (let ind = 1; ind <= parseInt(retData.data); ind++) {
                    var jsonObject2 = {
                        QueryExec: `UPDATE GENERALI_danneggiati SET numero_danneggiato = (SELECT MAX(numero_danneggiato) + 1 FROM (SELECT * FROM GENERALI_danneggiati WHERE id_incarico = '${this.IdIncarico}') T2) WHERE id_incarico = '${this.IdIncarico}' AND IFNULL(numero_danneggiato,'') = '' LIMIT 1`,
                    }
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', '', JSON.stringify(jsonObject2), retData2 => {
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    });
                }
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        });
    }
    getImportoLiquidazione() {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT T1.* FROM [11012] T1 WHERE T1.id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            if (retData.length > 0) {
                this.setState({ importoLiquidazioneRiepilogo: retData[0].finali_indenn_netti_arrotond });
                // if (retData1[0].finali_indenn_netti_arrotond >= 5000) {
                //     let dataSource = document.getElementById(`esito${isPerito}`).ej2_instances[0].dataSource;
                //     let newDataSource = dataSource?.filter(elm => { return elm.VALUE === '5' || elm.VALUE === '6' || elm.VALUE === '14' || elm.VALUE === '15' })
                //     document.getElementById(`esito${isPerito}`).ej2_instances[0].dataSource = newDataSource;
                // }
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    getDanneggiati() {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT T1.*, T2.id_danneggiato AS guidDanneggiato, T2.id_incarico, T2.esito_codice, T2.data_accertamento_conservativo, T3.polizza_regolaritaAmministrativa, T3.polizza_vincoli, T3.servizioEseguito_codice, T3.polizza_dataPagamentoPremio, T3.mantenimento_polizza, T3.mantenimento_polizza_nota FROM [00005] T1 LEFT OUTER JOIN [12004] T2 ON T1.id_contatto = T2.id_danneggiato_jftech LEFT OUTER JOIN [12003] T3 ON T1.id_sinistro = T3.id_sinistro WHERE T1.id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            if (retData.length > 0) {
                if (retData[0].esito_codice?.trim() === '4' || retData[0].esito_codice?.trim() === '7' || retData[0].esito_codice?.trim() === '8' || retData[0].esito_codice?.trim() === '10') {
                    if (!this.liquidazioniPresenti) {
                        document.getElementById(`divBtnAttoUnico${idSin}`).style.display = 'block';
                        document.getElementById(`divBtnAttiSeparati${idSin}`).style.display = 'block';    
                    }
                }
                else {
                    document.getElementById(`divBtnAttoUnico${idSin}`).style.display = 'none';
                    document.getElementById(`divBtnAttiSeparati${idSin}`).style.display = 'none';    
                }
                if (retData[0].esito_codice?.trim() === '14') {
                    document.getElementById(`divDataFirmaAttoNonConcordato${idSin}`).style.display = 'block';
                    this.getDataFirmaAtto();
                }
                document.getElementById(`esito${idSin}`).ej2_instances[0].value = retData[0].esito_codice?.trim();
                document.getElementById(`checkRegolaritaAmministrativa${idSin}`).ej2_instances[0].checked = retData[0].polizza_regolaritaAmministrativa === true;
                document.getElementById(`checkVincoli${idSin}`).ej2_instances[0].checked = retData[0].polizza_vincoli === true;
                document.getElementById(`tipoServizio${idSin}`).ej2_instances[0].value = retData[0].servizioEseguito_codice?.trim();
                document.getElementById(`dataPagamentoPremio${idSin}`).ej2_instances[0].value = moment(retData[0].polizza_dataPagamentoPremio).format('DD/MM/YYYY');
                document.getElementById(`mask_dataPagamentoPremio${idSin}`).ej2_instances[0].value = moment(retData[0].polizza_dataPagamentoPremio).format('DD/MM/YYYY');     
                document.getElementById(`checkMantenimentoPolizza${idSin}`).ej2_instances[0].checked = retData[0].mantenimento_polizza === true;
                document.getElementById(`notaMantenimentoPolizza${idSin}`).ej2_instances[0].value = retData[0].mantenimento_polizza_nota?.trim() ?? null;

                let arrDann = [];
                retData.forEach(elm => {
                    var objDann = {
                        id_danneggiato: elm.id_danneggiato,
                        guidDanneggiato: elm?.guidDanneggiato,
                        id_sinistro: document.getElementById(`idSinistro${idSin}`).value,
                        stato_originale: 'o',
                        stato: 'o',
                        nominativo: elm.nominativo || '',
                        liquidazione: 'n'
                    };
                    arrDann.push(objDann);
                    this.takeNomiDanneggiati.push(elm.nominativo);
                });
                sessionStorage.setItem('JellyfishTechnologyStrutturaDanneggiati', JSON.stringify(arrDann));
                this.items = [];
                JSON.parse(sessionStorage.getItem('JellyfishTechnologyStrutturaDanneggiati')).forEach(dann => {
                    if (dann.stato === 'n' || dann.stato === 'o') {
                        let objItem = {
                            text: dann.nominativo,
                            id: dann.guidDanneggiato,
                        }
                        this.items.push(objItem);
                    }
                });
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    getFotoRischioAssicurato() {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT polizza_fotografiaRischioAssicurato, note_ritardo_consegna FROM [12003] T1 WHERE T1.id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            if (!CustomJS.isNullEmpty(retData[0].polizza_fotografiaRischioAssicurato)) {
                document.getElementById(`fotoRischioBase64${idSin}`).value = `${CustomJS.getBase64Prefix(retData[0].polizza_fotografiaRischioAssicurato)}${retData[0].polizza_fotografiaRischioAssicurato}`;
                document.getElementById(`btnVisualizzaFotoRischioGENERALI${idSin}`).style.removeProperty('display');            
                document.getElementById(`btnCancellaFotoRischioGENERALI${idSin}`).style.removeProperty('display');   
            }
            else {
                document.getElementById(`btnVisualizzaFotoRischioGENERALI${idSin}`).style.display = 'none'; 
                document.getElementById(`btnCancellaFotoRischioGENERALI${idSin}`).style.display = 'none';
            }
            document.getElementById(`notaRitardoConsegna${idSin}`).ej2_instances[0].value = retData[0].note_ritardo_consegna?.trim() ?? null;
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    getNumeroSinistroPolizzaPrecedente(polNum) {
        const QueryExec = `SELECT sin_num FROM acsinistri WHERE pol_num = '${polNum}' LIMIT 1`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            if (retData.length > 0) {
                this.numeroSinistroPolizza = retData[0].sin_num;
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    getProdotto() {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT T1.codice AS codProdotto, T1.descrizione AS descrProdotto FROM [12018] T1 WHERE T1.id_incarico = '${document.getElementById(`idIncarico${idSin}`).value}'`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            if (retData.length > 0) {
                document.getElementById(`prodotto${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(retData[0].codProdotto?.trim()) ? document.getElementById(`tipoPolizza${idSin}`).ej2_instances[0].value : retData[0]?.codProdotto?.trim();
                document.getElementById(`descrizioneProdotto${idSin}`).ej2_instances[0].value = CustomJS.isNullEmpty(retData[0].descrProdotto) ? 'Nessuna' : retData[0]?.descrProdotto;
            }
            else
                document.getElementById(`prodotto${idSin}`).ej2_instances[0].value = document.getElementById(`tipoPolizza${idSin}`).ej2_instances[0].value?.trim();
            setTimeout(() => {
                this.aggiornaDettaglio('codice', document.getElementById(`prodotto${idSin}`).ej2_instances[0].value, 'CHAR', '[12018]', true);
                this.aggiornaDettaglio('prodotto', document.getElementById(`prodotto${idSin}`).ej2_instances[0].text, 'CHAR', '[12018]', true);
                this.aggiornaDettaglio('descrizione', document.getElementById(`descrizioneProdotto${idSin}`).ej2_instances[0].value, 'CHAR', '[12018]', true);   
            }, 150);
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    contaLiquidazioni() {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT count(*) AS num FROM [12012] T1 INNER JOIN [12004] T2 ON T1.id_danneggiato = T2.id_danneggiato WHERE T2.id_incarico = '${document.getElementById(`idIncarico${idSin}`).value}'`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            if (retData[0].num > 0) {
                document.getElementById(`divBtnVediLiquidazioni${idSin}`).style.display = 'block';
                //document.getElementById(`divBtnResetLiquidazioni${idSin}`).style.display = 'block';
                document.getElementById(`divBtnAttoUnico${idSin}`).style.display = 'none';
                document.getElementById(`divBtnAttiSeparati${idSin}`).style.display = 'none';
                this.liquidazioniPresenti = true;
            }
            else {
                document.getElementById(`divBtnVediLiquidazioni${idSin}`).style.display = 'none';
                document.getElementById(`divBtnAttoUnico${idSin}`).style.display = 'block';
                document.getElementById(`divBtnAttiSeparati${idSin}`).style.display = 'block';
                //document.getElementById(`divBtnResetLiquidazioni${idSin}`).style.display = 'none';
                // if (document.getElementById(`esito${idSin}`).ej2_instances[0].value === '4'
                //     || document.getElementById(`esito${idSin}`).ej2_instances[0].value === '7' 
                //     || document.getElementById(`esito${idSin}`).ej2_instances[0].value === '8' 
                //     || document.getElementById(`esito${idSin}`).ej2_instances[0].value === '10') {
                //     document.getElementById(`divBtnAttoUnico${idSin}`).style.display = 'block';
                //     document.getElementById(`divBtnAttiSeparati${idSin}`).style.display = 'block';
                // }
                this.liquidazioniPresenti = false;
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    getDataFirmaAtto() {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT data_accertamento_conservativo FROM [12004] T1 WHERE T1.id_incarico = '${document.getElementById(`idIncarico${idSin}`).value}'`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            if (retData.length > 0) {
                document.getElementById(`dataFirmaAttoNonConcordato${idSin}`).ej2_instances[0].value = moment(retData[0].data_accertamento_conservativo).format('DD/MM/YYYY');
                document.getElementById(`mask_dataFirmaAttoNonConcordato${idSin}`).ej2_instances[0].value = moment(retData[0].data_accertamento_conservativo).format('DD/MM/YYYY');
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    caricaTabelleCompagniaScelta(idCompagnia) {
        const idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        this.prefissoCompagnia = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaCompagnie_prefissi')))?.find(({ cod_ele }) => cod_ele === idCompagnia?.toString())?.descriz;
        this.dsEsito = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_esito`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        //this.dsEsitoDanno = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_esito_danno`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoLiquidazione = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_liquidazione`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoBeneficiario = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_beneficiario`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoPagamento = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_pagamento`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        //this.dsFormulaAssicurazione = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_formula_ass`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoServizio = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_servizio`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz, SERVIZIO_STR2: elm.servizio_str2 } });
        this.dsEsitoPerizia = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_esito_perizia`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
        this.dsMotivoMancataDefinizione = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_motivo_mancata_definizione`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
        this.dsMotivoNegativa = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_motivo_negativa_videoper`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
        this.dsMotivoInterlocutoria = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_motivo_interlocutoria`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } }).slice(1);
        this.dsTipoBeneGFK = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_tipo_bene_GFK`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoBeneGFKNonPresente = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_tipo_bene_GFK_non_presente`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoDefinizione = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_tipo_definizione`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsMezzoPagamento = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_mezzo_pagamento`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoBeneficiarioUNI = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_tipo_beneficiario`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        this.dsTipoPersona = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_tipo_persona`)))?.map(elm => { return { VALUE: elm.cod_ele.toString(), TEXT: elm.descriz } });
        document.getElementById(`esito${idSin}`).ej2_instances[0].dataSource = this.dsEsito ?? null;
        document.getElementById(`tipoServizio${idSin}`).ej2_instances[0].dataSource = this.dsTipoServizio ?? null;
        document.getElementById(`esitoUnipol${idSin}`).ej2_instances[0].dataSource = this.dsEsitoPerizia ?? null;
        document.getElementById(`mancataDefinizioneUnipol${idSin}`).ej2_instances[0].dataSource = this.dsMotivoMancataDefinizione ?? null;
        document.getElementById(`motivazioneNegativaUnipol${idSin}`).ej2_instances[0].dataSource = this.dsMotivoNegativa ?? null;
        document.getElementById(`tipoBeneGFKUnipol${idSin}`).ej2_instances[0].dataSource = this.dsTipoBeneGFK ?? null;
        document.getElementById(`tipoBeneGFKNonPresenteUnipol${idSin}`).ej2_instances[0].dataSource = this.dsTipoBeneGFKNonPresente ?? null;
        document.getElementById(`tipoDefinizioneUnipol${idSin}`).ej2_instances[0].dataSource = this.dsTipoDefinizione ?? null;
    }
    componiFiltroAgg(oggettoFiltri) {
        let strFiltroAgg = "";
        Object.values(oggettoFiltri).forEach((elm, idx) => {
            if (Object.keys(oggettoFiltri)[idx] === 'Stato' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `id_stato = ${elm.toString()} AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'Gruppo' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `id_compagnia = ${elm.toString()} AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'CompDiv' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `id_divisione = ${elm.toString()} AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'Agenzia' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `id_agenzia = ${elm.toString()} AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'Polizza' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `id_tipo_polizza = ${elm.toString()} AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'GradoSinistro' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `id_grado_sinistro = ${elm.toString()} AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'DataRemindDa' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `strDataReminder >= '${moment(elm).format('YYYYMMDD')}' AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'DataRemindA' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `strDataReminder <= '${moment(elm).format('YYYYMMDD')}' AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'DataIncaricoDa' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `strDataIncarico >= '${moment(elm).format('YYYYMMDD')}' AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'DataIncaricoA' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `strDataIncarico <= '${moment(elm).format('YYYYMMDD')}' AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'DataInvioAttoDa' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `strDataInvioAtto >= '${moment(elm).format('YYYYMMDD')}' AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'DataInvioAttoA' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `strDataInvioAtto <= '${moment(elm).format('YYYYMMDD')}' AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'DataChiusuraDa' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `strDataChiusuraSinistro >= '${moment(elm).format('YYYYMMDD')}' AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'DataChiusuraA' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `strDataChiusuraSinistro <= '${moment(elm).format('YYYYMMDD')}' AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'CAT' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `IDCAT = ${elm.toString()} AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'DataChiusuraCATDa' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `strDataChiusuraCat >= '${moment(elm).format('YYYYMMDD')}' AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'DataChiusuraCATA' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `strDataChiusuraCat <= '${moment(elm).format('YYYYMMDD')}' AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'Perito' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += (elm === '99999') ? `ISNULL(IDperito,'') = '' AND ` : `IDperito = ${elm.toString()} AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'DataChiusuraPeritoDa' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `strDataChiusuraPerizia >= '${moment(elm).format('YYYYMMDD')}' AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'DataChiusuraPeritoA' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `strDataChiusuraPerizia <= '${moment(elm).format('YYYYMMDD')}' AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'CQ' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `IDcq = ${elm.toString()} AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'DataControlloCQDa' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `strDataCQ >= '${moment(elm).format('YYYYMMDD')}' AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'DataControlloCQA' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `strDataCQ <= '${moment(elm).format('YYYYMMDD')}' AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'Videoperizia' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `ISNULL(videoperizia,0) = ${elm === true ? 1 : 0} AND `;
            if (Object.keys(oggettoFiltri)[idx] === 'Documentale' && !CustomJS.isNullEmpty(elm)) 
                strFiltroAgg += `ISNULL(documentale,0) = ${elm === true ? 1 : 0} AND `;
        });
        if (CustomJS.isNullEmpty(strFiltroAgg))
            document.querySelector('.e-acrdn-header-content').innerHTML = '<span style="font-size:17px;font-weight:300">Filtri Aggiuntivi</span>&nbsp;<span style="color:green;font-weight:600">(nessun filtro attivo)</span>';
        else
            document.querySelector('.e-acrdn-header-content').innerHTML = '<span style="font-size:17px;font-weight:300">Filtri Aggiuntivi</span>&nbsp;<span style="color:red;font-weight:bolder">(uno o più filtri attivi)</span>';
        
        return CustomJS.isNullEmpty(strFiltroAgg) ? 'NoFiltroAgg' : encodeURIComponent(strFiltroAgg.substring(0, strFiltroAgg.length-5).replaceAll('>','[gt]').replaceAll('<','[lt]'));
    }
    mostraAlertAnomaliaBeni() {
        let idSin = this.tabInstance.items[this.tabInstance.selectedItem].id;
        const QueryExec = `SELECT [01001].id_interno_sinistro FROM [010041] INNER JOIN [01004] ON [010041].id_cat = [01004].id_cat INNER JOIN [01001] ON [01001].id_sinistro = [01004].id_sinistro WHERE ISNULL([010041].numero_seriale_bene,'') IN (SELECT ISNULL([010041].numero_seriale_bene,'') FROM [010041] INNER JOIN [01004] ON [010041].id_cat = [01004].id_cat WHERE ISNULL([010041].numero_seriale_bene,'') != '' AND [01004].id_sinistro = ${document.getElementById(`idSinistro${idSin}`).value}) AND [01004].id_sinistro != ${document.getElementById(`idSinistro${idSin}`).value}`;        
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            let elencoSinistri = _.uniq(retData.map(elm => { return elm.id_interno_sinistro }));
            elencoSinistri = elencoSinistri.join(',');
            if (retData.length > 0)
                toast.error(`Attenzione!!! Anomalie presenti sui numeri seriali dei beni. Il/I sinistro/i ${elencoSinistri} contiene/contengono beni con uno o più numeri seriali uguali a quelli inseriti nella perizia del sinistro corrente`, {
                    containerId: 'toastContainer1',
                    //onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: false,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            else
                toast.dismiss();
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);   
    }
}