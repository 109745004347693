/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { FormValidator } from '@syncfusion/ej2-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent, CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent, ListBoxComponent, CheckBoxSelection, Inject } from '@syncfusion/ej2-react-dropdowns';
import { RichTextEditorComponent, Toolbar as RTEtoolbar, Inject as RTEInject, Image, Link, HtmlEditor, Count, QuickToolbar } from '@syncfusion/ej2-react-richtexteditor';
import { Browser } from '@syncfusion/ej2-base';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random } from 'underscore';
import moment from 'moment';
import { MDBFooter } from 'mdb-react-ui-kit';
import PaginaPeriziaOnlineCalcoli from './PaginaPeriziaOnlineCalcoli';
import $ from 'jquery';

export default class PaginaPeriziaOnline extends SampleBase {
    constructor() {
        super(...arguments);
        this.componentUnaVolta = 0;
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.childKey = 0;
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog' };
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.ddlFieldsList = { text: 'text', value: 'id' };
        this.RTEitems = ['Bold', 'Italic', 'Underline',
            'FontName', 'FontSize',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|', 'CreateLink', 'Print', '|', 'Undo', 'Redo'
        ];
        this.RTEtoolbarSettings = {
            items: this.RTEitems
        };
        this.formOptions = {
            rules: {
            }
        }
        //PREFISSO COMPAGNIA
        debugger;
        this.prefissoCompagnia = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaCompagnie_prefissi')))?.find(({ cod_ele }) => cod_ele === this.props.IdCompagnia.toString())?.descriz;
        this.dsMotiviRivalsa = [];
        if (this.isCompagniaGenerali()) {
            this.dsPartiteRicGen = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_partita_ric`))).map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
            this.dsPartite = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_partita`))).map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz, GUID: '0000-0000-0000-0000' } });
            this.dsGaranzie = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_garanzia`))).map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz, GUID: '0000-0000-0000-0000', servizio_str2: elm.servizio_str2 } });
            this.dsGaranzieRicevuteGen = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_garanzia_ric`))).map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
            this.dsGruppiGaranzia = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_gruppi_gar`))).map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
            this.dsFormaAssicurazioni = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_forma_ass`))).map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
            this.dsFormulaAssicurazioni = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_formula_ass`))).map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
            this.dsCondizioneRischioData = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_stato_rischio`))).map(elm => { return { id: elm.cod_ele, text: elm.descriz } });
        }
        else {
            if (this.isCompagniaUnipol()) {
                this.dsPartite = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaUNIPOLSAI_Partite`)))?.map(elm => { return { VALUE: elm.id_partita.toString(), TEXT: elm.partita, TIPO_SIN: elm.id_tipo_sinistro } });
                this.dsMotiviRivalsa = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaUNI_motivo_rivalsa`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
            }
            else
                this.dsPartite = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella_partite_app`)))?.map(elm => { return { VALUE: elm.id_partita_app.toString(), TEXT: elm.partita_app, TIPO_SIN: elm.id_tipo_sinistro } });
            // this.dsPartiteFiltrato = []
            // this.dsPartite.forEach(elm => {
            //     let more = []
            //     if (elm.TIPO_SIN.includes(',')) {
            //         more = elm.TIPO_SIN.split(',');
            //         more.forEach(moreEle => {
            //             if (moreEle === this.props.tipoSinistro) {
            //                 let obj = {
            //                     VALUE: elm.VALUE,
            //                     TEXT: elm.TEXT,
            //                 };
            //                 this.dsPartiteFiltrato.push(obj)
            //             }
            //         });
            //     }
            // })
            // this.dsPartite = this.dsPartiteFiltrato;
            // if (this.dsPartite.length === 0)
            //     this.dsPartite = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella_partite_app`))).map(elm => { return { VALUE: elm.id_partita_app.toString(), TEXT: elm.partita_app, TIPO_SIN: elm.id_tipo_sinistro } });

            this.dsGaranzie = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella_garanzie`)))?.map(elm => { return { VALUE: elm.id_garanzia.toString(), TEXT: elm.garanzia, TIPO_SIN: elm.id_tipo_sinistro.toString() } });
            // this.dsGaranzieFiltrato = []
            // this.dsGaranzie.forEach(elm => {
            //     if (elm.TIPO_SIN === this.props.tipoSinistro) {
            //         let obj = {
            //             VALUE: elm.VALUE,
            //             TEXT: elm.TEXT,
            //         };
            //         this.dsGaranzieFiltrato.push(elm)
            //     }
            // })
            // this.dsGaranzie = this.dsGaranzieFiltrato;
            // if (this.dsGaranzie.length === 0)
            //     this.dsGaranzie = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella_garanzie`))).map(elm => { return { VALUE: elm.id_garanzia.toString(), TEXT: elm.garanzia, TIPO_SIN: elm.id_tipo_sinistro.toString() } });

            //DS di Generali che non sono utilizzati ma devono essere instanziati
            this.dsPartiteRicGen = [];
            this.dsGaranzieRicevuteGen = [];
            this.dsFormaAssicurazioni = [];
            this.dsFormulaAssicurazioni = [];
            this.dsGruppiGaranzia = [];
        }
        //DS BASE CONDIVISI CON TUTTE LE COMPAGNIE
        //todo da gestire le altre determinazioni del danno!
        //todo da sbloccare quando ho gestito gli altri casi
        this.dsDeterminazioneDanno = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaDeterm_danno`))).map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } }).slice(1,6);
        //momentaneo!!
        // this.dsDeterminazioneDanno = [
        //     { TEXT: 'Valore a Nuovo', VALUE: '001' },
        //     { TEXT: "Valore allo stato d'uso e supplemento di indennizzo", VALUE: '003' }];

        if (this.isCompagniaUnipol())
            this.dsCondizioneRischioData = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_stato_fabbricato`)))?.map(elm => { return { id: elm.cod_ele, text: elm.descriz } });
        else
            //DS GENERALI CONDIVISI CON TUTTE LE COMPAGNIE
            this.dsCondizioneRischioData = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaGEN_stato_rischio`))).map(elm => { return { id: elm.cod_ele, text: elm.descriz } });
        //todo da gestire le altre forme garanzie!
        //todo da sbloccare quando ho gestito gli altri casi
        // this.dsFormaGaranzia = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaGEN_forma_ass`))).map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
        this.dsFormaGaranzia = [
            { TEXT: "P.R.A.", VALUE: '1' },
            { TEXT: "VALORE INTERO", VALUE: '2' },
            { TEXT: "VALORE INTERO - NO PREESISTENZA", VALUE: '3' },
        ]
        this.dsTipoServizio = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaGEN_servizio`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz, servizio_str2: elm.servizio_str2 } });
        this.dsNoteSinistroModalitaFE = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaNoteSinistroModalitaFE`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz, servizio_str1: elm.servizio_str1 } });
        this.notePeritaliListData = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaNoteOperazioniPeritali`)))?.filter(elm => { return !CustomJS.isNullEmpty(elm.cod_ele) }).map(elm => { return { id: elm.cod_ele, text: elm.descriz, nota: elm.servizio_str1 } });
        //Numero di ogni combo per ciclare i box
        this.numComboPartite = 0;
        this.numComboGaranzie = 0;
        this.numComboStime = 0;
        this.danneggiati = [];
        this.dsDanneggiati = [];
        this.dsFranchigiaCondivisa = [];

        //STRUTTURA INTERNA STATI OPERAZIONI CRUD 
        this.strutturaStatiPartite = [];
        this.strutturaStatiGaranzie = [];
        this.strutturaStatiStime = [];
        this.statoInfoElaboratoPeritale = {};

        this.strutturaStatiEliminaDati = {
            idPartite: [],
            idGaranzie: [],
            idStime: [],
            idPreesistenzaJftech: [],
            idPreesistenzaCostruz: [],
            idPreesistenzaDescr: [],
            idPreesistenzaGen: [],
            idStimeDescrizioni: [],
            idDannoDettInfoRichGen: [],
            idCoassicurazioni: [],
            idInfoRichieste: [],
            idDanneggiatiGen: [],
            idFranchige: [],
            idRiepilogoPerizia: 0,
            idRiepilogoDanneggiati: [],
            idRiepilogoRigheDanneggiato: [],
            idCausali: []
        }
        //VARIABILI DI UTILITY
        this.datiPerNote = {}
        this.danneggiatiAggiunti = []
        this.periziaSenzaCalcoli = false;

        //DATI DELLE LISTBOX
        this.dsLimiteIndennizzo = [
            { TEXT: 'Anno', VALUE: 'anno' },
            { TEXT: 'Sinistro', VALUE: 'sinistro' },
        ]

        this.tipoAbitazioneRischAssData = [
            { text: 'Condominio', id: 'condominio' },
            { text: 'Appartamento', id: 'appartamento' },
            { text: 'Villa', id: 'villa' },
            { text: 'Villa a schiera', id: 'villa-a-schiera' },
            { text: 'Fabbricato Unifamiliare', id: 'fabbricato-unifamiliare' },
            { text: 'Capannone Industriale', id: 'capannone-unifamiliare' },
            { text: 'Negozio', id: 'negozio' },
            { text: 'Capannone commerciale', id: 'capannone-commerciale' }
        ];
        this.adibitoData = [
            { text: 'Totalita', id: 'totalita' },
            { text: 'Almeno 3/4', id: 'almeno-3/4' },
            { text: 'Almeno 2/3', id: 'almeno-2/3' },
            { text: 'Prevalentemente', id: 'prevalentemente' }
        ];
        this.adibitoAData = [
            { text: 'Civile Abitazione', id: 'civile-abitazione' },
            { text: 'Uso Commerciale', id: 'uso-commerciale' },
            { text: 'Uso Industriale', id: 'uso-industriale' },
            { text: 'Uso Servizi', id: 'uso-servizi' }
        ];
        this.strutturaPortanteData = [
            { text: 'Calcestruzzo', id: 'calcestruzzo' },
            { text: 'Legno', id: 'legno' },
            { text: 'Muratura Portante', id: 'muratura-portante' },

        ];
        this.tamponamentiData = [
            { text: 'Laterizio', id: 'laterizio' },
            { text: 'Pennelli Metallici', id: 'pennelli-metallici' },
            { text: 'Legno', id: 'legno' },
        ];
        this.ordituraTettoData = [
            { text: 'Calcestruzzo', id: 'calcestruzzo' },
            { text: 'Legno', id: 'legno' },
            { text: 'Metallo', id: 'metallo' },
            { text: 'Prefabbricati', id: 'prefabbricati' },
        ];
        this.coperturaData = [
            { text: 'Tegole', id: 'tegole' },
            { text: 'Pennelli Metallici', id: 'pennelli-metallici' },
        ];
        this.finitureData = [
            { text: 'Standard', id: 'standard' },
            { text: 'Economiche', id: 'economiche' },
            { text: 'Di Pregio', id: 'di-pregio' }
        ];
        this.tipoImpiantoData = [
            { text: 'Installato o A terra', id: 'installato-o-a-terra' },
            { text: 'Integrato', id: 'integrato' },
        ]
        this.tipoAbitazioneMezzChiusData = [
            { text: 'Condominio', id: 'condominio' },
            { text: 'Appartamento', id: 'appartamento' },
            { text: 'Villa', id: 'villa' },
            { text: 'Villa a schiera', id: 'villa-a-schiera' },
            { text: 'Fabbricato Unifamiliare', id: 'fabbricato-unifamiliare' },
            { text: 'Capannone Industriale', id: 'capannone-unifamiliare' },
            { text: 'Negozio', id: 'negozio' },
            { text: 'Capannone commerciale', id: 'capannone-commerciale' }
        ];
        this.porteIngressoLocaliData = [
            { text: 'In legno pieno', id: 'in-legno-pieno' },
            { text: 'In metallo', id: 'metallo' },
            { text: 'In plastica', id: 'plastica' },
            { text: 'Con telaio in legno e vetro', id: 'con-telaio-in-legno-e-vetro' },
            { text: 'Con telaio in metallo e vetro', id: 'con-telaio-in-metallo-e-vetro' },
        ]
        this.finestreFuoriTerra = [
            { text: 'In legno pieno', id: 'in-legno-pieno' },
            { text: 'In metallo', id: 'in-metallo' },
            { text: 'In plastica', id: 'in-plastica' },
            { text: 'Con telaio in legno e vetro', id: 'con-telaio-in-legno-e-vetro' },
            { text: 'Con telaio in metallo e vetro', id: 'con-telaio-in-metallo-e-vetro' },
        ]
        this.tipoFinestreFuoriTerra = [
            { text: 'Antisfondamento', id: 'antisfondamento' },
            { text: 'Non antisfondamento', id: 'non-antisfondamento' },
        ];
        this.tipoFinestreFuoriTerra2 = [
            { text: 'Tapparelle', id: 'tapparelle' },
            { text: 'Gelosie', id: 'gelosie' },
        ];
        this.tipoFinestreFuoriTerra3 = [
            { text: 'In legno', id: 'in-legno' },
            { text: 'In PVC', id: 'in-pvc' },
            { text: 'Metalliche', id: 'metalliche' },
        ];
        this.dsCausaSinistro = [
            { text: 'Occlusione Della Colonna Di Scarico', id: 'occlusioneColonnaScarico' },
            { text: 'Occlusione Impianto Fognario', id: 'occlusioneImpiantoFogniario' },
            { text: 'Rottura Accidentale Braga Del Bagno', id: 'rotturaBragaBagno' },
            { text: 'Rottura Accidentale Colonna Di Scarico', id: 'rotturaColonnaScarico' },
            { text: 'Rottura Accidentale Colonna Di Carico e Braga Del Bagno', id: 'rotturaColCaricoBragaBagno' },
            { text: 'Rottura Accidentale Colonna Di Scarico e Braga Del Bagno', id: 'rotturaColScaricoBragaBagno' },
            { text: 'Rottura Accidentale Conduttura Di Scarico', id: 'rotturaColonnaScarico' },
            { text: 'Rottura Accidentale Cucchiaia', id: 'rotturaCucchiaia' },
            { text: 'Rottura Accidentale Impianto Di Riscaldamento', id: 'rotturaImpiantoRisc' },
            { text: 'Rottura Accidentale Pluviale Incassato', id: 'rotturaPluvialeIncassato' },
            { text: 'Rottura Accidentale Pluviale Esterno', id: 'rotturaPluvialeEsterno' },
            { text: "Rottura Accidentale Tubazione Alimentazione Dell'Acqua", id: 'rotturaTubazioneAcqua' },
            { text: 'Rottura Accidentale Tubazione Di Scarico', id: 'rotturaTubazioneScarico' },
            { text: 'Rottura Accidentale Tubazione Scarico Sotto Piatto Doccia', id: 'rotturaTubazioneScaricoPiattoDoccia' },
            { text: 'Rottura Accidentale Tubazione Esterna', id: 'rotturaTubazioneEsterna' },
            { text: 'Rottura Accidentale Sifone a Pavimento', id: 'rotturaSifonePavimento' },
            { text: 'Rottura Tubazione Riscaldamento Vaso Di Espansione', id: 'rotturaRiscaldamentoEspansione' },
            { text: 'Semplici Infiltrazioni, Senza Alcuna Rottura', id: 'infiltrazioni' },
        ]
        this.dsEffettoSinistro = [
            { text: "Spargimento d'Acqua Nello Stesso Locale", id: 'spargStessoLocale' },
            { text: "Spargimento d'Acqua Nello Stesso Piano", id: 'spargStessoPiano' },
            { text: "Spargimento d'Acqua Nel Piano Sottostante", id: 'spargPianoSotto' },
            { text: "Spargimento d'Acqua Nei Piani Sottostanti", id: 'spargPianiSotto' },
            { text: "Spargimento d'Acqua Nello Stesso Piano e Nei Piani Sottostanti", id: 'spargStessoPianoSotto' },
            { text: "Spargimento d'Acqua Nello Stesso Locale e In Quello Al Piano Sottostante", id: 'spargStessoLocaleSotto' },
            { text: "Spargimento d'Acqua Nel Locale Cantine", id: 'spargLocaleCantine' },
            { text: "Spargimento d'Acqua Nel Vano Scale", id: 'spargVanoScale' },
        ];
        this.riservaData = [
            { text: "No RG", id: 'no-rg' },
            { text: "No Danni Materiali", id: 'no-danni-materiali' },
            { text: "Tempistiche", id: 'tempistiche' },
            { text: "No Garanzia", id: 'no-garanzia' },
            { text: "Esclusione", id: 'esclusione' },
            { text: "No Residui", id: 'no-residui' },
            { text: "Rischio Non Conforme", id: 'rischio-non-conforme' },
        ];
        this.dataSourcePartite = [];
        this.dataSourceGaranzie = [];
        this.keyPartitePrecModificate = []
        this.keyGaranziePrecModificate = []
        this.keyStimePrecModificate = []
    }
    state = {
        blocking: false,
        showDialogElab: false
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    toggleBlocking2(show) {
        let container, root;
        if (show) {
            document.getElementById('blockUIGenerico').style.removeProperty('display');
            document.getElementById('spinnerUIGenerico').style.removeProperty('display');
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.render(CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]);
        }
        else {
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.unmount();
            document.getElementById('blockUIGenerico').style.display = 'none';
            document.getElementById('spinnerUIGenerico').style.display = 'none';
        }
    }
    onCreatedBtnInizioPag() {
        this.element.addEventListener('click', function (args) {
            CustomJS.scrollAuto(500, 0, false, true);
        });       
    }
    //------------------FUNZIONI DI UTILITY INIZIO------------------
    rimuoviDaStrutturaStatiElementoNuovoEliminato(value, index, arr) {
        // If the value at the current array index matches the specified value
        if (value.statoIniziale === 'n' && value.stato === 'd') {
            // Removes the value from the original array
            arr.splice(index, 1);
            return;
        }
        return;
    }
    //------------------FUNZIONI DI UTILITY FINE------------------

    //------------------GESTIONE MODIFICA INIZIO------------------
    modificaPerizia() {
        this.dialogElaboratoInstance.height = '25%';
        this.dialogElaboratoInstance.minHeight = '25%';
        this.dialogElaboratoInstance.width = '40%';
        this.dialogElaboratoInstance.beforeOpen = (args) => {
            const container = document.getElementById(`containerDialogElaboratoPeritale_${this.props.IdInternoSinistro}`);
            const root = createRoot(container);
            root.unmount();
        }
        this.dialogElaboratoInstance.open = (args) => {
            const container = document.getElementById(`containerDialogElaboratoPeritale_${this.props.IdInternoSinistro}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
            }}>{<>
                <div style={{ textAlign: 'center' }}>
                    <h4 style={{ color: 'goldenrod' }}>ATTENZIONE!</h4>
                    <h6>Se modifichi la costruzione delle tabelle ricordati di <span style={{ color: 'green' }}>ri-salvare i calcoli </span>aggiornati nella pagina successiva</h6>
                    <h6>Sicuro di voler procedere ?</h6>
                </div>
            </>}</CallbackWrapper>);
        }
        this.dialogElaboratoInstance.buttons = [
            {
                click: () => {
                    this.dialogElaboratoInstance.hide();
                    document.getElementById(`btnModificaPerizia_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                    document.getElementById(`salvaEProcedi_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
                    this.sinistroELeSueModalitaEMezziChiusuraSiNO();
                    
                    //SBLOCCA COMBO
                    for (let p = 0; p < this.numComboPartite; p++) {
                        if (document.getElementById(`Partita${p}_${this.props.IdInternoSinistro}`)) {
                            //SBLOCCO PARTITE
                            document.getElementById(`Partita${p}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`DescrizionePartita${p}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`FormaGaranzia${p}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`SommaAssicurataPartita${p}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`aggiungiPartita${p}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
                            //se la partita NON E' importata lo abilito
                            if (document.getElementById(`partitaImportata${p}_${this.props.IdInternoSinistro}`).innerHTML !== '<i class="fas fa-arrow-circle-up"></i>')
                                document.getElementById(`eliminaPartita${p}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
                        }
                    }

                    document.getElementById(`btnAggiungiPartita_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false

                    for (let i = 0; i < this.numComboGaranzie; i++) {
                        if (document.getElementById(`Garanzia${i}_${this.props.IdInternoSinistro}`)) {
                            //SBLOCCO GARANZIE
                            document.getElementById(`Garanzia${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`DeterminazioneDanno${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`FranchigiaCondivisa${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`LimiteIndennizzoEuro${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`LimiteIndennizzoGiorni${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`LimiteIndennizzoPer${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`FranchigiaEuro${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`FranchigiaGiorni${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`ScopertoPerc${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`ScopertoMinimo${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`ScopertoMassimo${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            //SBLOCCO GARANZIE GENERALI
                            document.getElementById(`GruppoGaranzia${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`partitaAssicurata${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`FormulaAssicurazione${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`FormaAssicurazione${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`SommaAssicurata${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`aggiungiGaranzia${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false
                            //se la garanzia NON E' importata lo abilito
                            if (document.getElementById(`garanziaImportata${i}_${this.props.IdInternoSinistro}`).innerHTML !== '<i class="fas fa-arrow-circle-up"></i>')
                                document.getElementById(`eliminaGaranzia${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false
                        }
                    }

                    document.getElementById(`btnAggiungiGaranzia_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false

                    //controllo se ci sono più stime con lo stesso danneggiato importato le altre si possono eliminare 

                    let idDannImportati = []
                    for (let i = 0; i < this.numComboStime; i++) {
                        if (document.getElementById(`containerComboStima${i}`)) {
                            //SBLOCCO CAMPI TABELLE DI STIMA GENERICI
                            document.getElementById(`PartitaTabellaStima${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`GaranziaTabellaStima${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                            document.getElementById(`aggiungiStima${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false

                            //FACCIO IL CONTROLLO SUI DANENGGIATI IMPORTATI 
                            if (document.getElementById(`danneggiatoImportato${i}_${this.props.IdInternoSinistro}`).innerHTML === '<i class="fas fa-arrow-circle-up"></i>') {
                                idDannImportati.push({
                                    id: document.getElementById(`DanneggiatoTabellaStima${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                    num: i
                                })
                            }
                            else {
                                //se non è importato lo abilito
                                document.getElementById(`DanneggiatoTabellaStima${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                                document.getElementById(`btnSwitchDanneggiato${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
                                document.getElementById(`eliminaStima${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false
                            }
                        }
                    }
                    const raggruppati = idDannImportati.reduce((acc, obj) => {
                        const key = obj.id;
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key].push(obj);
                        return acc;
                    }, {});
                    for (const groupId in raggruppati) {
                        console.log(`Group ${groupId}:`);
                        const group = raggruppati[groupId];
                        let indice = 0;
                        for (const obj of group) {
                            if (indice > 0) {
                                //sono le stime con danneggiato importato doppio e posso modificarle a piacimento
                                document.getElementById(`DanneggiatoTabellaStima${obj.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                                document.getElementById(`btnSwitchDanneggiato${obj.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
                                document.getElementById(`eliminaStima${obj.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false
                            }
                            indice++
                        }
                    }

                    document.getElementById(`btnAggiungiStima_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;

                    //partite precedenti 
                    this.strutturaStatiPartite.forEach(part => {
                        this.keyPartitePrecModificate.push(
                            {
                                key: part.key,
                                codEle: document.getElementById(`Partita${part.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                formaGaranzia: document.getElementById(`FormaGaranzia${part.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                            }
                        )
                    })
                    //precedenti garanzie
                    this.strutturaStatiGaranzie.forEach(gar => {
                        this.keyGaranziePrecModificate.push(
                            {
                                key: gar.key,
                                codEle: document.getElementById(`Garanzia${gar.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                determinazioneDanno: document.getElementById(`DeterminazioneDanno${gar.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                            }
                        )
                    })
                    //stime precedenti
                    this.strutturaStatiStime.forEach(stima => {
                        this.keyStimePrecModificate.push(
                            {
                                keyJftech: stima.keyJftech,
                                keyGen: stima.keyGenerali,
                                codEleGaranzia: document.getElementById(`GaranziaTabellaStima${stima.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.cod_ele,
                            }
                        )
                    })
                },
                buttonModel: {
                    content: 'CONFERMA',
                    cssClass: 'e-primary',
                    isPrimary: true,
                }
            },
            {
                click: () => {
                    this.dialogElaboratoInstance.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.dialogElaboratoInstance.show();
    }

    ripristinaPerizia() {
        this.dialogElaboratoInstance.height = '20%';
        this.dialogElaboratoInstance.minHeight = '25%';
        this.dialogElaboratoInstance.width = '30%';
        this.dialogElaboratoInstance.beforeOpen = (args) => {
            const container = document.getElementById(`containerDialogElaboratoPeritale_${this.props.IdInternoSinistro}`);
            const root = createRoot(container);
            root.unmount();
        }
        this.dialogElaboratoInstance.open = (args) => {
            const container = document.getElementById(`containerDialogElaboratoPeritale_${this.props.IdInternoSinistro}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
            }}>{<>
                <div style={{ textAlign: 'center' }}>
                    <h4 style={{ color: 'red' }}>ATTENZIONE!</h4>
                    <h6>Se decidi di ripristinare la perizia tornerai allo stato iniziale e <span style={{ color: 'red' }}> perderai TUTTE </span> le modifiche e i calcoli effettuati!</h6>
                    <h6>Sicuro di voler procedere ?</h6>
                </div>
            </>}</CallbackWrapper>);
        }
        this.dialogElaboratoInstance.buttons = [
            {
                click: () => {
                    this.eliminaTuttiCalcoliPerizia();
                },
                buttonModel: {
                    content: 'CONFERMA',
                    cssClass: 'e-primary',
                    isPrimary: true,
                }
            },
            {
                click: () => {
                    this.dialogElaboratoInstance.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.dialogElaboratoInstance.show();
    }
    //------------------GESTIONE MODIFICA FINE------------------

    //------------------GESTIONE PARTITE INIZIO------------------
    aggiungiPartita(retDataPartiteP, casoIniziale) {
        debugger;
        let numCPG = this.numComboPartite;
        this.numComboPartite++;

        const DivContainer = document.getElementById(`tutteComboPartite_${this.props.IdInternoSinistro}`);
        const newDiv = document.createElement("div");
        newDiv.setAttribute("id", `containerComboPartita${numCPG}_${this.props.IdInternoSinistro}`);
        newDiv.setAttribute("class", "container-combo-periziaOnline-p1")
        DivContainer.append(newDiv);

        const container = document.getElementById(`containerComboPartita${numCPG}_${this.props.IdInternoSinistro}`)
        const root = createRoot(container);
        root.render(<CallbackWrapper callback={() => {
            if (CustomJS.isNullEmpty(retDataPartiteP)) {
                let objStrutturaStatiPartite = {
                    id_incarico: this.props.IdIncarico,
                    importato: false,
                    stato: 'n',
                    statoIniziale: 'n',
                    guid_partita: CustomJS.createGuid4(),
                    guidTemp: CustomJS.createGuid4(),
                    key: null,
                    id: `containerComboPartita${numCPG}_${this.props.IdInternoSinistro}`,
                    casoIniziale: false,
                    num: numCPG
                };
                this.strutturaStatiPartite.push(objStrutturaStatiPartite)
                if (this.isCompagniaGenerali()) {
                    let numFirstChild = document.getElementById(`tutteComboPartite_${this.props.IdInternoSinistro}`).firstChild.id;
                    numFirstChild = numFirstChild.replace("containerComboPartita", "").replace(`_${this.props.IdInternoSinistro}`, "");
                    document.getElementById(`PartitaRicevuta${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`PartitaRicevuta${numFirstChild}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                }
            }
            else {
                if (casoIniziale === 'casoIniziale') {
                    document.getElementById(`PartitaRicevuta${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataPartiteP.tipoPartita_codice.trim();
                    document.getElementById(`SommaAssicurataPartita${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataPartiteP.valore_assicurato;

                    let objStrutturaStatiPartite = {
                        id_incarico: this.props.IdIncarico,
                        importato: retDataPartiteP.importato,
                        stato: 'o',
                        statoIniziale: 'o',
                        guid_partita: retDataPartiteP.id_partita,
                        guidTemp: CustomJS.createGuid4(),
                        key: null,
                        id: `containerComboPartita${numCPG}_${this.props.IdInternoSinistro}`,
                        casoIniziale: true,
                        num: numCPG
                    };
                    this.strutturaStatiPartite.push(objStrutturaStatiPartite)
                }
                else {
                    document.getElementById(`Partita${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataPartiteP.cod_ele_partita;
                    document.getElementById(`FormaGaranzia${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataPartiteP.formaGaranzia;
                    document.getElementById(`SommaAssicurataPartita${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataPartiteP.sommaAssicurataPartita;
                    document.getElementById(`DescrizionePartita${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataPartiteP.descrizione;
                    //BLOCCO CAMPI
                    document.getElementById(`Partita${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    document.getElementById(`FormaGaranzia${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    document.getElementById(`SommaAssicurataPartita${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    document.getElementById(`DescrizionePartita${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    //BLOCCO TASTI
                    document.getElementById(`aggiungiPartita${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                    document.getElementById(`eliminaPartita${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true

                    //GENERALI
                    if (this.isCompagniaGenerali()) {
                        document.getElementById(`PartitaRicevuta${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataPartiteP.tipoPartita_codice.trim();
                    }
                    let objStrutturaStatiPartite = {
                        id_incarico: this.props.IdIncarico,
                        importato: retDataPartiteP.importato,
                        stato: 'o',
                        statoIniziale: 'o',
                        guid_partita: retDataPartiteP.id_partita,
                        key: retDataPartiteP.id_partitaPeriziaOnline,
                        guidTemp: retDataPartiteP.guid_partita_temp, //da mettere
                        id: `containerComboPartita${numCPG}_${this.props.IdInternoSinistro}`,
                        casoIniziale: false,
                        num: numCPG
                    };
                    this.strutturaStatiPartite.push(objStrutturaStatiPartite)
                    setTimeout(() => {
                        document.getElementById(`Partita${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.GUID = retDataPartiteP.id_partita;
                    }, 75)
                }

                if (this.isCompagniaGenerali()) {
                    //BLOCCO CAMPI
                    document.getElementById(`PartitaRicevuta${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    //SE E' IMPORTATO NON E' ELIMINABILE!
                    if (retDataPartiteP.importato === 1 || retDataPartiteP.importato === true) {
                        document.getElementById(`partitaImportata${numCPG}_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-arrow-circle-up"></i>'
                        document.getElementById(`DescrizionePartita${numCPG}_${this.props.IdInternoSinistro}`).style.backgroundColor = '#edf0fa';
                        if (document.getElementById(`eliminaPartita${numCPG}_${this.props.IdInternoSinistro}`)) {
                            document.getElementById(`eliminaPartita${numCPG}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                        }
                    }
                }
            }
        }}><div className='row'>
                <p className='importatoGen' title='Partita importata' id={`partitaImportata${numCPG}_${this.props.IdInternoSinistro}`}></p>
                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ display: this.isCompagniaGenerali() ? '' : 'none' }}>
                    <DropDownListComponent id={`PartitaRicevuta${numCPG}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsPartiteRicGen}
                        allowFiltering={true} filterType='Contains' showClearButton={true}
                        placeholder="Partita Ricevuta" floatLabelType='Auto' cssClass='e-outline'>
                    </DropDownListComponent>
                </div>
                <div className={this.isCompagniaGenerali() ? "col-xs-3 col-sm-3 col-lg-3 col-md-3" : "col-xs-5 col-sm-5 col-lg-5 col-md-5"}>
                    <DropDownListComponent onChange={this.cambiaPartita.bind(this)} id={`Partita${numCPG}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsPartite}
                        allowFiltering={true} filterType='Contains' showClearButton={true}
                        placeholder="Partita" floatLabelType='Auto' cssClass='e-outline'>
                    </DropDownListComponent>
                </div>
                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                    <DropDownListComponent id={`FormaGaranzia${numCPG}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsFormaGaranzia}
                        allowFiltering={true} filterType='Contains' showClearButton={true} 
                        placeholder="Forma Della Garanzia/Forma Assicurazione" floatLabelType='Auto' cssClass='e-outline'>
                    </DropDownListComponent>
                </div>
                <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1" >
                    <NumericTextBoxComponent showSpinButton={false} id={`SommaAssicurataPartita${numCPG}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' placeholder='Somma Assicurata' cssClass='e-outline'></NumericTextBoxComponent >
                </div>
                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                    <TextBoxComponent id={`DescrizionePartita${numCPG}_${this.props.IdInternoSinistro}`} onChange={this.descrizionePartiteChange.bind(this, numCPG)} floatLabelType='Auto' placeholder='Descrizione (Facoltativo)' cssClass='e-outline'></TextBoxComponent>
                </div>
                <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">
                    <ButtonComponent id={`eliminaPartita${numCPG}_${this.props.IdInternoSinistro}`} onClick={this.eliminaPartita.bind(this, `containerComboPartita${numCPG}_${this.props.IdInternoSinistro}`, numCPG)} title='Elimina' cssClass='e-fill e-danger' type='button'>X</ButtonComponent>
                    <ButtonComponent style={{ marginLeft: '15px' }} id={`aggiungiPartita${numCPG}_${this.props.IdInternoSinistro}`} onClick={this.aggiungiPartita.bind(this, '', '')} title='Aggiungi' cssClass='e-fill e-info' type='button'>+</ButtonComponent>
                </div>
            </div></CallbackWrapper>);
    }
    eliminaPartita(idContainerDaElimin, indice, args) {
        delete this.formOptions.rules[`Partita${indice}_${this.props.IdInternoSinistro}`];
        delete this.formOptions.rules[`FormaGaranzia${indice}_${this.props.IdInternoSinistro}`];
        delete this.formOptions.rules[`DescrizionePartita${indice}_${this.props.IdInternoSinistro}`];

        if (this.isCompagniaGenerali()) {
            delete this.formOptions.rules[`PartitaRicevuta${indice}_${this.props.IdInternoSinistro}`];
            delete this.formOptions.rules[`SommaAssicurataPartita${indice}_${this.props.IdInternoSinistro}`];
        }

        let containerDaElimin = document.getElementById(idContainerDaElimin);
        let containerGenitore = document.getElementById(`tutteComboPartite_${this.props.IdInternoSinistro}`);
        containerGenitore.removeChild(containerDaElimin);

        this.strutturaStatiPartite.forEach(elm => {
            if (elm.id === idContainerDaElimin)
                elm.stato = 'd';
        });
        this.strutturaStatiPartite.filter(this.rimuoviDaStrutturaStatiElementoNuovoEliminato)

        this.cambiaPartita();
    }
    cambiaPartita(args) {
        let combo = this.numComboPartite;
        let partitaVal = [];
        for (let index = 0; index < combo; index++) {
            if (!CustomJS.isNullEmpty(document.getElementById(`Partita${index}_${this.props.IdInternoSinistro}`))) {
                if (!CustomJS.isNullEmpty(document.getElementById(`Partita${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].text)) {
                    partitaVal.push(document.getElementById(`Partita${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].text)
                }
            }
        }
        this.contaDuplicatiPartiteDescrizioni(partitaVal);
        this.partiteScelteNelleStime(args);
    }
    contaDuplicatiPartiteDescrizioni(a) {
        let counts = {}
        let idproprieta = [];
        for (let i = 0; i < a.length; i++) {
            if (counts[a[i]]) {
                counts[a[i]] += 1
            } else {
                counts[a[i]] = 1
            }
        }
        for (let prop in counts) {
            if (counts[prop] >= 2) {
                for (let index = 0; index < this.numComboPartite; index++) {
                    if (!CustomJS.isNullEmpty(document.getElementById(`Partita${index}_${this.props.IdInternoSinistro}`))) {
                        if (document.getElementById(`Partita${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].text === prop) {
                            idproprieta.push(`DescrizionePartita${index}_${this.props.IdInternoSinistro}`);
                        }
                    }
                }
            }
        }
        for (let index = 0; index < this.numComboPartite; index++) {
            if (!CustomJS.isNullEmpty(document.getElementById(`DescrizionePartita${index}_${this.props.IdInternoSinistro}`))) {
                document.getElementById(`DescrizionePartita${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].placeholder = "Descrizione (Facoltativo)";
                document.getElementById(`DescrizionePartita${index}_${this.props.IdInternoSinistro}`).style.backgroundColor = '#edf0fa';
                delete this.formOptions.rules[`DescrizionePartita${index}_${this.props.IdInternoSinistro}`];
            }
        }
        for (let index = 0; index < idproprieta.length; index++) {
            document.getElementById(idproprieta[index]).ej2_instances[0].placeholder = "Descrizione Obbligatoria!";
            document.getElementById(idproprieta[index]).style.backgroundColor = '#ffcccc';
            document.getElementById(idproprieta[index]).style.borderRadius = '10px';
            this.formOptions.rules[idproprieta[index]] = {
                required: [true, "Descrizione è un campo obbligatorio!"]
            }
        }
    }
    partiteScelteNelleStime() {
        let arrayPartite = []
        let obj = {}
        let descr = ''
        let guidGen = '';
        let guidTemp = '';
        for (let index = 0; index < this.numComboPartite; index++) {
            if (document.getElementById(`containerComboPartita${index}_${this.props.IdInternoSinistro}`)) {
                if (!CustomJS.isNullEmpty(document.getElementById(`Partita${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)) {
                    this.strutturaStatiPartite.forEach(elm => {
                        if (elm.id === `containerComboPartita${index}_${this.props.IdInternoSinistro}`) {
                            guidGen = elm.guid_partita;
                            guidTemp = elm.guidTemp;
                            return;
                        }
                    });
                    let descrizionePartita = CustomJS.isNullEmpty(document.getElementById(`DescrizionePartita${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : (' (' + document.getElementById(`DescrizionePartita${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value + ')');
                    obj = {
                        TEXT: document.getElementById(`Partita${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].text + descrizionePartita,
                        VALUE: guidTemp,
                        GUID: guidGen,
                        cod_ele: document.getElementById(`Partita${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                    }
                    arrayPartite.push(obj)
                }
            }
        }
        for (let index = 0; index < this.numComboStime; index++) {
            if (document.getElementById(`PartitaTabellaStima${index}_${this.props.IdInternoSinistro}`)) {
                document.getElementById(`PartitaTabellaStima${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource = arrayPartite;
                let esiste = false;

                document.getElementById(`PartitaTabellaStima${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource.forEach(element => {
                    if (element.VALUE === document.getElementById(`PartitaTabellaStima${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                        esiste = true;
                    }
                });
                if (esiste === false) {
                    document.getElementById(`PartitaTabellaStima${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
                }
            }
        }
    }
    descrizionePartiteChange(num, args) {
        if (!CustomJS.isNullEmpty(document.getElementById(`DescrizionePartita${num}_${this.props.IdInternoSinistro}`).ej2_instances[0]) && document.getElementById(`DescrizionePartita${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].placeholder === "Descrizione Obbligatoria!") {
            document.getElementById(`DescrizionePartita${num}_${this.props.IdInternoSinistro}`).style.backgroundColor = '#edf0fa';
        }
        this.partiteScelteNelleStime();
    }

    //------------------GESTIONE PARTITE FINE------------------


    //------------------GESTIONE GARANZIE INIZIO------------------
    aggiungiGaranzia(retDataGaranzieG, casoIniziale) {
        const DivContainer = document.getElementById(`tutteComboGaranzie_${this.props.IdInternoSinistro}`);
        const newDiv = document.createElement("div");
        let numG = this.numComboGaranzie;
        ++this.numComboGaranzie;
        newDiv.setAttribute("id", `containerComboGaranzia${numG}_${this.props.IdInternoSinistro}`);
        newDiv.setAttribute("class", `container-combo-periziaOnline-p1`);
        DivContainer.append(newDiv);

        const container = document.getElementById(`containerComboGaranzia${numG}_${this.props.IdInternoSinistro}`)
        const root = createRoot(container);
        root.render(<CallbackWrapper callback={() => {
            if (CustomJS.isNullEmpty(retDataGaranzieG)) {
                let objStrutturaStatiGaranzie = {
                    id_incarico: this.props.IdIncarico,
                    importato: false,
                    stato: 'n',
                    statoIniziale: 'n',
                    guid_garanzia: CustomJS.createGuid4(),
                    guidTemp: CustomJS.createGuid4(),
                    key: null,
                    id: `containerComboGaranzia${numG}_${this.props.IdInternoSinistro}`,
                    casoIniziale: false,
                    num: numG
                };
                this.strutturaStatiGaranzie.push(objStrutturaStatiGaranzie)

                if (this.isCompagniaGenerali()) {
                    let numFirstChild = document.getElementById(`tutteComboGaranzie_${this.props.IdInternoSinistro}`).firstChild.id;
                    numFirstChild = numFirstChild.replace("containerComboGaranzia", "").replace(`_${this.props.IdInternoSinistro}`, "");
                    document.getElementById(`GaranziaRicevuta${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`GaranziaRicevuta${numFirstChild}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                }
            }
            else {
                if (casoIniziale === 'casoIniziale') {
                    document.getElementById(`GaranziaRicevuta${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.tipoGaranzia_codice.trim();
                    document.getElementById(`GruppoGaranzia${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.gruppo_garanzia?.trim();
                    document.getElementById(`partitaAssicurata${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.partita_assicurata?.trim();
                    document.getElementById(`FormaAssicurazione${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.formaAssicurazione_codice?.trim();
                    document.getElementById(`FormulaAssicurazione${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.formulaAssicurazione_codice?.trim();

                    let objStrutturaStatiGaranzie = {
                        id_incarico: this.props.IdIncarico,
                        importato: retDataGaranzieG.importato,
                        stato: 'o',
                        statoIniziale: 'o',
                        guidTemp: CustomJS.createGuid4(),
                        guid_garanzia: retDataGaranzieG.id_garanzia,
                        key: null,
                        id: `containerComboGaranzia${numG}_${this.props.IdInternoSinistro}`,
                        casoIniziale: true,
                        num: numG
                    };
                    this.strutturaStatiGaranzie.push(objStrutturaStatiGaranzie)
                }
                else {
                    document.getElementById(`Garanzia${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.cod_ele_garanzia;
                    document.getElementById(`DeterminazioneDanno${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.id_determinazioneDanno;
                    document.getElementById(`LimiteIndennizzoEuro${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.limiteIndennizzoEuro;
                    document.getElementById(`LimiteIndennizzoPer${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.limitePer;
                    document.getElementById(`FranchigiaEuro${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.franchigiaEuro;
                    document.getElementById(`FranchigiaGiorni${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.franchigiaGiorni;
                    document.getElementById(`ScopertoPerc${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.scopertoPerc;
                    document.getElementById(`ScopertoMinimo${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.scopertoMin;
                    document.getElementById(`ScopertoMassimo${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.scopertoMax;

                    //BLOCCO
                    document.getElementById(`Garanzia${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    document.getElementById(`DeterminazioneDanno${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    document.getElementById(`LimiteIndennizzoEuro${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    document.getElementById(`LimiteIndennizzoPer${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    document.getElementById(`LimiteIndennizzoGiorni${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    document.getElementById(`FranchigiaEuro${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    document.getElementById(`FranchigiaGiorni${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    document.getElementById(`ScopertoPerc${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    document.getElementById(`ScopertoMinimo${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    document.getElementById(`ScopertoMassimo${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    document.getElementById(`aggiungiGaranzia${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                    document.getElementById(`eliminaGaranzia${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true

                    //GENERALI
                    if (this.isCompagniaGenerali()) {
                        document.getElementById(`GaranziaRicevuta${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.tipoGaranzia_codice.trim();
                        document.getElementById(`GruppoGaranzia${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.gruppo_garanzia?.trim();
                        document.getElementById(`partitaAssicurata${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.partita_assicurata?.trim();
                        document.getElementById(`FormulaAssicurazione${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.formulaAssicurazione_codice?.trim();
                        document.getElementById(`FormaAssicurazione${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.formaAssicurazione_codice?.trim();
                        document.getElementById(`SommaAssicurata${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.somma_assicurata;
                        //BLOCCO GENERALI
                        document.getElementById(`GruppoGaranzia${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                        document.getElementById(`partitaAssicurata${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                        document.getElementById(`FormulaAssicurazione${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                        document.getElementById(`FormaAssicurazione${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                        document.getElementById(`SommaAssicurata${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    }
                    let objStrutturaStatiGaranzie = {
                        id_incarico: this.props.IdIncarico,
                        importato: retDataGaranzieG.importato,
                        stato: 'o',
                        statoIniziale: 'o',
                        guidTemp: retDataGaranzieG.guid_garanzia_temp,
                        guid_garanzia: retDataGaranzieG.id_garanzia,
                        key: retDataGaranzieG.id_garanziaPeriziaOnline,
                        id: `containerComboGaranzia${numG}_${this.props.IdInternoSinistro}`,
                        casoIniziale: false,
                        num: numG
                    };
                    this.strutturaStatiGaranzie.push(objStrutturaStatiGaranzie)
                    setTimeout(() => {
                        document.getElementById(`Garanzia${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.GUID = retDataGaranzieG.id_garanzia;
                        setTimeout(() => {
                            this.onChangeGaranzia(numG, 'fromCaricaDati', '')
                            document.getElementById(`FranchigiaCondivisa${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataGaranzieG.franchigiaCondivisa;
                            //BLOCCO
                            document.getElementById(`FranchigiaCondivisa${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                        }, 200)
                    }, 100)
                }

                if (this.isCompagniaGenerali()) {
                    document.getElementById(`GaranziaRicevuta${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    //SE E' IMPORTATO NON E' ELIMINABILE!
                    if (retDataGaranzieG.importato === 1 || retDataGaranzieG.importato === true) {
                        document.getElementById(`garanziaImportata${numG}_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-arrow-circle-up"></i>'
                        if (document.getElementById(`eliminaGaranzia${numG}_${this.props.IdInternoSinistro}`)) {
                            document.getElementById(`eliminaGaranzia${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                        }
                    }
                }
            }
        }}><>
                <div className='row'>
                    <p className='importatoGen' title='Garanzia importata' id={`garanziaImportata${numG}_${this.props.IdInternoSinistro}`}></p>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" style={{ display: this.isCompagniaGenerali() ? '' : 'none' }}>
                        <DropDownListComponent id={`GaranziaRicevuta${numG}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsGaranzieRicevuteGen}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Garanzia Ricevuta" floatLabelType='Auto' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className={this.isCompagniaGenerali() ? "col-xs-2 col-sm-2 col-lg-2 col-md-2" : "col-xs-4 col-sm-4 col-lg-4 col-md-4"} >
                        <p className='mexErrGaranzia' id={`messaggioErroreDoppiaGaranzia${numG}_${this.props.IdInternoSinistro}`}><br></br></p>
                        <DropDownListComponent onChange={this.onChangeGaranzia.bind(this, numG, '')} id={`Garanzia${numG}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsGaranzie}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Garanzia" floatLabelType='Auto' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" >
                        <DropDownListComponent id={`DeterminazioneDanno${numG}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsDeterminazioneDanno}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Determinazione Del Danno" floatLabelType='Auto' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" id={`boxFranchigiaCondivisa${numG}_${this.props.IdInternoSinistro}`}>
                        <p className='mexErrGaranzia' id={`messaggioErroreStessaFranchigia${numG}_${this.props.IdInternoSinistro}`}><br></br></p>
                        <DropDownListComponent onChange={this.trasferisciFranchigiaCondivisa.bind(this, numG)} id={`FranchigiaCondivisa${numG}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsFranchigiaCondivisa}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Franchigia/Scopertura Condivisa" floatLabelType='Auto' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ display: this.isCompagniaGenerali() ? '' : 'none' }} >
                        <DropDownListComponent id={`GruppoGaranzia${numG}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsGruppiGaranzia}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Gruppo Garanzia" floatLabelType='Auto' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ display: this.isCompagniaGenerali() ? '' : 'none' }}>
                        <DropDownListComponent id={`partitaAssicurata${numG}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsPartiteRicGen}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Partita Assicurata" floatLabelType='Auto' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{ display: this.isCompagniaGenerali() ? '' : 'none' }}>
                        <DropDownListComponent id={`FormulaAssicurazione${numG}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsFormulaAssicurazioni}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Formula Assicurazione" floatLabelType='Auto' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{ display: this.isCompagniaGenerali() ? '' : 'none' }}>
                        <DropDownListComponent id={`FormaAssicurazione${numG}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsFormaGaranzia.slice(0, 2)}
                            allowFiltering={true} filterType='Contains' showClearButton={true} popupWidth='350px'
                            placeholder="Forma Assicurazione" floatLabelType='Auto' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{ display: this.isCompagniaGenerali() ? '' : 'none' }}>
                        <NumericTextBoxComponent showSpinButton={false} id={`SommaAssicurata${numG}_${this.props.IdInternoSinistro}`} onChange={() => { document.getElementById(`LimiteIndennizzoEuro${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`SommaAssicurata${numG}_${this.props.IdInternoSinistro}`).ej2_instances[0].value }} floatLabelType='Auto' format="c2" decimals='2' placeholder='Somma Assicurata' cssClass='e-outline'></NumericTextBoxComponent >
                    </div>
                </div>
                <div className='row'>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" >
                        <div tabIndex="0" className="e-card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                            <div className="e-card-content" id={`cardContentLimiti${numG}_${this.props.IdInternoSinistro}`} style={{ backgroundColor: '#e5ebf3' }}>
                                <div className='row'>
                                    <h5 className="card-title" style={{ color: 'black', fontWeight: '400', paddingBottom: '15px', display: 'flex', justifyContent: 'center' }}>Limite Indennizzo</h5>
                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                        <NumericTextBoxComponent showSpinButton={false} id={`LimiteIndennizzoEuro${numG}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' placeholder='Limite Indennizzo €' cssClass='e-outline'></NumericTextBoxComponent >
                                    </div>
                                    <div id={`LimiteIndennizzoGiorniDiv${numG}_${this.props.IdInternoSinistro}`} style={{ display: 'none' }} className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                        <TextBoxComponent id={`LimiteIndennizzoGiorni${numG}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' placeholder='GG' cssClass='e-outline'></TextBoxComponent>
                                    </div>
                                    <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5">
                                        <DropDownListComponent id={`LimiteIndennizzoPer${numG}_${this.props.IdInternoSinistro}`} value={'sinistro'} dataSource={this.dsLimiteIndennizzo} fields={this.ddlFields}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Tipo Limite" floatLabelType='Auto' cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                    <p style={{ color: 'red', fontWeight: '500', fontSize: '12.5px' }} id={`mexErrMettIndennizzo${numG}_${this.props.IdInternoSinistro}`}><br></br></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                        <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                            <div className="e-card-content" id={`cardContentFranchigie${numG}_${this.props.IdInternoSinistro}`} style={{ backgroundColor: '#e5ebf3' }}>
                                <div className='row'>
                                    <h5 className="card-title" style={{ color: 'black', fontWeight: '400', paddingBottom: '15px', display: 'flex', justifyContent: 'center' }}><h5 id={`sceltaFranchigia${numG}_${this.props.IdInternoSinistro}`}>→</h5> Franchigia</h5>
                                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                        <NumericTextBoxComponent showSpinButton={false} id={`FranchigiaEuro${numG}_${this.props.IdInternoSinistro}`} onChange={this.bloccaFranchigiaOScopertura.bind(this, numG, 'franchigia')} onBlur={this.modificaFranchigiaETrasporta.bind(this, numG)} floatLabelType='Auto' format="c2" decimals='2' placeholder='Franchigia' value={0} cssClass='e-outline'></NumericTextBoxComponent >
                                    </div>
                                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                        <TextBoxComponent id={`FranchigiaGiorni${numG}_${this.props.IdInternoSinistro}`} onChange={this.bloccaFranchigiaOScopertura.bind(this, numG, 'franchigia')} onBlur={this.modificaFranchigiaETrasporta.bind(this, numG)} decimals='0' type='number' floatLabelType='Auto' placeholder='GG' cssClass='e-outline'></TextBoxComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                            <div className="e-card-content" id={`cardContentScoperti${numG}_${this.props.IdInternoSinistro}`} style={{ backgroundColor: '#e5ebf3' }} >
                                <div className='row'>
                                    <h5 className="card-title" style={{ color: 'black', fontWeight: '400', paddingBottom: '15px', display: 'flex', justifyContent: 'center' }}><h5 id={`sceltaScoperto${numG}_${this.props.IdInternoSinistro}`}></h5> Scoperto</h5>
                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                        <NumericTextBoxComponent showSpinButton={false} id={`ScopertoPerc${numG}_${this.props.IdInternoSinistro}`} onBlur={this.modificaScopertoETrasporta.bind(this, numG)} onChange={this.bloccaFranchigiaOScopertura.bind(this, numG, 'scoperto')} floatLabelType='Auto' decimals='2' min={0} format="#.## '%'" placeholder='Scoperto %' cssClass='e-outline'></NumericTextBoxComponent >
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                        <NumericTextBoxComponent showSpinButton={false} id={`ScopertoMinimo${numG}_${this.props.IdInternoSinistro}`} onBlur={this.modificaScopertoETrasporta.bind(this, numG)} onChange={this.bloccaFranchigiaOScopertura.bind(this, numG, 'scoperto')} floatLabelType='Auto' format="c2" decimals='2' placeholder='Scoperto Min' cssClass='e-outline'></NumericTextBoxComponent >
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                        <NumericTextBoxComponent showSpinButton={false} id={`ScopertoMassimo${numG}_${this.props.IdInternoSinistro}`} onBlur={this.modificaScopertoETrasporta.bind(this, numG)} onChange={this.bloccaFranchigiaOScopertura.bind(this, numG, 'scoperto')} floatLabelType='Auto' format="c2" decimals='2' placeholder='Scoperto Max' cssClass='e-outline'></NumericTextBoxComponent >
                                    </div>
                                    <h6 id={`scopertoMinMaxErr${numG}_${this.props.IdInternoSinistro}`} style={{ fontSize: '12px', color: 'red', display: 'none', justifyContent: 'center' }}>Il minimo deve essere minore del massimo!</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1" width='5%'>
                        <div className="eliminaGaranzAgg">
                            <ButtonComponent id={`eliminaGaranzia${numG}_${this.props.IdInternoSinistro}`} onClick={this.eliminaGaranzia.bind(this, `containerComboGaranzia${numG}_${this.props.IdInternoSinistro}`, numG)} title='Elimina' cssClass='e-fill e-danger' type='button'>X</ButtonComponent>
                            <ButtonComponent style={{ marginLeft: '15px' }} id={`aggiungiGaranzia${numG}_${this.props.IdInternoSinistro}`} onClick={this.aggiungiGaranzia.bind(this, '', '')} title='Aggiungi' cssClass='e-fill e-info' type='button'>+</ButtonComponent>
                        </div>
                    </div>
                </div>
            </></CallbackWrapper>);


    }
    onChangeGaranzia(num, fromCaricaDati, args) {
        if (!CustomJS.isNullEmpty(args)) {
            //TG005, 74, 4 è la garanzia Evento Atmosferico
            if (document.getElementById(`Garanzia${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === "TG005" ||
                document.getElementById(`Garanzia${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '53')
                document.getElementById(`LimiteIndennizzoGiorniDiv${num}_${this.props.IdInternoSinistro}`).style.removeProperty('display')
            else
                document.getElementById(`LimiteIndennizzoGiorniDiv${num}_${this.props.IdInternoSinistro}`).style.display = 'none';
            for (let index = 0; index < this.numComboGaranzie; index++) {
                if (document.getElementById(`Garanzia${index}_${this.props.IdInternoSinistro}`)) {
                    if (num !== index) {
                        if (document.getElementById(`Garanzia${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === document.getElementById(`Garanzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                            //RIMOSSO TEMPORANEAMENTE IN MODO TALE DA TESTARE SE E'GESTITO OPPURE CREA PROBLEMI
                            // document.getElementById(`messaggioErroreDoppiaGaranzia${num}_${this.props.IdInternoSinistro}`).innerHTML = 'Non puoi inserire una garanzia due volte!';
                            // document.getElementById(`Garanzia${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
                            // setTimeout(() => {
                            //     if (document.getElementById(`messaggioErroreDoppiaGaranzia${num}_${this.props.IdInternoSinistro}`))
                            //         document.getElementById(`messaggioErroreDoppiaGaranzia${num}_${this.props.IdInternoSinistro}`).innerHTML = '</br>';
                            // }, 3500)
                        }
                    }
                }
            }
        }
        let arrayV = []
        let obj = {}
        for (let index = 0; index < this.numComboGaranzie; index++) {
            if (document.getElementById(`Garanzia${index}_${this.props.IdInternoSinistro}`)) {
                if (index === 0) {
                    obj = {
                        TEXT: document.getElementById(`Garanzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                        VALUE: document.getElementById(`Garanzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                    }
                    if (!CustomJS.isNullEmpty(obj.TEXT))
                        arrayV.push(obj)
                }
                else {
                    if (document.getElementById(`Garanzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].text !== document.getElementById(`FranchigiaCondivisa${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].text && index !== num) {
                        obj = {
                            TEXT: document.getElementById(`Garanzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                            VALUE: document.getElementById(`Garanzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                        }
                        if (!CustomJS.isNullEmpty(obj.TEXT))
                            arrayV.push(obj)
                    }
                }
            }
            if (!CustomJS.isNullEmpty(document.getElementById(`FranchigiaCondivisa${index}_${this.props.IdInternoSinistro}`))) {
                document.getElementById(`FranchigiaCondivisa${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource = arrayV
            }
        }
        if (fromCaricaDati !== 'fromCaricaDati') {
            this.garanzieScelteNelleStime();
            this.sinistroELeSueModalitaEMezziChiusuraSiNO();
        }
        if (this.isCompagniaGenerali()) {
            let codEle = document.getElementById(`Garanzia${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            let codGruppo = ''
            this.dsGaranzie.forEach(elm => {
                if (elm.VALUE === codEle) {
                    codGruppo = elm.servizio_str2;
                }
            })
            document.getElementById(`GruppoGaranzia${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = codGruppo;
        }
    }
    garanzieScelteNelleStime(args) {
        let arrayGaranzie = []
        let obj = {}
        let guidGen = '';
        let guidTemp = '';
        for (let index = 0; index < this.numComboGaranzie; index++) {
            if (document.getElementById(`containerComboGaranzia${index}_${this.props.IdInternoSinistro}`)) {
                if (!CustomJS.isNullEmpty(document.getElementById(`Garanzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)) {
                    this.strutturaStatiGaranzie.forEach(elm => {
                        if (elm.id === `containerComboGaranzia${index}_${this.props.IdInternoSinistro}`) {
                            guidGen = elm.guid_garanzia;
                            guidTemp = elm.guidTemp;
                            return;
                        }
                    })
                    obj = {
                        TEXT: document.getElementById(`Garanzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                        VALUE: guidTemp,
                        GUID: guidGen,
                        cod_ele: document.getElementById(`Garanzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                    }
                    arrayGaranzie.push(obj)
                }
            }
        }
        for (let index = 0; index < this.numComboStime; index++) {
            if (document.getElementById(`containerComboStima${index}_${this.props.IdInternoSinistro}`)) {
                document.getElementById(`GaranziaTabellaStima${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource = arrayGaranzie;

                let esiste = false;
                document.getElementById(`GaranziaTabellaStima${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource.forEach(element => {
                    if (element.VALUE === document.getElementById(`GaranziaTabellaStima${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                        esiste = true;
                    }
                });
                if (esiste === false) {
                    document.getElementById(`GaranziaTabellaStima${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
                }
            }
        }
    }
    eliminaGaranzia(idContainerDaElimin, indice, args) {
        delete this.formOptions.rules[`Garanzia${indice}_${this.props.IdInternoSinistro}`];
        delete this.formOptions.rules[`DeterminazioneDanno${indice}_${this.props.IdInternoSinistro}`];

        if (this.isCompagniaGenerali()) {
            delete this.formOptions.rules[`GaranziaRicevuta${indice}_${this.props.IdInternoSinistro}`];
            delete this.formOptions.rules[`GruppoGaranzia${indice}_${this.props.IdInternoSinistro}`];
            delete this.formOptions.rules[`partitaAssicurata${indice}_${this.props.IdInternoSinistro}`];
            delete this.formOptions.rules[`FormulaAssicurazione${indice}_${this.props.IdInternoSinistro}`];
            delete this.formOptions.rules[`FormaAssicurazione${indice}_${this.props.IdInternoSinistro}`];
        }

        let containerDaElimin = document.getElementById(idContainerDaElimin);
        let containerGenitore = document.getElementById(`tutteComboGaranzie_${this.props.IdInternoSinistro}`);
        containerGenitore.removeChild(containerDaElimin);
        this.garanzieScelteNelleStime()

        this.strutturaStatiGaranzie.forEach(elm => {
            if (elm.id === idContainerDaElimin)
                elm.stato = 'd';
        });
        this.strutturaStatiGaranzie.filter(this.rimuoviDaStrutturaStatiElementoNuovoEliminato)
        this.garanzieScelteNelleStime();
    }

    //------------------GESTIONE GARANZIE FINE------------------

    //------------------GESTIONE STIME INIZIO------------------
    switchDanneggiato(numTS) {
        if (document.getElementById(`btnSwitchDanneggiato${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].children === '+') {
            document.getElementById(`btnSwitchDanneggiato${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].children = '←';
            document.getElementById(`btnSwitchDanneggiato${numTS}_${this.props.IdInternoSinistro}`).innerText = '←';
            document.getElementById(`DanneggiatoTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;

            document.getElementById(`contentDanneggiatiEsistenti${numTS}_${this.props.IdInternoSinistro}`).style.display = 'none'
            document.getElementById(`contentAggDanneggiato${numTS}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        }
        else if (document.getElementById(`btnSwitchDanneggiato${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].children === '←') {
            document.getElementById(`btnSwitchDanneggiato${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].children = '+';
            document.getElementById(`btnSwitchDanneggiato${numTS}_${this.props.IdInternoSinistro}`).innerText = '+';
            document.getElementById(`DanneggiatoAggiunto${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '';
            document.getElementById(`contentAggDanneggiato${numTS}_${this.props.IdInternoSinistro}`).style.display = 'none'
            document.getElementById(`contentDanneggiatiEsistenti${numTS}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        }
    }
    aggiungiStima(retDataStimeS, retDataDanneggiatiD, fromCaricaDanneggiati) {
        console.log(retDataStimeS)
        console.log(retDataDanneggiatiD)
        console.log(fromCaricaDanneggiati)

        let numTS = this.numComboStime;
        ++this.numComboStime;

        const DivContainer = document.getElementById(`tutteTabelleStima_${this.props.IdInternoSinistro}`);
        const newDiv = document.createElement("div");
        newDiv.setAttribute("id", `containerComboStima${numTS}_${this.props.IdInternoSinistro}`);
        newDiv.setAttribute("class", "container-combo-periziaOnline-p1")
        DivContainer.append(newDiv);

        const container = document.getElementById(`containerComboStima${numTS}_${this.props.IdInternoSinistro}`)
        const root = createRoot(container);
        root.render(<CallbackWrapper callback={() => {
            if (numTS === 0) {
                document.getElementById(`eliminaStima0_${this.props.IdInternoSinistro}`).style.display = 'none'
            }
            if (fromCaricaDanneggiati !== true)
                this.cambiaPartita();

            if (CustomJS.isNullEmpty(retDataStimeS)) {
                if (fromCaricaDanneggiati !== true)
                    this.garanzieScelteNelleStime();
            }
            if (CustomJS.isNullEmpty(retDataStimeS) && CustomJS.isNullEmpty(retDataDanneggiatiD)) {
                let objStrutturaStatiStime = {
                    id_incarico: this.props.IdIncarico,
                    stato: 'n',
                    statoIniziale: 'n',
                    keyJftech: null,
                    keyGenerali: null,
                    id: `containerComboStima${numTS}_${this.props.IdInternoSinistro}`,
                    num: numTS,
                    danneggiatoEliminato: 0
                };
                this.strutturaStatiStime.push(objStrutturaStatiStime)
            }
            else if (!CustomJS.isNullEmpty(retDataDanneggiatiD)) {
                document.getElementById(`DanneggiatoTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource = this.dsDanneggiati;
                document.getElementById(`DanneggiatoTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataDanneggiatiD.id_danneggiato_jftech

                //CASO INIZIALE per i danneggiati
                let objStrutturaStatiStime = {
                    id_incarico: this.props.IdIncarico,
                    stato: 'n',
                    statoIniziale: 'n',
                    keyJftech: null,
                    keyGenerali: null,
                    id: `containerComboStima${numTS}_${this.props.IdInternoSinistro}`,
                    num: numTS,
                    danneggiatoEliminato: 0
                };
                this.strutturaStatiStime.push(objStrutturaStatiStime)

                if (retDataDanneggiatiD.importato === 1 || retDataDanneggiatiD.importato === true) {
                    document.getElementById(`danneggiatoImportato${numTS}_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-arrow-circle-up"></i>'
                    if (document.getElementById(`eliminaStima${numTS}_${this.props.IdInternoSinistro}`)) {
                        document.getElementById(`eliminaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                    }
                    document.getElementById(`DanneggiatoTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                    document.getElementById(`btnSwitchDanneggiato${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                }
            }
            else {
                document.getElementById(`DanneggiatoTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource = this.dsDanneggiati;
                let objStrutturaStatiStime = {
                    id_incarico: this.props.IdIncarico,
                    stato: 'o',
                    statoIniziale: 'o',
                    keyJftech: retDataStimeS.id_stima,
                    keyGenerali: retDataStimeS.id_danno,
                    id: `containerComboStima${numTS}_${this.props.IdInternoSinistro}`,
                    num: numTS,
                    danneggiatoEliminato: 0
                };
                this.strutturaStatiStime.push(objStrutturaStatiStime)

                let textP = '';
                this.dsPartite.forEach(elm => {
                    if (elm.VALUE === retDataStimeS.cod_ele_partita) { textP = elm.TEXT }
                });
                textP = CustomJS.isNullEmpty(retDataStimeS.descrizione) ? textP : textP + ' (' + retDataStimeS.descrizione + ')'
                let objP = {
                    TEXT: textP,
                    VALUE: retDataStimeS.guid_partita_temp,
                    GUID: retDataStimeS.id_partita_colpita,
                    cod_ele: retDataStimeS.cod_ele_partita
                }
                this.dataSourcePartite.push(objP)

                let textG = '';
                this.dsGaranzie.forEach(elm => {
                    if (elm.VALUE === retDataStimeS.cod_ele_garanzia) { textG = elm.TEXT }
                });
                let objG = {
                    TEXT: textG,
                    VALUE: retDataStimeS.guid_garanzia_temp,
                    GUID: retDataStimeS.id_garanzia_colpita,
                    cod_ele: retDataStimeS.cod_ele_garanzia
                }
                this.dataSourceGaranzie.push(objG)

                setTimeout(() => {
                    document.getElementById(`PartitaTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource = this.dataSourcePartite;
                    document.getElementById(`GaranziaTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource = this.dataSourceGaranzie;

                    setTimeout(() => {
                        document.getElementById(`DanneggiatoTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeS.id_danneggiato;
                        document.getElementById(`PartitaTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeS.guid_partita_temp;
                        document.getElementById(`GaranziaTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeS.guid_garanzia_temp;

                        //BLOCCO
                        document.getElementById(`DanneggiatoTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                        document.getElementById(`PartitaTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                        document.getElementById(`GaranziaTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                        document.getElementById(`btnSwitchDanneggiato${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;

                        document.getElementById(`aggiungiStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                        document.getElementById(`eliminaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true

                        if (retDataStimeS.importato === 1 || retDataStimeS.importato === true) {
                            document.getElementById(`danneggiatoImportato${numTS}_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-arrow-circle-up"></i>'
                        }
                        setTimeout(() => {
                            document.getElementById(`DanneggiatoTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].GUID = retDataStimeS.id_danneggiato_colpito;
                            document.getElementById(`DanneggiatoTabellaStima${numTS}_${this.props.IdInternoSinistro}`).ej2_instances[0].IMPORTATO = true;
                        }, 250)
                    }, 100)
                }, 300)
            }
        }}><div className='row'>
                <p className='importatoGen' title='Danneggiato importato' id={`danneggiatoImportato${numTS}_${this.props.IdInternoSinistro}`}> </p>
                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                    <div className='row'>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <ButtonComponent id={`btnSwitchDanneggiato${numTS}_${this.props.IdInternoSinistro}`} alt='Switch Danneggiato' cssClass='e-fill e-info' type='button' onClick={this.switchDanneggiato.bind(this, numTS)}>+</ButtonComponent>
                        </div>
                        <div id={`contentDanneggiatiEsistenti${numTS}_${this.props.IdInternoSinistro}`} className="col-xs-10 col-sm-10 col-lg-10 col-md-10">
                            <DropDownListComponent onChange={this.stessaComboTabStima.bind(this, numTS)} id={`DanneggiatoTabellaStima${numTS}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsDanneggiati}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="Danneggiato" floatLabelType='Auto' cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div id={`contentAggDanneggiato${numTS}_${this.props.IdInternoSinistro}`} className="col-xs-10 col-sm-10 col-lg-10 col-md-10" style={{ display: 'none' }}>
                            <TextBoxComponent id={`DanneggiatoAggiunto${numTS}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' placeholder='Aggiungi Danneggiato' cssClass='e-outline'></TextBoxComponent>
                        </div>
                        <h6 id={`mexErroreDanneggiato${numTS}_${this.props.IdInternoSinistro}`} style={{ color: '#f44336', display: 'none', fontFamily: 'Roboto', fontSize: '12px' }}>Danneggiato (Stime) è un campo obbligatorio!</h6>
                    </div>
                </div>
                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                    <DropDownListComponent onChange={this.stessaComboTabStima.bind(this, numTS)} id={`PartitaTabellaStima${numTS}_${this.props.IdInternoSinistro}`} fields={this.ddlFields}
                        allowFiltering={true} filterType='Contains' showClearButton={true}
                        placeholder="Partita" floatLabelType='Auto' cssClass='e-outline'>
                    </DropDownListComponent>
                </div>
                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                    <DropDownListComponent onChange={this.stessaComboTabStima.bind(this, numTS)} id={`GaranziaTabellaStima${numTS}_${this.props.IdInternoSinistro}`} fields={this.ddlFields}
                        allowFiltering={true} filterType='Contains' showClearButton={true}
                        placeholder="Garanzia" floatLabelType='Auto' cssClass='e-outline'>
                    </DropDownListComponent>
                    <p id={`mexErroreStessaStima${numTS}_${this.props.IdInternoSinistro}`} style={{ color: 'red', fontWeight: '400' }}><br /></p>
                </div>

                <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1">
                    <ButtonComponent id={`eliminaStima${numTS}_${this.props.IdInternoSinistro}`} onClick={this.eliminaStima.bind(this, `containerComboStima${numTS}_${this.props.IdInternoSinistro}`)} title='Elimina' cssClass='e-fill e-danger' type='button'>X</ButtonComponent>
                    <ButtonComponent style={{ marginLeft: '15px' }} id={`aggiungiStima${numTS}_${this.props.IdInternoSinistro}`} onClick={this.aggiungiStima.bind(this, '', false)} title='Aggiungi' cssClass='e-fill e-info' type='button'>+</ButtonComponent>

                </div>
            </div></CallbackWrapper>);
    }
    eliminaStima(idContainerDaElimin, args) {
        for (let index = 0; index < this.numComboStime; index++) {
            if (document.getElementById(`containerComboStima${index}_${this.props.IdInternoSinistro}`)) {
                // if (document.getElementById(`btnSwitchDanneggiato${index}`).ej2_instances[0].children === '+') {
                //     delete this.formOptions.rules[`DanneggiatoTabellaStima${index}_${this.props.IdInternoSinistro}`];
                // }
                // else if (document.getElementById(`btnSwitchDanneggiato${index}`).ej2_instances[0].children === '←') {
                //     delete this.formOptions.rules[`DanneggiatoAggiunto${index}_${this.props.IdInternoSinistro}`];
                // }
                delete this.formOptions.rules[`PartitaTabellaStima${index}_${this.props.IdInternoSinistro}`];
                delete this.formOptions.rules[`GaranziaTabellaStima${index}_${this.props.IdInternoSinistro}`];
            }
        }
        this.strutturaStatiStime.forEach(elm => {
            if (elm.id === idContainerDaElimin) {
                elm.stato = 'd';
                elm.danneggiatoEliminato = CustomJS.isNullEmpty(document.getElementById(`DanneggiatoTabellaStima${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`DanneggiatoTabellaStima${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
            }
        });
        this.strutturaStatiStime.filter(this.rimuoviDaStrutturaStatiElementoNuovoEliminato)
        let containerDaElimin = document.getElementById(idContainerDaElimin);
        let containerGenitore = document.getElementById(`tutteTabelleStima_${this.props.IdInternoSinistro}`);
        containerGenitore.removeChild(containerDaElimin);
    }

    stessaComboTabStima(num, args) {
        for (let index = 0; index < this.numComboStime; index++) {
            if (document.getElementById(`DanneggiatoTabellaStima${index}_${this.props.IdInternoSinistro}`)) {
                if (index !== num) {
                    if (document.getElementById(`btnSwitchDanneggiato${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].children === '+') {
                        if (document.getElementById(`DanneggiatoTabellaStima${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === document.getElementById(`DanneggiatoTabellaStima${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value &&
                            document.getElementById(`PartitaTabellaStima${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === document.getElementById(`PartitaTabellaStima${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value &&
                            document.getElementById(`GaranziaTabellaStima${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === document.getElementById(`GaranziaTabellaStima${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                            document.getElementById(`GaranziaTabellaStima${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
                            document.getElementById(`mexErroreStessaStima${num}_${this.props.IdInternoSinistro}`).innerHTML = 'Non puoi inserire due volte la stessa stima!'
                            setTimeout(() => {
                                document.getElementById(`mexErroreStessaStima${num}_${this.props.IdInternoSinistro}`).innerHTML = '</br>';
                            }, 3500)
                        }
                    }
                }
            }
        }
    }
    ritiraPeriziaSenzaCalcoli() {
        if (this.periziaSenzaCalcoli === true) {
            //ritorno attivi tutti i buttons
            document.getElementById(`btnPeriziaZeroCalcoli_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false
            document.getElementById(`btnAggiungiPartita_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false
            document.getElementById(`btnAggiungiGaranzia_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false
            document.getElementById(`btnAggiungiStima_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false

            //apro gli accordion
            this.acrdnInstance0.expandItem(true, 0);
            this.acrdnInstance0.expandItem(true, 1);
            this.acrdnInstance0.expandItem(true, 2);

            //aggiungo una riga
            this.aggiungiPartita('', '')
            this.aggiungiGaranzia('', '')
            this.aggiungiStima('', '', false);

            this.periziaSenzaCalcoli = false;
            document.getElementById(`btnPeriziaZeroCalcoliUndo_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
            document.getElementById(`btnPeriziaZeroCalcoli_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
        }
    }
    abilitaPeriziaSenzaCalcoli(isFromCarica, args) {
        this.periziaSenzaCalcoli = true;
        let messaggioErrore = 'Attenzione! ';
        if (isFromCarica === false) {
            //---------INIZIO CONTROLLO SE CE UN DANNEGGIATO RICHIESTO DA GENERALI!!----------
            if (this.isCompagniaGenerali()) {
                for (let p = 0; p < this.numComboPartite; p++) {
                    if (document.getElementById(`containerComboPartita${p}_${this.props.IdInternoSinistro}`)) {
                        if (document.getElementById(`partitaImportata${p}_${this.props.IdInternoSinistro}`).innerHTML === '<i class="fas fa-arrow-circle-up"></i>') {
                            let nomePartita = document.getElementById(`PartitaRicevuta${p}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                            messaggioErrore += "\n" + "La partita " + nomePartita + " è richiesta dalla compagnia Generali!"
                            this.periziaSenzaCalcoli = false;
                        }
                    }
                }
                for (let i = 0; i < this.numComboGaranzie; i++) {
                    if (document.getElementById(`containerComboGaranzia${i}_${this.props.IdInternoSinistro}`)) {
                        if (document.getElementById(`garanziaImportata${i}_${this.props.IdInternoSinistro}`).innerHTML === '<i class="fas fa-arrow-circle-up"></i>') {
                            let nomeGaranzia = document.getElementById(`GaranziaRicevuta${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                            messaggioErrore += "\n" + "La garanzia " + nomeGaranzia + " è richiesta dalla compagnia Generali!"
                            this.periziaSenzaCalcoli = false;
                        }
                    }
                }
                for (let i = 0; i < this.numComboStime; i++) {
                    if (document.getElementById(`containerComboStima${i}_${this.props.IdInternoSinistro}`)) {
                        if (document.getElementById(`danneggiatoImportato${i}_${this.props.IdInternoSinistro}`).innerHTML === '<i class="fas fa-arrow-circle-up"></i>') {
                            let nomeDanneggiato = document.getElementById(`DanneggiatoTabellaStima${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                            messaggioErrore += "\n" + "Il danneggiato " + nomeDanneggiato + " è richiesto dalla compagnia Generali!"
                            this.periziaSenzaCalcoli = false;
                        }
                    }
                }
            }
        }
        //---------FUNZIONE PERIZIA SENZA CALCOLI OK----------
        if (this.periziaSenzaCalcoli === true) {
            //disabilito tutti i buttons
            document.getElementById(`btnPeriziaZeroCalcoli_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
            document.getElementById(`btnAggiungiPartita_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
            document.getElementById(`btnAggiungiGaranzia_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
            document.getElementById(`btnAggiungiStima_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true


            for (let p = 0; p < this.numComboPartite; p++) {
                if (document.getElementById(`containerComboPartita${p}_${this.props.IdInternoSinistro}`)) {
                    this.eliminaPartita(`containerComboPartita${p}_${this.props.IdInternoSinistro}`, p)
                }
            }
            for (let i = 0; i < this.numComboGaranzie; i++) {
                if (document.getElementById(`containerComboGaranzia${i}_${this.props.IdInternoSinistro}`)) {
                    this.eliminaGaranzia(`containerComboGaranzia${i}_${this.props.IdInternoSinistro}`, i);
                }
            }
            for (let i = 0; i < this.numComboStime; i++) {
                if (document.getElementById(`containerComboStima${i}_${this.props.IdInternoSinistro}`)) {
                    this.eliminaStima(`containerComboStima${i}_${this.props.IdInternoSinistro}`, '');
                }
            }
            //chiudo gli accordion
            this.acrdnInstance0.expandItem(false, 0);
            this.acrdnInstance0.expandItem(false, 1);
            this.acrdnInstance0.expandItem(false, 2);

            document.getElementById(`btnPeriziaZeroCalcoliUndo_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
            document.getElementById(`btnPeriziaZeroCalcoli_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
        }
        else {
            toast.error(messaggioErrore, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }
    }

    //------------------GESTIONE STIME FINE------------------

    //------------------GESTIONE FRANCHIGE SCOPERTI INIZIO------------------
    trasferisciFranchigiaCondivisa(num, args) {
        if (document.getElementById(`FranchigiaEuro${num}_${this.props.IdInternoSinistro}`)) {
            if (CustomJS.isNullEmpty(document.getElementById(`FranchigiaEuro${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)) {
                document.getElementById(`FranchigiaEuro${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
                document.getElementById(`FranchigiaGiorni${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
                document.getElementById(`ScopertoPerc${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
                document.getElementById(`ScopertoMinimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
                document.getElementById(`ScopertoMassimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
                document.getElementById(`FranchigiaEuro${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                document.getElementById(`FranchigiaGiorni${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                document.getElementById(`ScopertoPerc${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                document.getElementById(`ScopertoMinimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
                document.getElementById(`ScopertoMassimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
            }
        }
        if (!CustomJS.isNullEmpty(args.value)) {
            for (let index = 0; index < this.numComboGaranzie; index++) {
                if (document.getElementById(`Garanzia${index}`)) {
                    if (document.getElementById(`FranchigiaCondivisa${num}_${this.props.IdInternoSinistro}`).value === document.getElementById(`Garanzia${num}_${this.props.IdInternoSinistro}`).value) {
                        document.getElementById(`FranchigiaCondivisa${num}_${this.props.IdInternoSinistro}`).value = null;
                        document.getElementById(`messaggioErroreStessaFranchigia${num}_${this.props.IdInternoSinistro}`).innerHTML = 'Non puoi inserire la stessa garanzia della franchigia!'
                    }
                    setTimeout(() => {
                        if (!CustomJS.isNullEmpty(document.getElementById(`messaggioErroreStessaFranchigia${num}_${this.props.IdInternoSinistro}`)))
                            document.getElementById(`messaggioErroreStessaFranchigia${num}_${this.props.IdInternoSinistro}`).innerHTML = '</br>';
                    }, 3500)
                    if (!CustomJS.isNullEmpty(document.getElementById(`Garanzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].text)) {
                        if (document.getElementById(`Garanzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === args.value) {
                            document.getElementById(`FranchigiaEuro${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`FranchigiaEuro${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                            document.getElementById(`FranchigiaGiorni${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`FranchigiaGiorni${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                            document.getElementById(`ScopertoPerc${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`ScopertoPerc${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                            document.getElementById(`ScopertoMinimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`ScopertoMinimo${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                            document.getElementById(`ScopertoMassimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`ScopertoMassimo${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                            document.getElementById(`sceltaFranchigia${num}_${this.props.IdInternoSinistro}`).innerText = document.getElementById(`sceltaFranchigia${index}_${this.props.IdInternoSinistro}`).innerText
                            document.getElementById(`sceltaScoperto${num}_${this.props.IdInternoSinistro}`).innerText = document.getElementById(`sceltaScoperto${index}_${this.props.IdInternoSinistro}`).innerText;
                            document.getElementById(`FranchigiaEuro${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                            document.getElementById(`FranchigiaGiorni${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true
                            document.getElementById(`ScopertoPerc${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                            document.getElementById(`ScopertoMinimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                            document.getElementById(`ScopertoMassimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
                        }
                    }
                }
            }
        }
        else {
            document.getElementById(`FranchigiaEuro${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
            document.getElementById(`FranchigiaGiorni${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
            document.getElementById(`ScopertoPerc${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
            document.getElementById(`ScopertoMinimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
            document.getElementById(`ScopertoMassimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
        }
    }
    modificaFranchigiaETrasporta(num) {
        document.getElementById(`sceltaFranchigia${num}_${this.props.IdInternoSinistro}`).innerText = '→';
        document.getElementById(`sceltaScoperto${num}_${this.props.IdInternoSinistro}`).innerText = '';
        for (let index = 0; index < this.numComboGaranzie; index++) {
            if (document.getElementById(`FranchigiaEuro${index}_${this.props.IdInternoSinistro}`)) {
                if (document.getElementById(`FranchigiaCondivisa${index}_${this.props.IdInternoSinistro}`)) {
                    if (document.getElementById(`Garanzia${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === document.getElementById(`FranchigiaCondivisa${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                        document.getElementById(`FranchigiaEuro${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`FranchigiaEuro${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                        document.getElementById(`FranchigiaGiorni${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`FranchigiaGiorni${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                        document.getElementById(`ScopertoPerc${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
                        document.getElementById(`ScopertoMassimo${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
                        document.getElementById(`ScopertoMinimo${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
                        document.getElementById(`sceltaFranchigia${index}_${this.props.IdInternoSinistro}`).innerText = '→';
                        document.getElementById(`sceltaScoperto${index}_${this.props.IdInternoSinistro}`).innerText = '';
                    }
                }
            }
        }
    }
    minimoMinoreDiMassimoCheck(num) {
        let minimo = document.getElementById(`ScopertoMinimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        let massimo = document.getElementById(`ScopertoMassimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        if (!CustomJS.isNullEmpty(minimo) && !CustomJS.isNullEmpty(massimo)) {
            if (minimo < massimo) {
                return true
            }
            else {
                document.getElementById(`scopertoMinMaxErr${num}_${this.props.IdInternoSinistro}`).style.display = 'flex';
                setTimeout(() => {
                    document.getElementById(`scopertoMinMaxErr${num}_${this.props.IdInternoSinistro}`).style.display = 'none';
                }, 3500)
                return false;
            }
        }
        else {
            return true;
        }
    }
    modificaScopertoETrasporta(num) {
        let isOk = this.minimoMinoreDiMassimoCheck(num);
        if (isOk === true) {
            document.getElementById(`sceltaFranchigia${num}_${this.props.IdInternoSinistro}`).innerText = '';
            document.getElementById(`sceltaScoperto${num}_${this.props.IdInternoSinistro}`).innerText = '→';
            for (let index = 0; index < this.numComboGaranzie; index++) {
                if (document.getElementById(`ScopertoPerc${index}_${this.props.IdInternoSinistro}`)) {
                    if (document.getElementById(`FranchigiaCondivisa${index}_${this.props.IdInternoSinistro}`)) {
                        if (document.getElementById(`Garanzia${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === document.getElementById(`FranchigiaCondivisa${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                            document.getElementById(`ScopertoPerc${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`ScopertoPerc${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                            document.getElementById(`ScopertoMassimo${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`ScopertoMassimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                            document.getElementById(`ScopertoMinimo${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`ScopertoMinimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                            document.getElementById(`FranchigiaEuro${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
                            document.getElementById(`FranchigiaGiorni${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
                            document.getElementById(`sceltaFranchigia${index}_${this.props.IdInternoSinistro}`).innerText = '';
                            document.getElementById(`sceltaScoperto${index}_${this.props.IdInternoSinistro}`).innerText = '→';
                        }
                    }
                }
            }
        }
    }
    bloccaFranchigiaOScopertura(num, francOScop, args) {
        if (francOScop === 'scoperto') {
            document.getElementById(`FranchigiaEuro${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
            document.getElementById(`FranchigiaGiorni${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
        }
        else {
            document.getElementById(`ScopertoPerc${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
            document.getElementById(`ScopertoMinimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
            document.getElementById(`ScopertoMassimo${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
        }
    }
    //------------------GESTIONE FRANCHIGE SCOPERTI FINE------------------

    //------------------GESTIONE ACCORDION RISCHIO ASSICURATO INIZIO------------------
    rischioAssicuratoFabbricatoOFotovoltaico(fabbricatoOFotovoltaico, args) {
        if (fabbricatoOFotovoltaico === 'fotovoltaico') {
            document.getElementById(`schedaFabbricato_${this.props.IdInternoSinistro}`).style.display = 'none';
            document.getElementById(`schedaFotovoltaico_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        }
        else if (fabbricatoOFotovoltaico === 'fabbricato') {
            document.getElementById(`schedaFotovoltaico_${this.props.IdInternoSinistro}`).style.display = 'none';
            document.getElementById(`schedaFabbricato_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        }
    }
    rischioAssicuratoConformeSiNo(scelta, args) {
        if (scelta === 'si') {
            document.getElementById(`noteRischioNoConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '';
            document.getElementById(`noteRischioNoConformeDiv_${this.props.IdInternoSinistro}`).style.display = 'none';
        }
        else if (scelta === 'no') {
            document.getElementById(`noteRischioNoConformeDiv_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        }
    }
    generaDescrizioneRischioAssicurato(tipo) {
        if (tipo === 'fabbricato') {
            let tipo = CustomJS.isNullEmpty(document.getElementById(`tipoAbitazioneRischAss_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`tipoAbitazioneRischAss_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
            let piani = CustomJS.isNullEmpty(document.getElementById(`pianiFT_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`pianiFT_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            let pianiStr = (piani === '1' ? 'piano' : 'piani');
            let anni = CustomJS.isNullEmpty(document.getElementById(`costruitoAnniFabbr_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`costruitoAnniFabbr_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            let adibito = CustomJS.isNullEmpty(document.getElementById(`adibitoList_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`adibitoList_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
            adibito = adibito === 'totalita' ? 'totalità' : adibito;
            let adibitoA = CustomJS.isNullEmpty(document.getElementById(`adibitoAList_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`adibitoAList_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
            let strutturaPortante = CustomJS.isNullEmpty(document.getElementById(`strutturaPortante_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`strutturaPortante_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
            let tamponamenti = CustomJS.isNullEmpty(document.getElementById(`tamponamenti_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`tamponamenti_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
            let ordituraTetto = CustomJS.isNullEmpty(document.getElementById(`ordituraTetto_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`ordituraTetto_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
            let copertura = CustomJS.isNullEmpty(document.getElementById(`copertura_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`copertura_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
            let finiture = CustomJS.isNullEmpty(document.getElementById(`finiture_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`finiture_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
            let condizioneRischio = CustomJS.isNullEmpty(document.getElementById(`condizioneRischio_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`condizioneRischio_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
            condizioneRischio = condizioneRischio === '1' ? 'buono' : condizioneRischio === '2' ? 'normale' : condizioneRischio === '3' ? 'mediocre' : condizioneRischio === '4' ? 'pessimo' : condizioneRischio;
            let rischio = document.getElementById(`rischioFabbricatoConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 'CONFORME' : 'NON CONFORME';
            let testo = '';
            if (CustomJS.isNullEmpty(tipo) || CustomJS.isNullEmpty(adibito) || CustomJS.isNullEmpty(adibitoA) || CustomJS.isNullEmpty(strutturaPortante) || CustomJS.isNullEmpty(tamponamenti)
                || CustomJS.isNullEmpty(ordituraTetto) || CustomJS.isNullEmpty(copertura) || CustomJS.isNullEmpty(finiture) || CustomJS.isNullEmpty(condizioneRischio)) {
                testo = 'Inserisci tutti i campi!'
            }
            else {
                testo = `Trattasi di ${tipo.toLowerCase()} elevato a ${piani} ${pianiStr}, costruito negli anni ${anni} e adibito per ${adibito.toLowerCase()} a ${adibitoA.toLowerCase()}.\nL'immobile è costruito con struttura portante in ${strutturaPortante.toLowerCase()} e con tamponamenti in ${tamponamenti.toLowerCase()}.\nIl tetto è realizzato con orditura in ${ordituraTetto.toLowerCase()} e copertura con ${copertura.toLowerCase()}.\nLe finiture sono ${finiture.toLowerCase()} e lo stato di condizione rischio è ${condizioneRischio.toLowerCase()}.\n\nIl rischio è risultato ${rischio.toLowerCase()} alla descrizione indicata in polizza. `;
                testo = testo.replaceAll('-', ' ');
            }
            document.getElementById(`noteRischioAssicuratoFabbricato_${this.props.IdInternoSinistro}`).ej2_instances[0].value = testo;
        }
        else {
            let impianto = CustomJS.isNullEmpty(document.getElementById(`impianto_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`impianto_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
            let anniFotov = CustomJS.isNullEmpty(document.getElementById(`costruitoAnniFotov_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`costruitoAnniFotov_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            let rischioFotov = document.getElementById(`rischioFotovConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 'CONFORME' : 'NON CONFORME';
            let monitorato = document.getElementById(`monitoratoFotovSi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 'MONITORATO' : 'NON MONITORATO';
            let testoFotov = '';
            if (CustomJS.isNullEmpty(impianto) || CustomJS.isNullEmpty(anniFotov)) {
                testoFotov = 'Inserisci tutti i campi!'
            }
            else {
                testoFotov = `Trattasi di ${impianto} costruito negli anni ${anniFotov}.\n// IL RISCHIO È RISULTATO ${rischioFotov} ALLA DESCRIZIONE INDICATA IN POLIZZA\n// È RISULTATO ${monitorato} ALLA DESCRIZIONE INDICATA IN POLIZZA `;
                testoFotov = testoFotov.replaceAll('-', ' ');
            }
            document.getElementById(`noteRischioAssicuratoFotovoltaico_${this.props.IdInternoSinistro}`).ej2_instances[0].value = testoFotov;
        }
    }
    generaDescrizioneBreveRischioAssicurato() {
        let tipo = CustomJS.isNullEmpty(document.getElementById(`tipoAbitazioneRischAss_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`tipoAbitazioneRischAss_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
        let piani = CustomJS.isNullEmpty(document.getElementById(`pianiFT_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`pianiFT_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        let pianiStr = (piani === '1' ? 'piano' : 'piani');
        let anni = CustomJS.isNullEmpty(document.getElementById(`costruitoAnniFabbr_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`costruitoAnniFabbr_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        let rischio = document.getElementById(`rischioFabbricatoConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 'CONFORME' : 'NON CONFORME';

        let testo = '';
        if (CustomJS.isNullEmpty(tipo)) {
            testo = 'Inserisci tutti i campi!'
        }
        else {
            testo = `Trattasi di ${tipo.toLowerCase()} elevato a ${piani} ${pianiStr}, costruito negli anni ${anni}.\n\nIl rischio è risultato ${rischio.toLowerCase()} alla descrizione indicata in Polizza.`;
            testo = testo.replaceAll('-', ' ');
        }
        document.getElementById(`noteRischioAssicuratoFabbricato_${this.props.IdInternoSinistro}`).ej2_instances[0].value = testo;
    }
    //------------------GESTIONE ACCORDION RISCHIO ASSICURATO FINE------------------

    //------------------GESTIONE ACCORDION SINISTRO E LE SUE MODALITA INIZIO------------------
    sinistroELeSueModalitaEMezziChiusuraSiNO() {
        let presenteAcquaCondotta = false;
        let presenteFenomenoElettrico = false;
        let presenteFurto = false;
        let presenteEventoAtmosferico = false;
        let testoRivalsa = ''

        for (let g = 0; g < this.numComboGaranzie; g++) {
            if (document.getElementById(`containerComboGaranzia${g}_${this.props.IdInternoSinistro}`)) {
                if (!CustomJS.isNullEmpty(document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)) {
                    if (document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG001' ||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '51') {
                        presenteAcquaCondotta = true;
                    }
                    if (document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG007' ||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '2' ||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '3'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '14'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '15'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '23'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '24'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '25'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '26'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '27'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '28'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '29'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '30'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '31'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '32'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '33'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '34'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '37'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '38'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '39'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '40'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '41'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '42'||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '43') {
                        presenteFenomenoElettrico = true;
                    }
                    if (document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG009' ||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '52') {
                        presenteFurto = true;
                    }
                    if (document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG003' ||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG004' ||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG005' ||
                        document.getElementById(`Garanzia${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '53') {
                        presenteEventoAtmosferico = true;
                    }
                }
            }
        }
        //Acqua Condotta
        if (presenteAcquaCondotta === true)
            document.getElementById(`acquaCondottaModalita_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        else
            document.getElementById(`acquaCondottaModalita_${this.props.IdInternoSinistro}`).style.display = 'none';
        //Fenomeno Elettrico
        if (presenteFenomenoElettrico === true)
            document.getElementById(`fenomenoElettricoModalita_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        else
            document.getElementById(`fenomenoElettricoModalita_${this.props.IdInternoSinistro}`).style.display = 'none';
        //Furto
        if (presenteFurto === true) {
            document.getElementById(`furtoModalita_${this.props.IdInternoSinistro}`).style.removeProperty('display');
            document.getElementById(`mezziDiChiusura_${this.props.IdInternoSinistro}`).style.removeProperty('display');
            testoRivalsa = 'Alla data attuale, allo stato delle indagini conosciute, gli autori del reato restano ignoti ne sono emerse responsabilità civili a carico di terzi che possano colpevolmente aver agevolato la commissione del reato. Conseguentemente a oggi non sono emersi elementi che permettano di avviare con successo azione di rivalsa.'
            document.getElementById(`noteRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = testoRivalsa;
        }
        else {
            document.getElementById(`furtoModalita_${this.props.IdInternoSinistro}`).style.display = 'none';
            document.getElementById(`mezziDiChiusura_${this.props.IdInternoSinistro}`).style.display = 'none';
            testoRivalsa = 'Non sussistono a nostro avviso elementi che consentano di individuare precise responsabilità di terzi nel sinistro occorso e, conseguentemente, che permettano di avviare con successo azione di rivalsa.'
            document.getElementById(`noteRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = testoRivalsa;
        }
        //Evento Atmosferico
        if (presenteEventoAtmosferico === true)
            document.getElementById(`eventoAtmosfericoModalita_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        else
            document.getElementById(`eventoAtmosfericoModalita_${this.props.IdInternoSinistro}`).style.display = 'none'
    }
    generaDescrizioneSinistroSueModalita(garanzia) {
        let testo = '';
        //ACQUA CONDOTTA
        if (garanzia === 'acquaCondotta') {
            let causa = CustomJS.isNullEmpty(document.getElementById(`CausaSinistro_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`CausaSinistro_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
            let effetto = CustomJS.isNullEmpty(document.getElementById(`EffettoSinistro_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`EffettoSinistro_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
            testo = `La causa del sinistro è una ${causa.toLowerCase()} con ${effetto.toLowerCase()}`;
            document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value = (CustomJS.isNullEmpty(document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value) + testo;
        }
        //FENOMENO ELETTRICO
        else if (garanzia === 'sovratensioneFenomenoElettrico') {
            testo = ` A causa di una sovratensione elettrica che si è verificata in data ${this.datiPerNote.dataAccadimento} , si danneggiava il ………………. La denuncia del sinistro è stata effettuata in data  Data_Denuncia  . Dalle verifiche tecniche il danneggiamento è conseguente ad una sovratensione elettrica`
            document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value = (CustomJS.isNullEmpty(document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value) + testo;

        }
        else if (garanzia === 'temporaleFenomenoElettrico') {
            testo = `Durante un temporale che si è abbattuto nella zona ove è ubicato il rischio assicurato in data ${this.datiPerNote.dataAccadimento}, si verificava una sovratensione elettrica che causava danni al ……………….La denuncia del sinistro è stata effettuata in data Data_Denuncia. Dalle verifiche tecniche il danneggiamento è conseguente ad una sovratensione elettrica.`;
            document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value = (CustomJS.isNullEmpty(document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value) + testo;
        }
        else if (garanzia === 'furto') {
            testo = `In data ${this.datiPerNote.dataAccadimento} ignoti malfattori si introducevano nei locali del rischio assicurato e commettevano un furto come da denuncia allegata.`;
            document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value = (CustomJS.isNullEmpty(document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value) + testo;
        }
        else if (garanzia === 'eventoAtmosferico') {
            testo = `In data ${this.datiPerNote.dataAccadimento} si abbatteva nella zona del rischio assicurato un eccezionale evento atmosferico caratterizzato da venti pioggia e grandine. `;
            document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value = (CustomJS.isNullEmpty(document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value) + testo;
        }
    }
    //------------------GESTIONE ACCORDION SINISTRO E LE SUE MODALITA FINE------------------

    //------------------GESTIONE ACCORDION MEZZI DI CHIUSURA INIZIO------------------
    mezziChiusuraAntisfondamentoONo(args) {
        if (args.value[0] === 'con-telaio-in-legno-e-vetro' || args.value[0] === 'con-telaio-in-metallo-e-vetro')
            document.getElementById(`appareSoloSeCeVetro_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        else
            document.getElementById(`appareSoloSeCeVetro_${this.props.IdInternoSinistro}`).style.display = 'none';
    }
    motivazioniMezziChiusuraSiNo() {
        if (document.getElementById(`nonConformi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked)
            document.getElementById(`containerNoteMotivazioniMezziChiusura_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        else
            document.getElementById(`containerNoteMotivazioniMezziChiusura_${this.props.IdInternoSinistro}`).style.display = 'none';
    }
    generaDescrMezziChiusura() {
        let tipo = CustomJS.isNullEmpty(document.getElementById(`tipoListMezzChius_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`tipoListMezzChius_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
        let porta = CustomJS.isNullEmpty(document.getElementById(`porteIngressoLocali_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`porteIngressoLocali_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
        let tipoFinestre = CustomJS.isNullEmpty(document.getElementById(`tipoFinestreFuoriTerra_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`tipoFinestreFuoriTerra_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
        let tipoFinestreDue = CustomJS.isNullEmpty(document.getElementById(`tipoFinestreFuoriTerra2_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`tipoFinestreFuoriTerra2_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
        let tipoFinestreTre = CustomJS.isNullEmpty(document.getElementById(`tipoFinestreFuoriTerra3_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`tipoFinestreFuoriTerra3_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
        let inferriate = document.getElementById(`inferriate_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 'Inferriate' : '';
        let antintrusione = document.getElementById(`antintrusione_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 'Impianto Antintrusione' : '';
        let conformi = document.getElementById(`pienamenteConformi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 'Pienamente Conformi' : (document.getElementById(`parzialmenteConformi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 'Parzialmente conformi' : 'Non conformi')
        let sfondamentoOno = '';
        let testo = '';
        let sfondamentoBool = (tipoFinestre === 'con-telaio-in-legno-e-vetro' || tipoFinestre === 'con-telaio-in-metallo-e-vetro');
        if (sfondamentoBool)
            sfondamentoOno = CustomJS.isNullEmpty(document.getElementById(`antisfondamentoONo_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]) ? '' : document.getElementById(`antisfondamentoONo_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
        else
            sfondamentoOno = '';

        if (CustomJS.isNullEmpty(tipo) || CustomJS.isNullEmpty(porta) || CustomJS.isNullEmpty(tipoFinestre)
            || CustomJS.isNullEmpty(tipoFinestreDue) || CustomJS.isNullEmpty(tipoFinestreTre) || CustomJS.isNullEmpty(conformi)) {
            testo = 'Inserisci tutti i campi!'
        }
        else {
            testo = `Trattasi di ${tipo.toLowerCase()} di cui ingresso ai locali è costituito da porta ${porta.toLowerCase()} di cui mezzi di chiusura al primo 
            piano fuori terra sono costituiti da finestre ${tipoFinestre.toLowerCase()} con ${sfondamentoOno.toLowerCase() + ' con '} ${tipoFinestreDue.toLowerCase()} ${tipoFinestreTre.toLowerCase()}; </br>
            Le finestre sono ${inferriate.toLowerCase()} ${antintrusione.toLowerCase()}. </br>
            I mezzi di chiusura sono ${conformi.toLowerCase()}.`;
            testo = testo.replaceAll('-', ' ');
        }
        document.getElementById(`noteMezziChiusura_${this.props.IdInternoSinistro}`).ej2_instances[0].value = testo;
    }

    //------------------GESTIONE ACCORDION MEZZI DI CHIUSURA FINE------------------

    //------------------GESTIONE ACCORDION OPERAZIONI PERITALI INIZIO------------------
    generaDescrizioneOperazioniPeritali(tipologia, args) {
        let testo = ''
        if (tipologia === 'standard') {
            testo = `Ricevuto l\' incarico, abbiamo conferito con l \'Assicurato e fissato un sopralluogo presso l\'ubicazione del rischio assicurato per il giorno ${this.datiPerNote.dataSopralluogo}.\n In tale occasione si prendeva visione dei luoghi e si acquisiva la documentazione necessaria all\'espletamento delle indagini peritali`;
        }
        else if (tipologia === 'videoPerizia') {
            testo = 'Ricevuto l\'incarico, contattavamo il Contraente e ci accordavamo per effettuare la videoperizia.\n Attraverso tale strumento si prendeva visione dei danni e successivamente si acquisiva la documentazione necessaria all\'espletamento delle indagini peritali.';
        }
        else if (tipologia === 'listaNotePeritali') {
            let lista = document.getElementById(`notePeritaliList_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            testo = document.getElementById(`noteOperazioniPeritali_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            lista.forEach(elm => {
                testo += `${this.notePeritaliListData.find(({ id }) => id === elm)?.nota}`; 
            });
        }
        document.getElementById(`noteOperazioniPeritali_${this.props.IdInternoSinistro}`).ej2_instances[0].value = testo;
    }
    //------------------GESTIONE ACCORDION OPERAZIONI PERITALI FINE------------------

    //------------------GESTIONE ACCORDION RIVALSA INIZIO------------------
    possibilitaRivalsaSiNoRinuncia(scelta) {
        let testo = ''//
        if (scelta === 'si') {
            document.getElementById(`motivoRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '01';
            document.getElementById(`motivoRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].enabled = true;
            document.getElementById(`cognomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].enabled = true;
            document.getElementById(`nomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].enabled = true;
            testo = `A nostro parere è esperibile azione di rivalsa nei confronti di ${this.datiPerNote.nomeContraente}`;
            document.getElementById(`noteRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = testo;
            this.formOptions.rules[`motivoRivalsa_${this.props.IdInternoSinistro}`] = {
                required: [true, "Motivo Rivalsa è un campo obbligatorio!"]
            };
            this.formOptions.rules[`cognomeDebitoreRivalsa_${this.props.IdInternoSinistro}`] = {
                required: [true, "Cognome Debitore è un campo obbligatorio!"]
            }
            this.formOptions.rules[`nomeDebitoreRivalsa_${this.props.IdInternoSinistro}`] = {
                required: [true, "Nome Debitore è un campo obbligatorio!"]
            }
        }
        else if (scelta === 'no') {
            this.sinistroELeSueModalitaEMezziChiusuraSiNO();
            document.getElementById(`motivoRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
            document.getElementById(`cognomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
            document.getElementById(`nomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
            document.getElementById(`motivoRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].enabled = false;
            document.getElementById(`cognomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].enabled = false;
            document.getElementById(`nomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].enabled = false;
            if (document.getElementById(`motivoRivalsa-info_${this.props.IdInternoSinistro}`)) 
                document.getElementById(`motivoRivalsa-info_${this.props.IdInternoSinistro}`).style.display = 'none';
            if (document.getElementById(`cognomeDebitoreRivalsa-info_${this.props.IdInternoSinistro}`))
                document.getElementById(`cognomeDebitoreRivalsa-info_${this.props.IdInternoSinistro}`).style.display = 'none';
            if (document.getElementById(`nomeDebitoreRivalsa-info_${this.props.IdInternoSinistro}`))
                document.getElementById(`nomeDebitoreRivalsa-info_${this.props.IdInternoSinistro}`).style.display = 'none';                
            delete this.formOptions.rules[`motivoRivalsa_${this.props.IdInternoSinistro}`];
            delete this.formOptions.rules[`cognomeDebitoreRivalsa_${this.props.IdInternoSinistro}`];
            delete this.formOptions.rules[`nomeDebitoreRivalsa_${this.props.IdInternoSinistro}`];
        }
        else if (scelta === 'rinuncia') {
            testo = 'Si evidenzia che in polizza è prevista clausola di rinuncia alla rivalsa all\'art.';
            document.getElementById(`noteRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = testo;
            document.getElementById(`motivoRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
            document.getElementById(`cognomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
            document.getElementById(`nomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
            document.getElementById(`motivoRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].enabled = false;
            document.getElementById(`cognomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].enabled = false;
            document.getElementById(`nomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].enabled = false;
            if (document.getElementById(`motivoRivalsa-info_${this.props.IdInternoSinistro}`)) 
                document.getElementById(`motivoRivalsa-info_${this.props.IdInternoSinistro}`).style.display = 'none';
            if (document.getElementById(`cognomeDebitoreRivalsa-info_${this.props.IdInternoSinistro}`))
                document.getElementById(`cognomeDebitoreRivalsa-info_${this.props.IdInternoSinistro}`).style.display = 'none';
            if (document.getElementById(`nomeDebitoreRivalsa-info_${this.props.IdInternoSinistro}`))
                document.getElementById(`nomeDebitoreRivalsa-info_${this.props.IdInternoSinistro}`).style.display = 'none';                
            delete this.formOptions.rules[`motivoRivalsa_${this.props.IdInternoSinistro}`];
            delete this.formOptions.rules[`cognomeDebitoreRivalsa_${this.props.IdInternoSinistro}`];
            delete this.formOptions.rules[`nomeDebitoreRivalsa_${this.props.IdInternoSinistro}`];
        }
    }
    //------------------GESTIONE ACCORDION RIVALSA FINE------------------

    //------------------GESTIONE ACCORDION RISERVA INIZIO------------------
    generaDescrizioneRiserva() {
        let lista = document.getElementById(`riservaList_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        let testo = '';
        let scelta = ''
        lista.forEach(elm => {
            scelta = elm;
            if (scelta === 'no-rg')
                testo += ` Si eleva riserva di indennizzabilità in quanto, non essendo stata ancora effettuata la ricerca del guasto, non è stato possibile determinare le cause dell' evento e appurarne la riconducibilità alle garanzie contrattuali.\n`;
            if (scelta === 'no-danni-materiali')
                testo += `Si eleva riserva di indennizzabilità in quanto, non essendo stato rilevato danno materiale e diretto alle cose assicurate ai sensi dell'art. xx , Danni d'acqua, non risultano garantite le spese di ricerca della perdita di cui all'art. xx , Ricerca del guasto.\n`;
            if (scelta === 'tempistiche')
                testo += `Si eleva riserva di indennizzabilità in quanto non sono state rispettate le tempistiche contrattualizzate in termini di avviso alla Compagnia (o alle Autorità) così come previsto dal comma xx dell'articolo xx , Obblighi in caso di sinistro.\n`;
            if (scelta === 'no-garanzia')
                testo += `Si eleva riserva di indennizzabilità in quanto la garanzia coinvolta (art.xx , Ricerca del guasto) non è stata sottoscritta dal Contraente di polizza.\n`;
            if (scelta === 'esclusione')
                testo += `Si eleva riserva di indennizzabilità per i danni alla xxxxxxx, in quanto bene esplicitamente escluso in virtù di quanto riportato al comma xx dell'articolo xx , Esclusioni (delimitazioni di garanzia, ecc..)\n`;
            if (scelta === 'no-residui')
                testo = `Si eleva riserva di indennizzabilità in quanto non sono state mantenute le tracce ed i residui del sinistro, impedendo in questo modo al perito di poter verificare il danno, la causa e la riconducibilità del medesimo alle garanzie di polizza.\n`;
            if (scelta === 'rischio-non-conforme')
                testo += `Si eleva riserva di indennizzabilità in quanto il rischio non risulta conforme alle descrizioni di polizza. Il contratto assicura la "dimora abituale", mentre il fabbricato oggetto dell'evento risulta in realtà "dimora saltuaria" dell'assicurato.\n`;
            document.getElementById(`noteRiserva_${this.props.IdInternoSinistro}`).ej2_instances[0].value = testo;
        })
        // this.generaDescrizioneDefinizione('default');
    }
    //------------------GESTIONE ACCORDION RISERVA FINE------------------

    //------------------GESTIONE ACCORDION DEFINIZIONE INIZIO------------------
    // generaDescrizioneDefinizione(scelta) {
    //     let testo = ''
    //     if (scelta === 'default') {
    //         if (!CustomJS.isNullEmpty(document.getElementById(`noteRiserva_${this.props.IdInternoSinistro}`).ej2_instances[0].value))
    //             // se c'è la riserva :
    //             testo = `Al termine degli accertamenti peritali riteniamo che il sinistro non rientri nelle garanzie di polizza in quanto sussistono riserve di operatività.`
    //         else
    //             // se non c'è la riserva :
    //             testo = `Al termine degli accertamenti peritali riteniamo che il sinistro rientri nelle garanzie di polizza in quanto non sussistono riserve di operatività.`
    //     }
    //     else if (scelta === 'attesaAtto')
    //         testo = `Per il presente sinistro abbiamo inviato all'amm.re pro tempore del condomino  assicurato atto con RISERVA che ad oggi non ci è stato restituito perfezionato.`
    //     else if (scelta === 'accordoVerbale')
    //         testo = `Il danno stimato è stato concordato verbalmente nella persona dell'amm.re pro-tempore sig. ${this.datiPerNote.nomeContraente}.  per un importo pari a  ${this.datiPerNote.totaleRiepilogo}  al netto di franchigia.`
    //     else if (scelta === 'atto')
    //         testo = `Il danno de quo è stato definito mediante Atto con il sig. ${this.datiPerNote.nomeContraente} in qualità di amm.re pro tempore del condomino assicurato per un importo pari a ${this.datiPerNote.totaleRiepilogo} al netto delle franchigie.`
    //     document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value = testo;
    // }
    //------------------GESTIONE ACCORDION DEFINIZIONE FINE------------------

    //----------------------------------------CONTENT ACCORDION INIZIO ----------------------------------------
    contentPartite() {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className='row'>
                            <div className="col-xs-12 col-sm-12 offset-lg-8 col-lg-2 col-md-12" style={{ marginBottom: '-5px', marginTop: '-10px', textAlign: 'right' }}>
                                <div id={`aggiungiPartita_${this.props.IdInternoSinistro}`} style={{ textAlign: 'right', marginRight: '-100%' }}>
                                    <ButtonComponent id={`btnAggiungiPartita_${this.props.IdInternoSinistro}`} onClick={this.aggiungiPartita.bind(this, '', '')} title='Aggiungi Partita' cssClass='e-fill e-info' type='button'>AGGIUNGI PARTITA</ButtonComponent>
                                </div>
                            </div>
                        </div>
                        <div id={`tutteComboPartite_${this.props.IdInternoSinistro}`}>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    contentGaranzie(args) {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className='row'>
                            <div className="col-xs-12 col-sm-12 offset-lg-8 col-lg-2 col-md-12" style={{ marginBottom: '-5px', marginTop: '-10px', textAlign: 'right' }}>
                                <div id='aggiungiGaranzia' style={{ textAlign: 'right', marginRight: '-100%' }}>
                                    <ButtonComponent id={`btnAggiungiGaranzia_${this.props.IdInternoSinistro}`} onClick={this.aggiungiGaranzia.bind(this, '', '')} title='Aggiungi Garanzia' cssClass='e-fill e-info' type='button'>AGGIUNGI GARANZIA</ButtonComponent>
                                </div>
                            </div>
                        </div>
                        <div id={`tutteComboGaranzie_${this.props.IdInternoSinistro}`}>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    contentStime(args) {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className='row'>
                            <div className="col-xs-100col-sm-10 col-lg-10 col-md-10"></div>
                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-end" style={{ marginBottom: '-5px', marginTop: '-10px', marginRight: '-50px' }}>
                                <ButtonComponent id={`btnAggiungiStima_${this.props.IdInternoSinistro}`} onClick={this.aggiungiStima.bind(this, '', '', false)} title='Aggiungi Stima' cssClass='e-fill e-info' type='button'>AGGIUNGI STIMA</ButtonComponent>
                            </div>
                        </div>
                        <div id={`tutteTabelleStima_${this.props.IdInternoSinistro}`}>
                            {/* Qui tutte le tabelle di stima */}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    contentRischioAssicurato(args) {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className="control-section card-control-section basic_card_layout" >
                            <div className="e-card-resize-container">
                                <div id={`schedaFabbricato_${this.props.IdInternoSinistro}`}>
                                    <div className="row card-layout">
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                    <div className='row'>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                            <h4>Note Rischio Assicurato</h4>
                                                            <RichTextEditorComponent id={`noteRischioAssicuratoFabbricato_${this.props.IdInternoSinistro}`} showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                                maxLength={2000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                                            </RichTextEditorComponent>
                                                            <ButtonComponent id={`eliminaNoteRischioAssicuratoFabbricato_${this.props.IdInternoSinistro}`} style={{ marginTop: '50px' }} onClick={() => { document.getElementById(`noteRischioAssicuratoFabbricato_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                                            <div style={{ display: 'none', marginTop: '30px' }} id={`noteRischioNoConformeDiv_${this.props.IdInternoSinistro}`}>
                                                                <h5>Note Rischio non conforme</h5>
                                                                <RichTextEditorComponent id={`noteRischioNoConforme_${this.props.IdInternoSinistro}`} showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                                    maxLength={2000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                                    <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                                                </RichTextEditorComponent>
                                                                <ButtonComponent id={`eliminaNoteRischioNoConforme_${this.props.IdInternoSinistro}`} style={{ marginTop: '20px' }} onClick={() => { document.getElementById(`noteRischioNoConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                    <div id={`btnFotFabbDiv_${this.props.IdInternoSinistro}`} style={{ textAlign: 'right', marginLeft: '55%' }}>
                                                        <ButtonComponent id={`btnFotovoltaico_${this.props.IdInternoSinistro}`} style={{ margin: '0px 10px' }} onClick={this.rischioAssicuratoFabbricatoOFotovoltaico.bind(this, 'fotovoltaico')} cssClass='e-fill e-info pulsante-disabilitato' type='button'>Fotovoltaico</ButtonComponent>
                                                    </div>
                                                    <div className='row'>
                                                        <h5>Scheda Fabbricato</h5>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <h6>Tipo</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`tipoAbitazioneRischAss_${this.props.IdInternoSinistro}`} dataSource={this.tipoAbitazioneRischAssData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <h6>Piani</h6>
                                                            <TextBoxComponent type='number' value={0} id={`pianiFT_${this.props.IdInternoSinistro}`} placeholder='Piani' />
                                                            <h6>Costruito negli Anni</h6>
                                                            <TextBoxComponent type='number' value={0} id={`costruitoAnniFabbr_${this.props.IdInternoSinistro}`} placeholder='Anni' />
                                                            <div className='row' style={{ marginTop: '25px' }} >
                                                                <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                                    <h6>Adibito</h6>
                                                                    <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`adibitoList_${this.props.IdInternoSinistro}`} dataSource={this.adibitoData}>
                                                                        <Inject services={[CheckBoxSelection]} />
                                                                    </ListBoxComponent>
                                                                </div>
                                                                <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                                    <h6>A</h6>
                                                                    <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`adibitoAList_${this.props.IdInternoSinistro}`} dataSource={this.adibitoAData}>
                                                                        <Inject services={[CheckBoxSelection]} />
                                                                    </ListBoxComponent>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <h6>Struttura Portante</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`strutturaPortante_${this.props.IdInternoSinistro}`} dataSource={this.strutturaPortanteData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <h6>Tamponamenti</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`tamponamenti_${this.props.IdInternoSinistro}`} dataSource={this.tamponamentiData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <h6>Orditura Tetto</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`ordituraTetto_${this.props.IdInternoSinistro}`} dataSource={this.ordituraTettoData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <h6>Copertura</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`copertura_${this.props.IdInternoSinistro}`} dataSource={this.coperturaData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <h6>Finiture</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`finiture_${this.props.IdInternoSinistro}`} dataSource={this.finitureData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <h6>Condizione Rischio</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`condizioneRischio_${this.props.IdInternoSinistro}`} dataSource={this.dsCondizioneRischioData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <h6 style={{ textAlign: 'center' }}>Rischio</h6>
                                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                                <RadioButtonComponent id={`rischioFabbricatoConforme_${this.props.IdInternoSinistro}`} onChange={this.rischioAssicuratoConformeSiNo.bind(this, 'si')} checked={true} label="Conforme" name="rischio" />
                                                                <RadioButtonComponent id={`rischioFabbricatoNonConforme_${this.props.IdInternoSinistro}`} onChange={this.rischioAssicuratoConformeSiNo.bind(this, 'no')} label="Non Conforme" name="rischio" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row' style={{ marginLeft: '20%' }}>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" >
                                                            <ButtonComponent id={`btnGeneraDescrizioneBreveFabbricato_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneBreveRischioAssicurato.bind(this, 'fabbricato')} alt='Genera Descrizione Breve' cssClass='e-fill e-info' type='button'>Genera Descrizione Breve</ButtonComponent>
                                                        </div>
                                                        <div className="col-xs-3 offset-xs-2 col-sm-3 offset-sm-2 col-lg-3 offset-lg-2 col-md-3 offset-md-2" >
                                                            <ButtonComponent id={`btnGeneraDescrizioneFabbricato_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneRischioAssicurato.bind(this, 'fabbricato')} alt='Genera Descrizione Completa' cssClass='e-fill e-info' type='button'>Genera Descrizione Completa</ButtonComponent>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id={`schedaFotovoltaico_${this.props.IdInternoSinistro}`} style={{ display: 'none' }}>
                                    <div className="row card-layout">
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                    <div className='row'>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                            <RichTextEditorComponent id={`noteRischioAssicuratoFotovoltaico_${this.props.IdInternoSinistro}`} showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                                maxLength={2000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                                            </RichTextEditorComponent>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                    <div id={`btnFotFabbDiv_${this.props.IdInternoSinistro}`} style={{ textAlign: 'right' }} >
                                                        <ButtonComponent id={`btnFabbricato_${this.props.IdInternoSinistro}`} style={{ margin: '0px 10px' }} onClick={this.rischioAssicuratoFabbricatoOFotovoltaico.bind(this, 'fabbricato')} cssClass='e-fill e-info pulsante-disabilitato' type='button'>Fabbricato</ButtonComponent>
                                                    </div>
                                                    <div className='row'>
                                                        <h5>Scheda Fotovoltaico</h5>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <h6>Impianto</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`impianto_${this.props.IdInternoSinistro}`} dataSource={this.tipoImpiantoData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <TextBoxComponent value={0} type='number' id={`costruitoAnniFotov_${this.props.IdInternoSinistro}`} placeholder='Costruito negli Anni' />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3 text-center">
                                                            <h6 style={{ marginLeft: '75%' }}>Rischio</h6>
                                                            <RadioButtonComponent checked={true} id={`rischioFotovConforme_${this.props.IdInternoSinistro}`} label="Conforme" name="rischioFotov" />
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3 text-center">
                                                            <h6><br></br></h6>
                                                            <RadioButtonComponent id={`rischioFotovoltaicoNonConforme_${this.props.IdInternoSinistro}`} label="Non Conforme" name="rischioFotov" />
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3 text-center">
                                                            <h6 style={{ marginLeft: '75%' }}>Monitorato</h6>
                                                            <RadioButtonComponent checked={true} id={`monitoratoFotovSi_${this.props.IdInternoSinistro}`} label="Si" name="monitorato" />
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3 text-center">
                                                            <h6><br></br></h6>
                                                            <RadioButtonComponent id={`monitoratoFotovNo_${this.props.IdInternoSinistro}`} label="No" name="monitorato" />
                                                        </div>
                                                    </div>
                                                    <div className='row' style={{ marginLeft: '70%' }}>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" >
                                                            <ButtonComponent id={`btnGeneraDescrizioneFotovoltaico_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneRischioAssicurato.bind(this, "fotovoltaico")} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Genera Descrizione</ButtonComponent>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    contentSinistroModalita(args) {
        // IL SINISTRO E LE SUE MODALITA
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout" >
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <RichTextEditorComponent id={`noteSinistroModalita_${this.props.IdInternoSinistro}`} showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                maxLength={2000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='350px' >
                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                            </RichTextEditorComponent>
                                            <ButtonComponent style={{ marginTop: '50px' }} onClick={() => { document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null; document.getElementById(`sceltaNoteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null; }} id={`btnEliminaNoteSinistroModalita_${this.props.IdInternoSinistro}`} alt='Elimina Testo' cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            {/* ACQUA CONDOTTA */}
                            <div tabIndex="0" className="e-card" id={`acquaCondottaModalita_${this.props.IdInternoSinistro}`} style={{ borderRadius: '20px', border: '1px solid #bad0e3', display: 'none' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <h5>ACQUA CONDOTTA</h5>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <h5>AC -Causa del Sinistro</h5>
                                            <ListBoxComponent id={`CausaSinistro_${this.props.IdInternoSinistro}`} dataSource={this.dsCausaSinistro} />
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <h5>AC - Effetto</h5>
                                            <ListBoxComponent id={`EffettoSinistro_${this.props.IdInternoSinistro}`} dataSource={this.dsEffettoSinistro} />
                                            <ButtonComponent style={{ marginTop: '15px' }} onClick={this.generaDescrizioneSinistroSueModalita.bind(this, 'acquaCondotta')} id={`btnModalitaAcquaCondotta_${this.props.IdInternoSinistro}`} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Acqua Condotta</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* FENOMENO ELETTRICO */}
                            <div tabIndex="0" id={`fenomenoElettricoModalita_${this.props.IdInternoSinistro}`} className="e-card" style={{ borderRadius: '20px', border: '1px solid #bad0e3', display: 'none' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <h5>FENOMENO ELETTRICO</h5>
                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 me-1">
                                            <ButtonComponent onClick={this.generaDescrizioneSinistroSueModalita.bind(this, 'sovratensioneFenomenoElettrico')} id={`btnModalitaSovratensione_${this.props.IdInternoSinistro}`} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Sovratensione</ButtonComponent>
                                        </div>
                                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 ms-1">
                                            <ButtonComponent onClick={this.generaDescrizioneSinistroSueModalita.bind(this, 'temporaleFenomenoElettrico')} id={`btnModalitaTemporale_${this.props.IdInternoSinistro}`} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Temporale</ButtonComponent>
                                        </div>
                                        <div className="col-xs-7 col-sm-7 col-lg-7 col-md-7">
                                            <DropDownListComponent id={`sceltaNoteSinistroModalita_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsNoteSinistroModalitaFE}
                                                allowFiltering={true} filterType='Contains' showClearButton={true} change={this.onChangeNoteSinistroModalita.bind(this)} popupHeight='500px'
                                                placeholder="Scelta Altre Note" floatLabelType='Auto' cssClass='e-outline'>
                                            </DropDownListComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* FURTO */}
                            <div tabIndex="0" className="e-card" id={`furtoModalita_${this.props.IdInternoSinistro}`} style={{ borderRadius: '20px', border: '1px solid #bad0e3', display: 'none' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <h5>FURTO</h5>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <ButtonComponent onClick={this.generaDescrizioneSinistroSueModalita.bind(this, 'furto')} id={`btnModalitaFurto_${this.props.IdInternoSinistro}`} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Furto</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* EVENTO ATMOSFERICO */}
                            <div tabIndex="0" className="e-card" id={`eventoAtmosfericoModalita_${this.props.IdInternoSinistro}`} style={{ borderRadius: '20px', border: '1px solid #bad0e3', display: 'none' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <h5>EVENTO ATMOSFERICO</h5>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <ButtonComponent onClick={this.generaDescrizioneSinistroSueModalita.bind(this, 'eventoAtmosferico')} id={`btnModalitaEventoAtmosferico_${this.props.IdInternoSinistro}`} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Evento Atmosferico</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    contentMezziChiusura(args) {
        return <div >
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className="control-section card-control-section basic_card_layout" >
                            <div className="e-card-resize-container">
                                <div className="row card-layout" >
                                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                        <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                            <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                <div className='row'>
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                        <RichTextEditorComponent id={`noteMezziChiusura_${this.props.IdInternoSinistro}`} showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                            maxLength={2000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                            <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                                        </RichTextEditorComponent>
                                                        <ButtonComponent style={{ marginTop: '50px' }} onClick={() => { document.getElementById(`noteMezziChiusura_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '' }} id={`btnEliminaNoteMezziChiusura_${this.props.IdInternoSinistro}`} alt='Elimina Testo' cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                        <div tabIndex="0" className="e-card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                            <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                <div id={`schedaMezziChiusura_${this.props.IdInternoSinistro}`}>
                                                    <div className='row'>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <h6>Tipo</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`tipoListMezzChius_${this.props.IdInternoSinistro}`} dataSource={this.tipoAbitazioneMezzChiusData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <h6>L'ingresso ai locali è costituito da porta :</h6>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`porteIngressoLocali_${this.props.IdInternoSinistro}`} dataSource={this.porteIngressoLocaliData} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <h6>I mezzi di chiusura al primo piano fuori terra sono costituiti da finestre :</h6>
                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                                            <ListBoxComponent fields={this.ddlFieldsList} onChange={this.mezziChiusuraAntisfondamentoONo.bind(this)} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`tipoFinestreFuoriTerra_${this.props.IdInternoSinistro}`} dataSource={this.finestreFuoriTerra} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                        <div id={`appareSoloSeCeVetro_${this.props.IdInternoSinistro}`} style={{ display: 'none' }} className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`antisfondamentoONo_${this.props.IdInternoSinistro}`} dataSource={this.tipoFinestreFuoriTerra} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>

                                                        </div>
                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`tipoFinestreFuoriTerra2_${this.props.IdInternoSinistro}`} dataSource={this.tipoFinestreFuoriTerra2} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                            <br></br>
                                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Single", showCheckbox: false }} id={`tipoFinestreFuoriTerra3_${this.props.IdInternoSinistro}`} dataSource={this.tipoFinestreFuoriTerra3} >
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </ListBoxComponent>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <CheckBoxComponent checked={true} id={`inferriate_${this.props.IdInternoSinistro}`} label="Inferriate" />
                                                        </div>
                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                            <CheckBoxComponent id={`antintrusione_${this.props.IdInternoSinistro}`} label="Impianto antintrusione" />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <h6>Mezzi di chiusura :</h6>
                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 text-center">
                                                            <RadioButtonComponent checked={true} onChange={this.motivazioniMezziChiusuraSiNo.bind(this)} id={`pienamenteConformi_${this.props.IdInternoSinistro}`} name='modalitaConformi' label="Pienamente conformi" />
                                                        </div>
                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 text-center">
                                                            <RadioButtonComponent id={`parzialmenteConformi_${this.props.IdInternoSinistro}`} onChange={this.motivazioniMezziChiusuraSiNo.bind(this)} name='modalitaConformi' label="Parzialmente conformi" />
                                                        </div>
                                                        <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 text-centers">
                                                            <RadioButtonComponent id={`nonConformi_${this.props.IdInternoSinistro}`} onChange={this.motivazioniMezziChiusuraSiNo.bind(this)} name='modalitaConformi' label="Non conformi" />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" id={`containerNoteMotivazioniMezziChiusura_${this.props.IdInternoSinistro}`} style={{ display: 'none' }} >
                                                            <h6>Motivazioni Mezzi di Chiusura Non Conformi:</h6>
                                                            <RichTextEditorComponent id={`noteMotivazioniMezziChiusura_${this.props.IdInternoSinistro}`} showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                                maxLength={2000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                                            </RichTextEditorComponent>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ marginTop: '100px', alignItems: 'end', textAlign: 'end' }}>
                                                            <ButtonComponent id={`btnGeneraDescrizioneMezziChiusura_${this.props.IdInternoSinistro}`} onClick={this.generaDescrMezziChiusura.bind(this)} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Genera Descrizione</ButtonComponent>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    contentOperazioniPeritali(args) {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <h4>Note Operazioni Peritali</h4>
                                            <RichTextEditorComponent id={`noteOperazioniPeritali_${this.props.IdInternoSinistro}`} showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                maxLength={2000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='350px' >
                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                            </RichTextEditorComponent>
                                            <ButtonComponent id={`eliminaNoteOperazioniPeriitali_${this.props.IdInternoSinistro}`} style={{ marginTop: '55px' }} onClick={() => { document.getElementById(`noteOperazioniPeritali_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                            <ButtonComponent id={`btnOperazioniPeritaliStandard_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneOperazioniPeritali.bind(this, 'standard')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Frase Standard</ButtonComponent>
                                        </div>
                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                            <ButtonComponent id={`btnOperazioniPeritaliVideoPerizia_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneOperazioniPeritali.bind(this, 'videoPerizia')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Frase VideoPerizia</ButtonComponent>
                                        </div>
                                    </div>
                                    <div className='row'>    
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Multiple", showCheckbox: true }} allowFiltering={true} filterType='Contains' id={`notePeritaliList_${this.props.IdInternoSinistro}`} dataSource={this.notePeritaliListData} height='400px'>
                                                <Inject services={[CheckBoxSelection]} />
                                            </ListBoxComponent>
                                            <div style={{ textAlign: 'right', marginTop: '20px' }}>
                                                <ButtonComponent id={`btnGeneraDescrizioneNoteOperazPeritali_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneOperazioniPeritali.bind(this, 'listaNotePeritali')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Genera Descrizione</ButtonComponent>
                                            </div>
                                        </div>                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    contentRivalsa(args) {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <h4>Note Rivalsa</h4>
                                            <RichTextEditorComponent id={`noteRivalsa_${this.props.IdInternoSinistro}`} showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                maxLength={2000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                            </RichTextEditorComponent>
                                            <ButtonComponent id={`eliminaNoteRivalsa_${this.props.IdInternoSinistro}`} style={{ marginTop: '50px' }} onClick={() => { document.getElementById(`noteRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <h6 style={{ textAlign: 'center' }}>Possibilità Di Rivalsa</h6>
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <RadioButtonComponent id={`possibilitaRivalsaSi_${this.props.IdInternoSinistro}`} onChange={this.possibilitaRivalsaSiNoRinuncia.bind(this, 'si')} value='si' checked={false} label="Si" name="possRivalsa" />
                                                <RadioButtonComponent id={`possibilitaRivalsaNo_${this.props.IdInternoSinistro}`} onChange={this.possibilitaRivalsaSiNoRinuncia.bind(this, 'no')} value='no' label="No" name="possRivalsa" />
                                                <RadioButtonComponent id={`possibilitaRivalsaRinuncia_${this.props.IdInternoSinistro}`} onChange={this.possibilitaRivalsaSiNoRinuncia.bind(this, 'rinuncia')} value='rinuncia' label="Rinuncia" name="possRivalsa" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                        <DropDownListComponent id={`motivoRivalsa_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsMotiviRivalsa}
                                            allowFiltering={false} filterType='Contains' showClearButton={true} 
                                            placeholder="Motivo Rivalsa" floatLabelType='Auto' cssClass='e-outline'>
                                        </DropDownListComponent>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <TextBoxComponent id={`cognomeDebitoreRivalsa_${this.props.IdInternoSinistro}`} floatLabelType='Auto' placeholder='Cognome Debitore' cssClass='e-outline'></TextBoxComponent>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                            <TextBoxComponent id={`nomeDebitoreRivalsa_${this.props.IdInternoSinistro}`} floatLabelType='Auto' placeholder='Nome Debitore' cssClass='e-outline'></TextBoxComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    contentRiserva() {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <h4>Note Riserva</h4>
                                            <RichTextEditorComponent id={`noteRiserva_${this.props.IdInternoSinistro}`} showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                maxLength={2000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                            </RichTextEditorComponent>
                                            <ButtonComponent id={`eliminaNoteRiserva_${this.props.IdInternoSinistro}`} style={{ marginTop: '50px' }} onClick={() => { document.getElementById(`noteRiserva_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Multiple", showCheckbox: true }} id={`riservaList_${this.props.IdInternoSinistro}`} dataSource={this.riservaData} >
                                                <Inject services={[CheckBoxSelection]} />
                                            </ListBoxComponent>
                                            <div style={{ textAlign: 'right', marginTop: '20px' }}>
                                                <ButtonComponent id={`btnGeneraDescrizioneRiserva_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneRiserva.bind(this)} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Genera Descrizione</ButtonComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    // contentDefinizione(args) {
    //     return <div>
    //         <div className="control-section card-control-section basic_card_layout" >
    //             <div className="e-card-resize-container">
    //                 <div className="row card-layout">
    //                     <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
    //                         <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
    //                             <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
    //                                 <div className='row'>
    //                                     <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">

    //                                          <h4>Note Definizione</h4>
    //                                         <RichTextEditorComponent id={`noteDefinizione_${this.props.IdInternoSinistro}`} showCharCount={true} enableHtmlEncode={true} placeholder=''
    //                                             maxLength={2000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
    //                                             <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
    //                                         </RichTextEditorComponent>
    //                                         <ButtonComponent id={`eliminaNoteDefinizione_${this.props.IdInternoSinistro}`} style={{ marginTop: '20px' }} onClick={() => { document.getElementById('noteDefinizione').ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>

    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
    //                         <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
    //                             <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
    //                                 <div className='row'>
    //                                     <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
    //                                         <ButtonComponent id={`btnDefinizioneDefault_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneDefinizione.bind(this, 'default')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Default</ButtonComponent>
    //                                     </div>
    //                                     <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
    //                                         <ButtonComponent id={`btnDefinizioneAttesaAtto_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneDefinizione.bind(this, 'attesaAtto')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>In Attesa di Atto</ButtonComponent>
    //                                     </div>
    //                                     <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
    //                                         <ButtonComponent id={`btnDefinizioneAccordoVerbale_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneDefinizione.bind(this, 'accordoVerbale')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Accordo Verbale</ButtonComponent>
    //                                     </div>
    //                                     <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
    //                                         <ButtonComponent id={`btnDefinizioneAtto_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneDefinizione.bind(this, 'atto')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Atto</ButtonComponent>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>;
    // }
    contentRiservatoCompagnia(args) {
        return <div>
            <div className="control-section card-control-section basic_card_layout" >
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <h4>Note Riservate Alla Compagnia</h4>
                                            <RichTextEditorComponent id={`noteRiservateCompagnia_${this.props.IdInternoSinistro}`} showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                maxLength={2000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='200px' >
                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                            </RichTextEditorComponent>
                                            <ButtonComponent id={`btnNoteRiservateCompagnia_${this.props.IdInternoSinistro}`} style={{ marginTop: '20px' }} onClick={() => { document.getElementById(`noteRiservateCompagnia_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;

    }
    //----------------------------------------CONTENT ACCORDION FINE ----------------------------------------
    onChangeNoteSinistroModalita(args) {
        document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value = args.itemData?.servizio_str1 ?? null;
    }

    render() {
        return (<React.Fragment>
            <DialogComponent id={`dialogElaboratoPeritale_${this.props.IdInternoSinistro}`} showCloseIcon={true} animationSettings={this.animationSettings} visible={false}
                ref={dialog => this.dialogElaboratoInstance = dialog} isModal={true}>
                    <div id={`containerDialogElaboratoPeritale_${this.props.IdInternoSinistro}`}></div>
            </DialogComponent>
            <form id={`formElaboratoPeritale_${this.props.IdInternoSinistro}`}>
                <div className='container-fluid containerPeriziaElab1'>
                    <div className='idSinistroIncaricoElab'>
                        <p title='Sinistro importato' className='importatoGen' id={`sinistroImportatoGen_${this.props.IdInternoSinistro}`}></p>
                        <h4 style={{fontFamily: 'Muli, sans-serif', fontWeight: 'bolder'}} id={`idIncaricoTesta_${this.props.IdInternoSinistro}`}>ID Incarico : {this.props.IdIncarico}</h4>
                    </div>
                    <div className='row' style={{ marginTop: '20px' }}>
                        <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3 text-start' >
                            <ButtonComponent style={{ marginLeft: '15px' }} id={`btnPeriziaZeroCalcoliUndo_${this.props.IdInternoSinistro}`} onClick={this.ritiraPeriziaSenzaCalcoli.bind(this)} title='Ritorna alla perizia con calcoli' cssClass='e-fill e-info' type='button'><i className="fas fa-undo"></i></ButtonComponent>
                            <ButtonComponent style={{ marginLeft: '15px' }} id={`btnPeriziaZeroCalcoli_${this.props.IdInternoSinistro}`} onClick={this.abilitaPeriziaSenzaCalcoli.bind(this, false)} title='Perizia senza calcoli' cssClass='e-fill e-warning' type='button'>PERIZIA SENZA CALCOLI</ButtonComponent>
                        </div>
                        <div className='col-lg-5 col-md-5 col-sm-5 col-xs-5'></div>
                        <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2 text-end'>
                            <ButtonComponent id={`btnModificaPerizia_${this.props.IdInternoSinistro}`} onClick={this.modificaPerizia.bind(this)} title='Modifica Partite/Garanzie/Stime' cssClass='e-fill e-info' type='button'>MODIFICA PERIZIA</ButtonComponent>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-2 col-xs-2 text-end' >
                            <ButtonComponent style={{ marginRight: '30px' }} id={`btnRipristinaPerizia_${this.props.IdInternoSinistro}`} onClick={this.ripristinaPerizia.bind(this)} title='Cancella tutti i calcoli della Perizia e Sblocca Aggiungi Partite/Garanzie e Stime' cssClass='e-fill e-danger' type='button'>RIPRISTINA PERIZIA</ButtonComponent>
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: '-85px', marginLeft: '10px' }}>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ marginTop: '100px', width: '97.77%', padding: '10px', textAlign: 'center', backgroundColor: 'whitesmoke', borderRadius: '15px', border: '1px solid #bad0e3', alignItems: 'center' }}>
                            <DropDownListComponent id={`tipoServizio_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsTipoServizio}
                                allowFiltering={true} filterType='Contains' showClearButton={true} placeholder="Servizio Eseguito"
                                floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: '-85px' }}>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ marginTop: '100px', width: '98.5%' }}>
                            <AccordionComponent ref={acrdn => this.acrdnInstance0 = acrdn}  >
                                <AccordionItemsDirective >
                                    <AccordionItemDirective cssClass='e-outline' header='<h5>PARTITE</h5>' content={this.contentPartite.bind(this)} />
                                    <AccordionItemDirective cssClass='e-outline' header='<h5>GARANZIE</h5>' content={this.contentGaranzie.bind(this)} />
                                    <AccordionItemDirective cssClass='e-outline' header='<h5>TABELLE DI STIMA</h5>' content={this.contentStime.bind(this)} />
                                    <AccordionItemDirective cssClass='e-outline' header='<h5>RISCHIO ASSICURATO</h5>' content={this.contentRischioAssicurato.bind(this)} />
                                    <AccordionItemDirective cssClass='e-outline' header='<h5>IL SINISTRO E LE SUE MODALITÀ</h5>' content={this.contentSinistroModalita.bind(this)} />
                                    <AccordionItemDirective id={`mezziDiChiusura_${this.props.IdInternoSinistro}`} style={{ display: 'none' }} cssClass='e-outline' header='<h5>MEZZI DI CHIUSURA</h5>' content={this.contentMezziChiusura.bind(this)} />
                                    <AccordionItemDirective cssClass='e-outline' header='<h5>OPERAZIONI PERITALI</h5>' content={this.contentOperazioniPeritali.bind(this)} />
                                    <AccordionItemDirective cssClass='e-outline' header='<h5>RIVALSA</h5>' content={this.contentRivalsa.bind(this)} />
                                    <AccordionItemDirective cssClass='e-outline' header='<h5>RISERVA</h5>' content={this.contentRiserva.bind(this)} />
                                    {/* <AccordionItemDirective style={{ backgroundColor: '#edf0fa' }} cssClass='e-outline' header='<h5>LA DEFINIZIONE</h5>' content={this.contentDefinizione.bind(this)} /> */}
                                    <AccordionItemDirective id={`riservatoCompagnia_${this.props.IdInternoSinistro}`} style={{ display: 'none' }} cssClass='e-outline' header='<h5>NOTE RISERVATE COMPAGNIA</h5>' content={this.contentRiservatoCompagnia.bind(this)} />
                                </AccordionItemsDirective>
                            </AccordionComponent>
                        </div>
                    </div>
                    <div className='row'>   
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                            <ButtonComponent id={`btnInizioPag1${this.props.IdInternoSinistro}`} type='button' cssClass='e-info' created={this.onCreatedBtnInizioPag}>Vai ad inizio pagina</ButtonComponent>
                        </div>  
                    </div>
                    <div className='row' style={{ marginTop: '20px', marginRight: '3vw' }}>
                        <div className="col-xs-8 col-sm-8 offset-lg-10 col-lg-2 col-md-12">
                        </div>
                    </div>
                    <div className='row' style={{ marginRight: '95%' }}>
                        <div className="col-xs-12 col-sm-12 offset-lg-10 col-lg-2 col-md-12">
                        </div>
                    </div>
                </div>
            </form>
            <MDBFooter id={`footerElaboratoP1_${this.props.IdInternoSinistro}`} bgColor='light' className='fixed-bottom ' style={{ marginLeft: '65px', zIndex: 10 }}>
                <div className='row' style={{ backgroundColor: 'rgb(239, 239, 239)', borderTop: '1px solid #202351' }}>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-start">
                        <ButtonComponent id={`paginaDettaglioFromPO1_${this.props.IdInternoSinistro}`} style={{ marginLeft: '5%' }} alt='Torna al Dettaglio Sinistro' cssClass='e-fill e-info' type='button' onClick={this.paginaDettaglioSinistro.bind(this)}>← Dettaglio Sinistro</ButtonComponent>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 text-center">
                        <h3 id={`statoNonModificabilePeritoP1_${this.props.IdInternoSinistro}`} style={{ color: 'red' }}></h3>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-end">
                        <ButtonComponent id={`tabelleCalcoloDaPerizia1_${this.props.IdInternoSinistro}`} alt='Tabelle di Calcolo' cssClass='e-fill e-info' type='button' onClick={this.paginaPeriziaOnlineCalcoli.bind(this)}>Elaborato Calcoli →→</ButtonComponent>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-end">
                        <ButtonComponent id={`salvaEProcedi_${this.props.IdInternoSinistro}`} style={{ marginRight: '5%' }} title='Salva tutto e Procedi alle Tabelle di Calcolo' cssClass='e-fill e-success' type='button' onClick={this.requiredElaBoratoPeritale.bind(this)}>Salva e Procedi →</ButtonComponent>
                    </div>
                </div>
            </MDBFooter>
        </React.Fragment>);
    }
    //-------------------------------GESTIONE CARICAMENTO INIZIO-------------------------------

    sinistroChiuso() {
        document.getElementById(`statoNonModificabilePeritoP1_${this.props.IdInternoSinistro}`).innerText = "Sinistro non modificabile!"
        document.getElementById(`btnRipristinaPerizia_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
        document.getElementById(`btnModificaPerizia_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;

        document.getElementById(`btnAggiungiPartita_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
        document.getElementById(`btnAggiungiGaranzia_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
        document.getElementById(`btnAggiungiStima_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
        document.getElementById(`salvaEProcedi_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;


        for (let p = 0; p < this.numComboPartite; p++) {
            if (document.getElementById(`Partita${p}_${this.props.IdInternoSinistro}`)) {
                document.getElementById(`aggiungiPartita${p}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                document.getElementById(`eliminaPartita${p}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
            }
        }
        for (let i = 0; i < this.numComboGaranzie; i++) {
            if (document.getElementById(`Garanzia${i}_${this.props.IdInternoSinistro}`)) {
                //SBLOCCO GARANZIE
                document.getElementById(`aggiungiGaranzia${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                document.getElementById(`eliminaGaranzia${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
            }
        }
        for (let i = 0; i < this.numComboStime; i++) {
            if (document.getElementById(`DanneggiatoTabellaStima${i}_${this.props.IdInternoSinistro}`)) {
                //SBLOCCO TABELLE STIMA
                document.getElementById(`btnSwitchDanneggiato${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                document.getElementById(`aggiungiStima${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                document.getElementById(`eliminaStima${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
            }
        }
    }
    componentDidMount() {
        //document.getElementById(`paginaDettaglioFromPO1_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
        document.getElementById(`tabelleCalcoloDaPerizia1_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
        document.getElementById(`salvaEProcedi_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
        setTimeout(() => {
            this.collapseAccordionDatiSin()
            this.nuovaCaricaDati();
        }, 125);
    }
    collapseAccordionDatiSin() {
        this.acrdnInstance0.expandItem(true, 0);
        this.acrdnInstance0.expandItem(true, 1);
        this.acrdnInstance0.expandItem(true, 2);
        this.acrdnInstance0.expandItem(true, 3);
        this.acrdnInstance0.expandItem(true, 4);
        this.acrdnInstance0.expandItem(true, 5);
        this.acrdnInstance0.expandItem(false, 6);
        this.acrdnInstance0.enableItem(6, false);
        this.acrdnInstance0.expandItem(true, 7);
        this.acrdnInstance0.expandItem(true, 8);
        this.acrdnInstance0.expandItem(true, 9);
    }
    async nuovaCaricaDati() {
        let tempoAttesa = 0;
        let tempoOne = 200;

        this.toggleBlocking2(true);
        debugger;
        //RISERVATO ALLE COMPAGNIE ACCORDIO SI/NO
        if (this.isCompagniaUnipol())
            document.getElementById(`riservatoCompagnia_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        else
            document.getElementById(`riservatoCompagnia_${this.props.IdInternoSinistro}`).style.display = 'none';
        //INFO INCARICO INTESTAZIONE
        debugger;
        let QueryExecIncarico = `SELECT [01001].t_sopralluogo, [01001].t_incarico, [01001].t_sinistro, [01001].numero_sinistro_agenzia, [01001].contr_nominativo AS contraente, [12003].id_incarico as id_incarico_generali, [10001].id_incarico as id_incarico_unipol, [10003].id_sinistro as id_sinistro_unipol_perizia, [10004].id_sinistro as id_sinistro_unipol_osservazioni, [11001].* FROM [01001] LEFT OUTER JOIN [10001] ON [01001].id_interno_sinistro = [10001].id_interno_sinistro LEFT OUTER JOIN [12003] on [01001].id_sinistro = [12003].id_sinistro LEFT OUTER JOIN [10003] on [01001].id_sinistro = [10003].id_sinistro LEFT OUTER JOIN [10004] on [01001].id_sinistro = [10004].id_sinistro LEFT OUTER JOIN [11001] on [01001].id_sinistro = [11001].id_sinistro WHERE [01001].id_sinistro = ${this.props.IdSinistro}`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIncarico, '', retDataIncarico => {
            tempoAttesa += tempoOne;
            this.datiPerNote.dataSopralluogo = CustomJS.isNullEmpty(retDataIncarico[0].data_sop) ? '' : (moment(retDataIncarico[0].data_sop).format('DD/MM/YY'))
            this.datiPerNote.dataAccadimento = CustomJS.isNullEmpty(retDataIncarico[0].sin_acc) ? '' : (moment(retDataIncarico[0].sin_acc).format('DD/MM/YY'))
            this.datiPerNote.totaleRiepilogo = CustomJS.isNullEmpty(retDataIncarico[0].tot_indennizzo_netto_compl) ? '0' : (retDataIncarico[0].tot_indennizzo_netto_compl)
            this.datiPerNote.nomeContraente = CustomJS.isNullEmpty(retDataIncarico[0].contraente) ? (retDataIncarico[0].nome_contr + ' ' + retDataIncarico[0].cognome_contr) : retDataIncarico[0].contraente;

            if (this.isCompagniaUnipol()) {
                //Perizia
                if (CustomJS.isNullEmpty(retDataIncarico[0].id_sinistro_unipol_perizia) || retDataIncarico[0].id_sinistro_unipol_perizia === 0)
                    this.statoInfoElaboratoPeritale.statoUnipolPerizia = 'n'
                else
                    this.statoInfoElaboratoPeritale.statoUnipolPerizia = 'o'
                //Osservazioni
                if (CustomJS.isNullEmpty(retDataIncarico[0].id_sinistro_unipol_osservazioni) || retDataIncarico[0].id_sinistro_unipol_osservazioni === 0)
                    this.statoInfoElaboratoPeritale.statoUnipolOsservazioni = 'n'
                else
                    this.statoInfoElaboratoPeritale.statoUnipolOsservazioni = 'o'

                document.getElementById(`idIncaricoTesta_${this.props.IdInternoSinistro}`).innerHTML = CustomJS.isNullEmpty(this.props.IdIncaricoUnipol) ? '' : ('ID Incarico : ' + this.props.IdIncaricoUnipol);
                document.getElementById(`sinistroImportatoGen_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-arrow-circle-up"></i>'
            }
            else if (this.isCompagniaGenerali()) {
                document.getElementById(`idIncaricoTesta_${this.props.IdInternoSinistro}`).innerHTML = CustomJS.isNullEmpty(this.props.IdIncarico) ? '' : ('ID Incarico : ' + this.props.IdIncarico);
                document.getElementById(`sinistroImportatoGen_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-arrow-circle-up"></i>'
            }
            else {
                document.getElementById(`idIncaricoTesta_${this.props.IdInternoSinistro}`).innerHTML = 'ID Incarico : N/A';
                document.getElementById(`sinistroImportatoGen_${this.props.IdInternoSinistro}`).style.display = 'none';
            }
            // retDataIncarico[0].id_sinistro = E' riferito all' id_sinistro della tabella _perizia_incarico_info del db, se è vuoto bisogna effettuare la insert (stato Nuovo) altrimenti l' update (stato Modifica)
            //Se l' id_sinistro è vuoto sul db essendo un tipo INT nella retData è zero (0)
            if (CustomJS.isNullEmpty(retDataIncarico[0].id_sinistro) || retDataIncarico[0].id_sinistro === 0) {
                this.statoInfoElaboratoPeritale.statoInfo = 'Nuovo';
                setTimeout(() => {
                    this.valoriPredefinitiCaricamentoPagina();
                }, 150)
            }
            else {
                this.statoInfoElaboratoPeritale.statoInfo = 'Modifica';
                this.statoInfoElaboratoPeritale.key = retDataIncarico[0].id_sinistro;

                document.getElementById(`tipoServizio_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataIncarico[0].servizio_eseguito;
                document.getElementById(`noteRischioAssicuratoFabbricato_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataIncarico[0].RA_note_fabbr;
                if (retDataIncarico[0].RA_rischio_fabbr)
                    document.getElementById(`rischioFabbricatoConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true;
                else
                    document.getElementById(`rischioFabbricatoNonConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true;
                document.getElementById(`noteRischioNoConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataIncarico[0].RA_note_fabbr_no_conforme;
                document.getElementById(`noteRischioAssicuratoFotovoltaico_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataIncarico[0].RA_note_fotov;
                if (retDataIncarico[0].RA_rischio_fotov)
                    document.getElementById(`rischioFotovConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true;
                else
                    document.getElementById(`rischioFotovoltaicoNonConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true;
                if (retDataIncarico[0].RA_monitorato_fotov)
                    document.getElementById(`monitoratoFotovSi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true;
                else
                    document.getElementById(`monitoratoFotovNo_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true;
                document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataIncarico[0].SM_note;
                if (!CustomJS.isNullEmpty(retDataIncarico[0].MC_note)) {
                    document.getElementById(`mezziDiChiusura_${this.props.IdInternoSinistro}`).style.removeProperty('display');
                    document.getElementById(`noteMezziChiusura_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataIncarico[0].MC_note;
                    if (retDataIncarico[0].MC_mezzi_chiusura === 1)
                        document.getElementById(`pienamenteConformi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true;
                    else if (retDataIncarico[0].MC_mezzi_chiusura === 2)
                        document.getElementById(`parzialmenteConformi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true;
                    else if (retDataIncarico[0].MC_mezzi_chiusura === 3) {
                        document.getElementById(`containerNoteMotivazioniMezziChiusura_${this.props.IdInternoSinistro}`).style.removeProperty('display')
                        document.getElementById(`nonConformi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true;
                        document.getElementById(`noteMotivazioniMezziChiusura_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataIncarico[0].MC_motivazioni;
                    }
                }
                //document.getElementById(`noteOperazioniPeritali_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataIncarico[0].OP_note;
                if (retDataIncarico[0].R_possibilita_rivalsa === 1)
                    document.getElementById(`possibilitaRivalsaSi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true;
                else if (retDataIncarico[0].R_possibilita_rivalsa === 2)
                    document.getElementById(`possibilitaRivalsaRinuncia_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true;
                else
                    document.getElementById(`possibilitaRivalsaNo_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true;
                document.getElementById(`motivoRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataIncarico[0].R_motivo_rivalsa;
                document.getElementById(`cognomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataIncarico[0].R_cognome_debitore;
                document.getElementById(`nomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataIncarico[0].R_nome_debitore;
                document.getElementById(`noteRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataIncarico[0].R_note;
                document.getElementById(`noteRiserva_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataIncarico[0].Ris_note;
                document.getElementById(`condizioneRischio_${this.props.IdInternoSinistro}`).ej2_instances[0].value = [retDataIncarico[0].condizioneRischioCodice];
                if (document.getElementById(`possibilitaRivalsaSi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true) {
                    this.formOptions.rules[`motivoRivalsa_${this.props.IdInternoSinistro}`] = {
                        required: [true, "Motivo Rivalsa è un campo obbligatorio!"]
                    };
                    this.formOptions.rules[`cognomeDebitoreRivalsa_${this.props.IdInternoSinistro}`] = {
                        required: [true, "Cognome Debitore è un campo obbligatorio!"]
                    }
                    this.formOptions.rules[`nomeDebitoreRivalsa_${this.props.IdInternoSinistro}`] = {
                        required: [true, "Nome Debitore è un campo obbligatorio!"]
                    }    
                }
                else if (document.getElementById(`possibilitaRivalsaNo_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true 
                         || document.getElementById(`possibilitaRivalsaRinuncia_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true) {
                        document.getElementById(`motivoRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].enabled = false;
                        document.getElementById(`cognomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].enabled = false;
                        document.getElementById(`nomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].enabled = false;             
                }
            }
            //FACCIO 3 QUERY ANNIDATE : PARTITE > GARANZIE > STIME
            //PARTITE
            const QueryExecPartite = `SELECT DISTINCT [11002].id_partitaPeriziaOnline, [11002].id_partita as cod_ele_partita, [11002].formaGaranzia, [11002].sommaAssicurataPartita, [11002].descrizione, [11002].guid_partita_temp, [12006].* FROM [11002] left outer join [12006] on [11002].id_partitaPeriziaOnline = [12006].id_partita_jftech WHERE [11002].id_sinistro = ${this.props.IdSinistro}`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecPartite, '', retDataPartite => {
                for (let index = 0; index < retDataPartite.length; index++) {
                    this.strutturaStatiEliminaDati.idPartite.push({
                        keyJftech: retDataPartite[index].id_partitaPeriziaOnline,
                        keyGen: retDataPartite[index].id_partita
                    })
                }
                //ordino per partita importata gen
                if (this.isCompagniaGenerali()) {
                    let ordinaPartite = []
                    retDataPartite.forEach(part => {
                        if (part.importato === true || part.importato === 1)
                            ordinaPartite.unshift(part)
                        else
                            ordinaPartite.push(part)
                    })
                    retDataPartite = ordinaPartite;
                }
                if (retDataPartite.length > 0) {
                    for (let p = 0; p < retDataPartite.length; p++) {
                        this.aggiungiPartita(retDataPartite[p], '')
                    }
                    document.getElementById(`btnAggiungiPartita_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                    tempoAttesa += tempoOne * retDataPartite.length;
                }
                else {
                    //CASO INIZIALE
                    //Se il sinistro è Generali e la retDataPartite è vuota allora non è ancora stato inserito nulla sulla [11002], quindi estraggo solo i dati dalla [12006] inviati da Generali
                    if (this.isCompagniaGenerali()) {
                        const QueryExecPartiteGenerali = `SELECT id_partita, tipoPartita_codice, tipoPartita_descrizione, importato, valore_assicurato FROM [12006] WHERE id_incarico = '${this.props.IdIncarico}';`;
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecPartiteGenerali, '', retDataPartiteGen => {
                            //ordino per partita importata
                            let partiteOrdinate = []
                            retDataPartiteGen.forEach(part => {
                                if (part.importato === true || part.importato === 1)
                                    partiteOrdinate.unshift(part)
                                else
                                    partiteOrdinate.push(part)
                            })
                            retDataPartiteGen = partiteOrdinate;
                            for (let pGen = 0; pGen < retDataPartiteGen.length; pGen++) {
                                this.aggiungiPartita(retDataPartiteGen[pGen], 'casoIniziale')
                            }
                            tempoAttesa += tempoOne * retDataPartiteGen.length;
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        }, true, true);
                    }
                    else {
                        tempoAttesa += tempoOne;
                        this.aggiungiPartita('', '');
                    }
                }
                //GARANZIE > STIME
                //GARANZIE
                const QueryExecGaranzie = `SELECT DISTINCT [11003].id_garanziaPeriziaOnline, [11003].id_garanzia as cod_ele_garanzia, [11003].id_determinazioneDanno, [11003].franchigiaCondivisa, [11003].limiteIndennizzoEuro, [11003].limiteIndennizzoGiorni, [11003].limitePer, [11003].franchigiaEuro, [11003].franchigiaGiorni, [11003].scopertoPerc, [11003].scopertoMax, [11003].scopertoMin, [11003].guid_garanzia_temp, [12008].* FROM [11003] left outer join [12008] on [11003].id_garanziaPeriziaOnline = [12008].id_garanzia_jftech WHERE [11003].id_sinistro = ${this.props.IdSinistro}`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecGaranzie, '', retDataGaranzieZero => {
                    for (let index = 0; index < retDataGaranzieZero.length; index++) {
                        this.strutturaStatiEliminaDati.idGaranzie.push({
                            keyJftech: retDataGaranzieZero[index].id_garanziaPeriziaOnline,
                            keyGen: retDataGaranzieZero[index].id_garanzia
                        })
                    }
                    //ordino per franchigia condivisa
                    let retDataGaranzie = []
                    retDataGaranzieZero.forEach(elm => {
                        if (CustomJS.isNullEmpty(elm.franchigiaCondivisa))
                            // se la franchigia non c'è metto l' elemento all' inizio dell' array
                            retDataGaranzie.unshift(elm)
                        else
                            //se c'è lo metto alla fine 
                            retDataGaranzie.push(elm)
                    });
                    //ordino per garanzia importata gen
                    if (this.isCompagniaGenerali()) {
                        let ordinaGaranzie = []
                        retDataGaranzie.forEach(gar => {
                            if (gar.importato === true || gar.importato === 1)
                                ordinaGaranzie.unshift(gar)
                            else
                                ordinaGaranzie.push(gar)
                        })
                        retDataGaranzie = ordinaGaranzie;
                    }
                    if (retDataGaranzie.length > 0) {
                        for (let g = 0; g < retDataGaranzie.length; g++) {
                            this.aggiungiGaranzia(retDataGaranzie[g], '')
                        }
                        document.getElementById(`btnAggiungiGaranzia_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                        tempoAttesa += tempoOne * retDataGaranzie.length;
                    }
                    else {
                        //CASO INIZIALE
                        //Se il sinistro è Generali e la retDataGaranzie è vuota allora non è ancora stato inserito nulla sulla [11003], quindi estraggo solo i dati dalla [12008] inviati da Generali
                        if (this.isCompagniaGenerali()) {
                            const QueryExecGaranzieGenerali = `SELECT id_garanzia, tipoGaranzia_codice, tipoGaranzia_descrizione, importato, formaAssicurazione_codice, formulaAssicurazione_codice, partita_assicurata, gruppo_garanzia FROM [12008] WHERE id_incarico = '${this.props.IdIncarico}';`;
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecGaranzieGenerali, '', retDataGaranzieGen => {
                                //ordino per garanzia importata
                                debugger;
                                let garanzieOrdinate = [];
                                retDataGaranzieGen.forEach(gar => {
                                    if (gar.importato === true || gar.importato === 1)
                                        garanzieOrdinate.unshift(gar)
                                    else
                                        garanzieOrdinate.push(gar)
                                })
                                retDataGaranzieGen = garanzieOrdinate;
                                for (let gGen = 0; gGen < retDataGaranzieGen.length; gGen++) {
                                    this.aggiungiGaranzia(retDataGaranzieGen[gGen], 'casoIniziale')
                                }
                                tempoAttesa += tempoOne * retDataGaranzieGen.length;
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            }, true, true);
                        }
                        else {
                            tempoAttesa += tempoOne;
                            this.aggiungiGaranzia('', '')
                        }
                    }
                    //STIME (ultima query)
                    //STIME/DANNI + DANNEGGIATI
                    const QueryExecTabStima = `SELECT DISTINCT [11004].id_stima, [11004].id_danneggiato, [11004].id_partita, [11004].id_garanzia, [12011].id_danno, [12011].id_partita_colpita, [12011].id_garanzia_colpita, [12011].id_danneggiato as id_danneggiato_colpito, [00005].nominativo, [11002].guid_partita_temp, [11002].id_partita as cod_ele_partita, [11002].descrizione, [11003].guid_garanzia_temp, [11003].id_garanzia as cod_ele_garanzia, [12004].importato FROM [11004] left outer join [12011] on [11004].id_stima = [12011].id_stima_jftech left outer join [00005] on [11004].id_danneggiato = [00005].id_contatto left outer join [11002] on [11004].id_partita = [11002].id_partitaPeriziaOnline left outer join [11003] on [11004].id_garanzia = [11003].id_garanziaPeriziaOnline left outer join [12004] on [12011].id_danneggiato = [12004].id_danneggiato WHERE [11004].id_sinistro = ${this.props.IdSinistro}`;
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecTabStima, '', retDataStime => {
                        for (let index = 0; index < retDataStime.length; index++) {
                            this.strutturaStatiEliminaDati.idStime.push({
                                keyJftech: retDataStime[index].id_stima,
                                keyGen: retDataStime[index].id_danno
                            })
                        }
                        //anche se ci sono le stime carico comunque tutti i danneggiati nel datasource nel caso uno volesse rifare le stime
                        if (retDataStime.length > 0) {
                            this.statoInfoElaboratoPeritale.statoStime = 'Esistenti';
                            const QueryExecTabDanneggiati = `SELECT DISTINCT [00005].id_contatto as id_danneggiato_jftech, [00005].nominativo as danneggiato, [12004].id_danneggiato as guid_danneggiato, [12012].id_liquidazione FROM [00005] left outer join [12004] on [00005].id_contatto = [12004].id_danneggiato_jftech left outer join [12012] on [12004].id_danneggiato = [12012].id_danneggiato WHERE [00005].id_sinistro = ${this.props.IdSinistro}`;
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecTabDanneggiati, '', retDataDann => {
                                console.log(retDataDann) // fare attenzione al numero di liquidazioni!
                                //carico danneggiati sul ds danneggiati
                                for (let d = 0; d < retDataDann.length; d++) {
                                    let objDanneggiato = {
                                        id_danneggiato: retDataDann[d].id_danneggiato_jftech,
                                        danneggiato: CustomJS.isNullEmpty(retDataDann[d].danneggiato) ? retDataDann[d].nome + ' ' + retDataDann[d].cognome : retDataDann[d].danneggiato,
                                        guid_danneggiato: retDataDann[d].guid_danneggiato
                                    }
                                    this.danneggiati.push(objDanneggiato);
                                    this.strutturaStatiEliminaDati.idDanneggiatiGen.push({
                                        keyJftech: retDataDann[d].id_danneggiato_jftech,
                                        keyGen: retDataDann[d].guid_danneggiato,
                                        keyGenLiquidazione: retDataDann[d].id_liquidazione
                                    })
                                }
                                const unique2 = this.danneggiati.filter((obj, index) => {
                                    return index === this.danneggiati.findIndex(o => obj.id_danneggiato === o.id_danneggiato && obj.danneggiato === o.danneggiato && obj.guid_danneggiato === o.guid_danneggiato);
                                });
                                this.danneggiati = unique2;
                                sessionStorage.setItem('JellyfishTechnologyDanneggiatiPerizia', JSON.stringify(this.danneggiati));
                                this.dsDanneggiati = JSON.parse(sessionStorage.getItem('JellyfishTechnologyDanneggiatiPerizia')).map(elm => { return { VALUE: elm.id_danneggiato, TEXT: elm.danneggiato, GUID: elm.guid_danneggiato } });

                                //ordino per danneggiato importato da Generali
                                if (this.isCompagniaGenerali()) {
                                    let ordinaStime = []
                                    retDataStime.forEach(stima => {
                                        if (stima.importato === true || stima.importato === 1)
                                            ordinaStime.unshift(stima)
                                        else
                                            ordinaStime.push(stima)
                                    })
                                    retDataStime = ordinaStime;
                                }
                                for (let i = 0; i < retDataStime.length; i++) {
                                    this.aggiungiStima(retDataStime[i], '', false)
                                }
                                document.getElementById(`btnAggiungiStima_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                                tempoAttesa += tempoOne * retDataDann.length;
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            }, true, true);
                            tempoAttesa += tempoOne * retDataStime.length;
                        }
                        else {
                            this.statoInfoElaboratoPeritale.statoStime = 'Nessuna';
                            //SE LE STIME PER QUESTO SINISTRO NON ESISTONO CARICO I DANNEGGIATI
                            //DANNEGGIATI
                            const QueryExecDanneggiati = `SELECT DISTINCT [00005].id_contatto as id_danneggiato_jftech, [00005].nominativo as danneggiato, [12004].id_danneggiato as guid_danneggiato, [12004].importato FROM [00005] left outer join [12004] on [00005].id_contatto = [12004].id_danneggiato_jftech WHERE id_sinistro =  ${this.props.IdSinistro}`;
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecDanneggiati, '', retDataDanneggiati => {
                                //ordino i danneggiati mettendo prima quelli importati da Generali dopo quelli non importati
                                if (this.isCompagniaGenerali()) {
                                    let ordinaDanneggiati = []
                                    retDataDanneggiati.forEach(dann => {
                                        if (dann.importato === true || dann.importato === 1)
                                            ordinaDanneggiati.unshift(dann); // lo mette all' inizio dell' array
                                        else
                                            ordinaDanneggiati.push(dann); // lo mette alla fine dell'array
                                    })
                                    retDataDanneggiati = ordinaDanneggiati;
                                }
                                if (retDataDanneggiati.length > 0) {
                                    for (let d = 0; d < retDataDanneggiati.length; d++) {
                                        let objDanneggiato = {
                                            id_danneggiato: retDataDanneggiati[d].id_danneggiato_jftech,
                                            danneggiato: CustomJS.isNullEmpty(retDataDanneggiati[d].danneggiato) ? retDataDanneggiati[d].nome + ' ' + retDataDanneggiati[d].cognome : retDataDanneggiati[d].danneggiato,
                                            guid_danneggiato: retDataDanneggiati[d].guid_danneggiato
                                        }
                                        this.danneggiati.push(objDanneggiato);
                                    }
                                    const unique2 = this.danneggiati.filter((obj, index) => {
                                        return index === this.danneggiati.findIndex(o => obj.id_danneggiato === o.id_danneggiato && obj.danneggiato === o.danneggiato && obj.guid_danneggiato === o.guid_danneggiato);
                                    });
                                    this.danneggiati = unique2;
                                    sessionStorage.setItem('JellyfishTechnologyDanneggiatiPerizia', JSON.stringify(this.danneggiati));
                                    this.dsDanneggiati = JSON.parse(sessionStorage.getItem('JellyfishTechnologyDanneggiatiPerizia')).map(elm => { return { VALUE: elm.id_danneggiato, TEXT: elm.danneggiato, GUID: elm.guid_danneggiato } });

                                    for (let d = 0; d < retDataDanneggiati.length; d++) {
                                        this.aggiungiStima('', retDataDanneggiati[d], true)
                                    }
                                    tempoAttesa += tempoOne * retDataDanneggiati.length;
                                }
                                //SE NON CI SONO STIME CONTROLLO SE LA PERIZIA E SENZA CALCOLI!
                                setTimeout(() => {
                                    if (retDataIncarico[0].periziaNoCalcoli === true || retDataIncarico[0].periziaNoCalcoli === 1) {
                                        this.abilitaPeriziaSenzaCalcoli(true, '');
                                    }
                                }, 200 * retDataDanneggiati.length)
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            }, true, true);
                        }
                        //DENTRO LE STIME CHE SONO DENTRO LE GARANZIE CARICO LE FRANCHIGE 
                        setTimeout(() => {
                            const QueryExecFranchige = `SELECT * FROM [11005] where id_sinistro = ${this.props.IdSinistro}`;
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecFranchige, '', retDataFranc => {
                                retDataFranc.forEach(elm => {
                                    this.strutturaStatiGaranzie.forEach(garanzia => {
                                        if (document.getElementById(`Garanzia${garanzia.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === elm.codEleGaranzia) {
                                            if (elm.tipo === 'franchigia') {
                                                document.getElementById(`sceltaFranchigia${garanzia.num}_${this.props.IdInternoSinistro}`).innerText = '→';
                                                document.getElementById(`sceltaScoperto${garanzia.num}_${this.props.IdInternoSinistro}`).innerText = '';
                                            }
                                            else if (elm.tipo === 'scoperto') {
                                                document.getElementById(`sceltaFranchigia${garanzia.num}_${this.props.IdInternoSinistro}`).innerText = '';
                                                document.getElementById(`sceltaScoperto${garanzia.num}_${this.props.IdInternoSinistro}`).innerText = '→';
                                            }
                                        }
                                    })
                                })
                                //ID PER L ELIMINAZIONE DELLE FRANCHIGE
                                for (let index = 0; index < retDataFranc.length; index++) {
                                    this.strutturaStatiEliminaDati.idFranchige.push(retDataFranc[index].idFranchigiaCondivisa)
                                }
                                tempoAttesa += tempoOne * retDataFranc.length;
                                console.log(this.strutturaStatiEliminaDati)
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            }, true, true);
                        }, (retDataGaranzieZero.length * 300))

                        //SEMPRE DENTRO IL CALLBACK DELLE STIME CARICO GLI ID PER L EVENTUALE ELIMINAZIONE E IL TIMEOUT PER LA FINE DEL CARICAMENTO
                        //------------------ID PER L EVENTUALE ELIMINAZIONE INIZIO ------------------
                        const QueryExecIdPreesistenza = `SELECT id_preesistenza, id_partitaPeriziaOnline FROM [11006] WHERE id_sinistro = ${this.props.IdSinistro}`;
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdPreesistenza, '', retDataIdPrees => {
                            for (let index = 0; index < retDataIdPrees.length; index++) {
                                this.strutturaStatiEliminaDati.idPreesistenzaJftech.push(
                                    { idPrees: retDataIdPrees[index].id_preesistenza, idPartita: retDataIdPrees[index].id_partitaPeriziaOnline }
                                )
                            }
                            tempoAttesa += tempoOne * retDataIdPrees.length;
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        }, true, true);
                        //ID PREESISTENZA COSTRUZIONI
                        const QueryExecIdPreesCostr = `SELECT id_preesistenza_costruzione, id_preesistenza FROM [11007] where id_sinistro = ${this.props.IdSinistro}`;
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdPreesCostr, '', retDataIdPreesCostr => {
                            for (let index = 0; index < retDataIdPreesCostr.length; index++) {
                                this.strutturaStatiEliminaDati.idPreesistenzaCostruz.push({
                                    idCostruz: retDataIdPreesCostr[index].id_preesistenza_costruzione,
                                    idPrees: retDataIdPreesCostr[index].id_preesistenza
                                })
                            }
                            tempoAttesa += tempoOne * retDataIdPreesCostr.length;
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        }, true, true);
                        //ID PREESISTENZA DESCRIZIONI
                        const QueryExecIdPreesDescr = `SELECT id_preesistenza_descrizione, id_preesistenza FROM [11008] where id_sinistro = ${this.props.IdSinistro}`;
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdPreesDescr, '', retDataIdPreesDescr => {
                            for (let index = 0; index < retDataIdPreesDescr.length; index++) {
                                this.strutturaStatiEliminaDati.idPreesistenzaDescr.push({
                                    idDescr: retDataIdPreesDescr[index].id_preesistenza_descrizione, idPrees: retDataIdPreesDescr[index].id_preesistenza
                                })
                            }
                            tempoAttesa += tempoOne * retDataIdPreesDescr.length;
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        }, true, true);
                        if (this.isCompagniaGenerali()) {
                            //ID PREESISTENZA GENERALI
                            const QueryExecIdPreesGen = `SELECT id_preesistenza,id_preesistenza_jftech,id_prees_costruz_jftech FROM [12007] where id_incarico = '${this.props.IdIncarico}'`;
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdPreesGen, '', retDataIdPreesGen => {
                                for (let index = 0; index < retDataIdPreesGen.length; index++) {
                                    this.strutturaStatiEliminaDati.idPreesistenzaGen.push({
                                        idGen: retDataIdPreesGen[index].id_preesistenza, idJftech: retDataIdPreesGen[index].id_preesistenza_jftech, idCostruzJftech: retDataIdPreesGen[index].id_prees_costruz_jftech
                                    })
                                }
                                tempoAttesa += tempoOne * retDataIdPreesGen.length;
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            }, true, true);
                        }
                        //ID STIME DESCRIZIONI
                        const QueryExecIdStimeDescr = `SELECT [11009].id_stima_descrizione, [11009].id_stima, [12013].id_dettaglio, [12013].id_danno FROM [11009] left outer join [12013] on [11009].id_stima_descrizione = [12013].id_stima_descrizione where [11009].id_sinistro = ${this.props.IdSinistro}`;
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdStimeDescr, '', retDataIdStimeDescr => {
                            for (let index = 0; index < retDataIdStimeDescr.length; index++) {
                                this.strutturaStatiEliminaDati.idStimeDescrizioni.push({
                                    keyJftech: retDataIdStimeDescr[index].id_stima_descrizione,
                                    keyStima: retDataIdStimeDescr[index].id_stima,
                                    keyGen: retDataIdStimeDescr[index].id_dettaglio,
                                    keyDanno: retDataIdStimeDescr[index].id_danno
                                })
                            }
                            tempoAttesa += tempoOne * retDataIdStimeDescr.length;
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        }, true, true);
                        if (this.isCompagniaGenerali()) {
                            //ID GENERALI DANNO DETTAGLI INFO RICHIESTE
                            const QueryExecIdDannoDettInfoRichGen = `SELECT id_info_richiesta, id_dettaglio FROM [12016] where id_incarico = '${this.props.IdIncarico}';`;
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdDannoDettInfoRichGen, '', retDataIdDannoDettInfoRichGen => {
                                for (let index = 0; index < retDataIdDannoDettInfoRichGen.length; index++) {
                                    this.strutturaStatiEliminaDati.idDannoDettInfoRichGen.push(
                                        {
                                            idInfo: retDataIdDannoDettInfoRichGen[index].id_info_richiesta,
                                            idDettaglio: retDataIdDannoDettInfoRichGen[index].id_dettaglio
                                        }
                                    )
                                }
                                tempoAttesa += tempoOne * retDataIdDannoDettInfoRichGen.length;
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            }, true, true);
                        }
                        //ID COASSICURAZIONI
                        const QueryExecCoass = `SELECT [11010].id_compagnia_coass, [11010].id_stima, [12014].id_coassi_indiretta, [12014].id_danno FROM [11010] left outer join [12014] on [11010].id_compagnia_coass = [12014].id_compagnia_coass_jftech WHERE [11010].id_sinistro = ${this.props.IdSinistro}`;
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecCoass, '', retDataCoass => {
                            for (let index = 0; index < retDataCoass.length; index++) {
                                this.strutturaStatiEliminaDati.idCoassicurazioni.push({
                                    keyJftech: retDataCoass[index].id_compagnia_coass,
                                    keyStima: retDataCoass[index].id_stima,
                                    keyGen: retDataCoass[index].id_coassi_indiretta,
                                    keyDanno: retDataCoass[index].id_danno
                                });
                            }
                            tempoAttesa += tempoOne * retDataCoass.length;
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        }, true, true);
                        //ID INFO RICHIESTE
                        const QueryExecInfoRichieste = `SELECT [11011].id_info_richieste_compagnia, [11011].id_stima, [12015].id_info_richiesta, [12015].id_danno FROM [11011] left outer join [12015] on [11011].id_info_richieste_compagnia = [12015].id_info_rich_jftech WHERE [11011].id_sinistro = ${this.props.IdSinistro}`;
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecInfoRichieste, '', retDataInfo => {
                            for (let index = 0; index < retDataInfo.length; index++) {
                                this.strutturaStatiEliminaDati.idInfoRichieste.push({
                                    keyJftech: retDataInfo[index].id_info_richieste_compagnia,
                                    keyGen: retDataInfo[index].id_info_richiesta,
                                    idStima: retDataInfo[index].id_stima,
                                    idDanno: retDataInfo[index].id_danno
                                });
                            }
                            tempoAttesa += tempoOne * retDataInfo.length;
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        }, true, true);

                        //ID RIEPILOGO
                        const QueryExecIdRiep = `SELECT idRiepilogo_perizia FROM [11012] where id_sinistro = ${this.props.IdSinistro}`;
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdRiep, '', retDataIdPerizia => {
                            if (retDataIdPerizia.length > 0) {
                                this.strutturaStatiEliminaDati.idRiepilogoPerizia = retDataIdPerizia[0].idRiepilogo_perizia;
                                const QueryExecIdRiepDanneggiati = `SELECT idDanneggiatoRiep FROM [11013] where id_sinistro = ${this.props.IdSinistro}`;
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdRiepDanneggiati, '', retDataIdPeriziaDann => {
                                    for (let index = 0; index < retDataIdPeriziaDann.length; index++) {
                                        this.strutturaStatiEliminaDati.idRiepilogoDanneggiati.push(retDataIdPeriziaDann[index].idDanneggiatoRiep)
                                    }
                                    tempoAttesa += tempoOne * retDataIdPeriziaDann.length;
                                    const QueryExecIdRiepRigheDanneggiato = `SELECT idGarPart FROM [11014] where id_sinistro = ${this.props.IdSinistro}`;
                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdRiepRigheDanneggiato, '', retDataIdPeriziaRigheDann => {
                                        for (let index = 0; index < retDataIdPeriziaRigheDann.length; index++) {
                                            this.strutturaStatiEliminaDati.idRiepilogoRigheDanneggiato.push(retDataIdPeriziaRigheDann[index].idGarPart)
                                        }
                                        tempoAttesa += tempoOne * retDataIdPeriziaRigheDann.length;
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });
                                    });
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                }, true, true);
                            }
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        }, true, true);
                        //ID CAUSALI NON INDENNIZZO
                        if (this.isCompagniaGenerali()) {
                            const QueryExecIdCausali = `SELECT id_causale,id_danno FROM [12017] where id_incarico = '${this.props.IdIncarico}';`;
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdCausali, '', retDataIdCaus => {
                                if (retDataIdCaus.length > 0) {
                                    for (let index = 0; index < retDataIdCaus.length; index++) {
                                        this.strutturaStatiEliminaDati.idCausali.push({
                                            idCausale: retDataIdCaus[index].id_causale,
                                            idDanno: retDataIdCaus[index].id_danno
                                        })
                                    }
                                    tempoAttesa += tempoOne * retDataIdCaus.length;
                                }
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            }, true, true);
                        }
                        //------------------ID PER L EVENTUALE ELIMINAZIONE FINE ------------------
                        setTimeout(() => {
                            console.log("tempo attesa : " + tempoAttesa)
                            //todo da mettere un tempo calcolato
                            //document.getElementById(`paginaDettaglioFromPO1_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
                            if (this.statoInfoElaboratoPeritale.statoStime === 'Nessuna') { //SE NON CI SONO STIME
                                document.getElementById(`salvaEProcedi_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false; //posso salvare
                                document.getElementById(`tabelleCalcoloDaPerizia1_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true; // non posso accedere ai calcoli che non ci sono
                                document.getElementById(`btnModificaPerizia_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                                if (this.periziaSenzaCalcoli === false) {
                                    document.getElementById(`btnPeriziaZeroCalcoli_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
                                    document.getElementById(`btnPeriziaZeroCalcoliUndo_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
                                }
                            }
                            else { //SE CI SONO STIME
                                document.getElementById(`salvaEProcedi_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true; //NON POSSO SALVARE A MENO CHE NON CLICCO SUI TASTI MODIFICA E RICOSTRUISCI
                                document.getElementById(`tabelleCalcoloDaPerizia1_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false; //posso accedere ai calcoli
                                document.getElementById(`btnModificaPerizia_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
                                document.getElementById(`btnPeriziaZeroCalcoli_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                                document.getElementById(`btnPeriziaZeroCalcoliUndo_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                            }
                            //controllo se il sinistro è chiuso
                            if (this.props.restituito === true) {
                                this.sinistroChiuso();
                            }
                            //setTimeout(() => {
                                CustomJS.scrollAuto(500, 0, false, true);
                            //}, 700);                    
                            this.toggleBlocking2(false);
                        }, tempoAttesa)
                        //fine retData stime
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }, true, true);
                    //fine retData garanzie
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
                //fine retData partite
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
            //fine retData incarico
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    valoriPredefinitiCaricamentoPagina() {
        //Servizio Eseguito
        document.getElementById(`tipoServizio_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '3';

        //Rischio Assicurato (Fabbricato)
        document.getElementById(`tipoAbitazioneRischAss_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['condominio'];
        document.getElementById(`adibitoList_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['totalita'];
        document.getElementById(`adibitoAList_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['civile-abitazione'];
        document.getElementById(`strutturaPortante_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['calcestruzzo'];
        document.getElementById(`tamponamenti_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['laterizio'];
        document.getElementById(`ordituraTetto_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['calcestruzzo'];
        document.getElementById(`copertura_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['tegole'];
        document.getElementById(`finiture_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['standard'];
        if (this.isCompagniaUnipol())
            document.getElementById(`condizioneRischio_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['Normale'];
        else
            document.getElementById(`condizioneRischio_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['2'];
        //Rischio Assicurato (Fotovoltaico)
        document.getElementById(`impianto_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['installato-o-a-terra'];

        //Mezzi di chiusura
        document.getElementById(`tipoListMezzChius_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['condominio'];
        document.getElementById(`porteIngressoLocali_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['in-legno-pieno'];
        document.getElementById(`tipoFinestreFuoriTerra_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['in-legno-pieno'];
        document.getElementById(`antisfondamentoONo_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['antisfondamento'];
        document.getElementById(`tipoFinestreFuoriTerra2_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['tapparelle'];
        document.getElementById(`tipoFinestreFuoriTerra3_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['in-legno'];

        //Rivalsa
        if (this.isCompagniaGenerali())
            document.getElementById(`possibilitaRivalsaNo_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true

        //Operazioni Peritali 
        //document.getElementById(`noteOperazioniPeritali_${this.props.IdInternoSinistro}`).ej2_instances[0].value = `Ricevuto l'incarico, abbiamo conferito con l'Assicurato e fissato un sopralluogo presso l'ubicazione del rischio assicurato per il giorno ${this.datiPerNote.dataSopralluogo}.\n In tale occasione si prendeva visione dei luoghi e si acquisiva la documentazione necessaria all'espletamento delle indagini peritali.`
        //Definizione
        // document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value = `Al termine degli accertamenti peritali riteniamo che il sinistro rientri nelle garanzie di polizza in quanto non sussistono riserve di operatività.`
    }
    //-------------------------------GESTIONE CARICAMENTO FINE-------------------------------

    //-------------------------------GESTIONE SALVATAGGIO INIZIO-------------------------------
    requiredElaBoratoPeritale() {
        if (this.isCompagniaGenerali()) {
            this.formOptions.rules[`noteSinistroModalita_${this.props.IdInternoSinistro}`] = {
                required: [true, "Note Sinistro Modalità è un campo obbligatorio!"]
            }
            this.formOptions.rules[`tipoServizio_${this.props.IdInternoSinistro}`] = {
                required: [true, "Servizio Eseguito è un campo obbligatorio!"]
            }
            this.formOptions.rules[`condizioneRischio_${this.props.IdInternoSinistro}`] = {
                required: [true, "Condizione Rischio è un campo obbligatorio!"]
            }
        }
        //SE LA PERIZIA HA I I CALCOLI
        if (this.periziaSenzaCalcoli === false) {
            let almenoUnDanneggiatoOmesso = false;
            for (let index = 0; index < this.numComboPartite; index++) {
                if (document.getElementById(`containerComboPartita${index}_${this.props.IdInternoSinistro}`)) {
                    this.formOptions.rules[`Partita${index}_${this.props.IdInternoSinistro}`] = {
                        required: [true, "Partita è un campo obbligatorio!"]
                    }
                    this.formOptions.rules[`FormaGaranzia${index}_${this.props.IdInternoSinistro}`] = {
                        required: [true, "Forma Garanzia è un campo obbligatorio!"]
                    }
                    //CAMPI GENERALI
                    if (this.isCompagniaGenerali()) {
                        this.formOptions.rules[`PartitaRicevuta${index}_${this.props.IdInternoSinistro}`] = {
                            required: [true, "Partita Ricevuta è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`SommaAssicurataPartita${index}_${this.props.IdInternoSinistro}`] = {
                            required: [true, "Somma Assicurata Partita è un campo obbligatorio!"]
                        }
                    }
                }
            }
            for (let index = 0; index < this.numComboGaranzie; index++) {
                if (document.getElementById(`containerComboGaranzia${index}_${this.props.IdInternoSinistro}`)) {
                    this.formOptions.rules[`Garanzia${index}_${this.props.IdInternoSinistro}`] = {
                        required: [true, "Garanzia è un campo obbligatorio!"]
                    }
                    this.formOptions.rules[`DeterminazioneDanno${index}_${this.props.IdInternoSinistro}`] = {
                        required: [true, "Determinazione Danno è un campo obbligatorio!"]
                    }
                    //CAMPI GENERALI
                    if (this.isCompagniaGenerali()) {
                        this.formOptions.rules[`GaranziaRicevuta${index}_${this.props.IdInternoSinistro}`] = {
                            required: [true, "Garanzia Ricevuta è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`GruppoGaranzia${index}_${this.props.IdInternoSinistro}`] = {
                            required: [true, "Gruppo Garanzia è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`partitaAssicurata${index}_${this.props.IdInternoSinistro}`] = {
                            required: [true, "Partita Assicurata è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`FormulaAssicurazione${index}_${this.props.IdInternoSinistro}`] = {
                            required: [true, "Formula Assicurazione è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`FormaAssicurazione${index}_${this.props.IdInternoSinistro}`] = {
                            required: [true, "Forma Assicurazione è un campo obbligatorio!"]
                        }
                    }
                }
                else {
                    //Campi da togliere
                    if (this.formOptions.rules[`FranchigiaEuro${index}_${this.props.IdInternoSinistro}`])
                        delete this.formOptions.rules[`FranchigiaEuro${index}_${this.props.IdInternoSinistro}`];

                    if (this.formOptions.rules[`FranchigiaGiorni${index}_${this.props.IdInternoSinistro}`])
                        delete this.formOptions.rules[`FranchigiaGiorni${index}_${this.props.IdInternoSinistro}`];

                    if (this.formOptions.rules[`LimiteIndennizzoEuro${index}_${this.props.IdInternoSinistro}`])
                        delete this.formOptions.rules[`LimiteIndennizzoEuro${index}_${this.props.IdInternoSinistro}`];

                    if (this.formOptions.rules[`ScopertoMassimo${index}_${this.props.IdInternoSinistro}`])
                        delete this.formOptions.rules[`ScopertoMassimo${index}_${this.props.IdInternoSinistro}`];

                    if (this.formOptions.rules[`ScopertoMinimo${index}_${this.props.IdInternoSinistro}`])
                        delete this.formOptions.rules[`ScopertoMinimo${index}_${this.props.IdInternoSinistro}`];

                    if (this.formOptions.rules[`ScopertoPerc${index}_${this.props.IdInternoSinistro}`])
                        delete this.formOptions.rules[`ScopertoPerc${index}_${this.props.IdInternoSinistro}`];

                    if (this.formOptions.rules[`SommaAssicurata${index}_${this.props.IdInternoSinistro}`])
                        delete this.formOptions.rules[`SommaAssicurata${index}_${this.props.IdInternoSinistro}`];
                }
            }
            for (let index = 0; index < this.numComboStime; index++) {
                if (document.getElementById(`containerComboStima${index}_${this.props.IdInternoSinistro}`)) {
                    let danneggiatoObbligatorio = false;
                    if (document.getElementById(`btnSwitchDanneggiato${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].children === '+') {
                        if (CustomJS.isNullEmpty(document.getElementById(`DanneggiatoTabellaStima${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value))
                            danneggiatoObbligatorio = true;
                    }
                    else if (document.getElementById(`btnSwitchDanneggiato${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].children === '←') {
                        if (CustomJS.isNullEmpty(document.getElementById(`DanneggiatoAggiunto${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value))
                            danneggiatoObbligatorio = true;
                    }
                    if (danneggiatoObbligatorio === true) {
                        almenoUnDanneggiatoOmesso = true;
                        document.getElementById(`mexErroreDanneggiato${index}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
                        setTimeout(() => {
                            document.getElementById(`mexErroreDanneggiato${index}_${this.props.IdInternoSinistro}`).style.display = 'none'
                        }, 3500)
                    }
                    this.formOptions.rules[`PartitaTabellaStima${index}_${this.props.IdInternoSinistro}`] = {
                        required: [true, "Partita (Stime) è un campo obbligatorio!"]
                    }
                    this.formOptions.rules[`GaranziaTabellaStima${index}_${this.props.IdInternoSinistro}`] = {
                        required: [true, "Garanzia (Stime) è un campo obbligatorio!"]
                    }
                }
            }

            this.formObject = new FormValidator(`#formElaboratoPeritale_${this.props.IdInternoSinistro}`, this.formOptions);
            console.log(this.formObject)
            if (this.formObject.validate() && almenoUnDanneggiatoOmesso === false) {
                document.getElementById(`salvaEProcedi_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                this.collapseAccordionDatiSin()
                let arrayFranchigeCondivise = this.garanzieCondivise();
                setTimeout(() => {
                    this.salvaDati(arrayFranchigeCondivise);
                }, 350)
            }
            else {
                let fraseDanneggiato = 'Danneggiato (Stime) è un campo obbligatorio!';
                let messaggioCampiMancanti = '';
                this.formObject.errorRules.forEach(elm => {
                    messaggioCampiMancanti = messaggioCampiMancanti + elm.message + '\n'
                })
                messaggioCampiMancanti = almenoUnDanneggiatoOmesso === true ? (messaggioCampiMancanti + fraseDanneggiato) : messaggioCampiMancanti;
                toast.warn(messaggioCampiMancanti, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }
        }
        //SE NON HA I CALCOLI (CASO SPECIALE)
        else {
            this.formObject = new FormValidator(`#formElaboratoPeritale_${this.props.IdInternoSinistro}`, this.formOptions);
            if (this.formObject.validate()) {
                document.getElementById(`salvaEProcedi_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                let arrayFranchigeCondivise = this.garanzieCondivise();
                setTimeout(() => {
                    this.salvaDati(arrayFranchigeCondivise);
                }, 350)
            }
            else {
                let messaggioCampiMancanti = '';
                this.formObject.errorRules.forEach(elm => {
                    messaggioCampiMancanti = messaggioCampiMancanti + elm.message + '\n'
                })
                toast.warn(messaggioCampiMancanti, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }
        }
    }
    salvataggioInfoPerizia() {
        this.toggleBlocking2(true);
        //INFO INCARICO
        let queryIncaricoJftech = '';
        if (this.statoInfoElaboratoPeritale.statoInfo === 'Nuovo')
            queryIncaricoJftech = `INSERT INTO [11001] (id_sinistro,servizio_eseguito,RA_note_fabbr,RA_rischio_fabbr,RA_note_fabbr_no_conforme,RA_note_fotov,RA_rischio_fotov,RA_monitorato_fotov,SM_note,MC_note,MC_mezzi_chiusura,MC_motivazioni,OP_note,R_note,R_possibilita_rivalsa,Ris_note,condizioneRischioCodice,periziaNoCalcoli,R_cognome_debitore,R_nome_debitore,R_motivo_rivalsa) VALUES (@idSinistro,@servizioEseguito,@rischioAssicuratoNoteFabbr,@rischioFabbricato,@rischioAssicuratoFabbrNoteNoConforme,@rischioAssicuratoNoteFotov,@rischioFotovoltaico,@monitoratoFotov,@modalitaSinistroNote,@mezziChiusuraNote,@mezziChiusura,@motivazioniMezziChiusura,@operazioniPeritaliNote,@rivalsaNote,@possibilitaRivalsa,@riservaNote,@condizioneRischioCodice,@periziaNoCalcoli,@rivalsaCognomeDebitore,@rivalsaNomeDebitore,@rivalsaMotivo);`;
        else if (this.statoInfoElaboratoPeritale.statoInfo === 'Modifica')
            queryIncaricoJftech = `UPDATE [11001] SET servizio_eseguito = @servizioEseguito, RA_note_fabbr = @rischioAssicuratoNoteFabbr, RA_rischio_fabbr = @rischioFabbricato, RA_note_fabbr_no_conforme = @rischioAssicuratoFabbrNoteNoConforme, RA_note_fotov = @rischioAssicuratoNoteFotov,RA_rischio_fotov = @rischioFotovoltaico,RA_monitorato_fotov = @monitoratoFotov, SM_note = @modalitaSinistroNote,MC_note = @mezziChiusuraNote, MC_mezzi_chiusura = @mezziChiusura,MC_motivazioni = @motivazioniMezziChiusura, OP_note = @operazioniPeritaliNote, R_note = @rivalsaNote, R_possibilita_rivalsa = @possibilitaRivalsa, Ris_note = @riservaNote, condizioneRischioCodice = @condizioneRischioCodice,periziaNoCalcoli = @periziaNoCalcoli,R_cognome_debitore = @rivalsaCognomeDebitore,R_nome_debitore = @rivalsaNomeDebitore,R_motivo_rivalsa = @rivalsaMotivo WHERE id_sinistro = ${this.props.IdSinistro};`;

        //Mezzi di chiusura salvataggio : 1 = Pienamente conformi, 2 = Parzialmente conformi, 3 = Non conformi, 0 = non selezionato
        let mezziChiusura = null;
        if (document.getElementById(`pienamenteConformi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked)
            mezziChiusura = 1;
        else if (document.getElementById(`parzialmenteConformi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked)
            mezziChiusura = 2
        else if (document.getElementById(`nonConformi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked)
            mezziChiusura = 3
        else
            mezziChiusura = 0;

        let condizioneRischioAssicuratoCodice = CustomJS.isNullEmpty(document.getElementById(`condizioneRischio_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`condizioneRischio_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0];
        let condizioneRischioAssicuratoDescrizione = '';
        document.getElementById(`condizioneRischio_${this.props.IdInternoSinistro}`).ej2_instances[0].listData.forEach(elm => {
            if (elm.id === condizioneRischioAssicuratoCodice)
                condizioneRischioAssicuratoDescrizione = elm.text;
        });

        let jsonObjectIncaricoJftech = {
            idSinistro: this.props.IdSinistro,
            ServizioEseguito: CustomJS.isNullEmpty(document.getElementById(`tipoServizio_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`tipoServizio_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            RischioAssicuratoNoteFabbr: CustomJS.isNullEmpty(document.getElementById(`noteRischioAssicuratoFabbricato_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRischioAssicuratoFabbricato_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            RischioFabbricato: document.getElementById(`rischioFabbricatoConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].checked ? 1 : 0,
            RischioAssicuratoFabbrNoteNoConforme: CustomJS.isNullEmpty(document.getElementById(`noteRischioNoConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRischioNoConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            RischioAssicuratoNoteFotov: CustomJS.isNullEmpty(document.getElementById(`noteRischioAssicuratoFotovoltaico_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRischioAssicuratoFotovoltaico_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            RischioFotovoltaico: document.getElementById(`rischioFotovConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].checked ? 1 : 0,
            MonitoratoFotov: document.getElementById(`monitoratoFotovSi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked ? 1 : 0,
            MezziChiusuraNote: CustomJS.isNullEmpty(document.getElementById(`noteMezziChiusura_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteMezziChiusura_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            MezziChiusura: mezziChiusura,
            MotivazioniMezziChiusura: CustomJS.isNullEmpty(document.getElementById(`noteMotivazioniMezziChiusura_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteMotivazioniMezziChiusura_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            OperazioniPeritaliNote: null, //CustomJS.isNullEmpty(document.getElementById(`noteOperazioniPeritali_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteOperazioniPeritali_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            RivalsaNote: CustomJS.isNullEmpty(document.getElementById(`noteRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            //possibilità di rivalsa : 1 = 'si' - 0 = 'no', 2 = 'rinuncia'
            PossibilitaRivalsa: document.getElementById(`possibilitaRivalsaSi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked ? 1 : document.getElementById(`possibilitaRivalsaNo_${this.props.IdInternoSinistro}`).ej2_instances[0].checked ? 0 : document.getElementById(`possibilitaRivalsaRinuncia_${this.props.IdInternoSinistro}`).ej2_instances[0].checked ? 2 : 0,
            RiservaNote: document.getElementById(`noteRiserva_${this.props.IdInternoSinistro}`) ? document.getElementById(`noteRiserva_${this.props.IdInternoSinistro}`).ej2_instances[0].value : '',
            // definizioneNote: CustomJS.isNullEmpty(document.getElementById('noteDefinizione').ej2_instances[0].value) ? '' : document.getElementById('noteDefinizione').ej2_instances[0].value,
            CondizioneRischioCodice: condizioneRischioAssicuratoCodice,
            ModalitaSinistroNote: CustomJS.isNullEmpty(document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            PeriziaNoCalcoli: this.periziaSenzaCalcoli === true ? 1 : 0,
            RivalsaCognomeDebitore: document.getElementById(`cognomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value ?? '',
            RivalsaNomeDebitore: document.getElementById(`nomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value ?? '',
            RivalsaMotivo: document.getElementById(`motivoRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value ?? ''
        }
        let jsonObjectIncaricoGenerali = {}
        let queryIncaricoGenerali ='';
        if (this.isCompagniaGenerali()) {
            queryIncaricoGenerali = `UPDATE [12003] SET polizza_descrizioneRischioAssicurato = @rischioAssicuratoNoteFabbr, cause_sinistro = @modalitaSinistroNote, condizioniRischioAssicurato_codice = @condizioniRischioAssicuratoCodice,condizioniRischioAssicurato_descrizione = @condizioniRischioAssicuratoDescrizione,rispondenza_rischio = @rischioFabbricato, rispondenza_rischio_nota = @rischioAssicuratoFabbrNoteNoConforme, possibilita_rivalsa = @possibilitaRivalsa, servizioEseguito_codice = @tipoServizioCodice,servizioEseguito_descrizione = @tipoServizioDescr WHERE id_incarico = '${this.props.IdIncarico}'`;
            jsonObjectIncaricoGenerali = {
                RischioAssicuratoNoteFabbr: CustomJS.isNullEmpty(document.getElementById(`noteRischioAssicuratoFabbricato_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRischioAssicuratoFabbricato_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                CondizioniRischioAssicuratoCodice: condizioneRischioAssicuratoCodice,
                CondizioniRischioAssicuratoDescrizione: condizioneRischioAssicuratoDescrizione,
                RischioFabbricato: document.getElementById(`rischioFabbricatoConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].checked ? 1 : 0,
                RischioAssicuratoFabbrNoteNoConforme: CustomJS.isNullEmpty(document.getElementById(`noteRischioNoConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRischioNoConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                // definizioneNote: CustomJS.isNullEmpty(document.getElementById('noteDefinizione').ej2_instances[0].value) ? '' : document.getElementById('noteDefinizione').ej2_instances[0].value,
                PossibilitaRivalsa: document.getElementById(`possibilitaRivalsaSi_${this.props.IdInternoSinistro}`).ej2_instances[0].checked ? 1 : document.getElementById(`possibilitaRivalsaNo_${this.props.IdInternoSinistro}`).ej2_instances[0].checked ? 0 : document.getElementById(`possibilitaRivalsaRinuncia_${this.props.IdInternoSinistro}`).ej2_instances[0].checked ? 2 : 0,
                TipoServizioCodice: CustomJS.isNullEmpty(document.getElementById(`tipoServizio_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`tipoServizio_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                TipoServizioDescr: CustomJS.isNullEmpty(document.getElementById(`tipoServizio_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`tipoServizio_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                ModalitaSinistroNote: CustomJS.isNullEmpty(document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteSinistroModalita_${this.props.IdInternoSinistro}`).ej2_instances[0].value
            }
            console.log(jsonObjectIncaricoGenerali)
        }
        let jsonObjectIncaricoUnipolOss = {};
        let jsonObjectIncaricoUnipolPerizia = {};
        let queryIncaricoUnipolPerizia = '';
        let queryIncaricoUnipolOss = '';
        if (this.isCompagniaUnipol()) {
            //PERIZIA             
            if (this.statoInfoElaboratoPeritale.statoUnipolPerizia === 'n')
                queryIncaricoUnipolPerizia = `INSERT INTO [10003] (id_sinistro,riserveSuAtto,potenzialeRivalsa,cognomeDebitore,nomeDebitore,codiceMotivoRivalsa,noteRivalsa) values (${this.props.IdSinistro},@riserveSuAtto,@potenzialeRivalsa,@cognomeDebitore,@nomeDebitore,@codiceMotivoRivalsa,@noteRivalsa);`;
            else if (this.statoInfoElaboratoPeritale.statoUnipolPerizia === 'o')
                queryIncaricoUnipolPerizia = `UPDATE [10003] set riserveSuAtto = @riserveSuAtto, potenzialeRivalsa = @potenzialeRivalsa, cognomeDebitore = @cognomeDebitore, nomeDebitore = @nomeDebitore, codiceMotivoRivalsa = @codiceMotivoRivalsa, noteRivalsa = @noteRivalsa where id_sinistro = ${this.props.IdSinistro};`;
            jsonObjectIncaricoUnipolPerizia = {
                RiserveSuAtto: document.getElementById(`noteRiserva_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`noteRiserva_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : 1 : 0,
                PotenzialeRivalsa: $("input[type='radio'][name='possRivalsa']:checked").val() === 'si' ? 1 : 0,
                CognomeDebitore: document.getElementById(`cognomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value ?? '',
                NomeDebitore: document.getElementById(`nomeDebitoreRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value ?? '',
                CodiceMotivoRivalsa: document.getElementById(`motivoRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value ?? '',
                NoteRivalsa: $("input[type='radio'][name='possRivalsa']:checked").val() === 'si' ? document.getElementById(`noteRivalsa_${this.props.IdInternoSinistro}`).ej2_instances[0].value : ''
            }
            //OSSERVAZIONI
            if (this.statoInfoElaboratoPeritale.statoUnipolOsservazioni === 'n')
                queryIncaricoUnipolOss = `INSERT INTO [10004] (id_sinistro,noteRiservateCompagnia,rispondenzaRischio,noteRispondenzaRischio,codiceStatoFabbricato) values (${this.props.IdSinistro},@noteRiservateCompagnia,@rischioFabbricato,@noteRispondenzaRischio,@codiceStatoFabbricato)`;
            else if (this.statoInfoElaboratoPeritale.statoUnipolOsservazioni === 'o')
                queryIncaricoUnipolOss = `UPDATE [10004] set noteRiservateCompagnia = @noteRiservateCompagnia, rispondenzaRischio = @rischioFabbricato, noteRispondenzaRischio = @noteRispondenzaRischio, codiceStatoFabbricato = @codiceStatoFabbricato where id_sinistro = ${this.props.IdSinistro};`;

            jsonObjectIncaricoUnipolOss = {
                // definizioneNote: CustomJS.isNullEmpty(document.getElementById('noteDefinizione').ej2_instances[0].value) ? '' : document.getElementById('noteDefinizione').ej2_instances[0].value,
                NoteRiservateCompagnia: CustomJS.isNullEmpty(document.getElementById(`noteRiservateCompagnia_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRiservateCompagnia_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                RischioFabbricato: document.getElementById(`rischioFabbricatoConforme_${this.props.IdInternoSinistro}`).ej2_instances[0].checked ? 1 : 0,
                NoteRispondenzaRischio: !CustomJS.isNullEmpty(document.getElementById(`noteRischioAssicuratoFabbricato_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`noteRischioAssicuratoFabbricato_${this.props.IdInternoSinistro}`).ej2_instances[0].value : !CustomJS.isNullEmpty(document.getElementById(`noteRischioAssicuratoFotovoltaico_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`noteRischioAssicuratoFotovoltaico_${this.props.IdInternoSinistro}`).ej2_instances[0].value : '',
                CodiceStatoFabbricato: CustomJS.isNullEmpty(document.getElementById(`condizioneRischio_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`condizioneRischio_${this.props.IdInternoSinistro}`).ej2_instances[0].value[0]
            }
        }
        console.log(jsonObjectIncaricoJftech)
        console.log(jsonObjectIncaricoGenerali)
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryIncaricoJftech, JSON.stringify(jsonObjectIncaricoJftech), retDataIncaricoJftech => {
            if (this.isCompagniaGenerali()) {
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryIncaricoGenerali, JSON.stringify(jsonObjectIncaricoGenerali), retdataIncaricoIntestazione => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
            }
            if (this.isCompagniaUnipol()) {
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryIncaricoUnipolPerizia, JSON.stringify(jsonObjectIncaricoUnipolPerizia), retdataIncaricoUnipolPerizia => {
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryIncaricoUnipolOss, JSON.stringify(jsonObjectIncaricoUnipolOss), retdataIncaricoUnipolOss => {
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }, true, true);
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
            }
            this.toggleBlocking2(false);
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }

    async salvaDati(arrayFranchigeCondivise) {
        this.toggleBlocking2(true);

        this.salvataggioInfoPerizia();
        //ELIMINAZIONE DI TUTTI I CALCOLI SE L'UTENTE HA SCELTO DI MODIFICARE LE TABELLE

        let tempoOne = 75;
        let tempoTotale = (tempoOne * this.strutturaStatiStime.length) + (tempoOne * this.strutturaStatiPartite.length) + (tempoOne * this.strutturaStatiGaranzie.length)

        //AGGIUNTA DANNEGGIATO NEL CASO DI NUOVO INSERIMENTO INIZIO
        //----------------------INIZIO AGGIUNGI DANNEGGIATI---------------------------
        console.log(this.strutturaStatiPartite)
        console.log(this.strutturaStatiGaranzie)
        console.log(this.strutturaStatiStime)

        console.log('inizio danneggiati aggiunti')
        const fineInserimentoDanneggiati = () => {
            return new Promise((resolveFineDanneggiati) => {
                let funcListStime = [];
                this.strutturaStatiStime.forEach((elm) => {
                    const esegui = () => {
                        return new Promise(async (resolve) => {
                            //questo if serve per stabilire se il container con il danneggiato nuovo non sia stato eliminato
                            if (elm.stato !== 'd') {
                                if (document.getElementById(`btnSwitchDanneggiato${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].children === '←') {
                                    //aggiungo il tempo di una chiamata all' attesa
                                    tempoTotale += tempoOne;
                                    const guidGenerataDann = CustomJS.createGuid4();
                                    const aggiungiDanneggiatoJftech = () => {
                                        return new Promise((resolveInsert) => {
                                            let queryAggiungiDanneggiato = `INSERT INTO [00005] (id_sinistro,nominativo,aggiuntoDaStima,guid_utile_perizia) values (${this.props.IdSinistro}, @nominativo, 1, @guidGenerataDann);`;
                                            let jsonObjectDanneggiato = {
                                                Nominativo: CustomJS.isNullEmpty(document.getElementById(`DanneggiatoAggiunto${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`DanneggiatoAggiunto${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                GuidGenerataDann: guidGenerataDann
                                            }
                                            console.log(jsonObjectDanneggiato)
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryAggiungiDanneggiato, JSON.stringify(jsonObjectDanneggiato), retDataDanneggiato => {
                                                resolveInsert()
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                });
                                            }, true, true);
                                        })
                                    }
                                    await aggiungiDanneggiatoJftech();

                                    if (this.isCompagniaGenerali()) {
                                        const aggiungiDanneggiatoGEN = () => {
                                            return new Promise((resolveInsert) => {
                                                let queryAggiungiDanneggiatoGen = `BEGIN TRAN;INSERT INTO [12004] (id_danneggiato,id_danneggiato_jftech,id_incarico,nominativo,importato,aggiuntoDaStimaJfTech) values (@idDanneggiato,(SELECT id_contatto FROM [00005] WITH (UPDLOCK, HOLDLOCK) WHERE guid_utile_perizia = @guidGenerataDann and id_sinistro = ${this.props.IdSinistro}), '${this.props.IdIncarico}',@nominativo,0,1);COMMIT;`;
                                                let jsonObjectDanneggiatoGen = {
                                                    IdDanneggiato: CustomJS.createGuid4(),
                                                    Nominativo: CustomJS.isNullEmpty(document.getElementById(`DanneggiatoAggiunto${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`DanneggiatoAggiunto${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                    GuidGenerataDann: guidGenerataDann,
                                                }
                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryAggiungiDanneggiatoGen, JSON.stringify(jsonObjectDanneggiatoGen), retDataDanneggiatoGen => {
                                                    resolveInsert()
                                                }, messaggio => {
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    });
                                                }, true, true);
                                            })
                                        }
                                        await aggiungiDanneggiatoGEN();
                                    }
                                    //prendo gli id dei danneggiati aggiunti
                                    const queryIdDanneggiati = `SELECT T1.id_contatto,T1.id_sinistro,T1.danneggiato, T2.id_danneggiato as guid_danneggiato,T2.id_incarico FROM [00005] T1 left outer join [12004] T2 on T1.id_danneggiato = T2.id_danneggiato_jftech where T1.id_sinistro = ${this.props.IdSinistro} and aggiuntoDaStima = 1;`;
                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryIdDanneggiati, '', retDataDanneggiati => {
                                        for (let i = 0; i < retDataDanneggiati.length; i++) {
                                            let giaInserito = false;
                                            this.danneggiatiAggiunti.forEach(elm => {
                                                if (elm.VALUE === retDataDanneggiati[i].id_danneggiato)
                                                    giaInserito = true;
                                            })
                                            if (giaInserito === false) {
                                                this.danneggiatiAggiunti.push({
                                                    VALUE: retDataDanneggiati[i].id_danneggiato,
                                                    TEXT: retDataDanneggiati[i].danneggiato,
                                                    GUID: retDataDanneggiati[i].guid_danneggiato,
                                                })
                                            }
                                        }
                                        resolve('Prossima');
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });
                                    }, true, true);
                                }
                                else {
                                    resolve('Prossima');
                                }
                            }
                            else {
                                resolve('Prossima');
                            }
                        })
                    }
                    funcListStime.push(esegui);
                })
                const eseguiCiclica = (ind) => {
                    if (CustomJS.isNullEmpty(funcListStime[ind])) {
                        //fine ciclo chiamate nella strutturaStatiStime
                        //risolvo la Promise dentro la funzione fineInserimentoDanneggiati()
                        resolveFineDanneggiati();
                    }
                    else {
                        funcListStime[ind]().then(ret => {
                            if (ret === 'Prossima') {
                                eseguiCiclica(ind + 1);
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
                eseguiCiclica(0);
            })
        }

        await fineInserimentoDanneggiati()

        //----------------------FINE AGGIUNGI DANNEGGIATI-----------------------------

        console.log('inizio partite')

        const fineCrudPartite = () => {
            return new Promise((resolveFinePartite) => {

                let funcListPartite = [];
                //PARTITE
                this.strutturaStatiPartite.forEach((elm) => {
                    const esegui = () => {
                        return new Promise(async (resolve) => {

                            let guidPartitaTemp = elm.guidTemp;
                            let queryPartitaJftech = '';
                            let jsonObjectPartitaJftech = {};

                            const crudPartitaJftech = () => {
                                return new Promise((resolveCrud) => {
                                    if (elm.stato === 'o' && elm.casoIniziale === false) {
                                        queryPartitaJftech = `UPDATE [11002] SET id_partita = @idPartita, partitaDescrizione = @partitaDescr, formaGaranzia = @formaGaranzia, formaGaranziaDescrizione = @formaGaranziaDescrizione, sommaAssicurataPartita = @sommaAssicurata, descrizione = @descrizione WHERE id_partitaPeriziaOnline = ${elm.key};`
                                    }
                                    else if (elm.stato === 'o' && elm.casoIniziale === true) {
                                        queryPartitaJftech = `INSERT INTO [11002] (id_sinistro,id_partita,partitaDescrizione,formaGaranzia,formaGaranziaDescrizione,sommaAssicurataPartita,descrizione,guid_partita_temp) VALUES (${this.props.IdSinistro},@idPartita,@partitaDescr,@formaGaranzia,@formaGaranziaDescrizione,@sommaAssicurata,@descrizione,'${guidPartitaTemp}');`;
                                    }
                                    else if (elm.stato === 'n') {
                                        queryPartitaJftech = `INSERT INTO [11002] (id_sinistro,id_partita,partitaDescrizione,formaGaranzia, formaGaranziaDescrizione,sommaAssicurataPartita,descrizione,guid_partita_temp) VALUES (${this.props.IdSinistro},@idPartita,@partitaDescr,@formaGaranzia,@formaGaranziaDescrizione,@sommaAssicurata,@descrizione,'${guidPartitaTemp}');`;
                                    }
                                    else if (elm.stato === 'd') {
                                        queryPartitaJftech = `DELETE FROM [11002] WHERE id_partitaPeriziaOnline = ${elm.key};`
                                    }
                                    if (elm.stato === 'd') {
                                        jsonObjectPartitaJftech = {
                                        }
                                    }
                                    else {
                                        jsonObjectPartitaJftech = {
                                            IdPartita: CustomJS.isNullEmpty(document.getElementById(`Partita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`Partita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            PartitaDescr: CustomJS.isNullEmpty(document.getElementById(`Partita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`Partita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                            FormaGaranzia: CustomJS.isNullEmpty(document.getElementById(`FormaGaranzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`FormaGaranzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            FormaGaranziaDescrizione: CustomJS.isNullEmpty(document.getElementById(`FormaGaranzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`FormaGaranzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                            SommaAssicurata: CustomJS.isNullEmpty(document.getElementById(`SommaAssicurataPartita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`SommaAssicurataPartita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            Descrizione: CustomJS.isNullEmpty(document.getElementById(`DescrizionePartita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`DescrizionePartita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                        }
                                    }
                                    console.log(jsonObjectPartitaJftech)
                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryPartitaJftech, JSON.stringify(jsonObjectPartitaJftech), retDataPartitaJftech => {
                                        resolveCrud()
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });
                                    }, true, true);
                                })
                            }
                            await crudPartitaJftech()

                            if (this.isCompagniaGenerali()) {
                                const crudPartitaGEN = () => {
                                    return new Promise((resolveCrud) => {
                                        let queryPartitaGenerali = '';
                                        let jsonObjectPartitaGenerali = {};

                                        if (elm.stato === 'o' && elm.casoIniziale === false) {
                                            queryPartitaGenerali = `UPDATE [12006] SET tipoPartita_codice = @tipoPartitaCodice, tipoPartita_descrizione = @tipoPartitaDescr, partita_codice = @partitaCodice, partita_descrizione = @partitaDescr WHERE id_partita = '${elm.guid_partita}';`;
                                        }
                                        //Update Generali caso Iniziale
                                        else if (elm.stato === 'o' && elm.casoIniziale === true) {
                                            queryPartitaGenerali = `BEGIN TRAN;UPDATE [12006] SET id_partita_jftech = (SELECT id_partitaPeriziaOnline FROM [11002] WITH (UPDLOCK, HOLDLOCK) where guid_partita_temp = '${guidPartitaTemp}' and id_sinistro = ${this.props.IdSinistro}), partita_codice = @partitaCodice, partita_descrizione = @partitaDescr WHERE id_partita = '${elm.guid_partita}';COMMIT;`;
                                        }
                                        else if (elm.stato === 'n') {
                                            queryPartitaGenerali = `BEGIN TRAN;INSERT INTO [12006] (id_partita,id_partita_jftech,id_incarico,tipoPartita_codice,tipoPartita_descrizione,partita_codice,partita_descrizione,importato) VALUES (@guidPartita,(SELECT id_partitaPeriziaOnline FROM [11002] WITH (UPDLOCK, HOLDLOCK) where guid_partita_temp = '${guidPartitaTemp}' and id_sinistro = ${this.props.IdSinistro}),@idIncarico,@tipoPartitaCodice,@tipoPartitaDescr,@partitaCodice,@partitaDescr,0);COMMIT;`;
                                        }
                                        else if (elm.stato === 'd') {
                                            queryPartitaGenerali = `DELETE FROM [12006] WHERE id_partita = '${elm.guid_partita}' AND importato = 0;`
                                        }
                                        if (elm.stato === 'd') {
                                            jsonObjectPartitaGenerali = {
                                            }
                                        }
                                        else {
                                            jsonObjectPartitaGenerali = {
                                                GuidPartita: elm.guid_partita,
                                                IdIncarico: this.props.IdIncarico.toString(),
                                                TipoPartitaCodice: CustomJS.isNullEmpty(document.getElementById(`PartitaRicevuta${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`PartitaRicevuta${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                TipoPartitaDescr: CustomJS.isNullEmpty(document.getElementById(`PartitaRicevuta${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`PartitaRicevuta${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                                PartitaCodice: CustomJS.isNullEmpty(document.getElementById(`Partita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`Partita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                PartitaDescr: CustomJS.isNullEmpty(document.getElementById(`Partita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`Partita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                            }
                                        }
                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryPartitaGenerali, JSON.stringify(jsonObjectPartitaGenerali), retDataPartitaGen => {
                                            resolveCrud()
                                        }, messaggio => {
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            });
                                        }, true, true);
                                    })
                                }
                                await crudPartitaGEN()
                            }
                            //------------gestione modifica partite inizio----------
                            let tempoPrec = tempoOne;
                            if (elm.stato !== 'd') {
                                console.log("gestione modifica partite/preesistenze")
                                this.keyPartitePrecModificate.forEach(mod => {
                                    if (mod.key === elm.key) {
                                        //se prima era fabbricato e ora non più elimino le righe delle costruzioni (anche sulle tab generali)
                                        if (mod.codEle === 'TP016' || mod.codEle === '1') {
                                            if (document.getElementById(`Partita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value !== 'TP016' && document.getElementById(`Partita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value !== '1') {
                                                console.log("prima fabbricato ora altra partita")
                                                tempoPrec += tempoOne;
                                                this.strutturaStatiEliminaDati.idPreesistenzaJftech.forEach(prees => {
                                                    if (prees.idPartita === elm.key) {
                                                        let quanteCostruzioni = 0;

                                                        this.strutturaStatiEliminaDati.idPreesistenzaCostruz.forEach(costruz => {
                                                            if (costruz.idPrees === prees.idPrees) {
                                                                quanteCostruzioni++;
                                                            }
                                                        })
                                                        this.strutturaStatiEliminaDati.idPreesistenzaCostruz.forEach(costruz => {
                                                            if (costruz.idPrees === prees.idPrees) {
                                                                let queryPreesCostr = `DELETE FROM [11007] where id_preesistenza_costruzione = ${costruz.idCostruz};`;
                                                                let jsonObject = {};
                                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryPreesCostr, JSON.stringify(jsonObject), eliminato => {
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    });
                                                                }, true, true);
                                                                if (this.isCompagniaGenerali()) {
                                                                    this.strutturaStatiEliminaDati.idPreesistenzaGen.forEach(gen => {
                                                                        if (gen.idCostruzJftech === costruz.idCostruz) {
                                                                            if (quanteCostruzioni > 1) {
                                                                                let queryExec = `DELETE FROM [12007] where id_preesistenza = ${gen.idGen};`;
                                                                                let jsonObjectGen = {
                                                                                }
                                                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObjectGen), eliminato => {
                                                                                }, messaggio => {
                                                                                    toast.warn(messaggio, {
                                                                                        containerId: 'toastContainer1',
                                                                                        onClose: () => toast.dismiss(),
                                                                                        position: "bottom-right",
                                                                                        autoClose: 5000,
                                                                                        hideProgressBar: false,
                                                                                        closeOnClick: true,
                                                                                        pauseOnHover: true,
                                                                                        draggable: false,
                                                                                        progress: undefined,
                                                                                        newestOnTop: true,
                                                                                        rtl: false,
                                                                                        pauseOnFocusLoss: true
                                                                                    });
                                                                                }, true, true);
                                                                            }
                                                                            else if (quanteCostruzioni === 1) {
                                                                                let queryExec = `UPDATE [12007] set id_prees_costruz_jftech = null,valore_unitario = null,quantita = null, descrizione = @partitaDescr where id_preesistenza = ${gen.idGen};`;
                                                                                let jsonObjectGen = {
                                                                                    PartitaDescr: CustomJS.isNullEmpty(document.getElementById(`Partita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`Partita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                                                                }
                                                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObjectGen), update => {
                                                                                }, messaggio => {
                                                                                    toast.warn(messaggio, {
                                                                                        containerId: 'toastContainer1',
                                                                                        onClose: () => toast.dismiss(),
                                                                                        position: "bottom-right",
                                                                                        autoClose: 5000,
                                                                                        hideProgressBar: false,
                                                                                        closeOnClick: true,
                                                                                        pauseOnHover: true,
                                                                                        draggable: false,
                                                                                        progress: undefined,
                                                                                        newestOnTop: true,
                                                                                        rtl: false,
                                                                                        pauseOnFocusLoss: true
                                                                                    });
                                                                                }, true, true);
                                                                            }
                                                                            quanteCostruzioni--;
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        }
                                        //se prima era altro e adesso è fabbricato
                                        else if (mod.codEle !== 'TP016' && mod.codEle !== '1') {
                                            if (document.getElementById(`Partita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TP016' || document.getElementById(`Partita${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '1') {
                                                console.log("prima altra partita ora fabbricato")
                                                this.strutturaStatiEliminaDati.idPreesistenzaJftech.forEach(prees => {
                                                    if (prees.idPartita === elm.key) {
                                                        this.strutturaStatiEliminaDati.idPreesistenzaGen.forEach(gen => {
                                                            if (gen.idJftech === prees.idPrees) {
                                                                let queryExec = `DELETE FROM [12007] where id_preesistenza = ${gen.idGen};`
                                                                let jsonObjectGen = {
                                                                }
                                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObjectGen), eliminato => {
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    }
                                                                    );
                                                                }, true, true);
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        }
                                        //se prima era valore intero e ora non lo è più elimino i dati della preesistenza dal db
                                        if (mod.formaGaranzia === '2') {
                                            if (document.getElementById(`FormaGaranzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value !== '2') {
                                                tempoPrec += tempoOne;
                                                this.strutturaStatiEliminaDati.idPreesistenzaJftech.forEach(prees => {
                                                    if (prees.idPartita === elm.key) {
                                                        let queryExec = `DELETE FROM [11006] where id_preesistenza = ${prees.idPrees};`;
                                                        let jsonObjectPrees = {
                                                        }
                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObjectPrees), eliminato => {
                                                            this.strutturaStatiEliminaDati.idPreesistenzaCostruz.forEach(costruz => {
                                                                if (costruz.idPrees === prees.idPrees) {
                                                                    let queryExec = `DELETE FROM [11007] where id_preesistenza_costruzione = ${costruz.idCostruz};`;
                                                                    let jsonObjectCostruz = {                                                                        
                                                                    }
                                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObjectCostruz), eliminato => {
                                                                    }, messaggio => {
                                                                        toast.warn(messaggio, {
                                                                            containerId: 'toastContainer1',
                                                                            onClose: () => toast.dismiss(),
                                                                            position: "bottom-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            newestOnTop: true,
                                                                            rtl: false,
                                                                            pauseOnFocusLoss: true
                                                                        });
                                                                    }, true, true);
                                                                }
                                                            })
                                                            this.strutturaStatiEliminaDati.idPreesistenzaDescr.forEach(descr => {
                                                                if (descr.idPrees === prees.idPrees) {
                                                                    let queryExec = `DELETE FROM [11008] where id_preesistenza_descrizione = ${descr.idDescr};`;
                                                                    let jsonObjectDescr = {                                                                        
                                                                    }
                                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObjectDescr), eliminato => {
                                                                    }, messaggio => {
                                                                        toast.warn(messaggio, {
                                                                            containerId: 'toastContainer1',
                                                                            onClose: () => toast.dismiss(),
                                                                            position: "bottom-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            newestOnTop: true,
                                                                            rtl: false,
                                                                            pauseOnFocusLoss: true
                                                                        });
                                                                    }, true, true);
                                                                }
                                                            })
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            });
                                                        }, true, true);
                                                        if (this.isCompagniaGenerali()) {
                                                            this.strutturaStatiEliminaDati.idPreesistenzaGen.forEach(gen => {
                                                                if (gen.idJftech === prees.idPrees) {
                                                                    let queryExec = `DELETE FROM [12007] where id_preesistenza = ${gen.idGen};`
                                                                    let jsonObjectPreesGen = {                                                                        
                                                                    }
                                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObjectPreesGen), eliminato => {
                                                                    }, messaggio => {
                                                                        toast.warn(messaggio, {
                                                                            containerId: 'toastContainer1',
                                                                            onClose: () => toast.dismiss(),
                                                                            position: "bottom-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            newestOnTop: true,
                                                                            rtl: false,
                                                                            pauseOnFocusLoss: true
                                                                        });
                                                                    }, true, true);
                                                                }
                                                            })
                                                        }
                                                    }
                                                })
                                            }
                                        }
                                    }
                                })
                            }
                            else {
                                //se lo stato è d devo eliminare le preesistenze già compilate correlate
                                console.log('elimino preesistenze legate alla partita eliminata : ' + elm.key)
                                this.strutturaStatiEliminaDati.idPreesistenzaJftech.forEach(prees => {
                                    if (prees.idPartita === elm.key) {
                                        let queryExec = `DELETE FROM [11006] where id_preesistenza = ${prees.idPrees};`
                                        let jsonObjectPrees = {                                            
                                        }
                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObjectPrees), eliminato => {
                                            this.strutturaStatiEliminaDati.idPreesistenzaCostruz.forEach(costruz => {
                                                if (costruz.idPrees === prees.idPrees) {
                                                    let queryExec2 = `DELETE FROM [11007] where id_preesistenza_costruzione = ${costruz.idCostruz};`;
                                                    let jsonObjectCostruz = {                                                        
                                                    }
                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec2, JSON.stringify(jsonObjectCostruz), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    }, true, true);
                                                }
                                            })
                                            this.strutturaStatiEliminaDati.idPreesistenzaDescr.forEach(descr => {
                                                if (descr.idPrees === prees.idPrees) {
                                                    let queryExec3 = `DELETE FROM [11008] where id_preesistenza_descrizione = ${descr.idDescr};`;
                                                    let jsonObjectDescr = {                                                        
                                                    }
                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec3, JSON.stringify(jsonObjectDescr), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    }, true, true);
                                                }
                                            })
                                        }, messaggio => {
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            }
                                            );
                                        }, true, true);
                                        if (this.isCompagniaGenerali()) {
                                            this.strutturaStatiEliminaDati.idPreesistenzaGen.forEach(gen => {
                                                if (gen.idJftech === prees.idPrees) {
                                                    let queryExec4 = `DELETE FROM [12007] where id_preesistenza = ${gen.idGen};`;
                                                    let jsonObjectPreesGen = {                                                        
                                                    }
                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec4, JSON.stringify(jsonObjectPreesGen), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    }, true, true);
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                            //------------gestione modifica partite fine----------
                            console.log(tempoPrec)
                            setTimeout(() => {
                                resolve('Prossima');
                            }, tempoPrec)
                        })
                    }
                    funcListPartite.push(esegui);
                })

                const eseguiCiclica = (ind) => {
                    if (CustomJS.isNullEmpty(funcListPartite[ind])) {
                        //fine operazioni crud tutte le partite nella strutturaStatiPartite
                        resolveFinePartite()
                    }
                    else {
                        funcListPartite[ind]().then(ret => {
                            if (ret === 'Prossima') {
                                eseguiCiclica(ind + 1);
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
                eseguiCiclica(0);
            })
        }
        await fineCrudPartite();

        console.log('inizio garanzie')
        const fineCrudGaranzie = () => {
            return new Promise((resolveFineGaranzie) => {
                let funcListGaranzie = [];
                this.strutturaStatiGaranzie.forEach((elm) => {
                    const esegui = () => {
                        return new Promise(async (resolve) => {
                            let guidGaranziTemp = elm.guidTemp;
                            let queryGaranziaJftech = '';
                            let jsonObjectGaranziaJftech = {};

                            const crudGaranziaJftech = () => {
                                return new Promise((resolveGaranziaJftech) => {
                                    if (elm.stato === 'o' && elm.casoIniziale === false) {
                                        queryGaranziaJftech = `UPDATE [11003] SET id_garanzia = @idGaranzia, garanziaDescrizione = @garanziaDescr, id_determinazioneDanno = @determinazioneDanno, descrizioneDetermDanno = @determinazioneDannoDescr, franchigiaCondivisa = @franchigiaCondivisa,limiteIndennizzoEuro = @limiteIndennizzoEuro,limiteIndennizzoGiorni = @limiteIndennizzoGiorni, limitePer = @limitePer, franchigiaEuro = @franchigiaEuro,franchigiaGiorni = @franchigiaGiorni,scopertoPerc = @scopertoPerc,scopertoMax = @scopertoMax, scopertoMin = @scopertoMin WHERE id_garanziaPeriziaOnline = ${elm.key};`
                                    }
                                    else if (elm.stato === 'o' && elm.casoIniziale === true) {
                                        queryGaranziaJftech = `INSERT INTO [11003] (id_sinistro,id_garanzia,garanziaDescrizione,id_determinazioneDanno,descrizioneDetermDanno,franchigiaCondivisa,limiteIndennizzoEuro,limiteIndennizzoGiorni,limitePer,franchigiaEuro,franchigiaGiorni,scopertoPerc,scopertoMax,scopertoMin,guid_garanzia_temp) VALUES (${this.props.IdSinistro},@idGaranzia,@garanziaDescr,@determinazioneDanno,@determinazioneDannoDescr,@franchigiaCondivisa,@limiteIndennizzoEuro,@limiteIndennizzoGiorni,@limitePer,@franchigiaEuro,@franchigiaGiorni,@scopertoPerc,@scopertoMax,@scopertoMin,'${guidGaranziTemp}');`
                                    }
                                    else if (elm.stato === 'n') {
                                        queryGaranziaJftech = `INSERT INTO [11003] (id_sinistro,id_garanzia,garanziaDescrizione,id_determinazioneDanno,descrizioneDetermDanno,franchigiaCondivisa,limiteIndennizzoEuro,limiteIndennizzoGiorni,limitePer,franchigiaEuro,franchigiaGiorni,scopertoPerc,scopertoMax,scopertoMin,guid_garanzia_temp) VALUES (${this.props.IdSinistro},@idGaranzia,@garanziaDescr,@determinazioneDanno,@determinazioneDannoDescr,@franchigiaCondivisa,@limiteIndennizzoEuro,@limiteIndennizzoGiorni,@limitePer,@franchigiaEuro,@franchigiaGiorni,@scopertoPerc,@scopertoMax,@scopertoMin,'${guidGaranziTemp}');`
                                    }
                                    else if (elm.stato === 'd') {
                                        queryGaranziaJftech = `DELETE FROM [11003] WHERE id_garanziaPeriziaOnline = ${elm.key};`
                                    }
                                    if (elm.stato === 'd') {
                                        jsonObjectGaranziaJftech = {
                                        }
                                    }
                                    else {
                                        jsonObjectGaranziaJftech = {
                                            idSinistro: this.props.IdSinistro,
                                            IdGaranzia: CustomJS.isNullEmpty(document.getElementById(`Garanzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`Garanzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            GaranziaDescr: CustomJS.isNullEmpty(document.getElementById(`Garanzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`Garanzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                            DeterminazioneDanno: CustomJS.isNullEmpty(document.getElementById(`DeterminazioneDanno${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`DeterminazioneDanno${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DeterminazioneDannoDescr: CustomJS.isNullEmpty(document.getElementById(`DeterminazioneDanno${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`DeterminazioneDanno${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                            FranchigiaCondivisa: CustomJS.isNullEmpty(document.getElementById(`FranchigiaCondivisa${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`FranchigiaCondivisa${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            LimiteIndennizzoEuro: CustomJS.isNullEmpty(document.getElementById(`LimiteIndennizzoEuro${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`LimiteIndennizzoEuro${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value.toString(),
                                            LimiteIndennizzoGiorni: CustomJS.isNullEmpty(document.getElementById(`LimiteIndennizzoGiorni${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`LimiteIndennizzoGiorni${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value.toString(),
                                            LimitePer: CustomJS.isNullEmpty(document.getElementById(`LimiteIndennizzoPer${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`LimiteIndennizzoPer${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            FranchigiaEuro: CustomJS.isNullEmpty(document.getElementById(`FranchigiaEuro${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`FranchigiaEuro${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value.toString(),
                                            FranchigiaGiorni: CustomJS.isNullEmpty(document.getElementById(`FranchigiaGiorni${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`FranchigiaGiorni${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value.toString(),
                                            ScopertoPerc: CustomJS.isNullEmpty(document.getElementById(`ScopertoPerc${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`ScopertoPerc${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value.toString(),
                                            ScopertoMax: CustomJS.isNullEmpty(document.getElementById(`ScopertoMassimo${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`ScopertoMassimo${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value.toString(),
                                            ScopertoMin: CustomJS.isNullEmpty(document.getElementById(`ScopertoMinimo${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`ScopertoMinimo${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value.toString(),
                                        }
                                    }
                                    console.log(jsonObjectGaranziaJftech)
                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryGaranziaJftech, JSON.stringify(jsonObjectGaranziaJftech), retDataGaranziaJftech => {
                                        resolveGaranziaJftech();
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });
                                    }, true, true);
                                })
                            }
                            await crudGaranziaJftech()

                            if (this.isCompagniaGenerali()) {
                                const crudGaranziaGEN = () => {
                                    return new Promise((resolveGaranziaGEN) => {
                                        let queryGaranziaGenerali = ''
                                        let jsonObjectGaranziaGenerali = {}

                                        if (elm.stato === 'o' && elm.casoIniziale === false) {
                                            queryGaranziaGenerali = `UPDATE [12008] SET tipoGaranzia_codice = @tipoGaranziaCod,tipoGaranzia_descrizione = @tipoGaranziaDescr,  garanzia_codice = @garanziaCodice, garanzia_descrizione = @garanziaDescr,gruppo_garanzia = @gruppoGaranzia,partita_assicurata = @partitaAssicurata,somma_assicurata = @sommaAssicurata,formaAssicurazione_codice = @formaAssicurazioneCodice,formaAssicurazione_descrizione = @formaAssicurazioneDescrizione, formulaAssicurazione_codice = @formulaAssicurazioneCodice, formulaAssicurazione_descrizione = @formulaAssicurazioneDescrizione, franchigia = @franchigia,scoperto_percentuale = @scopertoPerc,scoperto_minimo = @scopertoMin WHERE id_garanzia = '${elm.guid_garanzia}';`;
                                        }
                                        else if (elm.stato === 'o' && elm.casoIniziale === true) {
                                            queryGaranziaGenerali = `BEGIN TRAN;UPDATE [12008] SET id_garanzia_jftech = (SELECT id_garanziaPeriziaOnline FROM [11003] WITH (UPDLOCK, HOLDLOCK) WHERE guid_garanzia_temp = '${guidGaranziTemp}' and id_sinistro = ${this.props.IdSinistro}), garanzia_codice = @garanziaCodice, garanzia_descrizione = @garanziaDescr,gruppo_garanzia = @gruppoGaranzia,partita_assicurata = @partitaAssicurata,somma_assicurata = @sommaAssicurata,formaAssicurazione_codice = @formaAssicurazioneCodice,formaAssicurazione_descrizione = @formaAssicurazioneDescrizione, formulaAssicurazione_codice = @formulaAssicurazioneCodice, formulaAssicurazione_descrizione = @formulaAssicurazioneDescrizione, franchigia = @franchigia,scoperto_percentuale = @scopertoPerc,scoperto_minimo = @scopertoMin WHERE id_garanzia = '${elm.guid_garanzia}';COMMIT;`
                                        }
                                        else if (elm.stato === 'n') {
                                            queryGaranziaGenerali = `BEGIN TRAN; INSERT INTO [12008] (id_garanzia,id_garanzia_jftech,id_incarico,tipoGaranzia_codice,tipoGaranzia_descrizione,garanzia_codice,garanzia_descrizione,gruppo_garanzia,partita_assicurata,somma_assicurata,formaAssicurazione_codice,formaAssicurazione_descrizione,formulaAssicurazione_codice,formulaAssicurazione_descrizione,franchigia,scoperto_percentuale,scoperto_minimo,importato) VALUES (@guidGaranzia,(SELECT id_garanziaPeriziaOnline FROM [11003] WITH (UPDLOCK, HOLDLOCK) where guid_garanzia_temp = '${guidGaranziTemp}' and id_sinistro = ${this.props.IdSinistro}),@idIncarico,@tipoGaranziaCod,@tipoGaranziaDescr,@garanziaCodice,@garanziaDescr,@gruppoGaranzia,@partitaAssicurata,@sommaAssicurata,@formaAssicurazioneCodice,@formaAssicurazioneDescrizione,@formulaAssicurazioneCodice,@formulaAssicurazioneDescrizione,@franchigia,@scopertoPerc,@scopertoMin,0);COMMIT;`
                                        }
                                        else if (elm.stato === 'd') {
                                            queryGaranziaGenerali = `DELETE FROM [12008] WHERE id_garanzia = '${elm.guid_garanzia}' AND importato = 0;`
                                        }
                                        if (elm.stato === 'd') {
                                            jsonObjectGaranziaGenerali = {
                                            }
                                        }
                                        else {
                                            jsonObjectGaranziaGenerali = {
                                                GuidGaranzia: elm.guid_garanzia,
                                                IdIncarico: this.props.IdIncarico.toString(),
                                                TipoGaranziaCod: CustomJS.isNullEmpty(document.getElementById(`GaranziaRicevuta${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`GaranziaRicevuta${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                TipoGaranziaDescr: CustomJS.isNullEmpty(document.getElementById(`GaranziaRicevuta${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`GaranziaRicevuta${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                                GaranziaCodice: CustomJS.isNullEmpty(document.getElementById(`Garanzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`Garanzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                GaranziaDescr: CustomJS.isNullEmpty(document.getElementById(`Garanzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`Garanzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                                GruppoGaranzia: CustomJS.isNullEmpty(document.getElementById(`GruppoGaranzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`GruppoGaranzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                PartitaAssicurata: CustomJS.isNullEmpty(document.getElementById(`partitaAssicurata${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`partitaAssicurata${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                SommaAssicurata: CustomJS.isNullEmpty(document.getElementById(`SommaAssicurata${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`SommaAssicurata${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value.toString(),
                                                FormaAssicurazioneCodice: CustomJS.isNullEmpty(document.getElementById(`FormaAssicurazione${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`FormaAssicurazione${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                FormaAssicurazioneDescrizione: CustomJS.isNullEmpty(document.getElementById(`FormaAssicurazione${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`FormaAssicurazione${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                                FormulaAssicurazioneCodice: CustomJS.isNullEmpty(document.getElementById(`FormulaAssicurazione${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`FormulaAssicurazione${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                FormulaAssicurazioneDescrizione: CustomJS.isNullEmpty(document.getElementById(`FormulaAssicurazione${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`FormulaAssicurazione${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                                Franchigia: CustomJS.isNullEmpty(document.getElementById(`FranchigiaEuro${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`FranchigiaEuro${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value.toString(),
                                                ScopertoPerc: CustomJS.isNullEmpty(document.getElementById(`ScopertoPerc${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`ScopertoPerc${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value.toString(),
                                                ScopertoMin: CustomJS.isNullEmpty(document.getElementById(`ScopertoMinimo${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`ScopertoMinimo${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value.toString(),
                                            }
                                        }
                                        console.log(jsonObjectGaranziaGenerali)
                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryGaranziaGenerali, JSON.stringify(jsonObjectGaranziaGenerali), retDataGaranziaGen => {
                                            resolveGaranziaGEN();
                                        }, messaggio => {
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            });
                                        }, true, true);
                                    })
                                }
                                await crudGaranziaGEN()
                            }
                            let tempoPrec = tempoOne;
                            if (elm.stato !== 'd') {
                                console.log("gestione modifica garanzie")
                                this.keyGaranziePrecModificate.forEach(garMod => {
                                    if (garMod.key === elm.key) {
                                        //se è diversa la determinazione del danno è cambiata
                                        if (garMod.determinazioneDanno !== document.getElementById(`DeterminazioneDanno${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                                            this.keyStimePrecModificate.forEach(stimaMod => {
                                                //rintraccio la stima dove era usata quella garanzia modificata
                                                if (stimaMod.codEleGaranzia === garMod.codEle) {
                                                    tempoPrec += tempoOne;
                                                    let jsonObjectStima = {};
                                                    let queryStima = '';
                                                    //se prima era un valore a stato uso 
                                                    if (garMod.determinazioneDanno === '003') {
                                                        //elimino i campi riferiti allo stato uso
                                                        queryStima = `UPDATE [11004] set DCA_valore_a_nuovo = null, DCA_degrado25 = null, DCA_valore_stato_uso = null,DRP_valore_a_nuovo = null, DRP_valore_a_nuovoMAN = null, DRP_degrado25 = null, DRP_degrado25MAN = null, DRP_valore_stato_uso = null, DRP_valore_stato_usoMAN = null, DN_valore_a_nuovo = null, DN_degrado25 = null, DN_valore_stato_uso = null, suppl_indenn_coass = null, vsu_coass = null where id_stima = ${stimaMod.keyJftech};`
                                                        jsonObjectStima = {
                                                        }
                                                    }
                                                    //se prima era a nuovo
                                                    else if (garMod.determinazioneDanno === '001') {
                                                        queryStima = `UPDATE [11004] set danno_compless_accertato = null, dopo_regola_proporzionale = null, dopo_regola_proporzionaleMAN = null where id_stima = ${stimaMod.keyJftech};`
                                                        jsonObjectStima = {
                                                        }
                                                    }
                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryStima, JSON.stringify(jsonObjectStima), update => {
                                                        //update anche delle righe 
                                                        this.strutturaStatiEliminaDati.idStimeDescrizioni.forEach(descr => {
                                                            if (descr.keyStima === stimaMod.keyJftech) {
                                                                let jsonObjectDescrizione = {};
                                                                let queryStimeDescr  = '';
                                                                if (garMod.determinazioneDanno === '003') {
                                                                    queryStimeDescr = `BEGIN TRAN;UPDATE [11009] set supp_indenn_perc = null, supp_indenn_importo = null , valore_del_bene = null where id_stima_descrizione = ${descr.keyJftech};UPDATE [12013] set degrado_percentuale = 0, valore_unitario_residuo = 0 where id_dettaglio = ${descr.keyGen};COMMIT;`
                                                                    jsonObjectDescrizione = {
                                                                    }
                                                                }
                                                                else if (garMod.determinazioneDanno === '001') {
                                                                    queryStimeDescr = `UPDATE [11009] set supp_indenn_perc = 0,supp_indenn_importo = 0,valore_del_bene = totale where id_stima_descrizione = ${descr.keyJftech};`
                                                                    jsonObjectDescrizione = {
                                                                    }
                                                                }
                                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryStimeDescr, JSON.stringify(jsonObjectDescrizione), update => {
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    });
                                                                }, true, true);
                                                            }
                                                        })
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    }, true, true);
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                            setTimeout(() => {
                                resolve('Prossima');
                            }, tempoPrec)
                        })
                    }
                    funcListGaranzie.push(esegui);
                })

                const eseguiCiclica = (ind) => {
                    if (CustomJS.isNullEmpty(funcListGaranzie[ind])) {
                        resolveFineGaranzie();
                    }
                    else {
                        funcListGaranzie[ind]().then(ret => {
                            if (ret === 'Prossima') {
                                eseguiCiclica(ind + 1);
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
                eseguiCiclica(0);
            })
        }
        await fineCrudGaranzie();

        console.log('inizio stime')
        const fineCrudStime = () => {
            return new Promise((resolveFineStime) => {
                //devo prendere gli id delle partite e garanzie nuove inserite
                let queryTrovaIdPartite = `SELECT id_partitaPeriziaOnline,guid_partita_temp FROM [11002] WHERE id_sinistro = ${this.props.IdSinistro}`;
                let queryTrovaIdGaranzie = `SELECT id_garanziaPeriziaOnline,guid_garanzia_temp FROM [11003] WHERE id_sinistro = ${this.props.IdSinistro}`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryTrovaIdPartite, '', retDataIdPartite => {
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryTrovaIdGaranzie, '', retDataIdGaranzie => {
                        //-------elimino franchige condivise gia presenti-------------
                        this.strutturaStatiEliminaDati.idFranchige.forEach(idFranc => {
                            let queryExec = `DELETE FROM [11005] where idFranchigiaCondivisa = ${idFranc};`;
                            let jsonObject = {
                            }
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObject), eliminato => {
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            }, true, true);
                        })
                        //STIME/DANNI
                        let funcListStime = [];
                        this.strutturaStatiStime.forEach((elm) => {
                            const esegui = () => {
                                return new Promise(async (resolve) => {
                                    let guidUtileStima = CustomJS.createGuid4();
                                    let queryStimaJftech = '';
                                    let jsonObjectStimaJftech = {}
                                    let keyPartita = 0;
                                    let keyGaranzia = 0;
                                    let idDanneggiato = 0;
                                    let guidDanneggiatoGenerali = '';
                                    //dati per stima
                                    if (elm.stato !== 'd') {
                                        retDataIdPartite.forEach(elmPartite => {
                                            if (elmPartite.guid_partita_temp === document.getElementById(`PartitaTabellaStima${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)
                                                keyPartita = elmPartite.id_partitaPeriziaOnline;
                                        })
                                        retDataIdGaranzie.forEach(elmGaranzie => {
                                            if (elmGaranzie.guid_garanzia_temp === document.getElementById(`GaranziaTabellaStima${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)
                                                keyGaranzia = elmGaranzie.id_garanziaPeriziaOnline;
                                        })
                                        if (document.getElementById(`btnSwitchDanneggiato${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].children === '+') {
                                            //dropdown
                                            idDanneggiato = CustomJS.isNullEmpty(document.getElementById(`DanneggiatoTabellaStima${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`DanneggiatoTabellaStima${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                            guidDanneggiatoGenerali = CustomJS.isNullEmpty(document.getElementById(`DanneggiatoTabellaStima${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`DanneggiatoTabellaStima${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.GUID;
                                        }
                                        else if (document.getElementById(`btnSwitchDanneggiato${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].children === '←') {
                                            //textbox
                                            let nomeDanneggiato = CustomJS.isNullEmpty(document.getElementById(`DanneggiatoAggiunto${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`DanneggiatoAggiunto${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                            this.danneggiatiAggiunti.forEach(elmDanneggiati => {
                                                if (elmDanneggiati.TEXT === nomeDanneggiato) {
                                                    idDanneggiato = elmDanneggiati.VALUE;
                                                    guidDanneggiatoGenerali = elmDanneggiati.GUID
                                                }
                                            })
                                        }
                                    }
                                    const crudStimaJftech = () => {
                                        return new Promise((resolveStimaJftech) => {
                                            if (elm.stato === 'o') {
                                                queryStimaJftech = `BEGIN TRAN;UPDATE [11004] SET id_danneggiato = @idDanneggiato, id_partita = @idPartita, id_garanzia = @idGaranzia,limite_sinistro = (SELECT limiteIndennizzoEuro FROM [11003] WITH (UPDLOCK, HOLDLOCK) where id_garanziaPeriziaOnline = @idGaranzia) WHERE id_stima = ${elm.keyJftech};COMMIT;`;
                                            }
                                            else if (elm.stato === 'n') {
                                                queryStimaJftech = `BEGIN TRAN;INSERT INTO [11004] (id_sinistro,id_danneggiato,id_partita,id_garanzia,limite_sinistro,guid_utile) VALUES (@idSinistro,@idDanneggiato,@idPartita,@idGaranzia,(SELECT limiteIndennizzoEuro FROM [11003] WITH (UPDLOCK, HOLDLOCK) where id_garanziaPeriziaOnline = @idGaranzia),'${guidUtileStima}');COMMIT;`;
                                            }
                                            else if (elm.stato === 'd') {
                                                queryStimaJftech = `DELETE FROM [11004] WHERE id_stima = ${elm.keyJftech};`
                                            }
                                            if (elm.stato === 'd') {
                                                jsonObjectStimaJftech = {};
                                            }
                                            else {
                                                jsonObjectStimaJftech = {
                                                    idSinistro: this.props.IdSinistro,
                                                    IdDanneggiato: idDanneggiato,
                                                    IdPartita: keyPartita.toString(),
                                                    IdGaranzia: keyGaranzia.toString()
                                                }
                                            }
                                            console.log(jsonObjectStimaJftech)
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryStimaJftech, JSON.stringify(jsonObjectStimaJftech), retDataStimaJftech => {
                                                resolveStimaJftech();
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                });
                                            }, true, true);
                                        })
                                    }
                                    await crudStimaJftech();
                                    //--------------------SALVA FRANCHIGIE CONDIVISE INIZIO--------------------
                                    //todo gestire operazioni crud franchigia condivisa!
                                    //5) SALVATAGGIO
                                    let countGruppo = 0;
                                    arrayFranchigeCondivise.forEach((gruppo) => {
                                        countGruppo++;
                                        gruppo.forEach(codEle => {
                                            let esegui = false;
                                            codEle.idContainer.forEach(container => {
                                                if (container === elm.id) {
                                                    esegui = true;
                                                }
                                            })
                                            if (esegui === true) {
                                                let idStima = 0;
                                                if (CustomJS.isNullEmpty(elm.keyJftech))
                                                    idStima = `(SELECT id_stima FROM [11004] WITH (UPDLOCK, HOLDLOCK) where guid_utile = '${guidUtileStima}' and id_sinistro = ${this.props.IdSinistro})`;
                                                else
                                                    idStima = elm.keyJftech;
                                                let queryExec = `BEGIN TRAN; INSERT INTO [11005] (id_sinistro,gruppo,idStimaCondivisa,codEleGaranzia,tipo,franchigia,scopertoMin,scopertoMax,scopertoPerc) VALUES (${this.props.IdSinistro},${countGruppo},${idStima},'${codEle.codEle}','${codEle.tipo}',${codEle.franchigia},${codEle.scopertoMin},${codEle.scopertoMax},${codEle.scopertoPerc}); COMMIT;`;
                                                let jsonObject = {
                                                }
                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObject), retData => {
                                                }, messaggio => {
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    });
                                                }, true, true);
                                            }
                                        })
                                    })
                                    //--------------------SALVA FRANCHIGIE CONDIVISE FINE--------------------
                                    if (this.isCompagniaGenerali()) {
                                        const crudStimaGEN = () => {
                                            return new Promise((resolveStimaGEN) => {
                                                let queryStimaGenerali = ''
                                                let jsonObjectStimaGenerali = {};
                                                if (elm.stato === 'o') {
                                                    queryStimaGenerali = `UPDATE [12011] SET id_partita_colpita = @guidPartita, id_garanzia_colpita = @guidGaranzia, id_danneggiato = @guidDanneggiato WHERE id_danno = ${elm.keyGenerali}`;
                                                }
                                                else if (elm.stato === 'n') {
                                                    queryStimaGenerali = `BEGIN TRAN; INSERT INTO [12011] (id_incarico,id_stima_jftech,id_partita_colpita,id_garanzia_colpita,id_danneggiato) VALUES ('${this.props.IdIncarico}',(SELECT id_stima FROM [11004] WITH (UPDLOCK, HOLDLOCK) where guid_utile = '${guidUtileStima}' and id_sinistro = ${this.props.IdSinistro}),@guidPartita, @guidGaranzia,@guidDanneggiato);COMMIT;`;
                                                }
                                                else if (elm.stato === 'd') {
                                                    queryStimaGenerali = `DELETE FROM [12011] WHERE id_danno = ${elm.keyGenerali};`
                                                }
                                                if (elm.stato === 'd') {
                                                    jsonObjectStimaGenerali = {
                                                    }
                                                }
                                                else {
                                                    jsonObjectStimaGenerali = {
                                                        GuidPartita: CustomJS.isNullEmpty(document.getElementById(`PartitaTabellaStima${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`PartitaTabellaStima${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.GUID,
                                                        GuidGaranzia: CustomJS.isNullEmpty(document.getElementById(`GaranziaTabellaStima${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`GaranziaTabellaStima${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.GUID,
                                                        GuidDanneggiato: guidDanneggiatoGenerali,
                                                    }
                                                }
                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryStimaGenerali, JSON.stringify(jsonObjectStimaGenerali), retDataStimaGen => {
                                                    resolveStimaGEN();
                                                }, messaggio => {
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    });
                                                }, true, true);
                                            })
                                        }
                                        await crudStimaGEN();

                                        if (elm.stato !== 'd') {
                                            //--------------------gestione infoRichieste se modifico garanzia inizio-----------------
                                            console.log("gestione modifica perizia info richieste")
                                            this.keyStimePrecModificate.forEach(precStima => {
                                                if (precStima.keyJftech === elm.keyJftech) {
                                                    if (precStima.codEleGaranzia !== document.getElementById(`GaranziaTabellaStima${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.cod_ele) {
                                                        this.strutturaStatiEliminaDati.idInfoRichieste.forEach(info => {
                                                            if (info.idStima === elm.keyJftech) {
                                                                let queryExec = `BEGIN TRAN; DELETE FROM [11011] where id_info_richieste_compagnia = ${info.keyJftech}; DELETE FROM [12015] where id_info_richiesta = ${info.keyGen};COMMIT;`;
                                                                let jsonObject = {
                                                                }
                                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObject), eliminato => {
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    });
                                                                }, true, true);
                                                            }
                                                        })
                                                    }
                                                }
                                            })
                                        }
                                        else {
                                            //elimino tutto ciò che riguarda quella stima
                                            //STIME DESCRIZIONI / DANNO DETTAGLI
                                            this.strutturaStatiEliminaDati.idStimeDescrizioni.forEach(descr => {
                                                if (descr.keyStima === elm.keyJftech) {
                                                    let queryExec = `BEGIN TRAN; DELETE FROM [11009] where id_stima_descrizione = ${descr.keyJftech}; DELETE FROM [12013] where id_dettaglio = ${descr.keyGen};COMMIT;`;
                                                    let jsonObjectDescr = {
                                                    }
                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObjectDescr), eliminato => {
                                                        //GENERALI DANNO DETTAGLI INFO RICHIESTE
                                                        this.strutturaStatiEliminaDati.idDannoDettInfoRichGen.forEach(dettInfo => {
                                                            if (dettInfo.idDettaglio === descr.keyGen) {
                                                                let queryExec2 = `DELETE FROM [12016] where id_info_richiesta = ${dettInfo.idInfo};`;
                                                                let jsonObjectDettInfo = {
                                                                }
                                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec2, JSON.stringify(jsonObjectDettInfo), eliminato => {
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    });
                                                                }, true, true);
                                                            }
                                                        })
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    }, true, true);
                                                }
                                            })
                                            //DANNO CAUSALI NON INDENNIZZO
                                            this.strutturaStatiEliminaDati.idCausali.forEach(causale => {
                                                if (causale.idDanno === elm.keyGenerali) {
                                                    let queryExec = `DELETE FROM [12017] WHERE id_causale = ${causale.idCausale};`
                                                    let jsonObjectEliminaCaus = {
                                                    }
                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObjectEliminaCaus), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    }, true, true);
                                                }
                                            })
                                            //UPDATE SULLA GENERALI DANNEGGIATI IMPORTO LORDO E NETTO
                                            //UPDATE SULLA GENERALI DANNEGGIATI LIQUIDAZIONI
                                            //se elimino la stima a cui era riferito quel danneggiato di Generali cancello anche l' importo netto e lordo dal db
                                            this.strutturaStatiEliminaDati.idDanneggiatiGen.forEach(dann => {
                                                if (dann.keyJftech === elm.danneggiatoEliminato) {
                                                    let queryUpdateDanneggiatiGen = `BEGIN TRAN;UPDATE [12004] set importo_netto = null, importo_lordo = null WHERE id_danneggiato = '${dann.keyGen}';UPDATE [12012] set arrotondamento = 0, importo_liquidazione = 0 WHERE id_liquidazione = '${dann.keyGenLiquidazione}';COMMIT;`;
                                                    let jsonObjectUpdateDanneggiati = {
                                                    }
                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryUpdateDanneggiatiGen, JSON.stringify(jsonObjectUpdateDanneggiati), retDataUpdate => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    }, true, true);
                                                }
                                            })
                                            //COASSICURAZIONI/ GENERALI COASSCIRAZIONI
                                            this.strutturaStatiEliminaDati.idCoassicurazioni.forEach(coass => {
                                                if (coass.keyStima === elm.keyJftech) {
                                                    let queryExec = `BEGIN TRAN;DELETE FROM [11010] WHERE id_compagnia_coass = ${coass.keyJftech};DELETE FROM [12014] WHERE id_coassi_indiretta = ${coass.keyGen};COMMIT;`
                                                    let jsonObjectCoass = {};
                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObjectCoass), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    }, true, true);
                                                }
                                            })
                                            //INFO RICHIESTE JFTECH + GEN
                                            this.strutturaStatiEliminaDati.idInfoRichieste.forEach(info => {
                                                if (info.idStima === elm.keyJftech) {
                                                    let queryExec = `BEGIN TRAN; DELETE FROM [11011] where id_info_richieste_compagnia = ${info.keyJftech}; DELETE FROM [12015] where id_info_richiesta = ${info.keyGen};COMMIT;`;
                                                    let jsonObject = {};
                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObject), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    }, true, true);
                                                }
                                            })
                                            //RIEPILOGO LO ELIMINO TUTTO SE CANCELLO ANCHE SOLO UNA STIMA
                                            //RIEPILOGO
                                            let queryExec = `DELETE FROM [11012] WHERE idRiepilogo_perizia = ${this.strutturaStatiEliminaDati.idRiepilogoPerizia};`;
                                            let jsonObjectRiep = {};
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObjectRiep), eliminato => {
                                                //ELIMINO DANNEGGIATI RIEPILOGO
                                                this.strutturaStatiEliminaDati.idRiepilogoDanneggiati.forEach(idDann => {
                                                    let queryExec2 = `DELETE FROM [11013] where idDanneggiatoRiep = ${idDann};`;
                                                    let jsonObjectRiepDann = {};
                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec2, JSON.stringify(jsonObjectRiepDann), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    }, true, true);
                                                })
                                                //ELIMINO RIGHE PARTITA GARANZIA PER OGNI DANNEGGIATO
                                                this.strutturaStatiEliminaDati.idRiepilogoRigheDanneggiato.forEach(idRiga => {
                                                    let queryExec = `DELETE FROM [11014] where idGarPart = ${idRiga};`;
                                                    let jsonObjectDannRiga = {};
                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryExec, JSON.stringify(jsonObjectDannRiga), eliminato => {
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    }, true, true);
                                                })
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                }
                                                );
                                            });
                                        }
                                        //--------------------gestione infoRichieste se modifico garanzia fine-----------------
                                    }
                                    resolve('Prossima');
                                })
                            }
                            funcListStime.push(esegui);
                        })
                        const eseguiCiclica = (ind) => {
                            if (CustomJS.isNullEmpty(funcListStime[ind])) {
                                resolveFineStime();
                            }
                            else {
                                funcListStime[ind]().then(ret => {
                                    if (ret === 'Prossima') {
                                        eseguiCiclica(ind + 1);
                                    }
                                }).catch(err => {
                                    console.log(err)
                                })
                            }
                        }
                        eseguiCiclica(0);
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }, true, true);
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
            })
        }
        await fineCrudStime();

        console.log("tempo salvataggio : " + tempoTotale)
        if (this.periziaSenzaCalcoli === false) {
            setTimeout(() => {
                this.paginaPeriziaOnlineCalcoli();
                this.toggleBlocking2(false);
            }, tempoTotale)
        }
        else {
            setTimeout(() => {
                this.paginaDettaglioSinistro();
                this.toggleBlocking2(false);
            }, tempoTotale)
        }
    }
    garanzieCondivise() {
        //1) = FRANCHIGE CONDIVISE SULLE GARANZIE
        let array = []
        this.strutturaStatiGaranzie.forEach(elm => {
            if (document.getElementById(`containerComboGaranzia${elm.num}_${this.props.IdInternoSinistro}`)) {
                if (!CustomJS.isNullEmpty(document.getElementById(`FranchigiaCondivisa${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)) {
                    let garanziaPartenza = document.getElementById(`Garanzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                    let guidPartenza = elm.guidTemp;
                    let garanziaCondivisa = document.getElementById(`FranchigiaCondivisa${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    let guidCondivisa = "";
                    let franchigiaEuro = CustomJS.isNullEmpty(document.getElementById(`FranchigiaEuro${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`FranchigiaEuro${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    let scopMin = CustomJS.isNullEmpty(document.getElementById(`ScopertoMinimo${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`ScopertoMinimo${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    let scopMax = CustomJS.isNullEmpty(document.getElementById(`ScopertoMassimo${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`ScopertoMassimo${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    let scopPerc = CustomJS.isNullEmpty(document.getElementById(`ScopertoPerc${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`ScopertoPerc${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    let tipologia = "";
                    if (document.getElementById(`sceltaFranchigia${elm.num}_${this.props.IdInternoSinistro}`).innerText === "→") {
                        tipologia = "franchigia";
                    }
                    else if (document.getElementById(`sceltaScoperto${elm.num}_${this.props.IdInternoSinistro}`).innerText === "→")
                        tipologia = "scoperto"

                    this.strutturaStatiGaranzie.forEach(trovaGuid => {
                        if (document.getElementById(`containerComboGaranzia${trovaGuid.num}_${this.props.IdInternoSinistro}`)) {
                            if (document.getElementById(`Garanzia${trovaGuid.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === garanziaCondivisa) {
                                guidCondivisa = trovaGuid.guidTemp
                            }
                        }
                    })

                    let gruppoNuovo = [
                        { tipo: tipologia, codEle: garanziaPartenza, guid: guidPartenza, idContainer: [], franchigia: franchigiaEuro, scopertoMin: scopMin, scopertoMax: scopMax, scopertoPerc: scopPerc },
                        { tipo: tipologia, codEle: garanziaCondivisa, guid: guidCondivisa, idContainer: [], franchigia: franchigiaEuro, scopertoMin: scopMin, scopertoMax: scopMax, scopertoPerc: scopPerc }
                    ];

                    let inserisci = true;
                    array.forEach(gruppoVecchio => {
                        gruppoVecchio.forEach(elmVecchio => {
                            if (elmVecchio.codEle === garanziaPartenza) {
                                gruppoVecchio.push(
                                    { tipo: tipologia, codEle: garanziaCondivisa, guid: guidCondivisa, idContainer: [], franchigia: franchigiaEuro, scopertoMin: scopMin, scopertoMax: scopMax, scopertoPerc: scopPerc }
                                );
                                inserisci = false
                            }
                            else if (elmVecchio.codEle === garanziaCondivisa) {
                                gruppoVecchio.push(
                                    { tipo: tipologia, codEle: garanziaPartenza, guid: guidPartenza, idContainer: [], franchigia: franchigiaEuro, scopertoMin: scopMin, scopertoMax: scopMax, scopertoPerc: scopPerc }
                                );
                                inserisci = false
                            }
                        })
                    })
                    if (inserisci === true)
                        array.push(gruppoNuovo);
                }

            }
        })
        //2) = FRANCHIGE CONDIVISE SULLE GARANZIE E ANCHE SULLE STIME PERCHE HANNO LA STESSA GARANZIA
        this.strutturaStatiStime.forEach(stima => {
            if (document.getElementById(`containerComboStima${stima.num}_${this.props.IdInternoSinistro}`)) {
                array.forEach(gruppo => {
                    gruppo.forEach(elm => {
                        if (document.getElementById(`GaranziaTabellaStima${stima.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === elm.guid) {
                            elm.idContainer.push(stima.id)
                        }
                    })
                })
            }
        })
        //3) : FRANCHIGE NON CONDIVISE MA CHE POSSONO ESSERLO SE SU PIU STIME
        let arrayGaranzieSingole = []
        this.strutturaStatiGaranzie.forEach(elm => {
            if (document.getElementById(`containerComboGaranzia${elm.num}_${this.props.IdInternoSinistro}`)) {
                let esiste = false;
                array.forEach(gruppo => {
                    gruppo.forEach(elm2 => {
                        if (elm2.codEle === document.getElementById(`Garanzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                            esiste = true;
                        }
                    })
                })
                if (esiste === false) {
                    let tipologia = "";

                    if (document.getElementById(`sceltaFranchigia${elm.num}_${this.props.IdInternoSinistro}`).innerText === "→") {
                        tipologia = "franchigia";
                    }
                    else if (document.getElementById(`sceltaScoperto${elm.num}_${this.props.IdInternoSinistro}`).innerText === "→") {
                        tipologia = "scoperto"
                    }

                    let garanzia = document.getElementById(`Garanzia${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                    let guid = elm.guidTemp;
                    let franchigiaEuro = CustomJS.isNullEmpty(document.getElementById(`FranchigiaEuro${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`FranchigiaEuro${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    let scopMin = CustomJS.isNullEmpty(document.getElementById(`ScopertoMinimo${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`ScopertoMinimo${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    let scopMax = CustomJS.isNullEmpty(document.getElementById(`ScopertoMassimo${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`ScopertoMassimo${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    let scopPerc = CustomJS.isNullEmpty(document.getElementById(`ScopertoPerc${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`ScopertoPerc${elm.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;

                    arrayGaranzieSingole.push(
                        { tipo: tipologia, codEle: garanzia, guid: guid, idContainer: [], franchigia: franchigiaEuro, scopertoMin: scopMin, scopertoMax: scopMax, scopertoPerc: scopPerc }
                    )
                }
            }
        });
        //4) :AGGIUNTA DELLE FRANCHIGE SINGOLE CONDIVISE TRA LORO SULLE STIME ALL ARRAY
        this.strutturaStatiStime.forEach(stima => {
            if (document.getElementById(`containerComboStima${stima.num}_${this.props.IdInternoSinistro}`)) {
                arrayGaranzieSingole.forEach(elm => {
                    if (document.getElementById(`GaranziaTabellaStima${stima.num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === elm.guid) {
                        elm.idContainer.push(stima.id)
                    }
                })

            }
        });
        arrayGaranzieSingole.forEach(garanziaSingola => {
            let arr = []
            arr.push(garanziaSingola)
            array.push(arr)
        })
        return array;
    }
    eliminaTuttiCalcoliPerizia() {
        this.toggleBlocking2(true);
        let tempoOneDelete = 75;
        //DATI QUESTA PAGINA
        let tempoPartite = tempoOneDelete * this.strutturaStatiEliminaDati.idPartite.length;
        let tempoGaranzie = tempoOneDelete * this.strutturaStatiEliminaDati.idGaranzie.length;
        let tempoStime = tempoOneDelete * this.strutturaStatiEliminaDati.idStime.length;
        //DATI PAGINA CALCOLI
        let tempoPreesistenze = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaJftech.length;
        let tempoPreesCostruz = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaCostruz.length;
        let tempoPreesDescr = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaDescr.length;
        let tempoPreesGen = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaGen.length;
        let tempoStimeDescr = tempoOneDelete * this.strutturaStatiEliminaDati.idStimeDescrizioni.length;
        let tempoDannoDettInfoRichGen = tempoOneDelete * this.strutturaStatiEliminaDati.idDannoDettInfoRichGen.length;
        let tempoCoassicurazioni = tempoOneDelete * this.strutturaStatiEliminaDati.idCoassicurazioni.length;
        let tempoInfoRichiesteGen = tempoOneDelete * this.strutturaStatiEliminaDati.idInfoRichieste.length;
        let tempoIdDanneggiati = tempoOneDelete * this.strutturaStatiEliminaDati.idDanneggiatiGen.length;
        let tempoUnipol = tempoOneDelete;
        let tempoTotaliPerizia = tempoOneDelete;
        let tempoFranchige = tempoOneDelete * this.strutturaStatiEliminaDati.idFranchige.length;
        let tempoIdRiepilogo = tempoOneDelete;
        let tempoIdRiepilogoDanneggiati = tempoOneDelete * this.strutturaStatiEliminaDati.idRiepilogoDanneggiati.length;
        let tempoIdRiepilogoRigheDanneggiato = tempoOneDelete * this.strutturaStatiEliminaDati.idRiepilogoRigheDanneggiato.length;
        let tempoIdCausali = tempoOneDelete * this.strutturaStatiEliminaDati.idCausali.length;
        //TOTALE
        let tempoTotale = tempoPartite + tempoGaranzie + tempoStime + tempoPreesistenze + tempoPreesCostruz + tempoPreesDescr + tempoPreesGen + tempoStimeDescr +
            tempoDannoDettInfoRichGen + tempoCoassicurazioni + tempoInfoRichiesteGen + tempoIdDanneggiati + tempoUnipol +
            tempoTotaliPerizia + tempoFranchige + tempoIdRiepilogo + tempoIdRiepilogoDanneggiati + tempoIdRiepilogoRigheDanneggiato + tempoIdCausali;
        console.log(this.strutturaStatiEliminaDati)
        //ELIMINO TABELLE QUESTA PAGINA
        this.strutturaStatiEliminaDati.idPartite.forEach(elm => {
            const queryEliminaPartite = `BEGIN TRAN;DELETE FROM [11002] WHERE id_partitaPeriziaOnline = ${elm.keyJftech};DELETE FROM [12006] WHERE id_partita = '${elm.keyGen}' and importato != 1;UPDATE [12006] set partita_codice = null,partita_descrizione = null,valore_assicurato = 0, valore_riferimento = 0,totale_preesistenza = 0,scopertura_percentuale = 0 WHERE id_partita = '${elm.keyGen}' and importato = 1;COMMIT;`;
            console.log(queryEliminaPartite)
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaPartite, '', retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,

                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        })
        this.strutturaStatiEliminaDati.idGaranzie.forEach(elm => {
            let queryEliminaGaranzie = `BEGIN TRAN;DELETE FROM [11003] WHERE id_garanziaPeriziaOnline = ${elm.keyJftech};DELETE FROM [12008] WHERE id_garanzia = '${elm.keyGen}' and importato != 1;UPDATE [12008] set garanzia_codice = null,garanzia_descrizione = null,gruppo_garanzia = null,partita_assicurata = null,somma_assicurata = null,formaAssicurazione_codice = null,formaAssicurazione_descrizione = null,formulaAssicurazione_codice = null,formulaAssicurazione_descrizione = null,franchigia = 0,scoperto_percentuale = 0,scoperto_minimo = 0 WHERE id_garanzia = '${elm.keyGen}' and importato = 1;COMMIT;`;
            console.log(queryEliminaGaranzie)
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaGaranzie, '', retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,

                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        })
        this.strutturaStatiEliminaDati.idStime.forEach(elm => {
            let queryEliminaStime = `BEGIN TRAN;DELETE FROM [11004] WHERE id_stima = ${elm.keyJftech};DELETE FROM [12011] WHERE id_danno = ${elm.keyGen};COMMIT;`;
            console.log(queryEliminaStime)
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaStime, '', retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,

                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        })
        //ELIMINA PREESISTENZE
        this.strutturaStatiEliminaDati.idPreesistenzaJftech.forEach(elm => {
            let queryEliminaPreesistenze = `DELETE FROM [11006] WHERE id_preesistenza = ${elm.idPrees};`;
            console.log(queryEliminaPreesistenze)
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaPreesistenze, '', retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        })
        //ELIMINA PREESISTENZE COSTRUZIONI
        this.strutturaStatiEliminaDati.idPreesistenzaCostruz.forEach(elm => {
            let queryEliminaPreesistenzeCostr = `DELETE FROM [11007] WHERE id_preesistenza_costruzione = ${elm.idCostruz};`;
            console.log(queryEliminaPreesistenzeCostr)
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaPreesistenzeCostr, '', retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        })
        //ELIMINA PREESISTENZE DESCRIZIONI
        this.strutturaStatiEliminaDati.idPreesistenzaDescr.forEach(elm => {
            let queryEliminaPreesistenzeDescr = `DELETE FROM [11008] WHERE id_preesistenza_descrizione = ${elm.idDescr};`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaPreesistenzeDescr, '', retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        })
        //ELIMINA PREESISTENZE GENERALI
        if (this.isCompagniaGenerali()) {
            this.strutturaStatiEliminaDati.idPreesistenzaGen.forEach(elm => {
                let queryEliminaPreesistenzeGen = `DELETE FROM [12007] WHERE id_preesistenza = ${elm.idGen};`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaPreesistenzeGen, '', retDataEliminato => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
            })
        }
        //ELIMINO STIME DESCRIZIONI
        this.strutturaStatiEliminaDati.idStimeDescrizioni.forEach(elm => {
            let queryEliminaStimaDescr = `DELETE FROM [11009] WHERE id_stima_descrizione = ${elm.keyJftech}; DELETE FROM [12013] WHERE id_dettaglio = ${elm.keyGen};`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaStimaDescr, '', retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        })
        if (this.isCompagniaGenerali()) {
            //ELIMINO DANNO DETTAGLI INFO RICHIESTE
            this.strutturaStatiEliminaDati.idDannoDettInfoRichGen.forEach(elm => {
                let queryEliminaDannDettInfoRich = `DELETE FROM [12016] WHERE id_info_richiesta = ${elm.idInfo};`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaDannDettInfoRich, '', retDataEliminato => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                },  true, true);
            })
        }
        //ELIMINA COASSICURAZIONI
        this.strutturaStatiEliminaDati.idCoassicurazioni.forEach(elm => {
            let queryEliminaCoass = `DELETE FROM [11010] WHERE id_compagnia_coass = ${elm.keyJftech}; DELETE FROM [12014] WHERE id_coassi_indiretta = ${elm.keyGen};`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaCoass, '', retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            },  true, true);
        })
        //ELIMINA INFO RICHIESTE
        this.strutturaStatiEliminaDati.idInfoRichieste.forEach(elm => {
            let queryEliminaInfoRich = `DELETE FROM [11011] WHERE id_info_richieste_compagnia = ${elm.keyJftech}; DELETE FROM [12015] WHERE id_info_richiesta = ${elm.keyGen};`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaInfoRich, '', retDataEliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        })
        if (this.isCompagniaGenerali()) {
            this.strutturaStatiEliminaDati.idDanneggiatiGen.forEach(elm => {
                let queryUpdateDanneggiatiGen = `BEGIN TRAN;UPDATE [12004] set importo_netto = null, importo_lordo = null WHERE id_danneggiato = '${elm.keyGen}';UPDATE [12012] set arrotondamento = 0, importo_liquidazione = 0 WHERE id_liquidazione = ${elm.keyGenLiquidazione};COMMIT;`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryUpdateDanneggiatiGen, '', retDataUpdate => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
            })
        }
        //UPDATE UNIPOL PERIZIA IMPORTI
        if (this.isCompagniaUnipol()) {
            let queryUpdatePeriziaUnipol = `UPDATE [10003] set importoPeriziato = null, importoConcordato = null, importoLiquidabile = null, presenzaAltreAssicurazioniStessoRischio = null where id_sinistro = ${this.props.IdSinistro};`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryUpdatePeriziaUnipol, '', retDataUpdate => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        }
        let queryUpdatePeriziaTot = `UPDATE [11001] set tot_indennizzo_lordo_compl = null, tot_indennizzo_netto_compl = null, tot_indennizzo_netto_compl_arrot = null, tot_danni_accertati = null, periziaNoCalcoli = 0 where id_sinistro = ${this.props.IdSinistro}`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryUpdatePeriziaTot, '', retDataUpdate => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
        //ELIMINO FRANCHIGE CONDIVISE
        this.strutturaStatiEliminaDati.idFranchige.forEach(idFranc => {
            let queryFrancCond = `DELETE FROM [11005] where idFranchigiaCondivisa = ${idFranc};`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryFrancCond, '', eliminato => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        })
        //ELIMINO DATI DAL RIEPILOGO
        //RIEPILOGO
        let queryRiep = `DELETE FROM [11012] where idRiepilogo_perizia = ${this.strutturaStatiEliminaDati.idRiepilogoPerizia};`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryRiep, '', eliminatoRiep => {
            //ELIMINO DANNEGGIATI RIEPILOGO
            this.strutturaStatiEliminaDati.idRiepilogoDanneggiati.forEach(idDann => {
                let queryDannRiep = `DELETE FROM [11013] where idDanneggiatoRiep = ${idDann};`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryDannRiep, '', eliminato => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
            });
            //ELIMINO RIGHE PARTITA GARANZIA PER OGNI DANNEGGIATO
            this.strutturaStatiEliminaDati.idRiepilogoRigheDanneggiato.forEach(idRiga => {
                let queryPertGarDann = `DELETE FROM [11014] where idGarPart = ${idRiga};`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryPertGarDann, '', eliminato => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                }, true, true);
            });
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
        //ELIMINA CAUSALI NON INDENNIZZO GENERALI
        if (this.isCompagniaGenerali()) {
            this.strutturaStatiEliminaDati.idCausali.forEach(elm => {
                let queryEliminaCaus = `DELETE FROM [12017] WHERE id_causale = ${elm.idCausale};`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaCaus, '', retDataEliminato => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
            });
        }
        this.distruggiDialog(true);
        setTimeout(() => {
            this.dialogElaboratoInstance.hide();
            this.toggleBlocking2(false);
            toast.success("Perizia ripristinata!", {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            const container = document.getElementById(`e-content-tabDettaglio${this.props.IdInternoSinistro}_1`);  
            const root = createRoot(container);
            //root.unmount();
            root.render(<CallbackWrapper callback={() => {
            }}><PaginaPeriziaOnline key={this.childKey} IdInternoSinistro={this.props.IdInternoSinistro} IdSinistro={this.props.IdSinistro} IdIncarico={this.props.IdIncarico} IdIncaricoUnipol={this.props.IdIncaricoUnipol} IdCompagnia={this.props.IdCompagnia} tipoSinistro={this.props.tipoSinistro} numeroPolizza={this.props.numeroPolizza}></PaginaPeriziaOnline>
            </CallbackWrapper>);
        }, tempoTotale)
    }
    //-------------------------------GESTIONE SALVATAGGIO FINE-------------------------------

    //-----------------------GESTIONE CAMBIO PAGINA INIZIO-----------------------

    distruggiDialog(soloProprie) {
        if (!soloProprie) {
            if (document.getElementById(`dialogPrezziario_${this.props.IdInternoSinistro}`))
                document.getElementById(`dialogPrezziario_${this.props.IdInternoSinistro}`).ej2_instances[0]?.destroy();
            if (document.getElementById(`dialogEliminaCalcoli_${this.props.IdInternoSinistro}`))
                document.getElementById(`dialogEliminaCalcoli_${this.props.IdInternoSinistro}`).ej2_instances[0]?.destroy();
        }
        if (document.getElementById(`dialogElaboratoPeritale_${this.props.IdInternoSinistro}`))
            document.getElementById(`dialogElaboratoPeritale_${this.props.IdInternoSinistro}`).ej2_instances[0]?.destroy();
    }

    paginaDettaglioSinistro() {
        const confermaConfUsc = () => {
            document.getElementById(`tabDettaglio${this.props.IdInternoSinistro}`).ej2_instances[0].select(0);
            document.getElementById(`elaboratoPeritale${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
            document.getElementById(`elaboratoCalcoli${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
            document.getElementById(`tabDettaglio${this.props.IdInternoSinistro}`).ej2_instances[0].enableTab(0, true);
            document.getElementById(`tabDettaglio${this.props.IdInternoSinistro}`).ej2_instances[0].removeTab(1);
            this.distruggiDialog(false);    
        }

        if ($(`#salvaEProcedi_${this.props.IdInternoSinistro}`).prop('disabled')) 
            confermaConfUsc();
        else {
            this.dialogElaboratoInstance.width = '30%';
            this.dialogElaboratoInstance.height = '24%';
            this.dialogElaboratoInstance.minHeight = '24%';
            this.dialogElaboratoInstance.buttons = [];
            this.dialogElaboratoInstance.beforeOpen = (args) => {
                const container = document.getElementById(`containerDialogElaboratoPeritale_${this.props.IdInternoSinistro}`);
                const root = createRoot(container);
                root.unmount();
            };
            this.dialogElaboratoInstance.open = (args) => {
                const container = document.getElementById(`containerDialogElaboratoPeritale_${this.props.IdInternoSinistro}`);
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                }}>
                    <div style={{ textAlign: 'center' }}>
                        <h4>{CustomJS.messaggioConfermaUscita}</h4>
                        <i className="fa fa-question-circle"></i>Scegli un opzione<br></br>
                        <div style={{ width: '70%', display: 'inline-flex' }}>
                            <button id='annullaBtn' className='pulsanti-sessione-custom' onClick={() => { this.dialogElaboratoInstance.hide(); }} >Annulla</button>
                            <button id='esciBtn' style={{ backgroundColor: '#b62f20' }} className='pulsanti-sessione-custom' onClick={() => { this.dialogElaboratoInstance.hide(); confermaConfUsc(); }}>Esci</button>
                        </div>
                    </div>
                </CallbackWrapper>);
            };
            this.dialogElaboratoInstance.show();
        }
    }

    paginaPeriziaOnlineCalcoli() {
        const confermaConfUsc = () => {
            ++this.childKey;
            document.getElementById(`tabelleCalcoloDaPerizia1_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
            const container = document.getElementById(`e-content-tabDettaglio${this.props.IdInternoSinistro}_1`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                this.distruggiDialog(true);
                document.getElementById(`tabDettaglio${this.props.IdInternoSinistro}`).ej2_instances[0].items[1].header.text = 'Perizia Online - Elaborato Calcoli';
                document.querySelectorAll(`#e-item-tabDettaglio${this.props.IdInternoSinistro}_1 div .e-tab-text`)[0].innerText = 'Perizia Online - Elaborato Calcoli'
            }}><PaginaPeriziaOnlineCalcoli key={this.childKey} IdInternoSinistro={this.props.IdInternoSinistro} IdIncarico={this.props.IdIncarico} IdIncaricoUnipol={this.props.IdIncaricoUnipol} IdSinistro={this.props.IdSinistro} IdCompagnia={this.props.IdCompagnia} numeroPolizza={this.props.numeroPolizza} tipoSinistro={this.props.tipoSinistro}></PaginaPeriziaOnlineCalcoli></CallbackWrapper>);
        }

        if ($(`#salvaEProcedi_${this.props.IdInternoSinistro}`).prop('disabled')) 
            confermaConfUsc();
        else {
            this.dialogElaboratoInstance.width = '30%';
            this.dialogElaboratoInstance.height = '24%';
            this.dialogElaboratoInstance.minHeight = '24%';
            this.dialogElaboratoInstance.buttons = [];
            this.dialogElaboratoInstance.beforeOpen = (args) => {
                const container = document.getElementById(`containerDialogElaboratoPeritale_${this.props.IdInternoSinistro}`);
                const root = createRoot(container);
                root.unmount();
            };
            this.dialogElaboratoInstance.open = (args) => {
                const container = document.getElementById(`containerDialogElaboratoPeritale_${this.props.IdInternoSinistro}`);
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                }}>
                    <div style={{ textAlign: 'center' }}>
                        <h4>{CustomJS.messaggioConfermaUscita}</h4>
                        <i className="fa fa-question-circle"></i>Scegli un opzione<br></br>
                        <div style={{ width: '70%', display: 'inline-flex' }}>
                            <button id='annullaBtn' className='pulsanti-sessione-custom' onClick={() => { this.dialogElaboratoInstance.hide(); }} >Annulla</button>
                            <button id='esciBtn' style={{ backgroundColor: '#b62f20' }} className='pulsanti-sessione-custom' onClick={() => { this.dialogElaboratoInstance.hide(); confermaConfUsc(); }}>Esci</button>
                        </div>
                    </div>
                </CallbackWrapper>);
            };
            this.dialogElaboratoInstance.show();
        }
    }
    //-----------------------GESTIONE CAMBIO PAGINA FINE-----------------------

    //CONTROLLI PER COMPAGNIA VALIDI SOLO PER I SINISTRI IMPORTATI!
    isCompagniaGenerali() {
        return this.props.IdCompagnia.toString() === CustomJS.codiceCompagniaGenerali && !CustomJS.isNullEmpty(this.props.IdIncarico);
    }
    isCompagniaUnipol() {
        return this.props.IdCompagnia.toString() === CustomJS.codiceCompagniaUnipolSAI && !CustomJS.isNullEmpty(this.props.IdIncaricoUnipol);
    }
}