/* eslint-disable default-case */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import BlockUi from '@availity/block-ui';
import { random, isUndefined, times } from 'underscore';
import _ from 'underscore/underscore-min';
import $ from 'jquery';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Filter, Sort, Reorder, Inject, Toolbar, ExcelExport, Edit, CommandColumn, Page} from '@syncfusion/ej2-react-treegrid';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import PaginaAgenzie from './PaginaAgenzie';
import 'react-toastify/dist/ReactToastify.css';

export default class PaginaCompagnie extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.toolbarOptions = ['Add', 'Delete', 'Cancel', 'Search', 'ExcelExport', { text: 'Aggiorna Tabella', prefixIcon: "e-aggiornatabella", id: "aggiornatabella" }];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Dialog'};
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                         { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" } }];
        this.pageSettings = { pageSize : 20000,  pageCount : 100 };
        this.childKey = 0;
        this.DdlObj = null;
        this.editTemplate = { create: () => { let elemDDL = document.createElement('input'); return elemDDL},
                               read: () => {return this.DdlObj.text},
                               destroy: () => {this.DdlObj.destroy()},
                               write: (args) => {this.DdlObj = new DropDownList({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:'Agenzia', popupHeight:'400px', floatLabelType: 'Auto', 
                                                 dataSource: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella00004')))?.map(elm => {return {VALUE: elm.id_agenzia.toString(), TEXT: elm.ragione_sociale}}), 
                                                 allowFiltering: true, filterType: 'Contains', value: null,
                                                 change: (args2) => {args.rowData.IdAge = parseInt(args2.value)} }); this.DdlObj.appendTo(args.element) }};
    }
    state = {
        blocking: false,
        dsCompDivisAge: [],
        showDialog: false
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }  
    checkDuplicateAgenzia(args) { 
        if (!CustomJS.isNullEmpty(_.find(this.treeGridInstance.getSelectedRecords()[0].childRecords, elm2 => { return elm2.IdAge.toString() === args.value } )))
            return false;
        else
            return true;
    }
    onActionBegin(args){
        if(args.requestType === 'beginEdit') {
           args.cancel = true;
           if (CustomJS.isNullEmpty(args.rowData.IdAge)) {
               this.treeGridInstance.enableToolbarItems(['TreeGridCompagnieDivisioniAgenzie_gridcontrol_add'], false);
               this.treeGridInstance.enableToolbarItems(['TreeGridCompagnieDivisioniAgenzie_gridcontrol_delete'], false);
               return;
           }
           this.dialogInstance.header = 'TABELLA AGENZIE';         
           this.dialogInstance.width = '90%';
           this.dialogInstance.height = '85%';
           this.dialogInstance.minHeight = '85%';
           this.dialogInstance.close = (args2) => {
               this.treeGridInstance.enableToolbarItems(['TreeGridCompagnieDivisioniAgenzie_gridcontrol_add'], false);
               this.treeGridInstance.enableToolbarItems(['TreeGridCompagnieDivisioniAgenzie_gridcontrol_delete'], false);
               setTimeout(() => {
                   this.caricaDati();
               }, 200);         
           };
           this.dialogInstance.open = (args2) => {
                ++this.childKey;
                const container = document.getElementById('defaultDialogContent');
                const root = createRoot(container);                         
                root.render(<CallbackWrapper callback={() => {
                    setTimeout(() => {                 
                        document.getElementById('GridAgenzie').ej2_instances[0].pageSettings.pageSize = 4;
                    }, 500);  
                }}><PaginaAgenzie key={this.childKey} DescrAgenzia={args.rowData.descrizione}></PaginaAgenzie></CallbackWrapper>); 
            }
            this.dialogInstance.show(); 
        }
        if(args.requestType === 'add') {           
            args.data.IdComp = this.treeGridInstance.getSelectedRecords()[0].IdComp;
            args.data.IdDiv = this.treeGridInstance.getSelectedRecords()[0].IdDiv;
        }
    }
    onActionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) { 
            args.dialog.width = '45%';
            args.dialog.header = args.requestType === 'add' ? `Inserimento nuova Agenzia per Divisione ${this.treeGridInstance.getSelectedRecords()[0].descrizione}` : `Dettaglio di ${args.rowData.descrizione}`;
        }
        if (args.type === 'actionComplete' && args.requestType === 'searching') {
            if (!CustomJS.isNullEmpty(args.searchString.trim()))
                this.treeGridInstance.expandAll();
            else
                this.treeGridInstance.collapseAll();             
        }
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '03002';
            const dataObj = {
                IdDivisione: CustomJS.isNullEmpty(args.data[0]) ? args.data.IdDiv : args.data[0].IdDiv,
                IdAgenzia: !CustomJS.isNullEmpty(args.rowData?.IdAge) ? args.rowData.IdAge : CustomJS.isNullEmpty(args.data[0]) ? args.data.IdAge : args.data[0].IdAge               
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabellaAgenziaDettaglio', CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        }, "SELECT [00004].id_agenzia, [00004].ragione_sociale, [00004].telefono1, [00004].email1, [00007].descrizione, [03002].id_divisione FROM ([00007] RIGHT JOIN [00004] ON [00007].id_area=[00004].id_area) INNER JOIN [03002] ON [00004].id_agenzia=[03002].id_agenzia ORDER BY [00004].ragione_sociale;");                       
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
            if(args.action === "add" || args.requestType === "delete")
               setTimeout(() => {
                   this.caricaDati();
               }, 200); 
        }
    }
    onDataBound(args) {
        this.treeGridInstance.collapseAll();
        setTimeout(() => {
            this.treeGridInstance.enableToolbarItems(['TreeGridCompagnieDivisioniAgenzie_gridcontrol_add'], false);
            this.treeGridInstance.enableToolbarItems(['TreeGridCompagnieDivisioniAgenzie_gridcontrol_delete'], false);
        });
    }
    onRowDataBound(args) {
        if (CustomJS.isNullEmpty(args.data.IdDiv) || CustomJS.isNullEmpty(args.data.IdAge)) {
            $($(args.row).find('button')[0]).hide(); 
            $($(args.row).find('button')[1]).hide(); 
        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.treeGridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.treeGridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.treeGridInstance.csvExport();
                break;
        }
        if (args.item.id === 'TreeGridCompagnieDivisioniAgenzie_gridcontrol_add') {
            if (this.treeGridInstance.getSelectedRecords().length === 0 || CustomJS.isNullEmpty(this.treeGridInstance.getSelectedRecords()[0].IdDiv)
                || !CustomJS.isNullEmpty(this.treeGridInstance.getSelectedRecords()[0].IdAge)) 
                args.cancel = true;
        }
        if (args.item.id === 'TreeGridCompagnieDivisioniAgenzie_gridcontrol_delete') {
            if (CustomJS.isNullEmpty(this.treeGridInstance.getSelectedRecords()[0].IdAge)) 
                args.cancel = true;
        }
        if (args.item.id === 'aggiornatabella') {
            this.caricaDati();
        }
    }
    onRowSelected(args) {
        if (!CustomJS.isNullEmpty(this.treeGridInstance.getSelectedRecords()[0].IdDiv) && CustomJS.isNullEmpty(this.treeGridInstance.getSelectedRecords()[0].IdAge))
            this.treeGridInstance.enableToolbarItems(['TreeGridCompagnieDivisioniAgenzie_gridcontrol_add'], true);
        else
            this.treeGridInstance.enableToolbarItems(['TreeGridCompagnieDivisioniAgenzie_gridcontrol_add'], false);
        if (!CustomJS.isNullEmpty(this.treeGridInstance.getSelectedRecords()[0].IdAge))
            this.treeGridInstance.enableToolbarItems(['TreeGridCompagnieDivisioniAgenzie_gridcontrol_delete'], true);
        else
            this.treeGridInstance.enableToolbarItems(['TreeGridCompagnieDivisioniAgenzie_gridcontrol_delete'], false);
    }

    render() {
        return(<React.Fragment>
            <BlockUi id='blockUIPagina' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="defaultDialog" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog} 
                        ref={dialog => this.dialogInstance = dialog} close={this.caricaDati.bind(this)} isModal={true} height='80%' width='90%' minHeight='80%'>   
                            <div id='defaultDialogContent'></div>                         
                </DialogComponent> 
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                        {/* <div className='row'></div> 
                                        <div className='row'></div>
                                        <div className='row'></div>   */}
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 col-md-8 offest-md-2">
                                                <TreeGridComponent id='TreeGridCompagnieDivisioniAgenzie' dataSource={this.state.dsCompDivisAge} treeColumnIndex={0} toolbar={this.toolbarOptions} 
                                                        toolbarClick={this.toolbarClick.bind(this)} ref={treeObj => this.treeGridInstance = treeObj } childMapping= 'sottolivello' dataBound={this.onDataBound.bind(this)}
                                                        rowDataBound={this.onRowDataBound.bind(this)} editSettings={this.editSettings} actionComplete={this.onActionComplete.bind(this)} allowPaging={false} allowExcelExport={true} 
                                                        pageSize={this.pageSettings} rowSelected={this.onRowSelected.bind(this)} actionBegin={this.onActionBegin.bind(this)}>
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='descrizione' headerText='Compagnia/Divisione/Agenzia' width='85%' editType='DropDownEdit' edit={this.editTemplate} validationRules={{required: true, checkDuplicate: [this.checkDuplicateAgenzia.bind(this), 'Agenzia già presente'] }}/>
                                                        <ColumnDirective field='comandi' headerText='' width='15%' commands={this.commands} textAlign='Right'/>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, Filter, CommandColumn, Sort, Edit]} />
                                                </TreeGridComponent>
                                            </div>
                                        </div>                        
                                    </div>                              
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount(){
        // document.getElementById('titoloPagina').innerText = 'Compagnie-Divisioni-Agenzie';
        this.caricaDati();
    }
    caricaDati(){
        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        const QueryExec = encodeURIComponent(`SELECT [03002].id, [00002].id_compagnia, [00002].descrizione AS DescrComp, [00003].id_divisione, [00003].descrizione AS DescrDiv, [00004].id_agenzia, [00004].ragione_sociale AS DescrAge FROM [03002] INNER JOIN [00003] ON [03002].id_divisione=[00003].id_divisione INNER JOIN [00004] ON [03002].id_agenzia=[00004].id_agenzia INNER JOIN [00002] ON [00003].id_compagnia=[00002].id_compagnia ORDER BY [00002].id_compagnia, [00003].id_divisione, [00004].id_agenzia`);        
        this.toggleBlocking();
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, token, 'GET', QueryExec, '', retData => {
            let dsTreeGrid = [], salvaComp = '', salvaDiv = '';
            retData.forEach(elm => { 
                if (CustomJS.isNullEmpty(salvaComp) || salvaComp !== elm.id_compagnia) {
                    dsTreeGrid.push({ id: elm.id, IdComp: elm.id_compagnia, IdDiv: '', descrizione: elm.DescrComp, sottolivello: [] });
                }
                if (CustomJS.isNullEmpty(salvaDiv) || salvaDiv !== elm.id_divisione) {
                    let sottoliv1 = _.find(dsTreeGrid, elm2 => { return elm2.IdComp === elm.id_compagnia && elm2.IdDiv === '' }).sottolivello;
                    sottoliv1.push({ id: elm.id, IdComp: elm.id_compagnia, IdDiv: elm.id_divisione, IdAge: '', descrizione: elm.DescrDiv, sottolivello: [] });
                }
                let sottoliv2 = _.find(_.find(dsTreeGrid, elm2 => { return elm2.IdComp === elm.id_compagnia && elm2.IdDiv === '' }).sottolivello, elm3 => { return elm3.IdComp === elm.id_compagnia && elm3.IdDiv === elm.id_divisione && CustomJS.isNullEmpty(elm3.IdAge) }).sottolivello;
                sottoliv2.push({ id: elm.id, IdComp: elm.id_compagnia, IdDiv: elm.id_divisione, IdAge: elm.id_agenzia, descrizione: elm.DescrAge });
                salvaComp = elm.id_compagnia;
                salvaDiv = elm.id_divisione;
            });
            this.setState({ dsCompDivisAge: dsTreeGrid });
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        }); 
    }
}