/* eslint-disable default-case */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { RichTextEditorComponent, Toolbar as RTEtoolbar, Inject as RTEInject, Image, Video, Link, HtmlEditor, Count, QuickToolbar, Table, FormatPainter, EmojiPicker} from '@syncfusion/ej2-react-richtexteditor';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';
import PaginaSituazioneAssistenza from './PaginaSituazioneAssistenza';
import { Editor } from '@tinymce/tinymce-react';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
import $ from 'jquery';
import ImmaginePDF from '../assets/Immagine2PDF.png';
import ImmagineDOC from '../assets/ImmagineDOC.png';
import ImmagineTXT from '../assets/ImmagineTXT1.png';
import ImmagineEXCEL from '../assets/ImmagineEXCEL.png';
import ImmagineZIP from '../assets/ImmagineZIP.png';
import ImmagineRAR from '../assets/ImmagineRAR.png';
import ImmagineXML from '../assets/ImmagineXML.png';
import ImmagineJSON from '../assets/ImmagineJSON.png';
import ImmagineLOG from '../assets/ImmagineLOG.png';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.74vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {
    let colore = props.column.field === 'priorita' ? props.priority.color : props.status.color;
    let renderDiv = <div><span style={{ fontSize: '0.85vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>;
    return (
        renderDiv
    );
}
export default class PaginaNuovaAssistenza extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.insertImageSettings = {
            allowedTypes: ['.jpg', '.png', '.jfif', '.jpeg', '.gif', '.webp', '.jfif', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.txt', '.log', '.xml', '.json', '.zip', '.rar'],
            saveFormat: 'Base64',
            width: '15%',
            height: '10%'
        };
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.toolbarOptions = [ 'ExcelExport', { text: 'Aggiorna Tabella', prefixIcon: "e-aggiornatabella", id: "aggiornatabella" }, 'Search'];
        this.filterSettings = { type: 'CheckBox'};  
        this.inlineModeSettings = {
            enable: false,
            onSelection: true
        };
        this.RTEfontFamily = {
            items: [
                { text: "Segoe UI", value: "Segoe UI", class: "e-segoe-ui", command: "Font", subCommand: "FontName" },
                { text: "Roboto", value: "Roboto", command: "Font", subCommand: "FontName" },
                { text: "Great vibes", value: "Great Vibes,cursive", command: "Font", subCommand: "FontName" },
                { text: "Noto Sans", value: "Noto Sans", command: "Font", subCommand: "FontName" },
                { text: "Impact", value: "Impact,Charcoal,sans-serif", class: "e-impact", command: "Font", subCommand: "FontName" },
                { text: "Tahoma", value: "Tahoma,Geneva,sans-serif", class: "e-tahoma", command: "Font", subCommand: "FontName" }
            ]
        };
        this.RTEtoolbarSettings = {
            items: ['FormatPainter', 'ClearFormat', 'Bold', 'Italic', 'Underline', 'StrikeThrough',
                    'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                    'LowerCase', 'UpperCase', '|',
                    'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                    'Outdent', 'Indent', '|',
                    'CreateTable', 'CreateLink', '|', 'Print',
                    'SourceCode', 'EmojiPicker', '|', 'Undo', 'Redo']
        };
        this.childGrid = {
            queryString: 'parent', 
            load() {
                const str = 'id';
                this.parentDetails.parentKeyFieldValue =
                    this.parentDetails.parentRowData[str];
            }, 
            columns: [
                { field: 'id', headerText: 'ID Attività', textAlign: 'Center', width: '10%', headerTemplate: headerTemplate, template: fieldTemplate },
                { field: 'name', headerText: 'Oggetto Attività', headerTemplate: headerTemplate, template: fieldTemplate, textAlign: 'Center' },
                { field: 'description', headerText: 'Descrizione Attività', headerTemplate: headerTemplate, template: fieldTemplate, textAlign: 'Center' },
                { field: 'stato', headerText: 'Stato', headerTemplate: headerTemplate, template: fieldTemplate, textAlign: 'Center', width: '10%' },
                { field: 'priorita', headerText: 'Priorità', headerTemplate: headerTemplate, template: fieldTemplate, textAlign: 'Center', width: '10%' },
            ]
        };
        this.childKey = 0;
    }
    state = {
        showDialog1: false
    }     
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    onRowDatabound(args) {  
        const filter = args.data.id;
        const childrecord = new DataManager(this.childGrid.dataSource)
                            .executeLocal(new Query().where("parent", "equal", filter, true));
        if(childrecord.length === 0) {
            args.row.querySelector('td').innerHTML=" ";
            args.row.querySelector('td').className = "e-customizedExpandcell";
        }
    }
    onToolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance1.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance1.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance1.csvExport();
                break;
        } 
        if (args.item.id === 'aggiornatabella') {
            document.getElementById('btnSituazioneRichieste').click();
        }
    }
    btnSituazioneRichieste(args) {
        this.dialogInstance1.header = `<div style="text-align: center;"><span class='titoloPagina'>Situazione Richieste Assistenza</span></div>`;;    
        this.dialogInstance1.width = '85%';
        this.dialogInstance1.height = '90%';
        this.dialogInstance1.minHeight = '90%';
        this.dialogInstance1.buttons = [];
        this.dialogInstance1.beforeOpen = (args) => {
            const container = document.getElementById('defaultDialogAssistenzaContent');
            const root = createRoot(container); 
            root.unmount();
        };
        this.dialogInstance1.open = (args) => {                
            const container = document.getElementById('defaultDialogAssistenzaContent');
            const root = createRoot(container);                         
            root.render(<CallbackWrapper callback={() => {
            }}><PaginaSituazioneAssistenza key={this.childKey} arrivaDa='NuovaRich'></PaginaSituazioneAssistenza></CallbackWrapper>);
        };  
        this.dialogInstance1.show();               
    }

    render() {
        return(<React.Fragment>
            <BlockUi id='blockUIPaginaAssistenza' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="defaultDialog13" showCloseIcon={true} target='body' closeOnEscape={true} animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1}
                    ref={dialogObj => this.dialogInstance1 = dialogObj} isModal={true} style={{ marginTop: '40px', marginLeft: '50px' }}>
                    <div id='defaultDialogAssistenzaContent'></div>
                </DialogComponent>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <span style={{ paddingTop: '5px', fontSize: '0.8vw', fontWeight: 'bold', color: '#696969', fontFamily: 'Muli, sans-serif'}}>NOTA BENE : prima di aprire una richiesta di assistenza, fare tutte le verifiche necessarie per essere certi della reale entità della segnalazione e quindi evitare l'apertura di richieste inutili oppure ridondanti. Assicurarsi di spiegare nel modo più dettagliato possibile la richiesta e di allegare tutta la documentazione necessaria. SOLO NEI CASI DI EMERGENZE BLOCCANTI, chiamare o contattare su WhatsApp il numero 327.7348995 (Giannotta) per richiedere supporto in tempo reale.</span>
                                            </div>
                                        </div>  
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <TextBoxComponent id='mittenteRichiesta' floatLabelType='Auto' placeholder='Mittente Richiesta (Inserire Nominativo e/o Indirizzo Email)' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                        </div>  
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <TextBoxComponent id='oggettoRichiesta' floatLabelType='Auto' placeholder='Oggetto Richiesta' cssClass='e-outline'></TextBoxComponent>
                                            </div>
                                        </div>  
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                &nbsp;&nbsp;&nbsp;
                                                <RadioButtonComponent id='prioritaAltissima' label="Priorità Altissima (Errore bloccante)" value='Altissima' name="priorita" />
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <RadioButtonComponent id='prioritaAlta' label="Priorità Alta" value='Alta' name="priorita" />
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <RadioButtonComponent id='prioritaNormale' label="Priorità Normale" value='Normale' name="priorita" checked={true} />
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <RadioButtonComponent id='prioritaBassa' label="Priorità Bassa" value='Bassa' name="priorita" />
                                            </div>
                                        </div> 
                                        <div className='row'></div>
                                        <div className='row'>
                                            <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9">
                                                &nbsp;&nbsp;&nbsp;
                                                <RadioButtonComponent id='errore' label="Errore" value='Errore' name="tipoRichiesta" checked={true} />
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <RadioButtonComponent id='modificaNonEvolutiva' label="Modifica Non Evolutiva" value='ModificaNoEvo' name="tipoRichiesta" />
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <RadioButtonComponent id='modificaEvolutiva' label="Modifica Evolutiva" value='ModificaEvo' name="tipoRichiesta" />
                                            </div>
                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3 text-end mr-5">
                                                <ButtonComponent id='btnSituazioneRichieste' alt='Vedi Situazione Richieste' onClick={this.btnSituazioneRichieste.bind(this)} cssClass='e-fill e-normal'>Situazione Richieste Assistenza</ButtonComponent>
                                            </div>
                                        </div> 
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                {/* <Editor
                                                    apiKey='fdqr7o23bepisr81nqtiwn96k33ohvnegkxhmiil06jcc1j1'
                                                    onInit={(evt, editor) => this.RTEInstance = editor}
                                                    initialValue="<p>This is the initial content of the editor.</p>"
                                                    init={{
                                                    height: 450,
                                                    menubar: true,
                                                    plugins: [
                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                    ],
                                                    toolbar: 'undo redo | blocks | ' +
                                                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent |' +
                                                        'removeformat | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                    }}
                                                />
                                                <button onClick={this.provaBottone.bind(this)}>Log editor content</button> */}
                                                <RichTextEditorComponent id='descrizioneRichiesta' ref={RTEobj => this.RTEInstance2 = RTEobj} showCharCount={true} placeholder='Descrizione Richiesta' enableHtmlEncode={false} 
                                                    insertImageSettings={this.insertImageSettings} maxLength={5000} inlineMode={this.inlineModeSettings} toolbarSettings={this.RTEtoolbarSettings} height='410px'>
                                                <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table, EmojiPicker, FormatPainter]}/>
                                                </RichTextEditorComponent>
                                                <span style={{ paddingTop: '5px', fontSize: '0.7vw', fontWeight: 'bold', color: '#696969', fontFamily: 'Muli, sans-serif'}}>Per allegare i documenti, trascinarli all'interno dello spazio testuale soprastante. I formati di allegato ammessi sono i seguenti : JPG, PNG, JFIF, JPEG, GIF, WEBP, PDF, DOC, DOCX, XLS, XLSX, TXT, LOG, XML, JSON, ZIP, RAR</span>
                                            </div>
                                        </div>
                                    </div>                              
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    provaBottone() {
        alert(this.RTEInstance.getContent());
    }
    componentDidMount(){
        $('#descrizioneRichiesta_toolbar_FontColor_Target').hide();
        $('#descrizioneRichiesta_toolbar_BackgroundColor_Target').hide();  
        this.interval = setInterval(() => {
            document.getElementById('descrizioneRichiesta')?.querySelectorAll('.e-rte-image.e-imginline').forEach((elm,idx) => {
                debugger;
                if (elm.getAttribute('src').indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') > -1
                    || elm.src.indexOf('application/msword') > -1) {
                    $(elm).each(function () {
                        var img = $(this);
                        img.attr("src2",img.attr("src"));
                        img.attr("src", ImmagineDOC);
                        img.css('width','80px');
                        img.css('height','80px');
                    });
                }     
                if (elm.getAttribute('src').indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') > -1
                    || elm.src.indexOf('application/msexcel') > -1) {
                    $(elm).each(function () {
                        var img = $(this);
                        img.attr("src2",img.attr("src"));
                        img.attr("src", ImmagineEXCEL);
                        img.css('width','80px');
                        img.css('height','80px');
                    });
                }   
                if (elm.getAttribute('src').indexOf('application/x-zip-compressed') > -1) {
                    $(elm).each(function () {
                        var img = $(this);
                        img.attr("src2",img.attr("src"));
                        img.attr("src", ImmagineZIP);
                        img.css('width','150px');
                        img.css('height','80px');
                    });
                }   
                if (elm.getAttribute('src').indexOf('application/x-compressed') > -1) {
                    $(elm).each(function () {
                        var img = $(this);
                        img.attr("src2",img.attr("src"));
                        img.attr("src", ImmagineRAR);
                        img.css('width','130px');
                        img.css('height','60px');
                    });
                }   
                if (elm.getAttribute('src').indexOf('application/pdf') > -1) {
                    $(elm).each(function () {
                        var img = $(this);
                        img.attr("src2",img.attr("src"));
                        img.attr("src", ImmaginePDF);
                        img.css('width','80px');
                        img.css('height','80px');
                    });
                } 
                if (elm.getAttribute('src').indexOf('text/plain') > -1) {
                    $(elm).each(function () {
                        var img = $(this);
                        img.attr("src2",img.attr("src"));
                        img.attr("src", ImmagineTXT);
                        img.css('width','80px');
                        img.css('height','80px');
                    });
                }  
                if (elm.getAttribute('src').indexOf('text/xml') > -1) {
                    $(elm).each(function () {
                        var img = $(this);
                        img.attr("src2",img.attr("src"));
                        img.attr("src", ImmagineXML);
                        img.css('width','80px');
                        img.css('height','80px');
                    });
                }  
                if (elm.getAttribute('src').indexOf('application/json') > -1) {
                    $(elm).each(function () {
                        var img = $(this);
                        img.attr("src2",img.attr("src"));
                        img.attr("src", ImmagineJSON);
                        img.css('width','120px');
                        img.css('height','80px');
                    });
                }  
                if (elm.getAttribute('src').indexOf('application/octet-stream') > -1) {
                    $(elm).each(function () {
                        var img = $(this);
                        img.attr("src2",img.attr("src"));
                        img.attr("src", ImmagineLOG);
                        img.css('width','80px');
                        img.css('height','80px');
                    });
                }  
            });
        }, 1000); 
    }
}