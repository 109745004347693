/* eslint-disable default-case */
import { Ajax, Browser } from '@syncfusion/ej2-base';
import $ from 'jquery';
import _ from 'underscore/underscore-min';
import axios from 'axios';
import * as Loader from "react-loader-spinner";
import pako from 'pako';
import { Buffer } from 'buffer';

let qs = require('qs');

export const APIJfishTechURLSvil = 'http://localhost:3010';
export const APIJfishTechURLTest = 'https://apicallertest2.digitalpraesidium.it';
export const APIJfishTechURLProd = 'https://apicaller2.digitalpraesidium.it';
export const APIJfishTechURLSvil2 = 'http://localhost:3005';
export const APIJfishTechURLTest2 = 'https://apicallertest.digitalpraesidium.it';
export const APIJfishTechURLProd2 = 'https://apicaller.digitalpraesidium.it';
export const APIVariousJfishTechURLSvil = 'https://localhost:5001';
export const APIVariousJfishTechURLTest = 'https://apicallertest3.digitalpraesidium.it';
export const APIVariousJfishTechURLProd = 'https://apicaller3.digitalpraesidium.it';
export const APIClickUpURLBase1 = 'https://api.clickup.com/api/v2/list/{0}/task?custom_task_ids=true&team_id={1}';
export const APIClickUpURLBase2 = 'https://api.clickup.com/api/v2/task/{0}/attachment?{1}';
export const APIClickUpURLBase3 = 'https://api.clickup.com/api/v2/list/{0}/task?{1}';
export const URLMonitorACTSvil = 'http://localhost:3001/';
export const URLMonitorACTTest = 'http://93.186.247.14:5010/';
export const URLMonitorACTProd = 'https://monitoract.jfish.it/';
export const APIUrlMapboxMaps = 'https://api.mapbox.com/geocoding/v5/mapbox.places/{0}.json?access_token={1}';
export const APIURLTokenUnipolSAICert = "https://mpgw-coll.unipol.it";
export const APIURLTokenUnipolSAIProd = "https://mpgw.unipol.it";
export const NomeCertificatoUnipolSAI = "07643030963";
export const APIURLDatiUnipolSAICert = "https://apigw-qa.unipol.it";
export const APIURLDatiUnipolSAIProd = "https://apigw.unipol.it";
export const PathURLDatiUnipolSAICert = "/unipolsai/coll-publ";
export const PathURLDatiUnipolSAIProd = "/unipolsai/prod-publ";
export const ClientIdCertificazioneUnipolSAI = "3faa8b3d-26ad-45b1-a193-19b96487513d";
export const ClientIdProduzioneUnipolSAI = "1f1e4bfc-3df7-48eb-8e59-91880a33dc19";
export const ClientSecretCertificazioneUnipolSAI = "tB3qW5pH3wL6yD2qE1hR3tC1vJ1jL1nG6jN8iV6sH2tC4fW2wL";
export const ClientSecretProduzioneUnipolSAI = "Q1eD5uP3bF4fU7oF8cN3xW1oL3eK2uQ8bH4tK2tP3tI2nP1rC2";
export const APIURLTokenGeneraliCert = "https://loginidm.generali.it";
export const APIURLTokenGeneraliProd = "https://loginidm.generali.it";
export const APIURLDatiGeneraliCert = "https://apigateway.generali.it";
export const APIURLDatiGeneraliProd = "https://apigateway.generali.it";
export const PathURLDatiGeneraliCert = "/ml/a-b2p-internet/ServiceReApi";
export const PathURLDatiGeneraliProd = "/ml/b2p-internet/ServiceReApi";
export const ClientIdTokenGenerali = "4ca6e915-cce2-4efb-8ee4-a2cc36377fd9";
export const ClientIdCertificazioneGENERALI = "1319e9d0-ae34-45ad-9e8b-475195c6f6f1";
export const ClientIdProduzioneGENERALI = "ff4e1d70677dbfba9c744f921e3aa5de";
export const ClientSecretCertificazioneGENERALI = "dCyFtZRyxopntSqRIwikqvHTk0I5aTHRRmg-T7VjLCWVAZHzKqQm8ANaHEttkHxF5pgPOtu8-AU31yGzGgIdww";
export const ClientSecretProduzioneGENERALI = "dCyFtZRyxopntSqRIwikqvHTk0I5aTHRRmg-T7VjLCWVAZHzKqQm8ANaHEttkHxF5pgPOtu8-AU31yGzGgIdww";
export const UtenteGENERALI = "PMASFR@GGL";
export const PasswordGENERALI = "Studio08";
export const tokenAPIMapboxPrivate = 'pk.eyJ1IjoiZGlnaXRhbHByYWVzaWRpdW0iLCJhIjoiY2txZ2VwZHJsMDBncTJvbHlndnIzcHc0ayJ9.jft2DoPO1laRqILoY5_QmQ';
export const tokenAPIMapboxPublic = 'pk.eyJ1IjoiZGlnaXRhbHByYWVzaWRpdW0iLCJhIjoiY2tndHQ3c2E0MDRjNjJxcnk4ZmV1YTlhciJ9.B3lqOAgkDn2-BitBCXRGhg';
export const tokenAuthAPIVarious = 'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTYzNjY0NDYzNiwiaWF0IjoxNjM2NjQ0NjM2fQ.uZHXED8JKuGWPJfjaiHzBjhlsHQhhWjeUaVqMyF5YIQ';
export const tokenAPIClickUP = 'pk_54104721_YS19F6WN4VGYIMTW6A4LOQEFKUAH3YY9';
export const clickUPListID = '900601479246';
export const clickUPTeamID = '90060241486';
export const CartellaTemporaneaACT = 'Temp';
export const CartellaTestTipiPolizzaACT = 'TipiPolizzaTest';
export const CartellaProdTipiPolizzaACT = 'TipiPolizza';
export const CartellaTestCGAPolizzeACT = 'ArchivioPolizzeTest';
export const CartellaProdCGAPolizzeACT = 'ArchivioPolizze';
export const CartellaTestDocumentazioneACT = 'DOCUMENTAZIONE_TEST';
export const CartellaProdDocumentazioneACT = 'DOCUMENTAZIONE';
export const codiceCompagniaUnipolSAI = '3';
export const codiceCompagniaGenerali = '5';
export const messaggioNoBackend = 'Attenzione!! Nessuna comunicazione attiva con il servizio accesso dati. Riprovare nuovamente l\'operazione e se il problema persiste, contattare il servizio di supporto Digital Praesidium.'
export const messaggioAPIBackendNonTrovata = 'Attenzione!! Comunicazione attiva con il servizio accesso dati ma API non trovata oppure non autorizzata. Contattare il servizio di supporto Digital Praesidium.';
export const messaggioUtenteNonAutorizzato = 'Attenzione!! Utente non autorizzato alle operazioni di Jellyfish Technology. Prima di effettuare qualsiasi operazione è necessario accedere al sistema!!';
export const messaggioSessioneScaduta = 'Attenzione!! La sessione di collegamento al backend è scaduta. Accedere nuovamente al sistema per continuare le operazioni!!';
export const messaggioSessioneScaduta2 = 'Attenzione!! La sessione di collegamento al sistema è scaduta (durata massima prevista : 4 ore di inattività completa). Accedere nuovamente al sistema per continuare le operazioni!!';
export const messaggioConfermaUscita = 'Tutti i dati non salvati andranno persi. Vuoi continuare?';
export const messaggioConfermaFirmaCliente = 'Confermi di voler acquisire la firma di fine lavori del cliente?';
export const messaggioErroreObbligo = 'Non è possibile proseguire con il salvataggio in quanto alcuni dei campi obbligatori non sono stati compilati.';
export const messaggioIncCreatoJFCAT = 'Incarico al CAT creato automaticamente con successo all\'interno dell\'applicazione Jellyfish CAT Mobile!!';
export const messaggioIncCreatoJFCATKO = 'Problemi durante la creazione dell\'Incarico al CAT all\'interno dell\'applicazione Jellyfish CAT Mobile!!';
export const messaggioInvioErratoJFCAT = 'La creazione automatica dell\'incarico in <b>Jellyfish CAT Mobile</b> NON è andata a buon fine. Il motivo potrebbe essere che le credenziali associate al CAT (Tabella CAT, campi <b>"Utente JFCAT"</b> e <b>"Password JFCAT"</b>) non sono corrette oppure che l\'incarico è già stato inviato allo stesso CAT in precedenza e non si trova negli stati <b>REVOCATO</b> oppure <b>ARCHIVIATO</b>. Controllare il motivo dell\'anomalia ed eventualmente riprovare...';
export const messaggioRevocaErrataJFCAT = 'La revoca automatica dell\'incarico in Jellyfish CAT Mobile NON è andata a buon fine. Il motivo potrebbe essere che le credenziali associate al CAT (Tabella CAT, campi "Utente JFCAT" e "Password JFCAT") non sono corrette oppure che l\'incarico non è presente tra quelli assegnati al CAT. Controllare il motivo dell\'anomalia ed eventualmente riprovare...';
export const messaggioRevocaJFCAT = 'Revoca automatica dell\'incarico al CAT effettuata con successo all\'interno dell\'applicazione Jellyfish CAT Mobile!!';
export const messaggioRevocaJFCATKO = 'Problemi durante la revoca automatica dell\'incarico al CAT all\'interno dell\'applicazione Jellyfish CAT Mobile!!';
export const messaggioImportaSelezionatiOK = 'Le operazioni di importazione e relativo invio delle comunicazioni ad UnipolSAI, sono state effettuate con successo per i seguenti incarichi :';
export const messaggioImportaSelezionatiOK2 = 'Le operazioni di importazione sono state effettuate con successo per i seguenti incarichi :';
export const messaggioImportaSelezionatiKO = 'Attenzione!! I seguenti incarichi hanno riscontrato problemi durante le operazioni di importazione :';
export const messaggioFotoGEOUnipolSAI = 'Caricamento delle foto geolocalizzate per UnipolSAI, concluso con successo. Si consiglia di controllare il contenuto della cartella "Da Chiudere", in quanto le foto che erano prive di geolocaizzazione, NON sono state caricate!!';
export const messaggioFotoRischioGENERALI = 'Caricamento della foto del rischio all\'interno dell\'incarico, concluso con successo!!';
export const timeout = 1000 * 60 * 240; //4 ore di inattività
export const widthScreenResolution = window.screen.width * window.devicePixelRatio;
export const heightScreenResolution = window.screen.height * window.devicePixelRatio;
export const chartWidth = Browser.isDevice ? '92%' : '96%'; //widthScreenResolution > 1900 ?  Browser.userAgent.indexOf('Firefox') > -1 ? '85%' :  '80%' : Browser.userAgent.indexOf('Firefox') > -1 ? '105%' :  '110%'; 
export const chartWidth2 = Browser.isDevice ? '92%' : '96%'; //widthScreenResolution > 1900 ?  Browser.userAgent.indexOf('Firefox') > -1 ? '115%' :  '120%' : Browser.userAgent.indexOf('Firefox') > -1 ? '95%' :  '100%';
export const chartWidth3 = Browser.isDevice ? '92%' : '96%'; //widthScreenResolution > 1900 ?  Browser.userAgent.indexOf('Firefox') > -1 ? '129%' :  '136%' : Browser.userAgent.indexOf('Firefox') > -1 ? '109%' :  '116%';
let chartHeightTemp = Browser.userAgent.indexOf('Firefox') > -1 ? '580' : Browser.userAgent.indexOf('Edg') > -1 ? '585' : Browser.userAgent.indexOf('OPR') > -1 ? '590' : '590';
let chartHeightTemp2 = Browser.userAgent.indexOf('Firefox') > -1 ? '755' : Browser.userAgent.indexOf('Edg') > -1 ? '760' : Browser.userAgent.indexOf('OPR') > -1 ? '760' : '760';
export const chartHeight = (parseInt(chartHeightTemp) - ((1080 - heightScreenResolution) === 0 ? 0 : (1080 - heightScreenResolution) < 0 ? (1080 - heightScreenResolution) : (1080 - heightScreenResolution + 50))).toString();
export const chartHeight2 = (parseInt(chartHeightTemp2) - ((1080 - heightScreenResolution) === 0 ? 0 : (1080 - heightScreenResolution) < 0 ? (1080 - heightScreenResolution) : (1080 - heightScreenResolution + 50))).toString();
export const colorsData = [
    { Color: 'AliceBlue', Code: '#F0F8FF' },
    { Color: 'Aqua', Code: '#00FFFF' },
    { Color: 'Aquamarine', Code: '#7FFFD4' },
    { Color: 'Beige', Code: '#F5F5DC' },
    { Color: 'BurlyWood', Code: '#DEB887' },
    { Color: 'Chartreuse', Code: '#7FFF00' },
    { Color: 'Coral', Code: '#FF7F50' },
    { Color: 'Cornsilk', Code: '#FFF8DC' },
    { Color: 'LightSalmon', Code: '#FFA07A' },
    { Color: 'DarkTurquoise', Code: '#00CED1' },
    { Color: 'DodgerBlue', Code: '#1E90FF' },
    { Color: 'Gainsboro', Code: '#DCDCDC' },
    { Color: 'Gold', Code: '#FFD700' },
    { Color: 'GreenYellow', Code: '#ADFF2F' },
    { Color: 'Khaki', Code: '#F0E68C' },
    { Color: 'Lavender', Code: '#E6E6FA' },
    { Color: 'LawnGreen', Code: '#7CFC00' },
    { Color: 'LightBlue', Code: '#ADD8E6' },
    { Color: 'MediumSpringGreen', Code: '#00FA9A' },
    { Color: 'MistyRose', Code: '#FFE4E1' },
    { Color: 'Plum', Code: '#DDA0DD' },
    { Color: 'SandyBrown', Code: '#F4A460' },
    { Color: 'Thistle', Code: '#D8BFD8' },
    { Color: 'Turquoise', Code: '#40E0D0' },
    { Color: 'Yellow', Code: '#FFFF00' },
    { Color: 'Tomato', Code: '#FF745C' },
    { Color: 'Violet', Code: '#EE82EE' },
];
export const loaderSpinners = [<Loader.Audio width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.BallTriangle width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Bars width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Blocks width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Circles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.CirclesWithBar width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.ColorRing width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Comment width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.Discuss width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, //<Loader.Dna width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.FallingLines width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.FidgetSpinner width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Grid width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.InfinitySpin width={250} height={250} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.LineWave width={250} height={250} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.MagnifyingGlass width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.MutatingDots width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Oval width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.ProgressBar width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Puff width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Radio width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RevolvingDot width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Rings width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RotatingLines width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.RotatingSquare width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RotatingTriangles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.TailSpin width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.ThreeCircles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
                               <Loader.ThreeDots width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Triangle width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
                               <Loader.Vortex width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}/>, <Loader.Watch width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>];

export async function getRemoteData(url, data, type, header) { //Chiamata con oggetti Syncfusion - Non funziona...dà errore a livello di CORS
    let ajax = new Ajax({
        url: url,
        type: type,
        mode: false,
        data: data,
        setRequestHeader: header,
        onFailure: (e) => { return false; }
    });

    const response = await ajax.send();
    let retData = JSON.parse(response);
    return { response: retData['response'], retData: retData['data'] };
}

export async function getRemoteData2(url, paramData, type, header, cbFunction, async, test) {  //Chiamata Javascript puro XHR - Funziona bene
    let xhr = new XMLHttpRequest();
    xhr.withCredentials = false;

    xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
            if (test) {
                cbFunction(this.responseText);
            }
            else {
                let retData = JSON.parse(this.responseText);
                console.log(this.responseText);
                if (retData['response'].cod === 0)
                    cbFunction(retData['data']);
                else
                    console.log(retData['response'].message);
            }
        }
    });
    debugger;
    xhr.open(type, url);
    xhr.setRequestHeader("Content-Type", header);
    xhr.async = async;

    if (!isNullEmpty(paramData))
        xhr.send(paramData);
    else
        xhr.send();
}

export async function getRemoteData3(url, paramData, type, header, cbFunction, async, test) {  //Chiamata Jquery - Funziona bene
    let settings = {
        "url": url,
        "method": type,
        "timeout": 0,
        "headers": header,
        "data": paramData,
        "async": async
    };

    $.ajax(settings).done((response) => {
        if (test) {
            cbFunction(response);
        }
        else {
            let retData = JSON.parse(response);
            console.log(response);
            if (retData['response'].cod === 0)
                cbFunction(retData['data']);
            else
                console.log(retData['response'].message);
        }
    }).fail((response) => {
        console.log(response);
    });
}

export async function getRemoteData4(url, paramData, type, header, cbFunctionOK, cbFunctionKO, async, test) {  //Chiamata Axios - Funziona bene (speriamo)
    let data = qs.stringify(paramData);
    let config = {
        method: type,
        url: url,
        headers: header,
        data: data
    };

    axios(config)
        .then(response => {
            debugger;
            if (test) {
                cbFunctionOK(response.data);
            }
            else {
                let retData = response.data;
                console.log(JSON.stringify(response.data));
                cbFunctionOK(retData);
            }
        })
        .catch(error => {
            console.log(error);
            cbFunctionKO(error);
        });
}

export async function getRemoteData5(url, paramData, type, header, cbFunctionOK, cbFunctionKO, async, test) {  //Chiamata Axios - Funziona bene (speriamo)
    let config = {
        method: type,
        url: url,
        headers: header,
        data: paramData,
        maxContentLength: Infinity, // Nessun limite per la risposta
        maxBodyLength: Infinity, // Nessun limite per la richiesta
    };

    axios(config)
        .then(response => {
            debugger;
            if (test) {
                cbFunctionOK(response.data);
            }
            else {
                let retData = response.data;
                console.log(JSON.stringify(response.data));
                cbFunctionOK(retData);
            }
        })
        .catch(error => {
            console.log(error);
            cbFunctionKO(error);
        });
}

export async function callAPI(url, paramData, type, header, headerAuth, cbFunctionOK, cbFunctionKO, async, test) {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;

    xhr.onerror = function (err) {
        debugger;
        cbFunctionKO(err);
    };

    xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4 && this.status === 200) {
            if (test) {
                cbFunctionOK(this.responseText);
            }
            else {
                debugger;
                let retData = isNullEmpty(this.responseText) ? [] : JSON.parse(this.responseText);
                cbFunctionOK(retData);
            }
        }
        else if (this.readyState === 4 && this.status === 404)
            cbFunctionKO(this.responseText + this.statusText);
    });

    xhr.open(type, url);
    if (!(paramData instanceof FormData))
        xhr.setRequestHeader("Content-Type", header);
    xhr.setRequestHeader("Authorization", headerAuth);
    xhr.async = async;

    if (paramData instanceof FormData || (!(paramData instanceof FormData) && !isNullEmpty(paramData)))
        xhr.send(paramData);
    else
       xhr.send();
}

export async function caricaCombo(URL, token, CodTab, cbFunctionOK, cbFunctionKO, QueryExec) {
    QueryExec = isNullEmpty(QueryExec) ? 'NoQuery' : encodeURIComponent(QueryExec);
    callAPI(`${URL}/api/JellyfishTechnology/caricaCombo/${CodTab}?QueryExec=${QueryExec}`, '', 'GET',
        '', token, retData => {
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? messaggioSessioneScaduta : retData.msg.message;
                cbFunctionKO(messaggio);
                return;
            }
            cbFunctionOK(retData);
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
            cbFunctionKO(messaggio);
        }, true, false)
}

export async function eseguiQuery(URL, token, tipo, QueryExec, paramData, cbFunctionOK, cbFunctionKO, encodeURL, comprimi) {
    let header = tipo === 'GET' ? '' : 'application/json';
    // QueryExec = isNullEmpty(comprimi) || comprimi === false ? QueryExec : pako.deflate(QueryExec, { to: 'string' }); 
    QueryExec = isNullEmpty(comprimi) || comprimi === false ? QueryExec : pako.deflate(QueryExec); 
    QueryExec = isNullEmpty(comprimi) || comprimi === false ? QueryExec :  Buffer.from(QueryExec).toString('base64');   
    QueryExec = isNullEmpty(encodeURL) || encodeURL === true ? encodeURIComponent(QueryExec) : QueryExec;

    callAPI(`${URL}/api/JellyfishTechnology/eseguiQuery?QueryExec=${QueryExec}`, paramData, tipo,
        header, token, retData => {
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? messaggioSessioneScaduta : retData.msg.message;
                cbFunctionKO(messaggio);
                return;
            }
            cbFunctionOK(retData);
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
            cbFunctionKO(messaggio);
        }, true, false)
}

export async function getParametroDB(ValPar) {
    const QueryExec = encodeURIComponent(`SELECT [09005].valore FROM [09005] WHERE [09005].id_parametro = ${ValPar}`);
    return new Promise((resolve, reject) => {
        eseguiQuery(APIJfishTechURLProd, JSON.parse(decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            if (retData.length > 0)
                resolve(retData[0].valore);
            else
                resolve(false);
        }, messaggio => {
            reject(messaggio);
        });
    });
}

export async function setParametroDB(ValPar, Valore, Memo) {
    const dataObj = {
        IdParam: ValPar,
        ValoreParam: Valore
    };
    const QueryExec = Memo === false ? encodeURIComponent(`UPDATE [09005] SET [09005].valore = @valore_param WHERE [09005].id_parametro = @id_param`) : encodeURIComponent(`UPDATE [09005] SET [09005].valore_memo = @valore WHERE [09005].id_parametro = @id_param`);
    return new Promise((resolve, reject) => {
        eseguiQuery(APIJfishTechURLProd, JSON.parse(decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
            resolve(retData.response);
        }, messaggio => {
            reject(messaggio);
        });
    });
}

export function handleMouseMove(event) {
    var eventDoc, doc, body;

    event = event || window.event; // IE-ism

    // If pageX/Y aren't available and clientX/Y are,
    // calculate pageX/Y - logic taken from jQuery.
    // (This is to support old IE)
    if (event.pageX == null && event.clientX != null) {
        eventDoc = (event.target && event.target.ownerDocument) || document;
        doc = eventDoc.documentElement;
        body = eventDoc.body;

        event.pageX = event.clientX +
            (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
            (doc && doc.clientLeft || body && body.clientLeft || 0);
        event.pageY = event.clientY +
            (doc && doc.scrollTop || body && body.scrollTop || 0) -
            (doc && doc.clientTop || body && body.clientTop || 0);
    }

    //console.log ('X: ' + event.pageX + ' - Y:' + event.pageY);

    //if (document.getElementById('TabMonitor').ej2_instances[0].selected<Index === 0 && event.pageX >= 680 && event.pageX <= 735 && event.pageY >= 620 && event.pageY <= 635) 
}

export function leapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}

export function isNullEmpty(valore, multiArray) {
    try {
        if (multiArray) {
            var _wRet = false;
            for (var i = 0; i < valore.length; i++) {
                if (isNullEmpty(valore[i])) {
                    _wRet = true;
                    break;
                }
            }
            return _wRet;
        } else {
            switch (true) {
                case valore === null:
                    return true;
                    break;
                case Object.prototype.toString.call(valore) === '[object Date]':
                    return false;
                    break;
                case typeof valore === 'undefined':
                case typeof valore === 'null':
                    return true
                    break;
                case typeof valore === 'string':
                    if (valore.toString().trim() == "null" || valore.toString().trim() == "undefined") {
                        return true;
                    } else if (valore.toString().trim().length > 0) {
                        return false;
                    } else {
                        return true;
                    }
                    break;
                case typeof valore === 'boolean':
                    return false;
                    break;
                case typeof valore === 'number':
                    return false;
                    break;
                case typeof valore === 'object':
                    if (Object.keys(valore).length > 0) {
                        return false;
                    } else {
                        return true;
                    }
                    break;
            }
        }
    }
    catch (ex) {
        return true;
    }
}

export function setInterval_(fn, delay) {
    var maxDelay = Math.pow(2, 31) - 1;

    if (delay > maxDelay) {
        var args = arguments;
        args[1] -= maxDelay;

        return setInterval(function () {
            setInterval_.apply(undefined, args);
        }, maxDelay);
    }

    return setInterval.apply(undefined, arguments);
}

//http://byronsalau.com/blog/how-to-create-a-guid-uuid-in-javascript/  
export function createGuid4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function makeID(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function checkInvalidChars(txtValue, pattern) {
    if (_.isUndefined(pattern))
        pattern = /^[0-9a-zA-Z]+$/;
    return (txtValue.value?.trim().match(pattern))
}

export function removeStringFromString(string, from, to) {
    return [...string].filter((_, i) => i < from || i > to).join('');
}

export function scrollAuto(speed, timeout, goBack, soloTop) {
    if (!soloTop) {
        $('html, body').animate({ scrollTop: $(document).height() - $(window).height() }, speed, function () {
            if (goBack)
                setTimeout(() => { $(this).animate({ scrollTop: 0 }, speed); }, timeout);
        });
    }
    else
        $('html, body').animate({ scrollTop: 0 }, speed);
}

export function validateEmails(emails) {
    let expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(\]?)$/;
    let emailsArr = emails?.indexOf(';') > -1 ? emails?.split(";") : [emails];
    if (emailsArr.length === 0 || isNullEmpty(emails))
        return true;
    let validEmail = true;
    emailsArr.forEach(email => {
        if (isNullEmpty(email.trim()))
            return;
        if (expr.test(email.trim()) === false)
            validEmail = false;
    });

    return validEmail;
}

export function validatePhoneNumber(numero) {
  // Utilizziamo una regex per controllare se il numero è valido
  // Deve iniziare con "+" seguito dal prefisso internazionale
  // quindi dai numeri del numero di cellulare (almeno 6 cifre).
  // La regex può essere personalizzata per adattarsi alle esigenze specifiche.
  const regex = /^\+\d{1,}$/;
  const regex2 = /^(\+\d{1,})?(;(\+\d{1,}))*$/;

  return regex2.test(numero);
}

export function controllaCodiceFiscale(testo) {
    var pattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;

    if (testo.search(pattern) === -1)
        return false;
    else
        return true;
}

export function controllaPIVA(testo) {
    if (testo === '') {
        return true;
    }
    if (! /^[0-9]{11}$/.test(testo)) {
        return false;
    }
    var s = 0;
    for (i = 0; i <= 9; i += 2) {
        s += testo.charCodeAt(i) - '0'.charCodeAt(0);
    }
    for (var i = 1; i <= 9; i += 2) {
        var c = 2 * (testo.charCodeAt(i) - '0'.charCodeAt(0));
        if (c > 9) c = c - 9;
        s += c;
    }
    var atteso = (10 - s % 10) % 10;
    if (atteso !== testo.charCodeAt(10) - '0'.charCodeAt(0)) {
        return false;
    }
    return true;
}

export const validIban = (value) => {
    let rearrange =
        value.substring(4, value.length)
        + value.substring(0, 4);
    let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');
    let alphaMap = {};
    let number = [];

    alphabet.forEach((value, index) => {
        alphaMap[value] = index + 10;
    });

    rearrange.split('').forEach((value, index) => {
        number[index] = alphaMap[value] || value;
    });

    return modulo(number.join('').toString(), 97) === 1;
}

export function checkColumnConditions(data, param1, param2, tipo, servizio) {
    const colonna1 = servizio === 'MCAST' ? 'column1' : 'distanza';
    const colonna2 = servizio === 'MCAST' ? 'column2' : 'tipo';
    const filteredData = tipo === "Entro3KM"
        ? data.filter(item => parseFloat(item[colonna1]) <= 3) 
        : data.filter(item => parseFloat(item[colonna1]) > 3); 

    const allMatchParam1 = filteredData.length === 0 ? false : filteredData.every(item => item[colonna2] === param1);
    if (allMatchParam1) {
        return 'CLOUD'; 
    }

    const someMatchParam2 = filteredData.some(item => item[colonna2] === param2);
    if (someMatchParam2) {
        return 'GROUND'; 
    }

    return '';
}

const modulo = (aNumStr, aDiv) => {
    var tmp = "";
    var i, r;
    for (i = 0; i < aNumStr.length; i++) {
        tmp += aNumStr.charAt(i);
        r = tmp % aDiv;
        tmp = r.toString();
    }
    return tmp / 1;
}

export function decypher(importoChar, chiaveUtentePar) {
    if (isNullEmpty(importoChar))
        return;
    const chiaveUtente = isNullEmpty(chiaveUtentePar) ? JSON.parse(decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].aux1 : chiaveUtentePar;
    let strDecyp = '';
    for (let ind = 1; ind <= importoChar.length / 4; ind++) {
        const risMoltipl = importoChar.substr((ind * 4 - 3) - 1, 4);
        if ((risMoltipl.padStart(5, ' ')).length !== 5) {

        }
        else {
            if (String.fromCharCode(parseInt(risMoltipl) / chiaveUtente.substr(ind - 1, 1).charCodeAt(0)) === '.') {
                strDecyp += ',';
            }
            else {
                strDecyp += String.fromCharCode(parseInt(risMoltipl) / chiaveUtente.substr(ind - 1, 1).charCodeAt(0));
            }
        }
    }
    return strDecyp;
}

export function cypher(importoChar) {
    debugger;
    const chiaveUtente = JSON.parse(decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].aux1;
    let strcryp = '';
    for (let ind = 1; ind <= importoChar.length; ind++) {
        const risMoltipl = (importoChar.substr(ind - 1, 1).charCodeAt(0) * chiaveUtente.substr(ind - 1, 1).charCodeAt(0)).toString();
        if ((risMoltipl.padStart(5, ' ')).length !== 5) {

        }
        else {
            debugger;
            strcryp += risMoltipl.substr(0, 2) + risMoltipl.slice(-2);
        }
    }
    return strcryp;
}

export function wordWrap(str, maxWidth) {
    let newLineStr = "\n", done = false, res = '';
    while (str.length > maxWidth) {                 
        let found = false;
        // Inserts new line at first whitespace of the line
        for (let i = maxWidth - 1; i >= 0; i--) {
            if (testWhite(str.charAt(i))) {
                res = res + [str.slice(0, i), newLineStr].join('');
                str = str.slice(i + 1);
                found = true;
                break;
            }
        }
        // Inserts new line at maxWidth position, the word is too long to wrap
        if (!found) {
            res += [str.slice(0, maxWidth), newLineStr].join('');
            str = str.slice(maxWidth);
        }

    }

    return res + str;
}

function testWhite(x) {
    var white = new RegExp(/^\s$/);
    return white.test(x.charAt(0));
};

export function getStorageUsagePercentage() {
    var totalSpace = 10 * 1024 * 1024; // Assume 5MB limit for sessionStorage
    var usedSpace = 0;

    for (var key in sessionStorage) {
        if (sessionStorage.hasOwnProperty(key)) {
            usedSpace += sessionStorage[key].length * 2; // Approximate size in bytes (UTF-16)
            //console.log(`Spazio session storage utilizzato dalla chiave ${key} : ${usedSpace}`)
        }
    }

    var percentage = (usedSpace / totalSpace) * 100;
    return percentage.toFixed(2); // Limit to two decimal places
}

export function decodeAndUncompress(base64String) {
    if (isBase64Encoded(base64String) === true) 
        return pako.inflate(Buffer.from(base64String, 'base64'), { to: 'string' });
    else
        return base64String;
}

export function compressString(inputString) {
    const utf8Array = new TextEncoder().encode(inputString);
    const compressedData = pako.deflate(utf8Array);
    const base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(compressedData)));
    return base64String;
}

export function compressAndEncode(obj) {
    return Buffer.from(pako.deflate(JSON.stringify(obj))).toString('base64')
}

export function onChangeCampoTestoMaiuscolo(args) {
    args.target.value = isNullEmpty(args.target.value) ? '' : args.target.value?.toUpperCase();
}
 
function isBase64Encoded(str) {
    try {
      return Buffer.from(str, 'base64').toString('base64') === str;
    } catch (error) {
      return false;
    }
}

export function b64toBlob(base64Data) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "application/octet-stream" });
}

export function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export async function processImages(imageBlobs) {
    const cidReferences = [];
    for (const [index, { elm, blob }] of imageBlobs.entries()) {
        const cid = `image${index}@example.com`; // Genera un CID unico
        elm.setAttribute('src', `cid:${cid}`); // Aggiorna il riferimento nel DOM
        cidReferences.push({ cid, content: await blobToBuffer(blob) });
    }
    return cidReferences;
}

async function blobToBuffer(blob) {
    const arrayBuffer = await blob.arrayBuffer();
    return Buffer.from(arrayBuffer);
}

export function getImageBase64(url) {
    return new Promise((resolve, reject) => {
        // Creazione di un oggetto XMLHttpRequest
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "arraybuffer";

        // Gestione della risposta
        xhr.onload = () => {
            if (xhr.status === 200) {
                // Conversione dell'arraybuffer in base64
                const buffer = new Uint8Array(xhr.response);
                const binary = buffer.reduce((data, byte) => data + String.fromCharCode(byte), "");
                const base64 = btoa(binary);
                const mimeType = xhr.getResponseHeader("Content-Type");
                resolve(`data:${mimeType};base64,${base64}`);
            } else {
                reject(new Error(`Errore durante il caricamento dell'immagine: ${xhr.status}`));
            }
        };

        // Gestione degli errori
        xhr.onerror = () => {
            reject(new Error("Errore nella richiesta"));
        };

        // Invio della richiesta
        xhr.send();
    });
}

export function getImageBase64AC3(fileURL, base64Value) {
    return new Promise((resolve, reject) => {
        getRemoteData5(`${APIVariousJfishTechURLProd}/api/WordCreator/CreaBase64daURL`, { fileURL: fileURL, base64Value: base64Value }, 'POST', 
        { "Authorization" : tokenAuthAPIVarious, "Content-Type": "application/json" }, retData => {
            resolve(retData);
        }, error => {
            let messaggio = _.isString(error) ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
            reject(messaggio);
        }, true, false);            
    });
}

export function getImageBase64AC3Optimized(fileURL, base64Value) {
    return new Promise((resolve, reject) => {
        getRemoteData5(`${APIVariousJfishTechURLProd}/api/WordCreator/CreaBase64DaUrlOptimized`, { fileURL: fileURL, base64Value: base64Value, maxImageSizeBytes: 500*1024 }, 'POST', 
        { "Authorization" : tokenAuthAPIVarious, "Content-Type": "application/json" }, retData => {
            resolve(retData);
        }, error => {
            let messaggio = _.isString(error) ? messaggioAPIBackendNonTrovata : messaggioNoBackend;
            reject(messaggio);
        }, true, false);            
    });
}

export function getBase64Prefix(base64String) {
    if (base64String.startsWith("/9j/"))
      return "data:image/jpeg;base64,";
    else if (base64String.startsWith("iVBORw0KGgo"))
      return "data:image/png;base64,";
    else if (base64String.startsWith("R0lGOD")) 
      return "data:image/gif;base64,";
    else 
      return "data:image/*;base64,";
}

export function compressBase64String(base64String) {
    // Decodifica la stringa Base64 in un buffer binario
    const binaryData = Buffer.from(base64String, 'base64');

    // Comprimi il buffer binario usando pako
    const compressedData = pako.gzip(binaryData);

    // Converti i dati compressi di nuovo in una stringa Base64
    return Buffer.from(compressedData).toString('base64');
}

export function getPdfAsBase64(url) {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.responseType = 'blob';

    xhr.onerror = function (err) {
        console.error('Error:', err);
    };
    
    xhr.onload = function() {
      const reader = new FileReader();
      
      // Quando la lettura del blob è completata
      reader.onloadend = function() {
        // Converte in base64 (estrai solo la parte Base64)
        const base64String = reader.result.split(',')[1];
        console.log(base64String); // Mostra il Base64 in console
      };
      
      // Leggi il blob come Data URL (base64)
      console.log(xhr.response);
      reader.readAsDataURL(xhr.response);
    };
    
    xhr.open('GET', url);
    xhr.send();
}

export function isDateInCurrentMonth(dataString) {
    const data = new Date(dataString);

    if (isNaN(data)) {
        console.error("Data non valida:", dataString);
        return false;
    }

    const meseData = data.getMonth(); // Mese (0-11)
    const annoData = data.getFullYear(); // Anno

    const oggi = new Date();
    const meseCorrente = oggi.getMonth();
    const annoCorrente = oggi.getFullYear();

    return meseData === meseCorrente && annoData === annoCorrente;
}

export function removeZeroEntries(array, excludedKeys = []) {
    return array.filter(item => {
        for (const key of Object.keys(item)) {
            if (excludedKeys.includes(key)) {
                continue;
            }
            const value = item[key];
            if (value !== 0 && value !== "0") {
                return true;
            }
        }
        return false;
    });
}

export function sanitizeInput(input) {
    if (typeof input !== 'string') {
      return input; 
    }
  
    return input
      .replace(/'/g, '') 
      .replace(/"/g, '') 
      .replace(/;/g, '') 
      .replace(/\\/g, ''); 
}