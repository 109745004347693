/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, DetailRow, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
let elemDdl = null, salvaGridId = '', azioneInsert = false;
export default class PaginaGruppiUtenti extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Update', 'Cancel', 'Search', 'ExcelExport', { text: 'Aggiungi Utente', prefixIcon: 'e-aggiungiUtente', id: 'aggiungiUtente'}];
        this.toolbarOptions2 = ['Add', 'Edit', 'Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: false, showDeleteConfirmDialog: true, mode: 'Normal'};
        this.editSettings2 = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Normal'};
        this.pageSettings = {pageSize : 15, pageCount : 15};
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } }];
        this.commands2 = [{ type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" }}];
        this.dsUtenti = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella09001'))).map(elm => { return { VALUE: elm.id_utente.toString(), TEXT: elm.nome + ' ' + elm.cognome } });
        this.DdlObj = null; 
        this.editTemplate = { create: () => { elemDdl = document.createElement('input'); return elemDdl},
                              read: () => {return this.DdlObj.text},
                              destroy: () => {this.DdlObj.destroy()},
                              write: (args) => {this.DdlObj = new DropDownList({fields:{text: 'TEXT', value: 'VALUE'}, placeholder:"Utente", popupHeight:'200px', floatLabelType: 'Always', allowFiltering: true, filterType: 'Contains', 
                                                                    dataSource: this.dsUtenti, value: args.rowData.id_utente?.toString(),
                                                                    change: (args2) => {args.rowData.id_utente = parseInt(args2.value)}}); this.DdlObj.appendTo(elemDdl)}};
        this.childGrid = {
            queryString: 'id_gruppo',
            editSettings: this.editSettings2,
            allowPaging: false,
            allowSelection: true,
            enableHover: true,
            showDeleteConfirmDialog: true,
            allowTextWrap:true,
            allowSorting:false, 
            allowFiltering:false,
            enableAltRow:true, 
            enablePersistence:false,
            pageSettings: { pageSize: 15, pageCount: 15 },
            toolbar: this.toolbarOptions2,
            columns: [
                { field: 'id_utente_gruppo', visible: false, isPrimaryKey: true },
                { field: 'id_gruppo', visible: false},
                { field: 'id_utente', visible: false},
                { field: 'nome_utente', headerText: 'Utenti del gruppo ', textAlign: 'Center', allowEditing: false, EditType:'DropDownEdit', edit:this.editTemplate, headerTemplate: headerTemplate , validationRules: {required: true, nonPresente: [this.checkNonPresente, 'Utente già presente in un gruppo']}},
                { field: 'attivo', headerText: "Attivo", displayAsCheckBox: true, editType: 'booleanedit', textAlign: 'Center', headerTemplate: headerTemplate },
                { field: 'comandi', headerText: "", width: '50', commands: this.commands2, textAlign: 'Center' },
            ], 
            actionComplete: this.actionComplete2.bind(this),
            actionBegin: this.actionBegin2,
        };
        this.dialogButtons1 = [{
            click: () => {
                if (CustomJS.isNullEmpty(document.getElementById('codice_utente').ej2_instances[0].value?.trim()) || CustomJS.isNullEmpty(document.getElementById('nome_utente').ej2_instances[0].value?.trim()) || CustomJS.isNullEmpty(document.getElementById('cognome_utente').ej2_instances[0].value?.trim()))
                    return;
                var format = /[ !@@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?àòéù£°§]/;
                if (format.test(document.getElementById('codice_utente').ej2_instances[0].value?.trim()) && format.test(document.getElementById('nome_utente').ej2_instances[0].value?.trim()) &&
                    format.test(document.getElementById('cognome_utente').ej2_instances[0].value?.trim()) && azioneInsert);
                this.dialogInstance1.hide();
                this.confermaNuovoUtente();
            },
            buttonModel: {
                content: 'CONFERMA',
                cssClass: 'e-primary',
                isPrimary: true
            },
        },
        {
            click: () => {
                this.dialogInstance1.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
            }];
    }
    state = {
        blocking: false,
        dsGruppiUtenti: [],
        showDialog1: false,
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    onRowDatabound(args) {       
    }
    checkNonPresente(args){
        let objTemp = _.find(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella09002'))), elm => { return elm.id_utente.toString() === args.value});
        return CustomJS.isNullEmpty(objTemp) || !azioneInsert;
    }
    actionBegin2(args){
        if(args.requestType === 'add') {           
            args.data.id_gruppo = this.parentDetails.parentRowData.id_gruppo;
            args.data.attivo = true;
            args.data.predefinito = false;
            azioneInsert = true;
        }
        else{
            azioneInsert = false;
        }
        salvaGridId = this.element.id;
    }
    actionComplete(args) {
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {   
            const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '09000';
            const dataObj = {
                IdGruppo: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_gruppo : args.data[0].id_gruppo,
                DescrGruppo: CustomJS.isNullEmpty(args.data[0]) ? args.data.descr_gruppo : args.data[0].descr_gruppo,
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        }); 
                        if (args.action === "add")
                            setTimeout(() => {
                                this.caricaDati();
                            }, 200);                      
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
        }
    }
    actionComplete2(args) {
        if ((args.requestType === 'beginEdit')) { 
            document.getElementById(`${salvaGridId}nome_utente`).ej2_instances[0].enabled = false;
        }
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {   
            const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '09002';
            const dataObj = {
                IdUtenteGruppo: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_utente_gruppo : args.data[0].id_utente_gruppo,
                Predefinito: CustomJS.isNullEmpty(args.data[0]) ? args.data.predefinito : args.data[0].predefinito,
                IdGruppo: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_gruppo : args.data[0].id_gruppo,
                IdUtente: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_utente : args.data[0].id_utente,
                Attivo: CustomJS.isNullEmpty(args.data[0]) ? args.data.attivo : args.data[0].attivo,
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => { 
                    if (retData.response === 'KO') {                       
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });  
                        if (args.action === "add")
                            setTimeout(() => {
                                this.caricaDati();
                            }, 200);   
                    }                         
            }, error => {                
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
        if(args.item.id === 'aggiungiUtente'){
            this.dialogInstance1.buttons = this.dialogButtons1;
            this.dialogInstance1.width = '35%';
            this.dialogInstance1.height = '20%';
            this.dialogInstance1.minHeight = '20%';
            this.dialogInstance1.show();
        }
    }
    confermaNuovoUtente(){
        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        const dataObj = {
            CodiceUtente : document.getElementById('codice_utente').ej2_instances[0].value?.trim(),
            NomeUtente: document.getElementById('nome_utente').ej2_instances[0].value?.trim(),
            CognomeUtente: document.getElementById('cognome_utente').ej2_instances[0].value?.trim(),
        };
        const QueryExec = encodeURIComponent("INSERT INTO [09001] ( username, nome, cognome ) VALUES (@codice_utente, @nome_utente, @cognome_utente);");
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
            toast.success('Nuovo utente aggiunto con successo', {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
            CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '09001', retData2 => {
                if (retData2.length > 0) {
                    let emptyObj = {};
                    Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                    retData2.unshift(emptyObj);
                }
                sessionStorage.setItem('JellyfishTechnologyTabella09001', CustomJS.compressAndEncode(retData2));
            }, messaggio => {
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true}
                );
            });
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,           
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            );
        });
    }
    content1(){
        return (<div>
            <div style={{padding: '5px'}}>
                <TextBoxComponent id='codice_utente' floatLabelType='Auto' placeholder='Username Utente' cssClass='e-outline'></TextBoxComponent>
            </div>
            <div style={{padding: '5px'}}>
                <TextBoxComponent id='nome_utente' floatLabelType='Auto' placeholder='Nome Utente' cssClass='e-outline'></TextBoxComponent>
            </div>
            <div style={{padding: '5px'}}>
                <TextBoxComponent id='cognome_utente' floatLabelType='Auto' placeholder='Cognome Utente' cssClass='e-outline' ></TextBoxComponent>
            </div>
                <h6>Confermare l'aggiunta di un nuovo utente?</h6>
                </div>);
    }
    render() {
        return(<React.Fragment>  
            <BlockUi id='blockUIPaginaGruppiUtenti' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="defaultDialog" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} width='35%'
                    ref={dialog => this.dialogInstance1 = dialog} header='Inserire dati nuovo utente' buttons={this.dialogButtons1} height='45%' minHeight='45%' isModal={true} 
                    content={this.content1}>
                </DialogComponent> 
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                        {/* <div className='row'></div> 
                                        <div className='row'></div>
                                        <div className='row'></div>   */}
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <GridComponent id="GridGruppiUtenti" dataSource={this.state.dsGruppiUtenti} ref={ grid => this.gridInstance = grid } allowSelection={true} enableHover={true} allowTextWrap={true}
                                                    allowPaging={true} allowSorting={false} allowFiltering={false} enableAltRow={true} enablePersistence={false} childGrid={this.childGrid} hierarchyPrintMode='All'
                                                    actionComplete={this.actionComplete.bind(this)} toolbarClick={this.toolbarClick.bind(this)} rowDataBound={this.onRowDatabound} pageSettings={this.pageSettings}
                                                    toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px'}} >
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='id_gruppo' headerText='ID gruppo' isPrimaryKey='true'  visible={false}  textAlign='Center'headerTemplate={headerTemplate} clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='descr_gruppo' headerText="Gruppi" validationRules={{ required: true }} textAlign='Center'headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='10%' commands={this.commands} textAlign='Right'></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit, DetailRow, Page]} />
                                                </GridComponent>
                                            </div>
                                        </div>                        
                                    </div>                              
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount(){
        this.caricaDati();
    }
    caricaDati() {
        this.toggleBlocking();
        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '09000', retData => {
            let GridData1 = _.uniq(retData, elm => elm.id_gruppo);
            let GridData2 = _.map(retData, (elm) => { return {id_utente_gruppo: elm.id, id_gruppo: elm.id_gruppo, id_utente: elm.id_utente, nome_utente: elm.nome_utente, cognome_utente: elm.cognome_utente, codice_: elm.codice_,  attivo: elm.attivo}});
            this.gridInstance.dataSource = GridData1;
            this.gridInstance.childGrid.dataSource = GridData2;
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            ); 
            this.toggleBlocking();
        });
    }
}
