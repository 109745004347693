/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, Page, Filter, Inject, Sort, Group } from '@syncfusion/ej2-react-grids';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import LogoACT from '../assets/LogoAllCTechnology.jpg';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
import moment from 'moment';

// eslint-disable-next-line no-extend-native
Array.prototype.sum1 = function (prop) {
    let total = 0
    for ( let i = 0, _len = this.length; i < _len; i++ ) {
        total += this[i][prop];
    }
    return total;
}

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.85vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {
    let colore = props.column.field === 'comunicazione_per_agenzia' ? 'green' : props.column.field === 'numero_sinistro_agenzia' || props.column.field === 'descr_perito' ? '#133B95' : 'purple';
    let renderDiv = <div><span style={{ fontSize: '0.8vw', color: colore, fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: props[props.column.field] }}></span></div>;

    return (
        renderDiv
    );
}

function fieldTemplate2(props) {
    let renderDiv = <div><span style={{ fontSize: '1.0vw', color: 'purple', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: props[props.column.field] }}></span></div>;

    return (
        renderDiv
    );
}

export default class HomeAgenzia extends SampleBase {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);        
        this.LogoSocieta = LogoACT;   
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.pageSettings = { pageSize : 13, pageCount : 13, pageSizes: ["13", "50", "100", "200", "500", "1000"] };  
        this.filterSettings = { type: 'Excel'};  
        this.toolbarOptions = ['ExcelExport', { text: 'Aggiorna Tabella', prefixIcon: "e-aggiornatabella", id: "aggiornatabella" }, 'Search']; 
        this.accordHeader = ['<span style="font-size:18px;font-weight:bolder;">Statistiche Agenzia/Studio Peritale</span>']; 
        this.mappaStatiAgenzia = [{ descrACT: 'Da assegnare CAT', descrAgenzia: 'Da assegnare tecnico per il sopralluogo'}, 
                                  { descrACT: 'In attesa doc. per documentale', descrAgenzia: 'In attesa documentazione per perizia documentale'}, 
                                  { descrACT: 'Incarico da chiudere a sistema', descrAgenzia: 'Pratica in chiusura sul portale di Compagnia'}, 
                                  { descrACT: 'Perizia controllata', descrAgenzia: 'Perizia controllata dal controllo qualità interno'},
                                  { descrACT: 'Perizia da controllare', descrAgenzia: 'Perizia in controllo qualità interno'}, 
                                  { descrACT: 'Perizia in chiusura', descrAgenzia: 'Perizia in lavorazione'}, 
                                  { descrACT: 'Perizia in chiusura (DOCUMENTALE)', descrAgenzia: 'Perizia in lavorazione'}, 
                                  { descrACT: 'Perizia in chiusura (NO RESIDUI)', descrAgenzia: 'Perizia in lavorazione'},
                                  { descrACT: 'Perizia in chiusura (URGENTE)', descrAgenzia: 'Perizia in lavorazione'}, 
                                  { descrACT: 'Sopralluogo assegnato', descrAgenzia: 'Incaricato tecnico per il sopralluogo'}, 
                                  { descrACT: 'Sopralluogo restituito', descrAgenzia: 'Sopralluogo effettuato'}];
    }
    state = {   
        blocking: false,
        GridData1: [],
        GridData2: []
    }
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }   
    sortComparer1(reference, comparer) {
        if (moment(reference, 'DD/MM/YYYY').isBefore(moment(comparer, 'DD/MM/YYYY'))) {
            return -1;
        }
        if (moment(reference, 'DD/MM/YYYY').isAfter(moment(comparer, 'DD/MM/YYYY'))) {
            return 1;
        }
        return 0;
    }
    onDataBound(args) {
        for (let ind = 0; ind <= 8; ind++)
             document.getElementById('GridSituazioneSinistriAgenzia').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
    }
    onToolbarClick1(args) {
        if (args.item.id === 'aggiornatabella') {
            this.caricaDati();
        }
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance1.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance1.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance1.csvExport();
                break;
        }
    }
    onToolbarClick2(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance2.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance2.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance2.csvExport();
                break;
        }
    }
    accordContent0() {
        return (
            <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="row card-layout">
                        <div className="col-xs-12 col-sm-8 offset-sm-2 col-lg-8 offset-lg-2 col-md-8 offset-md-2">                                           
                            <div tabIndex="0" className="e-card" id="main_card2">
                                <div className="e-card-content">
                                    <GridComponent id="GridStatStudioAgenzia" dataSource={this.state.GridData2} ref={ grid => this.gridInstance2 = grid }  
                                        allowSelection={false} enableHover={false} allowExcelExport={true} allowTextWrap={true} allowPaging={false} allowSorting={false} 
                                        allowFiltering={false} enableAltRow={true}>
                                        <ColumnsDirective>
                                            <ColumnDirective headerText="Anno Corrente" textAlign='Center' columns={[{ field: 'TotSinistriRicevutiAnnoCorr', headerText: 'Totale Sinistri Ricevuti', width: '12%', textAlign: 'Center', headerTextAlign: 'Center', template: fieldTemplate2 }, { field: 'TotSinistriApertiAnnoCorr', headerText: 'Totale Sinistri Aperti', width: '12%', textAlign: 'Center', template: fieldTemplate2 }, { field: 'PercChiusuraAnnoCorr', headerText: '% Chiusura', width: '12%', textAlign: 'Center', template: fieldTemplate2 }]} headerTemplate={headerTemplate} />
                                            <ColumnDirective headerText="Anno Precedente" textAlign='Center' columns={[{ field: 'TotSinistriRicevutiAnnoPrec', headerText: 'Totale Sinistri Ricevuti', width: '12%', textAlign: 'Center', template: fieldTemplate2 }, { field: 'TotSinistriApertiAnnoPrec', headerText: 'Totale Sinistri Aperti', width: '12%', textAlign: 'Center', template: fieldTemplate2 }, { field: 'PercChiusuraAnnoPrec', headerText: '% Chiusura', width: '12%', textAlign: 'Center', template: fieldTemplate2 }]} headerTemplate={headerTemplate} />
                                        </ColumnsDirective>
                                        <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Group, Page]} />
                                    </GridComponent>  
                                </div>  
                            </div>  
                        </div>
                    </div>   
                </div>
            </div>
        );
    }  
    render () {        
        return (<React.Fragment>  
                    <div className="container-fluid">
                        <BlockUi id='blockUIPaginaHomeAgenzia' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                            <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                            <div className="control-section card-control-section basic_card_layout">
                                <div className="e-card-resize-container">
                                    <div className="card-layout">
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                            <div tabIndex="0" className="e-card" id="main_card" style={{ backgroundColor: 'transparent' }}>                                                
                                                <div className="e-card-content" style={{ marginLeft: '20px' }}>
                                                    <div className='row'></div>
                                                    <div className='row'>
                                                        <div className="col-xs-12 col-sm-6 col-lg-4 col-md-4">
                                                            <img id='imgLogo' src={this.LogoSocieta} style={{ paddingTop: '20px', paddingLeft: '40px' }} width='70%' height='auto'></img>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-5 ms-5">
                                                            <span style={{ fontSize: '2.4vw', fontWeight: 'bold', color:'#133B95'}}>Situazione Sinistri Agenzia {JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].ragione_sociale}</span>
                                                        </div>
                                                    </div> 
                                                    <div className='row'>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                            <AccordionComponent id='accordionStatisticheAgenziaStudio' expandMode='Single' ref={accord => this.accordInstance = accord}>
                                                                <AccordionItemsDirective>
                                                                    <AccordionItemDirective header={this.accordHeader[0]} expanded={true} content={this.accordContent0.bind(this)} iconCss="e-imageAccordion" />
                                                                </AccordionItemsDirective>
                                                            </AccordionComponent>   
                                                        </div>
                                                    </div> 
                                                    <div className='row'>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                            <GridComponent id="GridSituazioneSinistriAgenzia" ref={ grid => this.gridInstance1 = grid } toolbar={this.toolbarOptions} dataBound={this.onDataBound.bind(this)}
                                                                allowSelection={false} enableHover={false} gridLines='Horizontal' allowTextWrap={true} allowPaging={false} allowSorting={true} allowFiltering={true} 
                                                                filterSettings={this.filterSettings} enableAltRow={true} pageSettings={this.pageSettings} allowGrouping={false} toolbarClick={this.onToolbarClick1.bind(this)}  
                                                                searchSettings={this.searchSettings} allowExcelExport={true}>
                                                                <ColumnsDirective>
                                                                    <ColumnDirective field='numero_sinistro_agenzia' headerText='Numero Sinistro' width='13%' headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                                    <ColumnDirective field='descrStato' headerText="Stato" width='8%'  headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                                                    <ColumnDirective field='strDataIncarico' headerText="Data Incarico" width='8%' format='dd/MM/yyyy' sortComparer={this.sortComparer1} headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                                                    <ColumnDirective field='strDataSopralluogo' headerText="Data Sopralluogo" width='8%' format='dd/MM/yyyy' sortComparer={this.sortComparer1} headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                                                    <ColumnDirective field='strDataInvioAtto' headerText="Data Invio Atto" width='8%' format='dd/MM/yyyy' sortComparer={this.sortComparer1} headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                                                    <ColumnDirective field='strDataChiusura' headerText="Data Chiusura" width='8%' format='dd/MM/yyyy' sortComparer={this.sortComparer1} headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                                                    <ColumnDirective field='danneggiato' headerText="Danneggiato" width='10%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                                    <ColumnDirective field='descr_perito' headerText="Perito" width='15%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center' clipMode="EllipsisWithTooltip" disableHtmlEncode={false}></ColumnDirective>
                                                                    <ColumnDirective field='comunicazione_per_agenzia' headerText="Comunicazione da studio peritale" headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                                </ColumnsDirective>
                                                                <Inject services={[Toolbar, Filter, Sort, Group, Page, ExcelExport]} />
                                                            </GridComponent>  
                                                        </div>
                                                    </div>                        
                                                </div>   
                                            </div>  
                                        </div>                                                          
                                    </div>
                                </div>
                            </div>                            
                        </BlockUi>     
                    </div>
            </React.Fragment>);
    }
    componentDidMount() {  
        document.getElementById('titoloPagina').innerText = 'Pagina Principale';
        this.caricaTabellaPeriti();
    }
    caricaTabellaPeriti() {
        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, 'NoTab', retData => {
            sessionStorage.setItem(`JellyfishTechnologyTabellaPeritoFiltro`, CustomJS.compressAndEncode(retData));
            this.dsPeriti = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaPeritoFiltro')))?.map(elm => { return { VALUE: elm.id_utente.toString(), TEXT: elm.perito, tel2: elm.telefono2, email1: elm.email1 } });
            this.caricaDati(); 
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        },"SELECT [09001].id_utente, CONCAT([09001].nome, ' ', [09001].cognome) AS perito, [09001].email1, [09001].telefono2 FROM [09001] INNER JOIN [09002] ON [09001].id_utente = [09002].id_utente WHERE ((([09002].id_gruppo)=3 Or ([09002].id_gruppo)=7 Or ([09002].id_gruppo)=1) AND (([09001].bloccato)=0) AND (([09002].attivo)=1)) GROUP BY [09001].id_utente, CONCAT([09001].nome, ' ', [09001].cognome), [09001].email1, [09001].telefono2;");
    }
    caricaDati() {
        this.toggleBlocking();
        const FiltroAggiuntivo = `id_agenzia = ${JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].id_agenzia}`;
        this.toggleBlocking();
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/situazioneSinistri/Recenti?FiltroAggiuntivo=${encodeURIComponent(FiltroAggiuntivo)}`, '', 'GET',              
            '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
            console.log(retData);
            this.toggleBlocking();
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking();
                return;
            }
            retData.forEach(elm => {
                const datiPerito = _.find(this.dsPeriti, elm2 => { return elm2.VALUE?.trim() === elm.IDPERITO?.toString() });
                elm.descrStato = _.find(this.mappaStatiAgenzia, elm2 => { return elm2.descrACT.toUpperCase() === elm.descrizione.toUpperCase() })?.descrAgenzia ?? elm.descrizione;
                elm.strDataIncarico = CustomJS.isNullEmpty(elm.t_incarico) ? '' : moment(elm.t_incarico).format('DD/MM/YYYY');
                elm.strDataSopralluogo = CustomJS.isNullEmpty(elm.t_sopralluogo) ? '' : moment(elm.t_sopralluogo).format('DD/MM/YYYY');
                elm.strDataInvioAtto = CustomJS.isNullEmpty(elm.data_invio_atto) ? '' : moment(elm.data_invio_atto).format('DD/MM/YYYY');
                elm.strDataChiusura = CustomJS.isNullEmpty(elm.t_chiusura) ? '' : moment(elm.t_chiusura).format('DD/MM/YYYY');
                elm.descr_perito = CustomJS.isNullEmpty(datiPerito) ? '' : `${datiPerito.TEXT}<br>${datiPerito.tel2 ?? '02.39565416'}<br>${datiPerito.email1?.indexOf('@actsrl.it') > -1 ? datiPerito.email1 : ''}`;
            }); 
            let GridData2 = [];
            GridData2.push({
                TotSinistriRicevutiAnnoCorr: _.filter(retData, elm => { return elm.t_incarico.startsWith(new Date().getFullYear().toString()) && !_.contains([23, 24, 51, 52], elm.id_stato)}).length,
                TotSinistriRicevutiAnnoPrec: _.filter(retData, elm => { return elm.t_incarico.startsWith((new Date().getFullYear() - 1).toString()) && !_.contains([23, 24, 51, 52], elm.id_stato)}).length,
                TotSinistriApertiAnnoCorr: _.filter(retData, elm => { return elm.t_incarico.startsWith(new Date().getFullYear().toString()) && !_.contains([3, 49, 23, 24, 51, 52], elm.id_stato)}).length,
                TotSinistriApertiAnnoPrec: _.filter(retData, elm => { return elm.t_incarico.startsWith((new Date().getFullYear() - 1).toString()) && !_.contains([3, 49, 23, 24, 51, 52], elm.id_stato)}).length,
                PercChiusuraAnnoCorr: `${parseInt(Math.round((_.filter(retData, elm => { return elm.t_incarico.startsWith(new Date().getFullYear().toString()) && _.contains([3, 49], elm.id_stato)}).length / _.filter(retData, elm => { return elm.t_incarico.startsWith(new Date().getFullYear().toString()) && !_.contains([23, 24, 51, 52], elm.id_stato)}).length) * 100))}%`, 
                PercChiusuraAnnoPrec: `${parseInt(Math.round((_.filter(retData, elm => { return elm.t_incarico.startsWith((new Date().getFullYear() - 1).toString()) && _.contains([3, 49], elm.id_stato)}).length / _.filter(retData, elm => { return elm.t_incarico.startsWith((new Date().getFullYear() - 1).toString()) && !_.contains([23, 24, 51, 52], elm.id_stato)}).length) * 100))}%`
            });
            this.gridInstance1.dataSource = retData; 
            this.setState({ GridData2: GridData2 });
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            this.toggleBlocking();
        }, true, false);
    }
}