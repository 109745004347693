import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ErrorBoundary from './errorBoundary';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'underscore/underscore-min';
import 'bootstrap/dist/css/bootstrap.css';
import './Custom.css';
import './BlockUI.css';
import './assets/fontawesome/css/all.min.css';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF1cVGhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjXn1acXxQT2RdVkFzXQ==');

const rootElement = document.getElementById('root');
const root = createRoot(rootElement); 
root.render(    
      <BrowserRouter>
         <ErrorBoundary>
            <App />
         </ErrorBoundary>
      </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();