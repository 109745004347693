/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, Page, Filter, Inject, Sort, Group } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';
import moment from 'moment';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {
    let colore = props.column.field === 'id_interno_sinistro' ? '#133B95' : 'purple';  
    let renderDiv = <div><span style={{ fontSize: '0.85vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>;
    return (
        renderDiv
    );
}

export default class PaginaAssegnazioneMultiplaPeriti extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.pageSettings = { pageSize : 13, pageCount : 13, pageSizes: ["13", "50", "100", "200", "500", "1000"] };  
        this.filterSettings = { type: 'Excel'};  
        this.toolbarOptions = [{ text: 'Aggiorna Tabella', prefixIcon: "e-aggiornatabella", id: "aggiornatabella" }, 'Search']; 
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.dsPeriti = _.sortBy(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaPeritoFiltro')))?.map(elm => { return { VALUE: elm.id_utente.toString(), TEXT: elm.perito, email: elm.email1 } }), 'TEXT');
    }
    state = {
        showDialog1: false,
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    onToolbarClick(args) {
        if (args.item.id === 'aggiornatabella') {
            this.caricaDati();
        }
    }
    assegnazioneMultiplaPeriti(args) {
        if (this.gridInstance.getSelectedRecords().length === 0 || CustomJS.isNullEmpty(document.getElementById('peritoAssegnMultipla').ej2_instances[0].value))
            return;
        this.dialogInstance.header = 'Attenzione!!';
        this.dialogInstance.content = `La procedura assegnerà tutti i sinistri selezionati al perito <b>${document.getElementById('peritoAssegnMultipla').ej2_instances[0].text}</b>. Verificare bene l'operazione da svolgere in quanto ha un alto impatto a livello di sistema. Confermare l'operazione?`;
        this.dialogInstance.width = '23%';
        this.dialogInstance.height = '22%';
        this.dialogInstance.minHeight = '22%';
        this.dialogInstance.buttons = [
            { click: () => { 
                this.dialogInstance.hide();
                this.gridInstance.getSelectedRecords().forEach(elm => {
                    const dataObj = {
                        IdUtente: document.getElementById('peritoAssegnMultipla').ej2_instances[0].value,
                        IdSinistro: elm.id_sinistro,
                    };
                    const QueryExec = "INSERT INTO [01002] ( id_utente, t_assegnato, id_sinistro ) VALUES (@id_utente, CURRENT_TIMESTAMP, @id_sinistro)";        
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                        this.getStatoAzione(1).then(ret => {
                            const id_stato_tmp = ret.stato;
                            if (!CustomJS.isNullEmpty(id_stato_tmp) && id_stato_tmp !== 0) {
                                this.aggiornaStatoSinistro(elm.id_sinistro, id_stato_tmp).then(ret2 => {
                                }).catch(messaggio => { 
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,           
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true}
                                    );
                                });        
                            }
                            this.getAzioneNota(1).then(ret3 => {
                                if (ret3 === true) {
                                    const dataObj = {
                                        IdSinistro : elm.id_sinistro,
                                        Utente: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username.toUpperCase(),
                                        DescrNota: `Assegnato il perito ${document.getElementById('peritoAssegnMultipla').ej2_instances[0].text}`,
                                        Stato: id_stato_tmp
                                    };
                                    const QueryExec = "INSERT INTO [01003] VALUES (@id_sinistro, null, @descr_nota"  +
                                                        ", CURRENT_TIMESTAMP, 0, 0, CURRENT_TIMESTAMP, @utente, null , 1, @stato)";        
                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,           
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true}
                                        );
                                    });
                                }
                            }).catch(messaggio => { 
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,           
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                );
                            });  
                        }).catch(messaggio => { 
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });   
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        );
                    }); 
                    if (CustomJS.isNullEmpty(document.getElementById('peritoAssegnMultipla').ej2_instances[0].itemData.email)){
                        this.dialogInstance.header = 'Attenzione!!';
                        this.dialogInstance.content = "Impossibile inviare mail al perito. Indirizzo assente";
                        this.dialogInstance.width = '23%';
                        this.dialogInstance.height = '21%';
                        this.dialogInstance.minHeight = '21%';
                        this.dialogInstance.buttons = [{ click: () => { this.dialogInstance3.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                        this.dialogInstance.show();  
                    }
                    else{
                        let indirEmail = document.getElementById('peritoAssegnMultipla').ej2_instances[0].itemData.email
                        let testoEmail = `E' stato assegnato il perito ${document.getElementById('peritoAssegnMultipla').ej2_instances[0].text} per il sinistro ${elm.id_interno_sinistro}.\n\nAttenzione, mail generata automaticamente. Si prega di non rispondere.`;
                        this.invioEmailGenerale('ACT', 'Assegnazione perito', testoEmail, indirEmail)
                        .then(ret => {
                            if (ret === 0) {
                            }
                            else if (ret === 1) {
                                toast.success('Email inviata con successo!!', {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });  
                            }
                            else if (ret.retCode === 2) {
                                toast.error(`Attenzione!! Parametri per invio Email errati. L' errore riscontrato è il seguente : ${JSON.stringify(ret.msgErr)}`, {
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 15000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });  
                            }
                        }).catch(messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });
                    }
                });
                setTimeout(() => {
                    this.caricaDati();
                }, this.gridInstance.getSelectedRecords().length * 100);
                }, 
                    buttonModel: { 
                        content: 'CONFERMA', 
                        cssClass: 'e-primary',
                        isPrimary: true 
                    }
                },
            {
                click: () => {
                    this.dialogInstance.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.dialogInstance.show();                                       
    }
    getStatoAzione(IdAzione) {
        const QueryExec = `SELECT [02010].*, [02004].descrizione AS descr_stato FROM [02010] LEFT JOIN [02004] ON [02010].id_stato = [02004].id_tipo_stato WHERE [02010].id_azione = ${IdAzione}`; 
        let num = 0, descr_stato = ''; 
        return new Promise((resolve,reject) => {                 
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) {                    
                    num = retData[0].id_stato;
                    descr_stato = retData[0].descr_stato;
                } 
                resolve({ stato: num, descr_stato: descr_stato });
            }, messaggio => {               
                reject(messaggio);
            });           
        });
    }
    getAzioneNota(IdAzione) {
        const QueryExec = `SELECT [02010].* FROM [02010] WHERE [02010].id_azione = ${IdAzione}`; 
        let num = false;  
        return new Promise((resolve,reject) => {                 
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                if (retData.length > 0) 
                   num = retData[0].genera_nota;
                resolve(num);
            }, messaggio => {
                reject(messaggio);
            });                     
        });
    }
    aggiornaStatoSinistro(idSinistro, stato) {
        return new Promise((resolve,reject) => {  
            const dataObj3 = {
                IdSinistro : idSinistro,
                Stato: stato            
            };  
            const QueryExec3 = `UPDATE [01001] SET [01001].id_stato = @stato WHERE [01001].id_sinistro = @id_sinistro`;        
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec3, JSON.stringify(dataObj3), retData3 => {
                resolve('OK');  
            }, messaggio => {
                reject(messaggio);
            });  
        });      
    }
    invioEmailGenerale(mittente, oggetto, corpo, dest, destCC, destCCN) {
        return new Promise((resolve,reject) => {
            if(dest.trim() === '[PERITO]'|| dest.trim() === '[CQ]'){            
                resolve(0);
            }
            else {
                CustomJS.getParametroDB(45).then(ret => {                
                    let valoriSplit = ret.split(';');
                    let sendersName = '';
                    let finalSendersName = '';
                    let testoHtml = '<html><head></head><body>';
                    testoHtml += corpo.replaceAll('\n', '<br>');
                    testoHtml += '</body></html>';
                    if (mittente === 'ACT'){
                        sendersName = 'ACT';
                        finalSendersName = `${sendersName} ${valoriSplit[6]}`;
                    } 
                    else{
                        finalSendersName = mittente;
                    }  
                    let dataObj = {
                        server:valoriSplit[0],
                        porta:valoriSplit[1],
                        utente:valoriSplit[2],
                        password:valoriSplit[3],
                        SSL:valoriSplit[4],
                        oauth2ClientID: valoriSplit[7],
                        oauth2ClientSecret: valoriSplit[8],
                        oauth2RefreshToken: valoriSplit[9],
                        mittente: finalSendersName,
                        destinatario: dest,
                        emailMittente:valoriSplit[6],
                        emailCC: destCC,
                        emailBCC: destCCN,
                        soggetto: oggetto,
                        messaggioHtml: testoHtml
                    };
                    CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/sendEmail`, JSON.stringify(dataObj), 'POST',
                        'application/json', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => {
                            if (retData.response === 'KO')
                                resolve({ retCode: 2, msgErr: retData.msg }); 
                            else                            
                                resolve(1);               
                    }, error => {
                        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                        reject(messaggio); 
                    }, true, false)
                }).catch(messaggio => { 
                    reject(messaggio);                    
                }); 
            }
        });
    }

    render() {
        return(<React.Fragment>
            <DialogComponent id="defaultDialogPaginaAssegnMultiplaPeriti" animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                ref={dialog => this.dialogInstance = dialog} isModal={true} >
            </DialogComponent>
            <BlockUi id='blockUIPaginaAssegnMultiplaPeriti' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                        <div className='row'>
                                            <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3'> 
                                                <DropDownListComponent id='peritoAssegnMultipla' fields={this.ddlFields} dataSource={this.dsPeriti}
                                                    allowFiltering={true} filterType='Contains' showClearButton={true} 
                                                    placeholder="Seleziona Perito per Assegnazione Multipla" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'> 
                                                </DropDownListComponent>
                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3 mt-2'>
                                                <ButtonComponent id='assegnazioneMultiplaPeriti' cssClass='e-fill e-info' onClick={this.assegnazioneMultiplaPeriti.bind(this)}>Assegna sinistri selezionati a perito</ButtonComponent>
                                            </div>  
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <GridComponent id="GridSinistriAssegnMultiplaPeriti" ref={ grid => this.gridInstance = grid } toolbar={this.toolbarOptions} 
                                                    allowSelection={true} enableHover={false} gridLines='Horizontal' allowTextWrap={true} allowPaging={true} allowSorting={true} allowFiltering={true} 
                                                    filterSettings={this.filterSettings} enableAltRow={true} pageSettings={this.pageSettings} allowGrouping={true} toolbarClick={this.onToolbarClick.bind(this)}  
                                                    searchSettings={this.searchSettings} allowExcelExport={true} selectionSettings={{ type: 'Multiple', checkboxMode: 'ResetOnRowClick', mode: 'Row', enableToggle: true, checkboxOnly: true }}>
                                                    <ColumnsDirective>
                                                        <ColumnDirective type='checkbox' allowSorting={false} allowFiltering={false} width='60'></ColumnDirective>
                                                        <ColumnDirective field='id_interno_sinistro' headerText='Num. Sinistro' width='7%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                                        <ColumnDirective field='descrizione' headerText="Stato"  headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                                        <ColumnDirective field='descrizione_divisione' headerText="Divisione" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='ragione_sociale_ag' headerText="Agenzia" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='conta_Apertura' headerText="Rit." format='n0' width='5%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                                        <ColumnDirective field='strDataIncarico' headerText="Data Incarico" format='dd/MM/yyyy' sortComparer={this.sortComparer1} headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                                        <ColumnDirective field='polizza' headerText="Polizza" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='danneggiato' headerText="Danneggiato" headerTemplate={headerTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='citta' headerText="Comune" headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, Filter, Sort, Group, Page]} />
                                                </GridComponent>  
                                            </div>
                                        </div>                        
                                    </div>                              
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount(){
        this.caricaDati();
    }
    caricaDati(){
        this.toggleBlocking();
        const FiltroAggiuntivo = `ISNULL(IDperito,'') = ''`;
        this.toggleBlocking();
        CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/situazioneSinistri/Recenti?FiltroAggiuntivo=${encodeURIComponent(FiltroAggiuntivo)}`, '', 'GET',              
        '', JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, retData => { 
            this.toggleBlocking();
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking();
                return;
            }
            retData.forEach(elm => {
                elm.strDataIncarico = CustomJS.isNullEmpty(elm.strDataIncarico) ? '' : moment(elm.strDataIncarico).format('DD/MM/YYYY');
            }); 
            this.gridInstance.dataSource = retData; 
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            this.toggleBlocking();
        }, true, false);
    }
}