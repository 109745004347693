/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable default-case */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, DetailRow, CommandColumn, Filter, Search, Edit, Inject, Group, Sort, Page } from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent, NumericTextBoxComponent, UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent, ListBoxComponent, CheckBoxSelection } from '@syncfusion/ej2-react-dropdowns';
import { Browser } from '@syncfusion/ej2-base';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { random } from 'underscore';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { MDBFooter } from 'mdb-react-ui-kit';
import { FormValidator } from '@syncfusion/ej2-inputs';
import { RichTextEditorComponent, Toolbar as RTEtoolbar, Inject as RTEInject, Image, Link, HtmlEditor, Count, QuickToolbar } from '@syncfusion/ej2-react-richtexteditor';
import PaginaPeriziaOnline from './PaginaPeriziaOnline';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import _ from 'underscore/underscore-min';
import $ from 'jquery';

export default class PaginaPeriziaOnlineCalcoli extends SampleBase {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Search'];
        this.childKey = 0;
        this.editSettings = { allowAdding: false, allowEditing: false, allowDeleting: false, showDeleteConfirmDialog: true, mode: 'Normal' };
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.animationSettings = { effect: 'FadeZoom' };
        this.searchSettings = { operator: 'contains', ignoreCase: true };
        this.pageSettings = { pageSize: 10, pageCount: 10, pageSizes: ["10", "25", "50", "Tutti"] };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.ddlFieldsList = { text: 'text', value: 'id' };
        this.asyncSettings =  {};        
        this.formOptions = {
            rules: {
            }
        }
        this.RTEitems = ['Bold', 'Italic', 'Underline',
            'FontName', 'FontSize',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|', 'CreateLink', 'Print', '|', 'Undo', 'Redo'
        ];
        this.RTEtoolbarSettings = {
            items: this.RTEitems
        };
        this.prefissoCompagnia = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaCompagnie_prefissi')))?.find(({ cod_ele }) => cod_ele === this.props.IdCompagnia.toString())?.descriz;
        this.dsTipoServizio = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaGEN_servizio`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
        this.dsEsitoDanno = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaGEN_esito_danno`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
        this.dsCausaliNonIndennizzo = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaGEN_causale_non_ind`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
        this.notePeritaliListData = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaNoteOperazioniPeritali`)))?.filter(elm => { return !CustomJS.isNullEmpty(elm.cod_ele) }).map(elm => { return { id: elm.cod_ele, text: elm.descriz, nota: elm.servizio_str1 } });
        if (this.isCompagniaGenerali()) {
            this.dsPartite = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_partita`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz, GUID: '0000-0000-0000-0000', KEY: 0 } });
            this.dsGaranzie = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_garanzia`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz, GUID: '0000-0000-0000-0000', servizio_str2: elm.servizio_str2 } });
            this.dsPrezziarioCategorie = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_voce`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } }); // Categorie dalla Gen_voce di Generali
            this.dsUM = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_um`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.cod_ele } });
            this.dsCompagnie = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_CodiciAnia`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz, servizio_str1: elm.servizio_str1 } });
            this.dsCompagnie.forEach(element => {
                if (element.servizio_str1 === this.props.IdCompagnia.toString()) {
                    this.IdCompagniaGeneraliSuCodiciAnia = element.VALUE;
                    return;
                }
            });
            //Informazioni Richieste su Danno
            this.dsInformazioniRichiesteSuDanno = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_InformazioniRichiesteSuDanno`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz, servizio_str2: elm.servizio_str2 } });
            this.nuovoArrInfoRichDanno = []
            var objNuovoInfoRicDanno = {}
            this.dsInformazioniRichiesteSuDanno.forEach(element => {
                var more = []
                if (element.servizio_str2.includes(',')) {
                    more = element.servizio_str2.split(',')
                    more.forEach(moreEle => {
                        objNuovoInfoRicDanno = {
                            cod_ele: element.VALUE,
                            servizio_str2: moreEle,
                            descriz: element.TEXT
                        };
                        this.nuovoArrInfoRichDanno.push(objNuovoInfoRicDanno)
                    });
                }
                else {
                    objNuovoInfoRicDanno = {
                        cod_ele: element.VALUE,
                        servizio_str2: element.servizio_str2,
                        descriz: element.TEXT
                    };
                    this.nuovoArrInfoRichDanno.push(objNuovoInfoRicDanno)
                }
            });
            //Risposte Informazioi Richieste su Danno
            this.dsRisposteInformazioniRichiesteSuDanno = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_RisposteInformazioniRichiesteSuDanno`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz, servizio_str2: elm.servizio_str2 } });
            this.nuovoArrRispInfoRichDanno = []
            var objNuovoRispInfoRicDanno = {}
            this.dsRisposteInformazioniRichiesteSuDanno.forEach(element => {
                var more = []
                if (element.servizio_str2.includes(',')) {
                    more = element.servizio_str2.split(',')
                    more.forEach(moreEle => {
                        objNuovoRispInfoRicDanno = {
                            cod_ele: element.VALUE,
                            servizio_str2: moreEle,
                            descriz: element.TEXT
                        };
                        this.nuovoArrRispInfoRichDanno.push(objNuovoRispInfoRicDanno)
                    });
                }
                else {
                    objNuovoRispInfoRicDanno = {
                        cod_ele: element.VALUE,
                        servizio_str2: element.servizio_str2,
                        descriz: element.TEXT
                    };
                    this.nuovoArrRispInfoRichDanno.push(objNuovoRispInfoRicDanno)
                }
            });
            //Informazioni Richieste su Riepilogo
            this.dsInformazioniRichiesteRiepilogo = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_InformazioniRichiesteRiepilogo`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz, servizio_str2: elm.servizio_str2, servizio_str3: elm.servizio_str3 } });
            this.nuovoArrInfoRichRiep = []
            var objNuovoInfoRicRiep = {}
            this.dsInformazioniRichiesteRiepilogo.forEach(element => {
                var more = []
                if (element.servizio_str2.includes(',')) {
                    more = element.servizio_str2.split(',')
                    more.forEach(moreEle => {
                        objNuovoInfoRicRiep = {
                            cod_ele: element.VALUE,
                            descriz: element.TEXT,
                            servizio_str2: moreEle,
                            servizio_str3: element.servizio_str3
                        };
                        this.nuovoArrInfoRichRiep.push(objNuovoInfoRicRiep)
                    });
                }
                else {
                    objNuovoInfoRicRiep = {
                        cod_ele: element.VALUE,
                        descriz: element.TEXT,
                        servizio_str2: element.servizio_str2,
                        servizio_str3: element.servizio_str3
                    };
                    this.nuovoArrInfoRichRiep.push(objNuovoInfoRicRiep)
                }
            });
            //Risposte Informazioni Richieste su Riepilogo
            this.dsRisposteInformazioniRichiesteRiepilogo = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella${this.prefissoCompagnia}_RisposteInformazioniRichiesteRiepilogo`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz, servizio_str2: elm.servizio_str2 } });
            this.nuovoArrRispInfoRicRiep = []
            var objNuovoRispInfoRicRiep = {}
            this.dsRisposteInformazioniRichiesteRiepilogo.forEach(element => {
                var more = []
                if (element.servizio_str2.includes(',')) {
                    more = element.servizio_str2.split(',')
                    more.forEach(moreEle => {
                        objNuovoRispInfoRicRiep = {
                            cod_ele: element.VALUE,
                            servizio_str2: moreEle,
                            descriz: element.TEXT
                        };
                        this.nuovoArrRispInfoRicRiep.push(objNuovoRispInfoRicRiep)
                    });
                }
                else {
                    objNuovoRispInfoRicRiep = {
                        cod_ele: element.VALUE,
                        servizio_str2: element.servizio_str2,
                        descriz: element.TEXT
                    };
                    this.nuovoArrRispInfoRicRiep.push(objNuovoRispInfoRicRiep)
                }
            });
        }
        //--------------------DS BASE DI TUTTE LE ALTRE COMPAGNIE INIZIO--------------------
        else {
            if (this.isCompagniaUnipol())
                this.dsPartite = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaUNIPOLSAI_Partite`)))?.map(elm => { return { VALUE: elm.id_partita.toString(), TEXT: elm.partita, TIPO_SIN: elm.id_tipo_sinistro } });
            else
                this.dsPartite = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella_partite_app`)))?.map(elm => { return { VALUE: elm.id_partita_app.toString(), TEXT: elm.partita_app, TIPO_SIN: elm.id_tipo_sinistro } });
            // this.dsPartiteFiltrato = []
            // this.dsPartite.forEach(elm => {
            //     var more = []
            //     if (elm.TIPO_SIN.includes(',')) {
            //         more = elm.TIPO_SIN.split(',');
            //         more.forEach(moreEle => {
            //             if (moreEle === this.props.tipoSinistro) {
            //                 var obj = {
            //                     VALUE: elm.VALUE,
            //                     TEXT: elm.TEXT,
            //                 };
            //                 this.dsPartiteFiltrato.push(obj)
            //             }
            //         });
            //     }
            // })
            // this.dsPartite = this.dsPartiteFiltrato;
            // if (this.dsPartite.length === 0)
            //     this.dsPartite = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella_partite_app`)))?.map(elm => { return { VALUE: elm.id_partita_app.toString(), TEXT: elm.partita_app, TIPO_SIN: elm.id_tipo_sinistro } });
            this.dsGaranzie = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella_garanzie`)))?.map(elm => { return { VALUE: elm.id_garanzia.toString(), TEXT: elm.garanzia, TIPO_SIN: elm.id_tipo_sinistro.toString() } });
            // this.dsGaranzieFiltrato = []
            // this.dsGaranzie.forEach(elm => {
            //     if (elm.TIPO_SIN === this.props.tipoSinistro) {
            //         var obj = {
            //             VALUE: elm.VALUE,
            //             TEXT: elm.TEXT,
            //         };
            //         this.dsGaranzieFiltrato.push(elm)
            //     }
            // })
            // this.dsGaranzie = this.dsGaranzieFiltrato;
            // if (this.dsGaranzie.length === 0)
            //     this.dsGaranzie = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella_garanzie`)))?.map(elm => { return { VALUE: elm.id_garanzia.toString(), TEXT: elm.garanzia, TIPO_SIN: elm.id_tipo_sinistro.toString() } });
            this.dsUM = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella_um_prezziario`)))?.map(elm => { return { VALUE: elm.servizio_str2, TEXT: elm.servizio_str2 } });
            //DS di Generali che non sono utilizzati ma devono essere instanziati
            this.dsPrezziarioCategorie = [];
            this.dsCompagnie = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabella00002`)))?.map(elm => { return { VALUE: elm.id_compagnia, TEXT: elm.descrizione } });
            this.dsBeniDanneggiati = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella09009')))?.map(elm => { return { VALUE: elm.ID.toString(), TEXT: elm.bene } });
            this.dsInformazioniRichiesteSuDanno = []
            this.nuovoArrInfoRichDanno = []
            this.dsRisposteInformazioniRichiesteSuDanno = [];
            this.nuovoArrRispInfoRichDanno = []
            this.dsInformazioniRichiesteRiepilogo = [];
            this.nuovoArrInfoRichRiep = []
            this.dsRisposteInformazioniRichiesteRiepilogo = []
            this.nuovoArrRispInfoRicRiep = [];
        }
        //DS CONDIVISI CON TUTTE LE COMPAGNIE
        this.dsDeterminazioneDanno = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaDeterm_danno`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
        this.dsPrezziarioCompleto = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaPrezziario`)))?.map(elm => { return { cod_ele: elm.cod_ele, DESCR_LUNGA: elm.servizio_str3, descriz_CORTA: elm.descriz, COD_CATEGORIA: elm.servizio_str1, MISURA: elm.servizio_str2, PREZZO: elm.servizio_num1 } }); // TEXT = Descrizione Lunga
        this.dsPrezziarioDescrizioniLunghe = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaPrezziario`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.servizio_str3 } }); // TEXT = Descrizione Lunga
        //DS DI GENERALI CONDIVISI CON TUTTE LE COMPAGNIE
        this.dsFormaGaranzia = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem(`JellyfishTechnologyTabellaGEN_forma_ass`)))?.map(elm => { return { VALUE: elm.cod_ele, TEXT: elm.descriz } });
        //DS DI UTILITY
        this.dsDanneggiati = [];
        this.dsLimitePer = [
            { TEXT: 'Anno', VALUE: 'anno' },
            { TEXT: 'Sinistro', VALUE: 'sinistro' },
        ]
        this.dsFranchigiaOrScoperto = [
            { TEXT: 'Franchigia', VALUE: 'franchigia' },
            { TEXT: 'Scoperto', VALUE: 'scoperto' },
        ]
        this.limitiContrattTipiCoass = [
            { TEXT: 'Franchigia €', VALUE: 'franchigia' },
            { TEXT: 'Scoperto %', VALUE: 'scoperto' },
            { TEXT: 'Limite Indennizzo €', VALUE: 'limite_indennizzo' },
            { TEXT: 'Nessuno', VALUE: 'nessuno' }
        ];
        //Numero di ogni combo per ciclare i box
        this.numPreesistenze = 1;
        this.numRighePreesCostruzioni = 1;
        this.numRighePreesDescrizioni = 1;
        this.numStime = 1;
        this.numTabPrezziari = 1;
        this.numRigheCoassicurazione = 0;
        this.numTabelleRiepilogo = 0;
        this.numRigheRiepilogoGaranzie = 0;
        this.numListboxGaranzia = 2;
        this.numListboxRiepilogo = 3;

        //VARIABILI DI UTILITY
        //altre
        this.allArrayLocalizzazioneForeachGaranzia = [];

        //STRUTTURA INTERNA STATI OPERAZIONI CRUD
        this.strutturaStatiPreesistenzeBase = [];
        this.strutturaStatiPreesistenzePRA = [];
        this.strutturaStatiPreesistenzeCostruzioni = [];
        this.strutturaStatiPreesistenzeDescrizioni = [];
        this.strutturaStatiStimeBase = [];
        this.strutturaStatiStimePrezziari = [];
        this.strutturaStatiCoassicurazioniRighe = [];
        this.strutturaStatiInfoRichiesteDelete = []
        this.strutturaStatiCausali = []

        //Info richieste array di utility per salvataggio e caricamento
        this.nuovoNumIdPrezziario = 0;
        this.strutturaRiepilogoDelete = {
            IdRiepilogo: '',
            IdDanneggiati: [],
            IdRighe: []
        }
        this.riservaCe = false;
        this.contraenteRiserva = "";
        this.tipoServizio = "";
        this.franchigeCondivise = []
        this.scopertiCondivisi = []
        this.periziaModificata = false;

        this.strutturaStatiEliminaDati = {
            idPreesistenzaJftech: [],
            idPreesistenzaCostruz: [],
            idPreesistenzaDescr: [],
            idPreesistenzaGen: [],
            idStimeDescrizioni: [],
            idDannoDettInfoRichGen: [],
            idCoassicurazioni: [],
            idInfoRichieste: [],
            idDanneggiatiGen: [],
            idRiepilogoPerizia: 0,
            idRiepilogoDanneggiati: [],
            idRiepilogoRigheDanneggiato: [],
            idCausali: []
        }
        this.lightboxImages = [];
        this.lightboxImage = '';
    }
    state = {
        blocking: false,
        showDialog: false,
        showLightbox: false
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    toggleBlocking2(show) {
        let container, root;
        if (show) {
            document.getElementById('blockUIGenerico').style.removeProperty('display');
            document.getElementById('spinnerUIGenerico').style.removeProperty('display');
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.render(CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]);
        }
        else {
            container = document.getElementById('spinnerUIGenerico');
            root = createRoot(container);
            root.unmount();
            document.getElementById('blockUIGenerico').style.display = 'none';
            document.getElementById('spinnerUIGenerico').style.display = 'none';
        }
    }
    onCreatedBtnInizioPag() {
        this.element.addEventListener('click', function (args) {
            CustomJS.scrollAuto(500, 0, false, true);
        });       
    }
    //--------------------FUNZIONI DI UTILITY INIZIO----------------
    rimuoviDaStrutturaStatiElementoNuovoEliminato(value, index, arr) {
        // If the value at the current array index matches the specified value
        if (value.stato_iniziale === 'n' && value.stato === 'd') {
            // Removes the value from the original array
            arr.splice(index, 1);
            return;
        }
        return;
    }

    sinistroChiuso() {
        document.getElementById(`statoNonModificabilePeritoP2_${this.props.IdInternoSinistro}`).innerText = "Sinistro non modificabile!"
        document.getElementById(`salvaEsciP2_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;

        for (let i = 0; i < this.numPreesistenze; i++) {
            if (document.getElementById(`tabellaCompletaPreesistenza-T${i}_${this.props.IdInternoSinistro}`)) {
                if (document.getElementById(`aggiornaPreesistenza-T${i}_${this.props.IdInternoSinistro}`))
                    document.getElementById(`aggiornaPreesistenza-T${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                //BLOCCO RIGHE COSTRUZIONI
                if (document.getElementById(`btnAggiungiRigaPartFabb-T${i}-R-0`))
                    document.getElementById(`btnAggiungiRigaPartFabb-T${i}-R-0`).ej2_instances[0].disabled = true;
                this.strutturaStatiPreesistenzeCostruzioni.forEach(costruz => {
                    if (document.getElementById(`rigaPreesistenzaCostruzione-T${i}-R-${costruz.r}_${this.props.IdInternoSinistro}`)) {
                        document.getElementById(`btnAggiungiRigaPartFabb-T${i}-R-${costruz.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                        if (document.getElementById(`btnEliminaRigaPreesCostruzione-T${i}-R-${costruz.r}_${this.props.IdInternoSinistro}`))
                            document.getElementById(`btnEliminaRigaPreesCostruzione-T${i}-R-${costruz.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                    }
                })
                //BLOCCO RIGHE descrizIONI
                if (document.getElementById(`btnAggiungiRigaPreesDescr-T${i}-R-0`))
                    document.getElementById(`btnAggiungiRigaPreesDescr-T${i}-R-0`).ej2_instances[0].disabled = true;
                this.strutturaStatiPreesistenzeDescrizioni.forEach(descr => {
                    if (document.getElementById(`rigaPreesDescrizione-T${i}-R-${descr.r}_${this.props.IdInternoSinistro}`)) {
                        document.getElementById(`btnAggiungiRigaPreesDescr-T${i}-R-${descr.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                        if (document.getElementById(`btnEiminaRigaPreesDescr-T${i}-R-${descr.r}_${this.props.IdInternoSinistro}`))
                            document.getElementById(`btnEiminaRigaPreesDescr-T${i}-R-${descr.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                    }
                })
            }
        }

        for (let i = 0; i < this.numStime; i++) {
            if (document.getElementById(`tabellaStima-D${i}_${this.props.IdInternoSinistro}`)) {
                //BLOCCO CAMPI STIMA
                if (document.getElementById(`determinazioneDanno_D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001')
                    document.getElementById(`aggiornaTabellaStimaValoreANuovo-D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
                else if (document.getElementById(`determinazioneDanno_D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                         || document.getElementById(`determinazioneDanno_D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                         || document.getElementById(`determinazioneDanno_D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                         || document.getElementById(`determinazioneDanno_D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                         || document.getElementById(`determinazioneDanno_D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006')
                    document.getElementById(`aggiornaTabellaStimaStatoUso-D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;

                document.getElementById(`btnAggiungiRigaStimaPrezziario-D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                document.getElementById(`btnFranchigiaManuale_D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true

                if (this.isCompagniaGenerali()) {
                    document.getElementById(`esitoDanno_D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true
                    document.getElementById(`accordoAniaSi-D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                    document.getElementById(`accordoAniaNo-D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                    document.getElementById(`ripristiniUltimSi-D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                    document.getElementById(`ripristiniUltimNo-D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true

                    document.getElementById(`noteRipristini-D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true
                }
                document.getElementById(`coassicurazioneSi-D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                document.getElementById(`coassicurazioneNo-D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true

                //BLOCCO RIGHE PREZZIARIO
                this.strutturaStatiStimePrezziari.forEach(prezz => {
                    if (document.getElementById(`tabPrezziario-D${i}-R-${prezz.r}_${this.props.IdInternoSinistro}`)) {
                        document.getElementById(`btnCambiaDescriz-D${i}-R${prezz.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                        document.getElementById(`btnApriPrezziario-D${i}-R${prezz.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                        document.getElementById(`btnAggiungiRigaStimaPrezziario-D${i}-R${prezz.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                        if (document.getElementById(`btnEliminaRigaStimaPrezziario-D${i}-R${prezz.r}_${this.props.IdInternoSinistro}`))
                            document.getElementById(`btnEliminaRigaStimaPrezziario-D${i}-R${prezz.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                    }
                })
                //BLOCCO RIGHE COASSICURAZIONI
                if (document.getElementById(`btnAggiungiRigaCompagniaR1-D${i}_${this.props.IdInternoSinistro}`))
                    document.getElementById(`btnAggiungiRigaCompagniaR1-D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                this.strutturaStatiCoassicurazioniRighe.forEach(coass => {
                    if (document.getElementById(`btnEliminaCompagniaRiparto1-D${i}-C${coass.c}_${this.props.IdInternoSinistro}`))
                        document.getElementById(`btnEliminaCompagniaRiparto1-D${i}-C${coass.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                    if (document.getElementById(`btnAggiungiRigaCompagniaR1-D${i}-R${coass.c}_${this.props.IdInternoSinistro}`))
                        document.getElementById(`btnAggiungiRigaCompagniaR1-D${i}-R${coass.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
                })
                document.getElementById(`btnAggiornaDatiCoassicuraz-D${i}_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true
            }
        }
        document.getElementById(`aggiornaRiepilogo`).ej2_instances[0].disabled = true;
    }

    cambiaPaginaFooter(pagina, fromSalva, args) {
        if (fromSalva)
            this.toggleBlocking2(false);
        if (pagina === 'ElaboratoP1') {    
            const confermaConfUsc = () => {
                ++this.childKey;
                const container = document.getElementById(`e-content-tabDettaglio${this.props.IdInternoSinistro}_1`);
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    document.getElementById(`tabDettaglio${this.props.IdInternoSinistro}`).ej2_instances[0].items[1].header.text = 'Perizia Online - Elaborato Peritale';
                    document.querySelectorAll(`#e-item-tabDettaglio${this.props.IdInternoSinistro}_1 div .e-tab-text`)[0].innerText = 'Perizia Online - Elaborato Peritale'
                    setTimeout(() => {
                        this.distruggiDialog(true);
                    }, 500);
                }}><PaginaPeriziaOnline key={this.childKey} IdInternoSinistro={this.props.IdInternoSinistro} IdSinistro={this.props.IdSinistro} IdIncarico={this.props.IdIncarico} IdIncaricoUnipol={this.props.IdIncaricoUnipol} IdCompagnia={this.props.IdCompagnia} numeroPolizza={this.props.numeroPolizza} tipoSinistro={this.props.tipoSinistro}></PaginaPeriziaOnline></CallbackWrapper>);
            }

            if (fromSalva) 
                confermaConfUsc();
            else {
                this.dialogInstanceElimina.width = '30%';
                this.dialogInstanceElimina.height = '25%';
                this.dialogInstanceElimina.minHeight = '25%';
                this.dialogInstanceElimina.buttons = [];
                this.dialogInstanceElimina.beforeOpen = (args) => {
                    const container = document.getElementById(`containerDialogEliminaCalcoli_${this.props.IdInternoSinistro}`);
                    const root = createRoot(container);
                    root.unmount();
                };
                this.dialogInstanceElimina.open = (args) => {
                    const container = document.getElementById(`containerDialogEliminaCalcoli_${this.props.IdInternoSinistro}`);
                    const root = createRoot(container);
                    root.render(<CallbackWrapper callback={() => {
                    }}>
                        <div style={{ textAlign: 'center' }}>
                            <h4>{CustomJS.messaggioConfermaUscita}</h4>
                            <i className="fa fa-question-circle"></i>Scegli un opzione<br></br>
                            <div style={{ width: '70%', display: 'inline-flex' }}>
                                <button id='annullaBtn' className='pulsanti-sessione-custom' onClick={() => { this.dialogInstanceElimina.hide(); }} >Annulla</button>
                                <button id='esciBtn' style={{ backgroundColor: '#b62f20' }} className='pulsanti-sessione-custom' onClick={() => { this.dialogInstanceElimina.hide(); confermaConfUsc(); }}>Esci</button>
                            </div>
                        </div>
                    </CallbackWrapper>);
                };
                this.dialogInstanceElimina.show();    
            }
        }
        else if (pagina === 'Dettaglio') {
            const confermaConfUsc = () => {
                document.getElementById(`tabDettaglio${this.props.IdInternoSinistro}`).ej2_instances[0].select(0);
                document.getElementById(`elaboratoPeritale${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
                document.getElementById(`elaboratoCalcoli${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
                document.getElementById(`tabDettaglio${this.props.IdInternoSinistro}`).ej2_instances[0].enableTab(0, true);
                document.getElementById(`tabDettaglio${this.props.IdInternoSinistro}`).ej2_instances[0].removeTab(1);
                setTimeout(() => {
                    this.distruggiDialog(false);
                }, 500);
            }

            if (fromSalva) 
                confermaConfUsc();
            else {
                this.dialogInstanceElimina.width = '30%';
                this.dialogInstanceElimina.height = '25%';
                this.dialogInstanceElimina.minHeight = '25%';
                this.dialogInstanceElimina.buttons = [];
                this.dialogInstanceElimina.beforeOpen = (args) => {
                    const container = document.getElementById(`containerDialogEliminaCalcoli_${this.props.IdInternoSinistro}`);
                    const root = createRoot(container);
                    root.unmount();
                };
                this.dialogInstanceElimina.open = (args) => {
                    const container = document.getElementById(`containerDialogEliminaCalcoli_${this.props.IdInternoSinistro}`);
                    const root = createRoot(container);
                    root.render(<CallbackWrapper callback={() => {
                    }}>
                        <div style={{ textAlign: 'center' }}>
                            <h4>{CustomJS.messaggioConfermaUscita}</h4>
                            <i className="fa fa-question-circle"></i>Scegli un opzione<br></br>
                            <div style={{ width: '70%', display: 'inline-flex' }}>
                                <button id='annullaBtn' className='pulsanti-sessione-custom' onClick={() => { this.dialogInstanceElimina.hide(); }} >Annulla</button>
                                <button id='esciBtn' style={{ backgroundColor: '#b62f20' }} className='pulsanti-sessione-custom' onClick={() => { this.dialogInstanceElimina.hide(); confermaConfUsc(); }}>Esci</button>
                            </div>
                        </div>
                    </CallbackWrapper>);
                };
                this.dialogInstanceElimina.show();    
            }
        }
    }
    //--------------------FUNZIONI DI UTILITY FINE----------------

    //--------------------------CREAZIONE TABELLE INIZIO---------------------------
    //---------------------------PREESISTENZA TABELLE INIZIO-----------------
    tabellaPreesistenzaBase(idPartita, descrizione, formaGaranzia, guidPartita, keyPartita, sommaAssicurata) {
        return new Promise((resolve, reject) => {
            var num = this.numPreesistenze;
            this.numPreesistenze++;

            const DivContainer = document.getElementById(`contentTutteTabellePreesistenze_${this.props.IdInternoSinistro}`);
            const newDiv = document.createElement("div");
            newDiv.setAttribute("id", `tabellaCompletaPreesistenza-T${num}_${this.props.IdInternoSinistro}`);
            DivContainer.append(newDiv);

            const container = document.getElementById(`tabellaCompletaPreesistenza-T${num}_${this.props.IdInternoSinistro}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                //La tabella Fabbricato è l' unica tabella preesistenza a contenere le costruzioni
                if (idPartita === 'TP016' || idPartita === '1') { }
                else
                    document.getElementById(`costruzioniPreesistenza-T${num}_${this.props.IdInternoSinistro}`).style.display = 'none';

                document.getElementById(`nomePartita${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idPartita;
                setTimeout(() => {
                    var titolo = ''
                    var part = document.getElementById(`nomePartita${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                    var descr = document.getElementById(`descrizionePartita${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    titolo = CustomJS.isNullEmpty(descr) ? part : part + ' : ' + descr;
                    document.getElementById(`nomePreesistenza-T${num}_${this.props.IdInternoSinistro}`).innerText = titolo;
                }, 125)

                document.getElementById(`descrizionePartita${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = descrizione;
                document.getElementById(`formaGaranzia${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = formaGaranzia;
                document.getElementById(`idPartitaPO-T${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource = [{ TEXT: keyPartita, VALUE: keyPartita, GUID_PARTITA_GEN: '' }]
                document.getElementById(`sommaAssicurataInput_T${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = sommaAssicurata;
                document.getElementById(`valoreDegradoInput_T${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 25;

                setTimeout(() => {
                    document.getElementById(`idPartitaPO-T${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = keyPartita;
                    setTimeout(() => {
                        document.getElementById(`idPartitaPO-T${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.GUID_PARTITA_GEN = guidPartita;
                        var valoreDerogato = sommaAssicurata * document.getElementById(`derogaInput_T${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                        document.getElementById(`valoreDerogatoResult_T${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = valoreDerogato
                        resolve();
                    }, 150)
                }, 150);
                //Per la struttura delle preesistenze base non c'è la key generali in quanto gestita nella struttura this.strutturaStatiPreesistenzeCostruzioni
                var objStrutturaStatiPreesistenza = {
                    id_incarico: this.props.IdIncarico,
                    stato: 'n',
                    stato_iniziale: 'n',
                    keyJftech: null,
                    keyGen: null,
                    id: `tabellaCompletaPreesistenza-T${num}_${this.props.IdInternoSinistro}`,
                    t: num,
                    keyPartita: keyPartita
                };
                this.strutturaStatiPreesistenzeBase.push(objStrutturaStatiPreesistenza)
            }}>{<>
                <table className='perizia-table preesistenza-table' style={{ marginTop: '2%' }} >
                    <tr style={{ display: 'none' }}>
                        <th><div style={{ width: '5%' }}>
                            <DropDownListComponent readonly={true} id={`idPartitaPO-T${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="ID Partita Perizia Online" floatLabelType='Auto' cssClass='e-outline'>
                            </DropDownListComponent>
                        </div></th>
                    </tr>
                    <tr>
                        <th><h4 id={`nomePreesistenza-T${num}_${this.props.IdInternoSinistro}`}></h4></th>
                    </tr>
                </table>
                <table className='perizia-table preesistenza-table' >
                    <tr>
                        <th style={{ display: 'none' }}><DropDownListComponent readonly={true} id={`nomePartita${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsPartite}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Partita" floatLabelType='Auto' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ display: 'none' }}><TextBoxComponent readonly={true} id={`descrizionePartita${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' placeholder='Descrizione' cssClass='e-outline'></TextBoxComponent></th>
                        <th><div style={{ width: '16.65%' }}><DropDownListComponent readonly={true} id={`formaGaranzia${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsFormaGaranzia}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Forma Garanzia" floatLabelType='Auto' cssClass='e-outline'>
                        </DropDownListComponent></div></th>
                    </tr>
                </table>
                <table className='perizia-table preesistenza-table' >
                    <tr>
                        <th style={{ textAlign: 'right' }}>Somma Assicurata :</th>
                        <th> <NumericTextBoxComponent showSpinButton={false} id={`sommaAssicurataInput_T${num}_${this.props.IdInternoSinistro}`} onBlur={this.calcolaPreesistenza.bind(this, num, 'noRiga')} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ textAlign: 'right' }}>Deroga :</th>
                        <th><NumericTextBoxComponent showSpinButton={false} id={`derogaInput_T${num}_${this.props.IdInternoSinistro}`} onBlur={this.calcolaPreesistenza.bind(this, num, 'noRiga')} floatLabelType='Auto' format="#.## '%'" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ textAlign: 'right' }}>Valore Derogato</th>
                        <th> <NumericTextBoxComponent showSpinButton={false} value={0} readonly={true} id={`valoreDerogatoResult_T${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    </tr>
                    <tr id={`costruzioniPreesistenza-T${num}_${this.props.IdInternoSinistro}`}>
                        <th>Valore Unitario (/m³)</th>
                        <th>Superficie Equivalente (m²)
                        </th>
                        <th >Altezza (m)</th>
                        <th >Volume (m³)</th>
                        <th>Valore di ricostruzione a nuovo</th>
                        <th style={{ textAlign: 'left' }}><ButtonComponent style={{ marginBottom: '12px' }} id={`btnAggiungiRigaPartFabb-T${num}-R-0_${this.props.IdInternoSinistro}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaCostruzionePreesistenza.bind(this, num, '', true)}><i class="fas fa-plus"></i></ButtonComponent></th>
                    </tr>
                </table>
                <table id={`contentRighePreesCostruzioni-T${num}_${this.props.IdInternoSinistro}`} className='perizia-table preesistenza-table contentRighePreesistenza costruzioni' >
                    {/* TUTTE LE RIGHE DELLA PREESISTENZA COSTRUZIONI */}
                </table>
                <table className='perizia-table preesistenza-table'>
                    <tr>
                        <th style={{ width: '1000px' }}>Descrizione</th>
                        <th style={{ width: '250px' }}>Valore</th>
                        <th style={{ textAlign: 'left' }}><ButtonComponent id={`btnAggiungiRigaPreesDescr-T${num}-R-0_${this.props.IdInternoSinistro}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaDescrizionePreesistenza.bind(this, num, '', '')}><i class="fas fa-plus"></i></ButtonComponent></th>
                    </tr>
                </table>
                <table id={`contentRighePreesDescrizioni-T${num}_${this.props.IdInternoSinistro}`} className='perizia-table preesistenza-table contentRighePreesistenza descrizioni'>
                    {/* TUTTE LE RIGHE DELLA PREESISTENZA descrizIONI */}
                </table>
                <table className='perizia-table prees-aggiustamento preesistenza-table'>
                    <tr>
                        <th></th>
                        <th></th>
                        <th id={`scoperturaTipo-T${num}_${this.props.IdInternoSinistro}`}>Scopertura</th>
                    </tr>
                    <tr>
                        <th style={{ width: '1000px', textAlign: 'right' }}>Totale esistenza a nuovo :</th>
                        <th style={{ width: '250px' }}> <NumericTextBoxComponent value={0} showSpinButton={false} style={{ fontWeight: '500' }} readonly={true} id={`valoreTotEsistNuovoResult_T${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th><NumericTextBoxComponent showSpinButton={false} readonly={true} value={0} id={`valoreScoperturaResult_T${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="#.## '%'" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    </tr>
                    <tr id={`rigaDegrado-T${num}_${this.props.IdInternoSinistro}`}>
                        <th style={{ width: '1000px', textAlign: 'right' }}>Degrado per stato d'uso :</th>
                        <th><NumericTextBoxComponent showSpinButton={false} onBlur={this.calcolaPreesistenza.bind(this, num, 'noRiga')} id={`valoreDegradoInput_T${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="#.## '%'" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th></th>
                    </tr>
                    <tr id={`rigaTotEsStatUso-T${num}_${this.props.IdInternoSinistro}`}>
                        <th style={{ width: '1000px', textAlign: 'right' }}>Totale esistenza allo stato d'uso :</th>
                        <th> <NumericTextBoxComponent showSpinButton={false} value={0} readonly={true} id={`valoreTotEsistStatoUsoInput_T${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th><NumericTextBoxComponent showSpinButton={false} value={0} readonly={true} id={`valoreScoperturaStatoUso_T${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="#.## '%'" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    </tr>
                </table>
                <table className='perizia-table prees-aggiustamento preesistenza-table'>
                    <tr>
                        <th style={{ width: '250px' }}> <div style={{ margin: '0.4%' }}>Insuff. Assicurativa</div><NumericTextBoxComponent showSpinButton={false} readonly={true} id={`insuffAssicurativaPerc_T${num}_${this.props.IdInternoSinistro}`} value={0} placeholder='Insufficienza Assicurativa' floatLabelType='Auto' format="#.## '%'" cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ width: '1250px' }}><div style={{ marginTop: '1.4%' }}></div><TextBoxComponent readonly={true} id={`messaggioNote-T${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' placeholder='Note Suff/Insuff Assicurativa' cssClass='e-outline'></TextBoxComponent></th>
                    </tr>
                </table>
                <table className='perizia-table prees-aggiustamento preesistenza-table'>
                    <tr>
                        <th><div style={{ margin: '0.4%' }}>Note Preesistenza</div><TextBoxComponent cssClass='notePreesistenza' htmlAttributes={{ rows: 1 }} multiline={true} id={`notePreesistenza-T${num}_${this.props.IdInternoSinistro}`} placeholder='Note Preesistenza' /></th>
                    </tr>
                </table>
                <div style={{ textAlign: 'right', padding: '1%' }}>
                    <ButtonComponent style={{ marginLeft: '2%' }} id={`aggiornaPreesistenza-T${num}_${this.props.IdInternoSinistro}`} alt='Aggiorna Preesistenza' cssClass='e-fill e-info' type='button' onClick={this.calcolaPreesistenza.bind(this, num, 'noRiga')}>AGGIORNA PREESISTENZA</ButtonComponent>
                </div>
            </>}</CallbackWrapper>);
        });
    }
    cambiaMetroCuboOQuadro(numPart, num, args) {
        //se è checkkato allora uso i metri quadri
        if (args.checked) {
            document.getElementById(`cubiOQuadri_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).innerText = 'm³'
            //sono in metri quadri
            document.getElementById(`altezzaInput_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 1;
            document.getElementById(`altezzaInput_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
            document.getElementById(`volumeResult_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 1;
            document.getElementById(`volumeResult_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = true;
            document.getElementById(`boxAltezza_${numPart}_R_${num}_${this.props.IdInternoSinistro}`).style.visibility = 'hidden';
            document.getElementById(`boxVolume_${numPart}_R_${num}_${this.props.IdInternoSinistro}`).style.visibility = 'hidden';
        }
        else {
            //uso i metri cubi (default)
            document.getElementById(`cubiOQuadri_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).innerText = 'm²'
            document.getElementById(`altezzaInput_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
            document.getElementById(`volumeResult_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].readonly = false;
            document.getElementById(`boxAltezza_${numPart}_R_${num}_${this.props.IdInternoSinistro}`).style.removeProperty('visibility')
            document.getElementById(`boxVolume_${numPart}_R_${num}_${this.props.IdInternoSinistro}`).style.removeProperty('visibility')
        }
        this.calcolaPreesistenza(numPart, num)
    }
    tabellaPreesistenzaPRA(idPartita, descrizione, formaGaranzia, guidPartita, keyPartita) {
        return new Promise((resolve, reject) => {
            var num = this.numPreesistenze;
            this.numPreesistenze++;
            const DivContainer = document.getElementById(`contentTutteTabellePreesistenze_${this.props.IdInternoSinistro}`);
            const newDiv = document.createElement("div");
            newDiv.setAttribute("id", `tabellaCompletaPreesistenza-T${num}_${this.props.IdInternoSinistro}`);
            newDiv.setAttribute("class", `marginSoprsTabPartfabb`);
            DivContainer.append(newDiv);

            const container = document.getElementById(`tabellaCompletaPreesistenza-T${num}_${this.props.IdInternoSinistro}`)
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {

                document.getElementById(`nomePartita${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idPartita;

                document.getElementById(`descrizionePartita${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = descrizione;
                document.getElementById(`formaGaranzia${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = formaGaranzia;
                document.getElementById(`idPartitaPO-T${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource = [{ TEXT: keyPartita, VALUE: keyPartita, GUID_PARTITA_GEN: '' }]

                setTimeout(() => {
                    document.getElementById(`idPartitaPO-T${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = keyPartita;
                    var titolo = ''
                    var part = document.getElementById(`nomePartita${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                    var descr = document.getElementById(`descrizionePartita${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    titolo = CustomJS.isNullEmpty(descr) ? part : part + ' : ' + descr;
                    document.getElementById(`nomePreesistenza-T${num}_${this.props.IdInternoSinistro}`).innerText = titolo;
                    setTimeout(() => {
                        document.getElementById(`idPartitaPO-T${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.GUID_PARTITA_GEN = guidPartita;
                        resolve()
                    }, 150)
                }, 150)
            }}>{<>
                <table className='perizia-table preesistenza-table partita-pra' style={{ marginTop: '2%' }} >
                    <tr style={{ display: 'none' }}>
                        <th><div style={{ width: '5%' }}>
                            <DropDownListComponent readonly={true} id={`idPartitaPO-T${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="ID Partita Perizia Online" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent>
                        </div></th>
                    </tr>
                    <tr>
                        <th><h4 id={`nomePreesistenza-T${num}_${this.props.IdInternoSinistro}`}></h4></th>
                    </tr>
                </table>
                <table className='perizia-table preesistenza-table partita-pra' >
                    <tr>
                        <th style={{ display: 'none' }}><DropDownListComponent readonly={true} id={`nomePartita${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsPartite}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Partita" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ display: 'none' }}><TextBoxComponent readonly={true} id={`descrizionePartita${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' placeholder='Descrizione' cssClass='e-outline'></TextBoxComponent></th>
                        <th style={{ display: 'none' }}><DropDownListComponent readonly={true} id={`formaGaranzia${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsFormaGaranzia}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Forma Garanzia" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                    </tr>
                </table>
                <table className='perizia-table preesistenza-table partita-pra' >
                    <tr >
                        <th ><h5 style={{ color: '#e53e14' }}>La partita è prestata nella forma PRIMO RISCHIO ASSOLUTO</h5></th>
                    </tr>
                </table>
            </>}</CallbackWrapper>);
        });
    }
    tabellaPreesistenzaVNNP(idPartita, descrizione, formaGaranzia, guidPartita, keyPartita) {
        return new Promise((resolve, reject) => {
            var num = this.numPreesistenze;
            this.numPreesistenze++;
            const DivContainer = document.getElementById(`contentTutteTabellePreesistenze_${this.props.IdInternoSinistro}`);
            const newDiv = document.createElement("div");
            newDiv.setAttribute("id", `tabellaCompletaPreesistenza-T${num}_${this.props.IdInternoSinistro}`);
            newDiv.setAttribute("class", `marginSoprsTabPartfabb`);
            DivContainer.append(newDiv);

            const container = document.getElementById(`tabellaCompletaPreesistenza-T${num}_${this.props.IdInternoSinistro}`)
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {

                document.getElementById(`nomePartita${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idPartita;

                document.getElementById(`descrizionePartita${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = descrizione;
                document.getElementById(`formaGaranzia${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = formaGaranzia;
                document.getElementById(`idPartitaPO-T${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource = [{ TEXT: keyPartita, VALUE: keyPartita, GUID_PARTITA_GEN: '' }]

                setTimeout(() => {
                    document.getElementById(`idPartitaPO-T${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = keyPartita;
                    var titolo = ''
                    var part = document.getElementById(`nomePartita${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                    var descr = document.getElementById(`descrizionePartita${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    titolo = CustomJS.isNullEmpty(descr) ? part : part + ' : ' + descr;
                    document.getElementById(`nomePreesistenza-T${num}_${this.props.IdInternoSinistro}`).innerText = titolo;
                    setTimeout(() => {
                        document.getElementById(`idPartitaPO-T${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.GUID_PARTITA_GEN = guidPartita;
                        resolve()
                    }, 150)
                }, 150)
            }}>{<>
                <table className='perizia-table preesistenza-table partita-pra' style={{ marginTop: '2%' }} >
                    <tr style={{ display: 'none' }}>
                        <th><div style={{ width: '5%' }}>
                            <DropDownListComponent readonly={true} id={`idPartitaPO-T${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="ID Partita Perizia Online" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent>
                        </div></th>
                    </tr>
                    <tr>
                        <th><h4 id={`nomePreesistenza-T${num}_${this.props.IdInternoSinistro}`}></h4></th>
                    </tr>
                </table>
                <table className='perizia-table preesistenza-table partita-pra' >
                    <tr>
                        <th style={{ display: 'none' }}><DropDownListComponent readonly={true} id={`nomePartita${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsPartite}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Partita" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ display: 'none' }}><TextBoxComponent readonly={true} id={`descrizionePartita${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' placeholder='Descrizione' cssClass='e-outline'></TextBoxComponent></th>
                        <th style={{ display: 'none' }}><DropDownListComponent readonly={true} id={`formaGaranzia${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsFormaGaranzia}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Forma Garanzia" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                    </tr>
                </table>
                <table className='perizia-table preesistenza-table partita-pra' >
                    <tr >
                        <th ><h5 style={{ color: '#e53e14' }}>La partita è prestata nella forma VALORE A NUOVO - NO PREESISTENZA</h5></th>
                    </tr>
                </table>
            </>}</CallbackWrapper>);
        });
    }
    aggiungiRigaCostruzionePreesistenza(numPart, retDataC, tabFocus) {
        return new Promise((resolve, reject) => {
            var num = this.numRighePreesCostruzioni;
            this.numRighePreesCostruzioni++;
            const DivContainer = document.getElementById(`contentRighePreesCostruzioni-T${numPart}_${this.props.IdInternoSinistro}`);
            const newTr = document.createElement("tr");
            newTr.setAttribute("id", `rigaPreesistenzaCostruzione-T${numPart}-R-${num}_${this.props.IdInternoSinistro}`);
            DivContainer.append(newTr);

            const container = document.getElementById(`rigaPreesistenzaCostruzione-T${numPart}-R-${num}_${this.props.IdInternoSinistro}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {

                if (!CustomJS.isNullEmpty(retDataC)) {
                    document.getElementById(`valoreUnitarioInput_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataC.valore_unitario
                    document.getElementById(`superficieEquivalenteInput_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataC.superficie_equivalente
                    document.getElementById(`altezzaInput_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataC.altezza
                    document.getElementById(`volumeResult_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataC.volume
                    document.getElementById(`valoreRicostruzioneResult_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataC.valore_ricostruz_nuovo

                    var objStrutturaStatiCostruzioni = {
                        stato: 'o',
                        stato_iniziale: 'o',
                        keyJftech: retDataC.id_preesistenza_costruzione,
                        keyGen: retDataC.id_preesistenza_gen,
                        id: `rigaPreesistenzaCostruzione-T${numPart}-R-${num}_${this.props.IdInternoSinistro}`,
                        r: num
                    };
                    this.strutturaStatiPreesistenzeCostruzioni.push(objStrutturaStatiCostruzioni)

                    resolve()
                }
                else {
                    //Gestione struttura stati costruzioni tabella preesistenza fabbricato
                    //La struttura delle costruzioni è la struttura PRINCIPALE per l' inserimento dei record nella GENERALI_partita_preesistenze
                    var objStrutturaStatiCostruzioni = {
                        stato: 'n',
                        stato_iniziale: 'n',
                        keyJftech: null,
                        keyGen: null,
                        id: `rigaPreesistenzaCostruzione-T${numPart}-R-${num}_${this.props.IdInternoSinistro}`,
                        r: num
                    };
                    this.strutturaStatiPreesistenzeCostruzioni.push(objStrutturaStatiCostruzioni)

                    if (tabFocus === true) {
                        setTimeout(() => {
                            document.getElementById(`superficieEquivalenteInput_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).focus()
                            resolve();
                        }, 100)
                    } else {
                        resolve();
                    }
                }

            }}>{<>
                <th> <NumericTextBoxComponent showSpinButton={false} id={`valoreUnitarioInput_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`} onBlur={this.calcolaPreesistenza.bind(this, numPart, num)} value={380} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                <th>
                    <th className='rimuovi-bordo' style={{ width: '60%' }}>
                        <NumericTextBoxComponent showSpinButton={false} id={`superficieEquivalenteInput_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`} placeholder='m²' onBlur={this.calcolaPreesistenza.bind(this, numPart, num)} floatLabelType='Auto' format="#.##" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                    </th>
                    <th className='rimuovi-bordo'>
                        <div className='scegliUmBox'>
                            <SwitchComponent change={this.cambiaMetroCuboOQuadro.bind(this, numPart, num)} />
                            <p className='scegliUmP' id={`cubiOQuadri_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`}> m²</p>
                        </div>
                    </th>
                </th>
                <th id={`boxAltezza_${numPart}_R_${num}_${this.props.IdInternoSinistro}`}><NumericTextBoxComponent showSpinButton={false} id={`altezzaInput_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`} placeholder='m' onBlur={this.calcolaPreesistenza.bind(this, numPart, num)} floatLabelType='Auto' format="#.##" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                <th id={`boxVolume_${numPart}_R_${num}_${this.props.IdInternoSinistro}`}><NumericTextBoxComponent showSpinButton={false} readonly={true} id={`volumeResult_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`} placeholder='m³' floatLabelType='Auto' decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                <th> <NumericTextBoxComponent showSpinButton={false} style={{ width: '190px' }} readonly={true} id={`valoreRicostruzioneResult_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                <th>
                    <ButtonComponent style={{ marginRight: '15px' }} id={`btnEliminaRigaPreesCostruzione-T-${numPart}-R${num}_${this.props.IdInternoSinistro}`} onClick={this.eliminaRigaCostruzionePreesistenza.bind(this, `rigaPreesistenzaCostruzione-T${numPart}-R-${num}_${this.props.IdInternoSinistro}`, `contentRighePreesCostruzioni-T${numPart}_${this.props.IdInternoSinistro}`, numPart, num)} title='Elimina Riga' cssClass='e-fill e-danger nascondiPrimoBtnEliminaPrees' type='button' ><i class="fas fa-trash"></i></ButtonComponent>
                    <ButtonComponent id={`btnAggiungiRigaPartFabb-T${numPart}-R-${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaCostruzionePreesistenza.bind(this, numPart, '', true)}><i class="fas fa-plus"></i></ButtonComponent>
                </th>
            </>}</CallbackWrapper>);
        })
    }
    aggiungiRigaDescrizionePreesistenza(numPart, retDataD, elmEffettiDomestici) {
        return new Promise((resolve, reject) => {

            var num = this.numRighePreesDescrizioni;
            this.numRighePreesDescrizioni++;
            const DivContainer2 = document.getElementById(`contentRighePreesDescrizioni-T${numPart}_${this.props.IdInternoSinistro}`);
            const newTr2 = document.createElement("tr");
            newTr2.setAttribute("id", `rigaPreesDescrizione-T${numPart}-R-${num}_${this.props.IdInternoSinistro}`);
            DivContainer2.append(newTr2);

            const container = document.getElementById(`rigaPreesDescrizione-T${numPart}-R-${num}_${this.props.IdInternoSinistro}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                if (!CustomJS.isNullEmpty(retDataD)) {
                    document.getElementById(`descrizioneCorpo_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataD.descrizione;
                    document.getElementById(`valoreAltroACorpoResult_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataD.prezzo;
                    var objStrutturaStatiDescrizioni = {
                        stato: 'o',
                        stato_iniziale: 'o',
                        key: retDataD.id_preesistenza_descrizione,
                        id: `rigaPreesDescrizione-T${numPart}-R-${num}_${this.props.IdInternoSinistro}`,
                        r: num

                    };
                    this.strutturaStatiPreesistenzeDescrizioni.push(objStrutturaStatiDescrizioni)
                    resolve();
                }
                else {
                    if (!CustomJS.isNullEmpty(elmEffettiDomestici)) {
                        document.getElementById(`descrizioneCorpo_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = elmEffettiDomestici;
                    }
                    else
                        document.getElementById(`descrizioneCorpo_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = "Altro a corpo (eventuali recinzioni, autorimesse, box, cancelli, ecc…) ";
                    //Gestione struttura stati descrizioni tabella preesistenza fabbricato/effetti domestici/restanti
                    //La struttura delle descrizioni NON contiene dati per la GENERALI_partita_preesistenze
                    var objStrutturaStatiDescrizioni = {
                        stato: 'n',
                        stato_iniziale: 'n',
                        key: null,
                        id: `rigaPreesDescrizione-T${numPart}-R-${num}_${this.props.IdInternoSinistro}`,
                        r: num
                    };
                    this.strutturaStatiPreesistenzeDescrizioni.push(objStrutturaStatiDescrizioni)
                    resolve();
                }
            }}>{<>
                <td style={{ width: '1000px' }}><TextBoxComponent id={`descrizioneCorpo_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' placeholder='Descrizione' cssClass='e-outline'></TextBoxComponent></td>
                <th style={{ width: '250px' }}> <NumericTextBoxComponent value={0} showSpinButton={false} id={`valoreAltroACorpoResult_T${numPart}_R_${num}_${this.props.IdInternoSinistro}`} onBlur={this.calcolaPreesistenza.bind(this, numPart, 'noRiga')} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                <th >
                    <ButtonComponent style={{ marginRight: '15px' }} id={`btnEiminaRigaPreesDescr-T${numPart}-R-${num}_${this.props.IdInternoSinistro}`} onClick={this.eliminaRigaDescrizionePreesistenza.bind(this, `rigaPreesDescrizione-T${numPart}-R-${num}_${this.props.IdInternoSinistro}`, `contentRighePreesDescrizioni-T${numPart}_${this.props.IdInternoSinistro}`, numPart, num)} title='Elimina Riga' cssClass='e-fill e-danger nascondiPrimoBtnEliminaPrees' type='button' ><i class="fa fa-trash"></i></ButtonComponent>
                    <ButtonComponent id={`btnAggiungiRigaPreesDescr-T${numPart}-R-${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaDescrizionePreesistenza.bind(this, numPart, '', '')}><i class="fa fa-plus"></i></ButtonComponent>
                </th>
            </>}</CallbackWrapper>);
        });
    }
    eliminaRigaCostruzionePreesistenza(idContainerRiga, idContainerGenitore, numTab, numRiga, args) {
        delete this.formOptions.rules[`valoreUnitarioInput_T${numTab}_R_${numRiga}_${this.props.IdInternoSinistro}`];
        delete this.formOptions.rules[`superficieEquivalenteInput_T${numTab}_R_${numRiga}_${this.props.IdInternoSinistro}`];
        delete this.formOptions.rules[`altezzaInput_T${numTab}_R_${numRiga}_${this.props.IdInternoSinistro}`];

        let containerDaElimin = document.getElementById(idContainerRiga);
        let containerGenitore = document.getElementById(idContainerGenitore);
        containerGenitore.removeChild(containerDaElimin);
        this.calcolaPreesistenza(numTab, 'noRiga');

        this.strutturaStatiPreesistenzeCostruzioni.forEach(elm => {
            if (elm.id === idContainerRiga)
                elm.stato = 'd';
        });
    }

    eliminaRigaDescrizionePreesistenza(idContainerRiga, idContainerGenitore, numTab, numRiga, args) {
        delete this.formOptions.rules[`descrizioneCorpo_T${numTab}_R_${numRiga}_${this.props.IdInternoSinistro}`];
        delete this.formOptions.rules[`valoreAltroACorpoResult_T${numTab}_R_${numRiga}_${this.props.IdInternoSinistro}`];

        let containerDaElimin = document.getElementById(idContainerRiga);
        let containerGenitore = document.getElementById(idContainerGenitore);
        containerGenitore.removeChild(containerDaElimin);
        this.calcolaPreesistenza(numTab, 'noRiga');
        this.strutturaStatiPreesistenzeDescrizioni.forEach(elm => {
            if (elm.id === idContainerRiga)
                elm.stato = 'd';
        });
    }
    //---------------------------PREESISTENZA TABELLE FINE-----------------

    //---------------------------STIMA TABELLE INIZIO-----------------
    tabellaStimaValoreANuovo(idStima, idDanno, idDanneggiato, idPartita, descrPartita, idGaranzia, idDeterminazioneDanno, limitePer, franchigiaOScoperto, retDataStimeS, numS) {
        return new Promise((resolve, reject) => {
            var dataSourceStima = [{ TEXT: idStima, VALUE: idStima }];
            var num = this.numStime;
            this.numStime++;

            const DivContainer = document.getElementById(`tabellaCompletaTutteLeStime_${this.props.IdInternoSinistro}`);
            const newDiv = document.createElement("div");
            newDiv.setAttribute("id", `tabellaStima-D${num}_${this.props.IdInternoSinistro}`);
            DivContainer.append(newDiv);

            const container = document.getElementById(`tabellaStima-D${num}_${this.props.IdInternoSinistro}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                document.getElementById(`danneggiatoFromSin-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idDanneggiato;
                document.getElementById(`partitaFromSin-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idPartita;
                document.getElementById(`garanziaFromSin-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idGaranzia;
                document.getElementById(`determinazioneDanno_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idDeterminazioneDanno;
                document.getElementById(`limitePer_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = limitePer;
                document.getElementById(`isFranchigiaOrScoperto-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = franchigiaOScoperto;

                var objStrutturaStatiStima = {
                    id_incarico: this.props.IdIncarico,
                    keyJftech: idStima,
                    keyGen: idDanno,
                    id: `tabellaStima-D${num}_${this.props.IdInternoSinistro}`,
                    d: num
                };
                this.strutturaStatiStimeBase.push(objStrutturaStatiStima);

                if (retDataStimeS.id_determinazioneDanno === '002' || retDataStimeS.id_determinazioneDanno === '003'
                    || retDataStimeS.id_determinazioneDanno === '004' || retDataStimeS.id_determinazioneDanno === '005'
                    || retDataStimeS.id_determinazioneDanno === '006') {
                    var franchigia = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    franchigia = CustomJS.isNullEmpty(franchigia) ? 0 : franchigia;
                    document.getElementById(`franchigiaValoreANuovo_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = franchigia;
                }

                document.getElementById(`idStima-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idStima;
                setTimeout(() => {
                    document.getElementById(`idStima-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.KEYGEN = idDanno;
                    document.getElementById(`danneggiatoStimaNome-D${num}_${this.props.IdInternoSinistro}`).innerHTML = document.getElementById(`danneggiatoFromSin-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                    document.getElementById(`partitaStimaNome-D${num}_${this.props.IdInternoSinistro}`).innerHTML = document.getElementById(`partitaFromSin-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text + (CustomJS.isNullEmpty(descrPartita) ? '' : ` : ${descrPartita}`);
                    document.getElementById(`garanziaStimaNome-D${num}_${this.props.IdInternoSinistro}`).innerHTML = document.getElementById(`garanziaFromSin-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;

                    document.getElementById(`accordionCoassicurazione-D${num}_${this.props.IdInternoSinistro}`).style.display = 'none'
                    document.getElementById(`accordionCoassicurazione-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].expandItem(false, 0);
                    resolve();
                }, 150)
            }}>{<>
                <table className='perizia-table' style={{ marginTop: '2%' }} >
                    <tr style={{ display: 'none' }}>
                        <th><div style={{ width: '5%' }}>
                            <DropDownListComponent readonly={true} id={`idStima-D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={dataSourceStima}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="ID Stima" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent>
                        </div></th>
                    </tr>
                </table>
                <table className='perizia-table titoli-stima' >
                    <tr >
                        <th><h4 id={`danneggiatoStimaNome-D${num}_${this.props.IdInternoSinistro}`}></h4></th>
                        <th><h4 id={`partitaStimaNome-D${num}_${this.props.IdInternoSinistro}`}></h4></th>
                        <th><h4 id={`garanziaStimaNome-D${num}_${this.props.IdInternoSinistro}`}></h4></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <tr>
                        <th style={{ width: '16.65%', display: 'none' }}><DropDownListComponent readonly={true} id={`danneggiatoFromSin-D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsDanneggiati}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Danneggiato" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ width: '16.65%', display: 'none' }}><DropDownListComponent readonly={true} id={`partitaFromSin-D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsPartite}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Partita" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ width: '16.65%', display: 'none' }}><DropDownListComponent readonly={true} id={`garanziaFromSin-D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsGaranzie}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Garanzia" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>

                        <th style={{ width: '33.3%' }}><DropDownListComponent readonly={true} id={`determinazioneDanno_D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsDeterminazioneDanno}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Determinazione del Danno" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th ><DropDownListComponent readonly={true} id={`limitePer_D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsLimitePer}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Limite per" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>

                        <th ><NumericTextBoxComponent showSpinButton={false} placeholder='Limite Sinistro' onBlur={this.calcolaStimaValoreANuovo.bind(this, num, true)} id={`limiteSinistro_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ width: '33.3%' }}><div style={{ width: '33.3%' }}><NumericTextBoxComponent showSpinButton={false} placeholder='N° Piano' id={`piano-D${num}_${this.props.IdInternoSinistro}`} format='#' floatLabelType='Auto' cssClass='e-outline'></NumericTextBoxComponent></div></th>
                    </tr>
                </table>
                <table className='perizia-table'>
                    <tr>
                        {this.isCompagniaGenerali() ?
                            //GENERALI
                            <>
                                <th style={{ width: '66.6%' }}>Descrizione Prezziario</th>
                                <th style={{ width: '22.35%' }}>Categoria Prezziario</th>
                            </> :
                            //JFTECH
                            <>
                                <th style={{ width: '89%' }}>Descrizione Prezziario</th>
                            </>}
                        <th><ButtonComponent style={{ marginTop: '-3px', marginLeft: '5px' }} id={`btnAggiungiRigaStimaPrezziario-D${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaStimaPrezziario.bind(this, num, 'tabellaValoreANuovo', '', '')}><i class="fas fa-plus"></i></ButtonComponent></th>
                    </tr>
                </table>
                <div className='tabPrezziarioBoxPerOgniStima' id={`tabellePrezziarioContent-D${num}_${this.props.IdInternoSinistro}`}>
                </div>
                <table className='perizia-table'>
                    <tr >
                        <th style={{ width: '66.6%', textAlign: 'right' }}>Danno Complessivo Accertato :</th>
                        <th style={{ width: '22.35%' }}> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`dannoComplessAccert_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'right' }}>Dopo regola proporzionale :</th>
                        <th style={{ width: '22.35%' }}>
                            <div className='row'>
                                <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10">
                                    <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`dopoRegolaProporzionale_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                    <ButtonComponent style={{ color: 'red' }} id={`btnDopoRegolaMAN_D${num}_${this.props.IdInternoSinistro}`} title='Inserisce Valore Manuale' cssClass='e-fill' type='button' onClick={this.dopoRegolaManuale.bind(this, num)}><i class="fas fa-pen"></i></ButtonComponent>
                                </div>
                            </div>
                            <div id={`boxDopoRegolaManuale_D${num}_${this.props.IdInternoSinistro}`} style={{ display: 'none' }}>
                                Valore Manuale (Definitivo)
                                <NumericTextBoxComponent onBlur={this.calcolaStimaValoreANuovo.bind(this, num, false)} showSpinButton={false} id={`dopoRegolaProporzionaleMAN_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                    <tr style={{ display: 'none' }} id={`ceLimiteIndennizzoONo-D${num}_${this.props.IdInternoSinistro}`}>
                        <th style={{ textAlign: 'right' }}>Limite Indennizzo :</th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`limiteIndennizzo-D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <tr>
                        <th style={{ width: '66.6%', textAlign: 'right' }}>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '23%', display: this.isCompagniaGenerali() ? '' : 'none' }}>Accordo Ania :</th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent id={`accordoAniaSi-D${num}_${this.props.IdInternoSinistro}`} label="Si" name={`accordoAnia-D${num}_${this.props.IdInternoSinistro}`} /></th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent id={`accordoAniaNo-D${num}_${this.props.IdInternoSinistro}`} label="No" name={`accordoAnia-D${num}_${this.props.IdInternoSinistro}`} /></th>
                            <th className='rimuovi-bordo'></th>
                            <div style={{ marginTop: '-45px' }} id={`franchigiaOscopertura-D${num}_${this.props.IdInternoSinistro}`}></div>
                        </th>
                        <th style={{ width: '22.35%' }}>
                            <div className='row'>
                                <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10">
                                    <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`franchigia_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                    <ButtonComponent style={{ color: 'red' }} id={`btnFranchigiaManuale_D${num}_${this.props.IdInternoSinistro}`} title='Cambia Valore Franchigia' cssClass='e-fill' type='button' onClick={this.franchigiaManuale.bind(this, num)}><i class="fas fa-pen"></i></ButtonComponent>
                                </div>
                            </div>
                            <div id={`boxFranchigiaManuale_D${num}_${this.props.IdInternoSinistro}`} style={{ display: 'none' }}>
                                Valore Manuale (Definitivo)
                                <NumericTextBoxComponent onBlur={this.calcolaStimaValoreANuovo.bind(this, num, false)} showSpinButton={false} id={`franchigiaMAN_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                            </div>
                        </th>
                        <th style={{ visibility: 'hidden' }}>
                            <DropDownListComponent readonly={true} id={`isFranchigiaOrScoperto-D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsFranchigiaOrScoperto}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="Franchigia/Scoperto" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '23%', paddingBottom: '30px', display: this.isCompagniaGenerali() ? '' : 'none' }}>Ripristini Ultimati : </th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', paddingBottom: '30px', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent onClick={this.ripristiniSuInfoRich.bind(this, num, 'si')} id={`ripristiniUltimSi-D${num}_${this.props.IdInternoSinistro}`} label="Si" name={`ripristini-D${num}_${this.props.IdInternoSinistro}`} /></th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', paddingBottom: '30px', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent onClick={this.ripristiniSuInfoRich.bind(this, num, 'no')} id={`ripristiniUltimNo-D${num}_${this.props.IdInternoSinistro}`} label="No" name={`ripristini-D${num}_${this.props.IdInternoSinistro}`} /></th>
                            <th className='rimuovi-bordo'></th>
                            <div id={`coinvoltoLimIndannoNetto_D${num}_${this.props.IdInternoSinistro}`} style={{ textAlign: 'right', marginTop: '-50px' }}>Danno netto :</div>
                        </th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`dannoNetto_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th>
                            <th className='rimuovi-bordo' style={{ ttextAlign: 'left', width: '23%' }}>Coassicurazione :</th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%' }}><RadioButtonComponent onChange={this.coassicurazionePresenteBool.bind(this, num)} id={`coassicurazioneSi-D${num}_${this.props.IdInternoSinistro}`} label="Si" name={`coassicurazioneBool-D${num}_${this.props.IdInternoSinistro}`} /></th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%' }}><RadioButtonComponent onChange={this.coassicurazionePresenteBool.bind(this, num)} id={`coassicurazioneNo-D${num}_${this.props.IdInternoSinistro}`} label="No" name={`coassicurazioneBool-D${num}_${this.props.IdInternoSinistro}`} /></th>
                            <th className='rimuovi-bordo'></th>
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                </table>
                <table className='perizia-table' style={{ display: this.isCompagniaGenerali() ? '' : 'none' }} >
                    <tr>
                        <th style={{ width: '33.3%' }} className='rimuovi-bordo'>
                            <th className='rimuovi-bordo' style={{ textAlign: 'right', width: '23%' }}>Esito Danno :</th>
                            <th className='rimuovi-bordo' ><DropDownListComponent id={`esitoDanno_D${num}_${this.props.IdInternoSinistro}`} onChange={this.mostraCausali.bind(this, num)} fields={this.ddlFields} dataSource={this.dsEsitoDanno}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="Esito Danno" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent></th>
                        </th>
                        <th style={{ visibility: 'hidden' }} id={`boxCausaliNonIndennizzo_D${num}_${this.props.IdInternoSinistro}`}>
                            <div className='row' >
                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ marginTop: '12px' }}>Causali :</div>
                                <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9 text-start" >
                                    <div style={{ marginLeft: '-40px' }}>
                                        <DropDownListComponent style={{ marginLeft: '-15px' }} id={`causaliNonIndennizzo_D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsCausaliNonIndennizzo}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Causali" floatLabelType='always' cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th style={{ visibility: 'hidden' }} id={`boxDannoNettoMAN_D${num}_${this.props.IdInternoSinistro}`}>
                            <div className='row' >
                                <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5" style={{ marginTop: '12px' }}>Danno Netto (forzatura manuale) :</div>
                                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 text-start ms-4" >
                                    <div style={{ marginLeft: '-40px' }}>
                                        <NumericTextBoxComponent alt='Danno Netto (forzatura manuale)' showSpinButton={false} id={`dannoNettoMAN_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent >
                                    </div>
                                </div>
                            </div>
                        </th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <tr>
                        <th style={{ width: '100%' }}><TextBoxComponent id={`noteRipristini-D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' placeholder='Note Ripristini Ultimati' cssClass='e-outline'></TextBoxComponent></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <AccordionComponent id={`accordionCoassicurazione-D${num}_${this.props.IdInternoSinistro}`} ref={acrdn => this.acrdnCoassicurazione = acrdn}>
                        <AccordionItemsDirective >
                            <AccordionItemDirective expanded={true} content={this.contentCoassicurazione.bind(this, num, 'valoreANuovo')} cssClass='e-outline' header='<h5>Coassicurazione Indiretta</h5>' />
                        </AccordionItemsDirective>
                    </AccordionComponent>
                </table>
                <div style={{ textAlign: 'right', padding: '1%' }}>
                    <ButtonComponent style={{ marginLeft: '2%' }} id={`aggiornaTabellaStimaValoreANuovo-D${num}_${this.props.IdInternoSinistro}`} cssClass='e-fill e-info' type='button' onClick={this.aggiornaStimaValoreANuovo.bind(this, num)}>AGGIORNA STIMA (Valore a Nuovo)</ButtonComponent>
                </div>
            </>}</CallbackWrapper>);
        });
    }

    tabellaStimaStatoUso(idStima, idDanno, idDanneggiato, idPartita, descrPartita, idGaranzia, idDeterminazioneDanno, limitePer, franchigiaOScoperto, retDataStimeS, numS) {
        return new Promise((resolve, reject) => {
            var dataSourceStima = [{ TEXT: idStima, VALUE: idStima }];
            var num = this.numStime;
            this.numStime++;
            const DivContainer = document.getElementById(`tabellaCompletaTutteLeStime_${this.props.IdInternoSinistro}`);
            const newDiv = document.createElement("div");
            newDiv.setAttribute("id", `tabellaStima-D${num}_${this.props.IdInternoSinistro}`);
            DivContainer.append(newDiv);
            const container = document.getElementById(`tabellaStima-D${num}_${this.props.IdInternoSinistro}`)
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                document.getElementById(`danneggiatoFromSin-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idDanneggiato;
                document.getElementById(`partitaFromSin-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idPartita;
                document.getElementById(`garanziaFromSin-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idGaranzia;
                document.getElementById(`determinazioneDanno_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idDeterminazioneDanno;
                document.getElementById(`limitePer_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = limitePer;
                document.getElementById(`isFranchigiaOrScoperto-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = franchigiaOScoperto;
                var objStrutturaStatiStima = {
                    id_incarico: this.props.IdIncarico,
                    keyJftech: idStima,
                    keyGen: idDanno,
                    id: `tabellaStima-D${num}_${this.props.IdInternoSinistro}`,
                    d: num
                };
                this.strutturaStatiStimeBase.push(objStrutturaStatiStima);

                if (retDataStimeS.id_determinazioneDanno === '002' || retDataStimeS.id_determinazioneDanno === '003'
                    || retDataStimeS.id_determinazioneDanno === '004' || retDataStimeS.id_determinazioneDanno === '005'
                    || retDataStimeS.id_determinazioneDanno === '006') {
                    // var franchigia = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    // franchigia = CustomJS.isNullEmpty(franchigia) ? 0 : franchigia;
                    document.getElementById(`franchigiaValoreANuovo_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`franchigia_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                }

                document.getElementById(`idStima-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idStima;
                setTimeout(() => {
                    document.getElementById(`idStima-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.KEYGEN = idDanno;
                    document.getElementById(`danneggiatoStimaNome-D${num}_${this.props.IdInternoSinistro}`).innerHTML = document.getElementById(`danneggiatoFromSin-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                    document.getElementById(`partitaStimaNome-D${num}_${this.props.IdInternoSinistro}`).innerHTML = document.getElementById(`partitaFromSin-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text + (CustomJS.isNullEmpty(descrPartita) ? '' : ` : ${descrPartita}`);
                    document.getElementById(`garanziaStimaNome-D${num}_${this.props.IdInternoSinistro}`).innerHTML = document.getElementById(`garanziaFromSin-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;

                    document.getElementById(`accordionCoassicurazione-D${num}_${this.props.IdInternoSinistro}`).style.display = 'none'
                    document.getElementById(`accordionCoassicurazione-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].expandItem(false, 0);
                    resolve();
                }, 150)
            }}>{<>
                <table className='perizia-table' style={{ marginTop: '2%' }} >
                    <tr style={{ display: 'none' }}>
                        <th><div style={{ width: '5%' }}>
                            <DropDownListComponent readonly={true} id={`idStima-D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={dataSourceStima}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="ID Stima" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent>
                        </div></th>
                    </tr>
                </table>
                <table className='perizia-table titoli-stima' >
                    <tr >
                        <th><h4 id={`danneggiatoStimaNome-D${num}_${this.props.IdInternoSinistro}`}></h4></th>
                        <th><h4 id={`partitaStimaNome-D${num}_${this.props.IdInternoSinistro}`}></h4></th>
                        <th><h4 id={`garanziaStimaNome-D${num}_${this.props.IdInternoSinistro}`}></h4></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <tr>
                        <th style={{ width: '16.65%', display: 'none' }}><DropDownListComponent readonly={true} id={`danneggiatoFromSin-D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsDanneggiati}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Danneggiato" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ width: '16.65%', display: 'none' }}><DropDownListComponent readonly={true} id={`partitaFromSin-D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsPartite}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Partita" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ width: '16.65%', display: 'none' }}><DropDownListComponent readonly={true} id={`garanziaFromSin-D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsGaranzie}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Garanzia" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th style={{ width: '33.3%' }}><DropDownListComponent readonly={true} id={`determinazioneDanno_D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsDeterminazioneDanno}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Determinazione del Danno" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th><DropDownListComponent readonly={true} id={`limitePer_D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsLimitePer}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Limite per" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent></th>
                        <th><NumericTextBoxComponent placeholder='Limite Sinistro' showSpinButton={false} onBlur={this.calcolaStimaStatoUso.bind(this, num, true)} id={`limiteSinistro_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ width: '33.3%' }}><div style={{ width: '33.3%' }}><NumericTextBoxComponent showSpinButton={false} id={`piano-D${num}_${this.props.IdInternoSinistro}`} placeholder='N° Piano' floatLabelType='Auto' format='#' cssClass='e-outline'></NumericTextBoxComponent></div></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    {this.isCompagniaGenerali() ?
                        <><tr>
                            <th style={{ width: '89%' }}>Prezziario Descrizione</th>
                            <th><ButtonComponent id={`btnAggiungiRigaStimaPrezziario-D${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaStimaPrezziario.bind(this, num, "tabellaStatoUso", '', '')}><i class="fas fa-plus"></i></ButtonComponent></th>
                        </tr></>
                        :
                        <><tr>
                            <th style={{ width: '89%' }}>Prezziario Descrizione</th>
                            <th><ButtonComponent id={`btnAggiungiRigaStimaPrezziario-D${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaStimaPrezziario.bind(this, num, "tabellaStatoUso", '', '')}><i class="fas fa-plus"></i></ButtonComponent></th>
                        </tr></>}
                </table>
                <div className='tabPrezziarioBoxPerOgniStima' id={`tabellePrezziarioContent-D${num}_${this.props.IdInternoSinistro}`}>
                </div>
                <table className='perizia-table' >
                    <tr >
                        <th style={{ textAlign: 'right', width: '49.95%', }}></th>
                        <th >Valore a Nuovo</th>
                        <th >Degrado/Suppl.Indennità</th>
                        <th>Valore in Uso</th>
                    </tr>
                    <tr >
                        <th style={{ textAlign: 'right' }}>Danno Complessivo Accertato :</th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`valoreANuovoDannComplessAcc_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`degradoPercDannComplessAcc_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`valoreBeneDannComplessAcc_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    </tr>
                    <tr >
                        <th style={{ textAlign: 'right' }}>Dopo Regola Proporzionale :</th>
                        <th >
                            <div className='row'>
                                <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9">
                                    <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`valoreANuovoDopoRegolaProp_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                    <ButtonComponent style={{ color: 'red' }} id={`btnValANuovoDoporegolaMAN_D${num}_${this.props.IdInternoSinistro}`} title='Inserisce Valore Manuale' cssClass='e-fill' type='button' onClick={this.dopoRegolaManualeValANuovo.bind(this, num)}><i class="fas fa-pen"></i></ButtonComponent>
                                </div>
                            </div>
                            <div id={`boxValANuovoDopoRegola_D${num}_${this.props.IdInternoSinistro}`} style={{ display: 'none' }}>
                                Valore Manuale (Definitivo)
                                <NumericTextBoxComponent onBlur={this.calcolaStimaStatoUso.bind(this, num, false)} showSpinButton={false} id={`valoreANuovoDopoRegolaPropMAN_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                            </div>
                        </th>
                        <th >
                            <div className='row'>
                                <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9">
                                    <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`degrado25PercDopoRegolaProp_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                    <ButtonComponent style={{ color: 'red' }} id={`btnDegrado25PercDopoRegolaMAN_D${num}_${this.props.IdInternoSinistro}`} title='Inserisce Valore Manuale' cssClass='e-fill' type='button' onClick={this.dopoRegolaManualeDegrado.bind(this, num)}><i class="fas fa-pen"></i></ButtonComponent>
                                </div>
                            </div>
                            <div id={`boxDegrado25DopoRegola_D${num}_${this.props.IdInternoSinistro}`} style={{ display: 'none' }}>
                                Valore Manuale (Definitivo)
                                <NumericTextBoxComponent onBlur={this.calcolaStimaStatoUso.bind(this, num, false)} showSpinButton={false} id={`degrado25PercDopoRegolaPropMAN_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                            </div>
                        </th>
                        <th >
                            <div className='row'>
                                <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9">
                                    <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`dopoRegolaProporzionale_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                    <ButtonComponent style={{ color: 'red' }} id={`btnDopoRegolaMAN_D${num}_${this.props.IdInternoSinistro}`} title='Inserisce Valore Manuale' cssClass='e-fill' type='button' onClick={this.dopoRegolaManuale.bind(this, num)}><i class="fas fa-pen"></i></ButtonComponent>
                                </div>
                            </div>
                            <div id={`boxDopoRegolaManuale_D${num}_${this.props.IdInternoSinistro}`} style={{ display: 'none' }}>
                                Valore Manuale (Definitivo)
                                <NumericTextBoxComponent onBlur={this.calcolaStimaStatoUso.bind(this, num, false)} showSpinButton={false} id={`dopoRegolaProporzionaleMAN_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                            </div>
                        </th>
                    </tr>
                    <tr style={{ display: 'none' }} id={`ceLimiteIndennizzoONo-D${num}_${this.props.IdInternoSinistro}`} >
                        <th style={{ textAlign: 'right' }}>Limite Indennizzo :</th>
                        <th></th>
                        <th></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`limiteIndennizzo-D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <tr >
                        <th style={{ width: '49.95%', textAlign: 'right' }}>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '23%', display: this.isCompagniaGenerali() ? '' : 'none' }}>Accordo Ania :</th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent id={`accordoAniaSi-D${num}_${this.props.IdInternoSinistro}`} label="Si" name={`accordoAnia-D${num}_${this.props.IdInternoSinistro}`} /></th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent id={`accordoAniaNo-D${num}_${this.props.IdInternoSinistro}`} label="No" name={`accordoAnia-D${num}_${this.props.IdInternoSinistro}`} /></th>
                            <th className='rimuovi-bordo'></th>
                            <div style={{ marginTop: '-45px' }} id={`franchigiaOscopertura-D${num}_${this.props.IdInternoSinistro}`} ></div>
                        </th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`franchigiaValoreANuovo_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ visibility: 'hidden' }}>
                            <DropDownListComponent readonly={true} id={`isFranchigiaOrScoperto-D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsFranchigiaOrScoperto}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="Franchigia/Scoperto" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent>
                        </th>
                        <th>
                            <div className='row'>
                                <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9">
                                    <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`franchigia_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                    <ButtonComponent style={{ color: 'red' }} id={`btnFranchigiaManuale_D${num}_${this.props.IdInternoSinistro}`} title='Cambia Valore Franchigia' cssClass='e-fill' type='button' onClick={this.franchigiaManuale.bind(this, num)}><i class="fas fa-pen"></i></ButtonComponent>
                                </div>
                            </div>
                            <div id={`boxFranchigiaManuale_D${num}_${this.props.IdInternoSinistro}`} style={{ display: 'none' }}>
                                Valore Manuale (definitivo)
                                <NumericTextBoxComponent onBlur={this.calcolaStimaStatoUso.bind(this, num, false)} showSpinButton={false} id={`franchigiaMAN_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent>
                            </div>
                        </th>
                    </tr>
                    <tr >
                        <th style={{ width: '49.95%', textAlign: 'right' }}>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '23%', paddingBottom: '30px', display: this.isCompagniaGenerali() ? '' : 'none' }}>Ripristini Ultimati :</th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', paddingBottom: '30px', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent id={`ripristiniUltimSi-D${num}_${this.props.IdInternoSinistro}`} onClick={this.ripristiniSuInfoRich.bind(this, num, 'si')} label="Si" name={`ripristini-D${num}_${this.props.IdInternoSinistro}`} /></th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%', paddingBottom: '30px', display: this.isCompagniaGenerali() ? '' : 'none' }}><RadioButtonComponent id={`ripristiniUltimNo-D${num}_${this.props.IdInternoSinistro}`} onClick={this.ripristiniSuInfoRich.bind(this, num, 'no')} label="No" name={`ripristini-D${num}_${this.props.IdInternoSinistro}`} /></th>
                            <th className='rimuovi-bordo'></th>
                            <div style={{ marginTop: '-50px' }}> Danno Netto :</div>
                        </th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`valoreANuovodannoNetto_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`degrado25PercdannoNetto_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`valoreStatoUsodannoNetto_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    </tr>
                    <tr>
                        <th style={{ width: '49.95%', textAlign: 'right' }}>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '23%' }} >Coassicurazione :</th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%' }} ><RadioButtonComponent onChange={this.coassicurazionePresenteBool.bind(this, num)} id={`coassicurazioneSi-D${num}_${this.props.IdInternoSinistro}`} label="Si" name={`coassicurazioneBool-D${num}_${this.props.IdInternoSinistro}`} /></th>
                            <th className='rimuovi-bordo' style={{ textAlign: 'left', width: '10%' }}><RadioButtonComponent onChange={this.coassicurazionePresenteBool.bind(this, num)} id={`coassicurazioneNo-D${num}_${this.props.IdInternoSinistro}`} label="No" name={`coassicurazioneBool-D${num}_${this.props.IdInternoSinistro}`} /></th>
                            <th className='rimuovi-bordo'></th>
                        </th>
                        <th ></th>
                        <th ></th>
                        <th ></th>
                    </tr>
                </table>
                <table className='perizia-table' style={{ display: this.isCompagniaGenerali() ? '' : 'none' }} >
                    <tr>
                        <th style={{ width: '33.3%' }} className='rimuovi-bordo'>
                            <th className='rimuovi-bordo' style={{ textAlign: 'right', width: '23%' }}>Esito Danno :</th>
                            <th className='rimuovi-bordo' ><DropDownListComponent id={`esitoDanno_D${num}_${this.props.IdInternoSinistro}`} onChange={this.mostraCausali.bind(this, num)} fields={this.ddlFields} dataSource={this.dsEsitoDanno}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="Esito Danno" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent></th>
                        </th>
                        <th style={{ visibility: 'hidden' }} id={`boxCausaliNonIndennizzo_D${num}_${this.props.IdInternoSinistro}`}>
                            <div className='row' >
                                <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ marginTop: '12px' }}>Causali :</div>
                                <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9 text-start" >
                                    <div style={{ marginLeft: '-40px' }}>
                                        <DropDownListComponent id={`causaliNonIndennizzo_D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsCausaliNonIndennizzo}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            placeholder="Causali" floatLabelType='always' cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th style={{ visibility: 'hidden' }} id={`boxDannoNettoMAN_D${num}_${this.props.IdInternoSinistro}`}>
                            <div className='row' >
                                <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5" style={{ marginTop: '12px' }}>Danno Netto (forzatura manuale) :</div>
                                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 text-start ms-4" >
                                    <div style={{ marginLeft: '-40px' }}>
                                        <NumericTextBoxComponent alt='Danno Netto (forzatura manuale)' showSpinButton={false} id={`dannoNettoMAN_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent >
                                    </div>
                                </div>
                            </div>
                            <ul style={{ paddingTop: 2, paddingLeft: '1em', listStylePosition: 'inside' }}>
                                <li style={{ color: 'brown', fontSize: '15px', textAlign: 'left', lineHeight: 1.5 }}>
                                    In caso di esito danno PAGATO, utilizzare il campo soprastante, se necessario, per allineare il valore della stima danno a quello della liquidazione finale.
                                </li>
                                <li style={{ color: 'brown', fontSize: '15px', textAlign: 'left', lineHeight: 1.5 }}>
                                    In caso di qualsiasi altro esito danno, utilizzare il campo soprastante, se necessario, per indicare il valore reale della liquidizione, come se fosse presente.
                                </li>
                            </ul>                        
                        </th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <tr>
                        <th style={{ width: '100%' }}><TextBoxComponent id={`noteRipristini-D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' placeholder='Note Ripristini Ultimati' cssClass='e-outline'></TextBoxComponent></th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <AccordionComponent id={`accordionCoassicurazione-D${num}_${this.props.IdInternoSinistro}`} ref={acrdn => this.acrdnCoassicurazione = acrdn}>
                        <AccordionItemsDirective >
                            <AccordionItemDirective expanded={true} content={this.contentCoassicurazione.bind(this, num, 'statoUso')} cssClass='e-outline' header='<h5>Coassicurazione Indiretta</h5>' />
                        </AccordionItemsDirective>
                    </AccordionComponent>
                </table>
                <div style={{ textAlign: 'right', padding: '1%' }}>
                    <ButtonComponent style={{ marginLeft: '2%' }} id={`aggiornaTabellaStimaStatoUso-D${num}_${this.props.IdInternoSinistro}`} alt='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiornaStimaStatoUso.bind(this, num)}>AGGIORNA STIMA (Stato Uso)</ButtonComponent>
                </div>
            </>}</CallbackWrapper>);
        });
    }

    mostraCausali(num, args) {
        if (args.value === "4") {
            document.getElementById(`boxCausaliNonIndennizzo_D${num}_${this.props.IdInternoSinistro}`).style.visibility = 'visible';
        }
        else {
            document.getElementById(`boxCausaliNonIndennizzo_D${num}_${this.props.IdInternoSinistro}`).style.visibility = 'hidden';
            document.getElementById(`causaliNonIndennizzo_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = "";
        }
        if (args.value === "1" || args.value === "2" || args.value === "3" || args.value === "4") {
            document.getElementById(`boxDannoNettoMAN_D${num}_${this.props.IdInternoSinistro}`).style.visibility = 'visible';
        }
        else {
            document.getElementById(`boxDannoNettoMAN_D${num}_${this.props.IdInternoSinistro}`).style.visibility = 'hidden';
            document.getElementById(`dannoNettoMAN_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
        }
    }

    //---------------------------PREZZIARIO RIGHE INIZIO-----------------
    aggiungiRigaStimaPrezziario(numeroDanneggiato, tipoTabella, prezziarioDefault, retDataStimeDescrD) {
        return new Promise((resolve, reject) => {
            var num = this.numTabPrezziari;
            this.numTabPrezziari++;
            const DivContainer = document.getElementById(`tabellePrezziarioContent-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`);
            const newTable = document.createElement("table");
            newTable.setAttribute("class", `perizia-table content-table-prezziario`);
            newTable.setAttribute("id", `tabPrezziario-D${numeroDanneggiato}-R-${num}_${this.props.IdInternoSinistro}`);
            DivContainer.append(newTable);

            var garanzia = document.getElementById(`garanziaFromSin-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            var arrayLocalizzazione = [];
            if (this.isCompagniaGenerali()) {
                this.allArrayLocalizzazioneForeachGaranzia.forEach(elm => {
                    if (elm.garanzia === garanzia)
                        arrayLocalizzazione = elm.array;
                });
            }
            if (tipoTabella === 'tabellaStatoUso') {
                const container = document.getElementById(`tabPrezziario-D${numeroDanneggiato}-R-${num}_${this.props.IdInternoSinistro}`)
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    if (!CustomJS.isNullEmpty(retDataStimeDescrD)) {
                        setTimeout(() => {
                            var presente = false;
                            document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource.forEach(elm => {
                                if (elm.VALUE === retDataStimeDescrD.descrizione_lunga_prezziario) {
                                    presente = true;
                                    elm.TEXT = retDataStimeDescrD.descrizione_cambiata;
                                }
                            })
                            if (presente === false) {
                                document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource.push({
                                    TEXT: retDataStimeDescrD.descrizione_cambiata,
                                    VALUE: retDataStimeDescrD.descrizione_lunga_prezziario
                                })
                            }
                            setTimeout(() => {
                                document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.descrizione_lunga_prezziario;
                            }, 150)
                            if (!CustomJS.isNullEmpty(retDataStimeDescrD.fotografia_documento)) {
                                let nomeElm = `fotografiaDocumentoStima_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`;
                                let nomeElm2 = `pathFotografiaDocumentoStima_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`;
                                let nomeElm3 = `pathFotografiaDocumentoStimaBackup_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`;
                                let nomeButtVediDocStima = `vediFotografiaDocumentoStima-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`;
                                let nomeButtRimuoviDocStima = `rimuoviFotografiaDocumentoStima-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`;
                                let fileName = retDataStimeDescrD.fotografia_documento.split('[|]')[0].substring(retDataStimeDescrD.fotografia_documento.split('[|]')[0].lastIndexOf('/')+1);
                                $(`<div id=${nomeElm}></div>`).appendTo(`#divDocumentiStima-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`);
                                setTimeout(async () => {
                                    const container = document.getElementById(nomeElm);
                                    const root = createRoot(container);   
                                    const immagineVisual = await CustomJS.getImageBase64AC3Optimized(retDataStimeDescrD.fotografia_documento.split('[|]')[0], null);                      
                                    root.render(<div style={{ marginTop: '4px' }}><input type="hidden" id={nomeElm2} value={retDataStimeDescrD.fotografia_documento.split('[|]')[0]}></input><input type="hidden" id={nomeElm3} value={!CustomJS.isNullEmpty(immagineVisual.base64) ? '' : retDataStimeDescrD.fotografia_documento.split('[|]')[1]}></input><span style={{ fontSize: '0.7vw', fontWeight: '700' }}><ButtonComponent id={nomeButtVediDocStima} title='Visualizza Foto/Documento Stima' style={{ marginTop: '4px' }} cssClass='e-info e' iconCss='fas fa-eye fa-10x' onClick={this.visualizzaFotografiaDocumentoStima.bind(this, fileName, nomeElm2, nomeElm3)} type='button'></ButtonComponent><ButtonComponent id={nomeButtRimuoviDocStima} title='Rimuovi Foto/Documento Stima' style={{ marginTop: '4px' }} cssClass='e-info' iconCss='fas fa-times fa-10x' onClick={this.rimuoviFotografiaDocumentoStima.bind(this, nomeElm)} type='button'></ButtonComponent></span></div>);                     
                                }, 200);                        
                            }
                            if (this.isCompagniaGenerali()) {
                                document.getElementById(`categoriaSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.categoria_prezziario;//
                            }
                        }, 100)

                        document.getElementById(`UMInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.unita_misura;
                        document.getElementById(`quantitaInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.quantita;
                        document.getElementById(`valUnitarioInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.valore_unitario
                        if (this.isCompagniaGenerali())
                            document.getElementById(`localizzazione_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.localizzazione;
                        else
                            document.getElementById(`beneDanneggiato_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.localizzazione;

                        document.getElementById(`valANuovoInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.totale;
                        document.getElementById(`percDegrSupInd_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.supp_indenn_perc;
                        document.getElementById(`importoDegrSupInd_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.supp_indenn_importo;
                        document.getElementById(`valoreBeneResult_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.valore_del_bene;

                        var objStrutturaStatiPrezziario = {
                            stato: 'o',
                            stato_iniziale: 'o',
                            keyJftech: retDataStimeDescrD.id_stima_descrizione,
                            keyGen: retDataStimeDescrD.id_dettaglio,
                            keyGenInfoDett: retDataStimeDescrD.id_info_richiesta,
                            id: `tabPrezziario-D${numeroDanneggiato}-R-${num}_${this.props.IdInternoSinistro}`,
                            r: num
                        };
                        this.strutturaStatiStimePrezziari.push(objStrutturaStatiPrezziario);

                        setTimeout(() => {
                            resolve();
                        }, 100)
                    }
                    else {
                        var objStrutturaStatiPrezziario = {
                            stato: 'n',
                            stato_iniziale: 'n',
                            keyJftech: null,
                            keyGen: null,
                            keyGenInfoDett: null,
                            id: `tabPrezziario-D${numeroDanneggiato}-R-${num}_${this.props.IdInternoSinistro}`,
                            r: num
                        };
                        this.strutturaStatiStimePrezziari.push(objStrutturaStatiPrezziario);

                        if (this.isCompagniaGenerali()) {
                            //localizzazione sulle nuove righe
                            var firstChild = document.getElementById(`tabellePrezziarioContent-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).firstChild.id;
                            var numFirstChild = firstChild.substring(firstChild.indexOf("R-") + 2).replace(`_${this.props.IdInternoSinistro}`, '');
                            document.getElementById(`localizzazione_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`localizzazione_D${numeroDanneggiato}_R${numFirstChild}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                        }
                        else {
                            var firstChild = document.getElementById(`tabellePrezziarioContent-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).firstChild.id;
                            var numFirstChild = firstChild.substring(firstChild.indexOf("R-") + 2).replace(`_${this.props.IdInternoSinistro}`, '');
                            document.getElementById(`beneDanneggiato_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`beneDanneggiato_D${numeroDanneggiato}_R${numFirstChild}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                        }

                        if (!CustomJS.isNullEmpty(prezziarioDefault)) {
                            document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = prezziarioDefault;
                            var codEleCategoria = '';
                            var um = '';
                            var valoreUnitario = 0;

                            let obj = _.find(this.dsPrezziarioCompleto, elm => {
                                return elm.cod_ele === prezziarioDefault
                            })
                            if (!CustomJS.isNullEmpty(obj)) {
                                codEleCategoria = obj.COD_CATEGORIA;
                                um = obj.MISURA;
                                valoreUnitario = obj.PREZZO;
                            }

                            if (this.isCompagniaGenerali())
                                document.getElementById(`categoriaSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = codEleCategoria;
                            document.getElementById(`UMInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = um;
                            document.getElementById(`valUnitarioInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = valoreUnitario;
                            setTimeout(() => {
                                resolve();
                            }, 100)
                        }
                        else {
                            resolve();
                        }
                    }

                }}>{<>
                    {/* TABELLA STATO USO */}
                    {this.isCompagniaGenerali() ?
                        //RIGA PREZZIARIO GENERALI STATO USO
                        <>
                            <tr>
                                <th style={{ width: '89%' }}>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent style={{ marginTop: '15px' }} id={`btnCambiaDescriz-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} onClick={this.cambiaDescrizione.bind(this, numeroDanneggiato, num)} title='Aggiungi/Modifica Descrizione' cssClass='e-fill e-warning' type='button'><i class="fas fa-marker"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent style={{ marginTop: '15px' }} id={`btnApriPrezziario-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} onClick={this.apriDialogPrezziario.bind(this, numeroDanneggiato, num)} title='Apri Prezziario' cssClass='e-fill e-warning' type='button'><i class="fas fa-clipboard"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentDescrizioneBase-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} style={{ width: '98%' }}>
                                        Descrizione<DropDownListComponent placeholder="Descrizione Prezziario"
                                            onChange={this.trasportaDatiPrezziarioAggiuntivi.bind(this, numeroDanneggiato, num)}
                                            dataSource={this.dsPrezziarioDescrizioniLunghe} id={`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`}
                                            fields={this.ddlFields} allowFiltering={true} filterType='Contains' showClearButton={true} floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </th>
                                    <th id={`divDocumentiStima-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent id={`btnAggiungiFoto-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Foto' onClick={this.btnAggiungiFoto.bind(this, numeroDanneggiato, num)} iconCss='fas fa-camera fa-10x' cssClass='e-info' type='button'></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentNuovaDescrizione-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} style={{ width: '98%', display: 'none' }}><TextBoxComponent onBlur={() => { document.getElementById(`btnCambiaDescriz-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`).click() }} placeholder="Aggiungi/Modifica Descrizione" id={`nuovaDescrizioneLibera-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' cssClass='e-outline'></TextBoxComponent></th>
                                </th>
                                <th >
                                    <ButtonComponent style={{ marginRight: '15px' }} id={`btnEliminaRigaStimaPrezziario-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} title='Elimina Riga' cssClass='e-fill e-danger nascondiPrimoBtnEliminaStime' type='button' onClick={this.eliminaRigaStimaPrezziario.bind(this, `tabPrezziario-D${numeroDanneggiato}-R-${num}_${this.props.IdInternoSinistro}`, `tabellePrezziarioContent-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`, numeroDanneggiato, num, 'tabellaStatoUso')}><i class="fas fa-trash"></i></ButtonComponent>
                                    <ButtonComponent id={`btnAggiungiRigaStimaPrezziario-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaStimaPrezziario.bind(this, numeroDanneggiato, 'tabellaStatoUso', '', '')}><i class="fas fa-plus"></i></ButtonComponent>
                                </th>
                            </tr>
                            <tr>
                                <th className='rimuovi-bordo'>
                                    <th style={{ width: '20%' }} className='rimuovi-bordo'>
                                        Categoria<DropDownListComponent placeholder="Categoria Prezziario" dataSource={this.dsPrezziarioCategorie} id={`categoriaSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </th>
                                    <th style={{ width: '20%' }} className='rimuovi-bordo'>
                                        <div>Bene danneggiato<DropDownListComponent onChange={this.localizzazioneSuInfoRich.bind(this, numeroDanneggiato, num)} placeholder="Bene danneggiato" dataSource={arrayLocalizzazione} id={`localizzazione_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent></div>
                                    </th>
                                    <th className='rimuovi-bordo'>U.M.<DropDownListComponent placeholder="U.M." id={`UMInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsUM}
                                        allowFiltering={true} filterType='Contains' showClearButton={true}
                                        floatLabelType='always' popupHeight="250px" cssClass='e-outline unita-misura'>
                                    </DropDownListComponent></th>
                                    <th className='rimuovi-bordo'>Quantità<NumericTextBoxComponent value={1} showSpinButton={false} placeholder="N°" onBlur={this.calcolaRigaStimaStatoUso.bind(this, numeroDanneggiato, num, false, document.getElementById(`determinazioneDanno_D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)} id={`quantitaInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} format='#.##' floatLabelType='Auto' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo' >Valore Unitario<NumericTextBoxComponent value={0} showSpinButton={false} onBlur={this.calcolaRigaStimaStatoUso.bind(this, numeroDanneggiato, num, false, document.getElementById(`determinazioneDanno_D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)} id={`valUnitarioInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'>Valore a Nuovo<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`valANuovoInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'>% Degrado S.I.<NumericTextBoxComponent value={0} showSpinButton={false} onBlur={this.calcolaRigaStimaStatoUso.bind(this, numeroDanneggiato, num, false, document.getElementById(`determinazioneDanno_D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)} id={`percDegrSupInd_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="#.## '%'" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'><div style={{ marginTop: '6px' }}>Importo S.I<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`importoDegrSupInd_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent><div style={{ color: '#b0402a' }} id={`ripristinato-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`}></div></div></th>
                                </th>
                                <th className='rimuovi-bordo'>
                                    <th className='rimuovi-bordo'>Totale<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`valoreBeneResult_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                </th>
                            </tr>
                        </> :
                        //RIGA PREZZIARIO JFTECH STATO USO
                        <>
                            <tr>
                                <th style={{ width: '89%' }}>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent style={{ marginTop: '15px' }} id={`btnCambiaDescriz-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} onClick={this.cambiaDescrizione.bind(this, numeroDanneggiato, num)} title='Aggiungi/Modifica Descrizione' cssClass='e-fill e-warning' type='button'><i class="fas fa-marker"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent style={{ marginTop: '15px' }} id={`btnApriPrezziario-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} onClick={this.apriDialogPrezziario.bind(this, numeroDanneggiato, num)} title='Apri Prezziario' cssClass='e-fill e-warning' type='button'><i class="fas fa-clipboard"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentDescrizioneBase-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} style={{ width: '98%' }}>
                                        Descrizione<DropDownListComponent placeholder="Descrizione Prezziario"
                                            onChange={this.trasportaDatiPrezziarioAggiuntivi.bind(this, numeroDanneggiato, num)}
                                            dataSource={this.dsPrezziarioDescrizioniLunghe} id={`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`}
                                            fields={this.ddlFields} allowFiltering={true} filterType='Contains' showClearButton={true} floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </th>
                                    <th id={`divDocumentiStima-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent id={`btnAggiungiFoto-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Foto' onClick={this.btnAggiungiFoto.bind(this, numeroDanneggiato, num)} iconCss='fas fa-camera fa-10x' cssClass='e-info' type='button'></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentNuovaDescrizione-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} style={{ width: '98%', display: 'none' }}><TextBoxComponent onBlur={() => { document.getElementById(`btnCambiaDescriz-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`).click() }} placeholder="Aggiungi/Modifica Descrizione" id={`nuovaDescrizioneLibera-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' cssClass='e-outline'></TextBoxComponent></th>
                                </th>
                                <th >
                                    <ButtonComponent style={{ marginRight: '15px' }} id={`btnEliminaRigaStimaPrezziario-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} title='Elimina Riga' cssClass='e-fill e-danger nascondiPrimoBtnEliminaStime' type='button' onClick={this.eliminaRigaStimaPrezziario.bind(this, `tabPrezziario-D${numeroDanneggiato}-R-${num}_${this.props.IdInternoSinistro}`, `tabellePrezziarioContent-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`, numeroDanneggiato, num, 'tabellaStatoUso')}><i class="fas fa-trash"></i></ButtonComponent>
                                    <ButtonComponent id={`btnAggiungiRigaStimaPrezziario-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaStimaPrezziario.bind(this, numeroDanneggiato, 'tabellaStatoUso', '', '')}><i class="fas fa-plus"></i></ButtonComponent>
                                </th>
                            </tr>
                            <tr>
                                <th className='rimuovi-bordo' style={{ width: '89%' }}>
                                    <th style={{ width: '30%' }} className='rimuovi-bordo'>
                                        <div>Bene danneggiato<DropDownListComponent placeholder="Bene danneggiato" dataSource={this.dsBeniDanneggiati} id={`beneDanneggiato_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent></div>
                                    </th>
                                    <th className='rimuovi-bordo'>Unità di Misura<DropDownListComponent placeholder="U.M." id={`UMInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsUM}
                                        allowFiltering={true} filterType='Contains' showClearButton={true}
                                        floatLabelType='always' popupHeight="250px" cssClass='e-outline unita-misura'>
                                    </DropDownListComponent></th>
                                    <th className='rimuovi-bordo'>Quantità<NumericTextBoxComponent value={1} showSpinButton={false} placeholder="N°" onBlur={this.calcolaRigaStimaStatoUso.bind(this, numeroDanneggiato, num, false, document.getElementById(`determinazioneDanno_D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)} id={`quantitaInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} format='#.##' floatLabelType='Auto' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo' >Valore Unitario<NumericTextBoxComponent value={0} showSpinButton={false} onBlur={this.calcolaRigaStimaStatoUso.bind(this, numeroDanneggiato, num, false, document.getElementById(`determinazioneDanno_D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)} id={`valUnitarioInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'>Valore a Nuovo<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`valANuovoInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'>% Degrado Supp.Ind.<NumericTextBoxComponent value={0} showSpinButton={false} onBlur={this.calcolaRigaStimaStatoUso.bind(this, numeroDanneggiato, num, false, document.getElementById(`determinazioneDanno_D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)} id={`percDegrSupInd_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="#.## '%'" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'>Importo S.I<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`importoDegrSupInd_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent><div style={{ color: '#b0402a' }} id={`ripristinato-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`}></div></th>
                                    <th className='rimuovi-bordo'>Totale<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`valoreBeneResult_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                </th>
                                <th className='rimuovi-bordo'>
                                </th>
                            </tr>
                        </>
                    }
                </>}</CallbackWrapper>);
            }
            else if (tipoTabella === 'tabellaValoreANuovo') {
                const container = document.getElementById(`tabPrezziario-D${numeroDanneggiato}-R-${num}_${this.props.IdInternoSinistro}`)
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    if (!CustomJS.isNullEmpty(retDataStimeDescrD)) {
                        var presente = false;
                        document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource.forEach(elm => {
                            if (elm.VALUE === retDataStimeDescrD.descrizione_lunga_prezziario) {
                                presente = true;
                                elm.TEXT = retDataStimeDescrD.descrizione_cambiata;
                            }
                        })
                        if (presente === false) {
                            document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource.push({
                                TEXT: retDataStimeDescrD.descrizione_cambiata,
                                VALUE: retDataStimeDescrD.descrizione_lunga_prezziario
                            })
                        }
                        setTimeout(() => {
                            document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.descrizione_lunga_prezziario;
                        }, 150);
                        if (!CustomJS.isNullEmpty(retDataStimeDescrD.fotografia_documento)) {
                            let nomeElm = `fotografiaDocumentoStima_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`;
                            let nomeElm2 = `pathFotografiaDocumentoStima_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`;
                            let nomeElm3 = `pathFotografiaDocumentoStimaBackup_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`;
                            let nomeButtVediDocStima = `vediFotografiaDocumentoStima-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`;
                            let nomeButtRimuoviDocStima = `rimuoviFotografiaDocumentoStima-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`;
                            let fileName = retDataStimeDescrD.fotografia_documento.split('[|]')[0].substring(retDataStimeDescrD.fotografia_documento.split('[|]')[0].lastIndexOf('/')+1);
                            $(`<div id=${nomeElm}></div>`).appendTo(`#divDocumentiStima-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`);
                            setTimeout(async () => {
                                const container = document.getElementById(nomeElm);
                                const root = createRoot(container);       
                                const immagineVisual = await CustomJS.getImageBase64AC3Optimized(retDataStimeDescrD.fotografia_documento.split('[|]')[0], null);    
                                root.render(<div style={{ marginTop: '4px' }}><input type="hidden" id={nomeElm2} value={retDataStimeDescrD.fotografia_documento.split('[|]')[0]}></input><input type="hidden" id={nomeElm3} value={!CustomJS.isNullEmpty(immagineVisual.base64) ? '' : retDataStimeDescrD.fotografia_documento.split('[|]')[1]}></input><span style={{ fontSize: '0.7vw', fontWeight: '700' }}><ButtonComponent id={nomeButtVediDocStima} title='Visualizza Foto/Documento Stima' style={{ marginTop: '4px' }} cssClass='e-info e' iconCss='fas fa-eye fa-10x' onClick={this.visualizzaFotografiaDocumentoStima.bind(this, fileName, nomeElm2, nomeElm3)} type='button'></ButtonComponent><ButtonComponent id={nomeButtRimuoviDocStima} title='Rimuovi Foto/Documento Stima' style={{ marginTop: '4px' }} cssClass='e-info' iconCss='fas fa-times fa-10x' onClick={this.rimuoviFotografiaDocumentoStima.bind(this, nomeElm)} type='button'></ButtonComponent></span></div>);                     
                            }, 200);                        
                        }
                        if (this.isCompagniaGenerali()) {
                            document.getElementById(`categoriaSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.categoria_prezziario;//
                        }
                        document.getElementById(`UMInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.unita_misura;
                        document.getElementById(`quantitaInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.quantita;
                        document.getElementById(`valUnitarioInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.valore_unitario
                        if (this.isCompagniaGenerali()) {
                            document.getElementById(`localizzazione_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.localizzazione;
                        }
                        else
                            document.getElementById(`beneDanneggiato_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.localizzazione;
                        
                        document.getElementById(`totaleResult_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataStimeDescrD.totale

                        var objStrutturaStatiPrezziario = {
                            stato: 'o',
                            stato_iniziale: 'o',
                            keyJftech: retDataStimeDescrD.id_stima_descrizione,
                            keyGen: retDataStimeDescrD.id_dettaglio,
                            keyGenInfoDett: retDataStimeDescrD.id_info_richiesta,
                            id: `tabPrezziario-D${numeroDanneggiato}-R-${num}_${this.props.IdInternoSinistro}`,
                            r: num
                        };
                        this.strutturaStatiStimePrezziari.push(objStrutturaStatiPrezziario)

                        setTimeout(() => {
                            resolve();
                        }, 100)
                    }
                    else {
                        var objStrutturaStatiPrezziario = {
                            stato: 'n',
                            stato_iniziale: 'n',
                            keyJftech: null,
                            keyGen: null,
                            keyGenInfoDett: null,
                            id: `tabPrezziario-D${numeroDanneggiato}-R-${num}_${this.props.IdInternoSinistro}`,
                            r: num
                        };
                        this.strutturaStatiStimePrezziari.push(objStrutturaStatiPrezziario)

                        if (this.isCompagniaGenerali()) {
                            //localizzazione sulle nuove righe
                            var firstChild = document.getElementById(`tabellePrezziarioContent-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).firstChild.id;
                            var numFirstChild = firstChild.substring(firstChild.indexOf("R-") + 2).replace(`_${this.props.IdInternoSinistro}`, '');
                            document.getElementById(`localizzazione_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`localizzazione_D${numeroDanneggiato}_R${numFirstChild}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                        }
                        else {
                            var firstChild = document.getElementById(`tabellePrezziarioContent-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).firstChild.id;
                            var numFirstChild = firstChild.substring(firstChild.indexOf("R-") + 2).replace(`_${this.props.IdInternoSinistro}`, '');
                            document.getElementById(`beneDanneggiato_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`beneDanneggiato_D${numeroDanneggiato}_R${numFirstChild}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                        }

                        if (!CustomJS.isNullEmpty(prezziarioDefault)) {
                            document.getElementById(`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = prezziarioDefault;
                            var codEleCategoria = '';
                            var um = '';
                            var valoreUnitario = 0;

                            let obj = _.find(this.dsPrezziarioCompleto, elm => {
                                return elm.cod_ele === prezziarioDefault
                            })
                            if (!CustomJS.isNullEmpty(obj)) {
                                codEleCategoria = obj.COD_CATEGORIA;
                                um = obj.MISURA;
                                valoreUnitario = obj.PREZZO;
                            }

                            if (this.isCompagniaGenerali())
                                document.getElementById(`categoriaSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = codEleCategoria;
                            document.getElementById(`UMInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = um;
                            document.getElementById(`valUnitarioInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = valoreUnitario;
                            setTimeout(() => {
                                resolve();
                            }, 100)
                        }
                        else {
                            resolve();
                        }
                    }
                }}>{<>
                    {/* TABELLA VALORE A NUOVO */}
                    {this.isCompagniaGenerali() ?
                        //RIGA PREZZIARIO GENERALI A NUOVO
                        <>
                            <tr id={`rigaPrezziarioScelta-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`}>
                                <th style={{ width: '89%' }}>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent id={`btnCambiaDescriz-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} onClick={this.cambiaDescrizione.bind(this, numeroDanneggiato, num)} title='Aggiungi/Modifica Descrizione' cssClass='e-fill e-warning' type='button'><i class="fas fa-marker"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent id={`btnApriPrezziario-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} onClick={this.apriDialogPrezziario.bind(this, numeroDanneggiato, num)} title='Apri Prezziario' cssClass='e-fill e-warning' type='button'><i class="fas fa-clipboard"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentDescrizioneBase-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} style={{ width: '98%' }}>
                                        Descrizione<DropDownListComponent placeholder="Descrizione Prezziario"
                                            onChange={this.trasportaDatiPrezziarioAggiuntivi.bind(this, numeroDanneggiato, num)}
                                            dataSource={this.dsPrezziarioDescrizioniLunghe} id={`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`}
                                            fields={this.ddlFields} allowFiltering={true} filterType='Contains' showClearButton={true} floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </th>
                                    <th id={`divDocumentiStima-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent id={`btnAggiungiFoto-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Foto' onClick={this.btnAggiungiFoto.bind(this, numeroDanneggiato, num)} iconCss='fas fa-camera fa-10x' cssClass='e-info' type='button'></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentNuovaDescrizione-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} style={{ width: '98%', display: 'none' }}><TextBoxComponent onBlur={() => { document.getElementById(`btnCambiaDescriz-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`).click() }} placeholder="Aggiungi/Modifica Descrizione" id={`nuovaDescrizioneLibera-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' cssClass='e-outline'></TextBoxComponent></th>
                                </th>
                                <th >
                                    <ButtonComponent id={`btnEliminaRigaStimaPrezziario-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} title='Elimina Riga' cssClass='e-fill e-danger nascondiPrimoBtnEliminaStime' type='button' onClick={this.eliminaRigaStimaPrezziario.bind(this, `tabPrezziario-D${numeroDanneggiato}-R-${num}_${this.props.IdInternoSinistro}`, `tabellePrezziarioContent-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`, numeroDanneggiato, num, 'tabellaValoreANuovo')}><i class="fas fa-trash"></i></ButtonComponent>
                                    <ButtonComponent style={{ marginLeft: '15px' }} id={`btnAggiungiRigaStimaPrezziario-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaStimaPrezziario.bind(this, numeroDanneggiato, 'tabellaValoreANuovo', '', '')}><i class="fas fa-plus"></i></ButtonComponent>
                                </th>
                            </tr>
                            <tr >
                                <th>
                                    <th className='rimuovi-bordo' style={{ width: '25%' }}>
                                        Categoria<DropDownListComponent placeholder="Categoria Prezziario" dataSource={this.dsPrezziarioCategorie} id={`categoriaSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </th>
                                    <th className='rimuovi-bordo' style={{ width: '25%' }}>Bene danneggiato<DropDownListComponent onChange={this.localizzazioneSuInfoRich.bind(this, numeroDanneggiato, num)} placeholder="Bene danneggiato" dataSource={arrayLocalizzazione} id={`localizzazione_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields}
                                        allowFiltering={true} filterType='Contains' showClearButton={true}
                                        floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                    </DropDownListComponent></th>
                                    <th className='rimuovi-bordo' >Unità di Misura<DropDownListComponent placeholder="U.M." id={`UMInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsUM}
                                        allowFiltering={true} filterType='Contains' showClearButton={true}
                                        floatLabelType='always' popupHeight="250px" cssClass='e-outline unita-misura'>
                                    </DropDownListComponent></th>
                                    <th className='rimuovi-bordo' >Quantità <NumericTextBoxComponent value={1} showSpinButton={false} onBlur={this.calcolaRigaStimaValoreANuovo.bind(this, numeroDanneggiato, num, false)} id={`quantitaInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} format='#.##' floatLabelType='Auto' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo' >Valore Unitario<NumericTextBoxComponent value={0} showSpinButton={false} id={`valUnitarioInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} onBlur={this.calcolaRigaStimaValoreANuovo.bind(this, numeroDanneggiato, num, false)} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'>Valore del Bene<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`totaleResult_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                </th>
                            </tr>
                        </> :
                        //RIGA PREZZIARIO JFTECH A NUOVO
                        <>
                            <tr>
                                <th style={{ width: '89%' }}>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent style={{ marginTop: '15px' }} id={`btnCambiaDescriz-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} onClick={this.cambiaDescrizione.bind(this, numeroDanneggiato, num)} title='Aggiungi/Modifica Descrizione' cssClass='e-fill e-warning' type='button'><i class="fas fa-marker"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent style={{ marginTop: '15px' }} id={`btnApriPrezziario-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} onClick={this.apriDialogPrezziario.bind(this, numeroDanneggiato, num)} title='Apri Prezziario' cssClass='e-fill e-warning' type='button'><i class="fas fa-clipboard"></i></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentDescrizioneBase-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} style={{ width: '98%' }}>
                                        Descrizione<DropDownListComponent placeholder="Descrizione Prezziario"
                                            onChange={this.trasportaDatiPrezziarioAggiuntivi.bind(this, numeroDanneggiato, num)}
                                            dataSource={this.dsPrezziarioDescrizioniLunghe} id={`descrizioneSceltaPrezziario_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`}
                                            fields={this.ddlFields} allowFiltering={true} filterType='Contains' showClearButton={true} floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent>
                                    </th>
                                    <th id={`divDocumentiStima-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} className='rimuovi-bordo' style={{ width: '2%' }}>
                                        <ButtonComponent id={`btnAggiungiFoto-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Foto' onClick={this.btnAggiungiFoto.bind(this, numeroDanneggiato, num)} iconCss='fas fa-camera fa-10x' cssClass='e-info' type='button'></ButtonComponent>
                                    </th>
                                    <th className='rimuovi-bordo' id={`contentNuovaDescrizione-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} style={{ width: '98%', display: 'none' }}><TextBoxComponent onBlur={() => { document.getElementById(`btnCambiaDescriz-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`).click() }} placeholder="Aggiungi/Modifica Descrizione" id={`nuovaDescrizioneLibera-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' cssClass='e-outline'></TextBoxComponent></th>
                                </th>
                                <th >
                                    <ButtonComponent id={`btnEliminaRigaStimaPrezziario-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} title='Elimina Riga' cssClass='e-fill e-danger nascondiPrimoBtnEliminaStime' type='button' onClick={this.eliminaRigaStimaPrezziario.bind(this, `tabPrezziario-D${numeroDanneggiato}-R-${num}_${this.props.IdInternoSinistro}`, `tabellePrezziarioContent-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`, numeroDanneggiato, num, 'tabellaValoreANuovo')}><i class="fas fa-trash"></i></ButtonComponent>
                                    <ButtonComponent style={{ marginLeft: '15px' }} id={`btnAggiungiRigaStimaPrezziario-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaStimaPrezziario.bind(this, numeroDanneggiato, 'tabellaValoreANuovo', '', '')}><i class="fas fa-plus"></i></ButtonComponent>
                                </th>
                            </tr>
                            <tr>
                                <th style={{ width: '89%' }} className='rimuovi-bordo'>
                                    <th style={{ width: '30%' }} className='rimuovi-bordo'>
                                        <div>Bene danneggiato<DropDownListComponent placeholder="Bene danneggiato" dataSource={this.dsBeniDanneggiati} id={`beneDanneggiato_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields}
                                            allowFiltering={true} filterType='Contains' showClearButton={true}
                                            floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                        </DropDownListComponent></div>
                                    </th>
                                    <th className='rimuovi-bordo' >Unità di Misura<DropDownListComponent placeholder="U.M." id={`UMInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsUM}
                                        allowFiltering={true} filterType='Contains' showClearButton={true}
                                        floatLabelType='always' popupHeight="250px" cssClass='e-outline unita-misura'>
                                    </DropDownListComponent></th>
                                    <th className='rimuovi-bordo' >Quantità<NumericTextBoxComponent value={1} showSpinButton={false} onBlur={this.calcolaRigaStimaValoreANuovo.bind(this, numeroDanneggiato, num, false)} id={`quantitaInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} format='#.##' floatLabelType='Auto' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo' >Valore Unitario<NumericTextBoxComponent value={0} showSpinButton={false} id={`valUnitarioInput_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} onBlur={this.calcolaRigaStimaValoreANuovo.bind(this, numeroDanneggiato, num, false)} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                    <th className='rimuovi-bordo'>Valore del Bene<NumericTextBoxComponent value={0} showSpinButton={false} readonly={true} id={`totaleResult_D${numeroDanneggiato}_R${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                                </th>
                                <th className='rimuovi-bordo'></th>
                            </tr>
                        </>}
                </>}</CallbackWrapper>);
            }
        });
    }
    eliminaRigaStimaPrezziario(idContainerRiga, idContainerGenitore, numDann, numRiga, tipoTabellaStima, args) {
        delete this.formOptions.rules[`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`];
        delete this.formOptions.rules[`UMInput_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`];
        delete this.formOptions.rules[`quantitaInput_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`];
        delete this.formOptions.rules[`valUnitarioInput_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`];

        if (this.isCompagniaGenerali()) {
            delete this.formOptions.rules[`categoriaSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`];
            delete this.formOptions.rules[`localizzazione_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`];
        }
        else
            delete this.formOptions.rules[`beneDanneggiato_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`];
        if (tipoTabellaStima === 'tabellaStatoUso') {
            delete this.formOptions.rules[`percDegrSupInd_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`];
        }

        let containerDaElimin = document.getElementById(idContainerRiga);
        let containerGenitore = document.getElementById(idContainerGenitore);
        containerGenitore.removeChild(containerDaElimin);

        if (tipoTabellaStima === 'tabellaValoreANuovo') {
            this.calcolaStimaValoreANuovo(numDann, false);
        }
        else if (tipoTabellaStima === 'tabellaStatoUso') {
            this.calcolaStimaStatoUso(numDann, false)
        }

        this.strutturaStatiStimePrezziari.forEach(elm => {
            if (elm.id === idContainerRiga)
                elm.stato = 'd';
        });
    }
    //---------------------------PREZZIARIO RIGHE FINE-----------------

    //---------------------------COASSICURAZIONI TABELLE INIZIO-----------------
    contentCoassicurazione(num, tipoTabella) {
        return (<>
            <div style={{ textAlign: 'right', padding: '1%' }}>
                <ButtonComponent id={`btnAggiornaDatiCoassicuraz-D${num}_${this.props.IdInternoSinistro}`} alt='Aggiorna Coassicurazione' cssClass='e-fill e-info' type='button' onClick={this.aggiornaCoassicurazione.bind(this, num)}>Aggiorna Coassicurazione</ButtonComponent>
            </div>
            <table className='perizia-table' >
                <tr>
                    <th style={{ width: '1500px' }}>COASSICURAZIONE INDIRETTA</th>
                </tr>
            </table>
            <table className='perizia-table' >
                <tr>
                    <th>Compagnie Coinvolte</th>
                    <th>Danno a Riparto</th>
                    <th>N° Polizza</th>
                    <th style={{ width: '500px' }}>Limitazioni Contrattuali</th>
                    <th >Danno netto</th>
                    <th><ButtonComponent style={{ marginLeft: '20px' }} id={`btnAggiungiRigaCompagniaR1-D${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaCompagniaCoassicurazioneRiparto1.bind(this, num, '', '')}><i class="fas fa-plus"></i></ButtonComponent></th>
                </tr>
            </table>
            <table id={`contentTutteLeRigheCompagnieRiparto1-D${num}_${this.props.IdInternoSinistro}`} className='perizia-table tutteLeRigheCompagnie' >
            </table>
            <table className='perizia-table' >
                <tr>
                    <th style={{ width: '71.4%', textAlign: 'right' }}>Somma Danni</th>
                    <th><NumericTextBoxComponent showSpinButton={false} ù id={`sommaDanniRiparto1_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    <th></th>
                </tr>
            </table>
            <table id={`contentTutteLeRigheCompagnieRiparto2-D${num}_${this.props.IdInternoSinistro}`} className='perizia-table' >
            </table>
            <table className='perizia-table' id={`contentSommaDanniRip2-D${num}_${this.props.IdInternoSinistro}`} >
                <tr>
                    <th style={{ width: '71.4%', textAlign: 'right' }}>Somma Danni</th>
                    <th><NumericTextBoxComponent showSpinButton={false} id={`sommaDanniRiparto2_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    <th></th>
                </tr>
            </table>
            {tipoTabella === 'statoUso' ? <>
                <table className='perizia-table' id={`contentIndVsCompRip2-D${num}_${this.props.IdInternoSinistro}`} >
                    <tr>
                        <th style={{ textAlign: 'right' }}>VSU :</th>
                        <th><NumericTextBoxComponent showSpinButton={false} id={`vsuCoass-D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ textAlign: 'right' }}>Suppl. Indennizzo :</th>
                        <th><NumericTextBoxComponent showSpinButton={false} id={`supplIndennitaCoass-D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th style={{ textAlign: 'right' }}>Indenn. A carico Vs Compagnia :</th>
                        <th><NumericTextBoxComponent showSpinButton={false} id={`indennizzoVsCompagnia_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                        <th></th>
                    </tr>
                </table>
            </> :
                <>
                    <table className='perizia-table' id={`contentIndVsCompRip2-D${num}_${this.props.IdInternoSinistro}`} >
                        <tr>
                            <th style={{ width: '71.4%', textAlign: 'right' }}>Indennizzo a Carico Vs Compagnia :</th>
                            <th><NumericTextBoxComponent showSpinButton={false} id={`indennizzoVsCompagnia_D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                            <th></th>
                        </tr>
                    </table>
                </>}
            <table className='perizia-table' id={`contentIndVsCompRip2-D${num}_${this.props.IdInternoSinistro}`} >
                <tr>
                    <th style={{ width: '14.2%', textAlign: 'right' }}>Note Coassicurazione</th>
                    <th><TextBoxComponent id={`noteCoassicurazione-D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' placeholder='Note Coassicurazione' cssClass='e-outline'></TextBoxComponent></th>
                </tr>
            </table>
        </>)
    }

    aggiungiRigaCompagniaCoassicurazioneRiparto1(numeroDanneggiato, retDataCoassC, numRiga0) {
        return new Promise((resolve, reject) => {
            const funzCoass2 = async () => {

                var num = this.numRigheCoassicurazione;
                await this.aggiungiRigaCompagniaCoassicurazioneRiparto2(numeroDanneggiato, num, retDataCoassC)
                this.numRigheCoassicurazione++;

                const DivContainer2 = document.getElementById(`contentTutteLeRigheCompagnieRiparto1-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`);
                const newTr2 = document.createElement("tr");
                newTr2.setAttribute("id", `rigaRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`);
                DivContainer2.append(newTr2);

                const container = document.getElementById(`rigaRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`)
                const root = createRoot(container);
                root.render(<CallbackWrapper callback={() => {
                    if (CustomJS.isNullEmpty(retDataCoassC)) {
                        if (!CustomJS.isNullEmpty(numRiga0)) {
                            if (CustomJS.isNullEmpty(document.getElementById(`compagniaCoinvoltaRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)) {
                                if (this.isCompagniaGenerali()) {
                                    document.getElementById(`compagniaCoinvoltaRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = this.IdCompagniaGeneraliSuCodiciAnia.toString();
                                }
                                else if (this.isCompagniaUnipol()) {
                                    document.getElementById(`compagniaCoinvoltaRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = this.props.IdCompagnia.toString();
                                }
                                if (document.getElementById(`determinazioneDanno_D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001')
                                    document.getElementById(`dannoARipartoRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`dannoNetto_D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                                else if (document.getElementById(`determinazioneDanno_D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                                         || document.getElementById(`determinazioneDanno_D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                                         || document.getElementById(`determinazioneDanno_D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                                         || document.getElementById(`determinazioneDanno_D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                                         || document.getElementById(`determinazioneDanno_D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006')
                                    document.getElementById(`dannoARipartoRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`valoreANuovodannoNetto_D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                            }
                        }
                        document.getElementById(`tipoLimitazioneContrattualeRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 'nessuno';
                        document.getElementById(`limitazioneContrattualeRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
                        var objStrutturaStatiRigheCoass = {
                            stato: 'n',
                            stato_iniziale: 'n',
                            keyJftech: null,
                            keyGen: null,
                            id: `rigaRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`,
                            c: num
                        };
                        this.strutturaStatiCoassicurazioniRighe.push(objStrutturaStatiRigheCoass);
                        resolve();
                    }
                    else {
                        document.getElementById(`compagniaCoinvoltaRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataCoassC.compagniaCoinvolta;
                        document.getElementById(`dannoARipartoRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataCoassC.dannoARiparto
                        document.getElementById(`numPolizzaRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataCoassC.polizzaCoass
                        document.getElementById(`tipoLimitazioneContrattualeRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataCoassC.tipoLimiteContrattuale
                        document.getElementById(`limitazioneContrattualeRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataCoassC.limiteContrattuale
                        document.getElementById(`dannoNettoCoassDirRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataCoassC.dannoNetto

                        var objStrutturaStatiRigheCoass = {
                            stato: 'o',
                            stato_iniziale: 'o',
                            keyJftech: retDataCoassC.id_compagnia_coass,
                            keyGen: retDataCoassC.id_coass_gen,
                            id: `rigaRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`,
                            c: num
                        };
                        this.strutturaStatiCoassicurazioniRighe.push(objStrutturaStatiRigheCoass);

                        resolve();
                    }
                }}>{<>
                    <th><DropDownListComponent onChange={this.cambiaCompagniaSuRip2.bind(this, numeroDanneggiato, num)} id={`compagniaCoinvoltaRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields}
                        allowFiltering={true} dataSource={this.dsCompagnie} filterType='Contains' showClearButton={true}
                        floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                    </DropDownListComponent></th>
                    <th><NumericTextBoxComponent showSpinButton={false} onBlur={this.calcolaRigaCoassicurazione.bind(this, numeroDanneggiato, num, false)} id={`dannoARipartoRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    <th><TextBoxComponent onBlur={this.calcolaRigaCoassicurazione.bind(this, numeroDanneggiato, num, false)} id={`numPolizzaRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' placeholder='N° Polizza' cssClass='e-outline'></TextBoxComponent></th>
                    <th><DropDownListComponent onChange={this.calcolaRigaCoassicurazione.bind(this, numeroDanneggiato, num, false)} id={`tipoLimitazioneContrattualeRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.limitiContrattTipiCoass}
                        allowFiltering={true} filterType='Contains' showClearButton={true}
                        floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                    </DropDownListComponent></th>
                    <th ><NumericTextBoxComponent showSpinButton={false} onBlur={this.calcolaRigaCoassicurazione.bind(this, numeroDanneggiato, num, false)} id={`limitazioneContrattualeRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="#.##" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    <th> <NumericTextBoxComponent showSpinButton={false} id={`dannoNettoCoassDirRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
                    <th >
                        <ButtonComponent id={`btnEliminaCompagniaRiparto1-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`} title='Elimina Riga' cssClass='e-fill e-danger eliminaBottoneCoassSecond' type='button' onClick={this.eliminaRigaCompagniaRiparto1.bind(this, numeroDanneggiato, num, false)}><i class="fas fa-trash"></i></ButtonComponent>
                        <ButtonComponent style={{ marginLeft: '15px' }} id={`btnAggiungiRigaCompagniaR1-D${numeroDanneggiato}-R${num}_${this.props.IdInternoSinistro}`} title='Aggiungi Riga' cssClass='e-fill e-info' type='button' onClick={this.aggiungiRigaCompagniaCoassicurazioneRiparto1.bind(this, numeroDanneggiato, '', '')}><i class="fas fa-plus"></i></ButtonComponent>
                    </th>
                </>}</CallbackWrapper>);
            }
            funzCoass2();
        });
    }
    aggiungiRigaCompagniaCoassicurazioneRiparto2(numeroDanneggiato, num, retDataCoassC) {
        return new Promise((resolve, reject) => {

            const DivContainer2 = document.getElementById(`contentTutteLeRigheCompagnieRiparto2-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`);
            const newTr2 = document.createElement("tr");
            newTr2.setAttribute("id", `rigaRiparto2-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`);
            DivContainer2.append(newTr2);

            const container = document.getElementById(`rigaRiparto2-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                if (!CustomJS.isNullEmpty(retDataCoassC)) {
                    document.getElementById(`compagniaCoinvoltaRiparto2-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataCoassC.compagniaCoinvolta;
                    document.getElementById(`formulaCoass-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataCoassC.formula;
                    document.getElementById(`dannNettoRip2-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataCoassC.dannoNettoRip2
                    resolve()
                }
                else {
                    if (CustomJS.isNullEmpty(document.getElementById(`compagniaCoinvoltaRiparto2-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)) {
                        if (this.isCompagniaGenerali()) {
                            document.getElementById(`compagniaCoinvoltaRiparto2-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = this.IdCompagniaGeneraliSuCodiciAnia.toString();
                        }
                        else if (this.isCompagniaUnipol()) {
                            document.getElementById(`compagniaCoinvoltaRiparto2-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = this.props.IdCompagnia.toString();
                        }
                    }
                    resolve();
                }
            }}>{<>
                <th><DropDownListComponent id={`compagniaCoinvoltaRiparto2-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsCompagnie}
                    allowFiltering={true} filterType='Contains' showClearButton={true}
                    floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                </DropDownListComponent></th>
                <th style={{ width: '750px' }} ><TextBoxComponent id={`formulaCoass-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' placeholder='Formula' cssClass='e-outline'></TextBoxComponent></th>
                <th><NumericTextBoxComponent showSpinButton={false} id={`dannNettoRip2-D${numeroDanneggiato}-C${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent></th>
            </>}</CallbackWrapper>);
        });
    }

    eliminaRigaCompagniaRiparto1(numeroDanneggiato, numeroRiga, fromCaricaDati, args) {
        document.getElementById(`dannoARipartoRiparto1-D${numeroDanneggiato}-C${numeroRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
        document.getElementById(`limitazioneContrattualeRiparto1-D${numeroDanneggiato}-C${numeroRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
        document.getElementById(`dannoNettoCoassDirRiparto1-D${numeroDanneggiato}-C${numeroRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
        let d = document.getElementById(`contentTutteLeRigheCompagnieRiparto1-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`);
        d.removeChild(document.getElementById(`rigaRiparto1-D${numeroDanneggiato}-C${numeroRiga}_${this.props.IdInternoSinistro}`));
        let d2 = document.getElementById(`contentTutteLeRigheCompagnieRiparto2-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`);
        d2.removeChild(document.getElementById(`rigaRiparto2-D${numeroDanneggiato}-C${numeroRiga}_${this.props.IdInternoSinistro}`));
        if (fromCaricaDati !== true) {
            setTimeout(() => {
                this.calcolaCoassicurazioneCompleta(numeroDanneggiato, false)
            }, 350)
        }
        this.strutturaStatiCoassicurazioniRighe.forEach(elm => {
            if (elm.id === `rigaRiparto1-D${numeroDanneggiato}-C${numeroRiga}_${this.props.IdInternoSinistro}`) {
                elm.stato = 'd'
            }
        })
        this.strutturaStatiCoassicurazioniRighe.filter(this.rimuoviDaStrutturaStatiElementoNuovoEliminato)
    }

    apriCoassicurazione(num) {
        const startTime = performance.now();
        document.getElementById(`accordionCoassicurazione-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].expandItem(true, 0);
        var numRiga0 = 0;
        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`)) {
                numRiga0 = index;
                break;
            }
        }
        const endTime = performance.now();
        const totalTime = endTime - startTime;
        setTimeout(async () => {
            for (let index = 0; index < 2; index++) {
                await this.aggiungiRigaCompagniaCoassicurazioneRiparto1(num, '', numRiga0)
                if (index === 1) {
                    document.getElementById(`accordionCoassicurazione-D${num}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
                }
            }
        }, totalTime)
    }

    chiudiCoassicurazione(num) {
        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`)) {
                let containerDaElimin = document.getElementById(`rigaRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`);
                let containerGenitore = document.getElementById(`contentTutteLeRigheCompagnieRiparto1-D${num}_${this.props.IdInternoSinistro}`);
                containerGenitore.removeChild(containerDaElimin);
                let containerDaElimin2 = document.getElementById(`rigaRiparto2-D${num}-C${index}_${this.props.IdInternoSinistro}`);
                let containerGenitore2 = document.getElementById(`contentTutteLeRigheCompagnieRiparto2-D${num}_${this.props.IdInternoSinistro}`);
                containerGenitore2.removeChild(containerDaElimin2);

                this.strutturaStatiCoassicurazioniRighe.forEach(elm => {
                    if (elm.id === `rigaRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`) {
                        elm.stato = 'd'
                    }
                })
                this.strutturaStatiCoassicurazioniRighe.filter(this.rimuoviDaStrutturaStatiElementoNuovoEliminato)
            }
        }
        document.getElementById(`accordionCoassicurazione-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].expandItem(false, 0);
        document.getElementById(`accordionCoassicurazione-D${num}_${this.props.IdInternoSinistro}`).style.display = 'none';
    }
    coassicurazionePresenteBool(numDann) {
        //Se c'è la coassicurazione
        if (document.getElementById(`coassicurazioneSi-D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked)
            this.apriCoassicurazione(numDann)
        else
            this.chiudiCoassicurazione(numDann)
        setTimeout(() => {
            this.trasportaDatiRiepilogo();
        }, 300)
    }

    //---------------------------COASSICURAZIONI TABELLE FINE-----------------

    //---------------------------STIMA TABELLE FINE-----------------

    //--------------------------RIEPILOGO TABELLA INIZIO-------------------------
    async tabellaRiepilogo0(idDanneggiato, comboTabelleStima) {
        return new Promise((resolve, reject) => {
            var num = this.numTabelleRiepilogo;
            this.numTabelleRiepilogo++;

            const DivContainer = document.getElementById(`tabellaCompletaTutteIRiepiloghi_${this.props.IdInternoSinistro}`);
            const newDiv = document.createElement("div");
            newDiv.setAttribute("id", `containerTabRiepilogo-D${num}_${this.props.IdInternoSinistro}`);
            DivContainer.append(newDiv);

            const container = document.getElementById(`containerTabRiepilogo-D${num}_${this.props.IdInternoSinistro}`)
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                const funzioneAsync = async () => {
                    document.getElementById(`danneggiatoRiep-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idDanneggiato;
                    setTimeout(async () => {
                        document.getElementById(`danneggiatoRiepTesto-D${num}_${this.props.IdInternoSinistro}`).innerText = document.getElementById(`danneggiatoRiep-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;

                        document.getElementById(`nomeDanneggiatoTotIndLordoRiep-D${num}_${this.props.IdInternoSinistro}`).innerText = "Totale : " + document.getElementById(`danneggiatoRiep-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                        for (let index = 0; index < comboTabelleStima.danneggiati.length; index++) {
                            if (comboTabelleStima.danneggiati[index] === idDanneggiato) {
                                await this.tabellaRiepilogo1(num, comboTabelleStima.garanzie[index], comboTabelleStima.partite[index]);
                                this.numRigheRiepilogoGaranzie++;
                                if (index === comboTabelleStima.danneggiati.length - 1)
                                    resolve();
                            }
                            else {
                                if (index === comboTabelleStima.danneggiati.length - 1)
                                    resolve();
                            }
                        }
                    }, 150)
                }
                funzioneAsync();
            }}>{<>
                <table className='perizia-table' >
                    <tr className='riepilogoParziale'>
                        <th>
                            <div style={{ display: 'none' }}>
                                <DropDownListComponent readonly={true} id={`danneggiatoRiep-D${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsDanneggiati}
                                    allowFiltering={true} filterType='Contains' showClearButton={true}
                                    placeholder="Danneggiato" floatLabelType='always' cssClass='e-outline'>
                                </DropDownListComponent>
                            </div>
                            <h5 className='danneggiato-testp-riep' id={`danneggiatoRiepTesto-D${num}_${this.props.IdInternoSinistro}`}></h5>
                        </th>
                    </tr>
                </table>
                <table className='perizia-table' >
                    <tr>
                        <th></th>
                        <th >Indennizzo Lordo in Uso</th>
                        <th>Franchigia/Scoperto</th>
                        <th >Supplemento Indennità</th>
                        <th >Indennizzo Netto</th>
                    </tr>
                </table>
                <table id={`quanteGaranzieRiep-D${num}_${this.props.IdInternoSinistro}`} className='perizia-table' >
                </table>
                <table className='perizia-table riepilogoParziale'>
                    <tr id={`rigaTotaliDanneggiatiRiepilogo-D${num}_${this.props.IdInternoSinistro}`}>
                        <th ><h6 style={{ fontFamily: 'Franklin Gothic Medium', fontSize: '18px', fontWeight: 'bold', fontStyle: 'italic' }} id={`nomeDanneggiatoTotIndLordoRiep-D${num}_${this.props.IdInternoSinistro}`}></h6></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleIndennizzoLordo-D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleFranchige-D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleSuppIndenn-D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                        <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleIndennizzo-D${num}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                    </tr>
                </table>
            </>}</CallbackWrapper>);
        });

    }
    tabellaRiepilogo1(num, idGaranzia, idPartita) {
        return new Promise((resolve, reject) => {
            var riga = this.numRigheRiepilogoGaranzie
            const DivContainer = document.getElementById(`quanteGaranzieRiep-D${num}_${this.props.IdInternoSinistro}`);
            const newDiv = document.createElement("tr");
            newDiv.setAttribute("id", `garanziaRiepIntestazione-D${num}-R-${riga}_${this.props.IdInternoSinistro}`);
            DivContainer.append(newDiv);

            const container = document.getElementById(`garanziaRiepIntestazione-D${num}-R-${riga}_${this.props.IdInternoSinistro}`)
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                document.getElementById(`partitadannRiep-D${num}-R-${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idPartita;
                document.getElementById(`garanziadannRiep-D${num}-R-${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idGaranzia;
                setTimeout(() => {
                    document.getElementById(`partitadannRiepTesto-D${num}-R-${riga}_${this.props.IdInternoSinistro}`).innerText = document.getElementById(`partitadannRiep-D${num}-R-${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                    document.getElementById(`garanziadannRiepTesto-D${num}-R-${riga}_${this.props.IdInternoSinistro}`).innerText = document.getElementById(`garanziadannRiep-D${num}-R-${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                    resolve();
                }, 100)
            }}>{<>
                <th>
                    <div style={{ display: 'none' }}>
                        <DropDownListComponent readonly={true} id={`partitadannRiep-D${num}-R-${riga}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsPartite}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Partita" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent>
                        <DropDownListComponent readonly={true} id={`garanziadannRiep-D${num}-R-${riga}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={this.dsGaranzie}
                            allowFiltering={true} filterType='Contains' showClearButton={true}
                            placeholder="Garanzia" floatLabelType='always' cssClass='e-outline'>
                        </DropDownListComponent>
                    </div>
                    <h6 style={{ fontStyle: 'italic' }} id={`partitadannRiepTesto-D${num}-R-${riga}_${this.props.IdInternoSinistro}`}></h6>
                    <h6 style={{ fontStyle: 'italic' }} id={`garanziadannRiepTesto-D${num}-R-${riga}_${this.props.IdInternoSinistro}`}></h6>
                </th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`indennizzoLordoRiep-D${num}-${riga}_${this.props.IdInternoSinistro}`} value={0} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`franchigiaRiep-D${num}-${riga}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`supplIndennRiepilogo-D${num}-${riga}_${this.props.IdInternoSinistro}`} value={0} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`indennizzoNettoRiep-D${num}-${riga}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
            </>}</CallbackWrapper>);
        });
    }
    tabellaRiepilogo2(num) {
        return new Promise((resolve, reject) => {
            var riga = this.numRigheRiepilogoGaranzie;
            const DivContainer = document.getElementById(`quanteGaranzieRiep-D${num}_${this.props.IdInternoSinistro}`);
            const newDiv = document.createElement("tr");
            newDiv.setAttribute("id", `garanziaRiep-D${num}-R-${riga}_${this.props.IdInternoSinistro}`);
            DivContainer.append(newDiv);

            const container = document.getElementById(`garanziaRiep-D${num}-R-${riga}_${this.props.IdInternoSinistro}`)
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                resolve();
            }}>{<>
                <th></th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`indennizzoLordoRiep-D${num}-${riga}_${this.props.IdInternoSinistro}`} value={0} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`franchigiaRiep-D${num}-${riga}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`supplIndennRiepilogo-D${num}-${riga}_${this.props.IdInternoSinistro}`} value={0} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`indennizzoNettoRiep-D${num}-${riga}_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
            </>}</CallbackWrapper>);
        });
    }
    //--------------------------RIEPILOGO TABELLA FINE----------------------------

    //----------------------------LISTBOX TABELLE INIZIO-------------------------
    cambiaListBox(t, index, numCategorie) {
        for (let n = 0; n < numCategorie; n++) {
            if (document.getElementById(`lista-t${t}-n${n}_${this.props.IdInternoSinistro}`)) {
                document.getElementById(`lista-t${t}-n${n}_${this.props.IdInternoSinistro}`).style.display = 'none';
                document.getElementById(`btnSwitchCategorie-t${t}-n${n}_${this.props.IdInternoSinistro}`).style.backgroundColor = '#FFFFFF';
            }
        }
        document.getElementById(`lista-t${t}-n${index}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        document.getElementById(`btnSwitchCategorie-t${t}-n${index}_${this.props.IdInternoSinistro}`).style.backgroundColor = '#cac6da';
    }
    cambiaRispostaList(idRisp, args) {
        var risp = args.value;
        var risposta = ''
        args.target.jsonData.forEach(elm => {
            risp.forEach(elm2 => {
                if (elm.id === elm2) {
                    risposta += elm.text + ', '
                }
            })
        })
        document.getElementById(idRisp).innerText = risposta.substring(0, risposta.length - 2);
    }

    ripristiniSuInfoRich(numeroDanneggiato, siNo) {
        //TG002 = elettronica
        if (document.getElementById(`garanziaFromSin-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG002') {
            var str2 = document.getElementById(`garanziaFromSin-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.servizio_str2;
            var categorie = []
            this.nuovoArrInfoRichRiep.forEach((elmS) => {
                if (elmS.servizio_str2 === str2)
                    categorie.push({
                        VALUE: elmS.cod_ele
                    })
            })
            for (let n = 0; n < categorie.length; n++) {
                //ripristini
                if (categorie[n].VALUE === '22') {
                    if (document.getElementById(`listbox-t${numeroDanneggiato}-n${n}_${this.props.IdInternoSinistro}`)) {
                        if (siNo === 'si') {
                            document.getElementById(`risposta-t${numeroDanneggiato}-n${n}_${this.props.IdInternoSinistro}`).innerText = "Ripristini avvenuti";
                            document.getElementById(`listbox-t${numeroDanneggiato}-n${n}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['00131'];
                        }
                        else if (siNo === 'no') {
                            document.getElementById(`risposta-t${numeroDanneggiato}-n${n}_${this.props.IdInternoSinistro}`).innerText = "Ripristini non avvenuti";
                            document.getElementById(`listbox-t${numeroDanneggiato}-n${n}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['00132'];
                        }
                        else {
                            document.getElementById(`risposta-t${numeroDanneggiato}-n${n}_${this.props.IdInternoSinistro}`).innerText = "Ripristini parziali";
                            document.getElementById(`listbox-t${numeroDanneggiato}-n${n}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ['00133'];
                        }
                    }
                }
            }
        }
    }

    localizzazioneSuInfoRich(numeroDanneggiato, num) {
        //locale interessato
        //acqua condotta
        if (document.getElementById(`garanziaFromSin-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG001') {

            var localizzazioni = []
            this.strutturaStatiStimePrezziari.forEach(prezz => {
                if (document.getElementById(`tabPrezziario-D${numeroDanneggiato}-R-${prezz.r}_${this.props.IdInternoSinistro}`)) {
                    let localName = document.getElementById(`localizzazione_D${numeroDanneggiato}_R${prezz.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                    localName = CustomJS.isNullEmpty(localName) ? '' : localName.toLowerCase().trim()
                    localizzazioni.push(localName)
                }
            })

            var localizzazioniUnique = localizzazioni.filter((obj, index) => {
                return index === localizzazioni.findIndex(o => obj === o);

            });
            localizzazioni = localizzazioniUnique;

            var str2 = document.getElementById(`garanziaFromSin-D${numeroDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.servizio_str2;
            var categorie = []
            this.nuovoArrInfoRichRiep.forEach((elmS) => {
                if (elmS.servizio_str2 === str2)
                    categorie.push({
                        VALUE: elmS.cod_ele
                    })
            })
            for (let n = 0; n < categorie.length; n++) {
                //locale interessato
                if (categorie[n].VALUE === '1') {
                    if (document.getElementById(`listbox-t${numeroDanneggiato}-n${n}_${this.props.IdInternoSinistro}`)) {
                        let arrayListbox = [];
                        let risposta = "";
                        localizzazioni.forEach(localizzazione => {
                            document.getElementById(`listbox-t${numeroDanneggiato}-n${n}_${this.props.IdInternoSinistro}`).ej2_instances[0].jsonData.forEach(locale => {
                                if (!CustomJS.isNullEmpty(locale)) {
                                    if (locale.text.toLowerCase().trim() === localizzazione) {
                                        arrayListbox.push(locale.id)
                                        if (CustomJS.isNullEmpty(risposta))
                                            risposta = locale.text;
                                        else
                                            risposta += ", " + locale.text;
                                    }
                                }
                            });
                        })
                        document.getElementById(`risposta-t${numeroDanneggiato}-n${n}_${this.props.IdInternoSinistro}`).innerText = risposta;
                        document.getElementById(`listbox-t${numeroDanneggiato}-n${n}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = arrayListbox;
                    }
                }
            }
        }
    }

    nuovaCreaInfoRichieste(dann, part, gar, garCodEle, idStima, idDanno, num, codEle, str2, retData) {
        var idStimaDB = [{ TEXT: idStima, VALUE: idStima }];
        const DivContainer = document.getElementById(`containerNuovoTutteLeListBox_${this.props.IdInternoSinistro}`);
        const newDiv = document.createElement("div");
        newDiv.setAttribute("id", `tabellaInfoRichieste-t${num}_${this.props.IdInternoSinistro}`);
        newDiv.setAttribute("class", `tabellaInfoRichieste`);
        DivContainer.append(newDiv);

        const container = document.getElementById(`tabellaInfoRichieste-t${num}_${this.props.IdInternoSinistro}`);
        const root = createRoot(container);
        root.render(<CallbackWrapper callback={() => {

            //-------CALLBACK TABELLA LIST INIZIO--------
            var dataBaseCategorie = []
            this.nuovoArrInfoRichRiep.forEach((elm) => {
                if (elm.servizio_str2 === str2)
                    dataBaseCategorie.push({
                        TEXT: elm.descriz,
                        VALUE: elm.cod_ele,
                        TIPOLOGIA: elm.servizio_str3,
                    })
            })
            const DivContainer2 = document.getElementById(`boxBottoniList-t${num}_${this.props.IdInternoSinistro}`);
            const DivContainer3 = document.getElementById(`boxList-t${num}_${this.props.IdInternoSinistro}`);
            for (let index = 0; index < dataBaseCategorie.length; index++) {
                //-------CALLBACK BOTTONI DINAMICI INIZIO--------
                var newDiv2 = document.createElement("div");
                newDiv2.setAttribute("id", `categoria-t${num}-n${index}_${this.props.IdInternoSinistro}`);
                DivContainer2.append(newDiv2);
                const container2 = document.getElementById(`categoria-t${num}-n${index}_${this.props.IdInternoSinistro}`);
                const root2 = createRoot(container2);
                root2.render(<CallbackWrapper callback={() => {
                    document.getElementById(`elmCategorie-t${num}-n${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataBaseCategorie[index].VALUE

                    //-------CALLBACK LISTA INIZIO--------
                    var data = []
                    this.nuovoArrRispInfoRicRiep.forEach(element => {
                        if (element.servizio_str2 === dataBaseCategorie[index].VALUE) {
                            data.push({
                                id: element.cod_ele,
                                text: (element.descriz.toLowerCase()).charAt(0).toUpperCase() + (element.descriz.toLowerCase()).slice(1)
                            })
                        }
                    })
                    var newDiv3 = document.createElement("div");
                    newDiv3.setAttribute("id", `lista-t${num}-n${index}_${this.props.IdInternoSinistro}`);
                    newDiv3.style.display = 'none'
                    DivContainer3.append(newDiv3);
                    const container3 = document.getElementById(`lista-t${num}-n${index}_${this.props.IdInternoSinistro}`);
                    const root3 = createRoot(container3);

                    // N = singolo
                    if (dataBaseCategorie[index].TIPOLOGIA === 'N') {
                        root3.render(<CallbackWrapper callback={() => {
                            if (index === 0)
                                document.getElementById(`lista-t${num}-n${index}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
                            if (CustomJS.isNullEmpty(retData)) {
                                //TG019 = ricerca guasto / TG001 = acqua condotta / TG002 = elettronica
                                if (codEle === 'TG019' || codEle === 'TG001' || codEle === 'TG002') {
                                    //modalità esecuzione perizia
                                    if (dataBaseCategorie[index].VALUE === '7') {
                                        if (this.tipoServizio === "video perizia") {
                                            document.getElementById(`listbox-t${num}-n${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ["00036"]
                                            document.getElementById(`risposta-t${num}-n${index}_${this.props.IdInternoSinistro}`).innerText = 'Videoperizia'
                                        }
                                        else if (this.tipoServizio === "perizia tradizionale") {
                                            document.getElementById(`listbox-t${num}-n${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ["00037"]
                                            document.getElementById(`risposta-t${num}-n${index}_${this.props.IdInternoSinistro}`).innerText = 'Sopralluogo in Loco'
                                        }
                                        else if (this.tipoServizio === "riparazione in forma specifica") {
                                            document.getElementById(`listbox-t${num}-n${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ["00038"]
                                            document.getElementById(`risposta-t${num}-n${index}_${this.props.IdInternoSinistro}`).innerText = 'Riparazione in forma specifica'
                                        }
                                    }

                                    //TG019 = ricerca guasto / TG001 = acqua condotta
                                    if (codEle === 'TG019' || codEle === 'TG001') {
                                        //grado difficioltà
                                        if (dataBaseCategorie[index].VALUE === '4') {
                                            document.getElementById(`listbox-t${num}-n${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ["00026"]
                                            document.getElementById(`risposta-t${num}-n${index}_${this.props.IdInternoSinistro}`).innerText = 'Medio'
                                        }
                                        //proprietà
                                        if (dataBaseCategorie[index].VALUE === '2') {
                                            document.getElementById(`listbox-t${num}-n${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = ["00022"]
                                            document.getElementById(`risposta-t${num}-n${index}_${this.props.IdInternoSinistro}`).innerText = 'Privata'
                                        }
                                    }
                                }
                            } else {
                                var arrayElementi = []
                                var risposta = ''
                                for (let r = 0; r < retData.length; r++) {
                                    if (retData[r].id_categoria === dataBaseCategorie[index].VALUE) {
                                        arrayElementi.push(retData[r].id_elemento)
                                        risposta = retData[r].risposta
                                    }
                                }
                                document.getElementById(`listbox-t${num}-n${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = arrayElementi
                                document.getElementById(`risposta-t${num}-n${index}_${this.props.IdInternoSinistro}`).innerText = risposta
                            }
                        }}>{<>
                            <div className='row'>
                                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-right">
                                    <ButtonComponent id={`btnCopiaRisposte-t${num}-n${index}_${this.props.IdInternoSinistro}`} type='button' title='Copia risposte della stessa garanzia' cssClass='e-fill' onClick={this.copiaRisposteStessaGaranzia.bind(this, num)}>COPIA RISPOSTE STESSA GARANZIA</ButtonComponent>
                                </div>
                            </div>
                            <ListBoxComponent id={`listbox-t${num}-n${index}_${this.props.IdInternoSinistro}`} onChange={this.cambiaRispostaList.bind(this, `risposta-t${num}-n${index}_${this.props.IdInternoSinistro}`)} dataSource={data} fields={this.ddlFieldsList} cssClass='listboxRiepCss' selectionSettings={{ mode: "Single", showCheckbox: false }} >
                                <Inject services={[CheckBoxSelection]} />
                            </ListBoxComponent>
                        </>}</CallbackWrapper>);
                    }
                    //S = multiplo
                    else if (dataBaseCategorie[index].TIPOLOGIA === 'S') {
                        root3.render(<CallbackWrapper callback={() => {
                            if (index === 0)
                                document.getElementById(`lista-t${num}-n${index}_${this.props.IdInternoSinistro}`).style.removeProperty('display')
                            if (CustomJS.isNullEmpty(retData)) {

                            }
                            else {
                                var arrayElementi = []
                                var arrayDescrizioni = []
                                for (let r = 0; r < retData.length; r++) {
                                    if (retData[r].id_categoria === document.getElementById(`elmCategorie-t${num}-n${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                                        arrayElementi.push(retData[r].id_elemento);
                                        arrayDescrizioni.push(retData[r].risposta)
                                    }
                                }
                                var risposta = ''
                                arrayDescrizioni.forEach(elm => {
                                    risposta += elm + ', '
                                })
                                document.getElementById(`listbox-t${num}-n${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = arrayElementi
                                document.getElementById(`risposta-t${num}-n${index}_${this.props.IdInternoSinistro}`).innerText = risposta

                            }

                        }}>{<>
                            <div className='row'>
                                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-right">
                                    <ButtonComponent id={`btnCopiaRisposte-t${num}-n${index}_${this.props.IdInternoSinistro}`} type='button' title='Copia risposte della stessa garanzia' cssClass='e-fill' onClick={this.copiaRisposteStessaGaranzia.bind(this, num)}>COPIA RISPOSTE STESSA GARANZIA</ButtonComponent>
                                </div>
                            </div>
                            <ListBoxComponent id={`listbox-t${num}-n${index}_${this.props.IdInternoSinistro}`} onChange={this.cambiaRispostaList.bind(this, `risposta-t${num}-n${index}_${this.props.IdInternoSinistro}`)} dataSource={data} fields={this.ddlFieldsList} cssClass='listboxRiepCss' selectionSettings={{ mode: "Multiple", showCheckbox: true }}  >
                                <Inject services={[CheckBoxSelection]} />
                            </ListBoxComponent>
                        </>}</CallbackWrapper>);
                    }

                    //-------CALLBACK LISTA FINE--------
                    document.getElementById(`idStimaList-t${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idStima;
                    setTimeout(() => {
                        document.getElementById(`idStimaList-t${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.KEYGEN = idDanno;
                    }, 300)
                }}>{<>
                    <div className='row e-box-riga-inforichieste'>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" style={{ padding: '2%' }}>
                            <ButtonComponent style={{ backgroundColor: 'white' }} onClick={this.cambiaListBox.bind(this, num, index, dataBaseCategorie.length)} id={`btnSwitchCategorie-t${num}-n${index}_${this.props.IdInternoSinistro}`} cssClass='e-fill e-info-richieste-btn' type='button'>{dataBaseCategorie[index].TEXT}</ButtonComponent>
                            <div style={{ display: 'none' }}> <DropDownListComponent dataSource={dataBaseCategorie} id={`elmCategorie-t${num}-n${index}_${this.props.IdInternoSinistro}`} fields={this.ddlFields}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                floatLabelType='always' popupHeight="250px" cssClass='e-outline buttondivLabelRiep2'>
                            </DropDownListComponent></div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                            <div className='e-risposta-inforichieste'>
                                <h6 id={`risposta-t${num}-n${index}_${this.props.IdInternoSinistro}`}></h6>
                            </div>
                        </div>
                    </div>
                </>}</CallbackWrapper>);
                //-------CALLBACK BOTTONI DINAMICI FINE--------
            }
        }}>{<>
            <div className="row card-layout intestazione-info-richieste">
                <div className="row">
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <h5 id={`danneggiatoInfoRichieste-t${num}_${this.props.IdInternoSinistro}`}>{dann}</h5>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <h5 id={`partitaInfoRichieste-t${num}_${this.props.IdInternoSinistro}`}>{part}</h5>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                        <h5 id={`garanziaInfoRichieste-t${num}_${this.props.IdInternoSinistro}`}>{gar}</h5>
                        <h5 style={{ display: 'none' }} id={`garanziaCodEleInfoRichieste-t${num}_${this.props.IdInternoSinistro}`}>{garCodEle}</h5>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                    <div tabIndex="0" className="e-card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                        <div style={{ display: 'none' }}>
                            <DropDownListComponent readonly={true} id={`idStimaList-t${num}_${this.props.IdInternoSinistro}`} fields={this.ddlFields} dataSource={idStimaDB}
                                allowFiltering={true} filterType='Contains' showClearButton={true}
                                placeholder="ID Stima" floatLabelType='always' cssClass='e-outline'>
                            </DropDownListComponent>
                        </div>
                        <div id={`boxBottoniList-t${num}_${this.props.IdInternoSinistro}`} className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                            {/* qui i bottoni dinamici */}
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                    <div tabIndex="0" className="e-card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                        <div id={`boxList-t${num}_${this.props.IdInternoSinistro}`} className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                            {/* qui listbox */}
                        </div>
                    </div>
                </div>
            </div>
        </>}</CallbackWrapper>);
        //-------CALLBACK TABELLA LIST FINE--------

    }

    copiaRisposteStessaGaranzia(num) {
        for (let stima = 1; stima < this.numStime; stima++) {
            if (document.getElementById(`boxList-t${stima}_${this.props.IdInternoSinistro}`)) {
                var figli = document.getElementById(`boxList-t${stima}_${this.props.IdInternoSinistro}`).childNodes;
                for (let index = 0; index < figli.length; index++) {
                    if (document.getElementById(`garanziaCodEleInfoRichieste-t${stima}_${this.props.IdInternoSinistro}`).innerText === document.getElementById(`garanziaCodEleInfoRichieste-t${num}_${this.props.IdInternoSinistro}`).innerText) {
                        document.getElementById(`listbox-t${num}-n${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`listbox-t${stima}-n${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                        document.getElementById(`risposta-t${num}-n${index}_${this.props.IdInternoSinistro}`).innerText = document.getElementById(`risposta-t${stima}-n${index}_${this.props.IdInternoSinistro}`).innerText
                    }
                }
            }
        }
    }
    //----------------------------LISTBOX TABELLE FINE---------------------------
    //--------------------------CREAZIONE TABELLE FINE--------------------------

    //----------------------------PREESISTENZA CALCOLI INIZIO----------------------------
    calcolaPreesistenza(numPart, numRiga) {
        //VALORE DEROGATO
        var somma = CustomJS.isNullEmpty(document.getElementById(`sommaAssicurataInput_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`sommaAssicurataInput_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        var deroga = CustomJS.isNullEmpty(document.getElementById(`derogaInput_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`derogaInput_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        var percentuale = (somma / 100) * deroga;
        var risultato = somma + percentuale;
        document.getElementById(`valoreDerogatoResult_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = parseFloat(risultato.toFixed(2));

        //VOLUME E RICOSTRUZIONE A NUOVO
        if (numRiga !== 'noRiga') {
            let risultato = 0;
            let risultato2 = 0;
            let valoreUnitario = CustomJS.isNullEmpty(document.getElementById(`valoreUnitarioInput_T${numPart}_R_${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreUnitarioInput_T${numPart}_R_${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            let superficie = CustomJS.isNullEmpty(document.getElementById(`superficieEquivalenteInput_T${numPart}_R_${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`superficieEquivalenteInput_T${numPart}_R_${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            let altezza = CustomJS.isNullEmpty(document.getElementById(`altezzaInput_T${numPart}_R_${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`altezzaInput_T${numPart}_R_${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            risultato = superficie * altezza;
            document.getElementById(`volumeResult_T${numPart}_R_${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = parseFloat(risultato.toFixed(2))
            risultato2 = valoreUnitario * risultato;
            document.getElementById(`valoreRicostruzioneResult_T${numPart}_R_${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = parseFloat(risultato2.toFixed(2))
        }

        //TOTALE ESISTENZA A NUOVO
        let valRicNuovoCell = 0;
        let totaleRicNuovo = 0;
        for (let index = 1; index < this.numRighePreesCostruzioni; index++) {
            if (document.getElementById(`rigaPreesistenzaCostruzione-T${numPart}-R-${index}_${this.props.IdInternoSinistro}`)) {
                if (CustomJS.isNullEmpty(document.getElementById(`valoreRicostruzioneResult_T${numPart}_R_${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value))
                    document.getElementById(`valoreRicostruzioneResult_T${numPart}_R_${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0
                valRicNuovoCell = parseFloat(document.getElementById(`valoreRicostruzioneResult_T${numPart}_R_${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value);
                totaleRicNuovo += valRicNuovoCell;
            }
        }
        let valoreAltroACorpo = 0;
        let totaleAltroACorpo = 0;
        for (let index = 1; index < this.numRighePreesDescrizioni; index++) {
            if (document.getElementById(`rigaPreesDescrizione-T${numPart}-R-${index}_${this.props.IdInternoSinistro}`)) {
                valoreAltroACorpo = CustomJS.isNullEmpty(document.getElementById(`valoreAltroACorpoResult_T${numPart}_R_${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreAltroACorpoResult_T${numPart}_R_${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                totaleAltroACorpo += valoreAltroACorpo;
            }
        }

        risultato = totaleRicNuovo + totaleAltroACorpo;
        document.getElementById(`valoreTotEsistNuovoResult_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = risultato;

        //DEGRADO STATO USO
        risultato = 0;
        percentuale = 0;
        var degrado = document.getElementById(`valoreDegradoInput_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        degrado = parseFloat(CustomJS.isNullEmpty(degrado) ? '0' : degrado);
        var convertita = document.getElementById(`valoreTotEsistNuovoResult_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        percentuale = (convertita / 100) * degrado;
        risultato = convertita - percentuale;
        document.getElementById(`valoreTotEsistStatoUsoInput_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = risultato

        //SCOPERTURA 
        var percNote = '';
        risultato = 0;
        var valoreDerogato = document.getElementById(`valoreDerogatoResult_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        var valoreANuovo = document.getElementById(`valoreTotEsistNuovoResult_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        var valoreInUso = document.getElementById(`valoreTotEsistStatoUsoInput_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        if (valoreDerogato >= valoreANuovo) {
            document.getElementById(`valoreScoperturaResult_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0
            document.getElementById(`valoreScoperturaStatoUso_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0
            document.getElementById(`messaggioNote-T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = "La somma assicurata è risultata sufficiente per il valore a nuovo";
            document.getElementById(`scoperturaTipo-T${numPart}_${this.props.IdInternoSinistro}`).innerText = "Scopertura";
            document.getElementById(`insuffAssicurativaPerc_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '0';
        }
        else if (valoreDerogato < valoreANuovo && valoreDerogato > valoreInUso) {
            risultato = (1 - (valoreDerogato - valoreInUso) / (valoreANuovo - valoreInUso)) * 100;
            document.getElementById(`valoreScoperturaResult_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = risultato
            document.getElementById(`valoreScoperturaStatoUso_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0
            percNote = (1 - (valoreDerogato / valoreANuovo)) * 100;
            document.getElementById(`insuffAssicurativaPerc_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = parseFloat(percNote.toFixed(2))
            document.getElementById(`messaggioNote-T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = `La somma assicurata (comprensiva di deroga) non è risultata sufficiente per il ${percNote.toFixed(1)}%; L'eventuale supplemento di indennizzo viene ridotto del ${risultato.toFixed(1)}%;`;
            document.getElementById(`scoperturaTipo-T${numPart}_${this.props.IdInternoSinistro}`).innerText = "Scopertura su S.I.";
        }
        else if (valoreDerogato < valoreInUso) {
            risultato = (1 - (valoreDerogato / valoreInUso)) * 100;
            document.getElementById(`valoreScoperturaResult_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
            document.getElementById(`valoreScoperturaStatoUso_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = risultato
            percNote = (1 - (valoreDerogato / valoreANuovo)) * 100;
            document.getElementById(`insuffAssicurativaPerc_T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = parseFloat(percNote.toFixed(2))
            document.getElementById(`messaggioNote-T${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = `La somma assicurata (comprensiva di deroga) non è risultata sufficiente per il ${risultato.toFixed(2)}% rispetto al valore allo stato d'uso e del ${percNote.toFixed(2)}% rispetto al valore a nuovo;`;
            document.getElementById(`scoperturaTipo-T${numPart}_${this.props.IdInternoSinistro}`).innerText = "Scopertura su V.s.u";
        }

        //AGGIORNAMENTO SU STIME
        //todo da rivedere l' aggiornamento dopo regola prop sulle stime
        var indici = []
        for (let index = 1; index < this.numStime; index++) {
            if (document.getElementById(`tabellaStima-D${index}_${this.props.IdInternoSinistro}`)) {
                if (document.getElementById(`nomePartita${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === document.getElementById(`partitaFromSin-D${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                    if (document.getElementById(`formaGaranzia${numPart}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '2') {
                        indici.push(index)
                    }
                }
            }
        }
        setTimeout(() => {
            for (let index = 0; index < indici.length; index++) {
                if (!CustomJS.isNullEmpty(document.getElementById(`determinazioneDanno_D${indici[index]}_${this.props.IdInternoSinistro}`))) {
                    if (document.getElementById(`determinazioneDanno_D${indici[index]}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001')
                        this.calcolaStimaValoreANuovo(indici[index], false)
                    else if (document.getElementById(`determinazioneDanno_D${indici[index]}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                             || document.getElementById(`determinazioneDanno_D${indici[index]}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                             || document.getElementById(`determinazioneDanno_D${indici[index]}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                             || document.getElementById(`determinazioneDanno_D${indici[index]}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                             || document.getElementById(`determinazioneDanno_D${indici[index]}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006')
                        this.calcolaStimaStatoUso(indici[index], false)
                }
            }
        }, 250)
    }
    //----------------------------PREESISTENZA CALCOLI FINE----------------------------

    //-------------------------STIME CALCOLI INIZIO------------------------
    cambiaLimiteSinistro(numDann) {
        this.strutturaStatiStimeBase.forEach(stima => {
            if (document.getElementById(`tabellaStima-D${stima.d}_${this.props.IdInternoSinistro}`)) {
                if (stima.d !== numDann) {
                    if (document.getElementById(`garanziaFromSin-D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                        document.getElementById(`limiteSinistro_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`limiteSinistro_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    }
                }
            }
        })
    }

    //STIMA VALORE A NUOVO INIZIO---------------------------------
    aggiornaStimaValoreANuovo(numDanneggiato) {
        //Calcolo prima tutte le righe singole e poi i parametri finali
        let tempoCalcolaRighe = 50;
        this.strutturaStatiStimePrezziari.forEach(prezz => {
            if (document.getElementById(`tabPrezziario-D${numDanneggiato}-R-${prezz.r}_${this.props.IdInternoSinistro}`)) {
                tempoCalcolaRighe += 50;
                this.calcolaRigaStimaValoreANuovo(numDanneggiato, prezz.r, true);
            }
        })
        //dopo aver aggiornato ogni riga aggiorno la stima completa
        setTimeout(() => {
            this.calcolaStimaValoreANuovo(numDanneggiato, false)
        }, tempoCalcolaRighe);
    }
    calcolaRigaStimaValoreANuovo(numDann, numR, fromAggiorna, args) {
        //controllo se la quantià è vuota o minore uguale a 0 metto minimo 1
        if (CustomJS.isNullEmpty(document.getElementById(`quantitaInput_D${numDann}_R${numR}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ||
            document.getElementById(`quantitaInput_D${numDann}_R${numR}_${this.props.IdInternoSinistro}`).ej2_instances[0].value <= 0) {
            document.getElementById(`quantitaInput_D${numDann}_R${numR}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 1;
        }
        let quantita = document.getElementById(`quantitaInput_D${numDann}_R${numR}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        let valUnitario = document.getElementById(`valUnitarioInput_D${numDann}_R${numR}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        let totale = parseFloat((quantita * valUnitario).toFixed(2));
        document.getElementById(`totaleResult_D${numDann}_R${numR}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = totale
        if (fromAggiorna !== true) // se non proviene dal tasto aggiorna per ogni riga cui valore viene cambiato aggiorna tutta la stima
            this.calcolaStimaValoreANuovo(numDann, false);
    }
    calcolaStimaValoreANuovo(numDanneggiato, fromLimite, args) {
        if (fromLimite === true) {
            this.cambiaLimiteSinistro(numDanneggiato);
        }
        //DANNO COMPLESSIVO ACCERTATO
        var valTot = 0;
        for (let index = 1; index < this.numTabPrezziari; index++) {
            if (document.getElementById(`tabPrezziario-D${numDanneggiato}-R-${index}_${this.props.IdInternoSinistro}`)) {
                if (CustomJS.isNullEmpty(document.getElementById(`totaleResult_D${numDanneggiato}_R${index}_${this.props.IdInternoSinistro}`)))
                    continue;
                else {
                    if (CustomJS.isNullEmpty(document.getElementById(`totaleResult_D${numDanneggiato}_R${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)) {
                        document.getElementById(`totaleResult_D${numDanneggiato}_R${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0
                    }
                    valTot += document.getElementById(`totaleResult_D${numDanneggiato}_R${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                }
            }
        }
        valTot = valTot < 0 ? 0 : valTot;
        document.getElementById(`dannoComplessAccert_D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = valTot

        //DOPO REGOLA PROPORZIONALE
        for (let index = 1; index < this.numPreesistenze; index++) {
            if (document.getElementById(`tabellaCompletaPreesistenza-T${index}_${this.props.IdInternoSinistro}`)) {
                if (document.getElementById(`partitaFromSin-D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === document.getElementById(`nomePartita${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                    if (document.getElementById(`formaGaranzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '2') {
                        var totEsisNuovo = document.getElementById(`valoreTotEsistNuovoResult_T${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                        var valoreDerogato = document.getElementById(`valoreDerogatoResult_T${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                        if (totEsisNuovo > valoreDerogato) {
                            document.getElementById(`dopoRegolaProporzionale_D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = valTot * (valoreDerogato / totEsisNuovo)
                        }
                        else {
                            if (document.getElementById(`dopoRegolaProporzionale_D${numDanneggiato}_${this.props.IdInternoSinistro}`))
                                document.getElementById(`dopoRegolaProporzionale_D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = valTot
                        }
                    }
                    else if (document.getElementById(`formaGaranzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '1'
                             || document.getElementById(`formaGaranzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '3') {
                        document.getElementById(`dopoRegolaProporzionale_D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`dannoComplessAccert_D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    }
                    //CALCOLO FRANCHIGIA O SCOPERTO
                    //la franchigia/scoperto condivisa/o chiama la >>>> limiteCondivisoStessaGaranzia 
                    //che chiama la >>>> calcolaStimaValoreANuovoParteFinale per ogni stima in un ciclo for

                    if (document.getElementById(`isFranchigiaOrScoperto-D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'franchigia') {
                        this.nuovaCalcolaFranchigeCondivise(numDanneggiato);
                    }
                    else {
                        this.nuovoScopertoCondiviso(numDanneggiato);
                    }
                    //all' interno di franchigia o scoperto condivisa/o c'è la gestione del limite condiviso o meno e 
                    //la parte finale del calcolo della stima con le funzioni
                }
            }
        }
    }
    calcolaStimaValoreANuovoParteFinale(numDanneggiato, limite) {
        //LIMITE 
        let dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
        dopoRegolaProp = CustomJS.isNullEmpty(dopoRegolaProp) ? 0 : dopoRegolaProp

        var franchigiaScoperto = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        franchigiaScoperto = CustomJS.isNullEmpty(franchigiaScoperto) ? 0 : franchigiaScoperto;

        var dannoNetto = parseFloat((dopoRegolaProp - franchigiaScoperto).toFixed(2));

        if (dannoNetto > limite) {
            dannoNetto = limite;
            document.getElementById(`ceLimiteIndennizzoONo-D${numDanneggiato}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
            document.getElementById(`limiteIndennizzo-D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = limite;
            document.getElementById(`coinvoltoLimIndannoNetto_D${numDanneggiato}_${this.props.IdInternoSinistro}`).innerHTML = 'Danno netto (coinvolto il limite indennizzo) :';
        }
        else {
            document.getElementById(`limiteIndennizzo-D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
            document.getElementById(`ceLimiteIndennizzoONo-D${numDanneggiato}_${this.props.IdInternoSinistro}`).style.display = 'none';
            document.getElementById(`coinvoltoLimIndannoNetto_D${numDanneggiato}_${this.props.IdInternoSinistro}`).innerHTML = 'Danno netto';
        }
        dannoNetto = dannoNetto < 0 ? 0 : dannoNetto;
        document.getElementById(`dannoNetto_D${numDanneggiato}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dannoNetto;

    }
    //STIMA VALORE A NUOVO FINE-----------------------------------

    //STIMA STATO USO INIZIO---------------------------------
    aggiornaStimaStatoUso(numDann) {
        //Calcolo prima tutte le righe singole e poi i parametri finali
        let tempoCalcolaRighe = 75;
        this.strutturaStatiStimePrezziari.forEach(prezz => {
            if (document.getElementById(`tabPrezziario-D${numDann}-R-${prezz.r}_${this.props.IdInternoSinistro}`)) {
                tempoCalcolaRighe += 300;
                this.calcolaRigaStimaStatoUso(numDann, prezz.r, true, document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value);
            }
        })
        //dopo aver aggiornato ogni riga aggiorno la stima completa
        setTimeout(() => {
            this.calcolaStimaStatoUso(numDann, false)
        }, tempoCalcolaRighe);
    }
    calcolaRigaStimaStatoUso(dann, riga, fromAggiorna, codDetermDanno) {
        if (CustomJS.isNullEmpty(document.getElementById(`quantitaInput_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ||
            document.getElementById(`quantitaInput_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value <= 0) {
            document.getElementById(`quantitaInput_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 1;
        }
        //GESTIONE RIGA PREZZIARIO : (Degrado/Suppl.Indennizzo + Importo Degrado Supp.Indennizzo + Valore del Bene)
        var quantita = document.getElementById(`quantitaInput_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        var valUnitario = CustomJS.isNullEmpty(document.getElementById(`valUnitarioInput_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valUnitarioInput_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        var valNuovo = parseFloat((valUnitario * quantita).toFixed(2));
        document.getElementById(`valANuovoInput_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = valNuovo
        var percentuale = CustomJS.isNullEmpty(document.getElementById(`percDegrSupInd_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`percDegrSupInd_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        var importo = parseFloat(((valNuovo / 100) * percentuale).toFixed(2));
        if (percentuale === 0 || codDetermDanno === '002') {
            document.getElementById(`importoDegrSupInd_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
            document.getElementById(`ripristinato-D${dann}-R${riga}_${this.props.IdInternoSinistro}`).innerText = (codDetermDanno === '003' || codDetermDanno === '004' || codDetermDanno === '005' || codDetermDanno === '006') ? 'Ripristinato' : `Supplemento indennizzo non previsto`;
        }
        else {
            document.getElementById(`importoDegrSupInd_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = importo
            document.getElementById(`ripristinato-D${dann}-R${riga}_${this.props.IdInternoSinistro}`).innerText = ''
        }
        var valoreBene = parseFloat((valNuovo - importo).toFixed(2));
        document.getElementById(`valoreBeneResult_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = valoreBene;

        if ((codDetermDanno === '004' && valNuovo < valoreBene*2) || (codDetermDanno === '005' && valNuovo < valoreBene*3) ||
            (codDetermDanno === '006' && valNuovo < valoreBene*4)) {
            document.getElementById(`importoDegrSupInd_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = valNuovo - document.getElementById(`valoreBeneResult_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            document.getElementById(`ripristinato-D${dann}-R${riga}_${this.props.IdInternoSinistro}`).innerHTML = `Valore in uso calcolato:<br/><span style='font-weight: bolder;font-size:14px;'>€ ${codDetermDanno === '004' ? parseFloat(parseFloat(valoreBene*2).toFixed(2)).toLocaleString() : codDetermDanno === '005' ? parseFloat(parseFloat(valoreBene*3).toFixed(2)).toLocaleString() : parseFloat(parseFloat(valoreBene*4).toFixed(2)).toLocaleString()}</span> e quindi limitato al valore a nuovo`;
        }
        else if (codDetermDanno === '004' || codDetermDanno === '005' || codDetermDanno === '006') {
            document.getElementById(`importoDegrSupInd_D${dann}_R${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = (codDetermDanno === '004' ? valoreBene*2 : codDetermDanno === '005' ? valoreBene*3 : valoreBene*4) - valoreBene;
            document.getElementById(`ripristinato-D${dann}-R${riga}_${this.props.IdInternoSinistro}`).innerHTML = `Valore in uso calcolato:<br/><span style='font-weight: bolder;font-size:16px;'>€ ${codDetermDanno === '004' ? parseFloat(parseFloat(valoreBene*2).toFixed(2)).toLocaleString() : codDetermDanno === '005' ? parseFloat(parseFloat(valoreBene*3).toFixed(2)).toLocaleString() : parseFloat(parseFloat(valoreBene*4).toFixed(2)).toLocaleString()}</span>`;
        }

        if (fromAggiorna !== true) { //se non arriva dal tasto aggiorna
            this.calcolaStimaStatoUso(dann, false)
        }
    }
    calcolaStimaStatoUso(dann, fromLimite) {
        if (fromLimite === true) {
            this.cambiaLimiteSinistro(dann);
        }
        //GESTIONE RIGA DANNO COMPLESSIVO ACCERTATO (Valore a nuovo  + Degrado/Supp. Indennità + Valore in Uso)
        var dannComplAccValANuovo = 0;
        var dannComplAccImporto = 0;
        var dannComplAccValBene = 0;
        for (let index = 1; index < this.numTabPrezziari; index++) {
            if (document.getElementById(`tabPrezziario-D${dann}-R-${index}_${this.props.IdInternoSinistro}`)) {
                var valNuovoMom = CustomJS.isNullEmpty(document.getElementById(`valANuovoInput_D${dann}_R${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valANuovoInput_D${dann}_R${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                dannComplAccValANuovo += valNuovoMom;
                var importoMom = CustomJS.isNullEmpty(document.getElementById(`importoDegrSupInd_D${dann}_R${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`importoDegrSupInd_D${dann}_R${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                dannComplAccImporto += importoMom;
                var valoreBeneMom = CustomJS.isNullEmpty(document.getElementById(`valoreBeneResult_D${dann}_R${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreBeneResult_D${dann}_R${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                dannComplAccValBene += valoreBeneMom;
            }
        }
        dannComplAccValANuovo = dannComplAccValANuovo < 0 ? 0 : dannComplAccValANuovo;
        dannComplAccImporto = dannComplAccImporto < 0 ? 0 : dannComplAccImporto;
        dannComplAccValBene = dannComplAccValBene < 0 ? 0 : dannComplAccValBene;
        document.getElementById(`valoreANuovoDannComplessAcc_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dannComplAccValANuovo
        document.getElementById(`degradoPercDannComplessAcc_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dannComplAccImporto
        document.getElementById(`valoreBeneDannComplessAcc_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dannComplAccValBene

        //GESTIONE RIGA DOPO REGOLA PROPORZIONALE (Valore a nuovo  + Degrado/Supp. Indennità + Valore in Uso)
        for (let index = 1; index < this.numPreesistenze; index++) {
            if (document.getElementById(`nomePartita${index}_${this.props.IdInternoSinistro}`)) {
                if (document.getElementById(`nomePartita${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === document.getElementById(`partitaFromSin-D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                    if (document.getElementById(`formaGaranzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '2') {
                        var scoperturaTipo = document.getElementById(`scoperturaTipo-T${index}_${this.props.IdInternoSinistro}`).innerText;
                        if (scoperturaTipo === 'Scopertura') {
                            document.getElementById(`valoreANuovoDopoRegolaProp_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`valoreANuovoDannComplessAcc_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                            document.getElementById(`degrado25PercDopoRegolaProp_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`degradoPercDannComplessAcc_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                            document.getElementById(`dopoRegolaProporzionale_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`valoreBeneDannComplessAcc_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                        }
                        else if (scoperturaTipo === 'Scopertura su S.I.') {
                            document.getElementById(`dopoRegolaProporzionale_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`valoreBeneDannComplessAcc_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value

                            let dannoComplAccDegrado = document.getElementById(`degradoPercDannComplessAcc_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                            let scopertura = document.getElementById(`valoreScoperturaResult_T${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value

                            let dopoRegolaPropDegrado = dannoComplAccDegrado - (dannoComplAccDegrado * scopertura / 100);
                            document.getElementById(`degrado25PercDopoRegolaProp_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = parseFloat(dopoRegolaPropDegrado.toFixed(2))

                            let dopoRegolaPropUso = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                            dopoRegolaPropUso = CustomJS.isNullEmpty(dopoRegolaPropUso) ? 0 : dopoRegolaPropUso

                            let doporegolaPropNuovo = dopoRegolaPropUso + dopoRegolaPropDegrado;
                            document.getElementById(`valoreANuovoDopoRegolaProp_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = parseFloat(doporegolaPropNuovo.toFixed(2))
                        }
                        else if (scoperturaTipo === 'Scopertura su V.s.u') {
                            //degrado dopo rela prop sempre 0
                            document.getElementById(`degrado25PercDopoRegolaProp_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
                            //dopo regola prop a nuovo
                            let insuffAss = document.getElementById(`insuffAssicurativaPerc_T${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                            let dannComplAccNuovo = document.getElementById(`valoreANuovoDannComplessAcc_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                            let scoperturaSINuovo = (dannComplAccNuovo / 100) * insuffAss; //scopertura da sottrarre
                            let dopoRegolaPropNuovo = dannComplAccNuovo - scoperturaSINuovo;
                            document.getElementById(`valoreANuovoDopoRegolaProp_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = parseFloat(dopoRegolaPropNuovo.toFixed(2));
                            //stesso risultato anche su dopo regola prop a nuovo
                            document.getElementById(`dopoRegolaProporzionale_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = parseFloat(dopoRegolaPropNuovo.toFixed(2));
                        }
                    }
                    else if (document.getElementById(`formaGaranzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '1'
                             || document.getElementById(`formaGaranzia${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '3') {
                        document.getElementById(`valoreANuovoDopoRegolaProp_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`valoreANuovoDannComplessAcc_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                        document.getElementById(`degrado25PercDopoRegolaProp_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`degradoPercDannComplessAcc_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                        document.getElementById(`dopoRegolaProporzionale_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`valoreBeneDannComplessAcc_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                    }
                }
            }
        }
        //AGGIORNO FRANCHIGIA O SCOPERTO
        //la franchigia/scoperto condivisa/o chiama la >>>> limiteCondivisoStessaGaranzia
        // che chiama la >>>> calcolaStimaStatoUsoParteFinale  per ogni stima in un ciclo for
        if (document.getElementById(`isFranchigiaOrScoperto-D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'franchigia')
            this.nuovaCalcolaFranchigeCondivise();
        else
            this.nuovoScopertoCondiviso();

        setTimeout(() => {
            this.trasportaDatiRiepilogo();
            return;
        }, 500)
    }
    calcolaStimaStatoUsoParteFinale(dann, limite) {
        //GESTIONE RIGA DANNO NETTO (Valore a nuovo  + Degrado/Supp. Indennità + Valore in Uso)
        let valoreInUsoDRP = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
        valoreInUsoDRP = CustomJS.isNullEmpty(valoreInUsoDRP) ? 0 : valoreInUsoDRP

        var franchigia = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        franchigia = CustomJS.isNullEmpty(franchigia) ? 0 : franchigia;
        franchigia = parseFloat(franchigia);

        document.getElementById(`franchigiaValoreANuovo_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = franchigia;

        var valoreInUsoDN = valoreInUsoDRP - franchigia;
        valoreInUsoDN = valoreInUsoDN < 0 ? 0 : valoreInUsoDN;
        document.getElementById(`valoreStatoUsodannoNetto_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = valoreInUsoDN;

        let valoreANuovoDRP = CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`valoreANuovoDopoRegolaProp_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
        valoreANuovoDRP = CustomJS.isNullEmpty(valoreANuovoDRP) ? 0 : valoreANuovoDRP

        var valoreANuovoDN = valoreANuovoDRP - franchigia;
        valoreANuovoDN = valoreANuovoDN < 0 ? 0 : valoreANuovoDN;
        document.getElementById(`valoreANuovodannoNetto_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = valoreANuovoDN

        var degradoDN = valoreInUsoDN === 0 ? document.getElementById(`degrado25PercDopoRegolaProp_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : valoreANuovoDN - valoreInUsoDN;
        degradoDN = degradoDN < 0 ? 0 : degradoDN;
        document.getElementById(`degrado25PercdannoNetto_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = degradoDN;

        if (document.getElementById(`valoreStatoUsodannoNetto_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 0 && document.getElementById(`valoreANuovodannoNetto_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 0)
            document.getElementById(`degrado25PercdannoNetto_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0

        //GESTIONE LIMITE SULLA RIGA DANNO NETTO
        var valoreInUsoDN = document.getElementById(`valoreStatoUsodannoNetto_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;

        if (valoreInUsoDN > limite) {
            document.getElementById(`ceLimiteIndennizzoONo-D${dann}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
            document.getElementById(`limiteIndennizzo-D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = limite;
            document.getElementById(`valoreStatoUsodannoNetto_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = limite;
            document.getElementById(`valoreANuovodannoNetto_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = limite;
            document.getElementById(`degrado25PercdannoNetto_D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
        }
        else {
            document.getElementById(`limiteIndennizzo-D${dann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
            document.getElementById(`ceLimiteIndennizzoONo-D${dann}_${this.props.IdInternoSinistro}`).style.display = 'none'
        }
    }
    limiteCondivisoStessaGaranzia() {
        var arrayStime = []
        this.strutturaStatiStimeBase.forEach(stima => {
            var garanzia = document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            var idStima = document.getElementById(`idStima-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            var limite = document.getElementById(`limiteSinistro_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            if (CustomJS.isNullEmpty(limite)) {
                limite = 0;
                document.getElementById(`limiteSinistro_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
            }
            var dopoRegolaProp = 0;
            if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                dopoRegolaProp = CustomJS.isNullEmpty(dopoRegolaProp) ? 0 : dopoRegolaProp
            }
            else if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`valoreANuovoDopoRegolaProp_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                dopoRegolaProp = CustomJS.isNullEmpty(dopoRegolaProp) ? 0 : dopoRegolaProp
            }

            arrayStime.push({ garanzia: garanzia, idStima: idStima, limite: limite, dopoRegolaProp: dopoRegolaProp, numStima: stima.d })
        })
        var gruppiConStessaGaranzia = _.groupBy(arrayStime, 'garanzia');

        var chiaviGruppiGaranzia = Object.keys(gruppiConStessaGaranzia);
        for (let gruppoGaranzia of chiaviGruppiGaranzia) {
            var gruppiConStessoLimite = _.groupBy(gruppiConStessaGaranzia[gruppoGaranzia], 'limite');
            var chiaviGruppiLimite = Object.keys(gruppiConStessoLimite);
            for (let keyGruppoLimite of chiaviGruppiLimite) {
                var sommaDopoRegoleProp = 0;
                for (let stima of gruppiConStessoLimite[keyGruppoLimite]) {
                    sommaDopoRegoleProp += stima.dopoRegolaProp;
                }
                sommaDopoRegoleProp = isNaN(sommaDopoRegoleProp) ? 0 : sommaDopoRegoleProp;
                sommaDopoRegoleProp = sommaDopoRegoleProp < 0 ? 0 : sommaDopoRegoleProp;
                for (let stima of gruppiConStessoLimite[keyGruppoLimite]) {
                    var limiteCondiviso = parseFloat(((stima.limite / sommaDopoRegoleProp) * stima.dopoRegolaProp).toFixed(2));
                    limiteCondiviso = isNaN(limiteCondiviso) ? 0 : limiteCondiviso;
                    limiteCondiviso = CustomJS.isNullEmpty(limiteCondiviso) ? 0 : limiteCondiviso;
                    limiteCondiviso = limiteCondiviso < 0 ? 0 : limiteCondiviso;

                    if (document.getElementById(`determinazioneDanno_D${stima.numStima}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                        this.calcolaStimaValoreANuovoParteFinale(stima.numStima, limiteCondiviso)
                    }
                    else if (document.getElementById(`determinazioneDanno_D${stima.numStima}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                             || document.getElementById(`determinazioneDanno_D${stima.numStima}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                             || document.getElementById(`determinazioneDanno_D${stima.numStima}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                             || document.getElementById(`determinazioneDanno_D${stima.numStima}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                             || document.getElementById(`determinazioneDanno_D${stima.numStima}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                        this.calcolaStimaStatoUsoParteFinale(stima.numStima, limiteCondiviso)
                    }
                }
            }
        }

        setTimeout(() => {
            this.trasportaDatiRiepilogo();
        }, 250 * this.strutturaStatiStimeBase.length)
    }
    //STIMA STATO USO FINE---------------------------------

    //SCOPERTO E FRANCHIGIA INZIZIO------------
    nuovaCalcolaFranchigeCondivise() {
        var gruppi = Object.keys(this.franchigeCondivise); // restituisce un array con i nomi delle proprietà
        for (let gruppo of gruppi) { // itera sui nomi delle proprietà
            let franchigiaEuroCondivisa = 0;
            let sommaDopoRegoleProp = 0; // somma delle dopo regole proporzionali per ogni gruppo di franchige condivise 
            this.strutturaStatiStimeBase.forEach(stima => {
                for (let franch of this.franchigeCondivise[gruppo]) { // itera sull'array contenuto nella proprietà
                    if (franch.idStimaCondivisa === document.getElementById(`idStima-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                        var tipologia = franch.tipo.charAt(0).toUpperCase() + franch.tipo.slice(1) + " :";
                        if (this.franchigeCondivise[gruppo].length > 1) {
                            tipologia = tipologia + "\n" + "Valore Iniziale : " + franch.franchigia + " €";
                            tipologia = tipologia + " " + "(Condivisa)";
                        }
                        document.getElementById(`franchigiaOscopertura-D${stima.d}_${this.props.IdInternoSinistro}`).innerText = tipologia;

                        franchigiaEuroCondivisa = franch.franchigia;
                        var dopoRegolaProp = 0;
                        if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                            dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                            dopoRegolaProp = CustomJS.isNullEmpty(dopoRegolaProp) ? 0 : dopoRegolaProp
                        }
                        else if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                            let drp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                            drp = CustomJS.isNullEmpty(drp) ? 0 : drp

                            let valoreANuovoDRP = CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`valoreANuovoDopoRegolaProp_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value

                            dopoRegolaProp = CustomJS.isNullEmpty(valoreANuovoDRP) ? 0 : drp
                        }
                        sommaDopoRegoleProp += dopoRegolaProp;
                    }
                }
            })
            //formula : la franchigia diviso la somma delle dopo regole prop per la singola dopo regola proporzionale
            this.strutturaStatiStimeBase.forEach(stima => {
                for (let franch of this.franchigeCondivise[gruppo]) { // itera sull'array contenuto nella proprietà
                    if (franch.idStimaCondivisa === document.getElementById(`idStima-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                        var dopoRegolaPropStima = 0;
                        if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                            dopoRegolaPropStima = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                            dopoRegolaPropStima = CustomJS.isNullEmpty(dopoRegolaPropStima) ? 0 : dopoRegolaPropStima
                        }
                        else if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                            let drp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                            drp = CustomJS.isNullEmpty(drp) ? 0 : drp

                            let valoreANuovoDRP = CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`valoreANuovoDopoRegolaProp_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value

                            dopoRegolaPropStima = CustomJS.isNullEmpty(valoreANuovoDRP) ? 0 : drp;
                        }
                        var calcoloFinale = 0
                        if (sommaDopoRegoleProp > 0) //controllo perchè è impossibile dividere per 0
                            calcoloFinale = parseFloat(((franchigiaEuroCondivisa / sommaDopoRegoleProp) * dopoRegolaPropStima).toFixed(2));
                        else {
                            //altrimenti rimetto il valore di franchigia originale
                            calcoloFinale = franchigiaEuroCondivisa
                        }
                        //controlli finali
                        calcoloFinale = calcoloFinale < 0 ? 0 : calcoloFinale;
                        document.getElementById(`franchigia_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = calcoloFinale;
                        if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                            || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                            || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                            || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                            || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                            var franchigia = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                            franchigia = CustomJS.isNullEmpty(franchigia) ? 0 : franchigia;
                            document.getElementById(`franchigiaValoreANuovo_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = franchigia;
                        }
                    }
                }
            })
        }
        //SULLA STIMA PRESENTE BISOGNA AGGIORNARE ANCHE I VALORI DEI LIMITI E DEI TOTALI RIAGGIORNANDO LA STIMA
        this.limiteCondivisoStessaGaranzia();
    }

    nuovoScopertoCondiviso() {
        var gruppi = Object.keys(this.scopertiCondivisi);
        for (let gruppo of gruppi) {
            let sommaScoperture = 0; // somma degli scoperti calcolati dalle percentuali per ogni gruppo 
            let sommaDopoRegoleProp = 0; // somma delle dopo regole proporzionali per ogni gruppo di scoperti condivisi 
            let minimo = 0;
            let massimo = 0;
            this.strutturaStatiStimeBase.forEach(stima => {
                for (let franch of this.scopertiCondivisi[gruppo]) {
                    if (franch.idStimaCondivisa === document.getElementById(`idStima-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                        var tipologia = franch.tipo.charAt(0).toUpperCase() + franch.tipo.slice(1);
                        tipologia += `\n%${franch.scopertoPerc}, `;
                        tipologia += ` Min ${franch.scopertoMin} €, `;
                        tipologia += ` Max ${franch.scopertoMax} €`;
                        if (this.scopertiCondivisi[gruppo].length > 1)
                            tipologia = tipologia;
                        document.getElementById(`franchigiaOscopertura-D${stima.d}_${this.props.IdInternoSinistro}`).innerText = tipologia;

                        if (franch.scopertoPerc > 0) {
                            minimo = franch.scopertoMin;
                            massimo = franch.scopertoMax;
                            var dopoRegolaProp = 0;
                            //se c'è la percentuale dello scoperto anche se c'è la condivisione calcolo solo la percentuale sulla dopo regola prop della singola stima
                            //formula scopero percentuale : dopo regola prop diviso 100 per la percentuale dello scoperto
                            if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                                dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                                dopoRegolaProp = CustomJS.isNullEmpty(dopoRegolaProp) ? 0 : dopoRegolaProp
                            }
                            else if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                                let drp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                                drp = CustomJS.isNullEmpty(drp) ? 0 : drp

                                let valoreANuovoDRP = CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`valoreANuovoDopoRegolaProp_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value

                                dopoRegolaProp = CustomJS.isNullEmpty(valoreANuovoDRP) ? 0 : drp;
                            }
                            var scopertoPercRisultato = parseFloat(((dopoRegolaProp / 100) * franch.scopertoPerc).toFixed(2));
                            document.getElementById(`franchigia_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = scopertoPercRisultato;

                            sommaScoperture += scopertoPercRisultato;
                            sommaDopoRegoleProp += dopoRegolaProp;
                        }
                    }
                }
            })
            if (sommaScoperture > massimo) {
                if (massimo !== 0)
                    sommaScoperture = massimo;
            }
            if (sommaScoperture < minimo) {
                sommaScoperture = minimo;
            }
            let risultatoScoperto = 0;
            this.strutturaStatiStimeBase.forEach(stima => {
                for (let franch of this.scopertiCondivisi[gruppo]) {
                    if (franch.idStimaCondivisa === document.getElementById(`idStima-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                        var dopoRegolaProp = 0;
                        if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                            dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                            dopoRegolaProp = CustomJS.isNullEmpty(dopoRegolaProp) ? 0 : dopoRegolaProp
                        }
                        else if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                            let drp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                            drp = CustomJS.isNullEmpty(drp) ? 0 : drp
                            dopoRegolaProp = CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaProp_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : drp;
                        }
                        dopoRegolaProp = dopoRegolaProp < 0 ? 0 : dopoRegolaProp;

                        risultatoScoperto = (dopoRegolaProp / sommaDopoRegoleProp) * sommaScoperture;

                        document.getElementById(`franchigia_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = risultatoScoperto
                        if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                            || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                            || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                            || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                            || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                            var franchigia = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                            franchigia = CustomJS.isNullEmpty(franchigia) ? 0 : franchigia;
                            document.getElementById(`franchigiaValoreANuovo_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = franchigia;
                        }
                    }
                }
            })
        }
        //SULLA STIMA PRESENTE BISOGNA AGGIORNARE ANCHE I VALORI DEI LIMITI E DEI TOTALI RIAGGIORNANDO LA STIMA
        this.limiteCondivisoStessaGaranzia();
    }
    //SCOPERTO  E FRANCHIGIA FINE--------------

    //PREZZIARIO INIZIO-----------------------

    trasportaDatiPrezziarioAggiuntivi(numDann, numRiga, args) {
        var codEle = document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        setTimeout(() => {
            document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = codEle;
        }, 100)
        var unitaMisura = '';
        var prezzo = 0;
        var descrLunga = ''
        var codEleCategoria = '';

        let obj = _.find(this.dsPrezziarioCompleto, elm => {
            return elm.cod_ele === codEle;
        });

        if (!CustomJS.isNullEmpty(obj)) {
            unitaMisura = obj.MISURA;
            prezzo = obj.PREZZO;
            descrLunga = obj.DESCR_LUNGA;
            codEleCategoria = obj.COD_CATEGORIA;
        }

        if (this.isCompagniaGenerali())
            document.getElementById(`categoriaSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = codEleCategoria;
        document.getElementById(`UMInput_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = unitaMisura;
        document.getElementById(`valUnitarioInput_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = prezzo;
        document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = descrLunga;
    }
    cambiaDescrizione(numDann, numRiga) {
        var idFittizio = 'new' + numDann + numRiga + this.nuovoNumIdPrezziario;
        this.nuovoNumIdPrezziario++;
        var testo = '';
        var id = '';
        id = document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        if (document.getElementById(`btnCambiaDescriz-D${numDann}-R${numRiga}_${this.props.IdInternoSinistro}`).innerHTML === `<i class="fas fa-marker"></i>`) {
            //cambio icona
            document.getElementById(`btnCambiaDescriz-D${numDann}-R${numRiga}_${this.props.IdInternoSinistro}`).innerHTML = `<i class="fas fa-share"></i>`;

            testo = document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
            document.getElementById(`contentNuovaDescrizione-D${numDann}-R${numRiga}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
            document.getElementById(`contentDescrizioneBase-D${numDann}-R${numRiga}_${this.props.IdInternoSinistro}`).style.display = 'none';
            document.getElementById(`nuovaDescrizioneLibera-D${numDann}-R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = testo;
        }
        else if (document.getElementById(`btnCambiaDescriz-D${numDann}-R${numRiga}_${this.props.IdInternoSinistro}`).innerHTML === `<i class="fas fa-share"></i>`) {
            //cambio icona
            document.getElementById(`btnCambiaDescriz-D${numDann}-R${numRiga}_${this.props.IdInternoSinistro}`).innerHTML = `<i class="fas fa-marker"></i>`;

            testo = document.getElementById(`nuovaDescrizioneLibera-D${numDann}-R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            document.getElementById(`contentNuovaDescrizione-D${numDann}-R${numRiga}_${this.props.IdInternoSinistro}`).style.display = 'none';
            document.getElementById(`contentDescrizioneBase-D${numDann}-R${numRiga}_${this.props.IdInternoSinistro}`).style.removeProperty('display')
            document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
            if (CustomJS.isNullEmpty(id)) {
                document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource.push({
                    TEXT: testo,
                    VALUE: idFittizio
                })
                document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].refresh();
                setTimeout(() => {
                    document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = idFittizio
                    document.getElementById(`categoriaSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '0300007'
                }, 300)
            }
            else {
                _.find(document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource, elm => {
                    return elm.VALUE === id;
                }).TEXT = testo;
                document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].refresh();

                setTimeout(() => {
                    document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = id
                }, 200)
            }
        }
    }
    //PREZZIARIO FINE-------------------------

    //----------------------------CALCOLI COASSICURAZIONI INIZIO------------------------
    aggiornaCoassicurazione(num) {
        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`)) {
                this.calcolaRigaCoassicurazione(num, index, true)
            }
        }
        this.calcolaCoassicurazioneCompleta(num, false);
    }
    calcolaRigaCoassicurazione(num, riga, fromAggiorna) {
        var dannoARiparto = CustomJS.isNullEmpty(document.getElementById(`dannoARipartoRiparto1-D${num}-C${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoARipartoRiparto1-D${num}-C${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        var tipo = document.getElementById(`tipoLimitazioneContrattualeRiparto1-D${num}-C${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        var limitazione = CustomJS.isNullEmpty(document.getElementById(`limitazioneContrattualeRiparto1-D${num}-C${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`limitazioneContrattualeRiparto1-D${num}-C${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        var dannoNetto = 0;
        if (tipo === 'scoperto') {
            dannoNetto = (dannoARiparto / 100) * limitazione;
        }
        else if (tipo === 'limite_indennizzo') {
            if (dannoARiparto > limitazione)
                dannoNetto = limitazione;
            else
                dannoNetto = dannoARiparto
        }
        else if (tipo === 'franchigia') {
            dannoNetto = dannoARiparto - limitazione;
        }
        else if (tipo === 'nessuno') {
            document.getElementById(`limitazioneContrattualeRiparto1-D${num}-C${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
            dannoNetto = dannoARiparto;
        }
        document.getElementById(`dannoNettoCoassDirRiparto1-D${num}-C${riga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dannoNetto < 0 ? 0 : dannoNetto;
        if (fromAggiorna !== true) {
            setTimeout(() => {
                this.calcolaCoassicurazioneCompleta(num, false);
            }, 100)
        }
    }
    calcolaCoassicurazioneCompleta(num, fromcaricaDati) {
        var variabilePrimaRigaCoass = 0
        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`)) {
                variabilePrimaRigaCoass = index;
                break;
            }
        }
        var eseguiMath = this.calcolaSommaDanniCoassicurazione(num, fromcaricaDati);
        if (eseguiMath !== true) {
            this.calcolaFormulaCoassicurazione(num)
            if (fromcaricaDati !== true)
                document.getElementById(`indennizzoVsCompagnia_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`dannNettoRip2-D${num}-C${variabilePrimaRigaCoass}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannNettoRip2-D${num}-C${variabilePrimaRigaCoass}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
        }

        //SOLO NEI CASO IN CUI LA STIMA E' STATO USO
        if (document.getElementById(`determinazioneDanno_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
            || document.getElementById(`determinazioneDanno_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
            || document.getElementById(`determinazioneDanno_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
            || document.getElementById(`determinazioneDanno_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
            || document.getElementById(`determinazioneDanno_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
            var dannNettoValoreUso = document.getElementById(`valoreStatoUsodannoNetto_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            var indennizzoACarico = document.getElementById(`indennizzoVsCompagnia_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            if (dannNettoValoreUso < indennizzoACarico) {
                document.getElementById(`vsuCoass-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dannNettoValoreUso
                document.getElementById(`supplIndennitaCoass-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = parseFloat((indennizzoACarico - dannNettoValoreUso).toFixed(2))
            }
            else {
                document.getElementById(`vsuCoass-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`indennizzoVsCompagnia_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                document.getElementById(`supplIndennitaCoass-D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '0';
            }
        }
        setTimeout(() => {
            this.trasportaDatiRiepilogo();
        }, 300)

    }
    calcolaSommaDanniCoassicurazione(num, fromcaricaDati) {
        var sommaDanni = 0;
        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`)) {
                var dannoNetto = CustomJS.isNullEmpty(document.getElementById(`dannoNettoCoassDirRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoNettoCoassDirRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                sommaDanni += dannoNetto;
            }
        }
        //controllo
        var dannoRiparto = 0;
        var variabilePrimaRigaCoass = 0;
        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`)) {
                dannoRiparto = CustomJS.isNullEmpty(document.getElementById(`dannoARipartoRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoARipartoRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                variabilePrimaRigaCoass = index;
                break;
            }
        }
        document.getElementById(`sommaDanniRiparto1_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = sommaDanni < 0 ? 0 : sommaDanni
        if (sommaDanni < dannoRiparto) {
            for (let index = 0; index < this.numRigheCoassicurazione; index++) {
                if (document.getElementById(`compagniaCoinvoltaRiparto2-D${num}-C${index}_${this.props.IdInternoSinistro}`)) {
                    document.getElementById(`dannNettoRip2-D${num}-C${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
                    document.getElementById(`formulaCoass-D${num}-C${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '';
                }
            }
            document.getElementById(`indennizzoVsCompagnia_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
            document.getElementById(`sommaDanniRiparto2_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = 0;
            document.getElementById(`contentTutteLeRigheCompagnieRiparto2-D${num}_${this.props.IdInternoSinistro}`).style.display = 'none'
            document.getElementById(`contentSommaDanniRip2-D${num}_${this.props.IdInternoSinistro}`).style.display = 'none'
            document.getElementById(`contentIndVsCompRip2-D${num}_${this.props.IdInternoSinistro}`).style.display = 'none'
            return true;
        }
        else {
            if (fromcaricaDati !== true)
                document.getElementById(`indennizzoVsCompagnia_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`dannNettoRip2-D${num}-C${variabilePrimaRigaCoass}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannNettoRip2-D${num}-C${variabilePrimaRigaCoass}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
            document.getElementById(`contentTutteLeRigheCompagnieRiparto2-D${num}_${this.props.IdInternoSinistro}`).style.removeProperty('display')
            document.getElementById(`contentSommaDanniRip2-D${num}_${this.props.IdInternoSinistro}`).style.removeProperty('display')
            document.getElementById(`contentIndVsCompRip2-D${num}_${this.props.IdInternoSinistro}`).style.removeProperty('display')
            return false;
        }
    }
    calcolaFormulaCoassicurazione(num) {
        var variabilePrimaRigaCoass = 0;
        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`)) {
                variabilePrimaRigaCoass = index;
                break;
            }
        }
        var sommaDanniRip2 = 0;
        var dannoNettoRiga = 0;
        var numeratore = CustomJS.isNullEmpty(document.getElementById(`dannoARipartoRiparto1-D${num}-C${variabilePrimaRigaCoass}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoARipartoRiparto1-D${num}-C${variabilePrimaRigaCoass}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        var den = CustomJS.isNullEmpty(document.getElementById(`sommaDanniRiparto1_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`sommaDanniRiparto1_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;

        for (let index = 0; index < this.numRigheCoassicurazione; index++) {
            if (document.getElementById(`rigaRiparto2-D${num}-C${index}_${this.props.IdInternoSinistro}`)) {
                var moltipl = CustomJS.isNullEmpty(document.getElementById(`dannoNettoCoassDirRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoNettoCoassDirRiparto1-D${num}-C${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                document.getElementById(`formulaCoass-D${num}-C${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '' + numeratore + ' / ' + '' + den + ' ' + '* ' + moltipl;
                dannoNettoRiga = ((numeratore / den) * moltipl);
                document.getElementById(`dannNettoRip2-D${num}-C${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = parseFloat(dannoNettoRiga.toFixed(2));
                sommaDanniRip2 += dannoNettoRiga;
            }
        }
        document.getElementById(`sommaDanniRiparto2_D${num}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = parseFloat(sommaDanniRip2.toFixed(2));
    }
    cambiaCompagniaSuRip2(numDann, numRiga) {
        document.getElementById(`compagniaCoinvoltaRiparto2-D${numDann}-C${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`compagniaCoinvoltaRiparto1-D${numDann}-C${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
    }
    //----------------------------CALCOLI COASSICURAZIONI FINE--------------------------
    //-------------------------STIME CALCOLI FINE------------------------

    //---------------------------- CALCOLI RIEPILOGO INIZIO-----------------------
    trasportaDatiRiepilogo() {
        debugger;
        //Se la coassicurazione c'è allora metto il campo testo coassicurazione per unipol
        if (this.isCompagniaUnipol()) {
            //Cerco coassicurazione
            var almenoUnaCoass = false;
            for (var d = 1; d < this.numStime; d++) {
                if (document.getElementById(`tabellaStima-D${d}_${this.props.IdInternoSinistro}`)) {
                    if (document.getElementById(`coassicurazioneSi-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) {
                        almenoUnaCoass = true;
                        break;
                    }
                }
            }
            if (almenoUnaCoass === true)
                document.getElementById(`tableNoteCoassiurazioneUnipol_${this.props.IdInternoSinistro}`).style.removeProperty('display')
            else {
                document.getElementById(`noteCoassicurazioneUnipol_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '';
                document.getElementById(`tableNoteCoassiurazioneUnipol_${this.props.IdInternoSinistro}`).style.display = 'none'
            }
        }
        let sommaDannComplAc = 0;
        let limiteIndennizziStime = [];
        let objLimiteIndennizzi = {}
        for (let index0 = 1; index0 < this.numStime; index0++) {
            var limitODPR = 0;
            var bool = false;
            if (document.getElementById(`tabellaStima-D${index0}_${this.props.IdInternoSinistro}`)) {
                objLimiteIndennizzi = {
                    garanzia: document.getElementById(`garanziaFromSin-D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                    // danneggiato: document.getElementById(`danneggiatoFromSin-D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                    limite: document.getElementById(`limiteSinistro_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                }
                limiteIndennizziStime.push(objLimiteIndennizzi);
                if (document.getElementById(`determinazioneDanno_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                    || document.getElementById(`determinazioneDanno_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'                
                    || document.getElementById(`determinazioneDanno_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                    || document.getElementById(`determinazioneDanno_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                    || document.getElementById(`determinazioneDanno_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                    let dannComplAc = document.getElementById(`valoreANuovoDannComplessAcc_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    sommaDannComplAc += dannComplAc;
                    bool = true;

                    if (document.getElementById(`valoreANuovoDannComplessAcc_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value > document.getElementById(`limiteSinistro_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)
                        limitODPR = document.getElementById(`limiteIndennizzo-D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    else
                        limitODPR = document.getElementById(`valoreANuovoDannComplessAcc_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                }
                else if (document.getElementById(`determinazioneDanno_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                    let dannComplAc = document.getElementById(`dannoComplessAccert_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    sommaDannComplAc += dannComplAc;
                    bool = true;

                    let drp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    drp = CustomJS.isNullEmpty(drp) ? 0 : drp

                    if (drp > document.getElementById(`limiteSinistro_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)
                        limitODPR = CustomJS.isNullEmpty(document.getElementById(`limiteIndennizzo-D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`limiteIndennizzo-D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    else
                        limitODPR = drp;
                }
            }
            if (bool === true) {
                for (let index = 0; index < this.numTabelleRiepilogo; index++) {
                    for (let z = 0; z < this.numRigheRiepilogoGaranzie; z++) {
                        if (document.getElementById(`garanziadannRiep-D${index}-R-${z}_${this.props.IdInternoSinistro}`)) {
                            if (document.getElementById(`danneggiatoRiep-D${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === document.getElementById(`danneggiatoFromSin-D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                                if (document.getElementById(`garanziadannRiep-D${index}-R-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === document.getElementById(`garanziaFromSin-D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                                    if (document.getElementById(`partitadannRiep-D${index}-R-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === document.getElementById(`partitaFromSin-D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {

                                        var franchigia = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                        franchigia = CustomJS.isNullEmpty(franchigia) ? 0 : franchigia;

                                        document.getElementById(`franchigiaRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = franchigia;

                                        if (document.getElementById(`determinazioneDanno_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                                            || document.getElementById(`determinazioneDanno_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'                                        
                                            || document.getElementById(`determinazioneDanno_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                                            || document.getElementById(`determinazioneDanno_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                                            || document.getElementById(`determinazioneDanno_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                                            if (document.getElementById(`coassicurazioneSi-D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) {
                                                document.getElementById(`indennizzoLordoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`vsuCoass-D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`vsuCoass-D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                document.getElementById(`indennizzoNettoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`indennizzoVsCompagnia_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                document.getElementById(`supplIndennRiepilogo-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = document.getElementById(`supplIndennitaCoass-D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                            }
                                            else {
                                                let drp = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                drp = CustomJS.isNullEmpty(drp) ? 0 : drp
                                                document.getElementById(`indennizzoLordoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = drp;
                                                if (document.getElementById(`determinazioneDanno_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002')
                                                    document.getElementById(`indennizzoNettoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`valoreStatoUsodannoNetto_D${index0}_${this.props.IdInternoSinistro}`)) ? 0 : document.getElementById(`valoreStatoUsodannoNetto_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                else
                                                    document.getElementById(`indennizzoNettoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`valoreANuovodannoNetto_D${index0}_${this.props.IdInternoSinistro}`)) ? 0 : document.getElementById(`valoreANuovodannoNetto_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;

                                                let drpDegrado = CustomJS.isNullEmpty(document.getElementById(`degrado25PercDopoRegolaPropMAN_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`degrado25PercDopoRegolaProp_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`degrado25PercDopoRegolaPropMAN_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                drpDegrado = CustomJS.isNullEmpty(drpDegrado) ? 0 : drpDegrado;
                                                document.getElementById(`supplIndennRiepilogo-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = drpDegrado;

                                                //if (document.getElementById(`valoreStatoUsodannoNetto_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 0) 
                                                    document.getElementById(`indennizzoNettoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = Math.max(0, document.getElementById(`indennizzoLordoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value - document.getElementById(`franchigiaRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value + document.getElementById(`supplIndennRiepilogo-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value);
                                            }
                                        }
                                        else if (document.getElementById(`determinazioneDanno_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                                            document.getElementById(`indennizzoLordoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = limitODPR;
                                            document.getElementById(`indennizzoNettoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = CustomJS.isNullEmpty(document.getElementById(`dannoNetto_D${index0}_${this.props.IdInternoSinistro}`)) ? 0 : document.getElementById(`dannoNetto_D${index0}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        var indennizziNettiRiepilogo = []
        var objIndennizzo = {}
        for (let index = 0; index < this.numTabelleRiepilogo; index++) {
            var sommaLordo = 0;
            var sommaFranchigie = 0
            var sommaSupplIndenn = 0
            for (let z = 0; z < this.numRigheRiepilogoGaranzie; z++) {
                if (document.getElementById(`garanziadannRiep-D${index}-R-${z}_${this.props.IdInternoSinistro}`)) {
                    objIndennizzo = {
                        garanzia: document.getElementById(`garanziadannRiep-D${index}-R-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                        indennizzo: document.getElementById(`indennizzoNettoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                    }
                    indennizziNettiRiepilogo.push(objIndennizzo)
                    var indLordo = CustomJS.isNullEmpty(document.getElementById(`indennizzoLordoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`indennizzoLordoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    sommaLordo += indLordo;
                    var franch = document.getElementById(`franchigiaRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    sommaFranchigie += franch;
                    var indenn = document.getElementById(`supplIndennRiepilogo-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    sommaSupplIndenn += indenn;
                }
            }
            //CONFRONTO I LIMITI CON GLI INDENNIZZI INIZIO
            //Tolgo i doppioni dall' array limiteIndennizziStime che hanno lo stesso danneggiato, garanzia e limite uguali
            const unique2 = limiteIndennizziStime.filter((obj, index) => {
                return index === limiteIndennizziStime.findIndex(o => obj.garanzia === o.garanzia && obj.limite === o.limite);
            });
            limiteIndennizziStime = unique2;

            var sommeGaranziaDanneggiato = _.groupBy(indennizziNettiRiepilogo, 'garanzia');
            //CICLO LE GARANZIE
            let sommeIndennizziPerGaranzia = []
            for (var i = 0; i < Object.keys(sommeGaranziaDanneggiato).length; i++) {
                var arrayPerGaranzia = sommeGaranziaDanneggiato[Object.keys(sommeGaranziaDanneggiato)[i]];
                //CICLO GLI INDENNIZZI
                let sommaIndennizziPerGaranzia = 0;
                arrayPerGaranzia.forEach(elm => {
                    sommaIndennizziPerGaranzia += elm.indennizzo;
                });
                sommeIndennizziPerGaranzia.push({
                    garanzia: arrayPerGaranzia[0].garanzia,
                    sommaIndennizzi: sommaIndennizziPerGaranzia
                })
            }
            var sommeTotaliIndennizzi = 0;
            for (let z = 0; z < this.numRigheRiepilogoGaranzie; z++) {
                if (document.getElementById(`garanziadannRiep-D${index}-R-${z}_${this.props.IdInternoSinistro}`)) {
                    limiteIndennizziStime.forEach(elm => {
                        sommeIndennizziPerGaranzia.forEach(elm2 => {
                            if (elm.garanzia === elm2.garanzia) {
                                if (elm2.sommaIndennizzi > elm.limite) {
                                    var indennizzoNetto = document.getElementById(`indennizzoNettoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                    var indennizzoNettoNuovo = (indennizzoNetto / elm2.sommaIndennizzi) * elm.limite;
                                    document.getElementById(`indennizzoNettoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = indennizzoNettoNuovo;
                                }
                            }
                        })
                    })
                    sommeTotaliIndennizzi += document.getElementById(`indennizzoNettoRiep-D${index}-${z}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                }
            }

            //CONFRONTO I LIMITI CON GLI INDENNIZZI FINE
            if (document.getElementById(`rigaTotaliDanneggiatiRiepilogo-D${index}_${this.props.IdInternoSinistro}`)) {
                document.getElementById(`totaleIndennizzo-D${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = sommeTotaliIndennizzi
                document.getElementById(`totaleIndennizzoLordo-D${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = sommaLordo
                document.getElementById(`totaleFranchige-D${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = sommaFranchigie
                document.getElementById(`totaleSuppIndenn-D${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = sommaSupplIndenn
            }
        }
        var sommaTotIndennLordi = 0;
        var sommaTotFranchScop = 0;
        var sommaTotSupllIndennita = 0;
        var sommaTotIndennNetti = 0;

        for (let index = 0; index < this.numTabelleRiepilogo; index++) {
            if (document.getElementById(`rigaTotaliDanneggiatiRiepilogo-D${index}_${this.props.IdInternoSinistro}`)) {
                sommaTotIndennLordi += document.getElementById(`totaleIndennizzoLordo-D${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                sommaTotFranchScop += document.getElementById(`totaleFranchige-D${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                sommaTotSupllIndennita += document.getElementById(`totaleSuppIndenn-D${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                sommaTotIndennNetti += document.getElementById(`totaleIndennizzo-D${index}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            }
        }

        if (CustomJS.isNullEmpty(document.getElementById(`totaleIndennizziLordi_${this.props.IdInternoSinistro}`)))
            return;

        document.getElementById(`totaleIndennizziLordi_${this.props.IdInternoSinistro}`).ej2_instances[0].value = sommaTotIndennLordi
        document.getElementById(`totaleFranchigeScoperti_${this.props.IdInternoSinistro}`).ej2_instances[0].value = sommaTotFranchScop
        document.getElementById(`totaleSupplIndennita_${this.props.IdInternoSinistro}`).ej2_instances[0].value = sommaTotSupllIndennita
        document.getElementById(`totaleIndennizziNetti_${this.props.IdInternoSinistro}`).ej2_instances[0].value = sommaTotIndennNetti

        var arrotondo = Math.round(sommaTotIndennNetti)
        arrotondo = this.arrotondaIndennizziNettiComplessivi(arrotondo)
        document.getElementById(`totaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`).ej2_instances[0].value = arrotondo
        document.getElementById(`totaleDanniComplessiviAccertati_${this.props.IdInternoSinistro}`).ej2_instances[0].value = sommaDannComplAc

        var indennDopoEventualeScop = parseFloat((sommaTotIndennLordi - sommaTotFranchScop).toFixed(2));
        document.getElementById(`indennizzoDopoEventualeScop_${this.props.IdInternoSinistro}`).ej2_instances[0].value = indennDopoEventualeScop < 0 ? 0 : indennDopoEventualeScop
    }

    arrotondaIndennizziNettiComplessivi(arrotondo) {
        const replaceLast = (str, pattern, replacement) => {
            const match =
                typeof pattern === 'string'
                    ? pattern
                    : (str.match(new RegExp(pattern.source, 'g')) || []).slice(-1)[0];
            if (!match) return str;
            const last = str.lastIndexOf(match);
            return last !== -1
                ? `${str.slice(0, last)}${replacement}${str.slice(last + match.length)}`
                : str;
        };
        var stringa = arrotondo.toString();
        var last = stringa.charAt(stringa.length - 1)
        stringa = replaceLast(stringa, last, '0');
        return stringa;
    }
    //----------------------------CALCOLI RIEPILOGO FINE---------------------------


    render() {
        return (<React.Fragment>
            <DialogComponent id={`dialogPrezziario_${this.props.IdInternoSinistro}`} cssClass='dialog-sipa' showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog}
                ref={dialog => this.dialogInstance0 = dialog} isModal={true} >
                <div id={`dialogPrezziarioContent_${this.props.IdInternoSinistro}`}></div>
            </DialogComponent>
            <DialogComponent id={`dialogEliminaCalcoli_${this.props.IdInternoSinistro}`} showCloseIcon={true} animationSettings={this.animationSettings} visible={false}
                ref={dialog => this.dialogInstanceElimina = dialog} isModal={true}>
                <div id={`containerDialogEliminaCalcoli_${this.props.IdInternoSinistro}`}></div>
            </DialogComponent>
            <div id='uploadFotografieDocumentiStime' style={{display: 'none'}}><UploaderComponent autoUpload={true} ref={upload => {this.uploadObj = upload }} asyncSettings={this.asyncSettings} uploading={this.onUploading} 
                    success={this.onUploadSuccess.bind(this)} allowedExtensions='.jpeg, .jpg, .png, .jfif, .gif, .tiff, .tif' multiple={false} />
            </div>        
            <div>{this.state.showLightbox ? <Lightbox image={this.lightboxImage} onClose={()=>this.setState({showLightbox: false})}></Lightbox> : null}</div> 
            <div className='container-fluid'>
                <div className="control-section card-control-section basic_card_layout" >
                    <div className="e-card-resize-container">
                        <div className="row card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ width: '99%' }}>
                                <div tabIndex="0" className="e-card" id="main_card" style={{ backgroundColor: 'whitesmoke', borderRadius: '20px', border: '2px solid #8fb3d2', marginTop: '10px', marginLeft: '5px' }}>
                                    <div className="e-card-content" style={{ backgroundColor: 'whitesmoke' }}>
                                        <div style={{ marginLeft: '1.75%' }} className='idSinistroIncaricoElab'>
                                            <p title='Sinistro importato' className='importatoGen' id={`sinistroImportatoGenElab2_${this.props.IdInternoSinistro}`}></p>
                                            <h4 style={{fontFamily: 'Muli, sans-serif', fontWeight: 'bolder'}} id={`idIncaricoTestaElab2_${this.props.IdInternoSinistro}`}>ID Incarico : {this.props.IdIncarico}</h4>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center" >
                                                <h4 id={`avvisoPeriziaModificata_${this.props.IdInternoSinistro}`} style={{ color: 'red' }} className="card-title"></h4>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-end" >
                                                {/* <ButtonComponent title='ELIMINA CALCOLI' style={{ marginRight: '3%' }} cssClass='e-fill e-danger' type='button' onClick={this.dialogEliminaCalcoli.bind(this)}>ELIMINA CALCOLI</ButtonComponent> */}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <form id={`formElaboratoPeritaleCalcoli_${this.props.IdInternoSinistro}`}>
                                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center" >
                                                    <h3 className="card-title" style={{ color: 'black', fontWeight: '500' }}>Preesistenze</h3>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-left">
                                                    <div className='box-tutte-le-tabelle' id={`contentTutteTabellePreesistenze_${this.props.IdInternoSinistro}`}>
                                                        {/* CONTENUTO DINAMICO DI TUTTE LE PREESISTENZE */}
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-left" style={{ marginTop: '50px', textAlign: 'center' }}>
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-left">
                                                        <h3 className="card-title" style={{ color: 'black', fontWeight: '500', marginBottom: '25px' }}>Stime</h3>
                                                    </div>
                                                    <div className='box-tutte-le-tabelle' id={`tabellaCompletaTutteLeStime_${this.props.IdInternoSinistro}`}>
                                                        {/* CONTENUTO DINAMICO DI TUTTE LE STIME */}
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-left" style={{ marginTop: '50px', textAlign: 'center' }}>
                                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-left">
                                                    <h3 className="card-title" style={{ color: 'black', fontWeight: '500', marginBottom: '25px' }}>Riepilogo</h3>
                                                </div>
                                                <div className='box-tutte-le-tabelle'>
                                                    <div id={`contentAggiornaRiep_${this.props.IdInternoSinistro}`} style={{ textAlign: 'right', padding: '1%' }}>
                                                        <ButtonComponent id={`aggiornaRiepilogo_${this.props.IdInternoSinistro}`} cssClass='e-fill e-info' type='button' onClick={this.trasportaDatiRiepilogo.bind(this)}>Aggiorna Riepilogo</ButtonComponent>
                                                    </div>
                                                    <div id={`tabellaCompletaTutteIRiepiloghi_${this.props.IdInternoSinistro}`}>
                                                        {/* CONTENUTO DINAMICO DEL RIEPILOGO */}
                                                    </div>
                                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                                        <h5>Totali Complessivi</h5>
                                                    </div>
                                                    <table className='perizia-table riepilogoTotale' >
                                                        <tr>
                                                            <th ></th>
                                                            <th >Totale indennizzi lordi</th>
                                                            <th >Totale Franchige/Scoperti</th>
                                                            <th >Totale Supplementi Indennità</th>
                                                            <th >Totale indennizzi Netti</th>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ textAlign: 'right' }}>Totali Complessivi Danneggiati : </th>
                                                            <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleIndennizziLordi_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                                                            <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleFranchigeScoperti_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                                                            <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleSupplIndennita_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                                                            <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleIndennizziNetti_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                                                        </tr>
                                                    </table>
                                                    <table className='perizia-table riepilogoTotale2' >
                                                        <tr>
                                                            <th></th>
                                                            <th>Totale Danni Complessivi Accertati</th>
                                                            <th></th>
                                                            <th>Indennizzo Dopo Eventuale Scopertura</th>
                                                            <th>Totale Indennizzi Netti Complessivi Per Arrotondamento</th>
                                                        </tr>

                                                        <tr>
                                                            <th style={{ textAlign: 'right' }}>Altri Totali Complessivi :</th>
                                                            <th><NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleDanniComplessiviAccertati_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                                                            <th></th>
                                                            <th> <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`indennizzoDopoEventualeScop_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent ></th>
                                                            <th>
                                                                <div className='row'>
                                                                    <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10">
                                                                        <NumericTextBoxComponent showSpinButton={false} readonly={true} id={`totaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent >
                                                                        <div id={`boxArrotondamentoMAN_${this.props.IdInternoSinistro}`} style={{ display: 'none' }}>
                                                                            Valore manuale (definitivo)
                                                                            <NumericTextBoxComponent alt='Cambio Valore Manuale' showSpinButton={false} id={`totaleIndennNettoComplApprossRiepMAN_${this.props.IdInternoSinistro}`} floatLabelType='Auto' format="c2" decimals='2' cssClass='e-outline'></NumericTextBoxComponent >
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                        <ButtonComponent style={{ color: 'red' }} id={`sbloccaTotaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`} alt='Sblocca Totale Arrotondamento' cssClass='e-fill' type='button' onClick={this.totaleIndennizzoManuale.bind(this)}><i class="fas fa-pen"></i></ButtonComponent>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </table>
                                                    <table id={`tableNoteCoassiurazioneUnipol_${this.props.IdInternoSinistro}`} style={{ display: 'none' }} className='perizia-table riepilogoTotale' >
                                                        <tr>
                                                            <th>
                                                                <TextBoxComponent id={`noteCoassicurazioneUnipol_${this.props.IdInternoSinistro}`} placeholder="Note Coassicurazione Unipol" floatLabelType="Auto" cssClass='e-outline' />
                                                            </th>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div id={`containerInformazioniRichiesteDallaCompagniaGenerali2_${this.props.IdInternoSinistro}`} style={{ display: this.isCompagniaGenerali() ? '' : 'none' }}>
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center" >
                                                        <h3 className="card-title" style={{ color: 'black', fontWeight: '500' }}>Informazioni Richieste dalla Compagnia</h3>
                                                    </div>
                                                    <div className='box-tutte-le-tabelle' id={`containerNuovoTutteLeListBox_${this.props.IdInternoSinistro}`}>
                                                        {/* QUI TUTTI I LISTBOX NUOVI DELLE INFO RICHIESTE*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center" >
                                            <h3 className="card-title" style={{ color: 'black', fontWeight: '500' }}>Definizione</h3>
                                        </div>
                                        <div className="row card-layout" style={{ marginTop: '2%' }}>
                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                    <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                        <div className='row'>
                                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                                <h4>Note</h4>
                                                                <RichTextEditorComponent id={`noteDefinizione_${this.props.IdInternoSinistro}`} showCharCount={true} enableHtmlEncode={true} placeholder=''
                                                                    maxLength={2000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='500px' >
                                                                    <RTEInject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar]} />
                                                                </RichTextEditorComponent>
                                                                <ButtonComponent id={`eliminaNoteDefinizione_${this.props.IdInternoSinistro}`} style={{ marginTop: '5%' }} onClick={() => { document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value = '' }} cssClass='e-fill e-danger' type='button'>Elimina Testo</ButtonComponent>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                <div tabIndex="0" className="e-card" id="main_card" style={{ borderRadius: '20px', border: '1px solid #bad0e3' }}>
                                                    <div className="e-card-content" style={{ backgroundColor: '#edf0fa' }}>
                                                        <div className='row' >
                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ textAlign: 'center' }}>
                                                                <ButtonComponent id={`btnDefinizioneDefault_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneDefinizione.bind(this, 'default')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Default</ButtonComponent>
                                                            </div>
                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ textAlign: 'center' }}>
                                                                <ButtonComponent id={`btnDefinizioneAttesaAtto_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneDefinizione.bind(this, 'attesaAtto')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>In Attesa di Atto</ButtonComponent>
                                                            </div>
                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ textAlign: 'center' }}>
                                                                <ButtonComponent id={`btnDefinizioneAccordoVerbale_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneDefinizione.bind(this, 'accordoVerbale')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Accordo Verbale</ButtonComponent>
                                                            </div>
                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ textAlign: 'center' }}>
                                                                <ButtonComponent id={`btnDefinizioneAtto_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneDefinizione.bind(this, 'atto')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Atto</ButtonComponent>
                                                            </div>
                                                        </div>
                                                        <div className='row'>    
                                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                                <ListBoxComponent fields={this.ddlFieldsList} selectionSettings={{ mode: "Multiple", showCheckbox: true }} allowFiltering={true} filterType='Contains' id={`notePeritaliList_${this.props.IdInternoSinistro}`} dataSource={this.notePeritaliListData} height='400px'>
                                                                    <Inject services={[CheckBoxSelection]} />
                                                                </ListBoxComponent>
                                                                <div style={{ textAlign: 'right', marginTop: '20px' }}>
                                                                    <ButtonComponent id={`btnGeneraDescrizioneNoteOperazPeritali_${this.props.IdInternoSinistro}`} onClick={this.generaDescrizioneOperazioniPeritali.bind(this, 'listaNotePeritali')} alt='Genera Descrizione' cssClass='e-fill e-info' type='button'>Genera Descrizione</ButtonComponent>
                                                                </div>
                                                            </div>                                    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>   
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                <ButtonComponent id={`btnInizioPag1${this.props.IdInternoSinistro}`} type='button' cssClass='e-info' created={this.onCreatedBtnInizioPag}>Vai ad inizio pagina</ButtonComponent>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: '7%' }}></div>
            <MDBFooter id={`footerElaboratoP1_${this.props.IdInternoSinistro}`} bgColor='light' className='fixed-bottom ' style={{ marginLeft: '65px', zIndex: 10 }}>
                <div className='row' style={{ backgroundColor: 'rgb(239, 239, 239)', borderTop: '1px solid #202351' }}>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-start">
                        <ButtonComponent id={`dettSinPag2_${this.props.IdInternoSinistro}`} style={{ marginLeft: '5%' }} alt='Pagina Dettaglio Sinistro' cssClass='e-fill e-info' type='button' onClick={this.cambiaPaginaFooter.bind(this, 'Dettaglio', false)}>←← Dettaglio Sinistro</ButtonComponent>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 text-center">
                        <h3 id={`statoNonModificabilePeritoP2_${this.props.IdInternoSinistro}`} style={{ color: 'red' }}></h3>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-end">
                        <ButtonComponent id={`elabPeritalePag2_${this.props.IdInternoSinistro}`} alt='Pagina Elaborato Peritale' cssClass='e-fill e-info' type='button' onClick={this.cambiaPaginaFooter.bind(this, 'ElaboratoP1', false)}>← Elaborato Peritale</ButtonComponent>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 text-end">
                        <ButtonComponent id={`salvaEsciP2_${this.props.IdInternoSinistro}`} style={{ marginRight: '5%' }} alt='Salva ed Esci' cssClass='e-fill e-success' type='button' onClick={this.requiredCampiTabelle.bind(this)}>← Salva ed Esci</ButtonComponent>
                    </div>
                </div>
            </MDBFooter>
        </React.Fragment>);
    }

    totaleIndennizzoManuale() {
        if (document.getElementById(`sbloccaTotaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`).innerHTML === '<i class="fas fa-pen"></i>') {
            document.getElementById(`sbloccaTotaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-undo"></i>'
            document.getElementById(`sbloccaTotaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`).style.color = "blue";
            document.getElementById(`boxArrotondamentoMAN_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        }
        else if (document.getElementById(`sbloccaTotaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`).innerHTML === '<i class="fas fa-undo"></i>') {
            document.getElementById(`sbloccaTotaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-pen"></i>'
            document.getElementById(`sbloccaTotaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`).style.color = "red";
            document.getElementById(`boxArrotondamentoMAN_${this.props.IdInternoSinistro}`).style.display = 'none';
            document.getElementById(`totaleIndennNettoComplApprossRiepMAN_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
        }
    }

    franchigiaManuale(numDann) {
        if (document.getElementById(`btnFranchigiaManuale_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML === '<i class="fas fa-pen"></i>') {
            document.getElementById(`btnFranchigiaManuale_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-undo"></i>';
            document.getElementById(`btnFranchigiaManuale_D${numDann}_${this.props.IdInternoSinistro}`).style.color = 'blue';
            document.getElementById(`boxFranchigiaManuale_D${numDann}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        }
        else if (document.getElementById(`btnFranchigiaManuale_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML === '<i class="fas fa-undo"></i>') {
            document.getElementById(`btnFranchigiaManuale_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-pen"></i>';
            document.getElementById(`btnFranchigiaManuale_D${numDann}_${this.props.IdInternoSinistro}`).style.color = 'red';
            document.getElementById(`boxFranchigiaManuale_D${numDann}_${this.props.IdInternoSinistro}`).style.display = 'none';
            document.getElementById(`franchigiaMAN_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
            if (document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001')
                this.calcolaStimaValoreANuovo(numDann, false);
            else if (document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006')
                this.calcolaStimaStatoUso(numDann, false);
        }
    }

    dopoRegolaManuale(numDann) {

        if (document.getElementById(`btnDopoRegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML === '<i class="fas fa-pen"></i>') {
            document.getElementById(`btnDopoRegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-undo"></i>';
            document.getElementById(`btnDopoRegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).style.color = 'blue';
            document.getElementById(`boxDopoRegolaManuale_D${numDann}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        }
        else if (document.getElementById(`btnDopoRegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML === '<i class="fas fa-undo"></i>') {
            document.getElementById(`btnDopoRegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-pen"></i>';
            document.getElementById(`btnDopoRegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).style.color = 'red';
            document.getElementById(`boxDopoRegolaManuale_D${numDann}_${this.props.IdInternoSinistro}`).style.display = 'none';

            document.getElementById(`dopoRegolaProporzionaleMAN_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
            if (document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001')
                this.calcolaStimaValoreANuovo(numDann, false);
            else if (document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006')
                this.calcolaStimaStatoUso(numDann, false);
        }
    }
    dopoRegolaManualeValANuovo(numDann) {
        if (document.getElementById(`btnValANuovoDoporegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML === '<i class="fas fa-pen"></i>') {
            document.getElementById(`btnValANuovoDoporegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-undo"></i>';
            document.getElementById(`btnValANuovoDoporegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).style.color = 'blue';
            document.getElementById(`boxValANuovoDopoRegola_D${numDann}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        }
        else if (document.getElementById(`btnValANuovoDoporegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML === '<i class="fas fa-undo"></i>') {
            document.getElementById(`btnValANuovoDoporegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-pen"></i>';
            document.getElementById(`btnValANuovoDoporegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).style.color = 'red';
            document.getElementById(`boxValANuovoDopoRegola_D${numDann}_${this.props.IdInternoSinistro}`).style.display = 'none';

            document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
            if (document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001')
                this.calcolaStimaValoreANuovo(numDann, false);
            else if (document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006')
                this.calcolaStimaStatoUso(numDann, false);
        }
    }

    dopoRegolaManualeDegrado(numDann) {
        if (document.getElementById(`btnDegrado25PercDopoRegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML === '<i class="fas fa-pen"></i>') {
            document.getElementById(`btnDegrado25PercDopoRegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-undo"></i>';
            document.getElementById(`btnDegrado25PercDopoRegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).style.color = 'blue';
            document.getElementById(`boxDegrado25DopoRegola_D${numDann}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
        }
        else if (document.getElementById(`btnDegrado25PercDopoRegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML === '<i class="fas fa-undo"></i>') {
            document.getElementById(`btnDegrado25PercDopoRegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-pen"></i>';
            document.getElementById(`btnDegrado25PercDopoRegolaMAN_D${numDann}_${this.props.IdInternoSinistro}`).style.color = 'red';
            document.getElementById(`boxDegrado25DopoRegola_D${numDann}_${this.props.IdInternoSinistro}`).style.display = 'none';

            document.getElementById(`degrado25PercDopoRegolaPropMAN_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = null;
            if (document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001')
                this.calcolaStimaValoreANuovo(numDann, false);
            else if (document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                     || document.getElementById(`determinazioneDanno_D${numDann}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006')
                this.calcolaStimaStatoUso(numDann, false);
        }
    }

    generaDescrizioneDefinizione(scelta) {
        var totale = CustomJS.isNullEmpty(document.getElementById(`totaleIndennNettoComplApprossRiepMAN_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`totaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`totaleIndennNettoComplApprossRiepMAN_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
        var testo = ''
        if (scelta === 'default') {
            if (this.riservaCe === true)
                // se c'è la riserva :
                testo = `Al termine degli accertamenti peritali riteniamo che il sinistro non rientri nelle garanzie di polizza in quanto sussistono riserve di operatività.`
            else
                // se non c'è la riserva :
                testo = `Al termine degli accertamenti peritali riteniamo che il sinistro rientri nelle garanzie di polizza in quanto non sussistono riserve di operatività.`
        }
        else if (scelta === 'attesaAtto')
            testo = `Per il presente sinistro abbiamo inviato all'amm.re pro tempore del condomino  assicurato atto con RISERVA che ad oggi non ci è stato restituito perfezionato.`
        else if (scelta === 'accordoVerbale')
            testo = `Il danno stimato è stato concordato verbalmente nella persona dell'amm.re pro-tempore sig. ${this.contraenteRiserva}.  per un importo pari a  ${totale} €  al netto di franchigia.`
        else if (scelta === 'atto')
            testo = `Il danno è stato definito mediante Atto con il sig. ${this.contraenteRiserva} in qualità di amm.re pro tempore del condomino assicurato per un importo pari a ${totale} € al netto delle franchigie.`
        document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value = testo;
    }
    generaDescrizioneOperazioniPeritali(tipologia, args) {
        let testo = ''
        if (tipologia === 'standard') {
            testo = `Ricevuto l\' incarico, abbiamo conferito con l \'Assicurato e fissato un sopralluogo presso l\'ubicazione del rischio assicurato per il giorno ${this.datiPerNote.dataSopralluogo}.\n In tale occasione si prendeva visione dei luoghi e si acquisiva la documentazione necessaria all\'espletamento delle indagini peritali`;
        }
        else if (tipologia === 'videoPerizia') {
            testo = 'Ricevuto l\'incarico, contattavamo il Contraente e ci accordavamo per effettuare la videoperizia.\n Attraverso tale strumento si prendeva visione dei danni e successivamente si acquisiva la documentazione necessaria all\'espletamento delle indagini peritali.';
        }
        else if (tipologia === 'listaNotePeritali') {
            let lista = document.getElementById(`notePeritaliList_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            testo = document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
            lista.forEach(elm => {
                testo += `${this.notePeritaliListData.find(({ id }) => id === elm)?.nota}`; 
            });
        }
        document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value = testo;
    }
    //------------------------------------FUNZIONI CREAZIONE CARICA SALVA INIZIO------------------------------------
    async componentDidMount() {
        document.getElementById(`dettSinPag2_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
        document.getElementById(`elabPeritalePag2_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
        document.getElementById(`salvaEsciP2_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
        this.toggleBlocking2(true);
        await this.nuovaCreaTabelle();
        this.toggleBlocking2(false);
        setTimeout(() => {
            this.nuovaCaricaDati()
        }, 200)
    }
    async nuovaCreaTabelle() {
        return new Promise((resolveCreaTabelle) => {
            //RIEPILOGO TIPI DI TABELLE 
            //----------------------GENERALI----------------------
            //PARTITE/PREESISTENZE = 3 In base alla Partita con Forma Garanzia : 'VALORE INTERO' = '2'
            //1)'FABBRICATO' = 'TP016'  (Fabbricato)
            //2)'CONTENUTO DIMORA SALTUARIA' = 'TP005' || 'EFFETTI DOMESTICI' = 'TP014' || 'EFFETTI DOMESTICI NELL''ABITAZIONE' = 'TP015' (Effetti domestici)
            //3)RESTANTI
            //Le Tabelle Preesistenza sono racchiuse tutte in un unica funzione render : tabellaPreesistenzaBase e gestite nella caricaDati

            //PARTITE/PREESISTENZE = 1 In base alla Forma Garanzia : 'P.R.A.' = '1' con qualunque partita
            //STIME/DANNI : 2 in base alla Determinazione del Danno
            //1)'Valore a Nuovo' = '001' BASE
            //2)'Valore allo stato d''uso e supplemento di indennizzo' = '003' COMPLESSA

            //----------------------DEFAULT----------------------

            //Id sinistro ed eventuale Id Incarico Top Pagina
            if (this.isCompagniaGenerali()) {
                document.getElementById(`sinistroImportatoGenElab2_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-arrow-circle-up"></i>'
                document.getElementById(`idIncaricoTestaElab2_${this.props.IdInternoSinistro}`).innerHTML = 'ID Incarico : ' + this.props.IdIncarico;
            }
            else if (this.isCompagniaUnipol()) {
                document.getElementById(`idIncaricoTestaElab2_${this.props.IdInternoSinistro}`).innerHTML = CustomJS.isNullEmpty(this.props.IdIncaricoUnipol) ? '' : ('ID Incarico : ' + this.props.IdIncaricoUnipol);
                document.getElementById(`sinistroImportatoGenElab2_${this.props.IdInternoSinistro}`).innerHTML = '<i class="fas fa-arrow-circle-up"></i>'
            }
            else {
                document.getElementById(`idIncaricoTestaElab2_${this.props.IdInternoSinistro}`).innerHTML = 'ID Incarico : N/A';
                document.getElementById(`sinistroImportatoGenElab2_${this.props.IdInternoSinistro}`).style.display = 'none';
            }

            //Dichiarazione Array per Info richieste
            var infoRichPartite = [];
            var infoRichGaranzie = [];
            var infoRichStime = []
            const queryPartite = `SELECT distinct T1.*, T2.id_partita as guid_partita FROM [11002] T1 left outer join [12006] T2 on T1.id_partitaPeriziaOnline = T2.id_partita_jftech WHERE T1.id_sinistro = ${this.props.IdSinistro}`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryPartite, '', retDataPartite => {
                //ordino le partite
                var nuovoRetDataPartite = [];
                retDataPartite.forEach(elm => {
                    if (elm.id_partita === '1' || elm.id_partita === 'TP016')
                        nuovoRetDataPartite.unshift(elm)
                    else
                        nuovoRetDataPartite.push(elm)
                })
                retDataPartite = nuovoRetDataPartite;
                let funcListPartite = [];

                //creo le tabelle delle preesistenze dalle partite
                retDataPartite.forEach(dataPartita => {
                    const esegui = () => {
                        return new Promise(async (resolvePartita) => {
                            //Inserimento id_partita per Info Richieste
                            infoRichPartite.push(dataPartita.id_partita)
                            //FORMA GARANZIA : 'VALORE A NUOVO'
                            if (dataPartita.formaGaranzia === '2') {
                                await this.tabellaPreesistenzaBase(dataPartita.id_partita, dataPartita.descrizione, dataPartita.formaGaranzia, dataPartita.guid_partita, dataPartita.id_partitaPeriziaOnline, dataPartita.sommaAssicurataPartita);
                            }
                            //PRIMO RISCHIO ASSOLUTO
                            else if (dataPartita.formaGaranzia === '1') {
                                await this.tabellaPreesistenzaPRA(dataPartita.id_partita, dataPartita.descrizione, dataPartita.formaGaranzia, dataPartita.guid_partita, dataPartita.id_partitaPeriziaOnline);
                            }
                            else if (dataPartita.formaGaranzia === '3') {
                                await this.tabellaPreesistenzaVNNP(dataPartita.id_partita, dataPartita.descrizione, dataPartita.formaGaranzia, dataPartita.guid_partita, dataPartita.id_partitaPeriziaOnline);
                            }

                            resolvePartita('Prossima');
                        })
                    }
                    funcListPartite.push(esegui);
                })

                const eseguiCiclicaPartite = (ind) => {
                    if (CustomJS.isNullEmpty(funcListPartite[ind])) {
                        //------------FINITO DI CREARE LE PREESISTENZE-----------
                        //per evitare le partite doppie con descrizione diversa
                        infoRichPartite = [...new Map(infoRichPartite?.map(item => [item, item])).values()];
                    }
                    else {
                        funcListPartite[ind]().then(ret => {
                            if (ret === 'Prossima') {
                                eseguiCiclicaPartite(ind + 1);
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
                eseguiCiclicaPartite(0);
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
            //CARICO LE GARANZIE NELL' ARRAY PER LE INFO RICHIESTE
            const queryGaranzie = `SELECT * FROM [11003] WHERE id_sinistro = ${this.props.IdSinistro};`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryGaranzie, '', retDataGaranzie => {
                retDataGaranzie.forEach(garanzia => {
                    //Inserimento id_garanzia per Info Richieste
                    infoRichGaranzie.push(garanzia.id_garanzia);
                })
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
            const queryStime = `SELECT distinct T4.id_danno,T4.id_danneggiato as guid_danneggiato,T1.*,T2.id_garanzia as cod_ele_garanzia, T2.id_determinazioneDanno, T2.limitePer,T2.franchigiaCondivisa,T2.franchigiaEuro,T2.scopertoPerc,T2.scopertoMin,T2.scopertoMax, T3.nominativo AS danneggiato, T5.id_partita as cod_ele_partita,T5.descrizione as descrizione_partita FROM [11004] T1 left outer join [11003] T2 on T1.id_garanzia = T2.id_garanziaPeriziaOnline left outer join [11002] T5 on T1.id_partita = T5.id_partitaPeriziaOnline left outer join [00005] T3 on T1.id_danneggiato = T3.id_contatto left outer join [12011] T4 on T1.id_stima = T4.id_stima_jftech where T1.id_sinistro = ${this.props.IdSinistro};`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryStime, '', async (retDataStime) => {
                var danneggiati = [];
                var partite = [];
                var garanzie = [];

                const fineCreaStime = () => {
                    return new Promise((resolveFineStime) => {
                        let funcListStime = [];
                        retDataStime.forEach((dataStima, idx) => {
                            const eseguiStima = () => {
                                return new Promise(async (resolveStima) => {
                                    //Inserimento combo stima per Info Richieste
                                    infoRichStime.push({ idStima: dataStima.id_stima, idDanno: dataStima.id_danno, idDanneggiato: dataStima.id_danneggiato, idPartita: dataStima.cod_ele_partita, idGaranzia: dataStima.cod_ele_garanzia })
                                    if (this.isCompagniaGenerali()) {
                                        //Localizzazione
                                        var servizioStringa2 = ''
                                        let obj = _.find(this.dsGaranzie, elm => {
                                            return elm.VALUE === dataStima.cod_ele_garanzia;
                                        });
                                        if (!CustomJS.isNullEmpty(obj))
                                            servizioStringa2 = obj.servizio_str2;

                                        var codEle = '';
                                        let obj2 = _.find(this.nuovoArrInfoRichDanno, elm => {
                                            return elm.servizio_str2 === servizioStringa2;
                                        });
                                        if (!CustomJS.isNullEmpty(obj2))
                                            codEle = obj2.cod_ele;

                                        var arrayLocalizzazione = []
                                        this.nuovoArrRispInfoRichDanno.forEach(elm => {
                                            if (elm.servizio_str2 === codEle)
                                                arrayLocalizzazione.push({ VALUE: elm.cod_ele, TEXT: elm.descriz });
                                        });
                                        this.allArrayLocalizzazioneForeachGaranzia.push({ garanzia: dataStima.cod_ele_garanzia, array: arrayLocalizzazione });
                                    }
                                    //Danneggiati
                                    this.dsDanneggiati.push({ TEXT: CustomJS.isNullEmpty(dataStima.danneggiato) ? (dataStima.nome + ' ' + dataStima.cognome) : dataStima.danneggiato, VALUE: dataStima.id_danneggiato, GUID: dataStima.guid_danneggiato });

                                    //Gestione franchigia o scoperto
                                    var franchigiaOScoperto = ''; // default
                                    if (dataStima.scopertoPerc !== 0 || dataStima.scopertoMin !== 0 || dataStima.scopertoMax !== 0)
                                        franchigiaOScoperto = 'scoperto';
                                    else if (dataStima.scopertoPerc === 0 && dataStima.scopertoMin === 0 && dataStima.scopertoMax === 0)
                                        franchigiaOScoperto = 'franchigia';

                                    //Creazione Stime in base alla Determinazione del danno //
                                    if (dataStima.id_determinazioneDanno === '001') {
                                        await this.tabellaStimaValoreANuovo(dataStima.id_stima, dataStima.id_danno, dataStima.id_danneggiato, dataStima.cod_ele_partita, dataStima.descrizione_partita, dataStima.cod_ele_garanzia, dataStima.id_determinazioneDanno, dataStima.limitePer, franchigiaOScoperto, dataStima, idx);
                                    }
                                    else if (dataStima.id_determinazioneDanno === '002'
                                             || dataStima.id_determinazioneDanno === '003'
                                             || dataStima.id_determinazioneDanno === '004'
                                             || dataStima.id_determinazioneDanno === '005'
                                             || dataStima.id_determinazioneDanno === '006') {
                                        await this.tabellaStimaStatoUso(dataStima.id_stima, dataStima.id_danno, dataStima.id_danneggiato, dataStima.cod_ele_partita, dataStima.descrizione_partita, dataStima.cod_ele_garanzia, dataStima.id_determinazioneDanno, dataStima.limitePer, franchigiaOScoperto, dataStima, idx);
                                    }
                                    //utili per la creazione della tabella Riepilogo
                                    danneggiati.push(dataStima.id_danneggiato);
                                    partite.push(dataStima.cod_ele_partita);
                                    garanzie.push(dataStima.cod_ele_garanzia);

                                    resolveStima('Prossima');
                                })
                            }
                            funcListStime.push(eseguiStima);
                        })

                        const eseguiCiclicaStime = (ind) => {
                            if (CustomJS.isNullEmpty(funcListStime[ind])) {
                                resolveFineStime();
                            }
                            else {
                                funcListStime[ind]().then(ret => {
                                    if (ret === 'Prossima') {
                                        eseguiCiclicaStime(ind + 1);
                                    }
                                }).catch(err => {
                                    console.log(err)
                                })
                            }
                        }
                        eseguiCiclicaStime(0);
                    });
                }
                await fineCreaStime()

                const fineRiepilogo = () => {
                    return new Promise((resolveFineRiepilogo) => {

                        //Creazione Riepilogo
                        var arrayComboStime = {
                            danneggiati: danneggiati,
                            partite: partite,
                            garanzie: garanzie
                        }
                        //Eliminazione doppioni nell' array : arrayComboStime.danneggiati
                        var distinctDanneggiati = arrayComboStime.danneggiati;
                        distinctDanneggiati = [...new Map(distinctDanneggiati?.map(item => [item, item])).values()];

                        let funcListDanneggiati = [];
                        for (let dd = 0; dd < distinctDanneggiati.length; dd++) {
                            const eseguiDanneggiato = () => {
                                return new Promise(async (resolveDanneggiato) => {
                                    await this.tabellaRiepilogo0(distinctDanneggiati[dd], arrayComboStime);
                                    resolveDanneggiato('Prossima')
                                })
                            }
                            funcListDanneggiati.push(eseguiDanneggiato);
                        }
                        const eseguiCiclica = (ind) => {
                            if (CustomJS.isNullEmpty(funcListDanneggiati[ind])) {
                                resolveFineRiepilogo();
                            }
                            else {
                                funcListDanneggiati[ind]().then(ret => {
                                    if (ret === 'Prossima') {
                                        eseguiCiclica(ind + 1);
                                    }
                                }).catch(err => {
                                    console.log(err)
                                })
                            }
                        }
                        eseguiCiclica(0);
                    });
                }
                await fineRiepilogo();

                if (this.isCompagniaUnipol()) {
                    const queryCoassUnipol = (`SELECT noteCoassicurazioniIndirette FROM [10004] where id_sinistro = ${this.props.IdSinistro};`);
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryCoassUnipol, '', retDataCoassDescr => {
                        document.getElementById(`noteCoassicurazioneUnipol_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataCoassDescr[0]?.noteCoassicurazioniIndirette;
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }, true, true);
                }
                resolveCreaTabelle();
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        });
    }
    async nuovaCaricaDati() {
        this.toggleBlocking2();

        const finePreesistenze = () => {
            return new Promise((resolveFinePreesistenze) => {
                const queryPreesistenze = `SELECT distinct T1.*,T3.id_preesistenza as id_preesistenza_gen,T4.id_partita as guid_partita_gen FROM [11006] T1 left outer join [11002] T2 on T1.id_partitaPeriziaOnline = T2.id_partitaPeriziaOnline left outer join [12007] T3 on T1.id_preesistenza = T3.id_preesistenza_jftech left outer join [12006] T4 on T4.id_partita_jftech = T2.id_partitaPeriziaOnline WHERE T1.id_sinistro = ${this.props.IdSinistro}`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryPreesistenze, '', retDataPrees => {
                    var idPreesistenze = []
                    let funcListPreesistenze = [];
                    retDataPrees.forEach((dataPrees, idxDataPrees) => {
                        this.strutturaStatiPreesistenzeBase.forEach(prees => {
                            if (document.getElementById(`tabellaCompletaPreesistenza-T${prees.t}_${this.props.IdInternoSinistro}`)) {
                                if (dataPrees.id_partitaPeriziaOnline === document.getElementById(`idPartitaPO-T${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                                    //Tabella preesistenza trovata
                                    const eseguiPrees = () => {
                                        return new Promise((resolvePreesistenza) => {
                                            if (document.getElementById(`formaGaranzia${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '2') {
                                                var isPreesistenzaCaricata = false;
                                                idPreesistenze.forEach((id) => {
                                                    if (id === dataPrees.id_partitaPeriziaOnline)
                                                        isPreesistenzaCaricata = true;
                                                })
                                                if (isPreesistenzaCaricata === false) {
                                                    //Utile per distinguere le tabelle fabbricato (3 totali)
                                                    var tipoTabella = document.getElementById(`nomePartita${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                    var arrayIdPrees = [{ TEXT: dataPrees.id_partitaPeriziaOnline, VALUE: dataPrees.id_partitaPeriziaOnline, GUID_PARTITA_GEN: dataPrees.guid_partita_gen }]
                                                    document.getElementById(`idPartitaPO-T${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource = arrayIdPrees;
                                                    document.getElementById(`idPartitaPO-T${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataPrees.id_partitaPeriziaOnline;

                                                    document.getElementById(`sommaAssicurataInput_T${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataPrees.somma_assicurata;
                                                    document.getElementById(`derogaInput_T${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataPrees.deroga
                                                    document.getElementById(`valoreDerogatoResult_T${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataPrees.valore_derogato
                                                    document.getElementById(`valoreTotEsistNuovoResult_T${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataPrees.totale_esistenza_nuovo
                                                    document.getElementById(`valoreScoperturaResult_T${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataPrees.scopertura_tot_esist_nuovo
                                                    document.getElementById(`valoreDegradoInput_T${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataPrees.degrado_stato_uso
                                                    document.getElementById(`valoreTotEsistStatoUsoInput_T${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataPrees.totale_esistenza_stato_uso
                                                    document.getElementById(`valoreScoperturaStatoUso_T${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataPrees.scopertura_stato_uso
                                                    document.getElementById(`insuffAssicurativaPerc_T${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataPrees.insufficienza_assicurativa_perc
                                                    document.getElementById(`messaggioNote-T${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataPrees.noteInsuffAss
                                                    document.getElementById(`notePreesistenza-T${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataPrees.notePreesistenza

                                                    //la struttura degli stati preesistenza vine creato sempre nuovo nella creaTabelle
                                                    this.strutturaStatiPreesistenzeBase.forEach(elm => {
                                                        if (elm.id === `tabellaCompletaPreesistenza-T${prees.t}_${this.props.IdInternoSinistro}`) {
                                                            elm.stato = 'o';
                                                            elm.stato_iniziale = 'o';
                                                            elm.keyJftech = dataPrees.id_preesistenza;
                                                            elm.keyGen = dataPrees.id_preesistenza_gen
                                                        }
                                                    });
                                                    //Per ogni preesistenza esistente cerco i record delle righe nella _preesistenza_costruzioni associati alla GENERALI_partita_preesistenze e nella _preesistenza_descrizioni associate solo alla _preesistenza_jftech
                                                    //--------------COSTRUZIONI--------------
                                                    const queryPreesCostruzioni = `SELECT  T1.*,T2.id_preesistenza as id_preesistenza_gen FROM [11007] T1 left outer join [12007] T2 on T2.id_prees_costruz_jftech = T1.id_preesistenza_costruzione WHERE T1.id_preesistenza = ${dataPrees.id_preesistenza}`;
                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryPreesCostruzioni, '', async (retDataCostruz) => {
                                                        if (retDataCostruz.length > 0) {
                                                            retDataCostruz.forEach(async (dataCostruzione, idxCostruz) => {
                                                                if (idxDataPrees === 0 && idxCostruz === 0)
                                                                    await this.aggiungiRigaCostruzionePreesistenza(prees.t, dataCostruzione, true);
                                                                else
                                                                    await this.aggiungiRigaCostruzionePreesistenza(prees.t, dataCostruzione, false);
                                                            })
                                                        }
                                                        else {
                                                            //Solo la tabella preesistenza fabbricato (TP016) ha le righe delle costruzioni collegate a Generali
                                                            if (tipoTabella === 'TP016' || tipoTabella === '1')
                                                                //Aggiungo una riga (default della tabella fabbricato)
                                                                if (idxDataPrees === 0)
                                                                    await this.aggiungiRigaCostruzionePreesistenza(prees.t, '', true);
                                                                else
                                                                    await this.aggiungiRigaCostruzionePreesistenza(prees.t, '', false);
                                                        }
                                                        //--------------descrizIONI--------------
                                                        const queryPreesDescrizioni = `SELECT * FROM [11008] WHERE id_preesistenza = ${dataPrees.id_preesistenza};`;
                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryPreesDescrizioni, '', async (retDataDescr) => {
                                                            if (retDataDescr.length > 0) {
                                                                retDataDescr.forEach(async (dataDescr) => {
                                                                    await this.aggiungiRigaDescrizionePreesistenza(prees.t, dataDescr, '');
                                                                })
                                                            }
                                                            else {
                                                                if (tipoTabella === 'TP016' || tipoTabella === '1') {
                                                                    await this.aggiungiRigaDescrizionePreesistenza(prees.t, '', '');
                                                                }
                                                                //tabella effetti domestici ( 3 righe di descrizioni)
                                                                else if (tipoTabella === 'TP005' || tipoTabella === 'TP014' || tipoTabella === 'TP015' || tipoTabella === '2') {
                                                                    var arrayEffettiDomestici = ['Elettrodomestici', 'Oggetti Personali', 'Gioielli'];
                                                                    for (let index = 0; index < arrayEffettiDomestici.length; index++) {
                                                                        await this.aggiungiRigaDescrizionePreesistenza(prees.t, '', arrayEffettiDomestici[index]);
                                                                    }
                                                                }
                                                                else {
                                                                    await this.aggiungiRigaDescrizionePreesistenza(prees.t, '', '');
                                                                }
                                                            }
                                                            idPreesistenze.push(dataPrees.id_partitaPeriziaOnline)
                                                            resolvePreesistenza('Prossima')
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            });
                                                        }, true, true);
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    }, true, true);
                                                }
                                                else {
                                                    idPreesistenze.push(dataPrees.id_partitaPeriziaOnline)
                                                    resolvePreesistenza('Prossima')
                                                }
                                            }
                                            else {
                                                resolvePreesistenza('Prossima')
                                            }
                                        })
                                    }
                                    funcListPreesistenze.push(eseguiPrees);
                                }
                            }
                        })
                    });

                    const eseguiCiclica = (ind) => {
                        if (CustomJS.isNullEmpty(funcListPreesistenze[ind])) {
                            //Se non ci sono record nelle preesistenze genero le righe costruzioni e descrizioni iniziali di ogni tipo di preesistenza
                            let funcListPreesistenze = [];
                            this.strutturaStatiPreesistenzeBase.forEach(prees => {
                                const eseguiPrees = () => {
                                    return new Promise(async (resolvePreesistenza) => {
                                        if (prees.stato === 'n') {
                                            if (document.getElementById(`tabellaCompletaPreesistenza-T${prees.t}_${this.props.IdInternoSinistro}`)) {
                                                //Utile per distinguere le tabelle fabbricato (3 totali)
                                                var tipoTabella = document.getElementById(`nomePartita${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                var formaGaranzia = document.getElementById(`formaGaranzia${prees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                if ((tipoTabella === 'TP016' || tipoTabella === '1') && formaGaranzia !== '1') {
                                                    //Aggiungo una riga costruzione e una descrizione (default della tabella fabbricato)
                                                    if (prees.t === 1)
                                                        await this.aggiungiRigaCostruzionePreesistenza(prees.t, '', true);
                                                    else
                                                        await this.aggiungiRigaCostruzionePreesistenza(prees.t, '', false);

                                                    await this.aggiungiRigaDescrizionePreesistenza(prees.t, '', '');
                                                }
                                                else if ((tipoTabella === 'TP005' && formaGaranzia !== '1')
                                                        || (tipoTabella === 'TP014' && formaGaranzia !== '1') 
                                                        || (tipoTabella === 'TP015' && formaGaranzia !== '1') 
                                                        || (tipoTabella === '2' && formaGaranzia !== '1')) {
                                                            var arrayEffettiDomestici = ['Elettrodomestici', 'Oggetti Personali', 'Gioielli'];
                                                            for (let index = 0; index < arrayEffettiDomestici.length; index++) {
                                                                await this.aggiungiRigaDescrizionePreesistenza(prees.t, '', arrayEffettiDomestici[index]);
                                                            }
                                                }
                                                else {
                                                    if (formaGaranzia !== '1')
                                                        await this.aggiungiRigaDescrizionePreesistenza(prees.t, '', '');
                                                }
                                                resolvePreesistenza('Prossima')
                                            }
                                            else {
                                                resolvePreesistenza('Prossima')
                                            }
                                        }
                                        else {
                                            resolvePreesistenza('Prossima')
                                        }
                                    })
                                }
                                funcListPreesistenze.push(eseguiPrees);
                            })
                            const eseguiCiclica = (ind) => {
                                if (CustomJS.isNullEmpty(funcListPreesistenze[ind])) {
                                    resolveFinePreesistenze();
                                }
                                else {
                                    funcListPreesistenze[ind]().then(ret => {
                                        if (ret === 'Prossima') {
                                            eseguiCiclica(ind + 1);
                                        }
                                    }).catch(err => {
                                        console.log(err)
                                    })
                                }
                            }
                            eseguiCiclica(0);
                        }
                        else {
                            funcListPreesistenze[ind]().then(ret => {
                                if (ret === 'Prossima') {
                                    eseguiCiclica(ind + 1);
                                }
                            }).catch(err => {
                                console.log(err)
                            })
                        }
                    }
                    eseguiCiclica(0);

                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
            });
        }
        await finePreesistenze();

        const fineCaricamentoStime = () => {
            return new Promise((resolveFineStime) => {
                //CARICA STIME
                const queryStime = `SELECT T3.id_causale,T3.codice as codiceCausale, T2.id_danno,T1.* FROM [11004] T1 left outer join [12011] T2 on T1.id_stima = T2.id_stima_jftech left outer join [12017] T3 on T2.id_danno = T3.id_danno WHERE T1.id_sinistro = ${this.props.IdSinistro}`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryStime, '', retDataStime => {
                    let funcListStime = [];
                    retDataStime.forEach(dataStima => {
                        this.strutturaStatiStimeBase.forEach(stima => {
                            if (document.getElementById(`tabellaStima-D${stima.d}_${this.props.IdInternoSinistro}`)) {
                                if (document.getElementById(`idStima-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === dataStima.id_stima) {
                                    //stima trovata
                                    const eseguiStima = () => {
                                        return new Promise((resolveStima) => {
                                            //Campi comuni ai due tipi di Stima esistenti
                                            document.getElementById(`limiteSinistro_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.limite_sinistro
                                            document.getElementById(`piano-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.piano;
                                            if (dataStima.franchigiaSalvata === true || dataStima.franchigiaSalvata === 1) {
                                                this.periziaModificata = true; //se il campo è true allora la perizia è stata salvata almeno una volta
                                                document.getElementById(`franchigia_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.franchigiaOScoperto
                                                if (!CustomJS.isNullEmpty(dataStima.franchigiaOScopertoMAN) && dataStima.franchigiaOScopertoMAN !== 0) {
                                                    document.getElementById(`btnFranchigiaManuale_D${stima.d}_${this.props.IdInternoSinistro}`).click();
                                                    document.getElementById(`franchigiaMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.franchigiaOScopertoMAN;
                                                }
                                            }
                                            document.getElementById(`ripristiniUltimSi-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = dataStima.ripristini_ultimati === true ? true : false;
                                            document.getElementById(`ripristiniUltimNo-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = dataStima.ripristini_ultimati === false ? true : false;
                                            document.getElementById(`accordoAniaSi-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = dataStima.accordo_ania === true ? true : false
                                            document.getElementById(`accordoAniaNo-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = dataStima.accordo_ania === false ? true : false
                                            document.getElementById(`noteRipristini-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.note_ripristini_ultimati;
                                            document.getElementById(`esitoDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.esito_danno;
                                            if (!CustomJS.isNullEmpty(dataStima.id_causale) && dataStima.id_causale !== 0) {
                                                document.getElementById(`boxCausaliNonIndennizzo_D${stima.d}_${this.props.IdInternoSinistro}`).style.visibility = 'visible';
                                                document.getElementById(`causaliNonIndennizzo_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.codiceCausale?.trim();
                                                this.strutturaStatiCausali.push({ idDanno: dataStima.id_danno, idCausale: dataStima.id_causale })
                                            }
                                            if (dataStima.limiteIndennizzo !== -1 && !CustomJS.isNullEmpty(dataStima.limiteIndennizzo)) {
                                                document.getElementById(`ceLimiteIndennizzoONo-D${stima.d}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
                                                document.getElementById(`limiteIndennizzo-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.limiteIndennizzo
                                            }
                                            if (dataStima.esito_danno === '1' || dataStima.esito_danno === '2' || dataStima.esito_danno === '3' || dataStima.esito_danno === '4') {
                                                document.getElementById(`boxDannoNettoMAN_D${stima.d}_${this.props.IdInternoSinistro}`).style.visibility = 'visible';
                                                document.getElementById(`dannoNettoMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.danno_netto_manuale;
                                            }
                                            //Tabella Valore a Nuovo
                                            if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                                                document.getElementById(`dannoComplessAccert_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.danno_compless_accertato
                                                if (!CustomJS.isNullEmpty(dataStima.dopo_regola_proporzionaleMAN) && dataStima.dopo_regola_proporzionaleMAN !== 0) {
                                                    document.getElementById(`btnDopoRegolaMAN_D${stima.d}_${this.props.IdInternoSinistro}`).click();
                                                    document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.dopo_regola_proporzionaleMAN;
                                                }
                                                document.getElementById(`dopoRegolaProporzionale_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.dopo_regola_proporzionale
                                                document.getElementById(`dannoNetto_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.danno_netto
                                            }
                                            //Tabella Stato Uso
                                            else if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                                                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'                                                
                                                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                                                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                                                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                                                var franchigia = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                franchigia = CustomJS.isNullEmpty(franchigia) ? 0 : franchigia;
                                                document.getElementById(`franchigiaValoreANuovo_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = franchigia;

                                                document.getElementById(`valoreANuovoDannComplessAcc_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.DCA_valore_a_nuovo
                                                document.getElementById(`degradoPercDannComplessAcc_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.DCA_degrado25
                                                document.getElementById(`valoreBeneDannComplessAcc_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.DCA_valore_stato_uso

                                                if (!CustomJS.isNullEmpty(dataStima.DRP_valore_a_nuovoMAN) && dataStima.DRP_valore_a_nuovoMAN !== 0) {
                                                    document.getElementById(`btnValANuovoDoporegolaMAN_D${stima.d}_${this.props.IdInternoSinistro}`).click();
                                                    document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.DRP_valore_a_nuovoMAN;
                                                }
                                                document.getElementById(`valoreANuovoDopoRegolaProp_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.DRP_valore_a_nuovo

                                                if (!CustomJS.isNullEmpty(dataStima.DRP_degrado25MAN) && dataStima.DRP_degrado25MAN !== 0) {
                                                    document.getElementById(`btnDegrado25PercDopoRegolaMAN_D${stima.d}_${this.props.IdInternoSinistro}`).click();
                                                    document.getElementById(`degrado25PercDopoRegolaPropMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.DRP_degrado25MAN;
                                                }
                                                document.getElementById(`degrado25PercDopoRegolaProp_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.DRP_degrado25

                                                if (!CustomJS.isNullEmpty(dataStima.DRP_valore_stato_usoMAN) && dataStima.DRP_valore_stato_usoMAN !== 0) {
                                                    document.getElementById(`btnDopoRegolaMAN_D${stima.d}_${this.props.IdInternoSinistro}`).click();
                                                    document.getElementById(`dopoRegolaProporzionaleMAN_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.DRP_valore_stato_usoMAN;
                                                }
                                                document.getElementById(`dopoRegolaProporzionale_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.DRP_valore_stato_uso

                                                document.getElementById(`valoreANuovodannoNetto_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.DN_valore_a_nuovo
                                                document.getElementById(`degrado25PercdannoNetto_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.DN_degrado25
                                                document.getElementById(`valoreStatoUsodannoNetto_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.DN_valore_stato_uso
                                            }
                                            //-------------descrizIONI PREZZIARIO------------
                                            const queryStimeDescr = `SELECT T2.id_dettaglio,T3.id_info_richiesta, T1.* FROM [11009] T1 left outer join [12013] T2 on T1.id_stima_descrizione = T2.id_stima_descrizione left outer join [12016] T3 on T2.id_dettaglio = T3.id_dettaglio where T1.id_stima = ${dataStima.id_stima}`;
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryStimeDescr, '', async (retDataStimeDescr) => {
                                                if (retDataStimeDescr.length > 0) {
                                                    //CARICO descrizIONI
                                                    retDataStimeDescr.forEach(async (prezz) => {
                                                        if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                                                            await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaValoreANuovo', '', prezz)
                                                        }
                                                        else if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                                                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                                                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                                                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                                                                 || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                                                            await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaStatoUso', '', prezz)
                                                        }
                                                    })
                                                }
                                                else {
                                                    //CARICO UNA RIGA descrizIONE PER OGNI TABELLA
                                                    if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                                                        //acqua condotta
                                                        if (document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG001' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '2' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '3' || document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '14' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '48' || document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '71' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '72' || document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '74'
                                                        ) {
                                                            await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaValoreANuovo', '00003', '')
                                                        }
                                                        //ricerca guasto
                                                        else if (document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG019' || document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG020' || document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG021' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '70' || document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '73' || document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '74') {
                                                            let ricercaGuasto = ['00036', '00030']
                                                            for (let i = 0; i < ricercaGuasto.length; i++) {
                                                                await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaValoreANuovo', ricercaGuasto[i], '')
                                                            }
                                                        }
                                                        //altre
                                                        else {
                                                            await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaValoreANuovo', '', '')
                                                        }
                                                    }
                                                    else if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                                                             || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'                                                        
                                                             || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                                                             || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                                                             || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                                                        //acqua condotta
                                                        if (document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG001' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '2' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '3' || document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '14' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '48' || document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '71' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '72' || document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '74') {
                                                            await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaStatoUso', '00003', '')
                                                        }
                                                        //ricerca guasto
                                                        else if (document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG019' || document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG020' || document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TG021' ||
                                                            document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '70' || document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '73' || document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '74') {
                                                            let ricercaGuasto = ['00036', '00030']
                                                            for (let i = 0; i < ricercaGuasto.length; i++) {
                                                                await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaStatoUso', ricercaGuasto[i], '')
                                                            }
                                                        }
                                                        //altre
                                                        else {
                                                            await this.aggiungiRigaStimaPrezziario(stima.d, 'tabellaStatoUso', '', '')
                                                        }
                                                    }
                                                }
                                                //-----------COASSICURAZIONI------------
                                                const queryCoass = `SELECT T1.*, T2.id_coassi_indiretta as id_coass_gen FROM [11010] T1 left outer join [12014] T2 on T1.id_compagnia_coass = T2.id_compagnia_coass_jftech where T1.id_stima = ${dataStima.id_stima}`;
                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryCoass, '', retDataCoassVecchio => {
                                                    var retDataCoass = [];
                                                    retDataCoassVecchio.forEach(elm => {
                                                        //compagnia Generali
                                                        if (elm.compagniaCoinvolta === '5')
                                                            retDataCoass.unshift(elm)
                                                        else
                                                            retDataCoass.push(elm)
                                                    })
                                                    if (retDataCoass.length > 0) {
                                                        const startTimeC = performance.now();
                                                        document.getElementById(`coassicurazioneSi-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true;
                                                        document.getElementById(`accordionCoassicurazione-D${stima.d}_${this.props.IdInternoSinistro}`).style.removeProperty('display');
                                                        document.getElementById(`accordionCoassicurazione-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].expandItem(true, 0);
                                                        const endTimeC = performance.now();
                                                        const totalTimeC = endTimeC - startTimeC;
                                                        setTimeout(async () => {
                                                            for (let c = 0; c < retDataCoass.length; c++) {
                                                                await this.aggiungiRigaCompagniaCoassicurazioneRiparto1(stima.d, retDataCoass[c], '');
                                                            }
                                                            //inserisco i dati coassicurazioni contenuti nella tabella stime_jftech
                                                            document.getElementById(`sommaDanniRiparto1_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.sommaDanniRiparto1
                                                            document.getElementById(`sommaDanniRiparto2_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.sommaDanniRiparto2
                                                            document.getElementById(`indennizzoVsCompagnia_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.indennizzoACaricoVsComp
                                                            if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                                                                || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                                                                || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                                                                || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                                                                || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                                                                document.getElementById(`supplIndennitaCoass-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.suppl_indenn_coass;
                                                                document.getElementById(`vsuCoass-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.vsu_coass;
                                                            }
                                                            document.getElementById(`noteCoassicurazione-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = dataStima.descrizione_coass;
                                                            setTimeout(() => {
                                                                this.calcolaCoassicurazioneCompleta(stima.d, true);
                                                            }, 200)
                                                        }, totalTimeC)
                                                    }
                                                    else {
                                                        document.getElementById(`coassicurazioneNo-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked = true;
                                                    }

                                                    //---------INFORMAZIONI RICHIESTE DALLA COMPAGNIA GENERALI---------
                                                    if (this.isCompagniaGenerali()) {
                                                        const queryInfoRichieste = `SELECT T1.*,T2.* FROM [11011] T1 left outer join [12015] T2 on T1.id_info_richieste_compagnia = T2.id_info_rich_jftech where T1.id_stima = ${dataStima.id_stima}`;
                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryInfoRichieste, '', retDataInfoRich => {
                                                            for (let index = 0; index < retDataInfoRich.length; index++) {
                                                                this.strutturaStatiInfoRichiesteDelete.push({ keyJftech: retDataInfoRich[index].id_info_richieste_compagnia, keyGen: retDataInfoRich[index].id_info_richiesta })
                                                            }

                                                            var garanziaCodEle = document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                            var garanziaSTR2 = document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.servizio_str2;
                                                            var dannNome = document.getElementById(`danneggiatoFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                                                            var partNome = document.getElementById(`partitaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                                                            var garNome = document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                                                            var garCodEle = document.getElementById(`garanziaFromSin-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;

                                                            this.nuovaCreaInfoRichieste(dannNome, partNome, garNome, garCodEle, dataStima.id_stima, dataStima.id_danno, stima.d, garanziaCodEle, garanziaSTR2, retDataInfoRich);
                                                            resolveStima('Prossima')
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            });
                                                        }, true, true);
                                                    }
                                                    else {
                                                        resolveStima('Prossima')
                                                    }
                                                }, messaggio => {
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    });
                                                }, true, true);
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                });
                                            }, true, true);
                                        })
                                    }
                                    funcListStime.push(eseguiStima);
                                }
                            }
                        })
                    })
                    const eseguiCiclica = (ind) => {
                        if (CustomJS.isNullEmpty(funcListStime[ind])) {
                            resolveFineStime();
                        }
                        else {
                            funcListStime[ind]().then(ret => {
                                if (ret === 'Prossima') {
                                    eseguiCiclica(ind + 1);
                                }
                            }).catch(err => {
                                console.log(err)
                            })
                        }
                    }
                    eseguiCiclica(0);
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
            });
        }

        await fineCaricamentoStime();

        //PRENDO DATI RIEPILOGO
        const queryRiep0 = `SELECT * FROM [11012] where id_sinistro = ${this.props.IdSinistro};`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryRiep0, '', retDataRiep0 => {
            if (retDataRiep0.length > 0) {
                this.strutturaRiepilogoDelete.IdRiepilogo = retDataRiep0[0].idRiepilogo_perizia;
                if (!CustomJS.isNullEmpty(retDataRiep0[0].finali_indenn_netti_arrotond_MAN) && retDataRiep0[0].finali_indenn_netti_arrotond_MAN !== 0) {
                    document.getElementById(`sbloccaTotaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`).click();
                    document.getElementById(`totaleIndennNettoComplApprossRiepMAN_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataRiep0[0].finali_indenn_netti_arrotond_MAN;
                }
                const queryRiepDanneggiati = `SELECT idDanneggiatoRiep FROM [11013] where id_sinistro = ${this.props.IdSinistro};`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryRiepDanneggiati, '', retDataDann => {
                    for (let d = 0; d < retDataDann.length; d++) {
                        this.strutturaRiepilogoDelete.IdDanneggiati.push(retDataDann[d].idDanneggiatoRiep)
                    }
                    const queryRiepRighe = `SELECT idGarPart FROM [11014] where id_sinistro = ${this.props.IdSinistro};`;
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', queryRiepRighe, '', retDataRighe => {
                        for (let r = 0; r < retDataRighe.length; r++) {
                            this.strutturaRiepilogoDelete.IdRighe.push(retDataRighe[r].idGarPart);
                        }
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }, true, true);
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);

        //RISERVA E DEFINIZIONE
        const riservaSiNo = `SELECT T2.contr_nominativo AS contraente,T1.Ris_note,T1.servizio_eseguito,T1.D_note FROM [11001] T1 left outer join [01001] T2 on T1.id_sinistro = T2.id_sinistro where T1.id_sinistro = ${this.props.IdSinistro};`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', riservaSiNo, '', retDataRis => {
            //Carico Riserva
            if (CustomJS.isNullEmpty(retDataRis[0]?.Ris_note))
                this.riservaCe = false;
            else
                this.riservaCe = true;

            if (CustomJS.isNullEmpty(retDataRis[0]?.contraente))
                this.contraenteRiserva = retDataRis[0]?.nome + " " + retDataRis[0]?.cognome;
            else
                this.contraenteRiserva = retDataRis[0].contraente;

            this.dsTipoServizio.forEach(elm => {
                if (elm.VALUE === retDataRis[0]?.servizio_eseguito)
                    this.tipoServizio = elm.TEXT.toLowerCase().trim();
            })
            //Carico definizione
            document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value = retDataRis[0]?.D_note;
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);

        //carico franchige condivise
        const franchigeCondivise = `SELECT * from [11005] where id_sinistro = ${this.props.IdSinistro};`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', franchigeCondivise, '', retDataFranc => {
            for (let index = 0; index < retDataFranc.length; index++) {
                this.strutturaStatiStimeBase.forEach(stima => {
                    if (document.getElementById(`tabellaStima-D${stima.d}_${this.props.IdInternoSinistro}`)) {
                        if (document.getElementById(`idStima-D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === retDataFranc[index].idStimaCondivisa) {
                            document.getElementById(`franchigiaOscopertura-D${stima.d}_${this.props.IdInternoSinistro}`).innerHTML = retDataFranc[index].tipo.charAt(0).toUpperCase() + retDataFranc[index].tipo.slice(1) + " :";
                        }
                    }
                });
            }

            //FRANCHIGE
            this.franchigeCondivise = []
            retDataFranc.forEach(dato => {
                if (dato.tipo === 'franchigia')
                    this.franchigeCondivise.push(dato)
            })
            this.franchigeCondivise = _.groupBy(this.franchigeCondivise, 'gruppo');

            //SCOPERTI
            this.scopertiCondivisi = []
            retDataFranc.forEach(dato => {
                if (dato.tipo === 'scoperto')
                    this.scopertiCondivisi.push(dato)
            })
            this.scopertiCondivisi = _.groupBy(this.scopertiCondivisi, 'gruppo');
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);

        //AGGIORNO TUTTI I CAMPI
        this.strutturaStatiPreesistenzeBase.forEach(prees => {
            document.getElementById(`aggiornaPreesistenza-T${prees.t}_${this.props.IdInternoSinistro}`).click();
        })
        this.strutturaStatiStimeBase.forEach(stima => {
            if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001')
                document.getElementById(`aggiornaTabellaStimaValoreANuovo-D${stima.d}_${this.props.IdInternoSinistro}`).click();
            else if (document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                     || document.getElementById(`determinazioneDanno_D${stima.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006')
                document.getElementById(`aggiornaTabellaStimaStatoUso-D${stima.d}_${this.props.IdInternoSinistro}`).click();
        });
        
        //CALCOLO RIEPILOGO
        setTimeout(() => {
            setTimeout(() => {
                //se l' id riepilogo è vuoto ma la perizia contiene almente una stima 
                if (CustomJS.isNullEmpty(this.strutturaRiepilogoDelete.IdRiepilogo) && this.periziaModificata === true) {
                    document.getElementById(`avvisoPeriziaModificata_${this.props.IdInternoSinistro}`).innerText = 'PERIZIA MODIFICATA! Si ricorda di salvare i calcoli!'
                }
                document.getElementById(`dettSinPag2_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
                document.getElementById(`elabPeritalePag2_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
                document.getElementById(`salvaEsciP2_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = false;
                //controllo se il sinistro è chiuso
                if (this.props.restituito === true) {
                    this.sinistroChiuso()
                }
                //setTimeout(() => {
                    CustomJS.scrollAuto(500, 0, false, true);
                //}, 700);       
                this.toggleBlocking2(false);
            }, 600)
            this.trasportaDatiRiepilogo()
        }, 400)
    }
    async salvaDati() {
        this.toggleBlocking2(true);

        var tempoOne = 75;
        var tempoOneLong = 150;
        var tempoPreesistenze = (tempoOne * this.strutturaStatiPreesistenzeBase.length) + (tempoOne * this.strutturaStatiPreesistenzeCostruzioni.length) + (tempoOne * this.strutturaStatiPreesistenzeDescrizioni.length);
        var tempoStime = ((tempoOne * this.strutturaStatiStimeBase.length) * 2) + (tempoOne * this.strutturaStatiStimePrezziari.length) + (tempoOne * this.strutturaStatiCoassicurazioniRighe.length);

        var tempoRiepilogo =
            //tempo delete 
            (tempoOne * this.strutturaRiepilogoDelete.IdDanneggiati.length) + (tempoOne * this.strutturaRiepilogoDelete.IdRighe.length) +
            //tempo insert 
            (tempoOneLong + (tempoOneLong * this.numTabelleRiepilogo - 1) + (tempoOneLong * this.numRigheRiepilogoGaranzie - 1)) +
            tempoOne * 2 //unipol e generali update su dati dal riepilogo

        var tempoDefinizione = tempoOne * 3;
        var tempoInfoRichieste = 0;
        if (this.isCompagniaGenerali()) {
            var categoriePerOgniStimaIdea = tempoOne * 5; //idea base con un elemento
            tempoInfoRichieste = (categoriePerOgniStimaIdea * this.strutturaStatiStimeBase.length)
        }
        var tempoTotale = tempoPreesistenze + tempoStime + tempoRiepilogo + tempoDefinizione + tempoInfoRichieste;

        //-----------------------INIZIO QUERY PREESISTENZE-----------------------
        console.log('inizio preesistenze')
        const finePreesistenze = () => {
            return new Promise((resolveFinePreesistenze) => {
                let funcListPreesistenze = [];
                this.strutturaStatiPreesistenzeBase.forEach((elm) => {
                    const eseguiPreesistenza = () => {
                        return new Promise(async (resolvePreesistenza) => {

                            var guidPreesGenerata = CustomJS.createGuid4()
                            var tipoPartita = document.getElementById(`nomePartita${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;

                            var preesistenzaJftech = () => {
                                return new Promise((resolvePreesJftech) => {
                                    //utile per la GENERALI_partita_preesistenze
                                    var queryPreesistenzaJftech = '';
                                    //La query per la preesistenza generali nella preesistenza costruzioni
                                    if (elm.stato === 'n') {
                                        queryPreesistenzaJftech = `BEGIN TRAN;INSERT INTO [11006] (id_sinistro,id_partitaPeriziaOnline, somma_assicurata,deroga,valore_derogato,totale_esistenza_nuovo,scopertura_tot_esist_nuovo,degrado_stato_uso,totale_esistenza_stato_uso,scopertura_stato_uso,insufficienza_assicurativa_perc,noteInsuffAss,notePreesistenza,guid_utile) VALUES (${this.props.IdSinistro},@idPartitaPO,@sommaAssicurata,@deroga,@valoreDerogato,@totaleEsistenzaNuovo,@scoperturaTotEsistenzaNuovo,@degradoStatoUso,@totaleEsistenzaStatoUso,@scoperturaStatoUso,@insufficienzaAssicurativaPerc,@notePres,@notePres2,'${guidPreesGenerata}');UPDATE [11002] SET sommaAssicurataPartita = @sommaAssicurata WHERE id_partitaPeriziaOnline = ${elm.keyPartita};COMMIT;`;
                                    }
                                    else if (elm.stato === 'o') {
                                        queryPreesistenzaJftech = `BEGIN TRAN;UPDATE [11006] SET somma_assicurata = @sommaAssicurata, deroga = @deroga, valore_derogato = @valoreDerogato, totale_esistenza_nuovo = @totaleEsistenzaNuovo, scopertura_tot_esist_nuovo = @scoperturaTotEsistenzaNuovo, degrado_stato_uso = @degradoStatoUso, totale_esistenza_stato_uso = @totaleEsistenzaStatoUso, scopertura_stato_uso = @scoperturaStatoUso,insufficienza_assicurativa_perc = @insufficienzaAssicurativaPerc, noteInsuffAss = @notePres, notePreesistenza = @notePres2 WHERE id_preesistenza = ${elm.keyJftech};UPDATE [11002] SET sommaAssicurataPartita = @sommaAssicurata WHERE id_partitaPeriziaOnline = ${elm.keyPartita};COMMIT;`;
                                    }
                                    //LA DELETE (STATO 'D') SI SVOLGE NELLA PAGINA PRECEDENTE DELLA PERIZIA ONLINE!
                                    var jsonObjectPreesJftech = {
                                        IdPartitaPO: CustomJS.isNullEmpty(document.getElementById(`idPartitaPO-T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`idPartitaPO-T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value, // key della tabella _partitePeriziaOnline
                                        SommaAssicurata: CustomJS.isNullEmpty(document.getElementById(`sommaAssicurataInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`sommaAssicurataInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                        Deroga: CustomJS.isNullEmpty(document.getElementById(`derogaInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`derogaInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                        ValoreDerogato: CustomJS.isNullEmpty(document.getElementById(`valoreDerogatoResult_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreDerogatoResult_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                        TotaleEsistenzaNuovo: CustomJS.isNullEmpty(document.getElementById(`valoreTotEsistNuovoResult_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreTotEsistNuovoResult_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                        ScoperturaTotEsistenzaNuovo: CustomJS.isNullEmpty(document.getElementById(`valoreScoperturaResult_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreScoperturaResult_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                        DegradoStatoUso: CustomJS.isNullEmpty(document.getElementById(`valoreDegradoInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreDegradoInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                        TotaleEsistenzaStatoUso: CustomJS.isNullEmpty(document.getElementById(`valoreTotEsistStatoUsoInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreTotEsistStatoUsoInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                        ScoperturaStatoUso: CustomJS.isNullEmpty(document.getElementById(`valoreScoperturaStatoUso_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreScoperturaStatoUso_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                        InsufficienzaAssicurativaPerc: CustomJS.isNullEmpty(document.getElementById(`insuffAssicurativaPerc_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`insuffAssicurativaPerc_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                        NotePres: CustomJS.isNullEmpty(document.getElementById(`messaggioNote-T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`messaggioNote-T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                        NotePres2: CustomJS.isNullEmpty(document.getElementById(`notePreesistenza-T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`notePreesistenza-T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                    };
                                    console.log(jsonObjectPreesJftech)
                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryPreesistenzaJftech, JSON.stringify(jsonObjectPreesJftech), retDataPreesJftech => {
                                        resolvePreesJftech()
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });
                                    }, true, true);
                                });
                            }
                            await preesistenzaJftech();

                            //Se la partita è Fabbricato le query per Generali vengono inserite in base alle righe delle costruzioni
                            //-------------GESTIONE PREESISTENZA JFTECH GENERALI PARTE 1-------------
                            if (tipoPartita === 'TP016' || tipoPartita === '1') {
                                const fineCostruzioni = () => {
                                    return new Promise((resolveFineCostruzioni) => {

                                        let funcListCostruzioni = [];
                                        this.strutturaStatiPreesistenzeCostruzioni.forEach((elm2) => {
                                            const eseguiCostruzione = () => {
                                                return new Promise(async (resolveCostruzione) => {
                                                    if (elm2.id === `rigaPreesistenzaCostruzione-T${elm.t}-R-${elm2.r}_${this.props.IdInternoSinistro}`) {

                                                        var guidCostruzioneGenerata = CustomJS.createGuid4()
                                                        //COSTRUZIONE JFTECH
                                                        var crudCostruzioneJftech = () => {
                                                            return new Promise(async (resolveCrudJftech) => {
                                                                var queryPreesCostruzioniJftech = '';
                                                                var jsonObjectCostruzioneJftech = {}

                                                                if (elm2.stato === 'n' && elm.stato === 'n') {
                                                                    queryPreesCostruzioniJftech = `BEGIN TRAN;INSERT INTO [11007] (id_preesistenza,id_sinistro, valore_unitario, superficie_equivalente,altezza,volume,valore_ricostruz_nuovo,guid_utile_costruzione) VALUES ((SELECT id_preesistenza FROM [11006] WITH (UPDLOCK, HOLDLOCK) WHERE id_sinistro = ${this.props.IdSinistro} and guid_utile = '${guidPreesGenerata}'),${this.props.IdSinistro},@valoreUnitarioCostruz,@superficieEquivalente,@altezza,@volume,@valoreRicostruzNuovo,'${guidCostruzioneGenerata}');COMMIT;`;
                                                                }
                                                                else if (elm2.stato === 'n' && elm.stato === 'o') {
                                                                    queryPreesCostruzioniJftech = `INSERT INTO [11007] (id_preesistenza,id_sinistro, valore_unitario, superficie_equivalente,altezza,volume,valore_ricostruz_nuovo,guid_utile_costruzione) VALUES (${elm.keyJftech},${this.props.IdSinistro},@valoreUnitarioCostruz,@superficieEquivalente,@altezza,@volume,@valoreRicostruzNuovo,'${guidCostruzioneGenerata}');`;
                                                                }
                                                                else if (elm2.stato === 'o') {
                                                                    queryPreesCostruzioniJftech = `UPDATE [11007] SET valore_unitario = @valoreUnitarioCostruz, superficie_equivalente = @superficieEquivalente, altezza = @altezza,volume = @volume,valore_ricostruz_nuovo = @valoreRicostruzNuovo WHERE id_preesistenza_costruzione = ${elm2.keyJftech}`;
                                                                }
                                                                else if (elm2.stato === 'd') {
                                                                    queryPreesCostruzioniJftech = `DELETE FROM [11007] WHERE id_preesistenza_costruzione = ${elm2.keyJftech}`;
                                                                }
                                                                if (elm2.stato === 'd') {
                                                                    jsonObjectCostruzioneJftech = {
                                                                    }
                                                                }
                                                                else {
                                                                    jsonObjectCostruzioneJftech = {
                                                                        ValoreUnitarioCostruz: CustomJS.isNullEmpty(document.getElementById(`valoreUnitarioInput_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreUnitarioInput_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        SuperficieEquivalente: CustomJS.isNullEmpty(document.getElementById(`superficieEquivalenteInput_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`superficieEquivalenteInput_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        Altezza: CustomJS.isNullEmpty(document.getElementById(`altezzaInput_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`altezzaInput_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        Volume: CustomJS.isNullEmpty(document.getElementById(`volumeResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`volumeResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        ValoreRicostruzNuovo: CustomJS.isNullEmpty(document.getElementById(`valoreRicostruzioneResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreRicostruzioneResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                    };
                                                                }
                                                                console.log(jsonObjectCostruzioneJftech)
                                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryPreesCostruzioniJftech, JSON.stringify(jsonObjectCostruzioneJftech), retDataPreesCostruzJftech => {
                                                                    resolveCrudJftech()
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    });
                                                                }, true, true);
                                                            });
                                                        }
                                                        await crudCostruzioneJftech();

                                                        //COSTRUZIONI GENERALI 
                                                        if (this.isCompagniaGenerali()) {
                                                            var crudCostruzioneGEN = () => {
                                                                return new Promise(async (resolveCrudGEN) => {
                                                                    var queryCostruzGenerali = '';
                                                                    var jsonObjectCostruzGenerali = {};
                                                                    if (elm2.stato === 'n' && elm.stato === 'n') {
                                                                        queryCostruzGenerali = `BEGIN TRAN; INSERT INTO [12007] (id_preesistenza_jftech,id_prees_costruz_jftech,id_partita,id_incarico,descrizione,valore_assicurato,deroga_percentuale,valore_riferimento,unita_misura,valore_unitario,quantita,totale_preesistenza) VALUES ((SELECT id_preesistenza FROM [11006] WITH (UPDLOCK, HOLDLOCK) where id_sinistro = ${this.props.IdSinistro} and guid_utile = '${guidPreesGenerata}'),(SELECT id_preesistenza_costruzione FROM [11007] WITH (UPDLOCK, HOLDLOCK) WHERE id_sinistro = ${this.props.IdSinistro} and guid_utile_costruzione = '${guidCostruzioneGenerata}'),@guidPartita,'${this.props.IdIncarico.toString()}',@partitaDescr,@sommaAssicurata,@deroga,@valoreDerogato,@unitaMisura,@valoreUnitarioCostruz,@quantita,@totaleEsistenzaNuovo);COMMIT;`;
                                                                    }
                                                                    else if (elm2.stato === 'n' && elm.stato === 'o') {
                                                                        queryCostruzGenerali = `BEGIN TRAN; INSERT INTO [12007] (id_preesistenza_jftech,id_prees_costruz_jftech,id_partita,id_incarico,descrizione,valore_assicurato,deroga_percentuale,valore_riferimento,unita_misura,valore_unitario,quantita,totale_preesistenza) VALUES (${elm.keyJftech},(SELECT id_preesistenza_costruzione FROM [11007] WITH (UPDLOCK, HOLDLOCK) where id_sinistro = ${this.props.IdSinistro} and guid_utile_costruzione = '${guidCostruzioneGenerata}'),@guidPartita,'${this.props.IdIncarico.toString()}',@partitaDescr,@sommaAssicurata,@deroga,@valoreDerogato,@unitaMisura,@valoreUnitarioCostruz,@quantita,@totaleEsistenzaNuovo);COMMIT;`;
                                                                    }
                                                                    else if (elm2.stato === 'o') {
                                                                        queryCostruzGenerali = `UPDATE [12007] SET valore_assicurato = @sommaAssicurata, deroga_percentuale = @deroga,valore_riferimento = @valoreDerogato, unita_misura = @unitaMisura,valore_unitario = @valoreUnitarioCostruz,quantita = @quantita, totale_preesistenza = @totaleEsistenzaNuovo WHERE id_preesistenza = ${elm2.keyGen}`
                                                                    }
                                                                    else if (elm2.stato === 'd') {
                                                                        queryCostruzGenerali = `DELETE FROM [12007] WHERE id_preesistenza = ${elm2.keyGen}`
                                                                    }
                                                                    if (elm2.stato === 'd') {
                                                                        jsonObjectCostruzGenerali = {
                                                                        }
                                                                    }
                                                                    else {
                                                                        jsonObjectCostruzGenerali = {
                                                                            GuidPartita: CustomJS.isNullEmpty(document.getElementById(`idPartitaPO-T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`idPartitaPO-T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.GUID_PARTITA_GEN,
                                                                            PartitaDescr: CustomJS.isNullEmpty(document.getElementById(`nomePartita${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`nomePartita${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                                                            SommaAssicurata: CustomJS.isNullEmpty(document.getElementById(`sommaAssicurataInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`sommaAssicurataInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            Deroga: CustomJS.isNullEmpty(document.getElementById(`derogaInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`derogaInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            ValoreDerogato: CustomJS.isNullEmpty(document.getElementById(`valoreDerogatoResult_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreDerogatoResult_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            UnitaMisura: 'nr',
                                                                            ValoreUnitarioCostruz: CustomJS.isNullEmpty(document.getElementById(`valoreUnitarioInput_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreUnitarioInput_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            Quantita: CustomJS.isNullEmpty(document.getElementById(`volumeResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`volumeResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            TotaleEsistenzaNuovo: CustomJS.isNullEmpty(document.getElementById(`valoreTotEsistNuovoResult_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreTotEsistNuovoResult_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        };
                                                                    }
                                                                    console.log(jsonObjectCostruzGenerali)
                                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryCostruzGenerali, JSON.stringify(jsonObjectCostruzGenerali), retDataCostruzioneGen => {
                                                                        resolveCrudGEN()
                                                                    }, messaggio => {
                                                                        toast.warn(messaggio, {
                                                                            containerId: 'toastContainer1',
                                                                            onClose: () => toast.dismiss(),
                                                                            position: "bottom-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            newestOnTop: true,
                                                                            rtl: false,
                                                                            pauseOnFocusLoss: true
                                                                        });
                                                                    }, true, true);
                                                                });
                                                            }
                                                            await crudCostruzioneGEN();
                                                        }
                                                        resolveCostruzione('Prossima');
                                                    }
                                                    else {
                                                        resolveCostruzione('Prossima');
                                                    }
                                                })
                                            }
                                            funcListCostruzioni.push(eseguiCostruzione);
                                        })
                                        const eseguiCiclica = (ind) => {
                                            if (CustomJS.isNullEmpty(funcListCostruzioni[ind])) {
                                                resolveFineCostruzioni();
                                            }
                                            else {
                                                funcListCostruzioni[ind]().then(ret => {
                                                    if (ret === 'Prossima') {
                                                        eseguiCiclica(ind + 1);
                                                    }
                                                }).catch(err => {
                                                    console.log(err)
                                                })
                                            }
                                        }
                                        eseguiCiclica(0);
                                    })
                                }
                                await fineCostruzioni();

                                //descrizIONI JFTECH
                                const fineDescrizioni = () => {
                                    return new Promise((resolveFineDescrizioni) => {
                                        let funcListDescrizioni = [];
                                        this.strutturaStatiPreesistenzeDescrizioni.forEach((elm2) => {
                                            const eseguiDescrizione = () => {
                                                return new Promise((resolveDescrizione) => {
                                                    if (elm2.id === `rigaPreesDescrizione-T${elm.t}-R-${elm2.r}_${this.props.IdInternoSinistro}`) {
                                                        var queryDescrizioneJftech = '';
                                                        var jsonObjectDescrizioneJftech = {};
                                                        //se la descrizione è nuova e la preesistenza è nuova :
                                                        if (elm2.stato === 'n' && elm.stato === 'n') {
                                                            queryDescrizioneJftech = `BEGIN TRAN;INSERT INTO [11008] (id_preesistenza,id_sinistro,descrizione, prezzo) VALUES ((SELECT id_preesistenza FROM [11006] WITH (UPDLOCK, HOLDLOCK) WHERE id_sinistro = ${this.props.IdSinistro} and guid_utile = '${guidPreesGenerata}'),${this.props.IdSinistro},@descrizioneOggetto,@prezzoOggetto);COMMIT;`;
                                                        }
                                                        //se la descrizione è nuova ma la preesistenza esiste :
                                                        else if (elm2.stato === 'n' && elm.stato === 'o') {
                                                            queryDescrizioneJftech = `INSERT INTO [11008] (id_preesistenza,id_sinistro,descrizione,prezzo) VALUES (${elm.keyJftech},${this.props.IdSinistro},@descrizioneOggetto,@prezzoOggetto);`;
                                                        }
                                                        else if (elm2.stato === 'o') {
                                                            queryDescrizioneJftech = `UPDATE [11008] SET descrizione = @descrizioneOggetto, prezzo = @prezzoOggetto WHERE id_preesistenza_descrizione = ${elm2.key};`;
                                                        }
                                                        else if (elm2.stato === 'd') {
                                                            queryDescrizioneJftech = `DELETE FROM [11008] WHERE id_preesistenza_descrizione = ${elm2.key};`;
                                                        }
                                                        if (elm2.stato === 'd') {
                                                            jsonObjectDescrizioneJftech = {
                                                            }
                                                        }
                                                        else {
                                                            jsonObjectDescrizioneJftech = {
                                                                DescrizioneOggetto: CustomJS.isNullEmpty(document.getElementById(`descrizioneCorpo_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`descrizioneCorpo_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                PrezzoOggetto: CustomJS.isNullEmpty(document.getElementById(`valoreAltroACorpoResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreAltroACorpoResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                            }
                                                        }
                                                        console.log(jsonObjectDescrizioneJftech)
                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryDescrizioneJftech, JSON.stringify(jsonObjectDescrizioneJftech), retDataDescrizioneJftech => {
                                                            resolveDescrizione('Prossima')
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            });
                                                        }, true, true);
                                                    }
                                                    else {
                                                        resolveDescrizione('Prossima')
                                                    }
                                                })
                                            }
                                            funcListDescrizioni.push(eseguiDescrizione);
                                        });
                                        const eseguiCiclica = (ind) => {
                                            if (CustomJS.isNullEmpty(funcListDescrizioni[ind])) {
                                                resolveFineDescrizioni();
                                            }
                                            else {
                                                funcListDescrizioni[ind]().then(ret => {
                                                    if (ret === 'Prossima') {
                                                        eseguiCiclica(ind + 1);
                                                    }
                                                }).catch(err => {
                                                    console.log(err)
                                                })
                                            }
                                        }
                                        eseguiCiclica(0);
                                    })
                                }
                                await fineDescrizioni();
                            }
                            //Se la partita NON è Fabbricato le query per Generali vengono inserite in base ali dati di una preesistenza
                            //-------------GESTIONE PREESISTENZA JFTECH GENERALI PARTE 2-------------
                            else {
                                const fineDescrizioni2 = () => {
                                    return new Promise((resolveFineDescrizioni2) => {
                                        let funcListDescrizioni2 = [];

                                        this.strutturaStatiPreesistenzeDescrizioni.forEach((elm2) => {
                                            const eseguiDescrizione2 = () => {
                                                return new Promise((resolveDescrizione2) => {
                                                    if (elm2.id === `rigaPreesDescrizione-T${elm.t}-R-${elm2.r}_${this.props.IdInternoSinistro}`) {
                                                        var jsonObjectDescrizione2 = {};
                                                        var queryDescrizioneJftech2 = '';
                                                        //se la descrizione è nuova e la preesistenza è nuova :
                                                        if (elm2.stato === 'n' && elm.stato === 'n') {
                                                            queryDescrizioneJftech2 = `BEGIN TRAN;INSERT INTO [11008] (id_preesistenza,id_sinistro,descrizione, prezzo) VALUES ((SELECT id_preesistenza FROM [11006] WITH (UPDLOCK, HOLDLOCK) where id_sinistro = ${this.props.IdSinistro} and guid_utile = '${guidPreesGenerata}'),${this.props.IdSinistro},@descrizioneOggetto,@prezzoOggetto);COMMIT;`;
                                                        }
                                                        //se la descrizione è nuova ma la preesistenza esiste :
                                                        else if (elm2.stato === 'n' && elm.stato === 'o') {
                                                            queryDescrizioneJftech2 = `INSERT INTO [11008] (id_preesistenza,id_sinistro,descrizione, prezzo) VALUES (${elm.keyJftech},${this.props.IdSinistro},@descrizioneOggetto,@prezzoOggetto);`;
                                                        }
                                                        else if (elm2.stato === 'o') {
                                                            queryDescrizioneJftech2 = `UPDATE [11008] SET descrizione = @descrizioneOggetto, prezzo = @prezzoOggetto WHERE id_preesistenza_descrizione = ${elm2.key}`;
                                                        }
                                                        else if (elm2.stato === 'd') {
                                                            queryDescrizioneJftech2 = `DELETE FROM [11008] WHERE id_preesistenza_descrizione = ${elm2.key}`;
                                                        }
                                                        if (elm2.stato === 'd') {
                                                            jsonObjectDescrizione2 = {
                                                            }
                                                        }
                                                        else {
                                                            jsonObjectDescrizione2 = {
                                                                DescrizioneOggetto: CustomJS.isNullEmpty(document.getElementById(`descrizioneCorpo_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`descrizioneCorpo_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                PrezzoOggetto: CustomJS.isNullEmpty(document.getElementById(`valoreAltroACorpoResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreAltroACorpoResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                            }
                                                        }
                                                        console.log(jsonObjectDescrizione2)
                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryDescrizioneJftech2, JSON.stringify(jsonObjectDescrizione2), retDataDescrizioneJftech => {
                                                            resolveDescrizione2('Prossima')
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            });
                                                        }, true, true);
                                                    }
                                                    else {
                                                        resolveDescrizione2('Prossima')
                                                    }
                                                })
                                            }
                                            funcListDescrizioni2.push(eseguiDescrizione2);
                                        });
                                        const eseguiCiclica = (ind) => {
                                            if (CustomJS.isNullEmpty(funcListDescrizioni2[ind])) {
                                                resolveFineDescrizioni2();
                                            }
                                            else {
                                                funcListDescrizioni2[ind]().then(ret => {
                                                    if (ret === 'Prossima') {
                                                        eseguiCiclica(ind + 1);
                                                    }
                                                }).catch(err => {
                                                    console.log(err)
                                                })
                                            }
                                        }
                                        eseguiCiclica(0);
                                    })
                                }
                                await fineDescrizioni2();

                                if (this.isCompagniaGenerali()) {
                                    const crudPreesistenzaGEN = () => {
                                        return new Promise((resolvePreesistenzaGEN) => {
                                            var queryPreesGen = '';
                                            var jsonObjectPreesGen = {}
                                            //Se la partita NON è Fabbricato viene inserito un solo record per preesistenza sulla GENERALI_partita_preesistenze
                                            //Nell' inserimento dei valori delle preesistenze diverse da fabbricato non sono previsti i campi valore_unitario, quantita, unita_misura
                                            if (elm.stato === 'n') {
                                                queryPreesGen = `BEGIN TRAN;INSERT INTO [12007] (id_preesistenza_jftech,id_partita,id_incarico,descrizione,valore_assicurato,deroga_percentuale,valore_riferimento,totale_preesistenza,unita_misura,valore_unitario,quantita) VALUES ((SELECT id_preesistenza FROM [11006] WITH (UPDLOCK, HOLDLOCK) WHERE id_sinistro = ${this.props.IdSinistro} and guid_utile = '${guidPreesGenerata}'),@guidPartita,'${this.props.IdIncarico.toString()}',@partitaDescr,@sommaAssicurata,@deroga,@valoreDerogato,@totaleEsistenzaNuovo,@unitaMisura,0,0);COMMIT;`;
                                            }
                                            else if (elm.stato === 'o') {
                                                queryPreesGen = `UPDATE [12007] SET valore_assicurato = @sommaAssicurata, deroga_percentuale = @deroga,valore_riferimento = @valoreDerogato, totale_preesistenza = @totaleEsistenzaNuovo, unita_misura = @unitaMisura,valore_unitario = 0,quantita = 0 WHERE id_preesistenza = ${elm.keyGen};`
                                            }
                                            else if (elm.stato === 'd') {
                                                queryPreesGen = `DELETE FROM [12007] WHERE id_preesistenza = ${elm.keyGen};`
                                            }
                                            //Preesistenza generali
                                            if (elm.stato === 'd') {
                                                jsonObjectPreesGen = {
                                                }
                                            }
                                            else {
                                                jsonObjectPreesGen = {
                                                    GuidPartita: CustomJS.isNullEmpty(document.getElementById(`idPartitaPO-T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`idPartitaPO-T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.GUID_PARTITA_GEN,
                                                    PartitaDescr: CustomJS.isNullEmpty(document.getElementById(`nomePartita${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`nomePartita${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                                    SommaAssicurata: CustomJS.isNullEmpty(document.getElementById(`sommaAssicurataInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : (document.getElementById(`sommaAssicurataInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value).toString(),
                                                    Deroga: CustomJS.isNullEmpty(document.getElementById(`derogaInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`derogaInput_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                    ValoreDerogato: CustomJS.isNullEmpty(document.getElementById(`valoreDerogatoResult_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreDerogatoResult_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                    TotaleEsistenzaNuovo: CustomJS.isNullEmpty(document.getElementById(`valoreTotEsistNuovoResult_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreTotEsistNuovoResult_T${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                    UnitaMisura: 'nr'
                                                }
                                            }
                                            console.log(jsonObjectPreesGen)
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryPreesGen, JSON.stringify(jsonObjectPreesGen), retDataPreesGen => {
                                                resolvePreesistenzaGEN();
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                });
                                            }, true, true);
                                        });
                                    }
                                    await crudPreesistenzaGEN();
                                }
                            }
                            resolvePreesistenza('Prossima');
                        })
                    }
                    funcListPreesistenze.push(eseguiPreesistenza);
                })

                const eseguiCiclica = (ind) => {
                    if (CustomJS.isNullEmpty(funcListPreesistenze[ind])) {
                        resolveFinePreesistenze();
                    }
                    else {
                        funcListPreesistenze[ind]().then(ret => {
                            if (ret === 'Prossima') {
                                eseguiCiclica(ind + 1);
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
                eseguiCiclica(0);
            })
        }
        await finePreesistenze();
        //-----------------------FINE QUERY PREESISTENZE-----------------------

        //-------------------------INIZIO QUERY STIME-------------------------
        console.log('inizio stime')

        const fineStime = () => {
            return new Promise((resolveFineStime) => {

                let funcListStime = [];
                this.strutturaStatiStimeBase.forEach((elm) => {
                    const eseguiStima = () => {
                        return new Promise(async (resolveStima) => {
                            //------DATI PER LE STIME
                            //insufficienza assicurativa
                            var insuffAss = 0;
                            this.strutturaStatiPreesistenzeBase.forEach(elmPrees => {
                                if (document.getElementById(`partitaFromSin-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === document.getElementById(`nomePartita${elmPrees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {
                                    if (document.getElementById(`formaGaranzia${elmPrees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value !== '1'
                                        && document.getElementById(`formaGaranzia${elmPrees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value !== '3') // '1' = P.R.A.
                                        insuffAss = document.getElementById(`insuffAssicurativaPerc_T${elmPrees.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                                }
                            })
                            //indennizzo finale
                            var indennizzoFinale = document.getElementById(`indennizzoVsCompagnia_D${elm.d}_${this.props.IdInternoSinistro}`) ? ((CustomJS.isNullEmpty(document.getElementById(`indennizzoVsCompagnia_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`indennizzoVsCompagnia_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)) : 0;
                            var limiteIndennizzo = document.getElementById(`ceLimiteIndennizzoONo-D${elm.d}_${this.props.IdInternoSinistro}`).style.display === 'none' ? -1 : CustomJS.isNullEmpty(document.getElementById(`limiteIndennizzo-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`limiteIndennizzo-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;

                            //-------INSERIMENTO STIME
                            var queryStimaJftech = ''; // cambia in base al tipo stima
                            var jsonObjectStimaJftech = {};

                            //GESTIONE ESITO 
                            if (this.isCompagniaGenerali()) {
                                var esitoDanno = document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : '';
                                //4 = esito non indennizzabile
                                if (esitoDanno === "4") {
                                    const eseguiCausale = () => {
                                        return new Promise(async (resolveCausale) => {
                                            var queryNonIndennizzo = `INSERT INTO [12017] (id_danno,id_incarico,codice,descrizione) VALUES (${elm.keyGen},'${this.props.IdIncarico}',@codiceCausaliNonIndenn, @descrCausaliNonIndenn);`;
                                            this.strutturaStatiCausali.forEach(causale => {
                                                if (causale.idDanno === elm.keyGen) {
                                                    queryNonIndennizzo = `UPDATE [12017] set codice = @codiceCausaliNonIndenn, descrizione = @descrCausaliNonIndenn where id_causale = ${causale.idCausale};`
                                                }
                                            })
                                            var jsonObjectCausali = {
                                                CodiceCausaliNonIndenn: CustomJS.isNullEmpty(document.getElementById(`causaliNonIndennizzo_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`causaliNonIndennizzo_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                DescrCausaliNonIndenn: CustomJS.isNullEmpty(document.getElementById(`causaliNonIndennizzo_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`causaliNonIndennizzo_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                            }
                                            console.log(jsonObjectCausali)
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryNonIndennizzo, JSON.stringify(jsonObjectCausali), retDataCausali => {
                                                resolveCausale()
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                });
                                            }, true, true);
                                        })
                                    }
                                    await eseguiCausale();
                                }
                                else {
                                    const eliminaCausale = () => {
                                        return new Promise(async (resolveDelete) => {
                                            var queryNonIndennizzo = ""
                                            this.strutturaStatiCausali.forEach(causale => {
                                                if (causale.idDanno === elm.keyGen) {
                                                    queryNonIndennizzo = `DELETE FROM [12017] where id_causale = ${causale.idCausale};`
                                                }
                                            })
                                            if (!CustomJS.isNullEmpty(queryNonIndennizzo)) {
                                                var jsonObjectCausaliDaElim = {
                                                }
                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryNonIndennizzo, JSON.stringify(jsonObjectCausaliDaElim), eliminato => {
                                                    resolveDelete()
                                                }, messaggio => {
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    });
                                                }, true, true);
                                            }
                                            else {
                                                resolveDelete()
                                            }
                                        })
                                    }
                                    await eliminaCausale();
                                }
                            }

                            const updateStimaJftech = () => {
                                return new Promise(async (resolveUpdateJftech) => {
                                    //STIMA VALORE A NUOVO (JFTECH)
                                    if (document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                                        queryStimaJftech = `UPDATE [11004] SET danno_compless_accertato = @dannoComplessivoAccertato,dopo_regola_proporzionale = @dopoRegolaProp,dopo_regola_proporzionaleMAN = @dopoRegolaPropMAN,  limite_sinistro = @limiteSinistro, piano = @piano, limiteIndennizzo = @limiteIndennizzo,franchigiaSalvata = @franchigiaSalvata, franchigiaOScoperto = @franchigiaOScoperto,franchigiaOScopertoMAN = @franchigiaOScopertoMAN, danno_netto = @dannoNetto, ripristini_ultimati = @ripristiniUltimati,note_ripristini_ultimati = @noteRipristiniUltimati, accordo_ania = @accordoAnia, coassicurazione = @coassicurazione,sommaDanniRiparto1 = @sommaDanniRiparto1, sommaDanniRiparto2 = @sommaDanniRiparto2,indennizzoACaricoVsComp = @indennizzoACaricVsCompagnia,descrizione_coass = @noteCoassicurazione, esito_danno = @esitoCodice, danno_netto_manuale = @dannoNettoMAN WHERE id_stima = ${elm.keyJftech};`;
                                        jsonObjectStimaJftech = {
                                            LimiteSinistro: CustomJS.isNullEmpty(document.getElementById(`limiteSinistro_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`limiteSinistro_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            Piano: CustomJS.isNullEmpty(document.getElementById(`piano-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`piano-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DannoComplessivoAccertato: CustomJS.isNullEmpty(document.getElementById(`dannoComplessAccert_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoComplessAccert_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DopoRegolaProp: CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionale_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dopoRegolaProporzionale_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DopoRegolaPropMAN: CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dopoRegolaProporzionaleMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            LimiteIndennizzo: limiteIndennizzo,
                                            FranchigiaSalvata: 1,
                                            FranchigiaOScoperto: CustomJS.isNullEmpty(document.getElementById(`franchigia_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`franchigia_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            FranchigiaOScopertoMAN: CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`franchigiaMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DannoNetto: CustomJS.isNullEmpty(document.getElementById(`dannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            RipristiniUltimati: CustomJS.isNullEmpty(document.getElementById(`ripristiniUltimSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) ? 0 : document.getElementById(`ripristiniUltimSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 1 : 0,
                                            NoteRipristiniUltimati: CustomJS.isNullEmpty(document.getElementById(`noteRipristini-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRipristini-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            AccordoAnia: CustomJS.isNullEmpty(document.getElementById(`accordoAniaSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) ? 0 : document.getElementById(`accordoAniaSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 1 : 0,
                                            Coassicurazione: CustomJS.isNullEmpty(document.getElementById(`coassicurazioneSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) ? 0 : document.getElementById(`coassicurazioneSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 1 : 0,
                                            SommaDanniRiparto1: document.getElementById(`sommaDanniRiparto1_D${elm.d}_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`sommaDanniRiparto1_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`sommaDanniRiparto1_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : 0,
                                            SommaDanniRiparto2: document.getElementById(`sommaDanniRiparto1_D${elm.d}_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`sommaDanniRiparto2_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`sommaDanniRiparto2_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : 0,
                                            IndennizzoACaricVsCompagnia: document.getElementById(`indennizzoVsCompagnia_D${elm.d}_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`indennizzoVsCompagnia_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`indennizzoVsCompagnia_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : 0,
                                            NoteCoassicurazione: document.getElementById(`noteCoassicurazione-D${elm.d}_${this.props.IdInternoSinistro}`) ? (CustomJS.isNullEmpty(document.getElementById(`noteCoassicurazione-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteCoassicurazione-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) : '',
                                            EsitoCodice: esitoDanno,
                                            DannoNettoMAN: document.getElementById(`dannoNettoMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value ?? null,
                                        }
                                    }
                                    //STIMA STATO USO (JFTECH)
                                    else if (document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                                             || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                                             || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                                             || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                                             || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                                        queryStimaJftech = `UPDATE [11004] SET limite_sinistro = @limiteSinistro, piano = @piano, limiteIndennizzo = @limiteIndennizzo,franchigiaSalvata = @franchigiaSalvata, franchigiaOScoperto = @franchigiaOScoperto,franchigiaOScopertoMAN = @franchigiaOScopertoMAN, DCA_valore_a_nuovo = @DCAValoreANuovo,DCA_degrado25 = @DCADegrado25,DCA_valore_stato_uso = @DCAValoreStatoUso,DRP_valore_a_nuovo = @DRPValoreANuovo,DRP_valore_a_nuovoMAN = @DRPValoreANuovoMAN,DRP_degrado25 = @DRPDegrado25,DRP_degrado25MAN = @DRPDegrado25MAN,DRP_valore_stato_uso = @DRPValoreStatoUso,DRP_valore_stato_usoMAN = @DRPValoreStatoUsoMAN,DN_valore_a_nuovo = @DNValAnuovo,DN_degrado25 = @DNDegrado25,DN_valore_stato_uso = @DNValStatoUso, ripristini_ultimati = @ripristiniUltimati,note_ripristini_ultimati = @noteRipristiniUltimati, accordo_ania = @accordoAnia, coassicurazione = @coassicurazione, sommaDanniRiparto1 = @sommaDanniRiparto1, sommaDanniRiparto2 = @sommaDanniRiparto2,indennizzoACaricoVsComp = @indennizzoACaricVsCompagnia,suppl_indenn_coass = @supplIndennCoass,vsu_coass = @vsuCoass, descrizione_coass = @noteCoassicurazione,esito_danno = @esitoCodice, danno_netto_manuale = @dannoNettoMAN WHERE id_stima = ${elm.keyJftech};`;
                                        jsonObjectStimaJftech = {
                                            LimiteSinistro: CustomJS.isNullEmpty(document.getElementById(`limiteSinistro_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`limiteSinistro_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            Piano: CustomJS.isNullEmpty(document.getElementById(`piano-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`piano-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            LimiteIndennizzo: limiteIndennizzo,
                                            FranchigiaSalvata: 1,
                                            FranchigiaOScoperto: CustomJS.isNullEmpty(document.getElementById(`franchigia_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`franchigia_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            FranchigiaOScopertoMAN: CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`franchigiaMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DCAValoreANuovo: CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDannComplessAcc_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreANuovoDannComplessAcc_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DCADegrado25: CustomJS.isNullEmpty(document.getElementById(`degradoPercDannComplessAcc_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`degradoPercDannComplessAcc_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DCAValoreStatoUso: CustomJS.isNullEmpty(document.getElementById(`valoreBeneDannComplessAcc_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreBeneDannComplessAcc_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DRPValoreANuovo: CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaProp_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreANuovoDopoRegolaProp_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DRPValoreANuovoMAN: CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DRPDegrado25: CustomJS.isNullEmpty(document.getElementById(`degrado25PercDopoRegolaProp_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`degrado25PercDopoRegolaProp_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DRPDegrado25MAN: CustomJS.isNullEmpty(document.getElementById(`degrado25PercDopoRegolaPropMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`degrado25PercDopoRegolaPropMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DRPValoreStatoUso: CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionale_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dopoRegolaProporzionale_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DRPValoreStatoUsoMAN: CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dopoRegolaProporzionaleMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DNValAnuovo: CustomJS.isNullEmpty(document.getElementById(`valoreANuovodannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreANuovodannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DNDegrado25: CustomJS.isNullEmpty(document.getElementById(`degrado25PercdannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`degrado25PercdannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            DNValStatoUso: CustomJS.isNullEmpty(document.getElementById(`valoreStatoUsodannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreStatoUsodannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            RipristiniUltimati: CustomJS.isNullEmpty(document.getElementById(`ripristiniUltimSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) ? 0 : document.getElementById(`ripristiniUltimSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 1 : 0,
                                            NoteRipristiniUltimati: CustomJS.isNullEmpty(document.getElementById(`noteRipristini-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRipristini-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                            AccordoAnia: CustomJS.isNullEmpty(document.getElementById(`accordoAniaSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) ? 0 : document.getElementById(`accordoAniaSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 1 : 0,
                                            Coassicurazione: CustomJS.isNullEmpty(document.getElementById(`coassicurazioneSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) ? 0 : document.getElementById(`coassicurazioneSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 1 : 0,
                                            SommaDanniRiparto1: document.getElementById(`sommaDanniRiparto1_D${elm.d}_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`sommaDanniRiparto1_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`sommaDanniRiparto1_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : 0,
                                            SommaDanniRiparto2: document.getElementById(`sommaDanniRiparto1_D${elm.d}_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`sommaDanniRiparto2_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`sommaDanniRiparto2_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : 0,
                                            IndennizzoACaricVsCompagnia: document.getElementById(`indennizzoVsCompagnia_D${elm.d}_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`indennizzoVsCompagnia_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`indennizzoVsCompagnia_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : 0,
                                            SupplIndennCoass: document.getElementById(`supplIndennitaCoass-D${elm.d}_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`supplIndennitaCoass-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`supplIndennitaCoass-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : 0,
                                            VsuCoass: document.getElementById(`vsuCoass-D${elm.d}_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`vsuCoass-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`vsuCoass-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : 0,
                                            NoteCoassicurazione: document.getElementById(`noteCoassicurazione-D${elm.d}_${this.props.IdInternoSinistro}`) ? (CustomJS.isNullEmpty(document.getElementById(`noteCoassicurazione-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteCoassicurazione-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) : '',
                                            EsitoCodice: esitoDanno,
                                            DannoNettoMAN: document.getElementById(`dannoNettoMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value ?? null
                                        }
                                    }
                                    console.log(jsonObjectStimaJftech)
                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryStimaJftech, JSON.stringify(jsonObjectStimaJftech), retDataStimeJftech => {
                                        resolveUpdateJftech();
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });
                                    }, true, true);
                                })
                            }
                            await updateStimaJftech();
                            //GENERALI
                            if (this.isCompagniaGenerali()) {
                                const updateStimaGEN = () => {
                                    console.log(this.scopertiCondivisi);
                                    return new Promise(async (resolveUpdateGEN) => {
                                        let queryStimaGen = `UPDATE [12011] set totale_danno = @dannoComplessivoAccertato, ripristini_ultimati = @ripristiniUltimati, note = @noteRipristiniUltimati,supplemento_indennizzo = @supplementoIndennizzo,totale_lordo_limitazioni = @totLordoLimitazioni,insufficienza_assicurativa_percentuale = @insufficienzaAssicurativaPerc,alNettoDi_insufficienza_assicurativa = @alNettoInsufficienzaAssicurativa,scoperto_percentuale = @scopertoPerc,alNettoDi_scoperto = @alNettoDiScoperto,scoperto_minimo = @scopertoMin,franchigia = @franchigia,alNettoDi_franchigia_scopertoMinimo = @alNettoFranchigiaScopMin,massimale = @massimale,indennizzo_netto = @dannoNetto,coassicurazione_indiretta_presente = @coassicurazIndirPresente,coassicurazione_indiretta_accordo_ANIA = @accordoAnia,note_riparto_coassicurazione = @noteRipCoassicurazione, indennizzo_finale = @indennizzoFinale,esito_codice = @esitoCodice,esito_descrizione = @esitoDescrizione, indennizzo_finale_manuale = @indennizzoFinaleMAN  WHERE id_danno = ${elm.keyGen}`;
                                        let jsonObjectStimaGen = {};
                                        let franchigiaDaInserire = CustomJS.isNullEmpty(document.getElementById(`franchigiaMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`franchigia_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`franchigiaMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                        let scopertoPerc = 0, scopertoMin = 0;
                                        let gruppi = Object.keys(this.scopertiCondivisi);
                                        for (let gruppo of gruppi) {
                                            for (let scop of this.scopertiCondivisi[gruppo]) {
                                                if (scop.idStimaCondivisa === document.getElementById(`idStima-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) {                                
                                                    scopertoPerc = scop.scopertoPerc;
                                                    scopertoMin = scop.scopertoMin;
                                                }
                                            }
                                        }
                                        //STIMA VALORE A NUOVO (GENERALI)
                                        if (document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                                            //let valoreANuovoDRP = CustomJS.isNullEmpty(document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`valoreANuovoDopoRegolaProp_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`valoreANuovoDopoRegolaPropMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                            let valoreANuovoDRP = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                            //todo DA RIGUARDARE L'INDENNIZZO FINALE
                                            if (indennizzoFinale === 0)
                                                indennizzoFinale = CustomJS.isNullEmpty(document.getElementById(`dannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                            jsonObjectStimaGen = {
                                                DannoComplessivoAccertato: CustomJS.isNullEmpty(document.getElementById(`dannoComplessAccert_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoComplessAccert_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                RipristiniUltimati: CustomJS.isNullEmpty(document.getElementById(`ripristiniUltimSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) ? 0 : document.getElementById(`ripristiniUltimSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 1 : 0,
                                                NoteRipristiniUltimati: CustomJS.isNullEmpty(document.getElementById(`noteRipristini-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRipristini-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                SupplementoIndennizzo: 0,
                                                TotLordoLimitazioni: CustomJS.isNullEmpty(franchigiaDaInserire) ? 0 : franchigiaDaInserire,
                                                InsufficienzaAssicurativaPerc: CustomJS.isNullEmpty(insuffAss) ? 0 : insuffAss,
                                                AlNettoInsufficienzaAssicurativa: valoreANuovoDRP,
                                                ScopertoPerc: scopertoPerc,
                                                ScopertoMin: scopertoMin,
                                                AlNettoDiScoperto: document.getElementById(`isFranchigiaOrScoperto-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'scoperto' ? document.getElementById(`dannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dannoComplessAccert_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                Franchigia: document.getElementById(`isFranchigiaOrScoperto-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'scoperto' ? 0 : CustomJS.isNullEmpty(franchigiaDaInserire) ? 0 : franchigiaDaInserire,
                                                AlNettoFranchigiaScopMin: CustomJS.isNullEmpty(document.getElementById(`dannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                Massimale: CustomJS.isNullEmpty(document.getElementById(`limiteSinistro_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`limiteSinistro_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                DannoNetto: CustomJS.isNullEmpty(document.getElementById(`dannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                CoassicurazIndirPresente: CustomJS.isNullEmpty(document.getElementById(`coassicurazioneSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) ? 0 : document.getElementById(`coassicurazioneSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 1 : 0,
                                                AccordoAnia: CustomJS.isNullEmpty(document.getElementById(`accordoAniaSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) ? 0 : document.getElementById(`accordoAniaSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 1 : 0,
                                                NoteRipCoassicurazione: document.getElementById(`noteCoassicurazione-D${elm.d}_${this.props.IdInternoSinistro}`) ? ((CustomJS.isNullEmpty(document.getElementById(`noteCoassicurazione-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteCoassicurazione-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)) : '',
                                                IndennizzoFinale: Math.round(indennizzoFinale), // Indennizzo a carico Vs Compagnia 
                                                EsitoCodice: document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : '',
                                                EsitoDescrizione: document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].text : '',
                                                IndennizzoFinaleMAN : document.getElementById(`dannoNettoMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value ?? null,
                                            }
                                        }
                                        //STIMA STATO USO (GENERALI)
                                        else if (document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                                                 || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'                                            
                                                 || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                                                 || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                                                 || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                                            let valoreDRP = CustomJS.isNullEmpty(document.getElementById(`dopoRegolaProporzionaleMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`dopoRegolaProporzionale_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`dopoRegolaProporzionaleMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                                            const dannoComplessivoAccertato = CustomJS.isNullEmpty(document.getElementById(`valoreBeneDannComplessAcc_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreBeneDannComplessAcc_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                            const supplementoIndennizzo = document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002' ? 0 : CustomJS.isNullEmpty(document.getElementById(`degrado25PercdannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : (document.getElementById(`degrado25PercDopoRegolaProp_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value < document.getElementById(`degrado25PercdannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`degrado25PercDopoRegolaProp_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`degrado25PercdannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                            const totLordoLimitazioni = parseFloat(dannoComplessivoAccertato + supplementoIndennizzo).toFixed(2);
                                            if (indennizzoFinale === 0) {
                                                const determinazioneDanno = document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                const valoreStatoUsoDN = document.getElementById(`valoreStatoUsodannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                const valoreANuovoDN = document.getElementById(`valoreANuovodannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                const degrado25PercDN = document.getElementById(`degrado25PercdannoNetto_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                const degrado25PercDRP = document.getElementById(`degrado25PercDopoRegolaProp_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                if (determinazioneDanno === '002') 
                                                    indennizzoFinale = valoreStatoUsoDN;
                                                else if (valoreStatoUsoDN === 0) {
                                                    if ((valoreANuovoDN - degrado25PercDN) < 0) 
                                                        indennizzoFinale = valoreANuovoDN;
                                                    else if (degrado25PercDRP < degrado25PercDN)
                                                        indennizzoFinale = parseFloat(valoreANuovoDN - degrado25PercDN + degrado25PercDRP).toFixed(2);
                                                    else
                                                        indennizzoFinale = parseFloat(valoreANuovoDN - degrado25PercDN).toFixed(2);                                                    
                                                } 
                                                else if (degrado25PercDRP < degrado25PercDN)
                                                    indennizzoFinale = parseFloat(valoreANuovoDN - degrado25PercDN + degrado25PercDRP).toFixed(2); 
                                                else 
                                                    indennizzoFinale = parseFloat(valoreStatoUsoDN + degrado25PercDN).toFixed(2);
                                            }
                                            jsonObjectStimaGen = {
                                                DannoComplessivoAccertato: dannoComplessivoAccertato,
                                                RipristiniUltimati: CustomJS.isNullEmpty(document.getElementById(`ripristiniUltimSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) ? 0 : document.getElementById(`ripristiniUltimSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 1 : 0,
                                                NoteRipristiniUltimati: CustomJS.isNullEmpty(document.getElementById(`noteRipristini-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteRipristini-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                SupplementoIndennizzo: supplementoIndennizzo,
                                                TotLordoLimitazioni: totLordoLimitazioni,
                                                InsufficienzaAssicurativaPerc: CustomJS.isNullEmpty(insuffAss) ? 0 : insuffAss,
                                                AlNettoInsufficienzaAssicurativa: valoreDRP + supplementoIndennizzo,
                                                ScopertoPerc: scopertoPerc,
                                                ScopertoMin: scopertoMin,
                                                AlNettoDiScoperto: (totLordoLimitazioni - ((totLordoLimitazioni*scopertoPerc/100) < scopertoMin ? scopertoMin : (totLordoLimitazioni*scopertoPerc/100))) < 0 ? 0 : (totLordoLimitazioni - ((totLordoLimitazioni*scopertoPerc/100) < scopertoMin ? scopertoMin : (totLordoLimitazioni*scopertoPerc/100))),
                                                Franchigia: document.getElementById(`isFranchigiaOrScoperto-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'scoperto' ? 0 : CustomJS.isNullEmpty(franchigiaDaInserire) ? 0 : franchigiaDaInserire,
                                                AlNettoFranchigiaScopMin: (totLordoLimitazioni - franchigiaDaInserire) < 0 ? 0 : parseFloat(totLordoLimitazioni - franchigiaDaInserire).toFixed(2),
                                                Massimale: CustomJS.isNullEmpty(document.getElementById(`limiteSinistro_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`limiteSinistro_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                DannoNetto: indennizzoFinale,
                                                CoassicurazIndirPresente: CustomJS.isNullEmpty(document.getElementById(`coassicurazioneSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) ? 0 : document.getElementById(`coassicurazioneSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 1 : 0,
                                                AccordoAnia: CustomJS.isNullEmpty(document.getElementById(`accordoAniaSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) ? 0 : document.getElementById(`accordoAniaSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true ? 1 : 0,
                                                NoteRipCoassicurazione: document.getElementById(`noteCoassicurazione-D${elm.d}_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`noteCoassicurazione-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteCoassicurazione-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : '',
                                                //se ce coassicurazione Indennizzo a carico Vs Compagnia :
                                                IndennizzoFinale: Math.round(indennizzoFinale),
                                                EsitoCodice: document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : '',
                                                EsitoDescrizione: document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`) ? CustomJS.isNullEmpty(document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].text : '',
                                                IndennizzoFinaleMAN : document.getElementById(`dannoNettoMAN_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value ?? null,
                                            }
                                        }
                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryStimaGen, JSON.stringify(jsonObjectStimaGen), retDataStimeGen => {
                                            resolveUpdateGEN();
                                        }, messaggio => {
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            });
                                        }, true, true);
                                    })
                                }
                                await updateStimaGEN();
                            }
                            const finePrezziari = () => {
                                return new Promise((resolveFinePrezziari) => {
                                    console.log('inizio prezziari')

                                    let funcListPrezziari = [];
                                    this.strutturaStatiStimePrezziari.forEach((elm2) => {
                                        const eseguiPrezziario = () => {

                                            return new Promise(async (resolvePrezziario) => {
                                                if (elm2.id === `tabPrezziario-D${elm.d}-R-${elm2.r}_${this.props.IdInternoSinistro}`) {
                                                    if (elm2.stato !== 'd') {
                                                        //-------DATI PREZZIARIO
                                                        //Gestione descrizioni libere o scelte
                                                        var categoriaPrezziario = '';
                                                        var descrizionePrezziarioGen = '';

                                                        if (!CustomJS.isNullEmpty(document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)) {
                                                            descrizionePrezziarioGen = document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].text;
                                                            if (this.isCompagniaGenerali())
                                                                categoriaPrezziario = document.getElementById(`categoriaSceltaPrezziario_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                                                        }
                                                    }
                                                    //INSERIMENTO PREZZIARIO
                                                    var guidPrezzGenerata = CustomJS.createGuid4();
                                                    //PREZZIARIO JFTECH
                                                    const funcPrezziarioJftech = () => {
                                                        return new Promise(async (resolvePrezzJftech) => {
                                                            //descrizIONI JFTECH
                                                            var queryStimaDescrJftech = '';
                                                            var jsonObjectStimaDescrJftech = {};

                                                            //VALORE A NUOVO descrizIONI PREZZIARIO
                                                            if (document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                                                                if (elm2.stato === 'n') {
                                                                    queryStimaDescrJftech = `INSERT INTO [11009] (id_stima,id_sinistro,descrizione_lunga_prezziario,descrizione_cambiata,categoria_prezziario,localizzazione,unita_misura,quantita,valore_unitario,totale,guid_utile_descr,fotografia_documento) VALUES (${elm.keyJftech},${this.props.IdSinistro},@descrizioneLunga,@descrizioneCambiata,@categoriaPrezziario,@localizzazione,@unitaMisura,@quantita,@valoreUnitarioPrez,@totaleRigaPrez,'${guidPrezzGenerata}',@fotografiaDocumentoStima);`;
                                                                }
                                                                else if (elm2.stato === 'o') {
                                                                    queryStimaDescrJftech = `UPDATE [11009] SET descrizione_lunga_prezziario = @descrizioneLunga,descrizione_cambiata = @descrizioneCambiata,categoria_prezziario = @categoriaPrezziario,localizzazione = @localizzazione,unita_misura = @unitaMisura, quantita = @quantita,valore_unitario = @valoreUnitarioPrez,totale = @totaleRigaPrez, fotografia_documento = @fotografiaDocumentoStima WHERE id_stima_descrizione = ${elm2.keyJftech};`;
                                                                }
                                                                else if (elm2.stato === 'd') {
                                                                    queryStimaDescrJftech = `DELETE FROM [11009] WHERE id_stima_descrizione = ${elm2.keyJftech}`;
                                                                }
                                                                if (elm2.stato === 'd') {
                                                                    jsonObjectStimaDescrJftech = {
                                                                    };
                                                                }
                                                                else {
                                                                    const imgBase64 = document.getElementById(`pathFotografiaDocumentoStima_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`) ? await CustomJS.getImageBase64AC3(document.getElementById(`pathFotografiaDocumentoStima_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).value, document.getElementById(`pathFotografiaDocumentoStimaBackup_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).value) : null;
                                                                    jsonObjectStimaDescrJftech = {
                                                                        Localizzazione: this.isCompagniaGenerali() ? CustomJS.isNullEmpty(document.getElementById(`localizzazione_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`localizzazione_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : CustomJS.isNullEmpty(document.getElementById(`beneDanneggiato_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`beneDanneggiato_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        DescrizioneLunga: CustomJS.isNullEmpty(document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        DescrizioneCambiata: CustomJS.isNullEmpty(document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                                                        CategoriaPrezziario: categoriaPrezziario,
                                                                        UnitaMisura: CustomJS.isNullEmpty(document.getElementById(`UMInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`UMInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        Quantita: CustomJS.isNullEmpty(document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        ValoreUnitarioPrez: CustomJS.isNullEmpty(document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`)) ? 0 : CustomJS.isNullEmpty(document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        TotaleRigaPrez: CustomJS.isNullEmpty(document.getElementById(`totaleResult_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleResult_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        FotografiaDocumentoStima: document.getElementById(`pathFotografiaDocumentoStima_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`) ? `${document.getElementById(`pathFotografiaDocumentoStima_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).value}[|]${imgBase64.base64}` : null
                                                                    }
                                                                }
                                                            }
                                                            //STIMA STATO USO DESCRIZIONI PREZZIARIO
                                                            else if (document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                                                                     || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'                                                                
                                                                     || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                                                                     || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                                                                     || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                                                                if (elm2.stato === 'n') {
                                                                    queryStimaDescrJftech = `INSERT INTO [11009] (id_stima,id_sinistro,descrizione_lunga_prezziario,descrizione_cambiata,categoria_prezziario,localizzazione,unita_misura,quantita,valore_unitario,totale,supp_indenn_perc,supp_indenn_importo,valore_del_bene,guid_utile_descr,fotografia_documento) VALUES (${elm.keyJftech},${this.props.IdSinistro},@descrizioneLunga,@descrizioneCambiata,@categoriaPrezziario,@localizzazione,@unitaMisura,@quantita,@valoreUnitarioPrez,@totaleRigaPrez,@supplIndennPerc,@supplIndennImporto,@valoreDelBene,'${guidPrezzGenerata}',@fotografiaDocumentoStima);`;
                                                                }
                                                                else if (elm2.stato === 'o') {
                                                                    queryStimaDescrJftech = ` UPDATE [11009] SET descrizione_lunga_prezziario = @descrizioneLunga,descrizione_cambiata = @descrizioneCambiata, categoria_prezziario = @categoriaPrezziario, localizzazione = @localizzazione, unita_misura = @unitaMisura, quantita = @quantita, valore_unitario = @valoreUnitarioPrez,totale = @totaleRigaPrez, supp_indenn_perc = @supplIndennPerc, supp_indenn_importo = @supplIndennImporto, valore_del_bene = @valoreDelBene, fotografia_documento = @fotografiaDocumentoStima WHERE id_stima_descrizione = ${elm2.keyJftech};`;
                                                                }
                                                                else if (elm2.stato === 'd') {
                                                                    queryStimaDescrJftech = `DELETE FROM [11009] WHERE id_stima_descrizione = ${elm2.keyJftech}`;
                                                                }
                                                                if (elm2.stato === 'd') {
                                                                    jsonObjectStimaDescrJftech = {
                                                                    };
                                                                }
                                                                else {
                                                                    const imgBase64 = document.getElementById(`pathFotografiaDocumentoStima_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`) ? await CustomJS.getImageBase64AC3(document.getElementById(`pathFotografiaDocumentoStima_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).value, document.getElementById(`pathFotografiaDocumentoStimaBackup_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).value) : null;
                                                                    jsonObjectStimaDescrJftech = {
                                                                        Localizzazione: this.isCompagniaGenerali() ? CustomJS.isNullEmpty(document.getElementById(`localizzazione_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`localizzazione_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value : CustomJS.isNullEmpty(document.getElementById(`beneDanneggiato_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`beneDanneggiato_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        DescrizioneLunga: CustomJS.isNullEmpty(document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        DescrizioneCambiata: CustomJS.isNullEmpty(document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                                                        CategoriaPrezziario: categoriaPrezziario,
                                                                        UnitaMisura: CustomJS.isNullEmpty(document.getElementById(`UMInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`UMInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        Quantita: CustomJS.isNullEmpty(document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        ValoreUnitarioPrez: CustomJS.isNullEmpty(document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        TotaleRigaPrez: CustomJS.isNullEmpty(document.getElementById(`valANuovoInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valANuovoInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        SupplIndennPerc: CustomJS.isNullEmpty(document.getElementById(`percDegrSupInd_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`percDegrSupInd_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        SupplIndennImporto: CustomJS.isNullEmpty(document.getElementById(`importoDegrSupInd_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`importoDegrSupInd_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        ValoreDelBene: CustomJS.isNullEmpty(document.getElementById(`valoreBeneResult_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreBeneResult_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        FotografiaDocumentoStima: document.getElementById(`pathFotografiaDocumentoStima_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`) ? `${document.getElementById(`pathFotografiaDocumentoStima_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).value}[|]${imgBase64.base64}` : null
                                                                    }
                                                                }
                                                            }
                                                            console.log(jsonObjectStimaDescrJftech)
                                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryStimaDescrJftech, JSON.stringify(jsonObjectStimaDescrJftech), retDataStimeDescrJftech => {
                                                                resolvePrezzJftech()
                                                            }, messaggio => {
                                                                toast.warn(messaggio, {
                                                                    containerId: 'toastContainer1',
                                                                    onClose: () => toast.dismiss(),
                                                                    position: "bottom-right",
                                                                    autoClose: 5000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: false,
                                                                    progress: undefined,
                                                                    newestOnTop: true,
                                                                    rtl: false,
                                                                    pauseOnFocusLoss: true
                                                                });
                                                            }, true, true);
                                                        })
                                                    }
                                                    await funcPrezziarioJftech();

                                                    //------PREZZIARIO + LOCALIZZAZIONE GENERALI-------
                                                    if (this.isCompagniaGenerali()) {
                                                        var guidGenerataDettaglio = CustomJS.createGuid4()
                                                        const funcPrezziarioGen = () => {
                                                            return new Promise(async (resolvePrezzGen) => {
                                                                var queryStimaDescrGenerali = '';
                                                                var jsonObjectStimaDescrGen = {};
                                                                //VALORE A NUOVO PREZZIARIO
                                                                if (document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                                                                    if (elm2.stato === 'n') {
                                                                        queryStimaDescrGenerali = `BEGIN TRAN;INSERT INTO [12013] (id_danno,id_stima_descrizione,id_incarico,codice_tipologia_voce,descrizione,unita_misura,quantita,valore_unitario,degrado_percentuale,valore_unitario_residuo,valore_danno,guid_utile_dettaglio,fotografia) VALUES (${elm.keyGen},(SELECT id_stima_descrizione FROM [11009] WITH (UPDLOCK, HOLDLOCK) WHERE guid_utile_descr = '${guidPrezzGenerata}' and id_sinistro = ${this.props.IdSinistro}),'${this.props.IdIncarico}',@codiceTipVoce,@descrizioneStima,@unitaMisuraGen,@quantitaGen,@valoreUnitarioPrezGen,@degradoPerc,@valoreUnitResiduo,@totaleRigaPrezGen,'${guidGenerataDettaglio}',@fotografiaDocumentoStima);COMMIT;`;
                                                                    }
                                                                    else if (elm2.stato === 'o') {
                                                                        queryStimaDescrGenerali = `BEGIN TRAN;UPDATE [12013] SET codice_tipologia_voce = @codiceTipVoce, descrizione = @descrizioneStima, unita_misura = @unitaMisuraGen, quantita = @quantitaGen, valore_unitario = @valoreUnitarioPrezGen,degrado_percentuale = @degradoPerc, valore_unitario_residuo = @valoreUnitResiduo, valore_danno = @totaleRigaPrezGen, fotografia = @fotografiaDocumentoStima WHERE id_dettaglio = ${elm2.keyGen};COMMIT;`;
                                                                    }
                                                                    else if (elm2.stato === 'd') {
                                                                        queryStimaDescrGenerali = `DELETE FROM [12013] WHERE id_dettaglio = ${elm2.keyGen};`;
                                                                    }
                                                                    if (elm2.stato === 'd') {
                                                                        jsonObjectStimaDescrGen = {
                                                                        }
                                                                    }
                                                                    else {
                                                                        const imgBase64 = document.getElementById(`pathFotografiaDocumentoStima_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`) ? await CustomJS.getImageBase64AC3Optimized(document.getElementById(`pathFotografiaDocumentoStima_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).value, document.getElementById(`pathFotografiaDocumentoStimaBackup_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).value) : null;
                                                                        jsonObjectStimaDescrGen = {
                                                                            CodiceTipVoce: categoriaPrezziario,
                                                                            DescrizioneStima: descrizionePrezziarioGen,
                                                                            UnitaMisuraGen: CustomJS.isNullEmpty(document.getElementById(`UMInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`UMInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            QuantitaGen: CustomJS.isNullEmpty(document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            ValoreUnitarioPrezGen: CustomJS.isNullEmpty(document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            DegradoPerc: 0,
                                                                            ValoreUnitResiduo: 0,
                                                                            TotaleRigaPrezGen: CustomJS.isNullEmpty(document.getElementById(`totaleResult_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleResult_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            FotografiaDocumentoStima: document.getElementById(`pathFotografiaDocumentoStima_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`) ? imgBase64.base64.substring(imgBase64.base64.indexOf(',')+1) : null
                                                                       }
                                                                    }
                                                                }
                                                                //STATO USO PREZZIARIO
                                                                else if (document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                                                                         || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'                                                                        
                                                                         || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                                                                         || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                                                                         || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                                                                    if (elm2.stato === 'n') {
                                                                        queryStimaDescrGenerali = `BEGIN TRAN;INSERT INTO [12013] (id_danno,id_stima_descrizione,id_incarico,codice_tipologia_voce,descrizione,unita_misura,quantita,valore_unitario,degrado_percentuale,valore_unitario_residuo,valore_danno,guid_utile_dettaglio,fotografia) VALUES (${elm.keyGen},(SELECT id_stima_descrizione FROM [11009] WITH (UPDLOCK, HOLDLOCK) WHERE guid_utile_descr = '${guidPrezzGenerata}' and id_sinistro = ${this.props.IdSinistro}),'${this.props.IdIncarico}',@codiceTipVoce,@descrizioneStima,@unitaMisuraGen,@quantitaGen,@valoreUnitarioPrezGen,@degradoPerc,@valoreUnitResiduo,@totaleRigaPrezGen ,'${guidGenerataDettaglio}',@fotografiaDocumentoStima);COMMIT;`;
                                                                    }
                                                                    else if (elm2.stato === 'o') {
                                                                        queryStimaDescrGenerali = `BEGIN TRAN;UPDATE [12013] SET codice_tipologia_voce = @codiceTipVoce, descrizione = @descrizioneStima, unita_misura = @unitaMisuraGen, quantita = @quantitaGen, valore_unitario = @valoreUnitarioPrezGen,degrado_percentuale = @degradoPerc, valore_unitario_residuo = @valoreUnitResiduo, valore_danno = @totaleRigaPrezGen, fotografia = @fotografiaDocumentoStima WHERE id_dettaglio = ${elm2.keyGen};COMMIT;`;
                                                                    }
                                                                    else if (elm2.stato === 'd') {
                                                                        queryStimaDescrGenerali = `DELETE FROM [12013] WHERE id_dettaglio = ${elm2.keyGen};`;
                                                                    }
                                                                    if (elm2.stato === 'd') {
                                                                        jsonObjectStimaDescrGen = {
                                                                        }
                                                                    }
                                                                    else {
                                                                        const imgBase64 = document.getElementById(`pathFotografiaDocumentoStima_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`) ? await CustomJS.getImageBase64AC3Optimized(document.getElementById(`pathFotografiaDocumentoStima_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).value, document.getElementById(`pathFotografiaDocumentoStimaBackup_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).value) : null;
                                                                        jsonObjectStimaDescrGen = {
                                                                            CodiceTipVoce: categoriaPrezziario,
                                                                            DescrizioneStima: descrizionePrezziarioGen,
                                                                            UnitaMisuraGen: CustomJS.isNullEmpty(document.getElementById(`UMInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`UMInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            QuantitaGen: CustomJS.isNullEmpty(document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`quantitaInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            ValoreUnitarioPrezGen: CustomJS.isNullEmpty(document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valUnitarioInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            DegradoPerc: CustomJS.isNullEmpty(document.getElementById(`percDegrSupInd_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`percDegrSupInd_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            ValoreUnitResiduo: CustomJS.isNullEmpty(document.getElementById(`importoDegrSupInd_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`importoDegrSupInd_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            TotaleRigaPrezGen: CustomJS.isNullEmpty(document.getElementById(`valoreBeneResult_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`valoreBeneResult_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                            FotografiaDocumentoStima: document.getElementById(`pathFotografiaDocumentoStima_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`) ? imgBase64.base64.substring(imgBase64.base64.indexOf(',')+1) : null
                                                                        }
                                                                    }
                                                                }
                                                                console.log(jsonObjectStimaDescrGen)
                                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryStimaDescrGenerali, JSON.stringify(jsonObjectStimaDescrGen), retDataStimeDescrGen => {
                                                                    resolvePrezzGen()
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    });
                                                                }, true, true);
                                                            })
                                                        }
                                                        await funcPrezziarioGen()
                                                        //GESTIONE DANNO DETTAGLI INFO RICHIESTE GENERALI ( LOCALIZZAZIONE)
                                                        const localizzazioneGEN = () => {
                                                            return new Promise((resolveLocalizzazioneGEN) => {
                                                                var descrizInfoRic = '';
                                                                var servizioStringa2 = '';

                                                                if (elm2.stato !== 'd') {
                                                                    var codiceInfoRic = document.getElementById(`localizzazione_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                                                    let obj = _.find(this.nuovoArrRispInfoRichDanno, elm => {
                                                                        return elm.cod_ele === codiceInfoRic;
                                                                    });
                                                                    if (!CustomJS.isNullEmpty(obj))
                                                                        servizioStringa2 = obj.servizio_str2;

                                                                    let obj2 = _.find(this.nuovoArrInfoRichDanno, elm => {
                                                                        return elm.cod_ele === servizioStringa2;
                                                                    });
                                                                    if (!CustomJS.isNullEmpty(obj2))
                                                                        descrizInfoRic = obj2.descriz;
                                                                }

                                                                var queryInfoRichiesta = ''
                                                                var jsonObjectInfoRich = {};
                                                                if (elm2.stato === 'n') {
                                                                    queryInfoRichiesta = `INSERT INTO [12016] (id_dettaglio,id_incarico,codice,descrizione,risposta) VALUES ((SELECT id_dettaglio FROM [12013] WITH (UPDLOCK, HOLDLOCK) WHERE guid_utile_dettaglio = '${guidGenerataDettaglio}' and id_incarico = '${this.props.IdIncarico}'),'${this.props.IdIncarico}',@codiceElemento,@descrizioneElemento,@noteRisposte);`;
                                                                }
                                                                else if (elm2.stato === 'o') {
                                                                    queryInfoRichiesta = `UPDATE [12016] set codice = @codiceElemento, descrizione = @descrizioneElemento, risposta = @noteRisposte where id_info_richiesta = ${elm2.keyGenInfoDett};`;
                                                                }
                                                                else if (elm2.stato === 'd') {
                                                                    queryInfoRichiesta = `DELETE FROM [12016] WHERE id_info_richiesta = ${elm2.keyGenInfoDett};`;
                                                                }
                                                                if (elm2.stato === 'd') {
                                                                    jsonObjectInfoRich = {
                                                                    }
                                                                }
                                                                else {
                                                                    jsonObjectInfoRich = {
                                                                        CodiceElemento: servizioStringa2,
                                                                        DescrizioneElemento: descrizInfoRic,
                                                                        NoteRisposte: CustomJS.isNullEmpty(document.getElementById(`localizzazione_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`localizzazione_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`).ej2_instances[0].text
                                                                    }
                                                                }
                                                                console.log(jsonObjectInfoRich)
                                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryInfoRichiesta, JSON.stringify(jsonObjectInfoRich), retDataInfo => {
                                                                    resolveLocalizzazioneGEN();
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    });
                                                                }, true, true);
                                                            })
                                                        }
                                                        await localizzazioneGEN()
                                                    }

                                                    resolvePrezziario('Prossima');
                                                }
                                                else {
                                                    resolvePrezziario('Prossima')
                                                }
                                            })
                                        }
                                        funcListPrezziari.push(eseguiPrezziario);
                                    })
                                    const eseguiCiclica = (ind) => {
                                        if (CustomJS.isNullEmpty(funcListPrezziari[ind])) {
                                            resolveFinePrezziari();
                                        }
                                        else {
                                            funcListPrezziari[ind]().then(ret => {
                                                if (ret === 'Prossima') {
                                                    eseguiCiclica(ind + 1);
                                                }
                                            }).catch(err => {
                                                console.log(err)
                                            })
                                        }
                                    }
                                    eseguiCiclica(0);
                                })
                            }
                            await finePrezziari();

                            //COASSICURAZIONI 
                            if (document.getElementById(`coassicurazioneSi-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked === true) {
                                console.log('inizio coassicurazioni si')
                                const fineCoassicurazioni = () => {
                                    return new Promise((resolveFineCoass) => {
                                        let funcListCoassicurazioniSI = [];
                                        this.strutturaStatiCoassicurazioniRighe.forEach((elm3) => {
                                            const eseguiCoassSI = () => {
                                                return new Promise(async (resolveCoassSI) => {

                                                    var guidGenerataCoass = CustomJS.createGuid4()
                                                    if (elm3.id === `rigaRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`) {
                                                        //COASSICURAZIONI JFTECH
                                                        const coassicurazioneJftechSI = () => {
                                                            return new Promise((resolveCoassJftech) => {
                                                                var queryCoassJftech = ''
                                                                var jsonObjectCoassJftech = {};
                                                                if (elm3.stato === 'n') {
                                                                    queryCoassJftech = `INSERT INTO [11010] (id_stima,id_sinistro,compagniaCoinvolta,dannoARiparto,polizzaCoass,tipoLimiteContrattuale,limiteContrattuale,dannoNetto,formula,dannoNettoRip2,guid_utile_coass) VALUES (${elm.keyJftech},${this.props.IdSinistro},@compagniaCoinvolta,@dannoARiparto,@numeroPolizzaCoass,@tipoLimiteContratt,@limiteContrattuale,@dannoNettoRip1,@formula,@dannoNettoRip2, '${guidGenerataCoass}');`;
                                                                }
                                                                else if (elm3.stato === 'o') {
                                                                    queryCoassJftech = `UPDATE [11010] set compagniaCoinvolta = @compagniaCoinvolta, dannoARiparto = @dannoARiparto, polizzaCoass = @numeroPolizzaCoass, tipoLimiteContrattuale = @tipoLimiteContratt, limiteContrattuale = @limiteContrattuale, dannoNetto = @dannoNettoRip1, formula = @formula, dannoNettoRip2 = @dannoNettoRip2 WHERE id_compagnia_coass = ${elm3.keyJftech};`;
                                                                }
                                                                else if (elm3.stato === 'd') {
                                                                    queryCoassJftech = `DELETE FROM [11010]  WHERE id_compagnia_coass = ${elm3.keyJftech};`;
                                                                }
                                                                if (elm3.stato === 'd') {
                                                                    jsonObjectCoassJftech = {
                                                                    }
                                                                }
                                                                else {
                                                                    jsonObjectCoassJftech = {
                                                                        CompagniaCoinvolta: CustomJS.isNullEmpty(document.getElementById(`compagniaCoinvoltaRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`compagniaCoinvoltaRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        DannoARiparto: CustomJS.isNullEmpty(document.getElementById(`dannoARipartoRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoARipartoRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        NumeroPolizzaCoass: CustomJS.isNullEmpty(document.getElementById(`numPolizzaRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`numPolizzaRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        TipoLimiteContratt: CustomJS.isNullEmpty(document.getElementById(`tipoLimitazioneContrattualeRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`tipoLimitazioneContrattualeRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        LimiteContrattuale: CustomJS.isNullEmpty(document.getElementById(`limitazioneContrattualeRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`limitazioneContrattualeRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        DannoNettoRip1: CustomJS.isNullEmpty(document.getElementById(`dannoNettoCoassDirRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannoNettoCoassDirRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        Formula: CustomJS.isNullEmpty(document.getElementById(`formulaCoass-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`formulaCoass-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                        DannoNettoRip2: CustomJS.isNullEmpty(document.getElementById(`dannNettoRip2-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`dannNettoRip2-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                                    }
                                                                }
                                                                console.log(jsonObjectCoassJftech)
                                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryCoassJftech, JSON.stringify(jsonObjectCoassJftech), retDataCoassJftech => {
                                                                    resolveCoassJftech()
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    });
                                                                }, true, true);
                                                            })
                                                        }
                                                        await coassicurazioneJftechSI()
                                                        //COASSICURAZIONI GENERALI
                                                        var queryCoassGen = '';
                                                        var jsonObjectCoassGen = {}

                                                        if (elm3.stato === 'n') {
                                                            queryCoassGen = `BEGIN TRAN; INSERT INTO [12014] (id_compagnia_coass_jftech,id_danno,id_incarico,codice_ANIA,descrizione,numero_polizza,percentuale_rischio) VALUES ((SELECT id_compagnia_coass from [11010] WITH (UPDLOCK, HOLDLOCK) WHERE guid_utile_coass = '${guidGenerataCoass}' and id_sinistro = ${this.props.IdSinistro}),${elm.keyGen},'${this.props.IdIncarico}',@codiceAnia,@descrizione,@numeroPolizza,@quotaPerc); COMMIT;`;
                                                        }
                                                        else if (elm3.stato === 'o') {
                                                            queryCoassGen = `UPDATE [12014] set codice_ANIA = @codiceAnia, descrizione = @descrizione, numero_polizza = @numeroPolizza, percentuale_rischio = @quotaPerc WHERE id_coassi_indiretta = ${elm3.keyGen};`;
                                                        }
                                                        else if (elm3.stato === 'd') {
                                                            queryCoassGen = `DELETE FROM [12014] WHERE id_coassi_indiretta = ${elm3.keyGen};`;
                                                        }
                                                        if (elm3.stato === 'd') {
                                                            jsonObjectCoassGen = {
                                                            }
                                                        }
                                                        else {
                                                            jsonObjectCoassGen = {
                                                                CodiceAnia: CustomJS.isNullEmpty(document.getElementById(`compagniaCoinvoltaRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : (document.getElementById(`compagniaCoinvoltaRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value),
                                                                Descrizione: CustomJS.isNullEmpty(document.getElementById(`compagniaCoinvoltaRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : (document.getElementById(`compagniaCoinvoltaRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].text),
                                                                NumeroPolizza: CustomJS.isNullEmpty(document.getElementById(`numPolizzaRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : (document.getElementById(`numPolizzaRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`).ej2_instances[0].value),
                                                                QuotaPerc: 0 //percentuale rischio da gestire
                                                            }
                                                        }
                                                        console.log(jsonObjectCoassGen)
                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryCoassGen, JSON.stringify(jsonObjectCoassGen), retDataCoassGen => {
                                                            resolveCoassSI('Prossima')
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            });
                                                        }, true, true);
                                                    }
                                                    else {
                                                        resolveCoassSI('Prossima')
                                                    }
                                                })
                                            }
                                            funcListCoassicurazioniSI.push(eseguiCoassSI);
                                        })
                                        const eseguiCiclica = (ind) => {
                                            if (CustomJS.isNullEmpty(funcListCoassicurazioniSI[ind])) {
                                                resolveFineCoass();
                                            }
                                            else {
                                                funcListCoassicurazioniSI[ind]().then(ret => {
                                                    if (ret === 'Prossima') {
                                                        eseguiCiclica(ind + 1);
                                                    }
                                                }).catch(err => {
                                                    console.log(err)
                                                })
                                            }
                                        }
                                        eseguiCiclica(0);
                                    })
                                }
                                await fineCoassicurazioni();
                            }
                            else {
                                //Elimino tutte le coassicurazioni presenti se tolgo la coassicurazione del tutto
                                console.log('inizio coassicurazioni no')
                                const fineCoassNo = () => {
                                    return new Promise((resolveFineCoassNo) => {
                                        if (this.strutturaStatiCoassicurazioniRighe.length !== 0) {

                                            let funcListCoassNO = [];
                                            this.strutturaStatiCoassicurazioniRighe.forEach((elm3) => {
                                                const eseguiCoassNO = () => {
                                                    return new Promise((resolveCoassNO) => {
                                                        if (elm3.id === `rigaRiparto1-D${elm.d}-C${elm3.c}_${this.props.IdInternoSinistro}`) {
                                                            if (elm3.stato === 'd') {
                                                                //Variabili query
                                                                var queryCoassJftech = ''
                                                                var queryCoassGen = ''
                                                                //Variabili JsonObject
                                                                var jsonObjectCoassJftech = {};
                                                                var jsonObjectCoassGen = {};

                                                                queryCoassJftech = `DELETE FROM [11010]  WHERE id_compagnia_coass = ${elm3.keyJftech};`;
                                                                if (this.isCompagniaGenerali())
                                                                    queryCoassGen = `DELETE FROM [12014] WHERE id_coassi_indiretta = ${elm3.keyGen};`;

                                                                jsonObjectCoassJftech = {
                                                                }
                                                                if (this.isCompagniaGenerali()) {
                                                                    jsonObjectCoassGen = {
                                                                    }
                                                                }
                                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryCoassJftech, JSON.stringify(jsonObjectCoassJftech), retDataCoassJftech => {
                                                                    if (this.isCompagniaGenerali()) {
                                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryCoassGen, JSON.stringify(jsonObjectCoassGen), retDataCoassGen => {
                                                                            resolveCoassNO('Prossima');
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            });
                                                                        }, true, true);
                                                                    }
                                                                    else {
                                                                        resolveCoassNO('Prossima');
                                                                    }
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    }
                                                                    );
                                                                }, true, true);
                                                            }
                                                            else {
                                                                resolveCoassNO('Prossima');
                                                            }
                                                        } else {
                                                            resolveCoassNO('Prossima');
                                                        }
                                                    })
                                                }
                                                funcListCoassNO.push(eseguiCoassNO);
                                            })
                                            const eseguiCiclica = (ind) => {
                                                if (CustomJS.isNullEmpty(funcListCoassNO[ind])) {
                                                    resolveFineCoassNo();
                                                }
                                                else {
                                                    funcListCoassNO[ind]().then(ret => {
                                                        if (ret === 'Prossima') {
                                                            eseguiCiclica(ind + 1);
                                                        }
                                                    }).catch(err => {
                                                        console.log(err)
                                                    })
                                                }
                                            }
                                            eseguiCiclica(0);
                                        }
                                        else {
                                            //neanche una coassicurazione
                                            resolveFineCoassNo()
                                        }
                                    })
                                }
                                await fineCoassNo();
                            }
                            resolveStima('Prossima');
                        })
                    }
                    funcListStime.push(eseguiStima);
                })

                const eseguiCiclica = (ind) => {
                    if (CustomJS.isNullEmpty(funcListStime[ind])) {
                        resolveFineStime()
                    }
                    else {
                        funcListStime[ind]().then(ret => {
                            if (ret === 'Prossima') {
                                eseguiCiclica(ind + 1);
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
                eseguiCiclica(0);
            })
        }
        await fineStime();
        //-------------------------FINE QUERY STIME-------------------------

        //-----------------------INIZIO QUERY INFO RICHIESTE COMPAGNIA-----------------------
        if (this.isCompagniaGenerali()) {
            console.log('inizio info richieste');

            this.strutturaStatiInfoRichiesteDelete.forEach(elm => {
                var queryElimina = `BEGIN TRAN;DELETE FROM [11011] WHERE id_info_richieste_compagnia = ${elm.keyJftech};DELETE FROM [12015] WHERE id_info_richiesta = ${elm.keyGen};COMMIT;`;
                var jsonObjectElim = {
                }
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryElimina, JSON.stringify(jsonObjectElim), retDataEliminato => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
            })

            const fineInfoRichiesteStime = () => {
                return new Promise((resolveFineInfoRichieste) => {

                    let funcListStime = [];
                    this.strutturaStatiStimeBase.forEach(async (elm) => {
                        const eseguiStima = () => {
                            return new Promise(async (resolveStima) => {

                                var str2 = document.getElementById(`garanziaFromSin-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.servizio_str2;
                                var categorie = []
                                this.nuovoArrInfoRichRiep.forEach((elmS) => {
                                    if (elmS.servizio_str2 === str2)
                                        categorie.push({
                                            VALUE: elmS.cod_ele
                                        })
                                })
                                for (let n = 0; n < categorie.length; n++) {
                                    if (document.getElementById(`listbox-t${elm.d}-n${n}_${this.props.IdInternoSinistro}`)) {
                                        let numeroElementi = document.getElementById(`listbox-t${elm.d}-n${n}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                                        for (let index = 0; index < numeroElementi.length; index++) {
                                            let guidGenerata = CustomJS.createGuid4();
                                            let descrizioneElemento = '';
                                            document.getElementById(`listbox-t${elm.d}-n${n}_${this.props.IdInternoSinistro}`).ej2_instances[0].jsonData.forEach(descr => {
                                                if (descr.id === numeroElementi[index])
                                                    descrizioneElemento = descr.text;
                                            })
                                            const funcListbox = () => {
                                                return new Promise((resolveList) => {
                                                    var queryInfoRichieste = `INSERT INTO [11011] (id_stima,id_sinistro,id_categoria,id_elemento,guid_utile) VALUES (@idStima,${this.props.IdSinistro},@idCategoria,@idElemento,@guidUtile);`;
                                                    var jsonObjectInfoRichieste = {
                                                        IdStima: CustomJS.isNullEmpty(document.getElementById(`idStima-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`idStima-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                        IdCategoria: CustomJS.isNullEmpty(document.getElementById(`elmCategorie-t${elm.d}-n${n}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`elmCategorie-t${elm.d}-n${n}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                        IdElemento: numeroElementi[index],
                                                        GuidUtile: guidGenerata
                                                    }
                                                    console.log(jsonObjectInfoRichieste)
                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryInfoRichieste, JSON.stringify(jsonObjectInfoRichieste), retDataInfoRichieste => {
                                                        resolveList();
                                                    }, messaggio => {
                                                        toast.warn(messaggio, {
                                                            containerId: 'toastContainer1',
                                                            onClose: () => toast.dismiss(),
                                                            position: "bottom-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            newestOnTop: true,
                                                            rtl: false,
                                                            pauseOnFocusLoss: true
                                                        });
                                                    }, true, true);
                                                });
                                            }
                                            await funcListbox();
                                            var queryInfoRichieste2 = `INSERT INTO [12015] (id_info_rich_jftech,id_incarico,id_danno,codice,descrizione,risposta) VALUES ((SELECT id_info_richieste_compagnia FROM [11011]  WITH (UPDLOCK, HOLDLOCK) WHERE guid_utile = '${guidGenerata}' and id_sinistro = ${this.props.IdSinistro}),'${this.props.IdIncarico}',@idDanno,@codiceElemento,@categoriaDescrizione,@descrizioneElemento);`
                                            var jsonObjectInfoRichieste2 = {
                                                IdDanno: CustomJS.isNullEmpty(document.getElementById(`idStima-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.KEYGEN) ? 0 : document.getElementById(`idStima-D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.KEYGEN,
                                                CodiceElemento: CustomJS.isNullEmpty(document.getElementById(`elmCategorie-t${elm.d}-n${n}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`elmCategorie-t${elm.d}-n${n}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                                CategoriaDescrizione: CustomJS.isNullEmpty(document.getElementById(`elmCategorie-t${elm.d}-n${n}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`elmCategorie-t${elm.d}-n${n}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                                DescrizioneElemento: descrizioneElemento
                                            }
                                            console.log(jsonObjectInfoRichieste2)
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryInfoRichieste2, JSON.stringify(jsonObjectInfoRichieste2), retDataInfoRichieste2 => {
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                });
                                            }, true, true);
                                        }
                                    }
                                }
                                resolveStima('Prossima');
                            })
                        }
                        funcListStime.push(eseguiStima);
                    })
                    const eseguiCiclica = (ind) => {
                        if (CustomJS.isNullEmpty(funcListStime[ind])) {
                            resolveFineInfoRichieste();
                        }
                        else {
                            funcListStime[ind]().then(ret => {
                                if (ret === 'Prossima') {
                                    eseguiCiclica(ind + 1);
                                }
                            }).catch(err => {
                                console.log(err)
                            })
                        }
                    }
                    eseguiCiclica(0);
                })
            }
            await fineInfoRichiesteStime();

        }
        //-----------------------FINE QUERY INFO RICHIESTE COMPAGNIA-----------------------

        console.log('inizio altre operazioni')

        //-----------------------UPDATE TOTALI NELLA PERIZIA INCARICO INIZIO-----------------------
        var queryUpdateTotali = `UPDATE [11001] set tot_indennizzo_lordo_compl = @totIndennizzoLordoComplessivo, tot_indennizzo_netto_compl = @totIndennizzoNettoComplessivo, tot_indennizzo_netto_compl_arrot = @totIndennizzoNettoComplArrotondato, tot_danni_accertati = @totDanniAccertati where id_sinistro = ${this.props.IdSinistro};`;
        var totIndennizzoNettoComplArrotondato = CustomJS.isNullEmpty(document.getElementById(`totaleIndennNettoComplApprossRiepMAN_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`totaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`totaleIndennNettoComplApprossRiepMAN_${this.props.IdInternoSinistro}`).ej2_instances[0].value
        var jsonObjectUpdateTotali = {
            TotIndennizzoLordoComplessivo: CustomJS.isNullEmpty(document.getElementById(`totaleIndennizziLordi_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleIndennizziLordi_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            TotIndennizzoNettoComplessivo: CustomJS.isNullEmpty(document.getElementById(`totaleIndennizziNetti_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleIndennizziNetti_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            TotIndennizzoNettoComplArrotondato: CustomJS.isNullEmpty(totIndennizzoNettoComplArrotondato) ? 0 : totIndennizzoNettoComplArrotondato,
            TotDanniAccertati: CustomJS.isNullEmpty(document.getElementById(`totaleDanniComplessiviAccertati_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleDanniComplessiviAccertati_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
        }
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryUpdateTotali, JSON.stringify(jsonObjectUpdateTotali), retDataUpdateTotali => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
        //-----------------------UPDATE TOTALI NELLA PERIZIA INCARICO FINE-----------------------

        //-----------------------UPDATE GENERALI DANNEGGIATI IMPORTO NETTO E LORDO + UPDATE GENERALI DANNEGGIATI LIQUIDAZIONI-----------------------
        if (this.isCompagniaGenerali()) {
            let importoNetto = 0;
            let importoLordo = 0;
            for (let d = 0; d < this.numTabelleRiepilogo; d++) {
                if (document.getElementById(`containerTabRiepilogo-D${d}_${this.props.IdInternoSinistro}`)) {
                    //il lordo e il netto sono scambiati
                    importoLordo = CustomJS.isNullEmpty(document.getElementById(`totaleIndennizzoLordo-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleIndennizzoLordo-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    let suppInde = CustomJS.isNullEmpty(document.getElementById(`totaleSuppIndenn-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleSuppIndenn-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    importoLordo = parseFloat((importoLordo + suppInde).toFixed(2));
                    let totFranchige = CustomJS.isNullEmpty(document.getElementById(`totaleFranchige-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleFranchige-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    importoNetto = parseFloat((importoLordo - totFranchige).toFixed(2));
                    importoNetto = importoNetto < 0 ? 0 : importoNetto;
                    let queryDaEseguire5 = `UPDATE [12004] SET importo_netto = @importoNetto, importo_lordo = @importoLordo WHERE id_danneggiato = '${document.getElementById(`danneggiatoRiep-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.GUID}';`;
                    let jsonObjectGenDann = {
                        ImportoNetto: importoNetto.toString(),
                        ImportoLordo: importoLordo.toString()
                    }
                    console.log(jsonObjectGenDann)
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryDaEseguire5, JSON.stringify(jsonObjectGenDann), retDataGenDann => {
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }, true, true);
                    let importoLiq = CustomJS.isNullEmpty(document.getElementById(`totaleIndennNettoComplApprossRiepMAN_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? CustomJS.isNullEmpty(document.getElementById(`totaleIndennizzo-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : this.arrotondaIndennizziNettiComplessivi(Math.round(document.getElementById(`totaleIndennizzo-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value)) : document.getElementById(`totaleIndennNettoComplApprossRiepMAN_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                    let queryDaEseguireLiq = `BEGIN TRAN;UPDATE [12012] SET arrotondamento = @arrotondamento, importo_liquidazione = @importoLiquidazione WHERE id_danneggiato = '${document.getElementById(`danneggiatoRiep-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.GUID}'; IF @@ROWCOUNT = 0 BEGIN INSERT INTO [12012] (id_danneggiato,arrotondamento,importo_liquidazione) values ('${document.getElementById(`danneggiatoRiep-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].itemData.GUID}',@arrotondamento, @importoLiquidazione);END;COMMIT;`;
                    let jsonObjectLiqGen = {
                        Arrotondamento: '0', //todo chiedi a giovanni
                        ImportoLiquidazione: importoLiq
                    }
                    console.log(jsonObjectLiqGen)
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryDaEseguireLiq, JSON.stringify(jsonObjectLiqGen), retDataLiqGen => {
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }, true, true);
                }
            }
        }
        if (this.isCompagniaUnipol()) {
            //Cerco coassicurazione
            let almenoUnaCoass = false;
            for (let d = 1; d < this.numStime; d++) {
                if (document.getElementById(`tabellaStima-D${d}_${this.props.IdInternoSinistro}`)) {
                    if (document.getElementById(`coassicurazioneSi-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].checked) {
                        almenoUnaCoass = true;
                        break;
                    }
                }
            }
            //TOTALI RIEPILOGO
            let queryTotRiepilogo = `UPDATE [10003] set importoPeriziato = @totDanniAccertati, importoConcordato = @totIndennizzoNettoComplArrotondato, importoLiquidabile = @totIndennizzoNettoComplArrotondato2, importoFranchigia = @totFranchigieScoperti, presenzaAltreAssicurazioniStessoRischio = @coassicurazIndirPresente where id_sinistro = ${this.props.IdSinistro};`;
            let totIndennizzoNettoComplArrotondato = CustomJS.isNullEmpty(document.getElementById(`totaleIndennNettoComplApprossRiepMAN_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? document.getElementById(`totaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`).ej2_instances[0].value : document.getElementById(`totaleIndennNettoComplApprossRiepMAN_${this.props.IdInternoSinistro}`).ej2_instances[0].value
            let jsonObjectRiepilogo = {
                TotDanniAccertati: CustomJS.isNullEmpty(document.getElementById(`totaleDanniComplessiviAccertati_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleDanniComplessiviAccertati_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                TotIndennizzoNettoComplArrotondato: CustomJS.isNullEmpty(totIndennizzoNettoComplArrotondato) ? '0' : totIndennizzoNettoComplArrotondato,
                TotIndennizzoNettoComplArrotondato2: CustomJS.isNullEmpty(totIndennizzoNettoComplArrotondato) ? '0' : totIndennizzoNettoComplArrotondato,
                TotFranchigieScoperti: CustomJS.isNullEmpty(document.getElementById(`totaleFranchigeScoperti_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleFranchigeScoperti_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                CoassicurazIndirPresente: almenoUnaCoass === true ? 1 : 0
            }
            let queryCoassUnipol = `UPDATE [10004] SET noteCoassicurazioniIndirette = @noteCoassicurazione where id_sinistro = ${this.props.IdSinistro};`;
            let jsonObjectCoassUnipol = {
                NoteCoassicurazione: CustomJS.isNullEmpty(document.getElementById(`noteCoassicurazioneUnipol_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteCoassicurazioneUnipol_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            }
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryTotRiepilogo, JSON.stringify(jsonObjectRiepilogo), retDataRiep => {
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryCoassUnipol, JSON.stringify(jsonObjectCoassUnipol), retDataCoass => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            }, true, true);
        }

        //------------------------ELIMINA PRECEDENTI RIEPILOGO INIZIO--------------------------------
        this.strutturaRiepilogoDelete.IdDanneggiati.forEach(elm => {
            let queryEliminaDann = `DELETE FROM [11013] WHERE idDanneggiatoRiep = ${elm};`;
            let jsonObjectEliminDann = {
            }
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaDann, JSON.stringify(jsonObjectEliminDann), retDataEliminato1 => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        });
        this.strutturaRiepilogoDelete.IdRighe.forEach(elm => {
            let queryEliminaRiga = `DELETE FROM [11014] WHERE idGarPart = ${elm};`;
            let jsonObjectEliminRiga = {
            }
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaRiga, JSON.stringify(jsonObjectEliminRiga), retDataEliminato2 => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        });
        //------------------------ELIMINA PRECEDENTI RIEPILOGO FINE----------------------------------

        //----------------------SALVA DATI RIEPILOGO PER DOC PERIZIA INIZIO----------
        let queryRiep = ""
        if (!CustomJS.isNullEmpty(this.strutturaRiepilogoDelete.IdRiepilogo)) {
            queryRiep = `UPDATE [11012] SET finali_indennizzi_lordi = @totIndennizzoLordoComplessivo, finali_franchigie = @finaliFranchigie, finali_suppl_indenn = @finaliSupplIndenn,finali_indenn_netti = @totIndennizzoNettoComplessivo, finali_danni_compl_acc = @totDanniAccertati, finali_indenn_dopo_scop = @finaliIndennDopoScop, finali_indenn_netti_arrotond = @totIndennizzoNettoComplArrotondato, finali_indenn_netti_arrotond_MAN = @totIndennizzoNettoComplArrotondato2 where idRiepilogo_perizia = ${this.strutturaRiepilogoDelete.IdRiepilogo};`;
        }
        else {
            queryRiep = `INSERT INTO [11012] (id_sinistro,finali_indennizzi_lordi,finali_franchigie,finali_suppl_indenn,finali_indenn_netti,finali_danni_compl_acc,finali_indenn_dopo_scop,finali_indenn_netti_arrotond,finali_indenn_netti_arrotond_MAN) values (${this.props.IdSinistro},@totIndennizzoLordoComplessivo,@finaliFranchigie,@finaliSupplIndenn,@totIndennizzoNettoComplessivo,@totDanniAccertati,@finaliIndennDopoScop,@totIndennizzoNettoComplArrotondato,@totIndennizzoNettoComplArrotondato2);`;
        }
        let jsonObjectRiep = {
            TotIndennizzoLordoComplessivo: CustomJS.isNullEmpty(document.getElementById(`totaleIndennizziLordi_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleIndennizziLordi_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            FinaliFranchigie: CustomJS.isNullEmpty(document.getElementById(`totaleFranchigeScoperti_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleFranchigeScoperti_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            FinaliSupplIndenn: CustomJS.isNullEmpty(document.getElementById(`totaleSupplIndennita_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleSupplIndennita_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            TotIndennizzoNettoComplessivo: CustomJS.isNullEmpty(document.getElementById(`totaleIndennizziNetti_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleIndennizziNetti_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            TotDanniAccertati: CustomJS.isNullEmpty(document.getElementById(`totaleDanniComplessiviAccertati_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleDanniComplessiviAccertati_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            FinaliIndennDopoScop: CustomJS.isNullEmpty(document.getElementById(`indennizzoDopoEventualeScop_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`indennizzoDopoEventualeScop_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            TotIndennizzoNettoComplArrotondato: CustomJS.isNullEmpty(document.getElementById(`totaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleIndennNettoComplApprossRiep_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            //TotIndennizzoNettoComplArrotondato2 = valore manuale
            TotIndennizzoNettoComplArrotondato2: CustomJS.isNullEmpty(document.getElementById(`totaleIndennNettoComplApprossRiepMAN_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? 0 : document.getElementById(`totaleIndennNettoComplApprossRiepMAN_${this.props.IdInternoSinistro}`).ej2_instances[0].value
        }
        if (jsonObjectRiep.TotIndennizzoNettoComplArrotondato2 !== 0) {
            jsonObjectRiep.TotIndennizzoNettoComplArrotondato = jsonObjectRiep.TotIndennizzoNettoComplArrotondato2
        }
        console.log(jsonObjectRiep)
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryRiep, JSON.stringify(jsonObjectRiep), async (retDataRiep) => {
            for (let d = 0; d < this.numTabelleRiepilogo; d++) {
                if (document.getElementById(`containerTabRiepilogo-D${d}_${this.props.IdInternoSinistro}`)) {
                    let guidUtileDann = CustomJS.createGuid4();
                    //DANNEGGIATO
                    const funcDanneggiato = () => {
                        return new Promise((resolveDann) => {
                            let queryDanneggiato = `INSERT INTO [11013] (id_sinistro,danneggiato,id_danneggiato,tot_indenn_lordo,tot_franchigia,tot_supp_indenn,tot_indenn_netto,guid_utile_dann) VALUES (${this.props.IdSinistro},@danneggiato,@idDanneggiato,@totIndennizzoLordoDann,@totFranchigiaDann,@totSupplIndennDann,@totIndennNettoDann,'${guidUtileDann}');`;
                            let jsonObjectDann = {
                                Danneggiato: CustomJS.isNullEmpty(document.getElementById(`danneggiatoRiep-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : document.getElementById(`danneggiatoRiep-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].text,
                                IdDanneggiato: CustomJS.isNullEmpty(document.getElementById(`danneggiatoRiep-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`danneggiatoRiep-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                TotIndennizzoLordoDann: CustomJS.isNullEmpty(document.getElementById(`totaleIndennizzoLordo-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`totaleIndennizzoLordo-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                TotFranchigiaDann: CustomJS.isNullEmpty(document.getElementById(`totaleFranchige-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`totaleFranchige-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                TotSupplIndennDann: CustomJS.isNullEmpty(document.getElementById(`totaleSuppIndenn-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`totaleSuppIndenn-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
                                TotIndennNettoDann: CustomJS.isNullEmpty(document.getElementById(`totaleIndennizzo-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : document.getElementById(`totaleIndennizzo-D${d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value
                            }
                            console.log(jsonObjectDann)
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryDanneggiato, JSON.stringify(jsonObjectDann), retDataDann => {
                                resolveDann();
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            }, true, true);
                        })
                    }
                    await funcDanneggiato();

                    for (let g = 0; g < this.numRigheRiepilogoGaranzie; g++) {
                        if (document.getElementById(`garanziaRiepIntestazione-D${d}-R-${g}_${this.props.IdInternoSinistro}`)) {
                            const funcRigaDanneggiato = () => {
                                return new Promise((resolveRiga) => {
                                    let queryGarPart = `BEGIN TRAN;INSERT INTO [11014] (id_riep_danneggiato,id_sinistro,garanzia,partita,indenn_lordo_uso,franchigia,suppl_indenn,indenn_netto) values ((SELECT idDanneggiatoRiep FROM [11013] WITH (UPDLOCK, HOLDLOCK) WHERE guid_utile_dann = '${guidUtileDann}'),${this.props.IdSinistro},@garanziaDescr,@partitaDescr,@indennLordo,@franchigia,@supplIndennImporto,@indennNetto);COMMIT;`;
                                    let jsonObjectGarPart = {
                                        GaranziaDescr: CustomJS.isNullEmpty(document.getElementById(`garanziadannRiep-D${d}-R-${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : (document.getElementById(`garanziadannRiep-D${d}-R-${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].text),
                                        PartitaDescr: CustomJS.isNullEmpty(document.getElementById(`partitadannRiep-D${d}-R-${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].text) ? '' : (document.getElementById(`partitadannRiep-D${d}-R-${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].text),
                                        IndennLordo: CustomJS.isNullEmpty(document.getElementById(`indennizzoLordoRiep-D${d}-${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : (document.getElementById(`indennizzoLordoRiep-D${d}-${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value),
                                        Franchigia: CustomJS.isNullEmpty(document.getElementById(`franchigiaRiep-D${d}-${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : (document.getElementById(`franchigiaRiep-D${d}-${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value),
                                        SupplIndennImporto: CustomJS.isNullEmpty(document.getElementById(`supplIndennRiepilogo-D${d}-${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : (document.getElementById(`supplIndennRiepilogo-D${d}-${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value),
                                        IndennNetto: CustomJS.isNullEmpty(document.getElementById(`indennizzoNettoRiep-D${d}-${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '0' : (document.getElementById(`indennizzoNettoRiep-D${d}-${g}_${this.props.IdInternoSinistro}`).ej2_instances[0].value),
                                    }
                                    console.log(jsonObjectGarPart)
                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryGarPart, JSON.stringify(jsonObjectGarPart), retDataGP => {
                                        resolveRiga()
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });
                                    }, true, true);
                                })
                            }
                            await funcRigaDanneggiato();
                        }
                    }
                }
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        }, true, true);
        //---------------------------SALVO DEFINIZIONE INIZIO-------------------------
        var definizione = `UPDATE [11001] set D_note = @definizioneNote where id_sinistro = ${this.props.IdSinistro}`;
        var jsonObjectDefinizione = {
            DefinizioneNote: CustomJS.isNullEmpty(document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
        }
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', definizione, JSON.stringify(jsonObjectDefinizione), retDataDef => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);

        if (this.isCompagniaGenerali()) {
            var definizioneGen = `UPDATE [12003] set note_osservazioni_finali = @definizioneNote where id_incarico = '${this.props.IdIncarico}';`;
            var jsonObjectDefinizioneGen = {
                DefinizioneNote: CustomJS.isNullEmpty(document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            }
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', definizioneGen, JSON.stringify(jsonObjectDefinizioneGen), retDataDef => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        }

        if (this.isCompagniaUnipol()) {
            var definizioneUni = `UPDATE [10004] set noteConclusive = @definizioneNote where id_sinistro = ${this.props.IdSinistro}`;
            var jsonObjectDefinizioneUni = {
                DefinizioneNote: CustomJS.isNullEmpty(document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value) ? '' : document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value,
            }
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', definizioneUni, JSON.stringify(jsonObjectDefinizioneUni), retDataDef => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        }
        //---------------------------SALVO DEFINIZIONE FINE-------------------------
        //---------------SALVA DATI RIEPILOGO PER DOC PERIZIA FINE----------

        // VAI ALLA PAGINA PERIZIA UNO
        setTimeout(() => {
            console.log("tempo totale : " + tempoTotale)
            this.cambiaPaginaFooter('Dettaglio', true);
        }, tempoTotale)
    }
    //------------------------------------FUNZIONI CREAZIONE CARICA SALVA INIZIO------------------------------------

    requiredCampiTabelle() {
        //PREESISTENZE
        this.strutturaStatiPreesistenzeBase.forEach(elm => {
            this.formOptions.rules[`sommaAssicurataInput_T${elm.t}_${this.props.IdInternoSinistro}`] = {
                required: [true, "Somma Assicurata è un campo obbligatorio!"]
            }
            this.formOptions.rules[`derogaInput_T${elm.t}_${this.props.IdInternoSinistro}`] = {
                required: [true, "Deroga è un campo obbligatorio!"]
            }
            this.formOptions.rules[`valoreDegradoInput_T${elm.t}_${this.props.IdInternoSinistro}`] = {
                required: [true, "Valore Derogato è un campo obbligatorio!"]
            }
            if (document.getElementById(`nomePartita${elm.t}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === 'TP016') {
                this.strutturaStatiPreesistenzeCostruzioni.forEach(elm2 => {
                    if (elm2.stato !== 'd') {
                        if (document.getElementById(`rigaPreesistenzaCostruzione-T${elm.t}-R-${elm2.r}_${this.props.IdInternoSinistro}`)) {
                            this.formOptions.rules[`valoreUnitarioInput_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`] = {
                                required: [true, "Valore Unitario è un campo obbligatorio!"]
                            }
                            this.formOptions.rules[`superficieEquivalenteInput_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`] = {
                                required: [true, "Superficie è un campo obbligatorio!"]
                            }
                            this.formOptions.rules[`altezzaInput_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`] = {
                                required: [true, "Altezza è un campo obbligatorio!"]
                            }
                        }
                    }
                    else if (elm2.stato === 'd') {
                        if (this.formOptions.rules[`volumeResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`])
                            delete this.formOptions.rules[`volumeResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`];
                        if (this.formOptions.rules[`valoreRicostruzioneResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`])
                            delete this.formOptions.rules[`valoreRicostruzioneResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`];
                    }
                })
            }
            this.strutturaStatiPreesistenzeDescrizioni.forEach(elm2 => {
                if (elm2.stato !== 'd') {
                    if (document.getElementById(`rigaPreesDescrizione-T${elm.t}-R-${elm2.r}_${this.props.IdInternoSinistro}`)) {
                        this.formOptions.rules[`descrizioneCorpo_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`] = {
                            required: [true, "Descrizione è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`valoreAltroACorpoResult_T${elm.t}_R_${elm2.r}_${this.props.IdInternoSinistro}`] = {
                            required: [true, "Valore è un campo obbligatorio!"]
                        }
                    }
                }
            })
        })
        //STIME
        this.strutturaStatiStimeBase.forEach(elm => {
            this.formOptions.rules[`piano-D${elm.d}_${this.props.IdInternoSinistro}`] = {
                required: [true, "Piano è un campo obbligatorio!"]
            }
            if (this.isCompagniaGenerali()) {
                this.formOptions.rules[`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`] = {
                    required: [true, "Esito Danno è un campo obbligatorio!"]
                }
                //non indennizzabile
                if (document.getElementById(`esitoDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '4') {
                    this.formOptions.rules[`causaliNonIndennizzo_D${elm.d}_${this.props.IdInternoSinistro}`] = {
                        required: [true, `Causali Non indennizzo è un campo obbligatorio se l' esito è "Non Indennizabile"!`]
                    }
                }
            }
            //PREZZIARI
            this.strutturaStatiStimePrezziari.forEach(elm2 => {
                if (elm2.stato !== 'd') {
                    if (document.getElementById(`tabPrezziario-D${elm.d}-R-${elm2.r}_${this.props.IdInternoSinistro}`)) {
                        this.formOptions.rules[`descrizioneSceltaPrezziario_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`] = {
                            required: [true, "Descrizione Prezziario è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`UMInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`] = {
                            required: [true, "Unità Misura è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`quantitaInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`] = {
                            required: [true, "Quantità è un campo obbligatorio!"]
                        }
                        this.formOptions.rules[`valUnitarioInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`] = {
                            required: [true, "Valore Unitario è un campo obbligatorio!"]
                        }
                        if (this.isCompagniaGenerali()) {
                            this.formOptions.rules[`categoriaSceltaPrezziario_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`] = {
                                required: [true, "Categoria Prezziario è un campo obbligatorio!"]
                            }
                            this.formOptions.rules[`localizzazione_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`] = {
                                required: [true, "Localizzazione è un campo obbligatorio!"]
                            }
                        }
                        else {
                            this.formOptions.rules[`beneDanneggiato_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`] = {
                                required: [true, "Bene Danneggiato è un campo obbligatorio!"]
                            }
                        }
                        if (document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                            || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'
                            || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                            || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                            || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                            this.formOptions.rules[`percDegrSupInd_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`] = {
                                required: [true, "Percentuale Degrado è un campo obbligatorio!"]
                            }
                        }
                    }
                }
                else if (elm2.stato === 'd') {
                    if (document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '001') {
                        if (this.formOptions.rules[`totaleResult_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`])
                            delete this.formOptions.rules[`totaleResult_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`];
                    }
                    else if (document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '002'
                             || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '003'                            
                             || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '004'
                             || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '005'
                             || document.getElementById(`determinazioneDanno_D${elm.d}_${this.props.IdInternoSinistro}`).ej2_instances[0].value === '006') {
                        if (this.formOptions.rules[`valANuovoInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`])
                            delete this.formOptions.rules[`valANuovoInput_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`];
                        if (this.formOptions.rules[`importoDegrSupInd_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`])
                            delete this.formOptions.rules[`importoDegrSupInd_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`];
                        if (this.formOptions.rules[`valoreBeneResult_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`])
                            delete this.formOptions.rules[`valoreBeneResult_D${elm.d}_R${elm2.r}_${this.props.IdInternoSinistro}`];
                    }
                }
            })
            //COASSICURAZIONI
            this.strutturaStatiCoassicurazioniRighe.forEach(elm2 => {
                if (this.formOptions.rules[`dannoARipartoRiparto1-D${elm.d}-C${elm2.c}_${this.props.IdInternoSinistro}`])
                    delete this.formOptions.rules[`dannoARipartoRiparto1-D${elm.d}-C${elm2.c}_${this.props.IdInternoSinistro}`];
                if (this.formOptions.rules[`limitazioneContrattualeRiparto1-D${elm.d}-C${elm2.c}_${this.props.IdInternoSinistro}`])
                    delete this.formOptions.rules[`limitazioneContrattualeRiparto1-D${elm.d}-C${elm2.c}_${this.props.IdInternoSinistro}`];
                if (this.formOptions.rules[`dannoNettoCoassDirRiparto1-D${elm.d}-C${elm2.c}_${this.props.IdInternoSinistro}`])
                    delete this.formOptions.rules[`dannoNettoCoassDirRiparto1-D${elm.d}-C${elm2.c}_${this.props.IdInternoSinistro}`];
                if (this.formOptions.rules[`dannNettoRip2-D${elm.d}-C${elm2.c}_${this.props.IdInternoSinistro}`])
                    delete this.formOptions.rules[`dannNettoRip2-D${elm.d}-C${elm2.c}_${this.props.IdInternoSinistro}`];
            })
        });

        this.formObject = new FormValidator(`#formElaboratoPeritaleCalcoli_${this.props.IdInternoSinistro}`, this.formOptions);
        console.log(this.formObject)

        if (this.formObject.validate() && !CustomJS.isNullEmpty( document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value?.trim())) {
            document.getElementById(`salvaEsciP2_${this.props.IdInternoSinistro}`).ej2_instances[0].disabled = true;
            this.salvaDati();
        }
        else {
            var messaggioCampiMancanti = '';
            this.formObject.errorRules.forEach(elm => {
                messaggioCampiMancanti = messaggioCampiMancanti + elm.message + '\n';
            });
            if (CustomJS.isNullEmpty(document.getElementById(`noteDefinizione_${this.props.IdInternoSinistro}`).ej2_instances[0].value?.trim()))
                messaggioCampiMancanti += 'Note Definizione è un campo obbligatorio';
            toast.warn(messaggioCampiMancanti, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }
    }
    inserisciValorePrezziarioSuDropDown(numDann, numRiga, args) {
        document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value = args.rowData.cod_ele?.trim();
        this.dialogInstance0.hide();
    }
    apriDialogPrezziario(numDann, numRiga) {
        this.dialogInstance0.width = '50%';
        this.dialogInstance0.height = '80%';
        this.dialogInstance0.minHeight = '80%';
        this.dialogInstance0.buttons = [];
        this.dialogInstance0.beforeOpen = (args) => {
            const container = document.getElementById(`dialogPrezziarioContent_${this.props.IdInternoSinistro}`);
            const root = createRoot(container);
            root.unmount();
        };
        this.dialogInstance0.open = (args) => {
            const container = document.getElementById(`dialogPrezziarioContent_${this.props.IdInternoSinistro}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
                document.getElementById(`gridPrezziario_${this.props.IdInternoSinistro}`).ej2_instances[0].pageSettings.pageSize = "10";
                //se c'è la descrizione prezziario quando apri la dialog è già selezionato in blu
                var codElePrezziario = document.getElementById(`descrizioneSceltaPrezziario_D${numDann}_R${numRiga}_${this.props.IdInternoSinistro}`).ej2_instances[0].value;
                if (!CustomJS.isNullEmpty(codElePrezziario)) {
                    document.getElementById(`gridPrezziario_${this.props.IdInternoSinistro}`).ej2_instances[0].pageSettings.pageSize = "Tutti";
                    setTimeout(() => {
                        let idx = _.findIndex(document.getElementById(`gridPrezziario_${this.props.IdInternoSinistro}`).ej2_instances[0].dataSource, elm => {
                            if (elm.cod_ele === codElePrezziario)
                                return elm
                        })
                        if (!CustomJS.isNullEmpty(idx)) {
                            document.getElementById(`gridPrezziario_${this.props.IdInternoSinistro}`).ej2_instances[0].selectedRowIndex = idx;
                        }
                    }, 300)
                }
            }}>{this.contenutoGridPrezziario(numDann, numRiga)}</CallbackWrapper>);
        };
        this.dialogInstance0.show();
    }

    contenutoGridPrezziario(numDann, numRiga) {
        return <React.Fragment>
            <h6 style={{ fontSize: '13.5px', textAlign: 'center' }}>Prezziario</h6>
            <GridComponent id={`gridPrezziario_${this.props.IdInternoSinistro}`} dataSource={this.dsPrezziarioCompleto} recordDoubleClick={this.inserisciValorePrezziarioSuDropDown.bind(this, numDann, numRiga)} ref={grid => this.gridInstancePrezziario = grid}
                toolbar={this.toolbarOptions} allowSelection={true} enableHover={true} allowTextWrap={true} allowPaging={true} allowSorting={true} enablePersistence={false} pageSettings={this.pageSettings}
                style={{ border: '0px' }}>
                <ColumnsDirective>
                    <ColumnDirective field='DESCR_LUNGA' width="80%" headerText="Descrizione" textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='cod_ele' visible={false} headerText="Codice Elemento" textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='descriz_CORTA' visible={false} headerText="Descrizione Corta" textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='COD_CATEGORIA' visible={false} headerText="Categoria" textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='MISURA' headerText="M" textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                    <ColumnDirective field='PREZZO' headerText="€" textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Toolbar, Edit, ExcelExport, Filter, DetailRow, CommandColumn, Sort, Group, Page]} />
            </GridComponent>
        </React.Fragment>
    }
    distruggiDialog(soloProprie) {
        if (!soloProprie) {
            if (document.getElementById(`dialogElaboratoPeritale_${this.props.IdInternoSinistro}`))
                document.getElementById(`dialogElaboratoPeritale_${this.props.IdInternoSinistro}`).ej2_instances[0]?.destroy();
        }
        if (document.getElementById(`dialogPrezziario_${this.props.IdInternoSinistro}`))
            document.getElementById(`dialogPrezziario_${this.props.IdInternoSinistro}`).ej2_instances[0]?.destroy();
        if (document.getElementById(`dialogEliminaCalcoli_${this.props.IdInternoSinistro}`))
            document.getElementById(`dialogEliminaCalcoli_${this.props.IdInternoSinistro}`).ej2_instances[0]?.destroy();
    }
    //CONTROLLI PER COMPAGNIA VALIDI SOLO PER I SINISTRI IMPORTATI!
    isCompagniaGenerali() {
        return this.props.IdCompagnia.toString() === CustomJS.codiceCompagniaGenerali && !CustomJS.isNullEmpty(this.props.IdIncarico)
    }
    isCompagniaUnipol() {
        return this.props.IdCompagnia.toString() === CustomJS.codiceCompagniaUnipolSAI && !CustomJS.isNullEmpty(this.props.IdIncaricoUnipol)
    }
    dialogEliminaCalcoli() {
        this.dialogInstanceElimina.height = '25%';
        this.dialogInstanceElimina.minHeight = '25%';
        this.dialogInstanceElimina.width = '40%';
        this.dialogInstanceElimina.beforeOpen = (args) => {
            const container = document.getElementById(`containerDialogEliminaCalcoli_${this.props.IdInternoSinistro}`);
            const root = createRoot(container);
            root.unmount();
        }
        this.dialogInstanceElimina.open = (args) => {
            const container = document.getElementById(`containerDialogEliminaCalcoli_${this.props.IdInternoSinistro}`);
            const root = createRoot(container);
            root.render(<CallbackWrapper callback={() => {
            }}>{<>
                <div style={{ textAlign: 'center' }}>
                    <h4 style={{ color: 'red' }}>ATTENZIONE!</h4>
                    <h6>Sei sicuro di voler eliminare i dati di questa pagina ?</h6>
                </div>
            </>}</CallbackWrapper>);
        }
        this.dialogInstanceElimina.buttons = [
            {
                click: () => {
                    this.dialogInstanceElimina.hide();
                    this.eliminaCalcoli();

                },
                buttonModel: {
                    content: 'ELIMINA',
                    cssClass: 'e-primary',
                    isPrimary: true,
                }
            },
            {
                click: () => {
                    this.dialogInstanceElimina.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.dialogInstanceElimina.show();
    }

    eliminaCalcoli() {
        this.toggleBlocking2(true);

        //--------------------------CARICO DATI----------------------------------------
        const QueryExecIdPreesistenza = `SELECT id_preesistenza FROM [11006] where id_sinistro = ${this.props.IdSinistro}`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdPreesistenza, '', retDataIdPrees => {
            for (let index = 0; index < retDataIdPrees.length; index++) {
                this.strutturaStatiEliminaDati.idPreesistenzaJftech.push(retDataIdPrees[index].id_preesistenza)
            }
            //ID PREESISTENZA COSTRUZIONI
            const QueryExecIdPreesCostr = `SELECT id_preesistenza_costruzione FROM [11007] where id_sinistro = ${this.props.IdSinistro}`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdPreesCostr, '', retDataIdPreesCostr => {
                for (let index = 0; index < retDataIdPreesCostr.length; index++) {
                    this.strutturaStatiEliminaDati.idPreesistenzaCostruz.push(retDataIdPreesCostr[index].id_preesistenza_costruzione)
                }
                //ID PREESISTENZA descrizIONI
                const QueryExecIdPreesDescr = `SELECT id_preesistenza_descrizione FROM [11008] where id_sinistro = ${this.props.IdSinistro}`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdPreesDescr, '', retDataIdPreesDescr => {
                    for (let index = 0; index < retDataIdPreesDescr.length; index++) {
                        this.strutturaStatiEliminaDati.idPreesistenzaDescr.push(retDataIdPreesDescr[index].id_preesistenza_descrizione)
                    }
                    //ID PREESISTENZA GENERALI
                    const QueryExecIdPreesGen = `SELECT id_preesistenza FROM [12007] where id_incarico = '${this.props.IdIncarico}'`;
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdPreesGen, '', retDataIdPreesGen => {
                        for (let index = 0; index < retDataIdPreesGen.length; index++) {
                            this.strutturaStatiEliminaDati.idPreesistenzaGen.push(retDataIdPreesGen[index].id_preesistenza)
                        }
                        const QueryExecTabStima = ``;   //todo query stima  //potrebbe essere questa (implementare se necessario) : SELECT T1.id_stima,T2.id_danno FROM [11004] T1 left outer join [12011] T2 on T1.id_stima = T2.id_stima_jftech where T1.id_sinistro = ${this.props.IdSinistro}
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecTabStima, '', retDataStime => {
                            for (let index = 0; index < retDataStime.length; index++) {
                                this.strutturaStatiEliminaDati.idStime.push({
                                    keyJftech: retDataStime[index].id_stima,
                                    keyGen: retDataStime[index].id_danno
                                })
                            }
                            //ID STIME descrizIONI
                            const QueryExecIdStimeDescr = `SELECT T1.id_stima_descrizione,T2.id_dettaglio FROM [11009] T1 left outer join [12013] T2 on T1.id_stima_descrizione = T2.id_stima_descrizione where id_sinistro = ${this.props.IdSinistro}`;
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdStimeDescr, '', retDataIdStimeDescr => {
                                for (let index = 0; index < retDataIdStimeDescr.length; index++) {
                                    this.strutturaStatiEliminaDati.idStimeDescrizioni.push({
                                        keyJftech: retDataIdStimeDescr[index].id_stima_descrizione,
                                        keyGen: retDataIdStimeDescr[index].id_dettaglio,
                                    })
                                }
                                //ID GENERALI DANNO DETTAGLI INFO RICHIESTE
                                const QueryExecIdDannoDettInfoRichGen = `SELECT id_info_richiesta,id_dettaglio FROM [12016] where id_incarico = '${this.props.IdIncarico}';`;
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdDannoDettInfoRichGen, '', retDataIdDannoDettInfoRichGen => {
                                    for (let index = 0; index < retDataIdDannoDettInfoRichGen.length; index++) {
                                        this.strutturaStatiEliminaDati.idDannoDettInfoRichGen.push(
                                            {
                                                idInfo: retDataIdDannoDettInfoRichGen[index].id_info_richiesta,
                                                idDettaglio: retDataIdDannoDettInfoRichGen[index].id_dettaglio
                                            }
                                        )
                                    }
                                    //ID COASSICURAZIONI
                                    const QueryExecCoass = `SELECT T1.id_compagnia_coass,T2.id_coassi_indiretta FROM [11010] T1 left outer join [12014] T2 on T1.id_compagnia_coass = T2.id_compagnia_coass_jftech WHERE id_sinistro = ${this.props.IdSinistro};`;
                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecCoass, '', retDataCoass => {
                                        for (let index = 0; index < retDataCoass.length; index++) {
                                            this.strutturaStatiEliminaDati.idCoassicurazioni.push({
                                                keyJftech: retDataCoass[index].id_compagnia_coass,
                                                keyGen: retDataCoass[index].id_coassi_indiretta
                                            })
                                        }
                                        //ID INFO RICHIESTE
                                        const QueryExecInfoRichieste = `SELECT T1.id_info_richieste_compagnia, T2.id_info_richiesta FROM [11011] T1 left outer join [12015] T2 on T1.id_info_richieste_compagnia = T2.id_info_rich_jftech where id_sinistro = ${this.props.IdSinistro};`;
                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecInfoRichieste, '', retDataInfo => {
                                            for (let index = 0; index < retDataInfo.length; index++) {
                                                this.strutturaStatiEliminaDati.idInfoRichieste.push({
                                                    keyJftech: retDataInfo[index].id_info_richieste_compagnia,
                                                    keyGen: retDataInfo[index].id_info_richiesta,
                                                })
                                            }
                                            //ID DANNEGGIATI GEN
                                            const QueryExecTabDanneggiati = `SELECT distinct T2.id_danneggiato as guid_danneggiato,T3.id_liquidazione FROM [00005] T1 left outer join [12004] T2 on T1.id_contatto = T2.id_danneggiato_jftech left outer join [12012] T3 on T2.id_danneggiato = T3.id_danneggiato WHERE T1.id_sinistro = ${this.props.IdSinistro};`;
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecTabDanneggiati, '', retDataDann => {
                                                for (let d = 0; d < retDataDann.length; d++) {
                                                    this.strutturaStatiEliminaDati.idDanneggiatiGen.push({
                                                        keyGen: retDataDann[d].guid_danneggiato,
                                                        keyGenLiquidazione: retDataDann[d].id_liquidazione
                                                    })
                                                }
                                                //ID RIEPILOGO
                                                const QueryExecIdRiep = `SELECT idRiepilogo_perizia FROM [11012] where id_sinistro = ${this.props.IdSinistro};`;
                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdRiep, '', retDataIdPerizia => {
                                                    if (retDataIdPerizia.length > 0) {
                                                        this.strutturaStatiEliminaDati.idRiepilogoPerizia = retDataIdPerizia[0].idRiepilogo_perizia;
                                                        const QueryExecIdRiepDanneggiati = `SELECT idDanneggiatoRiep FROM [11013] where id_sinistro = ${this.props.IdSinistro};`;
                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdRiepDanneggiati, '', retDataIdPeriziaDann => {
                                                            for (let index = 0; index < retDataIdPeriziaDann.length; index++) {
                                                                this.strutturaStatiEliminaDati.idRiepilogoDanneggiati.push(retDataIdPeriziaDann[index].idDanneggiatoRiep)
                                                            }
                                                            const QueryExecIdRiepRigheDanneggiato = `SELECT idGarPart FROM [11014] where id_sinistro = ${this.props.IdSinistro};`;
                                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdRiepRigheDanneggiato, '', retDataIdPeriziaRigheDann => {
                                                                for (let index = 0; index < retDataIdPeriziaRigheDann.length; index++) {
                                                                    this.strutturaStatiEliminaDati.idRiepilogoRigheDanneggiato.push(retDataIdPeriziaRigheDann[index].idGarPart)
                                                                }
                                                                const QueryExecIdCausali = `SELECT id_causale,id_danno FROM [12017] where id_incarico = '${this.props.IdIncarico}';`;
                                                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExecIdCausali, '', retDataIdCaus => {
                                                                    if (retDataIdCaus.length > 0) {
                                                                        for (let index = 0; index < retDataIdCaus.length; index++) {
                                                                            this.strutturaStatiEliminaDati.idCausali.push({
                                                                                idCausale: retDataIdCaus[index].id_causale,
                                                                                idDanno: retDataIdCaus[index].id_danno
                                                                            })
                                                                        }
                                                                    }
                                                                    //---------------------------ELIMINO DATI---------------------------------------
                                                                    let tempoOneDelete = 75;
                                                                    let tempoPreesistenze = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaJftech.length;
                                                                    let tempoPreesCostruz = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaCostruz.length;
                                                                    let tempoPreesDescr = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaDescr.length;
                                                                    let tempoPreesGen = tempoOneDelete * this.strutturaStatiEliminaDati.idPreesistenzaGen.length;
                                                                    let tempoStimeDescr = tempoOneDelete * this.strutturaStatiEliminaDati.idStimeDescrizioni.length;
                                                                    let tempoDannoDettInfoRichGen = tempoOneDelete * this.strutturaStatiEliminaDati.idDannoDettInfoRichGen.length;
                                                                    let tempoCoassicurazioni = tempoOneDelete * this.strutturaStatiEliminaDati.idCoassicurazioni.length;
                                                                    let tempoInfoRichiesteGen = tempoOneDelete * this.strutturaStatiEliminaDati.idInfoRichieste.length;
                                                                    let tempoIdDanneggiati = tempoOneDelete * this.strutturaStatiEliminaDati.idDanneggiatiGen.length;
                                                                    let tempoUnipol = tempoOneDelete;
                                                                    let tempoTotaliPerizia = tempoOneDelete;
                                                                    let tempoIdRiepilogo = tempoOneDelete;
                                                                    let tempoIdRiepilogoDanneggiati = tempoOneDelete * this.strutturaStatiEliminaDati.idRiepilogoDanneggiati.length;
                                                                    let tempoIdRiepilogoRigheDanneggiato = tempoOneDelete * this.strutturaStatiEliminaDati.idRiepilogoRigheDanneggiato.length;
                                                                    let tempoIdCausali = tempoOneDelete * this.strutturaStatiEliminaDati.idCausali.length;
                                                                    //TOTALE
                                                                    let tempoTotale = tempoPreesistenze + tempoPreesCostruz + tempoPreesDescr + tempoPreesGen + tempoStimeDescr +
                                                                        tempoDannoDettInfoRichGen + tempoCoassicurazioni + tempoInfoRichiesteGen + tempoIdDanneggiati + tempoUnipol +
                                                                        tempoTotaliPerizia + tempoIdRiepilogo + tempoIdRiepilogoDanneggiati + tempoIdRiepilogoRigheDanneggiato + tempoIdCausali;


                                                                    console.log(this.strutturaStatiEliminaDati)
                                                                    //ELIMINA PREESISTENZE
                                                                    this.strutturaStatiEliminaDati.idPreesistenzaJftech.forEach(elm => {
                                                                        let queryEliminaPreesistenze = `DELETE FROM [11006] WHERE id_preesistenza = ${elm};`;
                                                                        let jsonObjectEliminaPreesistenze = {
                                                                        }
                                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaPreesistenze, JSON.stringify(jsonObjectEliminaPreesistenze), retDataEliminato => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            });
                                                                        }, true, true);
                                                                    })
                                                                    //ELIMINA PREESISTENZE COSTRUZIONI
                                                                    this.strutturaStatiEliminaDati.idPreesistenzaCostruz.forEach(elm => {
                                                                        let queryEliminaPreesistenzeCostr = `DELETE FROM [11007] WHERE id_preesistenza_costruzione = ${elm};`;
                                                                        let jsonObjectEliminaPreesistenzeCostr = {
                                                                        }
                                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaPreesistenzeCostr, JSON.stringify(jsonObjectEliminaPreesistenzeCostr), retDataEliminato => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            });
                                                                        }, true, true);
                                                                    })
                                                                    //ELIMINA PREESISTENZE descrizIONI
                                                                    this.strutturaStatiEliminaDati.idPreesistenzaDescr.forEach(elm => {
                                                                        let queryEliminaPreesistenzeDescr = `DELETE FROM [11008] WHERE id_preesistenza_descrizione = ${elm};`;
                                                                        let jsonObjectEliminaPreesistenzeDescr = {
                                                                        }
                                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaPreesistenzeDescr, JSON.stringify(jsonObjectEliminaPreesistenzeDescr), retDataEliminato => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            });
                                                                        }, true, true);
                                                                    })
                                                                    //ELIMINA PREESISTENZE GENERALI
                                                                    if (this.isCompagniaGenerali()) {
                                                                        this.strutturaStatiEliminaDati.idPreesistenzaGen.forEach(elm => {
                                                                            let queryEliminaPreesistenzeGen = `DELETE FROM [12007] WHERE id_preesistenza = ${elm};`;
                                                                            let jsonObjectEliminaPreesistenzeGen = {
                                                                            }
                                                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaPreesistenzeGen, JSON.stringify(jsonObjectEliminaPreesistenzeGen), retDataEliminato => {
                                                                            }, messaggio => {
                                                                                toast.warn(messaggio, {
                                                                                    containerId: 'toastContainer1',
                                                                                    onClose: () => toast.dismiss(),
                                                                                    position: "bottom-right",
                                                                                    autoClose: 5000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: false,
                                                                                    progress: undefined,
                                                                                    newestOnTop: true,
                                                                                    rtl: false,
                                                                                    pauseOnFocusLoss: true
                                                                                });
                                                                            }, true, true);
                                                                        })
                                                                    }
                                                                    //ELIMINO STIME descrizIONI
                                                                    this.strutturaStatiEliminaDati.idStimeDescrizioni.forEach(elm => {
                                                                        let queryEliminaStimaDescr = `BEGIN TRAN;DELETE FROM [11009] WHERE id_stima_descrizione = ${elm.keyJftech}; DELETE FROM [12013] WHERE id_dettaglio = ${elm.keyGen};COMMIT;`;
                                                                        let jsonObjectEliminaStimaDescr = {
                                                                        }
                                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaStimaDescr, JSON.stringify(jsonObjectEliminaStimaDescr), retDataEliminato => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            });
                                                                        }, true, true);
                                                                    })
                                                                    if (this.isCompagniaGenerali()) {
                                                                        //ELIMINO DANNO DETTAGLI INFO RICHIESTE
                                                                        this.strutturaStatiEliminaDati.idDannoDettInfoRichGen.forEach(elm => {
                                                                            let queryEliminaDannDettInfoRich = `DELETE FROM [12016] WHERE id_info_richiesta = ${elm.idInfo};`;
                                                                            let jsonObjectEliminaDannDettInfoRich = {
                                                                            }
                                                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaDannDettInfoRich, JSON.stringify(jsonObjectEliminaDannDettInfoRich), retDataEliminato => {
                                                                            }, messaggio => {
                                                                                toast.warn(messaggio, {
                                                                                    containerId: 'toastContainer1',
                                                                                    onClose: () => toast.dismiss(),
                                                                                    position: "bottom-right",
                                                                                    autoClose: 5000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: false,
                                                                                    progress: undefined,
                                                                                    newestOnTop: true,
                                                                                    rtl: false,
                                                                                    pauseOnFocusLoss: true
                                                                                });
                                                                            }, true, true);
                                                                        })
                                                                    }
                                                                    //ELIMINA COASSICURAZIONI
                                                                    this.strutturaStatiEliminaDati.idCoassicurazioni.forEach(elm => {
                                                                        let queryEliminaCoass = `DELETE FROM [11010] WHERE id_compagnia_coass = ${elm.keyJftech}; DELETE FROM [12014] WHERE id_coassi_indiretta = ${elm.keyGen};`;
                                                                        let jsonObjectEliminaCoass = {
                                                                        }
                                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaCoass, JSON.stringify(jsonObjectEliminaCoass), retDataEliminato => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            });
                                                                        }, true, true);
                                                                    })
                                                                    //ELIMINA INFO RICHIESTE
                                                                    this.strutturaStatiEliminaDati.idInfoRichieste.forEach(elm => {
                                                                        let queryEliminaInfoRich = `BEGIN TRAN;DELETE FROM [11011] WHERE id_info_richieste_compagnia = ${elm.keyJftech}; DELETE FROM [12015] WHERE id_info_richiesta = ${elm.keyGen};COMMIT;`;
                                                                        let jsonObjectEliminaInfoRich = {
                                                                        }
                                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaInfoRich, JSON.stringify(jsonObjectEliminaInfoRich), retDataEliminato => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            });
                                                                        }, true, true);
                                                                    });
                                                                    if (this.isCompagniaGenerali()) {
                                                                        this.strutturaStatiEliminaDati.idDanneggiatiGen.forEach(elm => {
                                                                            let queryUpdateDanneggiatiGen = `BEGIN TRAN;UPDATE [12004] set importo_netto = null, importo_lordo = null WHERE id_danneggiato = '${elm.keyGen}';UPDATE [12012] set arrotondamento = 0, importo_liquidazione = 0 WHERE id_liquidazione = ${elm.keyGenLiquidazione};COMMIT;`;
                                                                            let jsonObjectUpdateDanneggiati = {
                                                                            }
                                                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryUpdateDanneggiatiGen, JSON.stringify(jsonObjectUpdateDanneggiati), retDataUpdate => {
                                                                            }, messaggio => {
                                                                                toast.warn(messaggio, {
                                                                                    containerId: 'toastContainer1',
                                                                                    onClose: () => toast.dismiss(),
                                                                                    position: "bottom-right",
                                                                                    autoClose: 5000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: false,
                                                                                    progress: undefined,
                                                                                    newestOnTop: true,
                                                                                    rtl: false,
                                                                                    pauseOnFocusLoss: true
                                                                                });
                                                                            }, true, true);
                                                                        })
                                                                    }
                                                                    //UPDATE UNIPOL PERIZIA IMPORTI
                                                                    if (this.isCompagniaUnipol()) {
                                                                        let queryUpdatePeriziaUnipol = `UPDATE [10003] set importoPeriziato = null, importoConcordato = null, importoLiquidabile = null, importoFranchigia = null, presenzaAltreAssicurazioniStessoRischio = null where id_sinistro = ${this.props.IdSinistro};`;
                                                                        let jsonObjectUpdateUnipol = {
                                                                        }
                                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryUpdatePeriziaUnipol, JSON.stringify(jsonObjectUpdateUnipol), retDataUpdate => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            });
                                                                        }, true, true);
                                                                    }
                                                                    let queryUpdatePeriziaTot = `UPDATE [11001] set tot_indennizzo_lordo_compl = null, tot_indennizzo_netto_compl = null, tot_indennizzo_netto_compl_arrot = null, tot_danni_accertati = null, periziaNoCalcoli = 0 where id_sinistro = ${this.props.IdSinistro}`;
                                                                    let jsonObjectUpdateTot = {
                                                                    }
                                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryUpdatePeriziaTot, JSON.stringify(jsonObjectUpdateTot), retDataUpdate => {
                                                                    }, messaggio => {
                                                                        toast.warn(messaggio, {
                                                                            containerId: 'toastContainer1',
                                                                            onClose: () => toast.dismiss(),
                                                                            position: "bottom-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            newestOnTop: true,
                                                                            rtl: false,
                                                                            pauseOnFocusLoss: true
                                                                        }
                                                                        );
                                                                    }, true, true);
                                                                    //ELIMINO DATI DAL RIEPILOGO
                                                                    //RIEPILOGO
                                                                    let queryRiep = `DELETE FROM [11012] where idRiepilogo_perizia = ${this.strutturaStatiEliminaDati.idRiepilogoPerizia};`;
                                                                    let jsonObjectRiep = {
                                                                    }
                                                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryRiep, JSON.stringify(jsonObjectRiep), eliminatoRiep => {
                                                                        //ELIMINO DANNEGGIATI RIEPILOGO
                                                                        this.strutturaStatiEliminaDati.idRiepilogoDanneggiati.forEach(idDann => {
                                                                            let query = `DELETE FROM [11013] where idDanneggiatoRiep = ${idDann};`;
                                                                            let jsonObject = {
                                                                            }
                                                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', query, JSON.stringify(jsonObject), eliminato => {
                                                                            }, messaggio => {
                                                                                toast.warn(messaggio, {
                                                                                    containerId: 'toastContainer1',
                                                                                    onClose: () => toast.dismiss(),
                                                                                    position: "bottom-right",
                                                                                    autoClose: 5000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: false,
                                                                                    progress: undefined,
                                                                                    newestOnTop: true,
                                                                                    rtl: false,
                                                                                    pauseOnFocusLoss: true
                                                                                });
                                                                            }, true, true);
                                                                        })
                                                                        //ELIMINO RIGHE PARTITA GARANZIA PER OGNI DANNEGGIATO
                                                                        this.strutturaStatiEliminaDati.idRiepilogoRigheDanneggiato.forEach(idRiga => {
                                                                            let query = `DELETE FROM [11014] where idGarPart = ${idRiga};`;
                                                                            let jsonObject = {
                                                                            }
                                                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', query, JSON.stringify(jsonObject), eliminato => {
                                                                            }, messaggio => {
                                                                                toast.warn(messaggio, {
                                                                                    containerId: 'toastContainer1',
                                                                                    onClose: () => toast.dismiss(),
                                                                                    position: "bottom-right",
                                                                                    autoClose: 5000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: false,
                                                                                    progress: undefined,
                                                                                    newestOnTop: true,
                                                                                    rtl: false,
                                                                                    pauseOnFocusLoss: true
                                                                                });
                                                                            }, true, true);
                                                                        });
                                                                    }, messaggio => {
                                                                        toast.warn(messaggio, {
                                                                            containerId: 'toastContainer1',
                                                                            onClose: () => toast.dismiss(),
                                                                            position: "bottom-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            newestOnTop: true,
                                                                            rtl: false,
                                                                            pauseOnFocusLoss: true
                                                                        }
                                                                        );
                                                                    }, true, true);
                                                                    //ELIMINA CAUSALI
                                                                    this.strutturaStatiEliminaDati.idCausali.forEach(elm => {
                                                                        let queryEliminaCaus = `DELETE FROM [12017] WHERE id_causale = ${elm.idCausale};`;
                                                                        let jsonObjectEliminaCaus = {                                                                           
                                                                        }
                                                                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', queryEliminaCaus, JSON.stringify(jsonObjectEliminaCaus), retDataEliminato => {
                                                                        }, messaggio => {
                                                                            toast.warn(messaggio, {
                                                                                containerId: 'toastContainer1',
                                                                                onClose: () => toast.dismiss(),
                                                                                position: "bottom-right",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: false,
                                                                                progress: undefined,
                                                                                newestOnTop: true,
                                                                                rtl: false,
                                                                                pauseOnFocusLoss: true
                                                                            });
                                                                        }, true, true);
                                                                    })
                                                                    this.distruggiDialog(false);
                                                                    setTimeout(() => {
                                                                        this.toggleBlocking2(false);
                                                                        toast.success("Calcoli eliminati!", {
                                                                            containerId: 'toastContainer1',
                                                                            onClose: () => toast.dismiss(),
                                                                            position: "bottom-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            newestOnTop: true,
                                                                            rtl: false,
                                                                            pauseOnFocusLoss: true
                                                                        });
                                                                        ++this.childKey;
                                                                        const container = document.getElementById(`e-content-tabDettaglio${this.props.IdInternoSinistro}_1`);
                                                                        const root = createRoot(container);
                                                                        root.render(<CallbackWrapper callback={() => {
                                                                            document.getElementById(`tabDettaglio${this.props.IdInternoSinistro}`).ej2_instances[0].items[1].header.text = 'Perizia Online - Elaborato Calcoli';
                                                                        }}><PaginaPeriziaOnlineCalcoli key={this.childKey} IdInternoSinistro={this.props.IdInternoSinistro} IdIncarico={this.props.IdIncarico} IdIncaricoUnipol={this.props.IdIncaricoUnipol} IdSinistro={this.props.IdSinistro} IdCompagnia={this.props.IdCompagnia} numeroPolizza={this.props.numeroPolizza} tipoSinistro={this.props.tipoSinistro}></PaginaPeriziaOnlineCalcoli></CallbackWrapper>);
                                                                    }, tempoTotale)
                                                                }, messaggio => {
                                                                    toast.warn(messaggio, {
                                                                        containerId: 'toastContainer1',
                                                                        onClose: () => toast.dismiss(),
                                                                        position: "bottom-right",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: false,
                                                                        progress: undefined,
                                                                        newestOnTop: true,
                                                                        rtl: false,
                                                                        pauseOnFocusLoss: true
                                                                    });
                                                                }, true, true);
                                                            }, messaggio => {
                                                                toast.warn(messaggio, {
                                                                    containerId: 'toastContainer1',
                                                                    onClose: () => toast.dismiss(),
                                                                    position: "bottom-right",
                                                                    autoClose: 5000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: false,
                                                                    progress: undefined,
                                                                    newestOnTop: true,
                                                                    rtl: false,
                                                                    pauseOnFocusLoss: true
                                                                });
                                                            }, true, true);
                                                        }, messaggio => {
                                                            toast.warn(messaggio, {
                                                                containerId: 'toastContainer1',
                                                                onClose: () => toast.dismiss(),
                                                                position: "bottom-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: false,
                                                                progress: undefined,
                                                                newestOnTop: true,
                                                                rtl: false,
                                                                pauseOnFocusLoss: true
                                                            });
                                                        }, true, true);
                                                    }
                                                }, messaggio => {
                                                    toast.warn(messaggio, {
                                                        containerId: 'toastContainer1',
                                                        onClose: () => toast.dismiss(),
                                                        position: "bottom-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: false,
                                                        progress: undefined,
                                                        newestOnTop: true,
                                                        rtl: false,
                                                        pauseOnFocusLoss: true
                                                    });
                                                }, true, true);
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                });
                                            }, true, true);
                                        }, messaggio => {
                                            toast.warn(messaggio, {
                                                containerId: 'toastContainer1',
                                                onClose: () => toast.dismiss(),
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: false,
                                                progress: undefined,
                                                newestOnTop: true,
                                                rtl: false,
                                                pauseOnFocusLoss: true
                                            });
                                        }, true, true);
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        });
                                    }, true, true);
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                }, true, true);
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                });
                            }, true, true);
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        }, true, true);
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }, true, true);
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    onUploading(args) {
        args.currentRequest.setRequestHeader("Authorization", JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token);
    }
    onUploadSuccess(args){
        let idSin = this.props.IdInternoSinistro;
        let nomeElm = `fotografiaDocumentoStima_D${this.numDannTempDocumStima}_R${this.numRigaTempDocumStima}_${this.props.IdInternoSinistro}`;
        let nomeElm2 = `pathFotografiaDocumentoStima_D${this.numDannTempDocumStima}_R${this.numRigaTempDocumStima}_${this.props.IdInternoSinistro}`;
        let nomeElm3 = `pathFotografiaDocumentoStimaBackup_D${this.numDannTempDocumStima}_R${this.numRigaTempDocumStima}_${this.props.IdInternoSinistro}`;
        let utente = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username;
        let nomeButtVediDocStima = `vediFotografiaDocumentoStima-D${this.numDannTempDocumStima}-R${this.numRigaTempDocumStima}_${this.props.IdInternoSinistro}`;
        let nomeButtRimuoviDocStima = `rimuoviFotografiaDocumentoStima-D${this.numDannTempDocumStima}-R${this.numRigaTempDocumStima}_${this.props.IdInternoSinistro}`;
        $(`<div id=${nomeElm}></div>`).appendTo(`#divDocumentiStima-D${this.numDannTempDocumStima}-R${this.numRigaTempDocumStima}_${this.props.IdInternoSinistro}`);
        setTimeout(() => {
            const container = document.getElementById(nomeElm);
            const root = createRoot(container);                         
            root.render(<div style={{ marginTop: '4px' }}><input type="hidden" id={nomeElm2} value={`${CustomJS.APIVariousJfishTechURLProd}/${CustomJS.CartellaTemporaneaACT}/${utente}/${idSin}/${args.file.name}`}></input><input type="hidden" id={nomeElm3} value=''></input><span style={{ fontSize: '0.7vw', fontWeight: '700' }}><ButtonComponent id={nomeButtVediDocStima} title='Visualizza Foto/Documento Stima' style={{ marginTop: '4px' }} cssClass='e-info e' iconCss='fas fa-eye fa-10x' onClick={this.visualizzaFotografiaDocumentoStima.bind(this, args.file.name, nomeElm2, nomeElm3)} type='button'></ButtonComponent><ButtonComponent id={nomeButtRimuoviDocStima} title='Rimuovi Foto/Documento Stima' style={{ marginTop: '4px' }} cssClass='e-info' iconCss='fas fa-times fa-10x' onClick={this.rimuoviFotografiaDocumentoStima.bind(this, nomeElm)} type='button'></ButtonComponent></span></div>);                     
        }, 200);
    }
    btnAggiungiFoto(numDann, numRiga) {
        this.numDannTempDocumStima = numDann;
        this.numRigaTempDocumStima = numRiga;
        let idSin = this.props.IdInternoSinistro;
        let utente = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username;
        this.uploadObj.asyncSettings.saveUrl = `${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/uploadDocumenti/JFTECH/${utente}/${idSin}/ALLEGATI`;
        document.getElementById('uploadFotografieDocumentiStime').querySelector('button.e-css.e-btn').click(); 
    }
    visualizzaFotografiaDocumentoStima(nomeFile, pathDocumento, pathDocumentoBackup) {
        if (nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'JPEG' || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'JPG'
            || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'PNG' || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'TIF'
            || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'TIFF' || nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'GIF'
            ||  nomeFile.substring(nomeFile.lastIndexOf('.')+1).toUpperCase() === 'JFIF') { 
                this.lightboxImage = !CustomJS.isNullEmpty(document.getElementById(pathDocumentoBackup).value) ? document.getElementById(pathDocumentoBackup).value :  document.getElementById(pathDocumento).value;
                this.setState({showLightbox: true});  
        }
    }    
    rimuoviFotografiaDocumentoStima(pathDocumento){
        $(`#${pathDocumento}`).remove();
    }
}