/* eslint-disable no-extend-native */
/* eslint-disable default-case */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, DetailRow, CommandColumn, Filter, Edit, Inject, Group, Sort, Page, ColumnChooser, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent, DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { SplitButtonComponent, ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { PdfViewerComponent, Toolbar as PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner, Inject as PDFVInject } from '@syncfusion/ej2-react-pdfviewer';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined, object } from 'underscore';
import moment from 'moment';
import $ from 'jquery';

Array.prototype.sum3 = function (prop) {
    let total = 0
    for (let i = 0, _len = this.length; i < _len; i++) {
        total += CustomJS.isNullEmpty(this[i][prop]) ? 0 : parseFloat(CustomJS.decypher(this[i][prop]));
    }
    return total;
}
Array.prototype.sum2 = function (prop) {
    let total = 0
    for (let i = 0, _len = this.length; i < _len; i++) {
        total += this[i][prop];
    }
    return total;
}

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
let elemDdl = null;
export default class PaginaEmissioniCompetenze extends SampleBase {
    constructor() {
        super(...arguments);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = [{ template: this.ddlFiltroSinistriToolbar.bind(this) }, ' ', { prefixIcon: "fas fa-angle-double-right fa-10x e-details", id: "spostaSinistri" }, 'Search'];
        this.toolbarOptions2 = ['Add', 'Edit', 'Update', 'Cancel', ' ', { template: this.resetToolbar.bind(this) }, { template: this.anteprimaToolbar.bind(this) }, { template: this.emettiNotaToolbar.bind(this) }, { template: this.allineaNotePregresse.bind(this) }];
        this.toolbarOptionsEmissNota = ['Edit', 'Update', 'Cancel'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Normal' };
        this.editSettingsAltrePrest = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Normal', newRowPosition: 'Bottom' };
        this.commands = [{ type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" } }];
        this.commands1 = [{ type: "Visualizza", buttonOption: { iconCss: "fas fa-eye fa-10x", cssClass: "e-details" } }];
        this.commandsCheck = [{ type: "", buttonOption: { iconCss: "fas fa-check fa-10x", cssClass: "e-details" } }];
        this.txtObj1 = null;
        this.pageSettings = { pageSize: 0, pageCount: 1 };
        this.pageSettings1 = { pageSize: 15, pageCount: 5, pageSizes: ["15", "50", "100", "200", "Tutti"] };
        this.pageSettings2 = { pageSize: 10, pageCount: 5 };
        this.filterSettings = { type: 'CheckBox' };
        this.searchSettings = { operator: 'contains', ignoreCase: true };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.dsMetodFatt = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabellaMetodoFatt')))?.map(elm => { return { VALUE: elm.id_metodo_fatturazione?.toString(), TEXT: elm.descrizione } });
        this.DdlObj = null;
        this.elemSelezStato = null;
        this.editTemplate = {
            create: () => { elemDdl = document.createElement('input'); return elemDdl },
            read: () => { return this.DdlObj.text },
            destroy: () => { this.DdlObj.destroy() },
            write: (args) => {
                this.elemSelezStato = args.rowData.id_tipo_stato; this.DdlObj = new DropDownList({
                    fields: { text: 'descrizione', value: 'id_tipo_stato' }, placeholder: 'Selezionare Stato', popupHeight: '200px',
                    dataSource: JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyTabella02004')))?.map(elm => { return { id_tipo_stato: elm.id_tipo_stato.toString(), descrizione: elm.descrizione } }), value: args.rowData.id_tipo_stato?.toString(),
                    allowFiltering: true, filterType: 'Contains',
                    change: (args2) => {
                        args.rowData.id_tipo_stato = parseInt(args2.value);
                        this.elemSelezStato = parseInt(args2.value);
                    }
                }); this.DdlObj.appendTo(elemDdl)
            }
        };
        this.dsFiltroDdl = [{ TEXT: 'Da Pagare', VALUE: 'Pagare' }, { TEXT: 'Fatturabili', VALUE: 'Fatturabili' }];
        this.numeroSinCount = 0;
        this.arrAnteprimaNoteEmesse = [];
    }
    state = {
        blocking: false,
        showDialog1: false,
        showDialog2: false,
    }
    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    actionBegin(args) {
        if (args.action === "edit" && args.requestType === "save") {
            args.data.id_tipo_stato = this.elemSelezStato;
        }
    }
    onActionComplete(args) {
        if (args.requestType === "delete") {
            const dataObj2 = {
            };
            const QueryExec2 = `DELETE FROM [08004] WHERE [08004].id_sinistro IN (${args.data[0].id_sinistro}) AND [08004].idPerito = ${document.getElementById('idPerito').ej2_instances[0].value}`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec2, JSON.stringify(dataObj2), retData => {
                //this.resetta_popola();
                //this.componentDidMount();
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        }
        if (((args.action === "edit" || args.action === "add") && args.requestType === "save") || args.requestType === "delete") {
            const dataObj2 = {
                IdSinistro: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_sinistro : args.data[0].id_sinistro,
                IdInternoSinistro: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_interno_sinistro : args.data[0].id_interno_sinistro,
                NumeroSinistroAgenzia: CustomJS.isNullEmpty(args.data[0]) ? args.data.numero_sinistro_agenzia : args.data[0].numero_sinistro_agenzia,
                ParcellaPerito: CustomJS.isNullEmpty(args.data[0]) ? args.data.importo : args.data[0].importo,
                NumeroSchede: CustomJS.isNullEmpty(args.data[0]) ? args.data.numero_schede : args.data[0].numero_schede,
                Importo: CustomJS.isNullEmpty(args.data[0]) ? args.data.importo : args.data[0].importo,
            };
            const QueryExec2 = "BEGIN TRAN; UPDATE [01001] SET parcellaPerito = @parcellaPerito WHERE id_interno_sinistro = @id_interno_sinistro; UPDATE [08004] SET numero_schede = @numero_schede, importo = @importo, id_sinistro = @id_sinistro WHERE id_sinistro = @id_sinistro; COMMIT;";
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec2, JSON.stringify(dataObj2), retData2 => {
                this.componentDidMount();
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            }, true, true);
        }
    }
    onActionComplete2(args) {
        if (args.requestType === "delete") {
            const dataObj2 = {
                IdAltraPrestazione: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_altra_prest : args.data[0].id_altra_prest
            };
            const QueryExec2 = `DELETE FROM [08006] WHERE [08006].id_altra_prest = @id_altra_prest`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec2, JSON.stringify(dataObj2), retData => {
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        }
        if (((args.action === "edit" || args.action === "add") && args.requestType === "save")) {
            const dataObj2 = {
                IdAltraPrestazione: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_altra_prest : args.data[0].id_altra_prest,
                IdUtenteFatturazione: document.getElementById('idPerito').ej2_instances[0].value,
                Oggetto: CustomJS.isNullEmpty(args.data[0]) ? args.data.oggetto : args.data[0].oggetto,
                Descrizione: CustomJS.isNullEmpty(args.data[0]) ? args.data.descrizione?.trim() : args.data[0].descrizione?.trim(),
                Importo: CustomJS.isNullEmpty(args.data[0]) ? args.data.importo : args.data[0].importo
            };
            const QueryExec2 = `UPDATE [08006] SET oggetto = @oggetto, descrizione = @descrizione, importo = @importo WHERE [08006].id_altra_prest = @id_altra_prest; IF @@ROWCOUNT = 0 BEGIN INSERT INTO [08006] (id_utente_fatturazione, oggetto, descrizione, importo ) VALUES (@id_utente_fatturazione, @oggetto, @descrizione, @importo) END`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec2, JSON.stringify(dataObj2), retData2 => {
                this.componentDidMount();
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        }
    }
    impostaDataNumeroFattura() {
        const dataObj = {
        };
        let QueryExec = '';
        if (CustomJS.isNullEmpty(document.getElementById('numeroFattura').ej2_instances[0].value)) {
            QueryExec = `UPDATE [08006] SET [08006].numero_fattura = NULL`;
        }
        else {
            QueryExec = `UPDATE [08006] SET [08006].numero_fattura = '${document.getElementById('numeroFattura').ej2_instances[0].value}'`;
        }
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        }, true, true);
        const dataObj2 = {
        };
        let QueryExec2 = '';
        if (CustomJS.isNullEmpty(document.getElementById('dataFattura').ej2_instances[0].value)) {
            QueryExec2 = `UPDATE [08006] SET [08006].data_fattura = NULL`;
        }
        else {
            QueryExec2 = `UPDATE [08006] SET [08006].data_fattura = '${moment(document.getElementById('dataFattura').ej2_instances[0].value, 'DD/MM/YYYY').format('YYYY-MM-DD')}'`;
        }
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec2, JSON.stringify(dataObj2), retData => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        }, true, true);
        const dataObj3 = {
        };
        const QueryExec3 = `UPDATE [08006] SET [08006].id_utente_fatturazione = ${document.getElementById('idPerito').ej2_instances[0].value}`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec3, JSON.stringify(dataObj3), retData => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        }, true, true);
    }
    onClickAllineaNote(){
        this.toggleBlocking();
        const QueryExec4 = `SELECT TOP 500 [040051].id_sinistro, [040051].importo, [09001].aux1 FROM [040051] INNER JOIN [04005] ON [040051].id_fattura = [04005].id_fattura INNER JOIN [09001] ON [09001].id_utente = [04005].id_utente_fatturazione WHERE ISNULL([040051].importo_num,0) = 0 ORDER BY [040051].id_fattura; SELECT TOP 10 [040052].id_voce, [040052].importo, [09001].aux1 FROM [040052] INNER JOIN [04005] ON [040052].id_fattura = [04005].id_fattura INNER JOIN [09001] ON [09001].id_utente = [04005].id_utente_fatturazione WHERE ISNULL([040052].importo_num,0) = 0 ORDER BY [040052].id_fattura;`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec4, '', retData4 => {
            let arr040051 = [], arr040052 = [];
            retData4[0].forEach(elm => { 
                arr040051.push({ id_sinistro: elm.id_sinistro, importo_num: CustomJS.decypher(elm.importo, elm.aux1)})
            });
            retData4[1].forEach(elm => { 
                arr040052.push({ id_voce: elm.id_voce, importo_num: CustomJS.decypher(elm.importo, elm.aux1)})
            });
            arr040051.forEach(elm => { 
                if (CustomJS.isNullEmpty(elm.importo_num))
                    return;
                const dataObj = {};
                const QueryExec3 = `UPDATE [040051] SET importo_num = ${CustomJS.isNullEmpty(elm.importo_num) ? 0 : parseFloat(elm.importo_num.replace(',','.'))} WHERE id_sinistro = ${elm.id_sinistro}`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec3, JSON.stringify(dataObj), retData3 => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);    
            });
            arr040052.forEach(elm => { 
                if (CustomJS.isNullEmpty(elm.importo_num))
                    return;
                const dataObj = {};
                const QueryExec3 = `UPDATE [040052] SET importo_num = ${CustomJS.isNullEmpty(elm.importo_num) ? 0 : parseFloat(elm.importo_num.replace(',','.'))} WHERE id_voce = ${elm.id_voce}`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec3, JSON.stringify(dataObj), retData3 => {
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                }, true, true);   
            });
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    ddlFiltroSinistriToolbar() {
        return (
            <DropDownListComponent id='ddlFiltroSinistri' fields={this.ddlFields} ref={ddlObj => this.ddlInstance1 = ddlObj} dataSource={this.dsFiltroDdl} allowFiltering={false} 
                value='Fatturabili' filterType='Contains' change={this.onChangeFiltroSinistri.bind(this)}
                style={{ width: '12vw' }} placeholder="Visualizza" floatLabelType='always' popupHeight="250px" cssClass='e-outline' />
        );
    }
    resetToolbar() {
        return (
            <ProgressButtonComponent id='btnReset' content="RESET" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }}
                animationSettings={{ effect: 'SlideRight' }} cssClass="e-fill e-info" onClick={this.onClickReset.bind(this)}></ProgressButtonComponent>
        );
    }
    anteprimaToolbar() {
        return (
            <ProgressButtonComponent id='btnAnteprima' content="ANTEPRIMA" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }}
                animationSettings={{ effect: 'SlideRight' }} cssClass="e-fill e-info" style={{ marginLeft: '10px' }} onClick={this.generaNotaAnteprima.bind(this)}></ProgressButtonComponent>
        );
    }
    emettiNotaToolbar() {
        return (
            <ProgressButtonComponent id='btnEmettiNota' content="EMETTI NOTA" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }}
                animationSettings={{ effect: 'SlideRight' }} cssClass="e-fill e-info" style={{ marginLeft: '10px' }} onClick={this.onClickEmettiNota.bind(this)}></ProgressButtonComponent>
        );
    }
    allineaNotePregresse() {
        // return (
        //     <ProgressButtonComponent id='btnAllineaNote' content="ALLINEA NOTE PREGRESSE" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }} 
        //         animationSettings={{ effect: 'SlideRight' }} cssClass="e-fill e-danger" style={{ marginLeft: '10px', display: 'inline' }} onClick={this.onClickAllineaNote.bind(this)}></ProgressButtonComponent>
        // );
    }
    onDataBound() {
        setTimeout(function () {
            $('span.e-label').hide();  //TODO : trovare soluzione più pulita a livello di codice oppure di CSS
            $('.e-btn-icon.fas.fa-angle-double-right.fa-10x.e-details.e-icons').css('font-size', '30px');
        }, 10);
    }
    onChangeFiltroSinistri(args) {
        let get_data_meseprec = moment(new Date()).subtract(1, 'months').endOf('month').format('YYYYMMDD');
        const QueryExec = `SELECT [01001].id_interno_sinistro, [01001].numero_sinistro_agenzia, [01001].parcellaPerito, [08003].crea, [08003].data_consegna_perizia, [08003].numero_schede, [08003].id_sinistro FROM [01001] INNER JOIN [08003] ON [01001].id_sinistro = [08003].id_sinistro WHERE [08003].idPerito=${document.getElementById('idPerito').ej2_instances[0].value} ORDER BY [08003].data_consegna_perizia ASC; `;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            retData.forEach(elm => {
                elm.fatturabile = (elm.data_consegna_perizia <= get_data_meseprec) ? 'si' : '';
            })
            this.arrDaFiltrare = retData;
            if (args.value === 'Fatturabili') {
                this.sinFatturabili = _.filter(this.arrDaFiltrare, obj => { return obj.fatturabile === 'si' });
                this.dsSinistriFatturabili = this.sinFatturabili.map(elm => { return { id_sinistro: elm.id_sinistro, id_interno_sinistro: elm.id_interno_sinistro, numero_sinistro_agenzia: elm.numero_sinistro_agenzia, crea: elm.crea, data_consegna_perizia: elm.data_consegna_perizia, numero_schede: elm.numero_schede, parcellaPerito: elm.parcellaPerito, fatturabile: elm.fatturabile } });
                document.getElementById(`gridSinistri`).ej2_instances[0].dataSource = this.dsSinistriFatturabili;
                this.gridInstanceSinistri.enableToolbarItems(['spostaSinistri'], true);
            }
            else {
                this.sinPagare = _.filter(this.arrDaFiltrare, obj => { return CustomJS.isNullEmpty(obj.fatturabile) });
                this.dsSinistriDaPagare = this.sinPagare.map(elm => { return { id_sinistro: elm.id_sinistro, id_interno_sinistro: elm.id_interno_sinistro, numero_sinistro_agenzia: elm.numero_sinistro_agenzia, crea: elm.crea, data_consegna_perizia: elm.data_consegna_perizia, numero_schede: elm.numero_schede, parcellaPerito: elm.parcellaPerito, fatturabile: elm.fatturabile } });
                document.getElementById(`gridSinistri`).ej2_instances[0].dataSource = this.dsSinistriDaPagare;
                this.gridInstanceSinistri.enableToolbarItems(['spostaSinistri'], false);
            }
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
        if (args.item.id === 'spostaSinistri') {
            const dataObj = {
            };
            this.toggleBlocking();
            const QueryExec = `INSERT INTO [08004] (idPerito, id_sinistro, importo, numero_schede, id_utente_fatturazione, data_consegna_perizia) SELECT [08003].idPerito, [08003].id_sinistro, [01001].parcellaPerito, [08003].numero_schede, ${document.getElementById('idPerito').ej2_instances[0].value}, [08003].data_consegna_perizia FROM [08003] INNER JOIN [01001] ON [08003].id_sinistro = [01001].id_sinistro WHERE [08003].crea = 1 AND [08003].idPerito = ${document.getElementById('idPerito').ej2_instances[0].value}`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                this.resetta_popola();
                setTimeout(() => {
                    let get_data_meseprec = moment(new Date()).subtract(1, 'months').endOf('month').format('YYYYMMDD');
                    const QueryExec = `SELECT [01001].id_interno_sinistro, [01001].numero_sinistro_agenzia, [01001].parcellaPerito, [08003].crea, [08003].data_consegna_perizia, [08003].numero_schede, [08003].id_sinistro FROM [01001] INNER JOIN [08003] ON [01001].id_sinistro = [08003].id_sinistro WHERE [08003].crea = 0 AND [08003].idPerito=${document.getElementById('idPerito').ej2_instances[0].value} ORDER BY [08003].data_consegna_perizia ASC;`;
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                        retData.forEach(elm => {
                            elm.fatturabile = (moment(elm.data_consegna_perizia).format('YYYYMMDD') <= get_data_meseprec) ? 'si' : '';
                        })
                        this.dsSinistri = retData.map(elm => { return { id_sinistro: elm.id_sinistro, id_interno_sinistro: elm.id_interno_sinistro, numero_sinistro_agenzia: elm.numero_sinistro_agenzia, crea: elm.crea, data_consegna_perizia: elm.data_consegna_perizia, numero_schede: elm.numero_schede, parcellaPerito: elm.parcellaPerito, fatturabile: elm.fatturabile } });
                        document.getElementById(`gridSinistri`).ej2_instances[0].dataSource = this.dsSinistri;
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }, true, true);
                    const QueryExec2 = `SELECT [08004].*, [01001].id_interno_sinistro, [01001].numero_sinistro_agenzia FROM [01001] INNER JOIN [08004] ON [01001].id_sinistro=[08004].id_sinistro WHERE [08004].idPerito=${document.getElementById('idPerito').ej2_instances[0].value};`;
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec2, '', retData2 => {
                        this.dsEmissioneNota = retData2.map(elm => { return { id_interno_sinistro: elm.id_interno_sinistro, numero_sinistro_agenzia: elm.numero_sinistro_agenzia, idPerito: elm.idPerito, importo: elm.importo, numero_schede: elm.numero_schede, id_utente_fatturazione: elm.id_utente_fatturazione, data_fattura: elm.data_fattura, id_sinistro: elm.id_sinistro, data_consegna_perizia: elm.data_consegna_perizia } });
                        document.getElementById(`gridEmissioneNota`).ej2_instances[0].dataSource = this.dsEmissioneNota;
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });
                    }, true, true);
                    this.toggleBlocking();
                }, 500);
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking();
            }, true, true);
        }
    }
    onClickReset() {
        this.dialogInstance.header = 'Attenzione!!';
        this.dialogInstance.content = "Svuotare le tabelle temporanee?";
        this.dialogInstance.width = '23%';
        this.dialogInstance.height = '22%';
        this.dialogInstance.minHeight = '22%';
        this.dialogInstance.buttons = [
            {
                click: () => {
                    this.dialogInstance.hide();
                    setTimeout(() => {
                        const dataObj = {
                        };
                        const QueryExec = `DELETE FROM [08004] WHERE [08004].idPerito = ${document.getElementById('idPerito').ej2_instances[0].value}; DELETE FROM [08006] WHERE [08006].id_utente_fatturazione = ${document.getElementById('idPerito').ej2_instances[0].value}`;
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                            // this.resetta_popola();
                            this.componentDidMount();
                        }, messaggio => {
                            toast.warn(messaggio, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true
                            });
                        }, true, true);
                    }, 500);
                },
                buttonModel: {
                    content: 'CONFERMA',
                    cssClass: 'e-primary',
                    isPrimary: true
                }
            },
            {
                click: () => {
                    this.dialogInstance.hide();
                },
                buttonModel: {
                    content: 'ANNULLA',
                }
            }
        ];
        this.dialogInstance.show();
    }
    generaNotaAnteprima() {
        this.impostaDataNumeroFattura();
        let importi = _.map(this.gridInstanceAltriTipiPrest.dataSource, (elm) => { return parseFloat(elm.importo) });
        let sum = 0;
        for (let i = 0; i < importi.length; i++) {
            sum += importi[i];
        }
        if (document.getElementById('gridEmissioneNota').ej2_instances[0].dataSource.length === 0 && document.getElementById('gridAltriTipiPrestazioni').ej2_instances[0].dataSource.length === 0) {
            this.dialogInstance.header = 'Attenzione!!';
            this.dialogInstance.content = 'Inserire un sinistro o un tipo di prestazione';
            this.dialogInstance.width = '23%';
            this.dialogInstance.height = '21%';
            this.dialogInstance.minHeight = '21%';
            this.dialogInstance.buttons = [{ click: () => { this.dialogInstance.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
            this.dialogInstance.show();
            return;
        }
        if (!CustomJS.isNullEmpty(document.getElementById('numeroFattura').ej2_instances[0].value) && !CustomJS.isNullEmpty(document.getElementById('dataFattura').ej2_instances[0].value)) {
            const QueryExec = `SELECT tot_temp.*, [09001].username, [09001].nome, [09001].cognome, [09001].ragione_sociale, [09001].cod_fiscale, [09001].p_iva, [09001].indirizzo, [09001].cap, [09001].citta, [09001].provincia, [09001].data_nascita, [09001].luogo_nascita, [09001].telefono1, [09001].email1, [09001].id_metodo_fatturazione, [09001].id_utente, [09001].iban FROM (SELECT [08004].id_utente_fatturazione, SUM([08004].importo) AS tot_fattura, 'Stesura relazioni peritali' AS descrizione_oggetto, 1 AS tipo_nota, [08004].data_fattura, [08004].numero_fattura FROM [08004] GROUP BY [08004].id_utente_fatturazione, [08004].data_fattura, [08004].numero_fattura UNION ALL SELECT [08006].id_utente_fatturazione, SUM([08006].importo) AS tot_fattura, [08006].[oggetto] AS descrizione_oggetto, 2 AS tipo_nota, [08006].data_fattura, [08006].numero_fattura FROM [08006] GROUP BY [08006].id_utente_fatturazione, [08006].oggetto, [08006].data_fattura, [08006].numero_fattura)  AS tot_temp INNER JOIN [09001] ON tot_temp.id_utente_fatturazione=[09001].id_utente WHERE [09001].id_utente = ${document.getElementById('idPerito').ej2_instances[0].value}; SELECT [08004].numero_fattura, [08004].id_sinistro, [08004].importo AS tot_sinistro, [01001].id_interno_sinistro, [08004].numero_schede FROM [01001] INNER JOIN [08004] ON [01001].id_sinistro = [08004].id_sinistro WHERE [08004].idPerito = ${document.getElementById('idPerito').ej2_instances[0].value}; SELECT [08006].numero_fattura, [08006].importo AS tot_sinistro, [08006].oggetto, [08006].descrizione FROM [08006] WHERE [08006].id_utente_fatturazione = ${document.getElementById('idPerito').ej2_instances[0].value};`;
            this.toggleBlocking();
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                retData[0].forEach(elm => {
                    elm.data_nascita = moment(elm.data_nascita).format('YYYY/MM/DD')
                });
                retData[1].forEach(elm => {
                    elm.tot_sinistri = retData[1].sum2('tot_sinistro')
                });
                const dataObj = {
                    NumDoc: document.getElementById('numeroFattura').ej2_instances[0].value,
                    DataDoc: moment(document.getElementById('dataFattura').ej2_instances[0].value).format('DD/MM/YYYY'),
                    IdMetodoFatt: document.getElementById('metodoFatturazione').ej2_instances[0].value,
                    GridAltriTipiPrestaz: document.getElementById('gridAltriTipiPrestazioni').ej2_instances[0].dataSource,
                    SommaImporti: sum,
                }
                CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/PdfCreator/GeneraNotaEmissioneCompetenze`, { datiNotaEmissioneCompetenzeJSON: JSON.stringify(retData), datiPeritoJSON: JSON.stringify(dataObj) }, 'POST',
                    { "Authorization": CustomJS.tokenAuthAPIVarious }, retData2 => {
                        this.dialogInstance5.header = 'ANTEPRIMA NOTA COMPETENZE'
                        this.dialogInstance5.width = '65%';
                        this.dialogInstance5.height = '90%';
                        this.dialogInstance5.minHeight = '90%';
                        this.dialogInstance5.buttons = [];
                        this.dialogInstance5.open = (args) => {
                            const container = document.getElementById('defaultDialogContent2');
                            const root = createRoot(container);                    
                            root.render(<CallbackWrapper callback={() => {
                                document.getElementById(`PDFViewer`).ej2_instances[0].enableDownload = true;
                                document.getElementById(`PDFViewer`).ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                                //this.PDFViewerInstance.dataBind();  //NON SEMBRA SERVIRE
                                setTimeout(() => {
                                    document.getElementById(`PDFViewer`).ej2_instances[0].load(retData2, null);
                                    // document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].load(`data:application/pdf;base64,${retData}`, null);
                                }, 250);
                                this.toggleBlocking();
                            }}>{this.PDFViewerRender()}</CallbackWrapper>);      
                        };
                        this.dialogInstance5.show();
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        }
                        );
                    });
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
            }, true, true);
        }
        else {
            this.dialogInstance.header = 'Attenzione!!';
            this.dialogInstance.content = 'Manca il numero e la data della fattura!';
            this.dialogInstance.width = '23%';
            this.dialogInstance.height = '21%';
            this.dialogInstance.minHeight = '21%';
            this.dialogInstance.buttons = [{ click: () => { this.dialogInstance.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
            this.dialogInstance.show();
        }
    }
    PDFViewerRender() {
        ++this.childKey;
        return (<React.Fragment>
            <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <div tabIndex="0" className="e-card" id="main_card">
                                <div className="e-card-content">
                                    <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <PdfViewerComponent id='PDFViewer' key={this.childKey} width="auto" style={{ 'height': '720px' }}
                                                enablePrint={true} enableDownload={true} enableAnnotation={false} enableCommentPanel={false}
                                                serviceUrl={`${CustomJS.APIVariousJfishTechURLProd}/api/PdfViewer`}>
                                                <PDFVInject services={[PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner]} />
                                            </PdfViewerComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
    onClickEmettiNota() {
        const verificheNota = () => {
            return new Promise((resolve, reject) => {
                let totale = 0;
                let stringaErrore = '';
                if (CustomJS.isNullEmpty(document.getElementById('numeroFattura').ej2_instances[0].value) || document.getElementById('numeroFattura').ej2_instances[0].value === '') {
                    stringaErrore += "Manca il numero fattura<br>";
                }
                if (CustomJS.isNullEmpty(document.getElementById('dataFattura').ej2_instances[0].value) || document.getElementById('dataFattura').ej2_instances[0].value === '') {
                    stringaErrore += "Manca la data fattura<br>";
                }
                if (CustomJS.isNullEmpty(document.getElementById('idPerito').ej2_instances[0].value) || document.getElementById('idPerito').ej2_instances[0].value === '') {
                    stringaErrore += "Manca il centro di fatturazione<br>";
                }
                if (document.getElementById('gridAltriTipiPrestazioni').ej2_instances[0].dataSource.length === 0 && document.getElementById('gridEmissioneNota').ej2_instances[0].dataSource.length === 0) {
                    stringaErrore += "Inserire un sinistro o una prestazione<br>";
                }
                //this.impostaDataNumeroFattura();
                const QueryExec = `SELECT [01001].id_interno_sinistro, [08004].importo FROM [08004] INNER JOIN [01001] ON [08004].id_sinistro = [01001].id_sinistro WHERE [08004].idPerito = ${document.getElementById('idPerito').ej2_instances[0].value}`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                    retData.forEach(elm => {
                        if (CustomJS.isNullEmpty(elm.importo)) {
                            stringaErrore += `Il sinistro <span style="color:#133B95;font-weight:bolder;font-size:1.3em;">${elm.id_interno_sinistro}</span> non ha importo!<br>`;
                        }
                        else {
                            totale += elm.importo;
                        }
                    });
                    const QueryExec2 = `SELECT [08006].importo FROM [08006] WHERE [08006].id_utente_fatturazione = ${document.getElementById('idPerito').ej2_instances[0].value}`;
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec2, '', retData2 => {
                        retData2.forEach(elm => {
                            if (CustomJS.isNullEmpty(elm.importo)) {
                                stringaErrore += `Immettere un valore per la nota!`;
                            }
                            else {
                                totale += elm.importo;
                            }
                        });
                        const dataObj = {
                            NumeroFattura: document.getElementById('numeroFattura').ej2_instances[0].value,
                            DataFattura: CustomJS.isNullEmpty(document.getElementById('dataFattura').ej2_instances[0].value) ? null : moment(document.getElementById('dataFattura').ej2_instances[0].value).format('YYYY-MM-DD'),
                            //IdUtenteFatturazione: document.getElementById('idPerito').ej2_instances[0].value,
                        };
                        const QueryExec3 = `UPDATE [08006] SET numero_fattura = @numero_fattura, data_fattura = @data_fattura WHERE [08006].id_utente_fatturazione = ${document.getElementById('idPerito').ej2_instances[0].value}`;
                        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec3, JSON.stringify(dataObj), retData3 => {
                            resolve({ err: stringaErrore, importo: totale });
                        }, messaggio => {
                            reject(messaggio);
                        });
                    }, messaggio => {
                        reject(messaggio)
                    });
                }, messaggio => {
                    reject(messaggio)
                }, true, true);
            })
        }
        verificheNota().then(ret => {
            let importi = _.map(this.gridInstanceEmissioneNota.dataSource, (elm) => { return parseFloat(elm.importo) });
            let sum = 0;
            for (let i = 0; i < importi.length; i++) {
                sum += importi[i];
            }
            if (CustomJS.isNullEmpty(ret.err)) {
                this.dialogInstance.header = 'Attenzione!!';
                this.dialogInstance.content = `Verrà emessa la fattura N°: <b>${document.getElementById('numeroFattura').ej2_instances[0].value}</b><br> sull'utente: <b>${document.getElementById('perito').ej2_instances[0].value}</b><br> per un totale di: <b>${ret.importo.toLocaleString()} &euro;</b><br> CONTINUARE?`;
                this.dialogInstance.width = '23%';
                this.dialogInstance.height = '28%';
                this.dialogInstance.minHeight = '27%';
                this.dialogInstance.show();
                this.dialogInstance.buttons = [
                    {
                        click: () => {
                            this.dialogInstance.hide();
                            const dataObj = {
                                NumeroFattura: document.getElementById(`numeroFattura`).value,
                                DataFattura: moment(document.getElementById('dataFattura').ej2_instances[0].value).format('YYYY-MM-DD'),
                                IdUtenteFatturazione: document.getElementById(`idPerito`).value,
                                UsernameEmissione: document.getElementById(`perito`).value,
                                IdMetodoFatturazione: document.getElementById(`metodoFatturazione`).ej2_instances[0].value,
                            };
                            const QueryExec = "INSERT INTO [04005] ( numero_fattura, data_fattura, id_utente_fatturazione, username_emissione, data_emissione, id_metodo_fatturazione ) VALUES (@numero_fattura, @data_fattura, @id_utente_fatturazione, @username_emissione, CURRENT_TIMESTAMP, @id_metodo_fatturazione)";
                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
                                const QueryExec2 = `SELECT max(id_fattura) AS m FROM [04005] WHERE id_utente_fatturazione = ${document.getElementById('idPerito').ej2_instances[0].value}`;
                                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec2, '', retData => {
                                    const QueryExec3 = `SELECT [08004].numero_schede, [08004].id_sinistro, [08004].importo FROM [08004] WHERE idPerito = ${document.getElementById('idPerito').ej2_instances[0].value}`;
                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec3, '', retData2 => {
                                        retData2.forEach(elm => {
                                            elm.importo_cifr = CustomJS.cypher(elm.importo.toString());
                                        });
                                        retData2.forEach(elm => {
                                            const dataObj5 = {
                                                NumeroSchede: elm.numero_schede,
                                                IdSinistro: elm.id_sinistro,
                                                ImportoChr: elm.importo_cifr,
                                                IdFattura: retData[0].m,
                                                ImportoNum: elm.importo,
                                            };
                                            const QueryExec5 = `INSERT INTO [040051] ( numero_schede, id_sinistro, importo, id_fattura, importo_num ) VALUES (@numero_schede, @id_sinistro, @importo_chr, @id_fattura, @importo_num); DELETE FROM [08004] WHERE [08004].idPerito = ${document.getElementById('idPerito').ej2_instances[0].value};`;
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec5, JSON.stringify(dataObj5), retData5 => {
                                                setTimeout(() => {
                                                    document.getElementById('numeroFattura').ej2_instances[0].value = null;
                                                    document.getElementById('numeroFattura').ej2_instances[0].text = null;
                                                    document.getElementById('dataFattura').ej2_instances[0].value = null;
                                                    document.getElementById('dataFattura').ej2_instances[0].text = null;
                                                }, 200);
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                }
                                                );
                                            }, true, true);
                                        });
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        }
                                        );
                                    }, true, true);
                                    const QueryExec4 = `SELECT [08006].importo, [08006].oggetto, [08006].descrizione FROM [08006] WHERE [08006].id_utente_fatturazione = ${document.getElementById('idPerito').ej2_instances[0].value};`;
                                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec4, '', retData4 => {
                                        retData4.forEach(elm => {
                                            elm.importo_cifr = CustomJS.cypher(elm.importo.toString());
                                        });
                                        retData4.forEach(elm => {
                                            const dataObj5 = {
                                                Oggetto: elm.oggetto,
                                                IdFattura: retData[0].m,
                                                Descrizione: elm.descrizione,
                                                ImportoChr: elm.importo_cifr,
                                                ImportoNum: elm.importo,
                                            };
                                            const QueryExec5 = `INSERT INTO [040052] ( importo, id_fattura, oggetto, descrizione, importo_num ) VALUES (@importo_chr, @id_fattura, @oggetto, @descrizione, @importo_num); DELETE FROM [08004] WHERE [08004].idPerito = ${document.getElementById('idPerito').ej2_instances[0].value}; DELETE FROM [08006] WHERE [08006].id_utente_fatturazione = ${document.getElementById('idPerito').ej2_instances[0].value}`;
                                            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec5, JSON.stringify(dataObj5), retData5 => {
                                                this.resetta_popola();
                                                setTimeout(() => {
                                                    document.getElementById('numeroFattura').ej2_instances[0].value = null;
                                                    document.getElementById('numeroFattura').ej2_instances[0].text = null;
                                                    document.getElementById('dataFattura').ej2_instances[0].value = null;
                                                    document.getElementById('dataFattura').ej2_instances[0].text = null;
                                                }, 200);
                                            }, messaggio => {
                                                toast.warn(messaggio, {
                                                    containerId: 'toastContainer1',
                                                    onClose: () => toast.dismiss(),
                                                    position: "bottom-right",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: false,
                                                    progress: undefined,
                                                    newestOnTop: true,
                                                    rtl: false,
                                                    pauseOnFocusLoss: true
                                                }
                                                );
                                            }, true, true);
                                        });
                                    }, messaggio => {
                                        toast.warn(messaggio, {
                                            containerId: 'toastContainer1',
                                            onClose: () => toast.dismiss(),
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: false,
                                            progress: undefined,
                                            newestOnTop: true,
                                            rtl: false,
                                            pauseOnFocusLoss: true
                                        }
                                        );
                                    }, true, true);
                                }, messaggio => {
                                    toast.warn(messaggio, {
                                        containerId: 'toastContainer1',
                                        onClose: () => toast.dismiss(),
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    }
                                    );
                                }, true, true);
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                }
                                );
                            }, true, true);

                            toast.success(`La fattura N° ${document.getElementById('numeroFattura').ej2_instances[0].value} è stata emessa.`, {
                                containerId: 'toastContainer1',
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true,
                                onOpen: () => {
                                    var countElmNota = this.gridInstanceEmissioneNota.dataSource.length + this.gridInstanceAltriTipiPrest.dataSource.length;
                                    this.toggleBlocking();
                                    setTimeout(() => {
                                        this.caricaDati();
                                        this.toggleBlocking();
                                    }, countElmNota * 150)

                                }
                            });
                        },
                        buttonModel: {
                            content: 'CONFERMA',
                            cssClass: 'e-primary',
                            isPrimary: true
                        }
                    },
                    {
                        click: () => {
                            this.dialogInstance.hide();
                        },
                        buttonModel: {
                            content: 'ANNULLA',
                        }
                    }
                ];
            }
            else {
                this.dialogInstance.header = 'Attenzione!!';
                this.dialogInstance.content = ret.err;
                this.dialogInstance.height = '27%';
                this.dialogInstance.minHeight = '27%';
                this.dialogInstance.width = '25%';
                this.dialogInstance.buttons = [{ click: () => { this.dialogInstance.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance.show();

            }
        }).catch(err => {
            toast.warn(err, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        })
    }
    onSelectSin(args) {
        const dataObj = {
        };
        let stringaIn = this.gridInstanceSinistri.getSelectedRecords().map(elm => { return elm.id_sinistro }).join(',');
        let arrayString = [];
        arrayString = stringaIn.split(",");
        this.numeroSinCount = arrayString.length;
        const QueryExec = `UPDATE [08003] SET [08003].crea = 1 FROM [08003] INNER JOIN [01001] ON [08003].id_sinistro = [01001].id_sinistro WHERE [01001].id_sinistro IN (${stringaIn})`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    onDeselectSin(args) {
        const dataObj = {
        };
        let QueryExec = '';
        if (args.isHeaderCheckboxClicked === true) {
            QueryExec = `UPDATE [08003] SET [08003].crea = 0 FROM [08003] INNER JOIN [01001] ON [08003].id_sinistro = [01001].id_sinistro WHERE [08003].idPerito = ${document.getElementById('idPerito').ej2_instances[0].value}`;
        }
        else {
            if(_.isArray(args.data)){
                QueryExec = `UPDATE [08003] SET [08003].crea = 0 FROM [08003] INNER JOIN [01001] ON [08003].id_sinistro = [01001].id_sinistro WHERE [01001].id_sinistro IN (${args.data.map(elm => { return elm.id_sinistro }).join(',')})`;
            }
            else{
                QueryExec = `UPDATE [08003] SET [08003].crea = 0 FROM [08003] INNER JOIN [01001] ON [08003].id_sinistro = [01001].id_sinistro WHERE [01001].id_sinistro = ${args.data.id_sinistro}`;
            }
        }
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        }, true, true);
    }
    resetta_popola() {
        const dataObj = {
        };
        const QueryExec = `DELETE FROM [08003] WHERE [08003].idPerito = ${document.getElementById('idPerito').ej2_instances[0].value}; INSERT INTO [08003] ( idPerito, id_sinistro, data_consegna_perizia, numero_schede) SELECT [01002].id_utente, [01001].id_sinistro, [01002].t_riconsegnato, [01002].numero_schede FROM [01001] INNER JOIN (SELECT MAX([01002].id_perizia) AS MaxDiid_perizia, [01002].id_sinistro From [01002] WHERE [01002].revocata = 0 And [01002].id_utente = ${document.getElementById('idPerito').ej2_instances[0].value} GROUP BY [01002].id_sinistro) AS sub2 ON [01001].id_sinistro = sub2.id_sinistro LEFT JOIN [040051] ON [01001].id_sinistro = [040051].id_sinistro INNER JOIN [01002] ON sub2.MaxDiid_perizia = [01002].id_perizia WHERE [040051].id_sinistro IS NULL AND ([01001].id_stato = 3 OR [01001].id_stato = 22) AND NOT EXISTS (SELECT [08004].id_sinistro FROM [08004] WHERE [08004].id_sinistro = [01001].id_sinistro)`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'POST', QueryExec, JSON.stringify(dataObj), retData => {
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
        }, true, true);
    }
    onCommandClick(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-eye') > -1) {
            const QueryExec = `SELECT [04005].id_utente_fatturazione, (SELECT SUM([040051].importo_num) FROM [040051] WHERE [040051].id_fattura = 1480) AS tot_fattura, 'Stesura relazioni peritali' AS descrizione_oggetto, 1 AS tipo_nota, [04005].data_fattura AS DataDoc, [04005].numero_fattura AS NumDoc, [09001].username, [09001].nome, [09001].cognome, [09001].ragione_sociale, [09001].cod_fiscale, [09001].p_iva, [09001].indirizzo, [09001].cap, [09001].citta, [09001].provincia, [09001].data_nascita, [09001].luogo_nascita, [09001].telefono1, [09001].email1, [04005].id_metodo_fatturazione AS IdMetodoFatt, [09001].id_utente, [09001].iban FROM [09001] INNER JOIN [04005] ON [09001].id_utente = [04005].id_utente_fatturazione WHERE [09001].id_utente = ${document.getElementById('idPerito').ej2_instances[0].value} AND [04005].id_fattura = ${args.rowData.id_fattura} UNION ALL SELECT [04005].id_utente_fatturazione, (SELECT SUM([040052].importo_num) FROM [040052] WHERE [040052].id_fattura = ${args.rowData.id_fattura}) AS tot_fattura, '' AS descrizione_oggetto, 1 AS tipo_nota, [04005].data_fattura, [04005].numero_fattura, [09001].username, [09001].nome, [09001].cognome, [09001].ragione_sociale, [09001].cod_fiscale, [09001].p_iva, [09001].indirizzo, [09001].cap, [09001].citta, [09001].provincia, [09001].data_nascita, [09001].luogo_nascita, [09001].telefono1, [09001].email1, [09001].id_metodo_fatturazione, [09001].id_utente, [09001].iban FROM [09001] INNER JOIN [04005] ON [09001].id_utente = [04005].id_utente_fatturazione WHERE [09001].id_utente = ${document.getElementById('idPerito').ej2_instances[0].value} AND [04005].id_fattura = ${args.rowData.id_fattura};`;
            CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
                this.arrAnteprimaNoteEmesse.push(retData);
                const QueryExec2 = `SELECT [040051].id_fattura, [040051].id_sinistro, [01001].id_interno_sinistro, [040051].importo_num AS tot_sinistro, [040051].numero_schede FROM [040051] INNER JOIN [01001] ON [040051].id_sinistro = [01001].id_sinistro WHERE [040051].id_fattura = ${args.rowData.id_fattura};`;
                CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec2, '', retData2 => {
                    this.arrAnteprimaNoteEmesse.push(retData2);
                    const QueryExec3 = `SELECT [040052].id_fattura, [040052].importo_num AS importo, [040052].oggetto, [040052].descrizione FROM [040052] WHERE [040052].id_fattura = ${args.rowData.id_fattura};`;
                    CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec3, '', retData3 => {
                        this.toggleBlocking();
                        this.arrAnteprimaNoteEmesse.push(retData3);
                        let sum = 0;
                        for (let i = 0; i < this.arrAnteprimaNoteEmesse[2].length; i++) {
                            let importi = this.arrAnteprimaNoteEmesse[2][i].importo;
                            sum += importi;
                        }
                        this.arrAnteprimaNoteEmesse[1].forEach(elm => {
                            elm.tot_sinistri = this.arrAnteprimaNoteEmesse[1].sum2('tot_sinistro')
                        })
                        const dataObj = {
                            NumDoc: this.arrAnteprimaNoteEmesse[0][0].NumDoc,
                            DataDoc: this.arrAnteprimaNoteEmesse[0][0].DataDoc,
                            IdMetodoFatt: this.arrAnteprimaNoteEmesse[0][0].IdMetodoFatt,
                            GridAltriTipiPrestaz: this.arrAnteprimaNoteEmesse[2],
                            SommaImporti: sum,
                        }
                        CustomJS.getRemoteData4(`${CustomJS.APIVariousJfishTechURLProd}/api/PdfCreator/GeneraNotaEmissioneCompetenze`, { datiNotaEmissioneCompetenzeJSON: JSON.stringify(this.arrAnteprimaNoteEmesse), datiPeritoJSON: JSON.stringify(dataObj) }, 'POST',
                            { "Authorization": CustomJS.tokenAuthAPIVarious }, retData4 => {
                                this.dialogInstance5.header = 'ANTEPRIMA NOTA COMPETENZE'
                                this.dialogInstance5.width = '65%';
                                this.dialogInstance5.height = '90%';
                                this.dialogInstance5.minHeight = '90%';
                                this.dialogInstance5.buttons = [];
                                this.dialogInstance5.open = (args) => {
                                    const container = document.getElementById('defaultDialogContent2');
                                    const root = createRoot(container);                    
                                    root.render(<CallbackWrapper callback={() => {
                                        document.getElementById(`PDFViewer`).ej2_instances[0].enableDownload = true;
                                        document.getElementById(`PDFViewer`).ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIVarious }];
                                        //this.PDFViewerInstance.dataBind();  //NON SEMBRA SERVIRE
                                        setTimeout(() => {
                                            document.getElementById(`PDFViewer`).ej2_instances[0].load(retData4, null);
                                            // document.getElementById(`PDFViewer${idSin}`).ej2_instances[0].load(`data:application/pdf;base64,${retData}`, null);
                                        }, 250);
                                        this.toggleBlocking(); 
                                    }}>{this.PDFViewerRender()}</CallbackWrapper>);   
                                };
                                this.dialogInstance5.show();
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    containerId: 'toastContainer1',
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true
                                }
                                );
                            });
                        this.arrAnteprimaNoteEmesse = [];
                    }, messaggio => {
                        toast.warn(messaggio, {
                            containerId: 'toastContainer1',
                            onClose: () => toast.dismiss(),
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        }
                        );
                    }, true, true);
                }, messaggio => {
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    }
                    );
                }, true, true);
            }, messaggio => {
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                }
                );
            }, true, true);
        }
    }

    render() {
        return (<React.Fragment>
            <BlockUi id='blockUIPaginaEmissioniCompetenze' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <DialogComponent id="defaultDialog" target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} height='80%' width='90%'
                    ref={dialog => this.dialogInstance = dialog} isModal={true} >
                </DialogComponent>
                <DialogComponent id="defaultDialog5" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog2}
                    ref={dialog => this.dialogInstance5 = dialog} isModal={true} height='80%' width='90%' minHeight='80%'>
                    <div id='defaultDialogContent2'></div>
                </DialogComponent>
                <input type="hidden" id='indice' />
                <div className='container-fluid' style={{ display: 'flex', marginTop: '60px' }}>
                    <div className="control-section card-control-section basic_card_layout" style={{ width: '50%' }}>
                        <div className="e-card-resize-container">
                            <div className="row card-layout">
                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                    <div tabIndex="0" className="e-card" id="main_card">
                                        <div className="e-card-content">
                                            <div className='row'>
                                                <div className="control-section card-control-section basic_card_layout">
                                                    <div className="e-card-resize-container">
                                                        <div className="row card-layout">
                                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                                <div tabIndex="0" className="e-card" id="main_card">
                                                                    <div className="e-card-content">
                                                                        <div className='row'>
                                                                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" style={{ marginLeft: '50px' }}>
                                                                                <TextBoxComponent id='perito' floatLabelType='Auto' placeholder='Perito' readonly={true} cssClass='e-outline' value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].username}></TextBoxComponent>
                                                                            </div>
                                                                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" >
                                                                                <TextBoxComponent id='idPerito' floatLabelType='Auto' placeholder='ID Utente' readonly={true} cssClass='e-outline' value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].id_utente}></TextBoxComponent>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ marginLeft: '50px' }}>
                                                                                <TextBoxComponent id='nome' floatLabelType='Auto' placeholder='Nome' cssClass='e-outline' readonly={true} value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].nome}></TextBoxComponent>
                                                                            </div>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                                <TextBoxComponent id='cognome' floatLabelType='Auto' placeholder='Cognome' cssClass='e-outline' readonly={true} value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].cognome}></TextBoxComponent>
                                                                            </div>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                                <TextBoxComponent id='codiceFiscale' floatLabelType='Auto' placeholder='Cod.Fiscale' cssClass='e-outline' readonly={true} value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].cod_fiscale}></TextBoxComponent>
                                                                            </div>
                                                                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                                <TextBoxComponent id='pIva' floatLabelType='Auto' placeholder='P.IVA' cssClass='e-outline' readonly={true} value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].p_iva}></TextBoxComponent>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ marginLeft: '50px' }}>
                                                                                <TextBoxComponent id='indirizzo' floatLabelType='Auto' placeholder='Indirizzo' cssClass='e-outline' readonly={true} value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].indirizzo}></TextBoxComponent>
                                                                            </div>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                                <TextBoxComponent id='citta' floatLabelType='Auto' placeholder='Città' cssClass='e-outline' readonly={true} value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].citta}></TextBoxComponent>
                                                                            </div>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                                <TextBoxComponent id='provincia' floatLabelType='Auto' placeholder='Provincia' cssClass='e-outline' readonly={true} value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].provincia}></TextBoxComponent>
                                                                            </div>
                                                                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                                <TextBoxComponent id='cap' floatLabelType='Auto' placeholder='CAP' cssClass='e-outline' readonly={true} value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].cap}></TextBoxComponent>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ marginLeft: '50px' }}>
                                                                                <TextBoxComponent id='dataNascita' floatLabelType='Auto' placeholder='Data Nascita' cssClass='e-outline' readonly={true} value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].data_nascita === null ? '' : moment(JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].data_nascita).format('DD/MM/YYYY')}></TextBoxComponent>
                                                                            </div>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                                <TextBoxComponent id='luogoNascita' floatLabelType='Auto' placeholder='Luogo Nascita' cssClass='e-outline' readonly={true} value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].luogo_nascita}></TextBoxComponent>
                                                                            </div>
                                                                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                                                                <TextBoxComponent id='provinciaNascita' floatLabelType='Auto' placeholder='Prov. Nascita' cssClass='e-outline' readonly={true} value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].provincia_nascita}></TextBoxComponent>
                                                                            </div>
                                                                            <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3">
                                                                                <TextBoxComponent id='telefono' floatLabelType='Auto' placeholder='Telefono' cssClass='e-outline' readonly={true} value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].telefono1}></TextBoxComponent>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" style={{ marginLeft: '50px' }}>
                                                                                <DropDownListComponent id='metodoFatturazione' fields={this.ddlFields} dataSource={this.dsMetodFatt}
                                                                                    allowFiltering={true} filterType='Contains' showClearButton={true} value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].id_metodo_fatturazione?.toString()}
                                                                                    placeholder="Metodo di pagamento" floatLabelType='always' popupHeight="250px" cssClass='e-outline'>
                                                                                </DropDownListComponent>
                                                                            </div>
                                                                            <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5">
                                                                                <TextBoxComponent id='iban' floatLabelType='Auto' placeholder='IBAN' cssClass='e-outline' readonly={true} value={JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).data[0].iban}></TextBoxComponent>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="control-section card-control-section basic_card_layout">
                                                <div className="e-card-resize-container">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                            <div tabIndex="0" className="e-card" id="main_card">
                                                                <div className="e-card-content">
                                                                    <div className='row'>
                                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" >
                                                                            <GridComponent id='gridSinistri' ref={grid => this.gridInstanceSinistri = grid} allowSelection={true} enableHover={true} allowTextWrap={true} toolbarClick={this.toolbarClick.bind(this)} dataBound={this.onDataBound.bind(this)}
                                                                                allowPaging={true} allowSorting={false} allowEditing={false} allowFiltering={false} enableAltRow={true} pageSettings={this.pageSettings1} filterSettings={this.filterSettings} 
                                                                                toolbar={this.toolbarOptions} showColumnChooser={true} rowSelected={this.onSelectSin.bind(this)} rowDeselected={this.onDeselectSin.bind(this)} enableInfiniteScrolling={false} style={{ marginLeft: '40px', marginRight: '5px' }}>
                                                                                <ColumnsDirective>
                                                                                    <ColumnDirective field='Comandi' headerText="" width='6%' type='checkbox'></ColumnDirective>
                                                                                    <ColumnDirective field='idPerito' headerText="ID Perito" visible={false} validationRules={{ required: true }} isPrimaryKey='true' readonly={true} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='id_sinistro' headerText="ID Sinistro" visible={false} validationRules={{ required: true }} isPrimaryKey='true' readonly={true} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='id_interno_sinistro' headerText="ID Interno Sinistro" readonly={true} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='numero_sinistro_agenzia' headerText="N° Sinistro Agenzia." width='25%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='numero_schede' headerText="Numero Schede" textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='data_consegna_perizia' headerText="Data Consegna Perizia" width='20%' type='Date' format='dd/MM/yyyy' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='crea' headerText="Crea" width='20%' visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='parcellaPerito' headerText="Importo Calcolato" textAlign='Center' headerTemplate={headerTemplate} type='number' editType='NumericEdit' format='N2' ></ColumnDirective>
                                                                                    <ColumnDirective field='fatturabile' headerText="Fatturabile" visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                </ColumnsDirective>
                                                                                <Inject services={[Toolbar, ColumnChooser, Filter, CommandColumn, Sort, Edit, DetailRow, Page, InfiniteScroll]} />
                                                                            </GridComponent>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="control-section card-control-section basic_card_layout" style={{ width: '50%' }}>
                        <div className="e-card-resize-container">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <div tabIndex="0" className="e-card" id="main_card">
                                    <div className="e-card-content">
                                        <div className="control-section card-control-section basic_card_layout">
                                            <div className="e-card-resize-container">
                                                <div className="row card-layout">
                                                    <div className="row card-layout">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                            <div tabIndex="0" className="e-card" id="main_card">
                                                                <div className="e-card-content" style={{ backgroundColor: '#F8F8FF' }}>
                                                                    <div className='row'>
                                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-left">
                                                                            <h5 className="card-title">Emissione Nota</h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3 text-left">
                                                                            <TextBoxComponent id='numeroFattura' floatLabelType='Auto' placeholder='Numero Documento' cssClass='e-outline'></TextBoxComponent>
                                                                        </div>
                                                                        <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3 text-left">
                                                                            <DatePickerComponent id='dataFattura' cssClass='e-outline' format='dd/MM/yyyy'
                                                                                allowEdit={false} showClearButton={true} placeholder='Data Documento' floatLabelType='Always'></DatePickerComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" >
                                                                            <GridComponent id='gridEmissioneNota' ref={grid => this.gridInstanceEmissioneNota = grid} allowSelection={true} enableHover={true} allowTextWrap={true}
                                                                                allowPaging={true} allowSorting={false} allowFiltering={false} enableAltRow={true} pageSettings={this.pageSettings1}
                                                                                editSettings={this.editSettings} showColumnChooser={true} actionComplete={this.onActionComplete.bind(this)} toolbar={this.toolbarOptionsEmissNota}>
                                                                                <ColumnsDirective>
                                                                                    <ColumnDirective field='idPerito' headerText="ID Perito" visible={false} readonly={true} isPrimaryKey='true' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='id_sinistro' headerText="ID Sinistro." width='20%' visible={false} isPrimaryKey='true' textAlign='Center' headerTemplate={headerTemplate} ></ColumnDirective>
                                                                                    <ColumnDirective field='data_fattura' headerText="Data Fattura" width='20%' type='Date' format='dd/MM/yyyy' visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='id_utente_fatturazione' headerText="ID Utente Fatturazione" width='20%' visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='id_interno_sinistro' headerText="ID Interno Sinistro" width='20%' textAlign='Center' headerTemplate={headerTemplate} allowEditing={false}></ColumnDirective>
                                                                                    <ColumnDirective field='numero_sinistro_agenzia' headerText="Numero Sinistro Agenzia" width='25%' textAlign='Center' headerTemplate={headerTemplate} allowEditing={false}></ColumnDirective>
                                                                                    <ColumnDirective field='numero_schede' headerText="Numero Schede" editType='NumericEdit' format='N0' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='data_consegna_perizia' headerText="Data Consegna Perizia" width='20%' type='Date' format='dd/MM/yyyy' textAlign='Center' headerTemplate={headerTemplate} allowEditing={false}></ColumnDirective>
                                                                                    <ColumnDirective field='importo' headerText="Importo" textAlign='Center' headerTemplate={headerTemplate} type='number' editType='NumericEdit' format='N2'></ColumnDirective>
                                                                                    <ColumnDirective field='Comandi' headerText="" width='6%' commands={this.commands} headerTemplate={headerTemplate} textAlign='Right' allowSorting={false} allowFiltering={false}></ColumnDirective>
                                                                                </ColumnsDirective>
                                                                                <Inject services={[Toolbar, ColumnChooser, Filter, CommandColumn, Sort, Edit, DetailRow, Page]} />
                                                                            </GridComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-left">
                                                                            <h5 className="card-title">Altri Tipi Di Prestazioni</h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" >
                                                                            <GridComponent id='gridAltriTipiPrestazioni' ref={grid => this.gridInstanceAltriTipiPrest = grid} allowSelection={true} enableHover={true} allowTextWrap={true}
                                                                                allowPaging={false} allowSorting={false} allowFiltering={false} enableAltRow={true} pageSettings={this.pageSettings} filterSettings={this.filterSettings}
                                                                                toolbar={this.toolbarOptions2} editSettings={this.editSettingsAltrePrest} showColumnChooser={true} actionComplete={this.onActionComplete2.bind(this)} toolbarClick={this.toolbarClick.bind(this)} >
                                                                                <ColumnsDirective>
                                                                                    <ColumnDirective field='id_altra_prest' isPrimaryKey={true} visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='idPerito' visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='id_sinistro' visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                    <ColumnDirective field='oggetto' headerText="Tipo Prestazione" textAlign='Center' headerTemplate={headerTemplate} visible={false}></ColumnDirective>
                                                                                    <ColumnDirective field='descrizione' headerText="Descrizione" textAlign='Center' headerTemplate={headerTemplate} validationRules={{ required: true }}></ColumnDirective>
                                                                                    <ColumnDirective field='importo' headerText="Importo" textAlign='Center' headerTemplate={headerTemplate} validationRules={{ required: true }} editType='NumericEdit' format='N2'></ColumnDirective>
                                                                                    <ColumnDirective field='Comandi' headerText="" width='6%' commands={this.commands} headerTemplate={headerTemplate} textAlign='Right' allowSorting={false} allowFiltering={false}></ColumnDirective>
                                                                                </ColumnsDirective>
                                                                                <Inject services={[Toolbar, ColumnChooser, Filter, CommandColumn, Sort, Edit, DetailRow, Page]} />
                                                                            </GridComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className="control-section card-control-section basic_card_layout">
                                                                        <div className="e-card-resize-container">
                                                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                                                <div tabIndex="0" className="e-card" id="main_card">
                                                                                    <div className="e-card-content">
                                                                                        <div className="control-section card-control-section basic_card_layout">
                                                                                            <div className="e-card-resize-container">
                                                                                                <div className="row card-layout">
                                                                                                    <div className="row card-layout">
                                                                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                                                                            <div tabIndex="0" className="e-card" id="main_card">
                                                                                                                <div className="e-card-content" style={{ backgroundColor: '#F8F8FF' }}>
                                                                                                                    <div className='row'>
                                                                                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-left">
                                                                                                                            <h5 className="card-title">Note Emesse</h5>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className='row'>
                                                                                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" >
                                                                                                                            <GridComponent id='gridNoteEmesse' ref={grid => this.gridInstanceNoteEmesse = grid} allowSelection={true} enableHover={true} allowTextWrap={true}
                                                                                                                                allowPaging={true} allowSorting={false} allowFiltering={false} enableAltRow={true} pageSettings={this.pageSettings2} filterSettings={this.filterSettings}
                                                                                                                                showColumnChooser={true} commandClick={this.onCommandClick.bind(this)}>
                                                                                                                                <ColumnsDirective>
                                                                                                                                    <ColumnDirective field='id_fattura' headerText="ID Fattura" visible={false} validationRules={{ required: true }} isPrimaryKey='true' readonly={true} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                                                                    <ColumnDirective field='numero_fattura' headerText="N° Doc." width='20%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                                                                    <ColumnDirective field='data_fattura' headerText="Data Doc." width='20%' type='Date' format='dd/MM/yyyy' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                                                                    <ColumnDirective field='importo_tot' headerText="Totale" width='20%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                                                                    <ColumnDirective field='conta_sinistri' headerText="Sinistri" width='20%' textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                                                                    <ColumnDirective field='id_metodo_fatturazione' headerText="ID Metodo Fatturazione" visible={false} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                                                                    <ColumnDirective field='Comandi' headerText="" width='10%' commands={this.commands1} textAlign='Right' headerTemplate={headerTemplate}></ColumnDirective>
                                                                                                                                </ColumnsDirective>
                                                                                                                                <Inject services={[Toolbar, ColumnChooser, Filter, CommandColumn, Sort, Edit, DetailRow, Page]} />
                                                                                                                            </GridComponent>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>
        );
    }
    componentDidMount() {
        document.getElementById('titoloPagina').innerText = 'Emissioni Competenze';
        this.resetta_popola();
        setTimeout(() => {
            this.caricaDati();
        }, 300);
    }
    caricaDati() {
        let get_data_meseprec = moment(new Date()).subtract(1, 'months').endOf('month').format('YYYYMMDD');
        const QueryExec = `SELECT [01001].id_interno_sinistro, [01001].numero_sinistro_agenzia, [01001].parcellaPerito, [08003].crea, [08003].data_consegna_perizia, [08003].numero_schede, [08003].id_sinistro FROM [01001] INNER JOIN [08003] ON [01001].id_sinistro = [08003].id_sinistro WHERE [08003].crea = 0 AND [08003].idPerito=${document.getElementById('idPerito').ej2_instances[0].value} AND NOT EXISTS (SELECT [08004].id_sinistro FROM [08004] WHERE [08004].id_sinistro = [01001].id_sinistro) ORDER BY [08003].data_consegna_perizia ASC;`;
        this.toggleBlocking();
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec, '', retData => {
            retData.forEach(elm => {
                elm.fatturabile = (moment(elm.data_consegna_perizia).format('YYYYMMDD') <= get_data_meseprec) ? 'si' : '';
            })
            this.dsSinistri = retData.map(elm => { return { id_sinistro: elm.id_sinistro, id_interno_sinistro: elm.id_interno_sinistro, numero_sinistro_agenzia: elm.numero_sinistro_agenzia, crea: elm.crea, data_consegna_perizia: elm.data_consegna_perizia, numero_schede: elm.numero_schede, parcellaPerito: elm.parcellaPerito, fatturabile: elm.fatturabile } });
            document.getElementById(`gridSinistri`).ej2_instances[0].dataSource = this.dsSinistri;
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        }, true, true);
        const QueryExec2 = `SELECT [08004].*, [01001].id_interno_sinistro, [01001].numero_sinistro_agenzia FROM [01001] INNER JOIN [08004] ON [01001].id_sinistro=[08004].id_sinistro WHERE [08004].idPerito=${document.getElementById('idPerito').ej2_instances[0].value};`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec2, '', retData2 => {
            this.dsEmissioneNota = retData2.map(elm => { return { id_interno_sinistro: elm.id_interno_sinistro, numero_sinistro_agenzia: elm.numero_sinistro_agenzia, idPerito: elm.idPerito, importo: elm.importo, numero_schede: elm.numero_schede, id_utente_fatturazione: elm.id_utente_fatturazione, data_fattura: elm.data_fattura, id_sinistro: elm.id_sinistro, data_consegna_perizia: elm.data_consegna_perizia } });
            document.getElementById(`gridEmissioneNota`).ej2_instances[0].dataSource = this.dsEmissioneNota;
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        }, true, true);
        const QueryExec3 = `SELECT [04005].numero_fattura, [04005].data_fattura, [04005].id_fattura, tot_fatturato.importo_tot, tab_conta_sinistri.conta_sinistri, [04005].id_metodo_fatturazione FROM [04005] INNER JOIN [09001] ON [04005].id_utente_fatturazione=[09001].id_utente INNER JOIN (SELECT [040051].id_fattura, [040051].importo AS importo_tot FROM [040051] UNION ALL SELECT [040052].id_fattura, [040052].importo AS importo_tot FROM [040052]) tot_fatturato ON [04005].id_fattura = tot_fatturato.id_fattura LEFT JOIN (SELECT [040051].id_fattura, Count([040051].id_sinistro) AS conta_sinistri FROM [040051] GROUP BY [040051].id_fattura) tab_conta_sinistri ON [04005].id_fattura = tab_conta_sinistri.id_fattura WHERE [04005].id_utente_fatturazione = ${document.getElementById('idPerito').ej2_instances[0].value} ORDER BY [04005].data_fattura DESC`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec3, '', retData3 => {
            let groupedArr1 = _.groupBy(retData3, 'id_fattura');
            let uniqArray = _.uniq(retData3, elm => { return elm.id_fattura });
            Object.values(groupedArr1).forEach((elm, idx) => {
                _.find(uniqArray, elm2 => { return elm2.id_fattura === parseInt(Object.keys(groupedArr1)[idx]) }).importo_tot = elm.sum3("importo_tot");
            });
            document.getElementById(`gridNoteEmesse`).ej2_instances[0].dataSource = uniqArray;
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        }, true, true);
        const QueryExec4 = `SELECT [08006].* FROM [08006] WHERE [08006].id_utente_fatturazione = ${document.getElementById('idPerito').ej2_instances[0].value}`;
        CustomJS.eseguiQuery(CustomJS.APIJfishTechURLProd, JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token, 'GET', QueryExec4, '', retData4 => {
            this.dsAltriTipiPrestazioni = retData4.map(elm => { return { id_altra_prest: elm.id_altra_prest, idPerito: elm.id_utente_fatturazione, oggetto: elm.oggetto, importo: elm.importo, descrizione: elm.descrizione } });
            document.getElementById(`gridAltriTipiPrestazioni`).ej2_instances[0].dataSource = this.dsAltriTipiPrestazioni;
        }, messaggio => {
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            }
            );
        }, true, true);
    }
}