/* eslint-disable default-case */
import React from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, CommandColumn, ExcelExport, PdfExport, Edit, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { random, isUndefined } from 'underscore';

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
export default class PaginaAree extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.toolbarOptions = ['Add', 'Edit', 'Delete','Update', 'Cancel', 'Search', 'ExcelExport'];
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Normal'};
        this.pageSettings = {pageSize : 15, pageCount : 15};
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                         { type: "Delete", buttonOption: { iconCss: "fas fa-trash fa-10x", cssClass: "e-details" }}];
    }
    state = {
        dsAree: [],
        blocking: false,
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }
    actionComplete(args) {
        if (((args.action === "edit" || args.action === "add")  && args.requestType === "save") || args.requestType === "delete") {       
            const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
            debugger;
            const azione =  args.requestType === "delete" ? 'Delete' : args.action === "edit" ? 'Edit' : args.action === "add" ? 'Insert' : 'AzioneNonPrevista';
            const tabella = '00007';
            const dataObj = {
                IdArea: CustomJS.isNullEmpty(args.data[0]) ? args.data.id_area : args.data[0].id_area,
                Descrizione: CustomJS.isNullEmpty(args.data[0]) ? args.data.descrizione : args.data[0].descrizione
            };
            CustomJS.callAPI(`${CustomJS.APIJfishTechURLProd}/api/JellyfishTechnology/salvaTabella/${tabella}/${azione}`, JSON.stringify(dataObj), 'POST',
                'application/json', token, retData => {
                    if (retData.response === 'KO') {
                        let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg.message;
                        toast.warn(messaggio, {
                            containerId: 'toastContainer2',
                            onClose: () => toast.dismiss(),                   
                            position: "bottom-right",
                            autoClose: 15000,
                            hideProgressBar: false,           
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        });                           
                        return;
                    }   
                    if (retData.response === 'OK') {   
                        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, tabella, retData2 => {
                            if (retData2.length > 0) {
                                let emptyObj = {};
                                Object.keys(retData2[0]).forEach(e => emptyObj[e] = '');
                                retData2.unshift(emptyObj);
                            }
                            sessionStorage.setItem('JellyfishTechnologyTabella' + tabella, CustomJS.compressAndEncode(retData2));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,           
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            );
                        });                       
                    }                         
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer2',
                    onClose: () => toast.dismiss(),
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,           
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true})
            }, true, false);
        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    }

    render() {
        return(<React.Fragment>
            <BlockUi id='blockUIPaginaBeni' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
            <ToastContainer transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content"> 
                                        {/* <div className='row'></div> 
                                        <div className='row'></div>
                                        <div className='row'></div>   */}
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <GridComponent id="GridAree" dataSource={this.state.dsAree} ref={ grid => this.gridInstance = grid } allowSelection={true} enableHover={true} allowTextWrap={true}
                                                    allowPaging={true} allowSorting={false} allowFiltering={false} enableAltRow={true} enablePersistence={true} pageSettings={this.pageSettings}
                                                    actionComplete={this.actionComplete.bind(this)} toolbarClick={this.toolbarClick.bind(this)} 
                                                    toolbar={this.toolbarOptions} editSettings={this.editSettings} allowExcelExport={true} style={{ marginLeft: '50px', marginRight: '5px'}} >
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='id_area' headerText='ID Area' isPrimaryKey='true' visible={false}></ColumnDirective>
                                                        <ColumnDirective field='descrizione' headerText="Descrizione" validationRules={{ required: true }} textAlign='Center' headerTemplate={headerTemplate}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='10%' commands={this.commands} textAlign='Right' headerTemplate={headerTemplate}></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort, Edit]} />
                                                </GridComponent>
                                            </div>
                                        </div>                        
                                    </div>                              
                                </div>
                            </div> 
                        </div>
                    </div>
               </div>
               </BlockUi>
            </React.Fragment>
        );
    }
    componentDidMount(){
        // document.getElementById('titoloPagina').innerText = 'Aree';
        this.caricaDati();
    }
    caricaDati(){
        this.toggleBlocking();
        const token = JSON.parse(CustomJS.decodeAndUncompress(sessionStorage.getItem('JellyfishTechnologyAuthenticationPayload'))).token;
        CustomJS.caricaCombo(CustomJS.APIJfishTechURLProd, token, '00007', retData => {
            this.setState({dsAree:retData})
            this.toggleBlocking();
        }, messaggio => {
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true}
            ); 
            this.toggleBlocking();
        });
    }   
}